import { Box, useTheme } from '@mui/material';
import { WebChatStyle } from '../../../models/webChatStyle';
import { FormikProps } from 'formik';

interface Bubble2Props {
  formikProps: FormikProps<WebChatStyle>;
}

export const Bubble2: React.FC<Bubble2Props> = ({ formikProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: formikProps.values.bubbleStyle.bubbleColor2.color,
        borderRadius: '50%',
        padding: '13px',
        width: 160,
        height: 160,
        boxShadow:
          formikProps.values.bubbleStyle.dropShadow &&
          formikProps.values.bubbleStyle.innerShadow
            ? '0px 4px 7px rgba(0, 0, 0, 0.5), 0px 30px 60px -12px inset rgba(50, 50, 93, 0.25), 0px 18px 36px -18px inset rgba(0, 0, 0, 0.3)'
            : formikProps.values.bubbleStyle.innerShadow
              ? '0px 30px 60px -12px inset rgba(50, 50, 93, 0.25), 0px 18px 36px -18px inset rgba(0, 0, 0, 0.3)'
              : formikProps.values.bubbleStyle.dropShadow
                ? '0px 4px 7px rgba(0, 0, 0, 0.5)'
                : 'none',
        backdropFilter: formikProps.values.bubbleStyle.layerBlur
          ? 'blur(10px)'
          : 'none'
      }}
      boxShadow={2}
    >
      <svg
        width={formikProps.values.bubbleStyle.size * 1.5}
        height={formikProps.values.bubbleStyle.size * 1.5}
        viewBox="0 0 120 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          filter: formikProps.values.bubbleStyle.layerBlur
            ? 'blur(4px)'
            : 'none'
        }}
      >
        <g transform="translate(0, 7.5)">
          <path
            d="M16 29C16 16.8497 25.8497 7 38 7H82C94.1503 7 104 16.8497 104 29V62C104 65.3137 101.314 68 98 68H38C25.8497 68 16 58.1503 16 46V29Z"
            fill={formikProps.values.bubbleStyle.bubbleColor1.color}
          />
        </g>
        <g transform="translate(45, 45)">
          <path
            d="M0.515625 3.60742C0.515625 2.84245 0.751628 2.19954 1.22363 1.67871C1.71191 1.1416 2.41178 0.873047 3.32324 0.873047C4.2347 0.873047 4.92643 1.1416 5.39844 1.67871C5.88672 2.19954 6.13086 2.84245 6.13086 3.60742C6.13086 4.35612 5.88672 4.99089 5.39844 5.51172C4.92643 6.03255 4.2347 6.29297 3.32324 6.29297C2.41178 6.29297 1.71191 6.03255 1.22363 5.51172C0.751628 4.99089 0.515625 4.35612 0.515625 3.60742ZM13.6992 3.60742C13.6992 2.84245 13.9352 2.19954 14.4072 1.67871C14.8955 1.1416 15.5954 0.873047 16.5068 0.873047C17.4183 0.873047 18.11 1.1416 18.582 1.67871C19.0703 2.19954 19.3145 2.84245 19.3145 3.60742C19.3145 4.35612 19.0703 4.99089 18.582 5.51172C18.11 6.03255 17.4183 6.29297 16.5068 6.29297C15.5954 6.29297 14.8955 6.03255 14.4072 5.51172C13.9352 4.99089 13.6992 4.35612 13.6992 3.60742ZM26.8828 3.60742C26.8828 2.84245 27.1188 2.19954 27.5908 1.67871C28.0791 1.1416 28.779 0.873047 29.6904 0.873047C30.6019 0.873047 31.2936 1.1416 31.7656 1.67871C32.2539 2.19954 32.498 2.84245 32.498 3.60742C32.498 4.35612 32.2539 4.99089 31.7656 5.51172C31.2936 6.03255 30.6019 6.29297 29.6904 6.29297C28.779 6.29297 28.0791 6.03255 27.5908 5.51172C27.1188 4.99089 26.8828 4.35612 26.8828 3.60742Z"
            fill="#10103F"
          />
        </g>
      </svg>
    </Box>
  );
};
