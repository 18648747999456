import axios from 'axios';
import { ReportingTicket } from '../../models/tickets';
import { AvailableFilter } from 'src/components/DialTable/models/filters';
import {
  REPORTING_TICKET,
  REPORTING_TICKET_FILTER
} from 'src/utils/apiRoutes/msReporting';

export const getTicketFilterFieldsApi = async () => {
  const resp = await axios.get<AvailableFilter<ReportingTicket>[]>(
    REPORTING_TICKET_FILTER
  );
  return resp.data;
};

export const getReportingTicketFieldsApi = async () => {
  const resp =
    await axios.get<AvailableFilter<ReportingTicket>[]>(REPORTING_TICKET);
  return resp.data;
};
