import { CloseTwoTone } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography
} from '@mui/material';
import {
  WhatsAppTemplateType,
  WhatsAppTemplateVariables
} from 'src/services/whatsapp/models/WhatsAppTemplate';
import { WhatsAppTemplateParamsForm } from './WhatsAppTemplateParamsForm';
import { getWhatsAppTemplateParamsValidationSchema } from '../utils/getWhatsAppTemplateParamsSchema';
import { useTranslation } from 'react-i18next';
import { MouseEvent, useMemo } from 'react';
import FileDropZone from 'src/components/FileDropZone';
import useWhatsAppTemplateFileSelector from './useWhatsAppTemplateFileSelector';

interface Props {
  open: boolean;
  whatsAppTemplateParams: WhatsAppTemplateVariables;
  file: File;
  type: WhatsAppTemplateType;
  onChange: (whatsAppTemplateParams: WhatsAppTemplateVariables | File) => void;
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  title?: string;
  subheader?: string;
  setFile: React.Dispatch<React.SetStateAction<File>>;
}

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
              transition: ${theme.transitions.create([
                'transform',
                'background'
              ])};
              transform: scale(1);
              transform-origin: center;
          
              &:hover {
                  transform: scale(1.1);
              }
            `
);

export function WhatsAppTemplateParamsDialog(props: Props) {
  const {
    title,
    open,
    whatsAppTemplateParams,
    onClose,
    onSubmit,
    onChange,
    type,
    file,
    subheader,
    setFile
  } = props;
  const { t }: { t: any } = useTranslation();
  const validationSchema = getWhatsAppTemplateParamsValidationSchema(
    whatsAppTemplateParams as WhatsAppTemplateVariables
  );

  const hasVariables = Object.keys(whatsAppTemplateParams)?.length > 0;
  const schemaIsInvalid = !validationSchema.isValidSync(whatsAppTemplateParams);

  const { exceedMaxFileSize, sizeAlert, acceptedFiles, MaxSizeTypography } =
    useWhatsAppTemplateFileSelector({
      type,
      file
    });

  const isDisabled = useMemo(() => {
    if (type === WhatsAppTemplateType.TEXT) {
      return schemaIsInvalid;
    }

    if (file && exceedMaxFileSize) return true;

    if (!file) return true;

    if (file && hasVariables) {
      return schemaIsInvalid;
    }

    return false;
  }, [type, schemaIsInvalid, file, hasVariables, whatsAppTemplateParams]);

  return (
    <Dialog open={open} maxWidth="md">
      <Box width="500px">
        <CardHeader
          title={title ?? t('Template parameters')}
          subheader={subheader}
          action={
            <IconButtonWrapper
              size="small"
              color="secondary"
              onClick={(e) => {
                onClose();
              }}
            >
              <CloseTwoTone />
            </IconButtonWrapper>
          }
        />
        <Divider />
        <CardContent sx={{}}>
          {whatsAppTemplateParams &&
            Object.keys(whatsAppTemplateParams)?.length > 0 && (
              <Stack direction="column" mb={1}>
                <WhatsAppTemplateParamsForm
                  templateParams={
                    whatsAppTemplateParams as WhatsAppTemplateVariables
                  }
                  onChange={(values) => {
                    onChange(values);
                  }}
                />
              </Stack>
            )}
          {type !== WhatsAppTemplateType.TEXT && (
            <Stack direction="column">
              {exceedMaxFileSize && sizeAlert}

              <Typography mt={1.5} variant="h4">
                {t(
                  type?.charAt(0).toUpperCase() + type?.slice(1).toLowerCase()
                ) + ':'}
              </Typography>

              <FileDropZone
                files={file ? [file] : []}
                setFiles={(files) => {
                  setFile(files[0]);
                }}
                onChange={(file) => {
                  setFile(file);
                }}
                accept={acceptedFiles}
                info={MaxSizeTypography}
              />
            </Stack>
          )}
        </CardContent>
        <Divider orientation="horizontal" />
        <CardActions
          sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}
        >
          <Button
            color="secondary"
            variant="outlined"
            onClick={(e) => {
              onClose();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={isDisabled}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </CardActions>
      </Box>
    </Dialog>
  );
}
