import { FC, useState, createContext } from 'react';
import {
  WhatspAppAccount,
  WhatspAppAccountForm,
  WhatspAppAccountTypeForm
} from 'src/services/whatsapp/models/WhatsAppAccount';

type WhatsAppAccountContext = {
  openTemplatesModal: boolean;
  setOpenTemplatesModal: React.Dispatch<React.SetStateAction<boolean>>;
  openWhatsAppAccountModal: boolean;
  setOpenWhatsAppAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
  openCreateTemplateModal: boolean;
  setOpenCreateTemplateModal: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  variables: {
    variable: string;
    value: string;
  }[];
  setVariables: React.Dispatch<
    React.SetStateAction<
      {
        variable: string;
        value: string;
      }[]
    >
  >;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
  variablesBody: {
    variable: string;
    value: string;
  }[];
  setVariablesBody: React.Dispatch<
    React.SetStateAction<
      {
        variable: string;
        value: string;
      }[]
    >
  >;
  typeForm: WhatspAppAccountTypeForm;
  setTypeForm: React.Dispatch<React.SetStateAction<WhatspAppAccountTypeForm>>;
  whatsAppAccount: WhatspAppAccount;
  setWhatsAppAccount: React.Dispatch<React.SetStateAction<WhatspAppAccount>>;
  typeFormTemplate: WhatspAppAccountTypeForm;
  setTypeFormTemplate: React.Dispatch<
    React.SetStateAction<WhatspAppAccountTypeForm>
  >;
  providerId: string;
  setProviderId: React.Dispatch<React.SetStateAction<string>>;
  isFetching: boolean;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WhatsAppAccountContext = createContext<WhatsAppAccountContext>(
  {} as WhatsAppAccountContext
);

export const WhatsAppAccountProvider: FC = ({ children }) => {
  const [openTemplatesModal, setOpenTemplatesModal] = useState<boolean>(false);
  const [openWhatsAppAccountModal, setOpenWhatsAppAccountModal] =
    useState<boolean>(false);
  const [openCreateTemplateModal, setOpenCreateTemplateModal] =
    useState<boolean>(false);
  const [variables, setVariables] = useState<
    { variable: string; value: string }[]
  >([]);
  const [text, setText] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [variablesBody, setVariablesBody] = useState<
    { variable: string; value: string }[]
  >([]);
  const [typeForm, setTypeForm] = useState<WhatspAppAccountTypeForm>(
    WhatspAppAccountForm.CREATE
  );
  const [typeFormTemplate, setTypeFormTemplate] =
    useState<WhatspAppAccountTypeForm>(WhatspAppAccountForm.CREATE);
  const [whatsAppAccount, setWhatsAppAccount] = useState<WhatspAppAccount>();
  const [providerId, setProviderId] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  return (
    <WhatsAppAccountContext.Provider
      value={{
        openTemplatesModal,
        openWhatsAppAccountModal,
        openCreateTemplateModal,
        text,
        variables,
        body,
        variablesBody,
        typeForm,
        whatsAppAccount,
        typeFormTemplate,
        providerId,
        isFetching,
        setOpenTemplatesModal,
        setOpenWhatsAppAccountModal,
        setOpenCreateTemplateModal,
        setText,
        setVariables,
        setBody,
        setVariablesBody,
        setTypeForm,
        setWhatsAppAccount,
        setTypeFormTemplate,
        setProviderId,
        setIsFetching
      }}
    >
      {children}
    </WhatsAppAccountContext.Provider>
  );
};
