import axios from 'axios';
import { REPORTING_LOGS } from 'src/utils/apiRoutes/msReporting';

interface SendLogsApiProps {
  companyId: string;
  userId: string;
  logContent: string;
}

export function sendLogsApi({
  companyId,
  userId,
  logContent
}: SendLogsApiProps) {
  const url = `${REPORTING_LOGS}`;

  return axios.post(
    url,
    { logContent },
    {
      params: {
        companyId,
        userId
      }
    }
  );
}
