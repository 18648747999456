import { useEffect, useContext } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import VideoWebRTCDevices from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';

export const useScreenShare = () => {
  const { streams, rtcSession } = useContext(VideocallRoomContext);

  const startScreenShare = async () => {
    try {
      const newStream = await navigator.mediaDevices.getDisplayMedia({
        video: true
      });

      const localStream = streams.local;
      // Add tracks of the screen
      localStream.getVideoTracks().forEach((track) => track.stop());
      localStream
        .getAudioTracks()
        .forEach((track) => newStream.addTrack(track));

      VideoWebRTCDevices.replaceStreamOnRTCSession({
        stream: newStream,
        rtcSession: rtcSession.currentRTCSession,
        kind: 'video'
      });

      streams.setLocalStream(newStream);

      streams.setAmIScreenSharing(true);
    } catch (error) {
      console.error('Error starting screen share:', error);
    }
  };

  const stopScreenShare = async () => {
    streams.setAmIScreenSharing(false);
    streams.local.getVideoTracks().forEach((track) => track.stop());

    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    });

    streams.setLocalStream(stream);
    VideoWebRTCDevices.replaceStreamOnRTCSession({
      stream: stream,
      rtcSession: rtcSession.currentRTCSession,
      kind: 'video'
    });
  };

  const toggleScreenShare = () => {
    if (streams.amIScreenSharing) {
      stopScreenShare();
    } else {
      startScreenShare();
    }
  };

  useEffect(() => {
    return () => {
      if (streams.amIScreenSharing) {
        stopScreenShare();
      }
    };
  }, [streams.amIScreenSharing]);

  return {
    isScreenSharing: streams.amIScreenSharing,
    toggleScreenShare
  };
};
