import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { CAMPAIGNS_ID } from 'src/utils/apiRoutes/msUsers';
import { queryClient } from 'src/utils/queryClient';
import { CAMPAIGN_DRAFTS_KEY } from './getCampaigns';
import { Campaign } from '../../models/campaigns';

export const deleteCampaignApi = async (
  campaignId: string
): Promise<boolean | void> => {
  const path = replaceURLParams(CAMPAIGNS_ID, { '{id}': campaignId });

  const response = await axios.delete(path);
  queryClient.invalidateQueries({
    queryKey: ['campaigns']
  });

  return response.data;
};

export const deleteCampaignDraftApi = async (draftId: string) => {
  const resp = localStorage.getItem(CAMPAIGN_DRAFTS_KEY);
  const previousCampaignDrafts: Campaign[] = !!resp ? JSON.parse(resp) : [];
  const newCampaignDrafts = previousCampaignDrafts.filter(
    (campaign) => campaign.draftId !== draftId
  );
  localStorage.setItem(CAMPAIGN_DRAFTS_KEY, JSON.stringify(newCampaignDrafts));
};
