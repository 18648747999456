import { MicOffTwoTone, MicTwoTone } from '@mui/icons-material';
import { Grid, IconButton, Stack } from '@mui/material';
import { useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { getIsMuted } from 'src/services/webrtc/domain/getIsMuted';
import { toggleMute } from 'src/services/webrtc/domain/toggleMute';

export const MuteButton: React.FC = () => {
  const { barState } = useContext(CTIBarContext);
  const { isBarCompressed, isDragging } = barState;
  const isMuted = getIsMuted(true);

  const toggleMicVolume = () => {
    toggleMute();
  };

  //
  //color: '#F5C9EB',  lighter
  //iconColor: '#E161D5',
  return (
    <Grid
      item
      xs={6} // There are 12 columns and this component should occupy 50%
      sx={{
        backgroundColor: !isMuted ? '#F5C9EB' : '#E161D5',
        height: isBarCompressed ? 23 : 'auto',
        width: isBarCompressed ? 23 : 'auto',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: isBarCompressed ? '#DFBADC' : 'inherited'
        }
      }}
      onClick={() => {
        if (!isDragging) toggleMicVolume();
      }}
    >
      {!isBarCompressed && ( // Show Icon if the bar is not compressed
        <Stack alignItems={'center'} justifyContent={'center'}>
          <IconButton
            id="mute-button"
            sx={{
              borderRadius: 0,
              color: !isMuted ? '#E161D5' : 'white'
            }}
          >
            {!isMuted && <MicTwoTone fontSize="medium" />}
            {isMuted && <MicOffTwoTone fontSize="medium" />}
          </IconButton>
        </Stack>
      )}
    </Grid>
  );
};
