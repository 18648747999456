export const usePreviewMessage = (
  body: string,
  variablesBody: {
    variable: string;
    value: string;
  }[]
) => {
  let markedWords = [];

  const bodyWithValues = body.replace(/\{\{(\d+)\}\}/g, (match, numero) => {
    const variable = `{{${numero}}}`;
    const variableEncontrada = variablesBody.find(
      (v) => v.variable === variable
    );

    //found value for variable
    if (variableEncontrada) {
      markedWords.push(`<${variableEncontrada.value}>`);
      return `<${variableEncontrada.value}>`;
    }

    //value not found
    markedWords.push(`<${match}>`);
    return `<${match}>`;
  });

  return {
    markedWords,
    bodyWithValues
  };
};
