import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { WEBCHAT_ACCOUNT_ID } from 'src/utils/apiRoutes/msWebChat';

export const deleteWebChatAccountApi = async (accountId: string) => {
  const url = replaceURLParams(WEBCHAT_ACCOUNT_ID, {
    '{id}': accountId
  });
  return axios
    .delete(url)
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};
