import { AnswerOptions, RTCSession } from 'jssip/lib/RTCSession';
import WebRTCDevices, { prepareStreamForCall } from './webrtc.devices';
import WebRTCNotifications from './notifications/webrtc-notifications';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { logger } from 'src/utils/logger';
import wait from 'src/utils/wait';
import { getRTCSessions } from './domain/getRTCSessions';
import { getConnectedRTUser } from '../rtusers/domain/rtuser/getConnectedRTUser';

let WEBRTC_SESSION: RTCSession;
let WEBRTC_MEDIA_STREAM: MediaStream = new MediaStream();

const getWebRTCSession: Function = (): RTCSession => {
  return WEBRTC_SESSION;
};

const attendCall = async (session: WebRTCSession, isAutoanswered?: boolean) => {
  logger.log('[WEBRTC] Attending Call. Session: ', session);

  if (isAutoanswered) {
    await wait(500);
  }

  WEBRTC_SESSION = session.RTCSession;

  let mediaStream = WebRTCDevices.getOutStream();
  if (!mediaStream) {
    await prepareStreamForCall();
    mediaStream = WebRTCDevices.getOutStream();
  }

  const options: AnswerOptions = {
    extraHeaders: undefined,
    mediaConstraints: {
      audio: true,
      video: false
    },
    sessionTimersExpires: 7200,
    rtcAnswerConstraints: { offerToReceiveAudio: true },
    mediaStream
  };
  session.RTCSession.answer(options);
  // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addstream_event
  session.RTCSession.connection.ontrack = (event): void => {
    logger.log('[WEBRTC] SESSION CONNECTION ONTRACK', event);
    WebRTCNotifications.answerCallAlert();
    const remoteAudioElement = document.getElementById(
      'webrtc-audio-remote'
    ) as HTMLAudioElement;

    WEBRTC_MEDIA_STREAM.addTrack(event.track);

    remoteAudioElement.srcObject = WEBRTC_MEDIA_STREAM;
  };
  WebRTCNotifications.stopRinger();
};

const hangupCall = (session: WebRTCSession) => {
  logger.log('[WEBRTC] Hanging up Call. Session: ', session);
  WebRTCNotifications.hangUpCallAlert();
  session.RTCSession.terminate();
};

const WebRTCCallManagement = {
  getWebRTCSession,
  attendCall,
  hangupCall
};

export default WebRTCCallManagement;
