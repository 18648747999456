import { useCustomEventListener } from 'react-custom-events';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { HOTLINE_COMPANIES } from 'src/services/socialmedia/domain/conversation/utils';
import { useState } from 'react';
import { HelperTab, HelperTabsValue } from '../HelperMenu/items';
import {
  getHelperMenuItems,
  getInboxHelperPanel,
  getInboxMainPanel,
  getInboxTicketPanel
} from '../utils/getInboxPanels';
import { InboxMainPanel } from '../utils/inboxMainPanel';
import { getExternalActionByIdQuery } from 'src/modules/Admin/modules/Integrations/modules/externalActions/utils/getExternalActionByIdQuery';
import { getCampaignByIdQuery } from 'src/services/users/application/query/getUsersDataQueries';
import { logger } from 'src/utils/logger';
import {
  Ticket,
  TicketMsg
} from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import { getTicketById } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/getTickets';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import AuthManager from 'src/services/authentication/manager';
import { getDaServiceByIdQuery } from 'src/modules/Admin/modules/Operations/modules/DaServices/views/hooks/daServiceQueries';

export const useInboxPanels = () => {
  const [mainPanel, setMainPanel] = useState<InboxMainPanel | null>(null);
  const [helperPanelState, setHelperPanelState] = useState<HelperTab | null>(
    null
  );
  /**
   * helperPanelRef saves tha last not null value of helperPanel and the initial value contactData.
   * It is useful to open the helper panel with the last value when the helperPanel value is null
   *  */
  const [helperPanelRef, setHelperPanelRef] = useState<HelperTab>(
    HelperTab.CONTACT_DATA
  );
  const initialHelperMenuItems = getHelperMenuItems();
  const [helperMenuItems, setHelperMenuItems] = useState<
    Record<HelperTab, HelperTabsValue>
  >(initialHelperMenuItems);
  const [isEditingOrAddingTicket, setIsEditingOrAddingTicket] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>(null);

  const isHotlineConversation = (conversation: Conversation) =>
    HOTLINE_COMPANIES.includes(conversation?.companyId);
  const setHelperPanel = (helperTab: HelperTab | null) => {
    setHelperPanelState(helperTab);
    if (helperTab) {
      setHelperPanelRef(helperTab);
    }
  };

  const openHelperPanel = async (conversation: Conversation) => {
    if (!conversation) {
      setMainPanel(null);
      setHelperPanel(null);
      return;
    }

    const conversationService = await getDaServiceByIdQuery(
      conversation?.serviceId
    );
    if (!conversationService) {
      logger?.error('No service has been found in the current conversation');
    }

    // PROVISIONAL : unify the code below and the code from src/services/socialmedia/domain/conversation/setCurrentConversation.ts
    // and save the inbox panels in the store
    let serviceExternalAction = null;
    if (conversationService?.externalActionId) {
      try {
        serviceExternalAction = await getExternalActionByIdQuery(
          conversationService.externalActionId
        );
      } catch (error) {
        logger.error(error);
      }
    }
    const conversationCampaign = await getCampaignByIdQuery(
      conversation?.campaignId
    );
    // PROVISIONAL : SGI (HOTLINE) is not displayed when outbound call
    const isExternalActionConfigured = HOTLINE_COMPANIES.includes(
      conversation?.companyId
    )
      ? // HOTLINE
        conversation.originAgent.orientation === 'Outbound'
        ? false
        : true
      : // NOT HOTLINE
        !!conversationService?.externalActionId &&
        // The external action is configured but it is displayed in a new browser tab (not displayed in the inbox panels)
        !serviceExternalAction?.openNewTab &&
        // The external action is configured but not displayed because the current conversation campain ignores the external action
        !conversationCampaign?.ignoreExternalAction;

    const mainPanel = await getInboxMainPanel(
      conversation,
      isExternalActionConfigured
    );

    const helperPanel = getInboxHelperPanel(
      conversation,
      isExternalActionConfigured
    );

    const helperMenuItems = getHelperMenuItems({
      conversation,
      isExternalActionConfigured
    });

    setMainPanel(mainPanel);
    setHelperPanelRef(helperPanel);
    setHelperMenuItems(helperMenuItems);

    // If the channel conversation is CALL and there is an external action configured, the helper panel should close (or should not open)
    // When the current tab changes, the inbox opens the helper panel.
    if (
      conversation?.channel === ChannelType.CALL &&
      isExternalActionConfigured
    ) {
      setHelperPanelState(null);
    } else {
      setHelperPanelState(helperPanel);
    }

    if (
      mainPanel === InboxMainPanel.TICKETS ||
      helperPanel === HelperTab.TICKETS
    ) {
      const ticket = await getInboxTicketPanel(conversation);
      if (!!ticket) {
        setSelectedTicket(ticket);
        setIsEditingOrAddingTicket(true);
      } else {
        setSelectedTicket(null);
        setIsEditingOrAddingTicket(false);
      }
    }
  };

  const openTicket = async (data: TicketMsg) => {
    if (
      mainPanel !== InboxMainPanel.TICKETS &&
      helperPanelState !== HelperTab.TICKETS
    )
      return;

    let createdTicket: Ticket = undefined;
    try {
      createdTicket = await getTicketById(data?.ticketId);
    } catch (error) {
      logger.error(getErrorMessage(error).errorMessage);
      return;
    }

    const loggedUserId = AuthManager.getUserId();
    const currentConversation = getCurrentConversation(false);
    const isLoggedUserAssigned =
      currentConversation?.assignedUserIds.includes(loggedUserId) ||
      Object.keys(createdTicket?.assignedUserIds ?? {}).some(
        (userId) => userId === loggedUserId
      );

    if (!createdTicket.conversationIds.includes(currentConversation?.id))
      return;
    if (!isLoggedUserAssigned) return;

    setIsEditingOrAddingTicket(true);
    setSelectedTicket(createdTicket);
  };

  useCustomEventListener(CustomEventNames.OPEN_HELPER_PANEL, openHelperPanel);
  // useCustomEventListener(CustomEventNames.TICKET, openTicket);

  return {
    helperMenuItems,
    helperPanel: helperPanelState,
    mainPanel,
    setHelperPanel,
    isEditingOrAddingTicket,
    setIsEditingOrAddingTicket,
    helperPanelRef,
    selectedTicket,
    setSelectedTicket
  };
};
