import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import {
  CampaignsParams,
  getCampaignDraftsApi,
  getCampaignsApi
} from '../infrastructure/campaigns/getCampaigns';
import {
  putCampaign,
  updateCampaignDraftApi
} from '../infrastructure/campaigns/putCampaigns';
import {
  createCampaignDraftApi,
  postCampaign
} from '../infrastructure/campaigns/postCampaigns';
import {
  deleteCampaignApi,
  deleteCampaignDraftApi
} from '../infrastructure/campaigns/deleteCampaign';

/** GET ALL CAMPAIGNS
 *
 * Get all campaigns in database
 *
 *
 * @returns array of campaigns
 */
export const getAllCampaigns = async (campaignsParams: CampaignsParams) => {
  const { page = 0, size = -1 } = campaignsParams;
  const allCampaigns = await getCampaignsApi({
    ...campaignsParams,
    page,
    size
  });
  return allCampaigns;
};

export const enableCampaign = async (campaign: Campaign): Promise<Campaign> => {
  const campaignDto: Campaign = { ...campaign, enabled: true };
  const campaignId = campaign.id;
  const response = await putCampaign(campaignDto, campaignId);
  return response;
};

export const createCampaign = async (campaign: Campaign) => {
  const isDraft = checkIsCampaignDraft(campaign);
  if (isDraft) {
    deleteCampaignDraft(campaign?.draftId);
  }
  return postCampaign(campaign);
};

export const updateCampaign = async (campaign: Campaign) => {
  const isDraft = checkIsCampaignDraft(campaign);
  if (isDraft) {
    deleteCampaignDraft(campaign?.draftId);
  }
  return putCampaign(campaign, campaign?.id);
};

export const disableCampaign = async (
  campaign: Campaign
): Promise<Campaign> => {
  const campaignDto: Campaign = { ...campaign, enabled: false };
  const campaignId = campaign.id;
  const response = await putCampaign(campaignDto, campaignId);
  return response;
};

export const deleteCampaign = async (campaign: Campaign) => {
  const isDraft = checkIsCampaignDraft(campaign);
  if (isDraft) {
    await deleteCampaignDraft(campaign?.draftId);
  }
  return deleteCampaignApi(campaign?.id);
};

export const getCampaignDrafts = (params: CampaignsParams) => {
  return getCampaignDraftsApi(params);
};

export const getCampaignDraftById = (id: string) => {
  return getCampaignDrafts({ ids: [id] });
};

export const createCampaignDraft = (campaign: Campaign) => {
  return createCampaignDraftApi(campaign);
};

export const updateCampaignDraft = (updatedCampaign: Campaign) => {
  return updateCampaignDraftApi(updatedCampaign);
};

export const deleteCampaignDraft = (draftId: string) => {
  return deleteCampaignDraftApi(draftId);
};

export const deleteCampaignDrafts = async (draftIds: string[]) => {
  const deletePromises = draftIds.map((id) => {
    deleteCampaignDraft(id);
  });
  await Promise.all(deletePromises);
};

export const checkIsCampaignDraft = (campaign: Campaign) => {
  return !!campaign?.draftId;
};
