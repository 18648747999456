import { User } from 'src/models/users';
import { encryptString } from 'src/services/utils/encryptString';
import {
  deleteUserById,
  createUser,
  updateUser
} from '../infraestructure/api/users/users';

/** DELETE ONE OR MULTIPLE USERS
 *
 * Delete one or multiples users
 */
export const actionDeleteUser = async (id: string): Promise<string> => {
  const deleteUser = await deleteUserById(id);
  return deleteUser;
};

/** CREATE A  USER
 *
 * Create a user
 */
export const actionCreateUser = async (user: User): Promise<User> => {
  const newUser = await createUser({
    ...user,
    password:
      user.password.length === 60
        ? user.password
        : encryptString(user.password),
    enabled: true
  });
  return newUser;
};

/** UPDATE USER
 *
 * Update user
 */
export const actionUpdateUser = async (user: User): Promise<User> => {
  if (user.password) {
    user.password = encryptString(user.password);
  }

  const newUser = await updateUser({
    ...user
  });
  return newUser;
};
/**
 * Change the status of the user
 * @param users
 * @param enabled
 */
export const changeUsersStatus = async (users: User[], enabled: boolean) => {
  const userPromises = users.map((user) => changeUserStatus(user, enabled));
  await Promise.all(userPromises);
};

const changeUserStatus = async (user: User, enabled: boolean) => {
  if (user.enabled === enabled) return;
  await updateUser({
    ...user,
    enabled
  });
};
