import { EmailAttachment } from "src/models/conversations/messages";
import EmailAttachmentItem from "./item";
import { motion } from "framer-motion";

interface Props {
  attachments: EmailAttachment[];
}

function Attachments({ attachments }: Props) {
  const fileAttachments = attachments?.filter((a) => a.type === "File");

  if (attachments?.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "13.5px",
        paddingLeft: "13.5px",
        paddingBottom: "13.5px",
      }}
    >
      {fileAttachments?.map((attachment, index) => {
        return <EmailAttachmentItem key={index} attachment={attachment} />;
      })}
    </div>
  );
}

export default Attachments;
