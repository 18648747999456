import { FC, useState, createContext, Dispatch, SetStateAction } from 'react';
import { FormTabs } from 'src/modules/Shared/models/forms';
import {
  Dashboard,
  GridLayout,
  HiddenPanelsGroup,
  Panel,
  PanelsGroup,
  PanelsGroupInLayout
} from '../../models/Dashboards';
import { basePanelForm, PanelFormTabs } from '../utils/constants';
import { useLocation } from 'react-router-dom';

type DashboardsContext = {
  setOpenCreateModal: Dispatch<SetStateAction<boolean>>;
  openCreateModal: boolean;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  openDeleteModal: boolean;
  setLayout: Dispatch<SetStateAction<GridLayout[]>>;
  layout: GridLayout[];
  setCurrentDashboard: Dispatch<SetStateAction<Dashboard>>;
  currentDashboard: Dashboard;
  setOpenCreatePanelModal: Dispatch<SetStateAction<boolean>>;
  openCreatePanelModal: boolean;
  setOpenUpdatePanelModal: Dispatch<SetStateAction<boolean>>;
  openUpdatePanelModal: boolean;
  currentPanel: Panel;
  setCurrentPanel: Dispatch<SetStateAction<Panel>>;
  currentFormTab: string;
  setCurrentFormTab: Dispatch<SetStateAction<string>>;
  panelFormTabs: FormTabs[];
  setPanelFormTabs: Dispatch<SetStateAction<FormTabs[]>>;
  gridWidth: number;
  setGridWith: Dispatch<SetStateAction<number>>;
  getOffset: (width: any) => 50 | 120;
  panels: Panel[];
  setPanels: Dispatch<SetStateAction<Panel[]>>;
  showAddEditPanelGroup: boolean;
  setShowAddEditPanelGroup: Dispatch<SetStateAction<boolean>>;
  PanelGroup: PanelsGroup;
  setPanelGroup: Dispatch<SetStateAction<PanelsGroup>>;
  reloadLayout: boolean;
  setReloadLayout: Dispatch<SetStateAction<boolean>>;
  hiddenPanels: HiddenPanelsGroup[];
  setHiddenPanels: Dispatch<SetStateAction<HiddenPanelsGroup[]>>;
  panelsGroupsInLayout: PanelsGroupInLayout[];
  setPanelsGroupsInLayout: Dispatch<SetStateAction<PanelsGroupInLayout[]>>;
  isPanelVisible: string[];
  setIsPanelVisible: Dispatch<SetStateAction<string[]>>;
  configurationFormView: string;
  setConfigurationFormView: Dispatch<SetStateAction<string>>;
  currentDatatype: string;
  setCurrentDatatype: Dispatch<SetStateAction<string>>;
  isRealtime: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DashboardsContext = createContext<DashboardsContext>(
  {} as DashboardsContext
);

export const DashboardProvider = ({ children }) => {
  const [configurationFormView, setConfigurationFormView] =
    useState('GeneralView');
  // Create or update dashboards
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // Create or update panels
  const [openCreatePanelModal, setOpenCreatePanelModal] = useState(false);
  const [openUpdatePanelModal, setOpenUpdatePanelModal] = useState(false);
  const [currentPanel, setCurrentPanel] = useState<Panel>(basePanelForm);

  const [panelFormTabs, setPanelFormTabs] = useState(PanelFormTabs);
  const [currentDatatype, setCurrentDatatype] = useState(null);
  const [currentFormTab, setCurrentFormTab] = useState('Charts');
  const [panels, setPanels] = useState<Panel[] | undefined>(null);
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard | null>(
    null
  );

  const [panelsGroupsInLayout, setPanelsGroupsInLayout] =
    useState<PanelsGroupInLayout[]>(null);
  const [hiddenPanels, setHiddenPanels] = useState<HiddenPanelsGroup[] | null>(
    null
  );
  const [showAddEditPanelGroup, setShowAddEditPanelGroup] = useState(false);
  const [PanelGroup, setPanelGroup] = useState<PanelsGroup | null>(null);
  const [reloadLayout, setReloadLayout] = useState(true);
  const getOffset = (width) => {
    if (width <= 960) return 50;
    return 120;
  };
  const [gridWidth, setGridWith] = useState(
    window.innerWidth - getOffset(window.innerWidth)
  );
  const location = useLocation();
  const isRealtime = !location.pathname.includes('reporting');

  const [layout, setLayout] = useState<GridLayout[] | null>(null);
  const [isPanelVisible, setIsPanelVisible] = useState<string[]>([]);
  return (
    <DashboardsContext.Provider
      value={{
        isRealtime,
        openCreateModal,
        setOpenCreateModal,
        openDeleteModal,
        setOpenDeleteModal,
        layout,
        setLayout,
        currentDashboard,
        setCurrentDashboard,
        openCreatePanelModal,
        setOpenCreatePanelModal,
        setOpenUpdatePanelModal,
        openUpdatePanelModal,
        currentPanel,
        setCurrentPanel,
        currentFormTab,
        setCurrentFormTab,
        panelFormTabs,
        setPanelFormTabs,
        gridWidth,
        setGridWith,
        getOffset,
        panels,
        setPanels,
        showAddEditPanelGroup,
        setShowAddEditPanelGroup,
        PanelGroup,
        setPanelGroup,
        reloadLayout,
        setReloadLayout,
        hiddenPanels,
        setHiddenPanels,
        panelsGroupsInLayout,
        setPanelsGroupsInLayout,
        isPanelVisible,
        setIsPanelVisible,
        configurationFormView,
        setConfigurationFormView,
        currentDatatype,
        setCurrentDatatype
      }}
    >
      {children}
    </DashboardsContext.Provider>
  );
};
