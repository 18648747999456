import { Alert, Typography } from '@mui/material';
import { t } from 'i18next';
import {
  WhatsAppMaxByteFileSizes,
  WhatsAppTemplateAcceptedFileExtensions,
  WhatsAppTemplateType
} from 'src/services/whatsapp/models/WhatsAppTemplate';

interface Props {
  type: WhatsAppTemplateType;
  file: File;
}

const useWhatsAppTemplateFileSelector = ({ type, file }: Props) => {
  const typeKey = type?.toUpperCase();

  const maxFileSize = WhatsAppMaxByteFileSizes[typeKey];

  const exceedMaxFileSize = file?.size > maxFileSize;

  const alertText =
    t(
      `Your file exceeds maximum file size for ${type === WhatsAppTemplateType.DOCUMENT ? 'documents' : 'images'}`
    ) + `. ${maxFileSize / 1000000}MB max.`;

  const acceptedFiles =
    WhatsAppTemplateAcceptedFileExtensions[typeKey] ??
    WhatsAppTemplateAcceptedFileExtensions.ANY;

  const sizeAlert = (
    <Alert sx={{ width: '100%' }} severity="error">
      {alertText}
    </Alert>
  );

  const MaxSizeTypography = (
    <Typography color="secondary" mt={0.5}>
      {`(Max. ${maxFileSize / 1000000} MB)`}
    </Typography>
  );

  return {
    exceedMaxFileSize,
    sizeAlert,
    acceptedFiles,
    MaxSizeTypography
  };
};

export default useWhatsAppTemplateFileSelector;
