import axios from 'axios';
import { AIModelUseCase } from 'src/modules/Admin/modules/Acd/modules/Models/AIModels/models/AIModels';
import { GENERATIVE_AI_GENERATE_DATA } from 'src/utils/apiRoutes/msGenerativeAI';

export type GenerateDataApiProps = {
  intention: string;
  prompt: string;
  language: 'Spanish' | 'English';
  useCase: AIModelUseCase;
  numberOfExamples: number;
};

async function generateDataApi(props: GenerateDataApiProps) {
  const response = await axios.post(GENERATIVE_AI_GENERATE_DATA, props);

  return response.data.data;
}

export default generateDataApi;
