import { useContext } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { VideoCallsDomain } from 'src/modules/VideoCall/domain';
import VideoWebRTCDevices, {
  stopStream
} from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';
import { getMicDevice } from 'src/services/webrtc/domain/getMicDeviceStore';
import { getCameraDeviceStore } from 'src/services/webrtc/infrastructure/store/devices/getCameraDeviceStore';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';

export const useVideocallMicrophone = () => {
  const voiceDevice = getMicDevice(true);
  const cameraSelected = getCameraDeviceStore(true);

  const { streams, rtcSession, devices, videoRoom } =
    useContext(VideocallRoomContext);

  const { local: localStream, setLocalStream } = streams;

  const mediaDevices = getDevicesStore(true).filter((d) => {
    return d.kind === 'audioinput';
  });

  const toggleMute = () => {
    if (videoRoom.room && videoRoom.me) {
      VideoCallsDomain.toggleMute(
        videoRoom.room.id,
        videoRoom.me.connectionId
      ).then((muted) => {
        if (devices.microphoneMuted && devices.speakerMuted)
          devices.setSpeakerMuted(false);
        devices.setMicrophoneMuted(muted);
        VideoWebRTCDevices.toggleDisableAudioStream(localStream, muted);
      });
    } else {
      if (devices.microphoneMuted && devices.speakerMuted)
        devices.setSpeakerMuted(false);

      devices.setMicrophoneMuted(!devices.microphoneMuted);
    }
  };

  const onSelectChange = async (newDevice: MediaDeviceInfo) => {
    const stream = await VideoWebRTCDevices.requestLocalStream({
      enableVideo: true,
      constraints: {
        audio: {
          deviceId: newDevice.deviceId
        },
        video: {
          deviceId: cameraSelected?.deviceId,
          aspectRatio: 16 / 9
        }
      }
    });

    setLocalStream(stream);
    VideoWebRTCDevices.toggleDisableAudioStream(
      stream,
      devices.microphoneMuted
    );

    devices.setMicrophoneDeviceId(newDevice.deviceId);

    if (!rtcSession.currentRTCSession) return;

    VideoWebRTCDevices.replaceStreamOnRTCSession({
      stream,
      rtcSession: rtcSession.currentRTCSession,
      kind: 'audio'
    });
  };

  return {
    voiceDevice,
    devices: mediaDevices,
    onSelectChange,
    toggleMute
  };
};
