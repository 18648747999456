export const textEditorInitialState = {
  blockType: 'paragraph',
  rootType: 'root',
  selectedElementKey: null,
  fontSize: '15px',
  fontColor: '#000',
  bgColor: '#fff',
  fontFamily: 'Arial',
  elementFormat: 'left',
  isLink: false,
  isBold: false,
  isItalic: false,
  isUnderline: false,
  isStrikethrough: false,
  isSubscript: false,
  isSuperscript: false,
  isCode: false,
  canUndo: false,
  canRedo: false,
  modal: false, // Asumiendo que useModal() retorna un valor booleano inicialmente
  isRTL: false,
  codeLanguage: '',
  isEditable: true
} as TextEditorState;

export interface TextEditorState {
  blockType: string;
  rootType: string;
  selectedElementKey: string | null;
  fontSize: string;
  fontColor: string;
  bgColor: string;
  fontFamily: string;
  elementFormat: string;
  isLink: boolean;
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  isStrikethrough: boolean;
  isSubscript: boolean;
  isSuperscript: boolean;
  isCode: boolean;
  canUndo: boolean;
  canRedo: boolean;
  modal: boolean;
  isRTL: boolean;
  codeLanguage: string;
  isEditable: boolean;
}
