import {
  Conversation,
  ConversationStatus
} from 'src/models/conversations/conversations';
import { RTCall } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';
import { DataFilter as CurrentDataFilter } from 'src/services/shared/domain/filters';
import { filterConversations } from 'src/services/socialmedia/domain/conversation/utils';
import { getRTUsersStore } from '../../infraestructure/store/getRTUsersStore';
import {
  getStoredConversations,
  getStoredFilteredConversations
} from 'src/services/conversations/domain/getConversations';

export interface GetRTUsersFilter extends CurrentDataFilter {
  id?: string;
  complexExtension?: string;
  rtCall?: RTCall; // Only owners of this rtcall
  withConversations?: boolean;
  withFilteredConversations?: boolean;
  callId?: string;
}

/** FILTER RTUSERS
 *
 * @param filter Parameters to filter the currently stored rtusers
 * @returns Array of rtusers after applying the specified filters
 */
export const getRTUsers = (filter: GetRTUsersFilter): RTUser[] => {
  let rtUsers = getRTUsersStore(filter.shouldUseHook) || [];

  rtUsers = rtUsers.filter((u: RTUser) => {
    // Check same id
    if (filter.id && filter.id !== u.id) return false;

    // Check same complexExtension
    if (
      filter.complexExtension &&
      filter.complexExtension !== u.extension?.complexExtension
    )
      return false;

    // Check is owner of RTCall
    if (filter.rtCall) {
      return u.id === filter.rtCall.userId;
    }

    if (filter.callId) {
      return u.calls.some((rtCall) => rtCall?.callId === filter.callId);
    }

    return true;
  });

  if (filter.withConversations || filter.withFilteredConversations) {
    // Add all RTUser conversations or RTUser filtered conversations
    let conversationsAllUsers: Conversation[];
    // Retrieve Conversations from RTUsers
    if (filter.withConversations) {
      conversationsAllUsers = getStoredConversations({
        status: [ConversationStatus.ASSIGNED],
        shouldUseHook: filter.shouldUseHook
      });
    }
    if (filter.withFilteredConversations) {
      // Retrieve Conversations from RTUsers
      conversationsAllUsers = getStoredFilteredConversations(
        filter.shouldUseHook
      );
    }
    // Assign conversations to rtusers
    rtUsers = rtUsers.map((rtUser) => {
      const userConvs = filterConversations(conversationsAllUsers, {
        shouldUseHook: filter.shouldUseHook,
        rtUsers: [rtUser]
      });
      return { ...rtUser, conversations: userConvs };
    });
  } else {
    // Add an empty array to RTUser conversations
    rtUsers = rtUsers.map((rtUser) => {
      return { ...rtUser, conversations: [] };
    });
  }

  return rtUsers;
};
