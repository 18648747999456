import { CircularProgress, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDialpadStateContext } from "src/contexts/CTIBarContext";
import {
  ChannelType,
  Conversation,
} from "src/models/conversations/conversations";
import { getRTUser } from "src/services/rtusers/domain/rtuser/getRTUser";
import {
  getConversationOriginAgentName,
  getConversationTitle,
} from "src/services/socialmedia/domain/conversation/getConversationInfo";
import { getChannelIcon } from "../utils/getChannelIcon";

interface Props {
  conversation: Conversation;
}

export const ConversationListItemCTI: React.FC<Props> = ({ conversation }) => {
  const date = new Date(conversation.metadata.creationDate);
  //get time from date in format hh:mm
  const time = date.toLocaleTimeString("es-ES", {
    hour: "numeric",
    minute: "numeric",
  });

  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const theme = useTheme();

  const { openDialpad } = useDialpadStateContext();

  async function handleOnclick() {
    if (conversation.channel === "Call") {
      const endpoint =
        conversation.involvedContacts?.[0]?.socialmediaAgent.endpoint;
      let phoneNumber = endpoint;
      if (endpoint?.includes("-")) {
        const user = getRTUser({
          complexExtension: endpoint,
          shouldUseHook: false,
        });
        if (user) {
          phoneNumber = user?.extension?.extension;
        }
      }
      openDialpad({ inputValue: phoneNumber, channel: ChannelType.CALL });
      return;
    }
    if (conversation.channel === "Telegram") {
      openDialpad({
        inputValue: conversation.involvedContacts[0].socialmediaAgent.userName,
        channel: ChannelType.TELEGRAM,
      });
      return;
    }
    if (conversation.channel === "Email") {
      openDialpad({
        inputValue:
          conversation.involvedContacts[0].socialmediaAgent.emailAddress,
        channel: ChannelType.EMAIL,
      });
    }
    if (conversation.channel === "WhatsApp") {
      openDialpad({
        inputValue: conversation.originAgent.phoneNumber,
        channel: ChannelType.WHATSAPP,
      });
    }
    if (conversation.channel === "Instagram") {
      openDialpad({
        inputValue: conversation.originAgent.username,
        channel: ChannelType.INSTAGRAM,
      });
    }
  }

  async function getConversationTitleAsync() {
    try {
      const title = await getConversationTitle(conversation);
      setTitle(title);
    } catch (error) {
      const subtitle = await getConversationOriginAgentName(conversation);
      setTitle(subtitle);
    }
  }

  useEffect(() => {
    getConversationTitleAsync();
  }, [conversation]);

  const channelColor = {
    [ChannelType.CALL]: theme.colors.primary.main,
    [ChannelType.EMAIL]: theme.colors.warning.main,
    [ChannelType.WHATSAPP]: theme.colors.success.main,
    [ChannelType.TELEGRAM]: theme.colors.secondary.main,
    [ChannelType.WEBCHAT]: theme.colors.warning.main,
    [ChannelType.INSTAGRAM]: "#c13584",
  };

  const contactWithoutName = title === "Contact without name";

  return (
    <Stack
      sx={{
        "&:hover": {
          backgroundColor: theme.colors.primary.lighter,
        },
        borderRadius: "10px",
        cursor: "pointer",
      }}
      px={2}
      height={58}
      width="100%"
      onClick={handleOnclick}
      direction="row"
      alignItems={"center"}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        p={1}
        color="white"
        sx={{
          backgroundColor: channelColor[conversation.channel],
          borderRadius: "50%",
        }}
      >
        {getChannelIcon(conversation.channel, {
          fontSize: "medium",
          color: "inherit",
        })}
      </Stack>
      <Stack
        direction="row"
        alignItems={"center"}
        width={"100%"}
        justifyContent="space-between"
        px={2}
      >
        <Stack
          direction="row"
          maxWidth={230}
          textOverflow="ellipsis"
          alignItems={"center"}
          spacing={2}
        >
          <h3
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "230px",
              fontStyle: contactWithoutName ? "italic" : "none",
            }}
          >
            {title ? (
              contactWithoutName ? (
                t(title)
              ) : (
                title
              )
            ) : (
              <CircularProgress size={20} />
            )}
          </h3>
          {conversation.channel === "Call" && (
            <label>{t(conversation.originAgent.orientation)}</label>
          )}
        </Stack>

        <div>{time}</div>
      </Stack>
    </Stack>
  );
};
