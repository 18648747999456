import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { queryClient } from 'src/utils/queryClient';
import { TicketTag } from '../../models/tickets';
import { TICKET_TAGS_ID } from 'src/utils/apiRoutes/msMessaging';

export const updateTicketTagApi = async (
  ticketTag: TicketTag
): Promise<TicketTag> => {
  const path = replaceURLParams(TICKET_TAGS_ID, {
    '{id}': ticketTag?.id
  });
  const resp = await axios.put(path, ticketTag);

  queryClient.invalidateQueries({
    queryKey: ['ticketTags']
  });

  return resp.data;
};
