import axios from 'axios';
import { ContactGroup } from '../../models/contacts';
import { CONTACT_GROUP } from 'src/utils/apiRoutes/msContacts';
import { invalidateContactGroupsQuery } from '../../domain/getContactGroups';

export const createContactGroupApi = async (contactGroup: ContactGroup) => {
  const response = await axios.post(CONTACT_GROUP, contactGroup);
  invalidateContactGroupsQuery();
  return response;
};
