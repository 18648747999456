import { Close, LaunchOutlined } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Card,
  IconButton,
  Modal,
  Stack,
  useTheme
} from '@mui/material';
import { MouseEvent, forwardRef, useState } from 'react';
import { UploadCsvResponse } from '../../models/models';
import { t } from 'i18next';
import { UploadCSVSummary } from '../UploadSummary';

interface Props {
  uploadSummary: UploadCsvResponse;
  /** The default is downloadReportingAttachment from ReportingAttachmentsDomain */
  onDownloadReport?: (
    event: MouseEvent<HTMLButtonElement>,
    reportingAttachmentId: string
  ) => Promise<void>;
  onClose: (key: string) => void;
}

export const UploadCSVSnackbarContent = forwardRef<HTMLDivElement, Props>(
  ({ uploadSummary, onClose }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();

    return (
      <>
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          <Card
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <UploadCSVSummary
              onClose={() => {
                setIsOpen(false);
                onClose(uploadSummary.timeElapsed.toString());
              }}
              response={uploadSummary}
            />
          </Card>
        </Modal>
        <Alert
          ref={ref}
          severity="info"
          color={uploadSummary.success ? 'success' : 'error'}
          action={
            <Stack direction="row" spacing={0.5}>
              <IconButton
                size="small"
                title={t('View summary')}
                onClick={() => {
                  setIsOpen(true);
                }}
                sx={{ borderRadius: 2 }}
              >
                <LaunchOutlined fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                title={t('Close')}
                onClick={(e) => {
                  setIsOpen(false);
                  onClose(uploadSummary.timeElapsed.toString());
                }}
                sx={{ borderRadius: 2 }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Stack>
          }
        >
          {/* ALERT TITLE */}
          <AlertTitle>{t('The contacts upload has finished')}</AlertTitle>
        </Alert>
      </>
    );
  }
);
