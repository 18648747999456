import { Card, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ContactField } from 'src/modules/Contacts/models/contacts';
import { getParticipantFields } from 'src/modules/VideoCall/domain/getParticipantContactFields';
import { VideoCallParticipant } from 'src/modules/VideoCall/types/videoCallTypes';
import ActionButtons from './ActionButtons';
import ParticipantName from './ParticipantName';

interface Props {
  participant: VideoCallParticipant;
}

export default function NewParticipantPopoverItem({ participant }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const participantFields = participant.formFieldValues;
  const participantFieldKeys = participantFields.map(
    (pfk) => pfk.contactFieldId
  );

  const [fields, setFields] = useState([] as ContactField[]);

  useEffect(() => {
    getParticipantFields(participant).then((fields) => {
      setFields(fields);
    });
  }, []);

  if (isOpen) {
    return (
      <motion.div
        key={participant.id}
        layoutId={participant.id}
        initial={{
          y: 32,
          opacity: 0
        }}
        animate={{
          y: 0,
          opacity: 1
        }}
        exit={{
          y: -32,
          opacity: 0
        }}
      >
        <Card
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            rowGap: 10,
            padding: 10,
            border: '1px solid rgba(0, 0, 0, 0.05)'
          }}
        >
          <ParticipantName
            participant={participant}
            name={participant.displayName}
            toggleOpen={() => setIsOpen((state) => !state)}
          />
          <Divider
            sx={{
              width: '100%',
              height: '1px'
            }}
          />

          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}
          >
            {!participantFieldKeys.length && (
              <span>{t('No fields available')}</span>
            )}
            {participantFieldKeys.map((fieldId, index) => {
              return (
                <div key={fieldId} style={{}}>
                  <span style={{ fontWeight: 'bold' }}>
                    {fields.find((f) => f.id === fieldId)?.name}
                  </span>
                  : {participantFields[index].value}
                </div>
              );
            })}
          </motion.div>
          <Divider
            sx={{
              width: '100%',
              height: '1px'
            }}
          />
          <motion.div
            layoutId={`buttons-${participant.id}`}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: 10
            }}
          >
            <ActionButtons participant={participant} buttonWidth={'50%'} />
          </motion.div>
        </Card>
      </motion.div>
    );
  }

  return (
    <motion.div
      key={participant.id}
      layoutId={participant.id}
      initial={{
        y: 32,
        opacity: 0
      }}
      animate={{
        y: 0,
        opacity: 1
      }}
      exit={{
        y: -32,
        opacity: 0
      }}
    >
      <Card
        style={{
          flexDirection: isOpen ? 'column' : 'row',
          display: 'flex',
          alignItems: 'center',
          columnGap: 10,
          padding: 10,
          border: '1px solid rgba(0, 0, 0, 0.05)'
        }}
      >
        <ParticipantName
          participant={participant}
          name={participant.displayName}
          toggleOpen={() => setIsOpen((state) => !state)}
        />

        <motion.div
          layoutId={`buttons-${participant.id}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10
          }}
        >
          <ActionButtons participant={participant} />
        </motion.div>
      </Card>
    </motion.div>
  );
}
