import { t } from 'i18next';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  ChatMessage,
  ChatMessageType,
  ConversationMessage,
  EmailMessage
} from 'src/models/conversations/messages';
import { RTCall } from 'src/models/rtcall';
import {
  Contact,
  FakeContactFields
} from 'src/modules/Contacts/models/contacts';
import { getRTCall } from 'src/services/rtcalls/domain/getRTCall';
import { getRTUser } from 'src/services/rtusers/domain/rtuser/getRTUser';
import { ConversationIconTypes } from 'src/utils/icons';
import { logger } from 'src/utils/logger';
import { getConversationInvolvedContacts } from './utils';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';
import { getWebChatAccountQueryNotPaginated } from 'src/services/webchat/domain/webChatAccount/getWebChatAccounts';

/** GET CONVERSATION NAME
 *
 * Search for the conversation's contact. If its found, we'll return his name
 * Otherwise, we'll return his number/mail/etc
 */
export const getConversationTitle = async (
  conv: Conversation
): Promise<string> => {
  if (!conv) return '';
  // If its an email, we'll return the subject
  if (conv.channel === 'Email') {
    let lastMessage = conv.lastMessage as EmailMessage;

    if (!lastMessage) {
      const messages = [...(conv.messages ?? [])];
      lastMessage = messages.pop() as EmailMessage;
    }
    if (lastMessage?.subject.length > 0) return lastMessage?.subject;

    return t('No subject');
  }

  //If its a telegram group, we'll return the group name
  if (conv.channel === 'Telegram' && isGroupConversation(conv)) {
    return getGroupConversationName(conv);
  }

  if (conv.channel === 'Call' && conv.originAgent.orientation === 'VideoCall') {
    return 'Videocall Conversation';
  }
  if (conv.channel === ChannelType.WEBCHAT) {
    return getWebChatConversationName(conv);
  }

  return getConversationContactListString(conv);
};

const getWebChatConversationName = async (conv: Conversation) => {
  //if exists asociated contact, contact's name
  if (conv.involvedContacts[0]?.contactId) {
    const contact: Contact[] = await getConversationInvolvedContacts(conv);
    const name = getConversationContactName(contact[0], conv);
    return name;
  } else {
    //matchBy field content
    const matchByValue = await getWebChatMatchFieldValue(conv);
    return matchByValue ?? 'WebChat';
  }
};

export const getWebChatMatchFieldValue = async (conv: Conversation) => {
  if (conv.channel !== ChannelType.WEBCHAT) return null;
  const account = await getWebChatAccountQueryNotPaginated({
    id: conv.accountId
  });
  const WCform = conv.originAgent.webChatForm;
  const INform = account[0].initialForm;

  const matchByField = INform.find(
    (initialFormField) =>
      initialFormField.matchBy === true &&
      WCform.some((formField) => formField.name === initialFormField.labelName)
  );

  if (matchByField) {
    const matchedFormField = WCform.find(
      (formField) => formField.name === matchByField.labelName
    );
    return matchedFormField?.value ?? 'WebChat';
  }
};

export const getGroupConversationName = (conversation: Conversation) => {
  const isGroup = isGroupConversation(conversation);
  if (!isGroup) {
    logger.error(
      'Tried to get the group name from a conversation with type other than Group'
    );
  }
  return isGroup ? conversation.originAgent.groupName : null;
};

export const isGroupConversation = (conversation: Conversation) => {
  return conversation?.originAgent?.type === 'Group';
};

/** GET CONVERSATION SUBTITLE
 *
 *  Search for the conversation's "subtitle" or "secondary summary"
 *  Email: List of contacts/mails
 *  Telegram: Text of last message
 *  Call: Phone Number
 */
export const getConversationSubtitle = async (
  conv: Conversation
): Promise<string> => {
  if (conv.channel === 'Email') {
    const lastMessage = conv.lastMessage as EmailMessage;
    if (lastMessage?.orientation === 'Outgoing') {
      return lastMessage.to.join(', ');
    } else {
      return lastMessage?.from;
    }
  }

  if (
    [
      ChannelType.TELEGRAM,
      ChannelType.WHATSAPP,
      ChannelType.INSTAGRAM,
      ChannelType.WEBCHAT
    ].includes(conv.channel)
  ) {
    const messageData = conv.lastMessage as ChatMessage;

    if (messageData?.type === ChatMessageType.TEXT) {
      return messageData?.content.text || '';
    } else if (messageData?.type === ChatMessageType.LOCATION) {
      return t('Location');
    } else {
      return messageData?.content.uploadedFile !== null
        ? messageData?.content.uploadedFile.name
        : t('Attachment');
    }
  }

  if (conv.channel === 'Call') {
    return getConversationOriginAgentName(conv) || '';
  }

  return getConversationOriginAgentName(conv) || '';
};

/**
 *  Returns a list of participants of the provided conversation,
 *  separated by commas.
 *
 *  If a participant is stored as Contact:
 *    Get his Name if it exists, and if it doesnt:
 *    Call - Show his Phone number
 *    Telegram - Show his TG Username
 *    Email - Show his email
 */
export const getConversationContactListString = async (
  conv: Conversation
): Promise<string> => {
  if (conv.involvedContacts.length == 0) {
    return getConversationOriginAgentName(conv);
  }

  // Get list of participants.
  let participants: Contact[] = [];
  try {
    participants = await getConversationInvolvedContacts(conv);
  } catch (error) {
    return getConversationOriginAgentName(conv);
  }

  // Get names from contacts
  const names = await Promise.all(
    participants.map((contact) => getConversationContactName(contact, conv))
  );

  // Join them separated by comma
  return names.join(' , ');
};

export const getConversationContactName = async (
  contact: Contact,
  conversation: Conversation
) => {
  //If the contact exists return it;
  const contactName = await getContactName(contact);
  if (contactName) return contactName;

  //If not, return the number / telegram / email
  const channel = conversation.channel;
  const id = conversation.id;

  if (channel === ChannelType.WEBCHAT) {
    const name = getWebChatConversationName(conversation);
    return name;
  }

  if (channel === 'Call') {
    const endpoint =
      conversation.involvedContacts?.[0]?.socialmediaAgent.endpoint;

    const isInternal = endpoint?.includes('-');

    if (!isInternal) return endpoint;

    // If the endpoint is a complex extension, we'll get the name from the RTUser
    const user = getRTUser({
      complexExtension: endpoint,
      shouldUseHook: false
    });

    return user?.username || '';
  }

  if (channel === 'Telegram') {
    const fields = contact?.fields as FakeContactFields;
    return fields.Name || fields.User || fields.Phone || 'Hidden';
  }

  if (channel === 'WhatsApp') {
    const fields = contact?.fields as FakeContactFields;
    return fields.Name ?? fields.Phone ?? 'Contact without name';
  }

  if (channel === 'Instagram') {
    return conversation.originAgent.username;
  }

  if (channel == 'Email') {
    return contact?.fields?.['Email'];
  }

  return id;
};

/**
 * Returns the name of the SocialmediaAgent from the conversation
 * depending on the channel.
 */
export const getConversationOriginAgentName = (conv: Conversation) => {
  if (conv.channel === 'Call' && conv.originAgent.orientation !== 'VideoCall') {
    return conv.involvedContacts[0].socialmediaAgent.endpoint;
  }
  if (conv.channel === 'Call' && conv.originAgent.orientation === 'VideoCall') {
    return conv.originAgent.orientation;
  }
  if (conv.channel === 'Email') {
    const messageData = conv.messages?.[0] as EmailMessage;
    return messageData?.subject;
  }
  if (conv.channel === 'Telegram') {
    return conv.originAgent.username || conv.originAgent.phoneNumber;
  }
  if (conv.channel === 'WhatsApp') {
    return conv.originAgent.phoneNumber;
  }
  if (conv.channel === 'Instagram') {
    return conv.originAgent.userName;
  }
};

export const getConversationIconString = (
  conv: Conversation,
  rtCall?: RTCall
): ConversationIconTypes => {
  if (conv?.channel === 'Call') {
    if (conv?.originAgent?.orientation === 'VideoCall') return 'VideoCall';
    if (rtCall?.status === 'Talking') return 'InCall';
    if (rtCall?.status === 'Parked') return 'Parked';

    return 'Call';
  }

  return conv?.channel as ConversationIconTypes;
};

export const getConversationSecondaryIconString = (
  conv: Conversation
): string => {
  if (conv?.channel !== 'Call') return null;

  const rtCall = getRTCallFromConversation(conv);

  return rtCall?.status;
};

export const getRTCallFromConversation = (
  conv: Conversation,
  shouldUseHook?: boolean
): RTCall => {
  if (conv?.channel !== 'Call') return null;
  return getRTCall({
    callId: conv?.originAgent?.callId,
    rtUserId: conv?.assignedUserIds?.[0],
    shouldUseHook: shouldUseHook
  });
};

/**
 *
 * Returns the amount of unread messages
 */
export const getUnreadMessages = (
  conv: Conversation
): ConversationMessage[] => {
  if (!conv?.messages) return;
  return conv.messages?.filter(
    (msg) => msg.orientation === 'Incoming' && msg.status === 'Received'
  );
};

/**
 *
 * Returns true if the conversation has unread incoming messages
 */
export const hasUnreadMessages = (conversation: Conversation) => {
  if (!conversation?.lastMessage) return false;
  return (
    conversation.lastMessage.orientation === 'Incoming' &&
    conversation.lastMessage.status === 'Received'
  );
};
