import {
  deleteAlertByPanleId,
  deletePanelById
} from '../infrastructure/api/deletePanels';

/** DELETE PANEL BY ID
 *
 * Returns panel deleted
 * @returns {string} Confirmation
 */
export const deletePanel = async (panelId: string): Promise<string> => {
  return deletePanelById(panelId);
};

/** DELETE ALERT BY PANELID
 *
 * Returns alert deleted
 * @returns {string} Confirmation
 */
export const deleteAlert = async (panelId: string): Promise<string> => {
  return deleteAlertByPanleId(panelId);
};
