import { Conversation } from 'src/models/conversations/conversations';
import { ThirdPartyIframeData } from 'src/store/slices/socialmedia/socialmediaSlice';
import store, { useSelector } from 'src/store/store';

/** GET IFRAME PANELS FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of Iframe Panels
 */
export const getIframepanelsStore = (shouldUseHook: boolean) => {
  const allPanels = (
    shouldUseHook
      ? useSelector((state) => state.socialmedia.thirdPartyIframes)
      : store.getState().socialmedia.thirdPartyIframes
  ) as ThirdPartyIframeData[];

  return allPanels;
};
