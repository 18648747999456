import {
  Box,
  Button,
  Card,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useVIdeoCallConversation } from '../../hooks/useVIdeoCallConversation';
import { Videocam } from '@mui/icons-material';

export const VideoCallConversation = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    isLoading,
    selectedCampaignId,
    videoCallCampaigns,
    handleChange,
    handleClick
  } = useVIdeoCallConversation();

  if (isLoading) {
    return (
      <Stack alignItems={'center'}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Card>
      <Stack minWidth={'400px'}>
        <Stack direction={'column'} width={'100%'}>
          {/* Header */}
          <Stack
            width="100%"
            direction={'row'}
            justifyContent={'space-between'}
            alignItems="center"
            color={theme.colors.error.main}
            sx={{
              backgroundColor: theme.colors.error.lighter,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}
            gap={2}
            px={2}
            borderRadius={1}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px'
              }}
            >
              <Videocam color="error"></Videocam>
              <h3>{t('New Videocall')}</h3>
            </Box>
          </Stack>
        </Stack>
        <Stack p={2} direction="column" spacing={2} alignItems={'center'}>
          <TextField
            select
            value={selectedCampaignId}
            fullWidth
            label={t('Campaign')}
            onChange={handleChange}
          >
            {videoCallCampaigns.map((videoCallCampaign) => {
              return (
                <MenuItem
                  key={videoCallCampaign.id}
                  value={videoCallCampaign.id}
                >
                  {videoCallCampaign.name}
                </MenuItem>
              );
            })}
          </TextField>
          <Button
            sx={{
              boxShadow: `${theme.colors.shadows.success}`,
              background: `${theme.colors.success.main}`,
              color: `${theme.palette.getContrastText(theme.colors.primary.main)}`,
              '&:hover': {
                background: `${theme.colors.success.dark}`,
                color: `${theme.palette.getContrastText(theme.colors.primary.main)}`
              },
              borderRadius: 1,
              width: theme.spacing(13),
              height: theme.spacing(5)
            }}
            onClick={handleClick}
            id="btnCall-DialPad"
          >
            {t('Create')}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );

  /* return (
    <Card sx={{ padding: 2 }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {isLoading ? (
              <Stack alignItems={'center'}>
                <CircularProgress />
              </Stack>
            ) : (
              <>
                <Grid item xs={12} spacing={1}>
                  <FormLabel>{t('Campaign')}</FormLabel>
                  <Select
                    value={selectedCampaignId}
                    fullWidth
                    onChange={handleChange}
                  >
                    {videoCallCampaigns.map((videoCallCampaign) => {
                      return (
                        <MenuItem
                          key={videoCallCampaign.id}
                          value={videoCallCampaign.id}
                        >
                          {videoCallCampaign.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid item xs={12} textAlign="center">
                  
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  ); */
};
