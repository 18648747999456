import { postIntentionModel, cloneIntentionModel } from './postIntentionModel';
import { getIntentionModel, getIntentionsModels } from './getIntentionModel';
import { updateIntentionModel } from './updateIntentionModel';
import { deleteIntentionModel } from './deleteIntentionModel';

export const IntentionsModelsLogic = {
  postIntentionModel,
  cloneIntentionModel,
  getIntentionsModels,
  getIntentionModel,
  updateIntentionModel,
  deleteIntentionModel
};
