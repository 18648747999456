import { Conversation } from 'src/models/conversations/conversations';
import { setCurrentConversation } from 'src/store/slices/socialmedia/socialmediaSlice';
import store from 'src/store/store';

/** ADD / UPDATE CURRENT CONVERSATION TO STORE
 *
 * @param conversation Conversation to be selected
 */
export const setCurrentConversationStore = (conversation: Conversation) => {
  store.dispatch(setCurrentConversation(conversation));
};
