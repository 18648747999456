import { createContext } from 'react';
import usePermissions, {
  UserPermissionsStatus
} from 'src/hooks/usePermissions';
import { Permissions, UserPermissions } from 'src/models/permissionGroups';

type PermissionsContext = {
  userPermissions: UserPermissions;
  hasAccess: (
    permissionsCheckerFn: (
      permissions: Permissions,
      isSuperAdmin: boolean
    ) => boolean,
    companyId?: string
  ) => boolean;
  loading: UserPermissionsStatus;
  isSuperAdmin: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionsContext = createContext<PermissionsContext>(
  {} as PermissionsContext
);

export const PermissionsProvider = ({ children }) => {
  const { userPermissions, hasAccess, loading, isSuperAdmin } =
    usePermissions();
  return (
    <PermissionsContext.Provider
      value={{ userPermissions, hasAccess, loading, isSuperAdmin }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
