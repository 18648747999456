import Scrollbar from 'src/components/Scrollbar';
import EmailEditor from '../EmailEditor';
import {
  EmailEditorMode,
  EmailSessionData
} from '../EmailEditor/models/models';

function EmailExternalView() {
  const stringifiedData = sessionStorage.getItem('emailEditorInfo');
  const emailEditorData = JSON.parse(
    stringifiedData || '{}'
  ) as EmailSessionData;

  if (!emailEditorData.conversation) {
    window.location.href = '/dashboard';
  }

  return (
    <div style={{ background: 'white', height: '100vh' }}>
      <Scrollbar>
        <EmailEditor
          config={{
            editorInfo: {
              messageToReply: emailEditorData.messageToReply,
              mode: emailEditorData.mode,
              serializedEditor: emailEditorData.serializedEditor,
              message: emailEditorData.message
            },
            isEditorOpen: true,
            isMaximized: true
          }}
          conversation={emailEditorData.conversation}
          selectedTicket={emailEditorData.selectedTicket}
          setConfig={() => {}}
        />
      </Scrollbar>
    </div>
  );
}

export default EmailExternalView;
