import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled, Tooltip, Typography } from '@mui/material';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import { WhatsAppTemplate } from 'src/services/whatsapp/models/WhatsAppTemplate';

interface Props {
  template: WhatsAppTemplate;
}

const DarkBlueTypography = styled(Typography)(
  () =>
    `color: #223354;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.64px;
    display: inline-flex;
    margin-right: 5px;`
);

const GrayTypography = styled(Typography)(
  () =>
    `color: #9EA6B3 !important;
    max-width: 100%;`
);

const TemplatesCardBody: React.FC<Props> = ({ template }) => {
  const { t } = useTranslation();

  return (
    <Stack direction={'column'}>
      <Box mt={1} mb={1}>
        <DarkBlueTypography variant="h5">
          {template?.type ?? 'Text'}
        </DarkBlueTypography>
        <DarkBlueTypography
          sx={{
            pl: '5px',
            borderLeft: '1px solid #223354'
          }}
        >
          {t('Template')}
        </DarkBlueTypography>
        {template.buttons.length > 0 && (
          <DarkBlueTypography
            sx={{
              color: '#1870F5',
              fontWeight: 700,
              pl: '5px',
              borderLeft: '1px solid #223354'
            }}
          >
            {t('Buttons')}
            <EditAttributesIcon sx={{ ml: 0.5 }} />
          </DarkBlueTypography>
        )}
      </Box>
      <DarkBlueTypography
        sx={{
          color: '#9EA6B3 !important'
        }}
      >
        <Tooltip title={template.content}>
          <GrayTypography>{template.content}</GrayTypography>
        </Tooltip>
      </DarkBlueTypography>
      <Stack direction="row" spacing={1} mt={2} maxHeight={'20px'}>
        <DarkBlueTypography>
          {t('Language')}
          {': '}
        </DarkBlueTypography>
        <GrayTypography>{template.language}</GrayTypography>
      </Stack>
      <Stack direction="row" spacing={1} mt={1} maxHeight={'20px'}>
        <DarkBlueTypography>
          {t('Description')}
          {': '}
        </DarkBlueTypography>
        <Tooltip title={template.description}>
          <GrayTypography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {template.description}
          </GrayTypography>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default TemplatesCardBody;
