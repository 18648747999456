import { getVoiceVolumeStore } from '../infrastructure/store/devices/getMicVolumeStore';
import { getAutoanswerStore } from '../infrastructure/store/getAutoanswerStore';

/** GET VOICE VOLUME VALUE IN STORE
 *
 * @param shouldUseHook
 */

export const getMicVolume = (shouldUseHook: boolean) => {
  const volume = getVoiceVolumeStore(shouldUseHook);
  return volume / 50;
};
