import { PromptElement } from 'src/modules/Shared/models/ariModels';
import { PromptNode } from '../../../../../models/routesNodes';

export const defaultPromptNode = {
  elementId: '',
  element: {
    name: '',
    description: '',
    text: '',
    type: 'Prompt'
  } as PromptElement,
  language: 'Spanish',
  readvariables: {},
  type: 'Prompt',
  writeVariableId: ''
} as PromptNode;
