import {
  Alert,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { UploadCsvResponse } from '../models/models';
import { AccessTime, CloseTwoTone } from '@mui/icons-material';
import AdminHeader from 'src/components/AdminHeader';

interface Props {
  response: UploadCsvResponse;
  onClose: () => void;
}

export const UploadCSVSummary = ({ response, onClose }: Props) => {
  const theme = useTheme();
  function summaryBox(value: string | number, label: string, border: string) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          borderRadius: 1,
          border: `2px solid ${border}`
        }}
        maxWidth={190}
        px={2}
        py={1}
      >
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>{value}</div>
        <div style={{ fontSize: 16 }}>{label}</div>
      </Stack>
    );
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        padding: '16px'
      }}
    >
      <Stack
        direction="row"
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h4">{t('Upload summary')}</Typography>
        <IconButton onClick={onClose}>
          <CloseTwoTone />
        </IconButton>
      </Stack>
      {response.success ? (
        <Alert severity="success">
          {t('Successful upload') + `. Total: ${response.totalImported}`}
        </Alert>
      ) : (
        <Alert severity="error">{t('Error uploading')}</Alert>
      )}
      <Alert icon={<AccessTime />} severity="info">
        <Typography variant="body1">
          {t('Time elapsed:')} {response.timeElapsed / 1000} {t('seconds')}
        </Typography>
      </Alert>
      <Stack
        direction={'row'}
        rowGap={2}
        columnGap={2}
        alignItems={'center'}
        justifyContent={'space-around'}
        flexWrap={'wrap'}
      >
        {summaryBox(
          response.createdEntities,
          t('Created'),
          theme.colors.success.main
        )}
        {summaryBox(
          response.updatedEntities,
          t('Updated'),
          theme.colors.secondary.main
        )}
        {summaryBox(
          response.ignoredEntities,
          t('Ignored'),
          theme.colors.alpha.black[30]
        )}
      </Stack>
    </motion.div>
  );
};
