import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Restricted from 'src/components/Restricted';
import {
  checkReadCallerIdsPermissions,
  checkReadExtensionPermissions,
  checkReadOutboundRoutePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Extensions = Loader(lazy(() => import('../modules/Extensions/views')));
const CallerIds = Loader(lazy(() => import('../modules/CallerIds/views')));
const RobinsonList = Loader(
  lazy(() => import('../modules/RobinsonList/views'))
);
const OutboundRoutes = Loader(
  lazy(() => import('../modules/OutboundRoutes/views/index'))
);
const Trunks = Loader(lazy(() => import('../modules/Trunks/views/index')));

const callsRoutes: RouteObject[] = [
  {
    path: 'extensions',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadExtensionPermissions}>
        <Extensions />
      </Restricted>
    )
  },
  {
    path: 'caller-ids',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadCallerIdsPermissions}>
        <CallerIds />
      </Restricted>
    )
  },

  {
    path: 'robinson-list',
    element: (
      <Restricted navigate permissionCheckerFn={() => true}>
        <RobinsonList />
      </Restricted>
    )
  },
  {
    path: 'outbound-routes',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadOutboundRoutePermissions}
      >
        <OutboundRoutes />
      </Restricted>
    ),
    children: [
      // {
      //   path: ':id',
      //   element: (
      //     <ReactFlowProvider>
      //       <OutboundRoutesProvider>
      //         <CERoute />
      //       </OutboundRoutesProvider>
      //     </ReactFlowProvider>
      //   )
      // },
      // {
      //   path: 'new-route',
      //   element: (
      //     <ReactFlowProvider>
      //       <OutboundRoutesProvider>
      //         <CERoute />
      //       </OutboundRoutesProvider>
      //     </ReactFlowProvider>
      //   )
      // }
    ]
  },
  {
    path: 'trunks',
    element: <Trunks />
  }
];

export default callsRoutes;
