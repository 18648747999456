import { ChannelType } from 'src/models/conversations/conversations';
import { deleteTelegramMessageTextApi } from 'src/services/telegram/infrastructure/message/deleteTelegramMessageTextApi';

export const deleteChatMessage = async (params: {
  channel: ChannelType;
  accountId: string;
  conversationId: string;
  messageId: string;
}) => {
  const { channel, ...restParams } = params;
  switch (channel) {
    case ChannelType.TELEGRAM:
      await deleteTelegramMessageTextApi(restParams);
      break;
    case ChannelType.WHATSAPP:
    case ChannelType.WEBCHAT:
    case ChannelType.INSTAGRAM:
  }
};
