import { Conversation } from 'src/models/conversations/conversations';
import { RTUser } from 'src/models/rtUser';
import { getRTUserById } from 'src/services/rtusers/domain/rtuser/getRTUser';
import { getRTUsersStore } from 'src/services/rtusers/infraestructure/store/getRTUsersStore';
import {
  handleQueuedConversations,
  setRTUsers
} from 'src/store/slices/rtusers/rtuserSlices';
import {
  addConversations,
  resetConversations,
  setConversations,
  startLoadingConversations
} from 'src/store/slices/socialmedia/socialmediaSlice';
import store from 'src/store/store';

export const setStartLoadingConversationsStore = () => {
  store.dispatch(startLoadingConversations());
};

export const setConversationsStore = (conversations: Conversation[]) => {
  store.dispatch(setConversations(conversations));
  setManyRTUserConversations(conversations);
};

export const resetAllConversationsStore = () => {
  store.dispatch(resetConversations());

  //Reset RT users conversation ids
  const rtUsers = getRTUsersStore(false);
  let rtUsersModified = [];
  for (const rtUser of rtUsers) {
    const newRTUser: RTUser = {
      ...rtUser,
      conversationIds: []
    };
    rtUsersModified.push(newRTUser);
  }
  store.dispatch(setRTUsers(rtUsers));
};

export const addConversationsStore = (conversations: Conversation[]) => {
  store.dispatch(addConversations(conversations));

  handleQueuedConversationsStore(conversations);

  setManyRTUserConversations(conversations);
};

function handleQueuedConversationsStore(conversations: Conversation[]) {
  store.dispatch(handleQueuedConversations(conversations));
}

export const addUserConversationStore = (
  conversations: Conversation[],
  userId: string
) => {
  store.dispatch(addConversations(conversations));
  handleQueuedConversationsStore(conversations);
  setRTUserConversations(conversations, userId);
};

export const setRTUserConversations = (
  convs: Conversation[],
  userId: string
) => {
  const rtUser = getRTUserById(userId);
  if (!rtUser) return;

  let modifiedRTUser = rtUser;

  let conversations: string[] = rtUser.conversationIds;
  for (const conv of convs) {
    let convIndex = rtUser.conversationIds.findIndex((id) => id === conv.id);
    if (convIndex === -1) {
      conversations = [...conversations, conv.id];
    }
  }

  modifiedRTUser = {
    ...modifiedRTUser,
    conversationIds: conversations
  };

  store.dispatch(setRTUsers([modifiedRTUser]));
};

// De aquí para abajo es verborrea
export const setManyRTUserConversations = (convs: Conversation[]) => {
  let params: SetRTUserConversationParams = {
    conversation: null,
    rtUsers: getRTUsersStore(false),
    modifiedRTUsers: []
  };

  for (const conv of convs) {
    console.log('setManyRTUserConversations', params.modifiedRTUsers);
    params.conversation = conv;
    params = addConversationToRTusersFromConv(params);
  }

  if (params.modifiedRTUsers.length > 0)
    store.dispatch(setRTUsers(params.modifiedRTUsers));
};

export interface SetRTUserConversationParams {
  conversation: Conversation;
  rtUsers: RTUser[];
  modifiedRTUsers: RTUser[];
}

const addConversationToRTusersFromConv = (
  params: SetRTUserConversationParams
): SetRTUserConversationParams => {
  const { conversation, rtUsers, modifiedRTUsers } = params;

  for (const assignedId of conversation.assignedUserIds || []) {
    const rtUserIndex = rtUsers.findIndex((u) => u?.id === assignedId);
    const rtUser = rtUsers[rtUserIndex];
    if (!rtUser || !assignedId.length) continue;

    let newRTUser;
    if (conversation.status === 'Assigned')
      newRTUser = addConversationToRTUser(rtUser, conversation);
    else {
      newRTUser = removeConversationFromRTUser(rtUser, conversation);
    }
    rtUsers[rtUserIndex] = newRTUser;
    const rtUserIndexModf = modifiedRTUsers.findIndex(
      (u) => u?.id === assignedId
    );
    if (rtUserIndexModf !== -1) modifiedRTUsers[rtUserIndexModf] = newRTUser;
    else modifiedRTUsers.push(newRTUser);
  }

  params.rtUsers = rtUsers;
  params.modifiedRTUsers = modifiedRTUsers.filter((u) => u !== null);

  return params;
};

const addConversationToRTUser = (
  rtUser: RTUser,
  conversation: Conversation
) => {
  let convIndex = rtUser.conversationIds.findIndex(
    (id) => id === conversation.id
  );
  if (convIndex !== -1) return rtUser;

  const newRTUser: RTUser = {
    ...rtUser,
    conversationIds: [...rtUser.conversationIds, conversation.id]
  };

  return newRTUser;
};

const removeConversationFromRTUser = (
  rtUser: RTUser,
  conversation: Conversation
) => {
  const newRTUser: RTUser = {
    ...rtUser,
    conversationIds: rtUser.conversationIds.filter(
      (id) => id !== conversation.id
    )
  };

  return newRTUser;
};
