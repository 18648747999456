import React, { useEffect, useState } from 'react';
import { WebChatStyle } from '../../../models/webChatStyle';
import {
  Box,
  InputBase,
  Paper,
  Stack,
  Switch,
  Typography,
  styled
} from '@mui/material';
import { ContactInformationHeader } from './ContactInformationHeader';
import { t } from 'i18next';
import useContactInformationPreviewComponents from '../../utils/useContactInformationPreviewComponents';

const RootWrapper = styled(Box)(
  () => `
        height: 560.5px;
        min-height: 560.5px;
        min-width: 300px;
        width: 390px;
         display: flex;
         flex-direction: column;
         border: 1px solid #b2b4b8;
         border-radius: 10px;
         -webkit-box-shadow: 9px 9px 12px -3px rgba(107,99,107,0.65);
        -moz-box-shadow: 9px 9px 12px -3px rgba(107,99,107,0.65);
        box-shadow: 9px 9px 12px -3px rgba(107,99,107,0.65);`
);

const ChatWindow = styled(Box)(
  ({ theme }) => `
        width: 100%;
        max-width: 450px;
        height: 505px;
        display: flex;
        flex-direction: column;
`
);

interface contactInformationPreviewProps {
  formikProps: WebChatStyle;
  logo: File;
}

const ContactInformationPreview: React.FC<contactInformationPreviewProps> = ({
  formikProps,
  logo
}) => {
  const [fileURL, setFileURL] = useState<string>('');
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);

  const { OpenChatButton } =
    useContactInformationPreviewComponents(formikProps);

  useEffect(() => {
    if (logo !== null) {
      setFileURL(URL.createObjectURL(logo));
      return () => {
        URL.revokeObjectURL(fileURL);
      };
    }
  }, [logo]);

  return (
    <RootWrapper>
      <Stack direction={'column'} height="100%">
        <ContactInformationHeader formikProps={formikProps} />
        <ChatWindow>
          <Stack
            flex={1}
            alignItems="center"
            justifyContent={'flex-start'}
            sx={{
              backgroundColor:
                formikProps?.conversationStyle.convBackGroundColor.color ??
                '#F6F8FB',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px'
            }}
            spacing={2.5}
          >
            <Box
              width="300px"
              height="150px"
              alignItems="center"
              justifyContent="center"
              display="flex"
              m={2}
              pt={3}
            >
              {logo === null && (
                <Box
                  component="img"
                  sx={{
                    height: `${12 * formikProps.contactStyle.logoSize}px`,
                    width: `${125 * formikProps.contactStyle.logoSize}px`,
                    maxHeight: 40
                  }}
                  alt="Dialapplet"
                  src="/static/images/logo/logo.png"
                />
              )}
              {logo !== null && (
                <img
                  style={{
                    objectFit: 'contain',
                    height: `${50 * formikProps.contactStyle.logoSize}px`,
                    width: `${50 * formikProps.contactStyle.logoSize}px`
                  }}
                  src={fileURL}
                />
              )}
            </Box>
            <Stack height="70%" width="50%">
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <Paper
                  elevation={formikProps.contactStyle.shadowBox ? 3 : 0}
                  sx={{
                    backgroundColor:
                      formikProps.contactStyle.fieldBGColor.color,
                    padding: '2px',
                    borderRadius: '5px',
                    width: `${formikProps.contactStyle.fieldWidth}px`,
                    height: '42.5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    border: `1px solid ${formikProps.contactStyle.fieldBorderColor.color}`
                  }}
                >
                  <InputBase
                    placeholder={t('Name')}
                    sx={{
                      ml: 1,
                      flex: 1,
                      mr: 0.5,
                      mt: 0.5,
                      mb: 0.5,
                      color: formikProps.contactStyle.fieldTextColor.color
                    }}
                  />
                </Paper>
                <Paper
                  elevation={formikProps.contactStyle.shadowBox ? 3 : 0}
                  sx={{
                    backgroundColor:
                      formikProps.contactStyle.fieldBGColor.color,
                    padding: '2px',
                    borderRadius: '5px',
                    width: `${formikProps.contactStyle.fieldWidth}px`,
                    height: '42.5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    border: `1px solid ${formikProps.contactStyle.fieldBorderColor.color}`
                  }}
                >
                  <InputBase
                    placeholder={t('Number')}
                    sx={{
                      ml: 1,
                      flex: 1,
                      mr: 0.5,
                      mt: 0.5,
                      mb: 0.5,
                      color: formikProps.contactStyle.fieldTextColor.color
                    }}
                  />
                </Paper>
                <Paper
                  elevation={formikProps.contactStyle.shadowBox ? 3 : 0}
                  sx={{
                    backgroundColor:
                      formikProps.contactStyle.fieldBGColor.color,
                    padding: '2px',
                    borderRadius: '5px',
                    width: `${formikProps.contactStyle.fieldWidth}px`,
                    height: '42.5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    border: `1px solid ${formikProps.contactStyle.fieldBorderColor.color}`
                  }}
                >
                  <InputBase
                    placeholder={t('Email')}
                    sx={{
                      ml: 1,
                      flex: 1,
                      mr: 0.5,
                      mt: 0.5,
                      mb: 0.5,
                      color: formikProps.contactStyle.fieldTextColor.color
                    }}
                  />
                </Paper>
              </Stack>
              <Stack mt={1.5}>
                <Stack
                  direction={'row'}
                  alignItems="center"
                  justifyContent={'start'}
                  width="50%"
                >
                  <Switch
                    color="secondary"
                    checked={privacyPolicy}
                    onChange={() => setPrivacyPolicy(!privacyPolicy)}
                    sx={{
                      '& .MuiSwitch-thumb': {
                        bgcolor:
                          `${formikProps?.contactStyle.dataPolicyText.color}` ??
                          '#1a75ff'
                      },
                      '& .Mui-checked .MuiSwitch-thumb': {
                        bgcolor:
                          `${formikProps?.contactStyle.dataPolicyText.color}` ??
                          '#1a75ff'
                      },
                      '& .Mui-checked + .MuiSwitch-track': {
                        bgcolor:
                          `${formikProps?.contactStyle.dataPolicyText.color}` ??
                          '#1a75ff',
                        opacity: '0.25'
                      }
                    }}
                  ></Switch>
                  <Typography
                    style={{
                      margin: 0,
                      fontSize: 16,
                      whiteSpace: 'nowrap',
                      fontFamily: `${formikProps.contactStyle.font}`
                    }}
                    variant="body1"
                  >
                    {t('Privacy policy')}
                  </Typography>
                </Stack>
              </Stack>

              <OpenChatButton variant="contained">{t('Open')}</OpenChatButton>
            </Stack>
          </Stack>
        </ChatWindow>
      </Stack>
    </RootWrapper>
  );
};

export default ContactInformationPreview;
