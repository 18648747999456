import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { AccordionIntention } from './IntentionsList';
import { IntentionNameHeader } from './IntentionNameHeader';
import IntentionTagItem from './IntentionTagItem';
import { Add } from '@mui/icons-material';
import { t } from 'i18next';
import GenerateTagsPopover from './GenerateTagsPopover';
import { AIModelLanguage } from '../../../models/AIModels';

interface Props {
  intention: AccordionIntention;
  setIntention: (newIntention: AccordionIntention) => void;
  onDelete: () => void;
  language: AIModelLanguage;
}

const Intention: React.FC<Props> = ({
  intention,
  setIntention,
  onDelete,
  language
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const tagInputRefs = useRef([] as HTMLInputElement[]);

  const stickyRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(entry.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }

    return () => {
      if (stickyRef.current) {
        observer.unobserve(stickyRef.current);
      }
    };
  }, [stickyRef]);

  return (
    <Stack
      width="100%"
      direction={'column'}
      sx={{
        borderRadius: 1,
        background: 'rgba(16, 16, 63, 0.05)'
      }}
    >
      <IntentionNameHeader
        intention={intention}
        setIntention={setIntention}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        onDelete={onDelete}
      />

      {isExpanded && (
        <Stack px={1} pb={1} width={'100%'} rowGap={1}>
          <Stack
            ref={stickyRef}
            p={1}
            direction="row"
            width="100%"
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              position: 'sticky',
              top: 41,
              zIndex: 100,
              background: '#f3f3f5'
            }}
          >
            <Typography variant="h4">{t('Tags')}</Typography>
            <Stack direction={'row'} columnGap={1}>
              <GenerateTagsPopover
                intentionName={intention.name}
                language={language}
                onDataGenerated={(generatedTags) => {
                  const newIntention = { ...intention };
                  newIntention.tags = [
                    ...intention.tags.filter((t) => t.length > 0),
                    ...generatedTags
                  ];
                  setIntention(newIntention);
                }}
              />
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<Add />}
                sx={{
                  background: 'transparent'
                }}
                onClick={() => {
                  setIntention({
                    ...intention,
                    tags: [...intention.tags, '']
                  });
                }}
              >
                {t('Add tag')}
              </Button>
            </Stack>
          </Stack>
          {isSticky && <Divider />}
          <Stack rowGap={1} pl={1} pb={1}>
            {intention.tags.map((tag, index) => {
              return (
                <IntentionTagItem
                  key={`${tag}-${index}`}
                  index={index}
                  tagRefs={tagInputRefs}
                  tag={tag}
                  setTag={(newTag: string) => {
                    const newIntention = { ...intention };
                    newIntention.tags[index] = newTag;
                    setIntention(newIntention);
                  }}
                  onRemove={() => {
                    const newTags = intention.tags.filter(
                      (_, i) => i !== index
                    );
                    const newIntention = { ...intention, tags: newTags };
                    setIntention(newIntention);
                  }}
                  addNewTag={() => {
                    // If not the last, select next one
                    if (index !== intention.tags.length - 1) {
                      //focus next tag
                      tagInputRefs.current[index + 1].focus();
                      //move cursor to the end
                      tagInputRefs.current[index + 1].setSelectionRange(
                        tagInputRefs.current[index + 1].value.length,
                        tagInputRefs.current[index + 1].value.length
                      );
                      return;
                    }

                    const newIntention = { ...intention };
                    newIntention.tags.push('');
                    setIntention(newIntention);
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Intention;
