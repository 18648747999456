import { Dispatch, SetStateAction, useState } from "react";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import { AccordionIntention } from "./IntentionsList";
import { Delete, Edit } from "@mui/icons-material";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  intention: AccordionIntention;
  setIntention: (newIntention: AccordionIntention) => void;
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  onDelete: () => void;
}

export const IntentionNameHeader = ({
  intention,
  setIntention,
  isExpanded,
  setIsExpanded,
  onDelete,
}: Props) => {
  const [editingIntentionName, setEditingIntentionName] = useState<
    false | string
  >(intention.name.length > 0 ? false : "");
  const [hoveringName, setHoveringName] = useState(false);

  const theme = useTheme();
  return (
    <>
      <Stack
        width="100%"
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          background: "#e8e8ec",
          borderRadius: 1,
          padding: 1,
          paddingLeft: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          setIsExpanded((state) => !state);
        }}
        style={{
          position: "sticky",
          top: -9,
          zIndex: 100,
        }}
      >
        {typeof editingIntentionName === "string" ? (
          <input
            placeholder={t("Enter intention name") + "..."}
            autoFocus
            type="text"
            value={editingIntentionName}
            onKeyDown={(e) => {
              if (e.key === "Enter" && editingIntentionName.length > 0) {
                setIntention({ ...intention, name: editingIntentionName });
                setEditingIntentionName(false);
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setEditingIntentionName(e.target.value);
            }}
            onBlur={() => {
              if (editingIntentionName.length === 0) {
                const newName = intention.name.length
                  ? intention.name
                  : t("New intention");
                setIntention({ ...intention, name: newName });
                setEditingIntentionName(false);
                return;
              }
              setIntention({ ...intention, name: editingIntentionName });
              setEditingIntentionName(false);
            }}
            style={{
              border: "none",
              background: "transparent",
              fontSize: 18,
              color: theme.colors.secondary.main,
              fontWeight: "bold",
              width: "100%",
              outline: "none",
              fontFamily: "inherit",
            }}
          ></input>
        ) : (
          <Stack direction={"row"} alignItems={"center"} columnGap={1.5}>
            <Typography
              onClick={(e) => {
                e.stopPropagation();
                setEditingIntentionName(intention.name);
                setHoveringName(false);
              }}
              onMouseEnter={() => setHoveringName(true)}
              onMouseLeave={() => setHoveringName(false)}
              variant="h6"
              color="secondary"
              fontWeight={"bold"}
              fontSize={18}
              sx={
                hoveringName && {
                  textDecoration: "underline",
                  cursor: "pointer",
                }
              }
            >
              {intention.name}
            </Typography>
            <AnimatePresence>
              {hoveringName && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.2,
                    ease: "easeOut",
                  }}
                >
                  <Edit color="secondary" />
                </motion.div>
              )}
            </AnimatePresence>
          </Stack>
        )}

        <Stack direction={"row"}>
          <IconButton size="small">
            <motion.span
              animate={{
                rotate: isExpanded ? 180 : 0,
              }}
              transition={{
                duration: 0.2,
                type: "spring",
                bounce: 0,
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ExpandMoreIcon />
            </motion.span>
          </IconButton>
          <IconButton
            size="small"
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
};
