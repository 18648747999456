export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

export interface Logger {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
}

export type LogLevel = 'Log' | 'Warn' | 'Error';

interface LogEntry {
  level: LogLevel;
  message: any;
  params: any[];
}

export class ConsoleLogger implements Logger {
  readonly log: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  private history: LogEntry[] = [];

  constructor() {
    this.error = this.createLogFn('Error', console.error.bind(console));
    this.warn = this.createLogFn('Warn', console.warn.bind(console));
    this.log = this.createLogFn('Log', console.log.bind(console));
  }

  private createLogFn(level: LogLevel, fn: LogFn): LogFn {
    return (message?: any, ...optionalParams: any[]) => {
      const currentDate = new Date().toISOString();
      const logMessage = `[${currentDate}] ${message}`;
      this.history.push({ level, message: logMessage, params: optionalParams });
      fn(logMessage, ...optionalParams);
    };
  }

  getHistory(): LogEntry[] {
    // Return a copy to prevent external modifications
    return [...this.history];
  }
}

export const logger = new ConsoleLogger();
