import { WSMessage } from 'src/services/websocket/utils/notifications/dataMsgs';
import {
  addDashboardDataToStore,
  setPanelInitialDataStore
} from '../store/dashboardData';
import { DashboardInstantData } from '../../models/Dashboards';
import dashboardLogic from '../../domain/dashboardsLogic';

export type KpiDataType = 'Speed' | 'Time' | 'Abandoned' | null;

export interface RTDashBoardEventMessage extends WSMessage {
  dashboardData: DashboardInstantData;
  dashboardId: string;
}

export interface RTInitialDataEventMessage extends WSMessage {
  instanceId: string;
  panelId: string;
}

export interface RTDashboardData {
  [key: string]: RTDataColumn;
}
export interface RTDataColumn {
  elements: ElementsRTData;
  instant: string;
  total?: number;
  kpiData?: number;
}

export interface KpiData {
  identifier: string;
  value: number;
  dataType?: KpiDataType;
  color?: string;
}
export interface ElementsRTData {
  [key: string]: number;
}
export interface InitialData {
  RTDataColumn?: RTDataColumn;
  GroupDataColumn?: RTDataColumn;
}
export interface Column {
  identifier: string;
  data: number;
}

/** UPDATE DASHBOARD DATA
 *
 */
export const onRTDashboardEvent = (message: RTDashBoardEventMessage) => {
  addDashboardDataToStore(message.dashboardData);
};

/** GET INITIAL DATA
 *
 */
export const onRTInitialDataEvent = async (
  message: RTInitialDataEventMessage
) => {
  //emitCustomEvent('Initial data ready' + message.panelId, message);
  const panelId = message.panelId;
  const instanceId = message.instanceId;

  const initialData = await dashboardLogic.getPanelData(panelId, instanceId);

  setPanelInitialDataStore(panelId, initialData);
};
