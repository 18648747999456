import axios from 'axios';
import { EMAIL_SIGNATURE } from 'src/utils/apiRoutes/msEmail';
import { EmailSignature } from '../../models/emailSignatureModels';
import { EmailSignatureParams } from '../../models/emailSignatureModels';
import { PaginatedElements } from 'src/models/pagination';

export const getEmailSignatureApi = async (
  params: EmailSignatureParams
): Promise<PaginatedElements<EmailSignature>> => {
  const resp = await axios.get<PaginatedElements<EmailSignature>>(
    EMAIL_SIGNATURE,
    { params }
  );
  return resp.data;
};
