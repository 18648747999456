import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { getContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { ContactFieldType } from 'src/modules/Contacts/models/contacts';

export interface ContactFieldOption {
  contactFieldId: string;
  contactFieldLabel: string;
  contactFieldUnique?: boolean;
  contactFieldType?: ContactFieldType;
  contactFieldRequired?: boolean;
}

interface useContactFieldsProps {
  fieldArrayName: string;
  contactGroupId: string;
}

/**
 * A custom hook to fetch and manage contact fields based on a contact group ID.
 * Important: This hook should be used within Formik components to utilize useFormikContext.
 *
 * @param {string} fieldArrayName - The name of the field array in the form values.
 * @param {string} contactGroupId - The ID of the contact group to fetch contact fields for.
 */

export const useContactFields = ({
  fieldArrayName,
  contactGroupId
}: useContactFieldsProps) => {
  const { values, errors } = useFormikContext<Campaign>();
  const [optionContactFields, setOptionContactFields] = useState<
    ContactFieldOption[]
  >([]);

  const getContactFields = async () => {
    const contactFields = (
      await getContactFieldsQuery({
        contactGroupId
      })
    )?.elements;

    const contactFieldsOptions = contactFields.map((contactField) => {
      if (contactField.autoGenerated === false) {
        return {
          contactFieldId: contactField.id,
          contactFieldLabel: contactField.name,
          contactFieldRequired: contactField.required,
          contactFieldType: contactField.type
        };
      }
    }) as ContactFieldOption[];
    setOptionContactFields(contactFieldsOptions);
  };

  const hasErrors = Object.keys(errors).length > 0;

  const selectedOptionsIds = values[fieldArrayName]?.map(
    (selectedOption: ContactFieldOption) => selectedOption.contactFieldId
  );

  useEffect(() => {
    getContactFields();
  }, [contactGroupId]);

  return {
    values,
    optionContactFields,
    hasErrors,
    selectedOptionsIds
  };
};
