import { ChatMessage } from 'src/models/conversations/messages';
import { getConversation } from '../conversation/getConversation';
import { addMessageToConversation } from './addMessageToConversation';
import { logger } from 'src/utils/logger';

/** REMOVE MESSAGE
 *
 */
export const removeMessage = (conversationId: string, messageId: string) => {
  // Find convo and message
  const conversation = getConversation({
    id: conversationId,
    shouldUseHook: false
  });
  if (!conversation) {
    logger.error(
      'Remove Message: Conversation not found ',
      conversationId,
      messageId,
      conversation
    );
    return;
  }

  let message = conversation.messages.find(
    (msg) => msg.messageId === messageId
  ) as ChatMessage;
  if (!message) {
    logger.error(
      'Remove Message: Conversation found, but message not found?? ',
      conversationId,
      messageId,
      conversation,
      message
    );

    return;
  }

  // Update message data
  message = {
    ...message,
    deleted: true
  };

  // Update conversation
  addMessageToConversation({ message, conversation });
};
