import { FC, useState, createContext, Dispatch, SetStateAction } from 'react';
import { DataStatus } from 'src/models/dataStatus';
import { Ticket } from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import {
  HelperTab,
  HelperTabsValue
} from 'src/modules/Dashboard/views/Inbox/HelperMenu/items';
import { useInboxPanels } from 'src/modules/Dashboard/views/Inbox/hooks/useInboxPanels';
import { InboxMainPanel } from 'src/modules/Dashboard/views/Inbox/utils/inboxMainPanel';

type InboxPanelsContext = {
  helperPanel: HelperTab;
  helperPanelRef: HelperTab;
  helperMenuItems: Record<HelperTab, HelperTabsValue>;
  isEditingOrAddingTicket: boolean;
  mainPanel: InboxMainPanel;
  setHelperPanel: (helperTab: HelperTab | null) => void;
  setIsEditingOrAddingTicket: Dispatch<SetStateAction<boolean>>;
  selectedTicket: Ticket;
  setSelectedTicket: Dispatch<SetStateAction<Ticket>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboxPanelsContext = createContext<InboxPanelsContext>(
  {} as InboxPanelsContext
);

export const InboxPanelsProvider: FC = ({ children }) => {
  const {
    setHelperPanel,
    helperMenuItems,
    helperPanel,
    helperPanelRef,
    mainPanel,
    isEditingOrAddingTicket,
    setIsEditingOrAddingTicket,
    selectedTicket,
    setSelectedTicket
  } = useInboxPanels();
  // const [disabled, setDisabled] = useState(null);
  // const [size, setSize] = useState('0%');
  // const [isShowed, setIsShowed] = useState<boolean>(false);

  return (
    <InboxPanelsContext.Provider
      value={{
        helperPanel,
        helperMenuItems,
        isEditingOrAddingTicket,
        mainPanel,
        helperPanelRef,
        setHelperPanel,
        setIsEditingOrAddingTicket,
        selectedTicket,
        setSelectedTicket
      }}
    >
      {children}
    </InboxPanelsContext.Provider>
  );
};
