import './GeneralImageComponent.css';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  $getNodeByKey,
  BaseSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import { $isGeneralImageNode, GeneralImageNode } from './GeneralImageNode';
import GeneralImageResizer from './GeneralImageResizer';
import {
  $getRoot,
  $createParagraphNode,
  $setSelection,
  $createRangeSelection
} from 'lexical';
import { ImageNode } from 'src/modules/Dashboard/views/Inbox/EmailPanel/components/EmailEditor/components/Editor/plugins/ImagePlugin/ImageNode';

interface Props {
  node: GeneralImageNode | ImageNode;
}

function GeneralImageRenderer({ node }: Props) {
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(
    node.getKey()
  );
  const [isResizing, setIsResizing] = useState(false);
  const imageRef = useRef<null | HTMLImageElement>(null);
  const [selection, setSelection] = useState<BaseSelection | null>(null);
  const activeEditorRef = useRef<LexicalEditor | null>(null);

  const onClick = useCallback(
    (payload: MouseEvent) => {
      const event = payload;

      if (isResizing) {
        return true;
      }
      if (event.target === imageRef.current) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          clearSelection();
          setSelected(true);
        }
        return true;
      }

      return false;
    },
    [isResizing, isSelected, setSelected, clearSelection]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Backspace' && isSelected) {
        event.preventDefault();
        editor.update(() => {
          const nodeKey = node.getKey();
          node.remove();

          // Create a new paragraph node if the editor is empty
          if ($getRoot().getChildrenSize() === 0) {
            const paragraph = $createParagraphNode();
            $getRoot().append(paragraph);
            $setSelection($createRangeSelection());
          }
        });
      }
    },
    [editor, isSelected, node]
  );

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor;
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        onClick,
        COMMAND_PRIORITY_LOW
      )
    );

    return () => {
      unregister();
    };
  }, [
    clearSelection,
    editor,
    isResizing,
    isSelected,
    node.getKey(),
    onClick,
    setSelected,
    onKeyDown
  ]);

  const onResizeEnd = (
    nextWidth: 'inherit' | number,
    nextHeight: 'inherit' | number
  ) => {
    // Delay hiding the resize bars for click case
    setTimeout(() => {
      setIsResizing(false);
    }, 200);

    editor.update(() => {
      const n = $getNodeByKey(node.getKey());
      if ($isGeneralImageNode(n)) {
        node.setWidthAndHeight(nextWidth, nextHeight);
      }
    });
  };

  const onResizeStart = () => {
    setIsResizing(true);
  };

  return (
    <>
      <img
        ref={imageRef}
        src={node.getSrc()}
        alt={node.getAltText()}
        style={{
          maxWidth: `${node.getMaxWidth()}px`,
          width:
            node.getWidth() === 'inherit' ? 'inherit' : `${node.getWidth()}px`,
          height:
            node.getHeight() === 'inherit'
              ? 'inherit'
              : `${node.getHeight()}px`,

          margin: 0,
          cursor: 'pointer',
          position: 'relative'
        }}
        onKeyDown={onKeyDown}
        tabIndex={0} // Make the image focusable
      />
      {isSelected && (
        <GeneralImageResizer
          editor={editor}
          imageRef={imageRef}
          onResizeEnd={onResizeEnd}
          onResizeStart={onResizeStart}
        />
      )}
    </>
  );
}

export default GeneralImageRenderer;
