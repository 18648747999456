import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {
  checkMassiveEmailFeature,
  checkMassiveSmsFeature,
  checkMassiveWhatsAppFeature
} from 'src/contexts/CompanyFeaturesContext/utils/checkFeatures';
import { DialerBotProvider } from 'src/modules/DialerBot/views/contexts/DialerBotContext';
import {
  checkReadAnyCallLaunchCampaignPermissions,
  checkReadMassiveEmailPermissions,
  checkReadMassiveSmsPermissions,
  checkReadMassiveWhatsAppPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const MassiveSms = Loader(
  lazy(() => import('../modules/MassiveSmsLaunching/views'))
);
const MassiveEmail = Loader(
  lazy(() => import('../modules/MassiveEmail/views'))
);
const MassiveWhatsapp = Loader(
  lazy(() => import('../modules/MassiveWhatsapp/views'))
);
const DialerBotView = Loader(
  lazy(() => import('src/modules/Dashboard/views/DialerBot'))
);

const massiveRoutes: RouteObject[] = [
  {
    path: 'dialerbot',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadAnyCallLaunchCampaignPermissions}
      >
        <DialerBotProvider>
          <DialerBotView />
        </DialerBotProvider>
      </Restricted>
    )
  },
  {
    path: 'massive-sms',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadMassiveSmsPermissions}
        featureCheckerFn={checkMassiveSmsFeature}
      >
        <MassiveSms />
      </Restricted>
    )
  },
  {
    path: 'massive-email',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadMassiveEmailPermissions}
        featureCheckerFn={checkMassiveEmailFeature}
      >
        <MassiveEmail />
      </Restricted>
    )
  },
  {
    path: 'massive-whatsapp',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadMassiveWhatsAppPermissions}
        featureCheckerFn={checkMassiveWhatsAppFeature}
      >
        <MassiveWhatsapp />
      </Restricted>
    )
  }
];

export default massiveRoutes;
