import React from 'react';
import {
  CardHeader,
  Card,
  Divider,
  styled,
  IconButton,
  CardContent,
  Dialog
} from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

interface ModalViewProps {
  title: string;
  children: JSX.Element;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  heightSize?: number;
}

const ModalView: React.FC<ModalViewProps> = ({
  title,
  children,
  setShowModal,
  heightSize
}) => {
  const handleClose = () => setShowModal(false);
  const { t } = useTranslation();
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth={false}
      sx={{
        '& .MuiDialog-paper': {
          width: '50%',
          maxWidth: 'none'
        }
      }}
    >
      <Card>
        <CardHeader
          title={t(title)}
          action={
            <IconButtonWrapper
              size="small"
              color="secondary"
              onClick={handleClose}
            >
              <CloseTwoToneIcon />
            </IconButtonWrapper>
          }
        />
        <Divider />
        <CardContent
          sx={{
            overflowX: 'auto',
            height: heightSize ? `${heightSize}dvh` : 'fit'
          }}
          className="fix-custom-scrollbar "
        >
          {children}
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default ModalView;
