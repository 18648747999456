import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { Ticket } from '../../models/tickets';
import { useTranslation } from 'react-i18next';
import { ContactUI } from 'src/modules/Contacts/models/contacts';
import ContactInfoModal from 'src/modules/Contacts/views/components/contacts/ContactInfoModal';
import { getContactListUI } from 'src/modules/Contacts/views/utils/getContactsUI';

interface Props {
  ticket: Ticket;
}

const TicketContacts = (props: Props) => {
  const { ticket } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [involvedContacts, setInvolvedContacts] = useState<ContactUI[]>([]);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const companyId = ticket?.companyId;

  useEffect(() => {
    const contactIds = ticket.involvedContacts
      ?.map((contact) => contact.contactId)
      .filter((id) => id);

    if (contactIds?.length > 0) {
      getContactListUI({ contactIds, companyId }).then((resp) => {
        setInvolvedContacts(resp);
      });
    }
  }, [ticket?.involvedContacts]);

  const getContactName = (contact: ContactUI) => {
    const name = contact?.name;
    return name;
  };

  const onCloseEditModal = () => setIsOpenEditModal(false);
  const onOpenEditModal = () => setIsOpenEditModal(true);

  if (involvedContacts?.length === 0) return <></>;

  return (
    <>
      <ContactInfoModal
        key={involvedContacts[0]?.id}
        contact={involvedContacts[0]}
        setContact={() => {}}
        open={isOpenEditModal}
        onClose={onCloseEditModal}
      />
      <Box width="100%" py={1}>
        <Divider sx={{ my: 1 }}>
          <Typography
            variant="body1"
            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
          >
            {t('Contacts')}
          </Typography>
        </Divider>
        <Stack direction="row" mt={2} spacing={0.5} overflow="scroll">
          {involvedContacts
            .sort((a, b) => getContactName(a).localeCompare(getContactName(b)))
            .map((contact) => (
              <Tooltip key={contact?.id} title={getContactName(contact)}>
                <Avatar
                  key={contact?.id}
                  style={{ backgroundColor: theme.colors.secondary.lighter }}
                  onClick={onOpenEditModal}
                >
                  <Typography
                    variant="h4"
                    color={theme.colors.secondary.main}
                    fontWeight="bold"
                  >
                    {getContactName(contact)?.[0]}
                  </Typography>
                </Avatar>
              </Tooltip>
            ))}
        </Stack>
      </Box>
    </>
  );
};

export default TicketContacts;
