import { FC, forwardRef, ReactNode, Ref } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, useTheme } from '@mui/material';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
  disableAutoHide?: boolean;
}

const Scrollbar: FC<ScrollbarProps> = forwardRef(
  ({ className, children, disableAutoHide, ...rest }, ref: Ref<Scrollbars>) => {
    const theme = useTheme();

    return (
      <Scrollbars
        ref={ref}
        autoHide={disableAutoHide ? false : true}
        renderThumbVertical={() => {
          return (
            <Box
              sx={{
                width: 5,
                background: `${theme.colors.alpha.black[100]}`,
                borderRadius: `${theme.general.borderRadiusLg}`,
                transition: `${theme.transitions.create(['background'])}`,
                '&:hover': {
                  background: `${theme.colors.alpha.black[30]}`
                }
              }}
            />
          );
        }}
        {...rest}
      >
        {children}
      </Scrollbars>
    );
  }
);

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
