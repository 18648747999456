import {
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Ticket } from '../../../models/tickets';
import { t } from 'i18next';
import { PersonOutline } from '@mui/icons-material';
import useShowflowInvolvedContacts from './hooks/useShoflowInvolvedContacts';
import useTicketShowflows from './hooks/useTicketShowflows';
import { ShowflowTextBlockRenderer } from './components/ShowflowTextBlock';

interface Props {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
}

function ShowFlowTicketViewer({ ticket, setTicket }: Props) {
  const theme = useTheme();

  const {
    selectedShowflow,
    setSelectedShowflowId,
    ticketShowflows,
    selectedShowflowId
  } = useTicketShowflows(ticket);

  // Contacts involved in the current conversation
  const {
    involvedContacts,
    selectedContact,
    selectedContactId,
    setSelectedContactId
  } = useShowflowInvolvedContacts();

  function LegendItem({ color, label }) {
    return (
      <Box display="flex" flexDirection={'row'} alignItems={'center'} gap={0.5}>
        <Box
          sx={{
            height: '12px',
            width: '12px',
            background: color,
            borderRadius: '50%'
          }}
        ></Box>
        <Typography>{label}</Typography>
      </Box>
    );
  }

  return (
    <Stack direction={'column'} spacing={2} pt={2}>
      <TextField
        size="small"
        select
        label={t('Script')}
        value={selectedShowflowId}
        onChange={(e) => setSelectedShowflowId(e.target.value)}
      >
        {ticketShowflows.map((showflow) => (
          <MenuItem key={showflow.id} value={showflow.id}>
            {showflow.name}
          </MenuItem>
        ))}
      </TextField>
      <Stack
        direction="column"
        style={{
          width: '100%',
          padding: '1rem',
          border: '1px solid #e0e0e0',
          backgroundColor: '#F6F8FB',
          borderRadius: '10px'
        }}
        spacing={2}
      >
        <TextField
          size="small"
          select
          label={t('Contact')}
          value={selectedContactId}
          key={selectedContactId}
          onChange={(e) => setSelectedContactId(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: selectedContactId ? <PersonOutline /> : null
          }}
        >
          {involvedContacts
            ?.filter((contact) => contact.id)
            .map((contact, idx) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.label?.value}
              </MenuItem>
            ))}
          {involvedContacts.every((contact) => !contact.id) && (
            <MenuItem value={null} disabled>
              {t('No involved contacts')}
            </MenuItem>
          )}
        </TextField>

        {!selectedShowflowId && (
          <Typography variant="h4" alignSelf={'center'}>
            {t('Select a script to see the content')}
          </Typography>
        )}

        {selectedShowflow?.textBlocks.map((textBlock, index) => {
          return (
            <ShowflowTextBlockRenderer
              key={index + `${ticket?.id}-${selectedShowflowId}`}
              textBlock={textBlock}
              contact={selectedContact}
              ticket={ticket}
              setTicket={setTicket}
            />
          );
        })}
      </Stack>
      {/* Leyenda con información del color asociado a cada variable */}
      <Stack direction="row" columnGap={2} flexWrap={'wrap'}>
        <LegendItem
          color={theme.palette.success.main}
          label={t('Call variable')}
        />
        <LegendItem color={'#D962D7'} label={t('Company variable')} />

        <LegendItem
          color={theme.palette.secondary.main}
          label={t('Contact field')}
        />
        <LegendItem
          color={theme.palette.warning.main}
          label={t('Ticket field')}
        />
      </Stack>
    </Stack>
  );
}

export default ShowFlowTicketViewer;
