import {
  getTicketSubResolutionByIdApi,
  getTicketSubResolutionsApi
} from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/subResolutions/getTicketSubResolutionsApi';
import {
  TicketSubResolution,
  TicketSubResolutionUI,
  TicketSubResolutionsRequestParams
} from '../../models/tickets';

export const getTicketSubResolutions = async (
  params: TicketSubResolutionsRequestParams
): Promise<TicketSubResolution[]> => {
  const subresolutions = await getTicketSubResolutionsApi(params);
  return subresolutions;
};

export const getTicketSubResolutionsUI = async (
  params: TicketSubResolutionsRequestParams
): Promise<TicketSubResolutionUI[]> => {
  const subresolutions = await getTicketSubResolutions(params);

  return subresolutions.map(getTicketSubResolutionUI);
};

export const getTicketSubResolutionUI = (
  subResolution: TicketSubResolution
): TicketSubResolutionUI => {
  return { ...subResolution, resolutionName: subResolution.resolution.name };
};

export const getTicketSubResolutionById = async (id: string) => {
  const subResolution = await getTicketSubResolutionByIdApi(id);
  return subResolution;
};
