import axios, { AxiosResponse } from 'axios';
import { AIModelApi, AIModel } from '../models/AIModels';
import { invalidateIntentionsModelsDataQuery } from '../views/hooks/getAIModelsQuery';
import { INTENTION_MODEL } from 'src/utils/apiRoutes/msBasicNlu';

export const postIntentionModelApi = async (
  params: AIModelApi
): Promise<AIModel> => {
  const response: AxiosResponse<AIModel> = await axios.post(
    INTENTION_MODEL,
    params
  );

  invalidateIntentionsModelsDataQuery();
  return response.data;
};
