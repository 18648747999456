import { queryClient } from 'src/utils/queryClient';
import { getExternalActionById } from '../domain/getExternalActionById';

export const getExternalActionByIdQuery = (id: string) => {
  return queryClient.fetchQuery(
    ['externalAction', id],
    () => getExternalActionById(id),
    { staleTime: 30 * 1000 }
  );
};
