import {
  getDashboardsApi,
  getDashboardsByIdApi,
  getPresetDashboardByFixedIdApi,
} from '../infrastructure/api/getDashboards';

/** GET ALL DASHBOARDS
 *
 * Returns all dashboards
 * @returns {Dashboard[]} Dashboard Array
 */
export const getDashboards = async (params?: {
  name?: string;
  page?: number;
  size?: number;
}) => {
  return getDashboardsApi(params);
};

/** GET  DASHBOARD BY ID
 *
 * Return a dashboard
 * @returns {Dashboard} Dashboard
 */
export const getDashboardById = async (dashboardId: string) => {
  return getDashboardsByIdApi(dashboardId);
};

/** GET PRESET DASHBOARD BY ID
 *
 * @param fixedId service or campaign id of the desired dashboard
 * @returns {Dashboard}
 */
export const getPresetDashboardByFixedId = async (fixedId: string) => {
  return getPresetDashboardByFixedIdApi(fixedId);
};
