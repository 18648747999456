import { useState, useRef, useEffect } from 'react';

function useCustomMeasure(key?: string) {
  const [bounds, setBounds] = useState({ width: 0, height: 0 });
  const ref = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const rect = entry.target.getBoundingClientRect();

        requestAnimationFrame(() => {
          setBounds({ width: rect.width, height: rect.height });
        });
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [key]);

  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let resizeTimer: NodeJS.Timeout;

    if (bounds.width !== 0 && bounds.height !== 0) {
      setIsResizing(true);
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setIsResizing(false);
      }, 150);
    }

    return () => {
      clearTimeout(resizeTimer);
    };
  }, [bounds.width, bounds.height, key]);

  return [ref, bounds, isResizing] as const;
}

export default useCustomMeasure;
