import { updateIntentionModelApi } from '../infrastructure/putIntentionModel';
import { AIModelApi, AIModel } from '../models/AIModels';

/** UPDATE INTENTION MODEL
 *
 * Update Intention Model in database
 *
 *
 * @returns INTENTION MODEL
 */
export const updateIntentionModel = async (
  id: string,
  params: AIModelApi
): Promise<AIModel> => {
  const intentionModel = await updateIntentionModelApi(id, params);
  return intentionModel;
};
