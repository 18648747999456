import axios, { AxiosResponse } from 'axios';
import { ExternalAction } from '../models/externalActionsModels';
import { replaceURLParams } from 'src/services/utils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const INTEGRATIONS = process.env.REACT_APP_BASE_INTEGRATIONS;
const EXTERNAL_ACTIONS = process.env.REACT_APP_EXTERNAL_ACTION_ID;

export const getExternalActionByIdAPI = async (
  id: string
): Promise<ExternalAction> => {
  const path = replaceURLParams(BASE_URL + INTEGRATIONS + EXTERNAL_ACTIONS, {
    '{id}': id
  });
  const response: AxiosResponse<ExternalAction> = await axios.get(path);

  return response.data;
};
