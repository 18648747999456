export interface ScheduleCalendar {
  campaignId: string;
  companyId?: string;
  id?: string;
  originCampaignId?: string;
  scheduleStatus?: ScheduleCalendarStatus;
  userId: string;
  calendarData: {
    title: string;
    description: string;
    scheduleTimestamp: number;
    createdBy: string;
    contactId: string;
    originCampaignId: string;
    ticketId: string;
  };
  callResult?: string;
  createDate?: string;
  endDate?: string;
  launchDate?: string;
  phonenumber?: string;
  phonenumbersList?: { [key: string]: string };
  relatedCalls?: string[];
  relaunchAttempts?: number;
  scheduleDatabaseId?: string;
}

export enum ScheduleCalendarStatus {
  Failed = 'Failed',
  Unlaunched = 'Unlaunched',
  Finished = 'Finished',
  Processing = 'Processing',
  Requesting = 'Requesting',
  RequestingAgent = 'RequestingAgent',
  Rescheduled = 'Rescheduled',
  Postponed = 'Postponed'
}
