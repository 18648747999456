import { EmailEditorConfig, EmailEditorMode } from '../models/models';
import { Conversation } from 'src/models/conversations/conversations';
import { useState } from 'react';

export interface SendEmailMessageData {
  message: string;
  to: string[];
  cc: string[];
  replyTo: string[];
  subject: string;
  inReplyToId: string;
}

interface Params {
  config: EmailEditorConfig;
  conversation: Conversation;
  initialEmailTo?: string[];
}

function useEmailEditor({ config, conversation, initialEmailTo = [] }: Params) {
  const { messageToReply, mode } = config;

  const agentEmail = conversation?.originAgent?.emailAddress;
  const removeAgentEmail = (email: string) => email !== agentEmail;

  const createTo = () => {
    if (mode === EmailEditorMode.Reply && messageToReply.replyTo?.length > 0) {
      return [...messageToReply.replyTo].filter(removeAgentEmail);
    }
    if (mode === EmailEditorMode.Reply || mode === EmailEditorMode.ReplyAll) {
      return [messageToReply.from, ...messageToReply.to].filter(
        removeAgentEmail
      );
    }
    return initialEmailTo;
  };

  const createCC = () => {
    if (mode === EmailEditorMode.ReplyAll) {
      return messageToReply.cc.filter(removeAgentEmail);
    }
    return [];
  };
  const createSubject = () => {
    if (mode === EmailEditorMode.Reply || mode === EmailEditorMode.ReplyAll) {
      return `Re: ${messageToReply.subject}`;
    }
    if (mode === EmailEditorMode.Forward) {
      return `Fwd: ${messageToReply.subject}`;
    }
    if (mode === EmailEditorMode.New) {
      return '';
    }
    return messageToReply.subject;
  };

  const initialMessage = {
    subject: createSubject(),
    to: createTo(),
    cc: createCC(),
    message: '',
    inReplyToId: messageToReply?.messageId,
    replyTo: []
  } as SendEmailMessageData;

  const [message, setMessage] = useState(config.message ?? initialMessage);

  return { message, setMessage };
}

export default useEmailEditor;
