import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { KeywordsGroupsProvider } from '../modules/KeywordsGroups/views/context';
import { AnalyzedRecordingsProvider } from '../modules/AnalyzedRecordings/views/context';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// example import component
const KeywordsGroups = Loader(
  lazy(() => import('../modules/KeywordsGroups/views'))
);

const AnalyzedRecordings = Loader(
  lazy(() => import('../modules/AnalyzedRecordings/views'))
);

const QuestionsGroups = Loader(
  lazy(() => import('../modules/QuestionsGroups/views'))
);
const qualityRoutes: RouteObject[] = [
  {
    path: 'keyword-groups',
    element: (
      <KeywordsGroupsProvider>
        <KeywordsGroups />
      </KeywordsGroupsProvider>
    )
  },
  {
    path: 'analyzed-conversations',
    element: (
      <AnalyzedRecordingsProvider>
        <AnalyzedRecordings />
      </AnalyzedRecordingsProvider>
    )
  },
  {
    path: 'questions-groups',
    element: <QuestionsGroups />
  }
];

export default qualityRoutes;
