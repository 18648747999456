import { ColumnDef, VisibilityState } from '@tanstack/react-table';

export const getHiddenVisibilityStateFromColumns = <T>(
  columns: ColumnDef<T>[]
) => {
  const visibilityState: VisibilityState = columns.reduce((prev, curr) => {
    return { ...prev, [curr.id]: false };
  }, {});
  return visibilityState;
};
