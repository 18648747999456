import { memo, useEffect, useState } from 'react';
import { Conversation } from 'src/models/conversations/conversations';
import { KeyboardArrowDown, Mail } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import EmailItem from './components/EmailMessageItem';
import { motion } from 'framer-motion';
import { FiltersTab } from './components/FiltersTab';
import EmailPanelHeader from './components/EmailPanelHeader';
import { EmailMessage, MessageStatus } from 'src/models/conversations/messages';
import { setConversationStore } from 'src/services/socialmedia/infraestructure/store/setConversationStore';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { readMessages } from 'src/services/socialmedia/domain/messages/readMessages';

interface Props {
  conversation: Conversation;
}

function EmailPanel({ conversation }: Props) {
  const [orderBy, setOrderBy] = useState<'Newest' | 'Oldest'>('Newest');
  const [textInput, setTextInput] = useState<string>('');
  const [textFilter, setTextFilter] = useState<string>('');

  // The oldest message will be the last with the reverse()
  const unorderedMessages = (
    (conversation?.messages as EmailMessage[]) ?? []
  ).slice();

  const filteredMessages =
    textFilter === ''
      ? unorderedMessages
      : unorderedMessages.filter((message) =>
          message.message.includes(textFilter)
        );

  const messages =
    orderBy === 'Newest' ? filteredMessages.reverse() : filteredMessages;

  const involvedContacts = conversation?.involvedContacts;

  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  async function readEmail() {
    const conversation = getCurrentConversation(false);
    await readMessages(conversation);
    const lastMessage = conversation.lastMessage
      ? conversation.lastMessage
      : messages?.[0];
    setConversationStore({
      ...conversation,
      lastMessage: {
        ...lastMessage,
        status: MessageStatus.READ
      },
      unreadIncomingMessages: false
    });
  }

  useEffect(() => {
    if (conversation?.unreadIncomingMessages) {
      readEmail();
    }
  }, [conversation?.unreadIncomingMessages, conversation?.messages]);

  return (
    <Stack height={'100%'} sx={{ background: '#f5f5f5' }}>
      <EmailPanelHeader
        icon={Mail}
        title={messages?.[0]?.subject}
        button={
          <IconButton
            color={'primary'}
            onClick={() => {
              setAreFiltersOpen((open) => !open);
            }}
          >
            <motion.div
              animate={{
                rotate: areFiltersOpen ? 180 : 0
              }}
              transition={{
                duration: 0.2,
                type: 'tween'
              }}
              style={{
                //align the icon on the center
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <KeyboardArrowDown />
            </motion.div>
          </IconButton>
        }
      />
      <FiltersTab
        areFiltersOpen={areFiltersOpen}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        textInput={textInput}
        setTextInput={setTextInput}
        setTextFilter={setTextFilter}
        textFilter={textFilter}
      />
      <div
        style={{
          overflowY: 'auto',
          scrollbarColor: 'black',
          scrollbarWidth: 'thin',
          scrollbarGutter: 'stable',
          minHeight: 'calc(100% - 73px)'
        }}
      >
        <Stack direction={'column'} p={1} rowGap={1}>
          {messages?.map((message, index) => (
            <EmailItem
              key={message.conversationId + index}
              conversationId={conversation.id}
              message={message}
              involvedContacts={involvedContacts}
              defaultMaximized={index === 0}
            />
          ))}
        </Stack>
      </div>
    </Stack>
  );
}

function areConversationsEqual(prevProps: Props, nextProps: Props) {
  return (
    JSON.stringify(prevProps.conversation) ===
    JSON.stringify(nextProps.conversation)
  );
}

export default memo(EmailPanel, areConversationsEqual);
