import { deleteTicketResolutionApi } from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/resolutions/deleteTicketResolutionsApi';
import { TicketResolution } from '../../models/tickets';

export const deleteTicketResolution = async (id: string) => {
  await deleteTicketResolutionApi(id);
};

export const deleteTicketResolutions = async (
  ticketResolutions: TicketResolution[]
) => {
  const ticketResolutionPromises = ticketResolutions.map((resolution) =>
    deleteTicketResolution(resolution.id)
  );
  await Promise.all(ticketResolutionPromises);
};
