import { Theme } from '@mui/material';
import { Box, Modal, Typography, Button, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

const style: SxProps<Theme> = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit',
  height: 'fit',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
  display: 'flex',
  alignItems: 'center'
};

export interface ModalButton<T> {
  text: string;
  handler: (data: T) => void;
  style?: SxProps<Theme>;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info';
}

interface ModalPropTypes<T, K extends keyof T> {
  text: string;
  isOpen: boolean;
  leftButton: ModalButton<T>;
  rightButton: ModalButton<T>;
  data?: T;
  body?: string[];
}

export const DialModal = <T, K extends keyof T>({
  text,
  isOpen,
  leftButton,
  rightButton,
  data,
  body
}: ModalPropTypes<T, K>) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} display="flex" flexDirection={'column'}>
        <Box mb={2}>
          <Typography id="modal-modal-title" variant="h4">
            {text}
          </Typography>
          <Box mt={2}>
            {body?.map((element, index) => {
              return (
                <Typography
                  key={index}
                  id="modal-modal-title"
                  variant="subtitle1"
                >
                  {element}
                </Typography>
              );
            })}
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'row'}
          width={'100%'}
        >
          <Button
            color={leftButton.color || 'primary'}
            variant={leftButton.variant || 'contained'}
            onClick={() => leftButton.handler(data)}
            sx={leftButton.style}
            id="btnLeft-DialModal"
          >
            {t(leftButton.text)}
          </Button>
          <Button
            color={rightButton.color || 'primary'}
            variant={rightButton.variant || 'contained'}
            onClick={() => rightButton.handler(data)}
            sx={rightButton.style}
            id="btnRight-DialModal"
          >
            {t(rightButton.text)}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DialModal;
