import {
  Divider,
  IconButton,
  List,
  MenuItem,
  Popover,
  Slider,
  Stack,
  Tooltip
} from '@mui/material';
import {
  KeyboardArrowDown,
  VolumeDown,
  VolumeOff,
  VolumeUp
} from '@mui/icons-material';
import { useContext, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { useVideocallSpeaker } from '../hooks/useVideocallSpeaker';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import VideoWebRTCDevices, {
  videoSpeakerDeviceKey
} from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';
import { VideoCallsDomain } from 'src/modules/VideoCall/domain';

export const AudioOutlinedSelect = (showIcon) => {
  const [speaker, speakers, onSelectChange] = useVideocallSpeaker();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const { devices, videoRoom, streams, rtcSession } =
    useContext(VideocallRoomContext);
  const {
    speakerMuted,
    setSpeakerMuted,
    setMicrophoneMuted,
    setSpeakersVolume,
    speakersVolume
  } = devices;

  const toggleMute = () => {
    if (videoRoom.room && videoRoom.me) {
      VideoCallsDomain.toggleDeaf(
        videoRoom.room.id,
        videoRoom.me.connectionId
      ).then((deaf) => {
        setSpeakerMuted(deaf);
        setMicrophoneMuted(deaf);
        VideoWebRTCDevices.toggleDisableAudioStream(streams.local, deaf);
      });
    } else {
      setSpeakerMuted(!speakerMuted);
      setMicrophoneMuted(!speakerMuted);
      VideoWebRTCDevices.toggleDisableAudioStream(streams.local, !speakerMuted);
    }
  };

  useEffect(() => {
    const savedSpeakerId = localStorage.getItem(videoSpeakerDeviceKey);
    if (savedSpeakerId) {
      const savedSpeaker = speakers.find(
        (speaker) => speaker.deviceId === savedSpeakerId
      );
      if (savedSpeaker) {
        onSelectChange(savedSpeaker.label);
      }
    }
  }, []);

  return (
    <div
      style={{
        maxWidth: 'fit-content',
        background: 'rgba(0,0,0,0.05)',
        borderRadius: '8px'
      }}
    >
      {showIcon && (
        <Tooltip title={speakerMuted ? t('Undeafen') : t('Deafen')}>
          <IconButton
            sx={{
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0'
            }}
            onClick={toggleMute}
          >
            {speakerMuted ? <VolumeOff /> : <VolumeUp />}
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('Speaker settings')}>
        <IconButton
          ref={ref}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          sx={{
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            width: '30px'
          }}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Tooltip>
      <Popover
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        anchorReference="anchorEl"
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stack direction="row" p={1.5} alignItems={'center'} columnGap={1.5}>
          <VolumeDown />
          <Slider
            min={0}
            value={speakersVolume}
            max={100}
            onChange={(e, value) => {
              setSpeakersVolume(value as number);
            }}
          />
          <VolumeUp />
        </Stack>
        <Divider />
        <List>
          {speakers.map((device, idx) => {
            return (
              <MenuItem
                key={idx}
                selected={speaker?.deviceId === device.deviceId}
                onClick={(): void => {
                  onSelectChange(device.label);
                  setIsOpen(false);
                }}
                value={device.label}
              >
                {device.label}
              </MenuItem>
            );
          })}
        </List>
      </Popover>
    </div>
  );
};
