import {
  Button,
  Divider,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FormikProps } from 'formik';
import { t } from 'i18next';
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useContext
} from 'react';
import { MatchByForm, WebChatAccount } from '../../../models/webChatAccount';

import { ContactFields } from 'src/components/ContactFields';
import { useWebChatAccount } from '../../utils/useWebChatAccount';
import { Add, Edit } from '@mui/icons-material';
import { CreateStyle } from '../../components/Style';
import { WebChatStyle } from '../../../models/webChatStyle';
import { getWebChatStyle } from '../../../../../../../../../services/webchat/infraestructure/webChatStyle/getWebChatStyle';
import { useAlert } from 'src/hooks/useAlert';
import { EmailAccount } from 'src/models/accounts';
import { getEmailAccounts } from 'src/services/email/domain/account/getEmailAccounts';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreateWebChatStylesPermissions,
  checkReadWebChatStylesPermissions,
  checkUpdateWebChatStylesPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

interface CreateWebChatAccountFormProps {
  formikProps: FormikProps<WebChatAccount>;
  formType: string;
  setWebChatStyle: Dispatch<SetStateAction<WebChatStyle>>;
  matchBy: MatchByForm;
  setMatchBy: Dispatch<SetStateAction<MatchByForm>>;
}

const CreateWebChatAccountForm: React.FC<CreateWebChatAccountFormProps> = ({
  formikProps,
  formType,
  setWebChatStyle,
  matchBy,
  setMatchBy
}) => {
  const { contactGroup, companyId } = useWebChatAccount();
  const [emailAccounts, setEmailAccounts] = useState<EmailAccount[]>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedStyle, setSelectedStyle] = useState<WebChatStyle>();
  const [styles, setStyles] = useState<WebChatStyle[]>();
  const { hasAccess } = useContext(PermissionsContext);

  const hasWebChatStylePermissions = hasAccess(
    checkReadWebChatStylesPermissions
  );

  const getEmailAccountsFromContactGroup = async () => {
    getEmailAccounts({ companyId }).then((emails) => {
      setEmailAccounts(emails);
    });
  };

  const { showAlert } = useAlert();

  const getWebChatStyles = async () => {
    try {
      const response = await getWebChatStyle({ companyId, size: -1 });
      setStyles(response?.elements);
      if (formType === 'Edit') {
        const sel = response?.elements.find(
          (e) => e.id === formikProps.values.styleId
        );
        setSelectedStyle(sel);
      }
    } catch (error) {
      showAlert('Error getting web chat styles', 'error', 3000);
    }
  };

  useEffect(() => {
    if (hasWebChatStylePermissions) {
      getWebChatStyles();
    }
  }, [isCreating, formikProps.values.styleId]);

  useEffect(() => {
    if (companyId) {
      getEmailAccountsFromContactGroup();
    }
  }, [formikProps.values?.contactGroupId]);

  const initialAutoMessageRef = useRef<any>();

  return (
    <Stack mx={1} py={1} spacing={2}>
      <CreateStyle
        show={isCreating}
        setShow={setIsCreating}
        editWCS={isEditing ? selectedStyle : null}
        formikProps={formikProps}
        setSelected={setSelectedStyle}
      />
      <Typography variant="h4">{t('Account details')}</Typography>
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
        <Stack spacing={2} width="100%" direction={{ xs: 'row', md: 'column' }}>
          <TextField
            fullWidth
            id="webchat-account-name-textfield-id"
            label={t('Name')}
            name="name"
            value={formikProps.values.name}
            onChange={formikProps.handleChange}
            required
          />
          <Stack direction="row" spacing={1}>
            <TextField
              helperText={
                !hasAccess(checkReadWebChatStylesPermissions)
                  ? t('You do not have permissions')
                  : ''
              }
              disabled={!hasAccess(checkReadWebChatStylesPermissions)}
              error={!hasAccess(checkReadWebChatStylesPermissions)}
              fullWidth
              label={t('Style')}
              id="webchat-account-style-textfield-id"
              value={formikProps.values.styleId}
              name="styleId"
              onChange={(e) => {
                formikProps.handleChange(e);
                const st = styles.find((s) => s.id === e.target.value);
                setWebChatStyle(st);
                setSelectedStyle(st);
              }}
              sx={{ minWidth: '150px' }}
              select
              required
            >
              {styles &&
                styles.map((s) => {
                  return (
                    <MenuItem value={s.id} key={s.id}>
                      {s.name}
                    </MenuItem>
                  );
                })}
              {!styles && <MenuItem value={undefined} key={'none'}></MenuItem>}
            </TextField>
            {formikProps.values.styleId !== '' && (
              <Tooltip title={t('Edit style')} arrow>
                <Button
                  disabled={
                    !hasAccess(checkReadWebChatStylesPermissions) ||
                    !hasAccess(checkUpdateWebChatStylesPermissions)
                  }
                  id="webchat-edit-style-button-id"
                  sx={{ width: '53px', height: '53px', minWidth: '53px' }}
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setIsEditing(true);
                    setIsCreating(true);
                  }}
                >
                  <Edit />
                </Button>
              </Tooltip>
            )}

            <Tooltip title={t('Create style')} arrow>
              <Button
                disabled={
                  !hasAccess(checkReadWebChatStylesPermissions) ||
                  !hasAccess(checkCreateWebChatStylesPermissions)
                }
                id="webchat-create-account-button-id"
                sx={{ width: '53px', height: '53px', minWidth: '53px' }}
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsCreating(true);
                  setIsEditing(false);
                }}
              >
                <Add />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack spacing={2} width="100%" direction={{ xs: 'row', md: 'column' }}>
          <TextField
            fullWidth
            label={t('Contact group')}
            id="contact-group-input"
            value={formikProps.values.contactGroupId}
            name="contactGroupId"
            onChange={(e) => {
              if (formType === 'Edit') {
                showAlert(
                  'Change the contact group can misconfigure the form',
                  'warning',
                  3000
                );
              }
              formikProps.values['initialForm'] = [];
              formikProps.setFieldValue('contactGroupId', e.target.value);
            }}
            select
            required
          >
            {contactGroup &&
              contactGroup.map((c) => {
                return (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
          </TextField>
          <TextField
            fullWidth
            id="email-account-input"
            label={t('Email account')}
            value={formikProps.values?.emailAccountId}
            name="emailAccountId"
            onChange={(e) => {
              formikProps.setFieldValue('emailAccountId', e.target.value);
            }}
            disabled={!(emailAccounts?.length > 0)}
            select
          >
            {emailAccounts &&
              emailAccounts?.map((e) => {
                return (
                  <MenuItem value={e.id} key={e.id}>
                    {e.emailAddress}
                  </MenuItem>
                );
              })}
            <MenuItem value={''}>{t('None')}</MenuItem>
          </TextField>
        </Stack>
      </Stack>
      <Stack direction={'row'} alignItems="center">
        <label style={{ fontSize: '16px', fontWeight: 'bold' }}>
          {t('Accept policies')}:{' '}
        </label>
        <Switch
          id="policies-CreateWebChatAccount"
          name="acceptPolicies"
          checked={formikProps.values.acceptPolicies}
          color="secondary"
          onChange={formikProps.handleChange}
        />
      </Stack>
      <Divider orientation="horizontal" sx={{ width: '100%' }} />
      <Stack>
        <Typography variant="h4">{t('Initial automessage')}</Typography>
        <Typography variant="subtitle1">
          {t(
            'Automatically sent greeting message when new conversation starts'
          )}
        </Typography>
        <Stack pt={2}>
          <TextField
            fullWidth
            id="greeting-message-input"
            label={t('Greeting message...')}
            value={formikProps?.values['initialAutoMessage']}
            onChange={(e) => {
              formikProps?.setFieldValue('initialAutoMessage', e.target.value);
            }}
            inputRef={initialAutoMessageRef}
            multiline
            maxRows={4}
            inputProps={{ maxlength: 120, maxlines: 3 }}
          />
          <Typography variant="subtitle1" alignSelf={'end'}>
            {initialAutoMessageRef.current?.value.length ?? 0}/120
          </Typography>
        </Stack>
      </Stack>
      <Divider orientation="horizontal" sx={{ width: '100%' }} />
      <Stack>
        <Typography variant="h4">{t('Initial form')}</Typography>
        <Typography variant="subtitle1">
          {t('There must be one field checked to select the contacts related.')}
        </Typography>

        {/* NEW COMPONENT*/}
        <Stack>
          {formikProps.values.contactGroupId !== '' && (
            <ContactFields
              fieldArrayName="initialForm"
              contactGroupId={formikProps.values.contactGroupId}
              buttonText={t('Add')}
              selectLabel="Field"
              inputsSize="medium"
              matchBy={matchBy}
              setMatchBy={setMatchBy}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateWebChatAccountForm;
