import DaServicesDomain from 'src/modules/Admin/modules/Operations/modules/DaServices/domain/DaServicesDomain';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import UsersDomain from 'src/services/users/domain/UserDomain';
import UserManager from 'src/services/users/manager';
import {
  getCalculationsApi,
  getChartTypesApi,
  getCoordinatorDataTypesApi,
  getDataTypesApi,
  getGroupIntervalsApi,
  getKpiCalculationsApi,
  getKpiDataTypesApi,
  getKpiIdentifiersApi,
  getPanelDataApi,
  getPanels,
  getPanelsById,
  getPanelSizesApi,
  getPanelTypesApi
} from '../infrastructure/api/getPanels';
import {
  InitialData,
  RTDataColumn
} from '../infrastructure/websocket/websocket';
import { DashboardData, Panel, PanelData } from '../models/Dashboards';
import dashboardLogic from './dashboardsLogic';

/** GET ALL PANLES
 *
 * Returns all panels
 * @returns {Panel[]} Panel Array
 */
export const getAllPanels = async (dashboardId?: string): Promise<Panel[]> => {
  const panels = await getPanels(dashboardId);
  return panels;
};

/** GET  PANEL BY ID
 *
 * Return a panel
 * @returns {Panel} panel
 */
export const getPanel = async (panelId: string): Promise<Panel> => {
  return getPanelsById(panelId);
};

/** GET  PANELS BY PANELID
 *
 * Return a panel array
 * @returns {Panel} panel
 */
export const getPanelsByPanelsIds = async (panelIds: string[] | []) => {
  const panelsPromise = panelIds.map(async (id) => {
    const panel = await dashboardLogic.getPanel(id);
    return panel;
  });
  const panels = await Promise.all(panelsPromise);

  return panels;
};

/** GET  DATATYPES OF CHARTS PANLES
 *
 * Return a datatypes array
 * @returns {string[]} data types
 */
export const getDataTypes = async (): Promise<string[]> => {
  return getDataTypesApi();
};

/** GET  DATATYPES OF KPI PANELS
 *
 * Return a datatypes array
 * @returns {string[]} data types
 */
export const getKpiDataTypes = async (): Promise<string[]> => {
  return getKpiDataTypesApi();
};

/** GET  DATATYPES OF Coordinator PANELS
 *
 * Return a datatypes array
 * @returns {string[]} data types
 */
export const getCoordinatorDataTypes = async (): Promise<string[]> => {
  return getCoordinatorDataTypesApi();
};

/** GET  TIMEUNITS OF PANELS
 *
 * Return a timeunits array
 * @returns {string[]} data types
 */
export const getPanelSizes = async (): Promise<string[]> => {
  return getPanelSizesApi();
};

/** GET  GROUP INTERVALS OF PANELS
 *
 * Return a timeunits array
 * @returns {string[]} data types
 */
export const getGroupIntervals = async (): Promise<string[]> => {
  return getGroupIntervalsApi();
};

/** GET  AVAILABLE CALCULATIONS OF PANELS BY DATATYPE
 *
 * Return a calculations array
 * @returns {string[]} calculations types
 */
export const getCalculations = async (datatype: string): Promise<string[]> => {
  return getCalculationsApi(datatype);
};

/** GET  AVAILABLE PANELTYPES OF PANELS BY DATATYPE
 *
 * Return a PANELTYPES array
 * @returns {string[]} PANELTYPES types
 */
export const getPanelTypes = async (datatype: string): Promise<string[]> => {
  return getPanelTypesApi(datatype);
};

const compareRTDataColum = (a: PanelData, b: PanelData) => {
  const dateA = new Date(a.instant);
  const dateB = new Date(b.instant);
  return dateA.getTime() - dateB.getTime();
};

/** GET  INITIAL DATA
 *
 * Return a INITIAL DATA sorted by instant
 * @returns {RTDataColumn[]} PANELTYPES types
 */
export const getPanelData = async (
  panelId: string,
  instanceId: string
): Promise<PanelData[]> => {
  const data = await getPanelDataApi(panelId, instanceId);
  const orderedData = data.sort(compareRTDataColum);
  return orderedData;
};

/** GET  KPIS
 *
 * Return a List of kpis available
 * @returns {string[]} KPIS string
 */
export const getPanelKpiCalculations = async (): Promise<string[]> => {
  return getKpiCalculationsApi();
};

/** GET  CHART TYPE
 *
 * Return a List of charts available
 * @returns {string[]} CHARTS string
 */
export const getChartTypes = async (): Promise<string[]> => {
  return getChartTypesApi();
};

/** GET  KPI Identifiers
 *
 * Return a List of kpi identifiers
 * @returns {string[]} CHARTS string
 */
export const getKpiIdentifiers = async (
  dataType: string
): Promise<string[]> => {
  return getKpiIdentifiersApi(dataType);
};

/** GET  campaign name by panel
 *
 * Return a the panel campaign
 * @returns {string} campaign name
 */
export const getPanelCampaginName = async (panel) => {
  if (panel.campaignId !== null) {
    try {
      const campaign = await UsersDomain.getCampaignById(panel.campaignId);
      return campaign.name;
    } catch (error) {
      const { errorTitle } = getErrorMessage(error);
      return errorTitle;
    }
  }

  return 'All Campaigns';
};

/** GET  service name by panel
 *
 * Return a the panel service
 * @returns {string} service name
 */
export const getPanelServiceName = async (panel) => {
  if (panel.serviceId !== null) {
    try {
      const service = await DaServicesDomain.retrieveDaServiceById(
        panel.serviceId
      );
      return service.name;
    } catch (error) {
      //TODO:Once retrieve service function is typed can show the backend message
      return 'Service not found';
    }
  }

  return 'All Services';
};
