import axios, { AxiosError } from 'axios';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { invalidateServicesQuery } from '../views/hooks/daServiceQueries';
import { USERS_MS } from 'src/utils/apiRoutes/msUsers';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';
import { v4 as uuidv4 } from 'uuid';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';

const API_SERVICES = process.env.REACT_APP_DA_SERVICES as string;
const SERVICE_DRAFTS_KEY = 'serviceDrafts';

export interface DaServicesRequestParams {
  assignedUser?: string;
  campaignTypes?: 'Call' | 'Email' | 'Telegram' | 'WebChat' | 'Whatsapp';
  companyId?: string;
  contactGroupId?: string;
  creationDateLowerBound?: string;
  creationDateUpperBound?: string;
  description?: string;
  findSubstring?: boolean; // if true, find substring at name and description
  ids?: string[];
  lastUpdateLowerBound?: string;
  lastUpdateUpperBound?: string;
  name?: string;
  showDisabled?: boolean;
  withContactGroupNames?: boolean;
  sortBy?: keyof DaService;
  sortDir?: DirectionType;
  page?: number;
  size?: number;
}

export const getDaServices = async (
  params: DaServicesRequestParams
): Promise<PaginatedElements<DaService>> => {
  const path = USERS_MS + API_SERVICES;

  const queryParams = { ...params };
  delete queryParams.withContactGroupNames;

  const resp = await axios.get(path, {
    params: queryParams,
    paramsSerializer: { indexes: null }
  });
  return resp.data;
};
//TODO: must be typed and change when it is used
export const getDaServiceById = async (
  daServiceId: string
): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES + '/' + daServiceId;

  try {
    const response = await axios.get(path);
    return response.data;
  } catch (exception: unknown) {
    throw exception as AxiosError;
  }
};

export const postDaService = async (
  daService: DaService
): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES;
  const response = await axios.post(path, daService);
  invalidateServicesQuery();
  return response.data;
};

export const putDaService = async (
  daService: DaService
): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES + '/' + daService.id;
  const response = await axios.put(path, daService);
  invalidateServicesQuery();
  return response.data;
};

export const deleteDaService = (daServiceId: string): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES + '/' + daServiceId;
  return axios
    .delete(path)
    .then((response) => {
      invalidateServicesQuery();
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export interface ServiceFilters {
  assignedUserId?: string;
  companyId?: string;
  contactGroupId?: string;
  description?: string;
  ids?: string[];
  name?: string;
  showDisabled?: boolean;
}

export interface GetServiceFilters extends ServiceFilters {
  sortBy?: keyof DaService;
  sortDir?: DirectionType;
  page?: number;
  size?: number;
}

const checkServiceFilters = (params: {
  service: DaService;
  filters: ServiceFilters;
}) => {
  const { service, filters } = params;
  const {
    assignedUserId,
    companyId,
    contactGroupId,
    description,
    ids,
    name,
    showDisabled
  } = filters;

  const filterFnArray = [
    // Check assignedUserId
    () => !assignedUserId || service.users.includes(assignedUserId),
    // Check companyId
    () => !companyId || service.companyId === companyId,
    // Check ids
    () => !ids || ids.includes(service.id) || ids.includes(service?.draftId),
    // Check contactGroupId
    () => !contactGroupId || service.contactGroupId === contactGroupId,
    // Check showDisabled
    () =>
      showDisabled === undefined || showDisabled === null || !service.enabled,
    // Check name
    () =>
      !name ||
      service.name.toLowerCase().trim().includes(name.trim().toLowerCase()),
    // Check description
    () =>
      !description ||
      service.description
        .toLowerCase()
        .trim()
        .includes(description.trim().toLowerCase())
  ];
  // Check every filter function
  return filterFnArray.every((filterFn) => filterFn());
};

export const getServiceDraftsApi = (params: GetServiceFilters = {}) => {
  const {
    page = 0,
    size = -1,
    sortBy = 'name',
    sortDir = DirectionType.ASC,
    ...filters
  } = params;
  const resp = localStorage.getItem(SERVICE_DRAFTS_KEY);
  const serviceDrafts: DaService[] = !!resp ? JSON.parse(resp) : [];
  const elements = serviceDrafts.filter((service) =>
    checkServiceFilters({ service, filters })
  );
  const paginatedServiceDrafts = getPaginatedElements({
    elements,
    page,
    size,
    sortBy,
    sortDir
  });
  return Promise.resolve(paginatedServiceDrafts);
};

export const createServiceDraftApi = async (service: DaService) => {
  const resp = localStorage.getItem(SERVICE_DRAFTS_KEY);
  const previousServiceDrafts: DaService[] = !!resp ? JSON.parse(resp) : [];
  const draftId = service?.draftId ?? uuidv4();
  const newService: DaService = { ...service, draftId };
  const newServiceDrafts = [...previousServiceDrafts, newService];
  localStorage.setItem(SERVICE_DRAFTS_KEY, JSON.stringify(newServiceDrafts));
  return newService;
};

export const updateServiceDraftApi = async (updatedService: DaService) => {
  const resp = localStorage.getItem(SERVICE_DRAFTS_KEY);
  const previousServiceDrafts: DaService[] = !!resp ? JSON.parse(resp) : [];
  const serviceDrafts = previousServiceDrafts.filter(
    (service) => service.draftId !== updatedService.draftId
  );
  const newService: DaService = { ...updatedService };
  const newServiceDrafts = [...serviceDrafts, newService];
  localStorage.setItem(SERVICE_DRAFTS_KEY, JSON.stringify(newServiceDrafts));
  return newService;
};

export const deleteServiceDraftApi = async (draftId: string) => {
  const resp = localStorage.getItem(SERVICE_DRAFTS_KEY);
  const previousServiceDrafts: DaService[] = !!resp ? JSON.parse(resp) : [];
  const newServiceDrafts = previousServiceDrafts.filter(
    (service) => service.draftId !== draftId
  );
  localStorage.setItem(SERVICE_DRAFTS_KEY, JSON.stringify(newServiceDrafts));
};
