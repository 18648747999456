import { Box, useTheme } from '@mui/material';
import { WebChatStyle } from '../../../models/webChatStyle';
import { FormikProps } from 'formik';

interface Bubble1Props {
  formikProps: FormikProps<WebChatStyle>;
}

export const Bubble1: React.FC<Bubble1Props> = ({ formikProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: formikProps.values.bubbleStyle.bubbleColor2.color,
        borderRadius: '50%',
        padding: '13px',
        width: 160,
        height: 160,
        boxShadow:
          formikProps.values.bubbleStyle.dropShadow &&
          formikProps.values.bubbleStyle.innerShadow
            ? '0px 4px 7px rgba(0, 0, 0, 0.5), 0px 30px 60px -12px inset rgba(50, 50, 93, 0.25), 0px 18px 36px -18px inset rgba(0, 0, 0, 0.3)'
            : formikProps.values.bubbleStyle.innerShadow
              ? '0px 30px 60px -12px inset rgba(50, 50, 93, 0.25), 0px 18px 36px -18px inset rgba(0, 0, 0, 0.3)'
              : formikProps.values.bubbleStyle.dropShadow
                ? '0px 4px 7px rgba(0, 0, 0, 0.5)'
                : 'none',
        backdropFilter: formikProps.values.bubbleStyle.layerBlur
          ? 'blur(10px)'
          : 'none'
      }}
      boxShadow={2}
    >
      <svg
        width={formikProps.values.bubbleStyle.size}
        height={formikProps.values.bubbleStyle.size}
        viewBox="0 0 84 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          filter: formikProps.values.bubbleStyle.layerBlur
            ? 'blur(4px)'
            : 'none'
        }}
      >
        <path
          d="M75.3333 0.333984H8.66668C4.08334 0.333984 0.333344 4.08398 0.333344 8.66732V83.6673L17 67.0007H75.3333C79.9167 67.0007 83.6667 63.2507 83.6667 58.6673V8.66732C83.6667 4.08398 79.9167 0.333984 75.3333 0.333984ZM75.3333 58.6673H17L8.66668 67.0007V8.66732H75.3333V58.6673Z"
          fill={formikProps.values.bubbleStyle.bubbleColor1.color}
        />
      </svg>
    </Box>
  );
};
