import { Close, Search, SwapVert } from '@mui/icons-material';
import {
  Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';

interface Props {
  areFiltersOpen: boolean;
  orderBy: 'Newest' | 'Oldest';
  setOrderBy: (value: 'Newest' | 'Oldest') => void;
  textInput: string;
  setTextInput: (value: string) => void;
  setTextFilter: (value: string) => void;
  textFilter: string;
}

export function FiltersTab({
  areFiltersOpen,
  orderBy,
  setOrderBy,
  textInput,
  setTextInput,
  setTextFilter,
  textFilter
}: Props) {
  const theme = useTheme();
  return (
    <motion.div
      initial={{
        height: 0
      }}
      animate={{
        height: areFiltersOpen ? 'auto' : 0
      }}
      transition={{
        duration: 0.2,
        type: 'tween',
        ease: 'easeOut'
      }}
      style={{
        background: theme.colors.alpha.white[100],
        borderBottom: `${theme.colors.alpha.black[10]} solid 1px`
      }}
    >
      <motion.div
        initial={{
          opacity: 0,
          y: -10
        }}
        animate={{
          opacity: areFiltersOpen ? 1 : 0,
          y: areFiltersOpen ? 0 : -10,
          pointerEvents: areFiltersOpen ? 'all' : 'none'
        }}
        transition={{
          duration: 0.1,
          delay: areFiltersOpen ? 0.05 : 0,
          type: 'tween',
          ease: 'easeOut'
        }}
      >
        <Stack direction={'row'} p={1} columnGap={1}>
          <TextField
            InputProps={{
              startAdornment: <SwapVert />
            }}
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value as 'Newest' | 'Oldest')}
            select
            sx={{ width: '250px' }}
            size={'small'}
            variant={'outlined'}
            placeholder={'Search'}
          >
            <MenuItem value={'Newest'}>{t('Newest first')}</MenuItem>
            <MenuItem value={'Oldest'}>{t('Oldest first')}</MenuItem>
          </TextField>
          <TextField
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    setTextFilter(textInput);
                  }}
                >
                  <Search />
                </IconButton>
              )
            }}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') setTextFilter(textInput);
            }}
            size={'small'}
            variant={'outlined'}
            placeholder={'Search'}
          />
          {textFilter !== '' && (
            <Button
              size="small"
              color="error"
              onClick={() => {
                setTextFilter('');
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Close color="error" fontSize="small" /> {t('Clear text filter')}
            </Button>
          )}
        </Stack>
      </motion.div>
    </motion.div>
  );
}
