import { Box, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AdminHeader from 'src/components/AdminHeader';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { KnowledgeModels } from '../KnowledgeModels';
import AIModelsView from '../AIModels/views';
import { CreateKnowledgeModel } from '../KnowledgeModels/components/CreateUpdateKnowledgeModel';
import AIModelsForm from '../AIModels/views/components/forms/AIModelsForm';
import { AIModel } from '../AIModels/models/AIModels';
import { initialAiModel } from '../AIModels/views/utils/constants';
import {
  checkReadIntentionModelsPermissions,
  checkReadKnowledgeModelsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { useFeaturesContext } from 'src/contexts/CompanyFeaturesContext/CompanyFeaturesContext';
import {
  checkKnowledgeModelsFeature,
  checkNluFeature
} from 'src/contexts/CompanyFeaturesContext/utils/checkFeatures';
import { getAddButtonNameByTab } from '../utils/getAddButtonName';
import { getPermissionsCheckerFnByTab } from '../utils/getPermissionsCheckerFnByTab.ts';

export enum ModelsTabs {
  KNOWLEDGE_MODELS_TAB = 'knowledgeModels',
  INTENTION_MODELS_TAB = 'intentionModels',
  ENTITIES_TAB = 'entities'
}

export const ModelsView: React.FC = () => {
  const { hasAccess, loading } = useContext(PermissionsContext);
  const { hasFeature } = useFeaturesContext();

  const { t } = useTranslation();

  const [_, setUrlSearchParams] = useSearchParams();
  const handleTabsChange = (e, value) => {
    setUrlSearchParams('');
    setTabValue(value);
  };

  const [currentAiModel, setCurrentAiModel] = useState<AIModel>(initialAiModel);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const tabs = useMemo(() => {
    const hasNluFeature = hasFeature(checkNluFeature);
    const hasKnowledgeModelsFeature = hasFeature(checkKnowledgeModelsFeature);

    const tabs = [] as {
      label: string;
      value: ModelsTabs;
      disabled: boolean;
    }[];

    if (hasNluFeature) {
      tabs.push({
        label: t('Intention models'),
        value: ModelsTabs.INTENTION_MODELS_TAB,
        disabled: !hasAccess(checkReadIntentionModelsPermissions)
      });
    }

    if (hasKnowledgeModelsFeature) {
      tabs.push({
        label: t('Knowledge models'),
        value: ModelsTabs.KNOWLEDGE_MODELS_TAB,
        disabled: !hasAccess(checkReadKnowledgeModelsPermissions)
      });
    }

    return tabs;
  }, [t, hasAccess, hasFeature, loading]);

  const getFirstEnabledTabValue = () => {
    const firstEnabledTab = tabs.find((tab) => !tab.disabled);
    return firstEnabledTab?.value;
  };

  const [tabValue, setTabValue] = useState<ModelsTabs>(getFirstEnabledTabValue);

  useEffect(() => {
    setTabValue(getFirstEnabledTabValue());
  }, [tabs]);

  const buttonName = t(getAddButtonNameByTab(tabValue));

  const permissionsChecker = getPermissionsCheckerFnByTab(tabValue);

  return (
    <>
      <Helmet>
        <title>{t('Models')}</title>
      </Helmet>
      <AdminHeader
        heading={t('Models')}
        subheading={t(
          'All aspects related to AI models can be managed from this page'
        )}
        buttonLabel={buttonName}
        onClick={() => {
          setIsFormOpen(true);
        }}
        permissionCheckerFn={permissionsChecker}
      />
      {tabValue === ModelsTabs.INTENTION_MODELS_TAB && (
        <AIModelsForm
          handleClose={() => {
            setCurrentAiModel(initialAiModel);
            setIsFormOpen(false);
          }}
          currentAiModel={currentAiModel}
          setCurrentAiModel={setCurrentAiModel}
          isOpen={isFormOpen}
          setOpen={setIsFormOpen}
        />
      )}
      {tabValue === ModelsTabs.KNOWLEDGE_MODELS_TAB && (
        <CreateKnowledgeModel
          isOpen={isFormOpen}
          onClose={() => setIsFormOpen(false)}
        />
      )}

      <Box px={4} pt={4} pb={2}>
        <Tabs
          onChange={handleTabsChange}
          value={tabValue}
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              disabled={tab.disabled}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Box>
      {tabValue === ModelsTabs.INTENTION_MODELS_TAB && (
        <AIModelsView
          currentAiModel={currentAiModel}
          setCurrentAiModel={setCurrentAiModel}
        />
      )}
      {tabValue === ModelsTabs.KNOWLEDGE_MODELS_TAB && <KnowledgeModels />}
      {
        //tabValue === ModelsTabs.ENTITIES_TAB && <></>
      }
    </>
  );
};

export default ModelsView;
