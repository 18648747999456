import { Logout, RadioButtonChecked } from '@mui/icons-material';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { startRecordingCallDomain } from 'src/services/recordings/domain/startRecordingCallDomain';
import { stopRecordingCallDomain } from 'src/services/recordings/domain/stopRecordingCallDomain';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';

export const RecordCallButton = () => {
  const { barState, activeRecordings, setActiveRecordings } =
    useContext(CTIBarContext);
  const { isDragging } = barState;
  const theme = useTheme();

  const activeCall = getActiveRTCall(getConnectedRTUser());

  const recordingId = activeRecordings[activeCall?.id];

  return (
    <Tooltip title={t(recordingId ? 'Stop recording' : 'Record call')}>
      <IconButton
        id="cti-hangup-btn"
        style={{
          color: recordingId ? theme.colors.error.main : undefined
        }}
        onClick={() => {
          if (!isDragging) {
            if (recordingId) {
              stopRecordingCallDomain(activeCall, recordingId).then(() => {
                const newActiveRecordings = { ...activeRecordings };
                delete newActiveRecordings[activeCall?.id];
                setActiveRecordings(newActiveRecordings);
              });
              return;
            }
            startRecordingCallDomain(activeCall).then((response) => {
              setActiveRecordings({
                ...activeRecordings,
                [activeCall?.id]: response.recordingId
              });
            });
          }
        }}
      >
        <RadioButtonChecked fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};
