import { FormikValues } from 'formik';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';

export interface FormFields {
  Name?: ContactField[];
  Phone?: ContactField[];
  Email?: ContactField[];
  Custom?: ContactField[];
}

export const getContactFieldsFromForm = (
  fields: ContactField[],
  values: FormikValues
) => {
  return fields.reduce((fields, field: ContactField) => {
    return {
      ...fields,
      [field.id]: values[field.type]?.find((f) => f.name === field.name)?.value
    };
  }, {});
};

export const getInitialContactFormValuesFrom = (
  contactFields: ContactField[],
  contact?: Contact
): FormFields => {
  return contactFields
    ?.filter((f) => !f.autoGenerated)
    .reduce((contactFields, field) => {
      if (!contactFields[field.type]) {
        contactFields[field.type] = [];
      }
      if (contact?.fields?.[field.id]) {
        contactFields[field.type].push({
          type: field.type,
          name: field.name,
          value: contact.fields[field.id],
          mainField: field.mainField,
          id: field.id
        });
      } else {
        const value = setFakeFields(field, contact);
        if (value || field.type === 'Name' || field.required || field.pinned) {
          contactFields[field.type].push({
            type: field.type,
            name: field.name,
            value: value ?? '',
            mainField: field.mainField,
            id: field.id,
            pinned: field.pinned
          });
        }
      }

      return contactFields as ContactField;
    }, {});
};

const setFakeFields = (field: ContactField, contact: Contact) => {
  if (!field.mainField) return '';
  const type = field.type;
  const value = {
    Name: contact?.fields['Name'] || '',
    TelegramUsername: contact?.fields['User'] || '',
    Phone: contact?.fields['Phone'] || '',
    InstagramUsername: contact?.fields['InstagramUsername'] || '',
    Email: contact?.fields['Email'] || ''
  };

  return value[type] || '';
};

export const getValidFields = (fields: ContactField[]) => {
  return fields.filter(
    (field) =>
      field.name &&
      field.value &&
      field.name.trim() !== '' &&
      field.value.trim() !== ''
  );
};
