import { FieldArray } from 'formik';
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  IconButton,
  useTheme,
  Tooltip,
  Checkbox
} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import { useContactFields } from './useContactFields';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { MatchByForm } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatAccount';

interface ContactFieldsProps {
  fieldArrayName?: string;
  selectLabel?: string;
  inputLabel?: string;
  inputsSize?: 'medium' | 'small';
  buttonText?: string;
  contactGroupId: string;
  matchBy?: MatchByForm;
  setMatchBy?: Dispatch<SetStateAction<MatchByForm>>;
}

/**
 * Important: This component should be used within Formik component to utilize useFormikContext.
 */
export const ContactFields: React.FC<ContactFieldsProps> = ({
  fieldArrayName = 'fieldsArray',
  selectLabel = 'Type',
  inputLabel = 'Name',
  inputsSize = 'small',
  buttonText = 'Add Item',
  contactGroupId,
  setMatchBy,
  matchBy
}) => {
  const { t } = useTranslation();
  const { values, optionContactFields, hasErrors, selectedOptionsIds } =
    useContactFields({ fieldArrayName, contactGroupId });
  const theme = useTheme();

  if (!values) return;

  return (
    <FieldArray name={fieldArrayName}>
      {({ push, remove, form }) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              {values[fieldArrayName] &&
                values[fieldArrayName].map((item, index: number) => (
                  <Grid container spacing={1} marginTop={1} key={index}>
                    <Grid
                      item
                      xs={1}
                      justifyContent={'center'}
                      display={'flex'}
                      flexDirection={'column'}
                    >
                      {!(item.required && setMatchBy) && (
                        <Tooltip title={t('Delete field')}>
                          <IconButton
                            id={`${index}-initial-form-delete-button-id`}
                            onClick={() => {
                              remove(index);
                              if (
                                matchBy &&
                                matchBy?.contactFieldId === item.contactFieldId
                              ) {
                                setMatchBy(null);
                              }
                            }}
                            size="small"
                          >
                            <RemoveCircle
                              sx={{ color: theme.colors.error.main }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {setMatchBy && (
                        <Checkbox
                          checked={
                            matchBy?.contactFieldId === item.contactFieldId
                          }
                          id={`${index}-initial-form-checkbox-id`}
                          onChange={() => {
                            setMatchBy({
                              contactFieldId: item.contactFieldId,
                              index: index
                            });
                          }}
                          sx={{ p: '0' }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        select
                        id={`${fieldArrayName}.${index}.contactFieldId`}
                        name={`${fieldArrayName}.${index}.contactFieldId`}
                        label={t(selectLabel)}
                        value={item.contactFieldId}
                        defaultValue={item.contactFieldId}
                        onChange={(e) => {
                          form.setFieldValue(
                            `${fieldArrayName}.${index}.contactFieldId`,
                            e.target.value
                          );
                          const field = optionContactFields.find(
                            (f) => f?.contactFieldId === e.target.value
                          );
                          form.setFieldValue(
                            `${fieldArrayName}.${index}.required`,
                            field?.contactFieldRequired
                          );
                          form.setFieldValue(
                            `${fieldArrayName}.${index}.type`,
                            field?.contactFieldType
                          );
                          form.handleChange(e);
                        }}
                        required={true}
                        size={inputsSize}
                        fullWidth
                        error={Boolean(
                          form.errors.formFields?.[index]?.contactFieldId &&
                            form.errors?.formFields?.[index]
                        )}
                        helperText={t(
                          form.errors.formFields?.[index]?.contactFieldId
                        )}
                      >
                        {optionContactFields.map((option) => {
                          return (
                            <MenuItem
                              key={option?.contactFieldId}
                              value={option?.contactFieldId}
                              disabled={selectedOptionsIds.includes(
                                option?.contactFieldId
                              )}
                              onChange={(e) => {
                                console.log('Menu item ', e);
                              }}
                            >
                              {option?.contactFieldLabel}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id={`${fieldArrayName}.${index}.labelName`}
                        name={`${fieldArrayName}.${index}.labelName`}
                        label={t(inputLabel)}
                        value={item.labelName}
                        required={true}
                        onChange={form.handleChange}
                        size={inputsSize}
                        fullWidth
                        error={Boolean(
                          form.errors.formFields?.[index]?.labelName &&
                            form.errors?.formFields?.[index]
                        )}
                        helperText={t(
                          form.errors.formFields?.[index]?.labelName
                        )}
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                disabled={
                  hasErrors ||
                  values[fieldArrayName]?.length === optionContactFields.length
                }
                id="initial-form-add-button-id"
                variant="outlined"
                size={inputsSize}
                sx={{ marginTop: 1 }}
                onClick={() => {
                  if (values[fieldArrayName]?.length === 0) {
                    optionContactFields?.map((item) => {
                      if (item?.contactFieldRequired) {
                        values[fieldArrayName]?.push({
                          contactFieldId: item.contactFieldId,
                          labelName: '',
                          required: item?.contactFieldRequired,
                          type: item?.contactFieldType
                        });
                      }
                    });
                    push({
                      contactFieldId: '',
                      labelName: '',
                      required: false,
                      type: null
                    });
                  } else {
                    push({
                      contactFieldId: '',
                      labelName: '',
                      required: false,
                      type: null
                    });
                  }
                }}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  );
};
