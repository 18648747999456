import { RTCall } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';
import { WSMessage } from '../../../websocket/utils/notifications/dataMsgs';
import { updateRTCall } from '../../../rtcalls/domain/updateRTCall';
import { updateRTUser, updateRTUsers } from '../../domain/rtuser/updateRTUser';
import store from 'src/store/store';

export interface RTUserEventMessage extends WSMessage {
  userData: RTUser[];
}

export interface RTCallEventMessage extends WSMessage {
  callData: RTCall[];
}

/** -- RTUSER WS EVENT --
 *
 */
export const onRTUsersEvent = (message: RTUserEventMessage) => {
  if (message.userData.length > 1) {
    updateRTUsers(message.userData);
  } else {
    const rtUser = message.userData[0];
    if (!rtUser) return;
    updateRTUser(rtUser);
  }
};

/** -- RTCALL WS EVENT --
 *
 */
export const onRTCallsEvent = (message: RTCallEventMessage) => {
  if (store.getState().callData.notificationsOff) return;

  message.callData.forEach(updateRTCall);
};
