
//paths to embedded webchat, if you want to change its directory or name this should change too
export const EmbeddedWebChat = '/embeddedWebChat';
export const buildEmbeddedWebChat = '/webchatDialapplet.js';

export interface WebChatForm {
    required: boolean;
    contactFieldId: string;
    labelName: string;
    type?: 'Custom' | 'Email' | 'TelegramUsername' | 'Phone' | 'Name' | 'InstagramUsername';
    matchBy?: boolean;
  }

  export interface MatchByForm {
    contactFieldId: string;
    index: number;
  }

export interface WebChatAccount {
    id?: string;
    name: string;
    companyId: string;
    acceptPolicies: boolean;
    enabledInitialForm: boolean;
    initialForm: WebChatForm[],
    contactGroupId: string,
    emailAccountId?: string;
    styleId: string;
}