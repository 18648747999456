import axios from 'axios';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { CAMPAIGNS } from 'src/utils/apiRoutes/msUsers';
import { queryClient } from 'src/utils/queryClient';
import { CAMPAIGN_DRAFTS_KEY } from './getCampaigns';
import { v4 as uuidv4 } from 'uuid';

export const postCampaign = async (
  campaignOutbound: Campaign
): Promise<Campaign> => {
  const response = await axios.post(CAMPAIGNS, campaignOutbound);

  queryClient.invalidateQueries({
    queryKey: ['campaigns']
  });

  return response.data;
};

export const createCampaignDraftApi = async (campaign: Campaign) => {
  const resp = localStorage.getItem(CAMPAIGN_DRAFTS_KEY);
  const previousCampaignDrafts: Campaign[] = !!resp ? JSON.parse(resp) : [];
  const draftId = campaign?.draftId ?? uuidv4();
  const newCampaign: Campaign = { ...campaign, draftId };
  const newCampaignDrafts = [...previousCampaignDrafts, newCampaign];
  localStorage.setItem(CAMPAIGN_DRAFTS_KEY, JSON.stringify(newCampaignDrafts));
  return newCampaign;
};
