import axios from 'axios';
import { ContactFields, Contact } from 'src/modules/Contacts/models/contacts';
import { Note } from 'src/models/conversations/conversations';

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
const BASE_MS = process.env.REACT_APP_BASE_SOCIALMEDIA_MS as string;
const PATH = process.env.REACT_APP_API_NOTES as string;

export interface CreateContactNoteApiParam {
  conversationId: string;
  note: Note;
}

export const createNoteApi = async (noteParams: CreateContactNoteApiParam) => {
  const path = BASE_URL + BASE_MS + PATH;
  const data = noteParams.note;

  const params = {
    conversationId: noteParams.conversationId
  };

  return axios
    .post(path, data, { params })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      return error.response;
    });
};
