import { RTCall } from 'src/models/rtcall';
import { getRTQueue } from './getRTQueue';
import { getRTQueues } from './getRTQueues';
import { updateRTQueue } from './updateRTQueue';
import { updateRTQueues } from './updateAllRTQueues';
import { addRTQueueDomain } from './addRTQueue';
import { RTQueue } from 'src/models/queue';

/** ADD RTCALL TO QUEUE
 *
 */
export const addRTCallToQueue = (rtCall: RTCall) => {
  // If the call is not queued, nothing to be done
  if (rtCall.status !== 'Queued') return;

  const campaignId = rtCall.queueInfo.queueId;

  // Find RTUser owner of the call
  let rtQueue = getRTQueue({
    id: campaignId,
    shouldUseHook: false
  });

  if (!rtQueue) {
    addRTQueueDomain({
      id: campaignId,
      calls: [rtCall]
    } as RTQueue);

    return;
  }

  // Update or insert message in conversation
  rtQueue = {
    ...rtQueue,
    calls: [...rtQueue.calls.filter((c) => c.id !== rtCall.id), rtCall]
  };

  updateRTQueue(rtQueue);
};
