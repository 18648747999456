import { logger } from 'src/utils/logger';
import {
  updateTicketApi,
  updateTicketCommentApi
} from '../infrastructure/updateTicketsApi';
import {
  Ticket,
  TicketComment,
  TicketConversationSummary,
  TicketSubResolution
} from '../models/tickets';
import { assignAgentsToTicket } from './createTickets';
import { removeAgentsFromTicket } from './deleteTickets';
import { convertDateTimeTicketFieldValueToIsoString } from './getFormattedDateTimeTicketField';

export const updateTicket = async (
  ticket: Ticket,
  firstRescheduleTimestamp?: string
): Promise<{ ticket: Ticket; closedConvs: TicketConversationSummary[] }> => {
  const formattedFields = { ...ticket?.fields };
  Object.keys(formattedFields).forEach((keyId) => {
    formattedFields[keyId] = convertDateTimeTicketFieldValueToIsoString(
      formattedFields[keyId]
    );
  });
  const newTicket: Ticket = {
    ...ticket,
    fields: formattedFields,
    subResolutionId: ticket.subResolutionId ?? ticket.subResolution?.id
  };
  const resp = await updateTicketApi(newTicket, firstRescheduleTimestamp);
  return resp;
};

export const editTicketComment = async (
  comment: TicketComment,
  ticketId: string
) => {
  const resp = await updateTicketCommentApi(comment, ticketId);
  return resp;
};

export const udpateAgentsAssignedToTicket = async (
  currentTicket: Ticket,
  previousTicket?: Ticket
) => {
  if (!currentTicket?.id) {
    logger.error('The currentTicket must include id');
    throw new Error('The currentTicket must include id');
  }
  if (
    !currentTicket?.assignedUserIds ||
    (Object.keys(currentTicket?.assignedUserIds)?.length === 0 &&
      previousTicket?.assignedUserIds) ||
    Object.keys(previousTicket?.assignedUserIds)?.length === 0
  ) {
    return;
  }

  let userIdsToAdd: string[] = [];
  let userIdsToDelete: string[] = [];

  Object.keys(currentTicket?.assignedUserIds)?.forEach((id) => {
    if (!Object.keys(previousTicket?.assignedUserIds).includes(id)) {
      userIdsToAdd.push(id);
    }
  });
  Object.keys(previousTicket?.assignedUserIds)?.forEach((id) => {
    if (!Object.keys(currentTicket?.assignedUserIds)?.includes(id)) {
      userIdsToDelete.push(id);
    }
  });

  if (userIdsToAdd.length > 0) {
    await assignAgentsToTicket(currentTicket, userIdsToAdd);
  }
  if (userIdsToDelete.length > 0) {
    await removeAgentsFromTicket(currentTicket, userIdsToDelete);
  }
};
