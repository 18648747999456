import { RTCall } from 'src/models/rtcall';
import { getRTQueue } from './getRTQueue';
import { updateRTQueue } from './updateRTQueue';

/** REMOVE RTCALL TO QUEUE
 *
 */
export const removeRTCallFromQueue = (rtCall: RTCall) => {
  // Find Queue with this RTCall (if any)
  let rtQueue = getRTQueue({ rtCall, shouldUseHook: false });
  if (!rtQueue) return;

  const newCallsList = rtQueue.calls.filter((c) => c.id !== rtCall.id);
  if (newCallsList.length === rtQueue.calls.length) return;

  // Remove call from Queue
  rtQueue = {
    ...rtQueue,
    calls: [...newCallsList]
  };

  updateRTQueue(rtQueue);
};
