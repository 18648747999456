import {
  useTheme,
  Box,
  PopoverOrigin,
  Stack,
  Tabs,
  Tab,
  styled,
  Tooltip
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useContext } from 'react';

import { DialPad } from './DialPad';
import { Contacts } from './ContactsTab/Contacts';
import { CTIBarContext, CTIPopoverNames } from 'src/contexts/CTIBarContext';
import { CtiPopover } from '../../CtiPopover';
import { useTranslation } from 'react-i18next';
import { ConversationHistoryCTI } from './recents/ConversationHistoryCTI';
import { getMissedCallsCountDomain } from 'src/services/rtusers/domain/rtcall/getMissedCallsCountDomain';

interface DevicesMenuProps {
  transformOrigin?: PopoverOrigin;
  anchorOrigin?: PopoverOrigin;
  isDragging?: boolean;
}

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    .MuiTabs-indicator {
      min-height: 4px;
      height: 4px;
      box-shadow: none;
      border: 0;
    }

    .MuiTab-root {
      &.MuiButtonBase-root {
        padding: 0;
        margin-right: ${theme.spacing(3)};
        font-size: ${theme.typography.pxToRem(16)};
        color: ${theme.colors.alpha.black[50]};

        .MuiTouchRipple-root {
          display: none;
        }
      }

      &.Mui-selected:hover,
      &.Mui-selected {
        color: ${theme.colors.alpha.black[100]};
      }
    }
  `
);

export const NewConversation: React.FC<DevicesMenuProps> = ({}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { activeTab, setActiveTab } = useContext(CTIBarContext);

  const missedCalls = getMissedCallsCountDomain(true);

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  return (
    <CtiPopover
      title={CTIPopoverNames.NEW_CONVERSATION}
      name={'newConversation'}
      icon={
        <Tooltip title={t('New conversation')}>
          <IconButton
            id="new-conversation-button"
            sx={{
              boxShadow: `${theme.colors.shadows.primary}`,
              background: `${theme.colors.secondary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.primary.main
              )}`,
              marginRight: '5px',

              '&:hover': {
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main
                )}`
              }
            }}
            color="primary"
          >
            {missedCalls.length > 0 && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -5,
                  right: -5,
                  width: 20,
                  height: 20,
                  background: 'red',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '12px'
                }}
              >
                {missedCalls.length}
              </Box>
            )}
            <Add fontSize="medium" />
          </IconButton>
        </Tooltip>
      }
    >
      <Box minWidth={'456px'} overflow="hidden">
        <Stack
          paddingTop={1}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <TabsContainerWrapper
            sx={{
              paddingLeft: 2
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabs}
              textColor="primary"
              indicatorColor="secondary"
            >
              <Tab label={t('Contacts')} value={'contacts'} />
              <Tab label={'DialPad'} value={'dialpad'} />
              <Tab label={t('Recents')} value={'recent'} />
              {missedCalls.length > 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    width: 15,
                    height: 15,
                    background: 'red',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '13px'
                  }}
                >
                  {missedCalls.length}
                </Box>
              )}
            </Tabs>
          </TabsContainerWrapper>
          {activeTab === 'dialpad' && <DialPad />}
          {activeTab === 'contacts' && <Contacts />}
          {activeTab === 'recent' && <ConversationHistoryCTI />}
        </Stack>
      </Box>
    </CtiPopover>
  );
};
