import axios from 'axios';
import { PROMPT_ELEMENT_ID } from 'src/utils/apiRoutes/msAcd';
import { replaceURLParams } from 'src/services/utils';

export async function deletePromptElementApi(id: string) {
  const path = replaceURLParams(PROMPT_ELEMENT_ID, {
    '{id}': id
  });
  const response = await axios.delete(path);
  return response.data;
}
