import axios from 'axios';
import { ReadMessagesApiParams } from 'src/services/telegram/infrastructure/message/readTelegramMessagesApi';
import { WEBCHAT_MESSAGE_READ } from 'src/utils/apiRoutes/msWebChat';

export const readWebChatMessageApi = async (params: ReadMessagesApiParams) => {
  const { messageIds, ...restParams } = params;

  const response = await axios.post(WEBCHAT_MESSAGE_READ, messageIds, {
    params: restParams
  });

  if (response.status !== 200) {
    throw new Error('Error reading messages');
  }

  return response.data;
};
