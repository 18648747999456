import axios, { AxiosResponse } from 'axios';
import { GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION_ID } from 'src/utils/apiRoutes/msGenerativeAI';
import { KnowledgeDocumentation } from './getKnowledgeDocumentationApi';
import { replaceURLParams } from 'src/services/utils';

export const deleteKnowledgeDocumentationApi = async (id: string) => {
  const path = replaceURLParams(GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION_ID, {
    '{id}': id
  });
  const response: AxiosResponse<KnowledgeDocumentation> =
    await axios.delete(path);
  return response.data;
};
