import { emitCustomEvent } from 'react-custom-events';
import { logger } from 'src/utils/logger';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

export const statusMsgs = (client: W3CWebSocket) => {
  client.onerror = (err): void => {
    logger.error('WS ERROR: ', err);
    emitCustomEvent('webrtc-connection-changed', false);
  };
};
