import { Box, Skeleton, Stack } from '@mui/material';

export const TicketDataViewSkeleton = () => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
        <Box sx={{ margin: 1 }}>
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Skeleton
            width="80%"
            variant="rectangular"
            sx={{ borderRadius: '10px' }}
          />
        </Box>
      </Box>
      <Skeleton
        variant="rectangular"
        height="48px"
        sx={{ borderRadius: '10px' }}
      />
      <Stack p={1} spacing={1}>
        <Skeleton
          variant="rectangular"
          height="180px"
          sx={{ borderRadius: '10px' }}
        />
      </Stack>
      <Skeleton
        variant="rectangular"
        height="48px"
        sx={{ borderRadius: '10px' }}
      />
      <Stack p={1} spacing={1}>
        <Skeleton
          variant="rectangular"
          height="400px"
          sx={{ borderRadius: '10px' }}
        />
      </Stack>
    </Box>
  );
};
