import {
  AvatarGroup,
  Box,
  CircularProgress,
  Divider,
  ListItem,
  styled,
  Typography
} from '@mui/material';
import { Conversation } from 'src/models/conversations/conversations';
import { getContactsFromConversationQuery } from 'src/services/socialmedia/application/query/getContactsQuery';
import AvatarAccordion from './AvatarAccordion';

interface RowListItemPropTypes {
  label: string;
  conversation: Conversation;
}

const DividerContrast = styled(Divider)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
  `
);

const RowListItemAvatarGroup: React.FC<RowListItemPropTypes> = ({
  label,
  conversation
}) => {
  const listContactsQuery = getContactsFromConversationQuery(
    conversation,
    conversation
  );

  if (listContactsQuery.isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />;
      </Box>
    );
  }

  return (
    <>
      <ListItem disableGutters>
        <Box
          sx={{
            flexGrow: 0,
            flexBasis: '50%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              pb: 0.6,
              width: '150px'
            }}
            align="left"
            color="text.primary"
            variant="h5"
          >
            {label + ':'}
          </Typography>
          <Box
            display="flex"
            alignItems="flex-start"
            sx={{ pb: '5.4px', pl: '25px' }}
          >
            <AvatarGroup max={4}>
              {!listContactsQuery.isLoading &&
                listContactsQuery.data?.map((contact) => (
                  <AvatarAccordion
                    contact={contact}
                    key={contact?.id + 'Key'}
                  />
                ))}
            </AvatarGroup>
          </Box>
        </Box>
        <DividerContrast />
      </ListItem>
    </>
  );
};

export default RowListItemAvatarGroup;
