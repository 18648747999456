import { RTUser } from 'src/models/rtUser';
import { deleteRTUserStore } from '../../infraestructure/store/deleteRTUserStore';
import {
  setRTUserStore,
  setRTUsersStore
} from '../../infraestructure/store/setRTUserStore';
import { getRTUser } from './getRTUser';
import { setUserConversations } from 'src/services/conversations/domain/setConversations';

/** UPDATE RTUSER
 *
 * @param rtuser RTUser ALREADY ALTERED.
 */
export const updateRTUser = async (rtuser: RTUser) => {
  let storedRTUser = getRTUser({ id: rtuser.id, shouldUseHook: false });
  // If RTUser comes without the calls attribute, copy it from already stored user
  if (!rtuser.calls) {
    rtuser.calls = storedRTUser?.calls || [];
  }
  if (!rtuser.conversationIds) {
    rtuser.conversationIds = storedRTUser?.conversationIds || [];
  }

  if (rtuser.currentStatus.type === 'Disconnected') {
    deleteRTUserStore(rtuser);
    return;
  }

  setRTUserStore(rtuser);

  if (!storedRTUser) {
    setUserConversations([rtuser.id]);
  }
};

export const updateRTUsers = async (rtusers: RTUser[]) => {
  let newRTUserIds = [];
  rtusers = rtusers.map((rtuser) => {
    let storedRTUser = getRTUser({ id: rtuser.id, shouldUseHook: false });
    if (!rtuser.calls) {
      rtuser.calls = storedRTUser?.calls || [];
    }
    if (!rtuser.conversationIds) {
      rtuser.conversationIds = storedRTUser?.conversationIds || [];
    }

    if (!storedRTUser) {
      newRTUserIds.push(rtuser.id);
    }

    return rtuser;
  });
  setRTUsersStore(rtusers);
  setUserConversations(newRTUserIds);
};
