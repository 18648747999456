import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { Dashboard } from '../../models/Dashboards';
import { REALTIME_DASHBOARDS_ID } from 'src/utils/apiRoutes/msFlow';

export const deleteDashboard = async (
  dashboardId: string
): Promise<Dashboard> => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_ID, {
    '{id}': dashboardId,
  });
  const response: AxiosResponse<Dashboard> = await axios.delete(path);

  return response.data;
};
