import { motion } from 'framer-motion';
import { $isLinkNode, CustomLinkNode } from '../LinkNode';
import {
  Button,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Close, Delete, Edit } from '@mui/icons-material';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNodeByKey } from 'lexical';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';

export function LinkNodeRenderer({ node }: { node: CustomLinkNode }) {
  const [open, setOpen] = useState(false);
  const [editor] = useLexicalComposerContext();
  const ref = useRef<HTMLDivElement>(null);
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(
    node.getKey()
  );

  const [payload, setPayload] = useState({
    url: node.getUrl(),
    label: node.getLabel()
  });

  const modifyLink = () => {
    editor.update(() => {
      const n = $getNodeByKey(node.getKey());
      if ($isLinkNode(n)) {
        n.setUrl(payload.url);
        n.setLabel(payload.label);
      }
    });

    setOpen(false);
  };

  function handleDelete() {
    editor.update(() => {
      node.remove();
    });

    setOpen(false);
  }

  return (
    <>
      <Tooltip
        title={
          <Stack direction="row" gap={1} alignItems="center">
            <Edit fontSize="small" sx={{ fontSize: '16px' }} />
            <Typography fontSize="14px">{t('Modify link')}</Typography>
          </Stack>
        }
        arrow
        placement="top"
      >
        <motion.div
          ref={ref}
          animate={
            isSelected || open
              ? {
                  background: '#e0e0e0'
                }
              : {
                  background: '#FFFFFF'
                }
          }
          whileHover={{
            background: '#e0e0e0'
          }}
          style={{
            background: '#FFFFFF',
            width: 'fit-content',
            padding: '2px 4px',
            borderRadius: '4px',
            display: 'inline-block'
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }}
        >
          <a href={node.url} target="_blank" rel="noopener noreferrer">
            {node.label}
          </a>
        </motion.div>
      </Tooltip>

      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <CardHeader
          title={t('Modify link')}
          action={
            <IconButton onClick={() => setOpen(false)} size="small">
              <Close fontSize="small" />
            </IconButton>
          }
        />
        <Divider />
        <Stack p={1} gap={1}>
          <TextField
            label="URL"
            value={payload.url}
            onChange={(e) => setPayload({ ...payload, url: e.target.value })}
            size="small"
            required
          />
          <TextField
            label={t('Label')}
            value={payload.label}
            onChange={(e) => setPayload({ ...payload, label: e.target.value })}
            size="small"
          />

          <Button
            startIcon={<Edit />}
            fullWidth
            onClick={modifyLink}
            variant="contained"
            color="secondary"
            size="small"
          >
            {t('Modify')}
          </Button>
          <Button
            startIcon={<Delete />}
            fullWidth
            onClick={handleDelete}
            variant="contained"
            color="error"
            size="small"
          >
            {t('Delete')}
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
