import {
  Avatar,
  Badge,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { Contact } from 'src/modules/Contacts/models/contacts';
import { Conversation } from 'src/models/conversations/conversations';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
	  width: ${theme.spacing(6)};
	  height: ${theme.spacing(6)};
  `
);

interface UserAvatarNameProps {
  contact?: Contact;
  conversation?: Conversation;
}

const UserAvatarName = (props: UserAvatarNameProps) => {
  const theme = useTheme();

  const { contact, conversation } = props;

  const nameContactFieldsQuery = useGetContactFields(
    {
      companyId: conversation.companyId,
      type: 'Name',
      contactGroupId: contact.contactGroupId
    },
    !!conversation
  );
  const contactNameKey = nameContactFieldsQuery.data.elements[0];
  const contactName = contact.fields[contactNameKey.id];
  const contactLabel = contact.label;

  return (
    <>
      <ListItem
        sx={{
          borderRadius: 0,
          ml: 2,
          pb: 1
        }}
      >
        <ListItemAvatar>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            overlap="circular"
          >
            <AvatarWrapper alt={contactName && contactName}>
              <Typography variant="h3" color="white">
                {contactName && contactName.charAt(0)}
              </Typography>
            </AvatarWrapper>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" gutterBottom noWrap>
              {contactLabel && contactLabel.value}
            </Typography>
          }
          secondary={<Typography variant="subtitle2"></Typography>}
          sx={{ ml: theme.spacing(1.5) }}
        />
      </ListItem>
    </>
  );
};
export default UserAvatarName;
