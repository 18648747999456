import { ContactTag } from 'src/modules/Contacts/models/contacts';
import EmailPanel from '../EmailPanel';


export const RtCallStatusList = [
  'Preparing',
  'Ringing',
  'Talking',
  'Finished',
  'Parked',
  'Queued'
];

export enum TAG_CUSTOM_TEXT {
  CREATE_TAG_ID = 'create-tag'
}
