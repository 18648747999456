import axios, { AxiosResponse } from 'axios';
import { GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION } from 'src/utils/apiRoutes/msGenerativeAI';
import qs from 'qs';
import { queryClient } from 'src/utils/queryClient';

export interface KnowledgeDocumentation {
  id?: string;
  _id?: string;
  name: string;
  companyId: string;
  status: KnowledgeDocumentationStatus;
  webpage: string;
  contentLabel: string;
  removeLabels: string[];
}

export interface KnowledgeDocumentationFilters {
  companyId: string;
  name?: string;
  webpage?: string;
  status?: KnowledgeDocumentationStatus;
}

export enum KnowledgeDocumentationStatus {
  PENDING = 'Pending',
  SCRAPING = 'Scraping',
  CREATED = 'Created',
  FAILED = 'Failed'
}

export const getKnowledgeDocumentationApi = async (
  filter: KnowledgeDocumentationFilters
) => {
  const params = { ...filter };

  const paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };
  const response: AxiosResponse<KnowledgeDocumentation[]> = await axios.get(
    GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION,
    {
      params,
      paramsSerializer
    }
  );
  return response.data;
};
