import {
  DateOperator,
  IntegerOperator,
  Operator,
  StringOperator
} from '../models/filters';

export const getOperatorLabel = (operator: Operator) => {
  switch (operator) {
    case IntegerOperator.EQUALS:
      return '=';
    case IntegerOperator.NOT_EQUALS:
      return '≠';
    case IntegerOperator.GREATHER_THAN:
      return '>';
    case IntegerOperator.GREATHER_THAN_OR_EQUALS:
      return '≥';
    case IntegerOperator.LESS_THAN:
      return '<';
    case IntegerOperator.LESS_THAN_OR_EQUALS:
      return '≤';
    case DateOperator.BETWEEN:
      return 'between';
    case DateOperator.NOT_NULL:
      return 'not null';
    case StringOperator.CONTAINS:
      return 'contains';
    default:
      return operator;
  }
};
