import { Box, Stack } from '@mui/material';
import { VideocallActionBar } from '../../ActionsBar';
import { TimeCounter } from '../TimeCounter/TimeCounter';
import { useContext, useEffect, useRef, useState } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { VideoCardMobile } from './VideoCardMobile';

export type StreamInFullScreen = 'none' | 'local' | 'remote';

function InCallMobile() {
  const { videoRoom, streams } = useContext(VideocallRoomContext);

  const [streamInFullScreen, setStreamInFullScreen] = useState(
    'none' as StreamInFullScreen
  );

  const boxRef = useRef();

  return (
    <Stack
      direction={'column'}
      height={'100svh'}
      width={'100vw'}
      maxHeight={'100vh'}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      <Box
        ref={boxRef}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          width: '100%',
          padding: '9px',
          rowGap: '9px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden', // Esto asegura que nada se salga del contenedor,
          position: 'relative'
        }}
      >
        <VideoCardMobile
          stream={streams.remote}
          label={'remote'}
          ref={boxRef}
          streamInFullScreen={streamInFullScreen}
          setStreamInFullScreen={setStreamInFullScreen}
        />
        <VideoCardMobile
          stream={streams.local}
          label={'local'}
          ref={boxRef}
          streamInFullScreen={streamInFullScreen}
          setStreamInFullScreen={setStreamInFullScreen}
        />
      </Box>
      <Box sx={{ width: '100%', px: 1 }}>
        <VideocallActionBar />
      </Box>
      {videoRoom.room.startCall && (
        <TimeCounter initialTime={Date.parse(videoRoom.room.startCall)} />
      )}
    </Stack>
  );
}

export default InCallMobile;
