import { useContext, useState } from 'react';
import {
  ContactFieldType,
  ContactFieldTypeNames,
  ContactUI,
  contactGroupFieldTypeOptions
} from '../../models/contacts';
import { IconButton, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import {
  checkDeleteContactPermissions,
  checkReadContactPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { ColumnDef, Row } from '@tanstack/react-table';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { ContactsDomain } from '../../domain/ContactsDomain';
import { useAlert } from 'src/hooks/useAlert';
import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import {
  getOption,
  getOptions,
  getPaginatedElements,
  getPaginatedOptions
} from 'src/components/DialTable/utils/getOptionValues';
import {
  getCompanyContactTagsQuery,
  getContactTagByIdQuery
} from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import { contactsRequestParams } from '../utils/contactsRequestParams';
import { getContactsUI } from '../utils/getContactsUI';
import AuthManager from 'src/services/authentication/manager';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { emitCustomEvent } from 'react-custom-events';
import {
  DialTableBulkActionsContext,
  DialTableDataContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';

export const useContactActionButtons = () => {
  const { hasAccess } = useContext(PermissionsContext);
  const companyId = AuthManager.getLoggedUserCompanyId();
  const { showAlert } = useAlert();
  const [selectedContact, setSelectedContact] = useState<ContactUI>(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [contactsToDelete, setContactsToDelete] = useState<ContactUI[]>([]);
  const [selectedRows, setSelectedRows] = useState<Row<ContactUI>[]>([]);

  const { t } = useTranslation();

  const onCloseEditModal = () => setIsOpenEditModal(false);

  const getData = async ({
    filtersObject,
    paginationValues
  }: DialTableDataContext<ContactUI>) => {
    contactsRequestParams.page = paginationValues.page;
    contactsRequestParams.size = paginationValues.size;
    contactsRequestParams.contactGroupId = filtersObject['contactGroupId']
      ? (filtersObject['contactGroupId']?.[0] as string)
      : undefined;
    contactsRequestParams.fieldType = filtersObject['fieldType']
      ? (filtersObject['fieldType']?.[0] as ContactFieldType)
      : undefined;
    contactsRequestParams.fieldValue = filtersObject['fieldValue']
      ? (filtersObject['fieldValue']?.[0] as string)
      : undefined;
    contactsRequestParams.tags = filtersObject['tags']
      ? (filtersObject['tags'] as string[])
      : undefined;
    const paginatedContacts = await getContactsUI(
      companyId,
      contactsRequestParams
    );
    return paginatedContacts;
  };

  const getColumns: () => Promise<ColumnDef<ContactUI>[]> = async () => [
    {
      accessorKey: 'contactGroupName',
      id: 'contactGroupName',
      header: t('Contact group')
    },
    {
      accessorKey: 'name',
      id: 'name',
      header: t('Name')
    },
    {
      accessorKey: 'phone',
      id: 'phone',
      header: t('Phone')
    },
    {
      accessorKey: 'email',
      id: 'email',
      header: t('Email')
    },
    {
      accessorKey: 'telegram',
      id: 'telegram',
      header: t('Telegram')
    },
    {
      accessorKey: 'displayedTags',
      id: 'displayedTags',
      header: t('Tags')
    }
  ];

  const getRowActions = ({
    rowData: contact
  }: DialTableRowActionsContext<ContactUI>) => [
    <IconButton
      key="editContact"
      title={t('See contact details')}
      size="small"
      color="secondary"
      onClick={() => {
        setSelectedContact(contact);
        setIsOpenEditModal(true);
      }}
      disabled={!hasAccess(checkReadContactPermissions)}
    >
      <VisibilityIcon fontSize="small" />
    </IconButton>,
    <IconButton
      key="deleteContact"
      title={t('Delete contact')}
      size="small"
      color="error"
      onClick={() => {
        setContactsToDelete([contact]);
        setIsOpenDeleteModal(true);
      }}
      disabled={!hasAccess(checkDeleteContactPermissions)}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  ];

  const getBulkActions = ({
    selectedData: contacts,
    selectedRowModel
  }: DialTableBulkActionsContext<ContactUI>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          color="error"
          title={t('Delete contacts')}
          size="small"
          onClick={() => {
            setContactsToDelete([...contacts]);
            setIsOpenDeleteModal(true);
            setSelectedRows(selectedRowModel.rows);
          }}
          disabled={!hasAccess(checkDeleteContactPermissions)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  const onDeleteContacts = async (contacts: ContactUI[]) => {
    try {
      await ContactsDomain.deleteContacts(contacts);
      showAlert(
        t(
          `The contact${contacts.length > 1 ? 's' : ''} ${contacts
            .map((contact) => contact.name)
            .join(', ')} was successfully deleted`
        ),
        'success',
        2000
      );
      emitCustomEvent(REFETCH_DATA_EVENT_NAME);
    } catch (error) {
      showAlert(
        t(
          `The contact${contacts.length > 1 ? 's' : ''} ${contacts
            .map((contact) => contact.name)
            .join(', ')} could not be deleted`
        ),
        'error',
        2000
      );
    }
    selectedRows.map((row) => row.toggleSelected());
    setSelectedRows([]);
    setContactsToDelete([]);
    setIsOpenDeleteModal(false);
  };

  const getAvailableFilters = () => {
    const filters: DialTableFilter<ContactUI>[] = [
      {
        fieldType: FieldType.TEXT,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'fieldValue',
        label: t('Field value'),
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null
      },
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'contactGroupId',
        label: t('Contact group'),
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: DataCategory.CONTACT_GROUP_ID
      },
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'fieldType',
        label: t('Field type'),
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null,
        options: {
          getValueOptionListFn: () => {
            const paginatedElements = getPaginatedElements({
              elements: contactGroupFieldTypeOptions
            });
            const paginatedOptions = getPaginatedOptions({
              paginatedOptions: paginatedElements,
              labelExtractor: (fieldType) =>
                t(ContactFieldTypeNames[fieldType]),
              valueExtractor: (fieldType) => fieldType
            });
            return Promise.resolve(paginatedOptions);
          },
          getValueOptionFn: (value) => {
            const options = getOptions({
              options: contactGroupFieldTypeOptions,
              labelExtractor: (fieldType) =>
                t(ContactFieldTypeNames[fieldType]),
              valueExtractor: (fieldType) => fieldType
            });
            const option = options.find((option) => option.value === value);
            return Promise.resolve(option);
          }
        }
      },
      {
        fieldType: FieldType.MULTI_SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'tags',
        label: t('Tags'),
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null,
        options: {
          getValueOptionListFn: async () => {
            const elements = (
              await getCompanyContactTagsQuery({
                companyId
              })
            )?.elements;
            const paginatedElements = {
              currentPage: 0,
              totalPages: 1,
              totalItems: elements.length,
              elements
            };
            const paginatedOptions = getPaginatedOptions({
              paginatedOptions: paginatedElements,
              labelExtractor: 'name',
              valueExtractor: 'id'
            });
            return Promise.resolve(paginatedOptions);
          },
          getValueOptionFn: async (value) => {
            const contactGroupById = await getContactTagByIdQuery(value);
            const option = getOption({
              option: contactGroupById,
              labelExtractor: (tags) => t(tags.name),
              valueExtractor: (tags) => tags.id
            });
            return Promise.resolve(option);
          }
        }
      }
    ];
    return Promise.resolve(filters);
  };

  return {
    isOpenEditModal,
    onCloseEditModal,
    isOpenDeleteModal,
    selectedContact,
    setIsOpenEditModal,
    setIsOpenDeleteModal,
    setSelectedContact,
    contactsToDelete,
    getColumns,
    getRowActions,
    getBulkActions,
    onDeleteContacts,
    getAvailableFilters,
    getData
  };
};
