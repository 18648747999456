import axios from 'axios';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { replaceURLParams } from 'src/services/utils';
import { USERS_MS } from 'src/utils/apiRoutes/msUsers';

const DA_SERVICES = process.env.REACT_APP_DA_SERVICES as string;
const DA_SERVICE_ID = process.env.REACT_APP_DA_SERVICE_ID as string;

export const getDaServices = () => {
  const path = USERS_MS + DA_SERVICES;

  return axios
    .get(path)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getDaServiceById = async (id: string) => {
  if (!id) return null;
  const path = replaceURLParams(USERS_MS + DA_SERVICE_ID, {
    '{id}': id
  });

  const response = await axios.get(path);

  return response.data as DaService;
};
