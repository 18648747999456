import { Contact } from 'src/modules/Contacts/models/contacts';
import { ShowflowTextBlock } from '../../../../models/showflow';
import { useEffect, useMemo, useState } from 'react';
import ShowflowInlineVariable from './ShowflowInlineVariable';
import { Ticket } from '../../../../models/tickets';
import { Skeleton } from '@mui/material';
import { checkTextBlockConditions } from '../domain/checkTextblockCondition';
import { AnimatePresence, motion } from 'framer-motion';

interface ShowflowTextBlockProps {
  textBlock: ShowflowTextBlock;
  contact: Contact;
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
}

export function ShowflowTextBlockRenderer({
  textBlock,
  contact,
  ticket,
  setTicket
}: ShowflowTextBlockProps) {
  const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);

  const styledText = useMemo(() => {
    if (!textBlock) return <></>;

    const text = textBlock.text;
    const regex = /\$[^$]*\$/g;
    let match: RegExpExecArray;
    let lastIndex = 0;
    const newText = [];

    function spanElement(text: string) {
      // Se utiliza dangerouslySetInnerHTML para poder escapar caracteres especiales como \n, -&gt; (->), etc...
      return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    while ((match = regex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        newText.push(spanElement(text.slice(lastIndex, match.index)));
      }

      const variableId = match[0].slice(1, -1);

      newText.push(
        <ShowflowInlineVariable
          variableId={variableId}
          contact={contact}
          ticket={ticket}
          setTicket={setTicket}
        />
      );

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < text?.length) {
      newText.push(spanElement(text.slice(lastIndex)));
    }

    return newText;
  }, [textBlock, contact, ticket]);

  useEffect(() => {
    checkTextBlockConditions({ contact, textBlock, ticket }).then((visible) => {
      setIsVisible(visible);
    });
  }, [contact, ticket]);

  // to avoid skeleton + text for a second
  const [skeletonHidden, setSkeletonHidden] = useState(false);

  return (
    <>
      <AnimatePresence onExitComplete={() => setSkeletonHidden(true)}>
        {isVisible === undefined && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.2,
              ease: 'easeOut'
            }}
          >
            <Skeleton
              sx={{
                width: `${Math.random() * 50 + 50}%`,
                height: `${Math.random() * 100}px`,
                minHeight: '40px',
                borderRadius: 1
              }}
              variant="rectangular"
            />
          </motion.div>
        )}
        {isVisible && skeletonHidden && (
          <p
            style={{
              height: 'max-content',
              whiteSpace: 'pre-wrap'
            }}
          >
            {styledText}
          </p>
        )}
      </AnimatePresence>
    </>
  );
}
