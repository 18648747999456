import { RTCall } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';
import WebRTCNotifications from 'src/services/webrtc/notifications/webrtc-notifications';
import { getConnectedRTUser } from '../rtuser/getConnectedRTUser';

import { getRTUser } from '../rtuser/getRTUser';
import { updateRTUser } from '../rtuser/updateRTUser';
import { getMissedCallsCountDomain } from './getMissedCallsCountDomain';
import { getMissedCallsDomain } from './getMissedCallsDomain';
import { setMissedCallsCountDomain } from './setMissedCallsCountDomain';
import { setMissedCallsDomain } from './setMissedCallsDomain';
import { getRTCSessions } from 'src/services/webrtc/domain/getRTCSessions';
import { getRTUsers } from '../rtuser/getRTUsers';
import { logger } from 'src/utils/logger';
import snackbar from 'src/utils/snackbar';
import { t } from 'i18next';

/** ADD MESSAGE TO CONVERSATION CONVERSATIONS
 *
 * @param filter Parameters to filter the currently stored conversations
 * @returns Array of conversations after applying the specified filters
 */
export const removeRTCallFromUsers = (rtCall: RTCall) => {
  if (rtCall.previousStatus === 'Conference') {
    removeConferenceCalls(rtCall);
    return;
  }

  let rtuser = getRTUser({ rtCall, shouldUseHook: false });
  if (!rtuser)
    return logger.error("Couldn't find any user for this RTCall: ", rtCall);

  hangupAlertIfParked(rtCall);
  handleMissedCall(rtuser, rtCall);

  // Update or insert rtcall in rtUser
  removeRTCallFromRTUser(rtCall, rtuser);
};

function removeRTCallFromRTUser(rtCall: RTCall, rtUser: RTUser) {
  rtUser = {
    ...rtUser,
    calls: [
      ...rtUser.calls
        .filter((call) => call.id !== rtCall.id)
        .filter((call) => call.id !== rtCall.linkedEndpoint.rtCallId)
    ]
  };
  updateRTUser(rtUser);
}

function removeConferenceCalls(rtCall: RTCall) {
  const rtusers = getRTUsers({ callId: rtCall.callId, shouldUseHook: false });

  rtusers.forEach((rtuser) => {
    removeRTCallFromRTUser(rtCall, rtuser);

    if (rtuser.id === rtCall.userId) {
      rtuser = {
        ...rtuser,
        calls: [
          ...rtuser?.calls.filter((call) => call.callId !== rtCall.callId)
        ]
      };
      updateRTUser(rtuser);
    }
  });
}

const handleMissedCall = (rtUser: RTUser, rtCall: RTCall) => {
  const connectedUser = getConnectedRTUser(false);

  //If the finised call is not from the connected user, we don't care
  if (!connectedUser || connectedUser.id !== rtUser.id) return;

  //The missed call must pass from ringing to finished
  const oldRtCall = rtUser.calls.find((c) => c.id === rtCall.id);
  if (!oldRtCall) return;
  if (oldRtCall?.status !== 'Ringing') return;

  let missedCallsCount = getMissedCallsCountDomain(false);
  missedCallsCount = missedCallsCount.concat(rtCall);
  setMissedCallsCountDomain(missedCallsCount);

  let missedCalls = getMissedCallsDomain(false);
  setMissedCallsDomain([...missedCalls, rtCall]);
};

function hangupAlertIfParked(rtCall: RTCall) {
  const connectedUser = getConnectedRTUser(false);
  if (!connectedUser) return;

  let oldCall = connectedUser.calls.find((c) => {
    //Find the call
    return c.id === rtCall.id;
  });

  let isCallFromConnectedUser = //Check if the call is from the connected agent
    oldCall?.linkedEndpoint?.endpoint ===
    connectedUser.extension?.complexExtension;

  if (oldCall?.status === 'Parked' && isCallFromConnectedUser) {
    WebRTCNotifications.hangUpCallAlert();
  }
}

export function finishedResultAlert(rtCall: RTCall) {
  const connectedUser = getConnectedRTUser(false);
  if (!connectedUser) return;

  let isCallFromConnectedUser = //Check if the call is from the connected agent
  rtCall?.linkedEndpoint?.endpoint ===
    connectedUser.extension?.complexExtension;

  if (!isCallFromConnectedUser) return;


  if (rtCall?.result === 'Busy') {
    return snackbar.toast(t('El destino al que se ha intentado llamar no está disponible.'), 'info');
  }
}