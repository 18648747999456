import { RTUser } from 'src/models/rtUser';
import { setNextOutgoingCall } from 'src/store/slices/calls/calls';
import { setRTUser } from 'src/store/slices/rtusers/rtuserSlices';
import store from 'src/store/store';

/** UPDATE PHONE NUMBER OF THE NEXT OUTGOING CALL
 *
 * @param phone Phone number of the next outgoing call
 */
export const setNextOutgoingCallStore = (phone: string) => {
  store.dispatch(setNextOutgoingCall(phone));
};
