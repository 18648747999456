import axios from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import {
  ASTERISK_MS,
  CALLS_ATTENDED_TRANSFER_COMPLETE
} from 'src/utils/apiRoutes/msAsterisk';

export const completeAttendedTransferCallApi: Function = (
  callId: string,
  complete: boolean,
  instance: string
) => {
  const completeAttendedTransferCallPath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) +
    CALLS_ATTENDED_TRANSFER_COMPLETE;

  const path = replaceURLParams(completeAttendedTransferCallPath, {
    '{id}': callId
  });
  const params = { complete };

  return axios
    .post(path, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
