import { useQuery } from '@tanstack/react-query';
import {
  getWhatsAppTemplateByIdApi,
  getWhatsAppTemplatesApi
} from '../../infrastructure/template/getWhatsAppTemplatesApi';
import {
  WhatsAppTemplateCategory,
  WhatsAppTemplateLanguage,
  WhatsAppTemplateType
} from '../../models/WhatsAppTemplate';
import { queryClient } from 'src/utils/queryClient';

export interface GetWhatsAppTemplateParams {
  accountId?: string;
  category?: WhatsAppTemplateCategory;
  companyId?: string;
  enabled?: boolean;
  facebookId?: string;
  id?: string;
  language?: WhatsAppTemplateLanguage;
  placeholders?: string[];
  providerId?: string;
  text?: string;
  type?: WhatsAppTemplateType;
}

export const getWhatsAppTemplates = async (
  params?: GetWhatsAppTemplateParams
) => {
  const resp = await getWhatsAppTemplatesApi(params);
  return resp;
};

export const getWhatsAppTemplateById = async (id: string) => {
  const resp = await getWhatsAppTemplateByIdApi(id);
  return resp;
};

export function useWhatsAppTemplateByIdQuery(id: string) {
  return useQuery({
    queryKey: ['whatsAppTemplateById', 'whatsAppTemplates', id],
    queryFn: () => getWhatsAppTemplateById(id),
    staleTime: 60 * 1000 * 5
  });
}

export function useWhatsAppTemplatesQuery(params?: GetWhatsAppTemplateParams) {
  return useQuery({
    queryKey: ['whatsAppTemplates', ...Object.values(params || {})],
    queryFn: () => getWhatsAppTemplates(params),
    staleTime: 60 * 1000 * 5
  });
}

export function getWhatsAppTemplatesQuery(params?: GetWhatsAppTemplateParams) {
  return queryClient.fetchQuery({
    queryKey: ['whatsAppTemplates', ...Object.values(params || {})],
    queryFn: () => getWhatsAppTemplates(params),
    staleTime: 60 * 1000 * 5
  });
}

export function invalidateWhatsAppTemplatesQuery() {
  queryClient.invalidateQueries({
    queryKey: ['whatsAppTemplates']
  });
}
