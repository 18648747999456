import { useQuery } from '@tanstack/react-query';
import {
  TicketTagsFilters,
  getTicketTagsApi,
  getTicketTagsByIdApi
} from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/tags/getTicketTagsApi';
import { queryClient } from 'src/utils/queryClient';
import { TicketTag } from '../../models/tickets';
import { TicketsDomain } from '../TicketsDomain';

export const getTicketTagById = (id: string) => {
  return getTicketTagsByIdApi(id);
};

export const getTicketTags = (filters: TicketTagsFilters) => {
  return getTicketTagsApi(filters);
};

export const useTicketTagsQuery = (filters: TicketTagsFilters) => {
  return useQuery<TicketTag[], Error>(
    ['ticketTags', filters?.companyId],
    () => TicketsDomain.getTicketTags(filters),
    { staleTime: 60000 }
  );
};

export const getTicketTagsQuery = (filters: TicketTagsFilters) => {
  return queryClient.fetchQuery(['ticketTags', filters], {
    queryFn: () => TicketsDomain.getTicketTags(filters),
    staleTime: 60000
  });
};
