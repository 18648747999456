import { useRef } from 'react';

/**
 * Guarantees that a func is only executed once within a set time interval (delay).
 * Subsequent calls are ignored until the interval (delay) has elapsed
 * @returns throttleFunction
 */
export const useThrottle = () => {
  const throttleSeed = useRef<NodeJS.Timeout | null>(null);

  const throttleFunctionRef = useRef((func: Function, delay = 200) => {
    if (!throttleSeed.current) {
      // Call the callback immediately for the first time
      func();
      throttleSeed.current = setTimeout(() => {
        throttleSeed.current = null;
      }, delay);
    }
  });

  return throttleFunctionRef.current;
};
