import { IconButton, List, MenuItem, Popover, Tooltip } from '@mui/material';
import { KeyboardArrowDown, KeyboardVoice, MicOff } from '@mui/icons-material';
import { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import { useVideocallMicrophone } from '../hooks/useVideocallMicrophone';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';

export const VoiceOutlinedSelect = (showIcon) => {
  const {
    devices: microphones,
    onSelectChange,
    toggleMute
  } = useVideocallMicrophone();

  const { devices } = useContext(VideocallRoomContext);
  const { microphoneDeviceId, microphoneMuted } = devices;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  return (
    <div
      style={{
        background: 'rgba(0,0,0,0.05)',
        borderRadius: '8px'
      }}
    >
      {showIcon && (
        <Tooltip
          title={
            microphoneMuted ? t('Turn on microphone') : t('Turn off microphone')
          }
        >
          <IconButton
            sx={{
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0'
            }}
            onClick={toggleMute}
          >
            {microphoneMuted ? <MicOff /> : <KeyboardVoice />}
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t('Select microphone')}>
        <IconButton
          ref={ref}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          sx={{
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            width: '30px'
          }}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Tooltip>

      <Popover
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        anchorReference="anchorEl"
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          {microphones.map((device, idx) => {
            return (
              <MenuItem
                key={idx}
                selected={device.deviceId === microphoneDeviceId}
                onClick={(): void => {
                  onSelectChange(device);
                  setIsOpen(false);
                }}
                value={device.label}
              >
                {device.label}
              </MenuItem>
            );
          })}
        </List>
      </Popover>
    </div>
  );
};
