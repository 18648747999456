import { RTCSession } from 'jssip/lib/RTCSession';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { getSessionsStore } from '../infrastructure/store/getSessions';
import { setSessionStore } from '../infrastructure/store/setSessions';

/** UPDATE CONVERSATION
 *
 * @param conversation Conversation object ALREADY ALTERED.
 */
export const removeRTCSession = (session: WebRTCSession) => {
  let storedSessions = getSessionsStore(false);
  storedSessions = [
    ...storedSessions.filter((s) => s.RTCSession.id !== session.RTCSession.id)
  ];
  setSessionStore(storedSessions);
};
