import axios, { AxiosResponse } from 'axios';
import { AIModelApi, AIModel } from '../models/AIModels';
import { replaceURLParams } from 'src/services/utils';
import { invalidateIntentionsModelsDataQuery } from '../views/hooks/getAIModelsQuery';
import { INTENTION_MODEL_ID } from 'src/utils/apiRoutes/msBasicNlu';

export const updateIntentionModelApi = async (
  id: string,
  params: AIModelApi
): Promise<AIModel> => {
  const path = replaceURLParams(INTENTION_MODEL_ID, {
    '{id}': id
  });

  const response: AxiosResponse<AIModel> = await axios.put(path, params);

  invalidateIntentionsModelsDataQuery();
  return response.data;
};
