import axios from 'axios';
import { ReadMessagesApiParams } from 'src/services/instagram/infrastructure/message/readInstagramMessageApi';
import { WHATSAPP_MESSAGE_READ } from 'src/utils/apiRoutes/msWhatsapp';

export const readWhatsAppMessageApi = async (params: ReadMessagesApiParams) => {
  const { messageIds, ...restParams } = params;

  const response = await axios.post(WHATSAPP_MESSAGE_READ, messageIds, {
    params: restParams
  });

  if (response.status !== 200) {
    throw new Error('Error reading messages');
  }

  return response.data;
};
