import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';

import { ConferenceButton } from './Conference/ConferenceButton';
import { HangupButton } from './HangupButton';
import { PadButton } from './PadButton';
import { RecordCallButton } from './RecordCallButton';

export const CallButtons: React.FC = () => {
  const activeCall = getActiveRTCall(getConnectedRTUser());

  if (!activeCall) return <></>;

  return (
    <>
      <RecordCallButton />
      <ConferenceButton />
      <PadButton />
      {/*<HangupButton />*/}
    </>
  );
};
