import {
  checkCreateEntityModelsPermissions,
  checkCreateIntentionModelsPermissions,
  checkCreateKnowledgeModelsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { ModelsTabs } from '../views';
import { Permissions } from 'src/models/permissionGroups';

export const getPermissionsCheckerFnByTab = (tab: ModelsTabs) => {
  const permissionsObject: Record<
    ModelsTabs,
    (permissions: Permissions) => boolean
  > = {
    [ModelsTabs.KNOWLEDGE_MODELS_TAB]: checkCreateKnowledgeModelsPermissions,
    [ModelsTabs.INTENTION_MODELS_TAB]: checkCreateIntentionModelsPermissions,
    [ModelsTabs.ENTITIES_TAB]: checkCreateEntityModelsPermissions
  };
  return permissionsObject?.[tab];
};
