import { emitCustomEvent } from 'react-custom-events';
import {
  EventTypes,
  WSMessage
} from 'src/services/websocket/utils/notifications/dataMsgs';
export interface PowerDialerNotificationMessage extends WSMessage {
  companyId: string;
  campaignId: string;
  scheduleId: string;
  userId: string;
  phonenumber: string;
  contactId: string;
}

const onRTPowerDialerEvent = (wsmessage: PowerDialerNotificationMessage) => {
  console.log(wsmessage);
  emitCustomEvent(EventTypes.POWERDIALERCALL, wsmessage);
};

export default onRTPowerDialerEvent;
