import { queryClient } from 'src/utils/queryClient';
import { deleteContactFieldApi } from '../infraestructure/api/deleteContactFieldApi';
import { ContactField } from '../models/contacts';
import { invalidateContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';

export const deleteContactField = async (contactField: ContactField) => {
  await deleteContactFieldApi(contactField.id);
  queryClient.invalidateQueries({
    queryKey: ['contactFields']
  });
  return true;
};

export const deleteContactFields = async (
  companyContactFields: ContactField[]
) => {
  const deleteFieldPromises = companyContactFields.map((field) =>
    deleteContactField(field)
  );
  await Promise.all(deleteFieldPromises);
  invalidateContactFieldsQuery();
};
