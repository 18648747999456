import { WebChatStyle } from '../../models/webChatStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteIconTwo from '@mui/icons-material/DeleteTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import { IconButton, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import { deleteWebChatStyle } from 'src/services/webchat/infraestructure/webChatStyle/deleteWebChatStyle';
import { useAlert } from 'src/hooks/useAlert';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { getWebChatStyle } from 'src/services/webchat/infraestructure/webChatStyle/getWebChatStyle';
import { emitCustomEvent } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import {
  DialTableBulkActionsContext,
  DialTableDataContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';
import {
  checkDeleteWebChatStylesPermissions,
  checkUpdateWebChatStylesPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

export const useWebChatStyleActionButtons = () => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const [selectedWebChatStyle, setSelectedWebChatStyle] =
    useState<WebChatStyle>(null);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [webChatStylesToDelete, setWebChatStylesToDelete] = useState<
    WebChatStyle[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<Row<WebChatStyle>[]>([]);
  const { hasAccess } = useContext(PermissionsContext);

  const onCloseAddModal = () => setIsOpenAddModal(false);
  const onCloseEditModal = () => setIsOpenEditModal(false);

  const getData = async ({
    filtersObject,
    paginationValues
  }: DialTableDataContext<WebChatStyle>) => {
    try {
      const paginatedElements = await getWebChatStyle({
        name: filtersObject['name']?.[0] ?? undefined,
        companyId: filtersObject['companyId']?.[0] ?? undefined,
        page: paginationValues.page,
        size: paginationValues.size
      });
      return paginatedElements;
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
  };

  const deleteAccounts = async (webChatStyles: WebChatStyle[]) => {
    try {
      const deleteWebChatStylePromises = webChatStyles.map((wcs) =>
        deleteWebChatStyle(wcs)
      );
      await Promise.all(deleteWebChatStylePromises);
      showAlert(t('WebChat style deleted succesfully'), 'success', 3000);
      emitCustomEvent(REFETCH_DATA_EVENT_NAME);
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
    setWebChatStylesToDelete([]);
    setIsOpenDeleteModal(false);
    selectedRows.map((row) => row.toggleSelected());
    setSelectedRows([]);
  };

  const getColumns: () => Promise<ColumnDef<WebChatStyle>[]> = async () => [
    {
      accessorKey: 'name',
      id: 'name',
      header: t('Name')
    },
    {
      accessorKey: 'description',
      id: 'description',
      header: t('Description')
    }
  ];

  const getRowActions = ({
    rowData: chat
  }: DialTableRowActionsContext<WebChatStyle>) => [
    <IconButton
      disabled={!hasAccess(checkUpdateWebChatStylesPermissions)}
      key="editWebChatStyle"
      title={t('Edit WebChat style')}
      size="small"
      color="secondary"
      onClick={() => {
        setSelectedWebChatStyle(chat);
        setIsOpenEditModal(true);
      }}
    >
      <LaunchTwoToneIcon fontSize="small" />
    </IconButton>,
    <IconButton
      disabled={!hasAccess(checkDeleteWebChatStylesPermissions)}
      key="deleteWebChatStyle"
      title={t('Delete WebChat style')}
      size="small"
      color="error"
      onClick={async () => {
        setWebChatStylesToDelete([chat]);
        setIsOpenDeleteModal(true);
      }}
    >
      <DeleteIconTwo fontSize="small" />
    </IconButton>
  ];

  const getBulkActions = ({
    selectedData: styles,
    selectedRowModel
  }: DialTableBulkActionsContext<WebChatStyle>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          color="error"
          title={t('Delete one or several web chat styles')}
          size="small"
          onClick={() => {
            setWebChatStylesToDelete([...styles]);
            setIsOpenDeleteModal(true);
            setSelectedRows(selectedRowModel.rows);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  const getAvailableFilters = () => {
    const filters: DialTableFilter<WebChatStyle>[] = [
      {
        fieldType: FieldType.TEXT,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'name',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null
      },
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'companyId',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: DataCategory.COMPANY_ID
      }
    ];
    return Promise.resolve(filters);
  };

  return {
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    webChatStylesToDelete,
    isOpenAddModal,
    isOpenEditModal,
    onCloseAddModal,
    onCloseEditModal,
    setIsOpenAddModal,
    setIsOpenEditModal,
    setSelectedWebChatStyle,
    selectedWebChatStyle,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    deleteAccounts,
    getData
  };
};
