import { uploadCSVContacts } from './createContacts';
import { deleteContact, deleteContacts } from './deleteContact';
import { deleteContactField, deleteContactFields } from './deleteContactField';
import { deleteTag, deleteTags } from './deleteTag';
import {
  getContactFieldsFromContact,
  getContactFieldsObject
} from './getContactFields';
import { getContactById, getContacts, getContactList } from './getContacts';
import { getContactTag, getContactTags } from './getContactTags';
import { updateContact } from './updateContact';
import { updateTag } from './updateTag';

export const ContactsDomain = {
  deleteContact,
  deleteContactField,
  deleteContactFields,
  deleteContacts,
  deleteTag,
  deleteTags,
  getContactFieldsFromContact,
  getContactFieldsObject,
  getContactById,
  getContacts,
  getContactList,
  getContactTag,
  getContactTags,
  updateContact,
  updateTag,
  uploadCSVContacts
};
