import {
  ChannelType,
  Conversation,
  ConversationStatus
} from 'src/models/conversations/conversations';
import { getConversationsApi } from '../infrastructure/getConversationsApi';
import {
  addConversationsStore,
  addUserConversationStore
} from '../../socialmedia/infraestructure/store/setConversationsStore';
import { getConversationFiltersDomain } from '../../socialmedia/domain/conversation/getConversationFilters';
import {
  ConversationsByUserResponse,
  getConversationsByUserApi,
  getConversationsByUserUngroupedApi
} from '../infrastructure/getConversationsByUserApi';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { selectProperConversation } from '../../socialmedia/domain/conversation/selectProperConversation';

export interface ConversationParams {
  assignedUserId?: string;
  channels?: ChannelType[];
  companyId?: string;
  contactId?: string;
  // /**If groupedByChannel is equals to true, the function does not divide the size by the channel array length */
  groupedByChannel?: boolean;
  page?: number;
  phoneNumber?: string;
  size?: number;
  status?: ConversationStatus[];
}

export interface UserConversationParams {
  channel?: ChannelType[];
  // /**If groupedByChannel is equals to true, the function does not divide the size by the channel array length */
  groupedByChannel?: boolean;
  page?: number;
  size?: number;
  status?: ConversationStatus;
}

export const setUserConversations = async (
  userIds: string[],
  size?: number
) => {
  const channelsObject = getConversationFiltersDomain(false).selectedChannels;
  const channels = Object.keys(channelsObject).filter((channel) => {
    return channelsObject[channel];
  }) as ChannelType[];

  const filters = getConversationFiltersDomain(false);
  const isGroupedByChannel = filters.groupByChannels;

  // GROUPED BY CHANNEL
  if (isGroupedByChannel) {
    const conversationPrmomises = channels.map((channel) => {
      return getConversationsByUserApi({
        assignedUserIds: userIds,
        channel: channel,
        userLimit: size
      });
    });

    const promises = await Promise.allSettled(conversationPrmomises);

    const fullFilledPromises = promises.filter(
      (promise) => promise.status === 'fulfilled'
    );

    const conversations = fullFilledPromises
      .map(
        (promise: PromiseFulfilledResult<ConversationsByUserResponse>) =>
          promise.value
      )
      .flatMap((conversation) => Object.values(conversation.conversations));

    addConversationsStore(conversations);
  }

  // UNGROUPED
  if (!isGroupedByChannel) {
    const conversationsByUser = await getConversationsByUserUngroupedApi({
      assignedUserIds: userIds,
      channels: channels,
      userLimit: size ?? 10
    });

    const conversations = Object.values(
      conversationsByUser.conversations
    ).flatMap((conversation) => conversation);

    Object.keys(conversationsByUser.userConvIds).forEach((userId) => {
      const userConversations: Conversation[] = conversations.filter((conv) =>
        conversationsByUser.userConvIds[userId].includes(conv.id)
      );
      addUserConversationStore(userConversations, userId);
    });
  }

  selectProperConversation();
};

export const addUserConversations = async (
  userId: string,
  page: number,
  size: number,
  channel?: ChannelType
) => {
  const conversationFilters = getConversationFiltersDomain(false);
  const { selectedChannels, showUnreadOnly } = conversationFilters;
  const channels = !!channel
    ? [channel]
    : (Object.keys(selectedChannels).filter(
        (channel) => selectedChannels[channel]
      ) as ChannelType[]);

  // Apply the unreadOnly filter only to the connected user
  const unreadOnly =
    showUnreadOnly && userId.includes(getConnectedRTUser(false)?.id);

  const conversations = await getConversationsApi({
    assignedUserId: userId,
    status: [ConversationStatus.ASSIGNED],
    channel: channels,
    page,
    size,
    unreadOnly
  });

  addUserConversationStore(conversations.elements, userId);

  return conversations;
};
