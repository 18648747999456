import { ReactNode } from 'react';
import { useVersionChecker } from './hooks/useVersionChecker';

interface Props {
  children: ReactNode;
}

function VersionChecker({ children }: Props) {
  useVersionChecker();
  return <>{children}</>;
}

export default VersionChecker;
