import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

const ContactDataFormSkeleton = () => {
  return (
    <Stack spacing={2} margin={2}>
      <Skeleton height="53px" variant="rounded" />
      <Stack spacing={2} marginY={1} direction="row">
        <Skeleton height="53px" width="100%" variant="rounded" />
        <Skeleton height="53px" width="64px" variant="rounded" />
      </Stack>
      <Skeleton height="53px" variant="rounded" />
      <Skeleton height="53px" variant="rounded" sx={{ marginX: 1 }} />
      <Skeleton height="53px" variant="rounded" sx={{ marginX: 1 }} />
      <Skeleton height="53px" variant="rounded" />
      <Skeleton height="53px" variant="rounded" sx={{ marginX: 1 }} />
      <Skeleton height="53px" variant="rounded" sx={{ marginX: 1 }} />
      <Skeleton height="53px" variant="rounded" />
      <Skeleton height="53px" variant="rounded" sx={{ marginX: 1 }} />
      <Skeleton height="53px" variant="rounded" sx={{ marginX: 1 }} />
    </Stack>
  );
};

export default ContactDataFormSkeleton;
