import { Add } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MuiTelInput } from 'mui-tel-input';
import { useContext, useEffect, useState } from 'react';
import { useAlert } from 'src/hooks/useAlert';
import { FormFieldVideoCall } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { ContactFieldTypes } from 'src/modules/Contacts/models/contacts';
import './style.css';
import { VideocallCampaignData } from 'src/modules/VideoCall/hooks/useVideocallCampaign';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';

interface VideocallViewProps {
  onSubmit: (fields?: FormFieldData[]) => void;
  direction?: 'row' | 'column';
  campaignData: VideocallCampaignData;
}

export interface FormFieldData {
  formField: FormFieldVideoCall;
  value: string;
}

export const VideoCallForm: React.FC<VideocallViewProps> = (props) => {
  const { onSubmit, direction, campaignData } = props;
  const isPlaceholder = !onSubmit;
  const [fieldValues, setFieldValues] = useState<FormFieldData[]>([]);
  const { isLoggedIn } = useContext(VideocallRoomContext);
  const { showAlert } = useAlert();
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    setFieldValues(
      campaignData?.formFields?.reduce((total, formField) => {
        total.push({ formField: formField, value: '' } as FormFieldData);
        return total;
      }, [])
    );
  }, [campaignData]);

  const handleSendRequest = () => {
    // Check Fields
    if (!isLoggedIn) {
      let error = false;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      for (const fieldValue of fieldValues) {
        if (!error) {
          if (!fieldValue.value) {
            showAlert(t('Fill all the fields'), 'error', 3000);
            error = true;
            return;
          }
          switch (fieldValue.formField.type) {
            case ContactFieldTypes.PHONE:
              if (!parsePhoneNumber(fieldValue.value).isValid()) {
                showAlert(t('Invalid phone number'), 'error', 3000);
                error = true;
              }
              break;
            case ContactFieldTypes.EMAIL:
              if (!emailRegex.test(fieldValue.value)) {
                showAlert(t('Invalid email'), 'error', 3000);
                error = true;
              }
              break;
          }
        }
        if (error) return;
      }
    }
    setIsWaiting(true);
    onSubmit(isLoggedIn ? null : fieldValues);
  };

  if (!isLoggedIn && !campaignData.formFields) {
    return (
      <Stack alignItems={'center'} spacing={2}>
        <CircularProgress />
        <Typography>Cargando Campaña</Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems={isPlaceholder ? 'center' : 'flex-start'}
      width="100%"
    >
      <Stack
        className={isPlaceholder ? '' : isLoggedIn ? '' : 'video-call-form'}
        direction={'column'}
        width={direction === 'column' ? '100%' : '80%'}
        alignItems={'center'}
        py={1}
        px={2}
        rowGap={2}
        style={{
          overflowY: isLoggedIn ? 'hidden' : 'auto',
          maxHeight: isLoggedIn ? undefined : 'calc(100vh - 200px)'
        }}
      >
        {(!isLoggedIn || isPlaceholder) &&
          fieldValues?.map((formFieldData) => {
            const field = formFieldData.formField;
            return (
              <>
                {field.type === ContactFieldTypes.PHONE ? (
                  <MuiTelInput
                    key={field.contactFieldId}
                    defaultCountry="ES"
                    disabled={isPlaceholder}
                    value={formFieldData.value}
                    onChange={(value) => {
                      formFieldData.value = value;
                      setFieldValues([...fieldValues]);
                    }}
                    sx={{
                      width: '100%'
                    }}
                  />
                ) : (
                  <TextField
                    style={{
                      width: '100%'
                    }}
                    disabled={isPlaceholder}
                    key={field.contactFieldId}
                    label={field.labelName}
                    onChange={(event) => {
                      formFieldData.value = event.target.value;
                      setFieldValues([...fieldValues]);
                    }}
                    value={formFieldData.value}
                  />
                )}
              </>
            );
          })}
        <Button
          variant="contained"
          color="secondary"
          style={{
            width: '100%',
            height: '42.5px',
            cursor: 'pointer'
          }}
          onClick={() => {
            if (!isPlaceholder) handleSendRequest();
          }}
        >
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
              key={isWaiting ? 'wating' : 'submit'}
              initial={{
                y: -32,
                opacity: 0
              }}
              animate={{
                y: 0,
                opacity: 1
              }}
              exit={{
                y: 32,
                opacity: 0
              }}
              transition={{
                type: 'spring',
                bounce: 0,
                duration: 0.25
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                columnGap: '8px',
                whiteSpace: 'nowrap'
              }}
            >
              {!isWaiting ? (
                <>
                  <Add />
                  <Typography fontFamily={'Inter'} fontWeight={'bold'}>
                    {t('Join videocall')}
                  </Typography>
                </>
              ) : (
                <>
                  <CircularProgress color="inherit" size={'20px'} />
                  <Typography fontFamily={'Inter'} fontWeight={'bold'}>
                    {t(!isLoggedIn ? 'Waiting to be accepted' : 'Loading')}
                  </Typography>
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </Button>
      </Stack>
    </Stack>
  );
};
