import axios from 'axios';
import qs from 'qs';
import { PaginatedElements } from 'src/models/pagination';
import { WebChatStyle } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatStyle';
import { WEBCHAT_STYLE } from 'src/utils/apiRoutes/msWebChat';

export interface WebChatStyleFilter {
  companyId?: string;
  name?: string;
  id?: string;
  page?: number;
  size?: number;
}

export const getWebChatStyle = async (filter: WebChatStyleFilter) => {
  const params = { ...filter };

  const paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };

  const response = await axios.get<PaginatedElements<WebChatStyle>>(
    WEBCHAT_STYLE,
    {
      params,
      paramsSerializer
    }
  );
  return response.data;
};
