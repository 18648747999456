import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  styled,
  CircularProgress,
  useTheme,
  Stack,
  Collapse
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Conversation } from 'src/models/conversations/conversations';

import { ConversationIconTypes, getIconComponent } from 'src/utils/icons';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AllInboxTwoToneIcon from '@mui/icons-material/AllInboxTwoTone';
import { useGetConversationTitleQuery } from 'src/services/socialmedia/application/query/getConversationInfoQuery';
import { getConversationIconString } from 'src/services/socialmedia/domain/conversation/getConversationInfo';
import { useCustomEventListener } from 'react-custom-events';
import { useState } from 'react';
import {
  SocialmediaEvent,
  SocialmediaMessageUserTypingEvent
} from 'src/services/socialmedia/application/websocket/socialmediaEvent';
import ChatTypingDots from './ChatTypingDots';
import { getUserTypingEventName } from 'src/services/socialmedia/application/websocket/websocket';

const ChatTopBarWrapper = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const ChatTopBarContent = styled(Box)(
  ({ theme }) => `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`
);

interface Props {
  conversation: Conversation;
}

const ChatTopBar = (props: Props) => {
  const { conversation } = props;
  const titleQuery = useGetConversationTitleQuery({
    conversation,
    enabled: !!conversation
  });
  const iconString = getConversationIconString(conversation);

  const [isTyping, setIsTyping] = useState(false);

  const theme = useTheme();
  const { t }: { t: any } = useTranslation();

  const eventName = getUserTypingEventName(conversation?.id);

  useCustomEventListener(
    eventName,
    (socialmediaEvent: SocialmediaMessageUserTypingEvent) => {
      if (socialmediaEvent.orientation === 'Incoming') {
        setIsTyping(socialmediaEvent.typing);
      }
    }
  );

  const titleData = titleQuery.data;
  const noName = titleData === 'Contact without name';
  const title = noName ? t(titleData) : titleData;

  return (
    <ChatTopBarWrapper
      sx={{
        display: { xs: 'flex', lg: 'inline-block' },
        width: '100%',
        height: theme.header.height
      }}
    >
      <ChatTopBarContent>
        <Box display="flex" alignItems="center">
          {getIconComponent(iconString as ConversationIconTypes)}
          <Box ml={1}>
            <Typography variant="h4" fontStyle={noName ? 'italic' : 'normal'}>
              {titleQuery.isLoading ? (
                <CircularProgress size={18} color="secondary" />
              ) : (
                title
              )}
            </Typography>
            <Collapse in={isTyping}>
              <Stack direction="row" alignItems="center">
                <ChatTypingDots />
                <Typography variant="subtitle1" color="secondary" ml={0.5}>
                  {t('typing')}
                </Typography>
              </Stack>
            </Collapse>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', lg: 'flex' }
          }}
        >
          <Tooltip placement="bottom" title={t('Transfer conversation')}>
            <IconButton color="primary" sx={{ marginRight: 0.5 }}>
              <SyncAltIcon />
            </IconButton>
          </Tooltip>
          <IconButton color="primary" sx={{ marginRight: 2 }}>
            <AllInboxTwoToneIcon />
          </IconButton>
        </Box>
      </ChatTopBarContent>
    </ChatTopBarWrapper>
  );
};

export default ChatTopBar;
