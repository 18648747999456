import { togglePinnedConversationId } from 'src/store/slices/socialmedia/socialmediaSlice';
import store, { useSelector } from 'src/store/store';

export const PINNED_CONVERSATION_IDS_LOCAL_STORAGE_KEY =
  'pinnedConversationIds';

export function getPinnedConversationIds(shouldUseHook?: boolean) {
  if (shouldUseHook) {
    return useSelector((store) => store.socialmedia.pinnedConversationIds);
  }

  return store.getState().socialmedia.pinnedConversationIds;
}

export function checkConversationIsPinned(conversationId: string) {
  const pinnedConversationIds = getPinnedConversationIds();
  return pinnedConversationIds.includes(conversationId);
}

export function useCheckConversationIsPinned(conversationId: string) {
  const pinnedConversationIds = getPinnedConversationIds(true);
  return pinnedConversationIds.includes(conversationId);
}

export function togglePinnedConversationIdStore(conversationId: string) {
  store.dispatch(togglePinnedConversationId(conversationId));
}

export function getPinnedConversationIdsFromLocalStorage(): string[] {
  const storedPinned = localStorage.getItem(
    PINNED_CONVERSATION_IDS_LOCAL_STORAGE_KEY
  );
  return storedPinned ? JSON.parse(storedPinned) : [];
}
