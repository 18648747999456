import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getAllDaServices } from './thunks';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';

interface DaServicesState {
  daServices: DaService[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  selectedService: DaService;
}

const initialState: DaServicesState = {
  daServices: [],
  selectedService: undefined,
  loading: 'idle'
};

export const daServicesSlice = createSlice({
  name: 'daServices',
  initialState,
  reducers: {
    setSelectedService: (state, action: PayloadAction<DaService>) => {
      state.selectedService = action.payload;
      state.loading = 'succeeded';
    },
    startLoadingDaServices: (state) => {
      state.loading = 'pending';
    },
    setDaServices: (state, action: PayloadAction<DaService[]>) => {
      state.daServices = action.payload;
      state.loading = 'succeeded';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDaServices.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(
      getAllDaServices.fulfilled,
      (state, action: PayloadAction<DaService[]>) => {
        state.daServices = action.payload;
        state.loading = 'succeeded';
      }
    );
    builder.addCase(getAllDaServices.rejected, (state) => {
      state.loading = 'failed';
    });
  }
});

export const { startLoadingDaServices, setDaServices, setSelectedService } =
  daServicesSlice.actions;

export default daServicesSlice.reducer;
