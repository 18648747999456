import { queryClient } from 'src/utils/queryClient';
import {
  GetPromptElementFilters,
  getPromptElementsApi
} from '../../infrastructure/api/prompt/getPromptElementsApi';
import { useQuery } from '@tanstack/react-query';

export async function getPromptElements(params?: GetPromptElementFilters) {
  return getPromptElementsApi(params ?? {});
}

export function getPromptElementsQuery(params?: GetPromptElementFilters) {
  return queryClient.fetchQuery({
    queryKey: ['promptElements', Object.values(params ?? {})],
    queryFn: () => getPromptElementsApi(params ?? {}),
    staleTime: 60 * 1000
  });
}

export function usePromptElementsQuery(params?: GetPromptElementFilters) {
  return useQuery({
    queryKey: ['promptElements', Object.values(params ?? {})],
    queryFn: () => getPromptElementsApi(params ?? {}),
    staleTime: 60 * 1000
  });
}

export function invalidatePromptElementsQuery() {
  queryClient.invalidateQueries({ queryKey: ['promptElements'] });
}
