import store, { useSelector } from 'src/store/store';

export const getAnswerOnClickStore = (shouldUseHook: boolean) => {
  const answerOnClickNotification = (
    shouldUseHook
      ? useSelector((state) => state.configVolume.answerOnClickNotification)
      : store.getState().configVolume.answerOnClickNotification
  ) as boolean;

  return answerOnClickNotification;
};

export const getAnswerOnClickStorage = () => {
  return localStorage.getItem('answerOnClickNotification') === 'true';
};

export const setAnswerOnClickStorage = (value: boolean) => {
  const stringValue = value ? 'true' : 'false';
  return localStorage.setItem('answerOnClickNotification', stringValue);
};
