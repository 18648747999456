import { Stack, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

const ChatTypingDots = () => {
  const dotIndexList = [0, 1, 2];
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={0.25}>
      {dotIndexList.map((dotIndex) => (
        <motion.div
          key={`dot-${dotIndex}`}
          animate={{ scale: [1, 1.5, 1] }}
          transition={{
            repeat: Infinity,
            duration: 1,
            delay: dotIndex * 0.2
          }}
          style={{
            width: '4px',
            height: '4px',
            backgroundColor: theme.colors.secondary.main,
            borderRadius: '50%'
          }}
        />
      ))}
    </Stack>
  );
};

export default ChatTypingDots;
