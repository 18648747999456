import { replaceURLParams } from 'src/services/utils';
import { WebChatStyle } from '../../../../modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatStyle';
import axios from 'axios';
import { WEBCHAT_STYLE_ID } from 'src/utils/apiRoutes/msWebChat';

/*PUT WebChatStyle*/
export const updateWebChatStyle = async (
  webChatStyle: WebChatStyle,
  logoImage?: File,
  bubbleImages?: File[]
) => {
  const formData = new FormData();
  if (logoImage) {
    formData.append('logoImage', logoImage);
  }
  formData.append('style', JSON.stringify(webChatStyle));
  if (bubbleImages) {
    bubbleImages.map((bubble) => formData.append('bubbleImages', bubble));
  }

  const path = replaceURLParams(WEBCHAT_STYLE_ID, {
    '{id}': webChatStyle.id
  });
  const response = await axios.put<WebChatStyle>(path, formData);
  return response.data;
};
