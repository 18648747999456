import { KeywordsGroup } from '../../models/keywordsGroups';

export const BASE_KEYWORDS_ID = 'base_keywords_groups';
export const CREATE_FORM = 'Create a keywords group';
export const UPDATE_FORM = 'Update a keywords group';
export const CREATE_FORM_SUBTITLE =
  'Use this dialog window to create a keywords group';
export const UPDATE_FORM_SUBTITLE =
  'Use this dialog window to update a keywords group';

export const baseKeywordsGroup: KeywordsGroup = {
  id: BASE_KEYWORDS_ID,
  name: '',
  serviceId: '',
  color: '#4A90E2',
  companyId: '',
  words: []
};

export enum KeywordsFormTypes {
  create = 'create',
  update = 'update'
}
