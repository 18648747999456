import axios from 'axios';
import { Showflow } from '../../models/showflow';

import { BASE_MS, BASE_URL, BASE_SHOWFLOW } from '../constants';
import { queryClient } from 'src/utils/queryClient';
import { SHOWFLOW_DRAFTS_KEY } from './getShowflowsApi';

async function deleteShowflowApi(id: string) {
  const path = `${BASE_URL}${BASE_MS}${BASE_SHOWFLOW}/${id}`;
  const response = await axios.delete(path);

  queryClient.invalidateQueries({
    queryKey: ['showflows']
  });

  return response.data as Showflow;
}

export const deleteShowflowDraftApi = async (draftId: string) => {
  const resp = localStorage.getItem(SHOWFLOW_DRAFTS_KEY);
  const previousShowflowDrafts: Showflow[] = !!resp ? JSON.parse(resp) : [];
  const newShowflowDrafts = previousShowflowDrafts.filter(
    (showflow) => showflow.draftId !== draftId
  );
  localStorage.setItem(SHOWFLOW_DRAFTS_KEY, JSON.stringify(newShowflowDrafts));
};

export default deleteShowflowApi;
