import { createAsyncThunk } from '@reduxjs/toolkit';
import CallService from 'src/modules/CTI/infrastructure/manager';

/* const performCall = createAsyncThunk(
  'calls/performCall',
  async (callInfo: any) => {
    const response = await CallService.launchCallApi(
      callInfo.campaignId,
      callInfo.phone,
      callInfo.srcExt
    );
    return response.data;
  } 
);*/

const parkCall = createAsyncThunk('calls/parkCall', async (callInfo: any) => {
  const response = await CallService.parkCallApi(
    callInfo.callId,
    callInfo.instance
  );
  return response.data;
});

const unParkCall = createAsyncThunk(
  'calls/unParkCall',
  async (callInfo: any) => {
    const response = await CallService.unParkCallApi(
      callInfo.callId,
      callInfo.extension,
      callInfo.instance
    );
    return response.data;
  }
);

const spyCall = createAsyncThunk('calls/spyCall', async (callInfo: any) => {
  const response = await CallService.spyCall(
    callInfo.callId,
    callInfo.extension,
    callInfo.mode,
    callInfo.instance
  );
  return response.data;
});

const hangupCall = createAsyncThunk(
  'calls/hangupCall',
  async (callInfo: any) => {
    const response = await CallService.hangupCallApi(
      callInfo.callId,
      callInfo.instance
    );
    return response.data;
  }
);

const transferCall = createAsyncThunk(
  'calls/transferCall',
  async (callInfo: any) => {
    const response = await CallService.transferCallApi({
      call: callInfo,
      extension: callInfo.extension,
      isInternal: callInfo.isInternal
    });
    return response.data;
  }
);

const CallAsync = {
  //performCall,
  parkCall,
  spyCall,
  hangupCall,
  transferCall,
  unParkCall
};

export default CallAsync;
