import { Add, Delete, DragHandle, DragIndicator } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useState, useRef } from 'react';
import { Reorder } from 'framer-motion';
import { SearchItemPopover } from './SearchFieldPopover.tsx';

interface Props<T> {
  onDragEnd?: (result: any) => void;
  rowItems: DraggableItem<T>[];
  setRowItems: Dispatch<SetStateAction<DraggableItem<T>[]>>;
  droppableId: string;
  renderItem?: (item: DraggableItem<T>) => JSX.Element;
  renderFullItem?: (item: DraggableItem<T>) => ReactNode;
  hideDeleteIcon?: (item: DraggableItem<T>) => boolean;
}
export interface DraggableItem<T> {
  id: number;
  name: string;
  value: T;
}

export const DraggableColumn = <T extends {}>({
  setRowItems,
  rowItems,
  renderItem,
  renderFullItem,
  hideDeleteIcon
}: Props<T>) => {
  const theme = useTheme();
  const containerRef = useRef(null);

  function onDelete(id: number) {
    const newItems = rowItems.filter((item) => item.id !== id);
    setRowItems(newItems);
  }

  return (
    <Reorder.Group
      axis="y"
      values={rowItems}
      onReorder={setRowItems}
      ref={containerRef}
      style={{
        height: rowItems.length * 43 + 'px',
        listStyle: 'none',
        margin: 0,
        padding: 0
      }}
    >
      {rowItems.map((item, index) => {
        return (
          <Reorder.Item
            key={item.id}
            value={item}
            dragConstraints={containerRef}
            dragElastic={0}
          >
            {renderFullItem?.(item) ?? (
              <>
                {index !== 0 && (
                  <Divider
                    sx={{
                      width: '100%',
                      color: 'inherit'
                    }}
                  />
                )}
                <Box
                  p={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '42px',
                    gap: '6px',
                    justifyContent: 'space-between'
                  }}
                >
                  {renderItem ? (
                    renderItem(item)
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        width: '100%',
                        overflow: 'hidden'
                      }}
                    >
                      <DragHandle />
                      <Typography
                        sx={{
                          fontSize: '14px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Stack>
                  )}

                  {!hideDeleteIcon?.(item) && (
                    <IconButton
                      onClick={() => {
                        onDelete(item.id);
                      }}
                      color="error"
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Box>
              </>
            )}
          </Reorder.Item>
        );
      })}
    </Reorder.Group>
  );
};
