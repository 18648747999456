import axios from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';
import {
  ContactField,
  ContactFieldType
} from 'src/modules/Contacts/models/contacts';
import { CONTACT_FIELDS } from 'src/utils/apiRoutes/msContacts';

interface GetContactFieldsFilterApi {
  companyId?: string;
  contactGroupId?: string;
  page?: number;
  size?: number;
  sortBy?: keyof ContactField;
  sortDir?: DirectionType;
  type?: ContactFieldType;
}

export const getContactFieldsApi = async (
  filter: GetContactFieldsFilterApi
) => {
  const resp = await axios.get<PaginatedElements<ContactField>>(
    CONTACT_FIELDS,
    { params: filter }
  );
  return resp.data;
};
