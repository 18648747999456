import { WhatsAppTemplateVariables } from 'src/services/whatsapp/models/WhatsAppTemplate';
import * as Yup from 'yup';

export const getWhatsAppTemplateParamsValidationSchema = (
  templateParams: WhatsAppTemplateVariables
) =>
  Yup.object().shape(
    Object.keys(templateParams).reduce((acc, key) => {
      acc[key] = Yup.string().required();
      return acc;
    }, {})
  );
