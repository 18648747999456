import axios, { AxiosResponse } from 'axios';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { replaceURLParams } from 'src/services/utils';
import { CAMPAIGNS_ID_ENABLE, CAMPAIGNS_ID } from 'src/utils/apiRoutes/msUsers';

export const getCampaignByIdApi = async (
  campaignId: string
): Promise<Campaign> => {
  const path = replaceURLParams(CAMPAIGNS_ID, {
    '{id}': campaignId
  });
  const response: AxiosResponse<Campaign> = await axios.get(path);
  return response.data;
};

export const enableCampaigns: Function = (id: string, enabled: boolean) => {
  const path = replaceURLParams(CAMPAIGNS_ID_ENABLE, {
    '{id}': id,
    '{enable}': enabled
  });

  return axios
    .post(path)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
