import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { COORDINATION_ASSIGN_CONVERSATION_CONTACT_ID } from 'src/utils/apiRoutes/msCoordination';

export interface AssignContactToConversationApiProps {
  conversationId: string;
  contactId: string;
  pos: number;
}

export async function assignContactToConversationApi(
  data: AssignContactToConversationApiProps
) {
  const path = replaceURLParams(COORDINATION_ASSIGN_CONVERSATION_CONTACT_ID, {
    '{id}': data.conversationId
  });

  const response = await axios.put(path, null, {
    params: {
      contactId: data.contactId,
      pos: data.pos
    }
  });

  return response.data;
}
