import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { KeywordsGroup } from '../../models/keywordsGroups';
import { KeywordsFormTypes } from '../utils/constants';

interface KeywordsGroupsContextProps {
  keywordsGroup: KeywordsGroup;
  setKeywordsGroup: Dispatch<SetStateAction<KeywordsGroup>>;
  setWords: Dispatch<SetStateAction<word[] | []>>;
  words: [] | word[];
}

interface KeywordsGroupsFormContextProps {
  keywordsGroupsFormType: KeywordsFormTypes;
  setKeywordsGroupsFormType: Dispatch<SetStateAction<KeywordsFormTypes>>;
  openKeywordsGroupsForm: boolean;
  setOpenKeywordsGroupsForm: Dispatch<SetStateAction<boolean>>;
}

export interface word {
  id: string;
  value: string;
}

export const KeywordsGroupsContext = createContext<KeywordsGroupsContextProps>(
  {} as KeywordsGroupsContextProps
);

export const KeywordsGroupsFormContext =
  createContext<KeywordsGroupsFormContextProps>(
    {} as KeywordsGroupsFormContextProps
  );

export const KeywordsGroupsProvider: React.FC = ({ children }) => {
  //Keywords groups context variables
  const [keywordsGroup, setKeywordsGroup] = useState<KeywordsGroup | null>(
    null
  );
  const [words, setWords] = useState<word[] | []>([]);

  //Keywords groups from context variables
  const [keywordsGroupsFormType, setKeywordsGroupsFormType] = useState(
    KeywordsFormTypes.create
  );
  const [openKeywordsGroupsForm, setOpenKeywordsGroupsForm] =
    useState<boolean>(false);

  return (
    <KeywordsGroupsContext.Provider
      value={{ keywordsGroup, setKeywordsGroup, words, setWords }}
    >
      <KeywordsGroupsFormContext.Provider
        value={{
          keywordsGroupsFormType,
          setKeywordsGroupsFormType,
          openKeywordsGroupsForm,
          setOpenKeywordsGroupsForm
        }}
      >
        {children}
      </KeywordsGroupsFormContext.Provider>
    </KeywordsGroupsContext.Provider>
  );
};
