import axios from 'axios';
import { Contact } from 'src/modules/Contacts/models/contacts';
import { replaceURLParams } from 'src/services/utils';
import { CONTACTS_ID } from 'src/utils/apiRoutes/msContacts';
import { queryClient } from 'src/utils/queryClient';

export const updateContactApi = async (contact: Contact): Promise<Contact> => {
  if (!contact?.id) {
    throw new Error('No contact id');
  }
  const path = replaceURLParams(CONTACTS_ID, {
    '{id}': contact?.id
  });

  const resp = await axios.put(path, contact);
  queryClient.invalidateQueries({
    queryKey: ['contactFields', 'contacts', 'contact']
  });
  return resp.data;
};
