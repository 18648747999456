import { queryClient } from 'src/utils/queryClient';
import {
  GetInstagramAccountsFilter,
  getInstagramAccountsApi
} from '../../infrastructure/account/getInstagramAccountsApi';

export const getInstagramAccountsQueryKey = 'instagramAccounts';

function getInstagramAccountsQuery(params: GetInstagramAccountsFilter) {
  return queryClient.fetchQuery(
    [getInstagramAccountsQueryKey, ...Object.values(params)],
    () => getInstagramAccountsApi(params),
    { staleTime: 1000 * 60 * 5 }
  );
}

export default getInstagramAccountsQuery;
