import { Stack } from '@mui/material';
import React from 'react';
import { ColorPicker } from '../../../../components/Style/ColorPicker';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../../models/webChatStyle';
import { FormikProps } from 'formik';

interface OpenChatAccordionProps {
  formikProps: FormikProps<WebChatStyle>;
}

const OpenChatAccordion: React.FC<OpenChatAccordionProps> = ({
  formikProps
}) => {
  return (
    <>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Background')}
            color={formikProps.values.contactStyle.openChatBGColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'contactStyle.openChatBGColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Text')}
            color={formikProps.values.contactStyle.openChatText.color}
            setColor={(c) => {
              formikProps.setFieldValue('contactStyle.openChatText.color', c);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default OpenChatAccordion;
