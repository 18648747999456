import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  Contact,
  ContactField,
  ContactFieldType
} from 'src/modules/Contacts/models/contacts';
import { Conversation } from 'src/models/conversations/conversations';

import { getContactsFromConversation } from 'src/modules/Contacts/domain/getContacts';
import { getConversationInvolvedContacts } from '../../domain/conversation/utils';

import { queryClient } from 'src/utils/queryClient';
import { getCompanyContactFields } from 'src/modules/Contacts/domain/getContactFields';
import { getContactFieldsApi } from 'src/modules/Contacts/infraestructure/api/getCompanyContactFieldsApi';
import { DirectionType } from 'src/components/TableReporting/models/direction';

export type GetCompanyContactFieldsFilter = {
  companyId?: string;
  contactGroupId?: string;
  type?: ContactFieldType;
  page?: number;
  size?: number;
  sortBy?: keyof ContactField;
  sortDir?: DirectionType;
};

export function invalidateContactFieldsQuery() {
  queryClient.invalidateQueries({
    queryKey: ['contactFields']
  });
}

export const useGetContactFields = (
  filter: GetCompanyContactFieldsFilter,
  enabled?: boolean
) => {
  return useQuery(
    [
      'contactFields',
      'contactFieldsByType',
      filter.companyId,
      filter.type,
      filter.contactGroupId
    ],
    () => getContactFieldsQuery(filter),
    { enabled: enabled ?? true, staleTime: 60000 * 5, retry: false }
  );
};

export function useContactFieldsQuery(filter?: GetCompanyContactFieldsFilter) {
  return useQuery({
    queryKey: ['contactFields', ...Object.values(filter ?? {})],
    queryFn: () => getContactFieldsApi(filter),
    staleTime: 60000
  });
}

export const getContactFields = (filter?: GetCompanyContactFieldsFilter) => {
  return getCompanyContactFields(filter);
};

export const getContactFieldsQuery = async (
  filter?: GetCompanyContactFieldsFilter
) => {
  const companyFields = await queryClient.fetchQuery(
    ['contactFields', filter?.companyId, filter?.contactGroupId, filter?.type],
    {
      queryFn: () => getCompanyContactFields(filter),
      staleTime: 60000
    }
  );

  return companyFields;
};

export const useGetContactsFromConversationQuery = (
  conversation: Conversation,
  waitFor: any
) => {
  return useQuery(
    ['contactsConversation', conversation.id],
    () => getContactsFromConversation(conversation),
    { enabled: !!waitFor, staleTime: 0 }
  );
};

/**
 *  OJO!! Este metodo va a devolver todos los contactos de la conversacion, incluyendo aquellos "no identificados".
 * Es decir, estamos creando contactos "fake" a partir de la informacion de aquellos involvedcontacts no identificados.
 */
export const createContactsFromInvolvedQuery = (
  conversation: Conversation,
  waitFor: any
): UseQueryResult<Contact[]> => {
  return useQuery<Contact[], Error>(
    ['contactsInvolvedConversation', conversation?.id],
    () => getConversationInvolvedContacts(conversation),
    { enabled: !!waitFor, staleTime: 0 }
  );
};
