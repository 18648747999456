import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { MenuItem, Popover, Stack, useTheme } from '@mui/material';
import {
  ChatMessage,
  ConversationMessage,
  MessageActions
} from 'src/models/conversations/messages';
import DialModal, { ModalButton } from 'src/components/DialModal';
import { useDispatch } from 'src/store/store';
import {
  setIsEditingChatMessage,
  setCurrentTelegramMessage,
  setReplyingToMessage,
  setIsReplyingToMessage
} from 'src/store/slices/socialmedia/socialmediaSlice';
import { ChannelType } from 'src/models/conversations/conversations';
import { useAlert } from 'src/hooks/useAlert';
import { deleteChatMessage } from 'src/services/socialmedia/domain/messages/deleteChatMessage';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { getConversationActionsQuery } from '../utils/getConversationActionsQuery';
import {
  DeleteOutline,
  EditOutlined,
  ReplyOutlined
} from '@mui/icons-material';

interface ContextMenuTypeProps {
  open: boolean;
  message: ChatMessage;
  anchorMessage: HTMLElement;
  setAnchorMessage: React.Dispatch<React.SetStateAction<HTMLElement>>;
  handleClose: () => void;
}
const ContextMenuMessage: React.FC<ContextMenuTypeProps> = (props) => {
  const { showAlert } = useAlert();
  const { anchorMessage, message, open, handleClose } = props;
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const titleModal = t('Are you sure do you want to delete this message?');
  const currentConversation = getCurrentConversation(true);
  const theme = useTheme();

  const [messageActions, setMessageActions] = useState<
    Partial<MessageActions>[]
  >([]);

  const renderMessageActionIcon = (action: MessageActions) => {
    switch (action) {
      case MessageActions.DELETE:
        return <DeleteOutline />;
      case MessageActions.EDIT:
        return <EditOutlined />;
      case MessageActions.REPLY:
        return <ReplyOutlined />;
    }
  };

  const renderMenuMessageActionItem = (action: MessageActions) => (
    <MenuItem
      key={action}
      onClick={() => handleActionsMessage(action)}
      sx={{
        borderRadius: 0,
        color:
          action === MessageActions.DELETE
            ? theme.colors.error.main
            : undefined,
        ':hover': {
          color:
            action === MessageActions.DELETE
              ? theme.colors.error.main
              : undefined
        }
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          flex: 1,
          gap: 4
        }}
      >
        {t(action)} {renderMessageActionIcon(action)}
      </Stack>
    </MenuItem>
  );

  useEffect(() => {
    if (!!message) {
      getConversationActionsQuery(message).then((act) => {
        setMessageActions(act);
      });
    }
  }, [message]);

  const dispatch = useDispatch();
  const { messageId } = message;
  const text = message.content?.text;

  const handleEditMessage = () => {
    dispatch(setIsEditingChatMessage(true));
    dispatch(setCurrentTelegramMessage(message));
    dispatch(setIsReplyingToMessage(false));
    handleClose();
  };

  const handleReplyToMessage = () => {
    dispatch(setIsReplyingToMessage(true));
    dispatch(setReplyingToMessage(message));
    dispatch(setIsEditingChatMessage(false));
    handleClose();
  };

  const handleDeleteMessage = () => {
    if (message.channel === ChannelType.WHATSAPP)
      return showAlert(t('You can`t delete whatsapp message'), 'error', 3000);
    setOpenDeleteModal(true);
  };

  const handleActionsMessage = (action: string) => {
    switch (action) {
      case 'Delete':
        handleDeleteMessage();
        break;
      case 'Edit':
        handleEditMessage();
        break;
      case 'Reply':
        handleReplyToMessage();
        break;
    }
  };

  const leftButtonDeleteModal: ModalButton<ConversationMessage> = {
    text: 'Cancel',
    handler: () => {
      setOpenDeleteModal(false);
      handleClose();
    },
    style: {
      marginRight: '40px'
    }
  };

  const rightButtonDeleteModal: ModalButton<ConversationMessage> = {
    text: 'Delete',
    handler: async () => {
      // Request delete
      await deleteChatMessage({
        channel: message.channel,
        accountId: currentConversation?.accountId,
        conversationId: currentConversation?.id,
        messageId
      });
      setOpenDeleteModal(false);
      handleClose();
    },
    style: {
      backgroundColor: '#ff1943',
      '&:hover': {
        backgroundColor: '#B9112F'
      }
    }
  };

  return (
    <>
      <Popover
        id="basic-menu"
        anchorEl={anchorMessage}
        open={open}
        onClose={handleClose}
        sx={{
          backdropFilter: 'blur(0px)'
        }}
      >
        {messageActions?.map((action) => renderMenuMessageActionItem(action))}
      </Popover>
      <DialModal
        text={titleModal}
        isOpen={openDeleteModal}
        leftButton={leftButtonDeleteModal}
        rightButton={rightButtonDeleteModal}
        body={[text]}
      />
    </>
  );
};

export default ContextMenuMessage;
