import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  TextField,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { Ticket, TicketComment } from '../../../../models/tickets';

interface Props {
  onSubmitTicketComment: (ticketComment: string) => Promise<TicketComment>;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
  author?: string;
}

const TicketNewComment = ({
  onSubmitTicketComment,
  setTicket,
  author
}: Props) => {
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [newTicketComment, setNewTicketComment] = useState('');
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);

  const clearNewComment = () => {
    setNewTicketComment('');
  };

  const cancelSubmittingComment = () => {
    clearNewComment();
    setIsAddingComment(false);
  };

  const user = author ?? getConnectedRTUser(true)?.username;
  const authorInitial = user?.charAt(0);

  return (
    <Box display="flex" py={1.25} gap={2}>
      <Box>
        <Avatar variant="circular" alt={author}>
          <Typography variant="h3" color="inherit" fontWeight="regular">
            {authorInitial}
          </Typography>
        </Avatar>
      </Box>
      <Box flex={1}>
        <TextField
          id={`ticket-new-comment-input`}
          fullWidth
          multiline
          rows={isAddingComment ? 3 : undefined}
          name="newComment"
          placeholder={t('Add a new comment here...')}
          onChange={(e) => {
            setNewTicketComment(e.target.value);
          }}
          onFocus={(e) => {
            setIsAddingComment(true);
          }}
          value={newTicketComment}
          variant="outlined"
        />
        <Collapse in={isAddingComment} mountOnEnter unmountOnExit>
          <Box display="flex" flex={1} justifyContent="end" mt={1} gap={1}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={cancelSubmittingComment}
            >
              {t('Cancel')}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={async (e) => {
                setIsSubmittingComment(true);
                const comment = await onSubmitTicketComment(newTicketComment);
                if (setTicket) {
                  setTicket((prev) => {
                    return {
                      ...prev,
                      comments: [...prev.comments, comment]
                    };
                  });
                }
                setIsSubmittingComment(false);
                setIsAddingComment(false);
                cancelSubmittingComment();
              }}
              disabled={isSubmittingComment}
              startIcon={
                isSubmittingComment ? <CircularProgress size="small" /> : null
              }
            >
              {t('Send')}
            </Button>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default TicketNewComment;
