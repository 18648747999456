import {
  getExtensions,
  updateExtensionFromForm,
  removeExtension,
  getOccupiedExtensions,
  disableMultipleExtensions,
  enableMultipleExtensions,
  disableExtension,
  enableExtension,
  removeMultipleExtensions,
  createSingleExtensionFromForm,
  createMultiplesExtensionsFromForm,
  assignExtensionsByRanges,
  assignExtensionToAnExtensionsGroup,
  unassignExtensionToAnExtensionsGroup
} from './extensions';
import {
  createExtensionsGroupFromForm,
  deleteExtensionsGroup,
  getExtensionsGroups,
  updateExtensionsGroupFromForm
} from './extensionsGroups';

const ExtensionsDomain = {
  getExtensions,
  updateExtensionFromForm,
  removeExtension,
  getOccupiedExtensions,
  disableMultipleExtensions,
  enableMultipleExtensions,
  disableExtension,
  enableExtension,
  removeMultipleExtensions,
  createSingleExtensionFromForm,
  createMultiplesExtensionsFromForm,
  assignExtensionsByRanges,
  assignExtensionToAnExtensionsGroup,
  unassignExtensionToAnExtensionsGroup,
  createExtensionsGroupFromForm,
  deleteExtensionsGroup,
  getExtensionsGroups,
  updateExtensionsGroupFromForm
};

export default ExtensionsDomain;
