import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { TicketSubResolution } from '../../models/tickets';
import {
  TICKET_SUBRESOLUTIONS,
  TICKET_SUBRESOLUTIONS_ID
} from 'src/utils/apiRoutes/msMessaging';

export const getTicketSubResolutionsApi = async (params: {
  companyId: string;
  resolutionId?: string;
}): Promise<TicketSubResolution[]> => {
  const resp = await axios.get(TICKET_SUBRESOLUTIONS, { params });
  return resp.data;
};

export const getTicketSubResolutionByIdApi = async (
  id: string
): Promise<TicketSubResolution> => {
  const path = replaceURLParams(TICKET_SUBRESOLUTIONS_ID, {
    '{id}': id
  });
  const resp = await axios.get(path);
  return resp.data;
};
