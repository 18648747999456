import { Dispatch, SetStateAction } from 'react';

import { Conversation } from 'src/models/conversations/conversations';
import { createContactsFromInvolvedQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import Scrollbar from 'src/components/Scrollbar';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  Box,
  CircularProgress,
  Divider,
  List,
  Typography,
  useTheme
} from '@mui/material';
import NoteContactListItem from './NoteContactListItem';

interface Props {
  conversation: Conversation;
  companyFields: ContactField[];
  setSelectedContact: Dispatch<SetStateAction<Contact>>;
  setIsAdding: Dispatch<SetStateAction<boolean>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const NoteContactDataListView = (props: Props) => {
  const {
    conversation,
    companyFields,
    setSelectedContact,
    setIsAdding,
    setIsEditing
  } = props;
  const { involvedContacts } = conversation;

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const participantsNumber = involvedContacts.length;

  const contactsQuery = createContactsFromInvolvedQuery(
    conversation,
    conversation
  );

  if (contactsQuery.isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        p={2}
      >
        <CircularProgress />
      </Box>
    );

  const contacts = contactsQuery.data;

  return (
    <>
      <Helmet>
        <title>Helper Panel - Contact Data List</title>
      </Helmet>

      <Box height="100%">
        <Box
          px={2}
          display="flex"
          alignItems="center"
          justifyContent="start"
          sx={{ mt: 2 }}
          height="30px"
        >
          <Typography variant="h5" color={'black'} noWrap>
            {`${participantsNumber} ${
              participantsNumber === 1 ? t('Participant') : t('Participants')
            }`}
          </Typography>
        </Box>
        <Divider sx={{ mx: 2 }} />
        <Box
          sx={{
            width: '100%',
            // minWidth: 300,
            height: `calc(100% - 48px)`
          }}
        >
          <Scrollbar>
            <List disablePadding>
              {contacts.map((contact, index) => {
                return (
                  <NoteContactListItem
                    key={conversation.id + contact.id + index}
                    companyFields={companyFields}
                    contact={contact}
                    conversation={conversation}
                    setSelectedContact={setSelectedContact}
                    setIsAdding={setIsAdding}
                    setIsEditing={setIsEditing}
                  />
                );
              })}
            </List>
          </Scrollbar>
        </Box>
      </Box>
    </>
  );
};

export default NoteContactDataListView;
