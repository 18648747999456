import { useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteIconTwo from '@mui/icons-material/DeleteTwoTone';
import CodeIcon from '@mui/icons-material/Code';
import { EditTwoTone } from '@mui/icons-material';
import { WebChatAccount } from '../../models/webChatAccount';
import { IconButton, Stack } from '@mui/material';
import { useAlert } from 'src/hooks/useAlert';
import { deleteWebChatAccount } from '../../../../../../../../services/webchat/domain/webChatAccount/deleteWebChatAccount';
import { ColumnDef, Row, SortingState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import { getNameContactGroup } from 'src/modules/Contacts/domain/getContactGroups';
import AuthManager from 'src/services/authentication/manager';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { getWebChatAccounts } from 'src/services/webchat/domain/webChatAccount/getWebChatAccounts';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { ContactGroup } from 'src/modules/Contacts/models/contacts';
import {
  DialTableBulkActionsContext,
  DialTableDataContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';
import { getSort } from 'src/components/DialTable/utils/getSort';
import { checkDeleteWebChatAccountPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

const useWebChatAccountActionButtons = () => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const companyId = AuthManager.getLoggedUserCompanyId();
  const [showEmbedChat, setShowEmbedChat] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [webChatAccountsToDelete, setWebChatAccountsToDelete] = useState<
    WebChatAccount[]
  >([]);
  const [contactGroupsData, setContactGroupsData] = useState<ContactGroup[]>(
    []
  );
  const initialSorting = [{ id: 'metadata.modificationDate', desc: false }];
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [selectedRows, setSelectedRows] = useState<Row<WebChatAccount>[]>([]);

  const getData = async ({
    filtersObject,
    paginationValues,
    state
  }: DialTableDataContext<WebChatAccount>) => {
    const sort = getSort(state?.sorting);
    try {
      const data = await getWebChatAccounts({
        name: filtersObject['name']?.[0] ?? undefined,
        companyId: filtersObject['companyId']?.[0] ?? undefined,
        contactGroupId: filtersObject['contactGroupId']?.[0] ?? undefined,
        page: paginationValues.page,
        size: paginationValues.size,
        sortBy: sort?.sort,
        sortDir: sort?.direction
      });
      return data;
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
  };

  const deleteAccounts = async (webChatStyles: WebChatAccount[]) => {
    try {
      const deleteWebChatStylePromises = webChatStyles.map((wcs) =>
        deleteWebChatAccount(wcs.id)
      );
      await Promise.all(deleteWebChatStylePromises);
      showAlert(t('WebChat accounts deleted succesfully'), 'success', 3000);
      emitCustomEvent(REFETCH_DATA_EVENT_NAME);
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
    setWebChatAccountsToDelete([]);
    setIsOpenDeleteModal(false);
    selectedRows.map((row) => row.toggleSelected());
    setSelectedRows([]);
  };

  const getColumns: () => Promise<ColumnDef<WebChatAccount>[]> = async () => [
    {
      accessorKey: 'name',
      id: 'name',
      header: t('Name')
    },
    {
      accessorKey: 'contactGroupId',
      id: 'contactGroupId',
      header: t('Contact group'),
      cell: (props) => (
        <>
          {getNameContactGroup(props.getValue() as string, contactGroupsData)}
        </>
      )
    }
  ];

  const getRowActions = ({
    rowData: chat,
    hasAccess
  }: DialTableRowActionsContext<WebChatAccount>) => [
    <IconButton
      key="embedChat"
      title={t('Embed chat')}
      size="small"
      color="secondary"
      onClick={() => {
        setSelected(chat);
        setShowEmbedChat(true);
      }}
    >
      <CodeIcon fontSize="small" />
    </IconButton>,
    <IconButton
      key="editAccount"
      title={t('Edit account')}
      size="small"
      color="secondary"
      onClick={async () => {
        setShowCreateAccount(true);
        setSelected(chat);
      }}
    >
      <EditTwoTone fontSize="small" />
    </IconButton>,
    <IconButton
      key="deleteAccount"
      title={t('Delete account')}
      size="small"
      color="error"
      disabled={!hasAccess(checkDeleteWebChatAccountPermissions)}
      onClick={async () => {
        setWebChatAccountsToDelete([chat]);
        setIsOpenDeleteModal(true);
      }}
    >
      <DeleteIconTwo fontSize="small" />
    </IconButton>
  ];

  const getBulkActions = ({
    selectedData: selectedAccounts,
    selectedRowModel
  }: DialTableBulkActionsContext<WebChatAccount>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          color="error"
          title={t('Delete one or several chats')}
          size="small"
          onClick={() => {
            setWebChatAccountsToDelete([...selectedAccounts]);
            setIsOpenDeleteModal(true);
            setSelectedRows(selectedRowModel.rows);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  const getAvailableFilters = () => {
    const filters: DialTableFilter<WebChatAccount>[] = [
      {
        fieldType: FieldType.TEXT,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'name',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null
      },
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'companyId',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: DataCategory.COMPANY_ID
      },
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'contactGroupId',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: DataCategory.CONTACT_GROUP_ID
      }
    ];
    return Promise.resolve(filters);
  };

  return {
    companyId,
    showEmbedChat,
    setShowEmbedChat,
    selected,
    setSelected,
    showCreateAccount,
    setShowCreateAccount,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    webChatAccountsToDelete,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    deleteAccounts,
    setContactGroupsData,
    getData,
    sorting,
    setSorting
  };
};

export default useWebChatAccountActionButtons;
