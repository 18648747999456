import axios, { AxiosRequestConfig } from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';
import { replaceURLParams } from 'src/modules/Shared/utils/apiFunctions';
import { BASE_MS, BASE_URL } from './constants';
import { Ticket, TicketFieldsValues, TicketType } from '../models/tickets';
import AuthManager from 'src/services/authentication/manager';
import { convertBlobToFile } from 'src/services/utils';
import qs from 'qs';
import {
  TICKETS,
  TICKETS_ATTACHMENT_FIELD,
  TICKETS_ID
} from 'src/utils/apiRoutes/msMessaging';

export const getTicketsApi = async (filters: {
  assignedUserIds?: string[];
  companyId: string;
  contactIds?: string[];
  fields?: TicketFieldsValues;
  friendlyId?: number;
  maxCreationDate?: string;
  minCreationDate?: string;
  page?: number;
  resolution?: TicketType;
  serviceId?: string;
  size?: number;
  sortDirection?: DirectionType;
  sortFields?: string[];
  subResolutionIds?: string[];
  tagIds?: string;
  title?: string;
}): Promise<PaginatedElements<Ticket>> => {
  const config: AxiosRequestConfig = {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' })
  };
  const resp = await axios.get(TICKETS, config);
  return resp.data;
};

export const getTicketByIdApi = async (id: string): Promise<Ticket> => {
  const path = replaceURLParams(TICKETS_ID, {
    '{id}': id
  });
  const resp = await axios.get(path);
  return resp.data;
};

export const getTicketAttachmentApi = async (params: {
  ticketId: string;
  fileId: string;
  fileName?: string;
}): Promise<File> => {
  const { ticketId, fileId, fileName } = params;
  const headers = {
    Authorization: 'Bearer ' + AuthManager.getToken()
  };
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: headers,
    credentials: 'omit'
  };
  const name = fileName || new Date().getTime().toString();
  const path = replaceURLParams(TICKETS_ATTACHMENT_FIELD, {
    '{iticketId}': ticketId,
    '{fileId}': fileId
  });
  const res = await fetch(path, requestOptions);
  const blob = await res.blob();
  const attachment = convertBlobToFile(blob, name);
  return attachment;
};
