import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePreviewMessage } from './hooks/usePreviewMessage';
import { CardWrapperPrimary } from 'src/modules/Dashboard/views/Inbox/TelegramPanel/ChatMessageWrapperCard';
import ChatMessageButton from 'src/modules/Dashboard/views/Inbox/TelegramPanel/ChatMessageButton';
import { MessageButton } from 'src/models/conversations/messages';

interface Props {
  icon?: JSX.Element;
  title: string;
  subtitle: string;
  body: string;
  variablesBody: {
    variable: string;
    value: string;
  }[];
  buttons?: MessageButton[];
}

const PreviewMessage: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  body,
  variablesBody,
  buttons
}) => {
  const { t } = useTranslation();
  const { bodyWithValues, markedWords } = usePreviewMessage(
    body,
    variablesBody
  );
  const theme = useTheme();

  const HighlightText = ({ text, markedWords }) => {
    //Regular expression that matches markedWords
    const regex = new RegExp(`(${markedWords.join('|')})`, 'gi');

    //split text by marked words
    const parts = text.split(regex);

    return (
      <span>
        {parts.map((part, index) =>
          markedWords.includes(part) ? (
            <span
              style={{
                backgroundColor: '#cceded',
                borderRadius: '4px',
                padding: '2px 4px',
                color: '#000000'
              }}
            >
              {part.replace('<', '').replace('>', '')}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Card
        sx={{
          width: '100%',
          minHeight: '250px',
          background: '#F6F8FB',
          border: '0.5px solid #10103F80'
        }}
      >
        <CardHeader
          sx={{
            background: '#FFFFFF',
            '& .MuiCardHeader-title': {
              color: theme.colors.success.main,
              fontSize: '17px',
              fontWeight: 500,
              lineHeight: '20.15px'
            },
            '& .MuiCardHeader-subheader': {
              color: theme.colors.primary.main,
              fontSize: '16px',
              fontWeight: 700
            }
          }}
          avatar={
            icon ? (
              <Avatar sx={{ background: '#FFFFFF' }} aria-label="recipe">
                {icon}
              </Avatar>
            ) : null
          }
          title={t(title)}
          subheader={t(subtitle)}
        />
        <Divider />
        <CardContent
          sx={{
            maxHeight: '500px',
            overflowY: 'auto'
          }}
        >
          <Box
            sx={{
              display: 'flex',

              padding: '5px',
              columnGap: 1
            }}
          >
            <CardWrapperPrimary
              sx={{
                flex: 1
              }}
            >
              <Box
                color="inherit"
                width={'100%'}
                paddingTop={0.5}
                sx={{ overflowWrap: 'break-word' }}
              >
                <Typography variant="h5" color={'inherit'}>
                  {HighlightText({ text: bodyWithValues, markedWords })}
                </Typography>

                {buttons && <ChatMessageButton buttons={buttons} />}
              </Box>
            </CardWrapperPrimary>
            <Avatar
              variant="circular"
              sx={{
                width: 42,
                height: 42,
                background: theme.colors.secondary.main
              }}
              alt={'A'}
            >
              <Typography variant="h3" color="inherit">
                A
              </Typography>
            </Avatar>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PreviewMessage;
