import {
  $applyNodeReplacement,
  DOMConversion,
  DOMConversionMap,
  DOMExportOutput,
  DecoratorNode,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedElementNode,
  SerializedLexicalNode,
  Spread
} from 'lexical';
import { ImagePayload } from './ImagePlugin';
import ImageComponent from './ImageComponent';
import GeneralImageRenderer from 'src/components/LexicalNodes/GeneralImageNode/GeneralImageNodeRenderer';

export type SerializedInlineImageNode = Spread<
  {
    src: string;
    altText: string;
    file: File;
    width: 'inherit' | number | string;
    height: 'inherit' | number | string;
    maxWidth: string | number;
  },
  SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  src: string;
  altText: string;
  file: File;
  width: 'inherit' | number | string;
  height: 'inherit' | number | string;
  maxWidth: string | number;

  static getType(): string {
    return 'ImageNode';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.src,
      node.altText,
      node.maxWidth,
      node.file,
      node.width,
      node.height,
      node.__key
    );
  }

  constructor(
    src: string,
    altText: string,
    maxWidth: number | string,
    file?: File,
    width?: 'inherit' | number | string,
    height?: 'inherit' | number | string,
    key?: NodeKey
  ) {
    super(key);
    this.src = src;
    this.altText = altText;
    this.maxWidth = maxWidth;
    this.width = width || 'inherit';
    this.height = height || 'inherit';
    this.file = file;
  }

  init(): void {}

  createDOM(): HTMLElement {
    const span = document.createElement('span');
    return span;
  }

  updateDOM(prevNode: ImageNode, dom: HTMLElement): boolean {
    return false;
  }

  importDOM(): DOMConversionMap | null {
    return {
      img: (element) => {
        const src = element.getAttribute('src');
        const altText = element.getAttribute('alt');
        const width = element.getAttribute('width');
        const height = element.getAttribute('height');
        const maxWidth = element.getAttribute('style');

        return {
          conversion: (node) => {
            return {
              node: $createImageNode({
                src,
                altText,
                width,
                height,
                maxWidth,
                file: null
              })
            };
          }
        } as DOMConversion<HTMLElement>;
      }
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = document.createElement('img');
    element.setAttribute('alt', this.altText);
    element.setAttribute('width', this.width.toString());
    element.setAttribute('height', this.height.toString());
    element.setAttribute('style', `max-width: ${this.maxWidth}`);
    element.setAttribute('src', `cid:${this.file?.name ?? ''}`);
    return { element };
  }

  static importJSON(serializedNode: SerializedInlineImageNode): ImageNode {
    const { altText, height, width, src, file, maxWidth, type, version } =
      serializedNode;
    const node = $createImageNode({
      altText,
      height,
      maxWidth,
      src,
      file,
      width
    });
    return node;
  }

  exportJSON(): SerializedInlineImageNode {
    return {
      src: this.src,
      altText: this.altText,
      file: this.file,
      width: this.width,
      height: this.height,
      maxWidth: this.maxWidth,
      type: ImageNode.getType(),
      version: 1
    };
  }

  decorate(): JSX.Element {
    return <GeneralImageRenderer node={this} />;
  }

  setFile(file: File): void {
    const writable = this.getWritable();
    writable.file = file;
  }

  setSrc(src: string): void {
    const writable = this.getWritable();
    writable.src = src;
  }

  setWidthAndHeight(
    width: 'inherit' | number | string,
    height: 'inherit' | number | string
  ): void {
    const writable = this.getWritable();
    writable.width = width;
    writable.height = height;
  }

  getFile(): File {
    return this.file;
  }

  getSrc(): string {
    return this.src;
  }

  getAltText(): string {
    return this.altText;
  }

  getWidth(): string | number {
    return this.width;
  }

  getHeight(): string | number {
    return this.height;
  }

  getMaxWidth(): string | number {
    return this.maxWidth;
  }
}

export function $createImageNode({
  altText,
  height,
  maxWidth = '100%',
  src,
  file,
  width,
  key
}: ImagePayload): ImageNode {
  return $applyNodeReplacement(
    new ImageNode(src, altText, maxWidth, file, width, height, key)
  );
}

export function $removeImageNode(node: ImageNode): void {
  node.remove();
}

export function $isImageNode(
  node: LexicalNode | null | undefined
): node is ImageNode {
  return node instanceof ImageNode;
}
