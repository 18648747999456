import { Autocomplete, Stack, TextField } from '@mui/material';
import { StyleAccordion } from '../../../components/Style/StyleAccordion';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../models/webChatStyle';
import BaseAccordion from './ContactAccordion/BaseAccordion';
import ContactFieldsAccordion from './ContactAccordion/ContactFieldsAccordion';
import OpenChatAccordion from './ContactAccordion/OpenChatAccordion';
import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';

interface StyleModifiersProps {
  formikProps: FormikProps<WebChatStyle>;
  setLogoImage: Dispatch<SetStateAction<File>>;
  setUpdatedLogo: Dispatch<SetStateAction<boolean>>;
}

const StyleModifiers: React.FC<StyleModifiersProps> = ({
  formikProps,
  setLogoImage,
  setUpdatedLogo
}) => {
  return (
    <Stack
      direction="column"
      px={1}
      py={1}
      overflow={{ xs: 'visible', md: 'scroll' }}
    >
      <Autocomplete
        sx={{ margin: '5px', marginBottom: '10px' }}
        value={formikProps.values.contactStyle.font}
        onChange={(e, value) => {
          formikProps.setFieldValue('contactStyle.font', value);
        }}
        options={[
          'Arial',
          'Helvetica',
          'Calibri',
          'Times New Roman',
          'Cambria',
          'Verdana'
        ]}
        renderInput={(params) => (
          <TextField {...params} label={t('Font')}></TextField>
        )}
      ></Autocomplete>
      <StyleAccordion title={t('Base')}>
        <BaseAccordion
          formikProps={formikProps}
          setLogoImage={setLogoImage}
          setUpdatedLogo={setUpdatedLogo}
        />
      </StyleAccordion>
      <StyleAccordion title={t('Contact fields')}>
        <ContactFieldsAccordion formikProps={formikProps} />
      </StyleAccordion>
      <StyleAccordion title={t('Open chat')}>
        <OpenChatAccordion formikProps={formikProps} />
      </StyleAccordion>
    </Stack>
  );
};

export default StyleModifiers;
