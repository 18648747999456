import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode } from 'react';

export const AccordionSummaryWrapper = styled(AccordionSummary)(({ theme }) => {
  return {
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&::before': {
      display: 'none'
    },

    '.MuiSvgIcon-root': {
      transition: `${theme.transitions.create(['color'])}`
    },

    '&.MuiButtonBase-root': {
      '&.Mui-expanded, &:hover': {
        background: `${theme.colors.alpha.black[10]}`
      },
      '.MuiSvgIcon-root': {
        color: ` ${theme.colors.primary.main}`
      }
    }
  };
});

interface ContactDataAccordionProps {
  title: string;
  children: ReactNode;
}

const HelperAccordion = (props: ContactDataAccordionProps) => {
  const { title, children } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        '& AccordionStyled': {
          margin: '0px !important'
        }
      }}
    >
      <Accordion defaultExpanded disableGutters>
        <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{title}</Typography>
        </AccordionSummaryWrapper>
        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default HelperAccordion;
