import axios from 'axios';
import { queryClient } from 'src/utils/queryClient';
import { replaceURLParams } from 'src/services/utils';
import { TicketField } from '../../models/tickets';
import { TICKET_FIELDS_ID } from 'src/utils/apiRoutes/msMessaging';

export const updateTicketFieldApi = async (
  ticketField: TicketField
): Promise<TicketField> => {
  const path = replaceURLParams(TICKET_FIELDS_ID, {
    '{id}': ticketField.id
  });
  const resp = await axios.put(path, ticketField);

  queryClient.invalidateQueries({
    queryKey: ['ticketFields']
  });

  return resp.data;
};
