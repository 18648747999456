import {
  dummyConversation,
  dummyConversationFichaje
} from 'src/mocks/conversation';
import {
  Conversation,
  OrderEmail
} from 'src/models/conversations/conversations';
import {
  getCurrentConversationIdStore,
  getCurrentMessageChannelStore,
  getCurrentMessageIdStore
} from '../../infraestructure/store/getCurrentConversationIdStore';
import { getConversation } from './getConversation';
import { useSelector } from 'src/store/store';

/** GET CURRENT CONVERSATION
 *
 * Filters the currently stored convesation and returns the first one that satisfies
 * the specified conditions.
 *
 * @param filter Parameters to filter the currently stored conversations by
 * @returns n Conversation
 */
export const getCurrentConversation = (
  shouldUseHook: boolean
): Conversation => {
  const id = getCurrentConversationIdStore(shouldUseHook);

  if (shouldUseHook)
    return useSelector((state) => state.socialmedia.conversations[id]);

  const conversation = getConversation({
    id: id || 'none',
    shouldUseHook: shouldUseHook
  });

  if (id === 'hotline') return dummyConversation;
  if (id === 'fichaje') return dummyConversationFichaje;

  return conversation;
};

/** GET CURRENT CONVERSATION ORDER BY ASC OR DESC
 *
 * Filters the currently stored convesation and returns the first one that satisfies
 * the specified conditions.
 *
 * @param filter Parameters to filter the currently stored conversations by
 * @returns n Conversation
 */
export const getOrderCurrentConversation = (
  conversation: Conversation,
  typeOrder: OrderEmail,
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
): Conversation => {
  let conversationCopy: Conversation = { ...conversation };
  let copyMessage;

  if (
    typeOrder !== undefined &&
    conversation.messages?.length > 0 &&
    conversation.channel === 'Email'
  ) {
    copyMessage = [...conversation.messages];
    if (typeOrder === 'asc') {
      conversationCopy.messages = copyMessage?.sort(function (a, b) {
        if (
          new Date(a.metadata.creationDate).getTime() >
          new Date(b.metadata.creationDate).getTime()
        ) {
          return -1;
        }
        if (
          new Date(a.metadata.creationDate).getTime() <
          new Date(b.metadata.creationDate).getTime()
        ) {
          return 1;
        }
        return 0;
      });
    }
    if (typeOrder === 'desc') {
      conversationCopy.messages = copyMessage?.sort(function (a, b) {
        if (
          new Date(a.metadata.creationDate).getTime() <
          new Date(b.metadata.creationDate).getTime()
        ) {
          return -1;
        }
        if (
          new Date(a.metadata.creationDate).getTime() >
          new Date(b.metadata.creationDate).getTime()
        ) {
          return 1;
        }
        return 0;
      });
    }
  } else {
    conversationCopy = conversation;
  }

  setIsSending(false);

  return conversationCopy;
};

/** GET CURRENT MESSAGE ID
 *
 * Get current message Id
 *
 * @param filter Parameters to filter the currently stored conversations by
 * @returns Id
 */
export const getCurrentMessageId = (shouldUseHook: boolean): string[] => {
  const messageId = getCurrentMessageIdStore(shouldUseHook);
  return messageId;
};

/** GET CURRENT CONVERSATION CHANNEL
 *
 * Get current conversation channel
 *
 * @param filter Parameters to filter the currently stored conversations by
 * @returns Id
 */
export const getCurrentMessageChannel = (shouldUseHook: boolean): string => {
  const messageChannel = getCurrentMessageChannelStore(shouldUseHook);
  return messageChannel;
};
