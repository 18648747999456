import axios, { AxiosError, AxiosResponse } from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import {
  ASTERISK_MS,
  CALLS_ATTENDED_TRANSFER
} from 'src/utils/apiRoutes/msAsterisk';

export const attendedTransferCallApi: Function = (
  callId: string,
  number: string,
  instance: string,
  isInternal: boolean,
  campaignId?: string
) => {
  const attendedTransferCallPath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) + CALLS_ATTENDED_TRANSFER;

  const path = replaceURLParams(attendedTransferCallPath, { '{id}': callId });
  const params = { destExtension: number, isInternal, campaignId };

  return axios
    .post(path, null, { params })
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
};
