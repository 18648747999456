import { Conversation } from 'src/models/conversations/conversations';
import { RTQueue } from 'src/models/queue';

export function addConversationToRTQueueAction(
  conversation: Conversation,
  queue: RTQueue
) {
  const campaignId = conversation.campaignId;
  if (!campaignId || !queue) return;

  if (!queue) return;

  queue.conversations = queue.conversations || [];
  const exists = queue.conversations.some(
    (conv) => conv.id === conversation.id
  );
  if (exists) return;
  queue.conversations.push(conversation);
}

export function removeConversationFromRTQueueAction(
  conversation: Conversation,
  queue: RTQueue
) {
  const campaignId = conversation.campaignId;
  if (!campaignId) return;
  if (!queue) return;

  queue.conversations = queue.conversations || [];
  queue.conversations = queue.conversations.filter(
    (conv) => conv.id !== conversation.id
  );
}
