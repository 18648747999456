import { Conversation } from 'src/models/conversations/conversations';
import { ChatMessage } from 'src/models/conversations/messages';
import getMessageById from 'src/services/socialmedia/domain/messages/getMessageById';
import { logger } from 'src/utils/logger';

/**
 * Try to find the message in the messages of the conversation. If it is not found, it is requested to the API.
 * @param param0
 * @returns
 */
export const getReplyMessage = async ({
  conversation,
  message
}: {
  conversation: Conversation;
  message: ChatMessage;
}) => {
  // message is what you have, msg what you want
  // Find message
  let msg = conversation?.messages.find(
    (m) => m.messageId === message.replyTo
  ) as ChatMessage;

  if (!msg) {
    // Request message
    try {
      msg = await getMessageById({
        conversationId: conversation?.id,
        messageId: message?.replyTo
      });
    } catch (error) {
      logger.error(error);
      return null;
    }
  }
  // Get users or contacts name
  const user = conversation.involvedContacts.find(
    (c) => c.socialmediaAgent.chatId === msg?.senderId
  );
  const reply = {
    ...msg,
    type: msg?.type,
    content: msg?.content,
    replyUser: user?.socialmediaAgent
      ? user?.socialmediaAgent.firstName
      : user?.contactId
  };
  return reply;
};
