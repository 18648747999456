export const getDialTableFilterLabel = (fieldName: string) => {
  return fieldNameLabelObject?.[fieldName] ?? fieldName;
};

const fieldNameLabelObject = {
  'contact.metadata.creationDate': 'Contact creation date',
  'contact.metadata.modificationDate': 'Contact modification date',
  'conversationSummaries._id': 'Conversation tickets',
  'involvedContacts.contactId': 'Contacts',
  'involvedContacts.socialmediaAgent.firstName': 'Telegram name',
  'involvedContacts.socialmediaAgent.lastName': 'Telegram last name',
  'involvedContacts.socialmediaAgent.phoneNumber': 'Telegram phone number',
  'involvedContacts.socialmediaAgent.userName': 'Telegram username',
  'subResolution.$id': 'Subtipolgy',
  'ticket.friendlyId': 'Ticket id',
  'ticket.metadata.creationDate': 'Ticket creation date',
  'ticket.metadata.modificationDate': 'Ticket modification date',
  'originAgent.firstName': 'Agent first name',
  'originAgent.lastName': 'Agent last name',
  'originAgent.phoneNumber': 'Agent phone number',
  'conversation.metadata.creationDate': 'Conversation creation date',
  'conversation.metadata.modificationDate': 'Conversation modification date',
  accountId: 'Account ID',
  ticketIds: 'Ticket IDs',
  channel: 'Channel',
  status: 'Status',
  campaignId: 'Campaign',
  creationDate: 'Creation date',
  callMode: 'Call mode',
  hangupCode: 'Hang up code',
  recorded: 'Recorded?',
  originNumber: 'Origin number',
  destinationNumber: 'Destination number',
  ddi: 'DDI',
  callDuration: 'Call duration',
  overallDuration: 'Overall duration',
  waitingTime: 'Waiting time',
  friendlyId: 'Friendly ID',
  serviceDataServiceId: 'Service',
  agentDataUserId: 'Agent',
  dialerbotDataScheduleDatabaseId: 'Call launch database',
  dialerbotDataFilename: 'Call launch file name',
  dialerbotDataUploadDate: 'Call launch file upload date',
  dialerbotDataUploadStatus: 'Call launch file status',
  companyId: 'Company',
  serviceId: 'Service',
  tagIds: 'Ticket tags',
  tags: 'Contact tags',
  title: 'Title',
  reason: 'Reason',
  duration: 'Duration',
  timeStamp: 'Timestamp',
  currentStatusType: 'Current status type',
  currentStatusName: 'Current status name',
  previousStatusType: 'Previous status type',
  previousStatusName: 'Previous status name',
  extensionId: 'Extension',
  serviceIds: 'Services',
  userId: 'Usuario'
};
