import {
  ConversationsCountFilter,
  getConversationsCountApi
} from '../infrastructure/getConversationsCountApi';
import setConversationsCountStore from '../../socialmedia/domain/conversation/setConversationsCountStore';
import { getRTUserIds } from 'src/services/rtusers/domain/rtuser/getRTUser';
import {
  ChannelType,
  UsersConversationCount
} from 'src/models/conversations/conversations';
import { SocialmediaConversationMessage } from '../../socialmedia/application/websocket/websocket';
import { getFullConversationCount } from '../../socialmedia/domain/conversation/getUserConversationCount';
import { logger } from 'src/utils/logger';

/**
 *
 * @param disableApi If true, it will not call the API and the count calculation will be done on the client side.
 * @returns
 */

export async function refreshRTUsersConversationsCount(
  userIds = getRTUserIds()
) {
  if (!userIds.length) return;

  const channels = Object.values(ChannelType);

  const oldConversationsCount = getFullConversationCount();

  const conversationsCount = await getConversationsCountApi({
    userIds,
    channels
  });

  setConversationsCountStore({
    ...oldConversationsCount,
    ...conversationsCount
  });

  return conversationsCount;
}

const emptyChannelCount = {
  [ChannelType.CALL]: 0,
  [ChannelType.EMAIL]: 0,
  [ChannelType.TELEGRAM]: 0,
  [ChannelType.WHATSAPP]: 0,
  [ChannelType.WEBCHAT]: 0,
  [ChannelType.INSTAGRAM]: 0
};

export async function calculateConversationsCount(
  message: SocialmediaConversationMessage
) {
  logger.log(
    '[COUNT] Conversations count calculation triggered due to new WS message: ',
    message
  );
  const conversationsCount: UsersConversationCount = getFullConversationCount();
  logger.log('[COUNT] Current conversations count: ', conversationsCount);
  let newConversationsCount = {
    ...conversationsCount
  };

  for (const conversation of message.conversations) {
    const userIds = conversation.assignedUserIds;

    for (const userId of userIds) {
      const conversationChannel = conversation.channel;
      const prevUserChannelsCount =
        conversationsCount[userId] || emptyChannelCount;
      const channelCount = prevUserChannelsCount[conversationChannel];

      const updatedChannelCount = () => {
        if (channelCount === 51) return 51;
        if (conversation.status === 'Assigned') return channelCount + 1;
        if (channelCount !== 0) return channelCount - 1;
        return 0;
      };

      newConversationsCount = {
        ...newConversationsCount,
        [userId]: {
          ...prevUserChannelsCount,
          [conversationChannel]: updatedChannelCount()
        }
      };
    }
  }

  // Para no hacer un set de un objeto vacío
  if (Object.keys(newConversationsCount).length === 0) return;

  logger.log('[COUNT] New conversations count: ', newConversationsCount);

  setConversationsCountStore(newConversationsCount);
}

export async function refreshConversationsCountStore(
  filter: ConversationsCountFilter
) {
  const conversationsCount = await getConversationsCountApi(filter);
  setConversationsCountStore(conversationsCount);

  return conversationsCount;
}
