import { RTUser } from 'src/models/rtUser';
import { getRTUsers, GetRTUsersFilter } from './getRTUsers';
import { getRTUserStore } from '../../infraestructure/store/getRTUsersStore';
import store, { useSelector } from 'src/store/store';

/** GET ONE CONVERSATION
 *
 * Filters the currently stored convesation and returns the first one that satisfies
 * the specified conditions.
 *
 * @param filter Parameters to filter the currently stored conversations by
 * @returns n Conversation
 */
export const getRTUser = (filter: GetRTUsersFilter): RTUser => {
  const rtusers = getRTUsers(filter);

  return rtusers[0];
};

export const getRTUserIds = () => {
  return store.getState().rtusers.rtUserIds;
};

export const getRTUserById = (userId: string) => {
  return store.getState().rtusers.rtUsers[userId];
};

/** GET CURRENT RTUSER FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 * @param idUser string
 *
 * @returns User
 */
export const getCurrentRTUser = (shouldUseHook: boolean, idUser: string) =>
  getRTUserStore(shouldUseHook, idUser);

export const useUserSelector = (userId: string) => {
  return useSelector((state) => state.rtusers.rtUsers[userId]);
};
