import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Conversation,
  ConversationNote
} from 'src/models/conversations/conversations';

import { Helmet } from 'react-helmet-async';

import { Box, Button, styled, useTheme, Tooltip, Avatar } from '@mui/material';
import Note from './Note';
import {
  getNotes,
  getNotesContact
} from 'src/modules/Contacts/domain/getNotes';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Contact } from 'src/modules/Contacts/models/contacts';
import Scrollbar from 'src/components/Scrollbar';

const ButtonAddAction = styled(Button)(
  ({ theme }) => `
        border: ${theme.colors.secondary.main} dashed 1px;
        color: ${theme.colors.secondary.main};
        transition: ${theme.transitions.create(['all'])};
        justify-content: center;
        align-items: center;
        display: flex;

        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[10]};
        color: ${theme.colors.secondary.main};
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
`
);

interface Props {
  conversation?: Conversation;
  contact?: Contact;
  filterNote: 'contactsNotes' | 'conversationsNotes';
}

const NoteListView = (props: Props) => {
  const { conversation, contact, filterNote } = props;
  const { t }: { t: any } = useTranslation();

  const [notes, setNotes] = useState<ConversationNote[]>([]);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const theme = useTheme();

  const handleAdd = () => {
    setIsAdd(!isAdd);
  };

  useEffect(() => {
    if (filterNote === 'conversationsNotes') {
      getNotes(conversation.id).then((response) => {
        setNotes(response.reverse());
      });
    } else {
      if (!!contact) {
        getNotesContact(contact.id).then((response) => {
          setNotes(response.reverse());
        });
      }
    }
  }, [conversation, contact]);

  return (
    <>
      <Helmet>
        <title>Helper Panel - Notes</title>
      </Helmet>

      <Box
        sx={{
          minWidth: 300,
          width: '100%',
          height: '100%'
        }}
      >
        {filterNote === 'conversationsNotes' && (
          <Box
            sx={{
              mx: 4,
              my: 2,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Tooltip arrow title={t('Click to add a new note')}>
              <ButtonAddAction
                disableRipple={false}
                disableTouchRipple={false}
                sx={{
                  width: '100%'
                }}
                onClick={handleAdd}
                id="btnAdd-NotesPanel"
                disabled={isAdd}
              >
                <AvatarAddWrapper>
                  <AddTwoToneIcon fontSize="medium" />
                </AvatarAddWrapper>
              </ButtonAddAction>
            </Tooltip>
          </Box>
        )}
        <Box
          height={
            filterNote === 'conversationsNotes' ? 'calc(100% - 92px)' : '100%'
          }
        >
          <Scrollbar>
            {isAdd && (
              <Note
                conversationNote={{
                  contactId: '',
                  conversationInfo: {
                    channel: conversation.channel,
                    conversationId: conversation.id,
                    creationDate: conversation.metadata.creationDate
                  },
                  content: '',
                  title: '',
                  id: '',
                  metadata: {
                    creationDate: '',
                    modificationDate: ''
                  }
                }}
                setNoteToView={setNotes}
                isAddNote={true}
                setIsAddNote={setIsAdd}
              />
            )}

            {notes.map((note: ConversationNote) => {
              return (
                <Note
                  conversationNote={note}
                  key={note.id + note?.metadata?.modificationDate}
                  setNoteToView={setNotes}
                />
              );
            })}
          </Scrollbar>
        </Box>
      </Box>
    </>
  );
};

export default NoteListView;
