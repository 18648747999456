import { forwardRef } from 'react';
import { Close, Refresh } from '@mui/icons-material';
import { Alert, AlertTitle, IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  version?: string;
  onClose?: () => void;
}

export const NewVersionAlert = forwardRef<HTMLDivElement, Props>(
  ({ version, onClose }: Props, ref) => {
    const { t } = useTranslation();
    const versionText = !!version ? `(v${version})` : '';
    return (
      <Alert
        ref={ref}
        key={`alert-${version}`}
        severity="warning"
        action={
          <Stack direction="row" spacing={0.5}>
            <IconButton
              size="small"
              title={t('Reload this page')}
              onClick={() => {
                window.location.reload();
              }}
              sx={{ borderRadius: 2 }}
            >
              <Refresh fontSize="small" />
            </IconButton>
            {!!onClose && (
              <IconButton
                size="small"
                title={t('Close')}
                onClick={(e) => {
                  onClose();
                }}
                sx={{ borderRadius: 2 }}
              >
                <Close fontSize="small" />
              </IconButton>
            )}
          </Stack>
        }
      >
        <AlertTitle sx={{ fontWeight: 'bold' }}>
          {t('New available version!')}
        </AlertTitle>
        {`${t('Reload this page to get the latest version')} ${versionText}`}
      </Alert>
    );
  }
);
