import dayjs from 'dayjs';

export const getCorrectHeaderNames = (header: string) => {
  switch (header) {
    case 'currentExecutionStatus':
      return 'STATUS';
    case 'startDate':
      return 'LAUNCH DATE';
    case 'executionProgress':
      return 'PROGRESS';
    case 'enabled':
      return 'STATUS';
    default:
      return header;
  }
};

export const convertEpochTimeToString = (epochTime) => {
  let date = new Date(epochTime * 1000);
  return date.toLocaleString().split(' ')[0].replace(',', '');
};

export const convertDateArrayToString = (dateArray) => {
  let date = dayjs(dateArray);
  return date.format('DD/MM/YYYY');
};
