import { Divider, Stack, Typography, useTheme } from '@mui/material';
import {
  LocalizationProvider,
  MultiSectionDigitalClock
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import './styles.css';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  value?: Value;
  onChange?: (value: Value) => void;
  selectRange?: boolean;
}

export const DateTimeCalendarPicker = ({
  value,
  onChange,
  selectRange
}: Props) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const startDateValue = Array.isArray(value)
    ? dayjs(value?.[0])
    : dayjs(value);
  const endDateValue = Array.isArray(value) ? dayjs(value?.[1]) : dayjs(value);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Stack direction="row" spacing={1} alignItems="center" px={1}>
        <Calendar value={value} onChange={onChange} selectRange={selectRange} />
        <Divider orientation="vertical" />
        <Stack pb={1} pt={2}>
          <Typography align="center">{t('Start time')}</Typography>
          <Stack
            borderLeft={1}
            borderRight={1}
            borderTop={1}
            borderColor={theme.colors.alpha.black[10]}
          >
            <MultiSectionDigitalClock
              ampm={false}
              timeSteps={{ minutes: 1 }}
              value={startDateValue}
              onChange={(valueDayjs: Dayjs) => {
                const hours = valueDayjs.get('hour');
                const minutes = valueDayjs.get('minute');
                const seconds = valueDayjs.get('second');
                let newValue: Value;
                if (Array.isArray(value)) {
                  newValue = [...value];
                  newValue[0].setHours(hours);
                  newValue[0].setMinutes(minutes);
                  newValue[0].setSeconds(seconds);
                } else {
                  newValue = new Date(value);
                  newValue.setHours(hours);
                  newValue.setMinutes(minutes);
                  newValue.setSeconds(seconds);
                }
                onChange(newValue as Value);
              }}
              slotProps={{
                digitalClockSectionItem: {
                  sx: {
                    borderRadius: 0,
                    '&.Mui-selected': {
                      color: theme.colors.alpha.white[100],
                      backgroundColor: theme.colors.secondary.light
                    },
                    '&.Mui-selected:hover': {
                      color: theme.colors.alpha.white[100],
                      backgroundColor: theme.colors.secondary.light
                    }
                    // ':hover': {
                    //   color: theme.colors.alpha.black[100],
                    //    backgroundColor: theme.colors.secondary.lighter
                    // }
                  }
                }
              }}
            />
          </Stack>
        </Stack>
        {selectRange && (
          <>
            <Stack pb={1} pt={2}>
              <Typography align="center">{t('End time')}</Typography>
              <Stack
                borderLeft={1}
                borderRight={1}
                borderTop={1}
                borderColor={theme.colors.alpha.black[10]}
              >
                <MultiSectionDigitalClock
                  ampm={false}
                  timeSteps={{ minutes: 1 }}
                  value={endDateValue}
                  onChange={(valueDayjs: Dayjs) => {
                    const hours = valueDayjs.get('hour');
                    const minutes = valueDayjs.get('minute');
                    const seconds = valueDayjs.get('second');
                    let newValue: Date[] = [];
                    if (Array.isArray(value)) {
                      newValue = [...value];
                    }
                    newValue[1].setHours(hours);
                    newValue[1].setMinutes(minutes);
                    newValue[1].setSeconds(seconds);

                    onChange(newValue as Value);
                  }}
                  slotProps={{
                    digitalClockSectionItem: {
                      sx: {
                        borderRadius: 0,
                        '&.Mui-selected': {
                          color: theme.colors.alpha.white[100],
                          backgroundColor: theme.colors.secondary.light
                        },
                        '&.Mui-selected:hover': {
                          color: theme.colors.alpha.white[100],
                          backgroundColor: theme.colors.secondary.light
                        }
                        // ':hover': {
                        //   color: theme.colors.alpha.black[100],
                        //    backgroundColor: theme.colors.secondary.lighter
                        // }
                      }
                    }
                  }}
                />
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </LocalizationProvider>
  );
};
