import { ChevronLeft, Close } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { t } from 'i18next';
import { WebChatStyle } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatStyle';

interface webChatHeaderProps {
  formikProps: WebChatStyle;
}

export const ContactInformationHeader: React.FC<webChatHeaderProps> = ({
  formikProps
}) => {
  return (
    <Stack
      direction="row"
      height="73px"
      px={2}
      py={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        background:
          formikProps?.conversationStyle.titleColor.color ?? '#FFFFFF',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box'
      }}
    >
      <label
        style={{
          fontWeight: 'bold',
          fontSize: '17px',
          fontFamily: `${formikProps.contactStyle.font}`,
          color: formikProps?.contactStyle.headerTextColor.color ?? '#1975FF'
        }}
      >
        {t('Contact information')}
      </label>

      <Stack direction="row" alignItems={'center'}>
        <IconButton
          sx={{
            color:
              formikProps?.conversationStyle.controlsColor.color ?? '#1975FF',
            rotate: '-90deg'
          }}
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          sx={{
            color:
              formikProps?.conversationStyle.controlsColor.color ?? '#1975FF'
          }}
        >
          <Close sx={{ width: '24px', height: '24px' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};
