import { WebRTCSession } from 'src/store/slices/calls/types';
import store, { useSelector } from 'src/store/store';

export const getIsMutedStore = (shouldUseHook: boolean) => {
  const isMuted = (
    shouldUseHook
      ? useSelector((state) => state.configVolume.isMuted)
      : store.getState().configVolume.isMuted
  ) as boolean;

  return isMuted;
};
