import { Conversation } from 'src/models/conversations/conversations';
import { ConversationList } from './components/ConversationList';
import { useTranslation } from 'react-i18next';
import { Contact } from 'src/modules/Contacts/models/contacts';
import { HelperTab } from 'src/modules/Dashboard/views/Inbox/HelperMenu/items';
import HelperPanelHeader from 'src/modules/Dashboard/views/Inbox/HelperPanel/components/HelperPanelHeader';

interface ConversationHistoryProps {
  conversation: Conversation;
  disableOnclick?: boolean;
  selectedContact?: Contact;
  itemsPerPage?: number;
  showHelperTabButtons?: boolean;
}

export const ConversationHistory: React.FC<ConversationHistoryProps> = ({
  conversation,
  disableOnclick,
  selectedContact,
  showHelperTabButtons,
  itemsPerPage = 5
}) => {
  const { t } = useTranslation();

  return (
    <>
      <HelperPanelHeader
        helperTab={HelperTab.CONVERSATION_HISTORY}
        showHelperTabButtons={showHelperTabButtons}
      />

      <ConversationList
        itemsPerPage={itemsPerPage}
        selectedContact={selectedContact}
        disableOnclick={disableOnclick}
      />
    </>
  );
};
