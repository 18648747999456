import { CountryCode } from 'libphonenumber-js';
import { RTCall, RTCallStatus } from 'src/models/rtcall';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import metadatas from 'libphonenumber-js/metadata.min.json';
import { logger } from 'src/utils/logger';
import { ChannelType } from 'src/models/conversations/conversations';

export const isRTCallActive = (rtCall: RTCall) => {
  if (!rtCall) return false;

  const activeRTCallStatus: RTCallStatus[] = ['Talking', 'Parked'];

  return activeRTCallStatus.includes(rtCall.status);
};

export const getCountryCodeFromOutboundCampaign = (
  campaign: Campaign
): CountryCode | null => {
  if (campaign.channel !== ChannelType.CALL) return null;
  if (
    campaign.channel === ChannelType.CALL &&
    campaign.callMode !== 'Outbound'
  ) {
    logger.error(
      `The callMode of the campaign ${campaign.id} must be 'Outbound'`
    );
    return null;
  }

  const campaignPrefix = campaign?.defaultPrefix;
  if (!campaignPrefix) {
    logger.error(`Could not find defaultPrefix in the campaign ${campaign.id}`);
    return null;
  }

  const countries = metadatas.countries;
  const countryEntry = Object.entries(countries).find((countryEntry) => {
    const [_, data] = countryEntry;
    return data[0] === campaignPrefix;
  });
  if (!countryEntry && !countryEntry?.[0]) return null;

  const [countryCode, _] = countryEntry;

  return countryCode as CountryCode;
};
