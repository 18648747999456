import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useRef, useCallback } from 'react';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import {
  Contact,
  ContactField,
  ContactGroup
} from 'src/modules/Contacts/models/contacts';
import { useInfiniteContactsQuery } from 'src/modules/Contacts/views/hooks/contactQueries';
import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';

interface Props {
  searchValue: string;
  selectedContactGroup: ContactGroup;
  setSelectedContact: (contact: Contact) => void;
  nameMainField: ContactField;
  fields: ContactField[];
}

const CONTACTS_LIST_PAGE_SIZE = 25;
export function ContactsList({
  searchValue,
  selectedContactGroup,
  setSelectedContact,
  nameMainField,
  fields
}: Props) {
  const contactsListQuery = useInfiniteContactsQuery({
    companyId: getLoggedUserCompanyId(),
    size: CONTACTS_LIST_PAGE_SIZE,
    fieldType: 'Name',
    fieldValue: searchValue.trim() !== '' ? searchValue : undefined,
    exactMatch: searchValue.trim() !== '' ? false : undefined,
    contactGroupId: selectedContactGroup?.id,
    sortDir: DirectionType.ASC,
    sortBy: nameMainField?.id
      ? (('fields.' + nameMainField?.id) as keyof Contact)
      : null
  });

  const contactListData = contactsListQuery.data?.pages ?? [];
  const contacts = contactListData.map((page) => page.elements).flat();

  const observer = useRef<IntersectionObserver | null>(null);

  const indexToFetch = Math.ceil(contacts.length - CONTACTS_LIST_PAGE_SIZE / 2);

  const lastContactElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (contactsListQuery.isFetchingNextPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && contactsListQuery.hasNextPage) {
          contactsListQuery.fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [contactsListQuery]
  );

  useEffect(() => {
    contactsListQuery.refetch();
  }, [selectedContactGroup?.id, nameMainField?.id, searchValue]);

  const theme = useTheme();
  return (
    <Stack
      direction={'column'}
      divider={<Divider orientation="horizontal" />}
      sx={{ overflowY: 'auto', pt: 1 }}
      height="100%"
    >
      {contacts?.length === 0 && !contactsListQuery.isLoading && (
        <Stack
          flexGrow={1}
          width="100%"
          flexBasis={0}
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{t('No contacts found')}</Typography>
        </Stack>
      )}
      {contactsListQuery.isLoading && (
        <Stack
          flexGrow={1}
          width="100%"
          flexBasis={0}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      )}
      {contacts?.map((contact: Contact, idx) => {
        const nameFields = fields.filter((f) => {
          return f.type === 'Name';
        });
        const names = nameFields
          .map((f) => {
            return contact.fields[f.id];
          })
          .filter((n) => !!n);
        const mainNameField = nameFields.find((f) => f.mainField);
        const mainName = contact.fields[mainNameField?.id];
        const name = mainName ?? names[0];

        const isOnIndexToFetch = idx === indexToFetch;

        return (
          <Box
            key={idx}
            ref={isOnIndexToFetch ? lastContactElementRef : null}
            sx={{
              cursor: 'pointer',
              borderRadius: '5px',
              padding: '9px 15px',
              '&:hover': {
                background: `${theme.colors.secondary.lighter}`
              }
            }}
            onClick={(): void => {
              setSelectedContact(contact);
            }}
          >
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <Stack
                sx={{
                  backgroundColor: theme.colors.secondary.main,
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  color: 'white'
                }}
                fontWeight="bold"
                alignItems="center"
                justifyContent="center"
              >
                {name?.charAt(0).toUpperCase()}
              </Stack>
              <h3>{name} </h3>
            </Stack>
          </Box>
        );
      })}
      {contactsListQuery.isFetchingNextPage && (
        <Stack my={2} width="100%" justifyContent="center" alignItems="center">
          <CircularProgress size="20px" />
        </Stack>
      )}
    </Stack>
  );
}
