import { UsersConversationCount } from 'src/models/conversations/conversations';
import { setConversationCount } from 'src/store/slices/socialmedia/socialmediaSlice';
import store from 'src/store/store';

function setConversationsCountStore(
  conversationsCount: UsersConversationCount
) {
  store.dispatch(setConversationCount(conversationsCount));
}

export default setConversationsCountStore;
