import { emitCustomEvent } from 'react-custom-events';
import { Conversation } from 'src/models/conversations/conversations';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';

function showNewConversationAlert(conversations: Conversation[]) {
  const loggedRTUser = getConnectedRTUser(false);

  conversations.forEach((conversation) => {
    if (conversation.status !== 'Assigned') return;

    if (!conversation.assignedUserIds.includes(loggedRTUser?.id)) return;

    emitCustomEvent(CustomEventNames.NEW_CONVERSATION, conversation);
  });
}

export default showNewConversationAlert;
