import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import useTokioAuthContext from 'src/hooks/useAuth';
import { useAuthContext } from 'src/contexts/AuthContext';

interface GuestProps {
  children: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to="/dashboard/inbox" replace />;
  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};

export default Guest;
