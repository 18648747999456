import { useCallback, useEffect, useState } from 'react';

import { Permissions, UserPermissions } from 'src/models/permissionGroups';
import { logger } from 'src/utils/logger';
import { checkUserPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import AuthManager from 'src/services/authentication/manager';
import { getPermissionsByUserIdQuery } from 'src/services/permissionGroups/application/getPermissionsByUserIdQuery';

export type UserPermissionsStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

const usePermissions = () => {
  const [userPermissions, setUserPermissions] = useState<UserPermissions>(null);
  const [loading, setLoading] = useState<UserPermissionsStatus>('idle');
  const userId = AuthManager.getUserId();

  useEffect(() => {
    let isMounted = true;
    if (userId) {
      setLoading('pending');
      getPermissionsByUserIdQuery(userId)
        .then((resp) => {
          if (isMounted) {
            setUserPermissions(resp);
            setLoading('succeeded');
          }
        })
        .catch((err) => {
          logger.error(err);
          setLoading('failed');
        });
    }
    return () => {
      isMounted = false;
    };
  }, [userId]);

  const hasAccess = useCallback(
    (
      permissionsCheckerFn: (
        permissions: Permissions,
        isSuperAdmin: boolean
      ) => boolean
    ) => {
      if (userPermissions) {
        const isAllowed = checkUserPermissions({
          userPermissions,
          permissionsCheckerFn
        });
        return isAllowed;
      }
    },
    [userPermissions]
  );

  const isSuperAdmin = userPermissions?.superadmin;

  return { userPermissions, hasAccess, loading, isSuperAdmin };
};

export default usePermissions;
