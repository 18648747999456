import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface AuthState {
  shouldRenewToken: boolean;
  websocketId: string;
}

const initialState = {
  shouldRenewToken: true,
  websocketId: ''
} as AuthState;

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setShouldRenewToken: (state, action: PayloadAction<boolean>) => {
      state.shouldRenewToken = action.payload;
    },
    setWebsocketID: (state, action: PayloadAction<string>) => {
      state.websocketId = action.payload;
    }
  }
});

export const { setShouldRenewToken, setWebsocketID } = authSlice.actions;
export default authSlice.reducer;
