import axios from 'axios';
import {
  ReportingAttachment,
  RetrieveReportingAttachmentsParams
} from '../models/reportingAttachment';
import { PaginatedElements } from 'src/models/pagination';
import { REPORTING_ATTACHMENT } from 'src/utils/apiRoutes/msReporting';

export const getReportingAttachmentsApi = async (
  params: RetrieveReportingAttachmentsParams
) => {
  const resp = await axios.get<PaginatedElements<ReportingAttachment>>(
    REPORTING_ATTACHMENT,
    {
      params
    }
  );
  return resp.data;
};
