import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box, Popover, CardContent, styled } from '@mui/material';
import AdminHeader from 'src/components/AdminHeader';
import CustomTabs, { TabsTypes } from 'src/components/CustomTabs';
import Scrollbar from 'src/components/Scrollbar';
import { ContactFieldsTable } from './components/contactFields/ContactFieldsTable';
import { CreateUpdateContactFieldForm } from './components/contactFields/CreateUpdateContactFieldForm';
import { CreateTagPopoverForm } from './components/tags/CreateTagPopoverForm';
import CreateContactsPopoverForm from './components/contacts/CreateContactsPopoverForm';
import ContactsTable from './components/contacts/ContactsTable';
import ContactTagsTable from './components/tags/TagsTable';
import { CreateUpdateContactGroupsPopover } from './components/contactGroups/popover';
import { ContactGroups } from './components/contactGroups';
import { checkContactTabsPermissions } from './utils/checkContactTabsPermissions';
import { useContacts } from './hooks/useContacts';
import { ContactsTabs, DASHBOARD_CONTACT_PATHNAME } from './utils/constants';
import { useContext } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { contactTabsPermissionsCheckerObject } from './utils/contactTabsPermissionsCheckerObject';

const TabsContainerWrapper = styled(CardContent)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.black[5]};
`
);

export type ContactTabType = 'contacts' | 'tags' | 'fields' | 'contactGroups';

export const ContactsView = () => {
  const { t } = useTranslation();
  const {
    isPopoverOpened,
    setIsPopoverOpened,
    popoverRef,
    tab,
    setTab,
    contactGroupToUpdate,
    setContactGroupToUpdate,
    getButtonName,
    handleAction,
    getRedirection,
    pathname
  } = useContacts();

  const { hasAccess } = useContext(PermissionsContext);

  const tabs: TabsTypes<ContactTabType>[] = [
    { label: 'Contacts', value: 'contacts' },
    { label: 'Contact Groups', value: 'contactGroups' },
    { label: 'Contact fields', value: 'fields' },
    { label: 'Tags', value: 'tags' }
  ];

  const allowedTabs = tabs.filter((tab) =>
    hasAccess(contactTabsPermissionsCheckerObject?.[tab.value])
  );

  return (
    <>
      <Helmet>
        <title>Contacts</title>
      </Helmet>
      <Box sx={{ height: '100vh' }}>
        <Popover
          open={isPopoverOpened}
          onClose={() => {
            setIsPopoverOpened(false);
          }}
          anchorEl={popoverRef}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          slotProps={{
            paper: {
              style: {
                minWidth: '30vw',
                marginRight: '20px'
              }
            }
          }}
        >
          {tab === ContactsTabs.CONTACTS_TAB && (
            <CreateContactsPopoverForm
              onClose={() => setIsPopoverOpened(false)}
            />
          )}
          {tab === ContactsTabs.CONTACT_GROUPS_TAB && (
            <CreateUpdateContactGroupsPopover
              contactGroupToUpdate={contactGroupToUpdate}
              setContactGroupToUpdate={setContactGroupToUpdate}
              onClose={() => setIsPopoverOpened(false)}
            />
          )}
          {tab === ContactsTabs.CONTACT_FIELDS_TAB && (
            <CreateUpdateContactFieldForm
              onClose={() => {
                setIsPopoverOpened(false);
              }}
            />
          )}
          {tab === ContactsTabs.TAGS_TAB && (
            <CreateTagPopoverForm
              onClose={() => {
                setIsPopoverOpened(false);
              }}
            />
          )}
        </Popover>

        <Scrollbar>
          <AdminHeader
            heading={t('Contacts')}
            subheading={t(
              'All aspects related to the contacts can be managed here'
            )}
            buttonLabel={t(getButtonName())}
            onClick={handleAction}
            permissionCheckerFn={checkContactTabsPermissions(tab)}
          />
          <Box px={'34px'} pt={'34px'} pb={2}>
            <CustomTabs<ContactTabType>
              tabs={allowedTabs}
              currentTab={tab}
              setCurrentTab={setTab}
              onClick={(tab: ContactTabType) =>
                pathname === DASHBOARD_CONTACT_PATHNAME
                  ? null
                  : getRedirection(tab)
              }
            />
          </Box>
          <Box px={2}>
            {tab === ContactsTabs.CONTACTS_TAB && <ContactsTable />}
            {tab === ContactsTabs.CONTACT_GROUPS_TAB && (
              <ContactGroups
                setIsPopoverOpened={setIsPopoverOpened}
                setContactGroupToUpdate={setContactGroupToUpdate}
              />
            )}
            {tab === ContactsTabs.CONTACT_FIELDS_TAB && <ContactFieldsTable />}
            {tab === ContactsTabs.TAGS_TAB && <ContactTagsTable />}
          </Box>
        </Scrollbar>
      </Box>
    </>
  );
};

export default ContactsView;
