import { getIsMuted } from './domain/getIsMuted';
import { getMicVolume } from './domain/getMicVolume';
import { setMicrophoneDeviceStore } from './domain/setMicDevice';
import WebRTCCallManagement from './webrtc-call-management';
import { RTCSession } from 'jssip/lib/RTCSession';
import { logger } from 'src/utils/logger';
import { setDevicesStore } from './infrastructure/store/devices/setDevices';
import { setMicrophoneStateStore } from 'src/modules/CTI/infrastructure/store/microphoneState';
import { getVoiceDeviceStore } from './infrastructure/store/devices/getMicDeviceStore';

// Stream received from the user
let WEBRTC_STREAM: MediaStream;
// Stream sent to the user with modified volume
let WEBRTC_OUT_STREAM: MediaStream;

let WEBRTC_GAIN_NODE: GainNode;

export async function requestUserStream(
  microphone?: MediaDeviceInfo
): Promise<MediaStream | null> {
  setMicrophoneStateStore('prompt');
  const audioDeviceId = microphone?.deviceId;
  // Si se especifica un dispositivo, se intenta usar ese, si no, se usa el default
  const audio: boolean | MediaTrackConstraints = audioDeviceId
    ? { deviceId: audioDeviceId }
    : true;

  try {
    const stream = await navigator.mediaDevices?.getUserMedia?.({
      audio
    });

    if (stream && microphone) {
      setMicrophoneDeviceStore(microphone);
    }

    // En este punto en el que si que tenemos permisos, se aprovecha para actualizar los dispositivos disponibles
    navigator.mediaDevices.enumerateDevices().then((devicesList) => {
      setDevicesStore(devicesList);
    });

    WEBRTC_STREAM = stream;
    return stream;
  } catch (err) {
    logger.error('[WEBRTC] Error requesting user devices', err);
    const errorMessage: string = err.message;
    if (errorMessage.includes('Permission dismissed')) {
      setMicrophoneStateStore('not-enabled');
    } else {
      setMicrophoneStateStore('denied');
    }

    return null;
  }
}

export function createStreamWithGainNode(stream: MediaStream) {
  let audioContext = new AudioContext();
  let gainNode = audioContext.createGain();
  let audioSource = audioContext.createMediaStreamSource(stream);
  let audioDestination = audioContext.createMediaStreamDestination();

  // Connect the gain node to the new media stream
  audioSource.connect(gainNode);
  gainNode.connect(audioDestination);
  WEBRTC_GAIN_NODE = gainNode;

  // Set the gain value to the current microphone volume
  const currentVolume = getMicVolume(false);
  gainNode.gain.value = currentVolume;

  WEBRTC_OUT_STREAM = audioDestination.stream;
}

export function updateActiveCallSenderTrack(stream?: MediaStream) {
  const streamToModify = stream ?? WEBRTC_OUT_STREAM;
  const audioTrack = streamToModify.getAudioTracks()[0];
  const sender =
    WebRTCCallManagement.getWebRTCSession()?.connection?.getSenders()[0];
  if (sender && audioTrack) {
    sender?.replaceTrack?.(audioTrack);
  }

  //Keep the audio track muted if the user is muted
  const isMuted = getIsMuted(false);

  const audioTracks = streamToModify.getAudioTracks();
  audioTracks.forEach((track) => {
    track.enabled = !isMuted;
  });
}

export async function prepareStreamForCall() {
  stopAudioStreams();

  const savedMicrophone = getVoiceDeviceStore(false);
  const stream = await requestUserStream(savedMicrophone);
  if (!stream) {
    logger.error(
      '[WEBRTC] Error requesting user devices on prepareStreamForCall function'
    );
    return;
  }
  createStreamWithGainNode(stream);
  updateActiveCallSenderTrack();
}

export const sendDtmfTone = (tone: string) => {
  const session: RTCSession = WebRTCCallManagement.getWebRTCSession();
  if (session) {
    session.sendDTMF(tone);
  }
};

export async function checkMicrophoneIsAvailable() {
  const savedMicrophone = getVoiceDeviceStore(false);
  const stream = await requestUserStream(savedMicrophone);

  const isAvailable = stream !== null;

  stopAudioStreams(stream);

  return isAvailable;
}

/**
 * IMPORTANTE: Si no se para el stream el navegador interpreta que se sigue usando el microfono y mostrará una alerta al usuario en todo momento
 */
export function stopAudioStreams(stream?: MediaStream) {
  const streams = [WEBRTC_OUT_STREAM, WEBRTC_STREAM, stream].filter(Boolean);

  streams.forEach((stream) => {
    if (!stream) return;

    stream.getTracks().forEach((track) => {
      track.stop();
    });
  });

  WEBRTC_OUT_STREAM = undefined;
  WEBRTC_STREAM = undefined;
}

/**
 * Get the stream that is being sent to the other user with gain node applied
 * @returns Media stream
 */
const getOutStream = (): MediaStream => {
  return WEBRTC_OUT_STREAM;
};

const getGainNode = (): GainNode => {
  return WEBRTC_GAIN_NODE;
};

export const audioDeviceKey = 'audioDevice';
export const ringDeviceKey = 'ringDevice';
export const ringtoneSoundPathKey = 'ringtoneSoundPath';
export const telegramSoundPathKey = 'telegramSoundPath';
export const whatsappSoundPathKey = 'whatsappSoundPath';
export const emailSoundPathKey = 'emailSoundPath';
export const webchatSoundPathKey = 'webchatSoundPath';

const WebRTCDevices = {
  getOutStream,
  getGainNode
};

export default WebRTCDevices;
