import React, { useState, useEffect, CSSProperties } from 'react';
import moment from 'moment';
import { convertUtcToTimeZone } from 'src/modules/Shared/utils/convertDateToTimeZone';
import { createDummyDate } from 'src/modules/DialerBot/views/utils/helpers';

interface TimerTypes {
  updateTime?: string;
  modifyTime?: string;
  style?: CSSProperties;
}

const Timer: React.FC<TimerTypes> = ({ updateTime, modifyTime, style }) => {
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const setTimeCall = (): void => {
    const timeStart = moment(updateTime).format('HH:mm:ss');
    const timeCurrent = moment().format('HH:mm:ss');
    const time = moment(timeStart, 'HH:mm:ss a');
    const current = moment(timeCurrent, 'HH:mm:ss a');
    const duration = moment.duration(Math.max(current.diff(time), 0));
    const hours = duration.hours();
    const minute = duration.minutes() % 60;
    const second = duration.seconds();
    setHour(hours);
    setMinutes(minute);
    setSeconds(second);
  };

  const convertedModifyTime = (date: String) => {
    let newDate = date.split('T');
    let fixedTime = newDate[1].split('.')[0].slice(0, -3);
    // return newDate[0] + ' ' + fixedTime;
    const timeModify = createDummyDate(
      Number(fixedTime.split(':')[0]),
      Number(fixedTime.split(':')[1])
    );

    return (
      newDate[0] +
      ' ' +
      convertUtcToTimeZone(timeModify).getHours() +
      ':' +
      convertUtcToTimeZone(timeModify).getMinutes()
    );
  };
  useEffect(() => {
    setTimeCall();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeCall();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  if (modifyTime) {
    return <span style={style}>{convertedModifyTime(modifyTime)}</span>;
  } else {
    return (
      <span style={style}>
        {hour < 1 ? null : hour! < 10 ? `0${hour}:` : `${hour}:`}
        {minutes < 10 ? `0${minutes}:` : `${minutes}:`}
        {seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  }
};

export default React.memo(Timer);
