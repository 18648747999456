import { Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { ColorPicker } from '../../../../components/Style/ColorPicker';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../../models/webChatStyle';
import { FormikProps } from 'formik';

interface ResponseInboxAccordionProps {
  formikProps: FormikProps<WebChatStyle>;
}

const ResponseInboxAccordion: React.FC<ResponseInboxAccordionProps> = ({
  formikProps
}) => {
  return (
    <>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="32px">
          <ColorPicker
            title={t('Background color')}
            color={formikProps.values.conversationStyle.msgBoxBGColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.msgBoxBGColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="32px">
          <ColorPicker
            title={t('Font color')}
            color={formikProps.values.conversationStyle.msgBoxTextColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.msgBoxTextColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="32px">
          <ColorPicker
            title={t('Icons color')}
            color={formikProps.values.conversationStyle.msgBoxIconsColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.msgBoxIconsColor.color',
                c
              );
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ResponseInboxAccordion;
