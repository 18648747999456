import { ChannelType } from 'src/models/conversations/conversations';
import { typingWebChatApi } from 'src/services/webchat/infraestructure/typingWebchatApi';
import { typingTelegramMessageApi } from 'src/services/telegram/infrastructure/message/typingTelegramMessageApi';
import { logger } from 'src/utils/logger';

export const typingChatMessage = async (params: {
  channel: ChannelType;
  accountId: string;
  conversationId: string;
  typing: boolean;
}) => {
  const { channel, ...restParams } = params;
  switch (channel) {
    case ChannelType.TELEGRAM:
      try {
        const isTypingTelegramMessage =
          await typingTelegramMessageApi(restParams);
        return isTypingTelegramMessage;
      } catch (error) {
        logger.error(error);
        return false;
      }
    case ChannelType.WHATSAPP:
      return false;
    case ChannelType.WEBCHAT:
      try {
        const isTypingWebChat = await typingWebChatApi(restParams);
        return isTypingWebChat;
      } catch (error) {
        logger.error(error);
        return false;
      }
    case ChannelType.INSTAGRAM:
      return false;
    default:
      return false;
  }
};
