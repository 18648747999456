import { ReportingTicket } from '../../models/tickets';
import { deleteTicket } from '../deleteTickets';

export const deleteReportingTickets = async (
  reportingTickets: ReportingTicket[]
) => {
  const ticketPromises = reportingTickets.map((reportingTicket) =>
    
    deleteTicket(reportingTicket.id)
  );
  await Promise.all(ticketPromises);
};
