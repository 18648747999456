import store, { useSelector } from 'src/store/store';

/** GET VOICE VOLUME VALUE IN STORE
 *
 * @param shouldUseHook
 */

export const getVoiceVolumeStore = (shouldUseHook: boolean) => {
  const volume = (
    shouldUseHook
      ? useSelector((state) => state.configVolume.voiceVolume)
      : store.getState().configVolume.voiceVolume
  ) as number;

  return volume;
};
