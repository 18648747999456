import { Autocomplete, Stack, TextField } from '@mui/material';
import { Typography, Divider } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { t } from 'i18next';

import { createPortal } from 'react-dom';
import { Variable } from 'src/modules/Admin/modules/General/modules/Variables/models/models';
import { ContactField } from 'src/modules/Contacts/models/contacts';
import { ShowflowVariableType } from '../hooks/useShowflowVariable';
import {
  TicketField,
  TicketFieldType,
  TicketFieldValue
} from '../../../../models/tickets';
import { useShowflowColors } from '../hooks/useShowflowColors';
import { useEffect, useState } from 'react';
import { getTicketFieldByIdQuery } from '../../../../domain/fields';

interface Props {
  isHovered: boolean;
  isEditing: boolean;
  hasValue: boolean;
  field: Variable | TicketField | ContactField;
  type: ShowflowVariableType;
  top: number;
  left: number;
  applyChanges: (value: string[]) => void;
  inputType: TicketFieldType;
  value: string | string[] | boolean;
}

export function ShowflowVariableInfo({
  isHovered,
  isEditing,
  hasValue,
  field,
  type,
  top,
  left,
  applyChanges,
  inputType,
  value
}: Props) {
  const { showflowColorsMap } = useShowflowColors();
  const showAutocomplete = inputType === TicketFieldType.OPTIONS;
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (showAutocomplete && field?.id) {
      getTicketFieldByIdQuery(field.id).then((field) => {
        setOptions(field.options);
      });
    }
  }, [field?.id, showAutocomplete]);

  const getTypeLabel = {
    Call: t('Call variable'),
    Company: t('Company variable'),
    Ticket: t('Ticket field'),
    Contact: t('Contact field')
  };

  return createPortal(
    <AnimatePresence>
      {(isHovered || isEditing) && (
        <motion.div
          //tooltip on the bottom
          //layout="position"
          initial={{ opacity: 0, top, left }}
          animate={{ opacity: 1, top, left }}
          exit={{ opacity: 0, top, left }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          style={{
            position: 'fixed',
            translateX: '-50%',
            padding: '5px 9px',
            background: 'rgba(255,255,255,0.5)',
            border: `1px solid ${showflowColorsMap[type]}`,
            backdropFilter: 'blur(6px)',
            color: 'black',
            borderRadius: '5px',
            fontSize: '12px',
            zIndex: 10
          }}
        >
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: '-15px', // Ajusta la posición vertical de la punta de flecha
                left: '50%',
                transform: 'translateX(-50%)',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: `10px solid ${showflowColorsMap[type]}` // Cambia el color de la punta de flecha
              }}
            ></div>
          </div>
          <Stack direction={'column'}>
            <Typography
              variant="body1"
              fontSize={16}
              style={{ fontWeight: 'bold' }}
            >
              {field?.name}
            </Typography>
            <Typography variant="body2" fontSize={11}>
              {getTypeLabel[type]}
            </Typography>
            {(!hasValue || showAutocomplete) && <Divider sx={{ my: 0.5 }} />}

            {!hasValue && !showAutocomplete && (
              <Typography variant="caption" fontSize={14} width={'max-content'}>
                {t('No value set')}
              </Typography>
            )}

            {showAutocomplete && (
              <Autocomplete
                size="small"
                options={options}
                multiple
                disableCloseOnSelect
                value={value as string[]}
                onChange={(_, values) => {
                  applyChanges(values);
                }}
                sx={{
                  py: 0.5
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Add value')}
                    sx={{ width: '250px' }}
                  />
                )}
              />
            )}

            {['Company', 'Call'].includes(type) &&
              (field as Variable)?.description && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <Typography variant="body1" fontSize={14}>
                    {(field as Variable)?.description}
                  </Typography>
                </>
              )}
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
}
