import { RTCall } from 'src/models/rtcall';
import store, { useSelector } from 'src/store/store';

export const getMissedCallsStore = (shouldUseHook: boolean) => {
  const missedCalls = (
    shouldUseHook
      ? useSelector((state) => state.callData.missedCalls)
      : store.getState().callData.missedCalls
  ) as RTCall[];

  return missedCalls;
};
