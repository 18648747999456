import { Box, Dialog, Grid, IconButton, Tooltip } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  acceptPowerManual,
  ignorePowerManual,
  postponePowerManual
} from 'src/modules/DialerBot/domain/powerDialer';
import getMockConversation from './mockConversation';
import { useState } from 'react';

import ContactDataPowerdialer from './ContactDataPowerdialer';
import { SkipNext } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
interface Props {
  campaignId: string;
  scheduleId: string;
  userId: string;
  companyId: string;
  contactId: string;
  serviceId: string;
  phoneNumber: string;
  timestamp: string;
  onClose: () => void;
}

const ButtonPowerDialerNotification = (props: Props) => {
  const [isOpenContactData, setIsOpenContactData] = useState(false);
  const { t } = useTranslation();
  const {
    campaignId,
    scheduleId,
    userId,
    companyId,
    contactId,
    serviceId,
    phoneNumber,
    timestamp,
    onClose
  } = props;
  const converstionMock = getMockConversation({
    userId,
    campaignId,
    serviceId,
    companyId,
    contactId,
    phoneNumber,
    timestamp
  });
  const onCloseContactData = () => {
    setIsOpenContactData(false);
  };
  return (
    <>
      <Dialog open={isOpenContactData} maxWidth="lg">
        <Box sx={{ height: '100vh', width: '90vh' }}>
          <ContactDataPowerdialer
            conversation={converstionMock}
            onClose={onCloseContactData}
          />
        </Box>
      </Dialog>

      <Grid container>
        <Grid item xs={3}>
          <Tooltip title={t('Show contact information')}>
            <IconButton
              title={t('Show contact information')}
              sx={{ backgroundColor: '#0444ee', color: 'white' }}
              onClick={() => {
                setIsOpenContactData(true);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={t('Accept call')}>
            <IconButton
              title={t('Accept call')}
              sx={{ backgroundColor: '#35f909', color: 'white' }}
              onClick={async () => {
                await acceptPowerManual(campaignId, scheduleId, userId);
                onClose();
              }}
            >
              <CallIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={t('Reject call')}>
            <IconButton
              title={t('Reject call')}
              sx={{ backgroundColor: '#f90909', color: 'white' }}
              onClick={async () => {
                await ignorePowerManual(
                  campaignId,
                  scheduleId,
                  userId,
                  companyId
                );
                onClose();
              }}
            >
              <CallEndIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={t('Reschedule call')}>
            <IconButton
              title={t('Reschedule call')}
              sx={{ backgroundColor: '#09f5f9', color: 'white' }}
              onClick={async () => {
                await postponePowerManual(
                  campaignId,
                  scheduleId,
                  userId,
                  companyId
                );
                onClose();
              }}
            >
              <SkipNext />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default ButtonPowerDialerNotification;
