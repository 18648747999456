export enum OperationType {
  COUNT = 'COUNT',
  AVG = 'AVG',
  FIRST = 'FIRST',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM'
}

export const OperationTypeOptions = Object.values(OperationType);

export default interface GroupOperation {
  operation: OperationType;
  operationField?: string; // when operation is count, is not necessary
  resultName: string;
}
