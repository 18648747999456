import { TextField } from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import TextFieldWithVariables from 'src/components/TextInputWithVariables/styledComponents/TextFieldWithVariables';
import { useCompanyVariablesQuery } from 'src/modules/Admin/modules/General/modules/Variables/application/getVariablesQuery';
import { PromptElement } from 'src/modules/Shared/models/ariModels';

interface Props {
  promptElement: PromptElement;
  onChange: (prev: PromptElement) => void;
  hideNameAndDescription?: boolean;
}

export function PromptElementForm({
  promptElement,
  onChange,
  hideNameAndDescription
}: Props) {
  const companyId = promptElement.companyId;
  const [text, setText] = useState(promptElement.text);

  const variablesQuery = useCompanyVariablesQuery({
    companyId,
    pageSize: -1,
    contactGroupId: ''
  });
  const variables = variablesQuery?.data?.elements ?? [];

  function handleTextChange(text: string) {
    onChange({ ...promptElement, text });
  }

  useEffect(() => {
    handleTextChange(text);
  }, [text]);

  return (
    <>
      {!hideNameAndDescription && (
        <>
          <TextField
            required
            autoFocus
            label={t('Name')}
            value={promptElement.name}
            onChange={(e) =>
              onChange({ ...promptElement, name: e.target.value })
            }
          />
          <TextField
            label={t('Description')}
            value={promptElement.description}
            onChange={(e) =>
              onChange({ ...promptElement, description: e.target.value })
            }
          />
        </>
      )}
      <TextFieldWithVariables
        companyId={companyId}
        label={t('Text')}
        text={text}
        setText={setText}
        variables={variables}
        shrinkOnBlur={false}
      />
    </>
  );
}
