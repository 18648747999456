import snackbar from 'src/utils/snackbar';
import { VideoCallsDomain } from '.';
import { FormFieldData } from '../components/PhasePreroom/components/form/VideoCallForm';
import {
  VideocallPhase,
  FinishReason,
  VideocallRoomContextProps
} from '../context/VideocallRoomContext';
import { VideoroomWebRTCService } from '../infrastructure/webrtc';
import { VideoroomWSService } from '../infrastructure/websocket/websocket';
import { IdentifyMessage } from '../infrastructure/websocket/websocket-messages';
import { ForbiddenException } from './exceptions/forbiddenException';
import { LockedException } from './exceptions/lockedException';
import { logger } from 'src/utils/logger';
import { ParticipantType } from '../types/videoCallTypes';

export const connectWebRTC = async (
  videoCallsContext: VideocallRoomContextProps,
) => {
  VideoroomWebRTCService.instance.connectWebRTC({
    videoRoomContext: videoCallsContext,
    extension: videoCallsContext.extensionData.extension.current,
    password: videoCallsContext.extensionData.password.current,
    localStream: videoCallsContext.streams.local,
    remoteStream: videoCallsContext.streams.remote
  });

  VideoroomWebRTCService.instance.on(
    'webrtc:on-extension-registered',
    'joinVideoroom',
    (event) => {
      logger.log("[VideoCalls]: Extension connected succesfully")
      const extension = event.response.from._uri._user;
      VideoCallsDomain.participantReady(extension, videoCallsContext.videoRoom.room.id).then(success => {
        if (success) {
          videoCallsContext.phase.setVideocallPhase(VideocallPhase.INCALL);
        } else {
          videoCallsContext.phase.setVideocallPhase(VideocallPhase.FINISHED);
          videoCallsContext.phase.setFinishedReason(FinishReason.ERROR)
        }
      });
    }
  );
};

export const connectWebsocket = async (
  videoCallsContext: VideocallRoomContextProps,
  formFieldValues: FormFieldData[],
) => {
  VideoroomWSService.instance.connect();
  VideoroomWSService.instance.on(
    'videocalls:identify-message',
    'joinVideoroom',
    async (data: IdentifyMessage) => {
      videoCallsContext.connection.setConnectionId(data.socketId);
      const participant = await joinVideoRoomRequest(
        videoCallsContext,
        data.socketId,
        formFieldValues
      );

      if (!participant) return;

      videoCallsContext.videoRoom.setMe(participant);
      videoCallsContext.extensionData.extension.current = participant.extension.username;
      videoCallsContext.extensionData.password.current = participant.extension.password;

      if (participant.type == ParticipantType.AgentParticipant)
        connectWebRTC(videoCallsContext);
    }
  );
};

const joinVideoRoomRequest = async (
  videocallContext: VideocallRoomContextProps,
  connectionId: string,
  formFieldValues?: FormFieldData[]
) => {
  try {
    return await VideoCallsDomain.joinVideoRoom(
      videocallContext.videoRoom.room.id,
      connectionId,
      videocallContext.devices.microphoneMuted,
      videocallContext.devices.speakerMuted,
      formFieldValues
    );
  } catch (err: any | ForbiddenException | LockedException) {
    if (err instanceof ForbiddenException) {
      videocallContext.phase.setVideocallPhase(VideocallPhase.FINISHED);
      videocallContext.phase.setFinishedReason(FinishReason.DENIED);
      //setRoom(null);
      VideoroomWSService.instance.disconnect();
      return;
    }

    if (err instanceof LockedException) {
      snackbar.error("The videoroom is full, you can't join");
      videocallContext.phase.setVideocallPhase(VideocallPhase.FINISHED);
      videocallContext.phase.setFinishedReason(FinishReason.IS_FULL);
      VideoroomWSService.instance.disconnect();
      return;
    }
    logger.error(err, typeof err);
    alert('Peticion fallada');
  }
};
