import {
  $applyNodeReplacement,
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread
} from 'lexical';
import { EmailMessage } from 'src/models/conversations/messages';
import { ParsedInlineMessage } from 'src/modules/Shared/utils/attachments';

export type SerializedReplyingToNode = Spread<
  {
    message: EmailMessage;
    parsedHtml: string;
    parsedInlineMessage: ParsedInlineMessage;
  },
  SerializedLexicalNode
>;

interface ReplyingToNodePayload {
  message: EmailMessage;
  parsedHtml: string;
  parsedInlineMessage: ParsedInlineMessage;
  key?: NodeKey;
}

export class ReplyingToNode extends DecoratorNode<JSX.Element> {
  message: EmailMessage;
  parsedHtml: string;
  parsedInlineMessage: ParsedInlineMessage;
  title: string;

  static getType(): string {
    return 'ReplyingToNode';
  }

  static clone(node: ReplyingToNode): ReplyingToNode {
    return new ReplyingToNode(
      node.message,
      node.parsedHtml,
      node.parsedInlineMessage,
      node.__key
    );
  }

  constructor(
    message: EmailMessage,
    parsedHtml: string,
    parsedInlineMessage: ParsedInlineMessage,
    key?: NodeKey
  ) {
    super(key);
    this.message = message;
    this.parsedHtml = parsedHtml;
    this.parsedInlineMessage = parsedInlineMessage;
    this.title = `El ${new Date(message.metadata.creationDate).toLocaleString(
      'es-ES',
      {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
    )}, ${message.from}:
`;
  }

  init(): void {}

  createDOM(): HTMLElement {
    const div = document.createElement('div');
    return div;
  }

  updateDOM(prevNode: ReplyingToNode, dom: HTMLElement): boolean {
    return false;
  }

  exportDOM(_): DOMExportOutput {
    const container = document.createElement('div');

    const titleDiv = document.createElement('p');
    titleDiv.style.paddingLeft = '0px';
    titleDiv.textContent = this.title;
    container.appendChild(titleDiv);

    const messageDiv = document.createElement('div');
    messageDiv.innerHTML = this.parsedInlineMessage.message;
    messageDiv.style.display = 'block';
    messageDiv.style.marginLeft = '0px';
    messageDiv.style.paddingLeft = '8px';
    messageDiv.style.borderLeft = `2px solid blue`;
    container.appendChild(messageDiv);

    return { element: container };
  }

  static importJSON(serializedNode: SerializedReplyingToNode): ReplyingToNode {
    const { message, parsedHtml, parsedInlineMessage } = serializedNode;
    const node = $createReplyingToNode({
      message,
      parsedHtml,
      parsedInlineMessage
    });
    return node;
  }

  exportJSON(): SerializedReplyingToNode {
    return {
      message: this.message,
      parsedHtml: this.parsedHtml,
      parsedInlineMessage: this.parsedInlineMessage,
      type: ReplyingToNode.getType(),
      version: 1
    };
  }

  decorate(): JSX.Element {
    return (
      <div>
        <div>{this.title}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.parsedHtml
          }}
          className="email-message"
          style={{
            marginLeft: '0px',
            paddingLeft: '8px',
            borderLeft: `2px solid blue`,
            marginBottom: '10px'
          }}
        />
      </div>
    );
  }

  getInlineFiles(): File[] {
    return this.parsedInlineMessage.inlineFiles;
  }
}

export function $createReplyingToNode({
  message,
  parsedHtml,
  parsedInlineMessage,
  key
}: ReplyingToNodePayload): ReplyingToNode {
  return $applyNodeReplacement(
    new ReplyingToNode(message, parsedHtml, parsedInlineMessage, key)
  );
}

export function $removeReplyingToNode(node: ReplyingToNode): void {
  node.remove();
}

export function $isReplyingToNode(
  node: LexicalNode | null | undefined
): node is ReplyingToNode {
  return node instanceof ReplyingToNode;
}
