import { getContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { Contact } from '../models/contacts';

export const getContactName = async (contact: Contact) => {
  const contactGroupId = contact?.contactGroupId;
  const contactFields = (await getContactFieldsQuery())?.elements?.filter(
    (field) => field.type === 'Name' && field.contactGroupId === contactGroupId
  );

  if (!contactFields) return undefined;

  //Get main name field
  const mainNameFieldId = contactFields.find((f) => f.mainField);

  //Return main field if it exists
  if (mainNameFieldId)
    return getContactFieldValue(contact, mainNameFieldId?.id);

  //Otherwise, return the first name field
  let firstContactfield = contactFields[0];
  if (!firstContactfield) return undefined;

  return getContactFieldValue(contact, firstContactfield?.id);
};

export const getContactFieldValue = (
  contact: Contact,
  contactFieldId: string
) => {
  return contact.fields?.[contactFieldId];
};
