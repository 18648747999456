import axios from 'axios';
import { ChannelType } from 'src/models/conversations/conversations';
import {
  ChatMessage,
  MessageActions,
  MessageOrientation
} from 'src/models/conversations/messages';
import { INSTAGRAM_MESSAGE_ACTIONS } from 'src/utils/apiRoutes/msInstagram';
import { TELEGRAM_MESSAGE_ACTIONS } from 'src/utils/apiRoutes/msTelegram';
import { WEBCHAT_MESSAGE_ACTIONS } from 'src/utils/apiRoutes/msWebChat';
import { WHATSAPP_MESSAGE_ACTIONS } from 'src/utils/apiRoutes/msWhatsapp';

export const getChannelActions = async (message: ChatMessage) => {
  const incomingActions = [MessageActions.REPLY];
  let path = null;

  switch (message.channel) {
    case ChannelType.WEBCHAT:
      path = WEBCHAT_MESSAGE_ACTIONS;
      break;
    case ChannelType.TELEGRAM:
      path = TELEGRAM_MESSAGE_ACTIONS;
      break;
    case ChannelType.WHATSAPP:
      path = WHATSAPP_MESSAGE_ACTIONS;
      break;
    case ChannelType.INSTAGRAM:
      path = INSTAGRAM_MESSAGE_ACTIONS;
      break;
  }
  if (!path) return [];

  const res = await axios.get<Partial<MessageActions>[]>(path);

  const actions = res.data.filter((action) => {
    if (message.orientation === MessageOrientation.INCOMING) {
      return incomingActions.includes(action);
    }
    return true;
  });

  return actions;
};
