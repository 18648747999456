import store, { useSelector } from 'src/store/store';

/** GET WEBSOCKET ID FROM STORE
 *
 * @param shouldUseHook
 */

export const getWebsocketIdStore = (shouldUseHook: boolean) => {
  const websocketId = (
    shouldUseHook
      ? useSelector((state) => state.authSlice.websocketId)
      : store.getState().authSlice.websocketId
  ) as string;

  return websocketId;
};
