import AuthManager from 'src/services/authentication/manager';

import { convertBlobToFile, replaceURLParams } from 'src/services/utils';
import { WEBCHAT_ATTACHMENT_ID } from 'src/utils/apiRoutes/msWebChat';
import { queryClient } from 'src/utils/queryClient';

export const downloadAttachment = async (
  id: string,
  name: string = 'filename'
) => {
  const path = replaceURLParams(WEBCHAT_ATTACHMENT_ID, { '{id}': id });
  const imageBlob = await getBlobQuery(path);
  const file = convertBlobToFile(imageBlob, name);

  return file as File;
};

function getBlobQuery(url: string) {
  const headers = {
    Authorization: 'Bearer ' + AuthManager.getToken()
  };
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: headers,
    credentials: 'omit'
  };

  return queryClient.fetchQuery({
    queryKey: [url],
    queryFn: () =>
      fetch(url, requestOptions).then((res) => {
        return res.blob();
      }),
    staleTime: 1000 * 60 * 5
  });
}
