import { RTQueue } from 'src/models/queue';
import { getRTQueues, GetRTQueuesFilter } from './getRTQueues';

/** FIND ONE RTQueues
 *
 */
export const getRTQueue = (filter: GetRTQueuesFilter): RTQueue => {
  let rtQueues = getRTQueues(filter);

  return rtQueues[0] as RTQueue;
};
