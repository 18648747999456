import { MenuItem, Select, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';
import WebRTCDevices from 'src/services/webrtc/webrtc.devices';
import { connectDevices } from 'src/services/webrtc/webrtc.service';
import {
  setAudioDevice,
  setRingDevice
} from 'src/store/slices/users/configVolumeSlice';
import { useDispatch, useSelector } from 'src/store/store';

export const RingSelect: React.FC = () => {
  const deviceLocalStgKey = 'ringDevice';
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { ringDevice } = useSelector((state) => state.configVolume);

  const dispatch = useDispatch();

  const audio = document.getElementById('webrtc-ring') as HTMLMediaElement & {
    setSinkId(deviceId: string): void;
  };

  const socialmedia = document.getElementById(
    'socialmedia-message-notification'
  ) as HTMLMediaElement & {
    setSinkId(deviceId: string): void;
  };
  const [mediaDevices, setMediaDevices] = useState([] as MediaDeviceInfo[]);

  const handleDevices = (): void => {
    const devices = getDevicesStore(false);
    if (devices.length > 0) {
      setMediaDevices(
        devices.filter((d) => {
          return d.kind === 'audiooutput';
        })
      );
    }
  };

  const onSelectChange = (e: string): void => {
    handleDevices();

    const newDevice = mediaDevices.filter((device) => {
      return device.label === e;
    })[0];
    /* audio.setSinkId(newDevice.deviceId);
    socialmedia.setSinkId(newDevice.deviceId);*/
    localStorage.setItem(deviceLocalStgKey, JSON.stringify(newDevice));

    connectDevices(
      'ringDevice',
      ['webrtc-ring', 'socialmedia-message-notification'],
      setRingDevice
    );
  };

  // audio.volume values between 0 and 1.
  useEffect(() => {
    handleDevices();
  }, []);

  return (
    <>
      {mediaDevices.length > 0 && (
        <Select
          value={ringDevice.label}
          sx={{
            width: '175px',
            backgroundColor: theme.colors.secondary.main,
            color: 'white'
          }}
          onChange={(event) => {
            onSelectChange(event.target.value);
          }}
          id="selectRing-RingSelect"
        >
          <MenuItem sx={{ display: 'none' }} value={ringDevice.label}>
            {ringDevice.label}
          </MenuItem>
          {mediaDevices.map((device, idx) => {
            return (
              <MenuItem
                key={idx}
                onClick={(): void => {
                  //onSelectChange(device.label);
                }}
                value={device.label}
              >
                {device.label}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </>
  );
};
