import { CountryCode } from 'libphonenumber-js';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  CTIPopoverNames,
  NewConversationMenu
} from 'src/contexts/CTIBarContext';
import { ChannelType } from 'src/models/conversations/conversations';
import { CallType } from 'src/modules/Admin/modules/Calls/modules/CallerIds/models/callerId';
import { Ticket } from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import { Contact, ContactGroup } from 'src/modules/Contacts/models/contacts';
import useEnabledChannels from './useEnabledChannels';

type ContactGroupSelection =
  | {
      state: 'NONE';
    }
  | {
      state: 'GROUP';
      contactGroup: ContactGroup;
    }
  | {
      state: 'ALL';
    };

export interface DialpadState {
  conversationSelected: NewConversationMenu;
  setConversationSelected: (conversationSelected: NewConversationMenu) => void;
  /** The phone number input (Call, Telegram and WhatsApp) */
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  countryCode: CountryCode;
  setCountryCode: Dispatch<SetStateAction<CountryCode>>;
  callType: CallType;
  setCallType: Dispatch<SetStateAction<CallType>>;
  /** In Telegram conversations, if true, the inputValue will be used. Otherwise, the username will be used */
  isPhoneInput: boolean;
  setIsPhoneInput: Dispatch<SetStateAction<boolean>>;
  /** Username input (Telegram, Instagram and Email)*/
  username: string;
  setUsername: Dispatch<SetStateAction<string>>;

  contactGroupSelection: ContactGroupSelection;
  setContactGroupSelection: Dispatch<SetStateAction<ContactGroupSelection>>;
  selectedContact: Contact;
  setSelectedContact: Dispatch<SetStateAction<Contact>>;
  selectedTicket: Ticket;
  setSelectedTicket: Dispatch<SetStateAction<Ticket>>;

  openDialpad: (params?: OpenDialpadParams) => void;

  channelsWithAccess: NewConversationMenu[];
  isChannelEnabled: (channel: ChannelType) => boolean;
}

interface OpenDialpadParams {
  channel?: Partial<ChannelType>;
  callType?: CallType;
  inputValue?: string;
}

interface Props {
  openPopover: (popoverName: CTIPopoverNames) => void;
  setActiveTab: (tabName: 'dialpad' | 'contacts' | 'recent') => void;
}

function useDialpadState({ openPopover, setActiveTab }: Props): DialpadState {
  const [conversationSelected, setConversationSelected] =
    useState<NewConversationMenu>(undefined);

  const [inputValue, setInputValue] = useState('');
  const navigatorCountryCode = navigator.language.split('-')[1] as CountryCode;
  const [countryCode, setCountryCode] = useState<CountryCode>(
    navigatorCountryCode || 'ES'
  );

  const [callType, setCallType] = useState<CallType>('External');

  // Telegram related
  const [isPhoneInput, setIsPhoneInput] = useState<boolean>(false);
  const [username, setUsername] = useState('');

  const [contactGroupSelection, setContactGroupSelection] =
    useState<ContactGroupSelection>({ state: 'NONE' });
  const [selectedContact, setSelectedContact] = useState<Contact>(null);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>(null);

  const { channelsWithAccess, isChannelEnabled } = useEnabledChannels({
    conversationSelected,
    setConversationSelected
  });

  const openDialpad = (params?: {
    channel?: Partial<ChannelType>;
    callType?: CallType;
    inputValue?: string;
  }) => {
    //check if the desired channel is enabled
    if (!isChannelEnabled(params?.channel)) return;

    const {
      channel = ChannelType.CALL,
      callType = 'External',
      inputValue = ''
    } = params ?? {};

    setCallType(callType);
    openPopover(CTIPopoverNames.NEW_CONVERSATION);
    setActiveTab('dialpad');

    switch (channel) {
      case ChannelType.CALL:
        setConversationSelected(NewConversationMenu.Call);
        break;
      case ChannelType.EMAIL:
        setConversationSelected(NewConversationMenu.Email);
        setIsPhoneInput(false);
        setUsername(inputValue);
        return;
      case ChannelType.TELEGRAM:
        setConversationSelected(NewConversationMenu.Telegram);
        // If the inputValue is not a phone number
        if (/^[0-9]+$/.test(inputValue)) break;
        setIsPhoneInput(false);
        setUsername(inputValue);
        return;
      case ChannelType.WHATSAPP:
        setConversationSelected(NewConversationMenu.WhatsApp);
        break;
      case ChannelType.INSTAGRAM:
        setConversationSelected(NewConversationMenu.Instagram);
        setIsPhoneInput(false);
        setUsername(inputValue);
        return;
    }
    setInputValue(inputValue);
  };

  return {
    channelsWithAccess,
    isChannelEnabled,
    conversationSelected,
    setConversationSelected,
    inputValue,
    setInputValue,
    countryCode,
    setCountryCode,
    callType,
    setCallType,
    isPhoneInput,
    setIsPhoneInput,
    username,
    setUsername,
    selectedContact,
    setSelectedContact,
    selectedTicket,
    setSelectedTicket,
    openDialpad,
    contactGroupSelection,
    setContactGroupSelection
  };
}
export default useDialpadState;
