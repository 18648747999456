import axios from 'axios';
import { ContactFields, Contact } from 'src/modules/Contacts/models/contacts';
import { replaceURLParams } from 'src/services/utils';
import { CONTACTS_ID } from 'src/utils/apiRoutes/msContacts';

export interface CreateContactApiParam {
  companyId: string;
  fields: ContactFields;
}

export const deleteContactApi = async (contact: Contact) => {
  if (!contact?.id) {
    throw new Error('No contact id');
  }
  const path = replaceURLParams(CONTACTS_ID, { '{id}': contact.id });

  await axios.delete(path);
};
