import { useRef, useState } from 'react';
import { Button, Divider, Popover, useTheme, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { StatusIcon } from './components/LatencyIcon';
import { AgentBox } from './components/AgentBox';
import { AgentInfo } from './components/AgentInfo';
import { ChangeStatusForm } from './components/ChangeStatusForm';
import { NotificationsOffTwoTone } from '@mui/icons-material';
import { getNotificationsOffDomain } from 'src/services/users/domain/getNotificationsOffDomain';
import { setNotificationsOffDomain } from 'src/services/users/domain/setNotificationsOffDomain';
import { useSelector } from 'src/store/store';
import { setSocialMediaVolumeStore } from 'src/services/users/infraestructure/store/setSocialMediaVolumeStore';
import { useGetCompaniesQuery } from 'src/services/users/application/query/getUsersDataQueries';

interface StateMenuProps {
  expanded: boolean;
}

export const StateMenu: React.FC<StateMenuProps> = ({ expanded }) => {
  const { t }: { t: any } = useTranslation();

  const connectedRTUser = getConnectedRTUser();
  const companies = useGetCompaniesQuery();
  const userCompany = companies?.data?.find(
    (c) => c.id === connectedRTUser?.companyId
  );
  const companyStatus = userCompany?.userStatuses;

  const userStatus = connectedRTUser?.currentStatus;
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const areNotificationsOff = getNotificationsOffDomain(true);
  const handleClose = (): void => {
    setOpen(false);
  };
  const { ringVolume, socialMediaVolume } = useSelector(
    (state) => state.configVolume
  );

  const statusColor = companyStatus?.find(
    (status) => status.name === userStatus?.name
  )?.color;

  return (
    <>
      <StatusIcon
        status={userStatus}
        setIsOpen={setOpen}
        color={statusColor}
        expanded={expanded}
        r={ref}
      />
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Stack direction={'column'}>
          <AgentBox color={statusColor} />
          <AgentInfo />
          <Divider orientation="horizontal"></Divider>
          <ChangeStatusForm userId={connectedRTUser?.id} />
          <Divider orientation="horizontal"></Divider>
          <Stack p={2} spacing={1}>
            <Button
              key={socialMediaVolume}
              variant={socialMediaVolume === 0 ? 'contained' : 'outlined'}
              fullWidth
              onClick={() => {
                if (socialMediaVolume === 0) {
                  setSocialMediaVolumeStore(ringVolume / 100);
                } else {
                  setSocialMediaVolumeStore(0);
                }
              }}
              id="btnMuteSocialMedia-StateMenu"
              color="secondary"
            >
              <NotificationsOffTwoTone /> {t('Mute socialmedia')}
            </Button>
            <Button
              variant={areNotificationsOff ? 'contained' : 'outlined'}
              fullWidth
              onClick={() => {
                setNotificationsOffDomain(!areNotificationsOff);
              }}
              id="btnDisturb-StateMenu"
              color="secondary"
            >
              <NotificationsOffTwoTone /> {t('Do not disturb')}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
