import { queryClient } from 'src/utils/queryClient';
import { TicketsDomain } from '../../domain/TicketsDomain';
import { TicketsFilters } from '../../domain/getTickets';

export const getTicketsQuery = (filters?: TicketsFilters) => {
  return queryClient.fetchQuery(['tickets', filters], {
    queryFn: () => TicketsDomain.getTickets(filters),
    staleTime: 60 * 1000
  });
};
