import metadatas from 'libphonenumber-js/metadata.min.json';
import { getKeys } from '../helpers/object';

export const COUNTRIES = metadatas.countries;

export const COUNTRY_CALLING_CODES = metadatas.country_calling_codes;

export const ISO_CODES = getKeys(COUNTRIES);

export type MuiTelInputCountry = (typeof ISO_CODES)[number];

export const DEFAULT_ISO_CODE: MuiTelInputCountry = 'ES';
