import {
  Avatar,
  Box,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
            background-color: ${theme.colors.secondary.lighter};
            color: ${theme.colors.secondary.main};
            width: ${theme.spacing(4)};
            height: ${theme.spacing(4)};
      `
);

interface Props {
  icon: any;
  title: string;
  button?: React.ReactElement;
}

const VideoCallPanelHeader = (props: Props) => {
  const { icon: Icon, title } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        background: theme.colors.alpha.white[100],
        borderBottom: `${theme.colors.alpha.black[10]} solid 1px`,
        alignItems: 'center',
        height: theme.header.height
      }}
    >
      <Box
        display="flex"
        width={'100%'}
        alignItems="center"
        justifyContent="space-between"
        p={theme.spacing(2)}
        height={theme.header.height}
      >
        <Stack
          display={'flex'}
          direction="row"
          alignItems={'center'}
          height={theme.header.height}
        >
          <AvatarWrapper
            sx={{
              mr: 1.5
            }}
          >
            <Icon />
          </AvatarWrapper>

          <Typography variant="h4" align="justify" color="primary">
            {title}
          </Typography>
        </Stack>
        {props.button}
      </Box>
    </Box>
  );
};

export default VideoCallPanelHeader;
