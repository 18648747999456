import {
  Box,
  Typography,
  Container,
  IconButton,
  Tooltip,
  Button,
  styled,
  Grid
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import LogoBig from 'src/components/LogoBig';
import { Link } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/Inbox';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { logger } from 'src/utils/logger';
const MainContent = styled(Box)(
  () => `
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function UnknownPage({ error, resetErrorBoundary }) {
  const handleDownloadLogs = () => {
    const logs = [
      ...logger.getHistory(),
      { level: 'error', message: error, params: {} }
    ];
    const logsText = logs
      .map((log) => {
        return `${log.level} ${log.message} ${JSON.stringify(log.params)}`;
      })
      .join('\n');
    const blob = new Blob([logsText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'logs.txt';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Helmet>
        <title>{t('Unknown Error')}</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '10px'
          }}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              padding: '5px'
            }}
          >
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                alignItems: 'center',
                width: '100%',
                height: '100%',
                paddingTop: '15px'
              }}
            >
              <Grid container>
                {/* Image */}
                <Grid item xs={12}>
                  <LogoBig />
                </Grid>
                {/* Title */}
                <Grid item xs={12} style={{ padding: '10px' }}>
                  <Typography variant="h1" style={{ textAlign: 'center' }}>
                    {t('Ops!')}
                  </Typography>
                </Grid>
                {/* Subtitle */}
                <Grid item xs={12} style={{ padding: '10px' }}>
                  <Typography
                    variant="h3"
                    color="text.secondary"
                    fontWeight="normal"
                    style={{ textAlign: 'center' }}
                  >
                    {t(
                      'An Error has ocurred! Please contact your administrator'
                    )}
                  </Typography>
                </Grid>
                {/* Image 2 */}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <img
                    alt="Coming Soon"
                    height={200}
                    src="/static/images/status/500.svg"
                  />
                </Grid>
                {/* Button */}
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'center', padding: '20px' }}
                >
                  <Button
                    onClick={handleDownloadLogs}
                    variant="contained"
                    sx={{ width: '30%' }}
                  >
                    {t('Download logs')}
                  </Button>
                </Grid>
                {/* Footer */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      textAlign: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'center'
                      }}
                    >
                      <Tooltip arrow placement="top" title="Back">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                      <Link to="/dashboard/inbox">
                        <Tooltip arrow placement="top" title="Inbox">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              resetErrorBoundary();
                            }}
                          >
                            <InboxIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <Link to="/dashboard/dialerbot">
                        <Tooltip arrow placement="top" title="Dialerbot">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              resetErrorBoundary();
                            }}
                          >
                            <SmartToyIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default UnknownPage;
