import { ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Popover,
  Stack,
  TablePagination,
  TablePaginationProps,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { ViewColumn } from '@mui/icons-material';
import { DialTableColumnActions } from './DialTableColumnActions';
import { useTranslation } from 'react-i18next';
import { GenerateReportButton } from 'src/components/GenerateReportButton';
import { Column, OnChangeFn } from '@tanstack/react-table';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { DialTableTimeZoneSelector } from './DialTableTimeZoneSelector';

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  overflow: auto;
  ::-webkit-scrollbar {
      height: 5px;
  }
  
  ::-webkit-scrollbar-track {
      background: ${theme.colors.alpha.black[10]}; /* Color of the track */
  }
  
  ::-webkit-scrollbar-thumb {
      background: ${theme.colors.alpha.black[50]}; /* Color of the thumb */
      border-radius: 5px; /* Roundness of the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background:${theme.colors.alpha.black[70]}; /* Color of the thumb on hover */
  }
      `
);

interface Props<T extends Object> {
  columns: Column<T>[];
  getIsAllColumnsVisible: () => boolean;
  getToggleAllColumnsVisibilityHandler: () => (event: unknown) => void;
  /** Number of rows displayed on the page */
  displayedRowsLength: number;
  tablePaginationProps: TablePaginationProps;
  bulkActions?: ReactNode;
  isFetching?: boolean;
  /** True if any rows are selected. False, otherwise. */
  selectedBulkActions?: boolean;
  onColumnDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  onGenerateReport?: (reportName: string) => Promise<void>;
  customHeaderActions?: ReactNode;
  showTimeZoneSelector?: boolean;
  timeZone?: string;
  onChangeTimeZone?: OnChangeFn<string>;
}

export const DialTableHeader = <T extends Object>(props: Props<T>) => {
  const {
    bulkActions,
    getIsAllColumnsVisible,
    getToggleAllColumnsVisibilityHandler,
    columns,
    isFetching,
    selectedBulkActions,
    tablePaginationProps,
    onColumnDragEnd,
    onGenerateReport,
    customHeaderActions,
    showTimeZoneSelector,
    timeZone,
    onChangeTimeZone
    // displayedRowsLength,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const theme = useTheme();
  const { t }: { t: any } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        height={theme.header.height}
      >
        {selectedBulkActions && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5" color="text.secondary">
              {t('Bulk actions')}:
            </Typography>
            <Stack direction="row">{bulkActions}</Stack>
          </Stack>
        )}
        {!selectedBulkActions && (
          <Box
            flex={1}
            display={{ xs: 'block', sm: 'flex' }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              {/* <Box mr={2}>
                <Typography component="span" variant="subtitle1">
                {`${t('Showing')}: `}
                </Typography>
                <b>{displayedRowsLength} items</b>
              </Box> */}
              <Stack direction="row" spacing={1}>
                {showTimeZoneSelector && (
                  <DialTableTimeZoneSelector
                    timeZone={timeZone}
                    onChangeTimezone={onChangeTimeZone}
                  />
                )}
                {customHeaderActions}
                <Button
                  size="small"
                  color="secondary"
                  title={t('Edit columns')}
                  startIcon={<ViewColumn />}
                  onClick={handleClick}
                >
                  {t('Edit columns')}
                </Button>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <DialTableColumnActions
                    columns={columns}
                    getIsAllColumnsVisible={getIsAllColumnsVisible}
                    getToggleAllColumnsVisibilityHandler={
                      getToggleAllColumnsVisibilityHandler
                    }
                    onDragEnd={onColumnDragEnd}
                  />
                </Popover>
                {!!onGenerateReport && (
                  <GenerateReportButton
                    variant="text"
                    onGenerateReport={onGenerateReport}
                  />
                )}
              </Stack>
            </Stack>
            <CustomTablePagination {...tablePaginationProps} />
          </Box>
        )}
      </Box>
      <Box sx={{ width: '100%' }}>
        {isFetching ? (
          <LinearProgress
            color="secondary"
            sx={{ borderRadius: 0, height: '3px' }}
          />
        ) : (
          <Box height="3px" />
        )}
      </Box>
      <Divider />
    </Box>
  );
};
