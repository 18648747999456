import { toggleMuteStore } from '../infrastructure/store/toggleMuteStore';
import WebRTCDevices from '../webrtc.devices';

export const toggleMute = () => {
  toggleMuteStore();
  const stream = WebRTCDevices.getOutStream();
  const audioTracks = stream?.getAudioTracks();
  audioTracks?.forEach((track) => {
    track.enabled = !track.enabled;
  });
};
