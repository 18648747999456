import {
  SendInstagramExistingMessageTextApiParams,
  sendInstagramExistingMessageTextApi
} from '../../infrastructure/message/sendInstagramExistingMessageTextApi';
import {
  SendInstagramNewMessageTextApiParams,
  sendInstagramNewMessageTextApi
} from '../../infrastructure/message/sendInstagramNewMessageTextApi';

/**
 * If username and campaignId: adds message to a new conversation.
 * If conversationId and accountId: adds message to an existing conversation.
 */

interface CommonProps {
  text: string;
}

type ConditionalProps =
  | {
      // NEW CONVERSATION
      username: string;
      conversationId?: never;
      campaignId: string;
      ticketId?: string;
      replyTo?: never;
      accountId?: never;
    }
  | {
      // EXISTING CONVERSATION
      username?: never;
      conversationId: string;
      campaignId?: never;
      ticketId?: never;
      replyTo?: string;
      accountId: string;
    };

export type SendInstagramMessageTextParams = CommonProps & ConditionalProps;

export const sendInstagramMessageText = async (
  params: SendInstagramMessageTextParams
) => {
  const {
    text,
    username,
    campaignId,
    conversationId,
    accountId,
    replyTo,
    ticketId
  } = params;
  if (username) {
    const apiParams: SendInstagramNewMessageTextApiParams = {
      campaignId: campaignId,
      content: { text: text },
      username: username,
      ticketId
    };
    await sendInstagramNewMessageTextApi(apiParams);
  } else if (conversationId) {
    const apiParams: SendInstagramExistingMessageTextApiParams = {
      content: { text: text },
      conversationId: conversationId,
      replyTo: replyTo,
      accountId: accountId
    };
    await sendInstagramExistingMessageTextApi(apiParams);
  }
};
