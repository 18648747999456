import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Queue } from 'src/models/ariQueues';

interface AriQueuesState {
  queues: Queue[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  mutedQueueIds: string[];
}

export const MUTED_QUEUES_KEY = 'mutedQueueIds';

const initialState: AriQueuesState = {
  queues: [],
  loading: 'idle',
  mutedQueueIds: JSON.parse(localStorage.getItem(MUTED_QUEUES_KEY)) ?? []
};

export const queuesSlice = createSlice({
  name: 'queues',
  initialState,
  reducers: {
    startLoadingQueues: (state) => {
      state.loading = 'pending';
    },
    setQueues: (state, action: PayloadAction<Queue[]>) => {
      state.queues = action.payload;
      state.loading = 'succeeded';
    },
    setMutedQueueIds: (state, action: PayloadAction<string[]>) => {
      state.mutedQueueIds = action.payload;
    }
  }
});

export const { startLoadingQueues, setQueues, setMutedQueueIds } =
  queuesSlice.actions;

export default queuesSlice.reducer;
