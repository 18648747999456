import { createKnowledgeDocumentationApi } from '../../infrastructure/api/knowledgeModel/createKnowledgeDocumentationApi';
import { KnowledgeDocumentation } from '../../infrastructure/api/knowledgeModel/getKnowledgeDocumentationApi';
import { invalidateKnowledgeDocumentationQuery } from './getKnowledgeModels';

export async function createKnowledgeDocumentation(
  data: KnowledgeDocumentation
) {
  const response = await createKnowledgeDocumentationApi(data);
  invalidateKnowledgeDocumentationQuery();
  return response;
}
