import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Pagination,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { SearchBar } from 'src/components/SearchBar';
import { useGetContactsQuery } from 'src/modules/Contacts/views/hooks/contactQueries';
import useUpdate from 'src/hooks/useUpdateEffect';
import { t } from 'i18next';
import { SearchContactListPopover } from './SearchContactListPopoverItem';
import { ArrowBack } from '@mui/icons-material';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLLIElement | null;
  companyFields: ContactField[];
  selectedContact: Contact;
  setSelectedContact?: Dispatch<SetStateAction<Contact>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  index?: number;
  onClick?: (contact: Contact) => void;
  goBack?: () => void;
}
const PAGE_SIZE = 25;

const ContactListPopover = (props: Props) => {
  const {
    isOpen,
    onClose,
    anchorEl,
    selectedContact,
    companyFields,
    onClick,
    goBack
  } = props;
  const connectedRTUser = getConnectedRTUser();

  const [initialFetch, setInitialFetch] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [paginationInfo, setPaginationInfo] = useState({
    page: 0,
    size: PAGE_SIZE,
    total: 1
  });

  const contactsListQuery = useGetContactsQuery({
    companyId: connectedRTUser?.companyId,
    size: PAGE_SIZE,
    page: paginationInfo.page,
    fieldType: 'Name',
    fieldValue: searchValue.trim() !== '' ? searchValue : undefined,
    contactGroupId: selectedContact?.contactGroupId
  });

  const queryData = contactsListQuery.data;
  const contactsList = contactsListQuery.data?.elements ?? [];

  useEffect(() => {
    contactsListQuery.refetch().then(() => {
      setInitialFetch(true);
    });
  }, [paginationInfo, selectedContact]);

  const [debounceActive, setDebounceActive] = useState(false);

  useEffect(() => {
    contactsListQuery.refetch();
  }, []);

  useUpdate(() => {
    if (searchValue !== '') {
      setDebounceActive(true);
    }
    const timer = setTimeout(async () => {
      await contactsListQuery.refetch();
      setDebounceActive(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Box pt={1} px={1} width={'400px'} height="500px">
        <Stack direction={'column'} width={'100%'} height="100%" spacing={1}>
          <Stack
            direction={'row'}
            justifyContent={'space-around'}
            alignItems="center"
            columnGap={1}
          >
            {!!goBack && (
              <IconButton onClick={goBack}>
                <ArrowBack />
              </IconButton>
            )}
            <SearchBar
              searchValue={searchValue}
              placeholder={`${t('Filter by name')}...`}
              setSearchValue={setSearchValue}
              fullWidth
              autoFocus
              endIcon={
                debounceActive && (
                  <Stack justifyContent={'center'} height={'32px'}>
                    <CircularProgress size="20px" />
                  </Stack>
                )
              }
            />
          </Stack>
          <Stack
            direction={'column'}
            divider={<Divider orientation="horizontal" />}
            sx={{ overflowY: 'auto' }}
            flex={1}
          >
            {contactsList.length === 0 && initialFetch && (
              <Box
                width="100%"
                height={'100%'}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body1" color="GrayText">
                  No contacts
                </Typography>
              </Box>
            )}
            {!initialFetch && (
              <Box
                width="100%"
                height={'100%'}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            )}
            {contactsList.map((contact: Contact, idx) => {
              return (
                <SearchContactListPopover
                  key={idx}
                  contact={contact}
                  companyFields={companyFields}
                  onClick={() => {
                    onClick(contact);
                    onClose();
                  }}
                />
              );
            })}
            {contactsList.length > 0 && queryData?.totalPages > 1 && (
              <Stack
                width={'100%'}
                py={2}
                justifyContent="center"
                alignItems="center"
              >
                <Pagination
                  defaultPage={1}
                  page={paginationInfo.page + 1}
                  onChange={(_, pageNumber) => {
                    setPaginationInfo({
                      ...paginationInfo,
                      page: pageNumber - 1,
                      total: queryData?.totalPages
                    });
                  }}
                  count={queryData?.totalPages || 1}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
    </Popover>
  );
};

export default ContactListPopover;
