import { t } from 'i18next';
import { DialTable } from 'src/components/DialTable';
import { Stack } from '@mui/material';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { useKnowledgeModelsTable } from './hooks/useKnowledgeModelsTable';
import { useAlert } from 'src/hooks/useAlert';
import { deleteKnowledgeDocumentationItems } from '../../InboundRoutes/domain/knowledgeDocumentation/deleteKnowledgeModel';
import { KnowledgeDocumentation } from '../../InboundRoutes/infrastructure/api/knowledgeModel/getKnowledgeDocumentationApi';

export function KnowledgeModels() {
  const {
    getColumnsFn,
    getDataFn,
    getRowActionsFn,
    itemsToDelete,
    setItemsToDelete
  } = useKnowledgeModelsTable();
  const { showAlert } = useAlert();

  return (
    <>
      <ConfirmationModal
        isOpen={itemsToDelete.length > 0}
        data={itemsToDelete}
        dataKey="name"
        mode={ConfirmationModalModes.DELETE}
        onConfirm={async (items) => {
          try {
            await deleteKnowledgeDocumentationItems(
              items.map((item) => item.id)
            );
            showAlert(t('Knowledge models deleted successfully'), 'success');
          } catch (error) {
            showAlert(t('Error deleting knowledge model'), 'error');
          }
          setItemsToDelete([]);
        }}
        closeFn={() => {
          setItemsToDelete([]);
        }}
      />
      <Stack px={4} py={2}>
        <DialTable<KnowledgeDocumentation>
          queryKey={['knowledgeDocumentation']}
          getColumnsFn={getColumnsFn}
          getDataFn={getDataFn}
          getRowActionsFn={getRowActionsFn}
        />
      </Stack>
    </>
  );
}
