import { queryClient } from 'src/utils/queryClient';
import { getChannelActions } from '../domain/getChannelActions';
import { ChatMessage } from 'src/models/conversations/messages';

export const getConversationActionsQuery = (message: ChatMessage) => {
  return queryClient.fetchQuery({
    queryKey: ['conversationActions', message?.channel, message?.orientation],
    queryFn: () => getChannelActions(message),
    staleTime: Infinity
  });
};
