import axios, { AxiosResponse } from 'axios';
import { User } from 'src/models/users';
import { invalidateUsersQuery } from 'src/services/users/domain/getUsersQuery';
import { replaceURLParams } from 'src/services/utils';
import { USERS, USERS_ID } from 'src/utils/apiRoutes/msUsers';

export const deleteUserById = async (id: string): Promise<string> => {
  const path = replaceURLParams(USERS_ID, {
    '{id}': id
  });

  const response: AxiosResponse<string> = await axios.delete(path);

  invalidateUsersQuery();
  return response.data;
};

export const createUser = async (user: Omit<User, 'id'>): Promise<User> => {
  const USER_URL = USERS;

  const response: AxiosResponse<User> = await axios.post(USER_URL, user);

  invalidateUsersQuery();
  return response.data;
};

export const updateUser = async (user: User): Promise<User> => {
  const path = replaceURLParams(USERS_ID, {
    '{id}': user.id
  });

  const response: AxiosResponse<User> = await axios.put(path, user);

  invalidateUsersQuery();
  return response.data;
};
