import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { WhatsApp } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import TemplatePreview from 'src/modules/Dashboard/views/Inbox/WhatsAppPanel/TemplatePreview';
import {
  getWhatsAppTemplate,
  setWhatsAppTemplate
} from 'src/services/socialmedia/domain/messages/whatspAppTemplate';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';
import { setCampaignSelector } from '../utils/setCampaignSelector';
import {
  WhatsAppTemplate,
  WhatsAppTemplateType,
  WhatsAppTemplateVariables
} from 'src/services/whatsapp/models/WhatsAppTemplate';
import PhoneInput from 'src/components/PhoneInput';
import { useWhatsAppTemplatesQuery } from 'src/services/whatsapp/domain/template/getWhatsAppTemplates';
import { WhatsAppTemplateParamsDialog } from './WhatsAppTemplateParamsDialog';
import { getWhatsAppTemplateParamsValidationSchema } from '../utils/getWhatsAppTemplateParamsSchema';
import {
  SendWhatsAppMessageTemplateParams,
  sendWhatsAppMessageTemplate
} from 'src/services/whatsapp/domain/message/sendWhatsAppMessageTemplate';
import { useAlert } from 'src/hooks/useAlert';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import TemplateCard from 'src/modules/Admin/modules/ContactCenter/views/WhatsAppAccountView/views/components/templates/TemplateCard';
import { useDialpadStateContext } from 'src/contexts/CTIBarContext';
import { emitCustomEvent } from 'react-custom-events';

interface Props {
  /** Default is true */
  useCTIBarContext?: boolean;
  /** ignored if useCTIBarContext equals to true */
  inputValue?: string;
  /** ignored if useCTIBarContext equals to true */
  setInputValue?: Dispatch<SetStateAction<string>>;
}

export function WhatsAppConversation({
  inputValue: inputValueProps,
  setInputValue: setInputValueProps,
  useCTIBarContext = true
}: Props) {
  const { showAlert } = useAlert();
  const selectedWhatsAppTemplate = getWhatsAppTemplate(true);
  const templateVariablesValidationSchema =
    getWhatsAppTemplateParamsValidationSchema(
      selectedWhatsAppTemplate?.exampleVariableValues || {}
    );

  const { selectedTicket } = useDialpadStateContext();

  const {
    inputValue: inputValueContext,
    setInputValue: setInputValueContext,
    countryCode,
    setCountryCode
  } = useDialpadStateContext();

  const [inputValue, setInputValue] = useCTIBarContext
    ? [inputValueContext, setInputValueContext]
    : [inputValueProps, setInputValueProps];

  const [
    isOpenWhatsAppTemplateParamsModal,
    setIsOpenWhatsAppTemplateParamsModal
  ] = useState(false);
  const [whatsAppTemplateParams, setWhatsAppTemplateParams] =
    useState<WhatsAppTemplateVariables>({} as WhatsAppTemplateVariables);
  const [file, setFile] = useState<File>();

  const isDisabledSendTemplateButton =
    !selectedWhatsAppTemplate ||
    (selectedWhatsAppTemplate?.type === WhatsAppTemplateType.TEXT &&
      (inputValue.trim().length === 0 ||
        !templateVariablesValidationSchema.isValidSync(
          whatsAppTemplateParams
        )));

  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const selectedCampaign = useMemo(() => {
    return campaigns.find((campaign) => campaign.id === selectedCampaignId);
  }, [selectedCampaignId]);

  const [open, setOpen] = useState(false);
  const [previewTemplate, setPreviewTemplate] =
    useState<WhatsAppTemplate>(null);
  const currentTemplate = getWhatsAppTemplate(true);

  const ticketId = selectedTicket?.id;

  const theme = useTheme();
  const { t } = useTranslation();

  const campaignsQuery = useUserCampaignsQuery(true);
  const templatesQuery = useWhatsAppTemplatesQuery();
  const templateQueryData = templatesQuery?.data ?? [];

  const templates = useMemo(() => {
    const campaignTemplates = templateQueryData.filter((template) => {
      return template.accountId === selectedCampaign?.accountId;
    });
    return campaignTemplates;
  }, [selectedCampaignId, templateQueryData]);

  const handleCloseModal = () => {
    setPreviewTemplate(null);
    setOpen(false);
  };

  const onChangeSelect = (id: string): void => {
    setSelectedCampaignId(id);
    localStorage.setItem('selectedWhatsappCampaign', id);
  };

  const handleStartConversation = async () => {
    const params = {
      phoneNumber: inputValue,
      campaignId: selectedCampaignId,
      templateId: selectedWhatsAppTemplate?.id,
      templateParams: whatsAppTemplateParams,
      ticketId
    } as SendWhatsAppMessageTemplateParams;

    if (selectedWhatsAppTemplate?.type !== WhatsAppTemplateType.TEXT) {
      params.file = file;
    }

    try {
      await sendWhatsAppMessageTemplate(
        params as SendWhatsAppMessageTemplateParams
      );
      showAlert(
        t('The mesage template was successfully sent'),
        'success',
        3000
      );
      setWhatsAppTemplate(null);
      setInputValue('');
      if (useCTIBarContext) {
        emitCustomEvent('Close popover');
      }
    } catch (error) {
      showAlert(getErrorMessage(error)?.errorMessage, 'error', 3000);
    }
  };

  useEffect(() => {
    setCampaignSelector(
      'selectedWhatsappCampaign',
      setCampaigns,
      setSelectedCampaignId,
      campaignsQuery?.data?.elements,
      'WhatsApp',
      selectedTicket?.serviceId
    );

    return () => {
      setWhatsAppTemplate(null);
    };
  }, [campaignsQuery?.dataUpdatedAt]);

  useEffect(() => {
    if (campaigns?.length === 1) {
      setSelectedCampaignId(campaigns?.[0]?.id);
    }
  }, [campaigns]);

  const openTemplatePreview = (template: WhatsAppTemplate) => {
    setPreviewTemplate(template);
    setOpen(true);
  };

  const getWhatsAppTemplateParams = (template: WhatsAppTemplate) => {
    const exampleVariableValues = template?.exampleVariableValues ?? {};
    return Object.keys(exampleVariableValues).reduce((prev, curr) => {
      return { ...prev, [curr]: '' };
    }, {});
  };

  const onSelectTemplate = (template: WhatsAppTemplate) => {
    // Unselect
    if (
      selectedWhatsAppTemplate &&
      selectedWhatsAppTemplate?.id === template?.id
    ) {
      setWhatsAppTemplateParams({});
      setWhatsAppTemplate(null);
      return;
    }
    // Select
    setWhatsAppTemplate(template);
    const whatsAppTemplateParams = getWhatsAppTemplateParams(template);
    if (Object.keys(whatsAppTemplateParams).length > 0) {
      setIsOpenWhatsAppTemplateParamsModal(true);
      setWhatsAppTemplateParams(whatsAppTemplateParams);
      return;
    }

    if (
      template.type === WhatsAppTemplateType.DOCUMENT ||
      template.type === WhatsAppTemplateType.IMAGE
    ) {
      setIsOpenWhatsAppTemplateParamsModal(true);
    }
  };

  const onChangeWhatsAppTemplateParams = (whatsAppTemplateParams) => {
    setWhatsAppTemplateParams(whatsAppTemplateParams);
  };

  const onSubmitWhatsAppTemplateParams = () => {
    setIsOpenWhatsAppTemplateParamsModal(false);
  };

  const onCloseWhatsAppTemplateParams = () => {
    setIsOpenWhatsAppTemplateParamsModal(false);
    setWhatsAppTemplateParams({});
    setWhatsAppTemplate(null);
  };

  const actionButtons = (template: WhatsAppTemplate) => {
    const isTemplateSelected =
      currentTemplate?.id && currentTemplate?.id === template?.id;

    return (
      <Stack width="100%" direction={'row'} columnGap={1}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => openTemplatePreview(template)}
          id="btnPreview-WhatsAppTemplate"
        >
          {t('Preview')}
        </Button>

        <Button
          fullWidth
          variant={isTemplateSelected ? 'contained' : 'outlined'}
          color="secondary"
          onClick={() => onSelectTemplate(template)}
        >
          {isTemplateSelected ? t('Selected') : t('Select')}
        </Button>
      </Stack>
    );
  };

  useEffect(() => {
    setFile(null);
  }, [selectedWhatsAppTemplate]);

  return (
    <>
      {isOpenWhatsAppTemplateParamsModal && (
        <WhatsAppTemplateParamsDialog
          subheader={selectedWhatsAppTemplate?.name}
          open={isOpenWhatsAppTemplateParamsModal}
          whatsAppTemplateParams={whatsAppTemplateParams}
          onChange={onChangeWhatsAppTemplateParams}
          onSubmit={onSubmitWhatsAppTemplateParams}
          onClose={onCloseWhatsAppTemplateParams}
          type={selectedWhatsAppTemplate?.type}
          file={file}
          setFile={setFile}
        />
      )}
      {open && (
        <TemplatePreview
          open={open}
          handleCloseModal={handleCloseModal}
          template={previewTemplate}
          templateParams={
            previewTemplate?.id === selectedWhatsAppTemplate?.id
              ? whatsAppTemplateParams
              : {}
          }
        />
      )}
      <Card sx={{ width: '847px' }}>
        <Stack
          width="100%"
          direction={'row'}
          justifyContent={'start'}
          alignItems="center"
          color={theme.colors.secondary.main}
          sx={{
            backgroundColor: '#F6F8FB',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }}
          gap={2}
          px={2}
          borderRadius={1}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <WhatsApp color="success" />
            <h3 style={{ color: theme.colors.success.main }}>
              {t('New WhatsApp Conversation')}
            </h3>
          </Box>
        </Stack>
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" />}
        >
          <Box p={3}>
            <PhoneInput
              id="number-WhatsAppConversation"
              sx={{
                width: { xs: '100%', sm: '352px' }
              }}
              phoneNumber={inputValue}
              setPhoneNumber={setInputValue}
              callType={'External'}
              setCallType={undefined}
              disableCallTypeSelector
              countryCode={countryCode}
              setCountryCode={setCountryCode}
            />
          </Box>

          <Stack width="100%" justifyContent={'center'} height="350px">
            <Scrollbar>
              <Grid container spacing={2} p={1}>
                {templates?.length === 0 ? (
                  <Grid item xs={12}>
                    <Stack
                      width="100%"
                      height="350px"
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography textAlign={'center'} variant="h4">
                        {t('No templates found for this campaign')}
                      </Typography>
                    </Stack>
                  </Grid>
                ) : (
                  templates?.map((template, idx) => (
                    <Grid item xs={12} sm={6} key={idx}>
                      <TemplateCard
                        template={template}
                        footerElement={actionButtons}
                        sx={{
                          height: '100%'
                        }}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Scrollbar>
          </Stack>

          <Box width="100%" p={2}>
            <Stack direction="row" justifyContent={'space-between'}>
              <Select
                placeholder="Campaña"
                sx={{ height: '40px', width: '240px' }}
                value={selectedCampaignId}
                onChange={(e) => onChangeSelect(e.target.value)}
                id="campana-WhatsAppConversation"
              >
                {campaigns.map((c, idx) => {
                  return (
                    <MenuItem key={idx} value={c.id}>
                      {c.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  disabled={
                    isDisabledSendTemplateButton || inputValue.length <= 5
                  }
                  color="secondary"
                  variant="contained"
                  onClick={handleStartConversation}
                >
                  {t('Send')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>
    </>
  );
}
