import { DeveloperBoardOffOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

const NoCampaginPlaceHolder = () => {
  return (
    <Box
      sx={{
        minHeight: 500,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 2,
        paddingBottom: 4
      }}
    >
      <DeveloperBoardOffOutlined sx={{ fontSize: 100 }} />
      <Typography variant="h3">{t('No channels available') + '.'}</Typography>
      <Typography variant="h5" color="GrayText">
        {t('You must be assigned to an outbound campaign') + '.'}
      </Typography>
    </Box>
  );
};

export default NoCampaginPlaceHolder;
