import { IconButton, Tooltip } from '@mui/material';
import { MouseEvent } from 'react';

interface Props {
  Icon: React.ElementType;
  onClick?: (e: MouseEvent) => void;
  style?: React.CSSProperties;
  title: string;
}

function EmailHeaderIconWrapper({ Icon, onClick, style, title }: Props) {
  return (
    <Tooltip title={title} arrow>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
        }}
        size="small"
        style={{ height: '30px', width: '35px', ...style }}
      >
        <Icon variant={'outlined'} fontSize="small" style={{ padding: 0 }} />
      </IconButton>
    </Tooltip>
  );
}

export default EmailHeaderIconWrapper;
