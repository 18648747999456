import {
  FC,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext
} from 'react';

interface ScreenContext {
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScreenContext = createContext<ScreenContext>({} as ScreenContext);

export const ScreenProvider: FC = ({ children }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <ScreenContext.Provider
      value={{
        isFullScreen,
        setIsFullScreen
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export const useFullScreen = () => {
  const { isFullScreen, setIsFullScreen } = useContext(ScreenContext);
  return { isFullScreen, setIsFullScreen };
};
