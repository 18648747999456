import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { useTheme } from '@mui/material';
import { getTicketHistoryItemText } from '../../../utils/getTicketHistoryItemText';
import { TicketChangeHistory } from '../../../../models/tickets';
import { useEffect, useState } from 'react';

interface Props {
  ticketHistory: TicketChangeHistory;
  author?: string;
}

const TicketHistoryItem = (props: Props) => {
  const { ticketHistory, author } = props;
  const [historyItemText, setHistoryItemText] = useState<JSX.Element>(null);
  const theme = useTheme();

  useEffect(() => {
    getTicketHistoryItemText(ticketHistory, author).then((resp) => {
      setHistoryItemText(resp);
    });
  }, [ticketHistory, author]);

  return (
    <TimelineItem
      sx={{
        p: 0
      }}
    >
      <TimelineOppositeContent
        sx={{
          width: '85px',
          flex: 'none',
          mr: 2
        }}
      >
        {new Date(ticketHistory?.timestamp).toLocaleString()}
      </TimelineOppositeContent>
      <TimelineSeparator
        sx={{
          position: 'relative'
        }}
      >
        <TimelineDot
          sx={{
            marginTop: 0,
            top: theme.spacing(1.2)
          }}
          variant="outlined"
          color="primary"
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          pb: 4
        }}
      >
        {historyItemText}
      </TimelineContent>
    </TimelineItem>
  );
};

export default TicketHistoryItem;
