import { AutoAwesome, CheckCircleOutline } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  MenuItem,
  Popover,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { AIModelUseCase } from '../../../models/AIModels';
import {
  AnimatePresence,
  motion,
  MotionConfig,
  useAnimation
} from 'framer-motion';
import { GenerateDataApiProps } from 'src/modules/Admin/modules/Quality/modules/AnalyzedRecordings/infrastructure/generateDataApi';
import { generateDataDomain } from 'src/modules/Admin/modules/Quality/modules/AnalyzedRecordings/domain/generateDataDomain';
import { useAlert } from 'src/hooks/useAlert';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';

interface Props {
  intentionName: string;
  language: 'Spanish' | 'English';
  onDataGenerated: (generatedTags: string[]) => void;
}

function GenerateTagsPopover({
  intentionName,
  onDataGenerated,
  language
}: Props) {
  const popoverAnchor = useRef();
  const [openGeneratePopover, setOpenGeneratePopover] = useState(false);

  const [generateData, setGenerateData] = useState<GenerateDataApiProps>({
    intention: intentionName,
    prompt: '',
    useCase: AIModelUseCase.STATISTIC_STUDY,
    language: language,
    numberOfExamples: 40
  });

  const useCaseConverter: {
    [key in AIModelUseCase]: string;
  } = {
    [AIModelUseCase.APPOINTMENTS]: 'Appointments',
    [AIModelUseCase.CLIENT_SUPPORT]: 'Client support',
    [AIModelUseCase.DEBT_RECOVERY]: 'Debt recovery',
    [AIModelUseCase.STATISTIC_STUDY]: 'Statistic study',
    [AIModelUseCase.TELEMARKETING]: 'Telemarketing'
  };

  const theme = useTheme();

  const controls = useAnimation();

  const [phase, setPhase] = useState<'input' | 'loading' | 'success'>('input');
  const { showAlert } = useAlert();

  function handleClose() {
    setOpenGeneratePopover(false);

    setTimeout(() => {
      setPhase('input');

      //reset form
      setGenerateData({
        intention: intentionName,
        prompt: '',
        useCase: AIModelUseCase.STATISTIC_STUDY,
        language: 'Spanish',
        numberOfExamples: 40
      });
    }, 300);
  }

  const disableButton = !generateData.intention || !generateData.prompt;

  async function onClickGenerate() {
    if (phase !== 'input') return;
    if (disableButton) return;

    setPhase('loading');

    try {
      const response = await generateDataDomain(generateData);
      onDataGenerated(response[intentionName]);
      setPhase('success');
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (err) {
      setTimeout(() => {
        setPhase('input');
        const errorMessage =
          err?.response?.data?.detail[0].msg ??
          getErrorMessage(err).errorMessage;
        showAlert(errorMessage, 'error', 3000);
      }, 1000);
    }
  }

  const variants = {
    input: {
      height: '42.5px',
      width: '414px',
      backgroundColor: theme.colors.secondary.main
    },
    loading: {
      height: '355.75px',
      width: '414px',
      backgroundColor: 'white',
      color: theme.colors.secondary.main
    },
    success: {
      height: '355.75px',
      width: '414px',
      backgroundColor: 'white',
      color: theme.colors.secondary.main
    }
  };

  return (
    <>
      <Button
        ref={popoverAnchor}
        variant="outlined"
        color="secondary"
        startIcon={<AutoAwesome />}
        sx={{
          background: 'transparent'
        }}
        onClick={() => {
          setOpenGeneratePopover(true);
        }}
      >
        {t('Generate tags with AI')}
      </Button>
      <Popover
        open={openGeneratePopover}
        anchorEl={popoverAnchor.current}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClose}
      >
        <Stack p={2} minWidth={450} rowGap={2} position={'relative'}>
          <TextField
            label={t('Use case')}
            select
            value={generateData.useCase}
            onChange={(e) => {
              setGenerateData({
                ...generateData,
                useCase: e.target.value as AIModelUseCase
              });
            }}
          >
            {Object.values(AIModelUseCase).map((useCase) => {
              return (
                <MenuItem key={useCase} value={useCase}>
                  {t(useCaseConverter[useCase])}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            fullWidth
            type="number"
            InputProps={{
              inputProps: {
                max: 50
              }
            }}
            label={t('Number of tags')}
            value={generateData.numberOfExamples}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (value > 50 || value <= 0) {
                return;
              }
              setGenerateData({
                ...generateData,
                numberOfExamples: parseInt(e.target.value)
              });
            }}
          />
          <TextField
            placeholder={t('Enter prompt to generate tags')}
            minRows={5}
            maxRows={5}
            multiline
            value={generateData.prompt}
            onChange={(e) => {
              setGenerateData({
                ...generateData,
                prompt: e.target.value
              });
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenGeneratePopover(false);
            }}
          >
            {t('Generate')}
          </Button>

          <motion.div
            initial={'input'}
            animate={phase}
            variants={variants}
            style={{
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontWeight: 'bold',
              cursor: 'pointer',
              position: 'absolute',
              gap: '18px',
              bottom: 18,
              zIndex: 100
            }}
            transition={{
              type: 'spring',
              bounce: 0,
              duration: 0.3
            }}
            onClick={onClickGenerate}
          >
            <MotionConfig
              transition={{
                type: 'spring',
                bounce: 0,
                duration: 0.2
              }}
            >
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.span
                  key="generateText"
                  initial={{ opacity: 0, x: -30 }}
                  animate={{
                    opacity: 1,
                    scale: phase !== 'input' ? 1.3 : 1,
                    x: 0
                  }}
                  exit={{ opacity: 0, x: 30 }}
                  layoutId="generateText"
                >
                  {phase === 'input' && t('Generate tags')}
                  {phase === 'loading' && t('Generating tags')}
                  {phase === 'success' && t('Tags generated')}
                </motion.span>
                {phase === 'loading' && (
                  <motion.span
                    key={'loadingSpinner'}
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, x: 30 }}
                  >
                    <CircularProgress color="secondary" />
                  </motion.span>
                )}
                {phase === 'success' && (
                  <motion.span
                    key={'loadingIcon'}
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                  >
                    <CheckCircleOutline
                      sx={{
                        height: '50px',
                        width: '50px'
                      }}
                    />
                  </motion.span>
                )}
              </AnimatePresence>
            </MotionConfig>
          </motion.div>
        </Stack>
      </Popover>
    </>
  );
}

export default GenerateTagsPopover;
