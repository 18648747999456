import {
  Card,
  MenuItem,
  Popover,
  Stack,
  styled,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Fragment, useMemo, useRef, useState } from 'react';
import {
  TicketField,
  TicketFieldType,
  TicketFieldValue
} from '../../../../models/tickets';
import { ContactField } from 'src/modules/Contacts/models/contacts';
import { ShowflowVariableType } from '../hooks/useShowflowVariable';
import { t } from 'i18next';
import { motion } from 'framer-motion';

interface Props {
  setIsEditing: (isEditing: boolean) => void;
  type: ShowflowVariableType;
  hasValue: boolean;
  value: string | string[] | boolean;
  setIsHovered: (isHovered: boolean) => void;
  applyChanges: (value: string | boolean | string[]) => void;
  field: TicketField | ContactField;
  setIsLoading: (isLoading: boolean) => void;
}

export function EditInlineShowflowField({
  hasValue,
  value,
  setIsEditing,
  applyChanges,
  field,
  type,
  setIsLoading
}: Props) {
  const getTextInputValue = () => {
    if (type === 'Contact') return value as string;

    const ticketField = field as TicketField;
    if (ticketField?.type === TicketFieldType.TEXT) return value as string;

    return '';
  };

  const [textInputValue, setTextInputValue] = useState(getTextInputValue());

  const inputType = useMemo(() => {
    if (type === 'Contact') return TicketFieldType.TEXT;
    return field.type;
  }, [field?.id, type]);

  if (inputType === TicketFieldType.TEXT)
    return (
      <input
        type="text"
        value={textInputValue}
        placeholder={hasValue ? '' : t('Enter value')}
        autoFocus
        onChange={(e) => setTextInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsEditing(false);
            if (textInputValue !== getTextInputValue()) {
              applyChanges(textInputValue);
              setIsLoading(true);
            }
          }
        }}
        onBlur={() => {
          setIsEditing(false);
          if (textInputValue !== getTextInputValue()) {
            applyChanges(textInputValue);
            setIsLoading(true);
          }
        }}
        style={{
          border: 0,
          outline: 'none',
          fontSize: '14px',
          background: 'transparent',
          color: 'black',
          fontWeight: 'bold'
        }}
      />
    );

  const ticketFieldValue = value as string | boolean | string[];
  if (inputType === TicketFieldType.BOOLEAN) {
    function handleChange(value: boolean) {
      applyChanges(value);
      setIsEditing(false);
    }

    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'baseline',
          flexDirection: 'row'
        }}
      >
        <motion.div
          style={{
            width: '40px',
            position: 'relative'
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '-11px',
              left: 0
            }}
          >
            <Switch
              checked={ticketFieldValue as boolean}
              color="warning"
              onChange={(e) => handleChange(e.target.checked)}
              sx={{
                marginLeft: '-10px'
              }}
            />
          </div>
        </motion.div>
        <Typography fontWeight={'bold'}>{field?.name}</Typography>
      </div>
    );
  }

  if (inputType === TicketFieldType.OPTIONS) {
    const values = ticketFieldValue as unknown as string[];
    const hasValues = values?.length > 0;

    const valuesString = values?.join?.(', ');

    return (
      <Typography fontWeight={'bold'}>
        {hasValues ? valuesString : field?.name}
      </Typography>
    );
  }

  return <></>;
}
