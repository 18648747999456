import { Queue } from 'src/models/ariQueues';
import { setMutedQueueIds } from 'src/store/slices/queues/queuesSlice';
import store, { useSelector } from 'src/store/store';

export function setMutedQueuesStore(queues: string[]) {
  store.dispatch(setMutedQueueIds(queues));
}

export function getMutedQueuesStore(shouldUseHook: boolean) {
  const mutedQueues = (
    shouldUseHook
      ? useSelector((state) => state.queues.mutedQueueIds)
      : store.getState().queues.mutedQueueIds
  ) as string[];

  return mutedQueues;
}
