import { StarTwoTone } from '@mui/icons-material';
import axios from 'axios';
import CallHelper from 'src/modules/CTI/infrastructure/callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import {
  ASTERISK_MS,
  CALLS_RECORDING_START
} from 'src/utils/apiRoutes/msAsterisk';

export interface StartRecordingResponse {
  recordingId: string;
}

export const startRecordingApi = async (callId: string, instance: string) => {
  const url =
    CallHelper.setInstanceID(ASTERISK_MS, instance) + CALLS_RECORDING_START;

  const response = await axios.post(url);

  return response.data as StartRecordingResponse;
};
