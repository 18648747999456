import { MenuItem, Slider, Stack, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { ColorPicker } from '../../../../components/Style/ColorPicker';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../../models/webChatStyle';
import { FormikProps } from 'formik';
import { useWebChatStyle } from '../../../../utils/useWebChatStyle';

interface ConversationAccordionProps {
  formikProps: FormikProps<WebChatStyle>;
}

const ConversationAccordion: React.FC<ConversationAccordionProps> = ({
  formikProps
}) => {
  const { fontSliderMarks, maxWidthMarks, marginMarks } = useWebChatStyle();

  return (
    <>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Contact message background')}
            color={
              formikProps.values.conversationStyle.contactSpeechBubbleBGColor
                .color
            }
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.contactSpeechBubbleBGColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Contact message text')}
            color={
              formikProps.values.conversationStyle.contactSpeechBubbleTextColor
                .color
            }
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.contactSpeechBubbleTextColor.color',
                c
              );
            }}
          />
        </Stack>
      </Stack>
      <Typography variant="h5">{t('Agent message')}:</Typography>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Agent message background')}
            color={
              formikProps.values.conversationStyle.agentSpeechBubbleBGColor
                .color
            }
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.agentSpeechBubbleBGColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Agent message text')}
            color={
              formikProps.values.conversationStyle.agentSpeechBubbleTextColor
                .color
            }
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.agentSpeechBubbleTextColor.color',
                c
              );
            }}
          />
        </Stack>
      </Stack>
      <Typography variant="h5">{t('Others')}:</Typography>
      <Stack>
        <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
          <Stack paddingRight="40px">
            <ColorPicker
              title={t('Scrollbar color')}
              color={formikProps.values.conversationStyle.scrollbarColor.color}
              setColor={(c) => {
                formikProps.setFieldValue(
                  'conversationStyle.scrollbarColor.color',
                  c
                );
              }}
            />
          </Stack>
          <Stack paddingRight="40px">
            <ColorPicker
              title={t('Conversation background')}
              color={
                formikProps.values.conversationStyle.convBackGroundColor.color
              }
              setColor={(c) => {
                formikProps.setFieldValue(
                  'conversationStyle.convBackGroundColor.color',
                  c
                );
              }}
            />
          </Stack>
          <TextField
            select
            sx={{ width: 110, minWidth: 100 }}
            label={t('Shadow')}
            value={
              formikProps.values.conversationStyle.shadowBox ? 'Yes' : 'No'
            }
            onChange={(e) => {
              formikProps.setFieldValue(
                'conversationStyle.shadowBox',
                e.target.value === 'Yes'
              );
            }}
          >
            <MenuItem value={'Yes'}>{t('Yes')}</MenuItem>
            <MenuItem value={'No'}>{t('No')}</MenuItem>
          </TextField>
        </Stack>
      </Stack>
      <Stack
        direction={{ md: 'column', lg: 'row' }}
        spacing={{ sx: 0, md: 2, lg: 5 }}
      >
        <Stack minWidth="28%">
          <Typography variant="subtitle1">{t('Font size')}</Typography>
          <Slider
            color="secondary"
            min={15}
            max={25}
            valueLabelDisplay="auto"
            marks={fontSliderMarks}
            value={formikProps.values.conversationStyle.fontSize}
            onChange={(event, value) => {
              formikProps.setFieldValue('conversationStyle.fontSize', value);
            }}
            id="switchSlider-StyleModifiersFontSize"
          />
        </Stack>
        <Stack minWidth="28%">
          <Typography variant="subtitle1">{t('Margin')}</Typography>
          <Slider
            color="secondary"
            min={5}
            max={25}
            marks={marginMarks}
            valueLabelDisplay="auto"
            value={formikProps.values.conversationStyle.margin}
            onChange={(event, value) => {
              formikProps.setFieldValue('conversationStyle.margin', value);
            }}
            id="switchSlider-StyleModifiersMargin"
          />
        </Stack>
        <Stack minWidth="28%">
          <Typography variant="subtitle1">{t('Max width')}</Typography>
          <Slider
            color="secondary"
            min={75}
            max={275}
            marks={maxWidthMarks}
            valueLabelDisplay="auto"
            value={formikProps.values.conversationStyle.maxWidth}
            onChange={(event, value) => {
              formikProps.setFieldValue('conversationStyle.maxWidth', value);
            }}
            id="switchSlider-StyleModifiersMaxWidth"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ConversationAccordion;
