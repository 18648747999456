import { emitCustomEvent } from 'react-custom-events';
import { TicketsDomain } from '../../domain/TicketsDomain';
import {
  ReportingTicket,
  Ticket,
  TicketConversationSummary
} from '../../models/tickets';
import { t } from 'i18next';
import { Conversation } from 'src/models/conversations/conversations';
import { getTicketsQuery } from './getTicketsQuery';
import { ReportingTicketQuerySelect } from '../../domain/reporting';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { Option } from 'src/components/DialTable/models/option';
import {
  CustomFilterValueOptions,
  DataCategory,
  DialTableFilter,
  FieldType,
  ObjectIdOperator,
  StringOperator
} from 'src/components/DialTable/models/filters';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';
import { getDialTableFilterLabel } from 'src/components/DialTable/utils/getDialTableFilterLabel';
import { getHiddenVisibilityStateFromColumns } from 'src/components/DialTable/utils/getHiddenVisibilityStateFromColumns';
import { generateReportingTicket } from '../../domain/reporting/generateReportingTicket';
import { getTicketFieldsQuery } from '../../domain/fields';
import {
  DialTableReportContext,
  DialTableDataContext
} from 'src/components/DialTable/models/functionContexts';
import { queryClient } from 'src/utils/queryClient';
import { getSort } from 'src/components/DialTable/utils/getSort';
import { getConversationReportinTicketsQuery } from './getConversationReportingTicketsQuery';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';

const CONVERSATION_FILTER_FIELDNAME = 'conversationSummaries._id';

export const getReportingTicketSelectArray = (
  ticketKeys: (keyof ReportingTicket)[]
) => {
  return ticketKeys.map(getReportingTicketSelect);
};

export const getReportingTicketSelect = (ticketKey: keyof ReportingTicket) => {
  const select: ReportingTicketQuerySelect = {
    value: ticketKey,
    type: 'TICKET'
  };
  return select;
};

export const initialSorting: SortingState = [
  { id: 'ticket.metadata.modificationDate', desc: true }
];

export const sort = getSort<ReportingTicket>(initialSorting);

export const initialVisibleColumns: ColumnDef<ReportingTicket>[] = [
  {
    accessorFn: (ticket) => ticket['ticket.friendlyId'],
    id: 'ticket.friendlyId',
    header: t('Id')
  },
  {
    accessorKey: 'title',
    id: 'title',
    header: t('Title')
  },
  {
    accessorFn: (ticket) => ticket['serviceId.name'],
    id: 'serviceId.name',
    header: t('Service')
  },
  {
    accessorFn: (ticket) => ticket['involvedContacts.uniqueField'],
    id: 'involvedContacts.uniqueField',
    header: t('Contacts')
  },
  {
    accessorFn: (ticket) => ticket['subResolution.name'],
    id: 'subResolution.name',
    header: t('Subtypology')
  }
];

export const initialHiddenColumns: ColumnDef<ReportingTicket>[] = [
  {
    accessorFn: (ticket) => ticket['ticket.metadata.creationDate'],
    id: 'ticket.metadata.creationDate',
    header: t('Creation date')
  },
  {
    accessorFn: (ticket) => ticket['ticket.metadata.modificationDate'],
    id: 'ticket.metadata.modificationDate',
    header: t('Modification date')
  },
  {
    accessorFn: (ticket) => ticket['subResolution.resolution'],
    id: 'subResolution.resolution',
    header: t('Typology')
  },
  {
    accessorFn: (ticket) => ticket['subResolution.type'],
    id: 'subResolution.type',
    header: t('Subtypology status')
  },
  {
    accessorFn: (ticket) => ticket['tagIds.name'],
    id: 'tagIds.name',
    header: t('Tags')
  },
  {
    accessorFn: (ticket) => ticket['assignedUserIds.name'],
    id: 'assignedUserIds.name',
    header: t('Assigned users')
  },
  {
    accessorFn: (ticket) => ticket['participants.name'],
    id: 'participants.name',
    header: t('Participants')
  }
];

const initialSelectKeys: (keyof ReportingTicket)[] = [
  ...initialVisibleColumns,
  ...initialHiddenColumns
].map((column) => column.id);

const initialSelect = getReportingTicketSelectArray(initialSelectKeys);

export const getInitialTicketColumnsVisibility = (
  columns: ColumnDef<ReportingTicket>[]
) => {
  const hiddenColumns = columns.filter(
    (column) =>
      !initialVisibleColumns.some(
        (visibleColumn) => visibleColumn.id === column.id
      )
  );
  const initialVisibilityState =
    getHiddenVisibilityStateFromColumns(hiddenColumns);
  return initialVisibilityState;
};

export const getInitialExtendedTicketColumnsVisibility = (
  columns: ColumnDef<ReportingTicket>[]
) => {
  const hiddenColumns = columns.filter(
    (column) =>
      !initialExtendedVisibleColumns.some(
        (visibleColumn) => visibleColumn.id === column.id
      )
  );
  const initialVisibilityState =
    getHiddenVisibilityStateFromColumns(hiddenColumns);
  return initialVisibilityState;
};

export const initialExtendedVisibleColumns: ColumnDef<ReportingTicket>[] = [
  {
    accessorFn: (ticket) => ticket['ticket.friendlyId'],
    id: 'ticket.friendlyId',
    header: t('Id')
  },
  {
    accessorKey: 'title',
    id: 'title',
    header: t('Title')
  },
  {
    accessorFn: (ticket) => ticket['serviceId.name'],
    id: 'serviceId.name',
    header: t('Service')
  },
  {
    accessorFn: (ticket) => ticket['involvedContacts.uniqueField'],
    id: 'involvedContacts.uniqueField',
    header: t('Contacts')
  },
  {
    accessorFn: (ticket) => ticket['subResolution.name'],
    id: 'subResolution.name',
    header: t('Subtypology')
  },
  {
    accessorFn: (ticket) => ticket['subResolution.resolution'],
    id: 'subResolution.resolution',
    header: t('Typology')
  },
  {
    accessorFn: (ticket) => ticket['subResolution.type'],
    id: 'subResolution.type',
    header: t('Subtypology status')
  },
  {
    accessorFn: (ticket) => ticket['tagIds.name'],
    id: 'tagIds.name',
    header: t('Tags')
  },
  {
    accessorFn: (ticket) => ticket['assignedUserIds.name'],
    id: 'assignedUserIds.name',
    header: t('Assigned users')
  },
  {
    accessorFn: (ticket) => ticket['participants.name'],
    id: 'participants.name',
    header: t('Participants')
  },
  {
    accessorFn: (ticket) => ticket['ticket.metadata.creationDate'],
    id: 'ticket.metadata.creationDate',
    header: t('Creation date')
  },
  {
    accessorFn: (ticket) => ticket['ticket.metadata.modificationDate'],
    id: 'ticket.metadata.modificationDate',
    header: t('Modification date')
  }
];

const noDisplayedColumns = [
  {
    accessorKey: 'id',
    id: 'id'
  }
];
const noDisplayedColumnIds = noDisplayedColumns.map((column) => column.id);

export const getReportingTicketColumns = async () => {
  return Promise.resolve([...initialVisibleColumns, ...initialHiddenColumns]);
};

export const getExtendedReportingTicketColumnsCallback =
  (companyId: string) => async () => {
    const ticketFieldColumns = await getTicketFieldTableColumns(companyId);
    return Promise.resolve([
      ...initialExtendedVisibleColumns,
      ...ticketFieldColumns
    ]);
  };

export const excludedReportingFilterFieldNames = [
  'assignedUserIds.$value$.count',
  'assignedUserIds.$value$.fromTicket',
  'involvedContacts.count',
  'involvedContacts.socialmediaAgent.callId',
  'involvedContacts.socialmediaAgent.channel',
  'involvedContacts.socialmediaAgent.chatId',
  'involvedContacts.socialmediaAgent.emailAddress',
  'involvedContacts.socialmediaAgent.endpoint',
  'involvedContacts.socialmediaAgent.orientation',
  'involvedContacts.socialmediaAgent.originIp',
  'involvedContacts.socialmediaAgent.type',
  'involvedContacts.socialmediaAgent.userchatId',
  'involvedContacts.socialmediaAgent.webChatConvId',
  'involvedContacts.socialmediaAgent.webChatForm.name',
  'involvedContacts.socialmediaAgent.webChatForm.value',

  'originAgent.callId',
  'originAgent.channel',
  'originAgent.chatId',
  'originAgent.emailAddress',
  'originAgent.endpoint',
  'originAgent.orientation',
  'originAgent.originIp',
  'originAgent.type',
  'originAgent.userchatId',
  'originAgent.webChatConvId',
  'originAgent.webChatForm.name',
  'originAgent.webChatForm.value',
  'originAgent.userName'
];

export const getAvailableFiltersCallback =
  (companyId: string, withConversationFilter: boolean = true) =>
  async () => {
    const resp = await TicketsDomain.getReportingTicketFilterFields(companyId);
    const filters = resp
      .map((filter) => {
        if (filter.dataCategory === DataCategory.CONVERSATION_SUMMARIES_ID) {
          const newFilter = {
            ...filter,
            label: t(getDialTableFilterLabel(filter.fieldName as string)),
            fieldType: FieldType.SELECTOR
          };
          return newFilter;
        }
        return {
          ...filter,
          label:
            (filter as DialTableFilter<ReportingTicket>)?.label ??
            t(getDialTableFilterLabel(filter.fieldName as string))
        };
      })
      .filter(
        (filter) =>
          !excludedReportingFilterFieldNames.includes(
            filter.fieldName as string
          ) &&
          (withConversationFilter ||
            (!withConversationFilter &&
              filter.fieldName !== CONVERSATION_FILTER_FIELDNAME))
      );
    return filters as DialTableFilter<ReportingTicket>[];
  };

export const getAdminCompanyTicketFields = (companyId: string) => async () => {
  const filters = await TicketsDomain.getReportingTicketFilterFields(companyId);
  const filteredFilters = filters.filter(
    (filter) => filter.fieldName !== CONVERSATION_FILTER_FIELDNAME
  );
  return filteredFilters;
};

export const getDataCallback =
  (companyId: string) =>
  async ({
    filters,
    paginationValues,
    columnIds,
    state,
    timeZone
  }: DialTableDataContext<ReportingTicket>) => {
    const select = getReportingTicketSelectArray([
      ...columnIds,
      ...noDisplayedColumnIds
    ]);
    const sort = getSort<ReportingTicket>(state?.sorting);
    const response = await TicketsDomain.getReportingTickets(
      {
        filters,
        paginationValues,
        select,
        sort
      },
      companyId,
      timeZone
    );
    return response;
  };

export const onGenerateTicketReportCallback =
  (companyId: string) =>
  async ({
    filters,
    reportName,
    visibleColumnIds,
    state,
    timeZone
  }: DialTableReportContext<ReportingTicket>) => {
    const select = getReportingTicketSelectArray(visibleColumnIds);
    const sort = getSort<ReportingTicket>(state?.sorting);
    await generateReportingTicket(
      {
        filters,
        paginationValues: { page: 0, size: -1 },
        select,
        sort
      },
      companyId,
      reportName,
      timeZone
    );
  };

export const onSubmitTicket = (selectedTicket: Ticket) => {
  return !!selectedTicket ? onEditTicket : onCreateTicket;
};

export const onCreateTicket = async (params: {
  ticket: Ticket;
  firstRescheduleTimestamp?: string;
}) => {
  // If conversation passed, add ticket to the conversation (current conversation)
  const newTicket = await TicketsDomain.createTicket(
    params.ticket,
    params.firstRescheduleTimestamp
  );
  queryClient.invalidateQueries({
    queryKey: ['reportingTicket']
  });
  return newTicket;
};

export const onEditTicket = async (params: {
  ticket: Ticket;
  firstRescheduleTimestamp?: string;
}) => {
  const resp: { ticket: Ticket; closedConvs: TicketConversationSummary[] } =
    await TicketsDomain.updateTicket(
      params.ticket,
      params.firstRescheduleTimestamp
    );

  const newTicket = resp.ticket;

  resp.closedConvs?.map(async (conv: TicketConversationSummary) => {
    emitCustomEvent(CustomEventNames.ALERT, {
      msg: `The conversation with id ${conv.id} was automatically closed due to ticket's typology change`,
      type: 'info'
    });
  });

  queryClient.invalidateQueries({
    queryKey: ['reportingTicket']
  });

  return newTicket;
};

export const onDeleteTicket = async (ticket: Ticket) => {
  await TicketsDomain.deleteTicket(ticket.id);
};

export const getInitialSelectedFiltersCallback =
  (conversation: Conversation) =>
  async (
    availableFilters: DialTableFilter<ReportingTicket>[]
  ): Promise<DialTableFilter<ReportingTicket>[]> => {
    if (!conversation) return [];
    const involvedContactIds =
      conversation?.involvedContacts
        ?.map((contact) => contact.contactId)
        .filter((contactId) => !!contactId) ?? [];

    const involvedContactFilter = availableFilters?.find(
      (filter) => filter.fieldName === 'involvedContacts.contactId'
    );
    const conversationSummariesFilter = availableFilters?.find(
      (filter) => filter.fieldName === CONVERSATION_FILTER_FIELDNAME
    );

    const involvedContactFilterWithValues: DialTableFilter<ReportingTicket> = {
      ...involvedContactFilter,
      operator: StringOperator.EQUALS,
      values: involvedContactIds
    };

    // IT IS A CONVERSATION PREVIEW
    if (!conversation?.id && involvedContactIds.length > 0) {
      return [involvedContactFilterWithValues];
    }

    const conversationSummariesFilterWithValues = {
      ...conversationSummariesFilter,
      operator: ObjectIdOperator.EQUALS,
      values: [conversation?.id]
    };

    if (involvedContactIds.length === 0) {
      return [conversationSummariesFilterWithValues];
    }

    const contactTicketsPromise = getTicketsQuery({
      contactIds: involvedContactIds,
      companyId: conversation?.companyId
    });

    const conversationReportingTicketsPromise =
      getConversationReportinTicketsQuery(conversation);

    const [paginatedContactTickets, paginatedConversationReportingTickets] =
      await Promise.all([
        contactTicketsPromise,
        conversationReportingTicketsPromise
      ]);
    const isSomeOpenTicket = paginatedContactTickets?.elements.some(
      TicketsDomain.checkIsOpenTicket
    );

    const isConversationWithAssignedTickets =
      paginatedConversationReportingTickets.totalItems > 0;

    if (isSomeOpenTicket || !isConversationWithAssignedTickets)
      return [involvedContactFilterWithValues];
    else
      return [
        conversationSummariesFilterWithValues,
        involvedContactFilterWithValues
      ];
  };

export const getConversationCustomFilterOptions =
  (conversation: Conversation) =>
  async (
    dataCategory: DataCategory,
    _companyId: string
  ): Promise<{ label: string; value: any }[]> => {
    if (dataCategory === 'CONVERSATIONSUMMARIESID') {
      return [
        { label: 'Current conversation', value: conversation.id },
        { label: 'All conversations', value: false }
      ];
    }
  };

export const getCustomFilterValueOptionsCallback = (
  conversation: Conversation
): CustomFilterValueOptions => {
  if (!conversation) {
    return null;
  }
  const options: Option[] = [
    { label: 'yes', value: conversation?.id },
    // The null value is removed before sending the filters to the backend
    { label: 'no', value: null }
  ];
  return {
    getValueOptionFn: (dataCategory: DataCategory, value: string) => {
      if (dataCategory === 'CONVERSATIONSUMMARIESID') {
        // CURRENT CONVERSATION
        const option = options.find((option) => option.value === value);
        return Promise.resolve(option);
      }
    },
    getValueOptionListFn: async (
      dataCategory: DataCategory,
      _companyId: string
    ) => {
      if (dataCategory === 'CONVERSATIONSUMMARIESID') {
        // CURRENT CONVERSATION
        const paginatedOptions = getPaginatedElements({ elements: options });
        return Promise.resolve(paginatedOptions);
      }
    }
  };
};

export const getTicketFieldTableColumns = async (companyId: string) => {
  const ticketFields = await getTicketFieldsQuery({ companyId });
  const ticketFieldsColumns: ColumnDef<ReportingTicket>[] = ticketFields.map(
    (ticketField) => {
      const column: ColumnDef<ReportingTicket> = {
        accessorFn: (ticket) => ticket[getTicketFieldColumnKey(ticketField.id)],
        id: String(getTicketFieldColumnKey(ticketField.id)),
        header: `${ticketField.name}`
      };
      return column;
    }
  );
  return ticketFieldsColumns;
};

export const getTicketFieldColumnKey = (ticketFieldKey: string) =>
  `fields.${ticketFieldKey}` as keyof ReportingTicket;
