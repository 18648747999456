import { Box, Typography } from '@mui/material';
import ReactDOMServer from 'react-dom/server';
import { convertTimeZoneToUtc } from 'src/modules/Shared/utils/convertDateToTimeZone';

const CustomToolTip = ({ date, identifiersArray }) => {
  const tooltipContent = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      alignItems={'center'}
    >
      <Box
        sx={{ background: '#ECEFF1' }}
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        fontWeight={'bold'}
      >
        <Typography>
          {convertTimeZoneToUtc(new Date(date)).toLocaleString()}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        flexWrap={'wrap'}
      >
        {identifiersArray.map((identifier) => {
          return (
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              key={identifier}
            >
              <Typography
                color={identifier.color}
                fontSize={'15px'}
                marginRight={'5px'}
                marginLeft={'5px'}
              >
                -
              </Typography>
              <Typography
                fontSize={'15px'}
                marginRight={'5px'}
                marginLeft={'2px'}
              >
                {identifier.identifier}:
              </Typography>
              <Typography
                fontSize={'15px'}
                marginRight={'5px'}
                marginLeft={'2px'}
                fontWeight={'bold'}
              >
                {identifier.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  // Convierte el componente de React a una cadena de texto HTML
  const tooltipHtml = ReactDOMServer.renderToStaticMarkup(tooltipContent);

  return tooltipHtml;
};

export default CustomToolTip;
