import { emitCustomEvent } from 'react-custom-events';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { logger } from 'src/utils/logger';
import { queryClient } from 'src/utils/queryClient';
import Cookies from 'universal-cookie';
import {
  CustomEventNames,
  WSMessage
} from '../../../websocket/utils/notifications/dataMsgs';
import { selectProperConversation } from '../../domain/conversation/selectProperConversation';
import { updateConversations } from '../../domain/conversation/updateConversations';
import { addMessageToConversation } from '../../domain/messages/addMessageToConversation';
import { removeMessage } from '../../domain/messages/removeMessage';
import { updateMessageStatus } from '../../domain/messages/updateMessage';
import { playNotificationSound, showMessageNotification } from '../utils';
import {
  SocialmediaEvent,
  SocialmediaMessageDeletedEvent,
  SocialmediaMessageReceivedEditedEvent,
  SocialmediaMessageStatusUpdateEvent
} from './socialmediaEvent';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getConversation } from '../../domain/conversation/getConversation';
import { refreshConversationMessages } from '../../domain/conversation/refreshConversationMessages';
import { calculateConversationsCount } from '../../../conversations/domain/refreshConversationsCount';
import showNewConversationAlert from './utils/showNewConversationAlert';

const cookies = new Cookies();

// interface SocialmediaSnapshotMessage extends WSMessage {
//   conversations: Conversation[]
// }

export interface SocialmediaMessageWSMessage extends WSMessage {
  event: SocialmediaEvent;
}

export interface SocialmediaConversationMessage extends WSMessage {
  conversations: Conversation[];
}

/** -- ALL CONVERSATIONS SNAPSHOT --
 *
 * This event is received right after logging in and subscribing to the Socialmedia topic through Websocket.
 * (Check initWs.tsx to see how that subscription is requested to the WS Server)
 */
export const onConversationWSMessage = (
  message: SocialmediaConversationMessage
) => {
  logger.log('[WS] Socialmedia CONVERSATIONS Event Received!! ', message);

  calculateConversationsCount(message);

  updateConversations(message.conversations);

  const loggedRTUser = getConnectedRTUser(false);

  const assignedUserIds = message?.conversations[0]?.assignedUserIds;

  if (loggedRTUser && !assignedUserIds.includes(loggedRTUser?.id)) return;

  setTimeout(selectProperConversation, 0);

  showNewConversationAlert(message.conversations);

  requestEmailMessages(message.conversations);
};

async function requestEmailMessages(conversations: Conversation[]) {
  const emailConversations = conversations.filter(
    (conversation) => conversation.channel === ChannelType.EMAIL
  );

  emailConversations.forEach((conversation) => {
    refreshConversationMessages(conversation);
  });
}

/**
 * -- GET USER_TYPING EVENT NAME
 *
 * used to know what conversation is typing
 */
export const getUserTypingEventName: Function = (conversationId: string) => {
  return 'UserTyping-' + conversationId;
};

/** -- CONVERSATION MESSAGE EVENT --
 *
 * This event is received a message was created/updated!
 */
export const onMessageWSMessage = (message: SocialmediaMessageWSMessage) => {
  logger.log('[WS] Socialmedia Message Received Event!! ', message);
  const userId = cookies.get('userId');

  // Emit an event to notify components that a user is typing
  if (message.event.type === CustomEventNames.USER_TYPING) {
    const eventName = getUserTypingEventName(message.event.conversationId);
    emitCustomEvent(eventName, message.event);
  }

  // Add or Edit message
  if (
    message.event.type == 'MessageReceived' ||
    message.event.type == 'MessageEdited'
  ) {
    const msgEvent = message.event as SocialmediaMessageReceivedEditedEvent;

    addMessageToConversation({ message: msgEvent.message });

    // Provisional: This conversation should be requested to the backend
    const conversation = getConversation({
      id: msgEvent.conversationId,
      shouldUseHook: false
    });

    if (msgEvent.channel === ChannelType.EMAIL) {
      refreshConversationMessages(conversation);
    }

    if (msgEvent.message.orientation === 'Incoming') {
      if (conversation?.assignedUserIds.includes(userId)) {
        showMessageNotification(msgEvent.message);
        playNotificationSound(message.event.channel);
      }
    }
  }

  // Update message status
  if (message.event.type == 'MessageStatusUpdate') {
    const event = message.event as SocialmediaMessageStatusUpdateEvent;
    updateMessageStatus(event);
  }

  // Update message status
  if (message.event.type == 'MessagesDeleted') {
    const msgEvent = message.event as SocialmediaMessageDeletedEvent;
    msgEvent.messageIds.forEach((msgId) =>
      removeMessage(msgEvent.conversationId, msgId)
    );
  }

  // Update UIs
  queryClient.refetchQueries([
    'conversationSubTitle',
    message.event.conversationId
  ]);
};
