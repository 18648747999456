import {
  Button,
  Card,
  ListItemButton,
  Popover,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import { DraggableItem } from '../DraggableColumn';
import { t } from 'i18next';
import Scrollbar from 'src/components/Scrollbar';

interface Props<T, K extends keyof T> {
  fields: DraggableItem<T>[];
  onAdd: (field: DraggableItem<T>) => void;
  descriptionKey?: K;
  title: string;
}

export const SearchItemPopover = <T, K extends keyof T>({
  fields,
  onAdd,
  title,
  descriptionKey
}: Props<T, K>) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const fieldsToShow = fields.filter((f) => {
    if (!search) {
      return true;
    }
    return f.name.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <Button
        ref={ref}
        fullWidth
        color="secondary"
        variant="outlined"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {title}
      </Button>

      <Popover
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Card>
          <Stack width={'310px'} height={'310px'} p={1} spacing={1} pb={0}>
            <TextField
              autoFocus
              sx={{ width: '100%' }}
              value={search}
              placeholder={t('Search')}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Scrollbar>
              {fieldsToShow.length === 0 && (
                <Typography sx={{ width: '100%', textAlign: 'center', py: 1 }}>
                  {t('No more to add')}
                </Typography>
              )}
              {fieldsToShow?.map((item, index) => {
                return (
                  <ListItemButton
                    key={index}
                    sx={{
                      width: '100%',
                      height: '42px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      onAdd(item);
                      setIsOpen(false);
                    }}
                  >
                    <Typography fontWeight={'bold'}>{item.name}</Typography>
                    <Typography>
                      {descriptionKey ? item.value[descriptionKey] : ''}
                    </Typography>
                  </ListItemButton>
                );
              })}
            </Scrollbar>
          </Stack>
        </Card>
      </Popover>
    </>
  );
};
