import axios from 'axios';
import { ChatMessageType } from 'src/models/conversations/messages';
import { TELEGRAM_MESSAGE_NEW_FILE } from 'src/utils/apiRoutes/msTelegram';

export interface SendTelegramMessageNewFileApiParams {
  campaignId: string;
  caption?: string;
  file: File;
  phoneNumber?: string;
  type: ChatMessageType;
  username?: string;
  ticketId?: string;
}

/**
 * Send a file message to a non existing conversation
 * @param params
 */
export const sendTelegramMessageNewFileApi = async (
  params: SendTelegramMessageNewFileApiParams
) => {
  const { file, ...restParams } = params;
  const formData = new FormData();
  formData.append('file', file);
  await axios.post(TELEGRAM_MESSAGE_NEW_FILE, formData, {
    params: restParams
  });
};
