import {
  Box,
  Stack,
  IconButton,
  useTheme,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ContactField, ContactTag } from 'src/modules/Contacts/models/contacts';
import { Add, ChevronLeft, Search } from '@mui/icons-material';
import CreateContactDataView from '../CreateContactDataView';
import ContactDataView from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel/ContactDataView';
import UpdateContactDataView from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel/CreateContactDataView/UpdateContactDataView';
import { useDialpadStateContext } from 'src/contexts/CTIBarContext';
import ContactActionsHeader from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel/ContactActionsHeader';
import { getContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { getCompanyContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import useUpdate from 'src/hooks/useUpdateEffect';
import AuthManager from 'src/services/authentication/manager';
import { t } from 'i18next';
import { CTIContactGroupList } from './components/CTIContactGroupList';
import { ContactsList } from './components/ContactsList';

interface ContactsProps {}
export const Contacts: React.FC<ContactsProps> = ({}) => {
  const companyId = AuthManager.getLoggedUserCompanyId();

  const [fields, setFields] = useState([] as ContactField[]);

  const {
    selectedContact,
    setSelectedContact,
    contactGroupSelection,
    setContactGroupSelection
  } = useDialpadStateContext();

  const allGroups = contactGroupSelection.state === 'ALL';
  const showContactGroupList = contactGroupSelection.state === 'NONE';
  const showContactsList = contactGroupSelection.state !== 'NONE';

  const [creatingContact, setCreatingContact] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  // GET ALL TAGS OPTIONS
  const [tags, setTags] = useState<ContactTag[]>([]);
  useEffect(() => {
    let isMounted = true;
    getCompanyContactTagsQuery({
      companyId
    }).then((response) => {
      if (isMounted) {
        setTags(response?.elements);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    getContactFieldsQuery({
      companyId
    }).then((res) => {
      setFields(res?.elements);
    });
  }, []);

  const selectedContactGroup =
    contactGroupSelection.state === 'GROUP'
      ? contactGroupSelection.contactGroup
      : undefined;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);

  const nameMainField = allGroups
    ? null
    : fields?.find(
        (field) =>
          field.mainField && field.contactGroupId === selectedContactGroup?.id
      );

  useUpdate(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);

  useEffect(() => {
    setSearchValue('');
    setDebouncedSearchValue('');
  }, [contactGroupSelection.state]);

  return (
    <Box width={'100%'} height="500px" px={1}>
      {isEditing && (
        <UpdateContactDataView
          contact={selectedContact}
          tags={tags}
          companyFields={fields}
          setContact={setSelectedContact}
          setIsOpen={setIsEditing}
        />
      )}
      {creatingContact && (
        <CreateContactDataView
          companyFields={fields}
          tags={tags}
          setContact={setSelectedContact}
          setIsOpen={setCreatingContact}
        />
      )}
      {selectedContact && !creatingContact && !isEditing && (
        <>
          <ContactActionsHeader
            onClickBackButton={() => {
              setIsEditing(false);
              setSelectedContact(null);
            }}
            onClickEditButton={() => {
              setIsEditing(true);
            }}
          />
          <ContactDataView
            contact={selectedContact}
            contactHeaderDirection="row"
            isCTI
          />
        </>
      )}
      {!selectedContact && !creatingContact && !isEditing && (
        <Stack direction={'column'} width={'100%'} height="100%">
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems="top"
            columnGap={1}
            pt={1}
          >
            {showContactsList && (
              <Tooltip
                title={t('Change contact group')}
                arrow
                placement="right"
              >
                <IconButton
                  size="small"
                  sx={{ width: '37px', height: '37px' }}
                  onClick={() => {
                    setContactGroupSelection({ state: 'NONE' });
                  }}
                >
                  <ChevronLeft />
                </IconButton>
              </Tooltip>
            )}
            <TextField
              id="tf-search-contacts"
              value={searchValue}
              size="small"
              autoFocus
              fullWidth
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={
                showContactsList
                  ? t('Search contacts')
                  : t('Search contact groups')
              }
              helperText={
                showContactGroupList &&
                t('Select a contact group to see its contacts')
              }
              InputProps={{
                startAdornment: <Search sx={{ mr: 1 }} />
              }}
            />
            <Tooltip title={t('Add contact')} arrow>
              <IconButton
                id="cti-add-contact-button"
                size="small"
                color="secondary"
                onClick={() => setCreatingContact(!creatingContact)}
                sx={{
                  textWrap: 'nowrap',
                  height: '38px',
                  width: '38px'
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Stack>
          {showContactsList && (
            <Typography
              fontSize={13}
              color="#22335480"
              fontWeight={'bold'}
              margin={'4px 8px 0px'}
            >
              {allGroups ? (
                t('All contact groups')
              ) : (
                <>
                  {t('Contact group') + ': '} {selectedContactGroup?.name}
                </>
              )}
            </Typography>
          )}
          {showContactGroupList && (
            <CTIContactGroupList searchValue={debouncedSearchValue} />
          )}

          {showContactsList && (
            <ContactsList
              nameMainField={nameMainField}
              searchValue={debouncedSearchValue}
              selectedContactGroup={selectedContactGroup}
              setSelectedContact={setSelectedContact}
              fields={fields}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};
