import { ContactTag } from 'src/modules/Contacts/models/contacts';
import { deleteContactTagApi } from '../infraestructure/api/deleteContactTagApi';
import { queryClient } from 'src/utils/queryClient';
import { invalidateContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';

/** DELETE CONTACT
 *
 *  @param contactID Contact id
 */
export const deleteTag = async (tag: ContactTag): Promise<void> => {
  queryClient.invalidateQueries({
    queryKey: ['companyTags']
  });
  await deleteContactTagApi(tag);
};

export const deleteTags = async (tags: ContactTag[]): Promise<void> => {
  const tagPromises = tags.map((tag) => deleteTag(tag));
  await Promise.all(tagPromises);
  invalidateContactTagsQuery();
};
