import { Box } from '@mui/material';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { ContactGroup } from 'src/modules/Contacts/models/contacts';
import { DialTable } from 'src/components/DialTable';
import { useContactGroups } from '../../hooks/useContactGroups';
import { DialTableRowActionsContext } from 'src/components/DialTable/models/functionContexts';

interface Props {
  setContactGroupToUpdate: (group: ContactGroup) => void;
  setIsPopoverOpened: (isOpened: boolean) => void;
  extraSingleActionButtons?: (group: ContactGroup) => JSX.Element[];
}

export const ContactGroups = ({
  setContactGroupToUpdate,
  setIsPopoverOpened,
  extraSingleActionButtons
}: Props) => {
  const {
    getColumns,
    getRowActions,
    getBulkActions,
    isDeleteModalOpened,
    setIsDeleteModalOpened,
    contactsToDelete,
    handleDelete,
    getAvailableFilters,
    getData
  } = useContactGroups();

  const getRowActionsFn = ({
    rowData: group
  }: DialTableRowActionsContext<ContactGroup>) => {
    return extraSingleActionButtons
      ? [
          ...extraSingleActionButtons(group),
          getRowActions(group, setContactGroupToUpdate, setIsPopoverOpened)
        ]
      : getRowActions(group, setContactGroupToUpdate, setIsPopoverOpened);
  };

  return (
    <>
      <ConfirmationModal<ContactGroup, keyof ContactGroup>
        isOpen={isDeleteModalOpened}
        setOpen={setIsDeleteModalOpened}
        data={contactsToDelete}
        dataKey="name"
        onConfirm={handleDelete}
        mode={ConfirmationModalModes.DELETE}
      />
      <Box width={'100%'} p={2}>
        <DialTable<ContactGroup>
          queryKey={['contactGroups']}
          getColumnsFn={getColumns}
          getRowActionsFn={getRowActionsFn}
          getDataFn={getData}
          getBulkActionsFn={getBulkActions}
          getAvailableFilters={getAvailableFilters}
          onlyEqualsOperator
        />
      </Box>
    </>
  );
};
