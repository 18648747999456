import axios, { AxiosError, HttpStatusCode } from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { VideoCallParticipant, VideoCallRoom } from '../types/videoCallTypes';
import { FormFieldData } from '../components/PhasePreroom/components/form/VideoCallForm';
import { ForbiddenException } from '../domain/exceptions/forbiddenException';
import { ContactFieldType } from 'src/modules/Contacts/models/contacts';
import { LockedException } from '../domain/exceptions/lockedException';
import { logger } from 'src/utils/logger';
import { FormFieldVideoCall } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { USERS_MS } from 'src/utils/apiRoutes/msUsers';

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
const BASE_MS_VIDEOCALLS = process.env.REACT_APP_BASE_VIDEOCALLS as string;

const VIDEOCALLS_URL = (BASE_URL + BASE_MS_VIDEOCALLS) as string;

const CREATE_VIDEOROOM = process.env
  .REACT_APP_VIDEOCALL_VIDEOROOM_CREATE as string;
const JOIN_VIDEOROOM = process.env.REACT_APP_VIDEOCALL_VIDEOROOM_JOIN as string;
const JOIN_VIDEOROOM_PUBLIC = process.env
  .REACT_APP_VIDEOCALL_VIDEOROOM_JOIN_PUBLIC as string;
const GET_VIDEOROOM_BY_ID = process.env.REACT_APP_VIDEOCALL_GET_BY_ID as string;
const GET_VIDEOROOM_BY_ID_PUBLIC = process.env
  .REACT_APP_VIDEOCALL_GET_BY_ID_PUBLIC as string;
const GET_VIDEOROOM = process.env.REACT_APP_VIDEOCALL_GET as string;
const HANG_UP = process.env.REACT_APP_VIDEOCALL_HANG_UP as string;
const HANG_UP_PUBLIC = process.env.REACT_APP_VIDEOCALL_HANG_UP_PUBLIC as string;
const FINISH_ROOM = process.env.REACT_APP_VIDEOCALL_FINISH_ROOM as string;
const READMIT = process.env.REACT_APP_VIDEOCALL_READMIT as string;

const GET_FORM_FIELDS =
  process.env.REACT_APP_VIDEOCALL_CAMPAIGN_GET_FORM_FIELDS;

export const createVideoRoom = async (campaignId: string) => {
  const url = VIDEOCALLS_URL + CREATE_VIDEOROOM;

  try {
    const response = await axios.post(url, { campaignId });
    return response.data as VideoCallRoom;
  } catch (error) {
    logger.error('Error creating video room', error);
    return null;
  }
};

export const getFormFieldsOfVideocallCampaign = async (campaignId: string) => {
  const url = replaceURLParams(USERS_MS + GET_FORM_FIELDS, {
    '{id}': campaignId
  });

  try {
    const response = await axios.get(url);
    return response.data as FormFieldVideoCall[];
  } catch (error) {
    logger.error('Error creating video room', error);
    return null;
  }
};

export const getVideoRooms = async (id: string) => {
  const url = VIDEOCALLS_URL + GET_VIDEOROOM;

  try {
    const response = await axios.get(url, { params: { id } });
    return response.data as VideoCallRoom[];
  } catch (error) {
    logger.error('Error fetching video rooms', error);
    return null;
  }
};

export const getVideoRoomById = async (roomId: string) => {
  const url = replaceURLParams(VIDEOCALLS_URL + GET_VIDEOROOM_BY_ID, {
    '{id}': roomId
  });

  const resp = await launchVideoRoomById(url, roomId);
  return resp;
};

export const getVideoRoomByIdPublic = async (roomId: string) => {
  const url = replaceURLParams(VIDEOCALLS_URL + GET_VIDEOROOM_BY_ID_PUBLIC, {
    '{id}': roomId
  });

  const resp = await launchVideoRoomById(url, roomId);
  return resp;
};

const launchVideoRoomById = async (url: string, roomId: string) => {
  try {
    const response = await axios.get(url);
    return response.data as VideoCallRoom;
  } catch (error) {
    if (error instanceof AxiosError) {
      logger.error('Error fetching video room ' + roomId, error);
      if (error.response?.status >= 500) return undefined;
      if (error.response?.status >= 400) return null;
    }
    return null;
  }
};
export class FormFieldValue {
  contactFieldId: string;
  value: string;
  contactFieldType: ContactFieldType;

  constructor(formData: FormFieldData) {
    this.contactFieldId = formData.formField.contactFieldId;
    this.value = formData.value;
    this.contactFieldType = formData.formField.type;
  }
}

export const joinVideoRoom = async (
  roomId: string,
  connectionId: string,
  muted: boolean,
  deafen: boolean
): Promise<VideoCallParticipant> => {
  const url = replaceURLParams(VIDEOCALLS_URL + JOIN_VIDEOROOM, {
    '{id}': roomId
  });

  const params = {
    connectionId,
    muted,
    deafen
  };
  const res = await launchJoinVideoroomRequest(url, params);
  return res;
};

export const joinVideoRoomPublic = async (
  roomId: string,
  connectionId: string,
  muted: boolean,
  deafen: boolean,
  formFieldDatas: FormFieldData[]
): Promise<VideoCallParticipant> => {
  const url = replaceURLParams(VIDEOCALLS_URL + JOIN_VIDEOROOM_PUBLIC, {
    '{id}': roomId
  });

  let formFieldValues = [];
  if (formFieldDatas)
    formFieldValues = formFieldDatas.map(
      (formData) => new FormFieldValue(formData)
    );

  const params = {
    connectionId,
    formFieldValues,
    muted,
    deafen
  };

  const res = await launchJoinVideoroomRequest(url, params);
  return res;
};

const launchJoinVideoroomRequest = async (path: string, params: object) => {
  try {
    const response = await axios.post(path, params);
    return response.data;
  } catch (error: AxiosError | any) {
    logger.error('Error joining video room!', error);
    if (error instanceof AxiosError) {
      const data = error.response?.data;
      if (error.response.status == HttpStatusCode.Forbidden) {
        throw new ForbiddenException(data);
      }
      if (error.response.status == HttpStatusCode.Conflict) {
        throw new LockedException(data);
      }
      if (
        error.response.status == HttpStatusCode.NotFound &&
        data?.message.includes('Room')
      ) {
        alert('(Probably) Room not found');
      }
    }
    throw new Error();
  }
};

export const readmitParticipant = async (
  roomId: string,
  participantId: string
) => {
  const url = replaceURLParams(VIDEOCALLS_URL + READMIT, {
    '{id}': roomId,
    '{participantId}': participantId
  });

  const response = await axios.post(url);
  return response.data as VideoCallRoom;
};

export const hangUpParticipant = async (
  participantId: string,
  roomId: string
) => {
  const url = replaceURLParams(VIDEOCALLS_URL + HANG_UP, {
    '{id}': roomId,
    '{participantId}': participantId
  });
  const resp = await launchHangUpParticipant(url, roomId);
  return resp;
};

export const hangUpParticipantPublic = async (
  participantId: string,
  roomId: string,
  socketId: string
) => {
  const url = replaceURLParams(VIDEOCALLS_URL + HANG_UP_PUBLIC, {
    '{id}': roomId,
    '{participantId}': participantId
  });
  const resp = await launchHangUpParticipant(url, roomId, socketId);
  return resp;
};

const launchHangUpParticipant = async (
  path: string,
  roomId: string,
  socketId?: string
) => {
  try {
    const response = await axios.post(path, {}, { headers: { socketId } });
    return response.data as VideoCallRoom;
  } catch (error) {
    logger.error('Error fetching video room ' + roomId, error);
    return null;
  }
};

export const finishRoom = async (roomId: string) => {
  const url = replaceURLParams(VIDEOCALLS_URL + FINISH_ROOM, {
    '{id}': roomId
  });

  try {
    const response = await axios.post(url);
    return response.data as VideoCallRoom;
  } catch (error) {
    logger.error('Error fetching video room ' + roomId, error);
    return null;
  }
};
