import { deleteIntentionModelApi } from '../infrastructure/deleteIntentionModel';

/** DELETE INTENTION MODEL
 *
 * Delete Intention Model in database
 *
 *
 * @returns INTENTION MODEL
 */
export const deleteIntentionModel = async (
  modelId: string
): Promise<string> => {
  const intentionModel = await deleteIntentionModelApi(modelId);
  return intentionModel;
};
