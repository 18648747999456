import { TicketConversationSummary } from '../../models/tickets';

export const sortTicketConversationSummaries = (
  conversations: TicketConversationSummary[],
  currentConversation?: TicketConversationSummary
) => {
  return [...conversations].sort((a, b) =>
    compareTicketConversations(a, b, currentConversation)
  );
};

export const compareTicketConversations = (
  a: TicketConversationSummary,
  b: TicketConversationSummary,
  currentConversation?: TicketConversationSummary
) => {
  if (a?.id === currentConversation?.id) return -1;
  if (b?.id === currentConversation?.id) return 1;
  return (
    new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
  );
};
