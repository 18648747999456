import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ContactListPopover from './searchContactListPopover/SearchContactListPopover';
import {
  ContactField,
  Contact,
  ContactFieldType,
  ContactFields,
  ContactsRequestParams
} from 'src/modules/Contacts/models/contacts';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkCreateContactPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { usePossibleContactsQuery } from 'src/modules/Contacts/views/hooks/contactQueries';
import { getInitialContactFormValuesFrom } from 'src/services/contacts/application/utils';
import { SearchContactListPopover } from './searchContactListPopover/SearchContactListPopoverItem';
import { Add } from '@mui/icons-material';

interface Props {
  setSelectedContact: (contact: Contact) => void;
  socialmediaAgent: Contact;
  setIsAdding: Dispatch<SetStateAction<boolean>>;
  setEditingIndex: Dispatch<SetStateAction<number>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  conversation: Conversation;
  index: number;
  companyFields: ContactField[];
  contact: Contact;
  webChatMatchContacts?: Contact[];
}

function ReasignOptionsPopover({
  setSelectedContact,
  socialmediaAgent,
  setIsAdding,
  setEditingIndex,
  setIsEditing,
  conversation,
  index,
  companyFields,
  contact,
  webChatMatchContacts
}: Props) {
  const theme = useTheme();
  const { hasAccess } = useContext(PermissionsContext);
  const hasCreateContactAccess = hasAccess(checkCreateContactPermissions);

  const [openModeSelector, setOpenModeSelector] = useState(false);
  const [openExistingContactList, setOpenExistingContactList] = useState(false);

  const typePerChannel = {
    [ChannelType.CALL]: 'Phone',
    [ChannelType.EMAIL]: 'Email',
    [ChannelType.WHATSAPP]: 'Phone',
    [ChannelType.INSTAGRAM]: 'InstagramUsername',
    [ChannelType.TELEGRAM]: 'TelegramUsername',
    [ChannelType.WEBCHAT]: 'Name'
  } as { [key in ChannelType]: ContactFieldType };

  const queryParams: ContactsRequestParams =
    conversation.channel === 'WebChat'
      ? {
          companyId: conversation.companyId,
          serviceId: conversation.serviceId,
          fieldType: typePerChannel[conversation.channel],
          fieldValue: socialmediaAgent?.fields.label,
          exactMatch: false,
          contactGroupId: socialmediaAgent?.contactGroupId,
          size: 5
        }
      : {
          companyId: conversation.companyId,
          serviceId: conversation.serviceId,
          fieldType: typePerChannel[conversation.channel],
          fieldValue: socialmediaAgent?.fields.label,
          exactMatch: false,
          size: 5
        };

  const possibleContactsQuery = usePossibleContactsQuery(queryParams);
  const possibleContacts =
    webChatMatchContacts ??
    (possibleContactsQuery.data?.elements.filter((c) => c.id !== contact.id) ||
      []);

  const actionRef = useRef(null);

  const onClickCreateContact = () => {
    setSelectedContact(socialmediaAgent);
    setIsAdding(true);
    setEditingIndex(index);
  };

  const onClickAddToExisting = () => {
    setOpenExistingContactList(true);
    setEditingIndex(index);
  };

  const onAddToExistingContact = async (existingContact: Contact) => {
    // Get the contact fields of the existing contact
    let mainContactFields = companyFields.filter((field) => {
      return (
        field.mainField &&
        field.contactGroupId === existingContact.contactGroupId
      );
    });

    const nonMainContactFields = companyFields.filter((field) => {
      return (
        !field.mainField &&
        field.contactGroupId === existingContact.contactGroupId
      );
    });
    //if its wechat it should use main and nonmain fields
    if (conversation.channel === 'WebChat') {
      mainContactFields = mainContactFields.concat(nonMainContactFields);
    }
    // Put the new contact data in the corresponding fields, this function does not return the desired format
    const formFields = getInitialContactFormValuesFrom(
      mainContactFields,
      socialmediaAgent
    );

    function valueIsOnExistingContact(value: string, fields: ContactFields) {
      const response = Object.values(fields).some((v) => v === value);
      return response;
    }

    const newContactFields = Object.values(formFields)
      .flat()
      .reduce((acc, item: ContactField) => {
        if (item.value === '') return acc;
        if (valueIsOnExistingContact(item.value, existingContact.fields))
          return acc;
        // If the field is empty, add the new value
        if (!existingContact?.fields[item.id]) {
          acc[item.id] = item.value;
        } else {
          const sameTypeFields = nonMainContactFields.filter(
            (f) => f.type === item.type
          );

          // Try to find a field with the same type that is empty
          const onEmptyField = sameTypeFields.some((field) => {
            if (existingContact?.fields[field.id] === undefined) {
              if (conversation.channel !== 'WebChat') {
                acc[field.id] = item.value;
                return true;
              } else if (acc[field.id] === undefined) {
                //not replace used fields in webchat
                acc[field.id] = item.value;
                return true;
              }
            }
            return false;
          });
          // If there is no empty field with the same type, overwrite the mainfield
          if (!onEmptyField && conversation.channel !== 'WebChat') {
            acc[item.id] = item.value;
          }
        }
        return acc;
      }, {});

    setSelectedContact({
      ...existingContact,
      fields: {
        ...existingContact.fields,
        ...newContactFields
      }
    });

    setIsEditing(true);
  };

  return (
    <>
      <PermissionsTooltip hasAccess={hasCreateContactAccess}>
        <Button
          ref={actionRef}
          color="secondary"
          size="small"
          variant="outlined"
          onClick={() => {
            setOpenModeSelector(true);
          }}
          id="btnReassign-ContactListItem"
          sx={{
            ml: 1
          }}
          disabled={!hasCreateContactAccess}
        >
          {contact.id ? t('Reassign') : t('Add contact')}
        </Button>
      </PermissionsTooltip>
      <Menu
        disableScrollLock
        anchorEl={actionRef.current}
        onClose={() => setOpenModeSelector(false)}
        open={openModeSelector}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={onClickCreateContact}>
          <Add />
          <Typography variant="body1" sx={{ ml: theme.spacing(1) }}>
            {t('Add as new contact')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={onClickAddToExisting}>
          <PersonOutlineIcon />
          <Typography variant="body1" sx={{ ml: theme.spacing(1) }}>
            {t('Add to an existing contact')}
          </Typography>
        </MenuItem>
        {possibleContacts.length > 0 && (
          <>
            <Stack width="100%" my={2}>
              <Divider textAlign="left">
                <Typography
                  color="GrayText"
                  variant="body1"
                  textTransform={'none'}
                >
                  {t('Recommended contacts')}
                </Typography>
              </Divider>
            </Stack>
            {possibleContacts.map((possibleContact) => {
              return (
                <SearchContactListPopover
                  companyFields={companyFields}
                  contact={possibleContact}
                  key={possibleContact.id}
                  onClick={() => {
                    setEditingIndex(index);
                    onAddToExistingContact(possibleContact);
                    setOpenModeSelector(false);
                  }}
                />
              );
            })}
          </>
        )}
      </Menu>
      {openExistingContactList && (
        <ContactListPopover
          goBack={() => setOpenExistingContactList(false)}
          key={contact.id}
          isOpen={openExistingContactList}
          onClose={() => {
            setOpenExistingContactList(false);
            setOpenModeSelector(false);
          }}
          anchorEl={actionRef.current}
          companyFields={companyFields}
          selectedContact={socialmediaAgent}
          setSelectedContact={setSelectedContact}
          setIsEditing={setIsEditing}
          index={index}
          onClick={onAddToExistingContact}
        />
      )}
    </>
  );
}

export default ReasignOptionsPopover;
