import { sortTicketFields } from '../../../utils/sortTicketFields';
import { Grid } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TicketFieldInput from './TicketFieldInput';
import {
  Ticket,
  TicketField,
  TicketFieldsValues
} from '../../../../models/tickets';
import { isVisibleEditableTicketField } from '../../../utils/checkEditableVisibleTicketField';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getUserRolesQuery } from 'src/services/permissionGroups/application/getUserRolesQuery';
import { UserRole } from 'src/models/permissionGroups';
import { getTicketFieldOptions } from '../../../utils/getTicketFieldOptions';

interface Props {
  ticketFields: TicketField[];
  ticketFieldValues: TicketFieldsValues;
  setTicket: Dispatch<SetStateAction<Ticket>>;
}

const TicketFields = ({
  ticketFields,
  ticketFieldValues,
  setTicket
}: Props) => {
  const loggedUser = getConnectedRTUser(false);

  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  const getTicketFieldValue = (ticketField: TicketField) => {
    return ticketFieldValues?.[ticketField?.id]?.value;
  };

  useEffect(() => {
    getUserRolesQuery({ userId: loggedUser?.id }).then((resp) => {
      setUserRoles(resp);
    });
  }, []);

  return (
    <>
      {ticketFields?.length > 0 &&
        sortTicketFields(ticketFields)?.map((field) => {
          const [isEditable, isVisible] = isVisibleEditableTicketField(
            field,
            ticketFieldValues,
            userRoles
          );
          const options = getTicketFieldOptions(field, ticketFieldValues);

          if (!isVisible) return <></>;
          return (
            <Grid key={field.id} item xs={12}>
              <TicketFieldInput
                ticketField={field}
                setTicket={setTicket}
                value={getTicketFieldValue(field)}
                disabled={!isEditable}
                options={options}
              />
            </Grid>
          );
        })}
    </>
  );
};

export default TicketFields;
