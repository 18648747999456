import axios, { AxiosError, AxiosResponse } from 'axios';
import { ExtensionsGroup } from 'src/modules/Admin/modules/Calls/modules/Extensions/models/ExtensionsGroup';
import { PaginatedElements } from 'src/models/pagination';
import { ExtensionsGroupDto } from 'src/modules/Admin/modules/Calls/modules/Extensions/models/ExtensionsGroupDto';
import {
  EXTENSIONS_GROUPS,
  EXTENSIONS_GROUPS_ID
} from 'src/utils/apiRoutes/msAcd';
import { replaceURLParams } from 'src/services/utils';

export interface ExtensionsGroupsRequestParams {
  companyId?: string;
  creationDateLowerBound?: string;
  creationDateUpperBound?: string;
  description?: string;
  findSubstring?: boolean;
  id?: string;
  lastUpdateLowerBound?: string;
  lastUpdateUpperBound?: string;
  name?: string;
  page?: number;
  size?: number;
}

export const getExtensionsGroupsByParams = (
  params: ExtensionsGroupsRequestParams
): Promise<PaginatedElements<ExtensionsGroup>> => {
  return axios
    .get(EXTENSIONS_GROUPS, { params: params })
    .then((response: AxiosResponse<PaginatedElements<ExtensionsGroup>>) => {
      return response.data;
    });
};

export const deleteExtensionsGroupById = async (
  extensionsGroupId: string
): Promise<boolean> => {
  const path = replaceURLParams(EXTENSIONS_GROUPS_ID, {
    '{id}': extensionsGroupId
  });

  const response = await axios.delete(path);
  return response.data ? true : false;
};

export const postExtensionsGroup = async (
  extensionsGroupDto: ExtensionsGroupDto
): Promise<ExtensionsGroup> => {
  const response: AxiosResponse<ExtensionsGroup> = await axios.post(
    EXTENSIONS_GROUPS,
    extensionsGroupDto
  );
  return response.data;
};

export const putExtensionsGroup = async (
  extensionsGroupDto: ExtensionsGroupDto
): Promise<ExtensionsGroup> => {
  const path = replaceURLParams(EXTENSIONS_GROUPS_ID, {
    '{id}': extensionsGroupDto.id
  });

  const response: AxiosResponse<ExtensionsGroup> = await axios.put(path, {
    description: extensionsGroupDto.description,
    name: extensionsGroupDto.name
  });
  return response.data;
};
