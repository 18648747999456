import { RTUser } from 'src/models/rtUser';
import { deleteRTUser } from 'src/store/slices/rtusers/rtuserSlices';
import store from 'src/store/store';

/** DELETERTUSER TO STORE
 *
 * @param rtuser RTUser to be deleted
 */
export const deleteRTUserStore = (rtuser: RTUser) => {
  store.dispatch(deleteRTUser(rtuser));
};
