import {
  outgopingMessageStatusOrder,
  MessageOrientation,
  MessageStatus
} from 'src/models/conversations/messages';
import { getConversation } from '../conversation/getConversation';
import { logger } from 'src/utils/logger';
import { setConversationStore } from '../../infraestructure/store/setConversationStore';
import { SocialmediaMessageStatusUpdateEvent } from '../../application/websocket/socialmediaEvent';
import { compareConversationMessages } from './utils';

/** UPDATE STATUS OF MESSAGE
 *
 */
export const updateMessageStatus = (
  event: SocialmediaMessageStatusUpdateEvent
) => {
  const { conversationId, newStatus, orientation, lastUpdatedMessageId } =
    event;
  if (orientation === MessageOrientation.INCOMING) return;
  // Find convo and message
  const conversation = getConversation({
    id: conversationId,
    shouldUseHook: false
  });

  if (!conversation) {
    logger.error('Update Message: Conversation not found ', conversation);
    return;
  }
  const conversationMessages = conversation?.messages ?? [];

  const lastUpdatedMessage = conversationMessages?.find(
    (message) => message.messageId === lastUpdatedMessageId
  );

  const updatedMessages = conversationMessages?.map((message) => {
    if (message.orientation === MessageOrientation.INCOMING) return message;
    if (message.status === MessageStatus.ERROR) return message;
    if (compareOutgoingMessageStatus(message.status, newStatus) >= 0)
      return message;
    if (
      !!lastUpdatedMessage &&
      compareConversationMessages(message, lastUpdatedMessage) > 0
    )
      return message;

    return { ...message, status: newStatus };
  });

  const newConversation = {
    ...conversation,
    messages: updatedMessages,
    lastMessage: updatedMessages?.at(-1)
  };

  setConversationStore(newConversation);
};

export const compareOutgoingMessageStatus = (
  statusA: MessageStatus,
  statusB: MessageStatus
) => {
  return (
    outgopingMessageStatusOrder[statusA] - outgopingMessageStatusOrder[statusB]
  );
};
