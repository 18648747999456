/**
 * Returns a callback that returns true if the filterInput appears as a substring of the result of converting the item to a String,
 * at one or more positions that are greater than or equal to position; otherwise, returns false.
 * @param filterInput
 * @returns callback
 */
export const filterOptionsCallback =
  (filterInput: string) =>
  <T extends string>(item: T) =>
    (filterInput?.trim() &&
      (item as string)
        .trim()
        .toLocaleLowerCase()
        .includes(filterInput.trim().toLowerCase())) ||
    !filterInput?.trim();
