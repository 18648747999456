import { DialerBotCampaign } from '../models/dialerbot';
import { Fields } from 'src/modules/Shared/models/forms';

interface getInitialValuesToFormFilter {
  context: string;
  fields: Fields[];
}

const valuesToAvoid = ['dayHours', 'holidays', 'users'];

/** GET THE INITIAL VALUES TO FORM
 *
 * According the campaign data , it will return the initial value
 * @param dialerbot dialerbot campaign
 * @returns object with initial values to Form
 */
export const getInitialValuesToForm = (
  dialerbot: DialerBotCampaign | null,
  filter: getInitialValuesToFormFilter[]
) => {
  let initialValues = {};

  filter.map((element) => {
    element.fields.map((entity) => {
      if (dialerbot !== null && !valuesToAvoid.includes(entity.entity))
        initialValues[entity.entity] = dialerbot[entity.entity];

      if (dialerbot === null && !valuesToAvoid.includes(entity.entity)) {
        if (entity.type === 'number' || entity.type === 'select-number') {
          if (entity.entity === 'timeout') initialValues[entity.entity] = 30;
          else initialValues[entity.entity] = 1;
        }

        if (entity.type === 'text' || entity.type === 'select-text') {
          if (entity.entity === 'inboundRouteId')
            initialValues[entity.entity] = 'default-inbound';
          else initialValues[entity.entity] = '';
        }

        if (entity.type === 'switch') {
          if (entity.entity === 'detectionAmd')
            initialValues[entity.entity] = true;
          else initialValues[entity.entity] = false;
        }
      }
    });
  });

  initialValues['submit'] = null;
  return initialValues;
};
