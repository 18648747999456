import { RTQueue } from 'src/models/queue';
import { RTUser } from 'src/models/rtUser';
import { setRTQueue, setRTUser } from 'src/store/slices/rtusers/rtuserSlices';
import store from 'src/store/store';

/** ADD / UPDATE QUEUE TO STORE
 *
 * @param rtQueue QUEUE to be added / updated
 */
export const setRTQueueStore = (rtQueue: RTQueue) => {
  store.dispatch(setRTQueue(rtQueue));
};
