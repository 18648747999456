import { queryClient } from 'src/utils/queryClient';
import { ContactsDomainParams, getContacts } from '../../domain/getContacts';

export const getContactsQuery = async (params: ContactsDomainParams) => {
  return queryClient.fetchQuery(
    ['contacts', params],
    () => getContacts(params),
    { staleTime: 60 * 1000 }
  );
};
