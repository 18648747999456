import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/modules/Shared/utils/apiFunctions';
import { Panel, PanelAlert, PanelParameters } from '../../models/Dashboards';
import {
  PANELS,
  PANELS_ALERT,
  PANELS_ID,
  PANELS_ID_PARAMETERS
} from 'src/utils/apiRoutes/msFlow';

export const createPanel = async (panel: Panel): Promise<Panel> => {
  const response: AxiosResponse<Panel> = await axios.post(PANELS, panel);
  return response.data;
};

export const updatePanelById = async (
  panelId: string,
  panel: Panel
): Promise<Panel> => {
  const path = replaceURLParams(PANELS_ID, {
    '{id}': panelId
  });

  const response: AxiosResponse<Panel> = await axios.put(path, panel);

  return response.data;
};

export const createAlert = async (alert: PanelAlert): Promise<PanelAlert> => {
  const response: AxiosResponse<PanelAlert> = await axios.post(
    PANELS_ALERT,
    alert
  );

  return response.data;
};

export const updatePanelParameters = async (
  panelId: string,
  panelParameters: PanelParameters
): Promise<Panel> => {
  const path = replaceURLParams(PANELS_ID_PARAMETERS, {
    '{id}': panelId
  });

  const response: AxiosResponse<Panel> = await axios.put(path, panelParameters);
  return response.data;
};
