import { ChannelTypes } from 'src/models/users';
import {
  Campaign,
  SocialmediaOutboundCampaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { getUserOutboundCampaignsByChannel } from 'src/services/users/domain/getUserCampaignsByChannel';

export function setCampaignSelector(
  storageId: string,
  setCampaigns: (campaign: Campaign[]) => void,
  setSelectedCampaign: (id: string) => void,
  campaigns: Campaign[],
  channel: ChannelTypes,
  serviceId?: string
) {
  if (!campaigns) return;

  const outboundCampaigns = getUserOutboundCampaignsByChannel(
    channel,
    campaigns
  );
  if (!outboundCampaigns) return;

  const filteredOutboundCampaigns = outboundCampaigns.filter(
    (campaign) =>
      !serviceId || (!!serviceId && campaign.serviceId === serviceId)
  );

  setCampaigns?.(filteredOutboundCampaigns);

  const savedCampaignId = localStorage.getItem(storageId);
  if (
    !savedCampaignId || // Si no hay campaña guardada o
    !outboundCampaigns.some((campaign) => campaign.id === savedCampaignId) // si la campaña guardada no está en las campañas filtradas
  ) {
    setSelectedCampaign(outboundCampaigns[0]?.id);
  } else {
    setSelectedCampaign(savedCampaignId);
  }
}
