import axios from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PromptElement } from 'src/modules/Shared/models/ariModels';
import { PROMPT_ELEMENT } from 'src/utils/apiRoutes/msAcd';

export type GetPromptElementFilters = {
  companyId?: string;
  description?: string;
  findSubstring?: boolean;
  name?: string;
  sortBy?: keyof PromptElement;
  sortDir?: DirectionType;
};

export async function getPromptElementsApi(params: GetPromptElementFilters) {
  const response = await axios.get(PROMPT_ELEMENT, { params });
  return response.data as PromptElement[];
}
