import {
  MicrophoneState,
  setMicrophoneState
} from 'src/store/slices/users/configVolumeSlice';
import store, { useSelector } from 'src/store/store';

export function setMicrophoneStateStore(state: MicrophoneState) {
  store.dispatch(setMicrophoneState(state));
}

export function useMicrophoneState() {
  return useSelector((state) => state.configVolume.microphoneState);
}

export function getMicrophoneState() {
  return store.getState().configVolume.microphoneState;
}
