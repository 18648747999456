import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import {
  IntegrationInstructionsOutlined,
  SvgIconComponent,
  VideocamOutlined
} from '@mui/icons-material';

// export interface HelperMenuItem {
//   value: string;
//   icon?: any;
//   label: string;
// }
// const helperMenuItems: HelperMenuItem[] = [
//   {
//     value: 'contactData',
//     icon: PersonOutlineIcon,
//     label: 'Contact Data'
//   },
//   {
//     value: 'conversationHistory',
//     icon: HistoryEduIcon,
//     label: 'Conversations History'
//   },
//   {
//     value: 'calendarSchedule',
//     icon: CalendarTodayOutlinedIcon,
//     label: 'Call Schedule'
//   },
//   {
//     value: 'notes',
//     icon: StickyNote2OutlinedIcon,
//     label: 'Notes'
//   },
//   {
//     value: 'thirdParty',
//     icon: UnfoldMoreOutlinedIcon,
//     label: 'SGI'
//   },
//   {
//     value: 'typology',
//     icon: BeenhereOutlinedIcon,
//     label: 'Typology'
//   },
//   {
//     value: 'cases',
//     icon: ConfirmationNumberOutlinedIcon,
//     label: 'Cases'
//   }
// ];

export enum HelperTab {
  CONTACT_DATA = 'contactData',
  CONVERSATION_HISTORY = 'conversationsHistory',
  CONVERSATION_STATUS = 'conversationStatus',
  CALENDAR_SCHEDULE = 'calendarSchedule',
  NOTES = 'notes',
  THIRD_PARTY = 'thirdParty',
  TICKETS = 'tickets',
  VIDEO_PARTICIPANTS = 'videoParticipants'
}

export type HelperMenuItemVisibility = Partial<Record<HelperTab, boolean>>;

export type HelperTabsValue = { icon: SvgIconComponent; label: string };

export const helperMenuItems: Record<HelperTab, HelperTabsValue> = {
  [HelperTab.CONTACT_DATA]: {
    icon: PersonOutlineIcon,
    label: 'Contacts'
  },
  [HelperTab.CONVERSATION_HISTORY]: {
    icon: HistoryEduIcon,
    label: 'Conversations History'
  },
  [HelperTab.CONVERSATION_STATUS]: {
    icon: BeenhereOutlinedIcon,
    label: 'Conversation Status'
  },
  [HelperTab.CALENDAR_SCHEDULE]: {
    icon: CalendarTodayOutlinedIcon,
    label: 'Call Schedule'
  },
  [HelperTab.NOTES]: {
    icon: StickyNote2OutlinedIcon,
    label: 'Notes'
  },
  [HelperTab.THIRD_PARTY]: {
    icon: IntegrationInstructionsOutlined,
    label: 'Third Party'
  },

  [HelperTab.TICKETS]: {
    icon: ConfirmationNumberOutlinedIcon,
    label: 'Tickets'
  },
  [HelperTab.VIDEO_PARTICIPANTS]: {
    icon: VideocamOutlined,
    label: 'Videocall Participants'
  }
};
