import { ChannelType } from 'src/models/conversations/conversations';
import store, { RootState, useSelector } from 'src/store/store';

export const getSelectedConversationChannelFilterStore = (
  channel: ChannelType,
  shouldUseHook: boolean
) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector(
        (state) =>
          state.socialmedia.conversationFilters.selectedChannels[channel]
      )
    : storeState.socialmedia.conversationFilters.selectedChannels[channel];
};

export const getShowClosedConversationsFilterStore = (
  shouldUseHook: boolean
) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector(
        (state) => state.socialmedia.conversationFilters.showClosedConversations
      )
    : storeState.socialmedia.conversationFilters.showClosedConversations;
};

export const getGroupConversationsByChannelFilterStore = (
  shouldUseHook: boolean
) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector(
        (state) => state.socialmedia.conversationFilters.groupByChannels
      )
    : storeState.socialmedia.conversationFilters.groupByChannels;
};

export const getTextFilterStore = (shouldUseHook: boolean) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector((state) => state.socialmedia.conversationFilters.textFilter)
    : storeState.socialmedia.conversationFilters.textFilter;
};

export const getExpandedUsersStore = (shouldUseHook: boolean) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector((state) => state.socialmedia.expandedUsers)
    : storeState.socialmedia.expandedUsers;
};

export const getExpandedUserStore = (
  userId: string,
  shouldUseHook: boolean
) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector((state) => state.socialmedia.expandedUsers[userId])
    : storeState.socialmedia.expandedUsers[userId];
};

export const getConversationFiltersStore = (shouldUseHook: boolean) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector((state) => state.socialmedia.conversationFilters)
    : storeState.socialmedia.conversationFilters;
};
