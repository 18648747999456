import { ContactTag } from 'src/modules/Contacts/models/contacts';
import {
  TagFilters,
  getContactTagApi,
  getContactTagsApi
} from '../infraestructure/api/getContactTagsApi';

/** GET ONE TAG
 *
 * Retrieves the first tag found with the specified filter
 *
 * @param id Parameters to filter tags by id
 * @returns Tags
 */
export const getContactTag = async (id: string): Promise<ContactTag> => {
  if (!id) return Promise.resolve(null);

  const tag = await getContactTagApi(id);
  return tag;
};

export const getContactTags = async (filter: TagFilters) => {
  const { page = 0, size = -1, ...rest } = filter ?? {};
  const contactTags = await getContactTagsApi({ ...rest, page, size });
  return contactTags;
};
