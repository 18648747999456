import {
  Button,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { t } from 'i18next';
import { ColorPicker } from '../../../../components/Style/ColorPicker';
import { WebChatStyle } from '../../../../../models/webChatStyle';
import { FormikProps } from 'formik';
import { useWebChatStyle } from '../../../../utils/useWebChatStyle';
import { Dispatch, SetStateAction } from 'react';

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '160px',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: '160px'
});

interface BaseAccordionProps {
  formikProps: FormikProps<WebChatStyle>;
  setLogoImage: Dispatch<SetStateAction<File>>;
  setUpdatedLogo: Dispatch<SetStateAction<boolean>>;
}

const BaseAccordion: React.FC<BaseAccordionProps> = ({
  formikProps,
  setLogoImage,
  setUpdatedLogo
}) => {
  const { sliderMarks } = useWebChatStyle();

  const onLoadAttachmentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    setLogoImage(fileList[0]);

    setUpdatedLogo(true);
    e.target.value = '';
  };
  return (
    <>
      <Stack
        direction={{ md: 'column', lg: 'row' }}
        spacing={{ xs: 2, md: 2 }}
        alignItems={'center'}
      >
        <Stack paddingRight="40px" minWidth="33%" width={'100%'}>
          <TextField
            select
            label={t('Shadow')}
            value={formikProps.values.contactStyle.shadowBox ? 'Yes' : 'No'}
            onChange={(event) => {
              formikProps.setFieldValue(
                'contactStyle.shadowBox',
                event.target.value === 'Yes'
              );
            }}
          >
            <MenuItem value="Yes">{t('Yes')}</MenuItem>
            <MenuItem value="No">{t('No')}</MenuItem>
          </TextField>
        </Stack>
        <Stack paddingRight="40px" minWidth="30%" width={'100%'}>
          <Typography variant="subtitle1">{t('Logo size')}</Typography>
          <Slider
            color="secondary"
            min={1}
            max={3}
            step={0.1}
            marks={sliderMarks}
            value={formikProps.values.contactStyle.logoSize}
            onChange={(event, value) => {
              formikProps.setFieldValue('contactStyle.logoSize', value);
            }}
            id="switchSlider-StyleModifiersFontSize"
          />
        </Stack>
        <Stack paddingRight="40px" width={'100%'}>
          <ColorPicker
            title={t('Header text')}
            color={formikProps.values.contactStyle.headerTextColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'contactStyle.headerTextColor.color',
                c
              );
            }}
          />
        </Stack>
      </Stack>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="40px" minWidth="33%">
          <Button
            variant="outlined"
            color="secondary"
            sx={{ height: '53px' }}
            component="label"
            role={undefined}
            tabIndex={-1}
          >
            {t('Upload logo')}
            <HiddenInput
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={onLoadAttachmentFile}
            />
          </Button>
        </Stack>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Data politics')}
            color={formikProps.values.contactStyle.dataPolicyText.color}
            setColor={(c) => {
              formikProps.setFieldValue('contactStyle.dataPolicyText.color', c);
            }}
          />
        </Stack>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Send conversation')}
            color={formikProps.values.contactStyle.sendConvHistoryText.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'contactStyle.sendConvHistoryText.color',
                c
              );
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default BaseAccordion;
