import { logger } from 'src/utils/logger';
import { updateEnableWhatsAppAccountsApi } from '../../infrastructure/account/updateWhatsAppAccountApi';
import { WhatspAppAccount } from '../../models/WhatsAppAccount';

/** UPDATE WHATSAPP ACCOUNT
 *
 * Update whatsapp account
 */
export const updateEnableWhatsAppAccount = async (
  whatsAppAccount: WhatspAppAccount
) => {
  const { id, companyId, description, name, enabled, maxOpenConversations } =
    whatsAppAccount;
  if (!id) {
    logger.error('WhatsApp account id was not provided but it is required');
    return;
  }
  const account: Partial<WhatspAppAccount> = {
    description,
    name,
    enabled,
    maxOpenConversations
  };
  const resp = await updateEnableWhatsAppAccountsApi(id, account);
  return resp;
};

export const changeWhatsAppAccountStatus = async (
  whatsAppAccount: WhatspAppAccount,
  enabled: boolean
) => {
  if (whatsAppAccount.enabled === enabled) return whatsAppAccount;
  const resp = await updateEnableWhatsAppAccount({
    ...whatsAppAccount,
    enabled
  });
  return resp;
};

export const changeWhatsAppAccountListStatus = async (
  whatsAppAccount: WhatspAppAccount[],
  enabled: boolean
) => {
  const promises = whatsAppAccount.map((account) =>
    changeWhatsAppAccountStatus(account, enabled)
  );
  const accounts = await Promise.all(promises);
  return accounts;
};
