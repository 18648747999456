import { AIModel, AIModelsKeys } from '../models/AIModels';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import AIModelsForm from './components/forms/AIModelsForm';
import { useIntentionsModelTable } from './hooks/useIntentionsModelTable';
import { DialTable } from 'src/components/DialTable';
import { Box } from '@mui/material';
import { initialAiModel } from './utils/constants';

const AIModelsView = ({ currentAiModel, setCurrentAiModel }) => {
  const {
    intentionsModelsQuery,
    getBulkActions,
    getRowActions,
    getColumns,
    open,
    setOpen,
    aiModelsToDelete,
    deleteAIModels,
    openDeleteModal,
    setOpenDeleteModal
  } = useIntentionsModelTable({ currentAiModel, setCurrentAiModel });

  const data = intentionsModelsQuery?.data ?? [];

  return (
    <>
      <AIModelsForm
        handleClose={() => {
          setCurrentAiModel(initialAiModel);
          setOpen(false);
        }}
        currentAiModel={currentAiModel}
        setCurrentAiModel={setCurrentAiModel}
        isOpen={open}
        setOpen={setOpen}
      />
      <ConfirmationModal<AIModel, AIModelsKeys>
        dataKey={'name'}
        data={aiModelsToDelete}
        isOpen={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onConfirm={deleteAIModels}
        mode={ConfirmationModalModes.DELETE}
      />

      {/* TABLE */}
      <Box px={4} py={2}>
        <DialTable<AIModel>
          key="ai-model-table"
          data={data}
          getColumnsFn={getColumns}
          getRowActionsFn={getRowActions}
          getBulkActionsFn={getBulkActions}
        />
      </Box>
    </>
  );
};

export default AIModelsView;
