import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import AuthManager from 'src/services/authentication/manager';
import store from 'src/store/store';

const axiosInt = axios.create();

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + AuthManager.getToken();

  return config;
});

export const mock = new AxiosMockAdapter(axiosInt, { delayResponse: 0 });

export default axiosInt;
