import { makeStyles } from '@mui/styles';
import { FormFields } from 'src/modules/Shared/models/forms';
import {
  DialerbotTypes,
  UploadStatusType,
  WeekTimes
} from '../../models/dialerbot';
import { t } from 'i18next';
import { keyframes } from '@mui/system';
import { Permissions } from 'src/models/permissionGroups';
import {
  checkReadContactGroupPermissions,
  checkReadContactPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

//DialerBot Table
export const DialerbotColumns = [
  'ID',
  'BDID',
  'CAMPAIGN',
  'CHANNEL',
  'LAUNCH DATE',
  'PROGRESS',
  'STATUS',
  'ACTIONS'
];

//Create DialerBot Tabs

export const TabsCreateForm = [
  { value: 'General', label: 'General' },
  { value: 'CSV', label: 'CSV' }
];

export const QueuePercentage = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100
];

export const createFields: FormFields[] = [
  {
    context: 'General Configuration',
    fields: [
      {
        name: 'Name',
        type: 'text',
        key: 'name',
        entity: 'name',
        enable: true
      },
      {
        name: 'Company',
        type: 'select-text',
        key: 'companies',
        entity: 'companyId',
        enable: true
      },
      {
        name: 'Description',
        type: 'text',
        key: 'description',
        entity: 'description',
        enable: true
      },
      {
        name: 'Service',
        type: 'select-text',
        key: 'services',
        entity: 'serviceId',
        enable: true
      }
    ]
  },
  {
    context: 'DialerBot configuration',
    fields: [
      {
        name: 'Timeout',
        type: 'number',
        key: 'timeout',
        entity: 'timeout',
        enable: true
      },
      // {
      //   name: 'Queue Percentage',
      //   type: 'select-text',
      //   key: 'queuepercentage',
      //   entity: 'queuePercentage',
      //   enable: true
      // },
      // {
      //   name: 'Max calls per second',
      //   type: 'number',
      //   key: 'maxcallsperseccond',
      //   entity: 'maxCallsPerSecond',
      //   enable: true
      // },
      {
        name: 'Max calls simultaneous',
        type: 'number',
        key: 'maxcallssimultaneous',
        entity: 'maxSimultaneousCalls',
        enable: true
      },
      {
        name: 'OutBound Route',
        type: 'select-text',
        key: 'outboundroutes',
        entity: 'outboundRouteId',
        enable: true
      },
      {
        name: 'Inbound Route',
        type: 'select-text',
        key: 'inboundroutes',
        entity: 'inboundRouteId',
        enable: true
      },
      {
        name: 'Answering machine detection',
        type: 'switch',
        key: 'amddetection',
        entity: 'detectionAmd',
        enable: true
      },
      {
        name: 'Record percentage',
        type: 'number',
        key: 'recordPercentage',
        entity: 'recordPercentage',
        enable: true
      }
    ]
  },
  {
    context: 'Dates configuration',
    fields: [
      {
        name: 'Pause on holidays',
        type: 'holidays',
        key: 'pauseonholidays',
        entity: 'pauseonholidays',
        enable: true
      },
      {
        name: 'Hours by week',
        type: 'schedule',
        key: 'hoursbyweek',
        entity: 'dayHours',
        enable: true
      },
      {
        name: 'Start Date',
        type: 'date',
        key: 'startDate',
        entity: 'startDate',
        enable: true
      }
    ]
  }
];

export const createFieldsPredictive: FormFields[] = [
  {
    context: 'General Configuration',
    fields: [
      {
        name: 'Name',
        type: 'text',
        key: 'name',
        entity: 'name',
        enable: true
      },
      {
        name: 'Company',
        type: 'select-text',
        key: 'companies',
        entity: 'companyId',
        enable: true
      },
      {
        name: 'Description',
        type: 'text',
        key: 'description',
        entity: 'description',
        enable: true
      },
      {
        name: 'Service',
        type: 'select-text',
        key: 'services',
        entity: 'serviceId',
        enable: true
      }
    ]
  },
  {
    context: 'DialerBot configuration',
    fields: [
      {
        name: 'Timeout',
        type: 'number',
        key: 'timeout',
        entity: 'timeout',
        enable: true
      },
      // {
      //   name: 'Queue Percentage',
      //   type: 'select-text',
      //   key: 'queuepercentage',
      //   entity: 'queuePercentage',
      //   enable: true
      // },
      // {
      //   name: 'Max calls per second',
      //   type: 'number',
      //   key: 'maxcallsperseccond',
      //   entity: 'maxCallsPerSecond',
      //   enable: true
      // },
      {
        name: 'Max calls simultaneous',
        type: 'number',
        key: 'maxcallssimultaneous',
        entity: 'maxSimultaneousCalls',
        enable: true
      },
      {
        name: 'OutBound Route',
        type: 'select-text',
        key: 'outboundroutes',
        entity: 'outboundRouteId',
        enable: true
      },
      {
        name: 'Inbound Route',
        type: 'select-text',
        key: 'inboundroutes',
        entity: 'inboundRouteId',
        enable: true
      },
      {
        name: 'Answering machine detection',
        type: 'switch',
        key: 'amddetection',
        entity: 'detectionAmd',
        enable: true
      },
      {
        name: 'Abandoned rate',
        type: 'number',
        key: 'abandonedRate',
        entity: 'abandonedRate',
        enable: true
      },
      {
        name: 'Record percentage',
        type: 'number',
        key: 'recordPercentage',
        entity: 'recordPercentage',
        enable: true
      }
    ]
  },
  {
    context: 'Dates configuration',
    fields: [
      {
        name: 'Pause on holidays',
        type: 'holidays',
        key: 'pauseonholidays',
        entity: 'pauseonholidays',
        enable: true
      },
      {
        name: 'Hours by week',
        type: 'schedule',
        key: 'hoursbyweek',
        entity: 'dayHours',
        enable: true
      },
      {
        name: 'Start Date',
        type: 'date',
        key: 'startDate',
        entity: 'startDate',
        enable: true
      }
    ]
  }
];

export const updateFields: FormFields[] = [
  {
    context: 'General Configuration',
    fields: [
      {
        name: 'Name',
        type: 'text',
        key: 'name',
        entity: 'name',
        enable: false
      },
      {
        name: 'Company',
        type: 'select-text',
        key: 'companies',
        entity: 'companyId',
        enable: false
      },
      {
        name: 'Description',
        type: 'text',
        key: 'description',
        entity: 'description',
        enable: true
      },
      {
        name: 'Service',
        type: 'select-text',
        key: 'services',
        entity: 'serviceId',
        enable: false
      }
    ]
  },
  {
    context: 'DialerBot configuration',
    fields: [
      {
        name: 'Timeout',
        type: 'number',
        key: 'timeout',
        entity: 'timeout',
        enable: true
      },
      // {
      //   name: 'Queue Percentage',
      //   type: 'select-text',
      //   key: 'queuepercentage',
      //   entity: 'queuePercentage',
      //   enable: true
      // },
      // {
      //   name: 'Max calls per second',
      //   type: 'number',
      //   key: 'maxcallsperseccond',
      //   entity: 'maxCallsPerSecond',
      //   enable: true
      // },
      {
        name: 'Max calls simultaneous',
        type: 'number',
        key: 'maxcallssimultaneous',
        entity: 'maxSimultaneousCalls',
        enable: true
      },
      {
        name: 'OutBound Route',
        type: 'select-text',
        key: 'outboundroutes',
        entity: 'outboundRouteId',
        enable: true
      },
      {
        name: 'Inbound Route',
        type: 'select-text',
        key: 'inboundroutes',
        entity: 'inboundRouteId',
        enable: true
      },

      {
        name: 'Answering machine detection',
        type: 'switch',
        key: 'amddetection',
        entity: 'detectionAmd',
        enable: true
      },
      {
        name: 'Record percentage',
        type: 'number',
        key: 'recordPercentage',
        entity: 'recordPercentage',
        enable: true
      }
    ]
  },
  {
    context: 'Dates configuration',
    fields: [
      {
        name: 'Pause on holidays',
        type: 'holidays',
        key: 'pauseonholidays',
        entity: 'pauseonholidays',
        enable: true
      },
      {
        name: 'Hours by week',
        type: 'schedule',
        key: 'hoursbyweek',
        entity: 'dayHours',
        enable: true
      },
      {
        name: 'Start Date',
        type: 'date',
        key: 'startDate',
        entity: 'startDate',
        enable: true
      }
    ]
  }
];

export const updateFieldsPredictive: FormFields[] = [
  {
    context: 'General Configuration',
    fields: [
      {
        name: 'Name',
        type: 'text',
        key: 'name',
        entity: 'name',
        enable: false
      },
      {
        name: 'Company',
        type: 'select-text',
        key: 'companies',
        entity: 'companyId',
        enable: false
      },
      {
        name: 'Description',
        type: 'text',
        key: 'description',
        entity: 'description',
        enable: true
      },
      {
        name: 'Service',
        type: 'select-text',
        key: 'services',
        entity: 'serviceId',
        enable: false
      }
    ]
  },
  {
    context: 'DialerBot configuration',
    fields: [
      {
        name: 'Timeout',
        type: 'number',
        key: 'timeout',
        entity: 'timeout',
        enable: true
      },
      // {
      //   name: 'Queue Percentage',
      //   type: 'select-text',
      //   key: 'queuepercentage',
      //   entity: 'queuePercentage',
      //   enable: true
      // },
      // {
      //   name: 'Max calls per second',
      //   type: 'number',
      //   key: 'maxcallsperseccond',
      //   entity: 'maxCallsPerSecond',
      //   enable: true
      // },
      {
        name: 'Max calls simultaneous',
        type: 'number',
        key: 'maxcallssimultaneous',
        entity: 'maxSimultaneousCalls',
        enable: true
      },
      {
        name: 'OutBound Route',
        type: 'select-text',
        key: 'outboundroutes',
        entity: 'outboundRouteId',
        enable: true
      },
      {
        name: 'Inbound Route',
        type: 'select-text',
        key: 'inboundroutes',
        entity: 'inboundRouteId',
        enable: true
      },

      {
        name: 'Answering machine detection',
        type: 'switch',
        key: 'amddetection',
        entity: 'detectionAmd',
        enable: true
      },
      {
        name: 'Abandoned rate',
        type: 'number',
        key: 'abandonedRate',
        entity: 'abandonedRate',
        enable: true
      },
      {
        name: 'Record percentage',
        type: 'number',
        key: 'recordPercentage',
        entity: 'recordPercentage',
        enable: true
      }
    ]
  },
  {
    context: 'Dates configuration',
    fields: [
      {
        name: 'Pause on holidays',
        type: 'holidays',
        key: 'pauseonholidays',
        entity: 'pauseonholidays',
        enable: true
      },
      {
        name: 'Hours by week',
        type: 'schedule',
        key: 'hoursbyweek',
        entity: 'dayHours',
        enable: true
      },
      {
        name: 'Start Date',
        type: 'date',
        key: 'startDate',
        entity: 'startDate',
        enable: true
      }
    ]
  }
];

export const daysWeek = [
  {
    day: 'Monday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'Tuesday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'Wednesday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'Thursday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'Friday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'Saturday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'Sunday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'All Days',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  },
  {
    day: 'Monday to Friday',
    startHour: 6,
    endHour: 17,
    startMinute: 0,
    endMinute: 0,
    enabled: false
  }
];

export const newDayHours: WeekTimes = {
  MONDAY: [
    {
      startHour: 0,
      endHour: 23,
      startMinute: 1,
      endMinute: 59,
      enabled: false
    }
  ],
  TUESDAY: [
    {
      startHour: 0,
      endHour: 23,
      startMinute: 1,
      endMinute: 59,
      enabled: false
    }
  ],

  WEDNESDAY: [
    {
      startHour: 0,
      endHour: 16,
      startMinute: 1,
      endMinute: 1,
      enabled: false
    }
  ],
  THURSDAY: [
    {
      startHour: 0,
      endHour: 23,
      startMinute: 1,
      endMinute: 59,
      enabled: false
    }
  ],
  FRIDAY: [
    {
      startHour: 0,
      endHour: 14,
      startMinute: 1,
      endMinute: 18,
      enabled: false
    }
  ],
  SATURDAY: [
    {
      startHour: 0,
      endHour: 23,
      startMinute: 1,
      endMinute: 59,
      enabled: false
    }
  ],
  SUNDAY: [
    {
      startHour: 0,
      endHour: 23,
      startMinute: 1,
      endMinute: 59,
      enabled: false
    }
  ],
  'MONDAY TO FRIDAY': [
    {
      startHour: 0,
      endHour: 14,
      startMinute: 1,
      endMinute: 18,
      enabled: false
    }
  ],
  'ALL DAYS': [
    {
      startHour: 0,
      endHour: 14,
      startMinute: 1,
      endMinute: 18,
      enabled: false
    }
  ]
};

export type TabContactType = {
  value: TypeFormProp;
  label: string;
  disabled: false;
  permissionsCheckerFn: (permissions: Permissions) => boolean;
};

export type TypeFormProp = 'csv' | 'select' | 'csv-contacts';

export const tabsContacts: TabContactType[] = [
  {
    value: 'csv',
    label: t('Upload CSV'),
    disabled: false,
    permissionsCheckerFn: () => true
  },
  {
    value: 'csv-contacts',
    label: t('Upload CSV with contacts'),
    disabled: false,
    permissionsCheckerFn: () => true
  },
  {
    value: 'select',
    label: t('Existing contacts'),
    disabled: false,
    permissionsCheckerFn: (permissions: Permissions) =>
      checkReadContactGroupPermissions(permissions) &&
      checkReadContactPermissions(permissions)
  }
];

export const dialerbotTypes: DialerbotTypes[] = [
  { id: 1, name: 'Dialerbot', type: 'dialerbot' },
  { id: 2, name: 'Predictive', type: 'predictive' },
  { id: 3, name: 'Progressive', type: 'progressive' }
];

export const arrayPriority = [
  { id: 1, value: 'High', name: 'Priority Hight', color: '#FF1943' },
  { id: 2, value: 'Medium', name: 'Priority Medium', color: '#FFA319' },
  { id: 3, value: 'Low', name: 'Priority Low', color: '#1975FF' }
];

export const getStatusFile = (status: UploadStatusType) => {
  const statusTitles: Record<UploadStatusType, string> = {
    Processing: 'Cargando base...',
    Uploaded: 'Lanzando',
    Completed: 'Completado',
    Failed: 'Carga fallida'
  };

  return statusTitles[status] || '';
};

export const stylesFormScheduleContent = {
  containerBox: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  containerButtons: {
    width: '100%',
    padding: 1,
    display: 'flex',
    justifyContent: 'space-between'
  }
};

export enum DialerbotMessage {
  EDIT_SCHEDULE_PRIORITY = 'The priority was updated successfully',
  DELETE_SCHEDULE = 'The file was deleted!',
  SCHEDULE_MODAL_HEADER = 'Here you can add a new contacts file and view call results details'
}

export enum TypeUploadStatus {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  PROCESSING = 'Processing',
  UPLOADED = 'Uploaded'
}

export const stylesFormScheduleProgress = {
  containerBox: {
    borderRadius: '6px',
    color: 'black',
    paddingY: 0.5,
    paddingX: 1,
    width: '100%',
    zIndex: 1,
    position: 'relative',
    background: '#F2F3F5',
    border: '1px solid #F2F3F5'
  },
  containerGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  containerPercentage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'flex-start',
      sm: 'center'
    }
  },
  containerIcon: {
    marginRight: 10,
    color: '#10103F'
  },
  containerResume: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'flex-start',
      sm: 'flex-end'
    }
  }
};

export const loadingAnimation = keyframes`
0% {
  background-position: 200% 0;
}
100% {
  background-position: -200% 0;
}
`;

export enum ContactsType {
  SELECT = 'select',
  CSV = 'csv',
  CSV_CONTACTS = 'csv-contacts'
}

export const useStyles: any = makeStyles(() => ({
  indicator: {
    background: 'none',
    border: '1px solid #1870F5',
    borderRadius: '10px'
  },
  tabs: {
    '& button': {
      padding: 5
    },
    "& button[aria-selected='true']": {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        border: '7px solid #1870F5',
        background: '#1870F5'
      },

      '& > *': { zIndex: 0 },
      '& > .MuiTab-wrapper': {
        background: '#fff',
        height: '100%'
      }
    }
  }
}));
