import { Card, Grow, Stack } from '@mui/material';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { ActiveCallInfo } from './ActiveCallInfo';
import { CallItem } from './CallItem';
import { useEffect, useState, useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

export const CallInfo: React.FC = () => {
  const user = getConnectedRTUser();
  const activeCall = getActiveRTCall(user);
  const callsList = user?.calls.filter((c) => {
    return (
      c.status !== 'Ringing' &&
      c.status !== 'Conference' &&
      c.callId !== activeCall?.callId
    );
  });
  const [isOpen, setIsOpen] = useState(false);
  const [close, setClose] = useState(false);
  const { barState } = useContext(CTIBarContext);
  const { setKeepBarExtended, lockButtonState } = barState;

  useEffect(() => {
    let timeout;

    if (close) {
      timeout = setTimeout(() => {
        setIsOpen(false);
        setTimeout(() => {
          setKeepBarExtended(lockButtonState !== 0);
        }, 500);
        setClose(false);
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [close, isOpen]);

  if (!callsList || callsList?.length === 0) return <ActiveCallInfo />; //If there is only 1 call, dont show the popover

  return (
    <div
      onMouseEnter={() => {
        setIsOpen(true);
        setKeepBarExtended(true);
        setClose(false);
      }}
      onMouseLeave={() => {
        setClose(true);
      }}
    >
      <ActiveCallInfo />

      <Grow
        style={{
          transformOrigin: 'bottom'
        }}
        in={isOpen}
        unmountOnExit
      >
        <Card
          sx={{
            position: 'absolute',
            bottom: '110%',
            left: '55px'
          }}
        >
          <Stack
            px={2}
            direction="row"
            alignItems="center"
            sx={{ backgroundColor: '#F6F8FB' }}
          >
            <h2>Active calls</h2>
          </Stack>
          <Stack direction={'column'} p={1} minWidth={400} spacing={1}>
            {callsList.map((c, index) => {
              return <CallItem key={index} call={c} showLinkedEnpoint />;
            })}
          </Stack>
        </Card>
      </Grow>
    </div>
  );
};
