import axios from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';
import { User } from 'src/models/users';
import AuthManager from 'src/services/authentication/manager';
import { replaceURLParams } from 'src/services/utils';
import { USERS, USERS_ID, USERS_LIST } from 'src/utils/apiRoutes/msUsers';

export interface UsersParams {
  serviceId?: string;
  companyId?: string;
  email?: string;
  id?: string;
  userId?: string;
  username?: string;
  page?: number;
  size?: number;
  sortDir?: DirectionType;
  sortBy?: keyof User;
}

export const getUserById: Function = () => {
  const path = USERS;

  let params = { id: AuthManager.getUserId() };

  return axios
    .get(path, { params })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getUsersApi = async (params: UsersParams) => {
  const path = USERS;
  const resp = await axios.get(path, { params });
  return resp.data as PaginatedElements<User>;
};

export const getUsersListApi = async (userIds: string[]): Promise<User[]> => {
  const path = USERS_LIST;
  const resp = await axios.post(path, userIds);
  return resp.data;
};

export const getUserByIdApi = async (userId: string): Promise<User> => {
  const path = replaceURLParams(USERS_ID, {
    '{id}': userId
  });
  const resp = await axios.get(path);
  return resp.data;
};
