import { DataFilter as CurrentDataFilter } from 'src/services/shared/domain/filters';
import { getConversationsApi } from '../infrastructure/getConversationsApi';

interface GetConversationsFilter extends CurrentDataFilter {
  contactId: string;
  page: number;
  size: number;
}

/** FILTER CONVERSATIONS BY CONTACT
 *
 * @param filter Parameters to filter the currently stored conversations
 * @returns Array of conversations after applying the specified filters
 */
export const getConversationsByContact = async (
  filter: GetConversationsFilter
) => {
  const paginatedConversations = await getConversationsApi({
    withMessages: true,
    contactId: filter.contactId ? filter.contactId : 'noContactId',
    size: filter.size,
    page: filter.page
  });
  /*const paginatedConversations = getConversationsStore(false).filter((conv) => {
    return conv.involvedContacts.some((contact) => {
      return contact.contactId === filter.contactId;
    });
  });*/

  return paginatedConversations;
};
