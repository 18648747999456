import axios from 'axios';
import {
  ChannelType,
  UsersConversationCount
} from 'src/models/conversations/conversations';
import { CONVERSATIONS_COUNT } from 'src/utils/apiRoutes/msConversations';

export interface ConversationsCountFilter {
  userIds: string[];
  channels: ChannelType[];
}

export const getConversationsCountApi = async (
  filter: ConversationsCountFilter
) => {
  const path = CONVERSATIONS_COUNT;

  const response = await axios.get(path, {
    params: filter,
    paramsSerializer: { indexes: null }
  });

  return response.data as UsersConversationCount;
};
