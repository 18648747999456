import axios, { AxiosResponse } from 'axios';
import { EntitiesPermissions } from '../../models/Dashboards';
import { PANELS_KPIS_ENTITIES_PERMISSIONS } from 'src/utils/apiRoutes/msFlow';

export const getEntitiesWithCreatePermissionsApi =
  async (): Promise<EntitiesPermissions> => {
    const response: AxiosResponse<EntitiesPermissions> = await axios.get(
      PANELS_KPIS_ENTITIES_PERMISSIONS
    );
    return response.data;
  };
