import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CallAsync from './callAsync';
import { callCampaign, CallTypes, WebRTCSession } from './types';
import { RTCSession } from 'jssip/lib/RTCSession';
import { RTCall } from 'src/models/rtcall';

const initialState = {
  rtQueues: [],
  loading: 'idle',
  error: '',
  source: '',
  sessions: [],
  currentConversation: '',
  callCampaigns: [],
  activeCallId: null,
  nextOutgoingCall: '',
  notificationsOff: false,
  missedCalls: [] as RTCall[],
  missedCallsCount: [] as RTCall[]
} as CallTypes;

const callCases = [
  //CallAsync.performCall,
  CallAsync.hangupCall,
  CallAsync.parkCall,
  CallAsync.unParkCall,
  CallAsync.spyCall,
  CallAsync.transferCall
];

export const callSlice = createSlice({
  name: 'callSlice',
  initialState,
  reducers: {
    setRtQueue: (state, action: PayloadAction<any[]>) => {
      state.rtQueues = action.payload;
    },
    setSessionsStore: (state, action: PayloadAction<WebRTCSession[]>) => {
      state.sessions = action.payload;
    },
    setCurrentConversation: (state, action: PayloadAction<string>) => {
      state.currentConversation = action.payload;
    },
    setCallCampaign: (state, action: PayloadAction<callCampaign[]>) => {
      state.callCampaigns = action.payload;
    },
    setActiveRTCallId: (state, action: PayloadAction<string>) => {
      state.activeCallId = action.payload;
    },
    setNextOutgoingCall: (state, action: PayloadAction<string>) => {
      state.nextOutgoingCall = action.payload;
    },
    setNotificationsOff: (state, action: PayloadAction<boolean>) => {
      state.notificationsOff = action.payload;
    },
    setMissedCalls: (state, action: PayloadAction<RTCall[]>) => {
      state.missedCalls = action.payload;
    },
    setMissedCallsCount: (state, action: PayloadAction<RTCall[]>) => {
      state.missedCallsCount = action.payload;
    }
  },
  extraReducers: (builder) => {
    callCases.map((cl, index) => {
      builder.addCase(cl.pending, (state) => {
        state.loading = 'pending';
      });
      builder.addCase(cl.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.source = index;
        state.error = '';
      });
      builder.addCase(cl.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });
    });
  }
});

export const {
  setRtQueue,
  setSessionsStore,
  setCurrentConversation,
  setCallCampaign,
  setActiveRTCallId,
  setNextOutgoingCall,
  setNotificationsOff,
  setMissedCalls,
  setMissedCallsCount
} = callSlice.actions;

export default callSlice.reducer;
