import { Card, Grid } from '@mui/material';
import { useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { RecoverCallPopover } from '../RecoverCallPopover';
import { MuteButton } from './MuteButton';
import { ParkButton } from './ParkButton';
import { TotalCalls } from './TotalCalls';
import { TransferButton } from './TransferButton';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';

export const MainButtons: React.FC = () => {
  const { barState } = useContext(CTIBarContext);
  const { isBarCompressed } = barState;

  return (
    <Card
      sx={{
        height: isBarCompressed ? 46 : 80,
        width: isBarCompressed ? 46 : 80
      }}
    >
      <RecoverCallPopover />
      <Grid container spacing={0}>
        <MuteButton />
        <TotalCalls />
        <TransferButton />
        <ParkButton />
      </Grid>
    </Card>
  );
};
