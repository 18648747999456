import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  CampaignsPermissionLevel,
  Permissions,
  ServicesPermissionLevel,
  UserPermissions
} from 'src/models/permissionGroups';
import {
  InitialElementData,
  NodeTypes
} from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/models/routesNodes';
import {
  CallCampaign,
  CallMode,
  Campaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { TicketConversationSummary } from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import AuthManager from 'src/services/authentication/manager';

// USER PERMISSIONS /////////////////////////////////////////////////////////////////////////////
/**
 * @param userPermissions UserPermissions to be checked.
 * @param permissionsCheckerFn Function in charge of checking userPermissions.
 * @param companyId Filter userPermissions by companyId. If specified, the rules of the other companies are not checked.
 * @returns true, if permissions. false, otherwise.
 */
export const checkUserPermissions = (params: {
  userPermissions: UserPermissions;
  permissionsCheckerFn: (
    permissions: Permissions,
    isSuperAdmin: boolean
  ) => boolean;
}) => {
  const { userPermissions, permissionsCheckerFn } = params;

  const isAllowed = permissionsCheckerFn(
    userPermissions?.permissions,
    userPermissions?.superadmin
  );

  return isAllowed;
};

// DASHBOARD /////////////////////////////////////////////////////////////////////////////
// INBOX
export const checkInboxPermissions = (permissions: Permissions) => true;
// permissions.operations.campaigns.email.read !==
//   CampaignsPermissionLevel.NONE ||
// permissions.operations.campaigns.inbound.read !==
//   CampaignsPermissionLevel.NONE ||
// permissions.operations.campaigns.manual.read !==
//   CampaignsPermissionLevel.NONE ||
// permissions.operations.campaigns.telegram.read !==
//   CampaignsPermissionLevel.NONE;

// PRODUCTIVITY
export const checkProductivityPermissions = (permissions: Permissions) =>
  permissions.operations.campaigns.dialerbot.report !==
    CampaignsPermissionLevel.NONE ||
  permissions.operations.campaigns.email.report !==
    CampaignsPermissionLevel.NONE ||
  permissions.operations.campaigns.inbound.report !==
    CampaignsPermissionLevel.NONE ||
  permissions.operations.campaigns.manual.report !==
    CampaignsPermissionLevel.NONE ||
  permissions.operations.campaigns.telegram.report !==
    CampaignsPermissionLevel.NONE ||
  checkReadDashboardPermissions(permissions) ||
  checkCreateDashboardPermissions(permissions);
// CONTACTS
export const checkContactsPermissions = (permissions: Permissions) =>
  checkReadContactPermissions(permissions) ||
  checkReadContactGroupPermissions(permissions);

// ADMIN
export const checkAdminPermissions = (permissions: Permissions) =>
  checkUpdateAnnouncementPermissions(permissions) ||
  checkUpdateMusicOnHoldPermissions(permissions) ||
  checkUpdateInboundRoutePermissions(permissions) ||
  checkUpdateOutboundRoutePermissions(permissions) ||
  checkUpdateTimeConditionPermissions(permissions) ||
  checkUpdateVariablePermissions(permissions) ||
  checkUpdateExtensionPermissions(permissions) ||
  checkUpdateUserRolePermissions(permissions) ||
  checkUpdateUserPermissions(permissions) ||
  checkUpdateEmailAccountPermissions(permissions) ||
  checkUpdateTelegramAccountPermissions(permissions) ||
  checkUpdateWebChatAccountPermissions(permissions) ||
  checkUpdateWhatsAppAccountPermissions(permissions) ||
  checkUpdateDialerbotCampaignPermissions(permissions) ||
  checkUpdateEmailCampaignPermissions(permissions) ||
  checkUpdateInboundCampaignPermissions(permissions) ||
  checkUpdateOutboundCampaignPermissions(permissions) ||
  checkUpdateTelegramCampaignPermissions(permissions) ||
  checkUpdateServicePermissions(permissions) ||
  checkUpdateExternalActionsPermissions(permissions) ||
  checkUpdateQuestionGroupsPermissions(permissions) ||
  checkUpdateRobinsonListsPermissions(permissions) ||
  checkUpdateIntentionModelsPermissions(permissions) ||
  checkCreateMassiveSmsPermissions(permissions) ||
  checkCreateMassiveEmailPermissions(permissions) ||
  checkCreateMassiveWhatsAppPermissions(permissions) ||
  checkReadTrunksPermissions(permissions) ||
  checkReadCallerIdsPermissions(permissions) ||
  checkCreateMassiveWhatsAppPermissions(permissions) ||
  checkUpdateUserStatusPermissions(permissions) ||
  checkUpdatePromptsPermissions(permissions);

// EXTENSIONS /////////////////////////////////////////////////////////////////////////////
export const checkCreateExtensionPermissions = (permissions: Permissions) =>
  permissions.admin.general.extensions.create;

export const checkReadExtensionPermissions = (permissions: Permissions) =>
  permissions.admin.general.extensions.read;

export const checkUpdateExtensionPermissions = (permissions: Permissions) =>
  permissions.admin.general.extensions.update;

export const checkDeleteExtensionPermissions = (permissions: Permissions) =>
  permissions.admin.general.extensions.delete;

// CALLER IDS /////////////////////////////////////////////////////////////////////////////
export const checkReadCallerIdsPermissions = (permissions: Permissions) =>
  permissions.admin.general.callerIds.read;

// TRUNKS ///////////////////////////////////////////////////////////////////////////////
export const checkReadTrunksPermissions = (permissions: Permissions) =>
  permissions.admin.general.trunks.read;

// ACCOUNTS /////////////////////////////////////////////////////////////////////////////
// EMAIL
export const checkCreateEmailAccountPermissions = (permissions: Permissions) =>
  permissions.omnichannel.accounts.email.create;

export const checkReadEmailAccountPermissions = (permissions: Permissions) =>
  permissions.omnichannel.accounts.email.read;

export const checkUpdateEmailAccountPermissions = (permissions: Permissions) =>
  permissions.omnichannel.accounts.email.update;

export const checkDeleteEmailAccountPermissions = (permissions: Permissions) =>
  permissions.omnichannel.accounts.email.delete;

// TELEGRAM
export const checkCreateTelegramAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.telegram.create;

export const checkReadTelegramAccountPermissions = (permissions: Permissions) =>
  permissions.omnichannel.accounts.telegram.read;

export const checkUpdateTelegramAccountPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.accounts.telegram.read &&
  permissions.omnichannel.accounts.telegram.update;

export const checkDeleteTelegramAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.telegram.delete;

// WEBCHAT
export const checkCreateWebChatAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.webChat.create;

export const checkReadWebChatAccountPermissions = (permissions: Permissions) =>
  permissions.omnichannel.accounts.webChat.read;

export const checkUpdateWebChatAccountPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.accounts.webChat.read &&
  permissions.omnichannel.accounts.webChat.update;

export const checkDeleteWebChatAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.webChat.delete;

// WHATSAPP
export const checkCreateWhatsAppAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.whatsApp.create;

export const checkReadWhatsAppAccountPermissions = (permissions: Permissions) =>
  permissions.omnichannel.accounts.whatsApp.read;

export const checkUpdateWhatsAppAccountPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.accounts.whatsApp.read &&
  permissions.omnichannel.accounts.whatsApp.update;

export const checkDeleteWhatsAppAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.whatsApp.delete;

// INSTAGRAM
export const checkCreateInstagramAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.instagram.create;

export const checkReadInstagramAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.instagram.read;

export const checkUpdateInstagramAccountPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.accounts.instagram.read &&
  permissions.omnichannel.accounts.instagram.update;

export const checkDeleteInstagramAccountPermissions = (
  permissions: Permissions
) => permissions.omnichannel.accounts.instagram.delete;

// USERS /////////////////////////////////////////////////////////////////////////////
export const checkCreateUserPermissions = (permissions: Permissions) =>
  permissions.admin.management.users.create;

export const checkReadUserPermissions = (permissions: Permissions) =>
  permissions.admin.management.users.read !== CampaignsPermissionLevel.NONE;

export const checkUpdateUserPermissions = (permissions: Permissions) =>
  permissions.admin.management.users.read !== CampaignsPermissionLevel.NONE &&
  permissions.admin.management.users.update;

export const checkDeleteUserPermissions = (permissions: Permissions) =>
  permissions.admin.management.users.delete;

// USER ROLES /////////////////////////////////////////////////////////////////////////////
export const checkCreateUserRolePermissions = (permissions: Permissions) =>
  permissions.admin.management.roles.create;

export const checkReadUserRolePermissions = (permissions: Permissions) =>
  permissions.admin.management.roles.read;

export const checkUpdateUserRolePermissions = (permissions: Permissions) =>
  permissions.admin.management.roles.read &&
  permissions.admin.management.roles.update;

export const checkDeleteUserRolePermissions = (permissions: Permissions) =>
  permissions.admin.management.roles.delete;

// INBOUND ROUTES /////////////////////////////////////////////////////////////////////////////
export const checkCreateInboundRoutePermissions = (permissions: Permissions) =>
  permissions.admin.acd.inboundRoutes.create;

export const checkReadInboundRoutePermissions = (permissions: Permissions) =>
  permissions.admin.acd.inboundRoutes.read;

export const checkUpdateInboundRoutePermissions = (permissions: Permissions) =>
  permissions.admin.acd.inboundRoutes.read &&
  permissions.admin.acd.inboundRoutes.update;

export const checkDeleteInboundRoutePermissions = (permissions: Permissions) =>
  permissions.admin.acd.inboundRoutes.delete;

// OUTBOUND ROUTES /////////////////////////////////////////////////////////////////////////////
export const checkCreateOutboundRoutePermissions = (permissions: Permissions) =>
  permissions.admin.acd.outboundRoutes.create;

export const checkReadOutboundRoutePermissions = (permissions: Permissions) =>
  permissions.admin.acd.outboundRoutes.read;

export const checkUpdateOutboundRoutePermissions = (permissions: Permissions) =>
  checkReadOutboundRoutePermissions(permissions) &&
  permissions.admin.acd.outboundRoutes.update;

export const checkDeleteOutboundRoutePermissions = (permissions: Permissions) =>
  permissions.admin.acd.outboundRoutes.delete;

// ACD /////////////////////////////////////////////////////////////////////////////
export const checkCreateNodePermissions = (element: InitialElementData) => {
  switch (element?.type) {
    case 'Campaign':
      return checkCreateCampaignPermissions;
    case 'TimeCondition':
      return checkAddTimeContionNodePermissions;
    case 'Announcement':
      return checkAddAnnouncementNodePermissions;
    default:
      return () => true;
  }
};

export const checkCreateNodePermissionsByType = (type: NodeTypes) => {
  switch (type) {
    case 'Campaign':
      return checkCreateCampaignPermissions;
    case 'TimeCondition':
      return checkAddTimeContionNodePermissions;
    case 'Announcement':
      return checkAddAnnouncementNodePermissions;
    default:
      return () => true;
  }
};

export const checkUpdateNodePermissions = (element: InitialElementData) => {
  switch (element?.type) {
    case 'Campaign':
      return checkUpdateSomeCampaignPermissions;
    case 'TimeCondition':
      return checkUpdateTimeConditionPermissions;
    case 'Announcement':
      return checkEditAnnouncementNodePermissions;
    default:
      return () => true;
  }
};

export const checkUpdateNodePermissionsByType = (type: NodeTypes) => {
  switch (type) {
    case 'Campaign':
      return checkUpdateSomeCampaignPermissions;
    case 'TimeCondition':
      return checkUpdateTimeConditionPermissions;
    case 'Announcement':
      return checkEditAnnouncementNodePermissions;
    default:
      return () => true;
  }
};

// VARIABLES /////////////////////////////////////////////////////////////////////////////
export const checkCreateVariablePermissions = (permissions: Permissions) =>
  permissions.admin.acd.variables.create;

export const checkReadVariablePermissions = (permissions: Permissions) =>
  permissions.admin.acd.variables.read;

export const checkUpdateVariablePermissions = (permissions: Permissions) =>
  permissions.admin.acd.variables.read &&
  permissions.admin.acd.variables.update;

export const checkDeleteVariablePermissions = (permissions: Permissions) =>
  permissions.admin.acd.variables.delete;

export const checkAddVariableNodePermissions = (permissions: Permissions) =>
  checkReadAnnouncementPermissions(permissions) &&
  checkReadVariablePermissions(permissions) &&
  checkCreateVariablePermissions(permissions);

export const checkEditVariableNodePermissions = (permissions: Permissions) =>
  checkReadAnnouncementPermissions(permissions) &&
  checkReadVariablePermissions(permissions) &&
  checkUpdateVariablePermissions(permissions);

// INTENTION MODELS //////////////////////////////////////////////////////////////////
export const checkCreateIntentionModelsPermissions = (
  permissions: Permissions
) => permissions.admin.acd.intentionModels.create;

export const checkReadIntentionModelsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.intentionModels.read;

export const checkUpdateIntentionModelsPermissions = (
  permissions: Permissions
) =>
  permissions.admin.acd.intentionModels.read &&
  permissions.admin.acd.intentionModels.update;

export const checkDeleteIntentionModelsPermissions = (
  permissions: Permissions
) => permissions.admin.acd.intentionModels.delete;

// ENTITIES MODELS //////////////////////////////////////////////////////////////////
export const checkCreateEntityModelsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.entityModels.create;

export const checkReadEntityModelsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.entityModels.read;

export const checkUpdateEntityModelsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.entityModels.read &&
  permissions.admin.acd.entityModels.update;

export const checkDeleteEntityModelsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.entityModels.delete;

// PROMPTS //////////////////////////////////////////////////////////////////
export const checkCreatePromptsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.prompts.create;

export const checkReadPromptsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.prompts.read;

export const checkUpdatePromptsPermissions = (permissions: Permissions) =>
  checkReadPromptsPermissions(permissions) &&
  permissions.admin.acd.prompts.update;

export const checkDeletePromptsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.prompts.delete;

// KNOWLEDGE MODELS //////////////////////////////////////////////////////////////////
export const checkCreateKnowledgeModelsPermissions = (
  permissions: Permissions
) => permissions.admin.acd.knowledgeModels.create;

export const checkReadKnowledgeModelsPermissions = (permissions: Permissions) =>
  permissions.admin.acd.knowledgeModels.read;

export const checkUpdateKnowledgeModelsPermissions = (
  permissions: Permissions
) =>
  permissions.admin.acd.knowledgeModels.read &&
  permissions.admin.acd.knowledgeModels.update;

export const checkDeleteKnowledgeModelsPermissions = (
  permissions: Permissions
) => permissions.admin.acd.knowledgeModels.delete;

// QUEUES /////////////////////////////////////////////////////////////////////////////
export const checkAddCampaignNodePermissions = (permissions: Permissions) =>
  checkReadCallOutboundCampaignPermissions(permissions) &&
  checkReadExtensionPermissions(permissions) &&
  checkReadCampaignPermissions(permissions) &&
  checkCreateCampaignPermissions(permissions);

export const checkEditCampaignNodePermissions = (permissions: Permissions) =>
  checkReadCallOutboundCampaignPermissions(permissions) &&
  checkReadExtensionPermissions(permissions) &&
  checkReadCampaignPermissions(permissions) &&
  checkUpdateSomeCampaignPermissions(permissions);

// TIME CONDITIONS /////////////////////////////////////////////////////////////////////////////
export const checkCreateTimeConditionPermissions = (permissions: Permissions) =>
  permissions.admin.acd.timeConditions.create;

export const checkReadTimeConditionPermissions = (permissions: Permissions) =>
  permissions.admin.acd.timeConditions.read;

export const checkUpdateTimeConditionPermissions = (permissions: Permissions) =>
  permissions.admin.acd.timeConditions.read &&
  permissions.admin.acd.timeConditions.update;

export const checkDeleteTimeConditionPermissions = (permissions: Permissions) =>
  permissions.admin.acd.timeConditions.delete;

export const checkAddTimeContionNodePermissions = (permissions: Permissions) =>
  checkReadTimeConditionPermissions(permissions) &&
  checkCreateTimeConditionPermissions(permissions);

export const checkEditTimeContionNodePermissions = (permissions: Permissions) =>
  checkUpdateTimeConditionPermissions(permissions);

// ANNOUNCEMENTS /////////////////////////////////////////////////////////////////////////////
export const checkCreateAnnouncementPermissions = (permissions: Permissions) =>
  permissions.admin.acd.announcements.create;

export const checkReadAnnouncementPermissions = (permissions: Permissions) =>
  permissions.admin.acd.announcements.read;

export const checkUpdateAnnouncementPermissions = (permissions: Permissions) =>
  permissions.admin.acd.announcements.read &&
  permissions.admin.acd.announcements.update;

export const checkDeleteAnnouncementPermissions = (permissions: Permissions) =>
  permissions.admin.acd.announcements.delete;

export const checkAddAnnouncementNodePermissions = (permissions: Permissions) =>
  checkReadAnnouncementPermissions(permissions) &&
  checkCreateAnnouncementPermissions(permissions);

export const checkEditAnnouncementNodePermissions = (
  permissions: Permissions
) => checkUpdateAnnouncementPermissions(permissions);

// MUSIC ON HOLD ////////////////////////////////////////////////////////////////////////
export const checkCreateMusicOnHoldPermissions = (permissions: Permissions) =>
  permissions.admin.acd.moh.create;

export const checkReadMusicOnHoldPermissions = (permissions: Permissions) =>
  permissions.admin.acd.moh.read;

export const checkUpdateMusicOnHoldPermissions = (permissions: Permissions) =>
  checkReadMusicOnHoldPermissions(permissions) &&
  permissions.admin.acd.moh.update;

export const checkDeleteMusicOnHoldPermissions = (permissions: Permissions) =>
  permissions.admin.acd.moh.delete;

// CONTACTS /////////////////////////////////////////////////////////////////////////////
export const checkCreateContactPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contacts.create !== ServicesPermissionLevel.NONE;

export const checkReadContactPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contacts.read !== ServicesPermissionLevel.NONE;

export const checkUpdateContactPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contacts.read !== ServicesPermissionLevel.NONE &&
  permissions.admin.contacts.contacts.update !== ServicesPermissionLevel.NONE;

export const checkUploadContactPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contacts.upload !== ServicesPermissionLevel.NONE;

export const checkDeleteContactPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contacts.delete !== ServicesPermissionLevel.NONE;

// CONTACT GROUPS /////////////////////////////////////////////////////////////////////////////
export const checkCreateContactGroupPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contactGroups.create;

export const checkReadContactGroupPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contactGroups.read !==
  ServicesPermissionLevel.NONE;

export const checkUpdateContactGroupPermissions = (permissions: Permissions) =>
  checkReadContactGroupPermissions(permissions) &&
  permissions.admin.contacts.contactGroups.update !==
    ServicesPermissionLevel.NONE;

export const checkDeleteContactGroupPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.contactGroups.delete !==
  ServicesPermissionLevel.NONE;

// CONTACTS & CONTACT GROUPS ////////////////////////////////////////////////////////////
export const checkReadContactsAndContactGroupsPermissions = (
  permissions: Permissions
) =>
  checkReadContactPermissions(permissions) &&
  checkReadContactGroupPermissions(permissions);

// CONTACT FIELDS /////////////////////////////////////////////////////////////////////////////
export const checkCreateContactFieldsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.fields.create !== ServicesPermissionLevel.NONE;

export const checkReadContactFieldsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.fields.read !== ServicesPermissionLevel.NONE;

export const checkUpdateContactFieldsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.fields.update !== ServicesPermissionLevel.NONE &&
  checkReadContactFieldsPermissions(permissions);

export const checkDeleteContactFieldsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.fields.delete !== ServicesPermissionLevel.NONE;

// CONTACT TAGS /////////////////////////////////////////////////////////////////////////////
export const checkCreateContactTagsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.tags.create !== ServicesPermissionLevel.NONE;

export const checkReadContactTagsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.tags.read !== ServicesPermissionLevel.NONE;

export const checkUpdateContactTagsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.tags.update !== ServicesPermissionLevel.NONE &&
  checkReadContactTagsPermissions(permissions);

export const checkDeleteContactTagsPermissions = (permissions: Permissions) =>
  permissions.admin.contacts.tags.delete !== ServicesPermissionLevel.NONE;

// INTEGRATIONS ////////////////////////////////////////////////////////////////////////

// EXTERNAL ACTIONS ////////////////////////////////////////////////////////////////////
export const checkCreateExternalActionsPermissions = (
  permissions: Permissions
) => permissions.admin.integrations.externalActions.create;

export const checkReadExternalActionsPermissions = (permissions: Permissions) =>
  permissions.admin.integrations.externalActions.read;

export const checkUpdateExternalActionsPermissions = (
  permissions: Permissions
) => permissions.admin.integrations.externalActions.update;

export const checkDeleteExternalActionsPermissions = (
  permissions: Permissions
) => permissions.admin.integrations.externalActions.delete;

// API KEYS ////////////////////////////////////////////////////////////////////
export const checkCreateApiKeysPermissions = (permissions: Permissions) =>
  permissions.admin.integrations.apiKeys.create;

export const checkReadApiKeysPermissions = (permissions: Permissions) =>
  permissions.admin.integrations.apiKeys.read;

export const checkUpdateApiKeysPermissions = (permissions: Permissions) =>
  permissions.admin.integrations.apiKeys.update &&
  checkReadApiKeysPermissions(permissions);

export const checkDeleteApiKeysPermissions = (permissions: Permissions) =>
  permissions.admin.integrations.apiKeys.delete;

// TICKETS /////////////////////////////////////////////////////////////////////////////
export const checkCreateTicketPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.tickets.create !== ServicesPermissionLevel.NONE;

export const checkReadTicketPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.tickets.read !== ServicesPermissionLevel.NONE;

export const checkUpdateTicketPermissions = (permissions: Permissions) =>
  checkReadTicketPermissions(permissions) &&
  permissions.admin.tickets.tickets.update !== ServicesPermissionLevel.NONE;

export const checkDeleteTicketPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.tickets.delete !== ServicesPermissionLevel.NONE;

export const checkUploadTicketPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.tickets.upload !== ServicesPermissionLevel.NONE;

// TICKET TAGS /////////////////////////////////////////////////////////////////////////////
export const checkCreateTicketTagPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.tags.create;

export const checkReadTicketTagPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.tags.read !== ServicesPermissionLevel.NONE;

export const checkUpdateTicketTagPermissions = (permissions: Permissions) =>
  checkReadTicketTagPermissions(permissions) &&
  permissions.admin.tickets.tags.update !== ServicesPermissionLevel.NONE;

export const checkDeleteTicketTagPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.tags.delete !== ServicesPermissionLevel.NONE;

// TICKET FIELDS /////////////////////////////////////////////////////////////////////////////
export const checkCreateTicketFieldPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.fields.create;

export const checkReadTicketFieldPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.fields.read !== ServicesPermissionLevel.NONE;

export const checkUpdateTicketFieldPermissions = (permissions: Permissions) =>
  checkReadTicketFieldPermissions(permissions) &&
  permissions.admin.tickets.fields.update !== ServicesPermissionLevel.NONE;

export const checkDeleteTicketFieldPermissions = (permissions: Permissions) =>
  permissions.admin.tickets.fields.delete !== ServicesPermissionLevel.NONE;

// TICKET RESOLUTIONS /////////////////////////////////////////////////////////////////////////////
export const checkCreateTicketResolutionPermissions = (
  permissions: Permissions
) => permissions.admin.tickets.resolutions.create;

export const checkReadTicketResolutionPermissions = (
  permissions: Permissions
) =>
  permissions.admin.tickets.resolutions.read !== ServicesPermissionLevel.NONE;

export const checkUpdateTicketResolutionPermissions = (
  permissions: Permissions
) =>
  checkReadTicketResolutionPermissions(permissions) &&
  permissions.admin.tickets.resolutions.update !== ServicesPermissionLevel.NONE;

export const checkDeleteTicketResolutionPermissions = (
  permissions: Permissions
) =>
  permissions.admin.tickets.resolutions.delete !== ServicesPermissionLevel.NONE;

// TICKET VIEW /////////////////////////////////////////////////////////////////////////////
export const checkReadTicketViewPermissions = (permissions: Permissions) =>
  checkReadTicketPermissions(permissions) &&
  checkReadTicketTagPermissions(permissions) &&
  checkReadTicketFieldPermissions(permissions) &&
  checkReadTicketResolutionPermissions(permissions);

// SPEECH ANALYSIS /////////////////////////////////////////////////////////////////////////////
export const checkCreateAnalyzePermissions = (permissions: Permissions) =>
  // PROVISIONAL
  permissions.admin.quality?.analyze.create
    ? permissions.admin.quality?.analyze.create !== ServicesPermissionLevel.NONE
    : false;

export const checkReadAnalyzePermissions = (permissions: Permissions) =>
  // PROVISIONAL
  permissions.admin.quality?.analyze.read
    ? permissions.admin.quality?.analyze.read !== ServicesPermissionLevel.NONE
    : false;

export const checkUploadAnalyzePermissions = (permissions: Permissions) =>
  // PROVISIONAL
  permissions.admin.quality?.analyze.upload
    ? permissions.admin.quality?.analyze.upload !== ServicesPermissionLevel.NONE
    : false;

export const checkDeleteAnalyzePermissions = (permissions: Permissions) =>
  // PROVISIONAL
  permissions.admin.quality?.analyze.delete
    ? permissions.admin.quality?.analyze.delete !== ServicesPermissionLevel.NONE
    : false;

// SPEECH KEYWORD GROUPS /////////////////////////////////////////////////////////////////////////////
export const checkCreateKeyWordsGroupsPermissions = (
  permissions: Permissions
) =>
  // PROVISIONAL
  permissions.admin.quality?.keyWordsGroups.create
    ? permissions.admin.quality?.keyWordsGroups.create !==
      ServicesPermissionLevel.NONE
    : false;

export const checkReadKeyWordsGroupsPermissions = (permissions: Permissions) =>
  // PROVISIONAL
  permissions.admin.quality?.keyWordsGroups.read !==
  ServicesPermissionLevel.NONE;

export const checkUpdateKeyWordsGroupsPermissions = (
  permissions: Permissions
) =>
  // PROVISIONAL
  permissions.admin.quality?.keyWordsGroups.update
    ? checkReadKeyWordsGroupsPermissions(permissions) &&
      permissions.admin.quality?.keyWordsGroups.update !==
        ServicesPermissionLevel.NONE
    : false;

export const checkDeleteKeyWordsGroupsPermissions = (
  permissions: Permissions
) =>
  // PROVISIONAL
  permissions.admin.quality?.keyWordsGroups.delete
    ? permissions.admin.quality?.keyWordsGroups.delete !==
      ServicesPermissionLevel.NONE
    : false;

// SPEECH QUESTION GROUPS /////////////////////////////////////////////////////////////////////////////
export const checkCreateQuestionGroupsPermissions = (
  permissions: Permissions
) => permissions.admin.quality.questionGroups.create;

export const checkReadQuestionGroupsPermissions = (permissions: Permissions) =>
  permissions.admin.quality.questionGroups.read;

export const checkUpdateQuestionGroupsPermissions = (
  permissions: Permissions
) => permissions.admin.quality.questionGroups.update;

export const checkDeleteQuestionGroupsPermissions = (
  permissions: Permissions
) => permissions.admin.quality.questionGroups.delete;

// ROBINSON LISTS /////////////////////////////////////////////////////////////////////////////
export const checkCreateRobinsonListsPermissions = (permissions: Permissions) =>
  permissions.operations.robinsonList.basic.create;

export const checkReadRobinsonListsPermissions = (permissions: Permissions) =>
  permissions.operations.robinsonList.basic.read !==
  ServicesPermissionLevel.NONE;

export const checkUpdateRobinsonListsPermissions = (permissions: Permissions) =>
  checkReadRobinsonListsPermissions(permissions) &&
  permissions.operations.robinsonList.basic.update !==
    ServicesPermissionLevel.NONE;

export const checkDeleteRobinsonListsPermissions = (permissions: Permissions) =>
  permissions.operations.robinsonList.basic.delete;

// EMAIL MASSIVE  /////////////////////////////////////////////////////////////////////////////
export const checkCreateMassiveEmailPermissions = (permissions: Permissions) =>
  permissions.operations.massive.email.create;

export const checkReadMassiveEmailPermissions = (permissions: Permissions) =>
  permissions.operations.massive.email.read;

export const checkLaunchMassiveEmailPermissions = (permissions: Permissions) =>
  permissions.operations.massive.email.launch;

export const checkRemoveMassiveEmailPermissions = (permissions: Permissions) =>
  permissions.operations.massive.email.remove;

export const checkUploadMassiveEmailPermissions = (permissions: Permissions) =>
  permissions.operations.massive.email.upload;

export const checkReportMassiveEmailPermissions = (permissions: Permissions) =>
  permissions.operations.massive.email.report;

// SMS MASSIVE  /////////////////////////////////////////////////////////////////////////////
export const checkCreateMassiveSmsPermissions = (permissions: Permissions) =>
  permissions.operations.massive.sms.create;

export const checkReadMassiveSmsPermissions = (permissions: Permissions) =>
  permissions.operations.massive.sms.read;

export const checkLaunchMassiveSmsPermissions = (permissions: Permissions) =>
  permissions.operations.massive.sms.launch;

export const checkRemoveMassiveSmsPermissions = (permissions: Permissions) =>
  permissions.operations.massive.sms.remove;

export const checkUploadMassiveSmsPermissions = (permissions: Permissions) =>
  permissions.operations.massive.sms.upload;

export const checkReportMassiveSmsPermissions = (permissions: Permissions) =>
  permissions.operations.massive.sms.report;

// WHATSAPP MASSIVE  /////////////////////////////////////////////////////////////////////////////
export const checkCreateMassiveWhatsAppPermissions = (
  permissions: Permissions
) => permissions.operations.massive.whatsApp.create;

export const checkReadMassiveWhatsAppPermissions = (permissions: Permissions) =>
  permissions.operations.massive.whatsApp.read;

export const checkLaunchMassiveWhatsAppPermissions = (
  permissions: Permissions
) => permissions.operations.massive.whatsApp.launch;

export const checkRemoveMassiveWhatsAppPermissions = (
  permissions: Permissions
) => permissions.operations.massive.whatsApp.remove;

export const checkUploadMassiveWhatsAppPermissions = (
  permissions: Permissions
) => permissions.operations.massive.whatsApp.upload;

export const checkReportMassiveWhatsAppPermissions = (
  permissions: Permissions
) => permissions.operations.massive.whatsApp.report;

// USER STATUSES  /////////////////////////////////////////////////////////////////////////////
export const checkChangeOtherUserStatusPermissions = (
  permissions: Permissions
) =>
  permissions.operations.company.userStatuses.changeOthers !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateUserStatusPermissions = (permissions: Permissions) =>
  permissions.operations.company.userStatuses.update;

// SERVICES /////////////////////////////////////////////////////////////////////////////
export const checkCreateServicePermissions = (permissions: Permissions) =>
  permissions.operations.services.basic.create;

export const checkReadServicePermissions = (permissions: Permissions) =>
  permissions.operations.services.basic.read !== ServicesPermissionLevel.NONE;

export const checkUpdateServicePermissions = (permissions: Permissions) =>
  checkReadServicePermissions(permissions) &&
  permissions.operations.services.basic.update !== ServicesPermissionLevel.NONE;

export const checkDeleteServicePermissions = (permissions: Permissions) =>
  permissions.operations.services.basic.delete !== ServicesPermissionLevel.NONE;

// CAMPAIGNS /////////////////////////////////////////////////////////////////////////////
export const checkCreateCampaignPermissions = (permissions: Permissions) =>
  checkCreateDialerbotCampaignPermissions(permissions) ||
  checkCreateEmailCampaignPermissions(permissions) ||
  checkCreateVideocallCampaignPermissions(permissions) ||
  checkCreateInboundCampaignPermissions(permissions) ||
  checkCreateOutboundCampaignPermissions(permissions) ||
  checkCreateTelegramCampaignPermissions(permissions) ||
  checkCreateInstagramAccountPermissions(permissions) ||
  checkCreateWebChatAccountPermissions(permissions);

export const checkReadCampaignPermissions = (permissions: Permissions) =>
  checkReadDialerbotCampaignPermissions(permissions) ||
  checkReadEmailCampaignPermissions(permissions) ||
  checkReadVideocallCampaignPermissions(permissions) ||
  checkReadInboundCampaignPermissions(permissions) ||
  checkReadOutboundCampaignPermissions(permissions) ||
  checkReadTelegramCampaignPermissions(permissions) ||
  checkReadInstagramAccountPermissions(permissions) ||
  checkReadWebChatAccountPermissions(permissions);

export const checkUpdateSomeCampaignPermissions = (permissions: Permissions) =>
  checkUpdateDialerbotCampaignPermissions(permissions) ||
  checkUpdateEmailAccountPermissions(permissions) ||
  checkUpdateVideocallCampaignPermissions(permissions) ||
  checkUpdateInboundCampaignPermissions(permissions) ||
  checkUpdateOutboundCampaignPermissions(permissions) ||
  checkUpdateTelegramAccountPermissions(permissions) ||
  checkUpdateInstagramAccountPermissions(permissions) ||
  checkUpdateWebChatAccountPermissions(permissions);

// Detect if is DialerBot, Progressive or Predictive
const isLaunchEngine = (campaign: CallCampaign) => {
  return [
    CallMode.DIALERBOT,
    CallMode.PREDICTIVE,
    CallMode.PROGRESSIVE,
    CallMode.POWERDIALER
  ].includes(campaign.callMode);
};

export const checkEnableDisableCampaignPermissions = (campaign: Campaign) => {
  return (permissions: Permissions) => {
    if (campaign.channel === ChannelType.EMAIL)
      return (
        permissions.operations.campaigns.email.enableDisable !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.INSTAGRAM)
      return (
        permissions.operations.campaigns.instagram.enableDisable !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.TELEGRAM)
      return (
        permissions.operations.campaigns.telegram.enableDisable !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.WEBCHAT)
      return (
        permissions.operations.campaigns.webChat.enableDisable !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.WHATSAPP)
      return (
        permissions.operations.campaigns.webChat.enableDisable !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.CALL) {
      if (isLaunchEngine(campaign))
        return (
          permissions.operations.campaigns.dialerbot.enableDisable !==
          CampaignsPermissionLevel.NONE
        );
      if (campaign.callMode === CallMode.INBOUND)
        return (
          permissions.operations.campaigns.inbound.enableDisable !==
          CampaignsPermissionLevel.NONE
        );
      if (campaign.callMode === CallMode.OUTBOUND)
        return (
          permissions.operations.campaigns.manual.enableDisable !==
          CampaignsPermissionLevel.NONE
        );
      if (campaign.callMode === CallMode.VIDEOCALL)
        return (
          permissions.operations.campaigns.videocalls.enableDisable !==
          CampaignsPermissionLevel.NONE
        );
    }
    // DEFAULT
    return false;
  };
};

/** Check permissions to update and view campaigns */
export const checkUpdateCampaignPermissions = (campaign: Campaign) => {
  return (permissions: Permissions) => {
    if (campaign.channel === ChannelType.EMAIL)
      return (
        checkUpdateEmailCampaignPermissions(permissions) &&
        checkReadEmailAccountPermissions(permissions)
      );
    if (campaign.channel === ChannelType.INSTAGRAM)
      return (
        checkUpdateInstagramCampaignPermissions(permissions) &&
        checkReadInstagramAccountPermissions(permissions)
      );
    if (campaign.channel === ChannelType.TELEGRAM)
      return (
        checkUpdateTelegramCampaignPermissions(permissions) &&
        checkReadTelegramAccountPermissions(permissions)
      );
    if (campaign.channel === ChannelType.WEBCHAT)
      return (
        checkUpdateWebChatCampaignPermissions(permissions) &&
        checkReadWebChatAccountPermissions(permissions)
      );
    if (campaign.channel === ChannelType.WHATSAPP)
      return (
        checkUpdateWhatsAppCampaignPermissions(permissions) &&
        checkReadWhatsAppAccountPermissions(permissions)
      );
    if (campaign.channel === ChannelType.CALL) {
      if (campaign.callMode === CallMode.INBOUND) {
        return checkUpdateInboundCampaignPermissions(permissions);
      }
      if (campaign.callMode === CallMode.OUTBOUND) {
        return checkUpdateOutboundCampaignPermissions(permissions);
      }
      if (campaign.callMode === CallMode.VIDEOCALL) {
        return checkUpdateVideocallCampaignPermissions(permissions);
      }
      if (isLaunchEngine(campaign)) {
        return checkUpdateDialerbotCampaignPermissions(permissions);
      }
    }
    return false;
  };
};

export const checkDeleteCampaignPermissions = (campaign: Campaign) => {
  return (permissions: Permissions) => {
    if (campaign.channel === ChannelType.EMAIL)
      return (
        permissions.operations.campaigns.email.delete !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.INSTAGRAM)
      return (
        permissions.operations.campaigns.instagram.delete !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.TELEGRAM)
      return (
        permissions.operations.campaigns.telegram.delete !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.WEBCHAT)
      return (
        permissions.operations.campaigns.webChat.delete !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.WHATSAPP)
      return (
        permissions.operations.campaigns.whatsApp.delete !==
        CampaignsPermissionLevel.NONE
      );
    if (campaign.channel === ChannelType.CALL) {
      if (isLaunchEngine(campaign))
        return checkDeleteDialerbotCampaignPermissions(permissions);
      if (campaign.callMode === CallMode.INBOUND)
        return (
          permissions.operations.campaigns.inbound.delete !==
          CampaignsPermissionLevel.NONE
        );
      if (campaign.callMode === CallMode.OUTBOUND)
        return (
          permissions.operations.campaigns.manual.delete !==
          CampaignsPermissionLevel.NONE
        );
      if (campaign.callMode === CallMode.VIDEOCALL)
        return (
          permissions.operations.campaigns.videocalls.delete !==
          CampaignsPermissionLevel.NONE
        );
    }
    // DEFAULT
    return false;
  };
};

// DIALERBOT
export const checkCreateDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.dialerbot.create !==
  ServicesPermissionLevel.NONE;

export const checkReadDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.dialerbot.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.dialerbot.update !==
  CampaignsPermissionLevel.NONE;

export const checkDeleteDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.dialerbot.delete !==
  CampaignsPermissionLevel.NONE;

export const checkStartPauseDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.dialerbot.startPause !==
  CampaignsPermissionLevel.NONE;

export const checkUploadOrReportDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  checkReportDialerbotCampaignPermissions(permissions) ||
  checkUploadDialerbotCampaignPermissions(permissions);

export const checkReportDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.dialerbot.report !==
  CampaignsPermissionLevel.NONE;

export const checkUploadDialerbotCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.dialerbot.upload !==
  CampaignsPermissionLevel.NONE;

// LAUNCH ENGINE
export const checkCreateLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.launchEngine.create !==
  ServicesPermissionLevel.NONE;

export const checkReadLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.launchEngine.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.launchEngine.update !==
  CampaignsPermissionLevel.NONE;

export const checkDeleteLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.launchEngine.delete !==
  CampaignsPermissionLevel.NONE;

export const checkStartPauseLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.launchEngine.startPause !==
  CampaignsPermissionLevel.NONE;

export const checkUploadOrReportLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  checkReportLaunchEngineCampaignPermissions(permissions) ||
  checkUploadLaunchEngineCampaignPermissions(permissions);

export const checkReportLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.launchEngine.report !==
  CampaignsPermissionLevel.NONE;

export const checkUploadLaunchEngineCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.launchEngine.upload !==
  CampaignsPermissionLevel.NONE;

// DIALERBOT + CALL LAUNCH
export const checkCreateAnyCallLaunchCampaignPermissions = (
  permissions: Permissions
) =>
  checkCreateDialerbotCampaignPermissions(permissions) ||
  checkCreateLaunchEngineCampaignPermissions(permissions);

export const checkReadAnyCallLaunchCampaignPermissions = (
  permissions: Permissions
) =>
  checkReadDialerbotCampaignPermissions(permissions) ||
  checkReadLaunchEngineCampaignPermissions(permissions);

export const checkUpdateAnyCallLaunchCampaignPermissions = (
  permissions: Permissions
) =>
  checkUpdateDialerbotCampaignPermissions(permissions) ||
  checkUpdateLaunchEngineCampaignPermissions(permissions);

// EMAIL
export const checkCreateEmailCampaignPermissions = (permissions: Permissions) =>
  permissions.operations.campaigns.email.create !==
  ServicesPermissionLevel.NONE;

export const checkReadEmailCampaignPermissions = (permissions: Permissions) =>
  permissions.operations.campaigns.email.read !== CampaignsPermissionLevel.NONE;

export const checkUpdateEmailCampaignPermissions = (permissions: Permissions) =>
  permissions.operations.campaigns.email.update !==
  CampaignsPermissionLevel.NONE;

// INBOUND
export const checkCreateInboundCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.inbound.create !==
  ServicesPermissionLevel.NONE;

export const checkReadInboundCampaignPermissions = (permissions: Permissions) =>
  permissions.operations.campaigns.inbound.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateInboundCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.inbound.update !==
  CampaignsPermissionLevel.NONE;

// OUTBOUND
export const checkCreateOutboundCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.manual.create !==
  ServicesPermissionLevel.NONE;

export const checkReadCallOutboundCampaignPermissions = (
  permissions: Permissions
) =>
  checkReadOutboundCampaignPermissions(permissions) ||
  checkReadDialerbotCampaignPermissions(permissions);

export const checkReadOutboundCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.manual.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateOutboundCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.manual.update !==
  CampaignsPermissionLevel.NONE;

// VIDEOCALL
export const checkCreateVideocallCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.videocalls.create !==
  ServicesPermissionLevel.NONE;

export const checkReadVideocallCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.videocalls.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateVideocallCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.videocalls.update !==
  CampaignsPermissionLevel.NONE;

// TELEGRAM
export const checkCreateTelegramCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.telegram.create !==
  ServicesPermissionLevel.NONE;

export const checkReadTelegramCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.telegram.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateTelegramCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.telegram.update !==
  CampaignsPermissionLevel.NONE;

// INSTAGRAM
export const checkCreateInstagramCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.instagram.create !==
  ServicesPermissionLevel.NONE;

export const checkReadInstagramCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.instagram.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateInstagramCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.instagram.update !==
  CampaignsPermissionLevel.NONE;

// WEBCHAT
export const checkCreateWebChatCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.webChat.create !==
  ServicesPermissionLevel.NONE;

export const checkReadWebChatCampaignPermissions = (permissions: Permissions) =>
  permissions.operations.campaigns.webChat.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateWebChatCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.webChat.update !==
  CampaignsPermissionLevel.NONE;

// WHATSAPP
export const checkCreateWhatsAppCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.whatsApp.create !==
  ServicesPermissionLevel.NONE;

export const checkReadWhatsAppCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.whatsApp.read !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateWhatsAppCampaignPermissions = (
  permissions: Permissions
) =>
  permissions.operations.campaigns.whatsApp.update !==
  CampaignsPermissionLevel.NONE;

// CONVERSATIONS /////////////////////////////////////////////////////////////////////////////
export const checkViewConversationPermissions = (
  conversation: Conversation
) => {
  const userId = AuthManager.getUserId();
  if (conversation.assignedUserIds.some((id) => id === userId))
    return () => true;
  if (conversation?.channel === 'Call')
    return checkViewVoiceConversationPermissions;
  if (conversation?.channel === 'Email')
    return checkViewEmailConversationPermissions;
  // Chat
  return checkViewChatConversationPermissions;
};

export const checkViewConversationSummaryPermissions = (
  conversation: TicketConversationSummary
) => {
  if (conversation?.channel === 'Call')
    return checkViewVoiceConversationPermissions;
  if (conversation?.channel === 'Email')
    return checkViewEmailConversationPermissions;
  // Chat
  return checkViewChatConversationPermissions;
};

export const checkCloseConversationPermissions = (
  conversation: Conversation
) => {
  if (conversation?.channel === 'Call')
    return checkCloseVoiceConversationPermissions;
  if (conversation?.channel === 'Email')
    return checkCloseEmailConversationPermissions;
  // Chat
  return checkCloseChatConversationPermissions;
};

// CHAT
export const checkInteractChatConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.chat.interact !==
  CampaignsPermissionLevel.NONE;

export const checkCloseChatConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.chat.close !==
  CampaignsPermissionLevel.NONE;

export const checkViewChatConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.chat.view !==
  CampaignsPermissionLevel.NONE;

// VOICE
export const checkInterveneVoiceConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.voice.intervene !==
  CampaignsPermissionLevel.NONE;

export const checkViewVoiceConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.voice.view !==
  CampaignsPermissionLevel.NONE;

export const checkCloseVoiceConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.voice.close !==
  CampaignsPermissionLevel.NONE;

// EMAIL
export const checkInteractEmailConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.email.interact !==
  CampaignsPermissionLevel.NONE;

export const checkCloseEmailConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.email.close !==
  CampaignsPermissionLevel.NONE;

export const checkViewEmailConversationPermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.conversations.email.view !==
  CampaignsPermissionLevel.NONE;

// EMAIL SIGNATURES
export const checkReadEmailSignaturePermissions = (permissions: Permissions) =>
  permissions.omnichannel.related.emailSignatures.read;

export const checkUpdateEmailSignaturePermissions = (
  permissions: Permissions
) =>
  permissions.omnichannel.related.emailSignatures.update &&
  checkReadEmailSignaturePermissions(permissions);

export const checkCreateEmailSignaturePermissions = (
  permissions: Permissions
) => permissions.omnichannel.related.emailSignatures.create;

export const checkDeleteEmailSignaturePermissions = (
  permissions: Permissions
) => permissions.omnichannel.related.emailSignatures.delete;

// WEB CHAT STYLES
export const checkReadWebChatStylesPermissions = (permissions: Permissions) =>
  permissions.omnichannel.related.webchatStyles.read;

export const checkUpdateWebChatStylesPermissions = (permissions: Permissions) =>
  permissions.omnichannel.related.webchatStyles.update &&
  checkReadWebChatStylesPermissions(permissions);

export const checkCreateWebChatStylesPermissions = (permissions: Permissions) =>
  permissions.omnichannel.related.webchatStyles.create;

export const checkDeleteWebChatStylesPermissions = (permissions: Permissions) =>
  permissions.omnichannel.related.webchatStyles.delete;

// PRODUCTIVITY /////////////////////////////////////////////////////////////////////////////

// DASHBOARD
export const checkCreateDashboardPermissions = (permissions: Permissions) =>
  permissions.productivity.realtimeReporting.dashboard.create;

export const checkDeleteDashboardPermissions = (permissions: Permissions) =>
  permissions.productivity.realtimeReporting.dashboard.delete !==
  CampaignsPermissionLevel.NONE;

export const checkUpdateDashboardPermissions = (permissions: Permissions) =>
  checkReadDashboardPermissions(permissions) &&
  permissions.productivity.realtimeReporting.dashboard.update !==
    CampaignsPermissionLevel.NONE;

export const checkReadDashboardPermissions = (permissions: Permissions) =>
  permissions.productivity.realtimeReporting.dashboard.read !==
  CampaignsPermissionLevel.NONE;

// PANEL
export const checkCreatePanelPermissions = (permissions: Permissions) =>
  permissions.productivity.realtimeReporting.panel.create !==
  CampaignsPermissionLevel.NONE;

export const checkDeletePanelPermissions = (permissions: Permissions) =>
  permissions.productivity.realtimeReporting.panel.delete !==
  CampaignsPermissionLevel.NONE;

export const checkUpdatePanelPermissions = (permissions: Permissions) =>
  permissions.productivity.realtimeReporting.panel.update !==
  CampaignsPermissionLevel.NONE;

export const checkCreatePanelDashboardPermissions = (
  permissions: Permissions
) =>
  checkCreateDashboardPermissions(permissions) &&
  permissions.productivity.realtimeReporting.panel.create ===
    CampaignsPermissionLevel.ALL;
