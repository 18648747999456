import { RTCall } from 'src/models/rtcall';
import { setActiveRTCallIdStore } from '../../infraestructure/store/setActiveRTCallStore';

/** SET ACTIVE CALL
 *
 * For showing an specific parked call if there are no Talking calls
 *
 * @param call RTCallId to set
 */
export const setActiveRTCallId = (callId: string) => {
  //change the active call in the store
  setActiveRTCallIdStore(callId);
};
