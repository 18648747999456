import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { CallerIdData } from 'src/models/CallerIdData';
import { RTCall } from 'src/models/rtcall';
import {
  getDisplayNameFromCallerIdData,
  getNameFromCall
} from 'src/services/webrtc/domain/utils';
import { getContactNameQuery } from './getContactsQuery';
import { queryClient } from 'src/utils/queryClient';

export const getDisplayNameFromCallerIdDataQuery = (
  callerIdData: CallerIdData
): UseQueryResult<string> => {
  return useQuery<string, Error>(
    ['getDisplayNameFromCallerIdDataQuery', callerIdData],
    () => getDisplayNameFromCallerIdData(callerIdData),
    { enabled: true, staleTime: 60000 }
  );
};

export const useRTCallDisplayNameQuery = (call: RTCall) => {
  return useQuery(
    ['useRTCallDisplayNameQuery', call],
    () => getRTCallDisplayName(call),
    { enabled: true, staleTime: 60000 }
  );
};

export const useGetNameFromCallQuery = (
  phoneNumber: string,
  serviceId: string,
  enabled: boolean = true
) => {
  return useQuery(
    ['getNameFromCallQuery', phoneNumber, serviceId],
    () => getNameFromCall(phoneNumber, serviceId),
    { enabled, placeholderData: phoneNumber }
  );
};

export const getNameFromCallQueryNoHook = (
  phoneNumber: string,
  serviceId: string
) => {
  return queryClient.fetchQuery({
    queryKey: ['getNameFromCallQuery', phoneNumber, serviceId],
    queryFn: () => getNameFromCall(phoneNumber, serviceId),
    staleTime: 1000 * 60 * 5
  });
};

export async function getRTCallDisplayName(call: RTCall) {
  if (!call) return null;

  if (call.status === 'Conference') {
    return 'Conference';
  }

  let endpoint =
    call?.linkedEndpoint?.endpoint ?? call?.currentEndpoint?.endpoint;

  const isInternal = endpoint?.includes('-');

  if (!call?.contactId || isInternal) {
    const callName = await getNameFromCallQueryNoHook(
      endpoint,
      call?.serviceId
    );

    if (!callName) {
      return endpoint;
    }

    return callName;
  }

  const contactName = await getContactNameQuery(call.contactId);

  if (!contactName) {
    return endpoint;
  }

  return contactName;
}
