import { Stack } from "@mui/material"
import VolumeBox from "./VolumeBox"

interface VolumeMeterProps {
  input: number
}

// Hardcoded because of efficiency
const limitValues = [5, 10, 15, 20, 25, 30, 35, 40,
  45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]

/**
 * 
 * @param props Must pass the state of volume value (between 0 - 100)
 * @returns Your sensational volume meter
 */
const VolumeMeter: React.FC<VolumeMeterProps> = (props) => {

  return (
    <Stack direction='row' spacing={1}>
      {limitValues.map(limit => (
        <VolumeBox
          key={`volume-box${limit}-${props.input}`}
          input={props.input}
          limit={limit}
        />
      ))}


    </Stack>
  )
}

export default VolumeMeter