/** CREATE NEW KPI IBject
 *
 * Returns a kpi object
 * @param panel Panel Object
 * @param value Kpi Value
 * @returns {KPI} Kpi  Object
 */

import {
  KpiConfig,
  KpiObject,
  Panel,
  PanelCalculation
} from '../models/Dashboards';

export const createNewKpiObject = (
  panel: Panel,
  value: number | string
): KpiObject => {
  return {
    identifier: panel.name,
    value: getKpiValueByCalculation(value, panel.calculation),
    color: panel.kpiConfig.color,
    kpiIcon: panel.kpiConfig.kpiIcon,
    selectedIdentifier: panel.kpiConfig.selectedIdentifier
  };
};

export const getKpiValueByCalculation = (
  value: number | string,
  calculation: PanelCalculation
) => {
  if (typeof value === 'string') return value;
  if (calculation === 'Count') return parseInt(value.toFixed(2));
  if (calculation === 'Percentage' || calculation === 'Mean') {
    const valueFixed = value.toFixed(2).split('.');
    if (valueFixed[1] === '00') return parseInt(value.toFixed(2));
  }
  return value.toFixed(2);
};

/** GET BASE KPI OBJECT
 *
 * Returns a kpi object
 * @returns {KPI} Kpi  Object
 */

export const getBaseKpiObject = (kpiConfig: KpiConfig): KpiConfig => {
  return {
    color: kpiConfig !== null ? kpiConfig.color : 'green',
    kpiCalculation: kpiConfig !== null ? kpiConfig.kpiCalculation : 'Last',
    kpiIcon: kpiConfig !== null ? kpiConfig.kpiIcon : 'Speed',
    selectedIdentifier:
      kpiConfig !== null ? kpiConfig.selectedIdentifier : 'Abandoned'
  };
};
