import { WebChatStyle } from '../../models/webChatStyle';
import * as Yup from 'yup';
import { t } from 'i18next';

export const useWebChatStyle = () => {

  const sliderMarks = [
    {
      value: 1,
      label: '0%'
    },
    {
      value: 3,
      label: '100%'
    }
  ];

  const bubbleSizeSliderMarks = [
    {
      value: 1,
      label: '0%'
    },
    {
      value: 100,
      label: '100%'
    }
  ];

  const fontSliderMarks = [
    {
      value: 15,
      label: '15px'
    },
  {
    value: 25,
    label: '25px'
  }
  ]

  const fieldsWidthMarks = [
    {
      value: 150,
      label: '150px'
    },
  {
    value: 275,
    label: '275px'
  }
  ]

  const maxWidthMarks = [
    {
      value: 75,
      label: '75px'
    },
  {
    value: 275,
    label: '275px'
  }
  ]

  const marginMarks = [
    {
      value: 5,
      label: '5px'
    },
  {
    value: 25,
    label: '25px'
  }
  ]


    const defaultWebChatStyle = () => {
        return {
          name: '',
        description: '',
        bubbleStyle: {
          bubbleImage: '',
          bubbleColor1: {
           color: '#f66868',
          opacity: 100},
          bubbleColor2: {
            
            color:'#00ffff',
            opacity: 100
          },
          dropShadow: false,
          innerShadow: false,
          backgroundBlur: false,
          layerBlur: false,
          size: 60,
          uploadedImages: []
        },
        conversationStyle: {
          agentSpeechBubbleBGColor: {
             
            color:'#FFFFFF',
            opacity: 100
          },
          agentSpeechBubbleTextColor: {
             
            color:'#223354',
            opacity: 100
          },
          bubbleColor: {
             
            color:'#1975FF',
            opacity: 100
          },
          contactSpeechBubbleBGColor: {
            
            color:'#1975FF',
            opacity: 100
          },
          contactSpeechBubbleTextColor: {
            
            color:'#FFFFFF',
            opacity: 100
          },
          controlsColor: {
             
            color:'#1975FF',
            opacity: 100
          },
          convBackGroundColor: {
             
            color:'#F6F8FB',
            opacity: 100
          },
          fontSize: 17,
          font: 'Arial',
          mainContentColor: {
            color:'#223354',
            opacity: 100
          },
          margin: 7,
          maxWidth: 230,
          msgBoxBGColor: {
            color:'#FFFFFF',
            opacity: 100
          },
          msgBoxIconsColor: {
             
            color:'#1A75FF',
            opacity: 100
          },
          msgBoxTextColor: {
             
            color:'#223354',
            opacity: 100
          },
          scrollbarColor: {
             
            color:'#223354',
            opacity: 100
          },
          shadowBox: false,
          titleColor: {
             
            color:'#FFFFFF',
            opacity: 100
          },
          titleText: {
             
            color:'#1975FF',
            opacity: 100
          }
        },
        contactStyle: {
          dataPolicyText: {
            opacity: 100,
            color: "#1a75ff"
          },
          fieldBGColor: {
            color: "#ffffff",
            opacity: 100
          },
          fieldBorderColor: {
            color: "#223354",
            opacity: 100
          },
          fieldTextColor: {
            color: "#223354",
            opacity: 100
          },
          fieldWidth: 225,
          font: "Arial",
          logo: '',
          logoSize: 2,
          openChatBGColor: {
            color: "#1a75ff",
            opacity: 100
          },
          openChatText: {
            color: "#ffffff",
            opacity: 100
          },
          sendConvHistoryText: {
            color: "#1a75ff",
            opacity: 100
          },
          shadowBox: false,
          headerTextColor: {
            color: "#1975ff",
            opacity: 100
          }
        }
      } as WebChatStyle;
    };

    const schemaValidations = Yup.object().shape({
      name: Yup.string()
        .max(255)
        .required(t('The webchat style name  is required')),
      description: Yup.string(),
      conversationStyle: Yup.object().shape({
        contactSpeechBubbleBGColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        contactSpeechBubbleTextColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        agentSpeechBubbleBGColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        agentSpeechBubbleTextColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        scrollbarColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        convBackGroundColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        shadowBox: Yup.boolean(),
        fontSize: Yup.number(),
        margin: Yup.number(),
        maxWidth: Yup.number(),
        msgBoxBGColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        msgBoxTextColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        msgBoxIconsColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        })
      }),
      contactStyle: Yup.object().shape({
        font: Yup.string(),
        shadowBox: Yup.boolean(),
        logoSize: Yup.number(),
        logo: Yup.string(),
        dataPolicyText: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        sendConvHistoryText: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        headerTextColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        fieldBorderColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        fieldTextColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        fieldBGColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        fieldWidth: Yup.number(),
        openChatBGColor: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        openChatText: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        })
      }),
      bubbleStyle: Yup.object().shape({
        bubbleColor1: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        bubbleColor2: Yup.object().shape({
          color: Yup.string().matches(/^#[0-9A-F]{6}$/i,"Colors must be hexadecimal."),
          opacity: Yup.number()
        }),
        dropShadoW: Yup.boolean(),
        innerShadow: Yup.boolean(),
        backgroundBlur: Yup.boolean(),
        layerBlue: Yup.boolean(),
        size: Yup.number()
      }),
      }
        

    );
    
      return {
        bubbleSizeSliderMarks,
        defaultWebChatStyle,
        schemaValidations,
        sliderMarks,
        fontSliderMarks,
        fieldsWidthMarks,
        maxWidthMarks,
        marginMarks
      };
 
}

