import axios, { AxiosError, AxiosResponse } from 'axios';
import { ContactField } from '../../models/contacts';
import { CONTACT_FIELDS } from 'src/utils/apiRoutes/msContacts';

export const createContactFieldApi = async (contactField: ContactField) => {
  try {
    const response: AxiosResponse = await axios.post(
      CONTACT_FIELDS,
      contactField
    );
    return response.data;
  } catch (exception) {
    throw exception as AxiosError;
  }
};
