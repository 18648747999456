import { BASE_URL } from './base';

const BASIC_NLU_MS = `${BASE_URL}/ai-nlu/basic_nlu`;

// Entities models
const ENTITIES_MODEL_CONTROLLER = `${BASIC_NLU_MS}/entities-model`;

export const ENTITIES_MODEL = ENTITIES_MODEL_CONTROLLER;
export const ENTITIES_MODEL_ID = `${ENTITIES_MODEL_CONTROLLER}/{modelId}`;

// Intention models
const INTENTION_MODEL_CONTROLLER = `${BASIC_NLU_MS}/intention-model`;

export const INTENTION_MODEL = INTENTION_MODEL_CONTROLLER;
export const INTENTION_MODEL_ID = `${INTENTION_MODEL_CONTROLLER}/{id}`;
