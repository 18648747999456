import { setCameraDevice } from 'src/store/slices/users/configVolumeSlice';
import store from 'src/store/store';

/** SET CAMERA DEVICE IN STORE
 *
 * @param device New microphone
 */
export const setCameraDeviceStore = (device: MediaDeviceInfo) => {
  store.dispatch(setCameraDevice(device));
};
