//Function to set instamce id if needed base on Prod or Dev

const setInstanceID = (path: string, instanceID: string): string => {
	if (process.env.REACT_APP_BK === 'prod') return path + '/' + instanceID
	else return path
}

const CallHelper = {
	setInstanceID,
}


export default CallHelper
