import {
  getTicketAttachmentApi,
  getTicketByIdApi,
  getTicketsApi
} from '../infrastructure/getTicketsApi';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';
import { Conversation } from 'src/models/conversations/conversations';
import {
  Ticket,
  TicketAttachment,
  TicketAttachmentFile,
  TicketConversationSummary,
  TicketFieldsValues,
  TicketType,
  TicketUI
} from '../models/tickets';

export interface TicketsFilters {
  assignedUserIds?: string[];
  companyId: string;
  contactIds?: string[];
  fields?: TicketFieldsValues;
  friendlyId?: number;
  maxCreationDate?: string;
  minCreationDate?: string;
  page?: number;
  resolution?: TicketType;
  serviceId?: string;
  size?: number;
  sortDirection?: DirectionType;
  sortFields?: string[];
  subResolutionIds?: string[];
  tagIds?: string;
  title?: string;
}

export const getTickets = async (filters: TicketsFilters) => {
  const paginatedTickets = await getTicketsApi(filters);
  return paginatedTickets;
};

export const getTicketById = async (id: string) => {
  const ticket = await getTicketByIdApi(id);
  return ticket;
};

export const getTicketsUI = async (
  filters: TicketsFilters
): Promise<PaginatedElements<TicketUI>> => {
  const paginatedTickets = await getTicketsApi(filters);
  const ticketsUIPromises = paginatedTickets.elements.map((ticket) =>
    getTicketUI(ticket)
  );
  const ticketsUI = await Promise.all(ticketsUIPromises);
  return { ...paginatedTickets, elements: ticketsUI };
};

export const getTicketUIById = async (id: string) => {
  const ticket = await getTicketByIdApi(id);
  const ticketUI = await getTicketUI(ticket);
  return ticketUI;
};

export const getTicketUI = async (ticket: Ticket): Promise<TicketUI> => {
  return {
    ...ticket,
    idDetails: `${ticket.friendlyId} ${ticket?.title ? '-' : ''} ${
      ticket.title ?? ''
    }`,
    resolutionName: ticket.subResolution.resolution.name,
    creationDate: new Date(ticket.metadata.creationDate).toLocaleString(),
    modificationDate: new Date(
      ticket.metadata.modificationDate
    ).toLocaleString()
  };
};

export const getTicketConversationSummaryFrom = (
  conversation: Conversation
): TicketConversationSummary | null => {
  if (!conversation) return null;
  return {
    id: conversation?.id,
    channel: conversation?.channel,
    creationDate: conversation?.metadata?.creationDate,
    status: conversation?.status
  };
};

export const downloadTicketAttachment = async (params: {
  ticketId: string;
  ticketAttachment: TicketAttachment;
}): Promise<TicketAttachmentFile> => {
  const { ticketId, ticketAttachment } = params;
  const resp = await getTicketAttachmentApi({
    ticketId: ticketId,
    fileId: ticketAttachment.id,
    fileName: ticketAttachment.name
  });
  return { ticketId: ticketId, fileId: ticketAttachment?.id, file: resp };
};

export const downloadTicketAttachments = async (params: {
  ticketId: string;
  ticketAttachments: TicketAttachment[];
}) => {
  const { ticketId, ticketAttachments } = params;
  const attachmentPromises = ticketAttachments.map((attachment) =>
    downloadTicketAttachment({
      ticketId: ticketId,
      ticketAttachment: attachment
    })
  );
  const resp = await Promise.all(attachmentPromises);
  return resp;
};
