import { useEffect, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';

export interface BarState {
  positionX: number;
  setPositionX: (positionX: number) => void;
  positionY: number;
  setPositionY: (positionY: number) => void;
  isBarExtended: boolean;
  setIsBarExtended: (isBarExtended: boolean) => void;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  isPopoverOpened: boolean;
  setIsPopoverOpened: (isPopoverOpened: boolean) => void;
  isBarCompressed: boolean;
  setIsBarCompressed: (isBarCompressed: boolean) => void;
  isBarBlocked: boolean;
  setIsBarBlocked: (isBarBlocked: boolean) => void;
  keepBarExtended: boolean;
  setKeepBarExtended: (keepBarExtended: boolean) => void;
  lockButtonState: number;
  setLockButtonState: (lockButtonState: number) => void;
}
function useBarState(): BarState {
  const [positionX, setPositionX] = useState(300);
  const [positionY, setPositionY] = useState(window.innerHeight - 250);

  const [isBarExtended, setIsBarExtended] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [isBarCompressed, setIsBarCompressed] = useState(false);
  const [isBarBlocked, setIsBarBlocked] = useState(false); // If the bar is blocked, it can't be dragged
  const [keepBarExtended, setKeepBarExtended] = useState(false);

  // 0 = unlocked
  // 1 = Is extended but can be dragged
  // 2 = Is extended and can't be dragged
  const [lockButtonState, setLockButtonState] = useState(0);

  useEffect(() => {
    setIsBarBlocked(lockButtonState === 2);
    setKeepBarExtended(lockButtonState !== 0);
  }, [lockButtonState]);

  useCustomEventListener('Extend CTIBar', () => {
    setIsBarExtended(true);
  });

  return {
    positionX,
    setPositionX,
    positionY,
    setPositionY,
    isBarExtended,
    setIsBarExtended,
    isDragging,
    setIsDragging,
    isPopoverOpened,
    setIsPopoverOpened,
    isBarCompressed,
    setIsBarCompressed,
    isBarBlocked,
    setIsBarBlocked,
    keepBarExtended,
    setKeepBarExtended,
    lockButtonState,
    setLockButtonState
  };
}

export default useBarState;
