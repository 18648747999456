import {
  Avatar,
  Box,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { HelperTab } from '../../../HelperMenu/items';
import HelperPanelHeaderButtons from './HelperPanelHeaderButtons';
import { getHelperMenuItems } from '../../../utils/getInboxPanels';
import { useTranslation } from 'react-i18next';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
		background-color: ${theme.colors.secondary.lighter};
		color: ${theme.colors.secondary.main};
		width: ${theme.spacing(4)};
		height: ${theme.spacing(4)};
  `
);

type Props = {
  helperTab: HelperTab;
  showHelperTabButtons?: boolean;
};

const HelperPanelHeader = (props: Props) => {
  const { helperTab, showHelperTabButtons } = props;
  const theme = useTheme();
  const menuItems = getHelperMenuItems();
  const tab = menuItems?.[helperTab];
  const Icon = tab?.icon;
  const label = tab?.label;
  const { t } = useTranslation();
  return (
    <Box
      p={theme.spacing(2)}
      sx={{
        display: 'flex',
        background: theme.colors.alpha.white[100],
        borderBottom: `${theme.colors.alpha.black[10]} solid 1px`,
        alignItems: 'center',
        height: theme.header.height,
        justifyContent: 'space-between',
        gap: 1
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={theme.header.height}
      >
        <Stack
          display={'flex'}
          direction="row"
          alignItems={'center'}
          height={theme.header.height}
          width="100%"
        >
          <AvatarWrapper
            sx={{
              mr: 1.5
            }}
          >
            {Icon && <Icon />}
          </AvatarWrapper>

          <Typography
            variant="h4"
            align="justify"
            color="primary"
            textAlign="left"
            noWrap
          >
            {t(label)}
          </Typography>
        </Stack>
      </Box>
      {showHelperTabButtons && (
        <HelperPanelHeaderButtons helperTab={helperTab} />
      )}
    </Box>
  );
};

export default HelperPanelHeader;
