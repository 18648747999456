import axios from 'axios';
import { UploadTicketCsvParams } from '../models/tickets';
import { TICKETS_UPLOAD_CSV } from 'src/utils/apiRoutes/msMessaging';

export const uploadTicketCsvFileApi = async (
  uploadTicketCsvParams: UploadTicketCsvParams
) => {
  const { specialFieldMapping, file, fieldMapping, ...params } =
    uploadTicketCsvParams;
  const formData = new FormData();
  formData.append('specialFieldMapping', JSON.stringify(specialFieldMapping));
  formData.append('file', file);
  formData.append('fieldMapping', JSON.stringify(fieldMapping));
  await axios.post(TICKETS_UPLOAD_CSV, formData, { params });
};
