import { Box, Typography, useTheme, Link } from '@mui/material';
import { ChatMessage } from 'src/models/conversations/messages';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import { useEffect, useState } from 'react';
import { ChatImage } from './ChatImage';
import {
  formatAttachmentUrl,
  getBlobUrl,
  getStickerFileApi
} from 'src/modules/Shared/utils/attachments';
import StickerViewer from './StickerViewer';
import AudioPlayer from 'src/components/AudioPlayer';
import useId from '@mui/material/utils/useId';
import ChatMessageMap from './ChatMessageMap';
import { isChatMessage } from 'src/modules/VideoCall/utils/isVideoCallConversation';

interface Props {
  message: ChatMessage;
}

const ChatMessageCardContent = (props: Props) => {
  const { message } = props;
  const { deleted, type } = message;

  const uploadedFile = message.content?.uploadedFile;

  const audioPlayerId = useId();
  const theme = useTheme();

  const [blobUrl, setBlobUrl] = useState(null);
  const [stickerData, setStickerData] = useState(null);

  const hasAttachment = type !== 'Text' && type !== 'Location';
  const url = hasAttachment
    ? formatAttachmentUrl(uploadedFile, message.channel)
    : null;
  const imgExtensions = ['.png', '.jpg', '.jpeg'];
  const isImage =
    type === 'Image' ||
    imgExtensions.some((ext) => uploadedFile?.name?.endsWith(ext));

  useEffect(() => {
    if (url && isChatMessage(message)) {
      let isMounted = true;

      getStickerFileApi(url).then((response) => {
        if (isMounted) {
          setStickerData(response);
        }
      });
      getBlobUrl(url).then((response) => {
        if (isMounted) {
          setBlobUrl(response);
        }
      });
      return () => {
        isMounted = false;
      };
    }
  }, [url, message]);

  const isTgsSticker = uploadedFile?.name.split('.')[1] === 'tgs';

  return (
    <Box>
      {!deleted && type === 'Sticker' && isTgsSticker && (
        <StickerViewer stickerUrl={stickerData} />
      )}
      {!deleted && type === 'Sticker' && !isTgsSticker && (
        <img src={blobUrl} style={{ maxHeight: '300px', maxWidth: '300px' }} />
      )}
      {/*!deleted && type === 'Animation' && (
        <video
          autoPlay
          loop
          muted
          src={blobUrl}
          style={{ maxHeight: '300px', maxWidth: '300px' }}
        />
      )*/}
      {!deleted && type === 'Location' && <ChatMessageMap message={message} />}
      {!deleted && blobUrl && type === 'Audio' && (
        <AudioPlayer
          id={audioPlayerId}
          src={blobUrl}
          containerWidth="24rem"
          inline
          // paperize
          waveColor={theme.colors.secondary.lighter}
          progressColor={theme.colors.secondary.main}
          cursorColor={theme.colors.secondary.main}
          display="waveform"
          playPauseIconButtonProps={{
            sx: {
              color: theme.colors.secondary.main,
              '&:hover': {
                color: 'white',
                backgroundColor: theme.colors.secondary.main
              }
            }
          }}
        />
      )}

      {hasAttachment && (
        <>
          {!deleted && isImage && (
            <ChatImage message={message} blob={blobUrl} />
          )}
          <Link
            href={blobUrl}
            download={uploadedFile?.name}
            target="_blank"
            color="inherit"
            sx={{ cursor: 'pointer' }}
          >
            {!deleted && (type === 'Document' || type === 'Video') && (
              <Box
                display="flex"
                sx={{
                  backgroundColor: theme.colors.alpha.black[10],
                  borderRadius: theme.general.borderRadiusXl,
                  padding: 1
                }}
              >
                <InsertDriveFileIcon sx={{ color: 'white' }} />
                <Box marginX={1}>
                  <Typography>{uploadedFile.name}</Typography>
                  <Typography color="inherit">
                    {uploadedFile.sizeKb} KB
                  </Typography>
                </Box>
                <Box alignSelf="center" padding={0.5}>
                  <ArrowCircleDownOutlinedIcon color="inherit" />
                </Box>
              </Box>
            )}
          </Link>
        </>
      )}
    </Box>
  );
};

export default ChatMessageCardContent;
