import { BASE_URL } from './base';

// USERS MICROSERVICE
export const USERS_MS = `${BASE_URL}/users`;

// Users
const USERS_CONTROLLER = `${USERS_MS}/users`;
export const USERS = USERS_CONTROLLER;
export const USERS_LIST = `${USERS_CONTROLLER}/list`;
export const USERS_ID = `${USERS_CONTROLLER}/{id}`;

// RTUsers
const RTUSERS_CONTROLLER = `${USERS_MS}/rtusers`;
export const RTUSERS = RTUSERS_CONTROLLER;
export const RTUSERS_CHANGE_STATUS = `${RTUSERS_CONTROLLER}/{id}/change-status`;
export const RTUSERS_RESET = `${RTUSERS_CONTROLLER}/{id}/reset`;

// Companies
const COMPANIES_CONTROLLER = `${USERS_MS}/companies`;
export const COMPANIES = COMPANIES_CONTROLLER;
export const COMPANIES_ENABLE = `${COMPANIES_CONTROLLER}/{id}/enable`;
export const COMPANIES_ID = `${COMPANIES_CONTROLLER}/{id}`;
export const COMPANIES_STATUS = `${COMPANIES_CONTROLLER}/{id}/status`;

// Campaigns
const CAMPAIGNS_CONTROLLER = `${USERS_MS}/campaigns`;
export const CAMPAIGNS = CAMPAIGNS_CONTROLLER;
export const CAMPAIGNS_ID = `${CAMPAIGNS_CONTROLLER}/{id}`;
export const CAMPAIGNS_ID_ENABLE = `${CAMPAIGNS_CONTROLLER}/enable/{id}/{enable}`;

// Robinson list
const ROBINSON_LIST_CONTROLLER = `${USERS_MS}/robinson-list`;
export const ROBINSON_LIST = ROBINSON_LIST_CONTROLLER;
export const ROBINSON_LIST_GET_NUMBERS = `${ROBINSON_LIST_CONTROLLER}/get-numbers`;
export const ROBINSON_LIST_ID = `${ROBINSON_LIST_CONTROLLER}/{id}`;
export const ROBINSON_LIST_NUMBER = `${ROBINSON_LIST_CONTROLLER}/number`;
export const ROBINSON_LIST_NUMBER_ID = `${ROBINSON_LIST_CONTROLLER}/number/{id}`;
export const ROBINSON_LIST_NUMBERS = `${ROBINSON_LIST_CONTROLLER}/numbers`;
export const ROBINSON_LIST_REMOVE_CSV = `${ROBINSON_LIST_CONTROLLER}/remove-csv`;
export const ROBINSON_LIST_UPLOAD_CSV = `${ROBINSON_LIST_CONTROLLER}/upload-csv`;
export const ROBINSON_LIST_CLONE = `${ROBINSON_LIST_CONTROLLER}/clone`;
