import {
  useTheme,
  Stack,
  Button,
  LinearProgress,
  IconButton,
  Box
} from '@mui/material';
import { useState, useEffect } from 'react';
import { CallEndOutlined } from '@mui/icons-material';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { getCallerIdDataFromSession } from 'src/services/webrtc/domain/utils';
import { useGetContactQuery } from 'src/services/socialmedia/application/query/getContactsQuery';

import {
  ContactNames,
  getAllContactNames
} from 'src/services/socialmedia/domain/conversation/utils';

import { t } from 'i18next';
import { getRTUser } from 'src/services/rtusers/domain/rtuser/getRTUser';
import { useCampaignsQuery } from 'src/services/users/application/query/getUsersDataQueries';

interface ItemProps {
  session: WebRTCSession;
  hangupCall: (session: WebRTCSession) => void;
  answerCall: (session: WebRTCSession) => void;
  myKey: number;
}

const LinearProgressWrapper = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, 290);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LinearProgress
      color="secondary"
      sx={{ width: '420px' }}
      variant="determinate"
      value={progress}
    />
  );
};

export const Item: React.FC<ItemProps> = ({
  session,
  answerCall,
  hangupCall,
  myKey
}) => {
  const [contactNames, setContactNames] = useState<ContactNames>({});

  const theme = useTheme();

  let callerIdData = getCallerIdDataFromSession(session);

  let contactQuery = useGetContactQuery(callerIdData.contactId);
  const contact = contactQuery.data;

  const campaignsQuery = useCampaignsQuery({});
  const campaignData =
    callerIdData.campaignId &&
    campaignsQuery?.data &&
    campaignsQuery?.data?.elements?.find(
      (c) => c.id === callerIdData.campaignId
    );

  useEffect(() => {
    if (callerIdData.internal) {
      const rtUser = getRTUser({
        complexExtension: callerIdData.phoneNumber,
        shouldUseHook: false
      });
      if (rtUser) setContactNames({ [t('Username')]: rtUser.username });
      else setContactNames({ ['Extension']: callerIdData.phoneNumber });
    } else {
      if (contact) getAllContactNames(contact).then(setContactNames);
      else setContactNames({ [t('Phone number')]: callerIdData.phoneNumber });
    }
  }, [contact]);

  // Array of name components
  const nameComponents = [];

  // Add from if present
  if (callerIdData.conference) {
    nameComponents.push(
      <p key={callerIdData.from} style={{ color: '#000000', margin: '0px' }}>
        ({t('Conference')})
      </p>
    );
  }

  // Add from if present
  if (callerIdData.from) {
    const user = getRTUser({
      shouldUseHook: false,
      complexExtension: callerIdData.from
    })?.username;
    nameComponents.push(
      <p key={callerIdData.from} style={{ color: '#000000', margin: '0px' }}>
        {t('Transfer from')}: {user ?? callerIdData.from}
      </p>
    );
  }

  if (callerIdData.transfer && callerIdData.from.length === 0) {
    const user = getRTUser({
      shouldUseHook: false,
      complexExtension: callerIdData.phoneNumber
    })?.username;
    nameComponents.push(
      <p key={callerIdData.from} style={{ color: '#000000', margin: '0px' }}>
        {t('Attended transfer from')}: {user ?? callerIdData.phoneNumber}
      </p>
    );
  }

  // Add all contact name fields
  for (const fieldName in contactNames) {
    nameComponents.push(
      <p key={fieldName} style={{ color: '#000000', margin: '0px' }}>
        {t(fieldName)}: {contactNames[fieldName]}
      </p>
    );
  }

  return (
    <Stack
      direction={'column'}
      marginY={1}
      spacing={0.5}
      key={myKey}
      style={{ marginTop: '0px' }}
    >
      <Stack direction="row" justifyContent="center" alignItems={'center'}>
        <Box width="100%">
          <Stack alignItems="center" justifyContent={'center'} flexGrow={1}>
            {nameComponents}

            {campaignData && (
              <p style={{ color: '#9099A9', margin: '0px' }}>
                {t('Campaign')}: {campaignData.name}
              </p>
            )}
          </Stack>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="space-between" paddingX={2}>
        <Button
          id="answer-call-btn"
          sx={{
            padding: '0px 15px',
            boxShadow: `${theme.colors.shadows.success}`,
            background: `${theme.colors.success.main}`,
            color: `${theme.palette.getContrastText(
              theme.colors.primary.main
            )}`,

            '&:hover': {
              background: `${theme.colors.success.dark}`,
              color: `${theme.palette.getContrastText(
                theme.colors.primary.main
              )}`
            },
            borderRadius: '5px'
          }}
          onClick={(): void => answerCall(session)}
        >
          AnswerCall
        </Button>
        <IconButton
          id="hangup-call-btn"
          sx={{
            boxShadow: `${theme.colors.shadows.error}`,
            background: `${theme.colors.error.main}`,
            color: 'white',

            '&:hover': {
              background: `${theme.colors.error.dark}`,
              color: `${theme.palette.getContrastText(
                theme.colors.primary.main
              )}`
            },
            borderRadius: '5px'
          }}
          color="primary"
          size="small"
          onClick={(): void => hangupCall(session)}
        >
          <CallEndOutlined fontSize="medium" />
        </IconButton>
      </Stack>
      <Stack width="100%" alignItems="center">
        <LinearProgressWrapper />
      </Stack>
    </Stack>
  );
};
