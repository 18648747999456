import {
  ReportingAttachment,
  ReportingAttachmentDetails
} from '../models/reportingAttachment';
import { getFiltersValuesFromDataCategory } from '../views/utils/getFilterValuesFromDataCategory';

export const getReportingAttachmentDetailsListText = async (
  reportingAttachment: ReportingAttachment
) => {
  if (!reportingAttachment?.details) return '';
  const textPromises = reportingAttachment.details?.map((details) =>
    getReportingAttachmentDetailsText(details, reportingAttachment.companyId)
  );
  const arrayText = await Promise.all(textPromises);
  return arrayText.join('; ');
};

export const getReportingAttachmentDetailsText = async (
  reportingAttachmentDetails: ReportingAttachmentDetails,
  companyId?: string
) => {
  const values = await getFiltersValuesFromDataCategory(
    reportingAttachmentDetails.value,
    reportingAttachmentDetails.dataCategory,
    companyId
  );
  const text = `${reportingAttachmentDetails.field} ${reportingAttachmentDetails.operator} ${values}`;
  return text;
};
