import { DialTableFilter } from '../models/filters';
import { SPLITTER } from './getFiltersFromUrlSearchParams';

export const getUrlSearchParamsFromDialTableFilters = <T>(
  selectedFilters: DialTableFilter<T>[]
) => {
  const params = new URLSearchParams();
  selectedFilters.forEach((filter) => {
    params.append(
      filter.fieldName as string,
      encodeURIComponent(
        `${filter.operator}${SPLITTER}${filter.values.join(SPLITTER)}`
      )
    );
  });
  return params;
};
