import { invalidateContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import { updateTagApi } from '../infraestructure/api/updateTagApi';
import { ContactTag } from '../models/contacts';

export const updateTag = async (
  id: string,
  name: string
): Promise<ContactTag> => {
  const tag = await updateTagApi(id, name);
  invalidateContactTagsQuery();
  return tag;
};
