import { Slider, Stack, Typography } from '@mui/material';
import React from 'react';
import { ColorPicker } from '../../../../components/Style/ColorPicker';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../../models/webChatStyle';
import { FormikProps } from 'formik';
import { useWebChatStyle } from '../../../../utils/useWebChatStyle';

interface ContactFieldsAccordionProps {
  formikProps: FormikProps<WebChatStyle>;
}

const ContactFieldsAccordion: React.FC<ContactFieldsAccordionProps> = ({
  formikProps
}) => {
  const { fieldsWidthMarks } = useWebChatStyle();

  return (
    <>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Border')}
            color={formikProps.values.contactStyle.fieldBorderColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'contactStyle.fieldBorderColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Text')}
            color={formikProps.values.contactStyle.fieldTextColor.color}
            setColor={(c) => {
              formikProps.setFieldValue('contactStyle.fieldTextColor.color', c);
            }}
          />
        </Stack>
        <Stack paddingRight="40px">
          <ColorPicker
            title={t('Background')}
            color={formikProps.values.contactStyle.fieldBGColor.color}
            setColor={(c) => {
              formikProps.setFieldValue('contactStyle.fieldBGColor.color', c);
            }}
          />
        </Stack>
      </Stack>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={10}>
        <Stack paddingX="40px" minWidth="30%">
          <Typography variant="subtitle1">{t('Fields width')}</Typography>
          <Slider
            color="secondary"
            min={150}
            max={275}
            marks={fieldsWidthMarks}
            valueLabelDisplay="auto"
            value={formikProps.values.contactStyle.fieldWidth}
            onChange={(event, value) => {
              formikProps.setFieldValue('contactStyle.fieldWidth', value);
            }}
            id="switchSlider-StyleModifiersFontSize"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ContactFieldsAccordion;
