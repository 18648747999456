import { Box, Typography, useTheme, CardMedia, Stack } from '@mui/material';
import { ChatMessage } from 'src/models/conversations/messages';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useEffect, useState } from 'react';
import {
  formatAttachmentUrl,
  getBlobUrl,
  getStickerFileApi
} from 'src/modules/Shared/utils/attachments';
import StickerViewer from './StickerViewer';
import AudioPlayer from 'src/components/AudioPlayer';
import useId from '@mui/material/utils/useId';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { isChatMessage } from 'src/modules/VideoCall/utils/isVideoCallConversation';

interface Props {
  message: ChatMessage;
}

const ChatReplyToFile = (props: Props) => {
  const { message } = props;
  const { deleted, type } = message;

  const uploadedFile = message.content?.uploadedFile;

  const audioPlayerId = useId();
  const theme = useTheme();

  const [blobUrl, setBlobUrl] = useState(null);
  const [stickerData, setStickerData] = useState(null);

  const hasAttachment = type !== 'Text' && type !== 'Location';
  const url = hasAttachment
    ? formatAttachmentUrl(uploadedFile, message.channel)
    : null;
  const imgExtensions = ['.png', '.jpg', '.jpeg'];
  const isImage =
    type === 'Image' ||
    imgExtensions.some((ext) => uploadedFile?.name?.endsWith(ext));

  useEffect(() => {
    if (url && isChatMessage(message)) {
      let isMounted = true;

      getStickerFileApi(url).then((response) => {
        if (isMounted) {
          setStickerData(response);
        }
      });
      getBlobUrl(url).then((response) => {
        if (isMounted) {
          setBlobUrl(response);
        }
      });
      return () => {
        isMounted = false;
      };
    }
  }, [url, message]);

  const isTgsSticker = uploadedFile?.name.split('.')[1] === 'tgs';

  return (
    <Box>
      {!deleted && type === 'Sticker' && isTgsSticker && (
        <StickerViewer stickerUrl={stickerData} />
      )}
      {!deleted && type === 'Sticker' && !isTgsSticker && (
        <img src={blobUrl} style={{ maxHeight: '300px', maxWidth: '300px' }} />
      )}
      {/*!deleted && type === 'Animation' && (
        <video
          autoPlay
          loop
          muted
          src={blobUrl}
          style={{ maxHeight: '300px', maxWidth: '300px' }}
        />
      )*/}
      {!deleted && blobUrl && type === 'Audio' && (
        <AudioPlayer
          id={audioPlayerId}
          src={blobUrl}
          containerWidth="24rem"
          inline
          // paperize
          waveColor={theme.colors.secondary.lighter}
          progressColor={theme.colors.secondary.main}
          cursorColor={theme.colors.secondary.main}
          display="waveform"
          playPauseIconButtonProps={{
            sx: {
              color: theme.colors.secondary.main,
              '&:hover': {
                color: 'white',
                backgroundColor: theme.colors.secondary.main
              }
            }
          }}
        />
      )}

      {hasAttachment && (
        <>
          {!deleted && isImage && (
            <Box
              sx={{
                height: '45px',
                width: '45px',
                overflow: 'hidden'
              }}
            >
              <CardMedia
                component="img"
                src={blobUrl}
                alt={uploadedFile.name}
                sx={{
                  minWidth: '100%',
                  minHeight: '100%',
                  flexShrink: '0',
                  borderRadius: theme.general.borderRadiusSm
                }}
              />
            </Box>
          )}
          {!deleted && type === 'Document' && (
            <Stack
              sx={{
                padding: 0.5
              }}
              direction="row"
            >
              <InsertDriveFileIcon sx={{ color: 'white' }} fontSize="small" />
              <Box marginX={0.5}>
                <Typography sx={{ wordBreak: 'break-word' }}>
                  {uploadedFile.name.slice(0, 40)}
                </Typography>
              </Box>
            </Stack>
          )}
          {!deleted && type === 'Video' && (
            <Box
              sx={{
                height: '45px',
                width: '45px',
                overflow: 'hidden'
              }}
            >
              <VideoCameraBackIcon sx={{ width: '100%', height: '100%' }} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ChatReplyToFile;
