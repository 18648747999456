import { createContext, useState } from 'react';

type ConversationsContext = {
  textSearch: string;
  setTextSearch: React.Dispatch<React.SetStateAction<string>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConversationsContext = createContext<ConversationsContext>(
  {} as ConversationsContext
);

export const ConversationsProvider = ({ children }) => {
  const [textSearch, setTextSearch] = useState<string>('');
  return (
    <ConversationsContext.Provider
      value={{
        textSearch,
        setTextSearch
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};
