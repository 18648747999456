import { Card, Popover, Stack, TextField, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRef, useState } from 'react';
import { RGBColor, SketchPicker } from 'react-color';

interface Props {
  title?: string;
  color: string;
  setColor: (color: string) => void;
  size?: 'small' | 'medium';
  hideTextField?: boolean;
  disableAlpha?: boolean;
  colorRGB?: RGBColor;
  setColorRGB?: (color: RGBColor) => void;
  disabled?: boolean;
}

export const ColorPicker: React.FC<Props> = ({
  color,
  setColor,
  title,
  size,
  hideTextField,
  disableAlpha,
  colorRGB,
  setColorRGB,
  disabled = false
}) => {
  const [editColor, setEditColor] = useState(false);
  const ref = useRef(null);

  return (
    <>
      <Popover
        open={editColor}
        onClose={() => {
          setEditColor(false);
        }}
        anchorEl={ref.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <SketchPicker
          color={color ?? colorRGB}
          onChangeComplete={(color) => {
            if (disableAlpha) {
              setColorRGB(color.rgb);
            }
            setColor(color.hex);
          }}
        />
      </Popover>
      <Stack direction="row" alignItems={'center'} spacing={1}>
        {!disabled && (
          <Card
            ref={ref}
            onClick={() => {
              setEditColor(!editColor);
            }}
            sx={{
              height: '30px',
              width: '30px',
              background: colorRGB ? alpha(color, colorRGB.a) : color
            }}
          />
        )}
        {disabled && (
          <Card
            ref={ref}
            sx={{
              height: '30px',
              width: '30px',
              background: 'grey'
            }}
          />
        )}
        {!hideTextField && (
          <TextField
            disabled={disabled}
            size={size ?? 'medium'}
            label={title}
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
            sx={{ flexGrow: 1 }}
          />
        )}
      </Stack>
    </>
  );
};
