import dayjs, { Dayjs } from 'dayjs';
import {
  AllDailyHours,
  Holidays,
  WeekTimes
} from 'src/modules/DialerBot/models/dialerbot';
import { TimeRanges } from 'src/models/timeRanges';
import { daysWeek } from './constants';
import {
  convertDateToTimeZone,
  convertDateToUtcMoment,
  convertUtcToTimeZone,
  convertUtcToTimezoneMoment
} from 'src/modules/Shared/utils/convertDateToTimeZone';
import moment from 'moment-timezone';


export const convertToScheduleData = (hoursWeek: TimeRanges[]) => {
  let newObj = {};
  let daysEnabled = [];

  let excludesAllDays = ['All Days', 'Monday to Friday'];
  let excludesWeekDays = [...excludesAllDays, 'Saturday', 'Sunday'];
  let indexAllDays = hoursWeek.findIndex(
    (element) => element.day === 'All Days'
  );

  let indexAllWeek = hoursWeek.findIndex(
    (element) => element.day === 'Monday to Friday'
  );

  //SI selecciono todos los dias
  if (hoursWeek[indexAllDays].enabled) {
    //creating keys
    hoursWeek.map((element) => {
      // Checking are not all and monday to friday
      if (!excludesAllDays.includes(element.day)) {
        newObj[element.day.toUpperCase()] = {
          startHour: hoursWeek[indexAllDays].startHour,
          endHour: hoursWeek[indexAllDays].endHour,
          startMinute: hoursWeek[indexAllDays].startMinute,
          endMinute: hoursWeek[indexAllDays].endMinute,
          enabled: true
        };
      }
    });
    return newObj;
  }
  // SI selecciono toda la semana
  if (hoursWeek[indexAllWeek].enabled && !hoursWeek[indexAllDays].enabled) {
    hoursWeek.map((element) => {
      if (!excludesWeekDays.includes(element.day)) {
        newObj[element.day.toUpperCase()] = {
          startHour: hoursWeek[indexAllWeek].startHour,
          endHour: hoursWeek[indexAllWeek].endHour,
          startMinute: hoursWeek[indexAllWeek].startMinute,
          endMinute: hoursWeek[indexAllWeek].endMinute,
          enabled: true
        };
      }
    });

    return newObj;
  }

  //Si selecciono algunos dias
  hoursWeek.map((element) => {
    if (element.enabled) {
      newObj[element.day.toUpperCase()] = {
        startHour: element.startHour,
        endHour: element.endHour,
        startMinute: element.startMinute,
        endMinute: element.endMinute,
        enabled: true
      };
    }
  });
  return newObj;
};

export const convertToFormDaysData = (memoryDays) => {
  if (memoryDays !== undefined) {
    let newsDayWeek = [];
    daysWeek.map((days, index) => {
      if (days.day.toUpperCase() in memoryDays) {
        let newDay = {
          day: days.day,
          enabled: true,
          startHour: memoryDays[days.day.toUpperCase()].startHour,
          endHour: memoryDays[days.day.toUpperCase()].endHour,
          startMinute: memoryDays[days.day.toUpperCase()].startMinute,
          endMinute: memoryDays[days.day.toUpperCase()].endMinute
        };
        newsDayWeek.push(newDay);
      } else {
        let newDay = {
          day: days.day,
          enabled: false,
          startHour: 6,
          endHour: 18,
          startMinute: 0,
          endMinute: 0
        };
        newsDayWeek.push(newDay);
      }
    });
    return newsDayWeek;
  }

  return [];
};
export const convertToHolidayData = (holidays: Dayjs[]) => {
  let newObj = [];
  holidays.map((holiday) => {
    newObj.push({
      month: new Date(holiday.toString())
        .toLocaleString('en-GB', {
          month: 'long'
        })
        .toUpperCase(),
      day: new Date(holiday.toString()).getDate()
    });
  });
  return newObj;
};

export const convertToDayjs = (holidays: Holidays[]) => {
  const days = holidays.map((holiday) => {
    return dayjs(
      new Date().getFullYear().toString() +
        '-' +
        holiday.month +
        '-' +
        holiday.day
    );
  });

  return days;
};

/**Convert dayHours object to Timezone
 * @param days dayHours object in utc
 * @returns dayHours object in timezone
 **/
export const convertDaysWeekUTCToTimezone = (days: WeekTimes) => {
  const newObject = {};
  for (const day in days) {
    const convertedTimesArray = days[day].map((dy) => {
      //Create dummy date for start and end date
      const startDate = createDummyDate(dy.startHour, dy.startMinute);
      const endDate = createDummyDate(dy.endHour, dy.endMinute);
      //Converted start and end date to timezone date
      const convertedStartDate = convertUtcToTimeZone(startDate);
      const convertedEndDate = convertUtcToTimeZone(endDate);
      //Create day Object
      return {
        startHour: convertedStartDate.getHours(),
        endHour: convertedEndDate.getHours(),
        startMinute: convertedStartDate.getMinutes(),
        endMinute: convertedEndDate.getMinutes(),
        enabled: dy.enabled
      };
    });
    newObject[day] = convertedTimesArray;
  }
  return newObject;
};

/**Convert dayHours object to Timezone
 * @param days dayHours object in timezone
 * @returns dayHours object in utc
 **/
export const convertTimezoneToDaysWeekUTC = (days: WeekTimes) => {
  const newObject = {};
  for (const day in days) {
    const convertedTimesArray = days[day].map((dy) => {
      //Create dummy date for start and end date
      const startDate = createDummyDate(dy.startHour, dy.startMinute);
      const endDate = createDummyDate(dy.endHour, dy.endMinute);
      //Converted start and end date to timezone date
      const convertedStartDate = convertDateToUtcMoment(startDate);
      const convertedEndDate = convertDateToUtcMoment(endDate);
      //Create day Object
      return {
        startHour: convertedStartDate.hour(),
        endHour: convertedEndDate.hour(),
        startMinute: convertedStartDate.minute(),
        endMinute: convertedEndDate.minute(),
        enabled: dy.enabled
      };
    });
    newObject[day] = convertedTimesArray;
  }
  return newObject;
};

/**Returns a dummy date with hour and number
 * @param  hour hour to add
 * @param minute minute to add
 * @returns Dummy Date
 **/
export const createDummyDate = (hour: number, minute: number) => {
  const dummyDate = new Date();
  dummyDate.setHours(hour);
  dummyDate.setMinutes(minute);

  return dummyDate;
};
