import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadedSchedulesProps } from 'src/modules/DialerBot/models/dialerbot';

interface DialerbotState {
  docShcedules: UploadedSchedulesProps[];
}

const initialState = {
  docShcedules: []
} as DialerbotState;

export const dialerbotSlice = createSlice({
  name: 'dialerbotSlice',
  initialState,
  reducers: {
    setDialerbotSchedules: (
      state,
      action: PayloadAction<UploadedSchedulesProps[]>
    ) => {
      state.docShcedules = [...action.payload];
    },
    deleteDialerbotSchedules: (state, action: PayloadAction<string>) => {
      state.docShcedules = state.docShcedules.filter(
        (item) => item.id !== action.payload
      );
    }
  }
});

export const { setDialerbotSchedules, deleteDialerbotSchedules } =
  dialerbotSlice.actions;
export default dialerbotSlice.reducer;
