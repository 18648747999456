import { queryClient } from 'src/utils/queryClient';
import DialerBotDomain from '../../domain/dialerbotDomain';

export const getDialerBotSchedulesQuery = (params?: {
  campaignId?: string;
  scheduleDatabaseId?: string;
  page?: number;
  size?: number;
}) => {
  return queryClient.fetchQuery(
    ['schedules', params],
    () =>
      DialerBotDomain.getDialerBotSchedules(
        params?.campaignId,
        params?.scheduleDatabaseId,
        params?.page,
        params?.size
      ),
    { staleTime: 30 * 1000 }
  );
};
