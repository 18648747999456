import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { KpiDataType } from '../infrastructure/websocket/websocket';
import { ApexOptions } from 'apexcharts';
import { InboundChannelSelections } from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/models/acdRoutes';

export interface Dashboard {
  id?: string;
  name: string;
  description: string;
  companyId: string;
  groupIntervalSeconds: number;
  panelSizeSeconds: number;
  campaignIds?: string[];
  panelGroups?: PanelsGroup[];
  serviceId?: string;
  hidden?: boolean;
  presetFixedId?: null;
  pastTime?: boolean;
}
export interface GroupsPanels {
  [label: string]: string[];
}

export interface PanelsGroup {
  name: string;
  description: string;
  pos: number;
  panelIds: string[];
  collapsed: boolean;
}
export interface DashboardPanelParams {
  panelId: string;
}

//panels
export type CalculationType = 'Percentage' | 'Count' | 'Mean';
export type PanelType = 'Realtime' | 'Group';
export type kpiCalculation = 'Last' | 'Percentage' | 'Both';

export enum kpiCalculationEnum {
  Last = 'Last',
  Percentage = 'Percentage',
  Both = 'Both'
}

export enum panelTypeEnum {
  Realtime = 'Realtime',
  Group = 'Group'
}

export enum PanelCalculationEnum {
  COUNT = 'Count',
  MEAN = 'Mean',
  PERCENTAGE = 'Percentage'
}

export type DataType =
  | 'WaitingTime'
  | 'AbandonendCalls'
  | 'AttendedCalls'
  | 'AvailableAgents'
  | 'DisconnectedAgents'
  | 'InCalls';
export type ChartType =
  | 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'treemap'
  | 'boxPlot'
  | 'candlestick'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'KPI'
  | 'Coordinator';

type BoundType = 'Lower' | 'Upper';
type AlertMsgType = 'Email' | 'Notification' | 'Sound';
export type PanelCalculation = 'Count' | 'Mean' | 'Percentage';

type AlertSeverity = 'Danger' | 'Ok' | 'Warning';

export type selectedIdentifier =
  | 'Abandoned'
  | 'Answeringmachine'
  | 'Assigned'
  | 'Attended'
  | 'Autoattended'
  | 'Available'
  | 'Busy'
  | 'CallDuration'
  | 'Canceled'
  | 'Circuit'
  | 'Closed'
  | 'Congestion'
  | 'Failed'
  | 'Forwarded'
  | 'InCall'
  | 'InRoute'
  | 'Incoming'
  | 'Internal'
  | 'Invalid'
  | 'Missed'
  | 'Opened'
  | 'Outgoing'
  | 'Overflow'
  | 'Parked'
  | 'Preparing'
  | 'Queued'
  | 'Rejected'
  | 'Requested'
  | 'Ringing'
  | 'Robinson'
  | 'RouteHangup'
  | 'Talking'
  | 'Transferred'
  | 'Unanswered'
  | 'Unknown'
  | 'WaitingTime';

export interface GroupInterval {
  time: number;
  timeUnit: string;
}
interface AlertMsg {
  message: string;
  msgtype: AlertMsgType;
}
export interface PanelAlert {
  bound: number;
  boundtype: BoundType;
  message: AlertMsg;
  severity: AlertSeverity;
}
export interface Alert {
  alerts: PanelAlert[];
}
export interface PanelParameters {
  coordX: number;
  coordY: number;
  height: number;
  id?: string;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  width: number;
  chartType?: ChartType;
  legendPosition?: LegendPosition;
}

export enum LegendPosition {
  legend = 'legend',
  tableBelow = 'tableBelow',
  tableRight = 'tableRight'
}

export interface KpiConfig {
  color: string;
  kpiCalculation?: kpiCalculation;
  kpiIcon: KpiDataType;
  selectedIdentifier: string;
}

export interface KpiObject extends KpiConfig {
  identifier: string;
  value?: number | string;
}
export interface GroupInterval {
  time: number;
  timeUnit: string;
}
export interface Panel {
  campaignId: string;
  calculation: PanelCalculation;
  companyId: string;
  datatype: string;
  serviceId: string;
  id?: string;
  name: string;
  panelAlert?: Alert;
  panelParameters: PanelParameters;
  dashboardId: string;
  paneltype: PanelType;
  accountIds: InboundChannelSelections;
  kpiConfig?: KpiConfig;
}

export interface PanelSizeRestrictions {
  [type: string]: {
    panelDefaultWidth: number;
    panelDefaultHeight: number;
    minPanelWidth: number;
    minPanelHeight: number;
  };
}

type SubscriptionSubType = 'Subscription' | 'Unsubscription';
type ElementType = 'Panel' | 'Dashboard';
export interface SubscriptionMessage {
  type: string;
  subType: SubscriptionSubType;
  elementType: ElementType;
  id: string;
  timestamp: string;
}

export interface ChartObject {
  name: string;
  type?: string;
  data: DataSeries[];
}

export interface DataSeries {
  x: string;
  y: number;
}

export type IconType = 'error' | 'warning' | 'primary' | 'success';
export interface KpiIcon {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  type: IconType;
}

export interface GridLayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  static?: boolean;
  isResizable?: boolean;
  legendPosition?: LegendPosition;
}

export interface GridLayoutGroup {
  [key: string]: GridLayout[];
}

export interface PanelsConstant {
  [name: string]: PanelConstantData;
}

export interface PanelsGroupsBoundaries {
  [key: string]: PanelsGroupBoundary;
}

export interface PanelsGroupBoundary {
  startY: number;
  endY: number;
}
interface PanelConstantData {
  key: string;
  label: string;
  panelDefaultSizes: PanelSizes;
  iconsArray?: KpiIcon[];
  baseChartOptions?: ApexOptions;
  defaultChart?: ChartType;
  dataTypeCalculation?: DataTypeCalculation;
  defaultInstance?: string;
  maxNumberForNonAveraging?: number;
  missingDataText: string;
  notApplicable?: string;
}

interface DataTypeCalculation {
  [dataType: string]: kpiCalculation;
}
interface PanelSizes {
  panelDefaultWidth: number;
  panelDefaultHeight: number;
  panelDefaultYposition: number;
  minPanelWidth: number;
  minPanelHeight: number;
  maxPanelWidth: number;
}

export interface PanelsGroupsConstans {
  defaults: PanelsGroupsDefaults;
}

interface PanelsGroupsDefaults {
  xPosition: number;
  width: number;
  height: number;
  panelsGroupsPrefix: string;
  noGroupPanelName: string;
}

export interface PanelsGroupInLayout {
  start: number;
  end: number;
  panelIds: string[];
  name: string;
}

export interface HiddenPanelsGroup {
  name: string;
  panelIds: GridLayout[];
  position: number;
}

export enum DashboardEvents {
  NEW_MESSAGE = 'New Dashboard Message',
  INITIAL_DATA = 'Initial data ready',
  PANELS_REFRESH = 'refreshPanels',
  RESET_CHART = 'resetGroupDataSeries'
}

export interface EntitiesPermissions {
  userId: string;
  userCompanyId: string;
  companyIds: string[];
  serviceIds: string[];
  campaignIds: string[];
}

export type DashboardData = Record<string, PanelData[]>;
export type DashboardInstantData = Record<string, PanelData>;

export type PanelData = {
  elements: Record<string, number>;
  instant: string;
  kpiData: number;
  total: number;
};
