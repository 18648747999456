import { Close, LaunchOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, IconButton, Stack } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import { t } from 'i18next';
import { Conversation } from 'src/models/conversations/conversations';
import { selectConversation } from 'src/modules/Dashboard/views/Inbox/domain/handleConversations';
import { useNavigate } from 'react-router-dom';
import { playConversationAssignedSound } from 'src/services/webrtc/notifications/webrtc-notifications';

interface Props {
  onClose: (key: string) => void;
  conversation: Conversation;
}

export const NewConversationSnackbarContent = forwardRef<HTMLDivElement, Props>(
  ({ conversation, onClose }, ref) => {
    const navigate = useNavigate();

    useEffect(() => {
      playConversationAssignedSound();
    }, []);

    return (
      <>
        <Alert
          ref={ref}
          severity="info"
          color={'success'}
          action={
            <Stack direction="row" spacing={0.5}>
              <IconButton
                size="small"
                title={t('View summary')}
                onClick={() => {
                  selectConversation(conversation);
                  navigate('/dashboard/inbox');
                  onClose(conversation.id);
                }}
                sx={{ borderRadius: 2 }}
              >
                <LaunchOutlined fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                title={t('Close')}
                onClick={(e) => {
                  onClose(conversation.id);
                }}
                sx={{ borderRadius: 2 }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Stack>
          }
        >
          <AlertTitle>
            {t(`New assigned ${conversation.channel} conversation`)}
          </AlertTitle>
        </Alert>
      </>
    );
  }
);
