import { useEffect, useState } from 'react';
import { Ticket } from '../../../../models/tickets';
import { useShowflowsQuery } from '../../../../domain/showflow/getShowflowsQuery';

function useTicketShowflows(ticket: Ticket) {
  const ticketShowflowsQuery = useShowflowsQuery({
    serviceIds: [ticket.serviceId],
    companyId: ticket.companyId
  });
  const ticketShowflows = ticketShowflowsQuery?.data ?? [];
  const [selectedShowflowId, setSelectedShowflowId] = useState('');
  useEffect(() => {
    if (ticketShowflows.length === 1 && selectedShowflowId === '') {
      setSelectedShowflowId(ticketShowflows[0].id);
    }
  }, [ticketShowflows]);
  const selectedShowflow = ticketShowflows?.find(
    (showflow) => showflow.id === selectedShowflowId
  );

  return {
    ticketShowflows,
    selectedShowflow,
    setSelectedShowflowId,
    selectedShowflowId
  };
}

export default useTicketShowflows;
