import axios from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { RTCall } from 'src/models/rtcall';
import {
  ASTERISK_MS,
  CALLS_DIRECT_TRANSFER
} from 'src/utils/apiRoutes/msAsterisk';

export interface TransferCallProps {
  call: RTCall;
  extension: string;
  isInternal: boolean;
  campaignId?: string;
  instanceId?: string;
}

export const transferCallApi = async ({
  call,
  extension,
  isInternal,
  campaignId,
  instanceId
}: TransferCallProps) => {
  const transferCallPath =
    CallHelper.setInstanceID(ASTERISK_MS, instanceId) + CALLS_DIRECT_TRANSFER;
  const extensionFrom = getConnectedRTUser(false)?.extension?.complexExtension;
  const path = replaceURLParams(transferCallPath, {
    '{id}': call.linkedEndpoint?.rtCallId
  });
  const params = {
    extension,
    extensionFrom,
    campaignId,
    isInternal
  };

  const response = await axios.post(path, null, { params });

  return response.data;
};
