import axios from 'axios';
import { WhatspAppAccount } from 'src/services/whatsapp/models/WhatsAppAccount';
import { replaceURLParams } from 'src/services/utils';
import {
  WHATSAPP_ACCOUNT,
  WHATSAPP_ACCOUNT_ID
} from 'src/utils/apiRoutes/msWhatsapp';

export interface WhatsAppAccountsParams {
  companyId?: string;
  enabled?: boolean;
  id?: string;
  phoneNumber?: string;
  userId?: string;
  userName?: string;
}

export const addWhatsAppAccountApi = async (
  whatsAppAccount: WhatspAppAccount
) => {
  const resp = await axios.post(WHATSAPP_ACCOUNT, whatsAppAccount);
  return resp.data;
};
