import axios from 'axios';
import { WebChatAccount } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatAccount';
import { WEBCHAT_ACCOUNT } from 'src/utils/apiRoutes/msWebChat';

export const createWebChatAccountApi = async (
  webChatAccount: WebChatAccount
): Promise<WebChatAccount> => {
  const response = await axios.post<WebChatAccount>(
    WEBCHAT_ACCOUNT,
    webChatAccount
  );
  return response.data;
};
