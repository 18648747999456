import { DialerBotCampaign } from 'src/modules/DialerBot/models/dialerbot';
import {
  createDialerBotCampaigns,
  updateDialerBotCampaigns
} from '../infrastructure/api/dialerBotCampaigns';
import { ChannelType } from 'src/models/conversations/conversations';

/** CREATE A  DIALERBOTS
 *
 * Create a dialerbot
 */
export const createDialerBot = (
  dialerbot: DialerBotCampaign
): Promise<DialerBotCampaign> => {
  delete dialerbot.id;
  dialerbot.channelAccountId = null;
  dialerbot.callsLaunchInterval = 0;
  dialerbot.channel = ChannelType.CALL;
  dialerbot.sendKafkaEvent = true;
  // dialerbot.callMode = 'DialerBot';
  return createDialerBotCampaigns(dialerbot);
};

/** UPDATE A  DIALERBOTS
 *
 * Start the schedules for one or multiples dialerbot
 */
export const updateDialerBot = (
  dialerbot: DialerBotCampaign
): Promise<DialerBotCampaign> => {
  // dialerbot.callMode = 'DialerBot';
  dialerbot.channel = ChannelType.CALL;
  return updateDialerBotCampaigns(dialerbot);
};
