import { updateTicketSubResolutionApi } from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/subResolutions/updateTicketSubResolutionsApi';
import { TicketSubResolution } from '../../models/tickets';
import { queryClient } from 'src/utils/queryClient';

export const updateTicketSubResolution = async (
  ticketSubResolution: TicketSubResolution
) => {
  const resolutionId = ticketSubResolution.resolution?.id;
  const resp = await updateTicketSubResolutionApi({
    ...ticketSubResolution,
    resolutionId
  });
  return resp;
};

export const updateTicketSubResolutions = async (
  ticketSubResolutions: TicketSubResolution[]
) => {
  const subResolutionPromises = ticketSubResolutions.map(
    updateTicketSubResolution
  );
  const subResolutions = await Promise.all(subResolutionPromises);
  queryClient.invalidateQueries({
    queryKey: ['ticketSubResolutions']
  });
  return subResolutions;
};
