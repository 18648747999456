import store from 'src/store/store';
import { PanelData } from '../../models/Dashboards';
import {
  addDashboardData,
  setDashboardInitialData,
  setPanelInitialData,
  setTotalPeriod,
  setUpdateInterval
} from 'src/store/slices/dashboards/dashboardsSlice';

export function addDashboardDataToStore(data: Record<string, PanelData>) {
  store.dispatch(addDashboardData(data));
}

export function setDashboardInitialDataStore(
  data: Record<string, PanelData[]>
) {
  store.dispatch(setDashboardInitialData(data));
}

export function setPanelInitialDataStore(panelId: string, data: PanelData[]) {
  store.dispatch(setPanelInitialData({ panelId, data }));
}

export function setDashboardUpdateIntervalStore(interval: number) {
  store.dispatch(setUpdateInterval(interval));
}

export function setDashboardTotalPeriodStore(period: number) {
  store.dispatch(setTotalPeriod(period));
}
