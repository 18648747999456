import axios from 'axios';
import { ChatMessageType } from 'src/models/conversations/messages';
import { INSTAGRAM_SEND_NEW_FILE_MESSAGE } from 'src/utils/apiRoutes/msInstagram';

export interface SendInstagramNewMessageFileApiParams {
  campaignId: string;
  username: string;
  type: ChatMessageType;
  file: File;
  ticketId?: string;
}

export const sendInstagramNewMessageFileApi = async (
  params: SendInstagramNewMessageFileApiParams
) => {
  const { file, ...restParams } = params;
  const formData = new FormData();
  formData.append('file', file);
  await axios.post(INSTAGRAM_SEND_NEW_FILE_MESSAGE, formData, {
    params: restParams
  });
};
