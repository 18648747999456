import { emitCustomEvent } from 'react-custom-events';
import {
  CustomEventNames,
  WSMessage
} from 'src/services/websocket/utils/notifications/dataMsgs';
import { UploadCsvResponse } from '../views/components/contacts/UploadCSVContacts/models/models';

export interface ContactEvent extends WSMessage {
  resolution: UploadCsvResponse;
}

export interface CSVUploadEvent extends WSMessage {
  subtype: CSVUploadEventSubType;
  resolution: UploadCsvResponse;
}

export enum CSVUploadEventSubType {
  Contact = 'Contact',
  RobinsonList = 'RobinsonList'
}

const onContactEvent = (event: ContactEvent) => {
  //This event occurs when a user uploads a CSV file with contacts
  emitCustomEvent(CustomEventNames.CSV_UPLOADED, event.resolution);
};

export const onCSVUploadEvent = (event: CSVUploadEvent) => {
  switch (event.subtype) {
    case CSVUploadEventSubType.Contact:
      emitCustomEvent(CustomEventNames.CSV_UPLOADED, event.resolution);
      break;
    case CSVUploadEventSubType.RobinsonList:
      break;
    default:
      break;
  }
};

export default onContactEvent;
