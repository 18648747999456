import { EmailMessage } from 'src/models/conversations/messages';
import useEmailParser from '../../../../hooks/useEmailParser';
import './style.css';
import { Skeleton, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useRef } from 'react';

interface Props {
  message: EmailMessage;
  isMaximized: boolean;
}

function EmailMessageRenderer({ message, isMaximized }: Props) {
  const { htmlMessage } = useEmailParser({ message });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Open links on new tab
    if (ref.current) {
      const links = ref.current.querySelectorAll('a');
      links.forEach((link) => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [htmlMessage]);

  if (!isMaximized) return null;

  if (htmlMessage === undefined)
    return (
      <Stack pb={1.5}>
        <Skeleton variant="text" width={'25%'} height={20} />
        <Skeleton variant="text" width={'50%'} height={20} />

        <Skeleton
          variant="rectangular"
          width="100%"
          height={25}
          sx={{ marginTop: 1, borderRadius: 0.5 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={50}
          sx={{ marginTop: 1, borderRadius: 0.5 }}
        />
      </Stack>
    );

  return (
    <>
      <div
        ref={ref}
        style={{ padding: '0 0 13.5px 0', position: 'relative' }}
        className="email-message"
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      ></div>
    </>
  );
}

export default EmailMessageRenderer;
