import { Card, CircularProgress, IconButton, Stack } from '@mui/material';
import { CustomFilterValueOptions, DialTableFilter } from '../models/filters';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import { DialTableFilterInput } from './DialTableFilterInput';
import { OnChangeFn } from '@tanstack/react-table';

interface Props<T> {
  availableFilters: DialTableFilter<T>[];
  selectedFilters: DialTableFilter<T>[];
  onChangeSelectedFilters: OnChangeFn<DialTableFilter<T>[]>;
  onApplyFilters: (selectedFilters: DialTableFilter<T>[]) => void;
  customFilterValueOptions?: CustomFilterValueOptions;
  onlyEqualsOperator?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const DialTableFilters = <T extends Object>(props: Props<T>) => {
  const {
    availableFilters,
    selectedFilters,
    onChangeSelectedFilters,
    onApplyFilters: onClickApplyFiltersProps,
    customFilterValueOptions,
    onlyEqualsOperator,
    disabled,
    loading
  } = props;

  const onApplyFilters = () => {
    onClickApplyFiltersProps?.(selectedFilters);
  };

  const { t }: { t: any } = useTranslation();

  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Stack width="100%" direction="row" spacing={0.75}>
        <DialTableFilterInput
          availableFilters={availableFilters}
          selectedFilters={selectedFilters}
          onChange={(value) => {
            onChangeSelectedFilters(value);
          }}
          onEnterKeyDown={onClickApplyFiltersProps}
          customFilterValueOptions={customFilterValueOptions}
          onlyEqualsOperator={onlyEqualsOperator}
        />
        <Stack py={0.5} justifyContent="end" spacing={1}>
          <IconButton
            size="small"
            color="secondary"
            title={t('Apply filters')}
            disabled={disabled}
            onClick={onApplyFilters}
          >
            {loading ? (
              <CircularProgress color="secondary" size="1rem" />
            ) : (
              <Search />
            )}
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};
