import { Metadata } from 'src/modules/Admin/modules/Acd/modules/MOH/model/MusicOnHold';
import { ConversationMessage } from './messages';

export const CONVERSATIONS_PAGE_SIZE = 25;
export const CHANNEL_CONVERSATIONS_PAGE_SIZE = 10;

export type OrderEmail = 'asc' | 'desc';

export enum ChannelType {
  CALL = 'Call',
  EMAIL = 'Email',
  TELEGRAM = 'Telegram',
  WHATSAPP = 'WhatsApp',
  WEBCHAT = 'WebChat',
  INSTAGRAM = 'Instagram'
}

export const ALL_CHANNEL_TYPES = Object.values(ChannelType);

//Missed is not valid in the backend, its used to manage the missed calls in the frontend
export enum ConversationStatus {
  ASSIGNED = 'Assigned',
  CLOSED = 'Closed',
  QUEUED = 'Queued',
  MISSED = 'Missed',
  ROUTECLOSED = 'RouteClosed',
  INROUTE = 'InRoute'
}

export const ConversationStatusNames: {
  key: ConversationStatus;
  name: string;
}[] = [
  { key: ConversationStatus.ASSIGNED, name: 'Opened' },
  { key: ConversationStatus.CLOSED, name: 'Closed' }
];

export interface Conversation {
  accountId: string;
  assignedUserIds: string[];
  campaignId: string;
  channel: ChannelType;
  companyId: string;
  id: string;
  involvedContacts: ConversationContact[];
  lastInteractionTime: string;
  lastMessage: ConversationMessage;
  metadata: Metadata;
  originAgent: SocialmediaOriginAgent;
  serviceId: string;
  status: ConversationStatus;
  unreadIncomingMessages: boolean;
  // This props are managed in the frontend
  messages?: ConversationMessage[];
  totalMessages?: number;
  variables: Record<string, string>;
  channelConvId?: string;
  orientation?: ConversationOrientation;
}

export enum ConversationOrientation {
  OUTGOING = 'Outgoing',
  INCOMING = 'Incoming'
}

export interface ConversationTableColumns extends Conversation {
  campaignName: string;
  campaignType: string;
  serviceName: string;
  companyName: string;
  creationDate: string;
  modificationDate: string;
  creationHour: string;
  modificationHour: string;
  overallDuration: string;
  agentName: string;
}

export interface ConversationContact {
  contactId?: string;
  socialmediaAgent: SocialmediaOriginAgent;
}

export interface SocialmediaOriginAgent {
  callId?: string;
  channel: ChannelType;
  chatId?: string;
  closedByContact?: boolean;
  ddi?: string;
  emailAddress?: string;
  endpoint?: string;
  firstName?: string;
  groupName?: string;
  instagramId?: string;
  lastName?: string;
  members?: string[];
  orientation?: string;
  phoneNumber?: string;
  type?: 'User' | 'Group' | 'Dummy';
  username?: string;
  userName?: string;
  webChatConvId?: string;
  webChatForm?: { name: string; value: string }[];
}

export interface ConversationNote {
  contactId: string;
  content: string;
  conversationInfo: ConversationInfo;
  id: string;
  metadata: Metadata;
  title: string;
}

export interface ConversationInfo {
  channel: ChannelType;
  conversationId: string;
  creationDate: string;
}

export interface Note {
  content: string;
  title: string;
}

export interface UserConversation {
  elements: Conversation[];
  id: string;
}

export interface UsersConversationCount {
  [userId: string]: Record<ChannelType, number>;
}
