import { Slider, Stack, Switch, Typography } from '@mui/material';
import { StyleAccordion } from '../../../components/Style/StyleAccordion';
import { ColorPicker } from '../../../components/Style/ColorPicker';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../models/webChatStyle';
import { FormikProps } from 'formik';
import { useWebChatStyle } from '../../../utils/useWebChatStyle';

interface StyleModifiersProps {
  formikProps: FormikProps<WebChatStyle>;
  disabled?: boolean;
}

export const StyleModifiers: React.FC<StyleModifiersProps> = ({
  formikProps,
  disabled = false
}) => {
  const { bubbleSizeSliderMarks } = useWebChatStyle();

  return (
    <Stack direction="column" width="100%" py={1} overflow="auto">
      <StyleAccordion title="Color">
        <Typography variant="subtitle1">{t('Customize color')}:</Typography>
        <Stack
          spacing={{ xs: 2, md: 2 }}
          direction={{ md: 'column', lg: 'row' }}
        >
          <ColorPicker
            disabled={disabled}
            title={'Color 1'}
            color={formikProps.values.bubbleStyle.bubbleColor1.color}
            setColor={(color) => {
              formikProps.setFieldValue(
                'bubbleStyle.bubbleColor1.color',
                color
              );
            }}
          />
          <ColorPicker
            disabled={disabled}
            title={'Color 2'}
            color={formikProps.values.bubbleStyle.bubbleColor2.color}
            setColor={(color) => {
              formikProps.setFieldValue(
                'bubbleStyle.bubbleColor2.color',
                color
              );
            }}
          />
        </Stack>
      </StyleAccordion>
      <StyleAccordion title={t('Effects')}>
        <Typography variant="subtitle1">{t('Effects')}:</Typography>
        <Stack
          direction={{ lg: 'column', xl: 'row' }}
          flexWrap={'wrap'}
          justifyContent={'space-between '}
          width="100%"
        >
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'start'}
            width="50%"
          >
            <Switch
              disabled={disabled}
              color="secondary"
              checked={formikProps.values.bubbleStyle.dropShadow}
              onChange={(e, value) => {
                formikProps.setFieldValue('bubbleStyle.dropShadow', value);
              }}
              id="switchDrop-StyleModifiers"
            ></Switch>
            <Typography
              style={{ margin: 0, fontSize: 16, whiteSpace: 'nowrap' }}
              variant="body1"
            >
              {t('Drop shadow')}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems="center" width="50%">
            <Switch
              disabled={disabled}
              color="secondary"
              checked={formikProps.values.bubbleStyle.innerShadow}
              onChange={(e, value) => {
                formikProps.setFieldValue('bubbleStyle.innerShadow', value);
              }}
              id="switcInner-StyleModifiers"
            ></Switch>
            <Typography
              style={{ margin: 0, fontSize: 16, whiteSpace: 'nowrap' }}
              variant="body1"
            >
              {t('Inner shadow')}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems="center" width="50%">
            <Switch
              disabled={disabled}
              color="secondary"
              checked={formikProps.values.bubbleStyle.backgroundBlur}
              onChange={(e, value) => {
                formikProps.setFieldValue('bubbleStyle.backgroundBlur', value);
              }}
              id="switchBlur-StyleModifiers"
            ></Switch>
            <Typography
              style={{ margin: 0, fontSize: 16, whiteSpace: 'nowrap' }}
              variant="body1"
            >
              {t('Background blur')}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems="center" width="50%">
            <Switch
              disabled={disabled}
              color="secondary"
              checked={formikProps.values.bubbleStyle.layerBlur}
              onChange={(e, value) => {
                formikProps.setFieldValue('bubbleStyle.layerBlur', value);
              }}
              id="switchLayerBlur-StyleModifiers"
            ></Switch>
            <Typography
              style={{ margin: 0, fontSize: 16, whiteSpace: 'nowrap' }}
              variant="body1"
            >
              {t('Layer blur')}
            </Typography>
          </Stack>
        </Stack>
      </StyleAccordion>
      <StyleAccordion title={t('Size')}>
        <Typography variant="subtitle1">{t('Size')}:</Typography>
        <Slider
          disabled={disabled}
          color="secondary"
          min={0}
          max={100}
          valueLabelDisplay="auto"
          marks={bubbleSizeSliderMarks}
          value={formikProps.values.bubbleStyle.size}
          onChange={(event, value) => {
            formikProps.setFieldValue('bubbleStyle.size', value);
          }}
          id="switchSlider-StyleModifiers"
        ></Slider>
      </StyleAccordion>
    </Stack>
  );
};
