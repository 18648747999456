import {
  addTicketComment,
  addTicketToConversation,
  assignAgentsToTicket,
  assignAgentToTicket,
  attachTicketFile,
  attachTicketFiles,
  copyTicket,
  createTicket
} from './createTickets';

import {
  deleteTicket,
  deleteTickets,
  removeAgentFromTicket,
  removeTicketAttachment,
  removeTicketFromConversation,
  removeTicketAttachments
} from './deleteTickets';

import {
  downloadTicketAttachment,
  downloadTicketAttachments,
  getTicketById,
  getTicketConversationSummaryFrom,
  getTickets,
  getTicketsUI,
  getTicketUIById
} from './getTickets';

import {
  editTicketComment,
  udpateAgentsAssignedToTicket,
  updateTicket
} from './updateTickets';

import { checkTicketContactLimit } from './checkTicketContactLimit';
import { shouldPerformFirstManualReschedule } from './shouldPerformFirstManualReschedule';

import {
  copyTicketResolution,
  createTicketResolution,
  deleteTicketResolution,
  deleteTicketResolutions,
  getTicketResolutions,
  updateTicketResolution
} from './resolutions';

import {
  createTicketSubResolution,
  createTicketSubResolutions,
  deleteTicketSubResolution,
  deleteTicketSubResolutions,
  getTicketSubResolutionById,
  getTicketSubResolutions,
  getTicketSubResolutionsUI,
  getTicketSubResolutionUI,
  updateTicketSubResolution,
  updateTicketSubResolutions
} from './subResolutions';

import {
  createTicketField,
  deleteTicketField,
  deleteTicketFields,
  getTicketFieldById,
  getTicketFields,
  getTicketFieldsQuery,
  updateTicketField,
  updateTicketFields,
  useTicketFieldsQuery
} from './fields';

import {
  getTicketTags,
  getTicketTagById,
  getTicketTagsQuery,
  useTicketTagsQuery,
  updateTicketTag,
  deleteTicketTag,
  createTicketTag,
  deleteTicketTags
} from './tags';

import {
  getReportingTicketFilterFields,
  getReportingTickets,
  deleteReportingTickets
  // getReportingTicketsUI
} from './reporting';
import { generateReportingTicket } from './reporting/generateReportingTicket';
import { checkIsOpenTicket } from './checkIsOpenTicket';
import { uploadTicketCsvFile } from './uploadTicketCsvFile';

export const TicketsDomain = {
  addTicketComment,
  addTicketToConversation,
  assignAgentsToTicket,
  assignAgentToTicket,
  attachTicketFile,
  attachTicketFiles,
  checkIsOpenTicket,
  checkTicketContactLimit,
  copyTicket,
  copyTicketResolution,
  createTicket,
  createTicketField,
  createTicketResolution,
  createTicketSubResolution,
  createTicketSubResolutions,
  createTicketTag,
  deleteReportingTickets,
  deleteTicket,
  deleteTicketField,
  deleteTicketFields,
  deleteTicketResolution,
  deleteTicketResolutions,
  deleteTickets,
  deleteTicketSubResolution,
  deleteTicketSubResolutions,
  deleteTicketTag,
  deleteTicketTags,
  downloadTicketAttachment,
  downloadTicketAttachments,
  editTicketComment,
  generateReportingTicket,
  getReportingTickets,
  getTicketById,
  getTicketConversationSummaryFrom,
  getTicketFieldById,
  getReportingTicketFilterFields,
  getTicketFields,
  getTicketFieldsQuery,
  getTicketResolutions,
  getTickets,
  getTicketSubResolutionById,
  getTicketSubResolutions,
  getTicketSubResolutionsUI,
  getTicketSubResolutionUI,
  getTicketsUI,
  getTicketTagById,
  getTicketTags,
  getTicketTagsQuery,
  getTicketUIById,
  removeAgentFromTicket,
  removeTicketAttachment,
  removeTicketAttachments,
  removeTicketFromConversation,
  shouldPerformFirstManualReschedule,
  udpateAgentsAssignedToTicket,
  updateTicket,
  updateTicketField,
  updateTicketFields,
  updateTicketResolution,
  updateTicketSubResolution,
  updateTicketSubResolutions,
  updateTicketTag,
  uploadTicketCsvFile,
  useTicketFieldsQuery,
  useTicketTagsQuery
};
