import axios from 'axios';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  CONVERSATIONS_BY_USER,
  CONVERSATIONS_BY_USER_UNGROUPED
} from 'src/utils/apiRoutes/msConversations';

interface ConversationsByUserFilters {
  assignedUserIds: string[];
  channel: ChannelType;
  userLimit?: number; // Default 5
}

export interface ConversationsByUserResponse {
  conversations: {
    [key: string]: Conversation;
  };
  userConvIds: {
    [convId: string]: string[];
  };
}

export async function getConversationsByUserApi(
  filters: ConversationsByUserFilters
) {
  const path = CONVERSATIONS_BY_USER;

  const { assignedUserIds, ...params } = filters;

  const resp = await axios.post(path, assignedUserIds, {
    params
  });

  return resp.data as ConversationsByUserResponse;
}

interface ConversationsByUserUngroupedFilters {
  assignedUserIds: string[];
  channels: ChannelType[];
  userLimit?: number; // Default 5
}

export async function getConversationsByUserUngroupedApi(
  filters: ConversationsByUserUngroupedFilters
) {
  const path = CONVERSATIONS_BY_USER_UNGROUPED;

  const { assignedUserIds, ...params } = filters;

  const resp = await axios.post(path, assignedUserIds, {
    params,
    paramsSerializer: { indexes: null }
  });

  return resp.data as ConversationsByUserResponse;
}
