import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AIModel, IntentionModelFilters } from '../../models/AIModels';
import { IntentionsModelsLogic } from '../../domain/intentionModelsLogic';
import { queryClient } from 'src/utils/queryClient';

export const useIntentionsModelsDataQuery = (
  params?: IntentionModelFilters
): UseQueryResult<AIModel[]> => {
  return useQuery<AIModel[], Error>(['intentions-models'], () =>
    IntentionsModelsLogic.getIntentionsModels(params)
  );
};

export const getIntentionsModelsDataQuery = async (
  params?: IntentionModelFilters
) => {
  const intentionsModels = queryClient.fetchQuery({
    queryKey: ['intentions-models', ...Object.values(params)],
    queryFn: () => IntentionsModelsLogic.getIntentionsModels(params),
    staleTime: 1000 * 60 * 5
  });

  return intentionsModels;
};

export function invalidateIntentionsModelsDataQuery() {
  queryClient.invalidateQueries({
    queryKey: ['intentions-models']
  });
}

export const useGetIntentionModelDataQuery = async (
  id: string
): Promise<AIModel> => {
  const intentionModel = await IntentionsModelsLogic.getIntentionModel(id);
  return intentionModel;
};
