import { Avatar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import { getContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';

function randomColor() {
  const color =
    'rgb(' +
    Math.round(Math.random() * 255) +
    ',' +
    Math.round(Math.random() * 255) +
    ',' +
    Math.round(Math.random() * 255) +
    ')';

  return color;
}

function stringAvatar(name: string) {
  if (!name) {
    return { children: '' };
  }
  return {
    children: `${name?.split(' ')[0][0]}` || ''
  };
}

interface AvatarAccordionPropTypes {
  contact: Contact;
}

const AvatarAccordion: React.FC<AvatarAccordionPropTypes> = ({ contact }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [fields, setFields] = useState([] as ContactField[]);

  useEffect(() => {
    let isMounted = true;
    getContactFieldsQuery({ companyId: contact?.companyId }).then(
      (res: any) => {
        if (isMounted) {
          setFields(res);
        }
      }
    );
    return () => {
      isMounted = false;
    };
  }, []);

  const nameField = fields?.find?.((f) => {
    return f.type === 'Name';
  })?.name;

  const handleOpen = () => {
    setOpen(!isOpen);
  };
  return (
    <>
      {isOpen && (
        <Typography
          sx={{
            pl: '5px',
            mr: '25px'
          }}
          color="text.primary"
          variant="h5"
        >
          {contact?.fields[nameField]}
        </Typography>
      )}
      <Avatar
        {...stringAvatar(contact?.fields[nameField])}
        sx={{
          width: 35,
          height: 35,
          backgroundColor: randomColor()
        }}
        onClick={handleOpen}
        key={contact?.id + 'avatar'}
      />
    </>
  );
};

export default AvatarAccordion;
