import axios from 'axios';
import { TextContent } from 'src/models/conversations/ChatMessages/TextMessages';
import { TELEGRAM_MESSAGE_NEW_TEXT } from 'src/utils/apiRoutes/msTelegram';

export interface SendTelegramMessageNewTextApiParams {
  campaignId: string;
  content: TextContent;
  phoneNumber?: string;
  username?: string;
  ticketId?: string;
}

/**
 * Send a text message to a non existing conversation
 * @param params
 */
export const sendTelegramMessageNewTextApi = async (
  params: SendTelegramMessageNewTextApiParams
) => {
  const { content, ...restParams } = params;
  await axios.post(TELEGRAM_MESSAGE_NEW_TEXT, content, {
    params: restParams
  });
};
