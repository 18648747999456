import { useEffect, useState } from 'react';
import { logger } from 'src/utils/logger';

function useCameraPermission() {
  const [permissionToUseCamera, setPermissionToUseCamera] = useState(true);

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        if (navigator.permissions) {
          const result = await navigator.permissions.query({
            //@ts-ignore
            name: 'camera'
          });

          setPermissionToUseCamera(result.state === 'granted');

          result.onchange = () => {
            setPermissionToUseCamera(result.state === 'granted');
          };
        } else {
          // Fallback if Permissions API is not supported
          try {
            await navigator.mediaDevices.getUserMedia({ video: true });
            setPermissionToUseCamera(true);
          } catch (error) {
            setPermissionToUseCamera(false);
          }
        }
      } catch (error) {
        logger.error('Error checking camera permissions:', error);
      }
    };

    checkCameraPermission();
  }, []);

  return permissionToUseCamera;
}

export default useCameraPermission;
