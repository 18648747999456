import {
  Card,
  CardContent,
  Modal,
  CardHeader,
  Stack,
  IconButton,
  Button,
  Divider,
  CircularProgress
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { PromptElement } from 'src/modules/Shared/models/ariModels';
import { defaultPromptNode } from '../../InboundRoutes/views/components/forms/promptNodeForm/utils/defaultPromptNode';
import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';
import { PromptElementForm } from './PromptElementForm';
import { Close } from '@mui/icons-material';
import { t } from 'i18next';
import { createPromptElement } from '../../InboundRoutes/domain/prompt/createPromptElement';
import { useAlert } from 'src/hooks/useAlert';
import { updatePromptElement } from '../../InboundRoutes/domain/prompt/updatePromptElement';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreatePromptsPermissions,
  checkUpdatePromptsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  promptElement?: PromptElement;
}

export function PromptElementFormModal({
  isOpen,
  onClose,
  promptElement
}: Props) {
  const companyId = getLoggedUserCompanyId();

  const formMode = !!promptElement ? 'Update' : 'Create';
  const [promptElementForm, setPromptElementForm] = useState<PromptElement>(
    formMode === 'Create'
      ? { ...defaultPromptNode.element, companyId }
      : promptElement
  );
  const [isLoading, setIsLoading] = useState(false);

  const { hasAccess } = useContext(PermissionsContext);

  const { showAlert } = useAlert();
  async function handleSave() {
    setIsLoading(true);
    if (formMode === 'Create') {
      try {
        await createPromptElement(promptElementForm);
        showAlert(t('Prompt created successfully'), 'success');
        onClose();
      } catch (error) {
        showAlert(t('Error creating prompt'), 'error');
      } finally {
        setIsLoading(false);
      }
    }
    if (formMode === 'Update') {
      try {
        await updatePromptElement(promptElementForm);
        showAlert(t('Prompt updated successfully'), 'success');
        onClose();
      } catch (error) {
        showAlert(t('Error updating prompt'), 'error');
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      setPromptElementForm(
        formMode === 'Create'
          ? { ...defaultPromptNode.element, companyId }
          : promptElement
      );
    }
  }, [promptElement, isOpen, formMode]);

  const disableSubmit =
    isLoading ||
    !promptElementForm.text ||
    !promptElementForm.name ||
    (formMode === 'Create' && !hasAccess(checkCreatePromptsPermissions)) ||
    (formMode === 'Update' && !hasAccess(checkUpdatePromptsPermissions));

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Card sx={{ width: '40vw' }}>
        <CardHeader
          title={`${t(formMode)} prompt`}
          action={
            <IconButton onClick={onClose} size="small">
              <Close />
            </IconButton>
          }
        />
        <Divider />
        <Stack px={2} height={'max-content'} maxHeight="70vh" overflow="auto">
          <Stack rowGap={2} my={2}>
            <PromptElementForm
              promptElement={promptElementForm}
              onChange={setPromptElementForm}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" p={2}>
          <Button variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            startIcon={isLoading ? <CircularProgress size={16} /> : undefined}
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={disableSubmit}
          >
            {t(formMode)}
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
}
