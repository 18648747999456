import { emitCustomEvent } from 'react-custom-events';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import {
  Dashboard,
  GridLayout,
  Panel,
  PanelParameters
} from '../models/Dashboards';
import dashboardLogic from './dashboardsLogic';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { PANELS } from '../views/utils/constants';

/**Get gridLayout object by dashboard
 *
 * Returns the grid layout that will be shown in screen
 *
 * @param dashboard Dashboard object
 * @returns Grid Layout
 */
export const getGridlayoutByDashboard = async (dashboard: Dashboard) => {
  const panels = await dashboardLogic.getAllPanels(dashboard.id);
  const layout = panels.map((panel) => {
    return convertPanelParametersToGLayout(panel);
  });
  return layout;
};

/**Get Default panel Sizes
 *
 * Returns the default width and height for panel based on type
 *
 * @param panelId PanelType
 * @returns number Object
 */
export const getPanelDefaultSize = (panelType: string) => {
  const width = PANELS[panelType].panelDefaultSizes.panelDefaultWidth;
  const height = PANELS[panelType].panelDefaultSizes.panelDefaultHeight;
  return { width, height };
};

/**Convert Panel parameters to GridLayout
 *
 * Returns the grid layout that will be shown in screen based on panel parameters
 *
 * @param panelId PanelId STRING
 * @param panelParameters Panel parameters to known the current position
 * @returns Grid Layout
 */
const convertPanelParametersToGLayout = (panel: Panel): GridLayout => {
  const layout = {
    i: panel.id,
    x: panel.panelParameters.coordX,
    y: panel.panelParameters.coordY,
    w: panel.panelParameters.width,
    h: panel.panelParameters.height
  };

  if (panel.panelParameters.chartType === PANELS.KPI.key) {
    layout['minW'] = PANELS.KPI.panelDefaultSizes.minPanelWidth;
    layout['minH'] = PANELS.KPI.panelDefaultSizes.minPanelHeight;
  } else {
    layout['minW'] = PANELS.Charts.panelDefaultSizes.minPanelWidth;
    layout['minH'] = PANELS.Charts.panelDefaultSizes.minPanelHeight;
  }

  return layout;
};

/** Convert Grid Layout object to Panel Parameters
 *
 * @param layout new layout
 * @param panels panels to update
 * @return update panels
 */
export const convertGLayoutToPanelParameters = async (
  layout,
  panels: Panel[]
) => {
  const updatePromises = layout.map(async (element) => {
    const panel = panels.filter((pn) => pn.id === element.i);
    const panelParameters: PanelParameters = {
      ...panel[0].panelParameters,
      coordX: element.x,
      coordY: element.y,
      height: element.h,
      width: element.w
    };
    try {
      return await dashboardLogic.updatePanelParams(
        panel[0].id,
        panelParameters
      );
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      emitCustomEvent(CustomEventNames.ALERT, {
        msg: errorMessage,
        type: 'error'
      });
    }
  });

  const updatePanels = await Promise.all(updatePromises);
  return updatePanels;
};

/** Get the Next Layout position
 *
 * @param layout layout
 * @return number
 */
export const getNextLayoutPosition = (layout: GridLayout[]): number => {
  if (layout.length === 0) return 0;
  const lastLayout = layout.reduce((a, b) => (b.y > a.y ? b : a));
  return lastLayout.h + lastLayout.y + 1;
};
