import { Button, Popover } from '@mui/material';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { CreateUpdateContactFieldForm } from '../CreateUpdateContactFieldForm';
import { ContactField } from 'src/modules/Contacts/models/contacts';

interface Props {
  contactGroupId?: string;
  contactField?: ContactField;
}

export const ContactFieldFormPopover = ({
  contactGroupId,
  contactField
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  return (
    <>
      <Button
        ref={ref}
        variant="outlined"
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('Crear')}
      </Button>
      <Popover
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        anchorEl={ref.current}
      >
        <CreateUpdateContactFieldForm
          contactField={contactField}
          contactGroupId={contactGroupId}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Popover>
    </>
  );
};
