import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { BASE_MS, BASE_URL } from './constants';
import { TICKETS_OPEN } from 'src/utils/apiRoutes/msMessaging';

export const checkTicketContactLimitApi = async (params: {
  contactIds: string[];
  serviceId: string;
}) => {
  const config: AxiosRequestConfig = {
    params: params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' })
  };
  const resp = await axios.get<boolean>(TICKETS_OPEN, config);
  return resp.data;
};
