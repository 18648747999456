import {
  ChatBubble,
  ChatBubbleTwoTone,
  ChevronLeft,
  Close
} from '@mui/icons-material';
import { IconButton, Stack, useTheme } from '@mui/material';
import { FormikProps } from 'formik';
import { t } from 'i18next';
import { WebChatStyle } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatStyle';

interface webChatHeaderProps {
  formikProps: WebChatStyle;
}

export const WebChatHeader: React.FC<webChatHeaderProps> = ({
  formikProps
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      height="75px"
      px={2}
      py={1}
      justifyContent="space-between"
      alignContent={'center'}
      sx={{
        background:
          formikProps?.conversationStyle.titleColor.color ?? '#FFFFFF',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box'
      }}
    >
      <Stack direction={'row'} spacing={1}>
        <Stack mr="3.5px">
          <ChatBubbleTwoTone
            sx={{
              color:
                formikProps?.conversationStyle.bubbleColor.color ?? '#1975FF',
              marginTop: '10px',
              marginX: '3.5px',
              fontSize: '28px'
            }}
          />
        </Stack>
        <Stack direction={'column'} justifyContent={'center'}>
          <label
            style={{
              fontWeight: 'bold',
              fontSize: '17px',
              color:
                formikProps?.conversationStyle.titleText.color ?? '#1975FF',
              fontFamily: formikProps?.conversationStyle.font ?? 'Roboto'
            }}
          >
            {t('Chatting with agent')}:
          </label>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems={'center'}>
        <IconButton
          sx={{
            color:
              formikProps?.conversationStyle.controlsColor.color ?? '#1975FF',
            rotate: '-90deg'
          }}
        >
          <ChevronLeft sx={{ width: '24px', height: '24px' }} />
        </IconButton>
        <IconButton
          sx={{
            color:
              formikProps?.conversationStyle.controlsColor.color ?? '#1975FF'
          }}
        >
          <Close sx={{ width: '24px', height: '24px' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};
