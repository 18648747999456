import { User } from 'src/models/users';

export const colors = [
  '#D98880',
  '#C39BD3',
  '#7FB3D5',
  '#76D7C4',
  '#7DCEA0',
  '#F7DC6F',
  '#F0B27A',
  '#D7DBDD',
  '#B2BABB',
  '#808B96',
  '#E74C3C',
  '#8E44AD',
  '#3498DB',
  '#16A085',
  '#2ECC71',
  '#F39C12',
  '#D35400',
  '#95A5A6',
  '#34495E'
];

export const initialValuesUser: User = {
  companyId: '',
  description: '',
  email: '',
  enabled: false,
  fixedExtension: '',
  password: '',
  username: '',
  id: '',
  roleIds: [],
  superadmin: false,
  setDoubleFactorOnNextLogin: false
};

export const ROUND_SALT_BCRYPT_PASSWORD: number = 12;
