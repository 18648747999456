import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
  useTheme
} from '@mui/material';

import { Conversation } from 'src/models/conversations/conversations';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { getConversationContactName } from 'src/services/socialmedia/domain/conversation/getConversationInfo';
import ReasignOptionsPopover from './ReasignOptionsPopover';

export const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
	  width: ${theme.spacing(6)};
	  height: ${theme.spacing(6)};
  `
);

interface Props {
  contact: Contact;
  companyFields: ContactField[];
  conversation: Conversation;
  setSelectedContact: Dispatch<SetStateAction<Contact>>;
  setIsAdding: Dispatch<SetStateAction<boolean>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  index: number;
  socialmediaAgent: Contact;
  setEditingIndex: Dispatch<SetStateAction<number | undefined>>;
  webChatMatchContacts?: Contact[];
}

const ContactListItem = (props: Props) => {
  const {
    contact,
    companyFields,
    conversation,
    setSelectedContact,
    setIsAdding,
    setIsEditing,
    index,
    socialmediaAgent,
    setEditingIndex,
    webChatMatchContacts
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const [name, setName] = useState('');

  useEffect(() => {
    getConversationContactName(contact, conversation).then((name) => {
      setName(name);
    });
  }, []);

  const nameContactFieldsQuery = useGetContactFields(
    { companyId: conversation.companyId, type: 'Name' },
    !!conversation
  );

  if (nameContactFieldsQuery.isLoading) return <CircularProgress />;

  return (
    <ListItem
      sx={{
        borderRadius: 0
      }}
    >
      <ListItemAvatar>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          overlap="circular"
        >
          <AvatarWrapper alt={name && name}>
            <Typography variant="h3" color="white">
              {name && name.charAt(0)}
            </Typography>
          </AvatarWrapper>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="h4" gutterBottom noWrap>
            {name}
          </Typography>
        }
        secondary={<Typography variant="subtitle2"></Typography>}
        sx={{ ml: theme.spacing(1.5) }}
      />
      {contact?.id && (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          sx={{ width: '120px', minWidth: '120px' }}
          onClick={() => {
            //setEditingIndex(index);
            setSelectedContact(contact);
          }}
          id="btnView-ContactListItem"
        >
          {t('View contact')}
        </Button>
      )}

      <ReasignOptionsPopover
        index={index}
        contact={contact}
        conversation={conversation}
        socialmediaAgent={socialmediaAgent}
        companyFields={companyFields}
        setEditingIndex={setEditingIndex}
        setIsAdding={setIsAdding}
        setIsEditing={setIsEditing}
        setSelectedContact={setSelectedContact}
        webChatMatchContacts={webChatMatchContacts}
      />
    </ListItem>
  );
};

export default ContactListItem;
