import { useContext } from 'react';
import { MusicOnHold } from '../../../model/MusicOnHold';
import { useMusicOnHoldTable } from '../../hooks/useMusicOnHoldTable';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { MusicOnHoldContext } from '../../context';
import { DialTable } from 'src/components/DialTable';

export const MusicOnHoldTable = () => {
  const { musicOnHoldsDelete, isOpenDelete, toggleMusicOnHoldDelete } =
    useContext(MusicOnHoldContext);

  const { getRowActions, deleteMusicOnHolds, getColumns, musicOnHoldQuery } =
    useMusicOnHoldTable();

  return (
    <>
      <DialTable<MusicOnHold>
        queryKey={['musicOnHold']}
        getColumnsFn={getColumns}
        getRowActionsFn={getRowActions}
        data={musicOnHoldQuery.data as MusicOnHold[]}
      />
      <ConfirmationModal<MusicOnHold, keyof MusicOnHold>
        dataKey={'name'}
        data={musicOnHoldsDelete}
        isOpen={isOpenDelete}
        setOpen={toggleMusicOnHoldDelete}
        onConfirm={deleteMusicOnHolds}
        mode={ConfirmationModalModes.DELETE}
      />
    </>
  );
};
