import { ReportQueryParam } from './getReportingTicket';
import {
  ReportQuery,
  getReportingTicketsApi
} from '../../infrastructure/reporting/getReportingTicketApi';
import { ReportingAttachment } from 'src/modules/Productivity/modules/Downloads/models/reportingAttachment';

export const generateReportingTicket = async (
  param: ReportQueryParam,
  companyId: string,
  name: string,
  timeZone?: string
) => {
  const { paginationValues, ...rest } = param;

  const reportQuery: ReportQuery = {
    ...rest,
    pageNumber: paginationValues.page,
    pageSize: paginationValues.size
  };
  const resp = (await getReportingTicketsApi(
    reportQuery,
    companyId,
    name,
    timeZone,
    true
  )) as ReportingAttachment;
  return resp;
};
