import AuthManager from '../manager';
import { getRTcalls } from 'src/services/rtcalls/domain/getRTCalls';
import CallManagerDomain from 'src/modules/CTI/CallManager';

export const logoutCurrentUser = () => {
  const userId = AuthManager.getUserId();
  if (userId) {
    const rtCalls = getRTcalls({ rtUserId: userId, shouldUseHook: false });
    rtCalls.forEach(CallManagerDomain.hangupCall);
  }

  AuthManager.logout();
};
