import {
  createPanel,
  updatePanelById,
  updatePanelParameters
} from '../infrastructure/api/postPanels';
import { Panel, PanelAlert, PanelParameters } from '../models/Dashboards';

/** CREATE A NEW PANEL
 *
 * Returns the new panel
 * @returns {Panel} panel
 */
export const createNewPanel = async (panel: Panel): Promise<Panel> => {
  return createPanel(panel);
};

/** UPDATE A  PANEL
 *
 * Returns the updated panel
 * @returns {Panel} panel
 */
export const updatePanel = async (
  panelId: string,
  panel: Panel
): Promise<Panel> => {
  return updatePanelById(panelId, panel);
};

/** CREATE ALERT
 *
 * Returns the updated alert
 * @returns {PanelAlert} Dashboard
 */
export const createAlert = async (alert: PanelAlert): Promise<PanelAlert> => {
  return createAlert(alert);
};

/** UPDATE PANEL PARAMETERS
 *
 * Returns the updated panel
 * @param panelId id
 * @param panelParameters parameters for location
 * @returns {Panel} Updated Panel
 */
export const updatePanelParams = async (
  panelId: string,
  panelParameters: PanelParameters
): Promise<Panel> => {
  return updatePanelParameters(panelId, panelParameters);
};
