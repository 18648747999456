import { Box, Stack, useTheme } from '@mui/material';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';

interface Props {
  contact: Contact;
  companyFields: ContactField[];
  onClick: (contact: Contact) => void;
}

export function SearchContactListPopover({
  contact,
  companyFields,
  onClick
}: Props) {
  const theme = useTheme();
  const nameCompanyFields = companyFields
    .filter((field) => field.type === 'Name')
    .filter((field) => field.mainField);
  const nameFieldId = nameCompanyFields.find(
    (field) => field.contactGroupId === contact.contactGroupId
  ).id;

  const name = contact.fields[nameFieldId];
  return (
    <Box
      sx={{
        cursor: 'pointer',
        borderRadius: '5px',
        padding: '9px 15px',
        '&:hover': {
          background: `${theme.colors.secondary.lighter}`
        }
      }}
      onClick={(): void => {
        onClick(contact);
      }}
    >
      <Stack direction={'row'} alignItems="center" spacing={2}>
        <Stack
          sx={{
            backgroundColor: theme.colors.secondary.main,
            borderRadius: '50%',
            width: '32px',
            height: '32px',
            color: 'white'
          }}
          fontWeight="bold"
          alignItems="center"
          justifyContent="center"
        >
          {name?.charAt(0).toUpperCase()}
        </Stack>
        <h3>{name}</h3>
      </Stack>
    </Box>
  );
}
