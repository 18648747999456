import { getMicDevice } from 'src/services/webrtc/domain/getMicDeviceStore';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';
import { prepareStreamForCall } from 'src/services/webrtc/webrtc.devices';
import { DeviceStorageKey } from './utils/constants';
import { setMicrophoneDeviceStore } from 'src/services/webrtc/domain/setMicDevice';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';

export const useMicrophone = () => {
  const voiceDevice = getMicDevice(true);
  const activeCall = getActiveRTCall(getConnectedRTUser());

  const mediaDevices = getDevicesStore(true).filter((d) => {
    return d.kind === 'audioinput';
  });

  const onSelectChange = (labelSelected: string): void => {
    const newDevice = mediaDevices.filter((device) => {
      return device.label === labelSelected;
    })[0];

    setMicrophoneDeviceStore(newDevice);
    localStorage.setItem(DeviceStorageKey.VOICE, JSON.stringify(newDevice));

    if (activeCall) {
      prepareStreamForCall();
    }
  };

  return [voiceDevice, mediaDevices, onSelectChange] as [
    MediaDeviceInfo,
    MediaDeviceInfo[],
    Function
  ];
};
