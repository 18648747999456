import { IMessageEvent, client, w3cwebsocket } from 'websocket';
import { NotificationMessage } from './websocket-messages';

const wsEndpoint = process.env.REACT_APP_VIDEOCALLS_WS as string;

export class VideoroomWSService {
  static instance: VideoroomWSService = new VideoroomWSService();
  static client: w3cwebsocket;

  events = new Map<EventName, { [key: string]: (event) => void }>();

  connect(): w3cwebsocket {
    if (VideoroomWSService.client) return VideoroomWSService.client;

    VideoroomWSService.client = new w3cwebsocket(wsEndpoint);

    VideoroomWSService.client.onopen = (): void => {
      console.log('Websocket connection OPENED');
    };

    VideoroomWSService.client.onclose = (): void => {
      console.log('Websocket connection CLOSED');
    };

    VideoroomWSService.client.onerror = (error: Error): void => {
      console.error('Websocket error: ', error.message);
      alert('Error connecting to videocalls websocket');
    };

    VideoroomWSService.client.onmessage = (message: IMessageEvent): void => {
      const data: NotificationMessage = JSON.parse(message.data.toString());

      this.exec(data.eventName, data);
    };

    return VideoroomWSService.client;
  }

  disconnect(): void {
    if (VideoroomWSService.client) {
      VideoroomWSService.client.close();
      VideoroomWSService.client = null;
    }
  }

  on(
    eventName: EventName,
    id: string,
    func: (event: NotificationMessage) => void
  ) {
    const functions = this.events.get(eventName) || {};

    functions[id] = func;

    this.events.set(eventName, functions);
  }

  private exec(event: EventName, data?: NotificationMessage) {
    if (this.events.has(event)) {
      Object.values(this.events.get(event)).forEach((func) => {
        func(data);
      });
    }
  }
}

export type EventName =
  | 'videocalls:identify-message'
  | 'videocalls:join-videoroom'
  | 'videocalls:participant-status-changed';

export enum ParticipantResolution {
  ACCEPTED,
  REJECTED
}
