import {
  updateDashboardPanelsGroupsApi,
  updateDashboardTimesApi
} from '../infrastructure/api/putDashboards';
import { Dashboard, PanelsGroup } from '../models/Dashboards';

/** Update dashboard times
 *
 * @param dashboardId string
 * @param groupIntervalSeconds number
 * @param panelSizeSeconds number
 * @returns {Dashboard} updated dashboard
 */
export const updateDashboardTimes = async (
  dashboardId: string,
  groupIntervalSeconds: number,
  panelSizeSeconds: number
): Promise<Dashboard> => {
  return updateDashboardTimesApi(
    dashboardId,
    groupIntervalSeconds,
    panelSizeSeconds
  );
};

/** Update panels Groups
 *
 * @param dashboardId string
 * @param panelsGroups panelsgrous array
 * @returns {Dashboard} updated dashboard
 */
export const updateDashboardPanelsGroups = async (
  dashboardId: string,
  panelsGroups: PanelsGroup[]
): Promise<Dashboard> => {
  return updateDashboardPanelsGroupsApi(dashboardId, panelsGroups);
};
