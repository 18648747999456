import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { TicketResolution } from '../../models/tickets';
import { TICKET_RESOLUTIONS_ID } from 'src/utils/apiRoutes/msMessaging';

export const updateTicketResolutionApi = async (
  ticketResolution: TicketResolution
): Promise<TicketResolution> => {
  const path = replaceURLParams(TICKET_RESOLUTIONS_ID, {
    '{id}': ticketResolution.id
  });
  const resp = await axios.put(path, ticketResolution);
  return resp.data;
};
