import { useEffect, useState } from "react";
import DaServicesDomain from "src/modules/Admin/modules/Operations/modules/DaServices/domain/DaServicesDomain";
import { FormFieldVideoCall, VideoCallCampaign } from "src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns";
import { VideoCallsDomain } from "../domain";


export interface VideocallCampaignData {
  campaignId: string
  formFields: FormFieldVideoCall[]
}

export function useVideocallCampaignData(campaignId: string, isLoggedIn: boolean) {
  const [campaignData, setCampaignData] = useState<VideocallCampaignData>({ campaignId, formFields: null });

  useEffect(() => {
    if (campaignId) {
      if (isLoggedIn) {
        DaServicesDomain.getCampaignById(campaignId).then(
          (campaign) => setCampaignData({ campaignId, formFields: (campaign as VideoCallCampaign).formFields })
        );
      } else {
        VideoCallsDomain.getFormFieldsOfVideocallCampaign(campaignId).then(
          (formFields) => setCampaignData({ campaignId, formFields })
        )
      }
    }

  }, [campaignId, isLoggedIn]);
  return campaignData;
}