export interface IntentionModelFilters {
  name?: string;
  companyId?: string;
}

export type AIModelsKeys = keyof AIModel;

interface AIModelData {
  [key: string]: string[];
}

export interface AIModel {
  name: string;
  intentions: string;
  description: string;
  status: string;
  companyId: string;
  id?: string;
  data: AIModelData;
}

export interface EntitiesModel {
  id?: string;
  name: string;
  labels: string[];
  description: string;
  status: AIModelStatus;
  companyId: string;
  languge: AIModelLanguage;
}

export enum AIModelStatus {
  PENDING = 'Pending',
  TRAINING = 'Training',
  TRAINED = 'Trained',
  FAILED = 'Failed'
}

export type AIModelLanguage = 'Spanish' | 'English';

export enum AIModelUseCase {
  STATISTIC_STUDY = 'StatisticStudy',
  TELEMARKETING = 'Telemarketing',
  APPOINTMENTS = 'Appointments',
  CLIENT_SUPPORT = 'ClientSuport',
  DEBT_RECOVERY = 'DebtRecovery'
}

export interface ItemsIntentions {
  id: number;
  title: string;
  items: ItemsModels[];
}

export interface ItemsModels {
  id: string;
  label: string;
}

export interface AIModelApi {
  name: string;
  data: Object;
  description: string;
  status?: string;
  companyId: string;
  language?: string;
}
