import axios from 'axios';
import { WHATSAPP_MESSAGE_EXISTING_TEMPLATE } from 'src/utils/apiRoutes/msWhatsapp';
import { WhatsAppTemplateVariables } from '../../models/WhatsAppTemplate';

export interface SendWhatsAppMessageExistingTemplateApiParams {
  accountId: string;
  file: File;
  conversationId: string;
  templateParams: WhatsAppTemplateVariables;
  templateId: string;
}
/**
 * Send a text message to an existing conversation
 * @param params
 */
export const sendWhatsAppMessageExistingTemplateApi = async (
  params: SendWhatsAppMessageExistingTemplateApiParams
) => {
  const { templateParams, ...restParams } = params;
  const formData = new FormData();
  formData.append('conversationId', params.conversationId);
  formData.append('accountId', params.accountId);
  formData.append('templateId', params.templateId);
  const fixedTemplateParams = {};
  Object.keys(params.templateParams).forEach((key) => {
    const numericKey = parseInt(key.replace(/\"/g, ''));
    fixedTemplateParams[numericKey] = params.templateParams[key];
  });
  formData.append('templateParams', JSON.stringify(fixedTemplateParams));
  if (params.file) {
    formData.append('file', params.file);
  }
  await axios.post(WHATSAPP_MESSAGE_EXISTING_TEMPLATE, formData);
};
