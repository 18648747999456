import axios from 'axios';
import { WEBCHAT_MESSAGE_TYPING } from 'src/utils/apiRoutes/msWebChat';

export interface TypingWwebChatMessageApiParams {
  accountId: string;
  conversationId: string;
  typing: boolean;
}

export const typingWebChatApi = async (
  params: TypingWwebChatMessageApiParams
) => {
  await axios.post(WEBCHAT_MESSAGE_TYPING, null, { params });
};
