import {
  getCampaignByIdQuery,
  useGetCompaniesQuery,
  useGetServicesQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  getCompaniesQuery,
  getUsersQuery
} from './query/getUsersDataQueries';

const UsersApplication = {
  getCampaignByIdQuery,
  useGetCompaniesQuery,
  getCompaniesQueryNoHook: getCompaniesQuery,
  getUsersQuery,
  useGetServicesQuery,
  useGetUsersQuery,
  useGetUserByIdQuery
};

export default UsersApplication;
