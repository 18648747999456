import store, { useSelector } from 'src/store/store';

export const getAutoanswerStore = (shouldUseHook: boolean) => {
  const autoanswer = (
    shouldUseHook
      ? useSelector((state) => state.configVolume.autoanswer)
      : store.getState().configVolume.autoanswer
  ) as boolean;

  return autoanswer;
};

export const getAutoanswerStorage = () => {
  return localStorage.getItem('autoanswer') === 'true';
};

export const setAutoanswerStorage = (value: boolean) => {
  const stringValue = value ? 'true' : 'false';
  return localStorage.setItem('autoanswer', stringValue);
};
