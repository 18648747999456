import {
  ChannelType,
  Conversation,
  ConversationStatus
} from 'src/models/conversations/conversations';
import { getConversationsApi } from '../infrastructure/getConversationsApi';
import { PaginatedElements } from 'src/models/pagination';
import AuthManager from 'src/services/authentication/manager';
import { useInfiniteQuery } from '@tanstack/react-query';

interface filter {
  page?: number;
  size?: number;
  channel?: ChannelType;
}

/**  CLOSED CONVERSATIONS FOR THE CONNECTED RTUSER
 *
 *
 * @returns Array of conversations after applying the specified filters
 */
export const getClosedConversations = (
  filter: filter
): Promise<PaginatedElements<Conversation>> => {
  const userId = AuthManager.getUserId();
  return getConversationsApi({
    assignedUserId: userId,
    withMessages: false,
    status: [ConversationStatus.CLOSED],
    page: !!filter.page ? filter.page : 0,
    size: !!filter.size ? filter.size : -1,
    channel: filter.channel ? [filter.channel] : []
  });
};

export function useClosedConversationsInfiniteQuery(filter: filter) {
  return useInfiniteQuery({
    queryKey: ['closedConversations', filter.page, filter.size, filter.channel],
    queryFn: ({ pageParam = 0 }) =>
      getClosedConversations({ ...filter, page: pageParam }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.currentPage + 1 < lastPage.totalPages
        ? lastPage.currentPage + 1
        : undefined,
    getPreviousPageParam: (firstPage, pages) =>
      firstPage.currentPage - 1 > 0 ? firstPage.currentPage - 1 : undefined
  });
}
