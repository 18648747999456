import { ChannelTypes } from 'src/models/users';
import { getCampaignsOfUserCompany } from './getCampaignsOfUserCompany';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  CallMode,
  Campaign,
  CampaignOrientation
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { ChannelType } from 'src/models/conversations/conversations';
import AuthManager from 'src/services/authentication/manager';

export const getUserOutboundCampaignsByChannel = (
  channel: ChannelTypes,
  campaigns: Campaign[]
) => {
  const channelCampaigns = campaigns?.filter((campaign) => {
    if (campaign.channel !== channel) {
      return false;
    }

    if (!campaign.enabled) return false;

    if (
      campaign.channel === ChannelType.CALL &&
      campaign.callMode !== CallMode.OUTBOUND
    ) {
      return false;
    }

    if (
      campaign.channel !== ChannelType.CALL &&
      campaign.orientation !== CampaignOrientation.OUTBOUND
    ) {
      return false;
    }

    return true;
  });

  return channelCampaigns;
};

export const useUserCampaignsQuery = (enabled?: boolean) => {
  return useQuery(
    [`campaigns`],
    () => getCampaignsOfUserCompany({ userId: AuthManager.getUserId() }),
    { staleTime: 1000 * 60 * 5, enabled }
  );
};
