import { Conversation } from 'src/models/conversations/conversations';
import {
  ConversationMessage,
  MessageOrientation,
  MessageStatus
} from 'src/models/conversations/messages';
import { setConversationStore } from '../../infraestructure/store/setConversationStore';
import {
  getConversation,
  getConversationByIdFromApi
} from '../conversation/getConversation';
import { compareConversationMessagesByCreationDate } from './utils';
import { SocialmediaMessageTypes } from '../../application/websocket/socialmediaEvent';
import { getCurrentConversation } from '../conversation/getCurrentConversation';

/** ADD MESSAGE TO CONVERSATION CONVERSATIONS
 *
 * @param filter Parameters to filter the currently stored conversations
 * @returns Array of conversations after applying the specified filters
 */
export const addMessageToConversation = async (params: {
  message: ConversationMessage;
  conversation?: Conversation;
  socialmediaMessageType?: SocialmediaMessageTypes;
}) => {
  const { message, socialmediaMessageType } = params;
  let {
    conversation = getConversation({
      id: message.conversationId,
      shouldUseHook: false
    })
  } = params;
  // The message in some cases can arrive before the conversation, so we need to get it from the API
  if (!conversation) {
    conversation = await getConversationByIdFromApi(message.conversationId);
  }

  // Update or insert message in conversation
  if (!conversation?.messages) {
    conversation = {
      ...conversation,
      messages: []
    };
  }

  // The oldest message between the conversation lastMessage and the message to add
  const lastMessage =
    conversation.lastMessage &&
    compareConversationMessagesByCreationDate(
      conversation.lastMessage,
      message
    ) <= 0
      ? conversation.lastMessage
      : message;

  const previousTotalMessages = conversation?.totalMessages ?? 0;
  const totalMessages =
    socialmediaMessageType === 'MessageReceived'
      ? previousTotalMessages + 1
      : previousTotalMessages;

  conversation = {
    ...conversation,
    messages: [
      ...conversation.messages
        .filter((msg) => msg.messageId !== message.messageId)
        .concat(message)
    ],
    lastMessage,
    unreadIncomingMessages: handleUnreadIncommingMessages(
      conversation,
      lastMessage
    ),
    totalMessages
  };

  setConversationStore(conversation);
};

function handleUnreadIncommingMessages(
  conversation: Conversation,
  lastMessage: ConversationMessage
) {
  const currentConversation = getCurrentConversation(false);
  const updatingCurrentConversation =
    currentConversation?.id === conversation.id;

  if (updatingCurrentConversation) {
    return false;
  }

  if (lastMessage.orientation === MessageOrientation.INCOMING) {
    return lastMessage.status !== MessageStatus.READ;
  }

  return conversation.unreadIncomingMessages;
}
