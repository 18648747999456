import axios from 'axios';
import { replaceURLParams } from '../../utils';
import { RTUSERS, RTUSERS_CHANGE_STATUS } from 'src/utils/apiRoutes/msUsers';

export const getRTUsers = () => {
  return axios
    .get(RTUSERS)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const changeStatus: Function = (id: string, status: string) => {
  const path = replaceURLParams(RTUSERS_CHANGE_STATUS, {
    '{id}': id
  });
  let params = { status };
  return axios
    .post(path, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
