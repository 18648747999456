import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import AdminHeader from 'src/components/AdminHeader';
import { Box, Tab, Tabs } from '@mui/material';
import { AccountsTab } from './tabs/AccountTabs/AccountsTab';
import { StyleTab } from './tabs/StyleTabs/StyleTab';
import { CreateStyle } from './components/Style/index';
import { CreateWebChatAccount } from './tabs/AccountTabs/CreateWebChatAccount';
import {
  checkCreateWebChatAccountPermissions,
  checkReadWebChatStylesPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { useSearchParams } from 'react-router-dom';

export interface Chat {
  id: string;
  campaign: string;
  style: string;
  prewrittenMessage: string;
  status: string;
}

export const ChatView: React.FC = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('acc');
  const [showCreateStyle, setShowCreateStyle] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [_, setUrlSearchParams] = useSearchParams();
  const handleTabsChange = (e, value) => {
    setUrlSearchParams('');
    setTabValue(value);
  };
  const { hasAccess } = useContext(PermissionsContext);

  return (
    <>
      <div id="web-chat-widget"></div>
      <CreateStyle show={showCreateStyle} setShow={setShowCreateStyle} />
      <CreateWebChatAccount
        show={showCreateAccount}
        setShow={setShowCreateAccount}
      />
      <Helmet>
        <title>Chat</title>
      </Helmet>
      <AdminHeader
        heading="WebChat"
        subheading={t(
          'All aspects related to webchat can be managed from this page'
        )}
        buttonLabel={t(
          'Add ' +
            (tabValue === 'acc'
              ? 'account'
              : tabValue === 'style'
                ? 'style'
                : 'prewritten message')
        )}
        onClick={() => {
          if (tabValue === 'acc') {
            setShowCreateAccount(true);
          } else {
            setShowCreateStyle(true);
          }
        }}
        permissionCheckerFn={checkCreateWebChatAccountPermissions}
      />
      <Box px={4} pt={4} pb={2}>
        <Tabs
          onChange={handleTabsChange}
          value={tabValue}
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label={t('Accounts')} value={'acc'} />
          {hasAccess(checkReadWebChatStylesPermissions) && (
            <Tab label={t('Style')} value={'style'} />
          )}
        </Tabs>
      </Box>
      {tabValue === 'acc' && <AccountsTab />}
      {tabValue === 'style' && <StyleTab />}
    </>
  );
};
