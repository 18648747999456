import {
  DialTableFilter,
  FieldDataType,
  Operator,
  booleanOperators,
  dateOperators,
  doubleOperators,
  integerOperators,
  objectIdOperators,
  stringOperators
} from '../models/filters';

export const getOperators = <T extends Object>(
  filter: DialTableFilter<T>
): Operator[] => {
  if (!filter) return [];
  let operators: Operator[] = [];
  switch (filter?.fieldDataType) {
    case FieldDataType.INTEGER:
      operators = integerOperators;
      break;
    case FieldDataType.DOUBLE:
      operators = doubleOperators;
      break;
    case FieldDataType.DATE:
      operators = dateOperators;
      break;
    case FieldDataType.BOOLEAN:
      operators = booleanOperators;
      break;
    case FieldDataType.STRING:
      operators = stringOperators;
      break;
    case FieldDataType.OBJECTID:
      operators = objectIdOperators;
      break;
    default:
      operators = integerOperators;
      break;
  }
  return operators;
};
