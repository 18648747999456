import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { t } from 'i18next';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import { AIModel, AIModelApi, AIModelLanguage } from '../../../models/AIModels';
import { useAlert } from 'src/hooks/useAlert';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { emitCustomEvent } from 'react-custom-events';
import { IntentionsModelsLogic } from '../../../domain/intentionModelsLogic';
import { useGetIntentionModelDataQuery } from '../../hooks/getAIModelsQuery';
import GeneralAiModelForm from './GeneralAiModelForm';
import FooterButtons from './FooterButtons';
import IntentionsList from '../intentions/IntentionsList';
import AuthManager from 'src/services/authentication/manager';
import { CloseTwoTone } from '@mui/icons-material';
import {
  checkCreateIntentionModelsPermissions,
  checkUpdateIntentionModelsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { queryClient } from 'src/utils/queryClient';
import { initialAiModel } from '../../utils/constants';

const schemaModels = Yup.object().shape({
  name: Yup.string().required(t('The name is required')),
  description: Yup.string().required(t('The description is required'))
});

interface Props {
  handleClose: () => void;
  currentAiModel?: AIModel;
  setCurrentAiModel?: Dispatch<SetStateAction<AIModel>>;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const AIModelsForm: React.FC<Props> = ({
  handleClose,
  currentAiModel,
  setCurrentAiModel,
  isOpen,
  setOpen
}) => {
  const { showAlert } = useAlert();
  const typeForm = currentAiModel?.id ? 'update' : 'create';

  const { hasAccess } = useContext(PermissionsContext);

  const companyId = AuthManager.getLoggedUserCompanyId();
  const [language, setLanguage] = React.useState<AIModelLanguage>('Spanish');

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as AIModelLanguage);
  };

  const getAiModelData = async () => {
    if (!currentAiModel?.id) {
      return null;
    }
    return useGetIntentionModelDataQuery(currentAiModel.id);
  };

  useEffect(() => {
    if (typeForm === 'update') {
      getAiModelData().then((res) => {
        if (!res) return;
        setCurrentAiModel(res);
      });
    }
  }, [currentAiModel?.id]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Card
        style={{
          height: '90%',
          width: '90%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <CardHeader
          title={
            typeForm === 'create'
              ? t('Create intention model')
              : t('Update intention model')
          }
          subheader={
            typeForm === 'create'
              ? t(
                  'Fill the fields below manually or use generative ai to create a new model'
                )
              : t(
                  'Fill the fields below manually or use generative ai to update a new model'
                )
          }
          action={
            <IconButton
              color="secondary"
              size="small"
              onClick={handleClose}
              sx={{
                m: 1
              }}
            >
              <CloseTwoTone />
            </IconButton>
          }
        />
        <Divider />
        <Box sx={{ flex: 1, display: 'flex' }}>
          <Formik
            initialValues={{
              name: typeForm === 'create' ? '' : currentAiModel?.name,
              description:
                typeForm === 'create' ? '' : currentAiModel?.description
            }}
            validationSchema={schemaModels}
            onSubmit={async (values) => {
              if (Object.keys(currentAiModel.data).length === 0) {
                showAlert(t('You have to write one intention'), 'error', 3000);
                return null;
              }

              const todasLasClavesConValores = Object.values(
                currentAiModel.data
              ).every((valores) => valores.length > 0);

              if (!todasLasClavesConValores) {
                showAlert(
                  t('You have to write one tag in every intention'),
                  'error',
                  3000
                );
                return null;
              }

              const model: AIModelApi = {
                name: values.name,
                data: currentAiModel.data,
                description: values.description,
                language,
                companyId:
                  typeForm === 'create' ? companyId : currentAiModel.companyId
              };
              try {
                if (typeForm === 'create') {
                  await IntentionsModelsLogic.postIntentionModel(model);
                } else {
                  delete model.language;
                  await IntentionsModelsLogic.updateIntentionModel(
                    currentAiModel.id,
                    model
                  );
                }
                showAlert(
                  t('The AI MODEL was successfully registered'),
                  'success',
                  3000
                );
                handleClose();
                setCurrentAiModel(initialAiModel);
                await queryClient.refetchQueries({
                  queryKey: ['ai-model-table']
                });
                emitCustomEvent('table-data-refetch');
              } catch (error) {
                showAlert(getErrorMessage(error).errorMessage, 'error', 3000);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              isSubmitting
            }) => (
              <Stack
                sx={{
                  flex: 1
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    flexBasis: 0,
                    overflowY: 'auto'
                  }}
                  p={2}
                >
                  {/* Campos generales */}
                  <GeneralAiModelForm
                    typeForm={typeForm}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleChangeLanguage={handleChangeLanguage}
                    language={language}
                    errors={errors}
                  />

                  {/* Accordion de intenciones */}
                  {typeForm === 'create' || currentAiModel?.data ? (
                    <IntentionsList
                      language={language}
                      currentAiModel={currentAiModel}
                      setCurrentAiModel={setCurrentAiModel}
                    />
                  ) : (
                    <Stack width={'100%'} p={2} alignItems={'center'}>
                      <CircularProgress />
                    </Stack>
                  )}
                </Stack>

                <Divider />
                {/* Botones del footer */}
                <FooterButtons
                  disabled={
                    isSubmitting ||
                    (currentAiModel?.data &&
                      (Object.keys(currentAiModel?.data)?.length < 3 ||
                        Object.values(currentAiModel?.data)?.some(
                          (valores) => valores.length < 5
                        ))) ||
                    (typeForm === 'create'
                      ? !hasAccess(checkCreateIntentionModelsPermissions)
                      : !hasAccess(checkUpdateIntentionModelsPermissions))
                  }
                  isSubmitting={isSubmitting}
                  typeForm={typeForm}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                />
              </Stack>
            )}
          </Formik>
        </Box>
      </Card>
    </Modal>
  );
};

export default AIModelsForm;
