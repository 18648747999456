import { RTCall } from 'src/models/rtcall';
import { getRTcalls, GetRTCallsFilter } from './getRTCalls';

/** FIND ONE RTCALL
 *
 * @param filter Parameters to filter the currently stored rtcalls
 * @returns FIRST RTCALL after applying the specified filters
 */
export const getRTCall = (filter: GetRTCallsFilter): RTCall => {
  const rtcalls = getRTcalls(filter);

  return rtcalls[0];
};
