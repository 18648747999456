import {
  MenuItem,
  SxProps,
  TextField,
  Theme,
  Autocomplete
} from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { t } from 'i18next';
import { memo, useEffect, useMemo, useState } from 'react';
import { getContactGroupsQuery } from 'src/modules/Contacts/domain/getContactGroups';
import { ContactGroup } from 'src/modules/Contacts/models/contacts';
import { Fields } from 'src/modules/Shared/models/forms';

interface Props {
  contactGroupId: string;
  setContactGroupId: (id) => void;
  companyId?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  formikInfo?: FormikInfo;
  small?: boolean;
}

interface FormikInfo {
  name: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  input: Fields;
}

export const ContactGroupsSelector = memo(
  ({
    contactGroupId,
    setContactGroupId,
    companyId,
    disabled,
    sx,
    formikInfo,
    small
  }: Props) => {
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);

    useEffect(() => {
      getContactGroupsQuery().then((resp) => {
        setContactGroups(resp?.elements);
      });
    }, []);

    const filteredContactGroups = useMemo(() => {
      return contactGroups.filter((group) =>
        companyId ? group.companyId === companyId : true
      );
    }, [companyId, contactGroups]);

    const selectedContactGroup = useMemo(() => {
      return contactGroups.find((group) => group.id === contactGroupId) || null;
    }, [contactGroupId, contactGroups]);

    return (
      <Autocomplete
        size={small ? 'small' : 'medium'}
        disabled={disabled}
        value={selectedContactGroup}
        onChange={(event, newValue) => {
          setContactGroupId(newValue ? newValue.id : '');
        }}
        options={filteredContactGroups}
        getOptionLabel={(option) => `${option.name} - (${option.description})`}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Contact group')}
            fullWidth
            sx={{
              ...sx
            }}
            name={formikInfo?.name}
            error={Boolean(
              formikInfo?.touched[formikInfo?.input.entity] &&
                formikInfo?.errors[formikInfo?.input.entity]
            )}
            required
          />
        )}
      />
    );
  }
);
