import axios from 'axios';
import { ContactField } from 'src/modules/Contacts/models/contacts';
import { replaceURLParams } from 'src/services/utils';
import { CONTACT_FIELDS_ID } from 'src/utils/apiRoutes/msContacts';

export const getContactFieldByIdApi = async (id: string) => {
  const path = replaceURLParams(CONTACT_FIELDS_ID, { '{id}': id });

  const resp = await axios.get(path);
  return resp.data as ContactField;
};
