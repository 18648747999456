import { Box, Divider, ListItem, styled, Typography } from '@mui/material';
import { t } from 'i18next';
import { renderStatusElement } from 'src/components/Table/components/utils/shareJsxContent';

interface RowListItemPropTypes {
  label: string;
  value: string;
}

const DividerContrast = styled(Divider)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
  `
);

const RowListItem: React.FC<RowListItemPropTypes> = ({ label, value }) => {
  return (
    <>
      <ListItem disableGutters>
        <Box
          sx={{
            flexGrow: 0,
            flexBasis: '50%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'justify'
          }}
        >
          <Typography
            sx={{
              pb: 0.6,
              width: '150px'
            }}
            align="left"
            color="text.primary"
            variant="h5"
          >
            {label + ':'}
          </Typography>
          <Box display="flex" alignItems="flex-start">
            {label !== t('Status') && (
              <Typography
                sx={{
                  lineHeight: 1,
                  pb: 0.6,
                  pl: '25px'
                }}
                variant="h5"
                color="text.secondary"
              >
                {value}
              </Typography>
            )}

            {label === t('Status') && (
              <Box sx={{ pl: '25px', pb: '5.4px' }}>
                {renderStatusElement(value)}
              </Box>
            )}
          </Box>
        </Box>
        <DividerContrast />
      </ListItem>
    </>
  );
};

export default RowListItem;
