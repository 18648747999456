import { RTCall } from 'src/models/rtcall';
import store, { useSelector } from 'src/store/store';

/** GET ACTIVE CALL ID FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns RTCall ID
 */
export const getActiveRTCallIdStore = (shouldUseHook: boolean) => {
  const activeCall = (
    shouldUseHook
      ? useSelector((state) => state.callData.activeCallId)
      : store.getState().callData.activeCallId
  ) as string;

  return activeCall;
};
