import { useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { checkAppVersion } from 'src/utils/appVersion';
import { NewVersionAlert } from '../components/NewVersionAlert';

export function useVersionChecker() {
  const intervalMs = 30 * 60 * 1000; // 30 minutes
  const timeoutMs = 30 * 60 * 1000; // 30 minutes
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setInterval(() => {
      const needToUpdate = !!timeoutRef.current;
      if (needToUpdate) return;
      checkAppVersion().then(({ isLastVersion, remoteAppVersion }) => {
        if (isLastVersion) return;
        timeoutRef.current = setTimeout(() => {
          enqueueSnackbar(null, {
            persist: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            content: <NewVersionAlert version={remoteAppVersion} />
          });
        }, timeoutMs);
      });
    }, intervalMs);
  }, []);
}
