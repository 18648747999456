import { logger } from 'src/utils/logger';
import { sendLogsApi } from '../infrastructure/sendLogsApi';
import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';
import AuthManager from 'src/services/authentication/manager';

export function sendLogs() {
  const logs = logger.getHistory();
  const logsText = logs
    .map((log) => {
      return `${log.level} ${log.message} ${JSON.stringify(log.params)}`;
    })
    .join('\n');
  const companyId = getLoggedUserCompanyId();
  const userId = AuthManager.getUserId();
  sendLogsApi({ companyId, userId, logContent: logsText });
}
