import { useContext } from 'react'

import { Box, IconButton, Tooltip, styled, useTheme } from '@mui/material'
import { SidebarContext } from 'src/contexts/SidebarContext'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'

import HeaderButtons from './Buttons'
import HeaderMenu from './Menu'
import HeaderUserbox from './Userbox'
import Logo from 'src/components/LogoApp'

const HeaderWrapper = styled(Box)(
	({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        top: 0;
        z-index: 6;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
            left: ${theme.spacing(8)};
            width: auto;
        }
`
)

function Header() {
	const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
	const theme = useTheme()

	return (
		<HeaderWrapper
			// display="flex"
			alignItems='center'
			sx={{
				width: {
					md: sidebarToggle
						? `calc(100% - ${theme.sidebar.width} )`
						: `calc(100%-${theme.spacing(8)})`,
				},
				marginLeft: {
					xs: '0px',
					md: sidebarToggle
						? `calc(${theme.sidebar.width} - ${theme.spacing(8)})`
						: '0px',
				},
				display: { md: 'none', xs: 'flex' },
			}}
		>
			<Box display='flex' alignItems='center'>
				<Box
					component='span'
					sx={{
						display: { md: 'none', xs: 'inline-block' },
					}}
				>
					<Logo />
				</Box>
				{/* <Tooltip arrow title="Extend Menu">
          <IconButton onClick={toggleSidebar}>
            <MenuOpenIcon
              sx={{ transform: sidebarToggle ? '' : 'rotate(180deg)' }}
            />
          </IconButton>
        </Tooltip> */}

				{/* <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <HeaderMenu />
        </Box> */}
			</Box>
			<Box display='flex' alignItems='center'>
				{/* <HeaderButtons />
        <HeaderUserbox /> */}
				<Box
					component='span'
					sx={{
						display: { md: 'none', xs: 'inline-block' },
					}}
				>
					<Tooltip arrow title='Toggle Menu'>
						<IconButton sx={{ color: 'white' }} onClick={toggleSidebar}>
							{!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
		</HeaderWrapper>
	)
}

export default Header
