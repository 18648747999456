import { ChangeEvent, useState } from 'react';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  styled,
  Tab,
  Tabs,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { ConversationTab } from './ConversationTab';
import { ContactsTab } from './ContactsTab';
import GeneralTab from './GeneralTab';
import { ConversationTabs } from '../../utils/ConversationParams';

const TabsContainerWrapper = styled(CardContent)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'background'])};
    transform: scale(1);
    transform-origin: center;

    &:hover {
        transform: scale(1.1);
    }
  `
);

interface ActionConversationPropTypes {
  conversation?: Conversation;
  isOpen: boolean;
  onClose: () => void;
}

const ActionConversation: React.FC<ActionConversationPropTypes> = ({
  conversation,
  isOpen,
  onClose
}) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState<ConversationTabs>(
    conversation?.channel !== ChannelType.CALL
      ? ConversationTabs.CONVERSATION
      : ConversationTabs.GENERAL
  );
  const tabs = [
    { value: ConversationTabs.GENERAL, label: t('General') },
    { value: ConversationTabs.CONVERSATION, label: t('Conversation') },
    { value: ConversationTabs.CONTACTS, label: t('Contacts') }
  ];

  const handleTabsChange = (
    _event: ChangeEvent<{}>,
    value: ConversationTabs
  ): void => {
    setCurrentTab(value);
  };

  if (!conversation) return null;

  return (
    <Popover
      anchorEl={document.body}
      onClose={() => {
        onClose();
        setCurrentTab(ConversationTabs.GENERAL);
      }}
      open={isOpen}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      sx={{
        overflow: 'hidden',
        background: alpha(theme.colors.alpha.black[100], 0.06)
      }}
    >
      <Card sx={{ height: '93vh', width: '70vw' }}>
        <CardHeader
          title={t('Conversation Report')}
          subheader={t(
            'Use this dialog window to analyze the conversation info'
          )}
          action={
            <IconButtonWrapper
              sx={{
                m: 1
              }}
              size="small"
              color="secondary"
              onClick={onClose}
            >
              <CloseTwoToneIcon />
            </IconButtonWrapper>
          }
        />
        <Divider />
        <TabsContainerWrapper>
          <Tabs
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
          >
            {tabs.map((tab) => {
              if (
                tab.value === ConversationTabs.CONVERSATION &&
                conversation.channel === 'Call'
              )
                return;
              return (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              );
            })}
          </Tabs>
        </TabsContainerWrapper>
        <Divider />

        {currentTab === ConversationTabs.GENERAL && (
          <Box
            sx={{
              m: 2,
              textAlign: 'center',
              height: 'calc(90vh - 162px)'
            }}
          >
            <GeneralTab simpleConversation={conversation} />
          </Box>
        )}

        {currentTab === ConversationTabs.CONVERSATION &&
          !(
            conversation.channel === 'Call' &&
            conversation.originAgent.orientation !== 'VideoCall'
          ) && <ConversationTab conversation={conversation} />}

        {currentTab === ConversationTabs.CONTACTS && (
          <ContactsTab conversation={conversation} />
        )}
      </Card>
    </Popover>
  );
};

export default ActionConversation;
