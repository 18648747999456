import { useCallback, useEffect, useRef } from 'react';
import { logger } from 'src/utils/logger';

interface Params {
  name: string;
  onMessage: (message: any) => void;
}

export const useBroadcastChannel = ({ name, onMessage }: Params) => {
  const channelRef = useRef<BroadcastChannel>(null);

  useEffect(() => {
    channelRef.current = new BroadcastChannel(name);

    const handleMessage = (event: MessageEvent<any>) => {
      onMessage?.(event.data);
    };

    channelRef.current.addEventListener('message', handleMessage);

    return () => {
      channelRef.current.removeEventListener('message', handleMessage);
      channelRef.current.close();
    };
  }, [name, onMessage]);

  const postMessage = useCallback((message) => {
    if (!!channelRef.current) {
      try {
        channelRef.current?.postMessage?.(message);
      } catch (error) {
        logger.error(error);
      }
    }
  }, []);

  return { postMessage };
};
