import downloadCSV from 'src/modules/Shared/utils/downloadCSV';
import { downloadReportingAttachmentApi } from '../infrastructure/downloadReportAttachmentApi';
import { emitCustomEvent } from 'react-custom-events';

export const downloadReportingAttachment = async (
  reportingAttachmentId: string
) => {
  const resp = await downloadReportingAttachmentApi(reportingAttachmentId);
  downloadCSV(resp);
};

export const downloadReportReadyById = async (attachmentId: string) => {
  setTimeout(async () => {
    emitCustomEvent('reportReady');
    const blob = await downloadReportingAttachmentApi(attachmentId);
    downloadCSV(blob, 'Report');
  }, 1000);
};
