import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Check, ImageOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  TextField
} from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { $insertNodes } from 'lexical';
import { useEffect, useRef, useState } from 'react';
import { $createGeneralImageNode } from 'src/components/LexicalNodes/GeneralImageNode/GeneralImageNode';

export function InsertImageButton() {
  const [showInput, setShowInput] = useState(false);
  const anchorRef = useRef();
  const [imageUrl, setImageUrl] = useState('');
  const [editor] = useLexicalComposerContext();
  const [imageError, setImageError] = useState(false);

  function handleImageLoad() {
    setImageError(false);
  }

  function handleImageError() {
    setImageError(true);
  }

  function insertImage() {
    editor.update(() => {
      const node = $createGeneralImageNode({
        src: imageUrl,
        altText: 'image'
      });
      $insertNodes([node]);
    });

    setShowInput(false);
  }

  useEffect(() => {
    if (!showInput) {
      setImageUrl('');
    }
  }, [showInput]);

  return (
    <>
      <Button
        startIcon={<ImageOutlined />}
        size="small"
        onClick={(event) => setShowInput(true)}
        aria-describedby="image-popover"
        ref={anchorRef}
      >
        {t('Insert Image')}
      </Button>
      <Popover
        id="image-popover"
        open={showInput}
        anchorEl={anchorRef.current}
        onClose={() => setShowInput(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack direction="column" rowGap={1} p={1} width="400px">
          <Stack direction="row" spacing={1} alignItems="center" width="100%">
            <TextField
              fullWidth
              label={t('Image URL')}
              size="small"
              value={imageUrl}
              onChange={(event) => setImageUrl(event.target.value)}
            />

            <IconButton
              size="small"
              onClick={insertImage}
              disabled={imageError || !imageUrl}
            >
              <Check />
            </IconButton>
          </Stack>
          {imageUrl && (
            <Box p={1}>
              <img
                src={imageUrl}
                alt={t('Unable to load image')}
                width="100%"
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            </Box>
          )}
        </Stack>
      </Popover>
    </>
  );
}
