import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { CallSequence } from './callFunctions';
import {
  SocialmediaConversationMessage,
  SocialmediaMessageWSMessage,
  onConversationWSMessage,
  onMessageWSMessage
} from 'src/services/socialmedia/application/websocket/websocket';
import {
  RTCallEventMessage,
  RTUserEventMessage,
  onRTCallsEvent,
  onRTUsersEvent
} from 'src/services/rtusers/application/websocket/websocket';
import { SessionChange } from 'src/services/authentication/application/sessionChange';
import { emitCustomEvent } from 'react-custom-events';
import {
  onRTDashboardEvent,
  onRTInitialDataEvent,
  RTDashBoardEventMessage,
  RTInitialDataEventMessage
} from 'src/modules/Productivity/modules/Dashboards/infrastructure/websocket/websocket';
import { onReportReadyMsg } from 'src/modules/Productivity/modules/Downloads/websocket/reportReadyMsg';
import { onChangeTicketPositiveSubResolution } from 'src/modules/Admin/modules/Operations/modules/Tickets/websocket/changeTicketPositiveSubResolution';
import { ReportReadyMessage } from 'src/modules/Productivity/modules/Downloads/models/reportingAttachment';
import { TicketMsg } from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import onContactEvent, {
  CSVUploadEvent,
  CSVUploadEventSubType,
  ContactEvent,
  onCSVUploadEvent
} from 'src/modules/Contacts/websocket/onContactEvent';
import onRTPowerDialerEvent, {
  PowerDialerNotificationMessage
} from 'src/modules/DialerBot/infrastructure/websocket/onRTPowerDialerEvent';
import { onCreateTicket } from 'src/modules/Admin/modules/Operations/modules/Tickets/websocket/createTicket';
// WS Events types and subtyes
export enum EventTypes {
  RTUSER = 'RTUser',
  AUTH = 'Auth',
  RTCALL = 'RTCall',
  CALL_SEQUENCE = 'CallSequence',
  SOCIALMEDIA = 'Socialmedia',
  PING = 'Ping',
  DASHBOARD = 'Dashboard',
  INITIAL_DATA_READY = 'InitialDataReady',
  REPORTING = 'Reporting',
  TICKET = 'Ticket',
  CONTACT = 'Contact',
  POWERDIALERCALL = 'PowerdialerCall',
  CSVUploadRes = 'CSVUploadRes'
}
export enum SocialmediaEventSubtypes {
  CONVERSATION = 'Conversation',
  MESSAGE = 'Message',
  VERIFICATION = 'Verification'
}
export enum AuthEventSubtypes {
  INVALID_TOKEN = 'InvalidToken',
  TOKEN_EXPIRATION = 'TokenExpiration',
  RENEW = 'Renew'
}
export enum TicketEventSubtypes {
  POSITIVE_SUBRESOLUTION = 'PositiveSubResolution',
  DEFAULT = 'Default'
}

export enum CustomEventNames {
  VERIFICATION = 'Verification',
  LATENCY_EVENT = 'latencyEvent',
  REPORT_READY = 'reportReady',
  ALERT = 'alert',
  AUTH_DATA = 'authData',
  RENEW = 'Renew',
  OPEN_HELPER_PANEL = 'openHelperPanel',
  CSV_UPLOADED = 'csvUploaded',
  USER_TYPING = 'UserTyping',
  LOGOUT = 'Logout',
  NEW_CONVERSATION = 'NewConversation',
  IMPORT_HTML = 'ImportHTML',
  TICKET = 'Ticket',
  WEBRTC_REQUESTING_MICROPHONE = 'webrtcRequestingMicrophone',
  WEBRTC_PERMISSION_GRANTED = 'webrtcPermissionGranted',
  WEBRTC_PERMISSION_DENIED = 'webrtcPermissionDenied',
  TICKET_FIELD_CHANGE = 'TicketFieldChange'
}

export interface WSMessage {
  type: EventTypes;
  subtype?:
    | SocialmediaEventSubtypes
    | AuthEventSubtypes
    | TicketEventSubtypes
    | CSVUploadEventSubType;
  timestamp: string;
}

export const dataMsgs = (client: W3CWebSocket) => {
  client.onmessage = (event): void => {
    let eventWS = JSON.parse(event.data as string) as WSMessage;
    switch (eventWS.type) {
      case EventTypes.RTUSER:
        return onRTUsersEvent(eventWS as RTUserEventMessage);
      //return UserChange(eventWS)
      case EventTypes.AUTH:
        return SessionChange(eventWS, client);
      case EventTypes.RTCALL:
        return onRTCallsEvent(eventWS as RTCallEventMessage);
      //return CallChange(eventWS)
      case EventTypes.CALL_SEQUENCE:
        return CallSequence(eventWS);
      case EventTypes.SOCIALMEDIA:
        if (eventWS.subtype === 'Conversation')
          return onConversationWSMessage(
            eventWS as SocialmediaConversationMessage
          );
        if (eventWS.subtype === 'Message') {
          return onMessageWSMessage(eventWS as SocialmediaMessageWSMessage);
        }
        if (eventWS.subtype === 'Verification') {
          return emitCustomEvent(CustomEventNames.VERIFICATION, eventWS);
        }
        return;

      case EventTypes.DASHBOARD:
        return onRTDashboardEvent(eventWS as RTDashBoardEventMessage);
      // Dashboards initial data ready
      case EventTypes.INITIAL_DATA_READY:
        onRTInitialDataEvent(eventWS as RTInitialDataEventMessage);
        return;
      case EventTypes.PING:
        return emitCustomEvent(CustomEventNames.LATENCY_EVENT, eventWS);
      case EventTypes.REPORTING:
        return onReportReadyMsg(eventWS as ReportReadyMessage);
      case EventTypes.TICKET:
        if (
          (eventWS as TicketMsg).subtype ===
          TicketEventSubtypes.POSITIVE_SUBRESOLUTION
        )
          return onChangeTicketPositiveSubResolution(eventWS as TicketMsg);
        return onCreateTicket(eventWS as TicketMsg);
      case EventTypes.CONTACT:
        return onContactEvent(eventWS as ContactEvent);
      case EventTypes.POWERDIALERCALL:
        return onRTPowerDialerEvent(eventWS as PowerDialerNotificationMessage);
      case EventTypes.CSVUploadRes:
        return onCSVUploadEvent(eventWS as CSVUploadEvent);
    }
  };
};
