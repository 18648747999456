import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { StyleAccordion } from '../../../components/Style/StyleAccordion';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../models/webChatStyle';
import ColorBaseAccordion from './ConversationAccordion/ColorBaseAccordion';
import ConversationAccordion from './ConversationAccordion/ConversationAccordion';
import ResponseInboxAccordion from './ConversationAccordion/ResponseInboxAccordion';
import { FormikProps } from 'formik';

interface StyleModifiersProps {
  formikProps: FormikProps<WebChatStyle>;
}

const StyleModifiers: React.FC<StyleModifiersProps> = ({ formikProps }) => {
  return (
    <Stack
      direction="column"
      px={1}
      py={1}
      overflow={{ xs: 'visible', md: 'scroll' }}
    >
      <Autocomplete
        sx={{ margin: '5px', marginBottom: '10px' }}
        options={[
          'Arial',
          'Helvetica',
          'Calibri',
          'Times New Roman',
          'Cambria',
          'Verdana'
        ]}
        value={formikProps.values.conversationStyle.font}
        onChange={(e, value) => {
          formikProps.setFieldValue('conversationStyle.font', value);
        }}
        renderInput={(params) => (
          <TextField {...params} label={t('Font')}></TextField>
        )}
      ></Autocomplete>
      <StyleAccordion title={t('Color base')}>
        <Typography variant="subtitle1">{t('Customize color')}</Typography>
        <ColorBaseAccordion formikProps={formikProps} />
      </StyleAccordion>
      <StyleAccordion title={t('Conversation')}>
        <Typography variant="h5">{t('Contact message')}:</Typography>
        <ConversationAccordion formikProps={formikProps} />
      </StyleAccordion>
      <StyleAccordion title={t('Response inbox')}>
        <ResponseInboxAccordion formikProps={formikProps} />
      </StyleAccordion>
    </Stack>
  );
};

export default StyleModifiers;
