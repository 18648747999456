import { Button, Divider, Grid, Stack } from '@mui/material';
import { MessageButton } from 'src/models/conversations/messages';

interface Props {
  buttons: MessageButton[];
}

const ChatMessageButton = ({ buttons }: Props) => {
  const filteredButtons = buttons.filter((b) => b.text.length);
  if (filteredButtons.length === 0) return null;

  const direction = filteredButtons.length <= 2 ? 'row' : 'column';
  return (
    <Stack paddingY={0.5} rowGap={1} width={'100%'}>
      <Divider sx={{ backgroundColor: `rgb(255 255 255 / 0.7)` }} />

      <Stack direction={direction} gap={1}>
        {filteredButtons.map((button, index) => {
          const isLastButton = index === filteredButtons.length - 1;
          return (
            <Button
              key={index}
              onClick={() => {}}
              sx={{
                wordBreak: 'break-word',
                color: 'white',
                backgroundColor: `rgb(255 255 255 / 0.3)`,
                minHeight: '30px',
                width: '100%',
                borderRadius:
                  isLastButton &&
                  (direction === 'column' || filteredButtons.length === 1)
                    ? '5px 5px 10px 10px'
                    : '5px',
                '&:hover': {
                  backgroundColor: `rgb(0 0 0 / 0.3)`,
                  color: 'white'
                }
              }}
            >
              {button.text}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ChatMessageButton;
