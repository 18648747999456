import { Contact } from 'src/modules/Contacts/models/contacts';
import {
  ShowflowCondition,
  ShowflowTextBlock
} from '../../../../models/showflow';
import { Ticket } from '../../../../models/tickets';
import { getShowflowVariable } from './getShowflowVariable';
import { ConditionOperation } from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/models/routesNodes';

interface CheckTextBlockConditionsProps {
  textBlock: ShowflowTextBlock;
  contact: Contact;
  ticket: Ticket;
}

export async function checkTextBlockConditions({
  textBlock,
  contact,
  ticket
}: CheckTextBlockConditionsProps) {
  if (!textBlock.conditions.length) return true;

  const evaluationsPromises = textBlock.conditions.map((condition) => {
    return checkTextBlockCondition(condition, contact, ticket);
  });
  const evaluations = await Promise.all(evaluationsPromises);

  if (textBlock.operator === 'And') {
    return evaluations.every((evaluation) => evaluation);
  } else {
    return evaluations.some((evaluation) => evaluation);
  }
}

export async function checkTextBlockCondition(
  condition: ShowflowCondition,
  contact: Contact,
  ticket: Ticket
) {
  const variableInfo = await getShowflowVariable(
    condition.variableId,
    contact,
    ticket
  );

  if (!variableInfo) return false;

  const { value } = variableInfo;

  const evaluation = condition.values.map((conditionValue) => {
    return evaluateCondition(
      value,
      condition.operation,
      conditionValue,
      condition.ignoreCase
    );
  });

  if (condition.booleanOperator === 'And') {
    return evaluation.every((evaluation) => evaluation);
  } else {
    return evaluation.some((evaluation) => evaluation);
  }
}

function evaluateCondition(
  value: string | string[] | boolean,
  operation: ConditionOperation,
  expectedValue: string,
  ignoreCase: boolean
): boolean {
  if (typeof value === 'boolean') {
    return evaluateBooleanCondition(value, operation, expectedValue);
  }

  if (typeof value === 'string') {
    if (ignoreCase) {
      value = value.toLowerCase();
      expectedValue = expectedValue.toLowerCase();
    }
    return evaluateStringCondition(value, operation, expectedValue);
  }

  return evaluateNullCondition(value, operation);
}

function evaluateBooleanCondition(
  value: boolean,
  operation: ConditionOperation,
  expectedValue: string
): boolean {
  const expectedBoolean = expectedValue === 'true';
  switch (operation) {
    case 'Equals':
      return value === expectedBoolean;
    case 'NotEquals':
      return value !== expectedBoolean;
    case 'Null':
      return !value;
    case 'NotNull':
      return !!value;
    default:
      return false;
  }
}

function evaluateStringCondition(
  value: string,
  operation: ConditionOperation,
  expectedValue: string
): boolean {
  const operationsMap: Record<ConditionOperation, () => boolean> = {
    Contains: () => value.includes(expectedValue),
    NotContains: () => !value.includes(expectedValue),
    StartsWith: () => value.startsWith(expectedValue),
    NotStartsWith: () => !value.startsWith(expectedValue),
    EndsWith: () => value.endsWith(expectedValue),
    NotEndsWith: () => !value.endsWith(expectedValue),
    Equals: () => value === expectedValue,
    NotEquals: () => value !== expectedValue,
    GreaterThan: () => value > expectedValue,
    LessThan: () => value < expectedValue,
    Null: () => !value,
    NotNull: () => !!value
  };

  return operationsMap[operation]?.() ?? false;
}

function evaluateNullCondition(
  value: any,
  operation: ConditionOperation
): boolean {
  switch (operation) {
    case 'Null':
      return value === null || value === undefined;
    case 'NotNull':
      return value !== null && value !== undefined;
    default:
      return false;
  }
}
