import {
  AdminPanelSettings,
  ErrorOutline,
  SignalCellular1BarTwoTone,
  SignalCellularAlt1BarTwoTone,
  SignalCellularAlt2BarTwoTone,
  SignalCellularAltTwoTone
} from '@mui/icons-material';
import {
  alpha,
  Box,
  Button,
  Stack,
  styled,
  Tooltip,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { UserStatus, UserStatusTypes } from 'src/models/users';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getRealtimeWSConnectedStore } from 'src/services/rtusers/infraestructure/store/getRealtimeWSConnectedStore';
import { getWebrtcWSConnectedStore } from 'src/services/rtusers/infraestructure/store/getWebrtcWSConnectedStore';
import { setRealtimeWSConnectedStore } from 'src/services/rtusers/infraestructure/store/setRealtimeWSConnectedStore';
import { setWebrtcWSConnectedStore } from 'src/services/rtusers/infraestructure/store/setWebrtcWSConnectedStore';
import wsManager from 'src/services/websocket/manager';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
      padding: ${theme.spacing(1)};
      background-color: ${alpha(theme.colors.alpha.black[100], 0.08)};
  
      .MuiButton-label {
        justify-content: flex-start;
      }
  
      &:hover {
        background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
      }
  `
);

interface IconProps {
  expanded: boolean;
  color: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  r: MutableRefObject<any>;
  status: UserStatus;
}

interface LatencyResponse {
  originTimestamp: string;
  type: 'Ping';
}

let timeoutId = null;
export const StatusIcon: React.FC<IconProps> = ({
  expanded,
  color,
  setIsOpen,
  r,
  status
}) => {
  const connectedRTUser = getConnectedRTUser();
  const { isSuperAdmin } = useContext(PermissionsContext);
  const theme = useTheme();
  const [latency, setLatency] = useState(null);

  const isRealtimeWSConnected = getRealtimeWSConnectedStore(true);
  const isWebrtcWSConnected = getWebrtcWSConnectedStore(true);

  function getIcon() {
    if (latency === 0) {
      return <ErrorOutline fontSize="small" color="error" />;
    }
    if (latency <= 100) {
      return <SignalCellularAltTwoTone color="success" />;
    }
    if (latency <= 150) {
      return <SignalCellularAlt2BarTwoTone color="warning" />;
    }
    return <SignalCellularAlt1BarTwoTone color="error" />;
  }

  useCustomEventListener(
    'webrtc-connection-changed',
    (isConnected: boolean) => {
      setWebrtcWSConnectedStore(isConnected);
    }
  );
  useCustomEventListener(
    'realtime-connection-changed',
    (isConnected: boolean) => {
      setRealtimeWSConnectedStore(isConnected);
    }
  );

  useCustomEventListener(
    CustomEventNames.LATENCY_EVENT,
    (latencyResponse: LatencyResponse) => {
      const latency = latencyResponse.originTimestamp;

      const originTimestamp = new Date(latency);

      const latencyInMs = new Date().getTime() - originTimestamp.getTime();
      setLatency(latencyInMs);

      // Si recibimos la respuesta, cancelamos el timeout
      clearTimeout(timeoutId);
    }
  );

  useEffect(() => {}, []);

  useEffect(() => {
    setTimeout(() => {
      wsManager.sendPing();
    }, 1000);

    const pingInterval = setInterval(() => {
      wsManager.sendPing();

      timeoutId = setTimeout(() => {
        //Si se ejecuta este timeout, es que no hemos recibido respuesta
        setLatency(0);
      }, 5000); // tiempo límite de 5 segundos
    }, 10000); // ping cada 10 segundos

    return () => {
      clearInterval(pingInterval);
      clearTimeout(timeoutId);
    };
  }, []);

  const statusString =
    status?.type === UserStatusTypes.IN_CALL ? 'In call' : status?.name;

  return (
    <UserBoxButton
      ref={r}
      sx={
        expanded
          ? {
              marginTop: '9px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              width: '100%',
              paddingLeft: '20px',
              gap: '18px',
              borderRadius: 0,
              color: 'white',
              cursor: 'pointer'
            }
          : {
              marginTop: '9px',
              paddingY: '0px'
            }
      }
      fullWidth
      color="secondary"
      onClick={() => {
        if (isRealtimeWSConnected && connectedRTUser) {
          setIsOpen(true);
        }
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Stack
          mb={1}
          direction={'row'}
          alignItems={'center'}
          style={{ color: 'white' }}
        >
          {isRealtimeWSConnected && connectedRTUser ? (
            <>
              {latency !== null && (
                <>
                  {getIcon()}
                  <label
                    style={{ color: 'white', fontSize: 12, cursor: 'pointer' }}
                  >
                    {latency === 0 ? 'Offline' : latency}
                  </label>
                </>
              )}
            </>
          ) : (
            latency !== null && (
              <>
                <ErrorOutline fontSize="small" color="error" />
                <label style={{ color: 'white', fontSize: 12 }}>
                  {'Offline'}
                </label>
              </>
            )
          )}
        </Stack>

        {connectedRTUser && (
          <>
            <Tooltip title={t(statusString)} arrow placement="right">
              <Box
                sx={{
                  borderRadius: '100%',
                  backgroundColor: 'white',
                  aspectRatio: 1,
                  height: 40,
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  color: theme.colors.primary.main,
                  position: 'relative'
                }}
              >
                {connectedRTUser?.username.charAt(0)}
                <Box
                  sx={{
                    height: 10,
                    width: 10,
                    backgroundColor: color,
                    position: 'absolute',
                    borderRadius: '50%',
                    left: 29,
                    bottom: 2
                  }}
                ></Box>
              </Box>
            </Tooltip>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'center'}
              marginTop={0.5}
              columnGap={0.5}
              color="white"
            >
              {isSuperAdmin && (
                <Tooltip title={t('Superadmin')} arrow placement="right">
                  <AdminPanelSettings
                    color="inherit"
                    fontSize="small"
                    sx={{
                      marginBottom: 0.25,
                      marginLeft: expanded ? 1 : 0
                    }}
                  />
                </Tooltip>
              )}
              <label
                style={{
                  color: isWebrtcWSConnected ? 'white' : 'red'
                }}
              >
                {connectedRTUser?.extension?.extension}
              </label>
            </Stack>
          </>
        )}
      </Stack>

      {expanded && connectedRTUser && (
        <div style={{ color: 'inherit', fontSize: 18 }}>
          {connectedRTUser?.extension?.extension} -{' '}
          <label style={{ textTransform: 'capitalize' }}>
            {connectedRTUser?.username}
          </label>
        </div>
      )}
    </UserBoxButton>
  );
};
