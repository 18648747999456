import { useContext, useEffect, useRef, useState } from 'react';
import { VideoCallParticipantWithStream } from '.';
import { motion } from 'framer-motion';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { Stack, Typography, useTheme } from '@mui/material';
import useUpdate from 'src/hooks/useUpdateEffect';
import VideoCardAudioLevel from './VideoCardAudioLevel';
import { logger } from 'src/utils/logger';
import useCameraPermission from 'src/modules/VideoCall/hooks/useCameraPermission';
import { VideocamOff } from '@mui/icons-material';
import { t } from 'i18next';

export interface Props {
  muted: boolean;
  data: VideoCallParticipantWithStream;
}

export function VideoCard(props: Props) {
  const isLocal = props.data.id === 'local';
  const permissionToUseCamera = useCameraPermission();

  const videoRef = useRef<HTMLVideoElement>(null);
  const { stream, participant } = props.data;

  const { devices, streams } = useContext(VideocallRoomContext);
  const { speakerDevice, speakerMuted, speakersVolume } = devices;

  const showVideocamOff = isLocal && !permissionToUseCamera;

  useEffect(() => {
    if (speakerDevice?.deviceId && videoRef?.current) {
      try {
        (
          videoRef.current as unknown as HTMLMediaElement & {
            setSinkId(deviceId: string): void;
          }
        ).setSinkId(speakerDevice.deviceId);
      } catch (e) {
        logger.error(e);
      }
    }
  }, [speakerDevice?.deviceId]);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const [key, setKey] = useState(0);

  useUpdate(() => {
    setKey(key + 1);
  }, []);

  useUpdate(() => {
    if (videoRef.current) {
      videoRef.current.volume = speakerMuted ? 0 : speakersVolume / 100;
    }
  }, [speakerMuted, speakersVolume]);

  return (
    <>
      <motion.div
        layoutId={`name-${participant?.id}`}
        style={{
          position: 'absolute',
          bottom: 8,
          left: 8,
          height: '30px',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          padding: '8px',
          borderRadius: '6px',
          fontSize: '12px',
          fontWeight: 'bold',
          fontFamily: 'Inter',
          maxWidth: '80%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {participant?.displayName}
      </motion.div>

      {!(streams.amIScreenSharing && isLocal) && (
        <VideoCardAudioLevel
          key={key}
          stream={stream}
          participant={participant}
        />
      )}
      {showVideocamOff && (
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          rowGap={2}
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <VideocamOff
            style={{
              height: '32px',
              width: '32px'
            }}
          />
          <Typography variant="h5">{t('Enable camera access')}</Typography>
        </Stack>
      )}

      <video
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          borderRadius: '8px',
          outline: 'none',
          display: showVideocamOff ? 'none' : 'block'
        }}
        ref={videoRef}
        autoPlay
        playsInline
        muted={props.muted}
      />
    </>
  );
}
