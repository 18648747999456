import {
  Box,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { WebChatHeader } from './WebChatHeader';
import { WebChatStyle } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatStyle';
import WebChatFooter from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/views/components/ConversationPreview/WebChatFooter';

import WebChatContent from './WebChatContent';
import { useContext } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkReadWebChatStylesPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { t } from 'i18next';

const ChatWindow = styled(Box)(
  ({ theme }) => `
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 508px;
`
);

const RootWrapper = styled(Box)(
  () => `
      height: 570px;
      min-height: 570px;
      min-width: 300px;
      width: 390px;
       display: flex;
       flex-direction: column;
       border: 1px solid #b2b4b8;
       border-radius: 10px;
       -webkit-box-shadow: 9px 9px 12px -3px rgba(107,99,107,0.65);
      -moz-box-shadow: 9px 9px 12px -3px rgba(107,99,107,0.65);
      box-shadow: 9px 9px 12px -3px rgba(107,99,107,0.65);
`
);

interface webChatStyleProps {
  formikProps: WebChatStyle;
}

export const WebChat: React.FC<webChatStyleProps> = ({ formikProps }) => {
  const theme = useTheme();
  const { hasAccess } = useContext(PermissionsContext);

  return (
    <RootWrapper>
      <Stack direction={'column'} height="100%">
        {!hasAccess(checkReadWebChatStylesPermissions) && (
          <Stack
            height={'100%'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography variant="h4">
              {t('You do not have permissions.')}
            </Typography>
          </Stack>
        )}
        {hasAccess(checkReadWebChatStylesPermissions) && (
          <>
            <WebChatHeader formikProps={formikProps} />

            <ChatWindow>
              <Box
                height={`100%`}
                width="100%"
                sx={{
                  backgroundColor:
                    formikProps?.conversationStyle.convBackGroundColor.color ??
                    '#F6F8FB',
                  '&::-webkit-scrollbar': { width: '0.5em' },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor:
                      formikProps?.conversationStyle.scrollbarColor.color ??
                      'rgba(0,0,0,0.2)'
                  }
                }}
                overflow={'scroll'}
              >
                <WebChatContent formikProps={formikProps} />
              </Box>
              <Divider />
              <WebChatFooter formikProps={formikProps} />
            </ChatWindow>
          </>
        )}
      </Stack>
    </RootWrapper>
  );
};
