import { Permissions } from 'src/models/permissionGroups';
import { ContactTabType } from '..';
import {
  checkCreateContactFieldsPermissions,
  checkCreateContactGroupPermissions,
  checkCreateContactPermissions,
  checkCreateContactTagsPermissions,
  checkUpdateContactGroupPermissions,
  checkUploadContactPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

export const checkContactTabsPermissions =
  (tab: ContactTabType) => (permissions: Permissions) => {
    switch (tab) {
      case 'contacts':
        return (
          checkCreateContactPermissions(permissions) ||
          checkUploadContactPermissions(permissions)
        );
      case 'contactGroups':
        return checkCreateContactGroupPermissions(permissions);
      case 'fields':
        return (
          checkCreateContactFieldsPermissions(permissions) &&
          checkUpdateContactGroupPermissions(permissions)
        );
      case 'tags':
        return (
          checkCreateContactTagsPermissions(permissions) &&
          checkUpdateContactGroupPermissions(permissions)
        );
      default:
        return true;
    }
  };
