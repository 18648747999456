import { launchCallApi } from './launchCallApi';
import { parkCallApi } from './parkCallApi';
import { unParkCallApi } from './unparkCallApi';
import { hangupCallApi } from './hangupCallApi';
import { spyCall } from './spyCall';
import { transferCallApi } from './transferCallApi';
import { attendedTransferCallApi } from './attendedTransferCallApi';
import { completeAttendedTransferCallApi } from './completeAttendedCallApi';
import { addToConferenceApi } from './addToConferenceApi';
import { removeFromConference } from './removeFromConferenceApi';

const CallManagerApi = {
  launchCallApi,
  parkCallApi,
  unParkCallApi,
  hangupCallApi,
  spyCall,
  transferCallApi,
  attendedTransferCallApi,
  completeAttendedTransferCallApi,
  addToConferenceApi,
  removeFromConference
};

export default CallManagerApi;
