import React from 'react'
import getMessageByIdApi from '../../infraestructure/api/messages/getMessageByIdApi';
import { ChatMessage } from 'src/models/conversations/messages';


interface Props {
    conversationId: string;
    messageId: string;
}

const getMessageById = async (params: Props): Promise<ChatMessage> => {
    const mes =  await getMessageByIdApi(params)
    return mes;
}

export default getMessageById