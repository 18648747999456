import axios from 'axios';
import AuthManager from '../../authentication/manager';
import { USERS } from 'src/utils/apiRoutes/msUsers';

export const getUserById: Function = () => {
  const path = USERS;

  let params = { id: AuthManager.getUserId() };

  return axios
    .get(path, { params })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getUsers: Function = () => {
  const path = USERS;

  return axios
    .get(path)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};
