import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TicketMetadata } from '../../models/tickets';

interface Props {
  ticketMetadata: TicketMetadata;
}

const TicketData = ({ ticketMetadata }: Props) => {
  const creationDate = new Date(ticketMetadata?.creationDate).toLocaleString();
  const modificationDate = new Date(
    ticketMetadata?.modificationDate
  ).toLocaleString();
  const { t }: { t: any } = useTranslation();
  return (
    <Box p={1}>
      <Typography variant="subtitle1">
        <b>{t('Creation date:')}</b> {creationDate}
      </Typography>
      <Typography variant="subtitle1">
        <b>{t('Last modification date:')}</b> {modificationDate}
      </Typography>
    </Box>
  );
};

export default TicketData;
