import { ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const DialTableNoResultsFilterItem = () => {
  const { t } = useTranslation();
  return (
    <ListItem sx={{ display: 'block' }}>
      <Typography textAlign="center">{t('No results')}</Typography>
    </ListItem>
  );
};
