import store, { useSelector } from 'src/store/store';

/** GET CAMERA DEVICE IN STORE
 *
 * @param shouldUseHook
 */

export const getDevicesStore = (shouldUseHook: boolean) => {
  const devices = shouldUseHook
    ? useSelector((state) => state.configVolume.devices)
    : store.getState().configVolume.devices;

  return devices;
};
