import { GroupAddOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CtiPopover } from '../../../CtiPopover';
import { AddMembersToConferenceModal } from './AddAgentModal';
import { getRTcalls } from 'src/services/rtcalls/domain/getRTCalls';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { CTIPopoverNames } from 'src/contexts/CTIBarContext';

export const ConferenceButton: React.FC = () => {
  const { t } = useTranslation();
  const user = getConnectedRTUser();
  const activeCall = getActiveRTCall(user);

  const conferenceCalls = getRTcalls({
    shouldUseHook: true,
    callId: activeCall?.callId
    // status: 'Conference'
  });

  return (
    <CtiPopover
      title={CTIPopoverNames.CONFERENCE}
      icon={
        <Tooltip title={t('Conference')}>
          <IconButton>
            <GroupAddOutlined fontSize="medium" />
          </IconButton>
        </Tooltip>
      }
    >
      <Stack
        width={350}
        p={2}
        spacing={2}
        alignItems="center"
        divider={<Divider orientation="horizontal" />}
      >
        <AddMembersToConferenceModal calls={conferenceCalls} />
      </Stack>
    </CtiPopover>
  );
};
