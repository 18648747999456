import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  Contact,
  ContactsRequestParams
} from 'src/modules/Contacts/models/contacts';
import { Conversation } from 'src/models/conversations/conversations';
import {
  getContactById,
  getContactByFieldValue,
  getContactsFromConversation
} from 'src/modules/Contacts/domain/getContacts';
import { queryClient } from 'src/utils/queryClient';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';

export const useGetContactsByFieldQuery = (
  params: ContactsRequestParams
): UseQueryResult<Contact> => {
  return useQuery<Contact, Error>(
    ['contactsByField', params],
    () => getContactByFieldValue(params),
    { enabled: true }
  );
};

export const getContactsByFieldQuery = (
  params: ContactsRequestParams
): Promise<Contact> => {
  return queryClient.fetchQuery(['contactsByField', params], {
    queryFn: () => getContactByFieldValue(params),
    staleTime: 60000
  });
};

export const getContactNameQuery = async (id: string) => {
  const contact = await getContactByIdQuery(id);
  if (!contact) return null;

  const displayName = await getContactName(contact);

  return displayName;
};

export const useGetContactNameQuery = (id: string, enabled: boolean = true) => {
  return useQuery(['contactName', id], () => getContactNameQuery(id), {
    enabled,
    staleTime: 0
  });
};

export const getContactByIdQuery = async (id: string) => {
  const contact = await queryClient.fetchQuery(['contact', id], {
    queryFn: () => getContactById(id),
    staleTime: 60000
  });
  return contact;
};

export const useGetContactQuery = (id: string) => {
  return useQuery({
    queryKey: ['contact', id],
    queryFn: () => getContactById(id),
    enabled: true,
    staleTime: 60000
  });
};

export const getContactsFromConversationQuery = (
  conversation: Conversation,
  waitFor: any
): UseQueryResult<Contact[]> => {
  return useQuery<Contact[], Error>(
    ['contactsConversation', conversation.id],
    () => getContactsFromConversation(conversation),
    { enabled: !!waitFor, staleTime: 0 }
  );
};
