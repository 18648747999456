import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import acdRoutes from '../modules/Acd/routes/acdRoutes';
import contactCenterRoutes from '../modules/ContactCenter/routes/contactCenterRoutes';
import operationsRoutes from '../modules/Operations/routes/operationsRoutes';
import generalRoutes from '../modules/General/routes/generalRoutes';
import qualityRoutes from '../modules/Quality/routes/qualityRoutes';
import integrationsRoutes from '../modules/Integrations/routes/integrationsRoutes';
import callsRoutes from '../modules/Calls/routes/callsRoutes';
import massiveRoutes from '../modules/Massive/routes/massiveRoutes';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Acd = Loader(lazy(() => import('../modules/Acd')));
const ContactCenter = Loader(lazy(() => import('../modules/ContactCenter')));
const Integrations = Loader(lazy(() => import('../modules/Integrations')));
const Operations = Loader(lazy(() => import('../modules/Operations')));
const Quality = Loader(lazy(() => import('../modules/Quality')));
const General = Loader(lazy(() => import('../modules/General')));
const Calls = Loader(lazy(() => import('../modules/Calls')));
const Massive = Loader(lazy(() => import('../modules/Massive')));

const adminRoutes: RouteObject[] = [
  {
    path: 'acd',
    element: <Acd />,
    children: acdRoutes
  },
  {
    path: 'contact-center',
    element: <ContactCenter />,
    children: contactCenterRoutes
  },
  {
    path: 'integrations',
    element: <Integrations />,
    children: integrationsRoutes
  },
  {
    path: 'operations',
    element: <Operations />,
    children: operationsRoutes
  },
  {
    path: 'quality',
    element: <Quality />,
    children: qualityRoutes
  },
  {
    path: 'general',
    element: <General />,
    children: generalRoutes
  },
  {
    path: 'calls',
    element: <Calls />,
    children: callsRoutes
  },
  {
    path: 'massive',
    element: <Massive />,
    children: massiveRoutes
  }
];

export default adminRoutes;
