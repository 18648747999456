import { useDispatch, useSelector } from 'src/store/store';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';
import { connectDevices } from 'src/services/webrtc/webrtc.service';
import { setAudioDevice } from 'src/store/slices/users/configVolumeSlice';
import { useContext } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { videoSpeakerDeviceKey } from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';

export const useVideocallSpeaker = () => {
  const { devices } = useContext(VideocallRoomContext);

  const { speakerDevice, setSpeakerDevice } = devices;

  const mediaDevices = getDevicesStore(true).filter((d) => {
    return d.kind === 'audiooutput';
  });

  const onSelectChange = (labelSelected: string): void => {
    const newDevice = mediaDevices.filter((device) => {
      return device.label === labelSelected;
    })[0];

    localStorage.setItem(videoSpeakerDeviceKey, newDevice.deviceId);
    setSpeakerDevice(newDevice);
  };

  return [speakerDevice, mediaDevices, onSelectChange] as [
    MediaDeviceInfo,
    MediaDeviceInfo[],
    (labelSelected: string) => void
  ];
};
