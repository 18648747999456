import axios from 'axios';
import qs from 'qs';
import {
  ChannelType,
  Conversation,
  ConversationStatus,
  UserConversation
} from 'src/models/conversations/conversations';
import { PaginatedElements } from 'src/models/pagination';
import { getMessagesApi } from '../../socialmedia/infraestructure/api/messages/getMessagesApi';
import { replaceURLParams } from 'src/services/utils';
import {
  CONVERSATIONS,
  CONVERSATIONS_BY_USER,
  CONVERSATIONS_ID
} from 'src/utils/apiRoutes/msConversations';

export interface APIConversationParams {
  assignedUserId?: string;
  channel?: ChannelType[];
  companyId?: string;
  contactId?: string;
  page?: number;
  phoneNumber?: string;
  size?: number;
  status?: ConversationStatus[];
  ticketId?: string;
  withMessages?: boolean;
  unreadOnly?: boolean;
}

/** FILTER CONVERSATIONS FROM API
 *
 * Return all conversations if no size and page specified.
 *
 * @param params Parameters to filter the conversations
 * @returns Array of conversations filtered according to the provided APIConversationFilters
 */

export const getConversationsApi = async (
  params: APIConversationParams
): Promise<PaginatedElements<Conversation>> => {
  const { withMessages, ...restParams } = params;

  const response = await axios.get(CONVERSATIONS, {
    params: restParams,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' })
  });

  if (withMessages) {
    for await (let conv of response.data.elements) {
      const messages = await getMessagesApi({ conversationId: conv.id });
      conv.messages = messages.elements;
    }
  }

  return response.data;
};

export const getConversationsByUserApi = async (filters: {
  assignedUserIds: string[];
  channel?: ChannelType;
  status?: ConversationStatus;
  userLimit?: number;
}): Promise<UserConversation[]> => {
  const { assignedUserIds, channel, status, userLimit } = filters;
  const params = { channel, status, userLimit };
  const resp = await axios.post(CONVERSATIONS_BY_USER, assignedUserIds, {
    params
  });
  return resp.data;
};

export const getConversationByIdApi = async (
  id: string
): Promise<Conversation> => {
  const path = replaceURLParams(CONVERSATIONS_ID, {
    '{id}': id
  });
  const resp = await axios.get(path);
  return resp.data;
};
