import { useQuery } from '@tanstack/react-query';
import {
  getIntentionModelApi,
  getIntentionsModelsApi
} from '../infrastructure/getIntentionModel';
import { AIModel } from '../models/AIModels';

/** GET INTENTIONS MODELS
 *
 * Get Intentions Models in database
 *
 *
 * @returns INTENTIONS MODELS
 */
export const getIntentionsModels = async (filters?: {
  name?: string;
  companyId?: string;
}): Promise<AIModel[]> => {
  const intentionModel = await getIntentionsModelsApi(filters);
  return intentionModel;
};

export function useIntentionsModelsQuery(filters?: {
  name?: string;
  companyId?: string;
}) {
  return useQuery({
    queryKey: ['intentionsModels', ...Object.values(filters)],
    queryFn: () => getIntentionsModels(filters),
    staleTime: 1000 * 60 * 5
  });
}

/** GET INTENTION MODEL
 *
 * Get Intention Model in database
 *
 *
 * @returns INTENTION MODEL
 */
export const getIntentionModel = async (id: string): Promise<AIModel> => {
  const intentionModel = await getIntentionModelApi(id);
  return intentionModel;
};
