import { WebRTCSession } from 'src/store/slices/calls/types';
import store, { useSelector } from 'src/store/store';

export const getSessionsStore = (shouldUseHook: boolean) => {
  const allSessions = (
    shouldUseHook
      ? useSelector((state) => state.callData.sessions)
      : store.getState().callData.sessions
  ) as WebRTCSession[];

  return allSessions;
};
