import { getUniqueObjects } from 'src/utils/getUniqueObjects';
import { DialTableFilter } from '../models/filters';

export const getUniqueFilters = <T>(columns: DialTableFilter<T>[]) => {
  const uniqueColumns = getUniqueObjects(
    columns,
    (filter) => filter.fieldName as string
  );
  return uniqueColumns;
};
