import { TableFilterSelector } from 'src/components/Table/models/TableFilters';
import { ConversationTableColumns } from 'src/models/conversations/conversations';
import { APIConversationParams } from 'src/services/conversations/infrastructure/getConversationsApi';

export let conversationParams: APIConversationParams = {
  assignedUserId: '',
  withMessages: false,
  contactId: ''
};

export const selectorFilters: TableFilterSelector<ConversationTableColumns>[] =
  [
    {
      type: 'selector',
      name: 'Status',
      key: 'status',
      options: [
        { value: 'Assigned', label: 'Assigned' },
        { value: 'Closed', label: 'Closed' },
        { value: 'Queued', label: 'Queued' }
      ]
    }
  ];

export enum ConversationTabs {
  GENERAL = 'General',
  CONVERSATION = 'Conversation',
  CONTACTS = 'Contacts'
}
