import ReactDOMServer from 'react-dom/server';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';
import { t } from 'i18next';

const CustomRightArrowIcon = () => {
  const rightArrow = (
    <Box title={t('Move Forward')}>
      <ArrowForwardIcon />
    </Box>
  );

  // Convierte el componente de React a una cadena de texto HTML
  const rightArrowHtml = ReactDOMServer.renderToStaticMarkup(rightArrow);

  return rightArrowHtml;
};

export default CustomRightArrowIcon;
