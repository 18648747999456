import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';
import createInstagramAccountApi from '../../infrastructure/account/createInstagramAccoutnApi';
import { emitCustomEvent } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';

async function createInstagramAccount(instagramUsername: string) {
  const companyId = getLoggedUserCompanyId();
  await createInstagramAccountApi({ companyId, instagramUsername });
}

export default createInstagramAccount;
