import { Contact } from 'src/modules/Contacts/models/contacts';
import { unassignContactFromConversations } from 'src/services/socialmedia/domain/conversation/updateConversations';

import { deleteContactApi } from '../infraestructure/api/deleteContactApi';

/** DELETE CONTACT
 *
 *  @param contactID Contact id
 */
export const deleteContact = async (contact: Contact): Promise<void> => {
  await deleteContactApi(contact);

  unassignContactFromConversations(contact.id);
};

export const deleteContacts = async (contacts: Contact[]): Promise<void> => {
  const contactPromises = contacts.map((contact) => deleteContact(contact));
  await Promise.all(contactPromises);
};
