import {
  CampaignsParams,
  getCampaignsApi
} from 'src/modules/Admin/modules/Operations/modules/DaServices/infrastructure/campaigns/getCampaigns';

/** GET ONLY CAMPAIGNS OF USER COMPANY
 *
 * @returns array of campaigns
 */
export const getCampaignsOfUserCompany = async (
  campaignsParams?: CampaignsParams
) => {
  const campaigns = await getCampaignsApi(campaignsParams);
  return campaigns;
};
