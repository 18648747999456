import { DialTableFilter, FiltersObject } from '../models/filters';

export const getFiltersObject = <T extends Object>(
  filters: DialTableFilter<T>[]
) => {
  const filtersObject: FiltersObject = filters.reduce((prev, curr) => {
    return { ...prev, [curr.fieldName]: curr?.values };
  }, {});
  return filtersObject;
};
