import { deletePromptElementApi } from '../../infrastructure/api/prompt/deletePromptElementApi';
import { invalidatePromptElementsQuery } from './getPromptElements';

export async function deletePromptElement(id: string) {
  await deletePromptElementApi(id);
  invalidatePromptElementsQuery();
}

export async function deletePromptElements(ids: string[]) {
  const promises = ids.map((id) => deletePromptElement(id));
  await Promise.all(promises);
  invalidatePromptElementsQuery();
}
