import { Suspense, lazy } from 'react';

import { Navigate } from 'react-router-dom';
import RecoverPasswordBasic from '../views/RecoverPassword';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import { VideocallRoomContextProvider } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { VideoCallsView } from 'src/modules/VideoCall';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Account

const LoginBasic = Loader(
  lazy(() => import('src/modules/Auth/views/Login/Basic'))
);
const Dashboards = Loader(lazy(() => import('src/modules/Dashboards')));

const authRoutes = [
  {
    path: '',
    element: (
      <Guest>
        <LoginBasic />
      </Guest>
    )
  },

  {
    path: '/videocalls',
    element: (
      <VideocallRoomContextProvider>
        <VideoCallsView isAgent={false} />
      </VideocallRoomContextProvider>
    )
  },
  {
    path: '/videocallsAgent',
    element: (
      <VideocallRoomContextProvider>
        <VideoCallsView isAgent={true} />
      </VideocallRoomContextProvider>
    )
  },
  /*{
    path: '/videocalls/preroom',
    element: <PreVideoCallRoom />
  },*/

  {
    path: '/dashboards',
    element: <Dashboards />
  },

  {
    path: 'account/recover-password',
    element: (
      <Guest>
        <RecoverPasswordBasic />
      </Guest>
    )
  },
  {
    path: 'account/*',
    element: <Navigate to="/" replace />
  }
];

export default authRoutes;
