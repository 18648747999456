import {
  ContactField,
  Contact,
  ContactFields,
  FakeContactFields
} from 'src/modules/Contacts/models/contacts';
import {
  ChannelType,
  Conversation,
  ConversationContact,
  ConversationStatus,
  UserConversation
} from 'src/models/conversations/conversations';
import { getContactByIdQuery } from '../../application/query/getContactsQuery';
import { GetConversationsFilter } from '../../../conversations/domain/getConversations';
import { selectedConversationChannelsFilter } from 'src/store/slices/socialmedia/socialmediaSlice';
import { RTCall } from 'src/models/rtcall';
import { getContactFieldsQuery } from '../../application/query/getCompanyContactFieldsQuery';
import {
  CallCampaign,
  Campaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { logger } from 'src/utils/logger';
import { getWebChatAccounts } from 'src/services/webchat/domain/webChatAccount/getWebChatAccounts';
import { getContactsQuery } from 'src/modules/Contacts/views/utils/getContactsQuery';

export const filterConversations = (
  conversations: Conversation[],
  filter: GetConversationsFilter
) => {
  let filteredConversations =
    conversations?.filter((conv) => {
      if (filter.id && filter.id !== conv.id) return false; // Check same id
      if (filter.channel && !filter.channel.includes(conv.channel))
        return false; // Check same channel
      if (filter.status && !filter.status.includes(conv.status)) return false; // Check same status
      if (filter.rtUsers) {
        const rtUserIds = filter.rtUsers
          .filter((rtUser) => rtUser && rtUser.id)
          .map((rtUser) => rtUser.id);

        const isAnyUserAssigned = conv.assignedUserIds?.some((uid) =>
          rtUserIds.includes(uid)
        );

        const anyUserHasCall = filter.rtUsers
          .filter((rtUser) => rtUser && rtUser.calls)
          .some((rtUser) => {
            return rtUser.calls.some((rtcall) => {
              return rtcall?.callId === filter.callId;
            });
          });
        if (!isAnyUserAssigned && !anyUserHasCall) return false; // Check assigned user
      }

      if (filter.callId && filter.callId !== conv.originAgent?.callId)
        return false; // Check callId

      return true;
    }) ?? [];

  return filteredConversations;
};

/**Delete Call conversations from Conversations which are not in RTCalls*/
export const filterFinishedCallConversations = (
  conversations: Conversation[],
  rtCalls: RTCall[]
) => {
  // const rtCallIds = rtCalls.map((rtCall) => rtCall.callId);
  return conversations.filter((conversation) => {
    if (conversation.channel !== 'Call') return true;
    // return rtCallIds.includes(conversation.originAgent.callId);
    return rtCalls.some(
      (rtCall) => rtCall.callId === conversation.originAgent?.callId
    );
  });
};

// /** Returns an object whose keys are of type ChannelType and the value for each key is a Conversation array with channel equals to ChannelType key*/
// export const getGroupedConversationsByChannel = (
//   conversations: Conversation[],
//   channels: ChannelType[]
// ): { [key in ChannelType]: Conversation[] } => {
//   let groupedConversations = {};
//   channels.forEach((channel) => {
//     groupedConversations[channel] = [];
//   });
//   if (conversations?.length > 0) {
//     conversations.forEach((conversation) => {
//       if (channels.includes(conversation.channel)) {
//         groupedConversations[conversation.channel] = [
//           ...groupedConversations[conversation.channel],
//           conversation
//         ];
//       }
//     });
//   }
//   return groupedConversations as { [key in ChannelType]: Conversation[] };
// };

/** Returns an object whose keys are RTUser ids concatenated with ChannelType separated by '-' and the value for each key is a Conversation array with conversations in which the RTUser id is included in assignedUserIds and the channel is the ChannelType key*/
// export const getRTUserChannelConversationsObject = (
//   rtUser: RTUser
// ): { [channel in ChannelType]: Conversation[] } => {
//   const allChannels = [
//     'Call',
//     'Email',
//     'Telegram',
//     'WebChat',
//     'WhatsApp'
//   ] as ChannelType[];
//   const conversations = rtUser?.conversations || [];

//   const conversationsByChannel = getGroupedConversationsByChannel(
//     conversations,
//     allChannels
//   );

//   return conversationsByChannel;
// };

export const filterConversationsByStatus = (
  conversations: Conversation[],
  status: ConversationStatus[]
) => {
  return conversations.filter((conversation) =>
    status.includes(conversation.status)
  );
};

export const filterConversationsByChannel = (
  conversations: Conversation[],
  channel: ChannelType[]
) => {
  return conversations.filter((conversation) =>
    channel.includes(conversation.channel)
  );
};

export const filterConversationsByCompanyId = (
  conversations: Conversation[],
  companyId: string
) => {
  return conversations.filter(
    (conversation) => conversation.companyId === companyId
  );
};

export const formatUserName = (userName: string) => {
  if (!userName) return '';
  if (userName.trim() === '') return '';
  return '@'.concat(userName);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  if (phoneNumber.trim() === '') return '';
  return '+'.concat(phoneNumber);
};

export const getConversationsFromUserConversations = (
  userConversations: UserConversation[]
): Conversation[] => {
  return userConversations.flatMap((userConversation) => {
    return userConversation.elements;
  });
};

export interface createContactFromInvolvedParams {
  nameField: ContactField;
  phoneField: ContactField;
  emailField: ContactField;
  telegramField: ContactField;
  telegramChatIdField: ContactField;
  involvedContact: ConversationContact;
  instagramField: ContactField;
  conversation: Conversation;
}

export const createContactFromInvolved = async (
  params: createContactFromInvolvedParams
): Promise<Contact> => {
  const {
    nameField,
    phoneField,
    emailField,
    telegramField,
    telegramChatIdField,
    involvedContact,
    conversation,
    instagramField
  } = params;

  // Si el involvedContact es un contacto añadido, lo buscamos.
  if (involvedContact.contactId) {
    const contactData = await getContactByIdQuery(involvedContact.contactId);
    if (!!contactData) return contactData;
  }

  // Si no hay contacto, se crea uno fake
  const contact: Contact = {
    companyId: conversation.companyId,
    fields: {},
    contactGroupId: undefined
  };

  if (nameField) {
    // Inicialmente establecemos el nombre del contacto a ''
    contact.fields[nameField.name] = '';
  }

  // Para tipo llamadas, seteamos su telefono
  if (conversation.channel === 'Call') {
    contact.fields['Phone'] = involvedContact.socialmediaAgent.endpoint;
    contact.fields.label = involvedContact.socialmediaAgent.endpoint;
  }
  if (conversation.channel === 'Email') {
    contact.fields['Email'] = involvedContact.socialmediaAgent.emailAddress;
    contact.fields.label = involvedContact.socialmediaAgent.emailAddress;
  }
  if (conversation.channel === 'Telegram') {
    const { phoneNumber, userName, fullName, chatId } =
      getInvolvedContactData(involvedContact);
    const fakeFields = contact.fields as FakeContactFields;

    if (!nameField) {
      fakeFields.Name = fullName;
    }
    if (!phoneField) {
      fakeFields.Phone = phoneNumber;
    }
    if (!telegramField) {
      fakeFields.User = phoneNumber || userName;
    }
    if (!telegramChatIdField) {
      fakeFields['telegramId'] = chatId;
    }
    contact.fields = fakeFields;
    contact.fields.label = userName;
  }

  if (conversation.channel === 'WhatsApp') {
    const fakeFields = contact.fields as FakeContactFields;

    if (involvedContact.socialmediaAgent.phoneNumber) {
      fakeFields.Phone = involvedContact.socialmediaAgent.phoneNumber;
    }
    if (involvedContact.socialmediaAgent.userName) {
      fakeFields.Name = involvedContact.socialmediaAgent.userName;
    }

    contact.fields.label = involvedContact.socialmediaAgent.phoneNumber;
  }

  if (conversation.channel === ChannelType.INSTAGRAM) {
    const fakeFields = contact.fields as FakeContactFields;

    fakeFields.User = involvedContact.socialmediaAgent.userName;

    contact.fields.label = involvedContact.socialmediaAgent.userName;
  }

  if (conversation.channel === ChannelType.WEBCHAT) {
    contact.fields.label = involvedContact.socialmediaAgent.userName;
  }

  return contact;
};

// For now, function focused only on Telegram channel
export const getInvolvedContactData = (
  involvedContact: ConversationContact
) => {
  const { phoneNumber, userName, firstName, lastName, chatId, username } =
    involvedContact?.socialmediaAgent;

  const fullName =
    firstName && lastName ? `${firstName} ${lastName}`.trim() : undefined;

  return {
    chatId: chatId,
    contactId: involvedContact?.contactId,
    firstName: firstName || '',
    fullName: fullName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    userName: userName || '',
    username: username || ''
  };
};

export const getChatInvolvedContactName = (
  involvedContact: ConversationContact
) => {
  const { userName, fullName, username } =
    getInvolvedContactData(involvedContact);
  return username
    ? username
    : userName
      ? userName
      : fullName
        ? fullName
        : 'Hidden';
};

/**
 * @param conversation
 * @returns matching contacts and the contact created with conversation's initial form
 */
export const getMatchesWebchat = async (conversation: Conversation) => {
  if (conversation?.channel !== ChannelType.WEBCHAT)
    return { matches: [], contact: null };

  const webChatForm = conversation.originAgent.webChatForm;

  const webChatAccount = (
    await getWebChatAccounts({ id: conversation.accountId })
  ).elements?.[0];
  const initialForm = webChatAccount.initialForm;
  const contactGroupId = webChatAccount.contactGroupId;
  const companyId = webChatAccount.companyId;
  let matchByField = null;

  const fields: ContactFields = initialForm.reduce((acc, initialFormField) => {
    const match = webChatForm.find(
      (formField) => initialFormField.labelName === formField.name
    );
    if (!!match) {
      acc[initialFormField.contactFieldId] = match.value;
    }
    if (!!match && initialFormField.matchBy) {
      matchByField = [initialFormField, match.value.trim()];
    }
    return acc;
  }, {});

  const contact: Contact = {
    companyId: companyId,
    fields,
    contactGroupId: contactGroupId,
    tags: []
  };

  const contacts = await getContactsQuery({
    fieldType: matchByField[0].type,
    fieldValue: matchByField[1],
    companyId: webChatAccount.companyId,
    exactMatch: false
  });
  return { matches: contacts.elements, contact };
};

//Return a contact or the client info like name, phone, email, telegramUsername
export const getConversationInvolvedContacts = async (
  conversation: Conversation
): Promise<Contact[]> => {
  let contacts: Contact[] = [];
  if (!conversation) {
    logger.error('No conversation');
    return Promise.resolve([]);
  }

  const contactPromises = conversation?.involvedContacts.map(
    async (involvedContact: ConversationContact) => {
      const contact = await getContactByIdQuery(involvedContact.contactId);

      const contactGroupFields = (
        await getContactFieldsQuery()
      )?.elements?.filter(
        (field) => field.contactGroupId === contact?.contactGroupId
      );

      const nameField = contactGroupFields?.find(
        (field) => field.type === 'Name' && field.mainField
      );

      const phoneField = contactGroupFields?.find(
        (field) => field.type === 'Phone' && field.mainField
      );

      const emailField = contactGroupFields?.find(
        (field) => field.type === 'Email' && field.mainField
      );

      const telegramField = contactGroupFields?.find(
        (field) => field.type === 'TelegramUsername' && field.mainField
      );
      const telegramChatIdField = contactGroupFields?.find(
        (field) => field.type === 'TelegramChatId' && field.mainField
      );
      const instagramField = contactGroupFields?.find(
        (field) => field.type === 'InstagramUsername' && field.mainField
      );

      let finalContact = await createContactFromInvolved({
        nameField,
        phoneField,
        emailField,
        telegramField,
        telegramChatIdField,
        involvedContact,
        instagramField,
        conversation
      });

      finalContact.label = getContactLabelName(
        contact,
        nameField,
        phoneField,
        emailField,
        telegramField,
        instagramField
      );

      return finalContact;
    }
  );

  contacts = await Promise.all(contactPromises);

  return [...contacts];
};

export const getContactLabelName = (
  contact: Contact,
  nameField: ContactField,
  phoneField: ContactField,
  emailField: ContactField,
  telegramField: ContactField,
  instagramField: ContactField
): { name: string; value: string } => {
  const name = contact?.fields[nameField?.id];
  const email = contact?.fields[emailField?.id];
  const telegramUsername = contact?.fields[telegramField?.id];
  const phone = contact?.fields[phoneField?.id];
  const instagramUsername = contact?.fields[instagramField?.id];

  return (
    (name && {
      name: nameField.name,
      value: name
    }) ||
    (email && {
      name: emailField.name,
      value: email
    }) ||
    (telegramUsername && {
      name: telegramField.name,
      value: telegramUsername
    }) ||
    (instagramUsername && {
      name: instagramField.name,
      value: instagramUsername
    }) ||
    (phone &&
      phone !== '' && {
        name: phoneField.name,
        // name: telegramField.name,
        value: phone
      })
  );
};

export const getChannelsArrayFromSelectedChannelsFilter = (
  selectedChannels: selectedConversationChannelsFilter
) => {
  return Object.entries(selectedChannels)
    .filter((entry) => {
      const isSelected = entry[1];
      return isSelected;
    })
    .map((entry) => {
      const channel = entry[0] as ChannelType;
      return channel;
    }) as ChannelType[];
};

export const getStatussArrayFromShowClosedConversationsFilter = (
  showClosedConversations: boolean
): ConversationStatus[] => {
  return showClosedConversations
    ? [ConversationStatus.ASSIGNED, ConversationStatus.CLOSED]
    : [ConversationStatus.ASSIGNED];
};

export interface ContactNames {
  [key: string]: string;
}

export const getAllContactNames = async (
  contact: Contact
): Promise<ContactNames> => {
  if (!contact) return {};

  let contactNames: ContactNames = {};

  const nameFields = await getContactFieldsQuery({
    contactGroupId: contact.contactGroupId,
    type: 'Name'
  });

  if (!nameFields || nameFields.elements.length === 0) return {};

  nameFields.elements.forEach((field) => {
    let fieldValue = contact.fields?.[field.id];
    if (!fieldValue) return;

    contactNames[field.name] = fieldValue;
  });

  return contactNames;
};

// This is a temporal array
export const HOTLINE_COMPANIES = [
  '645e2b9ce339381ed1b65400',
  '649c174434593b6b1599a7c2' // Will be removed once Katty complete test in dev,
  // '649a7fc3a812b911a107936e'
];

export const useSgiByCampaign = (campaign: Campaign) => {
  if (campaign) {
    if (!(campaign as CallCampaign).callMode) return false;
    if (
      campaign.channel === ChannelType.CALL &&
      campaign.callMode === 'Outbound'
    )
      return false;
    if (HOTLINE_COMPANIES.includes(campaign.companyId)) return true;
  }
  return false;
};
