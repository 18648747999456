import { Divider, Stack, styled } from '@mui/material';

import { useContext } from 'react';
import { InboxPanelsContext } from 'src/contexts/InboxPanelsContext';
import { HelperTab } from '../../../HelperMenu/items';
import HelperPanelHeaderButtonItem from './HelperPanelHeaderButtonItem';
import { getHelperMenuItems } from '../../../utils/getInboxPanels';
import { t } from 'i18next';

const CustomStack = styled(Stack)(
  ({ theme }) => `
  overflow: auto;
  ::-webkit-scrollbar {
      height: 5px;
  }
  
  ::-webkit-scrollbar-track {
      background: ${theme.colors.alpha.black[10]}; /* Color of the track */
  }
  
  ::-webkit-scrollbar-thumb {
      background: ${theme.colors.alpha.black[50]}; /* Color of the thumb */
      border-radius: 5px; /* Roundness of the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background:${theme.colors.alpha.black[70]}; /* Color of the thumb on hover */
  }
      `
);

type Props = {
  helperTab: HelperTab;
};

const HelperPanelHeaderButtons = (props: Props) => {
  const { helperTab } = props;
  const { setHelperPanel, helperMenuItems } = useContext(InboxPanelsContext);
  const menuItems = helperMenuItems ? helperMenuItems : getHelperMenuItems();

  return (
    <Stack direction="row" overflow="scroll">
      <Divider orientation="vertical" variant="middle" flexItem />
      <CustomStack direction="row" spacing={0.5} py={1} px={1} minWidth="150px">
        {Object.entries(menuItems).map(([key, value]) => {
          const Icon = value.icon;
          return (
            <HelperPanelHeaderButtonItem
              key={key}
              label={t(value.label)}
              icon={<Icon />}
              isSelected={key === helperTab}
              onClick={() => {
                setHelperPanel(key as HelperTab);
              }}
            />
          );
        })}
      </CustomStack>
    </Stack>
  );
};

export default HelperPanelHeaderButtons;
