export function detectDelimiter(text: string): string {
  const delimiters = [',', ';', '|', '.', ':', '-', '_'];
  let maxDelimiter = '';
  let maxCount = 0;

  delimiters.forEach((delimiter) => {
    const count = text.split('\n')[0].split(delimiter).length;
    if (count > maxCount) {
      maxCount = count;
      maxDelimiter = delimiter;
    }
  });

  return maxDelimiter;
}
