import {
  checkReadContactFieldsPermissions,
  checkReadContactGroupPermissions,
  checkReadContactPermissions,
  checkReadContactTagsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { ContactTabType } from '..';
import { Permissions } from 'src/models/permissionGroups';

export const contactTabsPermissionsCheckerObject: {
  [key in ContactTabType]: (permissions: Permissions) => boolean;
} = {
  contactGroups: checkReadContactGroupPermissions,
  contacts: checkReadContactPermissions,
  fields: checkReadContactFieldsPermissions,
  tags: checkReadContactTagsPermissions
};
