import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RTUser } from 'src/models/rtUser';
import { RTQueue } from 'src/models/queue';
import { RTCall } from 'src/models/rtcall';

interface RTCallsState {
  rtCalls: { [key: string]: RTCall };
  rtCallIds?: string[];
}

const initialState: RTCallsState = {
  rtCalls: {}
};

export const conversationsSlice = createSlice({
  name: 'rtcalls',
  initialState,
  reducers: {
    setRTCalls: (state, action: PayloadAction<RTCall[]>) => {
      let rtCalls = action.payload;
      rtCalls.forEach((rtc) => {
        state.rtCalls[rtc.id] = { ...rtc };
      });
      state.rtCalls = { ...state.rtCalls };
      state.rtCallIds = Object.keys(state.rtCallIds);
    },
    setRTCall: (state, action: PayloadAction<RTCall>) => {
      const currentState = state.rtCalls[action.payload.id];
      if (!currentState)
        state.rtCallIds = [...state.rtCallIds, action.payload.id];

      // Set RTCalls state
      state.rtCalls[action.payload.id] = { ...action.payload };

      // Update state to trigger re-render
      state.rtCalls = { ...state.rtCalls };
    },
    deleteRTCall: (state, action: PayloadAction<RTCall>) => {
      delete state.rtCalls[action.payload.id];
      state.rtCalls = { ...state.rtCalls };
      state.rtCallIds = Object.keys(state.rtCallIds);
    }
  },
  extraReducers: (builder) => {}
});

export const { setRTCalls, setRTCall, deleteRTCall } =
  conversationsSlice.actions;

export default conversationsSlice.reducer;
