import { useContext, useState } from 'react';
import { useAlert } from 'src/hooks/useAlert';
import { AIModel } from '../../models/AIModels';
import AuthManager from 'src/services/authentication/manager';
import { useIntentionsModelsDataQuery } from './getAIModelsQuery';
import { IntentionsModelsLogic } from '../../domain/intentionModelsLogic';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { t } from 'i18next';
import { IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  DialTableBulkActionsContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';
import { checkDeleteIntentionModelsPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

export const useIntentionsModelTable = ({
  currentAiModel,
  setCurrentAiModel
}) => {
  const { showAlert } = useAlert();
  const [open, setOpen] = useState<boolean>(false);
  const [typeForm, setTypeForm] = useState<'create' | 'update'>('create');
  const [aiModelsToDelete, setAIModelsToDelete] = useState<AIModel[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const companyId = AuthManager.getLoggedUserCompanyId();
  const [selectedRows, setSelectedRows] = useState<Row<AIModel>[]>([]);

  const { hasAccess } = useContext(PermissionsContext);

  const intentionsModelsQuery = useIntentionsModelsDataQuery({ companyId });
  const deleteAIModels = async (aiModelsToDelete: AIModel[]) => {
    for (const model of aiModelsToDelete) {
      try {
        await IntentionsModelsLogic.deleteIntentionModel(model.id);
        showAlert(`The AI Model ${model.name} was deleted`, 'success', 3000);
        setOpenDeleteModal(!openDeleteModal);
        intentionsModelsQuery.refetch();
      } catch (error) {
        showAlert(t(getErrorMessage(error).errorMessage), 'error', 3000);
        setOpenDeleteModal(!openDeleteModal);
      }
    }
    selectedRows.map((row) => row.toggleSelected());
    setSelectedRows([]);
    setAIModelsToDelete([]);
  };
  const getColumns = async (): Promise<ColumnDef<AIModel>[]> => {
    return [
      {
        accessorKey: 'name',
        id: 'name',
        header: t('AI Model')
      },
      {
        accessorKey: 'description',
        id: 'description',
        header: t('Description')
      },
      {
        accessorKey: 'status',
        id: 'status',
        header: t('Status')
      },
      {
        accessorKey: 'intentions',
        id: 'intentions',
        header: t('Intentions')
      }
    ];
  };
  const getRowActions = ({
    rowData: intentionModel
  }: DialTableRowActionsContext<AIModel>) => {
    return (
      <Stack direction="row" spacing={0.25}>
        <IconButton
          size="small"
          color="secondary"
          title={t('Edit Intention Model')}
          onClick={() => {
            setCurrentAiModel(intentionModel);
            setOpen(true);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          title={t('Delete Intention Model')}
          onClick={() => {
            setAIModelsToDelete([intentionModel]);
            setOpenDeleteModal(true);
          }}
          disabled={!hasAccess(checkDeleteIntentionModelsPermissions)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  const getBulkActions = ({
    selectedData: intentionsModels,
    selectedRowModel
  }: DialTableBulkActionsContext<AIModel>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          size="small"
          color="error"
          title={t('Delete Intention Model')}
          onClick={() => {
            setAIModelsToDelete(intentionsModels);
            setOpenDeleteModal(true);
            setSelectedRows(selectedRowModel.rows);
          }}
          disabled={!hasAccess(checkDeleteIntentionModelsPermissions)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  return {
    intentionsModelsQuery,
    getBulkActions,
    getRowActions,
    getColumns,
    typeForm,
    setTypeForm,
    open,
    setOpen,
    aiModelsToDelete,
    setAIModelsToDelete,
    deleteAIModels,
    openDeleteModal,
    setOpenDeleteModal
  };
};
