import { retrieveCompanyById } from './Companies';
import {
  getAllCompanies,
  getAllServices,
  getAllUsers,
  getUsedExtensionsCompanyById,
  getAvailableExtensionsCompanyById,
  getUsersList,
  getUserById
} from './getUserData';
import {
  actionDeleteUser,
  actionCreateUser,
  actionUpdateUser,
  changeUsersStatus
} from './users';
import { getCampaignsOfUserCompany } from './getCampaignsOfUserCompany';
import { getCampaignById } from './getCampaigns';
import { getCompanyUserStatusById } from './getCompanyUserStatus';

const UsersDomain = {
  actionCreateUser,
  actionDeleteUser,
  changeUsersStatus,
  actionUpdateUser,
  getAllCompanies,
  getAllServices,
  getAllUsers,
  getAvailableExtensionsCompanyById,
  getCampaignById,
  getCampaignsOfUserCompany,
  getCompanyUserStatusById,
  getUsedExtensionsCompanyById,
  getUserById,
  getUsersList,
  retrieveCompanyById
};

export default UsersDomain;
