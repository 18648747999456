import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import { Ticket, TicketField } from '../../../../models/tickets';
import { useEffect, useMemo, useState } from 'react';
import { Variable } from 'src/modules/Admin/modules/General/modules/Variables/models/models';
import { getShowflowVariable } from '../domain/getShowflowVariable';

export const ShowflowVariableTypeConverter = {
  ca: 'Call',
  com: 'Company',
  ti: 'Ticket',
  con: 'Contact'
} as { [key: string]: ShowflowVariableType };

export const ShowflowVariableTypeAbbreviation = {
  Call: 'ca',
  Company: 'com',
  Ticket: 'ti',
  Contact: 'con'
} as { [key in ShowflowVariableType]: string };

export type ShowflowVariableType = 'Call' | 'Company' | 'Ticket' | 'Contact';

interface Props {
  variableId: string;
  contact?: Contact;
  ticket?: Ticket;
}

interface ShowflowVariable {
  type: ShowflowVariableType;
  field: TicketField | ContactField | Variable;
  value: string | string[] | boolean;
  invalidateValue: () => void;
}

function useShowflowVariable({ variableId, contact, ticket }: Props) {
  const type = useMemo(() => {
    const [typeAbbreviation, ..._] = variableId.split('-');
    const variableType = ShowflowVariableTypeConverter[typeAbbreviation];
    return variableType;
  }, [variableId]);

  async function getVariableValue() {
    const result = await getShowflowVariable(variableId, contact, ticket);

    if (result) {
      setField(result.field);
      setValue(result.value);
    }
  }

  const [field, setField] = useState<TicketField | ContactField | Variable>(
    undefined
  );
  const [value, setValue] = useState<string | string[] | boolean>('');

  useEffect(() => {
    if (field?.id !== variableId) {
      getVariableValue();
    }
  }, [variableId, contact?.id, ticket]);

  useEffect(() => {
    getVariableValue();
  }, [contact, ticket]);

  return {
    type,
    field,
    value
  } as ShowflowVariable;
}

export default useShowflowVariable;
