import {
  ArrowCircleDownOutlined,
  Close,
  LaunchOutlined
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  IconButton,
  Stack
} from '@mui/material';
import { MouseEvent, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'src/hooks/useAlert';
import { ReportingAttachmentsDomain } from 'src/modules/Productivity/modules/Downloads/domain/ReportingAttachmentsDomain';

interface Props {
  reportingAttachmentId: string;
  filename?: string;
  isError?: boolean;
  /** The default is downloadReportingAttachment from ReportingAttachmentsDomain */
  onDownloadReport?: (
    event: MouseEvent<HTMLButtonElement>,
    reportingAttachmentId: string
  ) => Promise<void>;
  onClose: (
    event: MouseEvent<HTMLButtonElement>,
    reportingAttachmentId: string
  ) => void;
}

export const DownlaodReportAlert = forwardRef<HTMLDivElement, Props>(
  (
    {
      reportingAttachmentId,
      onDownloadReport = async (
        event: MouseEvent<HTMLButtonElement>,
        reportingAttachmentId: string
      ) => {
        const resp =
          await ReportingAttachmentsDomain.downloadReportingAttachment(
            reportingAttachmentId
          );
        return resp;
      },
      onClose,
      filename,
      isError
    },
    ref
  ) => {
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);

    const DOWNLOADS_PATH = '/dashboard/productivity/downloads';

    const navigate = useNavigate();

    const { showAlert } = useAlert();
    const { t }: { t: any } = useTranslation();

    const filenameText = `${
      filename ?? `${t('with id')} ${reportingAttachmentId}`
    }`;

    return (
      <Alert
        ref={ref}
        key={`alert-${reportingAttachmentId}`}
        severity={isError ? 'error' : 'info'}
        action={
          <Stack direction="row" spacing={0.5}>
            {!isError && onDownloadReport && reportingAttachmentId && (
              <IconButton
                disabled={isLoadingDownload}
                size="small"
                title={t('Download')}
                sx={{ borderRadius: 2 }}
                onClick={async (e) => {
                  setIsLoadingDownload(true);
                  await onDownloadReport(e, reportingAttachmentId)
                    .then(() => {
                      showAlert(
                        `${t('The report')} ${filenameText} ${t(
                          'has been downloaded succesfully'
                        )}`,
                        'success',
                        2000
                      );
                      setIsLoadingDownload(false);
                    })
                    .catch(() => {
                      showAlert(
                        `${t('The report')} ${filenameText} ${t(
                          'could not be downloaded'
                        )}`,
                        'error',
                        2000
                      );
                      setIsLoadingDownload(false);
                      onClose(e, reportingAttachmentId);
                    });
                }}
              >
                {isLoadingDownload ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <ArrowCircleDownOutlined fontSize="small" />
                )}
              </IconButton>
            )}
            <IconButton
              size="small"
              title={t('Go to Downloads')}
              onClick={() => {
                navigate(DOWNLOADS_PATH, {
                  replace: true
                });
              }}
              sx={{ borderRadius: 2 }}
            >
              <LaunchOutlined fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              title={t('Close')}
              onClick={(e) => {
                onClose(e, reportingAttachmentId);
              }}
              sx={{ borderRadius: 2 }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Stack>
        }
      >
        {/* ALERT TITLE */}
        <AlertTitle>
          {isError
            ? `${t('The report')} ${filenameText} ${t(
                'could not be generated!'
              )}`
            : `${t('The report')} ${filenameText} ${t(
                'has been generated succesfully!'
              )}`}
        </AlertTitle>
      </Alert>
    );
  }
);
