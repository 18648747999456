import {
  downloadReportReadyById,
  downloadReportingAttachment,
  getReportingAttachments,
  getReportingAttachmentsUI
} from '.';

export const ReportingAttachmentsDomain = {
  downloadReportingAttachment,
  downloadReportReadyById,
  getReportingAttachments,
  getReportingAttachmentsUI
};
