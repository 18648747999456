import { Stack } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Rnd } from 'react-rnd';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

interface XYPosition {
  x: number;
  y: number;
}

const CTIPositionKey = 'ctiPosition';

export const CustomRND = React.memo((props) => {
  const { barState } = useContext(CTIBarContext);
  const {
    setIsBarExtended,
    setIsDragging,
    isDragging,
    isPopoverOpened,
    setPositionX,
    setPositionY,
    isBarBlocked
  } = barState;

  const showExtendedBar = (show: boolean) => {
    //If the user is dragging the component keep the bar extended
    if (isDragging) return;
    setIsBarExtended(show);
  };

  const initialPosition = useMemo((): XYPosition => {
    const savedPosition = localStorage.getItem(CTIPositionKey);
    const parsedPosition = savedPosition ? JSON.parse(savedPosition) : null;
    if (!parsedPosition) return { x: 300, y: window.innerHeight - 250 };

    //check if the parsedPosition is out of the window
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const isOutOfWindowX = parsedPosition.x > windowWidth - 80;
    const isOutOfWindowY = parsedPosition.y > windowHeight - 80;

    if (isOutOfWindowX || isOutOfWindowY) {
      return {
        x: isOutOfWindowX ? windowWidth - 100 : parsedPosition.x,
        y: isOutOfWindowY ? windowHeight - 100 : parsedPosition.y
      };
    }

    return parsedPosition;
  }, []);

  const [position, setPosition] = useState(initialPosition);

  const resizeListener = useCallback(() => {
    // Si el usuario cambia el tamaño de la ventana, se reajusta la posición del CTI
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    setPosition((prevPosition) => ({
      x: prevPosition.x > windowWidth - 80 ? windowWidth - 100 : prevPosition.x,
      y:
        prevPosition.y > windowHeight - 80 ? windowHeight - 100 : prevPosition.y
    }));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [resizeListener]);

  function savePosition({ x, y }: XYPosition) {
    localStorage.setItem(CTIPositionKey, JSON.stringify({ x, y }));
  }

  const onDragStop = useMemo(() => {
    return (e, d) => {
      setPosition({ x: d.x, y: d.y });
      setPositionX(d.x);
      setPositionY(d.y);
      savePosition({ x: d.x, y: d.y });
      setTimeout(() => {
        setIsDragging(false);
      }, 0);
    };
  }, []);

  const onDrag = useMemo(() => {
    return (e, d) => {
      setPosition({ x: d.x, y: d.y });
      setIsDragging(true);
    };
  }, []);

  const style = useMemo(() => {
    return {
      zIndex: 100,
      pointerEvents: isDragging ? 'none' : 'auto'
    };
  }, []) as React.CSSProperties;

  const defaultObj = useMemo(() => {
    return {
      x: position.x,
      y: position.y,
      height: 'auto',
      width: 'auto'
    };
  }, []);

  return (
    <Rnd
      id="cti-draggable-container"
      style={style}
      default={defaultObj}
      position={position}
      onDrag={onDrag}
      onDragStop={onDragStop}
      bounds={'window'}
      enableResizing={false}
      disableDragging={isPopoverOpened || isBarBlocked}
    >
      <Stack
        direction={'row'}
        onMouseEnter={() => {
          showExtendedBar(true);
        }}
        onMouseLeave={() => {
          if (!isPopoverOpened) showExtendedBar(false);
        }}
        alignItems={'center'}
      >
        {props.children}
      </Stack>
    </Rnd>
  );
});
