import { CallEnd, Logout } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { RTCall } from 'src/models/rtcall';
import CallManagerDomain from 'src/modules/CTI/CallManager';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { setActiveRTCallId } from 'src/services/rtusers/domain/rtuser/setActiveRTCall';

interface Props {
  call?: RTCall;
}

export const HangupButton: React.FC<Props> = ({ call }) => {
  const activeCall = call ?? getActiveRTCall(getConnectedRTUser());
  const theme = useTheme();
  const { barState } = useContext(CTIBarContext);
  const { isDragging } = barState;

  const isConference =
    activeCall?.status === 'Conference' && call === undefined;

  const handleHangup = () => {
    CallManagerDomain.hangupCall(activeCall).then((response) => {
      if (response.status === 200) {
        setActiveRTCallId(null);
      }
    });
  };

  if (!activeCall) return <></>;

  return (
    <IconButton
      id="cti-hangup-btn"
      sx={{
        boxShadow: `${theme.colors.shadows.error}`,
        background: `${theme.colors.error.main}`,
        color: 'white',

        '&:hover': {
          background: `${theme.colors.error.dark}`,
          color: `${theme.palette.getContrastText(theme.colors.primary.main)}`
        }
      }}
      onClick={() => {
        if (!isDragging) handleHangup();
      }}
    >
      {isConference ? (
        <Logout fontSize="medium" />
      ) : (
        <CallEnd fontSize="medium" />
      )}
    </IconButton>
  );
};
