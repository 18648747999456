import { BASE_URL } from './base';

const WHATSAPP_MS = `${BASE_URL}/whatsapp`;

// Account controller
const ACCOUNT_CONTROLLER = `${WHATSAPP_MS}/account`;
export const WHATSAPP_ACCOUNT = `${ACCOUNT_CONTROLLER}`;
export const WHATSAPP_ACCOUNT_ID = `${ACCOUNT_CONTROLLER}/{id}`;
export const WHATSAPP_ACCOUNT_CHECK_ID = `${ACCOUNT_CONTROLLER}/check/{id}`;

// Template controller
const TEMPLATE_CONTROLLER = `${WHATSAPP_MS}/template`;
export const WHATSAPP_TEMPLATE = `${TEMPLATE_CONTROLLER}`;
export const WHATSAPP_TEMPLATE_ID = `${TEMPLATE_CONTROLLER}/{id}`;
export const WHATSAPP_TEMPLATE_ID_SEND = `${TEMPLATE_CONTROLLER}/{id}/send`;
export const WHATSAPP_TEMPLATE_TEXT = `${TEMPLATE_CONTROLLER}/text`;
export const WHATSAPP_TEMPLATE_MEDIA = `${TEMPLATE_CONTROLLER}/media`;

// Attachment controller
const ATTACHMENT_CONTROLLER = `${WHATSAPP_MS}/attachment`;
export const WHATSAPP_ATTACHMENT = `${ATTACHMENT_CONTROLLER}`;
export const WHATSAPP_ATTACHMENT_ID = `${ATTACHMENT_CONTROLLER}/{id}`;

// Message controller
const WHATSAPP_MESSAGE_CONTROLLER = `${WHATSAPP_MS}/message`;
export const WHATSAPP_MESSAGE_EXISTING_FILE = `${WHATSAPP_MESSAGE_CONTROLLER}/existing/file`;
export const WHATSAPP_MESSAGE_EXISTING_TEXT = `${WHATSAPP_MESSAGE_CONTROLLER}/existing/text`;
export const WHATSAPP_MESSAGE_EXISTING_TEMPLATE = `${WHATSAPP_MESSAGE_CONTROLLER}/existing/template`;
export const WHATSAPP_MESSAGE_LOCATION = `${WHATSAPP_MESSAGE_CONTROLLER}/location`;
export const WHATSAPP_MESSAGE_NEW_FILE = `${WHATSAPP_MESSAGE_CONTROLLER}/new/file`;
export const WHATSAPP_MESSAGE_NEW_TEXT = `${WHATSAPP_MESSAGE_CONTROLLER}/new/file`;
export const WHATSAPP_MESSAGE_NEW_TEMPLATE = `${WHATSAPP_MESSAGE_CONTROLLER}/new/template`;
export const WHATSAPP_MESSAGE_ACTIONS = `${WHATSAPP_MESSAGE_CONTROLLER}/actions`;
export const WHATSAPP_MESSAGE_READ = `${WHATSAPP_MESSAGE_CONTROLLER}/read`;

// Auth controller
const AUTH_CONTROLLER = `${WHATSAPP_MS}`;
export const WHATSAPP_AUTH_INFO = `${AUTH_CONTROLLER}/authentication`;

// Massive
const WHATSAPP_MASSIVE_CONTROLLER = `${WHATSAPP_MS}/massive-whatsapp`;
export const WHATSAPP_MASSIVE = `${WHATSAPP_MASSIVE_CONTROLLER}`;
export const WHATSAPP_MASSIVE_ID = `${WHATSAPP_MASSIVE_CONTROLLER}/{id}`;
export const WHATSAPP_MASSIVE_ID_LAUNCH = `${WHATSAPP_MASSIVE_CONTROLLER}/{id}/launch`;
export const WHATSAPP_MASSIVE_ID_UPLOAD_CSV = `${WHATSAPP_MASSIVE_CONTROLLER}/{id}/upload-csv`;

// Report
const WHATSAPP_MASSIVE_REPORT_CONTROLLER = `${WHATSAPP_MS}`;
export const WHATSAPP_MASSIVE_REPORT_ID = `${WHATSAPP_MASSIVE_REPORT_CONTROLLER}/{id}/report`;
