import axios from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';
import { ContactTag } from 'src/modules/Contacts/models/contacts';
import { replaceURLParams } from 'src/services/utils';
import { TAGS, TAGS_ID } from 'src/utils/apiRoutes/msContacts';

export const getContactTagApi = async (id: string): Promise<ContactTag> => {
  const path = replaceURLParams(TAGS_ID, { '{id}': id });
  const resp = await axios.get(path);
  return resp.data;
};

export interface TagFilters {
  companyId?: string;
  contactGroupId?: string;
  page?: number;
  size?: number;
  sortBy?: keyof ContactTag;
  sortDir?: DirectionType;
}

export const getContactTagsApi = async (filter: TagFilters) => {
  const resp = await axios.get<PaginatedElements<ContactTag>>(TAGS, {
    params: filter
  });
  return resp.data;
};
