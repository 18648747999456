import {
  Cameraswitch,
  KeyboardArrowUp,
  Mic,
  MicOff
} from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { toggleMute } from 'src/services/webrtc/domain/toggleMute';
import { getIsMutedStore } from 'src/services/webrtc/infrastructure/store/getIsMutedStore';
import { VideoCallForm } from '../../form/VideoCallForm';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { connectWebsocket } from 'src/modules/VideoCall/domain/room-join';
import Scrollbar from 'src/components/Scrollbar';
import { useVideocallCampaignData } from 'src/modules/VideoCall/hooks/useVideocallCampaign';
import { stopStream } from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';

interface Props {
  settings: MediaTrackSettings;
  stream?: MediaStream;
  videoRef: React.RefObject<HTMLVideoElement>;
  toggleCamera: () => void;
}
export function PreroomMobileView({
  settings,
  stream,
  videoRef,
  toggleCamera
}: Props) {
  const theme = useTheme();
  const isMicrophoneMuted = getIsMutedStore(true);

  const videoCallsContext = useContext(VideocallRoomContext);

  const controls = useAnimation();
  const [isDragging, setIsDragging] = useState(false);
  const videoCallCampaignData = useVideocallCampaignData(
    videoCallsContext.videoRoom?.room.campaignId,
    videoCallsContext.isLoggedIn
  );

  const windowAspectRatio = window.innerWidth / window.innerHeight;
  const isLandscape = windowAspectRatio > 1;
  const calculateAspectRatio = () => {
    if (settings?.aspectRatio !== undefined) {
      return String(settings.aspectRatio);
    }
    return isLandscape ? '1.777' : '0.75';
  };
  const aspectRatio = calculateAspectRatio();

  async function handleVideo() {
    //get user video
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: videoCallsContext.devices.facingMode
      },
      audio: true
    });

    if (!stream) return;

    // Delete previous stream to relase the camera, otherwise the camera will be active until the page is closed
    stopStream(videoCallsContext.streams.local);

    videoCallsContext.streams.setLocalStream(stream);
  }

  useEffect(() => {
    handleVideo();
  }, []);

  return (
    <Stack
      spacing={1}
      width={'100%'}
      height={'100%'}
      padding="0 16px"
      justifyContent={'space-between'}
      position={'relative'}
    >
      <div
        style={{
          aspectRatio, // El aspect ratio debe ser un string o no funciona
          borderRadius: '10px',
          background: '#F3F3F3',
          position: 'relative',
          width: '100%',
          height: 'auto',
          border: '1px solid #E0E0E0'
        }}
      >
        {!stream && (
          <Stack
            direction="column"
            gap={'8px'}
            height={'100%'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress />
            <Typography>{t('Waiting for video input')}</Typography>
          </Stack>
        )}
        {stream && (
          <>
            <video
              ref={videoRef}
              style={{
                borderRadius: '10px',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              autoPlay
              muted
              playsInline
            />

            <IconButton
              onClick={() => {
                toggleCamera();
              }}
              style={{
                borderRadius: '8px',
                position: 'absolute',
                bottom: '13px',
                left: '12px',
                backgroundColor: theme.colors.secondary.main,
                color: 'white',
                zIndex: 2
              }}
            >
              <Cameraswitch />
            </IconButton>
            <IconButton
              onClick={() => {
                toggleMute();
              }}
              style={{
                borderRadius: '8px',
                position: 'absolute',
                bottom: '13px',
                right: '12px',
                backgroundColor: isMicrophoneMuted
                  ? theme.colors.error.main
                  : theme.colors.secondary.main,
                color: 'white',
                zIndex: 2
              }}
            >
              {isMicrophoneMuted && <MicOff />}
              {!isMicrophoneMuted && <Mic />}
            </IconButton>
          </>
        )}
      </div>
      {!isDragging && (
        <motion.div
          initial={{
            y: 20,
            opacity: 1
          }}
          animate={{
            y: -20,
            opacity: 0
          }}
          transition={{
            repeat: Infinity,
            repeatType: 'loop',
            duration: 1.5,
            //cubic bezier ease
            ease: [0.895, 0.03, 0.685, 0.22]
          }}
          style={{
            position: 'fixed',
            width: 'calc(100% - 32px)',
            display: 'flex',
            justifyContent: 'center',
            top: '87%'
          }}
        >
          <KeyboardArrowUp />
        </motion.div>
      )}
      <div
        style={{
          width: 'calc(100% - 32px)',
          position: 'fixed',
          top: '90%',
          zIndex: 5
        }}
      >
        <motion.div
          drag="y"
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={(event, info) => {
            const direction = info.velocity.y > 0 ? 'down' : 'up';
            if (direction === 'down') {
              controls.start('closed').then(() => {
                setIsDragging(false);
              });
            } else {
              controls.start('open').then(() => {
                setIsDragging(false);
              });
            }
          }}
          initial="closed"
          dragElastic={0.2}
          animate={controls}
          variants={{
            open: { y: -600 },
            closed: { y: 0 }
          }}
          transition={{
            type: 'spring',
            damping: 20,
            stiffness: 300
          }}
          dragSnapToOrigin
          style={{
            width: '100%',
            height: window.innerHeight,
            background: 'white',
            border: '1px solid #E0E0E0',
            borderRadius: '10px 10px 0 0',
            overflow: 'hidden',
            zIndex: 5,
            padding: '0px 8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '100px',
              height: '4px',
              borderRadius: '2px',
              backgroundColor: '#E0E0E0',
              margin: '6px 0px'
            }}
          ></div>
          <h2 style={{ margin: 0 }}>{t('Join')}</h2>
          <Stack width="100%" height={'100%'}>
            <Scrollbar>
              <VideoCallForm
                direction={'column'}
                campaignData={videoCallCampaignData}
                onSubmit={(data) => {
                  connectWebsocket(videoCallsContext, data);
                }}
              />
            </Scrollbar>
          </Stack>
        </motion.div>
      </div>
    </Stack>
  );
}
