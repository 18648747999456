import { Button, TextField, styled } from '@mui/material';
import React from 'react'

const useContactInformationPreviewComponents = (formikProps) => {

    const OpenChatButton = styled(Button)(
        () => `
            background-color:
            ${formikProps?.contactStyle.openChatBGColor.color ?? '#1a75ff'};
            width: 100%;
            margin-top: 35px;
            height: 30px;
            border-radius: 5px;
            font-family: ${formikProps.contactStyle.font};
            color:
              ${formikProps?.contactStyle.openChatText.color ?? '#ffffff'};
            &:hover {
              background-color:
                ${formikProps?.contactStyle.openChatBGColor.color ?? '#1a75ff'} 
            },
            &:active {
              background-color:
                ${formikProps?.contactStyle.openChatBGColor.color ?? '#1a75ff'} 
            };
            font-size: 16px;
          margin-bottom: 40px`,
            
      );

    
    

  return (
   {OpenChatButton}
  )
}

export default useContactInformationPreviewComponents