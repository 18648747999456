import {
  IconButton,
  List,
  MenuItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import {
  CameraswitchTwoTone,
  KeyboardArrowDown,
  Videocam,
  VideocamOff
} from '@mui/icons-material';
import { useCamera } from '../../../../../components/WebRTCDevices/useCamera';
import { useContext, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import VideoWebRTCDevices from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { setDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/setDevices';

export const CameraOutlinedSelect = (showIcon) => {
  const [camera, localDevices, onSelectChange] = useCamera();
  const { streams, isMobile, devices, rtcSession } =
    useContext(VideocallRoomContext);
  const { facingMode, setFacingMode } = devices;
  const stream = streams.local;
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const toggleCam = async () => {
    setDisabled((wasDisabled) => {
      VideoWebRTCDevices.toggleDisableVideoStream(stream, !wasDisabled);
      return !wasDisabled;
    });
  };

  async function toggleFacingMode() {
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    const newStream = await VideoWebRTCDevices.requestLocalStream({
      constraints: {
        video: {
          facingMode: { ideal: newFacingMode }
        }
      }
    });

    streams.setLocalStream(newStream);

    VideoWebRTCDevices.replaceStreamOnRTCSession({
      stream: newStream,
      rtcSession: rtcSession.currentRTCSession,
      kind: 'video'
    });
    setFacingMode(newFacingMode);
  }

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = async () => {
      const newDevices = await navigator.mediaDevices.enumerateDevices();
      setDevicesStore(newDevices);
    };

    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);

  const noDevicesAvailable =
    !localDevices.length ||
    (localDevices.length === 1 && localDevices[0].label === '');

  return (
    <div
      style={{
        maxWidth: 'fit-content',
        background: 'rgba(0,0,0,0.05)',
        borderRadius: '8px'
      }}
    >
      {showIcon && (
        <Tooltip title={disabled ? t('Turn on camera') : t('Turn off camera')}>
          <IconButton
            sx={{
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0'
            }}
            onClick={toggleCam}
          >
            {disabled ? <VideocamOff /> : <Videocam />}
          </IconButton>
        </Tooltip>
      )}
      {isMobile && showIcon && (
        <Tooltip title={t('Toggle camera')}>
          <IconButton
            sx={{
              borderRadius: '0px'
            }}
            onClick={toggleFacingMode}
          >
            <CameraswitchTwoTone />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('Select camera')}>
        <IconButton
          ref={ref}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          sx={{
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            width: '30px'
          }}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Tooltip>
      <Popover
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        anchorReference="anchorEl"
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {noDevicesAvailable && (
          <>
            <Typography variant="h4" sx={{ padding: 2 }}>
              {t('No cameras available')}
            </Typography>
          </>
        )}
        {!noDevicesAvailable && (
          <List>
            <MenuItem sx={{ display: 'none' }} value={camera.label}>
              {camera.label}
            </MenuItem>
            {localDevices.map((device, idx) => {
              return (
                <MenuItem
                  key={idx}
                  selected={device.label === camera.label}
                  onClick={(): void => {
                    onSelectChange(device.label);
                    setIsOpen(false);
                  }}
                  value={device.label}
                >
                  {device.label}
                </MenuItem>
              );
            })}
          </List>
        )}
      </Popover>
    </div>
  );
};
