import axios from 'axios';
import { ContactField, Contact } from '../../models/contacts';
import { queryClient } from 'src/utils/queryClient';
import { CONTACT_FIELDS_ID } from 'src/utils/apiRoutes/msContacts';
import { replaceURLParams } from 'src/services/utils';
import { invalidateContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';

export const updateContactFieldApi = async (
  contactField: ContactField
): Promise<Contact> => {
  const path = replaceURLParams(CONTACT_FIELDS_ID, { '{id}': contactField.id });

  const response = await axios.put(path, contactField);

  invalidateContactFieldsQuery();

  return response.data;
};
