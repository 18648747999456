import { useContext, useEffect, useState } from 'react';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { getMessageStatusIcon } from './getMessageStatusIcon';
import { isNoBackgroungChatMessage } from 'src/services/socialmedia/application/utils';
import {
  ChatMessage,
  ChatMessageType
} from 'src/models/conversations/messages';
import ChatMessageMultimediaCardContent from './ChatMessageCardMultimediaContent';
import ContextMenuMessage from './ContextMenuMessage';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, Card, Stack, styled, Typography, useTheme } from '@mui/material';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkInteractChatConversationPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import ChatReplyMessageBox from './ChatReplyMessageBox';
import { getReplyMessage } from '../utils/replyMessages';
import ChatMessageButton from './ChatMessageButton';

export const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
        background: ${theme.colors.secondary.main};
        color: #FFFFFF;
        padding: ${theme.spacing(0.7)} ${theme.spacing(1)};
        border-radius: ${theme.general.borderRadiusXl};
        border-top-right-radius: ${theme.general.borderRadius};
        display: inline-flex;
  `
);

export const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[10]};
        color: ${theme.colors.alpha.black[100]};
        padding: ${theme.spacing(0.7)} ${theme.spacing(1)};
        border-radius: ${theme.general.borderRadiusXl};
        border-top-left-radius: ${theme.general.borderRadius};
        display: inline-flex;
  `
);

interface Props {
  contactName: string;
  conversation: Conversation;
  message: ChatMessage;
}

const ChatMessageWrapperCard = (props: Props) => {
  const { message, conversation, contactName } = props;
  const { involvedContacts } = conversation;
  const { orientation, deleted, edited, metadata, type, unsupported } = message;

  const text = message.content?.text;

  const { hasAccess } = useContext(PermissionsContext);
  const hasChatInteractAccess = hasAccess(
    checkInteractChatConversationPermissions
  );

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const [anchorMessage, setAnchorMessage] = useState<null | HTMLElement>(null);

  const [replyMessage, setReplyMessage] = useState<ChatMessage>(null);

  const isNoBackgroundMessage = isNoBackgroungChatMessage(message);
  const isGroupChat = involvedContacts.length > 1;

  const messageTime = moment(new Date(metadata.creationDate)).format('HH:mm');
  const iconStatus = getMessageStatusIcon(message);

  useEffect(() => {
    if (
      conversation?.id &&
      message?.messageId &&
      !message?.deleted &&
      message?.replyTo &&
      replyMessage === null
    ) {
      getReplyMessage({ conversation, message }).then((reply) =>
        setReplyMessage(reply)
      );
    }
  }, [message, conversation]);

  const timestampWithInfo = (
    <Stack direction={'row'} mt={0.2}>
      <Typography fontSize={'small'}>
        {!deleted && edited && t('Edited')}
      </Typography>
      {orientation === 'Outgoing' && iconStatus}
      <Typography ml={0.1} fontSize={'small'}>
        {messageTime}
      </Typography>
    </Stack>
  );

  const handleRightClickMessage = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (conversation.channel === ChannelType.WEBCHAT) return;
    if (!hasChatInteractAccess) return;
    if (deleted || unsupported) return;
    setAnchorMessage(event.currentTarget);
    setOpen(true);
    setAnchorMessage(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorMessage(null);
    setOpen(false);
  };

  // Message text for message cards with background
  const ChatMessageCardTextBackground = (
    <Box
      width={{
        xs: `${message.type === ChatMessageType.LOCATION ? '150px' : 'none'}`,
        md: `${message.type === ChatMessageType.LOCATION ? '250px' : 'none'}`,
        lg: `${message.type === ChatMessageType.LOCATION ? '300px' : 'none'}`
      }}
    >
      <Typography
        display="flex"
        whiteSpace="pre-wrap"
        fontStyle={deleted || unsupported ? 'italic' : 'normal'}
        sx={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
      >
        {!deleted
          ? !unsupported
            ? text
            : t('Unsupported message')
          : t('Deleted message')}
        <Typography sx={{ visibility: 'hidden' }}>
          {/* Trick to make the text wrap the timestamp and take up less space */}
          {timestampWithInfo}
        </Typography>
      </Typography>

      <Typography
        sx={{ float: 'right', mt: '-18px', mr: '-3px' }}
        display="flex"
        color="inherit"
      >
        {timestampWithInfo}
      </Typography>
    </Box>
  );

  // Message text for message cards with no background
  const ChatMessageCardTextNoBackgroundPrimary = (
    <Box display="flex" justifyContent="end" width="100%">
      <CardWrapperPrimary>
        {deleted && (
          <Typography fontStyle="italic" display="flex" color="inherit" mr={1}>
            {t('Deleted message')}
          </Typography>
        )}
        {unsupported && (
          <Typography fontStyle="italic" display="flex" color="inherit" mr={1}>
            {t('Unsupported message')}
          </Typography>
        )}
        <Typography display="flex" color="inherit">
          {timestampWithInfo}
        </Typography>
      </CardWrapperPrimary>
    </Box>
  );

  // Message text for message cards with no background
  const ChatMessageCardTextNoBackgroundSecondary = (
    <Box display="flex" justifyContent="end" width="100%">
      <CardWrapperSecondary>
        {deleted && (
          <Typography fontStyle="italic" display="flex" color="inherit" mr={1}>
            {t('Deleted message')}
          </Typography>
        )}
        {unsupported && (
          <Typography fontStyle="italic" display="flex" color="inherit" mr={1}>
            {t('Unsupported message')}
          </Typography>
        )}
        <Typography display="flex" color="inherit">
          {timestampWithInfo}
        </Typography>
      </CardWrapperSecondary>
    </Box>
  );

  if (orientation === 'Outgoing') {
    // Message with no background like Stickers, AnimatedStickers and Animations (GIFs) messages
    if (isNoBackgroundMessage) {
      return (
        <Box
          onContextMenu={handleRightClickMessage}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {/*Add reply box */}
          {replyMessage !== null && !message.deleted && (
            <ChatReplyMessageBox
              message={message}
              replyMessage={replyMessage}
            />
          )}
          <ContextMenuMessage
            open={open}
            message={message}
            anchorMessage={anchorMessage}
            handleClose={handleClose}
            setAnchorMessage={setAnchorMessage}
          />
          <ChatMessageMultimediaCardContent message={message} />
          {ChatMessageCardTextNoBackgroundPrimary}
        </Box>
      );
    }
    // Message with background like Text, Image and Video messages
    return (
      <CardWrapperPrimary>
        <Stack direction="column">
          <Box
            onContextMenu={handleRightClickMessage}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {/*Add reply box */}
            {replyMessage !== null && !message.deleted && (
              <ChatReplyMessageBox
                message={message}
                replyMessage={replyMessage}
              />
            )}
            <ContextMenuMessage
              open={open}
              message={message}
              anchorMessage={anchorMessage}
              handleClose={handleClose}
              setAnchorMessage={setAnchorMessage}
            />
            <ChatMessageMultimediaCardContent message={message} />
            {ChatMessageCardTextBackground}
          </Box>
          <Box>
            {message.content?.buttons.length > 0 && (
              <ChatMessageButton buttons={message.content.buttons} />
            )}
          </Box>
        </Stack>
      </CardWrapperPrimary>
    );
  }

  if (orientation === 'Incoming') {
    // Message with no background like Stickers, AnimatedStickers and Animations (GIFs) messages
    if (isNoBackgroundMessage) {
      return (
        <Box
          color="inherit"
          onContextMenu={handleRightClickMessage}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {/*Add reply box */}
          {replyMessage !== null && !message.deleted && (
            <ChatReplyMessageBox
              message={message}
              replyMessage={replyMessage}
            />
          )}
          <ContextMenuMessage
            open={open}
            message={message}
            anchorMessage={anchorMessage}
            handleClose={handleClose}
            setAnchorMessage={setAnchorMessage}
          />
          {isGroupChat && orientation === 'Incoming' && (
            <CardWrapperSecondary>
              <Typography variant="h5" color={theme.colors.primary.main}>
                {contactName}
              </Typography>
            </CardWrapperSecondary>
          )}
          <ChatMessageMultimediaCardContent message={message} />
          {ChatMessageCardTextNoBackgroundSecondary}
        </Box>
      );
    }
    // Message with background like Text, Image and Video messages
    return (
      <CardWrapperSecondary>
        <Box
          color="inherit"
          onContextMenu={handleRightClickMessage}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {/*Add reply box */}
          {replyMessage !== null && !message.deleted && (
            <ChatReplyMessageBox
              message={message}
              replyMessage={replyMessage}
            />
          )}
          <ContextMenuMessage
            open={open}
            message={message}
            anchorMessage={anchorMessage}
            handleClose={handleClose}
            setAnchorMessage={setAnchorMessage}
          />
          {isGroupChat && orientation === 'Incoming' && (
            <Typography variant="h5" color={theme.colors.primary.main}>
              {contactName}
            </Typography>
          )}

          <ChatMessageMultimediaCardContent message={message} />
          {ChatMessageCardTextBackground}
        </Box>
      </CardWrapperSecondary>
    );
  }
};
export default ChatMessageWrapperCard;
