import JsSIP from 'jssip';
import { RTCSessionEvent } from 'jssip/lib/UA';
import { AnswerOptions, RTCSession } from 'jssip/lib/RTCSession';
import { VideocallRoomContextProps } from '../context/VideocallRoomContext';
import { emitCustomEvent } from 'react-custom-events';

const sipUri = process.env.REACT_APP_SIP_ADDRESS as string;
const webrtcUri = process.env.REACT_APP_WEBRTC_ADDRESS as string;

export interface ConnectWebRTCParams {
  videoRoomContext: VideocallRoomContextProps;

  extension: string;
  password: string;

  localStream: MediaStream;
  remoteStream: MediaStream;
}

export class VideoroomWebRTCService {
  static instance: VideoroomWebRTCService = new VideoroomWebRTCService();
  events = new Map<WebRTCEvent, { [key: string]: (event) => void }>();

  private webRTCPhone: JsSIP.UA;

  async connectWebRTC(params: ConnectWebRTCParams) {
    if (this.webRTCPhone) return;

    const socket = new JsSIP.WebSocketInterface(webrtcUri);
    const configuration = {
      sockets: [socket],
      uri: 'sip:' + params.extension + '@' + sipUri,
      password: params.password,
      session_timers: false
    };
    this.webRTCPhone = new JsSIP.UA(configuration);

    this.webRTCPhone.on('registrationExpiring', (e) => {});

    this.webRTCPhone.on('registered', (ev) => {
      this.exec('webrtc:on-extension-registered', ev);
    });

    this.webRTCPhone.on('registrationFailed', (e) => {
      this.exec('webrtc:on-extension-registered-failed', e);
      alert('Error registering extension');
    });

    this.webRTCPhone.on('newRTCSession', (session: RTCSessionEvent) => {
      const rtcSession: RTCSession = session.session;
      console.log('session', session);
      console.log('rtcSession', rtcSession);
      emitCustomEvent('webrtc:on-call-started', rtcSession);

      this.exec('webrtc:on-call-started', session);

      params.videoRoomContext.rtcSession.setCurrentRTCSession(session.session);
      const options: AnswerOptions = {
        extraHeaders: undefined,
        mediaConstraints: {
          audio: true,
          video: true
        },
        sessionTimersExpires: 7200,
        rtcAnswerConstraints: {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        },
        mediaStream: params.localStream
      };
      if (rtcSession.direction === 'incoming') rtcSession.answer(options);

      // Escuchar al evento de que "nos llega" audio y/o video de la llamada
      rtcSession.on('ended', (ev) => {
        this.exec('webrtc:on-call-ended', ev);
        /* params.videoRoomContext.phase.setVideocallPhase(
          VideocallPhase.FINISHED
        );
        params.videoRoomContext.phase.setFinishedReason(FinishReason.NORMAL); */
      });

      rtcSession.connection.ontrack = (event): void => {
        console.log('ONTRACK EVENT!!', event);

        event.streams.forEach((str) =>
          str.getTracks().forEach((track) => {
            console.log('TRACK EXTERNO: ', track);
            params.remoteStream.addTrack(track);
          })
        );

        params.videoRoomContext.streams.setRemoteStream(params.remoteStream);
      };
    });

    this.webRTCPhone.start();
  }

  private exec(event: WebRTCEvent, data?: any) {
    if (this.events.has(event)) {
      Object.values(this.events.get(event)).forEach((func) => {
        func(data);
      });
    }
  }

  on(webRTCEvent: WebRTCEvent, id: string, func: (event?: any) => void) {
    const functions = this.events.get(webRTCEvent) || {};

    functions[id] = func;

    this.events.set(webRTCEvent, functions);
  }

  async disconnectWebRTC() {
    this.webRTCPhone?.stop();

    this.webRTCPhone = null;
  }

  async getDevicesPermission() {
    // Obtener video y audio del usuario.
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          aspectRatio: {
            ideal: 16 / 9
          }
        }
      });

      return stream;
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  }
}

export type WebRTCEvent =
  | 'webrtc:on-extension-registered'
  | 'webrtc:on-extension-registered-failed'
  | 'webrtc:on-call-started'
  | 'webrtc:on-call-ended';
