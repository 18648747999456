import { EmailAccount } from 'src/models/accounts';
import {
  EmailAccountParams,
  getEmailAccountsApi
} from '../../infrastructure/account/getEmailAccountsApi';

export const getEmailAccounts = async (
  params: EmailAccountParams
): Promise<EmailAccount[]> => {
  const accounts = await getEmailAccountsApi(params);
  return accounts;
};
