export interface InstagramAccount {
  accessToken: string;
  companyId: string;
  expired: boolean;
  id: string;
  instagramId: string;
  instagramUsername: string;
  pageId: string;
  profilePicture: string;
  status: InstagramAccountStatus;
}

export interface InstagramPage {
  accessToken: string;
  instagramAccount: string;
  name: string;
  pageId: string;
}

export interface CreateInstagramAccountParams {
  companyId: string;
  instagramUsername: string;
}

export enum InstagramAccountStatus {
  AUTHENTICATED = 'Authenticated',
  PENDING = 'PendingAuthentication',
  EXPIRED = 'Expired'
}
