import { ChevronRight, Groups } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { useDialpadStateContext } from 'src/contexts/CTIBarContext';
import { useContactGroupsQuery } from 'src/modules/Contacts/domain/getContactGroups';

export const CTIContactGroupList = ({
  searchValue
}: {
  searchValue: string;
}) => {
  const { contactGroupSelection, setContactGroupSelection } =
    useDialpadStateContext();
  const { data, isLoading } = useContactGroupsQuery({
    sortBy: 'name',
    sortDir: DirectionType.ASC,
    name: searchValue
  });
  const contactGroups = data?.elements ?? [];

  return (
    <Stack flexGrow={1} flexBasis={0} pt={1}>
      <ListItem
        sx={{
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: 'rgba(219, 219, 226, 0.4)'
          },
          cursor: 'pointer',
          borderRadius: '4px'
        }}
        onClick={() => setContactGroupSelection({ state: 'ALL' })}
      >
        <ListItemIcon>
          <Groups />
        </ListItemIcon>
        <ListItemText>{t('All contact groups')}</ListItemText>
        <ListItemIcon
          color="secondary"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <ChevronRight />
        </ListItemIcon>
      </ListItem>
      <Divider sx={{ my: 0.5 }} />
      <Stack
        flexGrow={1}
        flexBasis={0}
        overflow="auto"
        sx={{ scrollBehavior: 'smooth' }}
      >
        {isLoading && (
          <Stack
            flexGrow={1}
            flexBasis={0}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        )}
        {contactGroups.map((contactGroup, index) => (
          <ListItem
            key={contactGroup.id}
            sx={{
              transition: 'background-color 0.2s',
              '&:hover': {
                backgroundColor: 'rgba(219, 219, 226, 0.4)'
              },
              cursor: 'pointer',
              borderRadius: '4px',
              marginBottom: index === contactGroups.length - 1 ? 1 : 0
            }}
            onClick={() =>
              setContactGroupSelection({ state: 'GROUP', contactGroup })
            }
          >
            <ListItemIcon color="secondary">
              <Groups color="secondary" />
            </ListItemIcon>
            <ListItemText>{contactGroup.name}</ListItemText>
            <ListItemIcon
              color="secondary"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <ChevronRight />
            </ListItemIcon>
          </ListItem>
        ))}
      </Stack>
    </Stack>
  );
};
