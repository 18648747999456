import {
  ChatMessage,
  ChatMessageType,
  MessageOrientation,
  MessageStatus
} from 'src/models/conversations/messages';
import { ConversationFilters } from './socialmediaSlice';
import { ChannelType } from 'src/models/conversations/conversations';

export const CONVERSATIONS_FILTERS_KEY = 'conversationsFilters';
export const EXPANDED_USERS_KEY = 'expandedUsers';

export function getExpandedUsersFromLocalStorage() {
  const item = localStorage.getItem(EXPANDED_USERS_KEY);

  if (item) {
    return JSON.parse(item);
  }

  return {};
}

export function getConversationFiltersFromLocalStorage(): ConversationFilters {
  const item = localStorage.getItem(CONVERSATIONS_FILTERS_KEY);

  if (item) {
    return JSON.parse(item) as ConversationFilters;
  }
  return conversationFilters;
}

export const conversationFilters: ConversationFilters = {
  selectedChannels: {
    Call: true,
    Email: true,
    Telegram: true,
    WebChat: true,
    WhatsApp: true,
    Instagram: true
  },
  showClosedConversations: false,
  showFinishedCalls: true,
  showOnlyMyQueues: true,
  showOnlyUsersInMyCampaigns: true,
  groupByChannels: false,
  textFilter: '',
  showUnreadOnly: false
};

export const currentTelegramMessage: ChatMessage = {
  channel: ChannelType.TELEGRAM,
  conversationId: '',
  deleted: false,
  edited: false,
  messageId: '',
  orientation: MessageOrientation.OUTGOING,
  replyTo: null,
  senderId: '',
  status: MessageStatus.PENDING,
  type: ChatMessageType.TEXT,
  metadata: {
    creationDate: '',
    modificationDate: ''
  },
  content: {
    text: ''
  }
};
