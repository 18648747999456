import { Box, Card, Grow } from '@mui/material';
import { useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { getMissedCallsCountDomain } from 'src/services/rtusers/domain/rtcall/getMissedCallsCountDomain';

export const MissedCalls: React.FC = () => {
  const { barState } = useContext(CTIBarContext);
  const { isBarExtended, keepBarExtended } = barState;
  const missedCalls = getMissedCallsCountDomain(true);

  return (
    <Grow
      in={!isBarExtended && !keepBarExtended && missedCalls.length > 0}
      timeout={{ appear: 1500, enter: 1500, exit: 500 }}
      unmountOnExit
      mountOnEnter
      style={{
        position: 'absolute',
        left: '100%',
        top: 25
      }}
    >
      <Card
        sx={{
          borderRadius: '0 13px 13px 0',
          height: 30,
          width: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: 20,
            height: 20,
            background: 'red',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontWeight: 'bold'
          }}
        >
          {missedCalls.length}
        </Box>
      </Card>
    </Grow>
  );
};
