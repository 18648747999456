import { ModelsTabs } from '../views';

export const getAddButtonNameByTab = (tab: ModelsTabs) => {
  switch (tab) {
    case ModelsTabs.KNOWLEDGE_MODELS_TAB:
      return 'Add knowledge model';
    case ModelsTabs.INTENTION_MODELS_TAB:
      return 'Add intention model';
    case ModelsTabs.ENTITIES_TAB:
      return 'Add prewritten message';
  }
};
