import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

export const CompressBarButton: React.FC = () => {
  const { barState } = useContext(CTIBarContext);
  const { isBarCompressed, setIsBarCompressed } = barState;
  const { t } = useTranslation();

  return (
    <Tooltip title={t(isBarCompressed ? 'Maximize bar' : 'Compress bar')}>
      <IconButton
        id="compress-bar-button"
        onClick={() => {
          setIsBarCompressed(!isBarCompressed);
        }}
      >
        {isBarCompressed ? (
          <Fullscreen fontSize="medium" />
        ) : (
          <FullscreenExit fontSize="medium" />
        )}
      </IconButton>
    </Tooltip>
  );
};
