import AuthManager from 'src/services/authentication/manager';
import { RTQueue } from 'src/models/queue';
import { RTUser } from 'src/models/rtUser';
import { logger } from './logger';

export const generateNumbers: Function = (limit: number, type: string) => {
  let result: string[] = [];
  for (let i = 1; i++; i < limit) {
    result.push(i.toString());
  }

  if (type === 'keyboard') {
    result = [...result, '*', '0', '#'];
  }

  return result;
};

export const getSessionObject: Function = () => {
  const extensionData = localStorage.getItem('extensionData') || '{}';

  if (extensionData === '{}') {
    logger.error('Error: ExtensionData Null');
  }
  const extensionDataObj = JSON.parse(extensionData);

  return {
    userId: AuthManager.getUserId(),
    isLogged: true,
    companyId: '',
    voipData: {
      extension: extensionDataObj.extension,
      complexExtension: extensionDataObj.complexExtension,
      domain: extensionDataObj.domain,
      password: extensionDataObj.password
    }
  };
};

// Navigation pending by use

export const handleMenuNavegation: Function = (item, wsClients) => {
  if (item.name === 'Log out') {
    AuthManager.logout();
  } else window.location.href = item.path;
};

// Change User Status

export const changeUserStatus: Function = (
  rtUsersList,
  rtUser,
  index,
  tempUsers
) => {
  // Comparamos user id para comprobar si existen
  let searchIndex = tempUsers.findIndex((user) => user.id === rtUser.id);
  // si no existen lo agregamos a la lista
  if (searchIndex === -1) {
    tempUsers.push(rtUsersList[index]);
  }
  // si no debemos editar el current status
  else {
    let ex = {} as RTUser;

    ex = {
      ...tempUsers[searchIndex],
      currentStatus: rtUser.currentStatus
    };
    if (rtUser.id === tempUsers[searchIndex].id) tempUsers[searchIndex] = ex;
  }
  //retornamos
  return tempUsers;
};

export const editCalls: Function = (rtCall, tempUsers, index) => {
  let comingExtension = rtCall.currentEndpoint?.endpoint?.split('-')[3];

  if (rtCall.status === 'Parked')
    comingExtension = rtCall.linkedEndpoint?.endpoint?.split('-')[3];

  let indexUser = tempUsers.findIndex(
    (ext) => ext.extension === comingExtension
  );
  if (!rtCall.linkedEndpoint?.endpoint || comingExtension === undefined) {
    comingExtension = rtCall.currentEndpoint?.endpoint.split('-')[3];
    indexUser = tempUsers.findIndex((ext) => ext.extension === comingExtension);
  }

  if (comingExtension != undefined && comingExtension.includes('@')) {
    indexUser = tempUsers.findIndex(
      (ext) => ext.extension === comingExtension.split('@')[0]
    );
  }
  if (indexUser !== -1) {
    if (
      rtCall.status === 'Ringing' ||
      rtCall.status === 'Talking' ||
      rtCall.status === 'Parked'
    ) {
      let ex = {} as RTUser;
      let callsMaps = new Map(tempUsers[indexUser].calls);

      {
        // Remove calls from user.
        let userCallIndex = tempUsers.findIndex((ext) =>
          ext.calls.has(rtCall.id)
        );
        let userExt = tempUsers[userCallIndex];
        if (userExt != undefined) {
          let calls = new Map(userExt.calls);
          calls.delete(rtCall.id);
          tempUsers[userCallIndex] = {
            ...tempUsers[userCallIndex],
            calls: calls
          };
        }
      }
      callsMaps.set(rtCall.id, rtCall);
      ex = {
        ...tempUsers[indexUser],
        calls: callsMaps
      };
      tempUsers[indexUser] = ex;
    } else if (rtCall.status === 'Finished') {
      if (indexUser == -1) return;

      let ex = {} as RTUser;
      let callsMaps = new Map(tempUsers[indexUser].calls);
      callsMaps.delete(rtCall.id);

      ex = {
        ...tempUsers[indexUser],
        calls: callsMaps
      };

      if (ex == undefined) return;

      tempUsers[indexUser] = ex;
    }
  }

  return tempUsers;
};

export const editQueues: Function = (rtCall, tempQueues) => {
  if (rtCall.status === 'Queued') {
    let index = tempQueues.findIndex(
      (queue) => queue.id === rtCall.queueInfo?.queueId
    );
    if (index == -1)
      return logger.error(
        'Warning! Queued RTCall but couldn"t find its Queue on the Queues Panel!!  ' +
          rtCall
      );
    let queue = {} as RTQueue;
    let callsMaps = new Map(tempQueues[index].calls);

    callsMaps.set(rtCall.id, rtCall);

    queue = {
      ...tempQueues[index],
      calls: callsMaps
    };
    tempQueues[index] = queue;
  } else {
    tempQueues.map((queue, index) => {
      if (queue.calls.has(rtCall.id)) {
        let queue = {} as RTQueue;
        let callsMaps = new Map(tempQueues[index].calls);

        callsMaps.delete(rtCall.id);
        queue = {
          ...tempQueues[index],
          calls: callsMaps
        };
        tempQueues[index] = queue;
      }
    });
  }
  return tempQueues;
};

export const forceStatusChange: Function = (Users, id) => {
  let index = Users.findIndex((user) => user.id === id);
  let ex = {} as RTUser;

  ex = {
    ...Users[index],
    currentStatus: 'Available'
  };

  Users[index] = ex;

  return Users;
};
