import axios from 'axios';
import { ChatMessageType } from 'src/models/conversations/messages';
import { TELEGRAM_MESSAGE_EXISTING_FILE } from 'src/utils/apiRoutes/msTelegram';

export interface SendTelegramMessageExistingFileApiParams {
  accountId: string;
  caption?: string;
  conversationId: string;
  file: File;
  replyTo?: string;
  type: ChatMessageType;
}

/**
 * Send a file message to an existing conversation
 * @param params
 */
export const sendTelegramMessageExistingFileApi = async (
  params: SendTelegramMessageExistingFileApiParams
) => {
  const { file, ...restParams } = params;
  const formData = new FormData();
  formData.append('file', file);
  await axios.post(TELEGRAM_MESSAGE_EXISTING_FILE, formData, {
    params: restParams
  });
};
