import axios from 'axios';
import { ContactTag } from '../../models/contacts';
import { queryClient } from 'src/utils/queryClient';
import { replaceURLParams } from 'src/services/utils';
import { TAGS_ID } from 'src/utils/apiRoutes/msContacts';

export const updateTagApi = async (
  id: string,
  name: string
): Promise<ContactTag> => {
  const path = replaceURLParams(TAGS_ID, { '{id}': id });

  const response = await axios.put(path, {
    name
  });
  queryClient.invalidateQueries({ queryKey: ['companyTags'] });
  return response.data;
};
