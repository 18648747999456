import axios from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import { ASTERISK_MS, CALLS_PARK } from 'src/utils/apiRoutes/msAsterisk';

export const parkCallApi: Function = (callId: string, instance: string) => {
  const parkCallPath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) + CALLS_PARK;
  const path = replaceURLParams(parkCallPath, { '{id}': callId });
  // ApiHelper.setInstanceID(path)
  return axios
    .post(path)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
