import { Box, IconButton, styled, Typography } from '@mui/material';
import { t } from 'i18next';
import { getStatusStyles } from './getTableStatuses';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LineProgress from 'src/components/LineProgress/LineProgress';
import { convertDateArrayToString, convertEpochTimeToString } from './helpers';
import { Permissions } from 'src/models/permissionGroups';
import { TableRowActionButton } from '../../models/tableActionButton';
import withId from '../../models/withId';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import { TableColumnType } from 'src/models/table';

/** SHOW ACTION BUTTONS
 *
 * @param  element - array's object
 * @param singleActionButtons - Array with Actions Icons and functions
 * @return Icon buttons
 */
export const actionButtonsHTMLArray = <T extends withId>(
  element: T,
  singleActionButtons: TableRowActionButton<T>[],
  hasAccess: (
    permissionsCheckerFn: (permissions: Permissions) => boolean,
    companyId?: string
  ) => boolean
) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      {singleActionButtons &&
        singleActionButtons.map((actionButton) => {
          if (actionButton.hide?.(element)) return null;
          const id =
            actionButton.title
              .toString()
              .toLowerCase()
              .trim()
              .replace(/\s+/g, '-') + '-table-btn';

          const enabled = hasAccess(actionButton.permissionCheckerFn(element));

          return (
            <Box key={actionButton.title}>
              <PermissionsTooltip hasAccess={enabled}>
                <Box>
                  <IconButton
                    id={id}
                    key={actionButton.title}
                    sx={actionButton.iconButtonStyles}
                    onClick={() => {
                      actionButton.onClickSingle(element);
                    }}
                    title={t(actionButton.title)}
                    disabled={!enabled || actionButton.isDisabledFn?.(element)}
                  >
                    <actionButton.Icon sx={actionButton.iconStyles} />
                  </IconButton>
                </Box>
              </PermissionsTooltip>
            </Box>
          );
        })}
    </Box>
  );
};

/** STRING STYLES
 *
 * @param  text - string to show
 * @param key- Column Key
 * @return Typography
 */
export const setTypography = (
  text,
  key,
  type: TableColumnType = 'text',
  isSmall = false
) => {
  let finalText = text;
  if (type === 'epochTime') finalText = convertEpochTimeToString(text);
  if (type === 'dateArray') finalText = convertDateArrayToString(text);

  return (
    <Typography
      id="modal-modal-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: key === 'name' || key === 'id' ? 'bold' : 'none',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: isSmall ? 10 : 'none'
      }}
      variant="subtitle2"
    >
      {finalText}
    </Typography>
  );
};

/** RENDER STATUS ELEMENT
 *
 * @param  status - status string
 * @return Box with Status syles
 */

export const renderStatusElement = (status) => {
  const BoxStatus = styled(Box)(
    ({ theme }) => `
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: ${getStatusStyles(status).backColor};
      color: ${getStatusStyles(status).textColor};
      width: 112px;
      padding: 4px 10px 4px 10px;
      border-radius: ${theme.general.borderRadiusSm};
    `
  );

  return (
    <BoxStatus>
      <FiberManualRecordIcon
        sx={{
          marginRight: '10px',
          width: '15px'
        }}
      />
      <Typography variant="body1"> {status} </Typography>
      {status === true && 'Active'}
      {status === false && 'Inactive'}
    </BoxStatus>
  );
};

/** RENDER PROGRESS ELEMENT
 *
 * @param  status - status string
 * @return Box with Status syles
 */
export const renderProgressElement = (element, key) => {
  return (
    <Box sx={{ marginBottom: 0.75 }}>
      <LineProgress
        value={Math.round(element[key]) as unknown as number}
        color={'primary'}
      />
    </Box>
  );
};
