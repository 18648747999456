import { RTQueue } from 'src/models/queue';
import { setRTQueueStore } from '../../infraestructure/store/setRTQueueStore';

/** UPDATE RTQUEUE
 *
 */
export const updateRTQueue = (rtQueue: RTQueue) => {
  if (!rtQueue.calls) rtQueue.calls = [];
  if (!rtQueue.conversations) rtQueue.conversations = [];

  setRTQueueStore(rtQueue);
};
