import { Contact } from 'src/modules/Contacts/models/contacts';

import {
  createContactApi,
  uploadCSVContactsApi
} from '../infraestructure/api/createContactsApi';
import { UploadCsvParameters } from '../views/components/contacts/UploadCSVContacts/models/models';

export const createContact = (contact: Contact): Promise<Contact> => {
  return createContactApi(contact);
};

export const uploadCSVContacts = async (
  uploadCSVparams: UploadCsvParameters
) => {
  const response = await uploadCSVContactsApi(uploadCSVparams);

  return response;
};
