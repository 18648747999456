import { setDevices } from 'src/store/slices/users/configVolumeSlice';
import store from 'src/store/store';

/** SET VOICE DEVICE IN STORE
 *
 * @param device New microphone
 */
export const setDevicesStore = (devices: MediaDeviceInfo[]) => {
  store.dispatch(setDevices(devices));
};
