// Este documento recoge varias funciones destinadas a dar formato de fechas y horas
// funciones descritas:
//        getDateDDmmYYYY:  devuelve una fecha 05/09/2005
//        getDateDDmmmYYYY: devuelve una fecha 5 Sep 2005
//        getTimeHHmm:      devuelve una hora 20:30
//        subtractTimes:    devuelve la diferencia entre dos fechas 01:09:10:39, días, horas, minutos y segundos

export const getDateDDmmYYYY = (dateFormat: string) => {
  const day = new Date(dateFormat);

  const yyyy = day.getFullYear();
  let mm = day.getMonth() + 1; // Months start at 0!
  let dd = day.getDate();


  if (dd < 10) dd = Number('0' + dd);
  if (mm < 10) mm = Number('0' + mm);

  const formattedDate = check2digit(dd) + '/' + check2digit(mm) + '/' + yyyy;

  return formattedDate;
}

export const getDateDDmmmYYYY = (dateFormat: string): string => {
  const date = new Date(dateFormat);
  // Josep: esta cerdada es necesaria para poder trabajar con mocks
  // lo lamento compañeros
  if (date.toString() === 'Invalid Date') {
    return dateFormat;
  }
  let mmm = new Intl.DateTimeFormat('es-ES', { month: 'short'}).format(date);
  let yyyy = date.getFullYear();
  let dd = date.getDate();

  const formattedDate = dd + ' ' + mmm + ' ' + yyyy;

  return formattedDate;
}

export const getTimeHHmm = (dateFormat: string): string => {
  const date = new Date(dateFormat);
  const formattedTime = date.toLocaleString('es-ES', {
    hour: '2-digit',
    minute: '2-digit'
  });

  return formattedTime;
}

const check2digit = (oneDigit: number): string => {
  var firstDigit = '';
  if (oneDigit <10) {
    firstDigit = '0';
  }

  return firstDigit + oneDigit;
}

export const subtractTimes = (firstTime: string, secondTime: string): string => {

  var mlSecFirstTime = new Date(firstTime).getTime();
  var mlSecSecondTime = new Date(secondTime).getTime();
  var difMlSec = mlSecSecondTime - mlSecFirstTime;

  var seconds = Math.floor(difMlSec/1000);
  var miliS = difMlSec%1000;


  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var numseconds = ((seconds % 86400) % 3600) % 60;

  var stringTime = check2digit(numdays) + ":" + check2digit(numhours) + ":" + check2digit(numminutes) + ":" + check2digit(numseconds);

  return stringTime;
}