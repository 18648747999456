import { DialTableFilter } from '../models/filters';
import { filterOptionsCallback } from './filterOptionsCallback';

export const getFilteredAvailableFilterOptions = <T extends Object>(
  availableFilters: DialTableFilter<T>[],
  selectedFilters: DialTableFilter<T>[],
  filterInput?: string
) => {
  const filteredAvailableFilterOptions = availableFilters?.filter((filter) => {
    const isAvailableFilterInSelectedFilters =
      isAvailableFilterInSelectedFiltersCallback(selectedFilters);
    const filterOptions = filterOptionsCallback(filterInput);
    return (
      !isAvailableFilterInSelectedFilters(filter) &&
      filterOptions((filter?.label as string) ?? (filter?.fieldName as string))
    );
  });
  return filteredAvailableFilterOptions;
};

/**
 * Returns a function that returns true is the available filter is included in the selectedFilters array and false, otherwise.
 * @param selectedFilters
 * @returns isAvailableFilterInSelectedFilters
 */
const isAvailableFilterInSelectedFiltersCallback =
  <T extends Object>(selectedFilters: DialTableFilter<T>[]) =>
  (availableFilter: DialTableFilter<T>) =>
    selectedFilters?.some(
      (selectedFilter) =>
        selectedFilter?.fieldName === availableFilter.fieldName
    );
