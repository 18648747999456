import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { Box } from '@mui/material';

const StickerViewer = ({ stickerUrl }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: stickerUrl
    });

    return () => {
      animation.destroy();
    };
  }, [stickerUrl]);

  return (
    <Box ref={containerRef} sx={{ maxHeight: '300px', maxWidth: '300px' }} />
  );
};

export default StickerViewer;
