import { PaginationState } from '@tanstack/react-table';
import { PaginationValues } from 'src/models/pagination';

export const getPaginationValues = (pagination: PaginationState) => {
  const paginationValues: PaginationValues = {
    page: pagination.pageIndex,
    size: pagination.pageSize
  };
  return paginationValues;
};
