import { ExternalAction } from '../models/externalActionsModels';
import { getExternalActionByIdAPI } from '../infrastructure/getExternalActionByIdAPI';

export const getExternalActionById = async (
  id: string
): Promise<ExternalAction> => {
  const externalActions = await getExternalActionByIdAPI(id);

  return externalActions;
};
