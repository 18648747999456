import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import DialModal, { ModalButton } from 'src/components/DialModal';
import { RTCall } from 'src/models/rtcall';
import { getRTCall } from 'src/services/rtcalls/domain/getRTCall';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import CallManagerDomain from '../CallManager';

let stop = false;
let callIdToRecover = '';

export const RecoverCallPopover: React.FC = () => {
  const user = getConnectedRTUser();
  const [isOpen, setIsOpen] = useState(false);
  const [recoverCall, setRecoverCall] = useState(false);
  const theme = useTheme();

  //In the first render we dont have all the calls so this function will run a few times
  const handleRecoverCall = () => {
    //Search the call
    const call = getActiveRTCall(user);
    if (!call) return;

    if (recoverCall) {
      parkAndUnparkCall(call);
      return;
    }

    //Open the modal if there is a talking call
    if (call.status === 'Talking') {
      setIsOpen(true);
    }
  };

  const parkAndUnparkCall = (call: RTCall) => {
    if (call.status === 'Talking' && !callIdToRecover) {
      CallManagerDomain.parkCall(call);
      callIdToRecover = call.callId;
      return;
    }

    const callToUnpark = user.calls.find((c) => {
      return c.callId === callIdToRecover && c.status === 'Parked';
    });

    if (callToUnpark) {
      CallManagerDomain.unparkCall(callToUnpark);
      stop = true;
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!stop && user) {
      if (user.currentStatus.type !== 'InCall' && !callIdToRecover) {
        //User has no active calls to recover
        stop = true;
      } else {
        //There is a call to recover
        handleRecoverCall();
      }
    }
  }, [user]);

  const leftButton: ModalButton<boolean> = {
    text: 'Cancel',
    handler: () => {
      stop = true;
      const callToHangup = getRTCall({
        shouldUseHook: false,
        callId: callIdToRecover
      });
      setIsOpen(false);
      CallManagerDomain.hangupCall(callToHangup);
    },
    style: {
      background: theme.colors.error.main
    }
  };
  const rightButton: ModalButton<boolean> = {
    text: 'Recover audio',
    handler: () => {
      setRecoverCall(true);
      setIsOpen(false);
    },
    style: {
      marginLeft: '10px',
      background: theme.colors.success.main
    }
  };

  return (
    <>
      {isOpen && (
        <DialModal
          text="You have an active call"
          isOpen={isOpen}
          leftButton={leftButton}
          rightButton={rightButton}
          body={['Do you want to recover the audio?']}
        ></DialModal>
      )}
    </>
  );
};
