import { ChatMessage } from 'src/models/conversations/messages';
import { PaginatedElements } from 'src/models/pagination';
import {
  APIGetMessagesParams,
  getMessagesApi
} from '../../infraestructure/api/messages/getMessagesApi';

/** FILTER MESSAGES FROM API
 *
 * @param filter Parameters to filter the currently stored conversations
 * @returns Array of conversations after applying the specified filters
 */
export const getMessages = async (params: APIGetMessagesParams) => {
  const paginatedMessages = await getMessagesApi(params);
  return paginatedMessages;
};
