import { Box, useTheme } from '@mui/material';
import { WebChatStyle } from '../../../models/webChatStyle';
import { FormikProps } from 'formik';

interface Bubble3Props {
  formikProps: FormikProps<WebChatStyle>;
}

export const Bubble3: React.FC<Bubble3Props> = ({ formikProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: formikProps.values.bubbleStyle.bubbleColor2.color,
        borderRadius: '50%',
        padding: '13px',
        width: 160,
        height: 160,
        boxShadow:
          formikProps.values.bubbleStyle.dropShadow &&
          formikProps.values.bubbleStyle.innerShadow
            ? '0px 4px 7px rgba(0, 0, 0, 0.5), 0px 30px 60px -12px inset rgba(50, 50, 93, 0.25), 0px 18px 36px -18px inset rgba(0, 0, 0, 0.3)'
            : formikProps.values.bubbleStyle.innerShadow
              ? '0px 30px 60px -12px inset rgba(50, 50, 93, 0.25), 0px 18px 36px -18px inset rgba(0, 0, 0, 0.3)'
              : formikProps.values.bubbleStyle.dropShadow
                ? '0px 4px 7px rgba(0, 0, 0, 0.5)'
                : 'none',
        backdropFilter: formikProps.values.bubbleStyle.layerBlur
          ? 'blur(10px)'
          : 'none'
      }}
      boxShadow={2}
    >
      <svg
        width={formikProps.values.bubbleStyle.size * 1.05}
        height={formikProps.values.bubbleStyle.size * 1.05}
        viewBox="0 0 96 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          filter: formikProps.values.bubbleStyle.layerBlur
            ? 'blur(4px)'
            : 'none'
        }}
      >
        <path
          d="M23.7631 49.3077C25.6743 49.3077 27.2236 47.8268 27.2236 46C27.2236 44.1732 25.6743 42.6923 23.7631 42.6923C21.8519 42.6923 20.3026 44.1732 20.3026 46C20.3026 47.8268 21.8519 49.3077 23.7631 49.3077Z"
          stroke={formikProps.values.bubbleStyle.bubbleColor1.color}
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.2564 49.3077C48.1676 49.3077 49.717 47.8268 49.717 46C49.717 44.1732 48.1676 42.6923 46.2564 42.6923C44.3453 42.6923 42.7959 44.1732 42.7959 46C42.7959 47.8268 44.3453 49.3077 46.2564 49.3077Z"
          stroke={formikProps.values.bubbleStyle.bubbleColor1.color}
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.7498 49.3077C70.661 49.3077 72.2103 47.8268 72.2103 46C72.2103 44.1732 70.661 42.6923 68.7498 42.6923C66.8386 42.6923 65.2893 44.1732 65.2893 46C65.2893 47.8268 66.8386 49.3077 68.7498 49.3077Z"
          stroke={formikProps.values.bubbleStyle.bubbleColor1.color}
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.9867 3.00001C39.8493 3.00243 31.8648 5.11454 24.8839 9.11139C17.903 13.1082 12.187 18.8401 8.34466 25.6966C4.50237 32.553 2.67769 40.2772 3.06498 48.0465C3.45227 55.8158 6.03702 63.3392 10.5439 69.8154L3 89L28.2618 84.6338C34.3447 87.4751 41.0202 88.9674 47.7905 88.9995C54.5608 89.0315 61.2514 87.6025 67.3635 84.8189C73.4756 82.0354 78.8515 77.9691 83.0906 72.9232C87.3297 67.8772 90.3226 61.9818 91.8461 55.6764C93.3697 49.371 93.3847 42.8182 91.8899 36.5064C90.3951 30.1947 87.4292 24.2869 83.2132 19.2233C78.9971 14.1598 73.6398 10.0711 67.5405 7.2621C61.4411 4.45308 54.7571 2.99619 47.9867 3.00001V3.00001Z"
          stroke={formikProps.values.bubbleStyle.bubbleColor1.color}
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
