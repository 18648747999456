import {
  APISendEmailFileByCampaignParams,
  NewMailApiParams,
  sendNewEmailApi,
  sendReplyEmailApi
} from 'src/services/email/infrastructure/message/sendEmailApi';

/** SEND EMAIL
 *
 *  If params.emailMessage does **NOT** contain **inReplyToId**, it will be considered a new email.
 */
export const sendEmail = (params: APISendEmailFileByCampaignParams) => {
  if ((params as NewMailApiParams).campaignId) {
    return sendNewEmailApi(params);
  } else {
    return sendReplyEmailApi(params);
  }
};
