import { useSelector } from 'react-redux';
import { setNotificationsOffStore } from '../infraestructure/store/setNotificationsOffStore';
import store from 'src/store/store';
import { setSocialMediaVolumeStore } from '../infraestructure/store/setSocialMediaVolumeStore';

export const setNotificationsOffDomain = (value: boolean) => {
  const socialMediaRing = document.getElementById(
    'socialmedia-message-notification'
  ) as HTMLMediaElement;

  const telegramRing = document.getElementById(
    'socialmedia-message-notification-Telegram'
  ) as HTMLMediaElement

  const whatsappRing = document.getElementById(
    'socialmedia-message-notification-WhatsApp'
  ) as HTMLMediaElement

  const emailRing = document.getElementById(
    'socialmedia-message-notification-WebChat'
  ) as HTMLMediaElement

  const webchatRing = document.getElementById(
    'socialmedia-message-notification-Email'
  ) as HTMLMediaElement

  if (value) {
    socialMediaRing.volume = 0;
    telegramRing.volume = 0
    whatsappRing.volume = 0
    emailRing.volume = 0
    webchatRing.volume = 0
    setSocialMediaVolumeStore(0);
  } else {
    const ringVolume = store.getState().configVolume.ringVolume;
    socialMediaRing.volume = ringVolume / 100;
    telegramRing.volume = ringVolume / 100
    whatsappRing.volume = ringVolume / 100
    emailRing.volume = ringVolume / 100
    webchatRing.volume = ringVolume / 100
    setSocialMediaVolumeStore(ringVolume / 100);
  }
  setNotificationsOffStore(value);
};
