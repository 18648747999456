import {
  ReportQuery,
  getReportingTicketsApi
} from '../../infrastructure/reporting/getReportingTicketApi';
import { ReportingTicket } from '../../models/tickets';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements, PaginationValues } from 'src/models/pagination';
import { Conversation } from 'src/models/conversations/conversations';
import {
  FieldDataType,
  Filter,
  StringOperator
} from 'src/components/DialTable/models/filters';
import AuthManager from 'src/services/authentication/manager';

export interface ReportingTicketQuerySelect {
  value: keyof ReportingTicket;
  type: 'TICKET';
}

export interface ReportQueryParam {
  filters: Filter[];
  paginationValues: PaginationValues;
  select: ReportingTicketQuerySelect[];
  sort: {
    sort: keyof ReportingTicket;
    direction: DirectionType;
  };
}

export const getReportingTickets = async (
  param: ReportQueryParam,
  companyId: string,
  timeZone?: string
) => {
  const { paginationValues, ...rest } = param;
  const reportQuery: ReportQuery = {
    ...rest,
    pageNumber: paginationValues.page,
    pageSize: paginationValues.size
  };
  const resp = (await getReportingTicketsApi(
    reportQuery,
    companyId,
    undefined,
    timeZone,
    false
  )) as PaginatedElements<ReportingTicket>;
  return resp;
};

export const getConversationReportingTickets = async (
  conversartion: Conversation
) => {
  const filters: Filter[] = [
    {
      field: 'conversationSummaries._id',
      operator: StringOperator.EQUALS,
      values: [conversartion?.id],
      dataType: FieldDataType.OBJECTID
    }
  ];
  const select: ReportingTicketQuerySelect[] = [
    { type: 'TICKET', value: 'id' },
    { type: 'TICKET', value: 'title' },
    { type: 'TICKET', value: 'subResolution.type' }
  ];
  const paginationValues: PaginationValues = {
    page: 0,
    size: 50
  };
  const sort = {
    direction: DirectionType.ASC,
    sort: 'ticket.metadata.modificationDate'
  };
  const params: ReportQueryParam = {
    filters,
    paginationValues,
    select,
    sort
  };
  const companyId =
    conversartion?.companyId ?? AuthManager?.getLoggedUserCompanyId();

  const response = await getReportingTickets(params, companyId);
  return response;
};
