import { FC, ReactNode, useContext, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import AppSidebar from './AppSidebar';
import Header from './Header';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useSession from 'src/hooks/useSession';
import { AnswerCard } from 'src/components/AnswerCard/AnswerCard';
import { CTIBar } from 'src/modules/CTI/views/CTIBar';
import { useCustomEventListener } from 'react-custom-events';
import { MessageAlert, useAlert } from 'src/hooks/useAlert';
import { logger } from 'src/utils/logger';
import { useDownloadSnackbar } from 'src/hooks/useDownloadSnackbar';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { ReportReadyMessage } from 'src/modules/Productivity/modules/Downloads/models/reportingAttachment';
import { useUploadCSVSnackbar } from 'src/modules/Contacts/views/components/contacts/UploadCSVContacts/components/Snackbar';
import { UploadCsvResponse } from 'src/modules/Contacts/views/components/contacts/UploadCSVContacts/models/models';
import { ScreenContext } from 'src/contexts/ScreenContext';
import PowerDialerNotification from 'src/components/PowerDialerNotification/PowerDialerNotification';
import usePowerDialerNotification from 'src/modules/DialerBot/views/hooks/usePowerDialerNotification';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { useNewConversationSnackbar } from 'src/components/NewConversationSnackbar/useNewConversationSnackbar';
import { getDaServiceByIdQuery } from 'src/modules/Admin/modules/Operations/modules/DaServices/views/hooks/daServiceQueries';
interface CollapsedSidebarLayoutProps {
  children?: ReactNode;
}

const AppLayout: FC<CollapsedSidebarLayoutProps> = () => {
  const { sidebarToggle } = useContext(SidebarContext);
  const { showAlert } = useAlert();
  const { enqueueDownloadSnackbar } = useDownloadSnackbar();
  const { enqueueUploadCSVSnackbar } = useUploadCSVSnackbar();
  const { enqueueNewConversationSnackbar } = useNewConversationSnackbar();
  const theme = useTheme();
  const { isOpenPopover, messageData, onClose } = usePowerDialerNotification();
  const navigate = useNavigate();

  useCustomEventListener(
    CustomEventNames.NEW_CONVERSATION,
    async (data: Conversation) => {
      enqueueNewConversationSnackbar(data);

      const service = await getDaServiceByIdQuery(data.serviceId);
      const isCall = data.channel === ChannelType.CALL;
      if (isCall && service?.openInboxOnCall) {
        navigate('/dashboard/inbox');
      }
      if (!isCall && service?.openInboxOnConversation) {
        navigate('/dashboard/inbox');
      }
    }
  );

  const { isFullScreen } = useContext(ScreenContext);

  // REPORT READY FOR DOWNLOADING NOTIFICATIONS
  useCustomEventListener(
    CustomEventNames.REPORT_READY,
    (data: ReportReadyMessage) => {
      if (!!data) {
        const isError = data?.statusCode !== 200;
        enqueueDownloadSnackbar(data.attachmentId, data.filename, isError);
      }
    }
  );

  useCustomEventListener(
    CustomEventNames.CSV_UPLOADED,
    (data: UploadCsvResponse) => {
      enqueueUploadCSVSnackbar(data);
    }
  );

  useCustomEventListener(CustomEventNames.ALERT, (alert: MessageAlert) => {
    showAlert(alert.msg, alert.type, 2000);
  });

  useSession();
  useEffect(() => {
    //Asking for notifications permission
    if (!('Notification' in window)) {
      logger.log('This browser does not support desktop notification');
    } else {
      Notification.requestPermission();
    }
  }, []);

  return (
    <>
      <CTIBar />
      <AnswerCard />
      {isOpenPopover && (
        <PowerDialerNotification
          campaignId={messageData?.campaignId}
          scheduleId={messageData?.scheduleId}
          userId={messageData?.userId}
          phoneNumber={messageData?.phonenumber}
          contactId={messageData?.contactId}
          open={isOpenPopover}
          companyId={messageData?.companyId}
          timestamp={messageData?.timestamp}
          onClose={onClose}
        />
      )}
      {!isFullScreen && (
        <Box
          sx={{
            flex: 1,
            height: '100%',
            minHeight: '100vh',
            '.Mui-FixedWrapper': {
              '.MuiDrawer-root.MuiDrawer-docked': {
                '.MuiPaper-root': {
                  left: 108
                }
              }
            }
          }}
        >
          <Header />
          {/* {sidebarToggle ? <AccentSidebar /> : <CollapsedSidebar />} */}
          <AppSidebar />
          <Box
            sx={{
              position: 'relative',
              zIndex: 5,
              display: 'block',
              flex: 1,

              [theme.breakpoints.up('md')]: {
                marginLeft: theme.spacing(8),
                width: `calc(100% - ${theme.spacing(8)}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
                })
              },
              ...(sidebarToggle && {
                [theme.breakpoints.up('md')]: {
                  marginLeft: theme.sidebar.width,
                  width: `calc(100% - ${theme.sidebar.width}px)`,
                  transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                  })
                }
              }),
              [theme.breakpoints.down('md')]: {
                pt: theme.header.height
              }
              // [theme.breakpoints.up('md')]: {
              // 	ml: sidebarToggle ? theme.sidebar.width : theme.spacing(8),
              // },
            }}
          >
            <Box display="block">
              <Outlet />
            </Box>
            {/* <ThemeSettings /> */}
          </Box>
        </Box>
      )}
      {isFullScreen && (
        <Box display="block">
          <Outlet />
        </Box>
      )}
    </>
  );
};

export default AppLayout;
