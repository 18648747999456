import {
  Box,
  Button,
  Divider,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { useContext, useEffect, useState } from 'react';
import CallManagerDomain from 'src/modules/CTI/CallManager';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { RTCall } from 'src/models/rtcall';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'src/hooks/useAlert';
import { emitCustomEvent } from 'react-custom-events';
import { useSelector } from 'src/store/store';
import { useCampaignsQuery } from 'src/services/users/application/query/getUsersDataQueries';
import { setCampaignSelector } from '../../NewConversation/utils/setCampaignSelector';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';
import PhoneInput from 'src/components/PhoneInput';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

interface addAgentProps {
  calls: RTCall[];
}

export const AddMembersToConferenceModal: React.FC<addAgentProps> = ({
  calls
}) => {
  const { t } = useTranslation();
  const connectedRTUser = getConnectedRTUser();
  const activeCall = getActiveRTCall(connectedRTUser);
  const { dialpadState } = useContext(CTIBarContext);

  const { countryCode, setCountryCode } = dialpadState;

  const rtUsers = useSelector((state) => {
    return Object.values(state.rtusers.rtUsers).filter((user) => {
      return (
        user?.extension?.extension &&
        user.id !== connectedRTUser.id &&
        user.companyId === connectedRTUser.companyId &&
        !calls.find((c) => {
          return (
            c.currentEndpoint.endpoint === user.extension?.complexExtension
          );
        })
      );
    });
  })?.sort((a, b) => {
    return Number(a.extension.extension) - Number(b.extension.extension);
  });

  const [selectedExtension, setSelectedExtension] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const campaignsQuery = useUserCampaignsQuery(false);
  const { showAlert } = useAlert();

  const addToConference = () => {
    if (selectedExtension !== '' && phoneNumber !== '') {
      showAlert('Deseleccione el agente o quite el numero', 'warning', 3000);
      return;
    }
    if (selectedExtension !== '') {
      CallManagerDomain.addAgentToConference(
        selectedExtension,
        activeCall,
        true,
        null
      )
        .then(() => {
          showAlert('Agente añadido con éxito', 'success', 3000);
          emitCustomEvent('Close popover', 'Conference');
        })
        .catch(() => {
          showAlert('Error añadiendo agente a la conferencia', 'error', 3000);
        });
      return;
    }
    if (phoneNumber !== '') {
      CallManagerDomain.addAgentToConference(
        phoneNumber,
        activeCall,
        false,
        selectedCampaignId
      )
        .then(() => {
          showAlert('Añadido con éxito', 'success', 3000);
          emitCustomEvent('Close popover', 'Conference');
        })
        .catch(() => {
          showAlert('Error añadiendo usuario a la conferencia', 'error', 3000);
        });
    }
  };

  const handleChange = (e) => {
    setPhoneNumber('');
    setSelectedExtension(e.target.value);
  };

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');

  useEffect(() => {
    setCampaignSelector(
      'selectedCampaign',
      setCampaigns,
      setSelectedCampaignId,
      campaignsQuery?.data?.elements,
      'Call'
    );
  }, []);

  return (
    <Stack direction="column" spacing={2} width="100%">
      <TextField
        label={'Agent'}
        fullWidth
        value={selectedExtension}
        onChange={handleChange}
        id="extension-AddAgentModal"
        select
      >
        {rtUsers.map((u) => {
          return (
            <MenuItem
              key={u.extension?.extension}
              value={u.extension?.extension}
            >
              {`${u.extension?.extension} - ${u.username}`}
            </MenuItem>
          );
        })}
      </TextField>
      <Stack width="100%" alignItems="center" spacing={2} direction="row">
        <Divider style={{ flexGrow: 1 }} />
      </Stack>
      <PhoneInput
        fullWidth
        label={null}
        phoneNumber={phoneNumber}
        setPhoneNumber={(e) => {
          setSelectedExtension('');
          setPhoneNumber(e);
        }}
        callType={'External'}
        setCallType={undefined}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        disableCallTypeSelector
      />

      <TextField
        onChange={(e) => {
          setSelectedCampaignId(e.target.value);
        }}
        value={selectedCampaignId}
        select
        label={t('Campaign')}
      >
        {campaigns?.map((c) => {
          return (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          );
        })}
      </TextField>
      <Button
        onClick={addToConference}
        variant="contained"
        color="secondary"
        id="btnAdd-AddAgentModal"
      >
        Add
      </Button>
    </Stack>
  );
};
