import { Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { VideoGrid, VideoCallParticipantWithStream } from './VideoGrid';
import { VideocallRoomContext } from '../../context/VideocallRoomContext';
import { ParticipantStatus, ParticipantType } from '../../types/videoCallTypes';
import NewParticipantPopover from './NewParticipantsPopover';
import { InboxPanelsContext } from 'src/contexts/InboxPanelsContext';
import { HelperTab } from 'src/modules/Dashboard/views/Inbox/HelperMenu/items';
import { TimeCounter } from './TimeCounter/TimeCounter';
import InCallMobile from './Mobile';

interface Props {}

const InCallVideoRoom = (props: Props) => {
  // context
  const videoCallsContext = useContext(VideocallRoomContext);
  const context = useContext(InboxPanelsContext);
  const isAgent = videoCallsContext.isLoggedIn;
  const { selectedStreamId, setSelectedStreamId } = videoCallsContext.streams;
  const { callParticipants } = videoCallsContext.videoRoom.room;
  const agentParticipant = callParticipants.find(
    (participant) =>
      participant.type === ParticipantType.AgentParticipant &&
      participant.status === ParticipantStatus.TALKING
  );
  const contactParticipant = callParticipants.find(
    (participant) =>
      participant.type === ParticipantType.ContactParticipant &&
      participant.status === ParticipantStatus.TALKING
  );

  // Provisional para ver que funciona bien
  const streams = [
    {
      id: 'local',
      stream: videoCallsContext.streams.local,
      participant: isAgent ? agentParticipant : contactParticipant
    },
    {
      id: 'remote',
      stream: videoCallsContext.streams.remote,
      participant: isAgent ? contactParticipant : agentParticipant
    }
  ] as VideoCallParticipantWithStream[];

  const remoteParticipant = streams.find(
    (stream) => stream.id === 'remote'
  )?.participant;

  useEffect(() => {
    if (context && context?.helperPanel) {
      context?.setHelperPanel(HelperTab.VIDEO_PARTICIPANTS);
    }
  }, []);

  useEffect(() => {
    const selected = streams.find((stream) => stream.id === selectedStreamId);
    // Si el participante seleccionado no está hablando, se deselecciona
    if (selected?.participant?.status !== ParticipantStatus.TALKING) {
      setSelectedStreamId(selected.id === 'local' ? 'remote' : 'local');
      return;
    }

    if (remoteParticipant?.status === ParticipantStatus.TALKING) {
      setSelectedStreamId('remote');
    }
  }, [callParticipants]);

  if (videoCallsContext.isMobile) {
    return <InCallMobile />;
  }

  return (
    <Stack
      direction={'column'}
      height={'100%'}
      width="100%"
      alignItems={'center'}
      position={'relative'}
    >
      <VideoGrid
        participantWithStream={streams}
        selectedStreamId={selectedStreamId}
        setSelectedStreamId={setSelectedStreamId}
      />
      {/* Check in case that backend is not updated when we update the frontend. */}
      {videoCallsContext.videoRoom.room.startCall && (
        <TimeCounter
          initialTime={Date.parse(videoCallsContext.videoRoom.room.startCall)}
        />
      )}
      <NewParticipantPopover></NewParticipantPopover>
    </Stack>
  );
};

export default InCallVideoRoom;
