import { Box, CircularProgress } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { Conversation } from 'src/models/conversations/conversations';
import ContactDataPanel from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';

interface Props {
  conversation: Conversation;
}

export const ContactsTab: React.FC<Props> = ({ conversation }) => {
  const companyFieldsQuery = useGetContactFields(
    { companyId: conversation.companyId },
    !!conversation
  );

  if (companyFieldsQuery.isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          background: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />;
      </Box>
    );
  }

  return (
    <Box height={'calc(90vh - 162px)'}>
      <Scrollbar>
        <ContactDataPanel
          conversation={conversation}
          showConversationHistory={true}
        />
      </Scrollbar>
    </Box>
  );
};
