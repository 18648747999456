import { Timeline } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import Scrollbar from 'src/components/Scrollbar';
import TicketHistoryItem from './TicketHistoryItem';
import { memo, useEffect, useRef, useState } from 'react';
import { User } from '@auth0/auth0-spa-js';
import UsersDomain from 'src/services/users/domain/UserDomain';
import { TicketChangeHistory } from '../../../../models/tickets';

interface Props {
  ticketHistory?: TicketChangeHistory[];
  // A User array with at least the users involved in the change history (authors). If it is not received, it is fetched.
  involvedUsers?: User[];
}

const TicketHistory = memo((props: Props) => {
  const { ticketHistory, involvedUsers } = props;

  const [users, setUsers] = useState<User[]>([]);
  const userIds = ticketHistory?.map((item) => item.userId);
  const userIdsRef = useRef(userIds);

  useEffect(() => {
    if (!!involvedUsers) {
      setUsers(involvedUsers);
    } else {
      // If there are users or if it is added a new history change by a new user
      if (
        userIds?.length > 0 ||
        (userIds?.length > 0 && userIds.length >= userIdsRef.current.length)
      ) {
        UsersDomain.getUsersList(userIds).then((resp) => setUsers(resp));
      }
    }
  }, [ticketHistory, involvedUsers]);

  const getChangeAuthor = (userId: string) => {
    if (userId === 'SYSTEM') return 'SYSTEM';
    if (users.length > 0) {
      return users.find((user) => user.id === userId)?.username ?? 'Unknown';
    }
  };

  if (!ticketHistory || ticketHistory?.length === 0) {
    return (
      <Box display="flex" justifyContent="center" p={1}>
        <Typography fontWeight="bold">{t('No Change History')}</Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: 350
      }}
    >
      <Scrollbar>
        <Timeline>
          {ticketHistory.map((historyItem) => {
            return (
              <TicketHistoryItem
                key={new Date(historyItem.timestamp).getTime().toString()}
                ticketHistory={historyItem}
                author={getChangeAuthor(historyItem.userId)}
              />
            );
          })}
        </Timeline>
      </Scrollbar>
    </Box>
  );
});

export default TicketHistory;
