import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';

import { ConversationNote } from 'src/models/conversations/conversations';
import { ConversationIconTypes, getIconComponent } from 'src/utils/icons';
import DialModal, { ModalButton } from 'src/components/DialModal';

import { getTimeHHmm, getDateDDmmYYYY } from 'src/utils/formatDateTime';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import {
  createNoteApi,
  CreateContactNoteApiParam
} from 'src/modules/Contacts/infraestructure/api/createNoteApi';
import { updateNoteApi } from 'src/modules/Contacts/infraestructure/api/updateNoteApi';
import { deleteNoteApi } from 'src/modules/Contacts/infraestructure/api/deleteNoteApi';

import { Contact } from 'src/modules/Contacts/models/contacts';
import { getNotes } from 'src/modules/Contacts/domain/getNotes';

interface NoteProps {
  setContact?: Dispatch<SetStateAction<Contact>>;
  conversationNote: ConversationNote;
  setNoteToView: React.Dispatch<React.SetStateAction<ConversationNote[]>>;
  isAddNote?: boolean;
  setIsAddNote?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Note = (props: NoteProps) => {
  const {
    conversationNote,
    setNoteToView,
    isAddNote = false,
    setIsAddNote,
    setContact
  } = props;
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const dateHeader =
    getDateDDmmYYYY(
      isAddNote
        ? conversationNote.conversationInfo.creationDate
        : conversationNote.metadata.modificationDate
    ) +
    ' ' +
    getTimeHHmm(
      isAddNote
        ? conversationNote.conversationInfo.creationDate
        : conversationNote.metadata.modificationDate
    );
  const dateFooter =
    getDateDDmmYYYY(conversationNote.conversationInfo.creationDate) +
    ' ' +
    getTimeHHmm(conversationNote.conversationInfo.creationDate);

  // STATES
  const [isEdit, setIsEdit] = useState<boolean>(isAddNote);
  const [titleState, setTitle] = useState<string>(conversationNote?.title);
  const [titleRequired, setTitleRequired] = useState<boolean>(false);
  const [isModified, setIsModified] = useState(false);
  const [contentState, setContent] = useState<string>(
    conversationNote?.content
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [viewMoreDescription, setViewMoreDescription] =
    useState<boolean>(isAddNote);

  const icon = getIconComponent(
    conversationNote.conversationInfo.channel as ConversationIconTypes
  );
  const DESCRIPTION_CHAR_LIMIT = 150;
  const TITLE_CHAR_LIMIT = 40;

  const noteRef = useRef<ConversationNote>(null);

  if (isEdit) {
    noteRef.current = conversationNote;
  }

  const handleEdit = () => {
    setViewMoreDescription(true);
    setIsEdit(!isEdit);
  };

  const handleSubmit = async () => {
    // Primero comprobamos si la acción que queremos hacer es crear o editar una nota

    if (isAddNote) {
      if (titleState.trim() === '') {
        // con este if comprobamos si el título está vacío, si es así no hacemos nada y mostramos una alerta
        setTitle('');
        setTitleRequired(true);
        return;
      }

      setIsEdit(!isEdit);
      setTitleRequired(false);

      // Creamos el objeto necesario para realizar la petición POST
      const data: CreateContactNoteApiParam = {
        conversationId: conversationNote.conversationInfo.conversationId,
        note: {
          content: contentState.trim(),
          title: titleState.trim()
        }
      };

      // realizamos la petición POST para crear la nueva nota
      const response = await createNoteApi(data);

      // con esta instrucción introducimos la nueva nota y conseguimos limpiar la nota localmente
      setNoteToView((notePrev) => [response, ...notePrev]);
      setIsAddNote(false);
    } else {
      if (titleState.trim() === '') {
        // con este if comprobamos si el título está vacío, si es así no hacemos nada y mostramos una alerta
        setTitle('');
        setTitleRequired(true);
        return;
      }

      // set de los estados para cambiar al estado de edición/guardado, y set para ocultar el aviso de título requerido
      setIsEdit(!isEdit);
      setTitleRequired(false);

      // Petición PUT para editar la conversación
      const responseUpdate = await updateNoteApi(conversationNote.id, {
        content: contentState.trim(),
        title: titleState.trim()
      });

      let indexClear = 0;
      setNoteToView((notePrev) => {
        notePrev.map((note, index) => {
          if (note.id === responseUpdate.id) {
            indexClear = index;
          }
        });
        notePrev.splice(indexClear, 1, responseUpdate);
        return [...notePrev];
      });
    }
  };

  const handleToggleDescription = () => {
    setViewMoreDescription(!viewMoreDescription);
  };

  const leftButtonCancelModal: ModalButton<ConversationNote> = {
    text: 'No',
    handler: () => {
      setOpenDeleteModal(false);
    },
    style: {
      marginRight: '40px'
    }
  };

  const rightButtonCancelModal: ModalButton<ConversationNote> = {
    text: 'Yes',
    handler: async () => {
      if (isAddNote) {
        setIsAddNote(false);
      } else {
        setOpenDeleteModal(false);
        await deleteNoteApi(conversationNote.id);
        getNotes(conversationNote.conversationInfo.conversationId).then(
          (response) => {
            setNoteToView(response.reverse());
          }
        );
      }
    },
    style: {
      backgroundColor: '#ff1943',
      '&:hover': {
        backgroundColor: '#B9112F'
      }
    }
  };

  const leftButtonDeleteModal: ModalButton<ConversationNote> = {
    text: 'Cancel',
    handler: () => {
      setOpenDeleteModal(false);
    },
    style: {
      marginRight: '40px'
    }
  };

  const rightButtonDeleteModal: ModalButton<ConversationNote> = {
    text: 'Delete',
    handler: async () => {
      if (isAddNote) {
        setIsAddNote(false);
      } else {
        setOpenDeleteModal(false);
        await deleteNoteApi(conversationNote.id);
        getNotes(conversationNote.conversationInfo.conversationId).then(
          (response) => {
            setNoteToView(response.reverse());
          }
        );
      }
    },
    style: {
      backgroundColor: '#ff1943',
      '&:hover': {
        backgroundColor: '#B9112F'
      }
    }
  };

  const renderContent = (content: string) => {
    const parts2 = content.replace(/(\r\n|\n|\r)/gm, ' ');
    const parts = parts2.split(' ');

    // Here we determine whether the text content corresponds to a link or not
    const arrayContent: (string | JSX.Element)[] = parts.map((part, index) => {
      var textUrl = '';
      if (part.startsWith('http') || part.startsWith('www.')) {
        textUrl = part.startsWith('www.') ? 'https://' + part : part;
        return (
          <a
            href={textUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: theme.colors.alpha.black[50] }}
          >
            {part}
          </a>
        );
      } else {
        return part;
      }
    });

    // In resume save the content joined all parts with spaces
    const resume: (string | JSX.Element)[] = [];
    var stringText: string = '';

    for (let i = 0; i < arrayContent.length; i++) {
      if (typeof arrayContent[i] !== 'string') {
        resume.push(arrayContent[i]);
      } else {
        if (typeof arrayContent[i + 1] !== 'string') {
          resume.push(stringText + ' ' + arrayContent[i] + ' ');
          stringText = '';
        } else {
          stringText = stringText + ' ' + arrayContent[i];
        }
      }
    }

    return (
      <Typography
        style={{
          overflowWrap: 'break-word',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
          fontSize: '17px',
          color: theme.colors.alpha.black[50]
        }}
      >
        {resume}
      </Typography>
    );
  };

  return (
    <>
      {!isAddNote && (
        <DialModal
          text={t('Are you sure do you want to delete this note?')}
          isOpen={openDeleteModal}
          leftButton={leftButtonDeleteModal}
          rightButton={rightButtonDeleteModal}
          body={[titleState]}
        />
      )}
      {isAddNote && (
        <DialModal
          text={t('Do you want to cancel this note?')}
          isOpen={openDeleteModal}
          leftButton={leftButtonCancelModal}
          rightButton={rightButtonCancelModal}
          body={[titleState]}
        />
      )}

      <Card sx={{ padding: 1, margin: 4 }}>
        <Box
          sx={{
            mx: 2,
            my: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            <Tooltip title={titleState} placement="top-start">
              <TextField
                id="NoteTitleInput"
                inputProps={{ maxLength: TITLE_CHAR_LIMIT }}
                autoFocus={isEdit}
                value={titleState}
                placeholder={t('Title') + '*'}
                helperText={titleState.length + '/' + TITLE_CHAR_LIMIT}
                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (!isModified) {
                    setIsModified(true);
                  }
                }}
                variant="standard"
                disabled={!isEdit}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: '24px',
                    color: 'black'
                  }
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
            </Tooltip>

            <IconButton
              onClick={(e) => setOpenDeleteModal(true)}
              sx={{
                color: theme.colors.error.main,
                background: theme.colors.alpha
              }}
            >
              {!isAddNote && <DeleteIcon />}
              {isAddNote && <ClearIcon />}
            </IconButton>
          </Box>
          {titleRequired && (
            <Typography variant="subtitle1" sx={{ color: 'red' }}>
              {t('Title is required!')}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
          {icon}

          <Typography
            textOverflow="ellipsis"
            noWrap
            sx={{
              margin: 1,
              padding: 0.5,
              borderRadius: 0.75,
              background: '#e2f6ff',
              color: '#33c2ff'
            }}
          >
            {t('Conversation') + ':  ' + dateFooter}
          </Typography>
        </Box>

        <CardContent sx={{ pb: 0.5 }}>
          {isEdit && (
            <TextField
              id="NoteContentInput"
              inputProps={{ maxLength: DESCRIPTION_CHAR_LIMIT }}
              autoFocus={isEdit}
              value={contentState}
              placeholder={t('Description')}
              onChange={(e) => {
                setContent(e.target.value);
                if (!isModified) {
                  setIsModified(true);
                }
              }}
              variant="standard"
              fullWidth
              multiline
              maxRows={viewMoreDescription ? '' : '2'}
              disabled={!isEdit}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: '17px',
                  color: theme.colors.primary.light
                }
              }}
              sx={{
                '& .MuiInputBase-input': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: viewMoreDescription ? 'unset' : '2',
                  WebkitBoxOrient: 'vertical'
                }
              }}
            />
          )}
          {!isEdit && (
            <Box
              width="100%"
              height={viewMoreDescription ? '' : '48px'}
              overflow="hidden"
            >
              <div
                style={{
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  height: '100%'
                }}
              >
                {renderContent(contentState)}
              </div>
            </Box>
          )}

          <Box display="flex" justifyContent="center">
            <IconButton disabled={isEdit} onClick={handleToggleDescription}>
              <ExpandCircleDownIcon
                fontSize="small"
                sx={{
                  transform: viewMoreDescription && 'rotate(180deg)',
                  color: theme.colors.alpha.black[30]
                }}
              />
            </IconButton>
          </Box>
        </CardContent>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <CalendarTodayIcon sx={{ mr: 1, color: 'gray' }} />
            <Typography variant="h6" color={'gray'}>
              {dateHeader}
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            {isModified && (
              <Button
                id="undo-note-btn"
                onClick={(e) => {
                  setIsEdit(false);
                  setIsModified(false);
                  setTitle(noteRef.current.title);
                  setContent(noteRef.current.content);
                }}
                size="small"
                variant="outlined"
                color="secondary"
              >
                {t('Cancel')}
              </Button>
            )}

            <Button
              size="small"
              color="secondary"
              variant="contained"
              type="submit"
              id="btnEditSave-Note"
              onClick={isEdit ? handleSubmit : handleEdit}
            >
              {isEdit ? t('Save changes') : t('Edit Note')}
            </Button>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Note;
