export enum DaServiceModifyClosedTypes {
  ALL_EXCEPT_POSITIVE = 'AllExceptPositive',
  NO = 'No',
  YES = 'Yes'
}

export const daServiceModifyClosedOptions = Object.values(
  DaServiceModifyClosedTypes
);

export interface DaService {
  companyId: string;
  contactGroupId: string;
  description: string;
  enabled: boolean;
  externalActionId: string;
  id: string;
  maxAssignedConvsPerAgent: number;
  metadata: {
    creationDate: string;
    modificationDate: string;
  };
  name: string;
  recordingNamePattern: string;
  robinsonList: string[];
  timeZone: string;
  users: string[];
  companyName?: string;
  contactGroupName?: string;
  openInboxOnCall: boolean;
  openInboxOnConversation: boolean;
  ticketConfig: TicketConfig;
  /**
   * If truthy, the service is a draft. Otherwise, it is not
   */
  draftId?: string;
  ticketResolutionDraftIds?: string[];
  ticketFieldDraftIds?: string[];
  ticketTagDraftIds?: string[];
}

export interface TicketConfig {
  allowCloseConvOnOpenTickets: boolean;
  allowModifyClosed: DaServiceModifyClosedTypes;
  defaultTicketSubresolutionId: string | null;
  maxOpenTicketsPerContact: number;
  notifyPositiveResolutionUserRoles: string[];
}
