import {
  deleteTicketApi,
  deleteTicketAttachmentApi,
  removeAgentFromTicketApi,
  removeTicketFromConversationApi
} from '../infrastructure/deleteTicketsApi';
import { Conversation } from 'src/models/conversations/conversations';
import { Ticket, TicketAttachment } from '../models/tickets';

export const deleteTicket = async (id: string) => {
  await deleteTicketApi(id);
};

export const deleteTickets = async (tickets: Ticket[]) => {
  const ticketPromises = tickets.map((ticket) => deleteTicket(ticket.id));
  await Promise.all(ticketPromises);
};

export const removeTicketAttachment = async (params: {
  ticketId: string;
  ticketAttachment: TicketAttachment;
}) => {
  const { ticketId, ticketAttachment } = params;
  await deleteTicketAttachmentApi({
    ticketId: ticketId,
    fileId: ticketAttachment.id
  });
};

export const removeTicketAttachments = async (params: {
  ticketId: string;
  ticketAttachments: TicketAttachment[];
}) => {
  const { ticketId, ticketAttachments } = params;
  const attachmentPromises = ticketAttachments.map((attachment) =>
    removeTicketAttachment({ ticketId: ticketId, ticketAttachment: attachment })
  );
  await Promise.all(attachmentPromises);
};

export const removeAgentFromTicket = async (ticket: Ticket, userId: string) => {
  const resp = await removeAgentFromTicketApi(ticket?.id, userId);
  return resp;
};

export const removeAgentsFromTicket = async (
  ticket: Ticket,
  userIds: string[]
) => {
  const promises = userIds.map((id) => {
    return removeAgentFromTicket(ticket, id);
  });
  await Promise.all(promises);
};

export const removeTicketFromConversation = async (
  ticket: Ticket,
  conversation: Conversation
) => {
  if (!ticket?.id) throw new Error('The ticket must include id');
  await removeTicketFromConversationApi(ticket?.id, conversation?.id);
};
