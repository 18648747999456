import { MouseEventHandler } from 'react';
import {
  SortDirection,
  TableCell,
  TableCellProps,
  TableSortLabel
} from '@mui/material';

interface Props extends TableCellProps {
  /**
   * If true, the TableSortLabel component is rendered in the TableCell
   */
  sorting?: boolean;
  /**
   * If true, the label will have the active styling (should be true for the sorted column).
   */
  active?: boolean;
  /**
   * If sorting equals to 'true', hide sort icon when active is false.
   */
  hideSortIcon?: boolean;
  /**
   * Ignored if sorting equals to false
   */
  onClickSortLabel?: MouseEventHandler<HTMLSpanElement>;
}
export const DialTableHeadCell = (props: Props) => {
  const {
    children,
    onClickSortLabel,
    sorting = false,
    active = false,
    hideSortIcon = false,
    ...restProps
  } = props;

  const getDirection = (sortDirection: SortDirection) => {
    switch (sortDirection) {
      case 'asc':
        return 'asc';
      case 'desc':
        return 'desc';
      default:
        return undefined;
    }
  };

  if (sorting)
    return (
      <TableCell sx={{ px: 1, py: 0.5, fontSize: 12 }} {...restProps}>
        <TableSortLabel
          active={active}
          hideSortIcon={hideSortIcon ?? false}
          direction={getDirection({ ...restProps }?.sortDirection)}
          onClick={onClickSortLabel}
        >
          {children}
        </TableSortLabel>
      </TableCell>
    );

  return (
    <TableCell sx={{ px: 1, py: 0.5, fontSize: 12 }} {...restProps}>
      {children}
    </TableCell>
  );
};
