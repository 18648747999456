import { TicketField, TicketFieldsValues } from '../../models/tickets';

export const getTicketFieldOptions = (
  ticketField: TicketField,
  ticketFieldValues: TicketFieldsValues
) => {
  const ticketFieldOptions = ticketField?.options;
  if (!ticketFieldOptions) return undefined;
  if (ticketFieldOptions.length === 0) return [];
  if (ticketField?.valueDependencies.length === 0) return ticketFieldOptions;

  let options = [];
  ticketField.valueDependencies.forEach((dependency) => {
    // Convert to array in case the value is a string and flatten the array in case the type is string[]
    const previousValue = [
      ticketFieldValues?.[dependency.fieldId]?.value
    ].flat() as string[];
    dependency.valueMatchings.forEach((matching) => {
      const isMatching = previousValue.some((prevValue) =>
        matching.previousFieldValue.includes(prevValue)
      );
      if (isMatching) {
        options.push(matching.allowedValues);
      }
    });
  });
  return [...new Set(options)];
};
