import { CallEnd } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import CallManagerDomain from 'src/modules/CTI/CallManager';
import { getRTcalls } from 'src/services/rtcalls/domain/getRTCalls';
import { RTCall } from 'src/models/rtcall';
import { CallItem } from '../ExtendedBar/CallInfo/CallItem';

export const AttendedTransferModal = () => {
  const { t } = useTranslation();
  const { setCallToTransferID, callToTransferID } = useContext(CTIBarContext);
  const [callWithAgent, setCallWithAgent] = useState<RTCall | undefined>();
  const [callToTransfer, setCallToTransfer] = useState<RTCall | undefined>();
  const calls = getRTcalls({ shouldUseHook: true });

  const completeTransfer = (complete: boolean) => {
    setCallToTransferID(null);
    CallManagerDomain.completeAttendedTransferCall(
      callToTransfer,
      complete
    ).then((response: AxiosResponse) => {
      if (response.status === 200) {
        setCallToTransferID(null);
      }
    });
  };

  useEffect(() => {
    //The call with the agent and the call to transfer share the same callId
    const possibleCalls = calls.filter((c) => {
      return c.callId === callToTransferID;
    });
    if (possibleCalls.length > 1) {
      setCallWithAgent(possibleCalls.find((c) => c.status === 'Talking'));
      setCallToTransfer(possibleCalls.find((c) => c.status === 'Parked'));
    } else {
      setCallToTransfer(possibleCalls[0]);
    }
  }, [calls]);

  return (
    <Stack
      direction="column"
      alignItems={'center'}
      minWidth={300}
      p={2}
      spacing={2}
    >
      {callWithAgent ? (
        <CallItem disableOnClick call={callWithAgent}></CallItem>
      ) : (
        <Typography>{t('Ringing...')}</Typography>
      )}
      <Stack direction="row" width={'100%'}>
        <Stack
          direction="row"
          width={'100%'}
          justifyContent={'space-between'}
          gap={5}
        >
          <Button
            fullWidth
            color="error"
            variant="contained"
            onClick={() => {
              completeTransfer(false);
            }}
            id="btnCall-AttendedTransferModal"
          >
            <CallEnd />
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="warning"
            onClick={() => {
              completeTransfer(true);
            }}
            id="btnTransfer-AttendedTransferModal"
          >
            Transfer
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
