import { BASE_URL } from './base';

const COORDINATION_MS = `${BASE_URL}/coordination`;

// Assignment controller
const ASSIGNMENT_CONTROLLER = `${COORDINATION_MS}/assignment/conversation`;
export const COORDINATION_QUEUED_CONVERSATIONS = `${ASSIGNMENT_CONTROLLER}/queued`;
export const COORDINATION_ASSIGN_CONVERSATION_CONTACT_ID = `${ASSIGNMENT_CONTROLLER}/assignContact/{id}`;
export const COORDINATION_ASSIGN_CONVERSATION_ID = `${ASSIGNMENT_CONTROLLER}/{id}`;

export const COORDINATION_ASSIGN_CONVERSATION_AGENT_CONVERSATION_ID = `${ASSIGNMENT_CONTROLLER}/assignAgent/{conversationId}`;
