import { RTCall, RTCallStatus } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';
import { DataFilter as CurrentDataFilter } from 'src/services/shared/domain/filters';
import { getRTUsersStore } from '../../rtusers/infraestructure/store/getRTUsersStore';

export interface GetRTCallsFilter extends CurrentDataFilter {
  id?: string;
  callId?: string;
  status?: RTCallStatus;
  rtUser?: RTUser;
  rtUserId?: string;
  linkedEndpoint?: string;
  linkedRtCallId?: string;
}

/** FILTER RTCALLS
 *
 * @param filter Parameters to filter the currently stored rtcalls
 * @returns Array of rtcalls after applying the specified filters
 */
export const getRTcalls = (filter: GetRTCallsFilter): RTCall[] => {
  const rtusers = getRTUsersStore(filter.shouldUseHook);
  const rtcalls: RTCall[] = [];

  // Push all calls from rtusers to the array
  rtusers.forEach((rtuser) =>
    rtuser.calls.forEach((rtCall) => rtcalls.push(rtCall))
  );

  // Filter rtcalls based on specified filters
  return rtcalls.filter((c) => {
    // Check same id
    if (filter.id && filter.id !== c.id) return false;

    // Check same callId
    if (filter.callId && filter.callId !== c.callId) return false;

    // Check same rtUserId
    if (filter.rtUserId && filter.rtUserId !== c.userId) return false;

    // Check rtuser is owner
    if (filter.rtUser && filter.rtUser.calls.some((uc) => uc.id === c.id))
      return false;

    // Check same status
    if (filter.status && filter.status !== c.status) return false;

    //Check same linked endpoint
    if (
      filter.linkedEndpoint &&
      filter.linkedEndpoint !== c.linkedEndpoint?.endpoint
    )
      return false;

    //Check tame linked rtCallId
    if (
      filter.linkedRtCallId &&
      filter.linkedRtCallId !== c.linkedEndpoint?.rtCallId
    )
      return false;

    return true;
  });
};
