import { AnimatePresence, MotionConfig } from 'framer-motion';
import { useContext } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { ParticipantStatus } from 'src/modules/VideoCall/types/videoCallTypes';
import NewParticipantPopoverItem from './components/item';

export default function NewParticipantPopover() {
  const videoCallsContext = useContext(VideocallRoomContext);
  const participants = videoCallsContext.videoRoom.room.callParticipants;
  const waitingParticipants = participants.filter(
    (participant) => participant.status === ParticipantStatus.WAITING
  );

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 25,
        left: 25
      }}
    >
      <MotionConfig
        transition={{
          type: 'spring',
          bounce: 0,
          duration: 0.2
        }}
      >
        <AnimatePresence>
          {waitingParticipants.map((participant) => (
            <NewParticipantPopoverItem
              key={participant.id}
              participant={participant}
            />
          ))}
        </AnimatePresence>
      </MotionConfig>
    </div>
  );
}
