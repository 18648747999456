import {
  addTicketToConversationApi,
  assignAgentToTicketApi,
  createTicketApi,
  createTicketCommentApi,
  createTicketAttachmentApi
} from '../infrastructure/createTicketsApi';
import { Conversation } from 'src/models/conversations/conversations';
import { Ticket, TicketSubResolution } from '../models/tickets';
import { convertDateTimeTicketFieldValueToIsoString } from './getFormattedDateTimeTicketField';

export const createTicket = async (
  ticket: Ticket,
  firstRescheduleTimestamp?: string
) => {
  const formattedFields = ticket?.fields ? { ...ticket?.fields } : {};
  Object.keys(formattedFields).forEach((keyId) => {
    formattedFields[keyId] = convertDateTimeTicketFieldValueToIsoString(
      formattedFields[keyId]
    );
  });
  const newTicket: Ticket = {
    ...ticket,
    fields: formattedFields,
    subResolutionId: ticket.subResolutionId ?? ticket.subResolution?.id
  };
  const resp = await createTicketApi(newTicket, firstRescheduleTimestamp);
  return resp;
};

export const copyTicket = async (ticketResolution: Ticket): Promise<Ticket> => {
  const resp = await createTicket({
    ...ticketResolution,
    title: ticketResolution.title + ' - copy'
  });
  return resp;
};

export const attachTicketFile = async (file: File, ticketId: string) => {
  await createTicketAttachmentApi(file, ticketId);
};

export const attachTicketFiles = async (files: File[], ticketId: string) => {
  const attachmentPromises = files.map((file) =>
    attachTicketFile(file, ticketId)
  );
  await Promise.all(attachmentPromises);
};

export const addTicketComment = async (comment: string, ticket: Ticket) => {
  const resp = await createTicketCommentApi(comment, ticket?.id);
  return resp;
};

export const addTicketToConversation = async (
  ticket: Ticket,
  conversation: Conversation
) => {
  if (!ticket?.id) throw new Error('The ticket must include id');
  await addTicketToConversationApi(ticket?.id, conversation?.id);
};

export const assignAgentToTicket = async (ticket: Ticket, userId: string) => {
  const resp = await assignAgentToTicketApi(ticket?.id, userId);
  return resp;
};

export const assignAgentsToTicket = async (
  ticket: Ticket,
  userIds: string[]
) => {
  const promises = userIds.map((id) => {
    return assignAgentToTicket(ticket, id);
  });
  await Promise.all(promises);
};
