import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';

import { Helmet } from 'react-helmet-async';

import { Box, Button, styled, useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import {
  Contact,
  ContactTag,
  ContactField
} from 'src/modules/Contacts/models/contacts';

import { createContact } from 'src/modules/Contacts/domain/createContacts';
import ContactDataForm from '../ContactDataForm';
import { deleteContact } from 'src/modules/Contacts/domain/deleteContact';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        height: 100%;
        display: flex;
    `
);

interface CreateContactDataPanelProps {
  contact: Contact;
  setContact: Dispatch<SetStateAction<Contact>>;
  tags: ContactTag[];
  companyFields: ContactField[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isSetContact?: boolean;
  defaultContactGroupId?: string;
  onSave?: (contact: Contact) => void;
  saveButtonLabel?: string;
  fromConversation?: boolean;
}

const AddContactDataView = (props: CreateContactDataPanelProps) => {
  const {
    contact,
    setContact,
    tags,
    companyFields,
    setIsOpen,
    isSetContact = true,
    defaultContactGroupId,
    onSave,
    saveButtonLabel,
    fromConversation
  } = props;

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Add contact</title>
      </Helmet>
      <RootWrapper className="Mui-FixedWrapper">
        <Box
          sx={{
            minWidth: 300,
            width: '100%'
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="start">
            <Button
              color="primary"
              variant="text"
              size="small"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ marginTop: theme.spacing(0.75) }}
              onClick={() => {
                setIsOpen(false);
                setContact(null);
              }}
              id="btnBack-AddContactDataView"
            >
              {t('Back')}
            </Button>
          </Box>
          <Box height={'calc(100% - 40px)'}>
            <Scrollbar>
              <ContactDataForm
                contact={contact}
                setContact={setContact}
                onClose={() => setIsOpen(false)}
                onSubmitContact={async (newContact) => {
                  const createdContact = await createContact(newContact);
                  if (onSave) {
                    onSave(createdContact ?? newContact);
                  }

                  return newContact;
                }}
                onDeleteContact={deleteContact}
                isCreatingContact={true}
                isSetContact={isSetContact}
                defaultContactGroupId={defaultContactGroupId}
                saveButtonLabel={saveButtonLabel}
                disableContactGroupSelector={fromConversation}
              />
            </Scrollbar>
          </Box>
        </Box>
      </RootWrapper>
    </>
  );
};

export default AddContactDataView;
