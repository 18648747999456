import {
  useEffect,
  useRef,
  type DependencyList,
  type EffectCallback
} from 'react';

/**
 * Does not run the effect on the first render
 * @param effect  The effect to run
 * @param deps  The dependencies to watch
 * @param applyChanges If true, the effect will run when the dependencies change
 */
function useUpdate(
  effect: EffectCallback,
  deps: DependencyList,
  applyChanges = true
) {
  const isInitialMount = useRef(true);

  useEffect(
    isInitialMount.current || !applyChanges
      ? () => {
          isInitialMount.current = false;
        }
      : effect,
    deps
  );
}

export default useUpdate;
