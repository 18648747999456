import { BASE_URL } from './base';

// FLOW MICROSERVICE
export const BASE_FLOW = `${BASE_URL}/flow`;
export const BASE_DASHBOARDS = `${BASE_FLOW}/dashboards`;

// REALTIME DASHBOARD CONTROLLER
const REALTIME_CONTROLLER = `${BASE_DASHBOARDS}/realtime`;
export const REALTIME_DASHBOARDS = REALTIME_CONTROLLER;
export const REALTIME_DASHBOARDS_ID = `${REALTIME_CONTROLLER}/{id}`;
export const REALTIME_DASHBOARDS_ADD_PANEL = `${REALTIME_CONTROLLER}/{id}/addPanel`;
export const REALTIME_DASHBOARDS_SUBSCRIBE = `${REALTIME_CONTROLLER}/{id}/subscribe`;
export const REALTIME_DASHBOARDS_UNSUBSCRIBE = `${REALTIME_CONTROLLER}/{id}/unsubscribe`;
export const REALTIME_DASHBOARDS_UPDATE_TIMES = `${REALTIME_CONTROLLER}/updateTimes/{id}`;
export const REALTIME_DASHBOARDS_UPDATE_PANEL_GROUPS = `${REALTIME_CONTROLLER}/updatePanelGroups/{id}`;

// PAST TIME DASHBOARD CONTROLLER

// PRESET DASHBOARD CONTROLLER
const PRESET_CONTROLLER = `${BASE_DASHBOARDS}/presets`;
export const PRESETS_DASHBOARD_ID = `${PRESET_CONTROLLER}/{presetFixedId}`;

// PANELS CONTROLLER
const PANELS_CONTROLLER = `${BASE_FLOW}/panels`;
export const PANELS = PANELS_CONTROLLER;
export const PANELS_ID = `${PANELS_CONTROLLER}/{id}`;
export const PANELS_ALERT = `${PANELS_CONTROLLER}/alert`;
export const PANELS_ALERT_ID = `${PANELS_CONTROLLER}/alert/{panelId}`;
export const PANELS_ID_PARAMETERS = `${PANELS_CONTROLLER}/{id}/parameters`;
export const PANELS_ALERT_ACTIONS = `${PANELS_CONTROLLER}/alert/actions`;
export const PANELS_ALERT_SEVERITY = `${PANELS_CONTROLLER}/alert/severity`;
export const PANELS_CALCULATIONS = `${PANELS_CONTROLLER}/calculations`;
export const PANELS_DATA_TYPES = `${PANELS_CONTROLLER}/datatypes`;
export const PANELS_PANEL_TYPES = `${PANELS_CONTROLLER}/paneltypes`;
export const PANELS_PARAMETERS_CHAR_TYPE = `${PANELS_CONTROLLER}/parameters/chartTypes`;
export const PANELS_SUBSCRIBE = `${PANELS_CONTROLLER}/subscribe`;
export const PANELS_UNSUBSCRIBE = `${PANELS_CONTROLLER}/unsubscribe`;
export const PANELS_TIME_UNITS = `${PANELS_CONTROLLER}/timeUnits`;
export const PANELS_DATA = `/panels/{id}/data`;
export const PANELS_INITIAL_DATA = `${PANELS_CONTROLLER}/{id}/initialData`;
export const PANELS_SIZES = `${PANELS_CONTROLLER}/panelSizes`;
export const PANELS_GROUP_INTERVALS = `${PANELS_CONTROLLER}/groupIntervals`;
export const PANELS_KPIS_CALCULATIONS = `${PANELS_CONTROLLER}/parameters/kpiCalculations`;
export const PANELS_KPIS_IDENTIFIERS = `${PANELS_CONTROLLER}/identifiersByDatatype`;
export const PANELS_KPIS_DATA_TYPES = `${PANELS_CONTROLLER}/kpiDatatypes`;
export const PANELS_KPIS_COORDINATOR_DATA_TYPES = `${PANELS_CONTROLLER}/coordinatorDatatypes`;
export const PANELS_KPIS_ENTITIES_PERMISSIONS = `${PANELS_CONTROLLER}/entitiesWithCreatePermissions`;

// Past time dashboard controller
// REALTIME DASHBOARD CONTROLLER
const DASHBOARDS_REPORTING_CONTROLLER = `${BASE_FLOW}/dashboards/reporting`;
export const DASHBOARDS_REPORTING = DASHBOARDS_REPORTING_CONTROLLER;
export const DASHBOARDS_REPORTING_ID = `${DASHBOARDS_REPORTING_CONTROLLER}/{id}`;
export const DASHBOARDS_REPORTING_SUBSCRIBE = `${DASHBOARDS_REPORTING_CONTROLLER}/{id}/subscribe`;
export const DASHBOARDS_REPORTING_UNSUBSCRIBE = `${DASHBOARDS_REPORTING_CONTROLLER}/{id}/unsubscribe`;
export const DASHBOARDS_REPORTING_UPDATE_TIMES_ID = `${DASHBOARDS_REPORTING_CONTROLLER}/updateTimes/{id}`;
export const DASHBOARDS_REPORTING_UPDATE_PANEL_GROUPS_ID = `${DASHBOARDS_REPORTING_CONTROLLER}/updatePanelGroups/{id}`;
