import { deleteDashboard } from '../infrastructure/api/deleteDashboards';
import { Dashboard } from '../models/Dashboards';

/** DELETE DASHBOARD BY ID
 *
 * Returns dashboard deleted
 * @returns {Dashboard} Dashboard Array
 */
export const deleteDashboardById = async (
  dashboardId: string
): Promise<Dashboard> => {
  return deleteDashboard(dashboardId);
};
