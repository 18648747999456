import axios from 'axios';
import { WhatspAppAccount } from 'src/models/accounts';
import { replaceURLParams } from 'src/services/utils';
import { WHATSAPP_ACCOUNT_ID } from 'src/utils/apiRoutes/msWhatsapp';

export const updateEnableWhatsAppAccountsApi = async (
  id: string,
  whatsAppAccount: Partial<WhatspAppAccount>
) => {
  const path = replaceURLParams(WHATSAPP_ACCOUNT_ID, {
    '{id}': id
  });
  const response = await axios.put(path, whatsAppAccount);
  return response.data;
};
