import { DialTableFilter } from '../models/filters';

/**
 * Sorts a copy of the selectedFilters and returns the new sorted array of selectedFilters
 * @param selectedFilters
 * @returns sortedSelectedFilters
 */
export const sortSelectedFilters = <T extends Object>(
  selectedFilters: DialTableFilter<T>[]
) => {
  return [...selectedFilters]?.sort((a, b) => {
    if (a?.required && !b?.required) {
      return -1;
    } else if (!a?.required && b?.required) {
      return 1;
    } else {
      return 0;
    }
  });
};
