import {
  ContactFieldType,
  Contact,
  ContactField
} from 'src/modules/Contacts/models/contacts';
import { getContactFieldsApi } from '../infraestructure/api/getCompanyContactFieldsApi';
import { GetCompanyContactFieldsFilter } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { queryClient } from 'src/utils/queryClient';
import { getContactFieldByIdApi } from '../infraestructure/api/getContactFieldById';
import { DirectionType } from 'src/components/TableReporting/models/direction';

export interface GetContactFieldsParams {
  contact: Contact;
  fieldType?: ContactFieldType;
  sortBy?: keyof ContactField;
  sortDir?: DirectionType;
}

export const getCompanyContactFields = async (
  filter: GetCompanyContactFieldsFilter
) => {
  const { page = 0, size = -1, ...rest } = filter ?? {};
  const contactFields = await getContactFieldsApi({ ...rest, page, size });
  return contactFields;
};

/** FILTER CONTACT FIELDS FROM CONTACT
 *
 */
export const getContactFieldsFromContact = (
  filter: GetContactFieldsParams,
  companyFields: ContactField[]
): ContactField[] => {
  const result: ContactField[] = [];

  // Fill result with only the filtered fields from the contact
  Object.keys(filter.contact.fields).forEach((fieldId) => {
    // Find the Company Contact Field from the name
    const companyField = companyFields.find((f) => f.id == fieldId);

    // Filter by field type if specified
    if (filter?.fieldType && companyField?.type !== filter.fieldType) return;

    // Add to result
    result.push({
      name: companyField.name,
      value: filter.contact.fields[fieldId],
      type: companyField.type,
      mainField: companyField.mainField
    } as ContactField);
  });

  return result;
};

export const getContactFieldsObject = (
  contact: Contact,
  companyContactFields: ContactField[]
): {
  name: ContactField[];
  phone: ContactField[];
  email: ContactField[];
  telegram: ContactField[];
  instagram: ContactField[];
  custom: ContactField[];
} => {
  const nameContactFields = getContactFieldsFromContact(
    {
      contact: contact,
      fieldType: 'Name'
    },
    companyContactFields
  );

  const phoneContactFields = getContactFieldsFromContact(
    {
      contact: contact,
      fieldType: 'Phone'
    },
    companyContactFields
  );

  const emailContactFields = getContactFieldsFromContact(
    {
      contact: contact,
      fieldType: 'Email'
    },
    companyContactFields
  );

  const telegramContactFields = getContactFieldsFromContact(
    {
      contact: contact,
      fieldType: 'TelegramUsername'
    },
    companyContactFields
  );

  const instagramContactFields = getContactFieldsFromContact(
    {
      contact: contact,
      fieldType: 'InstagramUsername'
    },
    companyContactFields
  );

  const customContactFields = getContactFieldsFromContact(
    {
      contact: contact,
      fieldType: 'Custom'
    },
    companyContactFields
  );

  return {
    name: nameContactFields,
    phone: phoneContactFields,
    email: emailContactFields,
    telegram: telegramContactFields,
    instagram: instagramContactFields,
    custom: customContactFields
  };
};

export const getContactFieldByIdQuery = (id: string) => {
  return queryClient.fetchQuery({
    queryKey: ['contactField' + id],
    queryFn: () => getContactFieldByIdApi(id),
    staleTime: 60 * 5 * 1000
  });
};
