import { Box, Button, useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { t } from 'i18next';
import { useContext } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkUpdateContactPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

interface Props {
  onClickBackButton: () => void;
  onClickEditButton: () => void;
}

const ContactActionsHeader = (props: Props) => {
  const { onClickBackButton, onClickEditButton } = props;
  const theme = useTheme();
  const { hasAccess } = useContext(PermissionsContext);
  const hasEditContactAccess = hasAccess(checkUpdateContactPermissions);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Button
        color="primary"
        variant="text"
        size="small"
        startIcon={<ArrowBackIosNewIcon />}
        sx={{ marginTop: theme.spacing(0.75) }}
        onClick={onClickBackButton}
        id="btnBack-ContactDataView"
      >
        {t('Back')}
      </Button>
      {hasEditContactAccess && (
        <Button
          color="secondary"
          variant="text"
          size="small"
          startIcon={<EditTwoToneIcon />}
          sx={{ marginTop: theme.spacing(0.75) }}
          onClick={onClickEditButton}
          id="btnEdit-ContactDataView"
        >
          {t('Edit')}
        </Button>
      )}
    </Box>
  );
};

export default ContactActionsHeader;
