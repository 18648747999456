import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  CSSObject,
  Drawer,
  IconButton,
  styled,
  Theme,
  useTheme
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AccentSidebarMenu from './SidebarMenu/AccentSidebarMenu';
import CollapsedSidebarMenu from './SidebarMenu/CollapsedSidebarMenu';

import Logo from 'src/components/LogoApp';
import { useNavigate } from 'react-router-dom';

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.sidebar.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: theme.spacing(8)
});

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.md}px) {
          top: 0;
          left: 0;
          position: fixed;
          z-index: 10;
          border-top-right-radius: ${theme.general.borderRadius};
          border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);

const DrawerWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.md}px) {
          top: 0;
          left: 0;
          position: fixed;
          z-index: 10;
          border-top-right-radius: ${theme.general.borderRadius};
          border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          ...(sidebarToggle && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
          }),
          ...(!sidebarToggle && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
          })
        }}
      >
        <IconButton
          onClick={closeSidebar}
          size="small"
          disableRipple
          disableFocusRipple
          sx={{
            zIndex: 10,
            position: 'absolute',
            right: '-14px',
            top: '66px',
            color: theme.colors.primary,
            backgroundColor: theme.sidebar.menuItemColor,
            borderRadius: '100%',
            boxShadow: theme.colors.shadows.primary
          }}
        >
          {sidebarToggle ? (
            <ChevronLeftIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </IconButton>
        <TopSection>
          <Box
            sx={{
              marginX: sidebarToggle ? theme.spacing(3) : theme.spacing(2)
            }}
          >
            <Logo onClick={() => navigate('/dashboard/inbox')} />
          </Box>
        </TopSection>
        <Box
          sx={{
            height: 'calc(100% - 80px)'
          }}
        >
          <Scrollbar>
            {sidebarToggle ? <AccentSidebarMenu /> : <CollapsedSidebarMenu />}
          </Scrollbar>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
          display: {
            xs: 'inline-block',
            md: 'none'
          }
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <DrawerWrapper>
          <TopSection>
            <Box sx={{ marginX: theme.spacing(3) }}>
              <Logo />
            </Box>
          </TopSection>
          <Box
            sx={{
              height: 'calc(100% - 80px)'
            }}
          >
            <Scrollbar>
              <AccentSidebarMenu />
            </Scrollbar>
          </Box>
        </DrawerWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
