import { Suspense, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';
import StatusComingSoon from 'src/components/Status/ComingSoon';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { ConversationsProvider } from 'src/contexts/ConversationsContext';
import adminRoutes from 'src/modules/Admin/routes/adminRoutes';
import { ContactsView } from 'src/modules/Contacts/views';
import productivityRoutes from 'src/modules/Productivity/routes/productivityRoutes';
import {
  checkAdminPermissions,
  checkContactsPermissions,
  checkInboxPermissions,
  checkProductivityPermissions,
  checkUpdateServicePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const AdminView = Loader(
  lazy(() => import('src/modules/Dashboard/views/Admin'))
);
const Home = Loader(lazy(() => import('src/modules/Dashboard/views/Home')));
const Inbox = Loader(lazy(() => import('src/modules/Dashboard/views/Inbox')));
const Services = Loader(
  lazy(
    () =>
      import('src/modules/Admin/modules/Operations/modules/DaServices/views')
  )
);
const Productivity = Loader(lazy(() => import('src/modules/Productivity')));
const Schedules = Loader(
  lazy(() => import('src/modules/Dashboard/views/Schedules'))
);

const dashboardRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="/dashboard/inbox" replace />
  },
  {
    path: 'home',
    element: <Home />
  },
  {
    path: 'inbox/*',
    element: (
      <Restricted navigate permissionCheckerFn={checkInboxPermissions}>
        <ConversationsProvider>
          <Inbox />
        </ConversationsProvider>
      </Restricted>
    )
  },
  {
    path: 'services',
    element: (
      <Restricted navigate permissionCheckerFn={checkUpdateServicePermissions}>
        <Services />
      </Restricted>
    )
  },
  {
    path: 'contacts',
    element: (
      <Restricted navigate permissionCheckerFn={checkContactsPermissions}>
        <ContactsView />
      </Restricted>
    )
  },
  {
    path: 'admin',
    element: (
      <Restricted navigate permissionCheckerFn={checkAdminPermissions}>
        <AdminView />
      </Restricted>
    ),
    children: adminRoutes
  },
  // COULD CHANGE
  {
    path: 'schedule',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={(permissions) =>
          // permissions.modules.view.basic.scheduling
          true
        }
      >
        <Schedules />
      </Restricted>
    )
  },
  {
    path: 'productivity',
    element: (
      <Restricted navigate permissionCheckerFn={checkProductivityPermissions}>
        <Productivity />
      </Restricted>
    ),
    children: productivityRoutes
  },
  {
    path: 'user',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={(permissions) =>
          // permissions.modules.view.basic.contacts
          true
        }
      >
        <StatusComingSoon />
      </Restricted>
    )
  },
  {
    path: 'search',
    element: <StatusComingSoon />
  }
  //   ]
  // }
];

export default dashboardRoutes;
