import {
  ThirdPartyIframeData,
  ThirdPartyType
} from 'src/store/slices/socialmedia/socialmediaSlice';
import { getIframepanelsStore } from '../infraestructure/getIframepanelsStore';

/** FILTER IFRAME PANELS
 *
 * @param filter Parameters to filter the currently stored Iframe Panels
 * @returns Array of Iframe Panels after applying the specified filters
 */
export const getIframepanels = (
  shouldUseHook: boolean,
  type?: ThirdPartyType
): ThirdPartyIframeData[] => {
  let panels = getIframepanelsStore(shouldUseHook);

  // Filter type if defined
  if (type) panels = panels.filter((pan) => pan.type === type);

  return panels;
};
