import { ThirdPartyIframeData } from 'src/store/slices/socialmedia/socialmediaSlice';
import {
  setIframepanelsStore,
  setIframepanelStore
} from '../infraestructure/setIframepanelsStore';

/** UPDATE IFRAMEPANEL
 *
 * @param conversation Conversation object ALREADY ALTERED.
 */
export const updateIframepanel = (iframe: ThirdPartyIframeData) => {
  setIframepanelStore(iframe);
};

export const updateIframepanels = (iframes: ThirdPartyIframeData[]) => {
  setIframepanelsStore(iframes);
};
