import { createAsyncThunk } from '@reduxjs/toolkit'
import daServiceManager from '../../../services/daServices/daServiceManager'

export const getAllDaServices = createAsyncThunk(
	'daServices/getAll',
	async () => {
		const response = await daServiceManager.getDaServices()
		return response
	}
)
