import {
  Box,
  Fade,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popper,
  Typography,
  useTheme
} from '@mui/material';
import {
  CustomFilterValueOptions,
  DialTableFilter,
  Operator
} from '../models/filters';
import { DialTableFilterValueOptions } from './DialTableFilterValueOptions';
import { useDialTableFilterInput } from '../hooks/useDialTableFilterInput';
import { DialTableFilterItem } from './DialTableFilterItem';
import { useTranslation } from 'react-i18next';
import { FillFilterSteps } from '../models/fillFilterSteps';

import { DialTableListItemButton } from './DialTableListItemButton';
import { DialTableNoResultsFilterItem } from './DialTableNoResultsFilterItem';
import { Close } from '@mui/icons-material';

interface Props<T extends Object> {
  availableFilters: DialTableFilter<T>[];
  selectedFilters: DialTableFilter<T>[];
  onChange: (value: DialTableFilter<T>[]) => void;
  /**
   * Callback called when 'Enter' key down on the input and all the filters are confirmed
   * @param value
   * @returns
   */
  onEnterKeyDown?: (value: DialTableFilter<T>[]) => void;
  /** Object with callbacks called to return custom filter options and selected values. The custom options and selcted values will not overwrite the default options.
   *
   * This callback can be used to consider other DataCategory that are not already considered by the table.
   */
  customFilterValueOptions?: CustomFilterValueOptions;
  onlyEqualsOperator?: boolean;
}

export const DialTableFilterInput = <T extends Object>(props: Props<T>) => {
  const {
    availableFilters,
    selectedFilters,
    onChange,
    onEnterKeyDown,
    customFilterValueOptions,
    onlyEqualsOperator
  } = props;
  const {
    availableFilterOptions,
    clearFilters,
    currentStep,
    dropdownMenuRef,
    filtersBoxRef,
    filtersInputRef,
    filtersListRef,
    filterToEdit,
    handleAddNewSelectedFilter,
    handleAddOperator,
    handleKeyDown,
    input,
    isOpenDropdown,
    itemRefs,
    modifiers,
    onChangeFilterToEdit,
    onChangeFilterValues,
    onChangeInput,
    onChangeSelectedFilter,
    onClickInput,
    onKeyDownInput,
    onKeyUpInput,
    onRemoveFilterFilterToEdit,
    onRemoveSelectedFilter,
    operatorOptions,
    optionsMap,
    showFilterToEditValuesFn,
    sortedSelectedFilters
  } = useDialTableFilterInput({
    availableFilters,
    selectedFilters,
    onChange,
    onlyEqualsOperator,
    onEnterKeyDown
  });
  const getCustomFilterValueOptionListFn =
    customFilterValueOptions?.getValueOptionListFn;
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      {/* TEXTFIELD: actually a Box taht simulates a MUI TextField */}
      <Box
        ref={filtersBoxRef}
        display="flex"
        alignItems="center"
        tabIndex={0}
        overflow="scroll"
        border={isOpenDropdown ? 2 : 1}
        borderColor={isOpenDropdown ? '#10103F' : 'rgba(0, 0, 0, 0.23)'}
        borderRadius={1}
        boxSizing="border-box"
        py={0.2}
        px={1.5}
        minHeight={37}
        width="100%"
        sx={{
          '&:hover': {
            borderColor: !isOpenDropdown
              ? theme.colors.alpha.black[50]
              : undefined
          }
          // '&:focus-within': {
          //   border: 2,
          //   borderColor: '#10103F'
          // }
        }}
      >
        <List
          ref={filtersListRef}
          disablePadding
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            gap: 0.75
          }}
        >
          {/* SELECTED FILTERS */}
          {sortedSelectedFilters.map((filter, index) => (
            <ListItem
              key={`${String(filter.fieldName)}-${index}`}
              disablePadding
              sx={{ flex: 0 }}
            >
              <DialTableFilterItem
                filter={filter}
                onRemoveFilter={onRemoveSelectedFilter}
                onChangeFilter={onChangeSelectedFilter}
                // always show the value box
                showValuesFn={() => true}
                // always show the operator box
                showOperatorFn={() => true}
                optionsMap={optionsMap}
                customFilterValueOptions={customFilterValueOptions}
                onlyEqualsOperator={onlyEqualsOperator}
              />
            </ListItem>
          ))}
          {/* FILTER TO EDIT */}
          {!!filterToEdit && (
            <ListItem
              key={`${String(filterToEdit.fieldName)}-edit`}
              sx={{ flex: 0, p: 0 }}
            >
              <DialTableFilterItem
                filter={filterToEdit}
                onRemoveFilter={onRemoveFilterFilterToEdit}
                showValuesFn={showFilterToEditValuesFn}
                onChangeFilter={onChangeFilterToEdit}
                optionsMap={optionsMap}
                customFilterValueOptions={customFilterValueOptions}
                onlyEqualsOperator={onlyEqualsOperator}
              />
            </ListItem>
          )}
          <ListItem
            disablePadding
            sx={{ flex: 1, position: 'relative', width: '100%' }}
          >
            <Input
              inputRef={filtersInputRef}
              fullWidth
              disableUnderline
              placeholder={`${t('Filter results')}...`}
              value={input}
              onChange={onChangeInput}
              onKeyUp={onKeyUpInput}
              onKeyDown={onKeyDownInput}
              onClick={onClickInput}
              sx={{ width: '100%', minWidth: '150px' }}
              endAdornment={
                selectedFilters.filter((filter) => !filter.required).length >
                0 ? (
                  <IconButton
                    title={t('Clear')}
                    color="error"
                    size="small"
                    onClick={() => {
                      clearFilters();
                    }}
                  >
                    <Close />
                  </IconButton>
                ) : undefined
              }
            />
          </ListItem>
        </List>
      </Box>
      {/* DROPDOWN MENU */}
      <Popper
        // A zIndex higher than the zIndex of the Dialog
        sx={{ zIndex: 1300 }}
        open={isOpenDropdown}
        anchorEl={filtersBoxRef?.current}
        placement="bottom-start"
        transition
        modifiers={modifiers}
        onKeyDown={handleKeyDown}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper tabIndex={0} ref={dropdownMenuRef}>
              <List
                sx={{
                  minWidth: '60px',
                  maxHeight: '450px',
                  overflow: 'scroll'
                }}
              >
                {/* NO RESULTS */}
                {(currentStep === FillFilterSteps.OPERATOR &&
                  operatorOptions.length === 0) ||
                  (currentStep === FillFilterSteps.FIELDNAME &&
                    availableFilterOptions.length === 0 && (
                      <DialTableNoResultsFilterItem />
                    ))}
                {/* FIELDNAME */}
                {currentStep === FillFilterSteps.FIELDNAME &&
                  availableFilterOptions?.map((availableFilter, index) => {
                    return (
                      <ListItemButton
                        key={`${String(availableFilter.fieldName)}-${index}`}
                        ref={(listItem) => {
                          if (!!itemRefs)
                            return (itemRefs.current[index] = listItem);
                        }}
                        onClick={() => {
                          handleAddNewSelectedFilter(availableFilter);
                        }}
                      >
                        <Typography>
                          {availableFilter?.label ?? availableFilter.fieldName}
                        </Typography>
                      </ListItemButton>
                    );
                  })}
                {/* OPERATOR */}
                {currentStep === FillFilterSteps.OPERATOR &&
                  operatorOptions.map((operator, index) => {
                    return (
                      <DialTableListItemButton
                        key={String(operator?.value)}
                        ref={(listItem) => {
                          if (!!itemRefs)
                            return (itemRefs.current[index] = listItem);
                        }}
                        label={operator?.label}
                        description={operator?.description}
                        onClick={() => {
                          handleAddOperator(operator.value as Operator);
                        }}
                      />
                    );
                  })}
                {/* VALUES */}
                {currentStep === FillFilterSteps.VALUES && (
                  <DialTableFilterValueOptions
                    input={input}
                    itemRefs={itemRefs}
                    filterToEdit={filterToEdit}
                    onChangeFilterValues={onChangeFilterValues}
                    getCustomFilterValueOptionsFn={
                      getCustomFilterValueOptionListFn
                    }
                  />
                )}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
