import { Phone } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { useRef, useEffect } from 'react';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';

export const BlinkCall: React.FC = () => {
  const inCallRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const connectedRTUser = getConnectedRTUser();

  function blink() {
    if (connectedRTUser && connectedRTUser.currentStatus.name === 'InCall') {
      if (inCallRef.current.style.opacity === '1') {
        inCallRef.current.style.opacity = '0';
      } else {
        inCallRef.current.style.opacity = '1';
      }
    }
  }
  useEffect(() => {
    const interval = setInterval(blink, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      ref={inCallRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: theme.colors.success.lighter,
        height: '32px',
        width: '32px',
        transitionDuration: '1000ms'
      }}
    >
      <Phone
        sx={{ height: '15px', width: '15px' }}
        fontSize="small"
        color="success"
      ></Phone>
    </Box>
  );
};
