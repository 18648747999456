import axios from 'axios';
import { EXTENSIONS } from 'src/utils/apiRoutes/msAcd';

export const getExtensionsByCompanyId = (companyId: string) => {
  const params = {
    companyId
  };
  return axios
    .get(EXTENSIONS, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
