import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { INSTAGRAM_ACCOUNT_ID } from 'src/utils/apiRoutes/msInstagram';

function deleteInstagramAccountApi(accountId: string) {
  const path = replaceURLParams(INSTAGRAM_ACCOUNT_ID, { '{id}': accountId });

  return axios.delete(path);
}

export default deleteInstagramAccountApi;
