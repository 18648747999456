import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { emitCustomEvent } from 'react-custom-events';
import { ReportReadyMessage } from '../models/reportingAttachment';

export function onReportReadyMsg(eventWS: ReportReadyMessage) {
  const eventWSData = eventWS;
  const eventName = CustomEventNames.REPORT_READY;
  setTimeout(async () => {
    emitCustomEvent(eventName, eventWSData);
  }, 1000);
}
