import { useEffect } from 'react';

import { Box, IconButton, Slider, Stack } from '@mui/material';
import {
  setAudioVolume,
  setRingVolume
} from 'src/store/slices/users/configVolumeSlice';
import { useDispatch, useSelector } from 'src/store/store';
import { useMemoValue } from './useMemoValue';
import {
  NotificationsNoneTwoTone,
  NotificationsOffTwoTone
} from '@mui/icons-material';

export const RingSlider: React.FC = () => {
  const localStgKey = 'ringVolume';

  const { value, onSetInputValue, onResetMemoValue, onSetMemoValue, isReset } =
    useMemoValue(localStgKey);

  const { ringVolume } = useSelector((state) => state.configVolume);
  const dispatch = useDispatch();

  const audio = document.getElementById('webrtc-ring') as HTMLMediaElement & {
    setSinkId(deviceId: string): void;
  };
  const audioBeep = document.getElementById(
    'webrtc-beep'
  ) as HTMLMediaElement & {
    setSinkId(deviceId: string): void;
  };
  const socialMediaRing = document.getElementById(
    'socialmedia-message-notification'
  ) as HTMLMediaElement & {
    setSinkId(deviceId: string): void;
  };
  const telegramRing = document.getElementById(
    'socialmedia-message-notification-Telegram'
  ) as HTMLMediaElement & {
    setSinkId(deviceId: string): void
  }
  const whatsappRing = document.getElementById(
    'socialmedia-message-notification-WhatsApp'
  ) as HTMLMediaElement & {
    setSinkId(deviceId: string): void
  };
  const emailRing = document.getElementById(
    'socialmedia-message-notification-WebChat'
  ) as HTMLMediaElement & {
    setSinkId(deviceId: string): void
  };
  const webchatRing = document.getElementById(
    'socialmedia-message-notification-Email'
  ) as HTMLMediaElement & {
    setSinkId(deviceId: string): void
  };

  //Audio.volume values between 0 and 1.
  useEffect(() => {
    if (localStorage.getItem(localStgKey)) return;
    // because useMemoValue is in charge of getting the item from Local Storage
    audio.volume = value / 100;
    audioBeep.volume = value / 100;
    socialMediaRing.volume = value / 100;
    telegramRing.volume = value / 100;
    whatsappRing.volume = value / 100;
    emailRing.volume = value / 100;
    webchatRing.volume = value / 100;
    onSetInputValue(ringVolume);
  }, []);

  //Audio.volume values between 0 and 1.
  useEffect(() => {
    audio.volume = value / 100;
    audioBeep.volume = value / 100;
    socialMediaRing.volume = value / 100;
    telegramRing.volume = value / 100;
    whatsappRing.volume = value / 100;
    emailRing.volume = value / 100;
    webchatRing.volume = value / 100;
    dispatch(setRingVolume(value));
    localStorage.setItem(localStgKey, JSON.stringify(value));
  }, [value]);

  return (
    <Box>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems="center"
        spacing={2}
      >
        <Slider
          id="ring-slider"
          size="medium"
          sx={{
            width: '192px'
          }}
          color="secondary"
          orientation="horizontal"
          value={value}
          onChange={(e, val) => {
            onSetInputValue(val);
          }}
        />

        <IconButton id="ring-slider-button">
          {isReset ? (
            <NotificationsOffTwoTone
              color="secondary"
              onClick={onSetMemoValue}
            />
          ) : (
            <NotificationsNoneTwoTone
              color="secondary"
              onClick={onResetMemoValue}
            />
          )}
        </IconButton>
      </Stack>
    </Box>
  );
};
