import { useContext } from 'react';

import HelperAccordion from '../../components/HelperAccordion';
import ContactDataHeader from '../ContactDataHeader';
import ContactDataItem from '../ContactDataItem';
import Scrollbar from 'src/components/Scrollbar';
import {
  ContactFieldType,
  ContactFieldTypeNames,
  Contact,
  ContactField
} from 'src/modules/Contacts/models/contacts';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Box, Divider, List, styled } from '@mui/material';
import { getContactFieldsFromContact } from 'src/modules/Contacts/domain/getContactFields';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        height: 100%;
        display: flex;
    `
);

interface ContactDataPanelProps {
  contact: Contact;
  contactHeaderDirection?: 'column' | 'row';
  isCTI?: boolean;
}

const ContactDataView = (props: ContactDataPanelProps) => {
  const { contact, contactHeaderDirection = 'column', isCTI } = props;
  const { barState } = useContext(CTIBarContext);
  const { setIsPopoverOpened } = barState;

  const { t }: { t: any } = useTranslation();

  const companyFieldsQuery = useGetContactFields(
    { companyId: contact?.companyId },
    !!contact
  );

  if (companyFieldsQuery.isLoading)
    return <Box sx={{ height: '100%', width: '100%' }}>Loading...</Box>;

  const companyFields = companyFieldsQuery?.data?.elements;
  const fieldTypes: ContactFieldType[] = [
    ...new Set(companyFields.map((field: ContactField) => field.type))
  ];

  const nameFields = getContactFieldsFromContact(
    { contact, fieldType: 'Name' },
    companyFieldsQuery.data?.elements
  );
  const phoneFields = getContactFieldsFromContact(
    { contact, fieldType: 'Phone' },
    companyFieldsQuery.data?.elements
  );

  // TODO: En el futuro, los campos principales no seran simplemente el primero
  const primaryNameField = nameFields[0];
  const primaryPhoneField = phoneFields[0];

  if (!contact)
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {t('No contact saved')}
      </Box>
    );
  if (isCTI) setIsPopoverOpened(true);

  return (
    <>
      <Helmet>
        <title>Helper Panel - Contact Data</title>
      </Helmet>
      <RootWrapper className="Mui-FixedWrapper">
        <Box
          sx={{
            // minWidth: 360,
            width: '100%',
            minWidth: 300
          }}
        >
          <Scrollbar>
            <Box px={2} py={1}>
              <ContactDataHeader
                name={primaryNameField?.value}
                phoneNumber={primaryPhoneField?.value}
                contactHeaderDirection={contactHeaderDirection}
              />

              <Divider
                sx={{
                  my: 3
                }}
              />

              {fieldTypes &&
                fieldTypes.map((type: ContactFieldType) => {
                  if (type === 'Name') return;

                  const contactFieldsOfType = getContactFieldsFromContact(
                    { contact, fieldType: type },
                    companyFields
                  );

                  if (
                    contactFieldsOfType.find((field) => field.type === type) ===
                    undefined
                  )
                    return;

                  return (
                    <HelperAccordion
                      key={type}
                      title={ContactFieldTypeNames[type]}
                    >
                      <List component="div">
                        {contactFieldsOfType.map((field, index) => {
                          return (
                            <ContactDataItem
                              key={field.name + index}
                              field={field}
                            />
                          );
                        })}
                      </List>
                    </HelperAccordion>
                  );
                })}
            </Box>
          </Scrollbar>
        </Box>
      </RootWrapper>
    </>
  );
};

export default ContactDataView;
