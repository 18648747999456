import React from 'react';
import { t } from 'i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import { FormikErrors } from 'formik';

interface Props {
  typeForm: 'create' | 'update';
  values: {
    name: string;
    description: string;
  };
  errors: FormikErrors<{
    name: string;
    description: string;
  }>;
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleChangeLanguage: (event: SelectChangeEvent) => void;
  language: 'English' | 'Spanish';
}

const GeneralAiModelForm: React.FC<Props> = ({
  typeForm,
  values,
  errors,
  handleBlur,
  handleChange,
  handleChangeLanguage,
  language
}) => {
  return (
    <Stack direction={'row'} width="100%" spacing={2}>
      <TextField
        autoFocus
        fullWidth
        name="name"
        label={t('Name')}
        placeholder={t('Write here the AI model name')}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!errors.name}
        helperText={errors.name}
      />

      <TextField
        fullWidth
        name="description"
        label={t('Description')}
        placeholder={t('Write here a description...')}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!errors.description}
        helperText={errors.description}
      />

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t('Language')}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          label={t('Language')}
          onChange={handleChangeLanguage}
        >
          <MenuItem value="Spanish">{t('Spanish')}</MenuItem>
          <MenuItem value="English">{t('English')}</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

export default GeneralAiModelForm;
