import { useEffect, useState } from 'react';
import { Contact, ContactTag } from 'src/modules/Contacts/models/contacts';
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme
} from '@mui/material';
import ContactDataView from './ContactDataView';
import { Conversation } from 'src/models/conversations/conversations';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import ContactDataListView from './ContactDataListView';
import AddContactDataView from './CreateContactDataView/AddContactDataView';
import UpdateContactDataView from './CreateContactDataView/UpdateContactDataView';
import HelperPanelHeader from '../components/HelperPanelHeader';
import ContactActionsHeader from './ContactActionsHeader';
import { getCompanyContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import { HelperTab } from '../../HelperMenu/items';
import { getDaServiceById } from 'src/services/daServices/utils';
import { assignContactToConversationApi } from 'src/services/coordination/infrastructure/assignContactToConversationApi';
import { getConversationByIdFromApi } from 'src/services/socialmedia/domain/conversation/getConversation';
import { setConversationStore } from 'src/services/socialmedia/infraestructure/store/setConversationStore';
import { t } from 'i18next';
import { useServiceByIdQuery } from 'src/services/users/application/query/getUsersDataQueries';
import { getConversationInvolvedContacts } from 'src/services/socialmedia/domain/conversation/utils';
import ContactViewPanels from './ContactViewPanels';
import { useGetContactQuery } from 'src/services/socialmedia/application/query/getContactsQuery';

interface ContactDataPanelProps {
  conversation?: Conversation;
  showConversationHistory?: boolean;
  showHelperTabButtons?: boolean;
}

export enum ContactDataPanelTabs {
  CONTACT_LIST = 'contactList',
  CONTACT_DATA = 'contactData',
  CONTACT_CREATE = 'contactCreate',
  CONTACT_EDIT = 'contactEdit'
}

const ContactDataPanel = (props: ContactDataPanelProps) => {
  const { conversation, showConversationHistory, showHelperTabButtons } = props;

  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const contactQuery = useGetContactQuery(selectedContact?.id);

  useEffect(() => {
    if (contactQuery.data) {
      setSelectedContact(contactQuery.data);
    }
  }, [contactQuery.data]);

  const [webChatFieldsContact, setWebChatFieldsContact] =
    useState<Contact | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined
  );
  const [currentTab, setCurrentTab] = useState(
    ContactDataPanelTabs.CONTACT_LIST
  );

  const theme = useTheme();

  // GET ALL TAGS OPTIONS
  const [tags, setTags] = useState<ContactTag[]>([]);
  useEffect(() => {
    let isMounted = true;
    getCompanyContactTagsQuery({
      companyId: conversation.companyId
    }).then((response) => {
      if (isMounted) {
        setTags(response?.elements);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!selectedContact) {
      setEditingIndex(undefined);
    }
  }, [selectedContact]);

  const companyFieldsQuery = useGetContactFields(
    { companyId: conversation.companyId },
    !!conversation
  );

  useEffect(() => {
    if (
      conversation?.involvedContacts.length === 1 &&
      conversation.involvedContacts[0].contactId !== null
    ) {
      getConversationInvolvedContacts(conversation).then((contacts) => {
        setSelectedContact(contacts[0]);
        setCurrentTab(ContactDataPanelTabs.CONTACT_DATA);
      });
    }
  }, [conversation]);

  const serviceQuery = useServiceByIdQuery(conversation?.serviceId);
  const conversationService = serviceQuery?.data;
  const contactGroupId = conversationService?.contactGroupId;

  const isLoading = companyFieldsQuery.isLoading;

  const handleAssignContact = async (contact: Contact) => {
    if (conversation?.id && editingIndex !== undefined) {
      setEditingIndex(undefined);
      const service = await getDaServiceById(conversation.serviceId);
      if (!service) return;

      await assignContactToConversationApi({
        conversationId: conversation.id,
        contactId: contact.id,
        pos: editingIndex
      });

      const newConversation = await getConversationByIdFromApi(conversation.id);
      if (!newConversation) return;
      newConversation.messages = conversation?.messages;
      newConversation.totalMessages = conversation?.totalMessages;

      setConversationStore(newConversation);
    }
  };

  if (isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />;
      </Box>
    );

  return (
    <>
      {currentTab === ContactDataPanelTabs.CONTACT_LIST && (
        <>
          <HelperPanelHeader
            helperTab={HelperTab.CONTACT_DATA}
            showHelperTabButtons={showHelperTabButtons}
          />
          {conversation.involvedContacts.length > 0 && (
            <>
              <Box
                padding={1}
                display="flex"
                alignItems="center"
                justifyContent="start"
                sx={{ mt: 2 }}
                height="30px"
              >
                <Typography variant="h5" color={'black'} noWrap>
                  {`${conversation.involvedContacts.length} ${
                    conversation.involvedContacts.length === 1
                      ? t('Participant')
                      : t('Participants')
                  }`}
                </Typography>
              </Box>
              <Divider sx={{ mx: 2 }} />
            </>
          )}
          <Box
            height={
              showConversationHistory
                ? '100%'
                : `calc(100% - ${theme.header.height})`
            }
          >
            <ContactDataListView
              setEditingIndex={setEditingIndex}
              key={conversation.id}
              companyFields={companyFieldsQuery?.data?.elements}
              conversation={conversation}
              setSelectedContact={(contact) => {
                setSelectedContact(contact);
                setCurrentTab(ContactDataPanelTabs.CONTACT_DATA);
              }}
              setIsAdding={() =>
                setCurrentTab(ContactDataPanelTabs.CONTACT_CREATE)
              }
              setIsEditing={() =>
                setCurrentTab(ContactDataPanelTabs.CONTACT_EDIT)
              }
              setWebChatFieldsContact={setWebChatFieldsContact}
            />
          </Box>
        </>
      )}

      {currentTab === ContactDataPanelTabs.CONTACT_DATA && (
        <ContactViewPanels
          showConversationHistory={showConversationHistory}
          conversation={conversation}
          selectedContact={selectedContact}
          showHelperTabButtons={showHelperTabButtons}
        >
          <Box
            height={
              showConversationHistory
                ? `calc(100% - ${theme.header.height} - 40px)`
                : `100%`
            }
          >
            <ContactActionsHeader
              onClickBackButton={() => {
                setSelectedContact(null);
                setCurrentTab(ContactDataPanelTabs.CONTACT_LIST);
              }}
              onClickEditButton={() => {
                setCurrentTab(ContactDataPanelTabs.CONTACT_EDIT);
              }}
            />
            <Box height={`calc(100% - 40px)`}>
              <ContactDataView
                key={conversation.id}
                contact={selectedContact}
              />
            </Box>
          </Box>
        </ContactViewPanels>
      )}

      {currentTab === ContactDataPanelTabs.CONTACT_EDIT && (
        <ContactViewPanels
          showConversationHistory={showConversationHistory}
          conversation={conversation}
          selectedContact={selectedContact}
          showHelperTabButtons={showHelperTabButtons}
        >
          <Box height="100%">
            <UpdateContactDataView
              key={conversation.id}
              contact={selectedContact}
              tags={tags}
              companyFields={companyFieldsQuery?.data?.elements}
              setIsOpen={(open) =>
                open
                  ? setCurrentTab(ContactDataPanelTabs.CONTACT_EDIT)
                  : setCurrentTab(ContactDataPanelTabs.CONTACT_DATA)
              }
              onSave={(contact) => {
                handleAssignContact(contact);
                setCurrentTab(ContactDataPanelTabs.CONTACT_DATA);
              }}
              saveButtonLabel={
                editingIndex !== undefined && t('Save and Assign')
              }
              onDeleteClose={() =>
                setCurrentTab(ContactDataPanelTabs.CONTACT_LIST)
              }
            />
          </Box>
        </ContactViewPanels>
      )}

      {currentTab === ContactDataPanelTabs.CONTACT_CREATE && (
        <ContactViewPanels
          showConversationHistory={showConversationHistory}
          conversation={conversation}
          selectedContact={selectedContact}
          showHelperTabButtons={showHelperTabButtons}
        >
          <Box height="100%">
            <AddContactDataView
              key={conversation.id}
              contact={webChatFieldsContact ?? selectedContact}
              tags={tags}
              companyFields={companyFieldsQuery?.data?.elements}
              setContact={setSelectedContact}
              setIsOpen={(open) =>
                open
                  ? setCurrentTab(ContactDataPanelTabs.CONTACT_CREATE)
                  : setCurrentTab(ContactDataPanelTabs.CONTACT_LIST)
              }
              defaultContactGroupId={contactGroupId}
              onSave={handleAssignContact}
              saveButtonLabel={
                editingIndex !== undefined ? t('Save and Assign') : null
              }
              fromConversation={true}
            />
          </Box>
        </ContactViewPanels>
      )}
    </>
  );
};

export default ContactDataPanel;
