import axios from 'axios';
import { TextContent } from 'src/models/conversations/ChatMessages/TextMessages';
import { TELEGRAM_MESSAGE_EXISTING_TEXT } from 'src/utils/apiRoutes/msTelegram';

export interface SendTelegramMessageExistingTextApiParams {
  accountId: string;
  content: TextContent;
  conversationId: string;
  replyTo?: string;
}
/**
 * Send a text message to an existing conversation
 * @param params
 */
export const sendTelegramMessageExistingTextApi = async (
  params: SendTelegramMessageExistingTextApiParams
) => {
  const { content, ...restParams } = params;
  await axios.post(TELEGRAM_MESSAGE_EXISTING_TEXT, content, {
    params: restParams
  });
};
