import { RTCall } from 'src/models/rtcall';
import { addRTCallToQueue } from '../../rtusers/domain/queues/addRTCallToQueue';
import { removeRTCallFromQueue } from '../../rtusers/domain/queues/removeRTCallFromQueue';
import { addRTCallToRTUsers } from '../../rtusers/domain/rtcall/addRTCallToRTUser';
import { finishedResultAlert, removeRTCallFromUsers } from '../../rtusers/domain/rtcall/removeRTCallFromRTUser';

/** UPDATE DATA WITH THIS NEW RTCALL
 *
 */
export const updateRTCall = (rtCall: RTCall) => {

  if (rtCall.status === 'Finished') finishedResultAlert(rtCall);
  
  // IGNORE RINGING RTCALLS FROM OTHER USERS
  {
    if (rtCall.status === 'Ringing') return;
    if (rtCall.status === 'Finished' && rtCall.previousStatus === 'Ringing')
      return;
  }

  removeRTCallFromQueue(rtCall);
  if (rtCall.status === 'Queued') return addRTCallToQueue(rtCall);

  if (rtCall.status === 'Finished') removeRTCallFromUsers(rtCall);
    else addRTCallToRTUsers(rtCall);
};
