import axios from 'axios';
import { replaceURLParams } from '../../../../utils';
import { Company, UserStatus } from 'src/models/users';
import { COMPANIES, COMPANIES_ID, USERS_MS } from 'src/utils/apiRoutes/msUsers';
import { DirectionType } from 'src/components/TableReporting/models/direction';

const API_EXTENSION_COMPANY_ID = process.env
  .REACT_APP_API_COMPANIE_EXTENSION as string;

const USER_STATUS = process.env.REACT_APP_USER_STATUS as string;

export const getCompanyUserStatusByIdApi = async (companyId: string) => {
  const path = replaceURLParams(COMPANIES + USER_STATUS, {
    '{id}': companyId
  });

  const params = { manualChange: true };

  const resp = await axios.get<UserStatus[]>(path, { params });
  return resp.data;
};

export interface CompanyParams {
  sortDir?: DirectionType;
  sortBy?: keyof Company;
}

export const getCompanies = async (params?: CompanyParams) => {
  const resp = await axios.get<Company[]>(COMPANIES, {
    params
  });
  return resp.data;
};

export const getCompanyById = async (id: string) => {
  const path = replaceURLParams(COMPANIES_ID, {
    '{id}': id
  });

  const resp = await axios.get<Company>(path);
  return resp.data;
};

export const getUsedExtensionsCompanyByIdApi = (id: string) => {
  const path =
    replaceURLParams(COMPANIES_ID, {
      '{id}': id
    }) + API_EXTENSION_COMPANY_ID;

  return axios
    .get(path)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};
