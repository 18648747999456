import { useSnackbar } from 'notistack';
import { t } from 'i18next';

type MessageType = 'default' | 'error' | 'success' | 'info' | 'warning';
type MessagePositionY = 'bottom' | 'top';
type MessagePositionX = 'center' | 'left' | 'right';

export interface MessageAlert {
  msg: string;
  type: MessageType;
}

export const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showAlert = (
    message: string,
    type: MessageType,
    time?: number,
    positionY?: MessagePositionY,
    positionX?: MessagePositionX
  ) => {
    enqueueSnackbar(t(`${message}`), {
      variant: type,
      autoHideDuration: time ?? 3000,
      anchorOrigin: {
        vertical: positionY ?? 'top',
        horizontal: positionX ?? 'center'
      }
    });
  };

  return {
    showAlert
  };
};
