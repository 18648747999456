export const setSocialMediaAudioElementVolume = (value: number) => {
  const socialMediaRing = document.getElementById(
    'socialmedia-message-notification'
  ) as HTMLMediaElement;
  socialMediaRing.volume = value;

  const telegramRing = document.getElementById(
    'socialmedia-message-notification-Telegram'
  ) as HTMLMediaElement
  telegramRing.volume = value

  const whatsappRing = document.getElementById(
    'socialmedia-message-notification-WhatsApp'
  ) as HTMLMediaElement
  whatsappRing.volume = value

  const emailRing = document.getElementById(
    'socialmedia-message-notification-WebChat'
  ) as HTMLMediaElement
  emailRing.volume = value

  const webchatRing = document.getElementById(
    'socialmedia-message-notification-Email'
  ) as HTMLMediaElement
  webchatRing.volume = value
};
