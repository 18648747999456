import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import {
  Ticket,
  TicketComment,
  TicketConversationSummary
} from '../models/tickets';
import {
  TICKETS_COMMENT_ID,
  TICKETS_ID
} from 'src/utils/apiRoutes/msMessaging';

export const updateTicketApi = async (
  ticket: Ticket,
  firstRescheduleTimestamp?: string
): Promise<{ ticket: Ticket; closedConvs: TicketConversationSummary[] }> => {
  const path = replaceURLParams(TICKETS_ID, {
    '{id}': ticket.id
  });
  const params = { firstRescheduleTimestamp };
  const resp = await axios.put(path, ticket, { params });

  return resp.data;
};

export const updateTicketCommentApi = async (
  comment: TicketComment,
  ticketId: string
): Promise<TicketComment> => {
  const path = replaceURLParams(TICKETS_COMMENT_ID, {
    '{ticketId}': ticketId,
    '{commentId}': comment.id
  });
  const resp = await axios.put(path, comment);
  return resp.data;
};
