import { setMicVolumeStore } from '../infrastructure/store/devices/setMicVolumeStore';
import WebRTCDevices from '../webrtc.devices';

/** SET VOICE VOLUME VALUE IN STORE
 *
 * @param volume New volume value
 */
export const setMicVolume = (volume: number) => {
  const gainNode = WebRTCDevices.getGainNode();
  if (gainNode) {
    gainNode.gain.value = volume;
  }
  setMicVolumeStore(volume);
};
