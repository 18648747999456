import { Reply } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Input,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { WebChatStyle } from '../../../models/webChatStyle';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { wrap } from 'lodash';
import { t } from 'i18next';

interface webChatFooterProps {
  formikProps: WebChatStyle;
}

const Footer: React.FC<webChatFooterProps> = ({ formikProps }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background:
          formikProps?.conversationStyle.msgBoxBGColor.color ?? '#ffffff',
        display: 'flex',
        alignItems: 'center',
        px: 2,
        py: 1,
        justifyContent: 'space-between',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        marginBottom: '0px',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box'
      }}
    >
      <Input
        multiline
        maxRows={2}
        style={{
          border: 'none',
          outline: 'none',
          fontSize: '14px',
          width: '185px',
          backgroundColor:
            formikProps?.conversationStyle.msgBoxBGColor.color ?? '#ffffff',
          color:
            formikProps?.conversationStyle.msgBoxTextColor.color ?? '#223354',
          fontFamily: formikProps?.conversationStyle.font ?? 'Roboto'
        }}
        placeholder={t('Your message here...')}
      />
      <Stack
        direction="row"
        height="75%"
        borderRadius={1}
        sx={{
          backgroundColor:
            formikProps?.conversationStyle.msgBoxBGColor.color ?? '#ffffff'
        }}
        marginLeft="3.5px"
      >
        {/* <Stack
          width="35px"
          height="100%"
          justifyContent={'center'}
          flexWrap={'wrap'}
          alignContent={'center'}
        >
          <Button
            sx={{
              padding: '0px',
              minWidth: '30px',
              width: '40px',
              height: '100%',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '1px',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '1px'
            }}
          >
            <EmojiEmotionsIcon />
          </Button>
        </Stack> */}
        <Stack
          width="35px"
          height="100%"
          justifyContent={'center'}
          flexWrap={'wrap'}
          alignContent={'center'}
        >
          <Button
            sx={{
              padding: '0px',
              minWidth: '30px',
              width: '40px',
              height: '100%',
              borderRadius: '10px',
              // borderTopLeftRadius: '1px',
              // borderTopRightRadius: '10px',
              // borderBottomLeftRadius: '1px',
              // borderBottomRightRadius: '10px'
              '&:focus': {
                outline: 'none'
              },
              '&:active': {
                outline: 'none'
              }
            }}
          >
            <AttachFileIcon sx={{ width: '24px', height: '24px' }} />
          </Button>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        sx={{
          background:
            formikProps?.conversationStyle.msgBoxIconsColor.color ?? '#1975ff',
          color: 'white',
          paddingX: '12.5px'
        }}
        id="btnReply-webChatStyle"
      >
        <Typography
          marginX={0.5}
          sx={{ fontFamily: formikProps?.conversationStyle.font ?? 'Roboto' }}
        >
          {t('Send')}
        </Typography>
        <Reply sx={{ width: '24px', height: '24px' }} />
      </Button>
    </Box>
  );
};

export default Footer;
