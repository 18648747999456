import { useState } from 'react';

export const useMemoValue: Function = (
  // Local Storage key (string) or Numerical initial value (number)
  initialValue: string | number
): {
  value: number;
  memoValue: number;
  onSetInputValue: Function;
  onResetMemoValue: Function;
  onSetMemoValue: Function;
  isReset: boolean;
} => {
  const input =
    typeof initialValue === 'string'
      ? Number(localStorage.getItem(initialValue))
      : initialValue;

  const [value, setValue] = useState(input);
  const [memoValue, setMemoValue] = useState(0);
  const isReset = value === 0;

  const onSetInputValue = (inputValue: number): void => {
    setValue(inputValue);
  };

  const onResetMemoValue = (): void => {
    setMemoValue(value);
    setValue(0);
  };

  const onSetMemoValue = (): void => {
    setValue(memoValue);
    setMemoValue(value);
  };

  return {
    value,
    memoValue,
    onSetInputValue,
    onResetMemoValue,
    onSetMemoValue,
    isReset
  };
};
