import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Restricted from 'src/components/Restricted';
import {
  checkReadUserPermissions,
  checkReadUserRolePermissions,
  checkReadVariablePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { UserProvider } from '../modules/UsersView/context/userContext';
import { checkRolesAndPermsFeature } from 'src/contexts/CompanyFeaturesContext/utils/checkFeatures';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Companies = Loader(lazy(() => import('../modules/Companies/views')));
const Billing = Loader(lazy(() => import('../modules/Billing/views')));
const RolesPermissions = Loader(
  lazy(() => import('../modules/RolesPermissionsView'))
);
const Users = Loader(lazy(() => import('../modules/UsersView')));
const VariablesPage = Loader(lazy(() => import('../modules/Variables/views')));
const Accounts = Loader(lazy(() => import('../modules/Accounts')));

const generalRoutes: RouteObject[] = [
  {
    path: 'accounts',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={(_, isSuperadmin) => isSuperadmin}
      >
        <Accounts />
      </Restricted>
    )
  },
  {
    path: 'companies',
    element: <Companies />
  },
  {
    path: 'billing',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={(_, isSuperadmin) => isSuperadmin}
      >
        <Billing />
      </Restricted>
    )
  },
  {
    path: 'roles-and-permissions',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadUserRolePermissions}
        featureCheckerFn={checkRolesAndPermsFeature}
      >
        <RolesPermissions />
      </Restricted>
    )
  },
  {
    path: 'view-users',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadUserPermissions}>
        <UserProvider>
          <Users />
        </UserProvider>
      </Restricted>
    )
  },
  {
    path: 'variables',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadVariablePermissions}>
        <VariablesPage />
      </Restricted>
    )
  }
];

export default generalRoutes;
