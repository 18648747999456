import FullCalendar, {
  CustomButtonInput,
  EventInput,
  EventSourceInput,
  ToolbarInput
} from '@fullcalendar/react';
import {
  ScheduleCalendar,
  ScheduleCalendarStatus
} from '../../createScheduleCalendar';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { useTheme } from '@mui/material';
import { NEW_SCHEDULE_ID } from '../../ScheduleCalendarForm';
import { t } from 'i18next';

interface Props {
  schedules: ScheduleCalendar[];
  selectedSchedule: ScheduleCalendar;
  calendarRef?: React.MutableRefObject<FullCalendar>;
  onDateClick?: (date: Date) => void;
  onEventDrop?: (schedule: ScheduleCalendar) => void;
  onEventClick: (schedule: ScheduleCalendar) => void;
  selectedDay: Date;
  customButtons?: {
    [name: string]: CustomButtonInput;
  };
  headerToolbar?: ToolbarInput;
  onNewEventButtonClick: (date: Date) => void;
  height?: string;
  disableUpdateTimestamp?: boolean;
  disableNewButton?: boolean;
}

export const DailyTimeline = ({
  calendarRef,
  onDateClick,
  onEventDrop,
  onEventClick,
  schedules,
  selectedDay,
  customButtons,
  headerToolbar,
  selectedSchedule,
  onNewEventButtonClick,
  height,
  disableNewButton
}: Props) => {
  const theme = useTheme();

  const newSchedule = schedules?.find(
    (schedule) => schedule?.id === NEW_SCHEDULE_ID
  );

  const events: EventSourceInput = schedules?.map((schedule) => {
    const date = new Date(schedule?.calendarData.scheduleTimestamp * 1000);
    const end = new Date(date?.getTime() + 15 * 60000);

    const color =
      schedule?.id === selectedSchedule?.id
        ? theme.colors.error.main
        : theme.colors.secondary.main;
    return {
      schedule,
      title: schedule?.calendarData.title,
      date: date,
      start: date,
      end,
      color,
      editable:
        schedule.scheduleStatus &&
        schedule.scheduleStatus !== ScheduleCalendarStatus.Unlaunched &&
        !(schedule.relaunchAttempts > 0)
    } as EventInput;
  });

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[timeGridPlugin, interactionPlugin]}
      initialView="timeGridOneDay"
      views={{
        timeGridOneDay: {
          duration: { days: 1 },
          type: 'timeGrid'
        }
      }}
      customButtons={{
        ...customButtons,
        newSchedule: {
          text: t('New'),
          click: () => {
            onNewEventButtonClick(selectedDay);
          }
        }
      }}
      headerToolbar={
        headerToolbar ?? {
          right: disableNewButton ? '' : 'newSchedule',
          left: 'title',
          center: ''
        }
      }
      scrollTime={(new Date().getHours() - 1).toString() + ':00:00'}
      contentHeight={height ?? '320px'}
      key={selectedDay?.toString()}
      initialDate={selectedDay}
      events={events}
      locale={esLocale}
      eventContent={(e) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            {e.event.title.length > 0 ? e.event.title : t('No title')}
          </div>
        );
      }}
      editable
      allDaySlot={false}
      dateClick={(e) => {
        if (onDateClick) {
          onDateClick(e.date);
        }
      }}
      eventClick={(e) => {
        onEventClick?.(e.event.extendedProps.schedule);
      }}
      eventDrop={(e) => {
        console.log(e);

        const newSchedule = {
          ...e.event.extendedProps.schedule,
          calendarData: {
            ...e.event.extendedProps.schedule.calendarData,
            scheduleTimestamp: e.event.start.toISOString()
          }
        };
        onEventDrop?.(newSchedule);
      }}
      titleFormat={{
        day: 'numeric',
        month: 'long'
      }}
      slotLabelFormat={{
        minute: '2-digit',
        hour: 'numeric'
      }}
      slotDuration={'00:15:00'}
      eventDurationEditable={false}
    />
  );
};
