import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { InboundRoutesProvider } from 'src/contexts/InboundRoutesContext';
import { MusicOnHold } from '../modules/MOH/views';
import { MusicOnHoldProvider } from '../modules/MOH/views/context';
import Restricted from 'src/components/Restricted';
import {
  checkReadAnnouncementPermissions,
  checkReadInboundRoutePermissions,
  checkReadIntentionModelsPermissions,
  checkReadMusicOnHoldPermissions,
  checkReadPromptsPermissions,
  checkReadTimeConditionPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import ModelsView from '../modules/Models/views';
import {
  checkKnowledgeModelsFeature,
  checkNluFeature
} from 'src/contexts/CompanyFeaturesContext/utils/checkFeatures';
import Prompts from '../modules/Prompts';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Announcements = Loader(
  lazy(() => import('../modules/AnnouncementsView'))
);
const CERoute = Loader(
  lazy(
    () =>
      import(
        'src/modules/Admin/modules/Acd/modules/InboundRoutes/views/components/InboundRoutesCreateEdit'
      )
  )
);
const Routes = Loader(lazy(() => import('../modules/InboundRoutes/views')));
const TimerGroups = Loader(
  lazy(() => import('../modules/TimeGroupsViews/views'))
);
const AIModels = Loader(lazy(() => import('../modules/Models/AIModels/views')));
const Models = Loader(lazy(() => import('../modules/Models/views')));

const acdRoutes: RouteObject[] = [
  {
    path: 'routes',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadInboundRoutePermissions}
      >
        <Routes />
      </Restricted>
    ),
    children: [
      {
        path: ':id',
        element: (
          <ReactFlowProvider>
            <InboundRoutesProvider>
              <CERoute />
            </InboundRoutesProvider>
          </ReactFlowProvider>
        )
      },
      {
        path: 'new-route',
        element: (
          <ReactFlowProvider>
            <InboundRoutesProvider>
              <CERoute />
            </InboundRoutesProvider>
          </ReactFlowProvider>
        )
      }
    ]
  },
  {
    path: 'models',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={() => true}
        featureCheckerFn={(features) => {
          return (
            checkNluFeature(features) || checkKnowledgeModelsFeature(features)
          );
        }}
      >
        <ModelsView />
      </Restricted>
    )
  },
  {
    path: 'announcements',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadAnnouncementPermissions}
      >
        <Announcements />
      </Restricted>
    )
  },
  {
    path: 'prompts',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadPromptsPermissions}>
        <Prompts />
      </Restricted>
    )
  },
  {
    path: 'schedule-groups',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadTimeConditionPermissions}
      >
        <TimerGroups />
      </Restricted>
    )
  },
  {
    path: 'moh',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadMusicOnHoldPermissions}
      >
        <MusicOnHoldProvider>
          <MusicOnHold />
        </MusicOnHoldProvider>
      </Restricted>
    )
  }
];

export default acdRoutes;
