import ReactDOMServer from 'react-dom/server';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box } from '@mui/material';
import { t } from 'i18next'; //no possible use translation hook because that componet us render in charts

const CustomZoomIcon = () => {
  const zoom = (
    <Box title={t('Zoom is activated')} sx={{ cursor: 'crosshair' }}>
      <ZoomInIcon />
    </Box>
  );

  // Convierte el componente de React a una cadena de texto HTML
  const zoomHtml = ReactDOMServer.renderToStaticMarkup(zoom);

  return zoomHtml;
};

export default CustomZoomIcon;
