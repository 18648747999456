import axios, { AxiosResponse } from 'axios';
import { CallModeTypes } from 'src/models/users';
import { replaceURLParams } from 'src/services/utils';
import {
  CAMPAIGNS,
  CAMPAIGNS_ID_ENABLE,
  CAMPAIGNS_ID
} from 'src/utils/apiRoutes/msUsers';
import { CallCampaign, Campaign } from '../../models/campaigns';
import { PaginatedElements } from 'src/models/pagination';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { ChannelType } from 'src/models/conversations/conversations';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';

export const CAMPAIGN_DRAFTS_KEY = 'campaignDrafts';

interface CampaignFilters {
  callMode?: CallModeTypes;
  channel?: ChannelType;
  companyId?: string;
  ids?: string[];
  name?: string;
  serviceId?: string;
  userId?: string;
}

export interface CampaignsParams extends CampaignFilters {
  page?: number;
  size?: number;
  sortBy?: keyof Campaign;
  sortDir?: DirectionType;
}

export const getUnknownCampaignById = async (
  campaignId: string
): Promise<unknown> => {
  const path = replaceURLParams(CAMPAIGNS_ID, { '{id}': campaignId });
  const response: AxiosResponse<unknown> = await axios.get(path);
  return response.data;
};

export const getCampaignsApi = async (campaignsParams: CampaignsParams) => {
  const resp = await axios.get<PaginatedElements<Campaign>>(CAMPAIGNS, {
    params: campaignsParams,
    paramsSerializer: { indexes: null }
  });
  return resp.data;
};

/** OBSOLETE */
export const getCampaignById: Function = (id: string) => {
  const path = replaceURLParams(CAMPAIGNS_ID, {
    '{id}': id
  });

  return axios
    .get(path)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const enableCampaigns: Function = (id: string, enabled: boolean) => {
  const path = replaceURLParams(CAMPAIGNS_ID_ENABLE, {
    '{id}': id,
    '{enable}': enabled
  });

  return axios
    .post(path)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

const checkCampaignFilters = (params: {
  campaign: Campaign;
  filters: CampaignFilters;
}) => {
  const { campaign, filters } = params;
  const { callMode, channel, companyId, ids, name, serviceId, userId } =
    filters;

  const filterFnArray = [
    // Check callMode
    () => !callMode || (campaign as CallCampaign)?.callMode === callMode,
    // Check channel
    () => !channel || campaign.channel === channel,
    // Check companyId
    () => !companyId || campaign.companyId === companyId,
    // Check ids
    () => !ids || ids.includes(campaign.id) || ids.includes(campaign?.draftId),
    // Check name
    () =>
      !name ||
      campaign.name.toLowerCase().trim().includes(name.trim().toLowerCase()),
    // Check serviceId
    () => !serviceId || campaign.serviceId === serviceId,
    // Check userId
    () => !userId || campaign.users.includes(userId)
  ];
  // Check every filter function
  return filterFnArray.every((filterFn) => filterFn());
};

export const getCampaignDraftsApi = (params: CampaignsParams = {}) => {
  const {
    page = 0,
    size = -1,
    sortBy = 'name',
    sortDir = DirectionType.ASC,
    ...filters
  } = params;
  const resp = localStorage.getItem(CAMPAIGN_DRAFTS_KEY);
  const campaignDrafts: Campaign[] = !!resp ? JSON.parse(resp) : [];
  const elements = campaignDrafts.filter((campaign) =>
    checkCampaignFilters({ campaign, filters })
  );
  const paginatedServiceDrafts = getPaginatedElements({
    elements,
    page,
    size,
    sortBy,
    sortDir
  });
  return Promise.resolve(paginatedServiceDrafts);
};
