import {
  Avatar,
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { Ticket } from '../../models/tickets';
import { useEffect, useState } from 'react';
import UsersDomain from 'src/services/users/domain/UserDomain';
import { User } from 'src/models/users';
import { useTranslation } from 'react-i18next';

interface Props {
  ticket: Ticket;
  // A User array with at least the users involved in the  conversation. If it is not received, it is fetched.
  involvedUsers?: User[];
}

const TicketParticipants = (props: Props) => {
  const { ticket, involvedUsers } = props;
  const [ticketUsers, setTicketUsers] = useState<User[]>([]);
  const theme = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    if (!!involvedUsers) {
      setTicketUsers(involvedUsers);
    } else {
      const userIds = ticket?.assignedUserIds
        ? Object.keys(ticket?.assignedUserIds).filter(
            (userId) => !ticket?.assignedUserIds[userId].fromTicket
          )
        : null;
      if (userIds) {
        UsersDomain.getUsersList(userIds).then((resp) => {
          setTicketUsers(resp);
        });
      }
    }
  }, [ticket?.assignedUserIds, involvedUsers]);

  if (ticketUsers?.length === 0) return <></>;

  return (
    <Box width="100%" py={1}>
      <Divider sx={{ my: 1 }}>
        <Typography
          variant="body1"
          sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
        >
          {t('Participants')}
        </Typography>
      </Divider>
      <Stack direction="row" mt={2} spacing={0.5} overflow="scroll">
        {ticketUsers
          .sort((a, b) => a.username.localeCompare(b.username))
          .map((user) => (
            <Tooltip key={user?.id} title={user.username}>
              <Avatar
                key={user?.id}
                style={{ backgroundColor: theme.colors.secondary.lighter }}
              >
                <Typography
                  variant="h4"
                  color={theme.colors.secondary.main}
                  fontWeight="bold"
                >
                  {user?.username?.[0]}
                </Typography>
              </Avatar>
            </Tooltip>
          ))}
      </Stack>
    </Box>
  );
};

export default TicketParticipants;
