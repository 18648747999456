import {
  hangupCall,
  launchCall,
  parkCall,
  transferCall,
  unparkCall,
  attendedTransferCall,
  completeAttendedTransferCall,
  addAgentToConference,
  removeAgentFromConference
} from './domain/CallActions';

const CallManagerDomain = {
  launchCall,
  parkCall,
  unparkCall,
  hangupCall,
  transferCall,
  attendedTransferCall,
  completeAttendedTransferCall,
  addAgentToConference,
  removeAgentFromConference
};

export default CallManagerDomain;
