import DaServicesDomain from 'src/modules/Admin/modules/Operations/modules/DaServices/domain/DaServicesDomain';
import DaServices from 'src/modules/Admin/modules/Operations/modules/DaServices/views';
import UsersDomain from 'src/services/users/domain/UserDomain';
import UserManager from 'src/services/users/manager';
import { RTQueuesObject } from 'src/store/slices/rtusers/rtuserSlices';
import { Panel } from '../models/Dashboards';

export const getCoordinatorUsersIds = async (
  panel: Panel,
  rtUsers: string[]
) => {
  // If is selected all services and all campaigns
  if (panel.serviceId === null && panel.campaignId === null)
    return [...rtUsers];
  // If is selected one service and all campaign
  if (panel.serviceId !== null && panel.campaignId === null) {
    const service = await DaServicesDomain.retrieveDaServiceById(
      panel.serviceId
    );

    return [...rtUsers].filter((userId) => {
      return service.users.includes(userId);
    });
  }
  try {
    const campaign = await UsersDomain.getCampaignById(panel.campaignId);
    return [...rtUsers].filter((userId) => {
      return campaign.users.includes(userId);
    });
  } catch (error) {
    return [];
  }
};

export const getCoordinatorQueuesIds = async (
  //TODO: Change according Mario and Oscar confirmation
  panel: Panel,
  rtQueues: RTQueuesObject
) => {
  // If is selected all services and all campaigns
  if (panel.serviceId === null && panel.campaignId === null)
    return Object.keys(rtQueues);

  // If is selected one service and all campaign
  if (panel.serviceId !== null && panel.campaignId === null)
    return Object.keys(rtQueues);
  return Object.keys(rtQueues);
};
