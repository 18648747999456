import { useState } from 'react';
import useUpdate from 'src/hooks/useUpdateEffect';

export type StepsType = 'circular' | 'linear';

interface Params<T extends string> {
  steps: T[];
  /** The default initial step is the first one in steps */
  initialStep?: T;
  /** The default initial step index is 0 */
  initialStepIndex?: number;
  /** The default is 'circular' */
  type?: StepsType;
}

export const useSteps = <T extends string>(params: Params<T>) => {
  const { steps, initialStep, initialStepIndex, type = 'circular' } = params;
  const [currentStepIndex, setCurrentStepIndex] = useState(
    getInitialStepIndex()
  );
  const currentStep = steps?.[currentStepIndex];

  function getInitialStepIndex() {
    return (initialStep ? steps.indexOf(initialStep) : initialStepIndex) ?? 0;
  }
  function nextStep() {
    if (isLastStep()) {
      if (type === 'linear') return;
      setCurrentStepIndex(0);
    } else setCurrentStepIndex((prev) => prev + 1);
  }
  function previousStep() {
    if (isFirstStep()) {
      if (type === 'linear') return;
      setCurrentStepIndex(steps.length - 1);
    } else setCurrentStepIndex((prev) => prev - 1);
  }
  function resetStep() {
    const initialStepIndex = getInitialStepIndex();
    setCurrentStepIndex(initialStepIndex);
  }
  function isFirstStep() {
    return currentStepIndex === 0;
  }
  function isLastStep() {
    return currentStepIndex === steps.length - 1;
  }

  useUpdate(() => {
    const initialStepIndex = getInitialStepIndex();
    setCurrentStepIndex(initialStepIndex);
  }, [steps, initialStep, initialStepIndex]);

  return {
    currentStep,
    nextStep,
    previousStep,
    resetStep,
    isFirstStep,
    isLastStep
  };
};
