import { BASE_URL } from './base';

export const REPORTING_MS = `${BASE_URL}/reporting`;

// REPORTING ATTACHMENT CONTROLLER
const REPORTING_ATTACHMENT_CONTROLLER = `${REPORTING_MS}/reporting-attachment`;
export const REPORTING_ATTACHMENT = REPORTING_ATTACHMENT_CONTROLLER;
export const REPORTING_ATTACHMENT_DOWNLOAD = `${REPORTING_ATTACHMENT_CONTROLLER}/download`;

// REPORTING CALL SUMARY CONTROLLER
const REPORTING_CALL_SUMARY_CONTROLLER = `${REPORTING_MS}/reporting-call-summary`;
export const REPORTING_CALL_SUMARY = REPORTING_CALL_SUMARY_CONTROLLER;
export const REPORTING_CALL_SUMARY_ID = `${REPORTING_CALL_SUMARY_CONTROLLER}/{id}`;
export const REPORTING_CALL_SUMARY_FILTER = `${REPORTING_CALL_SUMARY_CONTROLLER}/filter`;
export const REPORTING_CALL_SUMARY_AGGREGATION = `${REPORTING_CALL_SUMARY_CONTROLLER}/aggregation`;
export const REPORTING_CALL_SUMARY_CALL_DETAILS = `${REPORTING_CALL_SUMARY_CONTROLLER}/callDetails`;

// REPORTING SESSION CONTROLLER
const REPORTING_SESSION_CONTROLLER = `${REPORTING_MS}/reporting-session`;
export const REPORTING_SESSION = REPORTING_SESSION_CONTROLLER;
export const REPORTING_SESSION_FILTER = `${REPORTING_SESSION_CONTROLLER}/filter`;
export const REPORTING_SESSION_WORK_DAY = `${REPORTING_SESSION_CONTROLLER}/workday`;

// REPORTING TICKET CONTROLLER
const REPORTING_TICKET_CONTROLLER = `${REPORTING_MS}/reporting-ticket`;
export const REPORTING_TICKET = REPORTING_TICKET_CONTROLLER;
export const REPORTING_TICKET_FILTER = `${REPORTING_TICKET_CONTROLLER}/filter`;

// REPORTING CONVERSATION CONTROLLER
const REPORTING_CONVERSATION_CONTROLLER = `${REPORTING_MS}/reporting-conversation`;
export const REPORTING_CONVERSATION = REPORTING_CONVERSATION_CONTROLLER;
export const REPORTING_CONVERSATION_FILTER = `${REPORTING_CONVERSATION_CONTROLLER}/filter`;

// REPORTING CONTACT CONTROLLER
const REPORTING_CONTACT_CONTROLLER = `${REPORTING_MS}/reporting-contact`;
export const REPORTING_CONTACT = REPORTING_CONTACT_CONTROLLER;
export const REPORTING_CONTACT_FILTER = `${REPORTING_CONTACT_CONTROLLER}/filter`;

// REPORTING CONTACT QUALIFICATION CONTROLLER
const REPORTING_CONTACT_QUALIFICATION_CONTROLLER = `${REPORTING_MS}/reporting-contact-qualification`;
export const REPORTING_CONTACT_QUALIFICATION =
  REPORTING_CONTACT_QUALIFICATION_CONTROLLER;
export const REPORTING_CONTACT_QUALIFICATION_FILTER = `${REPORTING_CONTACT_QUALIFICATION_CONTROLLER}/filter`;

// LOGS CONTROLLER
const REPORTING_LOGS_CONTROLLER = `${REPORTING_MS}/logs`;
export const REPORTING_LOGS = REPORTING_LOGS_CONTROLLER + '/frontend';

// BILLING CONTROLLER

const REPORTING_BILLING_CONTROLLER = `${REPORTING_MS}/billing`;
export const REPORTING_BILLING_BY_MONTH = `${REPORTING_BILLING_CONTROLLER}/byMonth`;
export const REPORTING_BILLING_BY_YEAR = `${REPORTING_BILLING_CONTROLLER}/byYear`;
