import { Avatar, Button, Tooltip, styled, useTheme } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

import { t } from 'i18next';
import { MouseEvent, useContext } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkCreateTicketPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

const ButtonAddAction = styled(Button)(
  ({ theme }) => `
        border: ${theme.colors.secondary.main} dashed 1px;
        color: ${theme.colors.secondary.main};
        transition: ${theme.transitions.create(['all'])};
        justify-content: center;
        align-items: center;
        display: flex;
    
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
    `
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[10]};
        color: ${theme.colors.secondary.main};
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
    `
);

interface Props {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const AddTicketButton = (props: Props) => {
  const { onClick } = props;
  const { hasAccess } = useContext(PermissionsContext);
  const isAllowedToCreateTicket = hasAccess(checkCreateTicketPermissions);
  if (!isAllowedToCreateTicket) return <></>;
  return (
    <Tooltip arrow title={t('Click to add a new ticket')}>
      <ButtonAddAction fullWidth onClick={onClick}>
        <AvatarAddWrapper>
          <AddTwoToneIcon fontSize="medium" />
        </AvatarAddWrapper>
      </ButtonAddAction>
    </Tooltip>
  );
};

export default AddTicketButton;
