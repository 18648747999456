import { Box, Button, Paper, Typography, styled } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { UploadedImage } from '../../../models/webChatStyle';

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '160px',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: '160px'
});

interface UploadBubbleProps {
  uploadedImages: UploadedImage[];
  setUploadedImages: Dispatch<SetStateAction<UploadedImage[]>>;
  setUpdatedBubble: Dispatch<SetStateAction<boolean>>;
  setNewBubbles: Dispatch<SetStateAction<File[]>>;
  newBubbles: File[];
}

const UploadBubble: React.FC<UploadBubbleProps> = ({
  uploadedImages,
  setUploadedImages,
  setUpdatedBubble,
  setNewBubbles,
  newBubbles
}) => {
  const onLoadAttachmentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    const f = [...uploadedImages];
    f.push({
      image: fileList[0],
      attachmentId: fileList[0].lastModified.toString(),
      mimeType: fileList[0].type
    });
    setUploadedImages(f);

    const b = [...newBubbles];
    b.unshift(fileList[0]);
    setNewBubbles(b);

    setUpdatedBubble(true);

    e.target.value = '';
  };

  return (
    <>
      <Paper elevation={1}>
        <Button
          component="label"
          role={undefined}
          tabIndex={-1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px',
            height: '160px',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: '2px',
            borderStyle: 'dashed',
            borderColor: 'rgba( 24, 112, 245, 0.5)',
            borderRadius: '10px',
            '& .MuiButton-startIcon': {
              margin: 0
            }
          }}
          startIcon={
            <Box sx={{ margin: '0' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  padding: '13px',
                  width: 120,
                  height: 120,
                  margin: 0
                }}
                boxShadow={2}
              >
                <Box
                  sx={{
                    backgroundColor: 'rgba(239, 239, 240, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                    borderRadius: '100%'
                  }}
                >
                  <FileUploadIcon color="primary" fontSize="large" />
                </Box>
              </Box>
            </Box>
          }
        >
          <Typography variant="subtitle1" fontSize="11px">
            {t('Upload bubble')}
          </Typography>
          <HiddenInput
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={onLoadAttachmentFile}
          />
        </Button>
      </Paper>
    </>
  );
};

export default UploadBubble;
