import { AxiosError } from 'axios';
import {
  Extension,
  ExtensionsRange
} from 'src/modules/Admin/modules/Calls/modules/Extensions/models/Extension';
import { PaginatedElements } from 'src/models/pagination';
import {
  ExtensionDto,
  ExtensionDtoRanges
} from 'src/modules/Admin/modules/Calls/modules/Extensions/models/ExtensionDto';
import { ExtensionForForm } from 'src/modules/Admin/modules/Calls/modules/Extensions/models/ExtensionForForm';
import {
  deleteExtension,
  ExtensionsRequestParams,
  getExtensionsByParams,
  getOccupiedExtensionsByCompanyId,
  postExtension,
  postExtensionsByRanges,
  putAssignExtensionsByRanges,
  putExtension
} from '../infrastructure/api/extensions';

/**
 *
 * @param params
 * @returns
 */
export const getExtensions = (
  params: ExtensionsRequestParams
): Promise<PaginatedElements<Extension>> => {
  return getExtensionsByParams(params);
};

/**
 *
 * @param extensionForForm
 * @param companyId
 * @returns
 */
export const createSingleExtensionFromForm = (
  extensionForForm: ExtensionForForm,
  companyId: string
): Promise<Extension> => {
  const extensionDto: ExtensionDto = {
    companyId: companyId,
    enabled: true,
    extension: extensionForForm.extension,
    external: true,
    groupIds: extensionForForm.groupIds
  };
  try {
    return postExtension(extensionDto);
  } catch (exception: unknown) {
    throw exception as AxiosError;
  }
};

export const createMultiplesExtensionsFromForm = (
  extensionForForm: ExtensionForForm,
  companyId: string,
  firstExtension: number,
  lastExtension: number
): Promise<Extension[]> => {
  const extensionDtoRanges: ExtensionDtoRanges = {
    companyId: companyId,
    enabled: true,
    external: true,
    groupIds: extensionForForm.groupIds
  };
  return postExtensionsByRanges(
    extensionDtoRanges,
    firstExtension,
    lastExtension
  );
};

/**
 *
 * @param extension
 * @returns
 */
export const updateExtensionFromForm = (
  extensionForForm: ExtensionForForm,
  oldExtension: Extension
): Promise<Extension> => {
  const extension: Extension = {
    id: oldExtension.id,
    companyId: oldExtension.companyId,
    extension: extensionForForm.extension,
    complexExtension: oldExtension.complexExtension,
    external: oldExtension.external,
    groupIds: extensionForForm.groupIds,
    inUse: oldExtension.inUse,
    enabled: oldExtension.enabled
  };
  return putExtension(extension);
};

/**
 *
 * @param extensionId
 * @returns
 */
export const removeExtension = (extensionId: string): Promise<boolean> => {
  return deleteExtension(extensionId);
};

export const removeMultipleExtensions = (
  extensionsIds: string[]
): Promise<boolean[]> => {
  return Promise.all(extensionsIds.map(removeExtension));
};

/**
 *
 * @param companyId
 * @returns
 */
export const getOccupiedExtensions = (
  companyId: string
): Promise<ExtensionsRange> => {
  return getOccupiedExtensionsByCompanyId(companyId);
};

/**
 *
 * @param extension
 * @returns
 */
export const enableExtension = (extension: Extension): Promise<Extension> => {
  extension.enabled = true;
  return putExtension(extension);
};

/**
 *
 * @param extension
 * @returns
 */
export const disableExtension = (extension: Extension): Promise<Extension> => {
  extension.enabled = false;
  return putExtension(extension);
};

/**
 *
 * @param extensions
 * @returns
 */
export const enableMultipleExtensions = (
  extensions: Extension[]
): Promise<Extension[]> => {
  return Promise.all(extensions.map(enableExtension));
};

/**
 *
 * @param extensions
 * @returns
 */
export const disableMultipleExtensions = (
  extensions: Extension[]
): Promise<Extension[]> => {
  return Promise.all(extensions.map(disableExtension));
};

/**
 *
 * @param companyId
 * @param groupId
 * @param firstExtension
 * @param lastExtension
 * @returns
 */
export const assignExtensionsByRanges = async (
  companyId: string,
  groupId: string,
  firstExtension: number,
  lastExtension: number
): Promise<boolean> => {
  return putAssignExtensionsByRanges(
    companyId,
    groupId,
    firstExtension,
    lastExtension
  );
};

export const assignExtensionToAnExtensionsGroup = async (
  extension: Extension,
  extensionsGroupId: string
): Promise<Extension> => {
  const assignedExtension: Extension = {
    ...extension,
    groupIds: extension.groupIds.concat(extensionsGroupId)
  };
  return putExtension(assignedExtension);
};

export const unassignExtensionToAnExtensionsGroup = async (
  extension: Extension,
  extensionsGroupId: string
): Promise<Extension> => {
  const assignedExtension: Extension = {
    ...extension,
    groupIds: extension.groupIds.filter(
      (groupId) => groupId !== extensionsGroupId
    )
  };
  return putExtension(assignedExtension);
};
