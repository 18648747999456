import { postIntentionModelApi } from '../infrastructure/postIntentionModel';
import { AIModelApi, AIModel } from '../models/AIModels';

/** CREATE INTENTION MODEL
 *
 * Create Intention Model in database
 *
 *
 * @returns INTENTION MODEL
 */
export const postIntentionModel = async (
  params: AIModelApi
): Promise<AIModel> => {
  const intentionModel = await postIntentionModelApi(params);
  return intentionModel;
};

/** COPY INTENTION MODEL
 *
 * Copy Intention Model in database
 *
 *
 * @returns INTENTION MODEL
 */
export const cloneIntentionModel = async (model: AIModel): Promise<AIModel> => {
  const newIntentionModel: AIModelApi = {
    name: `${model.name}_copy`,
    data: {
      Agendar: [],
      Eliminar: [],
      Modificar: []
    },
    description: `${model.description}_copy`,
    companyId: model.companyId
  };
  const intentionModel = await postIntentionModelApi(newIntentionModel);
  return intentionModel;
};
