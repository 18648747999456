import FullCalendar from '@fullcalendar/react';
import { Stack, useTheme } from '@mui/material';
import { useGetSchedulesCalendarQuery } from '../domain/getSchedulesCalendar';
import { useEffect, useRef, useState } from 'react';
import { ScheduleCalendar } from '../createScheduleCalendar';
import { MonthlyCalendar } from './components/monthlyCalendar';
import { DailyTimeline } from './components/dailyTimeline';
import { ScheduleCalendarFilter } from '../infrastructure/getScheduleCalendarsApi';
import { NEW_SCHEDULE_ID } from '../ScheduleCalendarForm';
import debounce from 'lodash/debounce';

interface Props {
  onDateClick?: (date: Date) => void;
  onEventDrop?: (schedule: ScheduleCalendar) => void;
  onEventClick?: (schedule: ScheduleCalendar) => void;
  schedulesFilter?: ScheduleCalendarFilter;
  selectedSchedule?: ScheduleCalendar;
  onNewEventButtonClick: (date: Date) => void;
  height?: string;
  disableUpdateTimestamp?: boolean;
  disableNewButton?: boolean;
}

const SchedulesCalendar = (props: Props) => {
  const {
    onEventDrop,
    onEventClick,
    schedulesFilter,
    selectedSchedule,
    onNewEventButtonClick,
    height,
    disableUpdateTimestamp,
    disableNewButton
  } = props;
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  const schedulesQuery = useGetSchedulesCalendarQuery(schedulesFilter);
  const schedulesQueyrData = schedulesQuery?.data ?? [];
  const schedules = [
    ...schedulesQueyrData.filter((s) => s.id !== selectedSchedule?.id),
    selectedSchedule
  ];

  const [size, setSize] = useState<'big' | 'small'>('big');
  const monthlyCalendarWidth = size === 'big' ? '60%' : '100%';

  const panelRef = useRef<HTMLDivElement>(null);
  const timeGridRef = useRef<FullCalendar>(null);
  const monthGridRef = useRef<FullCalendar>(null);

  useEffect(() => {
    const handleResize = debounce((entries) => {
      for (let entry of entries) {
        timeGridRef.current?.getApi().updateSize();
        monthGridRef.current?.getApi().updateSize();

        const width = entry.contentRect.width;
        const newSize = width < 500 ? 'small' : 'big';
        setSize(newSize);
      }
    }, 0); // Debounce with 300ms delay

    const observer = new ResizeObserver(handleResize);

    if (panelRef.current) {
      observer.observe(panelRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Stack width="100%" ref={panelRef} direction={'row'} spacing={1}>
      <Stack width={monthlyCalendarWidth}>
        <MonthlyCalendar
          height={height}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          calendarRef={monthGridRef}
          schedules={schedules}
          selectedSchedule={selectedSchedule}
          onDateClick={props.onDateClick}
          onEventDrop={onEventDrop}
          onEventClick={onEventClick}
          enableTimeGrid={size === 'small'}
          onNewEventButtonClick={onNewEventButtonClick}
        />
      </Stack>

      {size === 'big' && (
        <Stack width="40%" height={'100%'}>
          <DailyTimeline
            height={height}
            selectedDay={selectedDay}
            calendarRef={timeGridRef}
            onDateClick={(date) => {
              if (props.onDateClick) {
                props.onDateClick(date);
              }
            }}
            onEventDrop={onEventDrop}
            onEventClick={onEventClick}
            schedules={schedules}
            selectedSchedule={selectedSchedule}
            onNewEventButtonClick={onNewEventButtonClick}
            disableNewButton={disableNewButton}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default SchedulesCalendar;
