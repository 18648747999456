import {
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  Stack
} from '@mui/material';
import { StyleModifiers } from './StyleModifiers';
import UploadBubble from '../../../components/Bubbles/UploadBubble';
import { UploadedImage, WebChatStyle } from '../../../../models/webChatStyle';
import { FormikProps } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Bubble2 } from '../../../components/Bubbles/Bubble2';
import { Bubble3 } from '../../../components/Bubbles/Bubble3';
import { Bubble1 } from '../../../components/Bubbles/Bubble1';
import { Clear } from '@mui/icons-material';

interface BubbleProps {
  formikProps: FormikProps<WebChatStyle>;
  setUpdatedBubble: Dispatch<SetStateAction<boolean>>;
  uploadedImages: UploadedImage[];
  setUploadedImages: Dispatch<SetStateAction<UploadedImage[]>>;
  newBubbles: File[];
  setNewBubbles: Dispatch<SetStateAction<File[]>>;
}

export const Bubble: React.FC<BubbleProps> = ({
  formikProps,
  setUpdatedBubble,
  uploadedImages,
  setUploadedImages,
  newBubbles,
  setNewBubbles
}) => {
  const [fileURLs, setFileURLs] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const newFileURLs: { [key: string]: string } = {};
    uploadedImages.forEach((fileObject) => {
      const file = fileObject.image;
      newFileURLs[fileObject.attachmentId] = URL.createObjectURL(file);
    });

    setFileURLs(newFileURLs);
    // Clean up URLs when files change or component unmounts
    return () => {
      Object.values(newFileURLs).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [uploadedImages]);

  const deleteBubbleImage = (file: UploadedImage) => {
    formikProps.values.bubbleStyle.uploadedImages =
      formikProps.values.bubbleStyle.uploadedImages.filter(
        (file_id) => file_id.attachmentId !== file.attachmentId
      );

    const a = [...uploadedImages];
    setUploadedImages(a.filter((f) => f.attachmentId !== file.attachmentId));

    const aux = newBubbles;
    const news = aux.filter((f) => f !== file.image);
    setNewBubbles(news);
  };

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        height="calc(100vh - 375px)"
      >
        <Stack width="100%" height={{ xs: '50%', sm: '100%' }}>
          <Stack
            direction="column"
            overflow="auto"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid
              container
              spacing={2}
              paddingY="10px"
              justifyContent={'left'}
              width="100%"
              overflow="scroll"
            >
              <Grid item>
                <ListItemButton
                  sx={{
                    backgroundColor:
                      formikProps.values.bubbleStyle.bubbleImage ===
                      'bubble1-id'
                        ? '#DDDAE2'
                        : '#FFFFFF'
                  }}
                  onClick={() => {
                    formikProps.setFieldValue(
                      'bubbleStyle.bubbleImage',
                      'bubble1-id'
                    );
                  }}
                >
                  <Bubble1 formikProps={formikProps} />
                </ListItemButton>
              </Grid>
              <Grid item>
                <ListItemButton
                  sx={{
                    backgroundColor:
                      formikProps.values.bubbleStyle.bubbleImage ===
                      'bubble2-id'
                        ? '#DDDAE2'
                        : '#FFFFFF'
                  }}
                  onClick={() => {
                    formikProps.setFieldValue(
                      'bubbleStyle.bubbleImage',
                      'bubble2-id'
                    );
                  }}
                >
                  <Bubble2 formikProps={formikProps} />
                </ListItemButton>
              </Grid>
              <Grid item>
                <ListItemButton
                  sx={{
                    backgroundColor:
                      formikProps.values.bubbleStyle.bubbleImage ===
                      'bubble3-id'
                        ? '#DDDAE2'
                        : '#FFFFFF'
                  }}
                  onClick={() => {
                    formikProps.setFieldValue(
                      'bubbleStyle.bubbleImage',
                      'bubble3-id'
                    );
                  }}
                >
                  <Bubble3 formikProps={formikProps} />
                </ListItemButton>
              </Grid>

              {uploadedImages !== null &&
                uploadedImages.map((file, i) => {
                  return (
                    <Grid
                      item
                      width="210px"
                      height="194px"
                      key={file.attachmentId}
                    >
                      <ListItemButton
                        sx={{
                          width: '192px',
                          height: '176px',
                          justifyContent: 'center',
                          flexDirection: 'row-reverse',
                          zIndex: '1',
                          backgroundColor:
                            formikProps.values.bubbleStyle.bubbleImage ===
                            file.attachmentId
                              ? '#DDDAE2'
                              : '#FFFFFF'
                        }}
                        onClick={() => {
                          if (
                            formikProps.values.bubbleStyle.uploadedImages.find(
                              (image) =>
                                image.attachmentId === file.attachmentId
                            )
                          ) {
                            formikProps.setFieldValue(
                              'bubbleStyle.bubbleImage',
                              file.attachmentId
                            );
                          } else {
                            formikProps.setFieldValue(
                              'bubbleStyle.bubbleImage',
                              ''
                            );
                          }
                        }}
                      >
                        <IconButton
                          sx={{
                            zIndex: '2',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            margin: '4px',
                            backgroundColor: '#bdbdc9',
                            '&:hover': {
                              bgcolor: '#d73f3f'
                            }
                          }}
                          id={`webchat-style-icon-button-${file.attachmentId}`}
                          size="large"
                          color="primary"
                          onClick={() => {
                            deleteBubbleImage(file);
                          }}
                        >
                          <Clear fontSize="inherit" />
                        </IconButton>
                        {(file.mimeType === 'image/jpg' ||
                          file.mimeType === 'image/png' ||
                          file.mimeType === 'image/jpeg') && (
                          <img
                            src={fileURLs[file.attachmentId]}
                            style={{ zIndex: '1' }}
                          />
                        )}
                      </ListItemButton>
                    </Grid>
                  );
                })}
              <Grid item>
                <ListItem>
                  <UploadBubble
                    uploadedImages={uploadedImages}
                    setUploadedImages={setUploadedImages}
                    setUpdatedBubble={setUpdatedBubble}
                    newBubbles={newBubbles}
                    setNewBubbles={setNewBubbles}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Divider orientation="vertical"></Divider>

        <Stack
          direction="column"
          width="100%"
          height={{ xs: '50%', sm: '100%' }}
          px={1}
          py={1}
        >
          <StyleModifiers
            formikProps={formikProps}
            disabled={
              !(
                formikProps?.values.bubbleStyle.bubbleImage === 'bubble1-id' ||
                formikProps?.values.bubbleStyle.bubbleImage === 'bubble2-id' ||
                formikProps?.values.bubbleStyle.bubbleImage === 'bubble3-id'
              )
            }
          />
        </Stack>
      </Stack>
    </>
  );
};
