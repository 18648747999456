import axios from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import { SpyModesType } from 'src/models/call';
import { ASTERISK_MS, CALLS_SPY } from 'src/utils/apiRoutes/msAsterisk';

export const spyCall = (
  callId: string,
  extension: string,
  mode: SpyModesType,
  instance: string
) => {
  const spyCallPath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) + CALLS_SPY;

  const path = replaceURLParams(spyCallPath, { '{id}': callId });
  const params = {
    extension,
    mode
  };

  return axios
    .post(path, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
