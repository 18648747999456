import { IntentionModelFilters } from '../../models/AIModels';

export let aiModelsRoutesParams: IntentionModelFilters = {
  name: '',
  companyId: ''
};

export const menuModels = [
  {
    id: 1,
    title: 'Schedule',
    items: [
      { id: 2, text: 'Me gustaría pedir una cita' },
      { id: 3, text: 'Quiero solicitar una cita' }
    ]
  },
  {
    id: 4,
    title: 'Delete',
    items: []
  },
  {
    id: 5,
    title: 'Modify',
    items: [{ id: 6, text: 'Deseo cambiar mi cita' }]
  }
];

export const initialAiModel = {
  name: '',
  intentions: '',
  description: '',
  status: '',
  companyId: '',
  data: {}
};
