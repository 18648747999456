import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContactGroup } from '../../models/contacts';
import { ContactTabType } from '..';
import {
  ButtonNameContacts,
  ContactsTabs,
  PathNameContacts,
  routesAdminContacts
} from '../utils/constants';

export const useContacts = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [contactGroupToUpdate, setContactGroupToUpdate] = useState<
    ContactGroup | undefined
  >(undefined);

  const getPathName = (pathName: string) => {
    if (pathName === PathNameContacts.ADMIN_CONTACT_GROUPS)
      return ContactsTabs.CONTACT_GROUPS_TAB;
    if (pathName === PathNameContacts.ADMIN_CONTACTS)
      return ContactsTabs.CONTACTS_TAB;
    if (pathName === PathNameContacts.ADMIN_TAGS) return ContactsTabs.TAGS_TAB;
    if (pathName === PathNameContacts.ADMIN_CONTACT_FIELDS)
      return ContactsTabs.CONTACT_FIELDS_TAB;
    if (pathName === PathNameContacts.DASHBOARD_CONTACTS)
      return ContactsTabs.CONTACTS_TAB;
  };

  const lastPathName = pathname.split('/').pop();
  const [tab, setTab] = useState<ContactTabType>(getPathName(lastPathName));

  useEffect(() => {
    setTab(getPathName(lastPathName));
  }, [lastPathName]);

  function getButtonName() {
    if (tab === ContactsTabs.CONTACT_GROUPS_TAB)
      return ButtonNameContacts.CONTACT_GROUP_NAME;
    if (tab === ContactsTabs.CONTACTS_TAB)
      return ButtonNameContacts.CONTACT_NAME;
    if (tab === ContactsTabs.TAGS_TAB) return ButtonNameContacts.TAG_NAME;
    if (tab === ContactsTabs.CONTACT_FIELDS_TAB)
      return ButtonNameContacts.CONTACT_FIELD_NAME;
  }

  function handleAction(event) {
    setPopoverRef(event.target);
    setIsPopoverOpened(true);
  }

  const getRedirection = (tab: ContactTabType) => {
    if (tab === ContactsTabs.CONTACTS_TAB)
      return navigate(routesAdminContacts.ROUTE_ADMIN_CONTACTS, {
        replace: true
      });
    if (tab === ContactsTabs.CONTACT_GROUPS_TAB)
      return navigate(routesAdminContacts.ROUTE_ADMIN_CONTAC_GROUPS, {
        replace: true
      });
    if (tab === ContactsTabs.CONTACT_FIELDS_TAB)
      return navigate(routesAdminContacts.ROUTE_CONTAC_FIELDS, {
        replace: true
      });
    if (tab === ContactsTabs.TAGS_TAB)
      return navigate(routesAdminContacts.ROUTE_TAGS, { replace: true });
  };

  return {
    isPopoverOpened,
    setIsPopoverOpened,
    popoverRef,
    tab,
    setTab,
    contactGroupToUpdate,
    setContactGroupToUpdate,
    getButtonName,
    handleAction,
    getRedirection,
    pathname
  };
};
