import axios from 'axios';
import { USERS_MS } from 'src/utils/apiRoutes/msUsers';

const DA_SERVICES = process.env.REACT_APP_DA_SERVICES as string;

export const getDaServices: Function = () => {
  const path = USERS_MS + DA_SERVICES;

  return axios
    .get(path)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
};
