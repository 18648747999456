import { useEffect, useMemo, useState } from 'react';

import { QueryKey, useQuery } from '@tanstack/react-query';

import { useSearchParams } from 'react-router-dom';

import { DataStatus } from '../../../models/dataStatus';
import { DialTableFilter } from '../models/filters';
import { getFiltersFromUrlSearchParams } from '../utils/getFiltersFromUrlSearchParams';
import { getUniqueDialTableFilters } from '../utils/getUniqueDialTableFilters';
import { getUniqueFilters } from '../utils/getUniqueFilters';
import { getUrlSearchParamsFromDialTableFilters } from '../utils/getUrlSearchParamsFromDialTableFilters';
import { OnChangeFn } from '@tanstack/react-table';
import { DialTableQueryKeys } from '../models/queryKey';
import { getDialTableQueryKeys } from '../utils/getDialTableQueryKeys';

interface Params<T> {
  queryKey?: QueryKey | Partial<DialTableQueryKeys>;
  getAvailableFilters?: () => Promise<DialTableFilter<T>[]>;
  getInitialSelectedFilters?: (
    availableFilters?: DialTableFilter<T>[]
  ) => Promise<DialTableFilter<T>[]>;
  disableSetUrlSearchParams?: boolean;
  selectedFilters?: DialTableFilter<T>[];
  onSelectedFiltersChange?: OnChangeFn<DialTableFilter<T>[]>;
}

export const useDialTableSelectedFilters = <T extends Object>(
  params: Params<T>
) => {
  const {
    getAvailableFilters,
    getInitialSelectedFilters,
    disableSetUrlSearchParams,
    queryKey: queryKeyParams,
    selectedFilters: selectedFiltersParams = [],
    onSelectedFiltersChange
  } = params;

  const [isLoadingSelectedFilters, setIsLoadingSelectedFilters] = useState(
    DataStatus.LOADING
  );

  // selectedFilters includes URL filters, initial selected filters and required filters.
  // It is managed using useState and not useQuery so that its value can be easily modified.
  // If onSelectedFiltersChange is provided, it will be called when the selected filters changes and you will be expected to manage the state yourself.
  const [selectedFilters, setSelectedFilters] = onSelectedFiltersChange
    ? [selectedFiltersParams, onSelectedFiltersChange]
    : useState<DialTableFilter<T>[]>([]);
  // selectedFiltersQuery is intended to be used in the data query key and data query function to refetch the data by applying the new selected filters.
  // It should be set initially and when applying the selected filters.
  const [selectedFiltersQuery, setSelectedFiltersQuery] =
    useState<DialTableFilter<T>[]>(selectedFilters);

  /** If not passed, generated randomly. */
  const queryKeys = useMemo<DialTableQueryKeys>(() => {
    const keys = getDialTableQueryKeys(queryKeyParams);
    return keys;
  }, [queryKeyParams]);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const setUrlSearchParamsFromDialTableFilters = (
    selectedFilters: DialTableFilter<T>[]
  ) => {
    const urlSearchParams =
      getUrlSearchParamsFromDialTableFilters(selectedFilters);
    setUrlSearchParams(urlSearchParams);
  };

  // DATA AND FILTERS ///////////////////////////////////////////////////////
  const enabledAvailableFilters = !!getAvailableFilters;

  // 1. GET THE AVAILABLE FILTERS
  const availableFiltersQuery = useQuery({
    queryKey: [...queryKeys?.availableFilters, 'availableFilters'],
    queryFn: getAvailableFilters,
    enabled: enabledAvailableFilters,
    refetchOnWindowFocus: false
  });

  const enabledInitialFilters =
    !!getInitialSelectedFilters &&
    !!getAvailableFilters &&
    availableFiltersQuery.isSuccess;

  /** The unique available fiters */
  const availableFilters =
    availableFiltersQuery?.data?.length > 0
      ? getUniqueFilters(availableFiltersQuery?.data)
      : [];

  // 2. GET THE INITIAL FILTERS
  const initialFiltersQuery = useQuery({
    queryKey: [...queryKeys?.initialFilters, 'initialFilters'],
    queryFn: () => getInitialSelectedFilters?.(availableFiltersQuery?.data),
    enabled: enabledInitialFilters,
    refetchOnWindowFocus: false
  });

  // 3. GET THE SELECTED FILTERS
  useEffect(() => {
    if (
      (!getInitialSelectedFilters || initialFiltersQuery?.isSuccess) &&
      (!getAvailableFilters || availableFiltersQuery?.isSuccess)
    ) {
      const initialFilters = initialFiltersQuery?.data ?? [];
      const requiredFilters =
        availableFiltersQuery?.data?.filter((filter) => filter.required) ?? [];
      const urlFilters = disableSetUrlSearchParams
        ? []
        : getFiltersFromUrlSearchParams(
            urlSearchParams,
            availableFiltersQuery?.data
          );
      // The order of priority is: 1) urlFilters; 2) initialFilters; 3) requiredFilters
      const uniqueFilters = getUniqueDialTableFilters([
        ...urlFilters,
        ...initialFilters,
        ...requiredFilters
      ]);
      setSelectedFilters(uniqueFilters);
      setSelectedFiltersQuery(uniqueFilters);
      setIsLoadingSelectedFilters(DataStatus.SUCCESS);
    }
  }, [initialFiltersQuery?.isSuccess, availableFiltersQuery?.isSuccess]);

  return {
    availableFilters,
    isLoadingSelectedFilters,
    selectedFilters,
    setSelectedFilters,
    selectedFiltersQuery,
    setSelectedFiltersQuery,
    setUrlSearchParamsFromDialTableFilters
  };
};
