import {
  Button,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import {
  EmbeddedWebChat,
  WebChatAccount,
  buildEmbeddedWebChat
} from '../../../models/webChatAccount';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { CloseTwoTone } from '@mui/icons-material';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

const BASE = window.location.origin;

interface EmbeddedFormProps {
  open: boolean;
  setShow: (show: boolean) => void;
  webChatAccount: WebChatAccount;
}

const EmbeddedForm: React.FC<EmbeddedFormProps> = ({
  open,
  setShow,
  webChatAccount
}) => {
  const [id, setId] = useState<string>(webChatAccount?.id);
  const textFieldRef = useRef(null);

  const url = BASE + EmbeddedWebChat + buildEmbeddedWebChat;
  useEffect(() => {
    setId(webChatAccount?.id);
  }, [webChatAccount]);

  const copyText = () => {
    if (textFieldRef.current) {
      textFieldRef.current.select();
      navigator.clipboard.writeText(textFieldRef.current.value);
    }
  };

  return (
    <Dialog open={open}>
      <Stack>
        <CardHeader
          title={t('Embed account')}
          subheader={t('Use this dialog window to embed new webchat chat')}
          action={
            <IconButton
              sx={{
                m: 1
              }}
              size="small"
              color="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              <CloseTwoTone />
            </IconButton>
          }
        />
        <Divider />
        <Stack px={2} py={2}>
          <Paper elevation={3} sx={{ width: '100%', height: '100%' }}>
            <TextField
              fullWidth
              multiline
              inputRef={textFieldRef}
              variant="outlined"
              InputProps={{
                readOnly: true,
                style: { color: '#220da8', backgroundColor: '#edebf7' }
              }}
              defaultValue={`<script type="module" src="${url}" data-use-service-core defer></script>
<div class="${id}" id="dialapplet-web-chat"></div>`}
            />
          </Paper>
        </Stack>
        <Divider />
        <Stack
          px={1.5}
          py={1.5}
          width="100%"
          direction={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={copyText}
          >
            {t('Copiar')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default EmbeddedForm;
