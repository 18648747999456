import HomeIcon from '@mui/icons-material/Home';
import ReactDOMServer from 'react-dom/server';
import { Box } from '@mui/material';
import { t } from 'i18next'; //no possible use translation hook because that componet us render in charts

const CustomResetIcon = () => {
  const reset = (
    <Box title={t('Reset to base state')}>
      <HomeIcon />
    </Box>
  );

  // Convierte el componente de React a una cadena de texto HTML
  const resetHtml = ReactDOMServer.renderToStaticMarkup(reset);

  return resetHtml;
};

export default CustomResetIcon;
