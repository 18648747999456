import { Conversation } from 'src/models/conversations/conversations';
import { setConversation } from 'src/store/slices/socialmedia/socialmediaSlice';
import store from 'src/store/store';
import { sortMessages } from '../../domain/messages/utils';
import { setManyRTUserConversations } from './setConversationsStore';

/** ADD / UPDATE CONVERSATION TO STORE
 *
 * @param conversation Conversation to be added / updated
 */
export const setConversationStore = (conversation: Conversation) => {
  if (!conversation?.messages) conversation.messages = [];

  conversation = {
    ...conversation,
    messages: sortMessages([...conversation.messages])
  };
  /*  if (
    !conversation.hasOwnProperty('lastMessage') &&
    conversation.channel === 'Email'
  ) {
    const lastMessage = getStoredLastMessageEmail(false);
    conversation = {
      ...conversation,
      lastMessage
    };
  } */

  store.dispatch(setConversation(conversation));

  setManyRTUserConversations([conversation]);
};
