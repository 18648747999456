import { replaceURLParams } from 'src/services/utils';
import { BASE_MS, BASE_URL } from './constants';
import axios from 'axios';
import {
  TICKETS_ATTACHMENT_FIELD,
  TICKETS_ID,
  TICKETS_USER_ID
} from 'src/utils/apiRoutes/msMessaging';
const CONVERSATIONS_ID = process.env.REACT_APP_API_CONVERSATIONS_ID;
const TICKET_ID = process.env.REACT_APP_API_TICKET_ID;

export const deleteTicketApi = async (id: string): Promise<void> => {
  const path = replaceURLParams(TICKETS_ID, {
    '{id}': id
  });
  await axios.delete(path);
};

export const removeAgentFromTicketApi = async (
  ticketId: string,
  userId: string
): Promise<void> => {
  const path = replaceURLParams(TICKETS_USER_ID, {
    '{ticketId}': ticketId,
    '{userId}': userId
  });
  await axios.delete(path);
};

export const removeTicketFromConversationApi = async (
  ticketId: string,
  conversationId: string
): Promise<void> => {
  const path = replaceURLParams(
    BASE_URL + BASE_MS + CONVERSATIONS_ID + TICKET_ID,
    { '{id}': conversationId, '{ticketId}': ticketId }
  );
  await axios.delete(path);
};

export const deleteTicketAttachmentApi = async (params: {
  ticketId: string;
  fileId: string;
}): Promise<void> => {
  const { ticketId, fileId } = params;
  const path = replaceURLParams(TICKETS_ATTACHMENT_FIELD, {
    '{ticketId}': ticketId,
    '{fileId}': fileId
  });
  await axios.delete(path);
};
