import axios from 'axios';
import { USERS } from 'src/utils/apiRoutes/msUsers';

export const CreateUser: Function = (
  companyId: string,
  desc: string,
  email: string,
  enabled: boolean,
  fixedExtension: string,
  password: string,
  username: string
) => {
  const path = USERS;
  let params = {
    companyId,
    desc,
    email,
    enabled,
    fixedExtension,
    password,
    username
  };
  return axios
    .post(path, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
