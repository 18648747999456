import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Slide,
  styled,
  useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Dispatch, forwardRef, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import { Contact } from 'src/modules/Contacts/models/contacts';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ContactDataHistoryNoteInfo from './ContactDataHistoryNoteInfo';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  contact: Contact;
  setContact: Dispatch<SetStateAction<Contact>>;
  open: boolean;
  onClose: () => void;
}

const ContactInfoModal = (props: Props) => {
  const { contact, setContact, open, onClose } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  const title = t('Contact info');
  const subheader = t('Use this dialog window to see the contact info');

  return (
    contact && (
      <Dialog
        open={open}
        onClose={() => {
          setIsEditing(false);
          onClose();
        }}
        fullWidth={true}
        maxWidth="xl"
        TransitionComponent={Transition}
      >
        <Card sx={{ height: '90vh', p: 0, width: '100%' }}>
          <CardHeader
            title={title}
            subheader={subheader}
            action={
              <IconButtonWrapper
                sx={{
                  m: 1
                }}
                size="small"
                color="secondary"
                onClick={onClose}
              >
                <CloseTwoToneIcon />
              </IconButtonWrapper>
            }
          />
          <Divider />
          <CardContent
            sx={{
              padding: 1,
              height: `calc(100% - ${theme.header.height} - 10px)`,
              width: '100%'
            }}
          >
            <Scrollbar>
              <ContactDataHistoryNoteInfo
                contact={contact}
                setContact={setContact}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onClose={onClose}
              />
            </Scrollbar>
          </CardContent>
        </Card>
      </Dialog>
    )
  );
};

export default ContactInfoModal;
