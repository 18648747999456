import axios from 'axios';
import { TextContent } from 'src/models/conversations/ChatMessages/TextMessages';
import { INSTAGRAM_SEND_EXISTING_TEXT_MESSAGE } from 'src/utils/apiRoutes/msInstagram';

export interface SendInstagramExistingMessageTextApiParams {
  content: TextContent;
  conversationId: string;
  replyTo?: string;
  accountId: string;
}

export const sendInstagramExistingMessageTextApi = async (
  params: SendInstagramExistingMessageTextApiParams
) => {
  const { content, ...restParams } = params;
  await axios.post(INSTAGRAM_SEND_EXISTING_TEXT_MESSAGE, content, {
    params: restParams
  });
};
