import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { Conversation } from 'src/models/conversations/conversations';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import HelperPanelHeader from '../components/HelperPanelHeader';
import {
  ReportingTicket,
  Ticket
} from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import {
  getInitialSelectedFiltersCallback,
  onDeleteTicket,
  onSubmitTicket,
  getReportingTicketColumns,
  getDataCallback,
  initialVisibleColumns,
  getCustomFilterValueOptionsCallback,
  getAvailableFiltersCallback,
  initialSorting
} from 'src/modules/Admin/modules/Operations/modules/Tickets/views/utils/ticketTableUtils';
import Scrollbar from 'src/components/Scrollbar';
import TicketDataView from 'src/modules/Admin/modules/Operations/modules/Tickets/views/components/TicketDataView';
import { ArrowBackIosNew } from '@mui/icons-material';
import TicketTitle from 'src/modules/Admin/modules/Operations/modules/Tickets/views/components/TicketTitle';
import { TicketsDomain } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/TicketsDomain';
import AuthManager from 'src/services/authentication/manager';
import { HelperTab } from '../../HelperMenu/items';
import { DialTable, DialTableImperativeHandle } from 'src/components/DialTable';
import { getHiddenVisibilityStateFromColumns } from 'src/components/DialTable/utils/getHiddenVisibilityStateFromColumns';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { TicketDataViewSkeleton } from 'src/modules/Admin/modules/Operations/modules/Tickets/views/components/TicketDataViewSkeleton';
import { queryClient } from 'src/utils/queryClient';
import { checkReadTicketPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

interface Props {
  conversation: Conversation;
  showHelperTabButtons?: boolean;
}

const ContactInfoTicketsPanel = (props: Props) => {
  const { conversation, showHelperTabButtons } = props;
  const [isLoadingTicket, setIsLoadingTicket] = useState(false);
  const { hasAccess } = useContext(PermissionsContext);
  const hasTicketAccess = hasAccess(checkReadTicketPermissions);

  const companyId = AuthManager.getLoggedUserCompanyId();
  const getAvailableFilters = getAvailableFiltersCallback(companyId);

  const tableRef = useRef<DialTableImperativeHandle<ReportingTicket>>(null);

  const [selectedReportingTicket, setSelectedReportingTicket] =
    useState<ReportingTicket>(null);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>(null);

  const [isEditingOrAddingTicket, setIsEditingOrAddingTicket] =
    useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const theme = useTheme();

  const onClickReportingTicket = (clickedReportingTicket: ReportingTicket) => {
    setSelectedReportingTicket(clickedReportingTicket);
  };

  const onClose = (e: MouseEvent<HTMLButtonElement>) => {
    setSelectedReportingTicket(null);
    setIsEditingOrAddingTicket(false);
  };

  const customFilterValueOptions =
    getCustomFilterValueOptionsCallback(conversation);

  const getInitialSelectedFilters =
    getInitialSelectedFiltersCallback(conversation);

  const getInitialColumnVisibility = useCallback(
    (columns: ColumnDef<ReportingTicket>[]) => {
      const hiddenColumns = columns.filter(
        (column) =>
          !initialVisibleColumns.some(
            (visibleColumn) => visibleColumn.id === column.id
          )
      );
      const initialVisibilityState =
        getHiddenVisibilityStateFromColumns(hiddenColumns);
      return initialVisibilityState;
    },
    []
  );

  useEffect(() => {
    let isMounted = true;
    if (selectedReportingTicket) {
      setIsLoadingTicket(true);
      TicketsDomain.getTicketById(selectedReportingTicket?.id)
        .then((resp) => {
          if (isMounted) {
            setSelectedTicket(resp);
            setIsEditingOrAddingTicket(true);
          }
        })
        .finally(() => {
          setIsLoadingTicket(false);
        });
    } else {
      if (isMounted) {
        setSelectedTicket(null);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedReportingTicket]);

  const getColumnsVisibility = useCallback(getInitialColumnVisibility, []);
  const getColumns = useCallback(getReportingTicketColumns, []);

  return (
    <>
      <Helmet>
        <title>Helper Panel - Tickets</title>
      </Helmet>
      <HelperPanelHeader
        helperTab={HelperTab.TICKETS}
        showHelperTabButtons={showHelperTabButtons}
      />

      <Box
        p={1}
        height={`calc(100% - ${theme.header.height})`}
        sx={{ flex: 2 }}
      >
        <Scrollbar>
          {isLoadingTicket && <TicketDataViewSkeleton />}
          {!isEditingOrAddingTicket && (
            <>
              {/* <Box px={2} py={1}>
                <AddTicketButton
                  setIsOpenPopover={setIsEditingOrAddingTicket}
                />
              </Box> */}

              <DialTable<ReportingTicket>
                key={conversation?.id}
                ref={tableRef}
                getDataFn={getDataCallback(companyId)}
                getColumnsFn={getColumns}
                getInitialColumnVisibilityFn={getColumnsVisibility}
                getAvailableFilters={getAvailableFilters}
                getInitialSelectedFilters={getInitialSelectedFilters}
                customFilterValueOptions={customFilterValueOptions}
                onClickRow={
                  hasTicketAccess ? onClickReportingTicket : undefined
                }
                disableSetUrlSearchParams
                state={{ sorting }}
                onSortingChange={setSorting}
              />
            </>
          )}

          {isEditingOrAddingTicket && (
            <Box height={`calc(100% - 40px)`}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={0.5}
                px={1}
              >
                <TicketTitle ticket={selectedTicket} />
                <Button
                  id="ticket-panel-back-btn"
                  color="secondary"
                  variant="text"
                  size="small"
                  startIcon={<ArrowBackIosNew />}
                  onClick={onClose}
                >
                  {t('Back')}
                </Button>
              </Box>
              <TicketDataView
                onClose={onClose}
                ticket={selectedTicket}
                conversation={conversation}
                onSubmit={onSubmitTicket(selectedTicket)}
                onDelete={async (ticket) => {
                  setSelectedReportingTicket(null);
                  queryClient.invalidateQueries({
                    queryKey: ['reportingTicket']
                  });
                  await onDeleteTicket(ticket);
                }}
              />
            </Box>
          )}
        </Scrollbar>
      </Box>
    </>
  );
};

export default ContactInfoTicketsPanel;
