import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import { NewConversationSnackbarContent } from './NewConversationSnackbar';
import { Conversation } from 'src/models/conversations/conversations';

export const useNewConversationSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();

  const renderContent = (conversation: Conversation) => (
    <NewConversationSnackbarContent
      conversation={conversation}
      onClose={(key) => {
        closeSnackbar(key);
      }}
    />
  );

  const enqueueNewConversationSnackbar = (conversation: Conversation) => {
    enqueueSnackbar(null, {
      variant: 'success',
      key: conversation.id,
      persist: false,
      autoHideDuration: 4000,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      content: renderContent(conversation)
    });
  };
  return { enqueueNewConversationSnackbar };
};
