import { queryClient } from 'src/utils/queryClient';
import {
  getWebChatAccountsApi,
  WebChatAccountFilter
} from '../../infraestructure/webChatAccount/getWebChatAccountsApi';

export const getWebChatAccounts = async (params: WebChatAccountFilter) => {
  const accounts = await getWebChatAccountsApi(params);
  return accounts;
};

export const getWebChatAccountsQuery = (params: WebChatAccountFilter) => {
  return queryClient.fetchQuery({
    queryKey: ['webchat accounts', ...Object.values(params)],
    queryFn: () => getWebChatAccountsApi(params),
    staleTime: 1000 * 60 * 5
  });
};

export const getWebChatAccountQueryNotPaginated = (
  params: WebChatAccountFilter
) => {
  return queryClient.fetchQuery({
    queryKey: ['webchat accounts', ...Object.values(params)],
    queryFn: async () => {
      const response = await getWebChatAccountsApi(params);
      return response.elements;
    },
    staleTime: 1000 * 60 * 5
  });
};
