import {
  Contact,
  ContactsRequestParams
} from 'src/modules/Contacts/models/contacts';
import {
  useInfiniteQuery,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';
import { PaginatedElements } from 'src/models/pagination';
import {
  ContactsDomainParams,
  getContactById,
  getContacts
} from '../../domain/getContacts';

export const useGetContactsQuery = (
  params: ContactsDomainParams,
  enabled?: boolean
) => {
  return useQuery(
    ['contacts', ...Object.values(params ?? {})],
    () => getContacts(params),
    {
      enabled: enabled || true,
      staleTime: 60000,
      refetchOnWindowFocus: false
    }
  );
};

export function useInfiniteContactsQuery(
  params: ContactsDomainParams,
  disabled?: boolean
) {
  return useInfiniteQuery({
    queryKey: [
      'contacts',
      params.page,
      params.size,
      params.contactGroupId,
      params.fieldValue,
      params.fieldType,
      params.sortBy
    ],
    queryFn: ({ pageParam = 0 }) => {
      if (disabled) return;
      return getContacts({ ...params, page: pageParam });
    },
    getNextPageParam: (lastPage) =>
      lastPage.currentPage + 1 < lastPage.totalPages
        ? lastPage.currentPage + 1
        : undefined,

    getPreviousPageParam: (firstPage) =>
      firstPage.currentPage - 1 > 0 ? firstPage.currentPage - 1 : undefined,
    staleTime: 60000,
    enabled: false
  });
}

export const usePossibleContactsQuery = (
  params: ContactsDomainParams
): UseQueryResult<PaginatedElements<Contact>> => {
  return useQuery<PaginatedElements<Contact>, Error>(
    ['possible contacts', ...Object.values(params)],
    () => getContacts(params),
    {
      enabled: true,
      staleTime: 60000
    }
  );
};

export const useGetContactQuery = (params: {
  id: string;
  enabled: boolean;
}) => {
  const { id, enabled } = params;
  return useQuery(['contact', id], () => getContactById(id), {
    enabled: enabled || true,
    staleTime: 60000
  });
};
