import {
  ChannelType,
  Conversation,
  ConversationContact
} from 'src/models/conversations/conversations';
import {
  ConversationMessage,
  ChatMessage,
  EmailMessage
} from 'src/models/conversations/messages';

export const channelOrder: Record<ChannelType, number> = {
  Call: 0,
  Telegram: 1,
  Email: 2,
  WhatsApp: 3,
  WebChat: 4,
  Instagram: 5
};

export const sortMessages = (
  messages: ConversationMessage[]
): ConversationMessage[] => {
  return [...messages].sort(compareConversationMessages);
};

export const compareConversationMessages = (
  a: ConversationMessage,
  b: ConversationMessage
) => {
  const dateA = new Date(a.metadata.creationDate);
  const dateB = new Date(b.metadata.creationDate);

  return dateA.getTime() - dateB.getTime();
};

export const sortConversationsByCreationDate = (
  conversations: Conversation[]
): Conversation[] => {
  return conversations.sort(compareConversationsByCreationDate);
};

export const sortConversations = (
  conversations: Conversation[],
  byChannel?: boolean
): Conversation[] => {
  let conversationsSorted = [...conversations];
  conversationsSorted.sort((a: Conversation, b: Conversation) => {
    if (byChannel) {
      // COMPARE CHANNELS
      const channelComparison = compareConversationsByChannel(a, b);
      if (channelComparison !== 0) {
        return channelComparison;
      }
    }
    // COMPARE DATES OF LAST MESSAGE
    return sortConversationsByModificationDate(a, b);
  });
  return conversationsSorted;
};

export const compareConversationsByChannel = (
  a: Conversation,
  b: Conversation
) => {
  return channelOrder[a.channel] - channelOrder[b.channel];
};

export const compareConversationsByCreationDate = (
  a: Conversation,
  b: Conversation
) => {
  return (
    new Date(b.metadata.creationDate).getTime() -
    new Date(a.metadata.creationDate).getTime()
  );
};

export const sortConversationsByModificationDate = (
  a: Conversation,
  b: Conversation
) => {
  const aModificationDate = a.lastMessage
    ? new Date(a.lastMessage.metadata.modificationDate).getTime()
    : new Date(a?.metadata.modificationDate).getTime();
  const bModificationDate = b.lastMessage
    ? new Date(b.lastMessage.metadata.modificationDate).getTime()
    : new Date(b?.metadata.modificationDate).getTime();

  return bModificationDate - aModificationDate;
};

export const compareConversationsByLastMessageCreationDate = (
  a: Conversation,
  b: Conversation
) => {
  if (a.lastMessage && b.lastMessage) {
    return compareConversationMessagesByCreationDate(
      a.lastMessage,
      b.lastMessage
    );
  }
  if (!a.lastMessage && !b.lastMessage) {
    return compareConversationsByCreationDate(a, b);
  } else if (!b.lastMessage) {
    return 1;
  } else if (!a.lastMessage) {
    return -1;
  }
};

/**It is expected to return a negative value if the creationDate of the first MessageData is greater than the creationDate of the second MessageData, zero if they're equal, and a positive value otherwise */
export const compareConversationMessagesByCreationDate = (
  a: ConversationMessage,
  b: ConversationMessage
) => {
  return (
    new Date(b?.metadata.creationDate).getTime() -
    new Date(a?.metadata.creationDate).getTime()
  );
};

export const compareConversationsByModificationDate = (
  a: Conversation,
  b: Conversation
) => {
  return (
    new Date(b.metadata.modificationDate).getTime() -
    new Date(a.metadata.modificationDate).getTime()
  );
};

export const compareConversationsByLastMessageModificationDate = (
  a: Conversation,
  b: Conversation
) => {
  if (a.lastMessage && b.lastMessage) {
    return (
      new Date(b.lastMessage.metadata.modificationDate).getTime() -
      new Date(a.lastMessage.metadata.modificationDate).getTime()
    );
  } else return compareConversationsByModificationDate(a, b);
};

export const getLatestMessageFromConversation = (conv: Conversation) => {
  return conv.messages?.reduce(
    (latestMessage: ConversationMessage, msg: ConversationMessage) => {
      if (!latestMessage) return msg;

      const dateA = new Date(latestMessage.metadata.creationDate);
      const dateB = new Date(msg.metadata.creationDate);
      if (dateA < dateB) {
        return msg;
      }
      return latestMessage;
    },
    undefined
  );
};

export const emailsFrom = (message: EmailMessage, replyOne: boolean) => {
  let data = [];
  if (!replyOne) {
    if (message.to.length === 0) {
      data.push(message.from);
    } else {
      message.to.map((email) => data.push(email));
      data.push(message.from);
    }
  } else {
    data.push(message.from);
  }
  return data;
};
/**If message is equals to undefined or null, it returns the first involved contact of the conversation. Otherwise, it returns the involved contact from the message passed*/
export const getMessageInvolvedContact = (
  conversation: Conversation,
  message?: ChatMessage
): ConversationContact => {
  if (!message) return conversation.involvedContacts[0];
  const involvedContact = conversation.involvedContacts.find((involved) => {

    if (conversation.channel === ChannelType.WHATSAPP)
      return involved.socialmediaAgent.phoneNumber === message.senderId;

    else if (conversation.channel === ChannelType.INSTAGRAM){
      return involved.socialmediaAgent.instagramId === message.senderId

    }
    else {
      return involved.socialmediaAgent.chatId === message.senderId;
    }
  });

  return involvedContact;
};
