import { PaginatedElements } from 'src/models/pagination';
import { ReportingCallSummary } from '../../models/ReportingCallSummary';
import { Filter } from 'src/components/TableReporting/models/filtersRequest';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import axios, { AxiosResponse } from 'axios';
import AggregationRequest from 'src/components/TableReporting/models/AggregationRequest';
import { ReportingAttachment } from '../../../Downloads/models/reportingAttachment';
import { ReportingCallQuerySelect } from '../../domain/getReportingCallSummary';
import { AvailableFilter } from 'src/components/DialTable/models/filters';
import { replaceURLParams } from 'src/services/utils';
import {
  REPORTING_CALL_SUMARY,
  REPORTING_CALL_SUMARY_AGGREGATION,
  REPORTING_CALL_SUMARY_FILTER,
  REPORTING_CALL_SUMARY_ID
} from 'src/utils/apiRoutes/msReporting';

export const getReportingCallSummaryByIdApi = async (callId: string) => {
  const url = replaceURLParams(REPORTING_CALL_SUMARY_ID, { '{id}': callId });
  const response = await axios.get(url);
  return response.data as ReportingCallSummary;
};

export interface ReportQuery {
  filters: Filter[];
  pageNumber: number;
  pageSize: number;
  select: ReportingCallQuerySelect[];
  sort: {
    sort: keyof ReportingCallSummary;
    direction: DirectionType;
  };
}

/**
 * Returns a reporting attachment if download param equals to true.
 * Otherwise, returns paginated elements of a reporting call summary
 * @param companyId
 * @param reportQuery
 * @param name
 * @param timeZone
 * @param download
 * @returns reportingAttachment | paginatedReportingCallSummary
 */
export const getReportingCallSummaryApi = async (
  companyId: string,
  reportQuery: ReportQuery,
  name?: string,
  /** Example: Europe/Madrid */
  timeZone?: string,
  download: boolean = false
) => {
  const params = {
    timeZone,
    companyId,
    name,
    count: true,
    download
  };

  const resp = await axios.post<
    PaginatedElements<ReportingCallSummary> | ReportingAttachment
  >(REPORTING_CALL_SUMARY, reportQuery, { params });
  return resp.data;
};

export const downloadReportingCallSummaryAgregation = async (
  aggregationRequest: AggregationRequest,
  name: string,
  companyId: string
) => {
  const response = await axios.post<ReportingAttachment>(
    REPORTING_CALL_SUMARY_AGGREGATION,
    {
      filters: aggregationRequest.filters,
      pageNumber: 0,
      pageSize: -1,
      groupByFields: aggregationRequest.groupByFields,
      groupOperations: aggregationRequest.groupOperations,
      beforeSort: {
        sort: aggregationRequest.sort.sort,
        direction: 'DESC'
      }
    },
    {
      params: {
        download: true,
        name,
        companyId,
        count: false,
        merged: aggregationRequest.merged
      }
    }
  );

  return response.data;
};

export const getReportingCallSummaryFields = async (): Promise<
  AvailableFilter<ReportingCallSummary>[]
> => {
  const response: AxiosResponse<AvailableFilter<ReportingCallSummary>[]> =
    await axios.get(REPORTING_CALL_SUMARY_FILTER);

  return response.data;
};
