import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { queryClient } from 'src/utils/queryClient';
import daServiceManager from '../../../../../../../../services/daServices/daServiceManager';
import DaServicesDomain from '../../domain/DaServicesDomain';
import { CampaignsParams } from '../../infrastructure/campaigns/getCampaigns';
import { DaServicesRequestParams } from '../../infrastructure/DaServices';

interface DaServicesRequestParamsQuery extends DaServicesRequestParams {
  enabled?: boolean;
}

const SERVICES_QUERY_KEY = 'getDaServices';

export function invalidateServicesQuery() {
  queryClient.invalidateQueries({
    queryKey: [SERVICES_QUERY_KEY]
  });
}

export const useGetAllDaServicesDataQuery = (
  params?: DaServicesRequestParamsQuery
) => {
  return useQuery(
    [SERVICES_QUERY_KEY],
    () => DaServicesDomain.retrieveAllDaServices(params),
    { enabled: true, staleTime: 60000 }
  );
};
export const getServicesQuery = (params?: DaServicesRequestParamsQuery) => {
  return queryClient.fetchQuery([SERVICES_QUERY_KEY, params], {
    queryFn: () => DaServicesDomain.retrieveAllDaServices(params)
  });
};

export const getDaServiceByIdQuery = (
  daServiceId: string
): Promise<DaService> => {
  return queryClient.fetchQuery(['service by id', daServiceId], {
    queryFn: () => daServiceManager.getDaServiceById(daServiceId),
    staleTime: 5000
  });
};

export const getCampaignsQuery = (
  campaignsParams: CampaignsParams
): UseQueryResult<Campaign[]> => {
  return useQuery(
    ['campaigns'],
    () => DaServicesDomain.getAllCampaigns(campaignsParams),
    { enabled: true, staleTime: 0 }
  );
};

export const getCampaignByIdQuery = (
  campaignId: string
): UseQueryResult<Campaign> => {
  return useQuery<Campaign, Error>(
    ['campaign', campaignId],
    () => DaServicesDomain.getCampaignById(campaignId),
    { enabled: true, staleTime: 0 }
  );
};
