import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  detectDelimiterFromColumnValue,
  getCSVHeadersAndDelimiter
} from './LinkFieldsWithCSV/utils/getCSVHeaders';
import {
  Autocomplete,
  Box,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { UploadCsvParameters } from '../models/models';
import { ContactTag } from 'src/modules/Contacts/models/contacts';
import { getCompanyContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import AddIcon from '@mui/icons-material/Add';
import { CreateTagPopoverForm } from '../../../tags/CreateTagPopoverForm';
interface Props {
  uploadCsvParameters: UploadCsvParameters;
  setUploadCsvParameters: Dispatch<SetStateAction<UploadCsvParameters>>;
  mode: 'contacts' | 'dialerbot';
}

export const LinkTagsWithCSV = ({
  uploadCsvParameters,
  setUploadCsvParameters,
  mode
}: Props) => {
  const { file, tagsColumn, tagsDelimiter } = uploadCsvParameters;

  const [csvHeaders, setCsvHeaders] = useState([]);
  const [addTagsToAllContacts, setAddTagsToAllContacts] = useState<boolean>(
    uploadCsvParameters.addTagToAllContacts
  );
  const [contactTags, setContactTags] = useState<ContactTag[]>([]);
  const user = getConnectedRTUser();
  const [openNewTagModal, setOpenNewTagModal] = useState(false);
  useEffect(() => {
    const contactGroupId = uploadCsvParameters.contactGroupId;
    getCompanyContactTagsQuery({
      contactGroupId,
      companyId: user.companyId
    }).then((resp) => {
      if (resp.elements.length > 0) setContactTags(resp.elements);
    });
  }, []);

  useEffect(() => {
    if (!tagsColumn) return;
    detectDelimiterFromColumnValue(
      file,
      uploadCsvParameters.csvDelimiter,
      tagsColumn
    ).then((tagsDelimiter) => {
      setUploadCsvParameters({
        ...uploadCsvParameters,
        tagsDelimiter: tagsDelimiter
      });
    });
  }, [tagsColumn]);

  useEffect(() => {
    getCSVHeadersAndDelimiter(file, uploadCsvParameters.csvDelimiter).then(
      (response) => {
        setCsvHeaders(response.headers);
      }
    );
  }, [file]);

  return (
    <Stack py={1} spacing={2}>
      {mode === 'contacts' && (
        <>
          <TextField
            value={tagsColumn}
            label={t('Tags column')}
            select
            fullWidth
            onChange={(v) => {
              setUploadCsvParameters({
                ...uploadCsvParameters,
                tagsColumn: v.target.value
              });
            }}
          >
            {csvHeaders.map((header, index) => {
              return (
                <MenuItem key={index} value={header}>
                  {header}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            key={tagsDelimiter}
            value={tagsDelimiter}
            label={t('Delimiter character')}
            fullWidth
            onChange={(v) => {
              setUploadCsvParameters({
                ...uploadCsvParameters,
                tagsDelimiter: v.target.value
              });
            }}
          ></TextField>
          <Stack direction={'row'} alignItems={'center'}>
            <Switch
              checked={uploadCsvParameters.createTagWhenNotFound}
              onChange={(e) => {
                setUploadCsvParameters({
                  ...uploadCsvParameters,
                  createTagWhenNotFound: e.target.checked
                });
              }}
            />
            <Typography>{t('Create tag when not found')}</Typography>
          </Stack>
        </>
      )}

      {contactTags && (
        <Box display="flex" flexDirection="row">
          <Switch
            edge="end"
            name="addTagToAllContacts"
            checked={uploadCsvParameters.addTagToAllContacts}
            onChange={(e) => {
              setAddTagsToAllContacts(e.target.checked);
              setUploadCsvParameters({
                ...uploadCsvParameters,
                listTags: [],
                addTagToAllContacts: e.target.checked
              });
            }}
            id={`switch-tagToAllContacts`}
          />
          <Typography sx={{ marginTop: 0.5 }}>
            {' '}
            {t('Add tags to all contacts')}
          </Typography>
        </Box>
      )}

      {contactTags && addTagsToAllContacts && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Autocomplete
            multiple
            options={contactTags}
            getOptionLabel={(option) => option.name} // Mostrar el nombre del tag
            isOptionEqualToValue={(option, value) => option.id === value.id} // Comparar correctamente por ID
            value={contactTags.filter((ct) =>
              uploadCsvParameters.listTags.includes(ct.id)
            )}
            onChange={(_, values) => {
              setUploadCsvParameters({
                ...uploadCsvParameters,
                listTags: values.map((value) => value.id)
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={'listTags'}
                variant="outlined"
                placeholder={t('Filter by tags')}
              />
            )}
            className="autocompleteInput"
          />
          <IconButton
            id="searchButton"
            color="success"
            key="searchButton"
            onClick={() => setOpenNewTagModal(true)}
            title="Add group operation"
          >
            <AddIcon sx={{ mr: 0.7 }} />
            <Typography sx={{ mr: 0.3 }}>{t('Add tag')}</Typography>
          </IconButton>
        </Box>
      )}

      {mode === 'contacts' && (
        <Stack direction={'row'} alignItems={'center'}>
          <Switch
            checked={uploadCsvParameters.replaceTags}
            onChange={(e) => {
              setUploadCsvParameters({
                ...uploadCsvParameters,
                replaceTags: e.target.checked
              });
            }}
          />
          <Typography>{t('Replace tags')}</Typography>
        </Stack>
      )}

      <Popover
        open={openNewTagModal}
        onClose={() => setOpenNewTagModal(false)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        <CreateTagPopoverForm
          defaultContactGroupId={uploadCsvParameters.contactGroupId}
          onClose={() => setOpenNewTagModal(false)}
          onData={(resp) => {
            setContactTags([resp, ...contactTags]);
            setUploadCsvParameters((prev) => ({
              ...prev,
              listTags: [...prev.listTags, resp.id]
            }));
          }}
          disableContactGroupSelector={true}
        />
      </Popover>
    </Stack>
  );
};
