import { useEffect, useState } from 'react';
import {
  getEmailTextWithParsedBase64InlineAttachments,
  getEmailTextWithParsedInlineAttachments
} from '../utils/parseHtmlAttachments';
import {
  Base64Attachment,
  EmailMessage
} from 'src/models/conversations/messages';
import { logger } from 'src/utils/logger';

type UseEmailParserParams = {
  message: EmailMessage | string;
  base64files?: Base64Attachment[];
};

type UseEmailParserReturn = {
  htmlMessage: string;
  loading: boolean;
};

function useEmailParser({
  message,
  base64files
}: UseEmailParserParams): UseEmailParserReturn {
  const [htmlMessage, setHtmlMessage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const parseEmail = async () => {
      setLoading(true);

      try {
        let parsedHtml: string;

        if (typeof message === 'string' && base64files) {
          parsedHtml = await getEmailTextWithParsedBase64InlineAttachments(
            message,
            base64files
          );
        } else if (typeof message !== 'string') {
          parsedHtml = await getEmailTextWithParsedInlineAttachments(message);
        } else {
          throw new Error('Invalid message type');
        }

        if (isMounted) {
          setHtmlMessage(parsedHtml);
        }
      } catch (err) {
        logger.error(err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    parseEmail();

    return () => {
      isMounted = false;
    };
  }, [message, base64files]);

  return { htmlMessage, loading };
}

export default useEmailParser;
