import { Avatar, Box, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import UsersDomain from 'src/services/users/domain/UserDomain';
import { TicketComment } from '../../../../models/tickets';

interface Props {
  author?: string;
  ticketComment: TicketComment;
}
const TicketCommentItem = ({ ticketComment, author }: Props) => {
  const { comment, metadata } = ticketComment;
  const [user, setUser] = useState(author);
  const dateText = formatDistanceToNow(new Date(metadata.creationDate), {
    addSuffix: true
  });
  const authorInitial = user?.charAt(0);

  useEffect(() => {
    if (!author) {
      UsersDomain.getUserById(ticketComment?.userId).then((resp) => {
        setUser(resp.username);
      });
    }
  }, []);

  return (
    <Box display="flex" py={1.25} gap={2}>
      <Box>
        <Avatar variant="circular" alt={author}>
          <Typography variant="h3" color="inherit" fontWeight="regular">
            {authorInitial}
          </Typography>
        </Avatar>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          <b>{author}</b> {dateText}
        </Typography>
        <Typography>{comment}</Typography>
      </Box>
    </Box>
  );
};

export default TicketCommentItem;
