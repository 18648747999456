import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { BlinkCall } from './BlinkCall';
import LanguageSelector from 'src/components/LanguageSelector';
import { UserStatusTypes } from 'src/models/users';

interface AgentProps {
  color: string;
}

export const AgentBox: React.FC<AgentProps> = ({ color }) => {
  const user = getConnectedRTUser();
  const { t }: { t: any } = useTranslation();

  if (!user) return <></>;
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            backgroundColor: color,
            color: 'white',
            borderBottomRightRadius: '10px'
          }}
          p={1}
          width="min-content"
        >
          {t('Agent')}
        </Box>

        <LanguageSelector />
      </Stack>
      <Stack
        maxHeight={39}
        justifyContent={'center'}
        alignItems="center"
        marginRight={'5px'}
      >
        {user?.currentStatus?.name === UserStatusTypes.IN_CALL && <BlinkCall />}
      </Stack>
    </>
  );
};
