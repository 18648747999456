import { queryClient } from 'src/utils/queryClient';
import { createTagApi } from '../infraestructure/api/createTagApi';
import { ContactTag } from '../models/contacts';
import { invalidateContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';

export const createTag = async (
  companyId: string,
  name: string,
  contactGroupId: string
): Promise<ContactTag> => {
  const response = await createTagApi(companyId, name, contactGroupId);
  invalidateContactTagsQuery();
  return response;
};
