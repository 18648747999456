import { Box, Typography, Container, styled } from '@mui/material';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslation } from 'react-i18next';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    te
`
);

interface Props {
  title?: string;
  description?: string;
}

export const DialTableFallback = (props: Props) => {
  const {
    title = 'There was an error, please try again later',
    description = 'The server was not able to complete your request'
  } = props;
  const { t } = useTranslation();

  function handleClick() {
    window.location.reload();
  }

  return (
    <MainContent>
      <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center' }}>
        <Box textAlign="center">
          <img alt="500" height={260} src="/static/images/status/500.svg" />
          <Typography
            variant="h2"
            sx={{
              my: 2
            }}
          >
            {t(title)}
          </Typography>
          <Typography
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
            sx={{
              mb: 4
            }}
          >
            {t(description)}
          </Typography>
          <LoadingButton
            onClick={handleClick}
            variant="outlined"
            color="primary"
            startIcon={<RefreshTwoToneIcon />}
          >
            {t('Refresh view')}
          </LoadingButton>
        </Box>
      </Container>
    </MainContent>
  );
};
