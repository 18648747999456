import {
  ChannelType,
  Conversation,
  ConversationStatus
} from 'src/models/conversations/conversations';
import { RTUser } from 'src/models/rtUser';
import { getRTcalls } from 'src/services/rtcalls/domain/getRTCalls';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { DataFilter as CurrentDataFilter } from 'src/services/shared/domain/filters';
import store, { useSelector } from 'src/store/store';
import { queryClient } from 'src/utils/queryClient';
import {
  getConversationsApi,
  getConversationsByUserApi
} from '../infrastructure/getConversationsApi';
import {
  getConversationsLoadingStatusStore,
  getConversationsStore
} from '../../socialmedia/infraestructure/store/getConversationsStore';
import {
  getChannelsArrayFromSelectedChannelsFilter,
  getStatussArrayFromShowClosedConversationsFilter,
  filterConversations,
  filterFinishedCallConversations
} from '../../socialmedia/domain/conversation/utils';

export interface GetConversationsFilter extends CurrentDataFilter {
  id?: string;
  rtUsers?: RTUser[];
  status?: ConversationStatus[];
  channel?: ChannelType[];
  callId?: string;
  finishedCalls?: boolean;
}

export const useConversationSelector = (conversationId: string) => {
  const conversations = useSelector((state) => {
    if (!conversationId) return null;
    return state.socialmedia.conversations[conversationId];
  });

  return conversations;
};

export const getConversationsLoadingStatus = (shouldUseHook: boolean) => {
  return getConversationsLoadingStatusStore(shouldUseHook);
};

/** FILTER CONVERSATIONS
 *
 * @param filter Parameters to filter the currently stored conversations
 * @returns Array of conversations after applying the specified filters
 */
export const getStoredConversations = (
  filter: GetConversationsFilter
): Conversation[] => {
  const conversations = getConversationsStore(filter.shouldUseHook);

  return filterConversations(conversations, filter);
};

export const getStoredFilteredConversations = (
  shouldUseHook: boolean,
  assigned?: boolean
) => {
  const storeState = store.getState();
  const loggedRTUser = getConnectedRTUser(false);
  const rtCalls = getRTcalls({ shouldUseHook: false });

  const { selectedChannels, showClosedConversations, showFinishedCalls } =
    shouldUseHook
      ? useSelector((state) => state.socialmedia.conversationFilters)
      : storeState.socialmedia.conversationFilters;

  const selectedChannelsArray =
    getChannelsArrayFromSelectedChannelsFilter(selectedChannels);

  const status = getStatussArrayFromShowClosedConversationsFilter(
    showClosedConversations
  );

  const filter: GetConversationsFilter = {
    shouldUseHook: shouldUseHook,
    channel: selectedChannelsArray,
    status: status,
    rtUsers: assigned ? [loggedRTUser] : undefined
  };

  const conversations = getStoredConversations(filter);

  if (showFinishedCalls) {
    return conversations;
  } else {
    // Filtered conversations deleting finished conversations
    return filterFinishedCallConversations(conversations, rtCalls);
  }
};

export const isConversationInFilteredConversations = (
  conversation: Conversation
): boolean => {
  if (!conversation) return false;

  const filteredConversations = getStoredFilteredConversations(false);
  return filteredConversations.some((conv) => conv.id === conversation.id);
};

export const getConversationsByUserQuery = (filters: {
  assignedUserIds: string[];
  channel?: ChannelType;
  status?: ConversationStatus;
  userLimit?: number;
}) => {
  const { assignedUserIds, channel, status, userLimit } = filters;
  return queryClient.fetchQuery(
    ['conversationsByUser', channel, assignedUserIds],
    {
      queryFn: () =>
        getConversationsByUserApi({
          assignedUserIds,
          channel,
          status,
          userLimit
        }),
      staleTime: 1000
    }
  );
};

export const getConversations = async (params: {
  assignedUserId?: string;
  channel?: ChannelType[];
  companyId?: string;
  contactId?: string;
  page?: number;
  phoneNumber?: string;
  size?: number;
  status?: ConversationStatus[];
  ticketId?: string;
  withMessages?: boolean;
}) => {
  const conversations = await getConversationsApi(params);
  return conversations;
};
