import axios from 'axios';
import { BASE_URL, BASE_MS } from '../constants';
import { queryClient } from 'src/utils/queryClient';
import { TicketField } from '../../models/tickets';
import { TICKET_FIELDS } from 'src/utils/apiRoutes/msMessaging';

export const createTicketFieldApi = async (
  ticketField: TicketField
): Promise<TicketField> => {
  const resp = await axios.post(TICKET_FIELDS, ticketField);

  queryClient.invalidateQueries({
    queryKey: ['ticketFields']
  });

  return resp.data;
};
