import { DialTableFilter, Filter } from '../models/filters';
import { getValidDialTableFilterValues } from './getValidDialTableFilterValues';

export const getFiltersFromDialTableFilters = <T>(
  dialTableFilters: DialTableFilter<T>[]
) => {
  const filters: Filter[] = dialTableFilters.map((tableFilter) => {
    const validValues = getValidDialTableFilterValues(tableFilter.values);
    return {
      field: tableFilter.fieldName as string,
      dataType: tableFilter.fieldDataType,
      operator: tableFilter.operator,
      values: validValues
    };
  });
  return filters;
};
