import { BASE_URL } from './base';

const INSTAGRAM_MS = `${BASE_URL}/instagram`;

// Auth controller
const AUTH_CONTROLLER = `${INSTAGRAM_MS}/authentication`;
export const INSTAGRAM_AUTH_LOGIN = `${AUTH_CONTROLLER}/login`;
export const INSTAGRAM_AUTH_OAUTH = `${AUTH_CONTROLLER}/oauth`;
export const INSTAGRAM_AUTH_OAUTH_FINISH = `${AUTH_CONTROLLER}/oauth_finish`;

// Account controller
const ACCOUNT_CONTROLLER = `${INSTAGRAM_MS}/account`;
export const INSTAGRAM_ACCOUNT = `${ACCOUNT_CONTROLLER}`;
export const INSTAGRAM_ACCOUNT_ID = `${ACCOUNT_CONTROLLER}/{id}`;
export const INSTAGRAM_ACCOUNT_CHECK_ID = `${ACCOUNT_CONTROLLER}/check/{id}`;

// Message controller
const MESSAGE_CONTROLLER = `${INSTAGRAM_MS}/message`;
export const INSTAGRAM_SEND_MESSAGE = `${MESSAGE_CONTROLLER}`;
export const INSTAGRAM_SEND_NEW_TEXT_MESSAGE = `${MESSAGE_CONTROLLER}/new/text`;
export const INSTAGRAM_SEND_NEW_FILE_MESSAGE =  `${MESSAGE_CONTROLLER}/new/file`;
export const INSTAGRAM_SEND_EXISTING_TEXT_MESSAGE = `${MESSAGE_CONTROLLER}/existing/text`;
export const INSTAGRAM_SEND_EXISTING_FILE_MESSAGE = `${MESSAGE_CONTROLLER}/existing/file`;
export const INSTAGRAM_MESSAGE_READ = `${MESSAGE_CONTROLLER}/read`;
export const INSTAGRAM_MESSAGE_ACTIONS = `${MESSAGE_CONTROLLER}/actions`;



// Webhook controller
const WEBHOOK_CONTROLLER = `${INSTAGRAM_MS}/webhook`;
export const INSTAGRAM_WEBHOOK = `${WEBHOOK_CONTROLLER}`;
