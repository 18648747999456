import { RTUser } from 'src/models/rtUser';
import AuthManager from 'src/services/authentication/manager';
import { getRTUsers } from './getRTUsers';
import { useSelector } from 'src/store/store';

/** GET CONNECTED RT USER
 *
 *
 * @returns RTUser
 */
export const getConnectedRTUser = (
  shouldUseHook = true,
  withConv = true
): RTUser => {
  if (shouldUseHook) {
    return useSelector(
      (state) => state.rtusers.rtUsers[AuthManager.getUserId()]
    );
  }

  const rtusers = getRTUsers({
    id: AuthManager.getUserId(),
    shouldUseHook: !!shouldUseHook,
    withConversations: withConv
  });

  return rtusers[0];
};
