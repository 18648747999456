import { MouseEventHandler, ReactNode, useContext } from 'react';
import { Button, Stack } from '@mui/material';
import PageHeaderDocs from '../PageHeaderDocs';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import { Permissions } from 'src/models/permissionGroups';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

interface AdminHeaderPropTypes {
  heading: string;
  subheading?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonLabel?: string;
  permissionCheckerFn?: (permissions: Permissions) => boolean;
  action?: ReactNode;
}

const AdminHeader: React.FC<AdminHeaderPropTypes> = ({
  heading,
  subheading = '',
  onClick,
  buttonLabel,
  permissionCheckerFn,
  action
}) => {
  const { hasAccess } = useContext(PermissionsContext);
  const isAllowed = permissionCheckerFn ? hasAccess(permissionCheckerFn) : true;
  const isButtonDisplayed = buttonLabel && isAllowed && !action;
  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        px={5}
      >
        <PageHeaderDocs heading={heading} subheading={subheading} />
        {isButtonDisplayed && (
          <Button
            id="btnName-AdminHeader"
            variant="contained"
            color="secondary"
            onClick={onClick}
            sx={{ borderColor: 'secondary.main' }}
            startIcon={<AddIcon />}
          >
            {t(buttonLabel)}
          </Button>
        )}
        {!!action && action}
      </Stack>
    </>
  );
};
export default AdminHeader;
