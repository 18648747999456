import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import StatusComingSoon from 'src/components/Status/ComingSoon';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { DashboardProvider } from '../modules/Dashboards/views/contexts/DashboardsContext';
import Restricted from 'src/components/Restricted';
import {
  checkReadContactsAndContactGroupsPermissions,
  checkReadTicketViewPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Conversations = Loader(
  lazy(() => import('../modules/Conversations/views'))
);
const Calls = Loader(lazy(() => import('../modules/Calls/views')));
const CallsDetails = Loader(
  lazy(() => import('../modules/CallsDetails/views'))
);
const WorkDay = Loader(lazy(() => import('../modules/WorkDay/views')));
const Dashboards_realtime = Loader(
  lazy(() => import('../modules/Dashboards/views'))
);
const Dashboards_reporting = Loader(
  lazy(() => import('../modules/Dashboards/Reporting'))
);

const CreateEditDashboards = Loader(
  lazy(() => import('../modules/Dashboards/views/components/DashboardView'))
);
const Downloads = Loader(lazy(() => import('../modules/Downloads/views')));
const Tickets = Loader(lazy(() => import('../modules/Tickets/views')));
const Contacts = Loader(lazy(() => import('../modules/Contacts/views')));
const ContactQualification = Loader(
  lazy(() => import('../modules/ContactQualification/views'))
);
const Activity = Loader(lazy(() => import('../modules/Activity/views')));

const productivityRoutes: RouteObject[] = [
  {
    path: 'live',
    element: (
      <DashboardProvider>
        <Dashboards_realtime />
      </DashboardProvider>
    ),
    children: [
      {
        path: ':id',
        element: <CreateEditDashboards />
      }
    ]
  },
  {
    path: 'reporting/:id',
    element: (
      <DashboardProvider>
        <CreateEditDashboards />
      </DashboardProvider>
    )
  },
  {
    path: 'reporting',
    element: <Dashboards_reporting />
  },
  {
    path: 'conversations',
    element: <Conversations />
  },
  {
    path: 'contacts',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  },
  {
    path: 'contact-qualification',
    element: <ContactQualification />
  },
  {
    path: 'tickets',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadTicketViewPermissions}>
        <Tickets />
      </Restricted>
    )
  },
  {
    path: 'calls',
    element: <Calls />
  },
  {
    path: 'calls-details',
    element: <CallsDetails />
  },
  {
    path: 'socialMedia',
    element: <StatusComingSoon />
  },
  {
    path: 'work-day',
    element: <WorkDay />
  },
  {
    path: 'downloads',
    element: <Downloads />
  },
  {
    path: 'activity',
    element: <Activity />
  },
  {
    path: 'voicemail',
    element: <StatusComingSoon />
  },
  {
    path: 'integrations',
    element: <StatusComingSoon />
  },
  {
    path: 'tarificador',
    element: <StatusComingSoon />
  }
];

export default productivityRoutes;
