import { BASE_URL } from './base';

// CONVERSATIONS MICROSERVICE
const WEBCHAT_MS = `${BASE_URL}/webchat`;

// Message controller
const MESSAGE_CONTROLLER = `${WEBCHAT_MS}/message`;
export const WEBCHAT_MESSAGE_TYPING = `${MESSAGE_CONTROLLER}/typing`;
export const WEBCHAT_MESSAGE_FILE = `${MESSAGE_CONTROLLER}/existing/file`;
export const WEBCHAT_MESSAGE_FILE_PUBLIC = `${MESSAGE_CONTROLLER}/file/public`;
export const WEBCHAT_MESSAGE_READ = `${MESSAGE_CONTROLLER}/read`;
export const WEBCHAT_MESSAGE_TEXT = `${MESSAGE_CONTROLLER}/existing/text`;
export const WEBCHAT_MESSAGE_TEXT_PUBLIC = `${MESSAGE_CONTROLLER}/text/public`;
export const WEBCHAT_MESSAGE_ACTIONS = `${MESSAGE_CONTROLLER}/actions`;

// Conversation controller
const CONVERSATION_CONTROLLER = `${WEBCHAT_MS}/conversation`;
export const WEBCHAT_CONVERSATION = `${CONVERSATION_CONTROLLER}`;
export const WEBCHAT_CONVERSATION_ID = `${CONVERSATION_CONTROLLER}/{id}`;
export const WEBCHAT_CONVERSATION_ID_CLOSE = `${CONVERSATION_CONTROLLER}/{id}/close`;
export const WEBCHAT_CONVERSATION_ID_SENDMAIL = `${CONVERSATION_CONTROLLER}/{id}/sendEmail`;

// Style controller
const STYLE_CONTROLLER = `${WEBCHAT_MS}/style`;
export const WEBCHAT_STYLE = `${STYLE_CONTROLLER}`;
export const WEBCHAT_STYLE_ID = `${STYLE_CONTROLLER}/{id}`;
export const WEBCHAT_STYLE_ID_UPLOADED_IMAGES = `${STYLE_CONTROLLER}/{id}/uploadedImages`;

// Attachment controller
const ATTACHMENT_CONTROLLER = `${WEBCHAT_MS}/attachment`;
export const WEBCHAT_ATTACHMENT_ID = `${ATTACHMENT_CONTROLLER}/{id}`;

// Account controller
const ACCOUNT_CONTROLLER = `${WEBCHAT_MS}/account`;
export const WEBCHAT_ACCOUNT = `${ACCOUNT_CONTROLLER}`;
export const WEBCHAT_ACCOUNT_ID = `${ACCOUNT_CONTROLLER}/{id}`;
