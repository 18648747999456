import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $insertNodes,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  KEY_BACKSPACE_COMMAND,
  LexicalCommand,
  NodeKey,
  createCommand
} from 'lexical';
import { $createImageNode, ImageNode } from './ImageNode';
import { useEffect } from 'react';
export interface ImagePayload {
  altText: string;
  height?: number | string;
  key?: NodeKey;
  maxWidth?: number | string;
  src: string;
  width?: number | string;
  file?: File;
}
export type InsertImagePayload = Readonly<ImagePayload>;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand('INSERT_IMAGE_COMMAND');

export function ImagePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload);
          $insertNodes([imageNode]);

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_BACKSPACE_COMMAND,
        (event) => {
          const selection = $getSelection();
          if (selection) {
            const nodes = selection.getNodes();
            if (nodes.length === 1 && nodes[0] instanceof ImageNode) {
              const node = nodes[0] as ImageNode;
              node.remove();
              return true;
            }
            return false;
          }
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor]);

  return null;
}
