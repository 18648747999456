import { emitCustomEvent } from 'react-custom-events';
import { MessageAlert } from 'src/hooks/useAlert';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { TicketMsg } from '../models/tickets';

export const onChangeTicketPositiveSubResolution = (eventWS: TicketMsg) => {
  const ticketFriendly = eventWS.friendlyId;
  const msgText = `The ticket #${ticketFriendly} has been closed positively`;
  emitCustomEvent(CustomEventNames.ALERT, {
    msg: msgText,
    type: 'info'
  } as MessageAlert);
};
