import axios from 'axios';
import { replaceURLParams } from 'src/modules/Shared/utils/apiFunctions';
import { Dashboard } from '../../models/Dashboards';
import { PaginatedElements } from 'src/models/pagination';
import {
  PRESETS_DASHBOARD_ID,
  REALTIME_DASHBOARDS,
  REALTIME_DASHBOARDS_ID,
} from 'src/utils/apiRoutes/msFlow';

export const getDashboardsApi = async (params?: {
  name?: string;
  page?: number;
  size?: number;
}) => {
  const response = await axios.get<PaginatedElements<Dashboard>>(
    REALTIME_DASHBOARDS,
    {
      params,
    }
  );
  return response.data;
};

export const getDashboardsByIdApi = async (dashboardId: string) => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_ID, {
    '{id}': dashboardId,
  });
  const response = await axios.get<Dashboard>(path);

  return response.data;
};

export const getPresetDashboardByFixedIdApi = async (fixedId: string) => {
  const path = replaceURLParams(PRESETS_DASHBOARD_ID, {
    '{presetFixedId}': fixedId,
  });
  const response = await axios.get<Dashboard>(path);

  return response.data;
};
