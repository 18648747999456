import { useEffect } from 'react';

import { Box, IconButton, Slider, Stack } from '@mui/material';
import { setVoiceVolume } from 'src/store/slices/users/configVolumeSlice';
import { useDispatch, useSelector } from 'src/store/store';
import { useMemoValue } from './useMemoValue';
import {
  VolumeOffTwoTone,
  VolumeUpTwoTone,
  MicOffTwoTone,
  MicTwoTone
} from '@mui/icons-material';
import WebRTCDevices from 'src/services/webrtc/webrtc.devices';
import { setMicVolume } from 'src/services/webrtc/domain/setMicVolume';

export const VoiceSlider: React.FC = () => {
  const localStgKey = 'voiceVolume';

  const { value, onSetInputValue, onResetMemoValue, onSetMemoValue, isReset } =
    useMemoValue(localStgKey);

  const { voiceVolume } = useSelector((state) => state.configVolume);
  const dispatch = useDispatch();

  //GainNode values between 0 and 2.
  useEffect(() => {
    onSetInputValue(voiceVolume);
    if (localStorage.getItem(localStgKey)) return;
    // because useMemoValue is in charge of getting the item from Local Storage

    setMicVolume(voiceVolume);
  }, []);

  //GainNode values between 0 and 2.
  useEffect(() => {
    setMicVolume(value);
    dispatch(setVoiceVolume(value));
    localStorage.setItem(localStgKey, JSON.stringify(value));
  }, [value]);

  return (
    <Box>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems="center"
        spacing={2}
      >
        <Slider
          id="voice slider"
          size="medium"
          sx={{
            width: '192px'
          }}
          color="secondary"
          orientation="horizontal"
          value={value}
          onChange={(e, val) => {
            onSetInputValue(val);
          }}
        />

        <IconButton id="voice-slider-button">
          {isReset ? (
            <MicOffTwoTone color="secondary" onClick={onSetMemoValue} />
          ) : (
            <MicTwoTone color="secondary" onClick={onResetMemoValue} />
          )}
        </IconButton>
      </Stack>
    </Box>
  );
};
