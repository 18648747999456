import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { TICKET_RESOLUTIONS_ID } from 'src/utils/apiRoutes/msMessaging';

export const deleteTicketResolutionApi = async (id: string): Promise<void> => {
  const path = replaceURLParams(TICKET_RESOLUTIONS_ID, {
    '{id}': id
  });
  await axios.delete(path);
};
