import { Conversation } from 'src/models/conversations/conversations';
import {
  getStoredConversations,
  GetConversationsFilter
} from '../../../conversations/domain/getConversations';
import store from 'src/store/store';
import { getConversationByIdApi } from '../../../conversations/infrastructure/getConversationsApi';

/** GET ONE CONVERSATION FROM **STORE**
 *
 * Filters the currently stored convesations and returns the first one that satisfies
 * the specified conditions.
 *
 * @param filter Parameters to filter the currently stored conversations by
 * @returns n Conversation
 */
export const getConversation = (
  filter: GetConversationsFilter
): Conversation => {
  const conversations = getStoredConversations(filter);

  return conversations[0];
};

export const getConversationById = (id: string) => {
  return store.getState().socialmedia.conversations[id];
};

export const getConversationByIdFromApi = async (id: string) => {
  const resp = await getConversationByIdApi(id);
  return resp;
};
