import { useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { EventTypes } from 'src/services/websocket/utils/notifications/dataMsgs';
import { PowerDialerNotification } from '../../models/dialerbot';

const usePowerDialerNotification = () => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [messageData, setMessageData] = useState<PowerDialerNotification>(null);
  const onClose = () => {
    setIsOpenPopover(false);
  };
  useCustomEventListener(
    EventTypes.POWERDIALERCALL,
    (data: PowerDialerNotification) => {
      setIsOpenPopover(true);
      setMessageData(data);
    }
  );
  return { isOpenPopover, messageData, onClose };
};

export default usePowerDialerNotification;
