import { useTranslation } from 'react-i18next';
import AdminHeader from 'src/components/AdminHeader';
import { MusicOnHoldTable } from './components/MusicOnHoldTable';
import { MusicOnHoldContext } from './context';
import { lazy, useContext } from 'react';
import { MOH_CREATE_FORM } from './constants';
import { checkCreateMusicOnHoldPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Modal
} from '@mui/material';
import { CloseTwoTone } from '@mui/icons-material';

const MusicOnHoldFormLazy = lazy(
  () => import('./components/MusicOnHoldForm/index')
);

export const MusicOnHold = () => {
  const { t } = useTranslation();
  const { isOpenModal, toggleMusicOnHoldModal, setTypeForm, typeForm } =
    useContext(MusicOnHoldContext);

  const handleClickHeader = () => {
    toggleMusicOnHoldModal();
    setTypeForm(MOH_CREATE_FORM);
  };

  return (
    <>
      <AdminHeader
        heading={t('MOH_TITLE')}
        subheading={t('MOH_SUBTITLE')}
        buttonLabel={t('MOH_CREATE_BUTTON')}
        onClick={handleClickHeader}
        permissionCheckerFn={checkCreateMusicOnHoldPermissions}
      />

      <Modal open={isOpenModal}>
        <Card
          sx={{
            height: '50vh',
            width: '30vw',
            minWidth: '300px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <CardHeader
            title={
              typeForm === MOH_CREATE_FORM
                ? t('MOH_CREATE_TITLE')
                : t('MOH_UPDATE_TITLE')
            }
            subheader={
              typeForm === MOH_CREATE_FORM
                ? t('MOH_CREATE_SUBTITLE')
                : t('MOH_UPDATE_SUBTITLE')
            }
            action={
              <IconButton
                sx={{
                  m: 1
                }}
                size="small"
                color="secondary"
                onClick={toggleMusicOnHoldModal}
              >
                <CloseTwoTone />
              </IconButton>
            }
          />
          <Divider />
          <CardContent
            sx={{
              height: '100%',
              p: 0,
              '&.MuiCardContent-root': {
                paddingBottom: 0
              }
            }}
          >
            <MusicOnHoldFormLazy />
          </CardContent>
        </Card>
      </Modal>
      <Box width={'100%'} px={4} py={2}>
        <MusicOnHoldTable />
      </Box>
    </>
  );
};
