import {
  Autocomplete,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  ScheduleCalendar,
  ScheduleCalendarStatus
} from './createScheduleCalendar';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { useAlert } from 'src/hooks/useAlert';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import CustomCalendar from './Calendar';
import { createScheduleCalendar } from './infrastructure/postScheduleCalendarApi';
import { updateScheduleCalendar } from './infrastructure/putScheduleCalendarApi';
import { deleteScheduleCalendar } from './infrastructure/deleteScheduleCalendarApi';
import { useSchedulesForm } from './useSchedulesForm';
import { ScheduleCalendarFilter } from './infrastructure/getScheduleCalendarsApi';
import { CallCampaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import AuthManager from 'src/services/authentication/manager';

interface Props {
  filter?: ScheduleCalendarFilter;
  conversation: Conversation;
  editSchedule?: ScheduleCalendar;
  rowForm?: boolean;
  height?: string;
  disableNewButton?: boolean;
  setIsScheduleOpen?: Dispatch<SetStateAction<boolean>>;
  setIsScheduleDone?: Dispatch<SetStateAction<boolean>>;
  subresolutionId?: string;
}

export interface PhonesAutocompleteOption {
  contactId?: string;
  name: string;
  number: string;
}

export const NEW_SCHEDULE_ID = 'NEW_SCHEDULE';

const ScheduleCalendarForm = (props: Props) => {
  const {
    conversation,
    editSchedule,
    rowForm,
    height,
    filter,
    disableNewButton,
    setIsScheduleOpen,
    setIsScheduleDone,
    subresolutionId
  } = props;
  const { showAlert } = useAlert();

  const { allowedCampaigns, defaultSchedule, phoneNumbers, users } =
    useSchedulesForm(conversation);

  const initialValues = editSchedule ?? defaultSchedule;

  const onSubmit = async (newCalendarSchedule) => {
    try {
      const date = new Date(newCalendarSchedule.scheduleTimestamp * 1000);
      if (date < new Date()) {
        showAlert('Date can not be in the past', 'error', 3000);
        return;
      }

      if (!newCalendarSchedule.userId) {
        showAlert('Assigned user is required', 'error', 3000);
        return;
      }

      const isNew = newCalendarSchedule.id === NEW_SCHEDULE_ID;
      if (isNew) {
        const response = await createScheduleCalendar(
          newCalendarSchedule,
          conversation.id
        );
        setValues(response);
        showAlert(t('Schedule created succesfully'), 'success', 3000);
      } else {
        await updateScheduleCalendar(newCalendarSchedule);
        showAlert(t('Schedule updated succesfully'), 'success', 3000);
      }
      if (setIsScheduleOpen) {
        setIsScheduleDone(true);
        setIsScheduleOpen(false);
      }
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
  };

  const onDelete = async () => {
    try {
      await deleteScheduleCalendar(values);
      showAlert(t('Schedule deleted succesfully'), 'success', 3000);
      setValues(defaultSchedule);
      if (setIsScheduleOpen) setIsScheduleOpen(false);
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
  };

  // FORM
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    isSubmitting
  } = useFormik<ScheduleCalendar>({
    initialValues,
    onSubmit
  });

  const mode = values.id === NEW_SCHEDULE_ID ? 'Create' : 'Update';

  const campaign = allowedCampaigns?.find((c) => c.id === values.campaignId);
  const assignToSameAgent =
    campaign?.channel === ChannelType.CALL &&
    (campaign as CallCampaign)?.relaunchResolutionConfig?.[subresolutionId]
      ?.assignToSameAgent;

  const campaignUsers = users.filter((user) => {
    return (
      campaign?.users.includes(user.id) &&
      (!assignToSameAgent || user.id === AuthManager.getUserId())
    );
  });

  useEffect(() => {
    if (!values.campaignId) {
      setFieldValue('campaignId', defaultSchedule.campaignId);
    }
  }, [allowedCampaigns.length]);

  const schedulesFilter = useMemo(() => {
    if (filter) {
      return {
        ...filter,
        userId: getConnectedRTUser(false).id
      };
    }

    return {
      userId: getConnectedRTUser(false).id
    };
  }, [filter]);

  const disableDelete =
    (values.scheduleStatus &&
      values.scheduleStatus !== ScheduleCalendarStatus.Unlaunched) ||
    values.calendarData.ticketId !== null ||
    mode === 'Create';
  const disableUpdateTimestamp =
    (values.scheduleStatus &&
      values.scheduleStatus !== ScheduleCalendarStatus.Unlaunched) ||
    values.relaunchAttempts > 0;

  const [temporalTimestamp, setTemporalTimestamp] = useState(
    values.calendarData.scheduleTimestamp
  );
  useEffect(() => {
    // Due to the DateTimePicker key, we need a debounce to update it manually
    const timeout = setTimeout(() => {
      setFieldValue('calendarData.scheduleTimestamp', temporalTimestamp);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [temporalTimestamp]);

  if (rowForm) {
    return (
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <CustomCalendar
            disableNewButton={disableNewButton}
            disableUpdateTimestamp={disableUpdateTimestamp}
            height={height}
            schedulesFilter={schedulesFilter}
            selectedSchedule={values}
            onDateClick={(date: Date) => {
              if (disableUpdateTimestamp) return;
              const scheduleTimestamp = date.getTime() / 1000;
              setFieldValue(
                'calendarData.scheduleTimestamp',
                scheduleTimestamp
              );
            }}
            onEventDrop={(schedule: ScheduleCalendar) => {
              if (disableUpdateTimestamp) return;
              setValues(schedule);
            }}
            onNewEventButtonClick={(selectedDate) => {
              if (disableUpdateTimestamp) return;
              const newDate = new Date(selectedDate);
              newDate.setHours(new Date().getHours());
              const scheduleTimestamp = newDate.getTime() / 1000;
              setValues({
                ...defaultSchedule,
                calendarData: {
                  ...defaultSchedule.calendarData,
                  scheduleTimestamp
                }
              });
            }}
            onEventClick={(schedule) => {
              setValues(schedule);
            }}
          />
          <Stack direction="row" columnGap={2}>
            <Stack width="50%" rowGap={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  disabled={disableUpdateTimestamp}
                  key={values.calendarData.scheduleTimestamp}
                  label={t('Date')}
                  value={moment.unix(values.calendarData.scheduleTimestamp)}
                  minDate={moment.min(moment())}
                  onChange={(newValue) => {
                    setTemporalTimestamp(newValue.toDate().getTime() / 1000);
                  }}
                />
              </LocalizationProvider>
              <TextField
                label={t('Title')}
                name="title"
                value={values.calendarData.title}
                onChange={(e) => {
                  setFieldValue('calendarData.title', e.target.value);
                }}
              />
              <TextField
                label={t('Description')}
                name="description"
                value={values.calendarData.description}
                onChange={(e) => {
                  setFieldValue('calendarData.description', e.target.value);
                }}
              />
            </Stack>
            <Stack width="50%" rowGap={2}>
              <Stack
                direction="row"
                width="100%"
                flexWrap={'wrap'}
                rowGap={2}
                columnGap={1}
              >
                <TextField
                  sx={{
                    flexGrow: 2,
                    minWidth: '240px'
                  }}
                  key={values.campaignId}
                  label={t('Campaign')}
                  name="campaignId"
                  select
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values.campaignId}
                >
                  {allowedCampaigns &&
                    allowedCampaigns.map((campaign, i) => {
                      return (
                        <MenuItem key={i} value={campaign.id}>
                          {campaign.name}
                        </MenuItem>
                      );
                    })}
                </TextField>

                <TextField
                  sx={{
                    flexGrow: 2
                  }}
                  label={t('Assigned user')}
                  name="userId"
                  select
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={handleChange}
                  value={values.userId}
                >
                  {campaignUsers &&
                    campaignUsers.map((user) => {
                      return (
                        <MenuItem key={user.id} value={user.id}>
                          {user.username}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Stack>
              <Autocomplete
                groupBy={(option: PhonesAutocompleteOption) => option.name}
                options={phoneNumbers}
                disabled={mode === 'Update'}
                onChange={(e, value) => {
                  setFieldValue('phoneNumber', value.number);
                  setFieldValue('calendarData.contactId', value.contactId);
                }}
                getOptionLabel={(option: PhonesAutocompleteOption) =>
                  option.number
                }
                value={
                  {
                    number: values.phonenumber
                  } as PhonesAutocompleteOption
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="phoneNumber"
                    label={t('Phone number')}
                  />
                )}
              />
              <Stack direction="row-reverse" alignItems={'center'} spacing={2}>
                <Button
                  disabled={disableDelete}
                  variant="outlined"
                  color="error"
                  sx={{ width: '125px', py: 1.5 }}
                  onClick={onDelete}
                >
                  {t('Delete')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ width: '125px', py: 1.5 }}
                  type="submit"
                >
                  {t(mode)}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} marginLeft={1} marginRight={1.5} marginTop={2.5}>
        <CustomCalendar
          height={height}
          schedulesFilter={schedulesFilter}
          selectedSchedule={values}
          onDateClick={(date: Date) => {
            if (disableUpdateTimestamp) return;
            const scheduleTimestamp = date.getTime() / 1000;
            setFieldValue('calendarData.scheduleTimestamp', scheduleTimestamp);
          }}
          onEventDrop={(schedule: ScheduleCalendar) => {
            if (disableUpdateTimestamp) return;
            setValues(schedule);
          }}
          onNewEventButtonClick={(selectedDate) => {
            if (disableUpdateTimestamp) return;
            const newDate = new Date(selectedDate);
            newDate.setHours(new Date().getHours());
            const scheduleTimestamp = newDate.getTime() / 1000;
            setValues({
              ...defaultSchedule,
              calendarData: {
                ...defaultSchedule.calendarData,
                scheduleTimestamp
              }
            });
          }}
          onEventClick={(schedule) => {
            setValues(schedule);
          }}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            disabled={disableUpdateTimestamp}
            key={values.calendarData.scheduleTimestamp}
            label={t('Date')}
            value={moment.unix(values.calendarData.scheduleTimestamp)}
            minDate={moment.min(moment()).subtract(10, 'seconds')}
            onChange={(newValue) => {
              setTemporalTimestamp(newValue.toDate().getTime() / 1000);
            }}
          />
        </LocalizationProvider>
        <TextField
          label={t('Title')}
          name="title"
          value={values.calendarData.title}
          onChange={(e) => {
            setFieldValue('calendarData.title', e.target.value);
          }}
        ></TextField>
        <TextField
          label={t('Description')}
          name="description"
          value={values.calendarData.description}
          onChange={(e) => {
            setFieldValue('calendarData.description', e.target.value);
          }}
        ></TextField>
        <Stack
          direction="row"
          width="100%"
          flexWrap={'wrap'}
          rowGap={2}
          columnGap={1}
        >
          <TextField
            sx={{
              flexGrow: 2,
              minWidth: '240px'
            }}
            key={values.campaignId}
            label={t('Campaign')}
            name="campaignId"
            select
            InputLabelProps={{
              shrink: true
            }}
            onChange={(e) => {
              handleChange(e);
            }}
            value={values.campaignId}
          >
            {allowedCampaigns &&
              allowedCampaigns.map((campaign, i) => {
                return (
                  <MenuItem key={i} value={campaign.id}>
                    {campaign.name}
                  </MenuItem>
                );
              })}
          </TextField>

          <TextField
            sx={{
              flexGrow: 2
            }}
            label={t('Assigned user')}
            name="userId"
            select
            required
            InputLabelProps={{
              shrink: true
            }}
            onChange={handleChange}
            value={values.userId}
          >
            {campaignUsers &&
              campaignUsers.map((user) => {
                return (
                  <MenuItem key={user.id} value={user.id}>
                    {user.username}
                  </MenuItem>
                );
              })}
          </TextField>
        </Stack>
        <Autocomplete
          groupBy={(option: PhonesAutocompleteOption) => option.name}
          options={phoneNumbers}
          disabled={mode === 'Update'}
          onChange={(e, value) => {
            setFieldValue('phonenumber', value.number);
            setFieldValue('calendarData.contactId', value.contactId);
          }}
          getOptionLabel={(option: PhonesAutocompleteOption) => option.number}
          value={
            {
              number: values.phonenumber
            } as PhonesAutocompleteOption
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name="phonenumber"
              label={t('Phone number')}
            />
          )}
        />
        <Stack direction="row-reverse" justifyContent={'space-between'}>
          <Button
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={16} />}
            variant="contained"
            color="secondary"
            sx={{ width: '125px' }}
            type="submit"
          >
            {t(mode)}
          </Button>
          <Button
            disabled={disableDelete}
            variant="outlined"
            color="error"
            sx={{ width: '125px' }}
            onClick={onDelete}
          >
            {t('Delete')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ScheduleCalendarForm;
