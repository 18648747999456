import { CloseSharp, Download } from '@mui/icons-material';
import {
  Box,
  CardMedia,
  IconButton,
  Link,
  Modal,
  Slider,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatMessage } from 'src/models/conversations/messages';
import { useGetConversationTitleQuery } from 'src/services/socialmedia/application/query/getConversationInfoQuery';
import { getConversation } from 'src/services/socialmedia/domain/conversation/getConversation';

interface Props {
  message: ChatMessage;
  blob: string;
}

export const ChatImage: React.FC<Props> = ({ message, blob }) => {
  const theme = useTheme();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { t } = useTranslation();
  const messageMoment = moment(new Date(message.metadata.creationDate));
  const messageTime = messageMoment.format('HH:mm');
  const messageDate = messageMoment.format('DD/MM/YYYY');
  const [zoom, setZoom] = useState(0);

  const { uploadedFile } = message.content;

  const conversation = getConversation({
    id: message.conversationId,
    shouldUseHook: true
  });
  const incomingMessage = message.orientation === 'Incoming';

  const title = incomingMessage
    ? useGetConversationTitleQuery({
        conversation,
        enabled: !!conversation
      }).data
    : t('You');

  function handleScroll(e: React.WheelEvent<HTMLDivElement>) {
    e.preventDefault();
    const delta = e.deltaY;
    setZoom((prevZoom) => {
      const newZoom = prevZoom + (delta > 0 ? -10 : 10);
      return Math.max(Math.min(newZoom, 100), 0);
    });
  }

  return (
    <Box sx={{ cursor: 'pointer', width: '100%' }}>
      <Box>
        <CardMedia
          onClick={() => setIsPopoverOpen(true)}
          component="img"
          src={blob}
          alt={uploadedFile?.name}
          sx={{
            maxHeight: '300px',
            maxWidth: '300px',
            borderRadius: theme.general.borderRadiusXl
          }}
        />
      </Box>
      {/* POPOVER */}
      {isPopoverOpen && (
        <Modal open={isPopoverOpen}>
          <Stack
            width="100vw"
            height={'100vh'}
            justifyContent="center"
            alignItems={'center'}
          >
            <Stack
              width="100%"
              height={'100%'}
              sx={{
                background: 'rgba(181, 181, 181, 0.6)'
              }}
            >
              <Stack
                direction="row"
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ background: 'white' }}
                px={2}
                py={0.5}
                zIndex={100}
              >
                <Stack
                  direction="row"
                  alignItems={'center'}
                  gap={2}
                  flexGrow={1}
                  flexBasis={0}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h6" style={{ margin: 0 }}>
                      {title}
                    </Typography>
                    <Typography variant="h6">
                      {messageDate + ' , ' + messageTime}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={2}
                >
                  <Typography>Zoom</Typography>
                  <Slider
                    value={zoom}
                    onChange={(_, value) => setZoom(value as number)}
                    min={0}
                    max={100}
                    style={{ width: 100 }}
                  />
                  <Typography
                    display="flex"
                    justifyContent="start"
                    width={1.5}
                    flexGrow={1}
                  >
                    {zoom}%
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  flexGrow={1}
                  flexBasis={0}
                >
                  <Link
                    href={blob}
                    download={uploadedFile?.name}
                    target="_blank"
                    color="inherit"
                  >
                    <IconButton>
                      <Download />
                    </IconButton>
                  </Link>

                  <IconButton
                    onClick={() => {
                      setIsPopoverOpen(false);
                      setZoom(0);
                    }}
                  >
                    <CloseSharp />
                  </IconButton>
                </Stack>
              </Stack>
              <Box
                sx={{
                  height: '90%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img
                  onWheel={handleScroll}
                  style={{
                    objectFit: 'contain',
                    maxHeight: '90%',
                    minHeight: '60%',
                    maxWidth: 'calc(100vw-40px)',
                    transform: `scale(${(zoom + 50) / 100})`
                  }}
                  onClick={() => setIsPopoverOpen(true)}
                  src={blob}
                  alt={uploadedFile?.name}
                />
              </Box>
            </Stack>
          </Stack>
        </Modal>
      )}
    </Box>
  );
};
