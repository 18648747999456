import { Box, CircularProgress } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { ContactsDomain } from 'src/modules/Contacts/domain/ContactsDomain';
import { Contact, ContactTag } from 'src/modules/Contacts/models/contacts';
import ContactDataForm from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel/ContactDataForm';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { getCompanyContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';

interface Props {
  contact: Contact;
  setContact: Dispatch<SetStateAction<Contact>>;
  onCloseEditContact: () => void;
  onCloseModal: () => void;
}

const EditContactDataView = (props: Props) => {
  const { contact, setContact, onCloseEditContact, onCloseModal } = props;
  const companyFieldsQuery = useGetContactFields(
    { companyId: contact?.companyId },
    !!contact
  );

  // GET ALL TAGS OPTIONS
  const [tags, setTags] = useState<ContactTag[]>([]);
  useEffect(() => {
    let isMounted = true;
    getCompanyContactTagsQuery({ companyId: contact.companyId }).then(
      (response) => {
        if (isMounted) {
          setTags(response?.elements);
        }
      }
    );
    return () => {
      isMounted = false;
    };
  }, []);

  const onUpdateContact = async (contact: Contact) => {
    const updatedContact = await ContactsDomain.updateContact(contact);
    emitCustomEvent('table-data-refetch');
    return updatedContact;
  };

  const onDeleteContact = async (contact: Contact) => {
    await ContactsDomain.deleteContact(contact);
    emitCustomEvent('table-data-refetch');
  };

  const isLoading = companyFieldsQuery.isLoading;

  if (isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />;
      </Box>
    );

  return (
    !!companyFieldsQuery.data && (
      <Box height="100%" width="100%" px={2} key={contact.id + '-box-form'}>
        <ContactDataForm
          key={contact.id + '-form'}
          contact={contact}
          setContact={setContact}
          onClose={onCloseEditContact}
          onDeleteClose={onCloseModal}
          onSubmitContact={onUpdateContact}
          onDeleteContact={onDeleteContact}
          isCreatingContact={false}
          cancelToNull={false}
        />
      </Box>
    )
  );
};

export default EditContactDataView;
