import { Conversation } from 'src/models/conversations/conversations';
import { ConversationMessage } from 'src/models/conversations/messages';
import { setConversationStore } from '../../infraestructure/store/setConversationStore';
import { getConversation } from './getConversation';

/** SET CONVERSATION MESSAGES
 *
 * @param conversation Conversation to set the messages to
 * @param messages Array containing the messages to be set
 */
export const addConversationMessages = (
  oldConversation: Conversation,
  messages: ConversationMessage[]
) => {
  if (messages.length === 0) return;
  // TEMPORAL HASTA QUE EL BACK ENVIE BIEN LA FECHA
  let messagesWithCorrectDate = messages.map((msg) => {
    if (!msg.metadata.creationDate.includes('Z'))
      return {
        ...msg,
        metadata: {
          ...msg.metadata,
          creationDate: msg.metadata.creationDate + 'Z'
        }
      };
    else return msg;
  });

  const newMessageIds = messagesWithCorrectDate.map(
    (message) => message.messageId
  );

  // Set messages
  const conversation: Conversation = {
    ...oldConversation,
    totalMessages: oldConversation?.totalMessages ?? 0,
    messages: [
      ...(oldConversation?.messages?.filter(
        (message) => !newMessageIds.includes(message.messageId)
      ) ?? []),
      ...messagesWithCorrectDate
    ],
    lastMessage: messagesWithCorrectDate.at(0)
  };

  setConversationStore(conversation);
};
