import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import UsersApplication from 'src/services/users/application/UsersApplication';

export const AgentInfo: React.FC = () => {
  const user = getConnectedRTUser();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  if (!user) return <></>;
  //Get companies in case will be shown
  const companiesQuery = UsersApplication.useGetCompaniesQuery();
  const companies = companiesQuery.data;
  const companyName =
    companies &&
    companies.find((company) => company.id === user?.companyId).name;
  return (
    <Box>
      <Stack
        direction="row"
        alignItems={'center'}
        spacing={2}
        width="100%"
        p={2}
      >
        <Box
          sx={{
            height: 50,
            width: 50,
            backgroundColor: theme.colors.secondary.main,
            color: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '25px',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
          {user?.username.charAt(0)}
        </Box>
        <Box
          sx={{
            color: theme.colors.primary.main,
            fontSize: '18px',
            fontWeight: 'bold'
          }}
        >
          {user?.extension?.extension} {user?.extension?.extension && ' - '}
          <label
            style={{
              textTransform: 'capitalize'
            }}
          >
            {user?.username}
          </label>
          <Box sx={{ fontSize: 12 }}>{companyName ? companyName : ''}</Box>
        </Box>
      </Stack>
    </Box>
  );
};
