import { Divider, Stack } from '@mui/material';
import StyleModifiers from './StyleModifiers';
import { WebChatStyle } from '../../../../models/webChatStyle';
import { WebChat } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/views/components/ConversationPreview';
import { FormikProps } from 'formik';
import { t } from 'i18next';

interface ChatConversationTabProps {
  formikProps: FormikProps<WebChatStyle>;
}

export const ChatConverstionTab: React.FC<ChatConversationTabProps> = ({
  formikProps
}) => {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      height="calc(100vh - 375px)"
      overflow={{ xs: 'scroll', md: 'visible' }}
    >
      <Stack
        direction="column"
        alignItems={'center'}
        px={1}
        py={1.5}
        spacing={2}
        overflow={'scroll'}
        width="100%"
        minHeight={{ xs: '650px', md: 'auto' }}
        maxWidth={{ xs: 'auto', md: '420px' }}
      >
        <WebChat formikProps={formikProps.values} />
      </Stack>
      <Divider orientation="vertical" />
      <Stack width="100%">
        <StyleModifiers formikProps={formikProps} />
      </Stack>
    </Stack>
  );
};
