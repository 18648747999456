import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'src/store/store';
import { getMessages } from '../domain/messages/getMessages';
import {
  APIConversationParams,
  getConversationsApi
} from 'src/services/conversations/infrastructure/getConversationsApi';

export const getAllConversations = createAsyncThunk(
  'conversations/getAll',
  async (params: APIConversationParams) => {
    const conversations = await getConversationsApi(params);
    return conversations;
  }
);

export const getCurrentConversationMessages = createAsyncThunk(
  'messages/getAll',
  async (params: { page?: number; size?: number }, { getState }) => {
    const { page, size } = params;
    const state = getState() as RootState;
    const { currentConversationId } = state.socialmedia;
    if (!currentConversationId) return [];
    const messages = await getMessages({
      page,
      size,
      conversationId: currentConversationId
    });
    return messages.elements;
  }
);
