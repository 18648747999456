import { queryClient } from 'src/utils/queryClient';
import {
  ContactGroupsFilters,
  getContactGroupsApi
} from '../infraestructure/api/getContactGroups';
import { useQuery } from '@tanstack/react-query';
import { getContactGroupByIdApi } from '../infraestructure/api/getContactGroupById';
import { ContactGroup } from '../models/contacts';

export const getContactGroups = (filters?: ContactGroupsFilters) => {
  const { page = 0, size = -1, ...rest } = filters ?? {};
  return getContactGroupsApi({ ...rest, page, size });
};

export const getContactGroupById = (id: string) => {
  return getContactGroupByIdApi(id);
};

export const getContactGroupsQuery = async (filters?: ContactGroupsFilters) => {
  const contactGroups = await queryClient.fetchQuery(
    ['contactGroups', filters?.companyId, filters?.name, filters?.description],
    {
      queryFn: () => getContactGroupsApi(filters),
      staleTime: 60000
    }
  );

  return contactGroups;
};

export const useContactGroupsQuery = (filters?: ContactGroupsFilters) => {
  return useQuery(
    ['contactGroups', ...Object.values(filters ?? {})],
    () => getContactGroupsApi(filters),
    {
      staleTime: 60000
    }
  );
};

export function invalidateContactGroupsQuery() {
  queryClient.invalidateQueries({
    queryKey: ['contactGroups']
  });
}

export const getContactGroupByIdQuery = async (id: string) => {
  const contactGroups = await queryClient.fetchQuery(['contactGroups', id], {
    queryFn: () => getContactGroupByIdApi(id),
    staleTime: 60000
  });

  return contactGroups;
};

export const getNameContactGroup = (
  idContactGroup: string,
  contactGroupsData: ContactGroup[]
) => {
  if (contactGroupsData.length > 0) {
    const contactGroupName = contactGroupsData.find(
      (group) => group.id === idContactGroup
    );
    return contactGroupName?.name ?? '';
  }
};
