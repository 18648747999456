import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/store/slices/calendar';
import { reducer as mailboxReducer } from 'src/store/slices/mailbox';
import { reducer as projectsBoardReducer } from 'src/store/slices/projects_board';
import callSlice from './slices/calls/calls';
import configVolumeSlice from './slices/users/configVolumeSlice';
import daServicesSlice from './slices/daServices/daServicesSlice';
import queuesSlice from './slices/queues/queuesSlice';
import sessionSlice from './slices/session/session';
import type { Action } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import type { TypedUseSelectorHook } from 'react-redux';
import userSlice from './slices/users/rtusers';
import usersSlice from './slices/users/usersSlice';
import contactSlice from './slices/contacts/contactSlice';
import rtuserSlices from './slices/rtusers/rtuserSlices';
import socialmediaSlice from './slices/socialmedia/socialmediaSlice';
import dialerbotSlice from './slices/dialerbot/dialerbotSlice';
import authSlice from './slices/auth/authSlice';
import rtCallsSlices from './slices/rtcalls/rtCallsSlices';
import dashboardsSlice from './slices/dashboards/dashboardsSlice';

const store = configureStore({
  reducer: {
    calendar: calendarReducer,
    projectsBoard: projectsBoardReducer,
    mailbox: mailboxReducer,
    callData: callSlice,
    configVolume: configVolumeSlice,
    daServices: daServicesSlice,
    queues: queuesSlice,
    sessionData: sessionSlice,
    userData: userSlice,
    users: usersSlice,
    contacts: contactSlice,
    socialmedia: socialmediaSlice,
    rtusers: rtuserSlices,
    rtcalls: rtCallsSlices,
    dialerbot: dialerbotSlice,
    authSlice: authSlice,
    dashboards: dashboardsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
