import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { StreamInFullScreen } from '.';

interface Props {
  stream: MediaStream;
  label: string;
  ref: React.RefObject<HTMLDivElement>;
  streamInFullScreen: 'none' | 'local' | 'remote';
  setStreamInFullScreen: React.Dispatch<
    React.SetStateAction<StreamInFullScreen>
  >;
}

export const VideoCardMobile = forwardRef(function VideoCardMobile(
  { stream, label, streamInFullScreen, setStreamInFullScreen }: Props,
  ref: React.RefObject<HTMLDivElement>
) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, streamInFullScreen]);

  if (!['none', label].includes(streamInFullScreen)) {
    return (
      <motion.div
        layoutId={label}
        drag
        dragConstraints={ref}
        style={{
          position: 'absolute',
          bottom: 9,
          left: 9,
          width: '50%',
          height: '25%',
          maxHeight: '50%',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
        onClick={(e) => {
          //setStreamInFullScreen('none');
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      layoutId={label}
      style={{
        position: 'initial',
        width: '100%',
        height: '50%',
        maxHeight: '50%',
        borderRadius: '8px',
        overflow: 'hidden'
      }}
      onClick={(e) => {
        //setStreamInFullScreen(label === 'local' ? 'remote' : 'local');
      }}
    >
      <video
        controls={false}
        ref={videoRef}
        autoPlay
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
    </motion.div>
  );
});
