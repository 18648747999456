import { ColumnDef, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Delete, Edit } from '@mui/icons-material';
import { ContactGroup } from '../../models/contacts';
import { IconButton, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkDeleteContactGroupPermissions,
  checkUpdateContactGroupPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { useAlert } from 'src/hooks/useAlert';
import { deleteContactGroups } from '../../domain/deleteContactGroup';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { getContactGroups } from '../../domain/getContactGroups';
import {
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import { ContactGroupsFilters } from '../../infraestructure/api/getContactGroups';
import AuthManager from 'src/services/authentication/manager';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { emitCustomEvent } from 'react-custom-events';
import {
  DialTableBulkActionsContext,
  DialTableDataContext
} from 'src/components/DialTable/models/functionContexts';

export const useContactGroups = () => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { hasAccess } = useContext(PermissionsContext);
  const companyId = AuthManager.getLoggedUserCompanyId();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [contactsToDelete, setContactsToDelete] = useState<ContactGroup[]>([]);
  const [selectedRows, setSelectedRows] = useState<Row<ContactGroup>[]>([]);

  const handleDelete = async (groups) => {
    await deleteContactGroups(groups)
      .then(() => {
        showAlert('Deleted succesfully', 'success', 3000);
        emitCustomEvent(REFETCH_DATA_EVENT_NAME);
      })
      .catch((e) => {
        showAlert(getErrorMessage(e)?.errorMessage, 'error', 4000);
      });
    selectedRows.map((row) => row.toggleSelected());
    setSelectedRows([]);
  };

  const getData = async ({
    filtersObject,
    paginationValues
  }: DialTableDataContext<ContactGroup>) => {
    const params: ContactGroupsFilters = {
      companyId,
      page: paginationValues?.page,
      size: paginationValues?.size,
      name: filtersObject['name']
        ? (filtersObject['name']?.[0] as string)
        : undefined,
      description: filtersObject['description']
        ? (filtersObject['description']?.[0] as string)
        : undefined
    };

    const paginatedData = await getContactGroups(params);
    return paginatedData;
  };

  const getColumns: () => Promise<ColumnDef<ContactGroup>[]> = async () => [
    {
      accessorKey: 'name',
      id: 'name',
      header: t('Name')
    },
    {
      accessorKey: 'description',
      id: 'description',
      header: t('Description')
    }
  ];

  const getRowActions = (
    group: ContactGroup,
    setContactGroupToUpdate: (group: ContactGroup) => void,
    setIsPopoverOpened: (isOpened: boolean) => void
  ) => [
    <IconButton
      key="editContacGroup"
      title={t('See or edit group details')}
      size="small"
      color="secondary"
      onClick={() => {
        setContactGroupToUpdate(group);
        setIsPopoverOpened(true);
      }}
      disabled={!hasAccess(checkUpdateContactGroupPermissions)}
    >
      <Edit fontSize="small" />
    </IconButton>,
    <IconButton
      key="deleteContactGroup"
      title={t('Delete contact group')}
      size="small"
      color="error"
      onClick={() => {
        setIsDeleteModalOpened(true);
        setContactsToDelete([group]);
      }}
      disabled={!hasAccess(checkDeleteContactGroupPermissions)}
    >
      <Delete fontSize="small" />
    </IconButton>
  ];

  const getBulkActions = ({
    selectedData: groups,
    selectedRowModel
  }: DialTableBulkActionsContext<ContactGroup>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          color="error"
          title={t('Delete multiple contact groups')}
          size="small"
          onClick={() => {
            setIsDeleteModalOpened(true);
            setContactsToDelete(groups);
            setSelectedRows(selectedRowModel.rows);
          }}
          disabled={!hasAccess(checkDeleteContactGroupPermissions)}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  const getAvailableFilters = () => {
    const filters: DialTableFilter<ContactGroup>[] = [
      {
        fieldType: FieldType.TEXT,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'name',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null
      },
      {
        fieldType: FieldType.TEXT,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'description',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null
      }
    ];
    return Promise.resolve(filters);
  };

  return {
    getColumns,
    getRowActions,
    getBulkActions,
    isDeleteModalOpened,
    setIsDeleteModalOpened,
    contactsToDelete,
    handleDelete,
    getAvailableFilters,
    getData
  };
};
