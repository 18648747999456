import { INSTAGRAM_ACCOUNT } from 'src/utils/apiRoutes/msInstagram';
import { CreateInstagramAccountParams } from '../../models/instagramAccounts';
import axios from 'axios';

async function createInstagramAccountApi(body: CreateInstagramAccountParams) {
  const response = await axios.post(INSTAGRAM_ACCOUNT, body);

  return response.data;
}

export default createInstagramAccountApi;
