import axios from 'axios';
import { INSTAGRAM_ACCOUNT } from 'src/utils/apiRoutes/msInstagram';
import { InstagramAccount } from '../../models/instagramAccounts';
import { PaginatedElements } from 'src/models/pagination';
import { DirectionType } from 'src/components/TableReporting/models/direction';

export interface GetInstagramAccountsFilter {
  companyId?: string;
  sortBy?: keyof InstagramAccount;
  sortDir?: DirectionType;
}

export async function getInstagramAccountsApi({
  companyId
}: GetInstagramAccountsFilter) {
  const response = await axios.get(INSTAGRAM_ACCOUNT, {
    params: { companyId }
  });

  return response.data as InstagramAccount[];
}
