import { WhatsAppTemplate } from 'src/services/whatsapp/models/WhatsAppTemplate';
import {
  setWhatspAppTemplateStore,
  getWhatspAppTemplateStore
} from '../../infraestructure/store/setWhatspAppTemplate';

/** SEND WHATSPAPP TEMPLATE
 * @param template WhatspAppTemplate
 */
export const setWhatsAppTemplate = (template: WhatsAppTemplate) => {
  return setWhatspAppTemplateStore(template);
};

/** GET WHATSPAPP TEMPLATE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 */
export const getWhatsAppTemplate = (shouldUseHook: boolean) => {
  const template = getWhatspAppTemplateStore(shouldUseHook);

  return template;
};
