import React from 'react';
import { Box, Typography } from '@mui/material';
import { useWhatsAppAccount } from '../../hooks/useWhatsAppAccount';
import { WhatsAppTemplate } from 'src/services/whatsapp/models/WhatsAppTemplate';

interface Props {
  template?: WhatsAppTemplate;
}

const TemplatesCardHeader: React.FC<Props> = ({ template }) => {
  const { getStatusCard } = useWhatsAppAccount();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start'
      }}
    >
      <Box>
        <Typography
          component="h1"
          sx={{
            color: '#223354',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '24.43px',
            textAlign: 'left'
          }}
        >
          {template?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: !template?.status ? 'none' : 'flex',
          alignItems: 'center'
        }}
      >
        <Typography
          component="span"
          sx={{
            color: getStatusCard(template?.status).titleColor,
            fontSize: '10px',
            fontWeight: 400,
            lineHeight: '14.03px',
            textAlign: 'center',
            display: 'inline-flex',
            mr: 1
          }}
        >
          {getStatusCard(template?.status).title}
        </Typography>
        {getStatusCard(template?.status).icon}
      </Box>
    </Box>
  );
};

export default TemplatesCardHeader;
