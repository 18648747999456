import { getRTUsers, changeStatus } from './utils/getRTUsers';
import {
  enableCampaigns
} from './utils/getCampaigns';
import { getUsers, getUserById } from './utils/getUsers';
import {
  getCompanies,
  getCompanyStatusById,
  getCompanieById
} from './utils/getCompanies';
import { CreateUser } from './utils/CreateUser';

const UserManager = {
  getRTUsers,
  getUsers,
  getUserById,
  getCompanyStatusById,
  changeStatus,
  CreateUser,
  getCompanies,
  getCompanieById,
  enableCampaigns
};

export default UserManager;
