import { useQuery } from '@tanstack/react-query';
import {
  TicketFieldsFilters,
  getTicketFieldByIdApi,
  getTicketFieldsApi
} from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/fields/getTicketFieldsApi';
import { queryClient } from 'src/utils/queryClient';
import { TicketField } from '../../models/tickets';

export const getTicketFieldById = (id: string) => {
  return getTicketFieldByIdApi(id);
};

export const getTicketFieldByIdQuery = (id: string) => {
  return queryClient.fetchQuery(['ticketField' + id], {
    queryFn: () => getTicketFieldByIdApi(id),
    staleTime: 60 * 5 * 1000
  });
};

export const getTicketFields = (filters?: TicketFieldsFilters) => {
  return getTicketFieldsApi(filters);
};

export const useTicketFieldsQuery = (filters: TicketFieldsFilters) => {
  return useQuery<TicketField[], Error>(
    ['ticketFields', filters?.companyId],
    () => getTicketFieldsApi(filters),
    { staleTime: 60000 }
  );
};

export const getTicketFieldsQuery = (filters: TicketFieldsFilters) => {
  return queryClient.fetchQuery(['ticketFields', filters], {
    queryFn: () => getTicketFieldsApi(filters),

    staleTime: 60000
  });
};
