import { BASE_URL } from './base';

export const MESSAGING_MS = `${BASE_URL}/socialmedia`;

// Tickets Controller
const TICKETS_CONTROLLER = `${MESSAGING_MS}/tickets`;
export const TICKETS = TICKETS_CONTROLLER;
export const TICKETS_ID = `${TICKETS_CONTROLLER}/{id}`;
export const TICKETS_ATTACHMENT = `${TICKETS_CONTROLLER}/{ticketId}/attachment`;
export const TICKETS_ATTACHMENT_FIELD = `${TICKETS_ATTACHMENT}/{fileId}`;
export const TICKETS_COMMENT = `${TICKETS_CONTROLLER}/{ticketId}/comment`;
export const TICKETS_COMMENT_ID = `${TICKETS_COMMENT}/{commentId}`;
export const TICKETS_USER_ID = `${TICKETS_CONTROLLER}/{ticketId}/user/{userId}`;
export const TICKETS_OPEN = `${TICKETS_CONTROLLER}/openTickets`;
export const TICKETS_UPLOAD_CSV = `${TICKETS_CONTROLLER}/upload-csv`;
export const TICKETS_SHOULD_PERFORM_FIRST_MANUAL_RESCHEDULE = `${TICKETS_CONTROLLER}/shouldPerformFirstManualReschedule`;

// Tickettags Controller
const TICKET_TAGS_CONTROLLER = `${MESSAGING_MS}/tags`;
export const TICKET_TAGS = TICKET_TAGS_CONTROLLER;
export const TICKET_TAGS_ID = `${TICKET_TAGS_CONTROLLER}/{id}`;

// Tickets fields controller
const TICKET_FIELD_CONTROLLER = `${MESSAGING_MS}/fields`;
export const TICKET_FIELDS = TICKET_FIELD_CONTROLLER;
export const TICKET_FIELDS_ID = `${TICKET_FIELD_CONTROLLER}/{id}`;
export const TICKET_FIELDS_ID_SERVICE = `${TICKET_FIELDS_ID}/service`;
export const TICKET_FIELDS_SERVICES = `${TICKET_FIELD_CONTROLLER}/services`;

// Ticket resolutions controller
const TICKET_RESOLUTIONS_CONTROLLER = `${MESSAGING_MS}/resolutions`;
export const TICKET_RESOLUTIONS = TICKET_RESOLUTIONS_CONTROLLER;
export const TICKET_RESOLUTIONS_ID = `${TICKET_RESOLUTIONS_CONTROLLER}/{id}`;

// Tickets subresolutions controller
const TICKET_SUBRESOLUTIONS_CONTROLLER = `${MESSAGING_MS}/subresolutions`;
export const TICKET_SUBRESOLUTIONS = TICKET_SUBRESOLUTIONS_CONTROLLER;
export const TICKET_SUBRESOLUTIONS_ID = `${TICKET_SUBRESOLUTIONS_CONTROLLER}/{id}`;
export const TICKET_SUBRESOLUTIONS_ID_SERVICE = `${TICKET_SUBRESOLUTIONS_ID}/service`;
export const TICKET_SUBRESOLUTIONS_SERVICES = `${TICKET_SUBRESOLUTIONS_CONTROLLER}/services`;
