import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { Company, User } from 'src/models/users';
import { queryClient } from 'src/utils/queryClient';
import UsersDomain from '../../domain/UserDomain';
import { UsersParams } from '../../infraestructure/api/users/getUsers';
import DaServicesDomain from 'src/modules/Admin/modules/Operations/modules/DaServices/domain/DaServicesDomain';
import { PaginatedElements } from 'src/models/pagination';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { CampaignsParams } from 'src/modules/Admin/modules/Operations/modules/DaServices/infrastructure/campaigns/getCampaigns';
import { getDaServiceById } from 'src/services/daServices/utils';
import {
  DaServicesRequestParams,
  getDaServices
} from 'src/modules/Admin/modules/Operations/modules/DaServices/infrastructure/DaServices';
import { CompanyParams } from '../../infraestructure/api/companies/getCompanies';

export interface RequestCampaignsParams extends CampaignsParams {}

export const useCampaignsQuery = (params: RequestCampaignsParams) => {
  return useQuery(
    ['campaigns', params.userId, params.serviceId, params.callMode],
    () => DaServicesDomain.getAllCampaigns(params),
    { staleTime: 60 * 1000 * 5 }
  );
};

export const useCampaignsQueryById = (id: string) => {
  return useQuery<Campaign, Error>(
    ['campaignsById', id],
    () => DaServicesDomain.getCampaignById(id),
    { enabled: true, staleTime: 60 * 1000 * 5 }
  );
};

export const getCampaignsQuery = (params: RequestCampaignsParams) => {
  return queryClient.fetchQuery(
    ['campaigns', params.userId, params.serviceId, params.callMode],
    {
      queryFn: () => DaServicesDomain.getAllCampaigns(params),
      staleTime: 60 * 1000 * 5
    }
  );
};

export const getCampaignByIdQuery = (campaignId: string): Promise<Campaign> => {
  return queryClient.fetchQuery(['campaign by id' + campaignId], {
    queryFn: () => UsersDomain.getCampaignById(campaignId),
    staleTime: 15000
  });
};

export const useGetCompaniesQuery = (params?: CompanyParams) => {
  return useQuery<Company[], Error>(
    ['companies'],
    () => UsersDomain.getAllCompanies(params),
    {
      enabled: true
    }
  );
};

export const getCompaniesQuery = (): Promise<Company[]> => {
  return queryClient.fetchQuery(
    ['companies'],
    () => UsersDomain.getAllCompanies(),
    { staleTime: 60000 }
  );
};

export const getCompanyByIdQuery = (companyId: string) => {
  return queryClient.fetchQuery(['company by id', companyId], {
    queryFn: () => UsersDomain.retrieveCompanyById(companyId),
    staleTime: 5000
  });
};

export const getServicesQuery = async (userIds: string[]) => {
  return queryClient.fetchQuery(
    ['usersList', userIds],
    () => UsersDomain.getAllServices(),
    { staleTime: 30 * 1000 }
  );
};

export const useGetServicesQuery = (params?: DaServicesRequestParams) => {
  return useQuery(
    ['services', 'getDaServices', params ? Object.values(params) : undefined],
    () => getDaServices(params),
    {
      enabled: true,
      refetchOnWindowFocus: false
    }
  );
};

export const useServiceByIdQuery = (id: string): UseQueryResult<DaService> => {
  return useQuery(
    ['services', 'service by id', id],
    () => getDaServiceById(id),
    {
      enabled: true,
      staleTime: 60 * 1000 * 5
    }
  );
};

export const useGetUsersQuery = (
  params: UsersParams
): UseQueryResult<PaginatedElements<User>> => {
  return useQuery<PaginatedElements<User>, Error>(
    ['users', params.companyId, params.serviceId, params.size, params.id],
    () => UsersDomain.getAllUsers(params),
    { enabled: true, staleTime: 60000 }
  );
};

export const getUserByIdQuery = (id: string): Promise<User> => {
  return queryClient.fetchQuery(['userById', id], {
    queryFn: () => UsersDomain.getUserById(id),
    staleTime: 60000
  });
};

export const getUsersQuery = (
  params: UsersParams
): Promise<PaginatedElements<User>> => {
  return queryClient.fetchQuery(['users'], {
    queryFn: () => UsersDomain.getAllUsers(params),
    staleTime: 60000
  });
};

export const useGetUserByIdQuery = (
  id: string
): UseQueryResult<PaginatedElements<User>> => {
  return useQuery<PaginatedElements<User>, Error>(
    ['user', id],
    () => UsersDomain.getAllUsers({ id: id }),
    { enabled: true, staleTime: 0 }
  );
};

export const getUsersListQuery = async (userIds: string[]) => {
  return queryClient.fetchQuery(
    ['usersList', userIds],
    () => UsersDomain.getUsersList(userIds),
    { staleTime: 60 * 1000 }
  );
};

export const getCompanyUserStatusQuery = async (companyId: string) => {
  return queryClient.fetchQuery(
    ['userStatus', companyId],
    () => UsersDomain.getCompanyUserStatusById(companyId),
    { staleTime: 60 * 1000 }
  );
};
