import axios from 'axios';
import { TicketResolution } from '../../models/tickets';
import { TICKET_RESOLUTIONS } from 'src/utils/apiRoutes/msMessaging';

export const createTicketResolutionApi = async (
  ticketResolution: TicketResolution
): Promise<TicketResolution> => {
  const resp = await axios.post(TICKET_RESOLUTIONS, ticketResolution);
  return resp.data;
};
