import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ConversationContact } from 'src/models/conversations/conversations';
import { EmailMessage } from 'src/models/conversations/messages';
import htmlToString from '../../../utils/htmlToString';
import { t } from 'i18next';
import {
  ArrowDropDown,
  AttachFile,
  Reply,
  ReplyAll
} from '@mui/icons-material';
import EmailHeaderIconWrapper from './IconWrapper';
import { EmailItemState } from '..';
import { EmailEditorMode } from '../../EmailEditor/models/models';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import { useState } from 'react';

interface Props {
  itemState: EmailItemState;
  setItemState: React.Dispatch<React.SetStateAction<EmailItemState>>;
  message: EmailMessage;
  involvedContacts: ConversationContact[];
}

function EmailItemHeader({
  message,
  involvedContacts,
  itemState,
  setItemState
}: Props) {
  const theme = useTheme();
  const { isMaximized } = itemState;

  const [isEmailDestinationOpen, setIsEmailDestinationOpen] = useState(false);

  const [isSelectingText, setIsSelectingText] = useState(false);

  const handleMouseDown = () => {
    setIsSelectingText(false);
  };

  const handleMouseUp = () => {
    const selectedText = window.getSelection().toString();
    if (selectedText) {
      setIsSelectingText(true);
    } else {
      setIsSelectingText(false);
    }
  };

  const handleClick = () => {
    if (!isSelectingText) {
      setItemState((prevState) => ({
        ...prevState,
        isEditorOpen: false,
        isMaximized: !prevState.isMaximized
      }));
    }
  };

  const creationDate = () => {
    const creationDate = new Date(message.metadata.creationDate);

    const formattedDate = creationDate
      .toLocaleString('es-ES', {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
      .replaceAll(',', '');

    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  useCustomEventListener('email-editor-opened', (messageId: string) => {
    if (message.messageId !== messageId) {
      setItemState((state) => {
        return {
          ...state,
          isEditorOpen: false
        };
      });
    }
  });

  function openEditor(mode: EmailEditorMode) {
    emitCustomEvent('email-editor-opened', message.messageId);
    setItemState((state) => {
      return {
        ...state,
        isEditorOpen: true,
        editorInfo: {
          mode: mode,
          messageToReply: message,
          serializedEditor: null
        }
      };
    });
  }

  return (
    <Stack
      width="100%"
      direction={'row'}
      alignItems={'center'}
      px={1.5}
      py={2}
      sx={{
        '&:hover': {
          background: itemState.isMaximized ? 'inherit' : '#EBEBEB'
        },
        cursor: isMaximized ? 'default' : 'pointer'
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
    >
      <Box
        sx={{
          width: '44px',
          height: '44px',
          minWidth: '44px',
          minHeight: '44px',
          fontSize: '14px',
          background: theme.colors.secondary.main,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          color: 'white'
        }}
      >
        {message.from?.charAt(0).toUpperCase()}
      </Box>
      {/* DERECHA */}
      <Stack
        sx={{ backgrorund: 'red' }}
        justifyContent={'space-between'}
        style={{
          width: 'calc(100% - 40px)'
        }}
        pl={1.5}
      >
        {/* FILA DE ARRIBA*/}
        <Stack
          justifyContent={'space-between'}
          direction="row"
          alignItems={'center'}
          height={'30px'}
        >
          <div style={{ fontWeight: 'bold', paddingBottom: '2px' }}>
            {message.from}
          </div>
          {isMaximized ? (
            <Stack direction="row">
              <EmailHeaderIconWrapper
                title={t('Reply')}
                Icon={Reply}
                onClick={() => openEditor(EmailEditorMode.Reply)}
              />
              <EmailHeaderIconWrapper
                title={t('Reply all')}
                Icon={ReplyAll}
                onClick={() => openEditor(EmailEditorMode.ReplyAll)}
              />
              <EmailHeaderIconWrapper
                title={t('Forward')}
                Icon={Reply}
                style={{
                  transform: 'scaleX(-1)'
                }}
                onClick={() => openEditor(EmailEditorMode.Forward)}
              />
            </Stack>
          ) : (
            <>
              {message.attachedFiles?.some((f) => f.type === 'File') && (
                <AttachFile fontSize="small" />
              )}
            </>
          )}
        </Stack>
        {/* FILA DE ABAJO*/}
        <Stack
          direction="row-reverse"
          justifyContent={'space-between'}
          sx={{
            fontSize: '12px'
          }}
          columnGap={10}
        >
          <Box
            sx={{
              whiteSpace: 'nowrap'
            }}
          >
            {creationDate()}
          </Box>

          {isMaximized && (
            <Stack
              direction="row"
              overflow="hidden"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
            >
              {!isEmailDestinationOpen && (
                <>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: '12px'
                    }}
                    noWrap
                  >
                    {`${t('To')}: ${message.to?.join(', ')}`}
                  </Typography>
                  {message.to?.length > 1 && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEmailDestinationOpen(!isEmailDestinationOpen);
                      }}
                    >
                      <ArrowDropDown fontSize="small" />
                    </IconButton>
                  )}
                </>
              )}
              {isEmailDestinationOpen && (
                <Stack direction="column" width="100%">
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize="12px">{t('Recipients')}:</Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEmailDestinationOpen(!isEmailDestinationOpen);
                      }}
                    >
                      <ArrowDropDown
                        fontSize="small"
                        sx={{ transform: 'rotate(180deg)' }}
                      />
                    </IconButton>
                  </Stack>
                  {message.to?.map((to) => (
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '12px'
                      }}
                      noWrap
                      key={to}
                    >
                      {to}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Stack>
          )}
          {!isMaximized && (
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '12px'
              }}
              noWrap
            >
              {htmlToString(message.message)}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

//e.stopPropagation();

export default EmailItemHeader;
