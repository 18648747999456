import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { BASE_MS, BASE_URL } from '../constants';
import { TicketSubResolution } from '../../models/tickets';
import { TICKET_SUBRESOLUTIONS_ID } from 'src/utils/apiRoutes/msMessaging';

export const updateTicketSubResolutionApi = async (
  ticketSubResolution: TicketSubResolution
): Promise<TicketSubResolution> => {
  const path = replaceURLParams(TICKET_SUBRESOLUTIONS_ID, {
    '{id}': ticketSubResolution.id
  });
  const resp = await axios.put(path, ticketSubResolution);
  return resp.data;
};
