import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { Ticket } from '../../models/tickets';
import { HelperTab } from 'src/modules/Dashboard/views/Inbox/HelperMenu/items';
import { getHelperMenuItems } from 'src/modules/Dashboard/views/Inbox/utils/getInboxPanels';

interface Props {
  ticket?: Ticket;
}

const TicketTitle = (props: Props) => {
  const { ticket } = props;
  const menuItems = getHelperMenuItems();
  const TicketIcon = menuItems?.[HelperTab.TICKETS]?.icon;
  return (
    <Box display="flex" alignItems="end" overflow="hidden">
      <TicketIcon color="secondary" />
      <Typography ml={1.5} variant="h4" textOverflow="ellipsis" noWrap>
        {ticket?.friendlyId
          ? `#${ticket?.friendlyId} - ${ticket?.title}`
          : `${t('New ticket')}`}
      </Typography>
    </Box>
  );
};

export default TicketTitle;
