import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import Intention from './Intention';
import { t } from 'i18next';
import { AddTwoTone } from '@mui/icons-material';
import { AIModel, AIModelLanguage } from '../../../models/AIModels';

export interface AccordionIntention {
  name: string;
  tags: string[];
}

interface Props {
  language: AIModelLanguage;
  currentAiModel: AIModel;
  setCurrentAiModel: Dispatch<SetStateAction<AIModel>>;
}

const IntentionsList = ({
  language,
  currentAiModel,
  setCurrentAiModel
}: Props) => {
  console.log(currentAiModel);
  const initialArray = Object.entries(currentAiModel?.data ?? {}).map(
    ([key, val], index) => {
      return { name: key, tags: [...val] } as AccordionIntention;
    }
  );
  const [intentionsArray, setIntentionsArray] = useState(initialArray);

  useEffect(() => {
    const aiModelData = intentionsArray.reduce((acc, curr) => {
      acc[curr.name] = curr.tags.filter((tag) => tag.length > 0);
      return acc;
    }, {});

    setCurrentAiModel({ ...currentAiModel, data: aiModelData });
  }, [intentionsArray]);

  return (
    <Stack>
      <Stack
        mt={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={2}
      >
        <Typography
          ml={1}
          variant="h4"
          alignContent={'flex-start'}
          width={{ xs: '100%', md: 'auto' }}
        >
          {t('Intentions')}
        </Typography>
        <Stack direction="row" spacing={2} width={{ xs: '100%', md: 'auto' }}>
          <Alert severity="info">
            {t('There is a minimum of 3 intentions and 5 tags per intention.')}
          </Alert>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddTwoTone />}
            onClick={() => {
              setIntentionsArray([
                ...intentionsArray,
                { name: '', tags: [''] }
              ]);
            }}
          >
            {t('Add Intention')}
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2} mt={2}>
        {intentionsArray?.map((item, index) => {
          return (
            <Intention
              key={index}
              intention={item}
              language={language}
              setIntention={(newIntention) => {
                const newIntentionsArray = [...intentionsArray];
                newIntentionsArray[index] = newIntention;
                setIntentionsArray(newIntentionsArray);
              }}
              onDelete={() => {
                const newIntentionsArray = [...intentionsArray];
                newIntentionsArray.splice(index, 1);
                setIntentionsArray(newIntentionsArray);
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default IntentionsList;
