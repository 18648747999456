import {
  Autocomplete,
  Box,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio
} from '@mui/material';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FileDropZone from 'src/components/FileDropZone';
import { acceptedFiles } from 'src/modules/Contacts/views/utils/acceptedFiles';
import { delimiterOptions } from 'src/modules/Contacts/views/utils/delimiterOptions';
import { getCSVHeadersAndDelimiter } from './LinkFieldsWithCSV/utils/getCSVHeaders';
import { UploadCsvParameters } from '../models/models';
import { CountryCodeInput } from 'src/components/CountryCodeInput';
import { PrioritySchedule } from 'src/modules/DialerBot/models/dialerbot';
import PriorityForm from 'src/modules/DialerBot/views/components/Forms/PriorityForm';

interface Props {
  uploadCsvParameters: UploadCsvParameters;
  setUploadCsvParameters: Dispatch<SetStateAction<UploadCsvParameters>>;
  mode?: 'contacts' | 'dialerbot';
}

export const UploadCSVContacts = ({
  uploadCsvParameters,
  setUploadCsvParameters,
  mode = 'contacts'
}: Props) => {
  const { file } = uploadCsvParameters;

  useEffect(() => {
    if (file) {
      getCSVHeadersAndDelimiter(file).then((response) => {
        setUploadCsvParameters((prevParameters) => ({
          ...prevParameters,
          csvDelimiter: response.delimiter
        }));
      });
    }
  }, [file]);

  return (
    <Stack spacing={2} py={1}>
      <FileDropZone
        files={uploadCsvParameters.file ? [uploadCsvParameters.file] : []}
        setFiles={(files) => {
          if (files.length === 0) return;
          setUploadCsvParameters({
            ...uploadCsvParameters,
            file: files[0]
          });
        }}
        accept={acceptedFiles}
      />
      <Stack direction="row" spacing={2}>
        <TextField
          required
          select
          id={`contacts-delimiter-input`}
          name="delimiter"
          label={t('Delimiter character')}
          placeholder={t('For example: “;”')}
          fullWidth
          onChange={(e) =>
            setUploadCsvParameters({
              ...uploadCsvParameters,
              csvDelimiter: e.target.value
            })
          }
          key={uploadCsvParameters.csvDelimiter}
          value={uploadCsvParameters.csvDelimiter}
        >
          {delimiterOptions.map((delimiter, i) => {
            return (
              <MenuItem key={i} value={delimiter.value}>
                {t(delimiter.label)}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
      <CountryCodeInput
        code={uploadCsvParameters.defaultPrefix}
        setCode={(code) =>
          setUploadCsvParameters({
            ...uploadCsvParameters,
            defaultPrefix: code.toString()
          })
        }
        sx={{ width: '100%', height: '53px' }}
        label="Default prefix"
        showNoPrefixOption={false}
      />
      {mode === 'dialerbot' && (
        <>
          <PriorityForm
            showText={true}
            fullWidth={true}
            priority={uploadCsvParameters.priority}
            onChangePriority={(data: PrioritySchedule) => {
              setUploadCsvParameters({
                ...uploadCsvParameters,
                priority: data
              });
            }}
          />
          <RadioGroup
            value={
              uploadCsvParameters.callAll
                ? 'callAll'
                : uploadCsvParameters.callUntilAnswer
                  ? 'callUntilAnswer'
                  : ''
            }
            onChange={(e) => {
              const value = e.target.value;
              setUploadCsvParameters({
                ...uploadCsvParameters,
                callAll: value === 'callAll',
                callUntilAnswer: value === 'callUntilAnswer'
              });
            }}
            sx={{
              pl: 2
            }}
          >
            <FormControlLabel
              value="callAll"
              control={<Radio color="secondary" />}
              label={t('Call All')}
            />
            <FormControlLabel
              value="callUntilAnswer"
              control={<Radio color="secondary" />}
              label={t('Call until answer')}
            />
          </RadioGroup>
        </>
      )}
    </Stack>
  );
};
