import axios, { AxiosError, AxiosResponse } from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import {
  ASTERISK_MS,
  CALLS_CONFERENCE_ADD_AGENT
} from 'src/utils/apiRoutes/msAsterisk';

export const addToConferenceApi = async (
  callId: string,
  extension: string,
  instance: string,
  isInternal: boolean,
  campaignId: string
) => {
  const addToConferencePath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) +
    CALLS_CONFERENCE_ADD_AGENT;

  const path = replaceURLParams(addToConferencePath, { '{id}': callId });
  let params = {
    destExtension: extension,
    isInternal: isInternal,
    campaignId: !isInternal ? campaignId : null
  };

  const response = axios.post(path, null, { params });

  return response;
};
