import { DialTableFilter } from '../models/filters';

/**
 * Returns a new array removing repeated filters (based on the filter fieldName)
 * @param filters
 * @returns filters
 */
export const getUniqueDialTableFilters = <T>(filters: DialTableFilter<T>[]) => {
  const uniqueFilters: DialTableFilter<T>[] = [];
  filters.forEach((filter) => {
    const isUniqueFilter = !uniqueFilters.some(
      (f) => f.fieldName === filter.fieldName
    );
    if (isUniqueFilter) {
      uniqueFilters.push(filter);
    }
  });
  return uniqueFilters;
};
