import { Box, useTheme } from "@mui/material"

interface VolumeBoxProps {
  input: number,
  limit: number
}

const VolumeBox: React.FC<VolumeBoxProps> = (props) => {
  const theme = useTheme()

  return <Box sx={{
    backgroundColor: props.input >= props.limit ? theme.colors.secondary.main : 'lightgray',
    height: '50px',
    width: '4px'
  }} />
}

export default VolumeBox