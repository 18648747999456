import { BASE_URL } from './base';

// ASTERISK MICROSERVICE
export const ASTERISK_MS = `${BASE_URL}/asterisk`;

// CALLS CONTROLLER
const CALLS_CONTROLLER = `${ASTERISK_MS}/calls`;
export const CALLS = CALLS_CONTROLLER;
export const CALLS_LAUNCH = `${CALLS_CONTROLLER}/launch`;
export const CALLS_REALTIME = `/calls/realtime`;
export const CALLS_PARK = `${CALLS_REALTIME}/{id}/park`;
export const CALLS_UNPARK = `${CALLS_REALTIME}/{id}/unpark`;
export const CALLS_HANGUP = `${CALLS_REALTIME}/{id}/hangup`;
export const CALLS_SPY = `${CALLS_REALTIME}/{id}/spy`;
export const CALLS_RECORDING_START = `${CALLS_REALTIME}/{id}/recording/start`;
export const CALLS_RECORDING_STOP = `${CALLS_REALTIME}/{id}/recording/stop`;
export const CALLS_DIRECT_TRANSFER = `${CALLS_REALTIME}/{id}/transfer/direct`;
export const CALLS_ATTENDED_TRANSFER = `${CALLS_REALTIME}/{id}/transfer/attended`;
export const CALLS_ATTENDED_TRANSFER_COMPLETE = `${CALLS_REALTIME}/{id}/transfer/attended/complete`;
export const CALLS_CONFERENCE_ADD_AGENT = `${CALLS_REALTIME}/conference/addMember/{id}`;
export const CALLS_CONFERENCE_REMOVE_AGENT = `${CALLS_REALTIME}/conference/removeMember/{id}`;
