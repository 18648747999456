import {
  dialerbotDownloadReport,
  getDownloadFilters,
  getDownloadFiltersByCampaignByCallResult,
  getDownloadFiltersByScheduleId,
  getDownloadFiltersByScheduleIdByCallResult,
  getDownloadKeys,
  getDownloadPagination,
  downloadReportByAgregation,
  getAggregationObject
} from './dialerbotDownloadReport';
import { getInitialValuesToForm } from './dialerformTasks';
import {
  actionDeleteDialerBot,
  actionDeleteSchedule,
  actionStartDialerBot,
  actionStopDialerBot,
  UploadScheduleDatabase,
  actionUploadContacts,
  actionUpdateSchedulePrority
} from './executeActions';
import {
  getDialerBotProgress,
  getDialerBotsCampaigns,
  getDialerBotSchedules,
  getOneDialerBotsCampaignById
} from './getDialerBotData';
import {
  getCallResultForMultipleFiles,
  getCallResultsCountById,
  removeNullCallResults
} from './getSchedules';
import { createDialerBot, updateDialerBot } from './SaveDialerData';

const DialerBotDomain = {
  getInitialValuesToForm,
  actionStartDialerBot,
  actionStopDialerBot,
  actionDeleteDialerBot,
  getDialerBotsCampaigns,
  getDialerBotProgress,
  getDialerBotSchedules,
  getOneDialerBotsCampaignById,
  createDialerBot,
  updateDialerBot,
  actionDeleteSchedule,
  UploadSchedules: UploadScheduleDatabase,
  dialerbotDownloadReport,
  getCallResultsCountById,
  getCallResultForMultipleFiles,
  removeNullCallResults,
  getDownloadFiltersByScheduleIdByCallResult,
  getDownloadFilters,
  getDownloadPagination,
  getDownloadKeys,
  getDownloadFiltersByScheduleId,
  getDownloadFiltersByCampaignByCallResult,
  downloadReportByAgregation,
  getAggregationObject,
  actionUploadContacts,
  actionUpdateSchedulePrority
};

export default DialerBotDomain;
