import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  ChannelType,
  Conversation,
  UsersConversationCount
} from 'src/models/conversations/conversations';
import {
  ChatMessage,
  MessageOrientation,
  MessageStatus
} from 'src/models/conversations/messages';
// Esto solo es para pruebas (mock de whatsApp)
import {
  getAllConversations,
  getCurrentConversationMessages
} from '../../../services/socialmedia/application/thunks';
import {
  currentTelegramMessage,
  getConversationFiltersFromLocalStorage,
  getExpandedUsersFromLocalStorage
} from './initialConversationsState';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { WhatsAppTemplate } from 'src/services/whatsapp/models/WhatsAppTemplate';
import { dummyConversation } from 'src/mocks/conversation';
import {
  getPinnedConversationIdsFromLocalStorage,
  PINNED_CONVERSATION_IDS_LOCAL_STORAGE_KEY
} from 'src/services/socialmedia/domain/conversation/pinnedConversationsDomain';

export type selectedConversationChannelsFilter = {
  [key in ChannelType]: boolean;
};

export type ExpandedUsers = {
  [userId: string]: boolean;
};

export interface ConversationFilters {
  selectedChannels: selectedConversationChannelsFilter;
  showClosedConversations: boolean;
  showFinishedCalls: boolean;
  showOnlyMyQueues: boolean;
  groupByChannels: boolean;
  showOnlyUsersInMyCampaigns: boolean;
  textFilter: string;
  showUnreadOnly: boolean;
}

interface SocialmediaState {
  conversations: { [id: string]: Conversation };
  conversationFilters: ConversationFilters;
  currentConversationId: string;
  expandedUsers: ExpandedUsers;
  thirdPartyIframes: ThirdPartyIframeData[];
  loadingConversations: 'idle' | 'pending' | 'succeeded' | 'failed';
  currentMessageId: string;
  templateWhatspApp: WhatsAppTemplate;
  currentTelegramMessage: ChatMessage;
  replyingToMessage: ChatMessage;
  isEditingChatMessage: boolean;
  isReplyingToMessage: boolean;
  messageId: string[];
  conversationChannel: string;
  conversationCount: UsersConversationCount;
  selectedService: DaService;
  autoselectNextConversation: boolean;
  pinnedConversationIds: string[];
  multipleSelector: boolean;
  multipleSelectedConversations: Conversation[];
}

export type ThirdPartyType = 'CENTER' | 'HELPER';
export interface ThirdPartyIframeData {
  url: string;
  isVisible: boolean;
  conversationId: string;
  type: ThirdPartyType;
  channel: ChannelType;
}

const initialState: SocialmediaState = {
  conversations: {
    hotline: dummyConversation
  },
  conversationFilters: getConversationFiltersFromLocalStorage(),
  currentConversationId: '',
  expandedUsers: getExpandedUsersFromLocalStorage(),
  thirdPartyIframes: [],
  loadingConversations: 'idle',
  currentMessageId: '',
  templateWhatspApp: null,
  currentTelegramMessage: currentTelegramMessage,
  replyingToMessage: currentTelegramMessage,
  isEditingChatMessage: false,
  isReplyingToMessage: false,
  messageId: [''],
  conversationChannel: '',
  conversationCount: {},
  selectedService: undefined,
  autoselectNextConversation: false,
  pinnedConversationIds: getPinnedConversationIdsFromLocalStorage(),
  multipleSelector: false,
  multipleSelectedConversations: []
};

export const socialmediaSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setSelectedDaService: (state, action: PayloadAction<DaService>) => {
      state.selectedService = action.payload;
    },
    startLoadingConversations: (state) => {
      state.loadingConversations = 'pending';
    },
    addConversations: (state, action: PayloadAction<Conversation[]>) => {
      const conversationsToAdd = action.payload;

      // Add the new conversations to be added to the filtered stored conversations
      conversationsToAdd.forEach((conversation) => {
        state.conversations[conversation.id] = conversation;
      });
      // Make new object to trigger re-render
      state.conversations = {
        ...state.conversations
      };

      state.loadingConversations = 'succeeded';
    },
    setConversations: (state, action: PayloadAction<Conversation[]>) => {
      state.conversations = {};
      action.payload.forEach((conversation) => {
        state.conversations[conversation.id] = conversation;
      });

      state.loadingConversations = 'succeeded';
    },
    setConversation: (state, action: PayloadAction<Conversation>) => {
      const arrayMessageId = [];
      state.messageId = [];

      state.conversations[action.payload.id] = action.payload;
      state.conversations = { ...state.conversations };

      // Add messagesid for all email items
      if (
        action.payload.channel === 'Email' &&
        action.payload.messages?.length > 0
      ) {
        action.payload.messages?.map((msg) =>
          arrayMessageId.push(msg.messageId)
        );

        state.messageId = arrayMessageId;
      }

      state.loadingConversations = 'succeeded';
    },
    resetConversations: (state) => {
      state.conversations = {};
    },
    setThirdPartyPanel: (
      state,
      action: PayloadAction<ThirdPartyIframeData>
    ) => {
      //  If the panel DOES exist, DELETE IT
      state.thirdPartyIframes = state.thirdPartyIframes.filter(
        (panel) => panel.conversationId !== action.payload.conversationId
      );

      // Add panel to state
      state.thirdPartyIframes = [...state.thirdPartyIframes, action.payload];
    },
    removeThirdParyPanelByConversationId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.thirdPartyIframes = state.thirdPartyIframes.filter(
        (panel) => panel.conversationId !== action.payload
      );
    },
    setThirdPartyPanels: (
      state,
      action: PayloadAction<ThirdPartyIframeData[]>
    ) => {
      state.thirdPartyIframes = action.payload;
    },
    setCurrentConversation: (state, action: PayloadAction<Conversation>) => {
      if (!action.payload) {
        state.currentConversationId = undefined;
        return;
      }
      state.currentConversationId = action.payload.id;
      state.conversationChannel = action.payload.channel;
    },
    markConversationAsRead: (state, action: PayloadAction<Conversation>) => {
      const { payload } = action;

      let conversationToBeRead = { ...state.conversations[payload.id] };
      if (!conversationToBeRead) return;
      if (
        conversationToBeRead.lastMessage?.orientation !==
        MessageOrientation.INCOMING
      )
        return;

      conversationToBeRead.lastMessage.status = MessageStatus.READ;
      conversationToBeRead.unreadIncomingMessages = false;
      const readMessages = [...conversationToBeRead.messages].map((msg) => {
        if (msg.orientation === MessageOrientation.INCOMING) {
          return {
            ...msg,
            status: MessageStatus.READ
          };
        }
        return msg;
      });
      state.conversations = {
        ...state.conversations,
        [payload.id]: {
          ...conversationToBeRead,
          messages: readMessages
        }
      };
    },

    setCurrentMessageId: (state, action: PayloadAction<string>) => {
      state.currentMessageId = action.payload;
    },
    setWhatspAppTemplate: (state, action: PayloadAction<WhatsAppTemplate>) => {
      state.templateWhatspApp = action.payload;
    },

    setCurrentTelegramMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.currentTelegramMessage = action.payload;
    },
    setReplyingToMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.replyingToMessage = action.payload;
    },
    setIsEditingChatMessage: (state, action: PayloadAction<boolean>) => {
      state.isEditingChatMessage = action.payload;
    },
    setIsReplyingToMessage: (state, action: PayloadAction<boolean>) => {
      state.isReplyingToMessage = action.payload;
    },
    setConversationFilters: (
      state,
      action: PayloadAction<ConversationFilters>
    ) => {
      state.conversationFilters = action.payload;
    },
    setMultipleSelector: (state, actions: PayloadAction<boolean>) => {
      state.multipleSelector = actions.payload;
    },
    setMultipleSelectedConversations: (
      state,
      action: PayloadAction<Conversation[]>
    ) => {
      state.multipleSelectedConversations = action.payload;
    },
    addMultipleSelectedConversation: (
      state,
      action: PayloadAction<Conversation>
    ) => {
      state.multipleSelectedConversations = [
        ...state.multipleSelectedConversations,
        action.payload
      ];
    },
    toggleSelectedChannel: (state, action: PayloadAction<ChannelType>) => {
      state.conversationFilters = {
        ...state.conversationFilters,
        selectedChannels: {
          ...state.conversationFilters.selectedChannels,
          [action.payload]:
            !state.conversationFilters.selectedChannels[action.payload]
        }
      };
    },
    toggleShowClosedConversations: (state) => {
      state.conversationFilters = {
        ...state.conversationFilters,
        showClosedConversations:
          !state.conversationFilters.showClosedConversations
      };
    },
    toggleShowFinishedCalls: (state) => {
      state.conversationFilters = {
        ...state.conversationFilters,
        showFinishedCalls: !state.conversationFilters.showFinishedCalls
      };
    },
    toggleShowOnlyMyQueues: (state) => {
      state.conversationFilters = {
        ...state.conversationFilters,
        showOnlyMyQueues: !state.conversationFilters.showOnlyMyQueues
      };
    },
    toggleShowOnlyUsersInMyCampaigns: (state) => {
      state.conversationFilters = {
        ...state.conversationFilters,
        showOnlyUsersInMyCampaigns:
          !state.conversationFilters.showOnlyUsersInMyCampaigns
      };
    },
    toggleGroupByChannels: (state) => {
      state.conversationFilters = {
        ...state.conversationFilters,
        groupByChannels: !state.conversationFilters.groupByChannels
      };
    },
    toggleShowOnlyUnreadConversations: (state) => {
      state.conversationFilters = {
        ...state.conversationFilters,
        showUnreadOnly: !state.conversationFilters.showUnreadOnly
      };
    },
    setExpandedUsers: (state, action: PayloadAction<ExpandedUsers>) => {
      state.expandedUsers = action.payload;
    },
    setConversationCount: (
      state,
      action: PayloadAction<UsersConversationCount>
    ) => {
      state.conversationCount = action.payload;
    },
    setAutoselectNextConversation: (state, action: PayloadAction<boolean>) => {
      state.autoselectNextConversation = action.payload;
    },
    togglePinnedConversationId: (state, action: PayloadAction<string>) => {
      const isPinned = state.pinnedConversationIds.includes(action.payload);
      if (isPinned) {
        state.pinnedConversationIds = state.pinnedConversationIds.filter(
          (id) => id !== action.payload
        );
      } else {
        state.pinnedConversationIds = [
          ...state.pinnedConversationIds,
          action.payload
        ];
      }
      localStorage.setItem(
        PINNED_CONVERSATION_IDS_LOCAL_STORAGE_KEY,
        JSON.stringify(state.pinnedConversationIds)
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllConversations.pending, (state) => {
      state.loadingConversations = 'pending';
    });
    builder.addCase(getAllConversations.fulfilled, (state, { payload }) => {
      const conversationsToAdd = payload.elements;
      // Add the new conversations to be added to the filtered stored conversations
      conversationsToAdd.forEach((conversation) => {
        state.conversations[conversation.id] = conversation;
      });
      // Make new object to trigger re-render
      state.conversations = {
        ...state.conversations
      };
      state.loadingConversations = 'succeeded';
    });
    builder.addCase(getAllConversations.rejected, (state) => {
      state.loadingConversations = 'failed';
    });
    builder.addCase(
      getCurrentConversationMessages.fulfilled,
      (state, { payload }) => {
        const conv = state.conversations[state.currentConversationId];
        state.conversations[state.currentConversationId] = {
          ...conv,
          messages: payload
        };

        state.conversations = { ...state.conversations };
      }
    );
  }
});

export const {
  resetConversations,
  addConversations,
  markConversationAsRead,
  setConversation,
  setConversationFilters,
  setConversations,
  setCurrentConversation,
  setCurrentTelegramMessage,
  setReplyingToMessage,
  setIsEditingChatMessage,
  setIsReplyingToMessage,
  setCurrentMessageId,
  setThirdPartyPanel,
  setThirdPartyPanels,
  setWhatspAppTemplate,
  startLoadingConversations,
  toggleGroupByChannels,
  toggleSelectedChannel,
  toggleShowClosedConversations,
  toggleShowFinishedCalls,
  toggleShowOnlyMyQueues,
  toggleShowOnlyUsersInMyCampaigns,
  setExpandedUsers,
  setConversationCount,
  toggleShowOnlyUnreadConversations,
  setSelectedDaService,
  setAutoselectNextConversation,
  removeThirdParyPanelByConversationId,
  setMultipleSelectedConversations,
  setMultipleSelector,
  togglePinnedConversationId,
  addMultipleSelectedConversation
} = socialmediaSlice.actions;

export default socialmediaSlice.reducer;
