import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ContactGroupsSelector } from '../../../../contactGroups/selector';
import {
  Box,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  getContactFieldsQuery,
  useGetContactFields
} from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { getCSVHeadersAndDelimiter } from './utils/getCSVHeaders';
import { DraggableColumn, DraggableItem } from './components/DraggableColumn';
import { DragHandle } from '@mui/icons-material';
import { ContactField } from 'src/modules/Contacts/models/contacts';
import { SearchItemPopover } from './components/SearchFieldPopover.tsx';
import { t } from 'i18next';
import useUpdate from 'src/hooks/useUpdateEffect';
import { UploadCsvParameters } from '../../models/models';
import { ContactFieldFormPopover } from '../../../../contactFields/ContactFieldFormPopover';
import AuthManager from 'src/services/authentication/manager';

interface Props {
  uploadCsvParameters: UploadCsvParameters;
  setUploadCsvParameters: Dispatch<SetStateAction<UploadCsvParameters>>;
  mode: 'dialerbot' | 'contacts';
}

function isFieldRequired(field: ContactField) {
  return field.required && !field.unique;
}

function isFieldUnique(field: ContactField) {
  return field.unique;
}

function objectToDraggable<T>(object: T[], nameKey?: keyof T) {
  return object.map((field, index) => {
    return {
      id: index,
      name: nameKey ? field[nameKey] : (field as unknown as string),
      value: field
    } as DraggableItem<T>;
  });
}

export const LinkContactFieldsWithCSV = ({
  uploadCsvParameters,
  setUploadCsvParameters,
  mode
}: Props) => {
  const { file, csvDelimiter, contactGroupId, fieldMapping } =
    uploadCsvParameters;
  const companyId = AuthManager.getLoggedUserCompanyId();

  const defaultFieldMapping = useMemo(() => {
    return uploadCsvParameters.fieldMapping;
  }, []);

  const contactFieldsQuery = useGetContactFields({});

  // Contact fields
  const [allContactFields, setAllContactFields] = useState<
    DraggableItem<ContactField>[]
  >([]);

  const [selectedContactFields, setSelectedContactFields] = useState<
    DraggableItem<ContactField>[]
  >([]);

  // CSV columns
  const [allColumNames, setAllColumnNames] = useState<DraggableItem<string>[]>(
    []
  );

  const [selectedColumns, setSelectedColumns] = useState<
    DraggableItem<string>[]
  >([]);

  // Only run once
  useEffect(() => {
    // If there is an existent fieldMapping, fill the form with it.
    if (Object.keys(defaultFieldMapping).length && contactGroupId) {
      getContactFieldsQuery({ companyId, contactGroupId }).then((response) => {
        // Get selected CSV columns
        const columns = Object.keys(defaultFieldMapping);
        const draggableColumns = objectToDraggable(columns);
        setSelectedColumns(draggableColumns);

        // Get all contact fields
        const fields = response?.elements;
        const allDraggableFields = objectToDraggable(fields, 'name');
        setAllContactFields(allDraggableFields);

        // Get selected contact fields
        const selectedFieldIds = Object.values(defaultFieldMapping); // Field mapping -> [colName]: fieldName
        const selectedDraggableFields = allDraggableFields.filter((f) =>
          selectedFieldIds.includes(f.value.name)
        );
        setSelectedContactFields(selectedDraggableFields);
      });
    }
  }, []);

  // Does not run on first render, when contactGroup changes all fields are displayed
  useUpdate(() => {
    if (contactGroupId) {
      getContactFieldsQuery({ companyId, contactGroupId }).then((resp) => {
        const fields = resp?.elements;
        const draggableFields = objectToDraggable(fields, 'name');

        setAllContactFields(draggableFields);

        setSelectedContactFields(draggableFields);
      });
    }
  }, [contactGroupId]);

  //For dialerbot mode
  useEffect(() => {
    if (uploadCsvParameters.campaignId && uploadCsvParameters.contactGroupId) {
      getContactFieldsQuery({ companyId, contactGroupId }).then((resp) => {
        const fields = resp?.elements;
        const draggableFields = objectToDraggable(fields, 'name');

        setAllContactFields(draggableFields);

        setSelectedContactFields(draggableFields);
      });
    }
  }, []);

  //When the contact fields are updated (for example, when a new field is created, is added to the selected fields)
  useUpdate(() => {
    if (contactGroupId) {
      const notSelectedFields = allContactFields.filter((f) => {
        return !selectedContactFields.find((c) => c.value.id === f.value.id);
      });
      getContactFieldsQuery({ companyId, contactGroupId }).then((resp) => {
        const fields = resp?.elements;
        // Remove fields that are not selected
        const draggableFields = objectToDraggable(fields, 'name');
        const filteredFields = draggableFields.filter(
          (f) =>
            !notSelectedFields.some(
              (notSelectedField) => notSelectedField.value.id === f.value.id
            )
        );

        setAllContactFields(draggableFields);

        setSelectedContactFields(filteredFields);
      });
    }
  }, [contactFieldsQuery?.dataUpdatedAt]);

  // Get all column names from CSV file
  useEffect(() => {
    if (!file || !csvDelimiter) return;

    getCSVHeadersAndDelimiter(file, csvDelimiter)
      .then((response) => {
        const result = response.headers.map((name, index) => {
          return {
            name,
            id: index,
            value: name
          } as DraggableItem<string>;
        });
        setAllColumnNames(result);
        if (!Object.keys(fieldMapping).length) setSelectedColumns(result);
      })
      .catch(() => {
        setAllColumnNames([]);
        setSelectedColumns([]);
      });
  }, []);

  useUpdate(() => {
    const fieldMapping = selectedColumns.reduce((acc, column, index) => {
      const columnName = column.name;
      const fieldName = selectedContactFields[index]?.value.name;

      if (!columnName || !fieldName) return acc;

      return {
        ...acc,
        [columnName]: fieldName
      };
    }, {});

    setUploadCsvParameters({
      ...uploadCsvParameters,
      fieldMapping
    });
  }, [selectedContactFields, selectedColumns]);

  return (
    <Stack py={1} height={'100%'}>
      {mode !== 'dialerbot' && (
        <ContactGroupsSelector
          contactGroupId={uploadCsvParameters.contactGroupId}
          setContactGroupId={(contactGroupId) => {
            setUploadCsvParameters({
              ...uploadCsvParameters,
              contactGroupId
            });
          }}
          companyId={companyId}
        />
      )}
      <Stack width={'100%'} direction="column" marginTop={2} spacing={2}>
        <Stack direction={'row'} spacing={2}>
          <SearchItemPopover
            fields={allContactFields.filter((f) => {
              return !selectedContactFields.find(
                (c) => c.value.id === f.value.id
              );
            })}
            onAdd={(field) => {
              setSelectedContactFields([...selectedContactFields, field]);
            }}
            title={t('Add field')}
            descriptionKey="type"
          />
          <ContactFieldFormPopover
            contactGroupId={uploadCsvParameters.contactGroupId}
          />
        </Stack>
        <Stack>
          <SearchItemPopover
            fields={allColumNames.filter((c) => {
              return !selectedColumns.find((col) => col.value === c.value);
            })}
            onAdd={(col) => {
              setSelectedColumns([...selectedColumns, col]);
            }}
            title={t('Add CSV column')}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          marginTop: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '8px 8px 0 0',
          backgroundColor: '#F6F8FB',
          padding: '10px 0',
          border: '1px solid #E0E0E0'
        }}
      >
        <Box
          flexGrow={1}
          sx={{
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          {t('Contact field')}
        </Box>
        <Box
          flexGrow={1}
          sx={{
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          {t('CSV Column')}
        </Box>
      </Box>
      <Stack
        direction={'row'}
        width={'100%'}
        sx={{
          borderLeft: '1px solid #E0E0E0',
          borderRight: '1px solid #E0E0E0',
          borderBottom: '1px solid #E0E0E0',
          borderRadius: '0 0 8px 8px'
        }}
      >
        <Box sx={{ borderRight: '1px solid #E0E0E0', width: '50%' }}>
          <DraggableColumn<ContactField>
            droppableId="fieldNames"
            setRowItems={setSelectedContactFields}
            rowItems={selectedContactFields}
            hideDeleteIcon={(f) =>
              !f.value.autoGenerated && (f.value.unique || f.value.required)
            }
            renderItem={(field) => {
              return (
                <Tooltip
                  arrow
                  disableHoverListener={
                    !isFieldRequired(field.value) && !isFieldUnique(field.value)
                  }
                  title={
                    <>
                      {isFieldRequired(field.value) && t('Required')}
                      {isFieldUnique(field.value) && t('Unique')}
                    </>
                  }
                  placement="left"
                >
                  <Stack direction="row" alignItems={'center'}>
                    <DragHandle />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: isFieldUnique(field.value)
                          ? 'secondary.main'
                          : 'inherit'
                      }}
                    >
                      {field.name}
                      {isFieldRequired(field.value) && `*`}
                    </Typography>
                  </Stack>
                </Tooltip>
              );
            }}
          />
        </Box>
        <Box flexGrow={1} sx={{ width: '50%' }}>
          <DraggableColumn
            droppableId="csvColumns"
            setRowItems={setSelectedColumns}
            rowItems={selectedColumns}
          />
        </Box>
      </Stack>
      <Stack py={2} px={1} direction="column">
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '16px',
              textAlign: 'center'
            }}
          >
            {`(*)`}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px'
            }}
          >
            {`${t('Required')}`}
          </Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Box
            sx={{
              height: '16px',
              width: '16px',
              borderRadius: '6px',
              backgroundColor: 'secondary.main'
            }}
          ></Box>
          <Typography
            sx={{
              fontSize: '14px'
            }}
          >
            {t('Unique')}
          </Typography>
        </Stack>
      </Stack>
      <TextField
        value={uploadCsvParameters.identificationFieldId}
        onChange={(e) => {
          setUploadCsvParameters({
            ...uploadCsvParameters,
            identificationFieldId: e.target.value
          });
        }}
        label={t('Identification field')}
        select
        helperText={t('The identification field must be unique')}
      >
        {selectedContactFields
          .filter((f) => f.value.unique || f.value.autoGenerated)
          .map((field, i) => {
            return (
              <MenuItem key={i} value={field.value.id}>
                {field.name}
              </MenuItem>
            );
          })}
      </TextField>
    </Stack>
  );
};
