import {
  getPermissionsByUserIdApi,
  getPermissionsStructureApi
} from '../infrastructure/getGroupsApi';
import { UserPermissions } from 'src/models/permissionGroups';

export const getPermissionsByUserId = async (
  userId: string
): Promise<UserPermissions> => {
  const userPermissions = await getPermissionsByUserIdApi(userId);

  return userPermissions;
};

export const getPermissionsStructure = async () => {
  const structure = await getPermissionsStructureApi();
  return structure;
};
