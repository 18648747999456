import axios from 'axios';
import { TELEGRAM_MESSAGE_READ } from 'src/utils/apiRoutes/msTelegram';

export interface ReadMessagesApiParams {
  accountId: string;
  conversationId?: string;
  messageIds: string[];
}

export const readTelegramMessageApi = async (params: ReadMessagesApiParams) => {
  const { messageIds, ...restParams } = params;

  const response = await axios.post(TELEGRAM_MESSAGE_READ, messageIds, {
    params: restParams
  });

  if (response.status !== 200) {
    throw new Error('Error reading messages');
  }

  return response.data;
};
