import axios from 'axios';

import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import { ASTERISK_MS, CALLS_HANGUP } from 'src/utils/apiRoutes/msAsterisk';

export const hangupCallApi: Function = (callId: string, instance: string) => {
  const hangupCallPath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) + CALLS_HANGUP;

  const path = replaceURLParams(hangupCallPath, { '{id}': callId });

  return axios
    .post(path)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
