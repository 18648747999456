import { setVoiceDevice } from 'src/store/slices/users/configVolumeSlice';
import store from 'src/store/store';

/** SET VOICE DEVICE IN STORE
 *
 * @param device New microphone
 */
export const setMicDeviceStore = (device: MediaDeviceInfo) => {
  store.dispatch(setVoiceDevice(device));
};
