import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  styled
} from '@mui/material';
import { useContext, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { createContact } from 'src/modules/Contacts/domain/createContacts';
import ContactDataForm from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel/ContactDataForm';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import CustomTabs from 'src/components/CustomTabs';
import UploadCSVContactsForm from './UploadCSVContacts/UploadCSVContactsForm';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreateContactGroupPermissions,
  checkCreateContactPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import AuthManager from 'src/services/authentication/manager';
import { CloseTwoTone } from '@mui/icons-material';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

const TabsContainerWrapper = styled(CardContent)(
  ({ theme }) => `
        background-color: ${theme.colors.alpha.black[5]};
  `
);

interface Props {
  onClose: () => void;
  contactGroupId?: string;
  disableContactGroupSelector?: boolean;
}

const CreateContactsPopoverForm = (props: Props) => {
  const { onClose, disableContactGroupSelector = false } = props;
  const { hasAccess } = useContext(PermissionsContext);
  const hasCreateContactAccess = hasAccess(checkCreateContactPermissions);
  const hasCreateContactGroupAccess = hasAccess(
    checkCreateContactGroupPermissions
  );
  // The user is supposed to have permissions to access to add a new contact or to upload a csv
  const [currentTab, setCurrentTab] = useState<'addContact' | 'uploadContacts'>(
    hasCreateContactAccess ? 'addContact' : 'uploadContacts'
  );
  const { t }: { t: any } = useTranslation();
  const title = t('Create new contact');
  const subheader = t('Use this dialog window to add new contacts');

  const companyId = AuthManager.getLoggedUserCompanyId();
  const companyFieldsQuery = useGetContactFields(
    {
      companyId: companyId
    },
    true
  );

  const isLoading = companyFieldsQuery.isLoading;

  const onSubmitContact = async (contact) => {
    const newContact = await createContact(contact);
    emitCustomEvent('table-data-refetch');
    return newContact;
  };

  if (isLoading)
    return (
      <Box
        sx={{
          width: '550px',
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />;
      </Box>
    );

  return (
    <Card sx={{ height: '85vh', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <IconButtonWrapper
            sx={{
              m: 1
            }}
            size="small"
            color="secondary"
            onClick={() => onClose()}
          >
            <CloseTwoTone />
          </IconButtonWrapper>
        }
      />
      <Divider />
      <TabsContainerWrapper>
        <CustomTabs
          tabs={[
            {
              label: t('Add contact'),
              value: 'addContact',
              disabled: !hasCreateContactAccess
            },
            {
              label: t('Upload contacts CSV'),
              value: 'uploadContacts',
              disabled: !hasCreateContactGroupAccess
            }
          ]}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </TabsContainerWrapper>

      <CardContent
        sx={{
          height: '100%',
          p: 0,
          '&.MuiCardContent-root': {
            paddingBottom: 0
          },
          overflow: 'hidden'
        }}
      >
        <Box height="100%">
          {currentTab === 'addContact' && (
            <ContactDataForm
              onClose={onClose}
              onSubmitContact={onSubmitContact}
              isCreatingContact
              defaultContactGroupId={props.contactGroupId}
              disableContactGroupSelector={disableContactGroupSelector}
            />
          )}
          {currentTab === 'uploadContacts' && (
            <UploadCSVContactsForm onClose={onClose} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CreateContactsPopoverForm;
