import { Contact } from 'src/modules/Contacts/models/contacts';
import { Box, useTheme } from '@mui/material';
import { Conversation } from 'src/models/conversations/conversations';
import HelperPanelHeader from '../components/HelperPanelHeader';
import NoteListView from '../NotesPanel/NoteListView';
import { ConversationList } from 'src/components/ConversationHistory/components/ConversationList';
import { HelperTab } from '../../HelperMenu/items';
import ContactInfoTicketsPanel from '../TicketsPanel/ContactInfoTicketsPanel';
import { ReactNode } from 'react';

interface Props {
  showConversationHistory: boolean;
  selectedContact: Contact;
  conversation: Conversation;
  children: ReactNode;
  showHelperTabButtons: boolean;
}

const ContactViewPanels = ({
  children,
  showConversationHistory,
  selectedContact,
  conversation,
  showHelperTabButtons
}: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        gap: 6
      }}
    >
      <Box
        sx={{
          flex: 1,
          minWidth: '300px',
          height: showConversationHistory
            ? `calc(100% - ${theme.header.height})`
            : '100%'
        }}
      >
        <HelperPanelHeader
          helperTab={HelperTab.CONTACT_DATA}
          showHelperTabButtons={showHelperTabButtons}
        />
        {children}
      </Box>

      {showConversationHistory && (
        <>
          <Box
            sx={{
              flex: 1,
              minWidth: '300px',
              minHeight: `min( 100% , max-content )`
            }}
          >
            <HelperPanelHeader helperTab={HelperTab.CONVERSATION_HISTORY} />
            <Box sx={{ flex: 1 }}>
              <ConversationList
                itemsPerPage={5}
                selectedContact={selectedContact}
              />
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              minWidth: '300px',
              minHeight: '300px'
            }}
          >
            <HelperPanelHeader helperTab={HelperTab.NOTES} />
            <Box sx={{ flex: 1 }}>
              <NoteListView
                key={`${selectedContact?.id}-notes`}
                contact={selectedContact}
                filterNote="contactsNotes"
              />
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              minWidth: '300px',
              minHeight: '600px'
            }}
          >
            <ContactInfoTicketsPanel conversation={conversation} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ContactViewPanels;
