import React from 'react';
import {
  Box,
  Modal,
  Divider,
  CardHeader,
  styled,
  IconButton,
  Card
} from '@mui/material';
import { t } from 'i18next';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
  WhatsAppTemplate,
  WhatsAppTemplateVariables
} from 'src/services/whatsapp/models/WhatsAppTemplate';
import PreviewMessage from 'src/components/PreviewMessage';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
    `
);

interface TemplatePreviewProps {
  open: boolean;
  handleCloseModal: () => void;
  template: WhatsAppTemplate;
  templateParams: WhatsAppTemplateVariables;
}

const templateParamsToVariableValue = (params: WhatsAppTemplateVariables) => {
  const variableValueArray = [];

  Object.entries(params).map(([varNum, value]) =>
    variableValueArray.push({ variable: `{{${varNum}}}`, value })
  );

  return variableValueArray;
};

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  open,
  handleCloseModal,
  template,
  templateParams
}) => {
  const body = template.content.replace(/\$(\d+)\$/gm, '{{$1}}');
  const hasValues = Object.keys(templateParams).length > 0;
  const variableValues = hasValues
    ? templateParamsToVariableValue(templateParams)
    : templateParamsToVariableValue(template.exampleVariableValues);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '45%',
          maxWidth: '600px'
        }}
      >
        <CardHeader
          title={t('Preview')}
          subheader={hasValues ? '' : t('Showing default variable values.')}
          titleTypographyProps={{
            component: 'h1',
            variant: 'h1'
          }}
          action={
            <IconButtonWrapper
              sx={{
                m: 2
              }}
              size="small"
              color="secondary"
              onClick={handleCloseModal}
            >
              <CloseTwoToneIcon />
            </IconButtonWrapper>
          }
        />
        <Divider />
        <Box p={3}>
          <PreviewMessage
            title="WhatsApp with"
            subtitle="Contact"
            body={body}
            variablesBody={variableValues}
            icon={<WhatsAppIcon sx={{ color: '#44D600' }} />}
            buttons={template.buttons}
          />
        </Box>
        <Divider />
      </Card>
    </Modal>
  );
};

export default TemplatePreview;
