import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface AnalyzedRecordingsContextProps {
  openAnalyzedRecordingsForm: boolean;
  setOpenAnalyzedRecordingsForm: Dispatch<SetStateAction<boolean>>;
}

export const AnalyzedRecordingsContext =
  createContext<AnalyzedRecordingsContextProps>(
    {} as AnalyzedRecordingsContextProps
  );

export const AnalyzedRecordingsProvider: React.FC = ({ children }) => {
  const [openAnalyzedRecordingsForm, setOpenAnalyzedRecordingsForm] =
    useState<boolean>(false);

  return (
    <AnalyzedRecordingsContext.Provider
      value={{
        openAnalyzedRecordingsForm,
        setOpenAnalyzedRecordingsForm
      }}
    >
      {children}
    </AnalyzedRecordingsContext.Provider>
  );
};
