import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { PaginatedElements } from 'src/models/pagination';
import { WEBCHAT_STYLE_ID } from 'src/utils/apiRoutes/msWebChat';
import { WebChatStyle } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatStyle';

/*DELETE webChatStyle*/
export const deleteWebChatStyle = async (webChatStyle: WebChatStyle) => {
  const path = replaceURLParams(WEBCHAT_STYLE_ID, {
    '{id}': webChatStyle.id
  });
  const response = await axios.delete<PaginatedElements<WebChatStyle>>(path);

  return response.data;
};
