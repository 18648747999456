import { FileCopy, Verified } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import { useState } from 'react';

interface Props {
  roomId: string;
}

// TODO: ¿Lanzar toast verde para quitar logica y estados de este componente?
function CopyButton({ roomId }: Props) {
  const theme = useTheme();
  const [copied, setCopied] = useState<boolean>(false);

  const videocallUrl = () => {
    return window.origin + `/videocalls?roomId=${roomId}`;
  };

  const handleCopyUrl = () => {
    if (copied) return;
    const url = videocallUrl();
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  return (
    <button
      style={{
        background: copied
          ? theme.colors.success.main
          : theme.colors.secondary.main,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 43,
        width: 150,
        borderRadius: 8,
        gap: 8,
        border: 'none'
      }}
      onClick={handleCopyUrl}
    >
      {copied ? <Verified /> : <FileCopy />}
      <Typography fontWeight={'bold'}>
        {copied ? 'Copiada' : 'Copiar URL'}
      </Typography>
    </button>
  );
}

export default CopyButton;
