import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useContext } from 'react';
import { VideocallRoomContext } from '../../../context/VideocallRoomContext';
import { t } from 'i18next';
import { ScreenShare, StopScreenShare } from '@mui/icons-material';
import { useScreenShare } from '../../../hooks/useScreenShare';

export const ShareScreenSelect = () => {
  const { isScreenSharing, toggleScreenShare } = useScreenShare();

  return (
    <Tooltip
      title={isScreenSharing ? t('Stop sharing screen') : t('Share screen')}
    >
      <IconButton
        style={{
          background: 'rgba(0,0,0,0.05)',
          borderRadius: '8px'
        }}
        onClick={toggleScreenShare}
      >
        {isScreenSharing ? <StopScreenShare /> : <ScreenShare />}
      </IconButton>
    </Tooltip>
  );
};
