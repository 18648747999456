import { Box, Button, Grid, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import { Contact } from 'src/modules/Contacts/models/contacts';
import ContactDataView from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel/ContactDataView';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditContactDataView from './EditContactDataView';
import NoteListView from 'src/modules/Dashboard/views/Inbox/HelperPanel/NotesPanel/NoteListView';
import { ConversationList } from 'src/components/ConversationHistory/components/ConversationList';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkUpdateContactPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import HelperPanelHeader from 'src/modules/Dashboard/views/Inbox/HelperPanel/components/HelperPanelHeader';
import { HelperTab } from 'src/modules/Dashboard/views/Inbox/HelperMenu/items';
import getMockConversation from 'src/components/PowerDialerNotification/mockConversation';
import ContactInfoTicketsPanel from 'src/modules/Dashboard/views/Inbox/HelperPanel/TicketsPanel/ContactInfoTicketsPanel';

interface Props {
  contact: Contact;
  setContact: Dispatch<SetStateAction<Contact>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

const ContactDataHistoryNoteInfo = (props: Props) => {
  const { contact, setContact, isEditing, setIsEditing, onClose } = props;
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();

  const { hasAccess } = useContext(PermissionsContext);
  const hasEditContactAccess = hasAccess(checkUpdateContactPermissions);

  const conversationMock = getMockConversation({
    userId: '',
    campaignId: '',
    serviceId: '',
    companyId: '',
    contactId: contact.id,
    phoneNumber: '',
    timestamp: ''
  });

  return (
    <Grid
      container
      columns={{ sm: 1, md: 3.5, lg: 7 }}
      sx={{
        height: '100%',
        width: '100%'
      }}
    >
      <Grid item xs={2} sx={{ height: '100%' }}>
        <HelperPanelHeader helperTab={HelperTab.CONTACT_DATA} />
        {hasEditContactAccess && (
          <>
            {isEditing ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="start"
                pl={2}
                height="41px"
              >
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  startIcon={<ArrowBackIosNewIcon />}
                  sx={{ marginTop: theme.spacing(0.75) }}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                  id="btnBack-ContactDataView"
                >
                  {t('Back')}
                </Button>
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                pr={2}
                height="41px"
              >
                <Button
                  color="secondary"
                  variant="text"
                  size="small"
                  disabled={!hasEditContactAccess}
                  startIcon={<EditTwoToneIcon />}
                  sx={{ marginTop: theme.spacing(0.75) }}
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  id="btnEdit-ContactDataView"
                >
                  {t('Edit')}
                </Button>
              </Box>
            )}
          </>
        )}
        <Box height={`calc(100% - ${theme.header.height} - 41px)`}>
          <Scrollbar>
            {isEditing ? (
              <EditContactDataView
                key={contact?.id + 'edit'}
                contact={contact}
                setContact={setContact}
                onCloseEditContact={() => {
                  setIsEditing(false);
                }}
                onCloseModal={() => {
                  onClose();
                }}
              />
            ) : (
              <ContactDataView key={contact?.id + '-data'} contact={contact} />
            )}
          </Scrollbar>
        </Box>
      </Grid>
      <Grid item xs={1.5} sx={{ height: '100%' }}>
        <HelperPanelHeader helperTab={HelperTab.CONVERSATION_HISTORY} />
        <Box height={`calc(100% - ${theme.header.height})`}>
          <Scrollbar>
            <ConversationList itemsPerPage={5} selectedContact={contact} />
          </Scrollbar>
        </Box>
      </Grid>
      <Grid item xs={1.5} sx={{ height: '100%' }}>
        <HelperPanelHeader helperTab={HelperTab.NOTES} />
        <Box height={`calc(100% - ${theme.header.height})`}>
          <NoteListView
            key={contact?.id + '-notes'}
            contact={contact}
            filterNote="contactsNotes"
          />
        </Box>
      </Grid>
      <Grid item xs={2} sx={{ height: '100%' }}>
        <Box height={`100%`}>
          <ContactInfoTicketsPanel
            key={contact?.id + '-tickets'}
            conversation={conversationMock}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactDataHistoryNoteInfo;
