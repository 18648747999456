import {
  $applyNodeReplacement,
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread
} from 'lexical';
import { EmailMessage } from 'src/models/conversations/messages';
import { ParsedInlineMessage } from 'src/modules/Shared/utils/attachments';

export type SerializedSignatureNode = Spread<
  {
    content: string;
  },
  SerializedLexicalNode
>;

interface SignaturePayload {
  content: string;
  key?: NodeKey;
}

export class SignatureNode extends DecoratorNode<JSX.Element> {
  content: string;

  static getType(): string {
    return 'SignatureNode';
  }

  static clone(node: SignatureNode): SignatureNode {
    return new SignatureNode(node.content, node.__key);
  }

  constructor(content: string, key?: NodeKey) {
    super(key);
    this.content = content;
  }

  init(): void {}

  createDOM(): HTMLElement {
    const div = document.createElement('div');
    return div;
  }

  updateDOM(prevNode: SignatureNode, dom: HTMLElement): boolean {
    return false;
  }

  exportDOM(_): DOMExportOutput {
    const container = document.createElement('div');

    container.innerHTML = '$SIGNATURE_NODE$';

    return { element: container };
  }

  static importJSON(serializedNode: SerializedSignatureNode): SignatureNode {
    const { content } = serializedNode;
    const node = $createSignatureNode({
      content
    });
    return node;
  }

  exportJSON(): SerializedSignatureNode {
    return {
      content: this.content,
      type: SignatureNode.getType(),
      version: 1
    };
  }

  decorate(): JSX.Element {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(this.content, 'text/html');
    return (
      <div
        ref={(ref) => ref?.appendChild?.(doc.body)}
        className="signature-node"
      ></div>
    );
  }
}

export function $createSignatureNode({
  content,
  key
}: SignaturePayload): SignatureNode {
  return $applyNodeReplacement(new SignatureNode(content, key));
}

export function $removeReplyingToNode(node: SignatureNode): void {
  node.remove();
}

export function $isSignatureNode(
  node: LexicalNode | null | undefined
): node is SignatureNode {
  return node instanceof SignatureNode;
}
