import { AxiosResponse } from 'axios';

export const downloadCSV = (
  response: AxiosResponse<any>,
  documentName?: string
) => {
  const blob = new Blob([response.data], { type: 'text/csv' });
  const urlBlob = URL.createObjectURL(blob);

  const contentDisposition = response.headers
    ? response.headers['content-disposition']
    : undefined;
  let fileName = documentName ?? 'MyReport.csv';
  const regex = /filename="(.+?)"/;

  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(regex);
    if (fileNameMatch?.[1]) fileName = fileNameMatch[1];
  }

  const link = document.createElement('a');
  link.href = urlBlob;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  link.remove();
  URL.revokeObjectURL(urlBlob);
};

export default downloadCSV;
