import {
  acceptPowerManualApi,
  ignorePowerManualApi,
  postponePowerManualApi
} from '../infrastructure/api/powerDialerApi';

export const acceptPowerManual = async (
  campaignId: string,
  scheduleId: string,
  userId: string
) => {
  const response = acceptPowerManualApi(campaignId, scheduleId, userId);
  return response;
};
export const ignorePowerManual = async (
  campaignId: string,
  scheduleId: string,
  userId: string,
  companyId: string
) => {
  const response = ignorePowerManualApi(
    campaignId,
    scheduleId,
    userId,
    companyId
  );
  return response;
};

export const postponePowerManual = async (
  campaignId: string,
  scheduleId: string,
  userId: string,
  companyId: string
) => {
  const response = postponePowerManualApi(
    campaignId,
    scheduleId,
    userId,
    companyId
  );
  return response;
};
