import { ArrowForwardIos } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  styled,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AccordionSummaryWrapper = styled(AccordionSummary)(
  () => `
      &.Mui-expanded {
        font-weight: bold;
        margin: 0 0;
        color: #1870F5;
        background:#F6F8FB;
      }
      '&:hover': {
        backgroundColor: '#F6F8FB'
      }
      
  `
);
interface Props {
  title: string;
}

export const StyleAccordion: React.FC<Props> = ({ children, title }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Accordion
      sx={{ margin: 0, width: '100%' }}
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
      elevation={0}
      disableGutters
      square
    >
      <AccordionSummaryWrapper
        sx={{
          '&:hover': {
            backgroundColor: '#F6F8FB'
          }
        }}
      >
        <Stack
          direction={'row'}
          width={'100%'}
          justifyContent={'space-between'}
          sx={{
            fontWeight: 'bold',
            color: isExpanded
              ? theme.colors.secondary.main
              : theme.colors.primary.main
          }}
        >
          {t(title)}
          <ArrowForwardIos
            fontSize="small"
            sx={{ rotate: isExpanded ? '90deg' : '-90deg' }}
          />
        </Stack>
      </AccordionSummaryWrapper>
      <AccordionDetails sx={{ padding: 0 }}>
        <Stack direction="column" spacing={2} p={2} sx={{ width: '100%' }}>
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
