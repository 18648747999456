import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import type { MuiTelInputCountry } from './index.types';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  SxProps,
  Theme,
  Typography
} from '@mui/material';
import Flag from './components/Flag/Flag';
import {
  COUNTRIES,
  COUNTRY_CALLING_CODES,
  DEFAULT_ISO_CODE,
  ISO_CODES
} from './shared/constants/countries';
import { CountryCode } from 'libphonenumber-js';
import { getDisplayNames } from './shared/helpers/intl';
import { DEFAULT_LANG } from './shared/constants/lang';
import { useTranslation } from 'react-i18next';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

export {
  isValidPhoneNumber as matchIsValidTel,
  AsYouType,
  getNumberType
} from 'libphonenumber-js';

export const Styled = {
  ListItemIcon: styled(ListItemIcon)({
    marginRight: '10px'
  }),
  ListItemText: styled(ListItemText)({
    marginRight: '10px'
  })
};

export type FlagSize = `small` | 'medium';

export interface Props {
  code?: string | null;
  setCode: Dispatch<SetStateAction<string>>;
  defaultCountry?: MuiTelInputCountry;
  flagSize?: FlagSize;
  langOfCountryName?: string;
  sx?: SxProps<Theme>;
  label?: string;
  showNoPrefixOption?: boolean;
}

const CountryCodeInput = (props: Props) => {
  const {
    code,
    setCode,
    defaultCountry = DEFAULT_ISO_CODE,
    langOfCountryName = DEFAULT_LANG,
    flagSize = 'small',
    label,
    showNoPrefixOption = true
  } = props;

  const [selectedIsoCode, setSelectedIsoCode] = useState<CountryCode | null>(
    () => {
      if (code === null || code === '') {
        if (!showNoPrefixOption) return defaultCountry;
        return null;
      }
      if (code !== undefined) {
        return COUNTRY_CALLING_CODES[code][0];
      } else return defaultCountry;
    }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { t }: { t: any } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectCountry = (isoCode: CountryCode) => {
    setSelectedIsoCode(isoCode);
    setCode(COUNTRIES[isoCode][0]);
    handleClose();
  };

  const displayNames = useMemo(() => {
    return getDisplayNames(langOfCountryName);
  }, [langOfCountryName]);

  const width = flagSize === 'small' ? 40 : 80;

  return (
    <>
      <Box position="relative" display={'flex'} width={'100%'}>
        <Typography
          position="absolute"
          fontSize="10px"
          top={-7}
          left={7}
          sx={{ background: 'white' }}
          zIndex={30}
          px={0.25}
        >
          {label ? t(label) : t('Prefix')}
        </Typography>
        <Button
          aria-label="Select country"
          className="MuiTelInput-IconButton"
          aria-haspopup="listbox"
          onClick={handleClick}
          variant="outlined"
          sx={{
            px: 1.5,
            ':hover': {
              background: 'transparent'
            },
            ...props.sx
          }}
          size="large"
        >
          {selectedIsoCode ? (
            <>
              <Flag isoCode={selectedIsoCode} size={flagSize} />
              <Typography ml={0.5}>
                +{COUNTRIES[selectedIsoCode]?.[0]}
              </Typography>
            </>
          ) : (
            <Box display="flex" alignItems="center">
              <PhoneDisabledIcon sx={{ width: width / 2 }} color="secondary" />
              <Typography ml={0.5} noWrap>
                {t('No prefix')}
              </Typography>
            </Box>
          )}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ px: 1 }}
      >
        {showNoPrefixOption && (
          <MenuItem
            key="no prefix"
            onClick={() => {
              setCode(null);
              setSelectedIsoCode(null);
              handleClose();
            }}
            role="option"
            data-testid={`option-null`}
            className="MuiTelInput-MenuItem"
            selected={selectedIsoCode === null}
          >
            <Styled.ListItemIcon className="MuiTelInput-ListItemIcon-flag">
              <PhoneDisabledIcon sx={{ width: width / 2 }} color="secondary" />
            </Styled.ListItemIcon>
            <Styled.ListItemText className="MuiTelInput-ListItemText-country">
              {t('No prefix')}
            </Styled.ListItemText>
          </MenuItem>
        )}
        {ISO_CODES.map((isoCode) => (
          <MenuItem
            key={isoCode}
            onClick={() => onSelectCountry(isoCode)}
            role="option"
            data-testid={`option-${isoCode}`}
            className="MuiTelInput-MenuItem"
            selected={selectedIsoCode === isoCode}
          >
            <Styled.ListItemIcon className="MuiTelInput-ListItemIcon-flag">
              <Flag isoCode={isoCode} />
            </Styled.ListItemIcon>
            <Styled.ListItemText className="MuiTelInput-ListItemText-country">
              {displayNames.of(isoCode)}
            </Styled.ListItemText>
            <Typography
              variant="body2"
              color="text.secondary"
              className="MuiTelInput-Typography-calling-code"
            >
              +{COUNTRIES[isoCode]?.[0]}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export { CountryCodeInput };
