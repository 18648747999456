import axios from 'axios';
import { ReadMessagesApiParams } from 'src/services/instagram/infrastructure/message/readInstagramMessageApi';
import { EMAIL_MESSAGE_READ } from 'src/utils/apiRoutes/msEmail';

export const readEmailMessageApi = async (params: ReadMessagesApiParams) => {
  const { messageIds, ...restParams } = params;

  const response = await axios.post(EMAIL_MESSAGE_READ, messageIds, {
    params: restParams
  });

  return response.data;
};
