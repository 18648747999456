import { Alert, AlertTitle, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const TicketContactLimitReached = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <Stack height="100%" p={1}>
      <Alert variant="outlined" severity="warning" sx={{ my: 1 }}>
        <AlertTitle sx={{ fontWeight: 'bold' }}>
          {t('Maximum number of tickets per contact has been reached!')}
        </AlertTitle>
        {t('It is not possible to add a new ticket to this conversation.')}
      </Alert>
    </Stack>
  );
};
