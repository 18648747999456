import store, { useSelector } from 'src/store/store';

/** GET CAMERA DEVICE IN STORE
 *
 * @param shouldUseHook
 */

export const getCameraDeviceStore = (shouldUseHook: boolean) => {
  const camera = shouldUseHook
    ? useSelector((state) => state.configVolume.cameraDevice)
    : store.getState().configVolume.cameraDevice;

  return camera;
};
