import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { $getSelection, LexicalEditor } from 'lexical';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType
} from '@lexical/selection';
import { TextEditorState } from '../utils/initialState';
import { editorFontFamilies } from '../utils/textActions';

interface Props {
  editor: LexicalEditor;
  selectedTextState: TextEditorState;
}

function EditorFontSize({ editor, selectedTextState }: Props) {
  return (
    <Stack direction="row" alignItems={'center'} spacing={0.5}>
      {/* FONT FAMILY SELECTOR */}
      <TextField
        sx={{
          width: '170px'
        }}
        size="small"
        select
        value={selectedTextState.fontFamily}
        onChange={(e) => {
          editor.update(() => {
            const selection = $getSelection();
            if (selection !== null) {
              $patchStyleText(selection, {
                'font-family': e.target.value
              });
            }
          });
        }}
      >
        {editorFontFamilies.map((f) => {
          return (
            <MenuItem key={f} value={f}>
              {f}
            </MenuItem>
          );
        })}
      </TextField>
      <IconButton
        size="small"
        onClick={() => {
          //fontSize--
          editor.update(() => {
            const selection = $getSelection();
            if (selection !== null) {
              $patchStyleText(selection, {
                'font-size':
                  Number(selectedTextState.fontSize.replace('px', '')) -
                  1 +
                  'px'
              });
            }
          });
        }}
        style={{
          height: '100%',
          width: '40px'
        }}
      >
        -
      </IconButton>
      <Typography variant="body1">
        {selectedTextState.fontSize.replace('px', '')}
      </Typography>
      <IconButton
        onClick={() => {
          //fontSize--
          editor.update(() => {
            const selection = $getSelection();
            if (selection !== null) {
              $patchStyleText(selection, {
                'font-size':
                  Number(selectedTextState.fontSize.replace('px', '')) +
                  1 +
                  'px'
              });
            }
          });
        }}
        style={{
          height: '100%',
          width: '40px'
        }}
      >
        +
      </IconButton>
    </Stack>
  );
}

export default EditorFontSize;
