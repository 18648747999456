import { motion } from 'framer-motion';
import { VideoCallParticipant } from 'src/modules/VideoCall/types/videoCallTypes';

interface Props {
  participant: VideoCallParticipant;
  name: string;
  toggleOpen: () => void;
}

export default function ParticipantName({
  participant,
  toggleOpen,
  name
}: Props) {
  return (
    <motion.div
      layoutId={`name-${participant.id}-participant`}
      style={{
        fontWeight: 'bold',
        cursor: 'pointer',
        fontSize: 18
      }}
      whileHover={{
        scale: 1.05,
        textDecoration: 'underline'
      }}
      whileTap={{
        scale: 0.95
      }}
      onClick={() => {
        toggleOpen();
      }}
    >
      {name}
    </motion.div>
  );
}
