import { ListItemButton, Typography, useTheme } from '@mui/material';
import { MouseEvent, forwardRef } from 'react';

interface Props {
  label: string;
  description?: string;
  selected?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const DialTableListItemButton = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const { label, description, onClick, selected = false } = props;
    const theme = useTheme();
    return (
      <ListItemButton
        ref={ref}
        selected={selected}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
          gap: 2
        }}
        onClick={onClick}
      >
        <Typography
          minWidth="60px"
          noWrap
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {label}
        </Typography>
        {description && (
          <Typography
            color={theme.colors.alpha.black[70]}
            fontSize={(theme.typography.body1.fontSize as number) - 1}
          >
            {description}
          </Typography>
        )}
      </ListItemButton>
    );
  }
);
