import store, { useSelector } from 'src/store/store';

export const useUserDescription = (userId: string) => {
  return useSelector((state) => state.users.userDescriptions?.[userId]);
};

export const getUserDescriptionFromStore = (userId: string) => {
  return store.getState().users.userDescriptions?.[userId];
};

