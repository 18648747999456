import { useEffect, useRef, useState } from 'react';
import { ChannelType } from 'src/models/conversations/conversations';
import {
  playNotificationSound,
  playNotificationSoundPromise
} from 'src/services/socialmedia/application/utils';
import WebRTCNotifications from 'src/services/webrtc/notifications/webrtc-notifications';

export const useSpeakerTest = () => {
  const callTest = document.getElementById('call-test') as HTMLMediaElement;
  const socialmediaAudio = document.getElementById(
    'socialmedia-message-notification'
  ) as HTMLAudioElement;
  const ringtoneAudio = document.getElementById(
    'webrtc-ring'
  ) as HTMLMediaElement;

  // needed hooks for the speakers test
  const [isCallTestPlaying, setIsCallTestPlaying] = useState(false);
  const [isRingTestPlaying, setIsRingTestPlaying] = useState(false);
  const [isRingtoneTestPlaying, setIsRingtoneTestPlaying] = useState(false);
  const [mediaSourceNode, setMediaSourceNode] =
    useState<MediaElementAudioSourceNode | null>(null);
  const [ringSourceNode, setRingSourceNode] =
    useState<MediaElementAudioSourceNode | null>(null);
  const [ringtoneSourceNode, setRingtoneSourceNode] =
    useState<MediaElementAudioSourceNode | null>(null);
  const [speakersValue, setSpeakersValue] = useState(0);
  const [ringValue, setRingValue] = useState(0);
  const audioContext = useRef<AudioContext>(
    new (window.AudioContext || (window as any).webkitAudioContext)()
  );

  // this is for the speakers test, i needed to move up this useEffect because of the unmounted DevicesMenu
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (isCallTestPlaying) {
      timeoutId = setTimeout(() => {
        callTest.pause();
        setIsCallTestPlaying(false);
        timeoutId = undefined;
      }, 19000);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }
      if (mediaSourceNode) mediaSourceNode.disconnect();
    }
  }, [isCallTestPlaying]);

  const handlePlayPauseTestCall = () => {
    setIsCallTestPlaying((prev) => !prev);
    if (callTest.paused) {
      callTest.currentTime = 0;
      callTest.play();
    } else {
      callTest.pause();
    }
  };

  const handlePlayNotificationSound = async (channel: ChannelType) => {
    if (channel === 'Call') {
      setIsRingtoneTestPlaying(true);
      WebRTCNotifications.startRinger();
      setTimeout(() => {
        WebRTCNotifications.stopRinger();
        setIsRingtoneTestPlaying(false);
      }, 5000);
    } else {
      setIsRingTestPlaying(true);
      playNotificationSound(channel);
      //await playNotificationSoundPromise(channel);
      setIsRingTestPlaying(false);
    }
  };

  return {
    audioContext,
    isCallTestPlaying,
    handlePlayPauseTestCall,
    speakersValue,
    isRingTestPlaying,
    isRingtoneTestPlaying,
    handlePlayNotificationSound,
    ringValue
  } as SpeakerTestProps;
};

export interface SpeakerTestProps {
  audioContext: React.MutableRefObject<AudioContext>;
  isCallTestPlaying: boolean;
  handlePlayPauseTestCall: () => void;
  speakersValue: number;
  isRingTestPlaying: boolean;
  isRingtoneTestPlaying: boolean;
  handlePlayNotificationSound: (channel: ChannelType) => void;
  ringValue: number;
}
