import store, { useSelector } from 'src/store/store';

/** GET MIC DEVICE IN STORE
 *
 * @param shouldUseHook
 */

export const getVoiceDeviceStore = (shouldUseHook: boolean) => {
  const microphone = shouldUseHook
    ? useSelector((state) => state.configVolume.voiceDevice)
    : store.getState().configVolume.voiceDevice;

  return microphone;
};
