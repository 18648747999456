import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType
} from '../models/filters';
import { Option } from '../models/option';
import { getOptionValue } from './getOptionValue';
import { getOption } from './getOptionValues';

export const getFilterValues = async <T extends Object>(
  filter: DialTableFilter<T>,
  getCustomFilterValueOptionFn: (
    dataCategory: DataCategory,
    value: string
  ) => Promise<Option>,
  optionsMap?: { [fieldName: string]: Option[] }
) => {
  if (filter.fieldDataType === FieldDataType.BOOLEAN) {
    // It is not necessary to use the 'getOptionValue' function. If the value equals to 'true' return 'yes'. Otherwise, return 'no'
    // It is not necessary to use the options map because options are not requested to the backend. The options map is useful to avoid requesting the options to the backend.
    const valuesPromises = filter.values?.map(getBooleanFilterValue);
    const values = await Promise.all(valuesPromises);
    return values;
  }
  if (
    filter.fieldDataType === FieldDataType.DATE ||
    filter.dataCategory === DataCategory.DATE
  ) {
    // It is not necessary to use options map because options are not requested to the backend
    const valuesPromises = filter?.values?.map((value) => {
      return getOptionValue(
        value,
        filter.dataCategory,
        getCustomFilterValueOptionFn
      );
    });
    const values = await Promise.all(valuesPromises);
    return values;
  }
  if (
    (filter.fieldDataType === FieldDataType.STRING ||
      filter.fieldDataType === FieldDataType.DOUBLE ||
      filter.fieldDataType === FieldDataType.INTEGER) &&
    filter.fieldType === FieldType.TEXT
  ) {
    // It is not necessary to use the 'getOptionValue' function because the option label is equal to the option value.
    // It is not necessary to use the options map because options are not requested to the backend. The options map is useful to avoid requesting the options to the backend.
    const valuesPromises = filter.values.map(getStringNumberFilterValue);
    const values = await Promise.all(valuesPromises);
    return values;
  }

  const valuesPromises = filter?.values?.map((value) => {
    const optionSaved = optionsMap?.[filter?.fieldName as string]?.find(
      (option) => option.value === value
    );
    // If the filter has options, the customFilterValueOptions table prop and the options related to DataCategory filter prop are ignored
    const option = !!filter?.options?.getValueOptionFn
      ? filter?.options?.getValueOptionFn(value)
      : getOptionValue(
          value,
          filter.dataCategory,
          getCustomFilterValueOptionFn
        );
    return optionSaved ? optionSaved : option;
  });
  const values = await Promise.all(valuesPromises);
  return values;
};

const getBooleanFilterValue = (value: string) =>
  getOption({
    option: value,
    labelExtractor: (value) => {
      if (value === 'true') return 'yes';
      else return 'no';
    },
    valueExtractor: (value) => value
  });

export const getStringNumberFilterValue = (value: string) =>
  getOption({
    option: value,
    labelExtractor: (value) => value,
    valueExtractor: (value) => value
  });
