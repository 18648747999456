import axios from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import { ASTERISK_MS, CALLS_UNPARK } from 'src/utils/apiRoutes/msAsterisk';

export const unParkCallApi: Function = (
  callId: string,
  extension: string,
  instance: string,
  extensionFrom?: string
) => {
  const unparkCallPath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) + CALLS_UNPARK;

  const path = replaceURLParams(unparkCallPath, { '{id}': callId });
  const params = { extension, extensionFrom };

  return axios
    .post(path, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
