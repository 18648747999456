import { useState } from 'react';

import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { ReportReadyMessage } from 'src/modules/Productivity/modules/Downloads/models/reportingAttachment';
import { useAlert } from 'src/hooks/useAlert';

import {
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  TextField
} from '@mui/material';
import { Close, Done, Download } from '@mui/icons-material';
import { useCustomEventListener } from 'react-custom-events';
import { useTranslation } from 'react-i18next';

interface Props {
  onGenerateReport: (reportName: string) => Promise<void>;
  disabled?: boolean;
  /** If true, an icon button is displayed */
  iconButton?: boolean;
  /** The default is 'MyReport' */
  initialReportName?: string;
  /** The default is 'Generate report' */
  title?: string;
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  variant?: 'contained' | 'outlined' | 'text';
}

export const GenerateReportButton = ({
  onGenerateReport,
  disabled,
  iconButton,
  initialReportName,
  size,
  color,
  variant,
  title = 'Generate report'
}: Props) => {
  const [isLoadingGeneration, setIsLoadingGeneration] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // The name of the report must be longer than 3
  const initialDefaultName = 'MyReport';
  const [reportName, setReportName] = useState(
    initialReportName?.trim() ?? initialDefaultName
  );

  const { showAlert } = useAlert();
  const { t }: { t: any } = useTranslation();

  const handleClickGenerateReport = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setReportName(initialDefaultName);
    setAnchorEl(null);
  };

  const isDisabledReportGeneration = reportName?.trim().length === 0;

  const handleReportGeneration = () => {
    if (!isDisabledReportGeneration) {
      handleClosePopover();
      setIsLoadingGeneration(true);
      showAlert(t('Generating report...'), 'info', 2000);
      onGenerateReport(reportName).catch(() => {
        showAlert(t('The report could not be generated'), 'error', 2000);
        setIsLoadingGeneration(false);
      });
    }
  };

  // When the report is ready for downloading, isLoadingGeneration is set to false
  // and a snackbar is queued from the AppLayout component
  useCustomEventListener(
    CustomEventNames.REPORT_READY,
    (data: ReportReadyMessage) => {
      if (isLoadingGeneration) {
        setIsLoadingGeneration(false);
      }
    }
  );

  return (
    <>
      {/* ICON BUTTON / BUTTON */}
      {iconButton && (
        <IconButton
          title={t(title)}
          disabled={disabled || isLoadingGeneration}
          size={size ?? 'medium'}
          color={color ?? 'primary'}
          onClick={(event) => {
            handleClickGenerateReport(event);
          }}
        >
          {isLoadingGeneration ? (
            <CircularProgress color="primary" size="1rem" />
          ) : (
            <Download />
          )}
        </IconButton>
      )}
      {!iconButton && (
        <Button
          size={size ?? 'small'}
          title={t(title)}
          disabled={disabled || isLoadingGeneration}
          variant={variant ?? 'contained'}
          color={color ?? 'secondary'}
          startIcon={
            isLoadingGeneration ? (
              <CircularProgress color="secondary" size="1rem" />
            ) : (
              <Download />
            )
          }
          onClick={(event) => {
            handleClickGenerateReport(event);
          }}
        >
          {t('Generate report')}
        </Button>
      )}
      {/* POPOVER */}
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack mt={2} mb={1.5} mx={1.5} columnGap={0.5} direction="row">
          <TextField
            fullWidth
            autoFocus
            required
            size="small"
            placeholder={t('Enter the report name')}
            label={t('Report name')}
            value={reportName}
            onChange={(e) => {
              setReportName(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleReportGeneration();
              }
            }}
          />
          <IconButton
            size="small"
            color="success"
            disabled={isDisabledReportGeneration}
            title={t('Accept')}
            onClick={() => {
              handleReportGeneration();
            }}
          >
            <Done />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            title={t('Cancel')}
            onClick={handleClosePopover}
          >
            <Close />
          </IconButton>
        </Stack>
      </Popover>
    </>
  );
};
