import axios, { AxiosResponse } from 'axios';
import { AIModel } from '../models/AIModels';
import { replaceURLParams } from 'src/services/utils';
import { invalidateIntentionsModelsDataQuery } from '../views/hooks/getAIModelsQuery';
import {
  INTENTION_MODEL,
  INTENTION_MODEL_ID
} from 'src/utils/apiRoutes/msBasicNlu';

export const getIntentionsModelsApi = async (filters?: {
  name?: string;
  companyId?: string;
}): Promise<AIModel[]> => {
  const params = filters;
  const response: AxiosResponse<AIModel[]> = await axios.get(INTENTION_MODEL, {
    params
  });

  return response.data;
};

export const getIntentionModelApi = async (modelId: string) => {
  const path = replaceURLParams(INTENTION_MODEL_ID, {
    '{id}': modelId
  });

  const response: AxiosResponse<AIModel> = await axios.get(path);
  invalidateIntentionsModelsDataQuery();
  return response.data;
};
