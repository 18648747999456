import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ArticleIcon from '@mui/icons-material/Article';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PublicIcon from '@mui/icons-material/Public';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FlakyIcon from '@mui/icons-material/Flaky';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CallIcon from '@mui/icons-material/Call';
import { Item, Menu } from 'src/models/menus';
import {
  checkReadAnnouncementPermissions,
  checkReadCallerIdsPermissions,
  checkReadContactPermissions,
  checkReadEmailAccountPermissions,
  checkReadExtensionPermissions,
  checkReadInboundRoutePermissions,
  checkReadTelegramAccountPermissions,
  checkReadTimeConditionPermissions,
  checkReadUserPermissions,
  checkReadUserRolePermissions,
  checkReadVariablePermissions,
  checkReadWebChatAccountPermissions,
  checkReadWhatsAppAccountPermissions,
  checkReadTicketViewPermissions,
  checkReadMusicOnHoldPermissions,
  checkReadOutboundRoutePermissions,
  checkReadExternalActionsPermissions,
  checkReadInstagramAccountPermissions,
  checkReadKeyWordsGroupsPermissions,
  checkReadAnalyzePermissions,
  checkReadServicePermissions,
  checkReadQuestionGroupsPermissions,
  checkReadApiKeysPermissions,
  checkReadTrunksPermissions,
  checkReadRobinsonListsPermissions,
  checkReadMassiveSmsPermissions,
  checkReadMassiveEmailPermissions,
  checkReadMassiveWhatsAppPermissions,
  checkReadEntityModelsPermissions,
  checkReadKnowledgeModelsPermissions,
  checkReadIntentionModelsPermissions,
  checkReadAnyCallLaunchCampaignPermissions,
  checkReadPromptsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import {
  checkCallChannelFeature,
  checkEmailChannelFeature,
  checkExternalActionFeature,
  checkInstagramChannelFeature,
  checkKnowledgeModelsFeature,
  checkMassiveEmailFeature,
  checkMassiveSmsFeature,
  checkMassiveWhatsAppFeature,
  checkNluFeature,
  checkRolesAndPermsFeature,
  checkSpeechAnalyticsFeature,
  checkTelegramChannelFeature,
  checkWebChatChannelFeature,
  checkWhatsAppChannelFeature
} from 'src/contexts/CompanyFeaturesContext/utils/checkFeatures';

export const adminItems: Menu[] = [
  {
    option: 'General Settings',
    color: '#EBF9FF',
    iconColor: '#0AB6FF',
    icon: SettingsApplicationsIcon,
    items: [
      {
        name: 'Companies',
        link: '/dashboard/admin/general/companies',
        hasSuperadminFeatures: true
      },
      {
        name: 'Billing',
        link: '/dashboard/admin/general/billing',
        permissionsCheckerFn: (_, isSuperadmin) => isSuperadmin,
        hasSuperadminFeatures: true
      },
      {
        name: 'Users',
        link: '/dashboard/admin/general/view-users',
        permissionsCheckerFn: checkReadUserPermissions
      },
      {
        name: 'Roles and permissions',
        link: '/dashboard/admin/general/roles-and-permissions',
        permissionsCheckerFn: checkReadUserRolePermissions,
        featureCheckerFn: checkRolesAndPermsFeature
      },
      {
        name: 'Variables',
        link: '/dashboard/admin/general/variables',
        permissionsCheckerFn: checkReadVariablePermissions
      },
      {
        name: 'Accounts',
        link: '/dashboard/admin/general/accounts',
        permissionsCheckerFn: (_, isSuperadmin) => isSuperadmin,
        hasSuperadminFeatures: true
      }
    ] as Item[]
  },
  {
    option: 'Operations',
    color: '#ECFBE6',
    iconColor: '#3BB900	',
    icon: ArticleIcon,
    items: [
      {
        name: 'Services',
        link: '/dashboard/admin/operations/services',
        permissionsCheckerFn: checkReadServicePermissions
      },
      {
        name: 'Tickets',
        link: '/dashboard/admin/operations/tickets',
        permissionsCheckerFn: checkReadTicketViewPermissions
      },
      {
        name: 'Contacts',
        link: '/dashboard/admin/operations/view-contacts',
        permissionsCheckerFn: checkReadContactPermissions
      }
    ] as Item[]
  },
  {
    option: 'Calls',
    color: '#DEF6F0',
    iconColor: '#00B98D',
    icon: CallIcon,
    items: [
      {
        name: 'Caller Ids',
        link: '/dashboard/admin/calls/caller-ids',
        permissionsCheckerFn: checkReadCallerIdsPermissions,
        featureCheckerFn: checkCallChannelFeature,
        hasSuperadminFeatures: true
      },
      {
        name: 'Outbound Routes',
        link: '/dashboard/admin/calls/outbound-routes',
        permissionsCheckerFn: checkReadOutboundRoutePermissions,
        featureCheckerFn: checkCallChannelFeature
      },
      {
        name: 'Extensions',
        link: '/dashboard/admin/calls/extensions',
        permissionsCheckerFn: checkReadExtensionPermissions,
        featureCheckerFn: checkCallChannelFeature
      },
      {
        name: 'Robinson List',
        link: '/dashboard/admin/calls/robinson-list',
        permissionsCheckerFn: checkReadRobinsonListsPermissions
      },
      {
        name: 'Trunks',
        link: '/dashboard/admin/calls/trunks',
        permissionsCheckerFn: checkReadTrunksPermissions,
        featureCheckerFn: checkCallChannelFeature,
        hasSuperadminFeatures: true
      }
    ] as Item[]
  },
  {
    option: 'Channels',
    color: '#FFF6E8',
    iconColor: '#F49200',
    icon: PublicIcon,
    items: [
      {
        name: 'WebChat',
        link: '/dashboard/admin/contact-center/chat',
        permissionsCheckerFn: checkReadWebChatAccountPermissions,
        featureCheckerFn: checkWebChatChannelFeature
      },
      {
        name: 'Email',
        link: '/dashboard/admin/contact-center/email',
        permissionsCheckerFn: checkReadEmailAccountPermissions,
        featureCheckerFn: checkEmailChannelFeature
      },
      {
        name: 'Instagram',
        link: '/dashboard/admin/contact-center/instagram',
        permissionsCheckerFn: checkReadInstagramAccountPermissions,
        featureCheckerFn: checkInstagramChannelFeature
      },
      {
        name: 'Telegram',
        link: '/dashboard/admin/contact-center/telegram',
        permissionsCheckerFn: checkReadTelegramAccountPermissions,
        featureCheckerFn: checkTelegramChannelFeature
      },
      {
        name: 'WhatsApp',
        link: '/dashboard/admin/contact-center/whatsapp',
        permissionsCheckerFn: checkReadWhatsAppAccountPermissions,
        featureCheckerFn: checkWhatsAppChannelFeature,
        hasSuperadminFeatures: true
      }
    ] as Item[]
  },
  {
    option: 'ACD',
    color: '#8AAEF6',
    iconColor: '#1975FF',
    icon: AccountTreeIcon,
    items: [
      {
        name: 'Inbound Routes',
        link: '/dashboard/admin/acd/routes',
        permissionsCheckerFn: checkReadInboundRoutePermissions
      },
      {
        name: 'Models',
        link: '/dashboard/admin/acd/models',
        isAI: true,
        permissionsCheckerFn: (permissions) =>
          checkReadIntentionModelsPermissions(permissions) ||
          checkReadEntityModelsPermissions(permissions) ||
          checkReadKnowledgeModelsPermissions(permissions),
        featureCheckerFn(features) {
          return (
            checkKnowledgeModelsFeature(features) || checkNluFeature(features)
          );
        }
        // permissionsCheckerFn: checkReadIntentionModelsPermissions
      },

      {
        name: 'Music On Hold',
        link: '/dashboard/admin/acd/moh',
        permissionsCheckerFn: checkReadMusicOnHoldPermissions
      },
      {
        name: 'Temporal conditions',
        link: '/dashboard/admin/acd/schedule-groups',
        permissionsCheckerFn: checkReadTimeConditionPermissions
      },
      {
        name: 'Announcements',
        link: '/dashboard/admin/acd/announcements',
        permissionsCheckerFn: checkReadAnnouncementPermissions
      },
      {
        name: 'Prompts',
        link: '/dashboard/admin/acd/prompts',
        permissionsCheckerFn: checkReadPromptsPermissions
      }
    ] as Item[]
  },
  {
    option: 'Massive',
    color: '#F5C9EB',
    iconColor: '#E161D5',
    icon: SmartToyIcon,
    items: [
      {
        name: 'Call launch engines',
        link: '/dashboard/admin/massive/dialerbot',
        permissionsCheckerFn: checkReadAnyCallLaunchCampaignPermissions,
        featureCheckerFn: checkCallChannelFeature
      },
      {
        name: 'Massive SMS',
        link: '/dashboard/admin/massive/massive-sms',
        permissionsCheckerFn: checkReadMassiveSmsPermissions,
        featureCheckerFn: checkMassiveSmsFeature
      },
      {
        name: 'Massive Email',
        link: '/dashboard/admin/massive/massive-email',
        permissionsCheckerFn: checkReadMassiveEmailPermissions,
        featureCheckerFn: checkMassiveEmailFeature
      },
      {
        name: 'Massive WhatsApp',
        link: '/dashboard/admin/massive/massive-whatsapp',
        permissionsCheckerFn: checkReadMassiveWhatsAppPermissions,
        featureCheckerFn: checkMassiveWhatsAppFeature
      }
    ] as Item[]
  },
  {
    option: 'Quality',
    color: '#FFCFA4',
    iconColor: '#fc746d',
    icon: FlakyIcon,
    items: [
      {
        name: 'Keyword Groups',
        link: '/dashboard/admin/quality/keyword-groups',
        permissionsCheckerFn: checkReadKeyWordsGroupsPermissions
      },
      {
        name: 'Analyzed Conversations',
        link: '/dashboard/admin/quality/analyzed-conversations',
        permissionsCheckerFn: checkReadAnalyzePermissions,
        featureCheckerFn: checkSpeechAnalyticsFeature,
        isAI: true
      },
      {
        name: 'Questions Groups',
        link: '/dashboard/admin/quality/questions-groups',
        permissionsCheckerFn: checkReadQuestionGroupsPermissions,
        isAI: true
      }
    ] as Item[]
  },
  {
    option: 'Integrations',
    color: '#FFE8EC',
    iconColor: '#F4002C',
    icon: IntegrationInstructionsIcon,
    items: [
      {
        name: 'External Actions',
        link: '/dashboard/admin/integrations/externalActions',
        permissionsCheckerFn: checkReadExternalActionsPermissions,
        featureCheckerFn: checkExternalActionFeature
      },
      {
        name: 'API Keys',
        link: '/dashboard/admin/integrations/api-keys',
        permissionsCheckerFn: checkReadApiKeysPermissions
      }
    ]
  }
];
