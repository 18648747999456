export const getUniqueObjects = <T extends Object>(
  objectArray: T[],
  uniqueKeyValueExtractor: (object: T) => string
) => {
  const seenKeys: { [key in keyof T]: boolean } | {} = {};
  const uniqueVariables = objectArray.filter((object) => {
    const key = uniqueKeyValueExtractor(object);
    if (seenKeys.hasOwnProperty(key)) {
      return false;
    } else {
      seenKeys[key] = true;
      return true;
    }
  });
  return uniqueVariables;
};
