import {
  CloseTwoTone,
  GppMaybeTwoTone,
  Replay,
  VerifiedUser
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { AnimatePresence, MotionConfig, motion } from 'framer-motion';
import { t } from 'i18next';
import { useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { IconButtonWrapper } from 'src/modules/Productivity/modules/Calls/views/styles';
import getInstagramAccountsQuery, {
  getInstagramAccountsQueryKey
} from 'src/services/instagram/domain/account/getInstagramAccountsQuery';
import renewInstagramAccountTokenApi from 'src/services/instagram/infrastructure/account/renewInstagramAccountTokenApi';
import {
  InstagramAccount,
  InstagramAccountStatus
} from 'src/services/instagram/models/instagramAccounts';
import { INSTAGRAM_AUTH_LOGIN } from 'src/utils/apiRoutes/msInstagram';
import { queryClient } from 'src/utils/queryClient';

interface Props {
  instagramAccount: InstagramAccount;
  onClose: () => void;
  setAccount: (account: InstagramAccount) => void;
}

export function AuthenticateInstagramAccountModal({
  instagramAccount,
  setAccount,
  onClose
}: Props) {
  const status = instagramAccount?.status;

  const [showRefreshButton, setShowRefreshButton] = useState(false);

  const refreshTable = async () => {
    emitCustomEvent(REFETCH_DATA_EVENT_NAME);
    queryClient.invalidateQueries({
      queryKey: [getInstagramAccountsQueryKey]
    });
    const accounts = await getInstagramAccountsQuery({});
    setAccount(accounts.find((a) => a.id === instagramAccount.id));
  };

  const openPopup = () => {
    const top = window.outerHeight / 2 - 375;

    window.open(
      INSTAGRAM_AUTH_LOGIN,
      'popup',
      `width=600,height=750,top=${top}`
    );
  };

  return (
    <>
      <Modal open={instagramAccount !== null} onClose={onClose}>
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px'
          }}
        >
          <CardHeader
            title={t('Authenticate Instagram account')}
            subheader={t(
              'Use this dialog window to Authenticate an Instagram account'
            )}
            action={
              <IconButton
                sx={{
                  m: 1
                }}
                size="small"
                color="secondary"
                onClick={onClose}
              >
                <CloseTwoTone />
              </IconButton>
            }
          />
          <Divider />

          <MotionConfig
            transition={{
              type: 'spring',
              bounce: 0,
              duration: 0.2
            }}
          >
            <Stack
              alignSelf={'center'}
              alignItems={'center'}
              p={2}
              rowGap={1.5}
            >
              <AnimatePresence initial={false} exitBeforeEnter>
                {status !== InstagramAccountStatus.AUTHENTICATED && (
                  <motion.div
                    key={'not-verified'}
                    initial={{
                      opacity: 0,
                      x: 40
                    }}
                    animate={{
                      opacity: 1,
                      x: 0
                    }}
                    exit={{
                      opacity: 0,
                      x: -40
                    }}
                  >
                    <Stack
                      direction="column"
                      alignItems={'center'}
                      rowGap={1.5}
                    >
                      <Typography variant="h3" textAlign={'center'}>
                        {t('Not authenticated')}
                      </Typography>
                      <GppMaybeTwoTone
                        color="error"
                        sx={{
                          height: 100,
                          width: 100
                        }}
                      />
                      <Typography variant="body1" textAlign={'center'}>
                        {t(
                          'To authenticate this account, click on the button below to be redirected to Facebook and complete the authentication process'
                        )}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={'bold'}
                        textAlign={'center'}
                      >
                        {t('When you are done, click on the refresh button')}
                      </Typography>
                      <Stack
                        direction="row"
                        width="100%"
                        columnGap={1.5}
                        justifyContent={'center'}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: '#1877F2',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: '#1877F2',
                              color: 'white'
                            }
                          }}
                          onClick={async () => {
                            setShowRefreshButton(true);
                            openPopup();
                          }}
                        >
                          {t('Continue with Facebook')}
                        </Button>
                        {showRefreshButton && (
                          <motion.div
                            initial={{
                              opacity: 0,
                              x: 20
                            }}
                            animate={{
                              opacity: 1,
                              x: 0
                            }}
                          >
                            <Tooltip title={t('Refresh status')}>
                              <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={refreshTable}
                              >
                                <Replay />
                              </Button>
                            </Tooltip>
                          </motion.div>
                        )}
                      </Stack>
                    </Stack>
                  </motion.div>
                )}
                {status === InstagramAccountStatus.AUTHENTICATED && (
                  <motion.div
                    key={'verified'}
                    initial={{
                      opacity: 0,
                      x: 40
                    }}
                    animate={{
                      opacity: 1,
                      x: 0
                    }}
                    exit={{
                      opacity: 0,
                      x: -40
                    }}
                    style={{
                      width: '100%'
                    }}
                  >
                    <Stack
                      width="100%"
                      direction="column"
                      alignItems={'center'}
                      rowGap={1.5}
                    >
                      <Typography variant="h3" textAlign={'center'}>
                        {t('Authenticated')}
                      </Typography>
                      <VerifiedUser
                        color="success"
                        sx={{
                          height: 100,
                          width: 100
                        }}
                      />
                      <Typography variant="body1" textAlign={'center'}>
                        {t('Process completed successfully')}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                        fullWidth
                      >
                        {t('Close')}
                      </Button>
                    </Stack>
                  </motion.div>
                )}
              </AnimatePresence>
            </Stack>
          </MotionConfig>
        </Card>
      </Modal>
    </>
  );
}
