import { getEntitiesWithCreatePermissionsApi } from '../infrastructure/api/getEntitiesWithCreatePermissions';
import { EntitiesPermissions } from '../models/Dashboards';

/** GET ENTITIES WITH CREATE PERMISSIONS
 *
 * Returns Entities with create permissions
 * @returns {EntitiesPermissions} Entities with create permissions
 */
export const getEntitiesWithCreatePermissions =
  async (): Promise<EntitiesPermissions> =>
    getEntitiesWithCreatePermissionsApi();
