import { AnimatePresence, MotionConfig, motion } from 'framer-motion';
import { VideoCard } from './VideoCard';
import { VideocallActionBar } from '../../ActionsBar';
import { useContext } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { VideoCallParticipant } from 'src/modules/VideoCall/types/videoCallTypes';

//Provisional
export interface VideoCallParticipantWithStream {
  id: string;
  stream: MediaStream;
  participant: VideoCallParticipant;
}

interface Props {
  participantWithStream: VideoCallParticipantWithStream[];
  selectedStreamId: string | null;
  setSelectedStreamId: (stream: string) => void;
}

export function VideoGrid({
  participantWithStream,
  selectedStreamId,
  setSelectedStreamId
}: Props) {
  // context
  const selected = participantWithStream.find(
    (stream) => stream.id === selectedStreamId
  );
  const { isLoggedIn } = useContext(VideocallRoomContext);

  return (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        rowGap: '16px',
        width: '100%',
        height: '100%'
      }}
    >
      <MotionConfig
        transition={{
          type: 'spring',
          duration: 0.6,
          bounce: 0
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {selectedStreamId && (
            <motion.div
              key={selectedStreamId}
              layoutId={selectedStreamId}
              style={{
                width: isLoggedIn ? '80%' : '50%',
                aspectRatio: '16 / 9',
                background: 'rgba(0,0,0,0.1)',
                borderRadius: '8px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <VideoCard data={selected} muted={selected.id === 'local'} />
            </motion.div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '16px',
            flexWrap: 'wrap'
          }}
        >
          {participantWithStream.map((item, index) => {
            if (item.id === selectedStreamId) return;
            if (!item.participant) return;
            return (
              <motion.div
                layoutId={item.id}
                onClick={() => {
                  setSelectedStreamId(item.id);
                }}
                key={item.id}
                style={{
                  width: '300px',
                  height: 'calc(300px*9/16)',
                  background: 'rgba(0,0,0,0.1)',

                  borderRadius: '8px',
                  cursor: 'pointer',
                  position: 'relative'
                }}
              >
                <VideoCard data={item} muted={item?.id === 'local'} />
              </motion.div>
            );
          })}
        </div>
      </MotionConfig>
      <VideocallActionBar />
    </div>
  );
}
