/**Return an Identifiers array to show in tooltip
 *
 *@param dataPoint instant point
  *@param chartData  current chart data
 @returns  identifiers list
 */
export const getIdentifiersArray = (dataPoint, chartData) => {
  let identifiers = [];
  const seriesArray = chartData.globals.initialSeries;
  seriesArray.map((serie, index) => {
    serie.data.map((dt) => {
      if (dt.x === dataPoint.x && dt.y === dataPoint.y) {
        const identifierExist =
          identifiers.findIndex((idt) => idt.identifier === serie.name) !== -1;
        if (!identifierExist)
          identifiers = [
            ...identifiers,
            {
              identifier: serie.name,
              value: dt.y,
              color: chartData.globals.colors[index]
            }
          ];
      }
    });
  });
  return identifiers;
};
