import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ChatMessage } from 'src/models/conversations/messages';

export const getMessageStatusIcon = (message: ChatMessage) => {
  if (message.status === 'Pending' || message.status === 'Error')
    return <QueryBuilderIcon sx={{ fontSize: '18px', color: '#9e9e9e' }} />;
  if (message.status === 'Sent')
    return <DoneIcon sx={{ fontSize: '18px', color: '#9e9e9e' }} />;
  if (message.status === 'Delivered')
    return <DoneAllIcon sx={{ fontSize: '18px', color: '#9e9e9e' }} />;
  if (message.status === 'Read')
    return <DoneAllIcon color="success" sx={{ fontSize: '18px' }} />;
};
