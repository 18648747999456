import axios from 'axios';
import React from 'react';
import { replaceURLParams } from 'src/services/utils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
const MESSAGES_ID = process.env.REACT_APP_API_MESSAGES_ID as string;

interface Props {
  conversationId: string;
  messageId: string;
}

const getMessageByIdApi = async (params: Props) => {
  const { messageId, ...restParams } = params;
  const path = replaceURLParams(BASE_URL + '/socialmedia' + MESSAGES_ID, {
    '{messageId}': messageId
  });

  const message = await axios.get(path, {
    params: restParams
  });
  return message.data;
};

export default getMessageByIdApi;
