import axios from 'axios';
import { AuthData } from 'src/models/auth';
import { AUTHENTICATION_LOGIN } from 'src/utils/apiRoutes/msApiGateway';

export const loginApi = async (params: {
  withExtension: boolean;
  password: string;
  username: string;
  companyShortcode: string;
  doubleFactorCode?: string;
}) => {
  const response = await axios.post<AuthData>(AUTHENTICATION_LOGIN, params);
  return response.data;
};
