import {
  Avatar,
  Box,
  Card,
  Divider,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { WebChatStyle } from '../../../models/webChatStyle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { t } from 'i18next';

interface webChatHeaderProps {
  formikProps: WebChatStyle;
}

const WebChatContent: React.FC<webChatHeaderProps> = ({ formikProps }) => {
  const messagesDate = '12-Nov-2022'; //getMessagesDate(messages[i - 1], messages[i]);
  const theme = useTheme();

  const DividerWrapper = styled(Divider)(
    ({ theme }) => `
          .MuiDivider-wrapper {
            border-radius: ${theme.general.borderRadiusSm};
            text-transform: none;
            background: ${theme.palette.background.default};
            font-size: ${theme.typography.pxToRem(13)};
            color: ${theme.colors.alpha.black[50]};
          }
    `
  );

  const CardWrapperSecondary = styled(Card)(
    ({ theme }) => `
    background: ${formikProps ? formikProps?.conversationStyle.agentSpeechBubbleBGColor.color : theme.colors.alpha.black[10]};
    color: ${theme.colors.alpha.black[100]};
    padding: ${theme.spacing(0.7)} ${theme.spacing(1)};
    border-radius: ${theme.general.borderRadiusXl};
    border-top-left-radius: ${theme.general.borderRadius};
    max-width: 85%;
    display: inline-block;
    word-break: break-word;
      `
  );

  const CardWrapperSecondaryContact = styled(Card)(
    ({ theme }) => `
    background: ${formikProps ? formikProps?.conversationStyle.contactSpeechBubbleBGColor.color : theme.colors.alpha.black[10]};
    color: ${theme.colors.alpha.black[100]};
    padding: ${theme.spacing(0.7)} ${theme.spacing(1)};
    border-radius: ${theme.general.borderRadiusXl};
    border-top-right-radius: ${theme.general.borderRadius};
    max-width: 85%;
    display: inline-block;
    word-break: break-word;
      `
  );

  return (
    <Box p={3} overflow={'scroll'}>
      <Box>
        <DividerWrapper sx={{ marginTop: 0, marginBottom: 2 }}>
          {messagesDate}
        </DividerWrapper>
        {/*first message*/}
        <Box p={0.25}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent={'flex-start'}
          >
            <Box>
              <Avatar
                variant="circular"
                sx={{
                  width: 42,
                  height: 42,
                  backgroundColor: 'light-gray'
                }}
              >
                <Typography variant="h3" color="inherit">
                  <SmartToyIcon
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  />
                </Typography>
              </Avatar>
            </Box>
            <Box
              display="flex"
              alignItems={'flex-start'}
              flexDirection="column"
              justifyContent={'flex-start'}
              mx={2}
              zIndex={30}
            >
              <CardWrapperSecondary>
                <Box
                  color={
                    formikProps?.conversationStyle.agentSpeechBubbleTextColor
                      .color ?? 'inherit'
                  }
                >
                  <Typography
                    display="flex"
                    flexWrap="wrap"
                    whiteSpace="pre-wrap"
                    fontStyle={formikProps?.conversationStyle.font}
                    color={
                      formikProps?.conversationStyle.agentSpeechBubbleTextColor
                        .color ?? theme.colors.primary.main
                    }
                    sx={{
                      fontFamily: `${formikProps?.conversationStyle.font}, Roboto`,
                      fontSize: `${formikProps?.conversationStyle.fontSize}px`,
                      overflowWrap: 'break-word'
                    }}
                  >
                    {'That is indeed great news!'}
                  </Typography>
                </Box>
              </CardWrapperSecondary>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="80%"
                mt={0.5}
              >
                <AccessTimeIcon
                  fontSize="small"
                  sx={{ width: '15px', height: '15px', mr: 0.5 }}
                />
                <Typography variant="subtitle1" fontSize="10px">
                  15:30
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/*second message*/}
        <Box pt={1}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent={'flex-start'}
          >
            <Box>
              <Avatar
                variant="circular"
                sx={{
                  width: 42,
                  height: 42,
                  backgroundColor: 'light-gray'
                }}
                alt={'botito'}
              >
                <Typography variant="h3" color="inherit">
                  A
                </Typography>
              </Avatar>
            </Box>

            <Box
              display="flex"
              alignItems={'flex-start'}
              flexDirection="column"
              justifyContent={'flex-start'}
              mx={2}
              zIndex={30}
            >
              <CardWrapperSecondary>
                <Box
                  color={
                    formikProps?.conversationStyle.agentSpeechBubbleTextColor
                      .color ?? 'inherit'
                  }
                >
                  <Typography
                    display="flex"
                    flexWrap="wrap"
                    whiteSpace="pre-wrap"
                    fontStyle={formikProps?.conversationStyle.font}
                    color={
                      formikProps?.conversationStyle.agentSpeechBubbleTextColor
                        .color ?? theme.colors.primary.main
                    }
                    sx={{
                      fontFamily: `${formikProps?.conversationStyle.font}, Roboto`,
                      fontSize: `${formikProps?.conversationStyle.fontSize}px`,
                      overflowWrap: 'break-word'
                    }}
                  >
                    {
                      'Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.'
                    }
                  </Typography>
                </Box>
              </CardWrapperSecondary>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="80%"
                mt={0.5}
              >
                <AccessTimeIcon
                  fontSize="small"
                  sx={{ width: '15px', height: '15px', mr: 0.5 }}
                />
                <Typography variant="subtitle1" fontSize="10px">
                  20:30
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <DividerWrapper sx={{ marginTop: 2, marginBottom: 2 }}>
          {t('Today')}
        </DividerWrapper>
        {/*third message*/}
        <Box pt={1}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent={'flex-start'}
          >
            <Box>
              <Avatar
                variant="circular"
                sx={{
                  width: 42,
                  height: 42,
                  backgroundColor: 'light-gray'
                }}
                alt={'botito'}
              >
                <Typography variant="h3" color="inherit">
                  A
                </Typography>
              </Avatar>
            </Box>

            <Box
              display="flex"
              alignItems={'flex-start'}
              flexDirection="column"
              justifyContent={'flex-start'}
              mx={2}
              zIndex={30}
            >
              <CardWrapperSecondary>
                <Box
                  color={
                    formikProps?.conversationStyle.agentSpeechBubbleTextColor
                      .color ?? 'inherit'
                  }
                >
                  <Typography
                    display="flex"
                    flexWrap="wrap"
                    whiteSpace="pre-wrap"
                    fontStyle={formikProps?.conversationStyle.font}
                    color={
                      formikProps?.conversationStyle.agentSpeechBubbleTextColor
                        .color ?? theme.colors.primary.main
                    }
                    sx={{
                      fontFamily: `${formikProps?.conversationStyle.font}, Roboto`,
                      fontSize: `${formikProps?.conversationStyle.fontSize}px`,
                      overflowWrap: 'break-word'
                    }}
                  >
                    {
                      'Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.'
                    }
                  </Typography>
                </Box>
              </CardWrapperSecondary>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="80%"
                mt={0.5}
              >
                <AccessTimeIcon
                  fontSize="small"
                  sx={{ width: '15px', height: '15px', mr: 0.5 }}
                />
                <Typography variant="subtitle1" fontSize="10px">
                  20:30
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/*fourth message*/}
        <Box pt={1}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent={'flex-start'}
          >
            <Box>
              <Avatar
                variant="circular"
                sx={{
                  width: 42,
                  height: 42,
                  backgroundColor: 'light-gray'
                }}
                alt={'botito'}
              >
                <Typography variant="h3" color="inherit">
                  A
                </Typography>
              </Avatar>
            </Box>

            <Box
              display="flex"
              alignItems={'flex-start'}
              flexDirection="column"
              justifyContent={'flex-start'}
              mx={2}
              zIndex={30}
            >
              <CardWrapperSecondary>
                <Box
                  color={
                    formikProps?.conversationStyle.agentSpeechBubbleTextColor
                      .color ?? 'inherit'
                  }
                >
                  <Typography
                    display="flex"
                    flexWrap="wrap"
                    whiteSpace="pre-wrap"
                    fontStyle={formikProps?.conversationStyle.font}
                    color={
                      formikProps?.conversationStyle.agentSpeechBubbleTextColor
                        .color ?? theme.colors.primary.main
                    }
                    sx={{
                      fontFamily: `${formikProps?.conversationStyle.font}, Roboto`,
                      fontSize: `${formikProps?.conversationStyle.fontSize}px`,
                      overflowWrap: 'break-word'
                    }}
                  >
                    {
                      'Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.'
                    }
                  </Typography>
                </Box>
              </CardWrapperSecondary>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="80%"
                mt={0.5}
              >
                <AccessTimeIcon
                  fontSize="small"
                  sx={{ width: '15px', height: '15px', mr: 0.5 }}
                />
                <Typography variant="subtitle1" fontSize="10px">
                  20:32
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/*fifth message*/}
        <Box pt={1}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent={'flex-end'}
          >
            <Box
              display="flex"
              alignItems={'flex-end'}
              flexDirection="row"
              justifyContent={'flex-end'}
              ml={2}
              sx={{ width: '100%' }}
              zIndex={30}
            >
              <Box>
                <CardWrapperSecondaryContact>
                  <Box
                    color={
                      formikProps?.conversationStyle
                        .contactSpeechBubbleTextColor.color ?? 'inherit'
                    }
                    minWidth={'70px'}
                  >
                    <Typography
                      display="flex"
                      flexWrap="wrap"
                      whiteSpace="pre-wrap"
                      fontStyle={formikProps?.conversationStyle.font}
                      color={
                        formikProps?.conversationStyle
                          .contactSpeechBubbleTextColor.color ?? 'white'
                      }
                      sx={{
                        fontFamily: `${formikProps?.conversationStyle.font}, Roboto`,
                        fontSize: `${formikProps?.conversationStyle.fontSize}px`
                      }}
                    >
                      {'Thanks.'}
                    </Typography>
                  </Box>
                </CardWrapperSecondaryContact>
                <Box display="flex" justifyContent="flex-start" width="80%">
                  <AccessTimeIcon
                    fontSize="small"
                    sx={{ width: '15px', height: '15px', mr: 0.5 }}
                  />
                  <Typography variant="subtitle1" fontSize="10px">
                    30:36
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Avatar
                  variant="circular"
                  sx={{
                    width: 42,
                    height: 42,
                    backgroundColor: 'light-gray'
                  }}
                  alt={'Nil'}
                >
                  <Typography variant="h3" color="inherit">
                    NL
                  </Typography>
                </Avatar>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WebChatContent;
