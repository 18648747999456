import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RTCall } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';

interface UserState {
  rtUsers: RTUser[];
  rtCalls: RTCall[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | undefined;
  isRealtimeWSConnected: boolean;
  isWebrtcWSConnected: boolean;
}

const initialState = {
  rtUsers: [],
  loading: 'idle',
  error: '',
  isRealtimeWSConnected: false,
  isWebrtcWSConnected: false
} as UserState;

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setRTUsers: (state, action: PayloadAction<RTUser[]>) => {
      state.rtUsers = [...action.payload];
    },
    setRTCalls: (state, action: PayloadAction<RTCall[]>) => {
      state.rtCalls = [...action.payload];
    },
    setRealtimeWSConnected: (state, action: PayloadAction<boolean>) => {
      state.isRealtimeWSConnected = action.payload;
    },
    setWebrtcWSConnected: (state, action: PayloadAction<boolean>) => {
      state.isWebrtcWSConnected = action.payload;
    }
  }
});

// export const { extraReducers } = userDataSlice.actions
export const {
  setRTUsers,
  setRTCalls,
  setRealtimeWSConnected,
  setWebrtcWSConnected
} = userSlice.actions;
export default userSlice.reducer;
