import wsManager from 'src/services/websocket/manager';
import { SubscriptionMessage } from '../../models/Dashboards';

/** SUBSCRIPTION TO DASHBOARD
 *
 * sends a webscoket message to subscription to dashboard
 *
 * @param dashboardId
 */

export const subscriptionToDashboard = (dashboardId: string) => {
  const subscriptionMessage = {
    type: 'RTReportingSub',
    subType: 'Subscription',
    elementType: 'Dashboard',
    id: dashboardId,
    timestamp: new Date().toJSON()
  } as SubscriptionMessage;
  wsManager.getWSClient().send(JSON.stringify(subscriptionMessage));
};

/** UNSUBSCRIPTION TO DASHBOARD
 *
 * sends a webscoket message to unsubscription to panel
 *
 * @param dashboardId
 */

export const UnSubscriptionToDashboard = (dashboardId: string) => {
  const subscriptionMessage = {
    type: 'RTReportingSub',
    subType: 'Unsubscription',
    elementType: 'Dashboard',
    id: dashboardId,
    timestamp: new Date().toJSON()
  } as SubscriptionMessage;
  wsManager.getWSClient().send(JSON.stringify(subscriptionMessage));
};

/** SUBSCRIPTION TO PANEL
 *
 * sends a webscoket message to subscription to panel
 *
 * @param panelId
 */

export const subscriptionToPanel = (panelId: string) => {
  const subscriptionMessage = {
    type: 'RTReportingSub',
    subType: 'Subscription',
    elementType: 'Panel',
    id: panelId,
    timestamp: new Date().toJSON()
  } as SubscriptionMessage;
  wsManager.getWSClient().send(JSON.stringify(subscriptionMessage));
};

/** SUBSCRIPTION TO DASHBOARD
 *
 * sends a webscoket message to subscription to dashboard
 *
 * @param panelId
 */

export const UnSubscriptionToPanel = (panelId: string) => {
  const subscriptionMessage = {
    type: 'RTReportingSub',
    subType: 'Unsubscription',
    elementType: 'Dashboard',
    id: panelId,
    timestamp: new Date().toJSON()
  } as SubscriptionMessage;
  wsManager.getWSClient().send(JSON.stringify(subscriptionMessage));
};
