import { BASE_URL } from './base';

// TELEGRAM MICROSERVICE
const TELEGRAM_MS = `${BASE_URL}/telegram`;

// Telegram account
const TELEGRAM_ACCOUNT_CONTROLLER = `${TELEGRAM_MS}/account`;
export const TELEGRAM_ACCOUNT = TELEGRAM_ACCOUNT_CONTROLLER;
export const TELEGRAM_ACCOUNT_AUTHENTICATE = `${TELEGRAM_ACCOUNT_CONTROLLER}/authenticate`;
export const TELEGRAM_ACCOUNT_CLIENTS = `${TELEGRAM_ACCOUNT_CONTROLLER}/clients`;
export const TELEGRAM_ACCOUNT_CODE = `${TELEGRAM_ACCOUNT_CONTROLLER}/code`;
export const TELEGRAM_ACCOUNT_ID = `${TELEGRAM_ACCOUNT_CONTROLLER}/{id}`;
export const TELEGRAM_ACCOUNT_LAUNCH = `${TELEGRAM_ACCOUNT_CONTROLLER}/launch`;
export const TELEGRAM_ACCOUNT_LOGOUT_ID = `${TELEGRAM_ACCOUNT_CONTROLLER}/logout/{id}`;
export const TELEGRAM_ACCOUNT_RESEND_CODE = `${TELEGRAM_ACCOUNT_CONTROLLER}/resend_code`;

// Telegram message
const TELEGRAM_MESSAGE_CONTROLLER = `${TELEGRAM_MS}/message`;
export const TELEGRAM_MESSAGE_EXISTING_FILE = `${TELEGRAM_MESSAGE_CONTROLLER}/existing/file`;
export const TELEGRAM_MESSAGE_EXISTING_LOCATION = `${TELEGRAM_MESSAGE_CONTROLLER}/existing/location`;
export const TELEGRAM_MESSAGE_EXISTING_TEXT = `${TELEGRAM_MESSAGE_CONTROLLER}/existing/text`;
export const TELEGRAM_MESSAGE_GROUP_MEMBERS = `${TELEGRAM_MESSAGE_CONTROLLER}/group_members`;
export const TELEGRAM_MESSAGE_NEW_FILE = `${TELEGRAM_MESSAGE_CONTROLLER}/new/file`;
export const TELEGRAM_MESSAGE_NEW_TEXT = `${TELEGRAM_MESSAGE_CONTROLLER}/new/text`;
export const TELEGRAM_MESSAGE_READ = `${TELEGRAM_MESSAGE_CONTROLLER}/read`;
export const TELEGRAM_MESSAGE_TYPING = `${TELEGRAM_MESSAGE_CONTROLLER}/typing`;
export const TELEGRAM_MESSAGE_ACTIONS = `${TELEGRAM_MESSAGE_CONTROLLER}/actions`;

// Telegram attachement
const TELEGRAM_ATTACHMENT_CONTROLLER = `${TELEGRAM_MS}/attachment`;
export const TELEGRAM_ATTACHMENT_ID = `${TELEGRAM_ATTACHMENT_CONTROLLER}/{id}`;
