import { Queue } from 'src/models/ariQueues';
import { ChannelType } from 'src/models/conversations/conversations';
import { ContactFieldType } from 'src/modules/Contacts/models/contacts';
import {
  Holidays,
  PowerdialerType,
  RelaunchConfig,
  WeekTimes
} from 'src/modules/DialerBot/models/dialerbot';

export enum CallMode {
  OUTBOUND = 'Outbound',
  INBOUND = 'Inbound',
  DIALERBOT = 'DialerBot',
  PROGRESSIVE = 'Progressive',
  PREDICTIVE = 'Predictive',
  POWERDIALER = 'Powerdialer',
  VIDEOCALL = 'VideoCall',
  INTERNAL = 'Internal'
}
export enum CallResult {
  ABANDONED = 'Abandoned',
  ANSWERINGMACHINE = 'Answeringmachine',
  ATTENDED = 'Attended',
  AUTOATTENDED = 'Autoattended',
  CANCELED = 'Canceled',
  CIRCUIT = 'Circuit',
  CONGESTION = 'Congestion',
  FAILED = 'Failed',
  INCOMING = 'Incoming',
  INTERNAL = 'Internal',
  INVALID = 'Invalid',
  MISSED = 'Missed',
  OUTGOING = 'Outgoing',
  OVERFLOW = 'Overflow',
  PARKED = 'Parked',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  ROBINSON = 'Robinson',
  ROUTEHANGUP = 'RouteHangup',
  TRANSFERRED = 'Transferred',
  UNANSWERED = 'Unanswered',
  UNKNOWN = 'Unknown'
}

export enum DelayTimeUnitType {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MICROSECONDS = 'MICROSECONDS',
  MILLISECONDS = 'MILLISECONDS',
  MINUTES = 'MINUTES',
  NANOSECONDS = 'NANOSECONDS',
  SECONDS = 'SECONDS'
}

export const callModeOptions = Object.values(CallMode);
export const callResultOptions = Object.values(CallResult);
export const delayTimeUnitOptions = Object.values(DelayTimeUnitType);

export const DelayTimeUnitText: {
  [key in DelayTimeUnitType]: string;
} = {
  [DelayTimeUnitType.DAYS]: 'Days',
  [DelayTimeUnitType.HOURS]: 'Hours',
  [DelayTimeUnitType.MICROSECONDS]: 'Microseconds',
  [DelayTimeUnitType.MILLISECONDS]: 'Milliseconds',
  [DelayTimeUnitType.MINUTES]: 'Minutes',
  [DelayTimeUnitType.NANOSECONDS]: 'Nanoseconds',
  [DelayTimeUnitType.SECONDS]: 'Seconds'
};

export const CallCampaignModes = [
  CallMode.OUTBOUND,
  CallMode.INBOUND,
  'DialerBot',
  'Progressive',
  'Predictive'
];

export interface TicketResolutionConfig {
  onMaxCallRelaunches: string;
}

export interface FormFieldVideoCall {
  contactFieldId: string;
  labelName: string;
  type: ContactFieldType;
}

export type SocialmediaQueueConfig = {
  assignStrategy: AssignStrategy;
  pauseUnavailableMembers: boolean;
};

export enum AssignStrategy {
  ALL = 'All',
  LEAST_RECENT = 'LeastRecent',
  ROUND_ROBIN = 'RoundRobin',
  LEAVE_QUEUED = 'LeaveQueued'
}

export enum CampaignOrientation {
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound'
}

export type Campaign = {
  accountId: string;
  adminTime: number;
  channel: ChannelType;
  companyId: string;
  conversationTimeoutMins: number;
  description: string;
  enabled: boolean;
  endDate: string;
  holidays: Holidays[];
  id: string;
  ignoreExternalAction: boolean;
  name: string;
  serviceId: string;
  startDate: string;
  users: string[];
  autoCloseConvOnLastTicketEdited: boolean;
  /**
   * If truthy, the campaign is a draft. Otherwise, it is not
   */
  draftId?: string;
  /**
   * Useful to show if the campaign draft is definitely added to the service draft
   */
  isDraftAddedToService?: boolean;
} & (CallCampaign | SocialmediaCampaign);

export type CallCampaign = {
  channel: ChannelType.CALL;
  allowCreatingManualReschedules: boolean;
  autoTicketResolutionConfig: TicketResolutionConfig;
  dayHours: WeekTimes;
  forceAutoanswer: boolean;
  recordPercentage: number;
  recordingNamePattern: string;
  relaunchResolutionConfig: {
    [subResolutionId: string]: RelaunchResolutionConfig;
  };
} & (
  | InboundCallCampaign
  | OutboundCallCampaign
  | VideoCallCampaign
  | CallEngineCampaign
);

export type CallEngineCampaign = {
  allowRescheduling: boolean;
  callMode: Exclude<
    CallMode,
    CallMode.INBOUND | CallMode.OUTBOUND | CallMode.VIDEOCALL
  >;
  detectionAmd: boolean;
  endingRouteId: string;
  inboundRouteId: string;
  maxSimultaneousCalls: number;
  outboundRouteId: string;
  percentageReservedRelaunches: number;
  queueConfig: Queue;
  relaunchConfig: RelaunchConfig;
  timeout: number;
} & (
  | DialerBotCampaign
  | PowerdialerCampaign
  | ProgressiveCampaign
  | PredictiveCampaign
);

export type DialerBotCampaign = {};
export type ProgressiveCampaign = {
  forceAutoanswer: boolean;
};

export type PowerdialerCampaign = {
  powerdialerType: PowerdialerType;
};

export type PredictiveCampaign = {
  abandonedRate: number;
  forceAutoanswer: boolean;
};

export type InboundCallCampaign = {
  callMode: CallMode.INBOUND;
  queueConfig: Queue;
  relaunchConfig: RelaunchConfig;
};

export type OutboundCallCampaign = {
  anonymousCall: boolean;
  callMode: CallMode.OUTBOUND;
  defaultPrefix: string;
  endingRouteId: string;
  outboundRouteId: string;
  relaunchConfig: RelaunchConfig;
};

export type VideoCallCampaign = {
  callMode: CallMode.VIDEOCALL;
  formFields: FormFieldVideoCall[];
};

export type SocialmediaCampaign = {
  channel: Exclude<ChannelType, ChannelType.CALL>; // All channels except CALL
  channelData?: EmailChannelData;
} & (SocialmediaInboundCampaign | SocialmediaOutboundCampaign);

export type EmailChannelData = {
  emailSignatureId?: string;
};

export type SocialmediaInboundCampaign = {
  orientation: CampaignOrientation.INBOUND;
  queueConfig: SocialmediaQueueConfig;
};

export type SocialmediaOutboundCampaign = {
  accountId: string;
  orientation: CampaignOrientation.OUTBOUND;
};

export interface RelaunchResolutionConfig {
  allowFirstCallManualReschedule?: boolean;
  campaignId: string;
  delayAmount?: number;
  delayTimeUnit: DelayTimeUnitType;
  rescheduleLimit: number;
  assignToSameAgent: boolean;
}

export function isOutboundCampaign(campaign: Campaign): boolean {
  if (campaign.channel !== ChannelType.CALL)
    return campaign.orientation === CampaignOrientation.OUTBOUND;

  return (
    campaign.callMode === CallMode.OUTBOUND ||
    campaign.callMode === CallMode.VIDEOCALL
  );
}

export function isInboundCampaign(campaign: Campaign) {
  if (campaign.channel === ChannelType.CALL) {
    return [
      CallMode.INBOUND,
      CallMode.PROGRESSIVE,
      CallMode.PREDICTIVE
    ].includes(campaign.callMode);
  }

  return campaign.orientation === CampaignOrientation.INBOUND;
}

export function isVideoCallCampaign(campaign: Campaign) {
  return (
    campaign.channel === ChannelType.CALL &&
    campaign.callMode === CallMode.VIDEOCALL
  );
}
