import { detectDelimiter } from './detectDelimiter';

export function getCSVHeadersAndDelimiter(
  file: File,
  delimiter?: string
): Promise<{
  headers: string[];
  delimiter: string;
}> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let text = '';

    reader.onload = (event) => {
      text = event.target.result as string;
      delimiter = delimiter ?? detectDelimiter(text);
      const endOfLineIndex = text.indexOf('\n');
      if (endOfLineIndex !== -1) {
        reader.abort(); // Detiene la lectura
        const firstLine = text.slice(0, endOfLineIndex);
        const headers = firstLine
          .split(delimiter)
          .map((header) => header.trim());
        resolve({
          headers,
          delimiter
        });
      }
    };

    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing CSV file'));
    };

    // Leer el archivo en trozos
    const chunkSize = 1024; // Tamaño del fragmento en bytes
    let offset = 0;

    function readChunk(file: File) {
      const blob = file.slice(offset, Math.min(offset + chunkSize, file.size));

      reader.readAsText(blob);

      offset += chunkSize;
    }

    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        if (text.indexOf('\n') === -1) {
          readChunk(file); // Si no se ha encontrado el fin de la primera línea, sigue leyendo
        }
      }
    };

    readChunk(file);
  });
}

export function detectDelimiterFromColumnValue(
  file: File,
  delimiter: string,
  tagsColumn: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let text = '';

    reader.onload = (event) => {
      text = event.target.result as string;
      const lines = text.split('\n');
      if (lines.length > 1) {
        reader.abort(); // Detiene la lectura
        const headers = lines[0]
          .split(delimiter)
          .map((header) => header.trim());
        const tagsColumnIndex = headers.indexOf(tagsColumn);

        const firstRow = lines[1].split(delimiter).map((value) => value.trim());
        const tags = firstRow[tagsColumnIndex];
        const tagsDelimiter = detectDelimiter(tags);

        resolve(tagsDelimiter);
      }
    };

    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing CSV file'));
    };

    // Leer el archivo en trozos
    const chunkSize = 2048; // Aumenta el tamaño del fragmento para leer dos líneas
    let offset = 0;

    function readChunk(file: File) {
      const blob = file.slice(offset, Math.min(offset + chunkSize, file.size));

      reader.readAsText(blob);

      offset += chunkSize;
    }

    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        if (text.split('\n').length < 2) {
          readChunk(file); // Si no se han encontrado suficientes líneas, sigue leyendo
        }
      }
    };

    readChunk(file);
  });
}
