import { VideoCallParticipant } from 'src/modules/VideoCall/types/videoCallTypes';
import { useAudioLevel } from './hooks/useAudioLevel';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material';
import { HeadsetOff, MicOff } from '@mui/icons-material';

interface Props {
  stream: MediaStream;
  participant: VideoCallParticipant;
}
export default function VideoCardAudioLevel({ stream, participant }: Props) {
  const audioLevel = useAudioLevel(stream);

  const theme = useTheme();
  const isMuted = participant?.muted;
  const isDeafen = participant?.deafen;

  return (
    <motion.div
      layoutId={`audioLevelParent-${participant?.id}`}
      style={{
        position: 'absolute',
        bottom: 8,
        right: 8,
        height: '30px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '6px',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '6px',
        gap: '2px'
      }}
    >
      {isDeafen && <HeadsetOff />}
      {!isDeafen && isMuted && <MicOff />}
      {!isDeafen &&
        !isMuted &&
        [...Array(10)].map((_, index) => {
          const isActive = audioLevel > 0.01 * (index + 1);
          return (
            <motion.div
              key={index}
              animate={{
                height: isActive ? '100%' : '80%'
              }}
              transition={{
                type: 'spring',
                damping: 10,
                stiffness: 100
              }}
              style={{
                width: '4px',
                backgroundColor: isActive
                  ? theme.colors.secondary.main
                  : 'rgba(255, 255, 255, 0.2)'
              }}
            />
          );
        })}
    </motion.div>
  );
}
