import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState, useRef, useCallback } from "react";
import { ChannelType } from "src/models/conversations/conversations";
import { ConversationListItemCTI } from "./ConversationListItemCTI";
import { useClosedConversationsInfiniteQuery } from "src/services/conversations/domain/getClosedConversations";
import { t } from "i18next";
import { getChannelIcon } from "../utils/getChannelIcon";

const CONVERSATIONS_LIST_PAGE_SIZE = 30;

export const ConversationHistoryCTI = () => {
  const [paginationInfo, setPaginationInfo] = useState({
    actualPage: 0,
    totalPages: 1,
  });
  const [channel, setChannel] = useState(undefined);

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isFetching } =
    useClosedConversationsInfiniteQuery({
      size: CONVERSATIONS_LIST_PAGE_SIZE,
      channel: channel,
    });

  const list = data?.pages.flatMap((page) => page.elements) ?? [];

  const indexToFetch = Math.ceil(
    list.length - CONVERSATIONS_LIST_PAGE_SIZE / 2
  );
  const conversationIdToFetch = list.length > 0 ? list[indexToFetch]?.id : null;

  let conversationsPerDate = list.reduce((newArray, conversation) => {
    let dateObject = conversation.metadata
      ? new Date(conversation.metadata?.modificationDate)
      : new Date();
    let date = dateObject.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });
    if (!newArray[date]) {
      newArray[date] = [];
    }
    newArray[date].push(conversation);
    return newArray;
  }, {});

  const observer = useRef<IntersectionObserver | null>(null);

  const lastConversationElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isFetchingNextPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, hasNextPage, fetchNextPage, isFetching, paginationInfo]
  );

  return (
    <Stack height={"500px"} width="100%" p={1}>
      <Stack direction="row" width="100%" columnGap={1}>
        <TextField
          select
          size="small"
          label={t("Filter by channel")}
          fullWidth
          value={channel}
          onChange={(e) => {
            setChannel(e.target.value);
          }}
        >
          {Object.values(ChannelType).map((channel, index) => {
            return (
              <MenuItem value={channel} key={index}>
                <Stack direction="row" columnGap={1} alignItems={"center"}>
                  {getChannelIcon(channel)}
                  {t(channel)}
                </Stack>
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>

      <Stack
        direction="column"
        flexGrow={1}
        flexBasis={0}
        overflow="auto"
        width="100%"
        alignItems={"center"}
        spacing={1}
      >
        {Object.keys(conversationsPerDate).map((date, index) => {
          // Group conversations by date
          return (
            <Box width="100%" key={index}>
              <Stack width="100%" px={0.5} pt={1}>
                <Typography color="GrayText" fontSize={16} fontWeight={"bold"}>
                  {date}
                </Typography>
              </Stack>

              <Stack
                key={index}
                width="100%"
                direction="column"
                alignItems={"center"}
                spacing={0}
              >
                {conversationsPerDate[date].map((conv, idx) => {
                  const isConversationToFetch =
                    conv.id === conversationIdToFetch;
                  return (
                    <Box
                      key={idx}
                      ref={
                        isConversationToFetch
                          ? lastConversationElementRef
                          : null
                      }
                      width={"100%"}
                    >
                      <ConversationListItemCTI conversation={conv} />
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          );
        })}
        {isFetching && (
          <Stack width="100%" py={1} alignItems={"center"}>
            <CircularProgress size={24} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
