import axios from 'axios';
import { REPORTING_ATTACHMENT_DOWNLOAD } from 'src/utils/apiRoutes/msReporting';

export const downloadReportingAttachmentApi = async (attachmentId: string) => {
  const response = await axios.get(REPORTING_ATTACHMENT_DOWNLOAD, {
    params: { id: attachmentId },
    responseType: 'blob'
  });

  return response;
};
