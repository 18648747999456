import store, { useSelector } from 'src/store/store';

/** GET RTQUEUES FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of Queues
 */
export const getRTQueuesStore = (shouldUseHook: boolean) => {
  const allRTQueues = shouldUseHook
    ? useSelector((state) => state.rtusers.rtQueues)
    : store.getState().rtusers.rtQueues;

  return Object.values(allRTQueues);
};

export const getRTQueueIdsStore = (shouldUseHook: boolean) => {
  const allRTQueuesIds = shouldUseHook
    ? useSelector((state) => state.rtusers.rtQueuesIds)
    : store.getState().rtusers.rtQueuesIds;

  return allRTQueuesIds;
};
