import { useSnackbar } from 'notistack';
import { UploadCsvResponse } from '../../models/models';
import { UploadCSVSnackbarContent } from './UploadCSVSnackbarContent';
import { useTheme } from '@mui/material';

export const useUploadCSVSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();

  const renderContent = (uploadSummary: UploadCsvResponse) => (
    <UploadCSVSnackbarContent
      uploadSummary={uploadSummary}
      onClose={(key) => {
        closeSnackbar(key);
      }}
    />
  );

  const enqueueUploadCSVSnackbar = (uploadSummary: UploadCsvResponse) => {
    enqueueSnackbar(null, {
      variant: 'info',
      key: uploadSummary.timeElapsed.toString(),
      persist: true,
      preventDuplicate: true,
      // At the top center, the alerts from showAlert are already displayed
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      content: renderContent(uploadSummary)
    });
  };
  return { enqueueUploadCSVSnackbar };
};
