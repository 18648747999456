import { AxiosError } from 'axios';
import { DataErrorMessage } from '../models/apiCalls';

/**Returns the url with the replaced params
 * @param url string
 * @param params Object: { param1: value1, param2: value2, ... }
 * @returns replacedURL string
 * * e.g., replaceURLParams('https://api.dev.speechlens.com/companies/{id}', { '{id}': 1234 }) returns 'https://api.dev.speechlens.com/companies/1234'
 **/
export const replaceURLParams = (url: string, params: {}): string => {
  var replacedURL = url;
  for (const key in params) {
    replacedURL = replacedURL.replace(key, params[key]);
  }
  return replacedURL;
};

export const getErrorMessage = (
  error: AxiosError<DataErrorMessage>
): { errorTitle: string; errorMessage: string } => {
  return {
    errorTitle: error?.response?.data?.error ?? 'Error',
    errorMessage: error?.response?.data?.message ?? 'An error occurred'
  };
};
