import { getCameraDeviceStore } from 'src/services/webrtc/infrastructure/store/devices/getCameraDeviceStore';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';
import { getVoiceDeviceStore } from 'src/services/webrtc/infrastructure/store/devices/getMicDeviceStore';
import WebRTCDevices from 'src/services/webrtc/webrtc.devices';
import { DeviceStorageKey } from './utils/constants';
import { memo, useContext, useEffect } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import VideoWebRTCDevices, {
  VIDEOCALLS_WEBRTC_STREAM,
  videoCameraDeviceKey
} from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';
import { stream } from 'exceljs';
import { setCameraDeviceStore } from 'src/services/webrtc/infrastructure/store/devices/setCameraDeviceStore';

/**
 * Use Camera, returns the used camera, all the devices, and a function to change between them.
 *      The function receives the label of the device that is going to change.
 */
export const useCamera = () => {
  const cameraSelected = getCameraDeviceStore(true);
  const mediaDevices = getDevicesStore(true).filter(
    (d) => d.kind === 'videoinput'
  );

  const { streams, rtcSession, videoRoom } = useContext(VideocallRoomContext);

  const onSelectChange = async (labelSelected: string) => {
    const newDevice = mediaDevices.filter((device) => {
      return device.label === labelSelected;
    })[0];

    if (!videoRoom?.room?.id) return;
    const newStream = await VideoWebRTCDevices.requestLocalStream({
      constraints: {
        video: {
          deviceId: newDevice.deviceId,
          aspectRatio: 16 / 9
        }
      },
      enableVideo: true
    });
    const localStream = streams.local;
    localStream.getTracks().forEach((track) => {
      localStream.removeTrack(track);
    });
    localStream.addTrack(newStream?.getVideoTracks()[0]);
    streams.setLocalStream(localStream);

    console.log('[VIDEO.WEBRTC.DEVICES] SESSION ->', rtcSession);
    if (rtcSession.currentRTCSession) {
      console.log('[VIDEO.WEBRTC.DEVICES]  ->', rtcSession);
      VideoWebRTCDevices.replaceStreamOnRTCSession({
        stream: newStream,
        rtcSession: rtcSession.currentRTCSession,
        kind: 'video'
      });
    }
    localStorage.setItem(DeviceStorageKey.CAMERA, JSON.stringify(newDevice));
    setCameraDeviceStore(newDevice);
  };

  useEffect(() => {
    const savedDeviceId = localStorage.getItem(videoCameraDeviceKey);
    let deviceToSelect = mediaDevices[0]; // Default to the first available device

    if (savedDeviceId) {
      const savedDevice = mediaDevices.find(
        (device) => device.deviceId === savedDeviceId
      );
      if (savedDevice) {
        deviceToSelect = savedDevice;
      }
    }

    if (deviceToSelect) {
      onSelectChange(deviceToSelect.label);
    }
  }, []);

  return [cameraSelected, mediaDevices, onSelectChange] as [
    MediaDeviceInfo,
    MediaDeviceInfo[],
    Function
  ];
};
