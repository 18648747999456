import { Conversation } from 'src/models/conversations/conversations';
import {
  ConversationMessage,
  MessageStatus
} from 'src/models/conversations/messages';
import { addMessageToConversation } from 'src/services/socialmedia/domain/messages/addMessageToConversation';
import { markConversationAsRead } from 'src/store/slices/socialmedia/socialmediaSlice';
import store from 'src/store/store';

export const readMessageStore = (message: ConversationMessage) => {
  message = {
    ...message,
    status: MessageStatus.READ
  };
  addMessageToConversation({ message });
};

// export const readMessagesStore = (
//   messages: Message[],
//   conversation?: Conversation
// ) => {
//   if (!messages || messages?.length <= 0) return;

//   // Get conversation if not provided from arguments
//   if (!conversation)
//     conversation = getConversation({
//       id: messages[0].conversationId,
//       shouldUseHook: false
//     });

//   // If there arent any unread messages, dont do anything
//   const unreadMessages = getUnreadMessages(conversation);
//   if (unreadMessages?.length === 0) return;

//   // Update conversation messages
//   messages = messages?.map((msg) => {
//     if (msg.orientation === 'Outgoing') return msg;

//     return { ...msg, messageData: { ...msg.messageData, status: 'Read' } };
//   });

//   setConversationMessages(conversation, messages);
// };

export const readMessagesStore = (conversation: Conversation) => {
  store.dispatch(markConversationAsRead(conversation));
};
