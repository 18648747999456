import axios from 'axios';
import { ContactGroup } from '../../models/contacts';
import { CONTACT_GROUP_ID } from 'src/utils/apiRoutes/msContacts';
import { replaceURLParams } from 'src/services/utils';

export interface ContactGroupsFilters {
  companyId?: string;
  name?: string;
  description?: string;
}

export const getContactGroupByIdApi = async (
  id: string
): Promise<ContactGroup> => {
  const url = replaceURLParams(CONTACT_GROUP_ID, { '{id}': id });
  const request = await axios.get(url);
  return request.data;
};
