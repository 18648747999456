import {
  SendWhatsAppMessageNewTemplateApiParams,
  sendWhatsAppMessageNewTemplateApi
} from '../../infrastructure/message/sendWhatsAppMessageNewTemplateApi';
import {
  SendWhatsAppMessageExistingTemplateApiParams,
  sendWhatsAppMessageExistingTemplateApi
} from '../../infrastructure/message/sendWhatsAppMessageExistingTemplateApi';
import { WhatsAppTemplateVariables } from '../../models/WhatsAppTemplate';

export type SendWhatsAppMessageTemplateParams = {
  file?: File;
  templateParams: WhatsAppTemplateVariables;
  templateId: string;
  accountId: string;
  conversationId: string;
  phoneNumber?: string;
  campaignId?: string;
  ticketId?: string;
};

/**
 * Send a text message template.
 * Provide the phoneNumber or username param to start a new WhatsApp conversation.
 * Provide the conversationId to send a message to an existing conversation.
 * @param params
 */
export const sendWhatsAppMessageTemplate = async (
  params: SendWhatsAppMessageTemplateParams
) => {
  // NEW CONVERSATION
  if (params?.campaignId) {
    const apiNewParams: SendWhatsAppMessageNewTemplateApiParams = {
      file: params?.file ?? undefined,
      phoneNumber: params.phoneNumber,
      campaignId: params.campaignId,
      templateParams: params.templateParams,
      templateId: params.templateId,
      ticketId: params?.ticketId
    };
    await sendWhatsAppMessageNewTemplateApi(apiNewParams);
  }
  // EXISTING CONVERSATION
  if (params?.accountId) {
    const apiExistingParams: SendWhatsAppMessageExistingTemplateApiParams = {
      accountId: params.accountId,
      file: params.file,
      conversationId: params.conversationId,
      templateParams: params.templateParams,
      templateId: params.templateId
    };
    await sendWhatsAppMessageExistingTemplateApi(apiExistingParams);
  }
};
