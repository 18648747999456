import axios from 'axios';
import { ContactTag } from 'src/modules/Contacts/models/contacts';
import { TAGS } from 'src/utils/apiRoutes/msContacts';

export const createTagApi = async (
  companyId: string,
  name: string,
  contactGroupId: string
): Promise<ContactTag> => {
  const data = {
    companyId: companyId,
    name: name,
    contactGroupId: contactGroupId
  };

  const resp = await axios.post(TAGS, data);
  return resp.data;
};
