import {
  Conversation,
  ConversationStatus
} from 'src/models/conversations/conversations';
import { RTCall } from 'src/models/rtcall';
import {
  getStoredConversations,
  isConversationInFilteredConversations
} from '../../../conversations/domain/getConversations';
import { getCurrentConversation } from './getCurrentConversation';
import { refreshConversationMessages } from './refreshConversationMessages';
import { setCurrentConversation } from './setCurrentConversation';
import { logger } from 'src/utils/logger';
import { selectConversation } from 'src/modules/Dashboard/views/Inbox/domain/handleConversations';
import { sortAndFilterConversations } from './getConversationFilters';
import { getConversationsStore } from '../../infraestructure/store/getConversationsStore';
import AuthManager from 'src/services/authentication/manager';
import { HOTLINE_COMPANIES } from './utils';
import getAutoselectNextConversationDomain from './getAutoselectNextConversationDomain';
import setAutoselectNextConversationDomain from './setAutoselectNextConversationDomain';
import { dummyConversation } from 'src/mocks/conversation';

/**  SELECTS THE CORRECT CONVERSATION TO DISPLAY IN THE INBOX
 *   - In the first render, selects the first conversation in the list
 *   - When a new conversation is created by the agent, the conversation is selected
 *   - If was already selected, the messages are refreshed
 */
export const selectProperConversation = () => {
  const storedConversations = getConversationsStore(false);
  const userId = AuthManager.getUserId();
  const companyId = AuthManager.getLoggedUserCompanyId();
  const sortedFilteredConversations: Conversation[] =
    sortAndFilterConversations(storedConversations, userId, true);

  const currentConversation = getCurrentConversation(false);
  if (
    sortedFilteredConversations.length == 0 &&
    currentConversation &&
    currentConversation.assignedUserIds.includes(userId)
  ) {
    // DES-SELECCIONAR CONVERSACION
    setCurrentConversation(null);
    return;
  }

  const firstConversationOnList = sortedFilteredConversations[0];

  // When a new conversation is created by the agent, the conversation is selected
  const autoSelect = getAutoselectNextConversationDomain();
  if (autoSelect) {
    setAutoselectNextConversationDomain(false);
    selectConversation(firstConversationOnList);
    return;
  }

  const currentConversationPassFilters =
    isConversationInFilteredConversations(currentConversation);

  if (currentConversationPassFilters) {
    refreshConversationMessages(currentConversation);
    return;
  }

  // IF THE COMPANY IS HOTLINE, THE SGI CONVERSATION SHOULD BE SELECTED IN THE INBOX
  if (HOTLINE_COMPANIES.includes(companyId)) {
    selectConversation(dummyConversation);
  } else {
    selectConversation(firstConversationOnList);
  }
};

export const selectRTCallConversation = (rtCall: RTCall) => {
  const allConversations: Conversation[] = getStoredConversations({
    status: [ConversationStatus.ASSIGNED],
    shouldUseHook: false,
    callId: rtCall.callId
  });

  const conversation = allConversations[0];
  if (!conversation) {
    logger.error('Couldnt find Conversation from RTCall!', rtCall);
    return;
  }
  selectConversation(conversation);
};
