import wsManager from 'src/services/websocket/manager';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import WebRTCService from 'src/services/webrtc/webrtc.service';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { getSessionObject } from '../utils/helpers';
import { setSession } from 'src/store/slices/session/session';
import JsSIP from 'jssip';
import { useCustomEventListener } from 'react-custom-events';
import { logger } from 'src/utils/logger';
import { RenewWSMessage } from 'src/models/auth';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { getWebsocketIdDomain } from 'src/services/websocket/domain/getWebsocketIdDomain';
import { refreshRequestDomain } from 'src/services/authentication/domain/refreshRequestDomain';
import { logoutCurrentUser } from 'src/services/authentication/domain/logoutCurrentUser';
import AuthManager from 'src/services/authentication/manager';
import { useBroadcastChannel } from './useBroadcastChannel';
import { v4 as uuidv4 } from 'uuid';

const useSession = () => {
  const [webRTC, setWebRTC] = useState<JsSIP.WebSocketInterface>();
  const [wsClient, setWsClient] = useState<W3CWebSocket>();
  const dispatch = useDispatch();
  const wsTimeoutIdRef = useRef<NodeJS.Timeout>(null);
  const webrtcTimeoutIdRef = useRef<NodeJS.Timeout>(null);
  const renewTimeoutRef = useRef(null);
  const isRenewingTokenRef = useRef(false);
  const onMessage = (message: { tabId: string }) => {
    const { tabId } = message;
    if (currentTabId !== tabId)
      logger.log('[TOKEN] currenTab:', currentTabId, '; renewalTab:', tabId);
  };
  const { postMessage } = useBroadcastChannel({
    name: 'tokenRenewal',
    onMessage
  });
  const currentTabId = useRef(uuidv4()).current;

  const connectWebsocket = async (): Promise<W3CWebSocket> => {
    if (wsManager.getWSClient()?.readyState === W3CWebSocket.OPEN)
      return wsManager.getWSClient();

    const wsClient = await wsManager.startWs();
    setWsClient(wsClient);

    return wsClient;
  };

  const connectWebRTC = (): JsSIP.WebSocketInterface => {
    const extensionData = localStorage.getItem('extensionData');
    if (!extensionData) return;
    const webRTCClient = WebRTCService.initWebRTC(getSessionObject().voipData);
    setWebRTC(webRTCClient);

    return webRTCClient;
  };

  const renewToken = () => {
    const websocketId = getWebsocketIdDomain(false);
    refreshRequestDomain(websocketId);
  };

  const handleRenewToken = (token?: string, expiresInMs?: number) => {
    const thresholdMin = 20 * 1000;
    const thresholdMax = 40 * 1000;
    // The token comes from token param or from cookies
    const authToken = token ?? AuthManager.getToken();
    // The expiresIn comes from expiresInMs param or from cookies
    const expiresIn = expiresInMs ?? AuthManager.getExpiresInMs();
    const expiration: string = AuthManager.getExpiration();
    const currentTime = new Date().getTime();
    const tokenExpirationTime = currentTime + expiresIn;
    const isTokenExpired = !expiresIn || !authToken;
    // The threshold should be smaller than tokenExpirationTime - currentTime
    // The threshold is obtained randomly to prevent multiple browser tabs from sending token renewal requests at the same time
    const threshold = Math.min(
      Math.floor(Math.random() * (thresholdMax - thresholdMin + 1)) +
        thresholdMin,
      expiresIn
    );
    logger.log('[TOKEN] isRenewingTokenRef', isRenewingTokenRef.current);
    if (!isRenewingTokenRef.current) {
      clearTimeout(renewTimeoutRef.current);
      logger.log('[TOKEN] expiresIn:', expiresIn);
      logger.log('[TOKEN] authToken:', authToken);
      logger.log('[TOKEN] currentTime:', new Date(currentTime).toISOString());
      logger.log(
        '[TOKEN] tokenExpirationTime (currentTime + expiresIn):',
        new Date(tokenExpirationTime).toISOString()
      );
      logger.log('[TOKEN] threshold:', threshold);
      logger.log('[TOKEN] isRenewingTokenRef:', isRenewingTokenRef.current);
      if (isTokenExpired) {
        logger.log(
          '[TOKEN] Logging out at handleRenewToken, isTokenExpired is true'
        );
        logoutCurrentUser();
      } else {
        logger.log('[TOKEN] Setting isRenewingTokenRef to true');
        isRenewingTokenRef.current = true;
        const timeout = expiresIn - threshold;
        logger.log(
          '[TOKEN] Token should be renewed at:',
          new Date(currentTime + timeout).toISOString()
        );
        logger.log(
          `[TOKEN] Expiration token date: ${new Date(Number(expiration)).toISOString()}`
        );
        renewTimeoutRef.current = setTimeout(() => {
          const currentTime = new Date().getTime();
          // If the current time is smaller than the difference between the token expiration time and the start threshold,
          // the token is not renewed. So, another tab has already renewed the token
          if (currentTime >= tokenExpirationTime - thresholdMax) {
            renewToken();
            postMessage({
              tabId: currentTabId
            });
          }
          logger.log('[TOKEN] Setting isRenewingTokenRef to false');
          isRenewingTokenRef.current = false;
        }, timeout);
      }
    }
  };

  useCustomEventListener(
    'realtime-connection-changed',
    (status: boolean) => {
      logger.log('Connection changed! Status: ', status);

      if (!status && !wsTimeoutIdRef.current) {
        logger.log('Reconnecting...', new Date());
        wsTimeoutIdRef.current = setTimeout(() => {
          connectWebsocket();
          wsTimeoutIdRef.current = null;
        }, 5000);
      }
    },
    []
  );

  useCustomEventListener(
    'webrtc-connection-changed',
    (status: boolean) => {
      logger.log('[WEBRTC] Connection changed! Status: ', status);

      if (!status && !webrtcTimeoutIdRef.current) {
        logger.log('[WEBRTC] Reconnecting...');
        webrtcTimeoutIdRef.current = setTimeout(() => {
          connectWebRTC();
          webrtcTimeoutIdRef.current = null;
        }, 5000);
      }
    },
    []
  );

  // RENEW THE TOKEN WHEN RENEW EVENT
  useCustomEventListener(CustomEventNames.RENEW, (data: RenewWSMessage) => {
    handleRenewToken(data?.token, data?.expiresInMs);
  });

  useEffect(() => {
    // Connecting Websocket and WebRTC for the first time
    function connect() {
      connectWebsocket();
      connectWebRTC();
    }
    connect();
  }, []);

  useEffect(() => {
    dispatch(setSession(getSessionObject()));
  }, [wsClient, webRTC]);
};

export default useSession;
