import { Conversation } from 'src/models/conversations/conversations';
import { queryClient } from 'src/utils/queryClient';
import { getConversationReportingTickets } from '../../domain/reporting';

export const getConversationReportinTicketsQuery = (
  conversation: Conversation
) => {
  return queryClient.fetchQuery({
    queryKey: ['reportingConversationTicket', conversation?.id],
    queryFn: () => {
      return getConversationReportingTickets(conversation);
    },
    staleTime: 30 * 1000
  });
};
