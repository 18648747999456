import { useDispatch, useSelector } from 'src/store/store';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';
import { connectDevices } from 'src/services/webrtc/webrtc.service';
import { setAudioDevice } from 'src/store/slices/users/configVolumeSlice';
import { DeviceStorageKey } from './utils/constants';

export const useSpeaker = () => {
  const { audioDevice } = useSelector((state) => state.configVolume);
  const dispatch = useDispatch();

  const mediaDevices = getDevicesStore(true).filter((d) => {
    return d.kind === 'audiooutput';
  });

  const onSelectChange = (labelSelected: string): void => {
    const newDevice = mediaDevices.filter((device) => {
      return device.label === labelSelected;
    })[0];

    dispatch(setAudioDevice(newDevice));
    localStorage.setItem(DeviceStorageKey.AUDIO, JSON.stringify(newDevice));

    connectDevices(
      'audioDevice',
      ['webrtc-audio-remote', 'webrtc-answer', 'webrtc-hangup', 'call-test'],
      setAudioDevice
    );
  };

  return [audioDevice, mediaDevices, onSelectChange] as [
    MediaDeviceInfo,
    MediaDeviceInfo[],
    (labelSelected: string) => void
  ];
};
