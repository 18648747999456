import { getDashboardsApi } from '../infrastructure/api/getDashboards';
import {
  addDashboardPanel,
  createDashboard,
  updateDashboardById
} from '../infrastructure/api/postDashboards';
import { Dashboard, DashboardPanelParams } from '../models/Dashboards';
import {
  subscriptionToDashboard,
  UnSubscriptionToDashboard
} from '../infrastructure/websocket/subscription';

/** CREATE A NEW DASHBOARD
 *
 * Returns the new dashboard
 * @returns {Dashboard} Dashboard
 */
export const createNewDashboard = async (
  dashboard: Dashboard
): Promise<Dashboard> => {
  return createDashboard(dashboard);
};

/** UPDATE A  DASHBOARD
 *
 * Returns the updated dashboard
 * @returns {Dashboard} Dashboard
 */
export const updateDashboard = async (
  dashboardId: string,
  dashboard: Dashboard
): Promise<Dashboard> => {
  delete dashboard.id;
  return updateDashboardById(dashboardId, dashboard);
};

/** ADD PANEL TO DAHSBOARD
 *
 * Returns the updated dashboard
 * @returns {Dashboard} Dashboard
 */
export const addPanelToDashboard = async (
  dashboardId: string,
  params: DashboardPanelParams
): Promise<Dashboard> => {
  return addDashboardPanel(dashboardId, params);
};

/** SUBSCRIBE  DAHSBOARD
 *
 * Returns the updated dashboard
 * @returns {Dashboard} Dashboard
 */
export const subscribeToDashboard = (dashboardId: string) => {
  subscriptionToDashboard(dashboardId);
};

/**UNSUBSCRIBE TO DAHSBOARD
 *
 * Returns the updated dashboard
 * @returns {Dashboard} Dashboard
 */
export const unSubscribeToDashboard = (dashboardId: string) => {
  UnSubscriptionToDashboard(dashboardId);
};
