import { LocalParking, PhoneForwarded } from '@mui/icons-material';
import { Stack, useTheme, Box } from '@mui/material';
import { emitCustomEvent } from 'react-custom-events';
import Timer from 'src/components/Timer/timer';
import { RTCall } from 'src/models/rtcall';
import CallManagerDomain from 'src/modules/CTI/CallManager';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getRTUser } from 'src/services/rtusers/domain/rtuser/getRTUser';
import { setActiveRTCallId } from 'src/services/rtusers/domain/rtuser/setActiveRTCall';
import { useGetContactNameQuery } from 'src/services/socialmedia/application/query/getContactsQuery';

interface CallItemProps {
  call: RTCall;
  disableOnClick?: boolean;
  showLinkedEnpoint?: boolean;
}

export const CallItem: React.FC<CallItemProps> = ({
  call,
  disableOnClick,
  showLinkedEnpoint
}) => {
  const theme = useTheme();
  const isParked = call.status === 'Parked';

  const handleUnpark = () => {
    if (disableOnClick) return;
    const activeCall = getActiveRTCall(getConnectedRTUser(false));
    setActiveRTCallId(call.callId);

    if (activeCall.status === 'Talking') {
      CallManagerDomain.parkCall(activeCall);
    }
    if (call.status === 'Parked') {
      CallManagerDomain.unparkCall(call);
    }
    emitCustomEvent('Close popover');
  };

  const endpoint = showLinkedEnpoint
    ? call.linkedEndpoint.endpoint
    : call.currentEndpoint.endpoint;

  const isAgent = endpoint.includes('-');

  const rtUser = isAgent
    ? getRTUser({
        complexExtension: endpoint,
        shouldUseHook: false
      })
    : null;

  const contactNameQuery = useGetContactNameQuery(call.contactId, !isAgent);

  const displayedName = rtUser?.username ?? contactNameQuery?.data ?? endpoint;

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: isParked
            ? theme.colors.secondary.lighter
            : theme.colors.success.lighter,

          borderRadius: '5px'
        }}
        p={1}
        spacing={1}
        onClick={handleUnpark}
      >
        {isParked ? (
          <LocalParking fontSize="medium" color="secondary" />
        ) : (
          <PhoneForwarded fontSize="medium" color="success" />
        )}
        <label
          style={{
            color: theme.colors.primary.main,
            fontWeight: 'bold',
            cursor: 'inherit'
          }}
        >
          {displayedName}
        </label>
        <Timer
          style={{
            color: 'gray'
          }}
          updateTime={call.lastStatusUpdateTime}
        />
      </Stack>
    </Box>
  );
};
