import {
  DateOperator,
  IntegerOperator,
  Operator,
  StringOperator
} from '../models/filters';

export const getOperatorDescription = (operator: Operator) => {
  switch (operator) {
    case IntegerOperator.EQUALS:
      return 'equals';
    case IntegerOperator.NOT_EQUALS:
      return 'not equals';
    case IntegerOperator.GREATHER_THAN:
      return 'greater than';
    case IntegerOperator.GREATHER_THAN_OR_EQUALS:
      return 'greater than or equals';
    case IntegerOperator.LESS_THAN:
      return 'less than';
    case IntegerOperator.LESS_THAN_OR_EQUALS:
      return 'less than or equals';
    case DateOperator.BETWEEN:
      return 'between';
    case DateOperator.NOT_NULL:
      return 'not null';
    case StringOperator.CONTAINS:
      return 'contains';
  }
};
