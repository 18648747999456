import { Stack, Box, styled, useTheme, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { Conversation } from 'src/models/conversations/conversations';
import EmailPanel from 'src/modules/Dashboard/views/Inbox/EmailPanel';
import ChatContent from 'src/modules/Dashboard/views/Inbox/TelegramPanel/ChatContent';
import ChatTopBar from 'src/modules/Dashboard/views/Inbox/TelegramPanel/ChatTopBar';
import { getMessages } from 'src/services/socialmedia/domain/messages/getMessages';

interface Props {
  conversation: Conversation;
}

export const ConversationTab: React.FC<Props> = ({ conversation }) => {
  const theme = useTheme();
  const [conversationWithMessages, setConversationWithMessages] = useState(
    conversation.messages ? conversation : null
  );

  useEffect(() => {
    if (!conversation.messages) {
      getMessages({ conversationId: conversation.id }).then((response) => {
        let newConv = {
          ...conversation,
          messages: response.elements
        } as Conversation;
        setConversationWithMessages(newConv);
      });
    }
  }, []);

  if (!conversationWithMessages)
    return (
      <Stack alignItems={'center'} sx={{ width: '100%' }}>
        <CircularProgress />
      </Stack>
    );

  if (conversationWithMessages.channel === 'Email') {
    return <EmailPanel conversation={conversationWithMessages} />;
  }

  if (
    conversationWithMessages.channel === 'Telegram' &&
    conversationWithMessages.messages?.length === 0
  ) {
    return (
      <Box
        sx={{
          borderRadius: '10px'
        }}
        m={2}
        border={1}
        borderColor={theme.colors.primary.lighter}
        maxHeight={'calc(100vh - 200px)'}
        width={'800px'}
      >
        <h2>No messages</h2>
      </Box>
    );
  }

  return (
    <Stack width="100%" height={'calc(90vh - 162px)'}>
      <ChatTopBar
        key={conversationWithMessages.id}
        conversation={conversationWithMessages}
      />
      <Box height={'100%'}>
        <Scrollbar>
          <ChatContent
            key={conversationWithMessages.id}
            conversation={conversationWithMessages}
          />
        </Scrollbar>
      </Box>
    </Stack>
  );
};
