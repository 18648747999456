import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import HelperPanelHeader from '../components/HelperPanelHeader';
import { Conversation } from 'src/models/conversations/conversations';

import { Helmet } from 'react-helmet-async';
import { Box, Stack, styled, Tab, Tabs, useTheme } from '@mui/material';
import NoteListView from './NoteListView';
import NoteConversationTab from './NoteConversationTab';
import { HelperTab } from '../../HelperMenu/items';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    .MuiTabs-indicator {
      min-height: 4px;
      height: 4px;
      box-shadow: none;
      border: 0;
    }
    .MuiTab-root {
      &.MuiButtonBase-root {
        padding: 0;
        height: 100%;
        margin-right: ${theme.spacing(3)};
        font-size: ${theme.typography.pxToRem(16)};
        color: ${theme.colors.alpha.black[50]};
        .MuiTouchRipple-root {
          display: none;
        }
      }
      &.Mui-selected:hover,
      &.Mui-selected {
        color: ${theme.colors.alpha.black[100]};
      }
    }
  `
);

interface Props {
  conversation: Conversation;
  showHelperTabButtons?: boolean;
  showConversationNotes?: boolean;
}

const NotesPanel = (props: Props) => {
  const {
    conversation,
    showHelperTabButtons,
    showConversationNotes = true
  } = props;
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState<
    'contactsNotes' | 'conversationsNotes'
  >('conversationsNotes');

  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    value: 'contactsNotes' | 'conversationsNotes'
  ): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet>
        <title>{t('Helper Panel - Notes')}</title>
      </Helmet>

      <HelperPanelHeader
        helperTab={HelperTab.NOTES}
        showHelperTabButtons={showHelperTabButtons}
      />

      <Stack
        paddingTop={2}
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <TabsContainerWrapper>
          <Tabs
            value={currentTab}
            onChange={handleTabsChange}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="secondary"
          >
            <Tab
              label={t('Conversations notes')}
              value={'conversationsNotes'}
            />
            <Tab label={t('Contact notes')} value={'contactsNotes'} />
          </Tabs>
        </TabsContainerWrapper>
      </Stack>
      <Box height={`calc(100% - ${theme.header.height} - 56px)`} display="flex">
        {currentTab === 'contactsNotes' && (
          <NoteConversationTab conversation={conversation} />
        )}
        {currentTab === 'conversationsNotes' && showConversationNotes && (
          <NoteListView
            key={conversation.id}
            conversation={conversation}
            filterNote="conversationsNotes"
          />
        )}
      </Box>
    </>
  );
};

export default NotesPanel;
