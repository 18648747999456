import { isDeviceConnected } from '../../webrtc.service';
import { setVoiceVolume } from 'src/store/slices/users/configVolumeSlice';
import { setMicrophoneDeviceStore } from '../setMicDevice';
import { getDevicesStore } from '../../infrastructure/store/devices/getDevicesStore';

async function loadSavedMicrophoneDevice() {
  const localStorageItem = localStorage.getItem('voiceDevice');

  if (!localStorageItem) {
    return undefined;
  }

  const device = JSON.parse(localStorageItem) as MediaDeviceInfo;
  const deviceIsConnected = await isDeviceConnected(device);

  if (!deviceIsConnected) {
    const defaultDevice = getDevicesStore(false).find(
      (d) => d.kind === 'audiooutput'
    );
    if (defaultDevice) {
      setMicrophoneDeviceStore(defaultDevice);
    } else {
      setMicrophoneDeviceStore({
        deviceId: '',
        groupId: '',
        kind: 'audioinput',
        label: 'Default',
        toJSON: () => {}
      });
    }
    return;
  }

  setMicrophoneDeviceStore(device);
}

async function loadSavedMicrophoneVolume() {
  const localStorageItem = localStorage.getItem('voiceVolume');

  if (!localStorageItem) {
    return undefined;
  }

  const volume = JSON.parse(localStorageItem) as number;
  setVoiceVolume(volume);
}

export { loadSavedMicrophoneDevice, loadSavedMicrophoneVolume };
