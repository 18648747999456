import { deleteTicketSubResolutionApi } from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/subResolutions/deleteTicketSubResolutionsApi';
import { TicketSubResolution } from '../../models/tickets';
import { queryClient } from 'src/utils/queryClient';

export const deleteTicketSubResolution = async (id: string) => {
  await deleteTicketSubResolutionApi(id);
};

export const deleteTicketSubResolutions = async (
  ticketSubResolutions: TicketSubResolution[]
) => {
  const subResolutionPromises = ticketSubResolutions.map((subResolution) =>
    deleteTicketSubResolution(subResolution.id)
  );
  await Promise.all(subResolutionPromises);
  queryClient.invalidateQueries({
    queryKey: ['ticketSubResolutions']
  });
};
