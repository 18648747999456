import { cloneElement } from 'react';
import { Person } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  alpha
} from '@mui/material';
import { ChannelType } from 'src/models/conversations/conversations';
import { Ticket } from '../../models/tickets';
import { ContactUI } from 'src/modules/Contacts/models/contacts';
import { useTicketConversationButtons } from '../hooks/useTicketConversationButtons';

interface Props {
  ticket?: Ticket;
  onClick?: (context: { contact: ContactUI; channel: ChannelType }) => void;
}

export const TicketConversationButtons = ({ ticket, onClick }: Props) => {
  const {
    anchorEl,
    contactChannelList,
    getChannelColor,
    getChannelIcon,
    getChannelTitle,
    handleClick,
    handleClose,
    open,
    onClickMenuItem,
    startTicketConversationChannels,
    t,
    theme
  } = useTicketConversationButtons({ ticket, onClick });

  return (
    <Stack mx={1.5} overflow="scroll">
      <Stack
        direction="row"
        overflow="scroll"
        width="100%"
        justifyContent="center"
        alignItems="center"
        gap={0.5}
        borderRadius={1}
        sx={{
          backgroundColor: alpha(theme.colors.alpha.black[100], 0.03)
        }}
      >
        {startTicketConversationChannels.map((channel) => {
          const IconChannel = getChannelIcon(channel);
          const title = getChannelTitle(channel);
          return (
            <>
              <IconButton
                key={channel}
                title={t(title)}
                sx={{
                  color: getChannelColor(theme, channel),
                  fontSize: 'small'
                }}
                onClick={(event) => {
                  handleClick(event, channel);
                }}
              >
                {IconChannel &&
                  cloneElement(IconChannel, { sx: { size: 'small' } })}
              </IconButton>
              <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                {contactChannelList.map((contact, index) => (
                  <MenuItem
                    key={index}
                    dense
                    onClick={() => {
                      onClickMenuItem(contact);
                    }}
                  >
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    {contact?.name ?? t('Unknown name')}
                  </MenuItem>
                ))}
              </Menu>
            </>
          );
        })}
      </Stack>
    </Stack>
  );
};
