import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';

import { Helmet } from 'react-helmet-async';

import { Box, Button, styled, useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import {
  Contact,
  ContactField,
  ContactTag
} from 'src/modules/Contacts/models/contacts';

import { createContact } from 'src/modules/Contacts/domain/createContacts';
import ContactDataForm from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel/ContactDataForm';
import { deleteContact } from 'src/modules/Contacts/domain/deleteContact';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        height: 100%;
        display: flex;
    `
);

interface Props {
  setContact: Dispatch<SetStateAction<Contact>>;
  tags: ContactTag[];
  companyFields: ContactField[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const CreateContactDataView = (props: Props) => {
  const { setContact, tags, companyFields, setIsOpen } = props;

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Create contact</title>
      </Helmet>
      <RootWrapper className="Mui-FixedWrapper">
        <Box
          sx={{
            // minWidth: 360,
            minWidth: 300,
            width: '100%'
          }}
        >
          <Scrollbar>
            <Box display="flex" alignItems="center" justifyContent="start">
              <Button
                color="primary"
                variant="text"
                size="small"
                startIcon={<ArrowBackIosNewIcon />}
                sx={{ marginTop: theme.spacing(0.75) }}
                onClick={() => {
                  setIsOpen(false);
                  setContact(null);
                }}
                id="btnBack-CreateContactDataView"
              >
                {t('Back')}
              </Button>
            </Box>
            <Box p={1}>
              <ContactDataForm
                setContact={setContact}
                onClose={() => setIsOpen(false)}
                onSubmitContact={createContact}
                onDeleteContact={deleteContact}
                isCreatingContact={true}
              />
            </Box>
          </Scrollbar>
        </Box>
      </RootWrapper>
    </>
  );
};

export default CreateContactDataView;
