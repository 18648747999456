import { invalidateContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { createContactFieldApi } from '../infraestructure/api/createContactFieldApi';
import { ContactField, ContactFieldType } from '../models/contacts';
import { queryClient } from 'src/utils/queryClient';

export const createContactField = async (
  contactField: ContactField
): Promise<ContactField> => {
  const response = await createContactFieldApi(contactField);

  invalidateContactFieldsQuery();

  return contactField;
};
