import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  ChannelType,
  Conversation,
  ConversationStatus
} from 'src/models/conversations/conversations';
import { ConversationsDomain } from '../../domain/conversation/ConversationsDomain';
import { PaginatedElements } from 'src/models/pagination';
import { queryClient } from 'src/utils/queryClient';

export const useGetConversationsQuery = (
  params: {
    assignedUserId?: string;
    channel?: ChannelType[];
    companyId?: string;
    contactId?: string;
    page?: number;
    phoneNumber?: string;
    size?: number;
    status?: ConversationStatus[];
    ticketId?: string;
    withMessages?: boolean;
  },
  waitFor: any
): UseQueryResult<PaginatedElements<Conversation>> => {
  const {
    assignedUserId,
    channel,
    companyId,
    contactId,
    page,
    phoneNumber,
    size,
    status,
    ticketId
  } = params;
  return useQuery<PaginatedElements<Conversation>, Error>(
    [
      'conversations',
      [
        assignedUserId,
        channel,
        companyId,
        contactId,
        page,
        phoneNumber,
        size,
        status,
        ticketId
      ].flat()
    ],
    () => ConversationsDomain.getConversations(params),
    { enabled: !!waitFor, staleTime: 60000 }
  );
};

export const useGetConversationByIdQuery = (id: string, enabled?: any) => {
  return useQuery<Conversation, Error>(
    ['conversations', id],
    () => ConversationsDomain.getConversationByIdFromApi(id),
    { enabled: !!enabled, staleTime: 60000 }
  );
};

export function getConversationByIdQuery(id: string) {
  return queryClient.fetchQuery({
    queryKey: ['conversations', id],
    queryFn: () => ConversationsDomain.getConversationByIdFromApi(id),
    staleTime: 60000
  });
}
