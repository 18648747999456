import { useRef, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  Button
} from '@mui/material';
import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { Language } from 'src/models/language';

import { SectionHeading } from './styles';

export const languages: Language[] = [
  { code: 'en', label: 'English' },
  { code: 'de', label: 'Deutsch' },
  { code: 'es', label: 'Español' },
  { code: 'fr', label: 'Français' }
];

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const currentLanguageCode = i18n.language;

  const switchLanguage = (languageCode: string) =>
    internationalization.changeLanguage(languageCode);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSelectLanguage = (languageCode: string) => {
    switchLanguage(languageCode);
    handleClose();
  };

  const getListItemClass = (languageCode: string) =>
    currentLanguageCode === languageCode ? 'active' : '';

  const selectedLanguage = languages.find(
    (language) => language.code === currentLanguageCode
  );

  const languageLabel = selectedLanguage.label;

  return (
    <>
      <Tooltip arrow title={t('Language Switcher')}>
        <Button
          color="secondary"
          ref={ref}
          onClick={handleOpen}
          sx={{
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0
          }}
        >
          <Typography>{languageLabel}</Typography>
        </Button>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            maxWidth: 240
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t('Language Switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2,
              svg: {
                width: 26,
                mr: 1
              }
            }}
            component="nav"
          >
            {languages.map(({ code, label }) => (
              <ListItem
                key={code}
                className={getListItemClass(code)}
                onClick={() => handleSelectLanguage(code)}
              >
                <ListItemText sx={{ pl: 1 }} primary={t(label)} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default LanguageSelector;
