import {
  ChannelType,
  Conversation,
  ConversationContact,
  ConversationStatus,
  SocialmediaOriginAgent
} from 'src/models/conversations/conversations';
import { Metadata } from 'src/modules/Admin/modules/Acd/modules/MOH/model/MusicOnHold';

interface ConversationProps {
  userId: string;
  campaignId: string;
  serviceId: string;
  companyId: string;
  contactId: string;
  phoneNumber: string;
  timestamp: string;
}
const getMockConversation = (props: ConversationProps) => {
  const {
    userId,
    campaignId,
    serviceId,
    companyId,
    contactId,
    phoneNumber,
    timestamp
  } = props;
  const conversationStatu: ConversationStatus = ConversationStatus.ASSIGNED;
  const socialmediaAgent: SocialmediaOriginAgent = {
    channel: ChannelType.CALL,
    phoneNumber: phoneNumber
  };
  const conversationContact: ConversationContact = {
    contactId: contactId,
    socialmediaAgent: socialmediaAgent
  };
  const metadata: Metadata = {
    creationDate: timestamp,
    modificationDate: timestamp
  };
  //Los campos que son nulos o tiene un string vacio se debe a que no tenemos esta informacion para poder sacarlos
  //El id del campo es por tener algo que no sea vacio
  const conversation: Conversation = {
    accountId: '',
    assignedUserIds: [userId],
    campaignId: campaignId,
    channel: ChannelType.CALL,
    companyId: companyId,
    id: null,
    involvedContacts: [conversationContact],
    lastInteractionTime: null,
    lastMessage: null,
    metadata: metadata,
    originAgent: socialmediaAgent,
    serviceId: serviceId,
    status: conversationStatu,
    unreadIncomingMessages: null,
    variables: {}
  };

  return conversation;
};

export default getMockConversation;
