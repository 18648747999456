import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/modules/Shared/utils/apiFunctions';
import { DashboardData, Panel, PanelData } from '../../models/Dashboards';
import { RTDataColumn } from '../websocket/websocket';
import {
  BASE_FLOW,
  PANELS,
  PANELS_CALCULATIONS,
  PANELS_DATA,
  PANELS_DATA_TYPES,
  PANELS_GROUP_INTERVALS,
  PANELS_ID,
  PANELS_KPIS_CALCULATIONS,
  PANELS_KPIS_COORDINATOR_DATA_TYPES,
  PANELS_KPIS_DATA_TYPES,
  PANELS_KPIS_IDENTIFIERS,
  PANELS_PANEL_TYPES,
  PANELS_PARAMETERS_CHAR_TYPE,
  PANELS_SIZES
} from 'src/utils/apiRoutes/msFlow';

export const getPanels = async (dashboardId?: string): Promise<Panel[]> => {
  const params = dashboardId ? { dashboardId } : {};
  const response: AxiosResponse<Panel[]> = await axios.get(PANELS, { params });

  return response.data;
};

export const getPanelsById = async (panelId: string): Promise<Panel> => {
  const path = replaceURLParams(PANELS_ID, {
    '{id}': panelId
  });

  const response: AxiosResponse<Panel> = await axios.get(path);
  return response.data;
};

export const getDataTypesApi = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await axios.get(PANELS_DATA_TYPES);
  return response.data;
};

export const getKpiDataTypesApi = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_KPIS_DATA_TYPES
  );
  return response.data;
};

export const getCoordinatorDataTypesApi = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_KPIS_COORDINATOR_DATA_TYPES
  );
  return response.data;
};

export const getPanelSizesApi = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await axios.get(PANELS_SIZES);
  return response.data;
};

export const getGroupIntervalsApi = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_GROUP_INTERVALS
  );
  return response.data;
};

export const getCalculationsApi = async (datatype): Promise<string[]> => {
  const params = { datatype };
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_CALCULATIONS,
    { params }
  );
  return response.data;
};

export const getPanelTypesApi = async (datatype): Promise<string[]> => {
  const params = { datatype };
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_PANEL_TYPES,
    { params }
  );
  return response.data;
};

export const getPanelDataApi = async (
  panelId: string,
  instanceId: string
): Promise<PanelData[]> => {
  const FLOW = BASE_FLOW + `/${instanceId}`;
  const path = replaceURLParams(FLOW + PANELS_DATA, {
    '{id}': panelId
  });
  const response = await axios.get(path);
  return response.data;
};

export const getKpiCalculationsApi = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_KPIS_CALCULATIONS
  );
  return response.data;
};

export const getChartTypesApi = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_PARAMETERS_CHAR_TYPE
  );
  return response.data;
};

export const getKpiIdentifiersApi = async (
  datatype: string
): Promise<string[]> => {
  const params = { datatype };
  const response: AxiosResponse<string[]> = await axios.get(
    PANELS_KPIS_IDENTIFIERS,
    { params }
  );
  return response.data;
};
