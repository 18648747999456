import { UserPermissions } from 'src/models/permissionGroups';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getPermissionsByUserId } from '../domain/getPermissions';
import { queryClient } from 'src/utils/queryClient';

export const useGetPermissionsByUserIdQuery = (
  userId: string
): UseQueryResult<UserPermissions> => {
  return useQuery<UserPermissions, Error>(
    ['userPermisssions', userId],
    () => getPermissionsByUserId(userId),
    { staleTime: 60 * 1000, enabled: !!userId }
  );
};

export const getPermissionsByUserIdQuery = (
  userId: string
): Promise<UserPermissions> => {
  return queryClient.fetchQuery(['user permissions', userId], {
    queryFn: () => getPermissionsByUserId(userId),
    staleTime: 30 * 1000
  });
};
