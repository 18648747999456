import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useState } from 'react';
import LexicalVariablesEditor, {
  FocusState
} from './components/LexicalVariablesEditor';
import { Variable } from 'src/modules/Admin/modules/General/modules/Variables/models/models';
import { Box } from '@mui/material';
import { VariableNode } from './lexicalNode/VariableNode';
import { margin } from '@mui/system';
import './style.css';
import { VariablesContext } from './context/VariablesEditorContext';

interface Props {
  variables: Variable[];
  text: string;
  setText: (value: string) => void;
  companyId: string;
  focusState?: FocusState;
}

/** TextInput + Variables component **WITH NO STYLING**
 *
 */
export function TextInputWithVariables({
  text,
  setText,
  variables,
  focusState,
  companyId
}: Props) {
  if (!variables) {
    return null;
  }
  return (
    <VariablesContext.Provider key={variables.length} value={{ variables }}>
      <Box style={{ flex: 1 }}>
        <LexicalComposer
          initialConfig={{
            namespace: `VARIABLES_EDITOR`,
            onError: (error) => {
              console.error(error);
            },
            theme: {
              text: {},
              paragraph: 'lexical-paragraph'
            },
            nodes: [VariableNode]
          }}
        >
          <LexicalVariablesEditor
            focusState={focusState}
            text={text}
            setUpdatedText={(text) => {
              setText(text);
            }}
            variables={variables}
            companyId={companyId}
          />
        </LexicalComposer>
      </Box>
    </VariablesContext.Provider>
  );
}
