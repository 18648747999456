import React from 'react';
import { Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import { t } from 'i18next';

interface Props {
  handleClose: () => void;
  typeForm: 'create' | 'update';
  handleSubmit: () => void;
  disabled: boolean;
  isSubmitting: boolean;
}

const FooterButtons: React.FC<Props> = ({
  handleClose,
  typeForm,
  handleSubmit,
  disabled,
  isSubmitting
}) => {
  return (
    <Stack
      px={1.5}
      py={1.5}
      width="100%"
      direction={{ xs: 'column-reverse', sm: 'row' }}
      justifyContent={'space-between'}
      spacing={1.5}
    >
      <Button variant="outlined" color="secondary" onClick={handleClose}>
        {t('Cancel')}
      </Button>
      <Tooltip
        arrow
        title={t(
          'Se requiere un mínimo de 3 intenciones y 5 etiquetas por intención'
        )}
        disableHoverListener={!disabled}
      >
        <span>
          <Button
            disabled={disabled}
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={isSubmitting && <CircularProgress size={16} />}
          >
            {typeForm === 'create' ? t('Create') : t('Update')}
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default FooterButtons;
