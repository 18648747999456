import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { checkExternalActionFeature } from 'src/contexts/CompanyFeaturesContext/utils/checkFeatures';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// example import component
// const Routes = Loader(lazy(() => import('../views/RoutesView')));

const ExternalActionsView = Loader(
  lazy(() => import('../modules/externalActions'))
);
const ApiKeys = Loader(lazy(() => import('../modules/ApiKeys/views')));

const integrationsRoutes: RouteObject[] = [
  {
    path: 'externalActions',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={() => true}
        featureCheckerFn={checkExternalActionFeature}
      >
        <ExternalActionsView></ExternalActionsView>
      </Restricted>
    )
  },
  {
    path: 'api-keys',
    element: <ApiKeys />
  }
];

export default integrationsRoutes;
