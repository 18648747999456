import { Variable } from 'src/modules/Admin/modules/General/modules/Variables/models/models';
import { TextInputWithVariables } from '..';
import { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import useMeasure from 'react-use-measure';

interface Props {
  label: string;
  text: string;
  setText: (value: string) => void;
  variables: Variable[];
  size?: 'small';
  shrinkOnBlur?: boolean;
  companyId: string;
}

/** Tried to make it similar to MUI TextField with variables
 *
 * @param text - text value
 * @param setText - function to set text value
 * @param variables - array of variables to show in the dropdown
 * @param label - label for the text field
 * @param size - size of the text field
 * @param shrinkOnBlur - whether to shrink the text field on blur
 */
function TextFieldWithVariables({
  text,
  setText,
  variables,
  label,
  size,
  shrinkOnBlur,
  companyId
}: Props) {
  const [isFocused, setIsFocused] = useState(false);

  const [ref, bounds] = useMeasure();

  const onFocusBorder = '2px solid rgba(0,0,0, 0.8)';

  const height = size === 'small' ? '37.13px' : '60px';
  const paddingTop = size === 'small' ? '6px' : '17px';
  const maxHeight = size === 'small' ? '23px' : '26px';

  return (
    <Box
      flex={1}
      sx={{
        border: isFocused ? onFocusBorder : `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: '10px',

        '&:hover': {
          border: isFocused ? onFocusBorder : '1px solid rgba(0, 0, 0, 0.5)'
        },
        paddingTop: paddingTop,
        px: 1,
        paddingBottom: shrinkOnBlur ? 0 : paddingTop,
        minHeight: height,
        height: shrinkOnBlur ? (isFocused ? 'auto' : height) : 'auto',
        position: 'relative'
      }}
    >
      <Typography
        sx={{
          position: 'absolute',
          top: '-10px',
          left: '10px',
          background: 'white',
          padding: '0 5px',
          color: isFocused ? 'rgba(0,0,0,1)' : 'rgba(34, 51, 84, 0.7)',
          fontSize: size === 'small' ? '10px' : '12px',
          fontWeight: 500
        }}
      >
        {label}
      </Typography>
      <Box
        ref={ref}
        sx={
          isFocused
            ? { cursor: 'text' }
            : {
                overflow: 'hidden',
                maxHeight: shrinkOnBlur ? maxHeight : 'none',
                position: 'relative',
                cursor: 'text'
              }
        }
      >
        <TextInputWithVariables
          text={text}
          setText={setText}
          variables={variables}
          focusState={{
            isFocused,
            setIsFocused
          }}
          companyId={companyId}
        />
      </Box>
    </Box>
  );
}

export default TextFieldWithVariables;
