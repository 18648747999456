import axios from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { ChatMessage } from 'src/models/conversations/messages';
import { PaginatedElements } from 'src/models/pagination';

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
const BASE_MS = process.env.REACT_APP_BASE_SOCIALMEDIA_MS as string;

const PATH = process.env.REACT_APP_API_MESSAGES as string;

export interface APIGetMessagesParams {
  conversationId?: string;
  direction?: DirectionType;
  page?: number;
  size?: number;
}

/** FIND MESSAGES FROM CONVERSATION
 *
 * @returns Array of messages after filtering with params
 */
export const getMessagesApi = async (params: APIGetMessagesParams) => {
  const path = BASE_URL + BASE_MS + PATH;

  const resp = await axios.get<PaginatedElements<ChatMessage>>(path, {
    params
  });
  return resp.data;
};
