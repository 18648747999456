import { ContactField } from 'src/modules/Contacts/models/contacts';
import parsePhoneNumber from 'libphonenumber-js';

import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography
} from '@mui/material';
import { Mail, Phone, Telegram } from '@mui/icons-material';
import { useContext } from 'react';
import {
  CTIBarContext,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import { ChannelType } from 'src/models/conversations/conversations';

const ListItemIconWrapper = styled(ListItemIcon)(
  ({ theme }) => `
		  min-width: 36px;
		  color: ${theme.colors.secondary.main};
  `
);

interface ContactDataItemProps {
  field: ContactField;
}

const ContactDataItem = (props: ContactDataItemProps) => {
  const { field } = props;
  const { openDialpad } = useDialpadStateContext();

  return (
    <ListItem>
      <Box display="flex" flexDirection="column" justifyContent="start">
        <Box display="flex" alignItems="center">
          <ListItemIconWrapper>
            {field.type === 'Phone' && <Phone />}
            {field.type === 'Email' && <Mail />}
            {field.type === 'TelegramUsername' && <Telegram />}
          </ListItemIconWrapper>
          <ListItemText
            color="primary"
            primary={field.name}
            primaryTypographyProps={{ variant: 'h5' }}
          />
        </Box>
        <Box display="flex">
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            sx={{
              boxShadow: 0,
              '&:hover': { backgroundColor: 'transparent' }
            }}
            onClick={() => {
              if (field.type === 'Custom' || field.type === 'TelegramChatId')
                return;
              let channel: ChannelType = ChannelType.CALL;
              if (field.type === 'Email') {
                channel = ChannelType.EMAIL;
              }
              if (field.type === 'InstagramUsername') {
                channel = ChannelType.INSTAGRAM;
              }
              if (field.type === 'Phone') {
                channel = ChannelType.CALL;
              }
              if (field.type == 'TelegramUsername') {
                channel = ChannelType.TELEGRAM;
              }
              openDialpad({ inputValue: field?.value, channel });
            }}
            id="btnConversation-ContactDataItem"
          >
            <Typography variant="body1" color="secondary">
              {field.type === 'Phone'
                ? parsePhoneNumber?.(`+${field.value}`)?.formatInternational?.()
                : field.value}
            </Typography>
          </Button>
        </Box>
      </Box>
    </ListItem>
  );
};

export default ContactDataItem;
