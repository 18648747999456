import { Stack } from '@mui/material';
import TicketCommentItem from './TicketCommentItem';
import TicketNewComment from './TicketNewComment';
import {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState
} from 'react';
import UsersDomain from 'src/services/users/domain/UserDomain';
import { User } from 'src/models/users';
import { Ticket, TicketComment } from '../../../../models/tickets';

interface Props {
  ticketComments: TicketComment[];
  setTicketComments?: Dispatch<SetStateAction<TicketComment>>;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
  onSubmitTicketComment?: (ticketComment: string) => Promise<TicketComment>;
  // A User array with at least the users involved in the comments (authors). If it is not received, it is fetched.
  involvedUsers?: User[];
}
const TicketComments = memo(
  ({
    ticketComments,
    onSubmitTicketComment,
    setTicket,
    involvedUsers
  }: Props) => {
    const [users, setUsers] = useState<User[]>([]);
    const userIds = ticketComments?.map((comment) => comment.userId);
    const userIdsRef = useRef(userIds);

    useEffect(() => {
      if (!!involvedUsers) {
        setUsers(involvedUsers);
      } else {
        if (
          userIds?.length > 0 ||
          (userIds?.length > 0 && userIds.length >= userIdsRef.current.length)
        ) {
          // If there are users or if it is added a new comment by a new user
          UsersDomain.getUsersList(userIds).then((resp) => setUsers(resp));
        }
      }
    }, [ticketComments, involvedUsers]);

    const getCommentAuthor = (userId: string) => {
      return users.find((user) => user.id === userId)?.username ?? 'Unknown';
    };

    return (
      <Stack>
        <TicketNewComment
          onSubmitTicketComment={onSubmitTicketComment}
          setTicket={setTicket}
        />
        {ticketComments?.map((comment) => {
          return (
            <TicketCommentItem
              key={comment.id}
              ticketComment={comment}
              author={getCommentAuthor(comment?.userId)}
            />
          );
        })}
      </Stack>
    );
  }
);

export default TicketComments;
