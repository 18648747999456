import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { Telegram } from '@mui/icons-material';
import {
  Stack,
  Box,
  useTheme,
  Divider,
  TextField,
  Card,
  Select,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  styled,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import {
  CTIBarContext,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import { emitCustomEvent } from 'react-custom-events';
import { useAlert } from 'src/hooks/useAlert';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';
import { setCampaignSelector } from '../utils/setCampaignSelector';
import setAutoselectNextConversationDomain from 'src/services/socialmedia/domain/conversation/setAutoselectNextConversationDomain';
import {
  SendTelegramMessageFileParams,
  sendTelegramMessageFile
} from 'src/services/telegram/domain/message/sendTelegramMessageFile';
import { ChatMessageType } from 'src/models/conversations/messages';
import {
  SendTelegramMessageTextParams,
  sendTelegramMessageText
} from 'src/services/telegram/domain/message/sendTelegramMessageText';
import PhoneInput from 'src/components/PhoneInput';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';

interface Props {
  /** Default is true */
  useCTIBarContext?: boolean;
  /** ignored if useCTIBarContext equals to true */
  username?: string;
  /** ignored if useCTIBarContext equals to true */
  setUsername?: Dispatch<SetStateAction<string>>;
  initialIsPhoneInput?: boolean;
}

export const TelegramConversation = ({
  username: usernameProps,
  setUsername: setUsernameProps,
  initialIsPhoneInput,
  useCTIBarContext = true
}: Props) => {
  const theme = useTheme();
  const campaignsQuery = useUserCampaignsQuery(true);
  const { t }: { t: any } = useTranslation();
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const { setActiveTab } = useContext(CTIBarContext);
  const { selectedTicket } = useDialpadStateContext();

  const {
    username: usernameContext,
    setUsername: setUsernameContext,
    inputValue: inputValueContext,
    setInputValue: setInputValueContext,
    countryCode,
    setCountryCode,
    isPhoneInput: isPhoneInputContext,
    setIsPhoneInput: setIsPhoneInputContext
  } = useDialpadStateContext();

  const [username, setUsername] = useCTIBarContext
    ? [usernameContext, setUsernameContext]
    : [usernameProps, setUsernameProps];

  const [inputValue, setInputValue] = useCTIBarContext
    ? [inputValueContext, setInputValueContext]
    : [usernameProps, setUsernameProps];

  const [isPhoneInput, setIsPhoneInput] = useCTIBarContext
    ? [isPhoneInputContext, setIsPhoneInputContext]
    : useState(initialIsPhoneInput);
  const userInput = isPhoneInput ? inputValue : username;
  const formattedUsername = /^@\w+/.test(userInput)
    ? userInput.slice(1)
    : userInput;
  const ticketId = selectedTicket?.id;

  const onSendSuccessMessage = () => {
    setAutoselectNextConversationDomain(true);
    if (useCTIBarContext) {
      emitCustomEvent('Close popover');
      setActiveTab('dialpad');
    }
    showAlert('Telegram message has been sent!', 'success', 3000);
  };

  const { showAlert } = useAlert();

  const onChangeSelect = (e): void => {
    setSelectedCampaignId(e.target.value);
    localStorage.setItem('selectedTelegramCampaign', e.target.value);
  };

  const Input = styled('input')({
    display: 'none'
  });

  const handleStartConversation = () => {
    if (file) return startConversationAttachment();
    if (!userInput || !selectedCampaignId || !message) return;
    const baseParams: SendTelegramMessageTextParams = {
      campaignId: selectedCampaignId,
      text: message,
      ticketId
    };
    if (isPhoneInput) {
      sendTelegramMessageText({
        ...baseParams,
        phoneNumber: userInput
      })
        .then(onSendSuccessMessage)
        .catch(() => {
          showAlert('Error sending the message', 'error', 3000);
        });
    } else {
      sendTelegramMessageText({
        ...baseParams,
        username: formattedUsername
      })
        .then(onSendSuccessMessage)
        .catch((error) => {
          showAlert(t(getErrorMessage(error)?.errorMessage), 'error', 3000);
        });
    }
  };

  const startConversationAttachment = () => {
    if (!userInput || !selectedCampaignId || !file) return;

    let tp = file?.type.match(/^[^/]*/)[0];
    if (tp === 'application') tp = 'document';
    tp = tp.charAt(0).toUpperCase() + tp.slice(1);

    let params: SendTelegramMessageFileParams = {
      caption: message,
      campaignId: selectedCampaignId,
      file: file,
      type: tp as ChatMessageType,
      ticketId
    };

    if (isPhoneInput) params.phoneNumber = userInput;
    else params.username = formattedUsername;

    sendTelegramMessageFile(params)
      .then(onSendSuccessMessage)
      .catch(() => {
        showAlert('Error sending the message', 'error', 3000);
      });
  };

  useEffect(() => {
    setCampaignSelector(
      'selectedTelegramCampaign',
      setCampaigns,
      setSelectedCampaignId,
      campaignsQuery?.data?.elements,
      'Telegram',
      selectedTicket?.serviceId
    );
  }, [campaignsQuery?.dataUpdatedAt]);

  const onLoadAttachmentFile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    setFile(fileList[0]);
  };

  return (
    <Card sx={{ width: '500px' }}>
      <Stack
        width="100%"
        direction={'row'}
        justifyContent={setUsername ? 'space-between' : 'flex-start'}
        alignItems="center"
        color={theme.colors.secondary.main}
        sx={{
          backgroundColor: '#F6F8FB',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }}
        gap={2}
        px={2}
        borderRadius={1}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <Telegram />
          <h3>{t('New Telegram Conversation')}</h3>
        </Box>
      </Stack>
      <Stack direction="column" divider={<Divider orientation="horizontal" />}>
        <Stack direction={'column'} rowGap={1} p={1}>
          {isPhoneInput ? (
            <PhoneInput
              id="number-TelegramConversation"
              phoneNumber={inputValue}
              setPhoneNumber={setInputValue}
              callType={'External'}
              setCallType={undefined}
              disableCallTypeSelector
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              sx={{ width: '100%' }}
            />
          ) : (
            <TextField
              label={t('User')}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value.replace(/\s/g, ''));
              }}
              fullWidth={true}
              id="user-TelegramConversation"
            />
          )}

          <FormControlLabel
            control={
              <Switch
                checked={!isPhoneInput}
                onChange={() => {
                  setIsPhoneInput(!isPhoneInput);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                color="secondary"
              />
            }
            label={t('Use username')}
            sx={{ ml: 1 }}
          />
        </Stack>
        <Box p={1.5} width="100%">
          <textarea
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            placeholder={t('Write your message here...')}
            style={{
              borderRadius: '10px',
              resize: 'none',
              border: 'none',
              width: '100%',
              outline: 'none',
              fontSize: '1.1em',
              backgroundColor: '#e7f0fe',
              padding: '20px',
              fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`
            }}
          ></textarea>
        </Box>
        <Box width="100%" p={2}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Select
              placeholder="Campaña"
              sx={{ height: '40px', width: '240px' }}
              value={selectedCampaignId}
              onChange={onChangeSelect}
              id="campana-TelegramConversation"
            >
              {campaigns.map((c, idx) => {
                return (
                  <MenuItem key={idx} value={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Input
                id="messenger-upload-file-cti"
                type="file"
                onChange={onLoadAttachmentFile}
              />
              <Tooltip
                arrow
                placement="top"
                title={file ? file.name : t('Attach a file')}
              >
                <label htmlFor="messenger-upload-file-cti">
                  <IconButton
                    sx={{ mx: 1 }}
                    color={file ? 'success' : 'primary'}
                    component="span"
                  >
                    <AttachFileTwoToneIcon fontSize="small" />
                  </IconButton>
                </label>
              </Tooltip>
              <Button
                id="btnSend-TelegramConversation"
                disabled={
                  isPhoneInput ? inputValue.length <= 2 : username.length < 1
                }
                variant="contained"
                color="secondary"
                onClick={handleStartConversation}
              >
                {t('Send')}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};
