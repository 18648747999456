import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  ChatMessage,
  ChatMessageType,
  ConversationMessage,
  EmailMessage
} from 'src/models/conversations/messages';
import snackbar from 'src/utils/snackbar';
import { getChatInvolvedContactName } from '../domain/conversation/utils';
import { getMessageInvolvedContact } from '../domain/messages/utils';
import { getContactByIdQuery } from './query/getContactsQuery';
import i18n, { t } from 'i18next';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';

export const playNotificationSound = (channel: ChannelType) => {
  const audio = document.getElementById(
    'socialmedia-message-notification-' + channel
  ) as HTMLAudioElement;
  const socialmediaAudio = document.getElementById(
    'socialmedia-message-notification'
  ) as HTMLAudioElement;

  socialmediaAudio.src = audio.src;
  socialmediaAudio.play();
};

export const playNotificationSoundPromise = async (
  channel: ChannelType
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const audio = document.getElementById(
      'socialmedia-message-notification-' + channel
    ) as HTMLAudioElement;
    const socialmediaAudio = document.getElementById(
      'socialmedia-message-notification'
    ) as HTMLAudioElement;
    socialmediaAudio.src = audio.src;

    socialmediaAudio.onended = () => {
      resolve();
    };

    socialmediaAudio.onerror = (e) => {
      reject(e);
    };

    socialmediaAudio.play();
  });
};

export const showMessageNotification = (message: ConversationMessage) => {
  if (message.channel === 'Email') {
    const messageData = message as EmailMessage;
    snackbar.success(t('New email from') + ' ' + messageData.from);
  }
  if (message.channel === 'Telegram') {
    const messageData = message as ChatMessage;
    snackbar.success(
      t('New Telegram message from') + ' ' + messageData.senderId
    );
  }
};

export const isSameChatMessageSender = (
  messageData1: ChatMessage,
  messageData2: ChatMessage
) => {
  return (
    !!messageData1 &&
    !!messageData2 &&
    messageData1.senderId === messageData2.senderId
  );
};

export const isNoBackgroungChatMessage = (messageData: ChatMessage) => {
  const { type } = messageData;
  return type === ChatMessageType.STICKER;
};

export const getMessageContactName = async (
  conversation: Conversation,
  message?: ChatMessage
): Promise<string> => {
  const involvedContact = getMessageInvolvedContact(conversation, message);
  if (!involvedContact) return null;
  if (
    involvedContact?.contactId &&
    (conversation.channel === ChannelType.TELEGRAM ||
      conversation.channel === ChannelType.WHATSAPP ||
      conversation.channel === ChannelType.WEBCHAT)
  ) {
    const contact = await getContactByIdQuery(involvedContact?.contactId);
    const display_name = await getContactName(contact);

    if (!!display_name) return display_name;
  }

  return getChatInvolvedContactName(involvedContact);
};

/** Returns the formatted creationDate of the meessageA if the creationDate of the messageB is different than the one of the meesageA.
 * This funtion can be used to display timestamps between messages, grouping messages of the same date. messageB should not be undefined or null.
 *
 * @example
 *  messages.map((message) => {
 *    const date = getMessagesDate(messages[i-i], messages[i])
 *    return <Box>{date}</Box>
 *  })
 * */
export const getMessagesDate = (
  messageA?: ConversationMessage,
  messageB?: ConversationMessage
): string | null => {
  // if no messageA and no messageB return null
  if (!messageA && !messageB) return null;
  //if no messageA return the messageB formatted creationDate
  if (!messageA)
    return formatDate(new Date(messageB.metadata.creationDate), true, false);
  //if no messageA return the messageA formatted creationDate
  if (!messageB)
    return formatDate(new Date(messageA.metadata.creationDate), true, false);

  const dateA = new Date(messageA.metadata.creationDate);
  const dateB = new Date(messageB.metadata.creationDate);

  const dayA = dateA.getUTCDate();
  const monthA = dateA.getUTCMonth() + 1;
  const yearA = dateA.getUTCFullYear();

  const dayB = dateB.getUTCDate();
  const monthB = dateB.getUTCMonth() + 1;
  const yearB = dateB.getUTCFullYear();
  // if messageA date and messageB date are the same return null
  if (dayA === dayB && monthA === monthB && yearA === yearB) {
    return null;
  }
  // else return messageB formatted date
  return formatDate(dateB, true, false);
};

export function groupMessagesByDate(messages: ConversationMessage[]) {
  return messages?.reduce((newArray, message) => {
    const dateObject = message.metadata.creationDate
      ? new Date(message.metadata.creationDate)
      : new Date();
    const date = formatDate(dateObject, true, false);
    if (!newArray[date]) {
      newArray[date] = [];
    }
    newArray[date].push(message);
    return newArray;
  }, {});
}

export function formatDate(
  date: Date,
  includeWeekday: boolean,
  includeHour: boolean
) {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const options = {
    hour: '2-digit',
    minute: '2-digit'
  } as Intl.DateTimeFormatOptions;

  if (checkIsSameDay(date, today)) {
    let dateString = i18n.t('Hoy');

    if (includeHour) {
      dateString += ', ' + date.toLocaleTimeString('es', options);
    }

    return dateString;
  } else if (checkIsSameDay(date, yesterday)) {
    let dateString = i18n.t('Ayer');

    if (includeHour) {
      dateString += ', ' + date.toLocaleTimeString('es', options);
    }

    return dateString;
  } else {
    return date.toLocaleString(i18n.language, {
      weekday: includeWeekday ? 'long' : undefined,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  }
}

function checkIsSameDay(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}
