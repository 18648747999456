import { RTQueue } from 'src/models/queue';
import { RTCall } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';
import { DataFilter as CurrentDataFilter } from 'src/services/shared/domain/filters';
import {
  getRTQueueIdsStore,
  getRTQueuesStore
} from '../../infraestructure/store/getRTQueuesStore';
import { useSelector } from 'src/store/store';
import { getRTUserById } from '../rtuser/getRTUser';

export interface GetRTQueuesFilter extends CurrentDataFilter {
  id?: string;
  rtCall?: RTCall;
  rtUser?: RTUser;
}

export const useUserCompanyQueues = (
  userId: string,
  onlyUserQueues?: boolean
) => {
  const user = getRTUserById(userId);
  const companyId = user?.companyId;
  const extension = user?.extension?.extension;
  return useSelector((state) => {
    return state.rtusers.rtQueuesIds.filter((id) => {
      if (onlyUserQueues) {
        return state.rtusers.rtQueues[id].members.some(
          (member) => member.endpoint === extension
        );
      }
      return state.rtusers.rtQueues[id].companyId === companyId;
    });
  });
};

export const useQueueSelector = (queueId) => {
  return useSelector((state) => state.rtusers.rtQueues[queueId]);
};

export const getRTQueueIds = (shouldUseHook: boolean): string[] => {
  return getRTQueueIdsStore(shouldUseHook);
};

/** GET RTQueues
 *
 * @returns Array of Queues
 */
export const getRTQueues = (filter: GetRTQueuesFilter): RTQueue[] => {
  let rtQueues = getRTQueuesStore(filter.shouldUseHook);

  // Filter rtQueues based on specified filters
  return rtQueues.filter((q) => {
    // Check same id
    if (filter.id && filter.id !== q.id) return false;

    // Check queue contains rtcall id
    if (
      filter.rtCall &&
      q.calls &&
      !q.calls.some((c) => c.id === filter.rtCall.id)
    )
      return false;

    if (
      filter.rtUser &&
      !q.members.some(
        (member) => member.endpoint === filter.rtUser.extension?.extension
      )
    ) {
      return false;
    }

    return true;
  });
};
