import {
  DialerBotCampaign,
  DialerbotExecutionStatus,
  PrioritySchedule,
  ScheduleContactsDialerbot,
  ScheduleDatabaseFiles,
  UploadedSchedulesProps
} from 'src/modules/DialerBot/models/dialerbot';
import {
  deleteUploadedSchedules,
  uploadFiles,
  uploadContacts,
  updateSchedulePrority,
  enableSchedule
} from '../infrastructure/api/callSchedulling';
import { deleteDialerBotCampaigns } from '../infrastructure/api/dialerBotCampaigns';
import {
  startDialerBot,
  stopDialerBot
} from '../infrastructure/api/executionDialer';
import { AxiosResponse } from 'axios';
import { queryClient } from 'src/utils/queryClient';

/** START ONE OR MULTIPLE DIALERBOTS
 *
 * Start the schedules for one or multiples dialerbot
 */
export const actionStartDialerBot = (dialerbot: DialerbotExecutionStatus) => {
  return startDialerBot(dialerbot.id);
};

/** STOP ONE OR MULTIPLE DIALERBOTS
 *
 * Start the schedules for one or multiples dialerbot
 */
export const actionStopDialerBot = (dialerbot: DialerbotExecutionStatus) => {
  return stopDialerBot(dialerbot.id);
};

/** DELETE ONE OR MULTIPLE DIALERBOTS
 *
 * Delete one or multiples dialerbot
 */
export const actionDeleteDialerBot = (dialerbot: DialerbotExecutionStatus) => {
  return deleteDialerBotCampaigns(dialerbot.id);
};

/** DELETE ONE SCHDEULE
 *
 * Delete one shcedule
 */
export const actionDeleteSchedule = (documentId: string) => {
  return deleteUploadedSchedules(documentId);
};

/** UPLOAD SCHEDULES
 *
 * UPLOAD CSV FILES WITH SCHEDULES
 */
export const UploadScheduleDatabase = (
  scheduleDatabases: ScheduleDatabaseFiles
): Promise<ScheduleDatabaseFiles> => {
  return uploadFiles(scheduleDatabases);
};

/** UPLOAD CONTACTS
 *
 * UPLOAD EXISTING CONTACTS
 */
export const actionUploadContacts = async (
  contact: ScheduleContactsDialerbot
): Promise<AxiosResponse<ScheduleContactsDialerbot>> => {
  let paramContactFields = '';
  let paramTagFields = '';

  // Concat contact fields
  contact.phoneFielsdId.map(
    (contact) => (paramContactFields += `&phoneFielsdId=${contact}`)
  );

  // Concat tag fields
  contact.tagsIds.map((field) => (paramTagFields += `&tagsIds=${field}`));

  // Concat url with params
  const urlParams = `?callAll=${contact.callAll}&callUntilAnswer=${contact.callUntilAnswer}&campaignId=${contact.campaignId}${paramContactFields}${paramTagFields}&priority=${contact.priority}`;

  return uploadContacts(urlParams);
};

/** UPDATE ONE SCHDEULE PRORITY
 *
 * Update one shcedule prority
 */
export const actionUpdateSchedulePrority = (
  documentId: string,
  priority: PrioritySchedule
): Promise<UploadedSchedulesProps> => {
  return updateSchedulePrority(documentId, priority);
};

export const actionEnableSchedule = async (id: string, enabled: boolean) => {
  const response = await enableSchedule(id, enabled);

  queryClient.invalidateQueries({
    queryKey: ['dialerbotSchedulesById']
  });

  return response;
};
