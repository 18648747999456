import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  lighten,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Formik, FormikErrors, FormikProps } from 'formik';
import useTicketForm from '../../../hooks/useTicketForm';
import TicketSubResolutionLabel from './TicketSubResolutionLabel';
import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  memo,
  useContext,
  useRef,
  useState
} from 'react';
import wait from 'src/utils/wait';
import { useAlert } from 'src/hooks/useAlert';
import { getTicketUI } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/getTickets';
import { logger } from 'src/utils/logger';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { Conversation } from 'src/models/conversations/conversations';
import { User } from 'src/models/users';
import TicketFields from './TicketFields';
import {
  Ticket,
  TicketField,
  TicketFieldValue,
  TicketSubResolution,
  TicketTag
} from '../../../../models/tickets';
import { useTranslation } from 'react-i18next';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreateTicketPermissions,
  checkUpdateTicketPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import HelperAccordion from 'src/modules/Dashboard/views/Inbox/HelperPanel/components/HelperAccordion';
import ShowFlowTicketViewer from '../../showFlow';
import ShowflowWithverticalAccordion from '../../showFlow/components/ShowflowWithVerticalAccordion';
import { DaServiceModifyClosedTypes } from '../../../../../DaServices/models/daService';
import { checkIsOpenTicket } from '../../../../domain/checkIsOpenTicket';
import { useCustomEventListener } from 'react-custom-events';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';

interface Props {
  /** The ticket to edit */
  ticket?: Ticket;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
  onSubmit?: (ticket: Ticket) => Promise<Ticket>;
  onClose?: (e?: MouseEvent<HTMLButtonElement>) => void;
  conversation?: Conversation;
  /** Useful optional prop for disabling buttons or displaying error messages from other components */
  setFormikErrors?: Dispatch<SetStateAction<FormikErrors<Ticket>>>;
  setIsValidForm?: Dispatch<SetStateAction<boolean>>;
  enableShowFlow?: boolean;
  direction?: 'row' | 'column';
  onChangeTicketSubResolution?: (
    subResolution: TicketSubResolution,
    isFirstManualReschedule: boolean,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const TicketForm = memo((props: Props) => {
  const {
    ticket,
    setTicket,
    onClose,
    onSubmit,
    conversation,
    setFormikErrors,
    setIsValidForm,
    enableShowFlow,
    direction,
    onChangeTicketSubResolution: onChangeTicketSubResolutionProps
  } = props;
  const ticketRef = useRef(ticket);
  const isFirstMount = useRef(true);

  const { hasAccess } = useContext(PermissionsContext);
  const isAllowedToSubmitTicket =
    (ticketRef.current && hasAccess(checkUpdateTicketPermissions)) ||
    (!ticketRef.current && hasAccess(checkCreateTicketPermissions));

  const [isAddingDescription, setIsAddingDescription] = useState(false);

  /**
   * true if a new ticket is being created and the serviceId cannot be obtained from the conversation
   * because there is no current conversation
   *  */
  const isShowedServiceSelector = !ticketRef.current && !conversation;

  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showAlert } = useAlert();

  const {
    initialTicket,
    onChangeTicketResolution,
    onChangeTicketService,
    onChangeTicketSubResolution,
    onChangeTicketTags,
    onChangeTicketUsers,
    serviceList,
    ticketFields,
    ticketResolution,
    ticketResolutionList,
    ticketSubResolutionList,
    ticketTagsList,
    ticketUsers,
    ticketUsersList,
    ticketValidationSchema,
    ticketService
  } = useTicketForm({
    conversation,
    ticket,
    setTicket,
    onChangeTicketSubResolution: onChangeTicketSubResolutionProps
  });

  const isClosed = !checkIsOpenTicket(ticketRef.current);
  const isNotAllowedToModifyClosed =
    ticket?.id &&
    isClosed &&
    ticketService?.ticketConfig.allowModifyClosed ===
      DaServiceModifyClosedTypes.NO;

  if (!ticketValidationSchema)
    return (
      <Skeleton
        variant="rectangular"
        height="400px"
        sx={{ borderRadius: '10px', my: 2 }}
      />
    );

  return (
    <>
      <Formik
        initialValues={initialTicket}
        validationSchema={ticketValidationSchema}
        // Validate on mount only when editing the ticket
        validateOnMount={ticket?.id && isFirstMount.current}
        onSubmit={async (_values, { resetForm, setStatus, setSubmitting }) => {
          const newTicket: Ticket = {
            ...initialTicket,
            ..._values
          };
          try {
            await wait(1000);
            const ticketResp = await onSubmit(newTicket);
            setStatus({ success: true });
            showAlert(
              t(
                `The ticket was successfully ${ticket ? 'updated' : 'created'}`
              ),
              'success',
              2000
            );
            if (setTicket) {
              const ticketUI = await getTicketUI(ticketResp);
              setTicket(ticketUI);
            }
            setSubmitting(false);
            resetForm();
            if (onClose) {
              onClose();
            }
          } catch (err) {
            logger.error(err);
            showAlert(
              `${t(
                `The ticket could not be ${ticket ? 'updated' : 'created'}`
              )}. ${getErrorMessage(err).errorMessage}`,
              'error',
              2000
            );
            setStatus({ success: false });
            setSubmitting(false);
            onClose();
          }
        }}
      >
        {(formikProps: FormikProps<Ticket>) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values,
            isValid
          } = formikProps;
          if (isFirstMount.current) {
            isFirstMount.current = false;
          }
          if (setFormikErrors) {
            setFormikErrors(errors);
          }
          if (setIsValidForm) {
            setIsValidForm(isValid);
          }

          useCustomEventListener(
            CustomEventNames.TICKET_FIELD_CHANGE,
            ({ id, value }: { id: string; value: TicketFieldValue }) => {
              setFieldValue(`fields.${id}`, value);
            }
          );

          return (
            <form
              onSubmit={handleSubmit}
              style={{ height: '100%', width: '100%' }}
            >
              <Stack
                spacing={2}
                rowGap={0.5}
                width="100%"
                direction={direction}
              >
                {enableShowFlow &&
                  (direction === 'row' ? (
                    <ShowflowWithverticalAccordion
                      ticket={values}
                      setTicket={setTicket}
                    />
                  ) : (
                    <>
                      <Box width={'100%'}>
                        <HelperAccordion title={t('Script')}>
                          <Box sx={{}}>
                            <ShowFlowTicketViewer
                              ticket={values}
                              setTicket={setTicket}
                            />
                          </Box>
                        </HelperAccordion>
                      </Box>
                    </>
                  ))}
                <Stack rowGap={1} flex={1} spacing={1}>
                  <TextField
                    size="small"
                    id={`ticket-form-details-input`}
                    required
                    label={t('Title')}
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    name="title"
                    placeholder={t('Title here...')}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      if (setTicket) {
                        setTicket(
                          (prev) =>
                            ({
                              ...prev,
                              title: e.target.value.trim()
                            }) as Ticket
                        );
                      }
                    }}
                    value={values.title}
                    variant="outlined"
                  />
                  <TextField
                    size="small"
                    id={`ticket-form-description-input`}
                    fullWidth
                    multiline
                    rows={isAddingDescription ? 4 : undefined}
                    label={t('Description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    name="description"
                    placeholder={t('Description here...')}
                    onFocus={() => {
                      setIsAddingDescription(true);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setIsAddingDescription(false);
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      if (setTicket) {
                        setTicket((prev) => ({
                          ...prev,
                          description: e.target.value.trim()
                        }));
                      }
                    }}
                    value={values.description}
                    variant="outlined"
                  />
                  {isShowedServiceSelector && (
                    <TextField
                      size="small"
                      id={`ticket-form-service-input`}
                      select
                      required
                      label={t('Service')}
                      fullWidth
                      name="serviceId"
                      placeholder={t('Service here...')}
                      onBlur={handleBlur}
                      error={Boolean(touched.serviceId && errors.serviceId)}
                      helperText={touched.serviceId && errors.serviceId}
                      onChange={(e) => {
                        handleChange(e);
                        onChangeTicketService(e);
                      }}
                      value={values.serviceId}
                      variant="outlined"
                    >
                      {serviceList?.map((service) => (
                        <MenuItem
                          id={service.id}
                          key={service.id}
                          value={service.id}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  <Autocomplete
                    size="small"
                    id="tags-outlined"
                    multiple
                    fullWidth
                    options={ticketTagsList}
                    getOptionLabel={(tag: TicketTag) => tag?.name}
                    onBlur={handleBlur}
                    onChange={(_, values) => {
                      const tagIds = values.map((tag) => tag.id);
                      onChangeTicketTags(_, values);
                      setFieldValue('tagIds', tagIds);
                    }}
                    value={ticketTagsList.filter((tag) =>
                      values.tagIds.includes(tag.id)
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Tags')}
                        placeholder={t('Ticket tags')}
                        name="tagIds"
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      return value.map((tag, index) => {
                        return (
                          <Chip
                            key={tag.id}
                            sx={{
                              padding: 0,
                              backgroundColor: lighten(
                                tag?.hexColor
                                  ? `#${tag?.hexColor}`
                                  : theme.colors.secondary.main,
                                0.85
                              ),
                              color: tag?.hexColor
                                ? `#${tag?.hexColor}`
                                : theme.colors.secondary.main,
                              fontWeight: 'bold'
                            }}
                            label={tag.name}
                            {...getTagProps({ index })}
                          />
                        );
                      });
                    }}
                  />
                  <Autocomplete
                    size="small"
                    id={`ticket-assigned-users-options-input`}
                    multiple
                    fullWidth
                    options={ticketUsersList}
                    getOptionLabel={(user: User) => user.username}
                    onBlur={handleBlur}
                    onChange={(_, values) => {
                      onChangeTicketUsers(_, values);
                      const userIds = values.map((user) => user.id);
                      setFieldValue('ticketUserIds', userIds);
                    }}
                    value={ticketUsers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Assigned users')}
                        placeholder={`${t('Assigned users here')}...`}
                        name="ticketUserIds"
                      />
                    )}
                  />
                  <TextField
                    size="small"
                    id={`ticket-form-resolution-input`}
                    select
                    required
                    label={t('Typology')}
                    fullWidth
                    name="typology"
                    placeholder={t('Typology here...')}
                    onChange={onChangeTicketResolution}
                    value={ticketResolution?.id ?? ''}
                    variant="outlined"
                    disabled={isNotAllowedToModifyClosed}
                  >
                    {ticketResolutionList?.map((resolution) => (
                      <MenuItem
                        id={resolution.id}
                        key={resolution.id}
                        value={resolution.id}
                      >
                        {resolution.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    size="small"
                    id={`ticket-form-subresolution-input`}
                    select
                    required
                    disabled={!ticketResolution || isNotAllowedToModifyClosed}
                    label={t('Sub-typology')}
                    fullWidth
                    error={Boolean(
                      touched.subResolutionId && errors.subResolutionId
                    )}
                    helperText={
                      touched.subResolutionId && errors.subResolutionId
                    }
                    name="subResolutionId"
                    placeholder={t('Sub-typology here...')}
                    onChange={async (e) => {
                      onChangeTicketSubResolution(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.subResolutionId ?? values?.subResolution?.id}
                    variant="outlined"
                  >
                    {ticketSubResolutionList?.map((subResolution) => (
                      <MenuItem
                        id={subResolution.id}
                        key={subResolution.id}
                        value={subResolution.id}
                      >
                        <Stack
                          width="100%"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          flexWrap="wrap"
                        >
                          <Typography>{subResolution.name}</Typography>
                          <TicketSubResolutionLabel
                            subResolution={subResolution}
                          />
                        </Stack>
                      </MenuItem>
                    ))}
                  </TextField>
                  <TicketFields
                    key={ticket?.id}
                    ticketFields={ticketFields}
                    setTicket={setTicket}
                    ticketFieldValues={ticket?.fields}
                  />
                </Stack>
              </Stack>

              {onSubmit && (
                <Box
                  sx={{
                    display: { xs: 'block', sm: 'flex' },
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    my: 2,
                    py: 2,
                    px: 3,
                    borderTop: `${theme.colors.alpha.black[10]} solid 1px`
                  }}
                >
                  <Button
                    id="btnCancel-EmailAccountForm"
                    fullWidth={isMobile}
                    variant="outlined"
                    color="secondary"
                    onClick={onClose}
                  >
                    {t('Cancel')}
                  </Button>
                  <PermissionsTooltip hasAccess={isAllowedToSubmitTicket}>
                    <Button
                      id="btnSubmit-EmailAccountForm"
                      fullWidth={isMobile}
                      type="submit"
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        !isAllowedToSubmitTicket ||
                        isNotAllowedToModifyClosed
                      }
                      variant="contained"
                      color="secondary"
                    >
                      {ticket ? t('Edit') : t('Create')}
                    </Button>
                  </PermissionsTooltip>
                </Box>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
});

export default TicketForm;
