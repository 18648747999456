import {
  setThirdPartyPanel,
  setThirdPartyPanels,
  ThirdPartyIframeData
} from 'src/store/slices/socialmedia/socialmediaSlice';
import store from 'src/store/store';

/** SET IFRAME PANELS FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of Iframe Panels
 */
export const setIframepanelStore = (iframe: ThirdPartyIframeData) => {
  store.dispatch(setThirdPartyPanel(iframe));
};

export const setIframepanelsStore = (iframes: ThirdPartyIframeData[]) => {
  store.dispatch(setThirdPartyPanels(iframes));
};
