import axios from 'axios';
import { GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION_GENERATE } from 'src/utils/apiRoutes/msGenerativeAI';
import { KnowledgeDocumentation } from './getKnowledgeDocumentationApi';

export const createKnowledgeDocumentationApi = async (
  data: KnowledgeDocumentation
) => {
  const response = await axios.post(
    GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION_GENERATE,
    data
  );
  return response.data;
};
