import { Call } from 'src/models/call';
import {
  Contact,
  ContactsRequestParams
} from 'src/modules/Contacts/models/contacts';
import { Conversation } from 'src/models/conversations/conversations';
import { getContactByIdQuery } from 'src/services/socialmedia/application/query/getContactsQuery';
import {
  getContactByIdApi,
  getContactsApi,
  getContactListApi
} from '../infraestructure/api/getContactsApi';
import { getContactsByFieldValueApi } from '../infraestructure/api/getContactsByFieldValue';
import { getPermissionsByUserIdQuery } from 'src/services/permissionGroups/application/getPermissionsByUserIdQuery';
import AuthManager from 'src/services/authentication/manager';
import { checkReadContactPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';
import { PaginatedElements } from 'src/models/pagination';

export interface ContactsDomainParams extends ContactsRequestParams {
  selectedContactId?: string;
}

/** GET ONE CONTACT
 *
 * Retrieves the first contact found with the specified filter
 *
 * @param filter Parameters to filter contacts by
 * @returns Contact
 */
export const getContactById = async (id: string): Promise<Contact> => {
  if (!id) return Promise.resolve(null);

  const contact = await getContactByIdApi(id);
  return contact;
};

/**
 * if selectedContactId param is passed, permissions from the logged user are fetched and checked
 * (in backend, read contact permissions are only applied to the getContacts endpoint and not to getContactById)
 * @param param0
 * @returns
 */
export const getContacts = async ({
  selectedContactId,
  ...requestParams
}: ContactsDomainParams) => {
  let paginatedContacts: PaginatedElements<Contact> = null;
  // selectedContactId param is passed
  if (!!selectedContactId) {
    // get the permissions of the logged user
    const userPermissions = await getPermissionsByUserIdQuery(
      AuthManager.getUserId()
    );
    // check the read contact permissions
    const hasAccessToContacts = checkReadContactPermissions(
      userPermissions?.permissions
    );
    // has no read contact permission
    if (!hasAccessToContacts) {
      const contact = await getContactById(selectedContactId);
      paginatedContacts = getPaginatedElements({ elements: [contact] });
      return paginatedContacts;
    }
  }
  // selectedContactId param is not passed or has the read contact permissions
  paginatedContacts = await getContactsApi(requestParams);
  return paginatedContacts;
};

export const getContactList = async (contactIds: string[]) => {
  const resp = await getContactListApi(contactIds);
  return resp;
};

export const getContactByFieldValue = async (
  params: ContactsRequestParams
): Promise<Contact> => {
  let contactResult = await getContactsByFieldValueApi(
    params.serviceId,
    params.fieldType,
    params.fieldValue
  );
  return contactResult;
};

/** GET MANY CONTACTS
 *
 * It's a way to fetch many contacts with one promise.
 * Calls getContact internally.
 *
 * @param filters  Array of filters for each search.
 * @returns  Array of contacts, one for each filter.
 */
export const getManyContacts = async (
  filters: ContactsRequestParams[]
): Promise<Contact[]> => {
  const contacts = [];

  for (const filter of filters) {
    const contact = await getContactByIdQuery(filter?.id);
    contacts.push(contact);
  }

  return Promise.resolve(contacts);
};

/** GET CONTACTS FROM CONVERSATION
 *
 * @param conversation
 * @returns Listof contacts involved in the conversation
 */
export const getContactsFromConversation = async (
  conversation: Conversation
): Promise<Contact[]> => {
  const filters: ContactsRequestParams[] = conversation.involvedContacts.map(
    (invC) => {
      return { id: invC.contactId } as ContactsRequestParams;
    }
  );

  const filtersWithId = filters.filter((f) => f.id);
  return getManyContacts(filtersWithId);
};

export const getContactsFromCall = async (call: Call): Promise<Contact[]> => {
  const filters: ContactsRequestParams[] = call.involvedContacts.map((invC) => {
    return { id: invC.contactId } as ContactsRequestParams;
  });

  const filtersWithId = filters.filter((f) => f.id);
  return getManyContacts(filtersWithId);
};
