import axios from 'axios';
import { TICKETS_SHOULD_PERFORM_FIRST_MANUAL_RESCHEDULE } from 'src/utils/apiRoutes/msMessaging';

export const shouldPerformFirstManualRescheduleApi = async (params: {
  subResolutionId: string;
  conversationId?: string;
  ticketId?: string;
}) => {
  const resp = await axios.get<boolean>(
    TICKETS_SHOULD_PERFORM_FIRST_MANUAL_RESCHEDULE,
    { params }
  );
  return resp.data;
};
