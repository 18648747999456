import { Contact, ContactField } from 'src/modules/Contacts/models/contacts';
import {
  Ticket,
  TicketField,
  TicketFieldsValues,
  TicketFieldType,
  TicketFieldValue
} from '../../../../models/tickets';
import useShowflowVariable from '../hooks/useShowflowVariable';
import { CircularProgress } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Edit } from '@mui/icons-material';
import useCustomMeasure from 'src/hooks/useCustomMeasure';
import { EditInlineShowflowField } from './EditInlineVariablePopover';
import { updateContact } from 'src/modules/Contacts/domain/updateContact';
import { ShowflowVariableInfo } from './ShowflowVariableInfo';
import { emitCustomEvent } from 'react-custom-events';
import { useShowflowColors } from '../hooks/useShowflowColors';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import usePermissions from 'src/hooks/usePermissions';

interface Props {
  variableId: string;
  contact: Contact;
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
}

function ShowflowInlineVariable({
  variableId,
  ticket,
  contact,
  setTicket
}: Props) {
  const { showflowColorsMap } = useShowflowColors();

  const { field, type, value } = useShowflowVariable({
    variableId,
    contact,
    ticket
  });

  const fieldType = useMemo(() => {
    if (!field) return TicketFieldType.TEXT;
    if (type === 'Contact') return TicketFieldType.TEXT;
    return (field as TicketField).type;
  }, [field?.id, type]);

  const [isHovered, setIsHovered] = useState(false);
  const { userPermissions } = usePermissions();

  function userCanEditContactField() {
    if (!field) return false;
    if (type !== 'Contact') return false;

    const contactField = field as ContactField;

    if (contactField.editableBy.length === 0) return true;

    return contactField.editableBy.some((roleId) => {
      return userPermissions.roleIds.includes(roleId);
    });
  }

  const isEditable =
    type === 'Ticket' ||
    (type === 'Contact' && Boolean(contact?.id) && userCanEditContactField());

  const [showTextEditor, setShowTextEditor] = useState(false);
  const showInlineEditor =
    isEditable && (fieldType !== TicketFieldType.TEXT || showTextEditor);

  const [isLoading, setIsLoading] = useState(
    field === undefined || Object.keys(field)?.length === 0
  );

  const hasValue = !!field && (Boolean(value) || value === false);
  const valueText =
    !hasValue && !!field ? `"${field?.name}"` : value.toString();

  const [ref, { width }] = useCustomMeasure();
  const [isMounted, setIsMounted] = useState(false);

  async function applyChanges(value: string | string[] | boolean) {
    if (type === 'Ticket') {
      const ticketFieldValue: TicketFieldValue = {
        type: (field as TicketField).type,
        value
      };
      setTicket({
        ...ticket,
        fields: {
          ...ticket.fields,
          [field.id]: ticketFieldValue
        } as TicketFieldsValues
      });

      emitCustomEvent(CustomEventNames.TICKET_FIELD_CHANGE, {
        id: field.id,
        value: ticketFieldValue
      });
    }
    if (type === 'Contact') {
      const contactField = field as ContactField;
      await updateContact({
        ...contact,
        fields: {
          ...contact.fields,
          [contactField.id]: value as string
        }
      });
    }
  }

  const rect = ref.current?.getBoundingClientRect();
  const top = rect?.bottom + 14;
  const left = rect?.left + rect?.width / 2;

  useEffect(() => {
    if (value || value === '') setIsLoading(false);
  }, [value]);

  useEffect(() => {
    setIsLoading(field === undefined);
  }, [field]);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 200);
  }, []);

  return (
    <motion.div
      animate={isMounted ? { width } : false}
      transition={{ duration: 0.2, ease: 'easeOut' }}
      style={{
        borderRadius: '6px',
        height: '32px',
        border: !hasValue
          ? `1px solid ${showflowColorsMap[type]}`
          : '1px solid transparent',
        background: hasValue ? showflowColorsMap[type] + '20' : 'white',
        position: 'relative',
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!showInlineEditor && isEditable) setShowTextEditor(true);
      }}
    >
      <div
        ref={ref}
        style={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontWeight: 'bold',
          padding: '0px 9px',
          color: hasValue ? showflowColorsMap[type] : 'black',
          width: 'max-content',
          textWrap: 'nowrap'
        }}
      >
        {isLoading && (
          <CircularProgress
            size={16}
            style={{
              color: showflowColorsMap[type],
              margin: '0px 6px'
            }}
          />
        )}

        {showInlineEditor ? (
          <EditInlineShowflowField
            setIsEditing={setShowTextEditor}
            setIsHovered={setIsHovered}
            field={field as TicketField | ContactField}
            value={value}
            type={type}
            hasValue={hasValue}
            applyChanges={applyChanges}
            setIsLoading={setIsLoading}
          />
        ) : (
          valueText
        )}

        {/** Edit icon */}
        {isEditable && (
          <AnimatePresence>
            {(isHovered || showTextEditor) && (
              <motion.div
                layoutId={variableId}
                initial={{
                  opacity: 0,
                  x: -5,
                  scale: 0.8,
                  filter: 'blur(4px)'
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  scale: 1,
                  filter: 'blur(0px)'
                }}
                style={{
                  marginLeft: '4px',
                  marginTop: '2px'
                }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
              >
                <Edit sx={{ fontSize: 14 }} />
              </motion.div>
            )}
          </AnimatePresence>
        )}

        <ShowflowVariableInfo
          isHovered={isHovered}
          isEditing={showTextEditor}
          hasValue={hasValue}
          field={field as TicketField | ContactField}
          type={type}
          inputType={fieldType}
          value={value as string | string[] | boolean}
          top={top}
          left={left}
          applyChanges={applyChanges}
        />
      </div>
      {/* This div is used to remove the blank space between the text and the popover. 
          If this is not here and the user hovers this blank space the popover will open and
          close rapidly until the user stops hovering 
        */}
      {isHovered && (
        <div
          style={{
            width: '100%',
            position: 'absolute',
            height: '10px',
            top: '100%'
          }}
        ></div>
      )}
    </motion.div>
  );
}

export default ShowflowInlineVariable;
