import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { t } from 'i18next';
import { ContactGroup } from 'src/modules/Contacts/models/contacts';
import { getContactGroupsQuery } from 'src/modules/Contacts/domain/getContactGroups';

import { useAlert } from 'src/hooks/useAlert';

import { useTheme } from '@mui/material';
import { WebChatAccount } from '../../models/webChatAccount';
import { getWebChatStyle } from '../../../../../../../../services/webchat/infraestructure/webChatStyle/getWebChatStyle';
import { WebChatStyle } from '../../models/webChatStyle';
import AuthManager from 'src/services/authentication/manager';
import { checkReadWebChatStylesPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

export const useWebChatAccount = () => {
  const theme = useTheme();
  const { showAlert } = useAlert();
  const { hasAccess } = useContext(PermissionsContext);

  const hasWebChatPermissions = hasAccess(checkReadWebChatStylesPermissions);

  const [contactGroup, setContactGroup] = useState<ContactGroup[]>([]);

  const companyId = AuthManager.getLoggedUserCompanyId();

  const [styles, setSyles] = useState<WebChatStyle[]>([]);

  const getContactGroups = async () => {
    try {
      const contactGroups = (
        await getContactGroupsQuery({
          companyId
        })
      )?.elements;
      return contactGroups;
    } catch (error) {
      showAlert('Error getting contact groups', 'error', 3000);
    }
  };

  const getWebChatStyles = async () => {
    try {
      const webChatStyles = await getWebChatStyle({ companyId, size: -1 });
      return webChatStyles.elements;
    } catch (error) {
      showAlert('Error getting web chat styles', 'error', 3000);
    }
  };

  useEffect(() => {
    getContactGroups().then((contactGroups) => setContactGroup(contactGroups));
    if (hasWebChatPermissions) {
      getWebChatStyles().then((webChatStyle) => setSyles(webChatStyle));
    }
  }, []);

  const defaultAccount: WebChatAccount = {
    name: '',
    companyId: '',
    styleId: '',
    acceptPolicies: false,
    enabledInitialForm: false,
    contactGroupId: '',
    initialForm: []
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t('WebChat account name is required')),
    styleId: Yup.string().required(t('WebChat account style is required')),
    contactGroupId: Yup.string().required(
      t('WebChat account contact group is required')
    )
  });

  return {
    styles,
    companyId,
    contactGroup,
    validationSchema,
    defaultAccount
  };
};
