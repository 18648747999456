import { useEffect, useState } from 'react';
import { Conversation } from 'src/models/conversations/conversations';

import { Box, CircularProgress } from '@mui/material';
import { Contact, ContactTag } from 'src/modules/Contacts/models/contacts';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import UpdateContactDataView from '../ContactDataPanel/CreateContactDataView/UpdateContactDataView';
import AddContactDataView from '../ContactDataPanel/CreateContactDataView/AddContactDataView';
import NoteContactDataListView from './NoteContactDataListView';
import NoteContactList from './NoteContactList';
import { getCompanyContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';

interface NoteConversationTabProps {
  conversation: Conversation;
}

const NoteConversationTab = (props: NoteConversationTabProps) => {
  const { conversation } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  // GET ALL TAGS OPTIONS
  const [tags, setTags] = useState<ContactTag[]>([]);
  useEffect(() => {
    let isMounted = true;
    getCompanyContactTagsQuery({
      companyId: conversation?.companyId
    }).then((response) => {
      if (isMounted) {
        setTags(response?.elements);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const companyFieldsQuery = useGetContactFields(
    { companyId: conversation.companyId },
    !!conversation
  );

  const isLoading = companyFieldsQuery.isLoading;

  if (isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />;
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          minWidth: 300,
          width: '100%',
          height: '100%',
          padding: 2
        }}
      >
        {!selectedContact && (
          <NoteContactDataListView
            key={conversation.id}
            companyFields={companyFieldsQuery?.data?.elements}
            conversation={conversation}
            setSelectedContact={setSelectedContact}
            setIsAdding={setIsAdding}
            setIsEditing={setIsEditing}
          />
        )}
        {selectedContact && !isEditing && !isAdding && (
          <NoteContactList
            conversation={conversation}
            contact={selectedContact}
            setContact={setSelectedContact}
            filterNote="contactsNotes"
          />
        )}
        {selectedContact && isEditing && (
          <UpdateContactDataView
            key={conversation.id}
            contact={selectedContact}
            tags={tags}
            companyFields={companyFieldsQuery?.data?.elements}
            setContact={setSelectedContact}
            setIsOpen={setIsEditing}
            isSetContact={false}
          />
        )}
        {selectedContact && isAdding && (
          <AddContactDataView
            key={conversation.id}
            contact={selectedContact}
            tags={tags}
            companyFields={companyFieldsQuery?.data?.elements}
            setContact={setSelectedContact}
            setIsOpen={setIsAdding}
            isSetContact={false}
          />
        )}
      </Box>
    </>
  );
};

export default NoteConversationTab;
