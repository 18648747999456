import { updateTicketFieldApi } from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/fields/updateTicketFieldsApi';
import { TicketField } from '../../models/tickets';

export const updateTicketField = (updatedTicketField: TicketField) => {
  const options =
    updatedTicketField.type !== 'Options'
      ? undefined
      : updatedTicketField?.options;
  const formattedTicketField: TicketField = { ...updatedTicketField, options };
  return updateTicketFieldApi(formattedTicketField);
};

export const updateTicketFields = async (
  updatedTicketFields: TicketField[]
) => {
  const ticketFieldPromises = updatedTicketFields.map(updateTicketField);
  const resp = await Promise.all(ticketFieldPromises);
  return resp;
};
