import { WebChatStyle } from '../../../models/webChatStyle';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { useWebChatStyleActionButtons } from '../../utils/useWebChatStyleActionButtons';
import { CreateStyle } from '../../components/Style/index';
import { Box } from '@mui/material';
import { DialTable } from 'src/components/DialTable';
import { useContext } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkReadWebChatStylesPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

export const StyleTab = () => {
  const {
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    webChatStylesToDelete,
    isOpenEditModal,
    setIsOpenEditModal,
    selectedWebChatStyle,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    deleteAccounts,
    getData
  } = useWebChatStyleActionButtons();

  const { hasAccess } = useContext(PermissionsContext);

  return (
    <>
      <ConfirmationModal<WebChatStyle, keyof WebChatStyle>
        dataKey={'name'}
        data={webChatStylesToDelete}
        isOpen={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        onConfirm={deleteAccounts}
        mode={ConfirmationModalModes.DELETE}
      />
      <CreateStyle
        show={isOpenEditModal}
        setShow={setIsOpenEditModal}
        editWCS={selectedWebChatStyle}
      />
      <Box width={'100%'} py={2} px={4}>
        {
          <DialTable<WebChatStyle>
            queryKey={['webChatStyle']}
            getColumnsFn={getColumns}
            getRowActionsFn={getRowActions}
            getBulkActionsFn={getBulkActions}
            getAvailableFilters={getAvailableFilters}
            getDataFn={getData}
          />
        }
      </Box>
    </>
  );
};
