import { EmailSignatureParams } from '../../models/emailSignatureModels';
import { EmailSignature } from '../../models/emailSignatureModels';
import { getEmailSignatureApi } from '../../infrastructure/signature/getEmailSignatureApi';
import { PaginatedElements } from 'src/models/pagination';
import { queryClient } from 'src/utils/queryClient';
import { useQuery } from '@tanstack/react-query';

export const getEmailSignature = async (
  params: EmailSignatureParams
): Promise<PaginatedElements<EmailSignature>> => {
  const emailSigantures = await getEmailSignatureApi(params);
  return emailSigantures;
};

export async function getEmailSignatureQuery(params: EmailSignatureParams) {
  return queryClient.fetchQuery(
    ['emailSignature', ...Object.values(params)],
    () => getEmailSignature(params),
    {
      staleTime: 1000 * 60 * 5
    }
  );
}

export function useEmailSignatureQuery(params: EmailSignatureParams) {
  return useQuery({
    queryKey: ['emailSignature', ...Object.values(params)],
    queryFn: () => getEmailSignature(params),
    staleTime: 1000 * 60 * 5
  });
}
