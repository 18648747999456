import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Conversation } from 'src/models/conversations/conversations';
import { RTQueue } from 'src/models/queue';
import { RTUser } from 'src/models/rtUser';
import {
  addConversationToRTQueueAction,
  removeConversationFromRTQueueAction
} from './rtQueuesUtils/rtQueuesUtils';

export interface RTUsersObject {
  [key: string]: RTUser;
}

export interface RTQueuesObject {
  [key: string]: RTQueue;
}

interface RTUsersState {
  rtUsers: RTUsersObject;
  rtUserIds?: string[];
  rtQueues: RTQueuesObject;
  rtQueuesIds?: string[];
}

const initialState: RTUsersState = {
  rtUsers: {},
  rtUserIds: [],
  rtQueues: {},
  rtQueuesIds: []
};

export const conversationsSlice = createSlice({
  name: 'rtusers',
  initialState,
  reducers: {
    setRTUsers: (state, action: PayloadAction<RTUser[]>) => {
      let rtUsers = action.payload;
      let rerender = false;
      for (const rtu of rtUsers) {
        if (!rtu || rtu == undefined)
          return console.error('RTU Null', rtu, rtUsers);

        const currentState = state.rtUsers[rtu.id];
        if (!currentState) {
          rerender = true;
        }

        state.rtUsers[rtu.id] = {
          ...rtu,
          calls: [...(rtu.calls || [])],
          conversationIds: [...(rtu.conversationIds || [])]
        };
      }

      // Update state to trigger re-render
      if (rerender) {
        state.rtUsers = { ...state.rtUsers };
        state.rtUserIds = Object.keys(state.rtUsers);
      }
    },
    setRTUser: (state, action: PayloadAction<RTUser>) => {
      action.payload.conversationIds = action.payload.conversationIds || [];
      let rerender = false;

      const currentState = state.rtUsers[action.payload.id];
      if (!currentState) rerender = true;

      // Set RTUser state
      state.rtUsers[action.payload.id] = {
        ...action.payload,
        calls: action.payload.calls || [],
        conversationIds: action.payload.conversationIds || []
      };

      // Update state to trigger re-render
      if (rerender) {
        state.rtUserIds = [...state.rtUserIds, action.payload.id];
        state.rtUsers = { ...state.rtUsers };
      }
    },
    deleteRTUser: (state, action: PayloadAction<RTUser>) => {
      delete state.rtUsers[action.payload.id];
      state.rtUsers = { ...state.rtUsers };
      state.rtUserIds = Object.keys(state.rtUsers);
    },
    setRTQueues: (state, action: PayloadAction<RTQueue[]>) => {
      const newRTQueues = action.payload;
      const stateRTQueues = { ...state.rtQueues };
      newRTQueues.forEach((queue) => {
        stateRTQueues[queue.id] = { ...queue };
      });

      state.rtQueues = { ...stateRTQueues };
      state.rtQueuesIds = Object.keys(state.rtQueues);
    },
    setRTQueue: (state, action: PayloadAction<RTQueue>) => {
      //  If the Queue DOES NOT exist, CREATE IT
      state.rtQueues[action.payload.id] = { ...action.payload };
      state.rtQueues = { ...state.rtQueues };
      state.rtQueuesIds = Object.keys(state.rtQueues);
    },
    addConversationsToRTQueue: (
      state,
      action: PayloadAction<Conversation[]>
    ) => {
      const conversations = action.payload;
      conversations.forEach((conversation) => {
        const campaignId = conversation.campaignId;
        if (!campaignId) return;
        const queue = state.rtQueues[campaignId];
        if (!queue) return;

        addConversationToRTQueueAction(conversation, queue);
        state.rtQueues[campaignId] = { ...queue };
        state.rtQueues = { ...state.rtQueues };
      });
    },
    removeConversationsFromRTQueue: (
      state,
      action: PayloadAction<Conversation[]>
    ) => {
      const conversations = action.payload;
      conversations.forEach((conversation) => {
        const campaignId = conversation.campaignId;
        if (!campaignId) return;
        const queue = state.rtQueues[campaignId];
        if (!queue) return;

        removeConversationFromRTQueueAction(conversation, queue);
        state.rtQueues[campaignId] = { ...queue };
        state.rtQueues = { ...state.rtQueues };
      });
    },
    handleQueuedConversations: (
      state,
      action: PayloadAction<Conversation[]>
    ) => {
      const conversations = action.payload;
      const queues = { ...state.rtQueues };
      conversations.forEach((conversation) => {
        if (!conversation.campaignId) return;
        const queue = queues[conversation.campaignId];
        if (!queue) return;

        const isOnQueue = conversation.status === 'Queued';

        if (!isOnQueue) {
          removeConversationFromRTQueueAction(conversation, queue);
          queues[conversation.campaignId] = { ...queue };

          return;
        }

        addConversationToRTQueueAction(conversation, queue);
        queues[conversation.campaignId] = { ...queue };
      });
      state.rtQueues = { ...queues };
    },
    addConversationToRTQueue: (state, action: PayloadAction<Conversation>) => {
      const conversation = action.payload;
      const campaignId = conversation.campaignId;
      if (!campaignId) return;
      const queue = state.rtQueues[campaignId];
      if (!queue) return;

      addConversationToRTQueueAction(conversation, queue);

      state.rtQueues[campaignId] = { ...queue };
      state.rtQueues = { ...state.rtQueues };
    },
    removeConversationFromRTQueue: (
      state,
      action: PayloadAction<Conversation>
    ) => {
      const conversation = action.payload;
      const campaignId = conversation.campaignId;
      if (!campaignId) return;
      const queue = state.rtQueues[campaignId];
      if (!queue) return;

      removeConversationFromRTQueueAction(conversation, queue);

      state.rtQueues[campaignId] = { ...queue };
      state.rtQueues = { ...state.rtQueues };
    },
    addRTQueue: (state, action: PayloadAction<RTQueue>) => {
      //  If the Queue DOES NOT exist, CREATE IT
      state.rtQueues[action.payload.id] = { ...action.payload };
      state.rtQueues = { ...state.rtQueues };
      state.rtQueuesIds = Object.keys(state.rtQueues);
    }
  },
  extraReducers: (builder) => {}
});

export const {
  setRTUser,
  deleteRTUser,
  setRTUsers,
  setRTQueues,
  setRTQueue,
  addRTQueue,
  addConversationToRTQueue,
  removeConversationFromRTQueue,
  handleQueuedConversations,
  addConversationsToRTQueue,
  removeConversationsFromRTQueue
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
