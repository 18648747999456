import { BASE_URL } from './base';

export const BASE_GENERATIVE_AI_URL = BASE_URL + '/ai-generative/generative';

// Multiple offline analysis
export const BATCH_QUESTION = `${BASE_GENERATIVE_AI_URL}/batch_question`;
export const BATCH_QUESTION_BY_GROUP = `${BATCH_QUESTION}/{id}`;
export const BATCH_QUESTION_CSV = `${BASE_GENERATIVE_AI_URL}/batch_question_csv`;

// Chat AI
export const GENERATIVE_AI_CONVERSATION = `${BASE_GENERATIVE_AI_URL}/conversation`;
export const GENERATIVE_AI_CONVERSATION_ID = `${BASE_GENERATIVE_AI_URL}/conversation/{id}`;

// Generative for NLU
export const GENERATIVE_AI_GENERATE_DATA = `${BASE_GENERATIVE_AI_URL}/generate_data`;

// Knowledge documentation
export const GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION = `${BASE_GENERATIVE_AI_URL}/knowledge_documentation`;
export const GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION_GENERATE = `${BASE_GENERATIVE_AI_URL}/generate_documentation`;
export const GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION_ID = `${GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION}/{id}`;
export const GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION_CONVID = `${GENERATIVE_AI_KNOWLEDGE_DOCUMENTATION}/{conversationId}`;
