import { ChannelType } from './conversations';

export const CHAT_MESSAGES_PAGE_SIZE = 50;

export enum MessageOrientation {
  INCOMING = 'Incoming',
  OUTGOING = 'Outgoing'
}
export enum MessageStatus {
  ERROR = 'Error',
  PENDING = 'Pending',
  SENT = 'Sent',
  DELIVERED = 'Delivered',
  RECEIVED = 'Received',
  READ = 'Read'
}

export const outgopingMessageStatusOrder = {
  [MessageStatus.PENDING]: 0,
  [MessageStatus.SENT]: 1,
  [MessageStatus.DELIVERED]: 2,
  [MessageStatus.READ]: 3
};

export interface ConversationMessage {
  conversationId: string;
  channel: ChannelType;
  messageId: string;
  orientation: MessageOrientation;
  status: MessageStatus;
  metadata: MessageMetadata;
}

export interface EmailMessage extends ConversationMessage {
  channel: ChannelType.EMAIL;
  message: string;
  attachedFiles?: EmailAttachment[];
  cc: string[];
  from: string;
  inReplyToId: string;
  references: string[];
  replyTo: string[];
  sentDate: Date;
  subject: string;
  to: string[];
}

export enum ChatMessageType {
  AUDIO = 'Audio',
  DOCUMENT = 'Document',
  IMAGE = 'Image',
  LOCATION = 'Location',
  STICKER = 'Sticker',
  TEXT = 'Text',
  VIDEO = 'Video',
  DUMMY = 'Dummy'
}

type ChatMessageChannel =
  | ChannelType.WHATSAPP
  | ChannelType.TELEGRAM
  | ChannelType.WEBCHAT
  | ChannelType.INSTAGRAM;

export interface ChatMessage extends ConversationMessage {
  type: ChatMessageType;
  channel: ChatMessageChannel;
  senderId: string;
  replyTo: string;
  edited: boolean;
  deleted: boolean;
  unsupported?: boolean;
  content: ChatMessageContent;
}

export interface ChatMessageContent {
  text: string;
  uploadedFile?: SocialmediaAttachment;
  buttons?: MessageButton[];
}

export interface MessageButton {
  text: string;
  type: MessageButtonType;
}

export enum MessageButtonType {
  QUICK_REPLY = 'QuickReply',
  URL = 'Url'
}

export interface WebChatMessageData {
  channel: ChannelType;
  messageId: string;
  metadata: MessageMetadata;
  orientation: MessageOrientation;
  status: MessageStatus;
  type: 'Text' | 'File';
  text?: string;
}

export interface EmailAttachment {
  contentId: string;
  type: 'Inline' | 'File';
  uploadedFile: SocialmediaAttachment;
}

export interface SocialmediaAttachment {
  id: string;
  bucketName: string;
  key: string;
  name: string;
  sizeKb: number;
  sha256: string;
}

export interface Base64Attachment {
  base64: string;
  name: string;
  type: 'Inline' | 'File';
  contentType: string;
  sizeKb: number;
}

export type MessageFileType =
  | 'AnimatedEmoji'
  | 'Animation'
  | 'Audio'
  | 'Document'
  | 'Dummy'
  | 'Image'
  | 'Location'
  | 'Sticker'
  | 'Text'
  | 'Video'
  | 'VoiceNote'
  | 'File';

export interface SendTelegramMessageData {
  text: string;
  type: 'Text' | 'Image';
}

interface MessageMetadata {
  creationDate: string;
  modificationDate: string;
}

export enum MessageActions {
  DELETE = 'Delete',
  EDIT = 'Edit',
  REPLY = 'Reply'
}
