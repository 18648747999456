import axios from 'axios';
import { Variable } from '../models/models';
import { VariableFilterProps } from '../domain/getVariablesDomain';
import { VARIABLE } from 'src/utils/apiRoutes/msAcd';
import { PaginatedElements } from 'src/models/pagination';

export const getVariablesApi = async (filters: VariableFilterProps) => {
  const response = await axios.get<PaginatedElements<Variable>>(VARIABLE, {
    params: filters
  });
  return response.data;
};
