import axios, { AxiosError, AxiosResponse } from 'axios';
import CallHelper from './callHelperApi';
import { replaceURLParams } from 'src/services/utils';
import {
  ASTERISK_MS,
  CALLS_CONFERENCE_REMOVE_AGENT
} from 'src/utils/apiRoutes/msAsterisk';

export const removeFromConference: Function = (
  callId: string,
  instance: string
) => {
  const removeFromConferencePath =
    CallHelper.setInstanceID(ASTERISK_MS, instance) +
    CALLS_CONFERENCE_REMOVE_AGENT;

  const path = replaceURLParams(removeFromConferencePath, { '{id}': callId });

  return axios
    .post(path)
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
};
