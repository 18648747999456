import { ConversationNote } from 'src/models/conversations/conversations';
import { PaginatedElements } from 'src/models/pagination';
import {
  getNotesApi,
  getNotesContactApi
} from '../infraestructure/api/getContactNote';

/** FILTER MESSAGES FROM API
 *
 * @param filter Parameters to filter the currently stored conversations
 * @returns Array of conversations after applying the specified filters
 */
export const getNotes = async (
  conversationId: string
): Promise<ConversationNote[]> => {
  let notesResponse = (await getNotesApi(
    conversationId
  )) as PaginatedElements<ConversationNote>;
  let notes = notesResponse.elements;

  return notes;
};

export const getNotesContact = async (
  contactId: string
): Promise<ConversationNote[]> => {
  let notesResponse = (await getNotesContactApi(
    contactId
  )) as PaginatedElements<ConversationNote>;
  let notes = notesResponse.elements;

  return notes;
};
