import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import ButtonPowerDialerNotification from './ButtonPowerDialerNotification';
import Scrollbar from '../Scrollbar';
import { getCampaignById } from 'src/services/users/domain/getCampaigns';
import { getDaServiceById } from 'src/services/daServices/utils';
import { useEffect, useState } from 'react';
import { getContactById } from 'src/modules/Contacts/domain/getContacts';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';
import WebRTCNotifications from 'src/services/webrtc/notifications/webrtc-notifications';
interface Props {
  campaignId: string;
  scheduleId: string;
  userId: string;
  phoneNumber: string;
  contactId: string;
  open: boolean;
  companyId: string;
  timestamp: string;
  onClose: () => void;
}

const PowerDialerNotification = (props: Props) => {
  const theme = useTheme();
  const {
    campaignId,
    scheduleId,
    phoneNumber,
    contactId,
    userId,
    companyId,
    timestamp,
    onClose
  } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [campaign, setCampaign] = useState(null);
  const [service, setService] = useState(null);
  const [contact, setContact] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCampaignAndService = async () => {
      try {
        if (campaignId) {
          const campaignData = await getCampaignById(campaignId);
          setCampaign(campaignData);
          const serviceData = await getDaServiceById(campaignData.serviceId);
          setService(serviceData);
        }
        if (contactId) {
          const contactData = await getContactById(contactId);
          const contactName = await getContactName(contactData);
          setContact(contactName);
        }
      } catch (error) {
        console.error('Error fetching campaign and service:', error);
      }
    };

    fetchCampaignAndService();
  }, [contactId, campaignId]);

  useEffect(() => {
    WebRTCNotifications.startRinger();
    return () => {
      WebRTCNotifications.stopRinger();
    };
  }, []);

  return (
    <Rnd
      style={{ zIndex: 101 }}
      default={{
        x: window.innerWidth - 500,
        y: 50,
        height: '',
        width: '300px'
      }}
      enableResizing={false}
      bounds={'window'}
    >
      <Card sx={{ width: '50vh' }}>
        <CardHeader
          title={campaign?.name}
          fontWeight="bold"
          sx={{
            textAlign: 'center'
          }}
        />
        <Divider />
        <CardContent
          sx={{
            height: `10vh`,
            p: 0,
            marginTop: '2%'
          }}
        >
          <Scrollbar>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              marginTop={isMobile ? 0 : 2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{ borderRight: isMobile ? '' : '2px solid #ccc' }}
              >
                <Typography variant="h4" align="center" color={'#16a6e5'}>
                  {t('Service')}
                </Typography>
                <Typography variant="body1" align="center">
                  {service?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant={contactId ? 'h3' : 'h4'}
                  fontWeight="bold"
                  align="center"
                >
                  {contactId ? contact : t('Contact not added')}
                </Typography>
                <Typography variant="body1" align="center" color={'#5a4f4d'}>
                  {phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          </Scrollbar>
        </CardContent>
        <CardActions
          sx={{ justifyContent: 'center', textAlign: 'center', marginY: 2 }}
        >
          <ButtonPowerDialerNotification
            campaignId={campaignId}
            scheduleId={scheduleId}
            userId={userId}
            companyId={companyId}
            serviceId={service?.id}
            phoneNumber={phoneNumber}
            contactId={contactId}
            timestamp={timestamp}
            onClose={onClose}
          />
        </CardActions>
      </Card>
    </Rnd>
  );
};

export default PowerDialerNotification;
