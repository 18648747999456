import { WhatspAppAccount } from 'src/services/whatsapp/models/WhatsAppAccount';
import { addWhatsAppAccountApi } from '../../infrastructure/account/addWhatsAppAccountApi';
import { sendWhatsappAuthInfo } from '../auth/sendWhatsappAuthInfo';

/** CREATE WHATSAPP ACCOUNT
 *
 * Create whatsapp account
 */
export const addWhatsAppAccount = async (whatsAppAccount: WhatspAppAccount) => {
  if (whatsAppAccount.provider === 'Gupshup') {
    const resp = await addWhatsAppAccountApi(whatsAppAccount);
    return resp;
  }

  const { metaAuthData, ...account } = whatsAppAccount;
  const createdAccount = await addWhatsAppAccountApi({
    ...account
  });
  await sendWhatsappAuthInfo(metaAuthData);
  return createdAccount;
};
