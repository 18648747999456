import { shouldPerformFirstManualRescheduleApi } from '../infrastructure/shouldPerformFirstManualReschedule';

export const shouldPerformFirstManualReschedule = async (params: {
  subResolutionId: string;
  /** the conversation id from the last call conversation added to the ticket. Ignored by backend if ticketId passed */
  conversationId?: string;
  ticketId?: string;
}) => {
  const { conversationId, ticketId } = params;
  if (!conversationId && !ticketId) return false;
  const resp = await shouldPerformFirstManualRescheduleApi(params);
  return resp;
};
