import { StarTwoTone } from '@mui/icons-material';
import axios from 'axios';
import CallHelper from 'src/modules/CTI/infrastructure/callHelperApi';
import { ASTERISK_MS, CALLS_REALTIME } from 'src/utils/apiRoutes/msAsterisk';

export interface StartRecordingResponse {
  recordingId: string;
}

export const stopRecordingApi = async (
  callId: string,
  instance: string,
  recordingId: string
) => {
  const url =
    CallHelper.setInstanceID(ASTERISK_MS, instance) +
    CALLS_REALTIME +
    '/' +
    callId +
    '/recording/' +
    recordingId +
    '/stop';

  const response = await axios.post(url);

  return response.data as StartRecordingResponse;
};
