import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

export const convertDateToTimeZone = (date: Date | Dayjs) => {
  // Function to convert date time to time zone
  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
  const timeEndDateFormat = new Date(date.toString());
  const timeZoneUSA = timeEndDateFormat.toLocaleString('en-US', {
    timeZone: resolvedOptions.timeZone
  });

  const newDateFormat = new Date(timeZoneUSA);

  return newDateFormat;
};

/**Returns current Timezone
 * @returns timezone
 **/
export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**Returns an date to utc moment
 * @param date date moment
 * @returns timezone moment
 **/
export const convertUtcToTimezoneMoment = (
  date: moment.Moment
): moment.Moment => {
  return date.tz(getUserTimeZone());
};

/**Returns a date type
 * @param date date moment
 * @returns date Date
 **/
export const convertMomentToDate = (date: moment.Moment): Date => {
  return date.toDate();
};

/**Returns a date moment
 * @param date date Date
 * @returns date moment
 **/
export const convertDateToMoment = (date: Date): moment.Moment => {
  return moment(date);
};

/**Returns a date in Timezone
 * @param utcDate date utc
 * @returns localDate
 **/
export const convertUtcToTimeZone = (utcDate) => {
  const offset = new Date().getTimezoneOffset();
  const localDate = new Date(utcDate.getTime() - offset * 60 * 1000);
  return localDate;
};


/** Convierte una fecha en zona horaria local a UTC
 * @param localDate fecha en zona horaria local
 * @returns fecha en UTC
 **/
export const convertTimeZoneToUtc = (localDate) => {
  const offset = localDate.getTimezoneOffset();
  const utcDate = new Date(localDate.getTime() + offset * 60 * 1000);
  return utcDate;
};
/**Returns an date to utc moment
 * @param date date
 * @returns utc date
 **/
export const convertDateToUtcMoment = (date: Date): moment.Moment => {
  return moment.utc(date);
};

export const convertDateToTimeStamp = (date: Dayjs | Date) => {
  return parseFloat((date.valueOf() / 1000).toFixed(3));
};

/**Returns the current date
 * it is necessary to convert seconds to miliseconds and show date diferent to 1 of january of 1970
 * @param date number
 * @returns  number
 **/
export const convertTimestampSecondsToMilisecond = (date: number) => {
  return date * 1000;
};

export const getLocalSystemDate = (date: Date) => {
  const respDate = new Date(date);
  const offset = date?.getTimezoneOffset();
  respDate?.setHours(date?.getHours() - offset / 60);
  return respDate;
};
