import { useEffect } from 'react';
import { ParticipantStatus, ParticipantType } from '../types/videoCallTypes';
import {
  ParticipantResolution,
  VideoroomWSService
} from '../infrastructure/websocket/websocket';
import {
  IdentifyMessage,
  JoinVideoroomMessage,
  ParticipantStatusMessage
} from '../infrastructure/websocket/websocket-messages';
import {
  FinishReason,
  VideocallPhase,
  VideocallRoomContextProps
} from './VideocallRoomContext';
import AuthManager from 'src/services/authentication/manager';
import snackbar from 'src/utils/snackbar';
import { t } from 'i18next';
import { connectWebRTC } from '../domain/room-join';

let justTriggeredAnAlert = false;
export const contextWebsocket = (context: VideocallRoomContextProps) => {
  const { setConnectionId, connectionId } = context.connection;
  const { videocallPhase, setVideocallPhase, setFinishedReason } =
    context.phase;
  const { videoRoom } = context;

  //context

  useEffect(() => {
    //VideoroomWSService.instance.connect();
    return () => {
      VideoroomWSService.instance.disconnect();
    };
  }, []);

  useEffect(() => {
    subscribeToEvents();
  }, [videoRoom.room, videocallPhase]);

  const subscribeToEvents = () => {
    // Outside useEffect becuase we want the room to update when operating with it
    VideoroomWSService.instance.on(
      'videocalls:identify-message',
      'suscribeToEvents',
      (event: IdentifyMessage) => {
        setConnectionId(event.socketId);
      }
    );

    VideoroomWSService.instance.on(
      'videocalls:join-videoroom',
      'suscribeToEvents',
      (event: JoinVideoroomMessage) => {
        //alert('Te has unido a la videorroom');
        videoRoom.setRoom(event.room);
        // TODO: HANDLE PROPERLY WHEN WE HAVE MORE THAN ONE PERSON ON THE ROOM
        const myType = !AuthManager.getUserId()
          ? ParticipantType.ContactParticipant
          : ParticipantType.AgentParticipant;
        const me = videoRoom.room.callParticipants.find(
          (participant) => participant.type == myType
        );

        if (event.status == ParticipantResolution.ACCEPTED) {
          if (me?.type == ParticipantType.ContactParticipant) {
            connectWebRTC(context);
          }
        } else {
          setVideocallPhase(VideocallPhase.FINISHED);
          setFinishedReason(FinishReason.DENIED);
          VideoroomWSService.instance.disconnect();
        }
      }
    );

    VideoroomWSService.instance.on(
      'videocalls:participant-status-changed',
      'suscribeToEvents',
      (event: ParticipantStatusMessage) => {

        if (!videoRoom.room) return;

        const previousState = videoRoom.room.callParticipants.find(callParticipant => callParticipant.id == event.participant.id);

        // Remove previous participant
        videoRoom.room.callParticipants =
          videoRoom.room.callParticipants.filter(
            (participant) => participant.id != event.participant.id
          );

        // Kick out logic
        const isSelf = event.participant.id == videoRoom.me?.id;
        const statusIsExited =
          event.participant.status === ParticipantStatus.EXITED;
        const imInCall = videocallPhase === VideocallPhase.INCALL;
        if (isSelf && statusIsExited && imInCall) {
          setVideocallPhase(VideocallPhase.FINISHED);
          setFinishedReason(FinishReason.KICK_OUT);
          VideoroomWSService.instance.disconnect();
        }

        // Notification sounds
        if (event.participant.status === ParticipantStatus.TALKING && previousState.status != event.participant.status) {
          const audio = document.getElementById(
            'video-answer-sound'
          ) as HTMLAudioElement;
          audio.play();
          snackbar.toast(
            `${event.participant.displayName} ${t('has joined the call')}`,
            'success',
            { vertical: 'top', horizontal: 'right' }
          );
        }

        if (event.participant.status === ParticipantStatus.EXITED) {
          const audio = document.getElementById(
            'webrtc-hangup'
          ) as HTMLAudioElement;
          audio.play();
          if (!justTriggeredAnAlert) {
            snackbar.toast(
              `${event.participant.displayName} ${t('has left the call')}`,
              'warning',
              { vertical: 'top', horizontal: 'right' }
            );
            justTriggeredAnAlert = true;
            setTimeout(() => {
              justTriggeredAnAlert = false;
            }, 500);
          }
        }

        videoRoom.room.callParticipants.push(event.participant);
        //console.log(videoRoom.room)
        //alert('El estado de alguien se ha cambiado');

        videoRoom.setRoom({ ...videoRoom.room });
      }
    );
  };
};
