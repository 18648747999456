import { Ticket, TicketSubResolution, TicketType } from '../models/tickets';

export const isOpenTicketType = (type: TicketType) =>
  type === TicketType.NEW || type === TicketType.TOUCHED;

export const checkIsOpenSubResolution = (subResolution: TicketSubResolution) =>
  isOpenTicketType(subResolution?.type);

export const checkIsOpenTicket = (ticket: Ticket) =>
  checkIsOpenSubResolution(ticket?.subResolution);
