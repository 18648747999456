import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getAllUsers } from './thunks';
import { User } from 'src/models/users';

interface DaServicesState {
  users: User[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  userDescriptions: { [rtUserId: string]: string };
}

const initialState: DaServicesState = {
  users: [],
  loading: 'idle',
  userDescriptions: {}
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startLoadingUsers: (state) => {
      state.loading = 'pending';
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
      state.loading = 'succeeded';
    },
    setUserDescriptions: (
      state,
      action: PayloadAction<{ [rtUserId: string]: string }>
    ) => {
      state.userDescriptions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(
      getAllUsers.fulfilled,
      (state, action: PayloadAction<User[]>) => {
        state.users = action.payload;
        state.loading = 'succeeded';
      }
    );
    builder.addCase(getAllUsers.rejected, (state) => {
      state.loading = 'failed';
    });
  }
});

export const { startLoadingUsers, setUsers, setUserDescriptions } =
  usersSlice.actions;

export default usersSlice.reducer;
