import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RTSession } from 'src/models/rtsession'

const initialState = {
	session: {
		userId: '',
		isLogged: false,
		companyId: '',
		voipData: {
			extension: '',
			complexExtension: '',
			domain: '',
			password: '',
		},
	} as RTSession,
}

export const sessionSlice = createSlice({
	name: 'sessionSlice',
	initialState,
	reducers: {
		setSession: (state, action: PayloadAction<RTSession>) => {
			state.session = action.payload
		},
		removeSession: (state, action: PayloadAction<RTSession>) => {
			state.session = initialState.session
		},
		setCompany: (state, action: PayloadAction<string>) => {
			state.session.companyId = action.payload
		},
	},
})

export const { setSession, removeSession, setCompany } = sessionSlice.actions
export default sessionSlice.reducer
