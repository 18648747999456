import { Lock, LockOpenTwoTone, LockTwoTone } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

export const LockCTIButton: React.FC = () => {
  const { barState } = useContext(CTIBarContext);
  const { lockButtonState, setLockButtonState } = barState;
  const { t } = useTranslation();
  const theme = useTheme();

  function createDot(state: number, bgcolor?: string) {
    return (
      <Box
        sx={{
          width: 5,
          height: 5,
          borderRadius: '50%',
          bgcolor: lockButtonState === state ? (bgcolor ?? 'black') : 'black',
          opacity: lockButtonState === state ? 1 : 0.5
        }}
      ></Box>
    );
  }

  return (
    <Tooltip
      title={t(
        lockButtonState === 0
          ? 'Keep bar extended'
          : lockButtonState === 1
            ? 'Keep extended and lock'
            : 'Unlock'
      )}
    >
      <IconButton
        id="compress-bar-button"
        onClick={() => {
          setLockButtonState((lockButtonState + 1) % 3);
        }}
      >
        <>
          {lockButtonState === 0 ? (
            <LockOpenTwoTone fontSize="medium" />
          ) : lockButtonState === 1 ? (
            <LockTwoTone fontSize="medium" color="secondary" />
          ) : (
            <Lock fontSize="medium" />
          )}
          <Stack
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: 10
            }}
            direction="row"
            alignItems={'center'}
            justifyContent={'center'}
            spacing="3px"
          >
            {createDot(0)}
            {createDot(1, theme.colors.secondary.main)}
            {createDot(2)}
          </Stack>
        </>
      </IconButton>
    </Tooltip>
  );
};
