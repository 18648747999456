import { createTicketResolutionApi } from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/resolutions/createTicketResolutionsApi';
import { TicketResolution } from '../../models/tickets';

export const createTicketResolution = async (
  ticketResolution: TicketResolution
) => {
  const resp = await createTicketResolutionApi(ticketResolution);
  return resp;
};

export const copyTicketResolution = async (
  ticketResolution: TicketResolution
): Promise<TicketResolution> => {
  const resp = await createTicketResolution({
    ...ticketResolution,
    name: ticketResolution.name + ' - copy'
  });
  return resp;
};
