import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { COMPANY_FEATURES_ID } from 'src/utils/apiRoutes/msApiGateway';
import { CompanyFeatures } from '../../models/CompanyFeatures';

export async function getCompanyFeaturesApi(companyId: string) {
  const url = replaceURLParams(COMPANY_FEATURES_ID, { '{id}': companyId });

  const response = await axios.get<CompanyFeatures>(url);

  return response.data;
}
