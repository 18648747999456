import { createTicketSubResolutionApi } from 'src/modules/Admin/modules/Operations/modules/Tickets/infrastructure/subResolutions/createTicketSubResolutionsApi';
import { TicketSubResolution } from '../../models/tickets';
import { queryClient } from 'src/utils/queryClient';

export const createTicketSubResolution = async (
  ticketSubResolution: TicketSubResolution
) => {
  const resp = await createTicketSubResolutionApi(ticketSubResolution);
  return resp;
};

export const createTicketSubResolutions = async (
  ticketSubResolutions: TicketSubResolution[]
) => {
  const subResolutionPromises = ticketSubResolutions.map(
    createTicketSubResolution
  );
  const subResolutions = await Promise.all(subResolutionPromises);
  queryClient.invalidateQueries({
    queryKey: ['ticketSubResolutions']
  });
  return subResolutions;
};
