import { emitCustomEvent } from 'react-custom-events';
import { orderCallData } from '../wsHelpers';
import store from 'src/store/store';
import { setRTUsers } from 'src/store/slices/users/rtusers';
import { setRtQueue } from 'src/store/slices/calls/calls';
import { RTQueue } from 'src/models/queue';
import { RTUser } from 'src/models/rtUser';
import { logger } from 'src/utils/logger';

export const CallChange: Function = (eventWS) => {
  logger.log('Event RTCall Emitted');

  let rtusers = store.getState().userData.rtUsers;
  let newRTUsers = [...rtusers];
  let rtCallsList = orderCallData(eventWS.callData);
  let rtCallsQueue = store.getState().callData.rtQueues;
  let newRTCallQueue = [...rtCallsQueue];

  rtCallsList.map((rtUser) => {
    if (rtUser.status === 'Queued') {
      newRTCallQueue = manageRTCallQueue(rtCallsList, newRTCallQueue);

      store.dispatch(setRtQueue(newRTCallQueue));
    } else {
      newRTUsers = manageRTCall(rtCallsList, newRTUsers, newRTCallQueue);

      store.dispatch(setRTUsers(newRTUsers));
    }
  });
};

export const CallSequence: Function = (eventWS) => {
  logger.log('Event CallSequence Emitted');
  emitCustomEvent('CallSequence', eventWS.callSequenceData);
};

const manageRTCall: Function = (
  rtCallList,
  rtUsers: RTUser[],
  rtQueue: RTQueue[]
) => {
  logger.log('COMENZANDO A CAMBIAR ESTADOS.  CALL CHANGE', rtUsers);

  //Asginamos el valor que esta en la tienda
  let tempUsers = [...rtUsers];

  store.dispatch(setRtQueue(rtQueue));

  return tempUsers;
};

const manageRTCallQueue: Function = (rtCallList, rtCalls) => {
  //Asginamos el valor que esta en la tienda
  let tempQueues = [...rtCalls];

  // Recorremos la lista que viene del websocket
  rtCallList.map((rtCall) => {
    let index = tempQueues.findIndex(
      (queue) => queue.id === rtCall.queueInfo?.queueId
    );
    if (index == -1)
      return logger.error(
        'Warning! Queued RTCall but couldn"t find its Queue on the Queues Panel!!  ' +
          rtCall
      );
    let queue = {} as RTQueue;
    let callsMaps = new Map(tempQueues[index].calls);

    callsMaps.set(rtCall.id, rtCall);

    queue = {
      ...tempQueues[index],
      calls: callsMaps
    };
    tempQueues[index] = queue;
  });

  return tempQueues;
};
