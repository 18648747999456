import { Box, List } from '@mui/material';
import { t } from 'i18next';
import {
  Conversation,
  ConversationTableColumns
} from 'src/models/conversations/conversations';
import { getTimeHHmm, subtractTimes } from 'src/utils/formatDateTime';
import RowListItem from './RowListItem';
import RowListItemAvatarGroup from './RowListItemAvatarGroup';
import Scrollbar from 'src/components/Scrollbar';
import { getDateDDmmmYYYY } from 'src/services/socialmedia/domain/conversation/transformCall';
import {
  getCampaignByIdQuery,
  getDaServiceByIdQuery
} from 'src/modules/Admin/modules/Operations/modules/DaServices/views/hooks/daServiceQueries';
import { useEffect, useState } from 'react';

interface GeneralTabPropTypes {
  simpleConversation: Conversation;
}

const GeneralTab: React.FC<GeneralTabPropTypes> = ({ simpleConversation }) => {
  const conversation = simpleConversation as ConversationTableColumns;
  const [serviceName, setServiceName] = useState<string>('');
  const campaign = getCampaignByIdQuery(conversation.campaignId);
  const getServiceName = async () => {
    const service = await getDaServiceByIdQuery(conversation.serviceId);
    setServiceName(service.name);
  };

  useEffect(() => {
    getServiceName();
  }, []);

  return (
    <>
      <Box height="100%">
        <Scrollbar>
          <List
            sx={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <RowListItem label={t('Service')} value={serviceName ?? ''} />
            <RowListItem
              label={t('Campaign')}
              value={campaign?.data?.name ?? ''}
            />
            <RowListItem label={t('Channel')} value={conversation.channel} />
            <RowListItem label={t('Status')} value={conversation.status} />
            <RowListItemAvatarGroup
              label={t('Contacts')}
              conversation={simpleConversation}
            />
            <RowListItem
              label={t('Creation Day')}
              value={
                getDateDDmmmYYYY(conversation.metadata.creationDate) +
                ' ' +
                getTimeHHmm(conversation.metadata.creationDate)
              }
            />
            <RowListItem
              label={t('Modification Day')}
              value={
                getDateDDmmmYYYY(conversation.metadata.modificationDate) +
                ' ' +
                getTimeHHmm(conversation.metadata.modificationDate)
              }
            />
            <RowListItem
              label={t('Overall Duration')}
              value={subtractTimes(
                conversation.metadata.creationDate,
                conversation.metadata.modificationDate
              )}
            />
          </List>
        </Scrollbar>
      </Box>
    </>
  );
};

export default GeneralTab;
