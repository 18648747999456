import axios, { AxiosResponse } from 'axios';
import { WebChatStyle } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatStyle';
import { WEBCHAT_STYLE } from 'src/utils/apiRoutes/msWebChat';

/*Post WebChatStyle */
export const createWebChatStyle = async (
  webChatStyle: WebChatStyle,
  logoImage: File,
  bubbleImages: File[]
): Promise<WebChatStyle> => {
  const formData = new FormData();
  formData.append('logoImage', logoImage);
  formData.append('style', JSON.stringify(webChatStyle));

  bubbleImages.forEach((bubble) => {
    formData.append('bubbleImages', bubble);
  });

  const response: AxiosResponse<WebChatStyle> = await axios.post(
    WEBCHAT_STYLE,
    formData
  );
  return response.data;
};
