import { useEffect, useState } from 'react';
import { Contact } from 'src/modules/Contacts/models/contacts';
import { useGetContactQuery } from 'src/services/socialmedia/application/query/getContactsQuery';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { getConversationInvolvedContacts } from 'src/services/socialmedia/domain/conversation/utils';
import { queryClient } from 'src/utils/queryClient';

function useShowflowInvolvedContacts() {
  const conversation = getCurrentConversation(true);
  const [involvedContacts, setInvolvedContacts] = useState([] as Contact[]);

  useEffect(() => {
    getConversationInvolvedContacts(conversation).then((c) => {
      setInvolvedContacts(c);
    });
  }, []);

  const [selectedContactId, setSelectedContactId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (involvedContacts.length === 1 && selectedContactId === undefined) {
      setSelectedContactId(involvedContacts[0].id);
    }
  }, [involvedContacts]);

  const selectedContactQuery = useGetContactQuery(selectedContactId);

  const selectedContact =
    selectedContactQuery?.data ??
    involvedContacts.find((contact) => contact.id === selectedContactId);

  return {
    involvedContacts,
    selectedContact,
    selectedContactId,
    setSelectedContactId
  };
}

export default useShowflowInvolvedContacts;
