import axios from 'axios';
import { RTCall } from 'src/models/rtcall';
import CallHelper from './callHelperApi';
import { ASTERISK_MS } from 'src/utils/apiRoutes/msAsterisk';

export const getRTCallByCallIdApi = async (
  callId: string,
  instance: string
) => {
  const path =
    CallHelper.setInstanceID(ASTERISK_MS, instance) + '/calls/' + callId;

  const response = await axios.get(path);

  return response.data as RTCall;
};
