import { Button, useTheme } from '@mui/material';
import { t } from 'i18next';
import L from 'leaflet';
import { useEffect } from 'react';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import { LocationContent } from 'src/models/conversations/ChatMessages/LocationMessages';
import { ChatMessage } from 'src/models/conversations/messages';
// import 'leaflet/dist/leaflet.css';

interface Props {
  message: ChatMessage;
}

const mapIcon = L.divIcon({
  html: `<svg
      xmlns="http://www.w3.org/2000/svg"
      height="40px"
      viewBox="0 0 24 24"
      width="40px"
      fill="#EA3323"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" />
      <circle cx="12" cy="9" r="2.5" />
    </svg>`,
  className: 'svg-icon',
  iconSize: [38, 38]
});

const ResetCenterView = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(L.latLng(center[0], center[1]), map.getZoom(), {
        animate: true
      });
    }
  }, [center, map]);

  return null;
};

const ChatMessageMap = (props: Props) => {
  const { message } = props;
  const theme = useTheme();
  const { latitude, longitude } = message.content as LocationContent;

  return (
    latitude &&
    longitude && (
      <>
        <MapContainer
          style={{
            height: '160px',
            marginTop: '5px',
            marginBottom: '5px',
            borderRadius: '15px'
          }}
          zoom={5}
          center={[latitude, longitude]}
          scrollWheelZoom={true}
          zoomControl={false}
          attributionControl={false}
        >
          <Button
            sx={{
              width: '120px',
              height: '25px',
              zIndex: '10000',
              fontSize: '11.5px',
              margin: '7.5px',
              backgroundColor: `#dfe2e6`,
              '&:hover': {
                backgroundColor: `${theme.colors.secondary.main}`,
                color: '#FFFFFF'
              }
            }}
            onClick={() => {
              window.open(
                `https://www.google.com/maps?q=${latitude},${longitude}`,
                '_blank'
              );
            }}
          >
            {t('Open in Maps')}
          </Button>
          <TileLayer
            url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <ResetCenterView center={[latitude, longitude]} />
          <Marker position={[latitude, longitude]} icon={mapIcon} />
        </MapContainer>

        {/* <iframe
        key={message.messageId}
        src={locationUrl}
        width="100%"
        style={{ border: 0, borderRadius: '15px' }}
        allowFullScreen={false}
        aria-hidden="false"
        tabIndex={0}
    /> */}
      </>
    )
  );
};

export default ChatMessageMap;
