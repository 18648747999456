import axios from 'axios';
import { ReportingTicket } from '../../models/tickets';
import { Filter } from 'src/components/TableReporting/models/filtersRequest';
import { PaginatedElements } from 'src/models/pagination';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { ReportingAttachment } from 'src/modules/Productivity/modules/Downloads/models/reportingAttachment';
import { ReportingTicketQuerySelect } from '../../domain/reporting';
import { REPORTING_TICKET } from 'src/utils/apiRoutes/msReporting';

export interface ReportQuery {
  filters: Filter[];
  pageNumber: number;
  pageSize: number;
  select: ReportingTicketQuerySelect[];
  sort: {
    sort: keyof ReportingTicket;
    direction: DirectionType;
  };
}

export const getReportingTicketsApi = async (
  reportQuery: ReportQuery,
  companyId: string,
  name?: string,
  /** Example: Europe/Madrid */
  timeZone?: string,
  download: boolean = false
) => {
  const params = {
    timeZone,
    companyId,
    name,
    count: true,
    download
  };
  const resp = await axios.post<
    PaginatedElements<ReportingTicket> | ReportingAttachment
  >(REPORTING_TICKET, reportQuery, { params });
  return resp.data;
};
