import {
  SendTelegramMessageExistingTextApiParams,
  sendTelegramMessageExistingTextApi
} from '../../infrastructure/message/sendTelegramMessageExistingTextApi';
import {
  SendTelegramMessageNewTextApiParams,
  sendTelegramMessageNewTextApi
} from '../../infrastructure/message/sendTelegramMessageNewTextApi';

interface CommonParams {
  text: string;
}

type ConditionalParams =
  // EXISTING CONVERSATION
  | {
      accountId: string;
      conversationId: string;
      replyTo?: string;
      campaignId?: never;
      phoneNumber?: never;
      username?: never;
      ticketId?: never;
    }
  // NEW CONVERSATION
  | {
      campaignId: string;
      phoneNumber?: string;
      username?: string;
      ticketId?: string;
      accountId?: never;
      conversationId?: never;
      replyTo?: never;
    };

export type SendTelegramMessageTextParams = CommonParams & ConditionalParams;

/**
 * Send a text message text.
 * Provide the phoneNumber or username param to start a new Telegram conversation.
 * Provide the conversationId to send a message to an existing conversation.
 * @param params
 */
export const sendTelegramMessageText = async (
  params: SendTelegramMessageTextParams
) => {
  // NEW CONVERSATION
  if (params?.campaignId) {
    const apiNewParams: SendTelegramMessageNewTextApiParams = {
      campaignId: params.campaignId,
      content: { text: params.text },
      phoneNumber: params?.phoneNumber,
      username: params?.username,
      ticketId: params?.ticketId
    };
    await sendTelegramMessageNewTextApi(apiNewParams);
  }
  // EXISTING CONVERSATION
  if (params?.conversationId) {
    const apiExistingParams: SendTelegramMessageExistingTextApiParams = {
      accountId: params.accountId,
      content: { text: params.text },
      conversationId: params?.conversationId,
      replyTo: params?.replyTo
    };
    await sendTelegramMessageExistingTextApi(apiExistingParams);
  }
};
