import { ReactNode, Ref, forwardRef } from 'react';

import {
  ColumnDef,
  OnChangeFn,
  Row,
  SortingState,
  VisibilityState,
  flexRender
} from '@tanstack/react-table';
import { QueryKey, UseQueryResult } from '@tanstack/react-query';

import {
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  useTheme
} from '@mui/material';
import { CustomFilterValueOptions, DialTableFilter } from './models/filters';
import { DialTableFilters } from './components/DialTableFilters';
import { DialTableHeader } from './components/DialTableHeader';
import { PaginatedElements } from 'src/models/pagination';
import { DataStatus } from 'src/models/dataStatus';
import { DialTableState } from './models/state';
import { DialTableQueryKeys } from './models/queryKey';
import {
  DialTableFiltersContext,
  DialTableBulkActionsContext,
  DialTableReportContext,
  DialTableDataContext,
  DialTableRowActionsContext,
  DialTableActionsBaseContext
} from './models/functionContexts';
import { DialTableHeadCell } from './components/DialTableHeadCell';
import { defaultRowsPerPageOptions } from './models/pagination';
import { useDialTable } from './hooks/useDialTable';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useThrottle } from 'src/hooks/useThrottle';

enum TableView {
  TABLE = 'table',
  GRID = 'grid'
}

// interface ObjectWithId {
//   id?: string;
// }

interface CommonProps<T extends Object> {
  /**
   * Function in charge of retrieving the table columns.
   *
   * See https://tanstack.com/table/latest/docs/api/core/column-def .
   *
   * TO DO: extend ColumnDef interface to make the id prop required.
   *
   * The id prop of the columns is required because it is passed as a columnIds param of the function onGenerateReport and getDataFn,
   * and it is also used to manage the order and visibility of the columns.
   */
  getColumnsFn: () => Promise<ColumnDef<T>[]>;
  /**
   * The query key to use for the table queries. If not passed, generated randomly.
   * See https://tanstack.com/query/v5/docs/framework/react/guides/query-keys .
   */
  queryKey?: QueryKey | Partial<DialTableQueryKeys>;
  /** Function in charge of retrieving the available filters. Required to show the filters. */
  getAvailableFilters?: () => Promise<DialTableFilter<T>[]>;
  /** Function in charge of retrieving the initial selected filters. No initial selected filters if not passed. */
  getInitialSelectedFilters?: (
    availableFilters?: DialTableFilter<T>[]
  ) => Promise<DialTableFilter<T>[]>;
  state?: Partial<DialTableState<T>>;
  /** Only the 'table' type has been developed. The default is 'table' */
  view?: TableView;
  /**
   * 'true' to fetch and display the table data when the component is fisrt rendered.
   * False to not display the data initially. The default is 'true'.
   *
   * This prop is passed to the enabled prop of the useQuery hook in charge of fetching the data.
   */
  getDataOnInit?: boolean;
  /** Hide table header */
  hideTableHeader?: boolean;
  /** Hide table footer */
  hideTableFooter?: boolean;
  /**
   * Function in charge of retrieving custom actions.
   */
  getCustomHeaderActionsFn?: (
    context: DialTableActionsBaseContext<T>
  ) => ReactNode;
  /** Function in charge of retrieving the bulk actions. No bulk actions if not passed */
  getBulkActionsFn?: (context: DialTableBulkActionsContext<T>) => ReactNode;
  /** Function in charge of retrieving the row actions. No row actions if not passed */
  getRowActionsFn?: (context: DialTableRowActionsContext<T>) => ReactNode;
  onClickRow?: (rowData: T) => void;
  onApplyFilters?: (context: DialTableFiltersContext<T>) => void;
  /** Function to handle the report generation. The button is not displayed if not passed. */
  onGenerateReport?: (context: DialTableReportContext<T>) => Promise<void>;
  /**
   * Function to hide columns initially. If not passed, all columns are shown.
   * See https://tanstack.com/table/latest/docs/api/features/column-visibility .
   */
  getInitialColumnVisibilityFn?: (columns: ColumnDef<T>[]) => VisibilityState;
  /** If true, the URL search params are NOT added (also not retrieved) to the URL when clicking on the 'Apply filters' button. Defaults to false */
  disableSetUrlSearchParams?: boolean;
  /** Object with callbacks called to return custom filter options and selected values. The custom options and selcted values will not overwrite the default options.
   *
   * This callback can be used to consider other DataCategory that are not already considered by the table.
   */
  customFilterValueOptions?: CustomFilterValueOptions;
  /** If true, filters only display the equals operator option */
  onlyEqualsOperator?: boolean;
  /**
   * If this function is provided, it will be called when the selected filters changes and you will be expected to manage the state yourself.
   * You can pass the managed state back to the table via the props.state.selectedFilters option.
   */
  onSelectedFiltersChange?: OnChangeFn<DialTableFilter<T>[]>;
  onSortingChange?: OnChangeFn<SortingState>;
  /**
   * When manualSorting is set to true, the table will assume that the data that you provide is already sorted, and will not apply any sorting to it.
   */
  manualSorting?: boolean;
  /**
   * By default, the ability to remove sorting while cycling through the sorting states for a column is enabled.
   * You can disable this behavior using the enableSortingRemoval table option.
   * This behavior is useful if you want to ensure that at least one column is always sorted.
   */
  enableSortingRemoval?: boolean;
  /**
   * The default selected row per page will be the first item of the array
   */
  rowsPerPageOptions?: number[];
  renderFallback?: (error: any) => ReactNode | ReactNode;
  showTimeZoneSelector?: boolean;
  /**
   * Function that returns a unique row id for each row. By default, the row id for each row is, if possible, the T.id, otherwise, simply the row.index.
   * @param originalRow
   * @param index
   * @param parent
   * @returns
   */
  getRowId?: (originalRow: T, index: number, parent?: Row<T>) => string;
}

/** It is not allowed to pass both props (getDataFn and data) at the same time. Another way to do this would be to assgin priorities. */
type ConditionalProps<T> =
  | {
      /** Function in charge of getting the table data both initially and when clicking on the 'Apply filters' button from the filters section. */
      getDataFn: (
        context: DialTableDataContext<T>
      ) => Promise<PaginatedElements<T>>;
      data?: never;
    }
  | {
      getDataFn?: never;
      /** The data to be displayed in the table. */
      data: T[];
    };

const CustomTableContainer = styled(TableContainer)(
  ({ theme }) => `
  overflow: auto;
  ::-webkit-scrollbar {
      height: 5px;
  }
  
  ::-webkit-scrollbar-track {
      background: ${theme.colors.alpha.black[10]}; /* Color of the track */
  }
  
  ::-webkit-scrollbar-thumb {
      background: ${theme.colors.alpha.black[50]}; /* Color of the thumb */
      border-radius: 5px; /* Roundness of the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background:${theme.colors.alpha.black[70]}; /* Color of the thumb on hover */
  }
  `
);

/** DIALTABLE PROPS */
type DialTableProps<T> = CommonProps<T> & ConditionalProps<T>;

export type DialTableImperativeHandle<T> = {
  refetchData: (options?: {
    throwOnError: boolean;
    cancelRefetch: boolean;
  }) => Promise<UseQueryResult>;
};

const DialTableRender = <T extends Object>(
  props: DialTableProps<T>,
  ref?: Ref<DialTableImperativeHandle<T>>
) => {
  const {
    customFilterValueOptions,
    data: dataProps,
    disableSetUrlSearchParams,
    getAvailableFilters,
    getBulkActionsFn,
    getColumnsFn,
    getCustomHeaderActionsFn,
    getDataFn,
    getInitialColumnVisibilityFn,
    getInitialSelectedFilters,
    getRowActionsFn,
    getRowId,
    hideTableFooter,
    hideTableHeader,
    onApplyFilters: onApplyFiltersProps,
    onClickRow,
    onGenerateReport: onGenerateReportProps,
    onlyEqualsOperator,
    onSelectedFiltersChange,
    onSortingChange,
    queryKey,
    renderFallback,
    state,
    enableSortingRemoval = true,
    getDataOnInit = true,
    manualSorting = true,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    showTimeZoneSelector = false,
    view = TableView.TABLE
  } = props;

  const {
    availableFilters,
    bulkActions,
    columns,
    data,
    displayedRowsLength,
    fallback: Fallback,
    isError,
    onApplyFilters,
    onColumnDragEnd,
    onGenerateReport,
    queryResult,
    selectedBulkActions,
    selectedFilters,
    setSelectedFilters,
    table,
    tablePaginationProps,
    isLoadingSelectedFilters,
    customHeaderActions,
    timeZone,
    setTimeZone
  } = useDialTable({
    ref,
    queryKey,
    getColumnsFn,
    data: dataProps,
    getDataFn,
    getAvailableFilters,
    getInitialSelectedFilters,
    getRowActionsFn,
    getBulkActionsFn,
    getDataOnInit,
    getInitialColumnVisibilityFn,
    onGenerateReport: onGenerateReportProps,
    disableSetUrlSearchParams,
    onApplyFilters: onApplyFiltersProps,
    state,
    onSelectedFiltersChange,
    onSortingChange,
    manualSorting,
    enableSortingRemoval,
    rowsPerPageOptions,
    renderFallback,
    getCustomHeaderActionsFn,
    showTimeZoneSelector,
    getRowId
  });

  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const throttle = useThrottle();

  // If cached data, the fallback is not displayed
  if (isError) {
    enqueueSnackbar(t('An error ocurred'), {
      variant: 'error',
      autoHideDuration: 3000,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top'
      }
    });
    return <>{Fallback}</>;
  }

  return (
    <Stack spacing={3}>
      {!!getAvailableFilters && (
        <DialTableFilters
          availableFilters={availableFilters}
          selectedFilters={selectedFilters}
          onChangeSelectedFilters={setSelectedFilters}
          onApplyFilters={onApplyFilters}
          customFilterValueOptions={customFilterValueOptions}
          onlyEqualsOperator={onlyEqualsOperator}
          disabled={
            queryResult?.isFetching ||
            isLoadingSelectedFilters === DataStatus.LOADING ||
            isError
          }
          loading={queryResult?.isFetching}
        />
      )}
      {view === TableView.TABLE && (
        <Card>
          {!hideTableHeader && (
            <DialTableHeader
              bulkActions={bulkActions}
              columns={columns}
              getIsAllColumnsVisible={table.getIsAllColumnsVisible}
              getToggleAllColumnsVisibilityHandler={
                table.getToggleAllColumnsVisibilityHandler
              }
              displayedRowsLength={displayedRowsLength}
              selectedBulkActions={selectedBulkActions}
              tablePaginationProps={tablePaginationProps}
              isFetching={queryResult?.isFetching}
              onColumnDragEnd={onColumnDragEnd}
              onGenerateReport={onGenerateReport}
              customHeaderActions={customHeaderActions}
              showTimeZoneSelector={showTimeZoneSelector}
              timeZone={timeZone}
              onChangeTimeZone={setTimeZone}
            />
          )}

          {data.length === 0 ? (
            <>{/* TO DO: PLACEHOLDER FOR WHEN THERE IS NO DATA */}</>
          ) : (
            <>
              <CustomTableContainer>
                <Table>
                  <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <DialTableHeadCell
                            key={header.id}
                            sorting={
                              (!!state?.sorting || !manualSorting) &&
                              header.column.getCanSort()
                            }
                            active={!!header.column.getIsSorted()}
                            sortDirection={
                              header.column.getIsSorted()
                                ? state?.sorting.find(
                                    (columnSort) => columnSort.id === header.id
                                  ).desc
                                  ? 'desc'
                                  : 'asc'
                                : false
                            }
                            onClick={(e) => {
                              const sortingHandler =
                                header.column.getToggleSortingHandler();
                              // limits the execution of the function to once every specified time interval, which prevents it from being called too often.
                              throttle(() => {
                                sortingHandler(e);
                              }, 500);
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </DialTableHeadCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          selected={row.getIsSelected()}
                          onClick={() => {
                            onClickRow?.(row.original);
                          }}
                          sx={{ cursor: onClickRow ? 'pointer' : 'default' }}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell
                              key={cell.id}
                              sx={{ px: 1, py: 0.5, fontSize: 12 }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CustomTableContainer>
              {!hideTableFooter && (
                <Box
                  sx={{
                    p: hideTableHeader ? 0 : 2,
                    height: hideTableHeader ? undefined : theme.header.height
                  }}
                >
                  <TablePagination {...tablePaginationProps} />
                </Box>
              )}
            </>
          )}
        </Card>
      )}
    </Stack>
  );
};

/** DialTable, the awesome tanstack based table. See https://tanstack.com/ . */
export const DialTable = forwardRef(DialTableRender) as <T extends Object>(
  props: DialTableProps<T> & { ref?: Ref<DialTableImperativeHandle<T>> }
) => JSX.Element;
