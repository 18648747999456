import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/modules/Shared/utils/apiFunctions';
import { Dashboard, DashboardPanelParams } from '../../models/Dashboards';
import {
  REALTIME_DASHBOARDS,
  REALTIME_DASHBOARDS_ADD_PANEL,
  REALTIME_DASHBOARDS_ID,
  REALTIME_DASHBOARDS_SUBSCRIBE,
  REALTIME_DASHBOARDS_UNSUBSCRIBE,
} from 'src/utils/apiRoutes/msFlow';

export const createDashboard = async (
  dashboard: Dashboard
): Promise<Dashboard> => {
  const response: AxiosResponse<Dashboard> = await axios.post(
    REALTIME_DASHBOARDS,
    dashboard
  );

  return response.data;
};

export const updateDashboardById = async (
  dashboardId: string,
  dashboard: Dashboard
): Promise<Dashboard> => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_ID, {
    '{id}': dashboardId,
  });

  const response: AxiosResponse<Dashboard> = await axios.put(path, dashboard);

  return response.data;
};

export const addDashboardPanel = async (
  dashboardId: string,
  params: DashboardPanelParams
): Promise<Dashboard> => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_ADD_PANEL, {
    '{id}': dashboardId,
  });

  const response: AxiosResponse<Dashboard> = await axios.post(path, null, {
    params,
  });

  return response.data;
};

export const subscribeDashboard = async (
  dashboardId: string,
  sessionId: string
): Promise<Dashboard> => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_SUBSCRIBE, {
    '{id}': dashboardId,
  });
  const params = { sessionId };
  const response: AxiosResponse<Dashboard> = await axios.post(path, null, {
    params,
  });

  return response.data;
};

export const unSubscribeDashboard = async (
  dashboardId: string,
  sessionId: string
): Promise<Dashboard> => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_UNSUBSCRIBE, {
    '{id}': dashboardId,
  });
  const params = { sessionId };
  const response: AxiosResponse<Dashboard> = await axios.post(path, null, {
    params,
  });

  return response.data;
};
