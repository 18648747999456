import axios from 'axios';
import { WebChatAccount } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatAccount';

import { replaceURLParams } from 'src/services/utils';
import { WEBCHAT_ACCOUNT_ID } from 'src/utils/apiRoutes/msWebChat';

export const updateWebChatAccountApi = async (
  webChatAccount: WebChatAccount
) => {
  const path = replaceURLParams(WEBCHAT_ACCOUNT_ID, {
    '{id}': webChatAccount.id
  });

  const response = await axios.put(path, webChatAccount);
  return response.data as WebChatAccount;
};
