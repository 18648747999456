import { getTicketSubResolutions } from '../../domain/subResolutions';
import {
  TicketResolution,
  TicketResolutionUI,
  TicketSubResolution
} from '../../models/tickets';
import { getTicketSubResolutionsQuery } from './getTicketSubResolutionsQuery';
import { TicketsDomain } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/TicketsDomain';

/** Gets the ticket resolutions by fetching the filtered ticket subResolutions*/
export const getTicketResolutionsUIBy = async (filters: {
  companyId: string;
  serviceId?: string;
}): Promise<TicketResolutionUI[]> => {
  // Get the filtered ticket subResolutions
  const subResolutions = await getTicketSubResolutionsQuery({
    companyId: filters?.companyId,
    serviceId: filters?.serviceId
  });
  // Get unique resolutions from ticket subResolutions
  const resolutions = subResolutions.reduce(
    (prev: TicketResolutionUI[], curr: TicketSubResolution) => {
      // Index to save the position of a resolution already added to the prev array
      // So, allow NOT to add the same resolution more than once and also be able to add the subResolutions
      let index = 0;
      const resolutionAdded = prev.find((resolution, i) => {
        // Update the index
        index = i;
        return resolution.id === curr.resolution.id;
      });
      const isResolutionAdded = Boolean(resolutionAdded);

      // If the resolution is already added
      if (prev.length > 0 && isResolutionAdded) {
        // Update the subResolutions
        const subResolutions = [...prev[index].subResolutions, curr];
        // Update the subResolutionNames
        const subResolutionNames = getTicketResolutionNamesUI([
          ...prev[index].subResolutions,
          curr
        ]);
        const resolutionUi = {
          ...curr.resolution,
          subResolutions: subResolutions,
          subResolutionNames: subResolutionNames
        } as TicketResolutionUI;
        // Update the prev array
        prev[index] = resolutionUi;
        // Return the updated prev array
        return prev;
      }
      // If the resolution has not been added yet, the resolution is added to the prev array
      return [
        ...prev,
        {
          ...curr.resolution,
          subResolutionNames: getTicketResolutionNamesUI([curr]),
          subResolutions: [curr]
        } as TicketResolutionUI
      ];
    },
    [] as TicketResolutionUI[]
  );

  return resolutions;
};

/** Gets the ticket resolutions by fetching the ticket resolutions and their ticket subResolutions */
export const getTicketResolutionsUI = async (companyId: string) => {
  const resolutions = await TicketsDomain.getTicketResolutions(companyId);
  const resolutionPromises = resolutions.map(getTicketResolutionUI);
  const resolutionsUI = await Promise.all(resolutionPromises);
  return resolutionsUI;
};

export const getTicketResolutionUI = async (
  resolution: TicketResolution
): Promise<TicketResolutionUI> => {
  const companySubresolutions = await getTicketSubResolutionsQuery({
    companyId: resolution?.companyId
  });

  const subresolutions = companySubresolutions.filter(
    (sub) => resolution.id === sub.resolution.id
  );

  const subresolutionNames = getTicketResolutionNamesUI(subresolutions);

  return {
    ...resolution,
    subResolutionNames: subresolutionNames,
    subResolutions: subresolutions
  };
};

export const getTicketResolutionNamesUI = (
  subResolutions: TicketSubResolution[]
) => {
  const subResolutionNames = subResolutions
    .map((subresolution) => subresolution.name)
    .join(', ');
  return subResolutionNames;
};

export const getTicketResolution = (
  resolutionUI: TicketResolutionUI
): TicketResolution => {
  const { subResolutionNames, subResolutions, ...resolution } = resolutionUI;
  return resolution;
};
