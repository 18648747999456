import { MouseEvent, useContext, useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChannelType } from 'src/models/conversations/conversations';
import { ContactUI } from 'src/modules/Contacts/models/contacts';
import {
  Chat,
  EmailOutlined,
  Instagram,
  Phone,
  Telegram,
  WhatsApp
} from '@mui/icons-material';
import { getContactListUI } from 'src/modules/Contacts/views/utils/getContactsUI';
import { Ticket } from '../../models/tickets';
import { channelToDialpadTab, CTIBarContext } from 'src/contexts/CTIBarContext';

type ChannelContactField = {
  [key in ChannelType]: keyof ContactUI;
};

interface Params {
  ticket?: Ticket;
  onClick?: (context: { contact: ContactUI; channel: ChannelType }) => void;
}

export const useTicketConversationButtons = ({ ticket, onClick }: Params) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isChannelEnabled } = useContext(CTIBarContext);

  const startTicketConversationChannels = Object.values(ChannelType).filter(
    (channel) => {
      if (channel === ChannelType.WEBCHAT) return false;
      return isChannelEnabled(channel);
    }
  );

  const [contactList, setContactList] = useState<ContactUI[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<ChannelType>(null);

  const getChannelIcon = (channel: ChannelType) => {
    switch (channel) {
      case ChannelType.CALL:
        return <Phone />;
      case ChannelType.EMAIL:
        return <EmailOutlined />;
      case ChannelType.TELEGRAM:
        return <Telegram />;
      case ChannelType.WHATSAPP:
        return <WhatsApp />;
      case ChannelType.WEBCHAT:
        return <Chat />;
      case ChannelType.INSTAGRAM:
        return <Instagram />;
      default:
        return null;
    }
  };

  const getChannelColor = (theme: Theme, channel: ChannelType) => {
    switch (channel) {
      case ChannelType.CALL:
        return theme.colors.primary.main;
      case ChannelType.EMAIL:
        return theme.colors.primary.main;
      case ChannelType.TELEGRAM:
        return theme.colors.secondary.main;
      case ChannelType.WHATSAPP:
        return theme.colors.success.main;
      case ChannelType.WEBCHAT:
        return theme.colors.warning.main;
      case ChannelType.INSTAGRAM:
        return '#c13584';
      default:
        return theme.colors.primary.main;
    }
  };

  const getChannelTitle = (channel: ChannelType) => {
    switch (channel) {
      case ChannelType.CALL:
        return 'Call';
      case ChannelType.EMAIL:
        return 'Email';
      case ChannelType.TELEGRAM:
        return 'Telegram';
      case ChannelType.WHATSAPP:
        return 'WhatsApp';
      case ChannelType.WEBCHAT:
        return 'Webchat';
      case ChannelType.INSTAGRAM:
        return 'Instagram';
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!!ticket) {
      const involvedContactIds = ticket?.involvedContacts
        .map((contact) => contact?.contactId)
        .filter((id) => !!id);

      if (involvedContactIds.length > 0) {
        getContactListUI({
          contactIds: involvedContactIds,
          companyId: ticket?.companyId,
          withContactTags: false,
          withContactGroupName: false
        }).then((resp) => {
          setContactList(resp);
        });
      }
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    channel: ChannelType
  ) => {
    const channelContactFieldList = contactList?.filter((contact) =>
      isChannelContactField(contact, channel)
    );
    if (channelContactFieldList.length <= 1) {
      const contact = channelContactFieldList?.[0];
      onClick({ contact, channel });
      return;
    }
    setSelectedChannel(channel);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedChannel(null);
  };

  const onClickMenuItem = (contact: ContactUI) => {
    if (!selectedChannel) return;
    onClick({ contact, channel: selectedChannel });
    handleClose();
  };

  /** Checks if the contact has a main field of the channel */
  const isChannelContactField = (contact: ContactUI, channel: ChannelType) => {
    const channelContactFieldObject: ChannelContactField = {
      Call: 'phone',
      Email: 'email',
      Instagram: 'instagram',
      Telegram: 'telegram',
      WhatsApp: 'phone'
    } as ChannelContactField;
    const fieldKey = channelContactFieldObject?.[channel];
    return Boolean(contact?.[fieldKey]);
  };

  /** Contact list with fields of the selected channel */
  const contactChannelList = contactList.filter((contact) => {
    if (!selectedChannel) return false;
    return isChannelContactField(contact, selectedChannel);
  });
  return {
    anchorEl,
    contactChannelList,
    getChannelColor,
    getChannelIcon,
    getChannelTitle,
    handleClick,
    handleClose,
    onClickMenuItem,
    open,
    startTicketConversationChannels,
    t,
    theme
  };
};
