import {
  Box,
  CircularProgress,
  Divider,
  Pagination,
  Stack,
  Typography
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from 'src/modules/Contacts/models/contacts';
import { Conversation } from 'src/models/conversations/conversations';
import ActionConversation from 'src/modules/Productivity/modules/Conversations/views/components/ActionConversation';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { ConversationListItem } from './ConversationListItem';
import { getConversationsByContact } from 'src/services/conversations/domain/getClosedConversationsByContact';

interface ConversationListProps {
  disableOnclick?: boolean;
  selectedContact?: Contact;
  itemsPerPage: number;
}
interface PaginationInfo {
  actualPage: number;
  totalPages: number;
}

export const ConversationList: React.FC<ConversationListProps> = ({
  disableOnclick,
  selectedContact,
  itemsPerPage
}) => {
  const { t } = useTranslation();
  const [conversationList, setConversationList] = useState(
    [] as Conversation[]
  );
  const currentConversation = getCurrentConversation(true);

  const [isLoading, setIsLoading] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState({
    actualPage: 0,
    totalPages: 1
  } as PaginationInfo);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);

  const isOpen = selectedConversation !== null;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      const conversationRequest = getConversationsByContact({
        shouldUseHook: false,
        contactId:
          selectedContact?.id ||
          currentConversation?.involvedContacts?.[0]?.contactId,
        size: itemsPerPage,
        page: paginationInfo.actualPage
      });
      conversationRequest.then((conversations) => {
        if (isMounted) {
          setPaginationInfo({
            ...paginationInfo,
            totalPages: conversations.totalPages
          });
          setConversationList(
            conversations.elements.sort((a, b) => {
              return (
                new Date(b.metadata.creationDate).getTime() -
                new Date(a.metadata.creationDate).getTime()
              );
            })
          );
          setIsLoading(false);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [currentConversation?.id, paginationInfo.actualPage]);

  // If the contact is not saved
  if (
    (!selectedContact &&
      currentConversation?.involvedContacts[0]?.contactId == null) ||
    (selectedContact && selectedContact.id === undefined)
  ) {
    return (
      <Box pl={6} pr={1} height={'100%'}>
        <Stack alignItems={'center'} py={2}>
          <h3>{t('Save the contact to see the history')}</h3>
        </Stack>
      </Box>
    );
  }

  // If the conversation is loading
  if (isLoading)
    return (
      <Box pl={6} pr={1} height={'100%'}>
        <Stack alignItems={'center'}>
          <CircularProgress />
        </Stack>
      </Box>
    );

  // If there are no conversations
  if (!isLoading && conversationList.length === 0) {
    return (
      <Box
        pl={6}
        pt={6}
        pr={1}
        height={'100%'}
        display="flex"
        justifyContent="center"
      >
        <Typography variant="h4">{t('No conversations found')}</Typography>
      </Box>
    );
  }

  // If there are conversations
  return (
    <Box pl={0} pr={1} height={'100%'}>
      <ActionConversation
        isOpen={isOpen}
        onClose={() => {
          setSelectedConversation(null);
        }}
        conversation={selectedConversation}
      />
      <Stack
        width="100%"
        py={2}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={2}
      >
        <Stack
          width="100%"
          direction="column"
          alignItems={'center'}
          spacing={2}
          divider={<Divider orientation="horizontal" sx={{ width: '65%' }} />}
        >
          {conversationList.map((conv, index) => {
            return (
              <ConversationListItem
                key={index}
                disableOnclick={disableOnclick}
                conversation={conv}
                setConversation={setSelectedConversation}
              />
            );
          })}
        </Stack>

        {paginationInfo.totalPages > 1 && (
          <Pagination
            defaultPage={1}
            page={paginationInfo.actualPage + 1}
            onChange={(event, pageNumber) => {
              setPaginationInfo({
                ...paginationInfo,
                actualPage: pageNumber - 1
              });
            }}
            count={paginationInfo.totalPages}
          />
        )}
      </Stack>
    </Box>
  );
};
