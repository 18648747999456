import { fieldsCreateRoute } from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/views/utils/constants';
import {
  ContactFieldType,
  Contact
} from 'src/modules/Contacts/models/contacts';
import { getContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';

/**
 * Retrieves the name of the contact, taking the company contact fields into consideration.
 *
 * @param filter Parameters to filter contacts by
 * @returns Contact
 */
export const getContactFieldValueFromType = async (
  contact: Contact,
  fieldType: ContactFieldType
): Promise<string | null> => {
  let companyFields = await getContactFieldsQuery({
    contactGroupId: contact.contactGroupId,
    type: fieldType
  });

  if (companyFields?.elements?.length === 0) return null;

  let field = companyFields[0];
  return contact.fields[field.id];
};
