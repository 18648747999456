import { Typography } from '@mui/material';
import Label, { LabelColorType } from 'src/components/Label';
import {
  TicketChangeHistory,
  TicketChangeHistoryAction,
  TicketChangeHistoryElement
} from '../../models/tickets';
import { getUserByIdQuery } from 'src/services/users/application/query/getUsersDataQueries';

export const getTicketHistoryItemText = async (
  ticketHistory: TicketChangeHistory | null,
  author?: string
) => {
  const ticketChangeAuthor = author;
  const ticketHistoryElement = ticketHistory?.element.toLowerCase();
  const ticketHistoryElementId = getTicketHistoryElementId(ticketHistory);
  const ticketHistoryPreviousValue = await getTicketHistoryPreviousValue(
    ticketHistory
  );
  const ticketHistoryCurrentValue = await getTicketHistoryCurrentValue(
    ticketHistory
  );

  if (!ticketHistory) return <></>;

  if (ticketHistory.action === TicketChangeHistoryAction.UPDATE) {
    return (
      <Typography>
        <b>{ticketChangeAuthor}</b> updated the <b>{ticketHistoryElementId}</b>{' '}
        {ticketHistoryElement} from {ticketHistoryPreviousValue} to{' '}
        {ticketHistoryCurrentValue}
      </Typography>
    );
  }
  if (ticketHistory.action === TicketChangeHistoryAction.REMOVE) {
    return (
      <Typography>
        <b>{ticketChangeAuthor}</b> deleted the <b>{ticketHistoryElementId}</b>{' '}
        {ticketHistoryElement} {ticketHistoryPreviousValue}
      </Typography>
    );
  }
  if (ticketHistory.action === TicketChangeHistoryAction.ADD) {
    return (
      <Typography>
        <b>{ticketChangeAuthor}</b> added the <b>{ticketHistoryElementId}</b>{' '}
        {ticketHistoryElement} {ticketHistoryCurrentValue}
      </Typography>
    );
  }
};

const getTicketHistoryElementId = (ticketHistory: TicketChangeHistory) => {
  if (ticketHistory.element !== TicketChangeHistoryElement.ATTRIBUTE)
    return null;
  return ticketHistory.elementId;
};

const getTicketHistoryPreviousValue = async (
  ticketHistory: TicketChangeHistory
) => {
  let value = ticketHistory.previousValue;
  if (
    !ticketHistory.previousValue &&
    ticketHistory.element !== TicketChangeHistoryElement.AGENT
  )
    return null;
  if (ticketHistory.element === TicketChangeHistoryElement.COMMENT) {
    return `"${ticketHistory.previousValue}"`;
  }
  if (ticketHistory.element === TicketChangeHistoryElement.AGENT) {
    const user = await getUserByIdQuery(ticketHistory.elementId);
    value = user.username;
  }
  const color: LabelColorType =
    ticketHistory.action === TicketChangeHistoryAction.REMOVE
      ? 'error'
      : 'warning';

  return (
    <Label color={color}>
      <b>{value}</b>
    </Label>
  );
};

const getTicketHistoryCurrentValue = async (
  ticketHistory: TicketChangeHistory
) => {
  let value = ticketHistory.currentValue;

  if (
    !ticketHistory.currentValue &&
    ticketHistory.element !== TicketChangeHistoryElement.AGENT
  )
    return null;
  if (ticketHistory.element === TicketChangeHistoryElement.COMMENT) {
    return `"${ticketHistory.currentValue}"`;
  }

  if (ticketHistory.element === TicketChangeHistoryElement.AGENT) {
    const user = await getUserByIdQuery(ticketHistory.elementId);
    value = user.username;
  }
  const color: LabelColorType = 'secondary';

  return (
    <Label color={color}>
      <b>{value}</b>
    </Label>
  );
};
