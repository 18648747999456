import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  DialerBotCampaign,
  DialerbotExecutionStatus,
  DialerbotFiles
} from 'src/modules/DialerBot/models/dialerbot';
import {
  getDialerBotDomain,
  getDialerBots,
  getDialerBotsCampaigns
} from '../../domain/getDialerBotData';
import { DialerBotCampaignsParams } from 'src/modules/DialerBot/models/dialerbot';
import { PaginatedElements, PaginationValues } from 'src/models/pagination';
import { getSchedulesById } from '../../domain/getSchedules';
import { queryClient } from 'src/utils/queryClient';

export const useGetDialerBotCamapignsQuery = (
  params: DialerBotCampaignsParams
): UseQueryResult<DialerBotCampaign[]> => {
  return useQuery<DialerBotCampaign[], Error>(
    ['dialerbotCampaigns', params],
    () => getDialerBotsCampaigns(params),
    { enabled: true, refetchInterval: 10000 }
  );
};

export const useGetDialerBotDataSchedulesQuery = (
  campaignId: string,
  currentPage?: number
): UseQueryResult<PaginatedElements<DialerbotFiles>> => {
  return useQuery<PaginatedElements<DialerbotFiles>, Error>(
    ['dialerbotSchedulesById', campaignId, currentPage],
    () => getSchedulesById(campaignId, currentPage),
    { staleTime: 3 * 60 * 1000, refetchOnWindowFocus: false }
  );
};

export function invalidateDialerbotSchedulesById() {
  queryClient.invalidateQueries({ queryKey: ['dialerbotSchedulesById'] });
}

export const useGetDialerBotsQuery = (): UseQueryResult<
  DialerbotExecutionStatus[]
> => {
  return useQuery<DialerbotExecutionStatus[], Error>(
    ['dialerbots'],
    () => getDialerBots(),
    { enabled: true, refetchInterval: 10000 }
  );
};

export const useGetDialerBotInformationQuery = async (
  id: string
): Promise<DialerBotCampaign> => {
  const dialerbot = await getDialerBotDomain(id);
  return dialerbot;
};
