import { Dispatch, SetStateAction, useState } from 'react';

import { Avatar, Button, Tooltip, styled } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { t } from 'i18next';
import { Ticket, TicketConversationSummary } from '../../../../models/tickets';
import { Conversation } from 'src/models/conversations/conversations';
import { getTicketConversationSummaryFrom } from '../../../../domain/getTickets';
import { TicketsDomain } from '../../../../domain/TicketsDomain';
import { useAlert } from 'src/hooks/useAlert';

const ButtonAddAction = styled(Button)(
  ({ theme }) => `
      border: ${theme.colors.secondary.main} dashed 1px;
      color: ${theme.colors.secondary.main};
      transition: ${theme.transitions.create(['all'])};
      justify-content: center;
      align-items: center;
      display: flex;
  
      .MuiTouchRipple-root {
        opacity: .2;
      }
      
      &:hover {
        border-color: ${theme.colors.alpha.black[70]};
      }
  `
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.secondary.main};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
  `
);

interface Props {
  currentConversation: Conversation;
  setConversations?: Dispatch<SetStateAction<TicketConversationSummary[]>>;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
}
const AddTicketConversationButton = (props: Props) => {
  const { currentConversation, setConversations, setTicket } = props;
  const [isAllowedToAddConversation, setIsAllowedToAddConversation] =
    useState(true);
  const currentConversationSummary =
    getTicketConversationSummaryFrom(currentConversation);
  const conversationContactIds =
    currentConversation?.involvedContacts
      .map((contact) => contact.contactId)
      .filter((contactId) => !!contactId) ?? [];
  const serviceId = currentConversation?.serviceId;

  const { showAlert } = useAlert();

  return (
    <Tooltip title={t('Add conversation')}>
      <ButtonAddAction
        disabled={!isAllowedToAddConversation}
        disableRipple={false}
        disableTouchRipple={false}
        sx={{
          width: '25%'
        }}
        onClick={async () => {
          if (conversationContactIds.length > 0) {
            TicketsDomain.checkTicketContactLimit({
              contactIds: conversationContactIds,
              serviceId
            }).then((isAllowed) => {
              setIsAllowedToAddConversation(isAllowed);
              if (!isAllowed) {
                showAlert(
                  t(
                    'Maximum number of tickets per contact has been reached! It is not possible to add a new conversation to this ticket.'
                  ),
                  'warning',
                  3000
                );
                return;
              }
            });
          } else {
            setIsAllowedToAddConversation(true);
          }
          if (setConversations)
            setConversations((prev) => [currentConversationSummary, ...prev]);
          if (setTicket) {
            setTicket((prev) => {
              const previousConversationSummaries =
                prev?.conversationSummaries ?? [];
              const previousConversationIds = prev?.conversationIds ?? [];
              return {
                ...prev,
                conversationSummaries: [
                  currentConversationSummary,
                  ...previousConversationSummaries
                ],
                conversationIds: [
                  currentConversationSummary?.id,
                  ...previousConversationIds
                ]
              };
            });
          }
        }}
      >
        <AvatarAddWrapper>
          <AddTwoToneIcon fontSize="medium" />
        </AvatarAddWrapper>
      </ButtonAddAction>
    </Tooltip>
  );
};

export default AddTicketConversationButton;
