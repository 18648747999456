import { Conversation } from 'src/models/conversations/conversations';
import { getIframepanels } from 'src/services/iframepanels/domain/getIframepanels';
import { updateIframepanels } from 'src/services/iframepanels/domain/updateIframepanel';
import { ThirdPartyIframeData } from 'src/store/slices/socialmedia/socialmediaSlice';
import { setCurrentConversationStore } from '../../infraestructure/store/setCurrentConversationStore';
import { logger } from 'src/utils/logger';
import { getConversationExternalAction } from 'src/modules/Admin/modules/Integrations/modules/externalActions/domain/getConversationExternalAction';
import { HOTLINE_COMPANIES } from './utils';
import { emitCustomEvent } from 'react-custom-events';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { getVariableValueFromConversation } from 'src/modules/Admin/modules/General/modules/Variables/views/utils/getVariableValueFromConversation';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';

/** SET CURRENT CONVERSATION
 *
 * @param conversation Conversation object to be closed.
 */
export const setCurrentConversation = (conversation: Conversation) => {
  if (!conversation) {
    logger.error(
      'Set Current Conversation with non existant conversation...: ',
      conversation
    );
    emitCustomEvent(CustomEventNames.OPEN_HELPER_PANEL, null);
    // return;
  }
  setCurrentIframeData(conversation);
  setCurrentConversationStore(conversation);
};

export const getSocialmediaAgentEndpointFrom = (conversation: Conversation) => {
  let endpoint = '';

  if (conversation.involvedContacts?.length > 0) {
    endpoint = conversation.involvedContacts?.[0].socialmediaAgent?.endpoint;
  }
  if (endpoint?.startsWith('34')) endpoint = endpoint.slice(2);
  return endpoint;
};

export const replaceConversationVariables = async (
  stringWithVariables: string,
  conversation: Conversation
) => {
  const variableRegex = /\$([a-zA-Z0-9_-]+)\$/g;
  let match: RegExpExecArray;
  const variablePromises = [];
  while ((match = variableRegex.exec(stringWithVariables)) !== null) {
    const variable = match[1];
    variablePromises.push(
      getVariableValueFromConversation(variable, conversation).then((value) => {
        return { value };
      })
    );
  }
  let resolvedVariables = [];
  try {
    resolvedVariables = await Promise.all(variablePromises);
  } catch (error) {
    logger.error(getErrorMessage(error)?.errorMessage);
  }
  let newString = stringWithVariables;
  resolvedVariables.forEach(({ value }) => {
    newString = newString.replace(
      new RegExp(`\\$${value.id}\\$`, 'g'),
      value.value
    );
  });
  return newString;
};

export const setCurrentIframeData = async (conversation: Conversation) => {
  if (!conversation) return;
  const isVideocall = conversation?.originAgent?.orientation === 'VideoCall';

  const externalAction = await getConversationExternalAction(conversation);
  // PROVISIONAL : When HOTLINE and outbound call, isVisible equals to false
  const isVisible = HOTLINE_COMPANIES.includes(conversation?.companyId)
    ? // HOTLINE
      conversation.originAgent.orientation === 'Outbound'
      ? false
      : true
    : // NOT HOTLINE
      !!externalAction && !externalAction?.openNewTab;

  const url = await replaceConversationVariables(
    externalAction?.url,
    conversation
  );

  let newFrame: ThirdPartyIframeData = {
    url: externalAction?.openNewTab ? null : (url ?? null),
    isVisible,
    type:
      conversation?.channel === 'Call' && !isVideocall ? 'CENTER' : 'HELPER',
    conversationId: conversation?.id,
    channel: conversation?.channel
  };
  if (externalAction && externalAction?.openNewTab) {
    window.open(url, '_blank', 'width=1000,height=700,left=500,top=200');
  }
  //  Get all Panels
  let iframePanels = getIframepanels(false);
  // Hide ALL panels...
  iframePanels = iframePanels.map((panel) => {
    return { ...panel, isVisible: false };
  });
  // Find it if exists
  const existingIndex: number = iframePanels.findIndex(
    (panel) => panel.conversationId === conversation?.id
  );
  // If exists, just edit it
  if (existingIndex !== -1) {
    newFrame = iframePanels[existingIndex];
    newFrame.isVisible = isVisible;
    iframePanels[existingIndex] = newFrame;
  } else {
    //Add new Frame if not exists
    iframePanels = [...iframePanels, newFrame];
  }
  updateIframepanels(iframePanels);
};
