import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { BASE_MS, BASE_URL } from './constants';
import { Ticket, TicketComment } from '../models/tickets';
import {
  TICKETS,
  TICKETS_ATTACHMENT,
  TICKETS_COMMENT,
  TICKETS_USER_ID
} from 'src/utils/apiRoutes/msMessaging';

const CONVERSATIONS_ID = process.env.REACT_APP_API_CONVERSATIONS_ID;
const TICKET_ID = process.env.REACT_APP_API_TICKET_ID;

export const createTicketApi = async (
  ticket: Ticket,
  firstRescheduleTimestamp?: string
): Promise<Ticket> => {
  const params = { firstRescheduleTimestamp };
  const resp = await axios.post(TICKETS, ticket, { params });
  return resp.data;
};

export const createTicketAttachmentApi = async (
  file: File,
  ticketId: string
) => {
  const path = replaceURLParams(TICKETS_ATTACHMENT, {
    '{ticketId}': ticketId
  });
  const formData = new FormData();
  formData.append('file', file);
  await axios.post(path, formData);
};

export const createTicketCommentApi = async (
  comment: string,
  ticketId: string
): Promise<TicketComment> => {
  const path = replaceURLParams(TICKETS_COMMENT, {
    '{ticketId}': ticketId
  });
  const body = { comment };
  const resp = await axios.post(path, body);
  return resp.data;
};

export const addTicketToConversationApi = async (
  ticketId: string,
  conversationId: string
): Promise<void> => {
  const path = replaceURLParams(
    BASE_URL + BASE_MS + CONVERSATIONS_ID + TICKET_ID,
    { '{id}': conversationId, '{ticketId}': ticketId }
  );
  await axios.post(path);
};

export const assignAgentToTicketApi = async (
  ticketId: string,
  userId: string
): Promise<void> => {
  const path = replaceURLParams(TICKETS_USER_ID, {
    '{ticketId}': ticketId,
    '{userId}': userId
  });
  await axios.post(path);
};
