import { Close } from '@mui/icons-material';
import { Popover, useTheme, Box, Stack } from '@mui/material';
import { IconButton } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { CTIBarContext, CTIPopoverNames } from 'src/contexts/CTIBarContext';

interface CustomPopoverProps {
  icon: any;
  title: CTIPopoverNames;
  name?: string;
  hideBackdrop?: boolean;
}

export const CtiPopover: React.FC<CustomPopoverProps> = ({
  icon,
  title: titleProps,
  children,
  name,
  hideBackdrop
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const theme = useTheme();

  const { barState, dialpadState } = useContext(CTIBarContext);

  const { setUsername, setSelectedTicket, selectedTicket } = dialpadState;

  const { setIsBarExtended, setIsPopoverOpened, isDragging } = barState;

  const isSelectedTicket = Boolean(selectedTicket);

  const title = `${titleProps}${isSelectedTicket ? ` - ${selectedTicket?.title}` : ''}`;

  useCustomEventListener('Open popover', (popoverName: CTIPopoverNames) => {
    if (titleProps === popoverName) {
      setIsOpen(true);
    }
  });

  useCustomEventListener('Close popover', (t) => {
    if (!!!t || t === titleProps) {
      //if t is null or t == title
      setIsOpen(false);
    }
  });

  useEffect(() => {
    setIsPopoverOpened(isOpen);
  }, [isOpen]);

  return (
    <>
      <Box
        ref={ref}
        onClick={() => {
          if (!isDragging) setIsOpen(true);
        }}
      >
        {icon}
      </Box>

      <Popover
        open={isOpen}
        onClose={() => {
          if (name !== 'modal') {
            setIsOpen(false);
            setIsBarExtended(false);
          }
        }}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{ paddingX: 0 }}
        hideBackdrop={hideBackdrop}
        disableEnforceFocus
      >
        <>
          <Stack
            px={2}
            direction="row"
            justifyContent={'space-between'}
            alignItems="center"
            sx={{ backgroundColor: '#F6F8FB' }}
          >
            <h2>{t(title)}</h2>
            <IconButton
              sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
              color={'secondary'}
              onClick={(): void => {
                setIsOpen(false);
                setUsername('');
                setSelectedTicket(null);
                if (name !== 'modal') {
                  setIsBarExtended(false);
                }
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Stack>
          {children}
        </>
      </Popover>
    </>
  );
};
