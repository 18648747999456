import {
  Conversation,
  ConversationTableColumns
} from 'src/models/conversations/conversations';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import {
  CallCampaign,
  Campaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { getDaServiceByIdQuery } from 'src/modules/Admin/modules/Operations/modules/DaServices/views/hooks/daServiceQueries';
import UsersApplication from 'src/services/users/application/UsersApplication';
import {
  getDateDDmmmYYYY,
  getTimeHHmm,
  subtractTimes
} from 'src/utils/formatDateTime';

export const conversationsToConversationTableColumns = async (
  conversations: Conversation[]
): Promise<ConversationTableColumns[]> => {
  let conversationsTable: ConversationTableColumns[] = []; // CONTAINER OUTPUT

  conversationsTable = await Promise.all(
    conversations.map(conversationToConversationTableColumn)
  );

  return conversationsTable;
};

export { getDateDDmmmYYYY, getTimeHHmm };

export const conversationToConversationTableColumn = async (
  conversation: Conversation
) => {
  let conversationTable = conversation as ConversationTableColumns;
  conversationTable = {
    ...conversationTable,
    creationDate: getDateDDmmmYYYY(conversation.metadata.creationDate),
    modificationDate: getDateDDmmmYYYY(conversation.metadata.modificationDate),
    creationHour: getTimeHHmm(conversation.metadata.creationDate),
    modificationHour: getTimeHHmm(conversation.metadata.modificationDate),
    overallDuration: subtractTimes(
      conversation.metadata.creationDate,
      conversation.metadata.modificationDate
    ),
    agentName: conversation.originAgent.userName
  };

  // GET CAMPAIGN NAME
  let campaign: Campaign = await UsersApplication.getCampaignByIdQuery(
    conversation.campaignId
  );
  conversationTable.campaignName = campaign.name;
  conversationTable.campaignType = (campaign as CallCampaign).callMode;

  // GET SERVICE NAME
  let service: DaService = await getDaServiceByIdQuery(campaign.serviceId);
  conversationTable.serviceName = service.name;

  // PUSH ARRAY CONVERSATION TRANSFORMED
  return conversationTable;
};
