import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { invalidateIntentionsModelsDataQuery } from '../views/hooks/getAIModelsQuery';
import { INTENTION_MODEL_ID } from 'src/utils/apiRoutes/msBasicNlu';

export const deleteIntentionModelApi = async (
  modelId: string
): Promise<string> => {
  const path = replaceURLParams(INTENTION_MODEL_ID, {
    '{id}': modelId
  });

  const response: AxiosResponse<string> = await axios.delete(path);

  invalidateIntentionsModelsDataQuery();
  return response.data;
};
