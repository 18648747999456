import { Stack, Typography } from '@mui/material';
import { useState, useContext } from 'react';
import 'draft-js/dist/Draft.css';
import { emitCustomEvent } from 'react-custom-events';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import EmailEditor from 'src/modules/Dashboard/views/Inbox/EmailPanel/components/EmailEditor';
import { EmailEditorMode } from 'src/modules/Dashboard/views/Inbox/EmailPanel/components/EmailEditor/models/models';
import { EmailItemState } from 'src/modules/Dashboard/views/Inbox/EmailPanel/components/EmailMessageItem';
import {
  CTIBarContext,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';

interface Props {
  /** ignored if useCTIBarContext equals to true */
  initialEmailTo?: string[];
  useCTIBarContext?: boolean;
}

export const EmailConversation = ({
  initialEmailTo: initialEmailToProps = [],
  useCTIBarContext = true
}: Props) => {
  const { username } = useDialpadStateContext();

  const [editorConfig, setEditorConfig] = useState({
    editorInfo: {
      mode: EmailEditorMode.New,
      messageToReply: null
    },
    isEditorOpen: true,
    isMaximized: true
  } as EmailItemState);

  const initialEmailToContext = username.trim().length > 0 ? [username] : [];

  const initialEmailTo = useCTIBarContext
    ? initialEmailToContext
    : initialEmailToProps;

  return (
    <Stack direction={'column'} maxWidth={'75vw'}>
      <EmailEditor
        initialEmailTo={initialEmailTo}
        config={editorConfig}
        setConfig={setEditorConfig}
        onClose={() => {
          emitCustomEvent('Close popover');
        }}
      />
    </Stack>
  );
};
