import { KeyboardEvent, useRef, useState } from 'react';

interface Params {
  onKeyDown?: (event: KeyboardEvent<HTMLElement>, index: number) => void;
}

export const useSelectedIndex = (params: Params = {}) => {
  const { onKeyDown } = params;
  const INITIAL_SELECTED_INDEX = -1;
  const itemRefs = useRef([]);
  const [selectedIndex, setSelectedIndex] = useState(INITIAL_SELECTED_INDEX);
  const resetIndex = () => {
    setSelectedIndex(INITIAL_SELECTED_INDEX);
  };

  const scrollItemIntoView = (index: number) => {
    const item = itemRefs.current[index];
    if (!!item) {
      item.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    const itemsLength = itemRefs.current.length;
    if (event.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % itemsLength;
        scrollItemIntoView(newIndex);
        return newIndex;
      });
      // event.preventDefault(); // Prevent page scrolling
    } else if (event.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + itemsLength) % itemsLength;
        scrollItemIntoView(newIndex);
        return newIndex;
      });
      // event.preventDefault(); // Prevent page scrolling
    } else {
      onKeyDown?.(event, selectedIndex);
    }
  };
  return { selectedIndex, resetIndex, handleKeyDown, itemRefs };
};
