import axios from 'axios';
import { TELEGRAM_MESSAGE_TYPING } from 'src/utils/apiRoutes/msTelegram';

export interface TypingTelegramMessageApiParams {
  accountId: string;
  conversationId: string;
  typing: boolean;
}

export const typingTelegramMessageApi = async (
  params: TypingTelegramMessageApiParams
) => {
  await axios.put(TELEGRAM_MESSAGE_TYPING, null, { params });
};
