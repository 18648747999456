import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import {
  postDaService,
  getDaServices,
  putDaService,
  deleteDaService,
  DaServicesRequestParams,
  getDaServiceById,
  GetServiceFilters,
  getServiceDraftsApi,
  createServiceDraftApi,
  updateServiceDraftApi,
  deleteServiceDraftApi
} from '../infrastructure/DaServices';
import {
  TicketField,
  TicketResolutionUI,
  TicketSubResolutionUI,
  TicketTag
} from '../../Tickets/models/tickets';
import { TicketsDomain } from '../../Tickets/domain/TicketsDomain';
import { logger } from 'src/utils/logger';
import { ContactGroup } from 'src/modules/Contacts/models/contacts';
import { getContactGroupsQuery } from 'src/modules/Contacts/domain/getContactGroups';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { deleteCampaignDrafts, getCampaignDrafts } from './campaigns';
import { getShowflowDrafts } from '../../Tickets/domain/showflow/getShowflowsQuery';
import { deleteShowflowDrafts } from '../../Tickets/domain/showflow/deleteShowflow';

/**
 * Returns all the services
 *
 * @returns all the services
 */
export const retrieveAllDaServices = async (
  params?: DaServicesRequestParams
) => {
  const queryParams = { ...params, size: params?.size ?? -1 };
  let contactGroups: ContactGroup[] = [];
  let daServices = await getDaServices(queryParams);
  if (!params?.withContactGroupNames) return daServices;

  // Add the contact group name to each service
  try {
    contactGroups = (await getContactGroupsQuery())?.elements;
  } catch (error) {
    logger.error(getErrorMessage(error)?.errorMessage);
  }

  daServices.elements = daServices.elements.map((daService) => {
    const contactGroup = contactGroups.find(
      (contactGroupData) => daService.contactGroupId === contactGroupData.id
    );
    return { ...daService, contactGroupName: contactGroup?.name };
  });
  return daServices;
};

/**
 *
 * @param daServiceId
 * @returns
 */
export const retrieveDaServiceById = async (
  daServiceId: string
): Promise<DaService> => {
  const daService = await getDaServiceById(daServiceId);
  return daService;
};

/**
 * Creates a service
 *
 * @returns the created service
 */
export const createDaService = async (
  daService: DaService
): Promise<DaService> => {
  const daServiceCreated = await postDaService(daService);
  const isDraft = checkIsServiceDraft(daService);
  if (isDraft) {
    deleteServiceDraft(daService?.draftId);
    const serviceId = daService?.draftId ?? daService?.id;
    const serviceCampaignDrafts = await getCampaignDrafts({ serviceId });
    const campaignDraftIds = serviceCampaignDrafts.elements.map(
      (campaign) => campaign?.draftId
    );
    await deleteCampaignDrafts(campaignDraftIds);
  }
  return daServiceCreated;
};

/**
 * Updates a service
 *
 * @returns the updated service
 */
export const updateDaService = async (
  daService: DaService
): Promise<DaService> => {
  const daServiceUpdated = await putDaService(daService);
  const isDraft = checkIsServiceDraft(daService);
  if (isDraft) {
    deleteServiceDraft(daService?.draftId);
    const serviceId = daService?.draftId ?? daService?.id;
    const serviceCampaignDrafts = await getCampaignDrafts({ serviceId });
    const campaignDraftIds = serviceCampaignDrafts.elements.map(
      (campaign) => campaign?.draftId
    );
    await deleteCampaignDrafts(campaignDraftIds);
  }
  return daServiceUpdated;
};

/**
 * Deletes a services
 *
 * @returns the deleted service
 */
export const removeDaService = async (
  daServiceId: string
): Promise<DaService> => {
  const daServiceRemoved = await deleteDaService(daServiceId);
  return daServiceRemoved;
};

/**
 * Enables a service
 *
 * @param daService service to enable
 * @returns the enabled service
 */
export const enableDaService = async (
  daService: DaService
): Promise<DaService> => {
  const enabledDaService = daService;
  enabledDaService.enabled = true;
  return updateDaService(enabledDaService);
};

/**
 * Disables a service
 *
 * @param daService disabled service
 * @returns the disabled service
 */
export const disableDaService = async (
  daService: DaService
): Promise<DaService> => {
  const disabledDaService = daService;
  disabledDaService.enabled = false;
  return updateDaService(disabledDaService);
};

/** Add the service id to ticket subResolutions serviceIds and update the ticket subSubResolutions */
export const addResolutionsToService = async (
  service: DaService,
  ticketResolutions: TicketResolutionUI[],
  /** Resolutions initially in the service */
  previousTicketResolutions: TicketResolutionUI[]
) => {
  if (!service?.id) {
    logger.error('No service id');
    return;
  }

  let updatedPreviousSubResolutions: TicketSubResolutionUI[] = [];

  if (previousTicketResolutions.length > 0) {
    // Get the ticket subResolutions from the previous ticket resolutions that are not included in new ticket resolutions
    const previousSubResolutions = previousTicketResolutions
      .filter((previousResolution) => {
        const resolutionIds = ticketResolutions.map(
          (resolution) => resolution.id
        );
        return !resolutionIds.includes(previousResolution.id);
      })
      .map((resolution) => resolution.subResolutions)
      .flat();

    // Remove the service id from the serviceIds array
    updatedPreviousSubResolutions = previousSubResolutions.map(
      (subResolution) => {
        const serviceIds = [...new Set([...subResolution.serviceIds])].filter(
          (id) => id !== service.id
        );
        return {
          ...subResolution,
          serviceIds: serviceIds
        } as TicketSubResolutionUI;
      }
    );
  }

  // Get the ticket subResolutions from the ticket resolutions
  const subResolutions = ticketResolutions
    .map((resolution) => {
      return resolution.subResolutions;
    })
    .flat();

  // Add the service id to the serviceIds
  const updatedSubResolutions = subResolutions.map((subResolution) => {
    const serviceIds = [...new Set([...subResolution.serviceIds, service.id])];
    return {
      ...subResolution,
      serviceIds: serviceIds
    } as TicketSubResolutionUI;
  });

  // Update the ticket subResolutions
  const resp = await TicketsDomain.updateTicketSubResolutions([
    ...updatedPreviousSubResolutions,
    ...updatedSubResolutions
  ]);
  return resp;
};

/** Add the service id to ticket fields serviceIds and update the ticket fields */
export const addTicketFieldsToService = async (
  service: DaService,
  ticketFields: TicketField[],
  previouTicketFields: TicketField[]
) => {
  if (!service?.id) {
    logger.error('No service id');
    return;
  }

  let updatedPreviousTicketFields: TicketField[] = [];

  if (previouTicketFields.length > 0) {
    // updatedPreviousTicketFields are the previous ticket fields, first, discarding those that have NOT been removed from the service
    // and then removing the service id from the serviceIds array
    updatedPreviousTicketFields = previouTicketFields
      .filter(
        (previousField) =>
          !ticketFields.some((newField) => newField.id === previousField.id)
      )
      .map((ticketField) => {
        const serviceIds = [...new Set([...ticketField.serviceIds])].filter(
          (id) => id !== service.id
        );
        return {
          ...ticketField,
          serviceIds: serviceIds
        } as TicketField;
      });
  }

  // updatedNewTicketFields are all new ticketFields but adding the service id to the serviceIds array
  const updatedNewTicketFields = ticketFields.map((ticketField) => {
    const serviceIds = [...new Set([...ticketField.serviceIds, service.id])];
    return {
      ...ticketField,
      serviceIds: serviceIds
    } as TicketField;
  });

  // Update the ticket fields
  const resp = await TicketsDomain.updateTicketFields([
    ...updatedPreviousTicketFields,
    ...updatedNewTicketFields
  ]);
  return resp;
};

export const addTicketTagsToService = async (
  service: DaService,
  ticketTags: TicketTag[],
  previousTicketTags: TicketTag[]
) => {
  if (!service?.id) {
    logger.error('No service id');
    return;
  }

  let updatedPreviousTicketTags: TicketTag[] = [];

  if (previousTicketTags.length > 0) {
    // updatedPreviousTicketTags are the previous ticket tags, first, discarding those that have NOT been removed from the service
    // and then removing the service id from the serviceIds array
    updatedPreviousTicketTags = previousTicketTags
      .filter(
        (previousTag) =>
          !ticketTags.some((newTag) => newTag.id === previousTag.id)
      )
      .map((ticketTag) => {
        const serviceIds = [...new Set([...ticketTag.serviceIds])].filter(
          (id) => id !== service.id
        );
        return {
          ...ticketTag,
          serviceIds: serviceIds
        } as TicketTag;
      });
  }
  const updatedNewTicketTags = ticketTags.map((ticketTag) => {
    const serviceIds = [...new Set([...ticketTag.serviceIds, service.id])];
    return {
      ...ticketTag,
      serviceIds
    } as TicketTag;
  });
  updatedNewTicketTags.map((ticketTag) => {
    TicketsDomain.updateTicketTag(ticketTag);
  });
  updatedPreviousTicketTags.map((ticketTag) => {
    TicketsDomain.updateTicketTag(ticketTag);
  });
};

export const getServiceDrafts = (params: GetServiceFilters) => {
  return getServiceDraftsApi(params);
};

export const getServiceDraftById = async (id: string) => {
  const draft = (await getServiceDrafts({ ids: [id] }))?.elements?.[0] ?? null;
  return draft;
};

export const createServiceDraft = (service: DaService) => {
  return createServiceDraftApi(service);
};

export const updateServiceDraft = (updatedService: DaService) => {
  return updateServiceDraftApi(updatedService);
};

export const deleteServiceDraft = async (draftId: string) => {
  // Get all the campaign and showflow drafts from the service to delete
  const [serviceCampaignDrafts, serviceShowflowDrafts] = await Promise.all([
    getCampaignDrafts({ serviceId: draftId }),
    getShowflowDrafts({
      serviceIds: [draftId]
    })
  ]);
  const campaignDraftIds = serviceCampaignDrafts.elements.map(
    (campaign) => campaign?.draftId
  );
  const showflowDraftIds = serviceShowflowDrafts.map(
    (showflow) => showflow?.draftId
  );
  // Delete the campaigns and the showflows
  await Promise.all([
    [
      deleteCampaignDrafts(campaignDraftIds),
      deleteShowflowDrafts(showflowDraftIds)
    ]
  ]);
  // Delete the service
  await deleteServiceDraftApi(draftId);
};

export const deleteServiceDrafts = async (draftIds: string[]) => {
  const deletePromises = draftIds?.map((id) => {
    deleteServiceDraft(id);
  });
  await Promise.all(deletePromises);
};

export const checkIsServiceDraft = (service: DaService) => {
  return !!service?.draftId;
};
