import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Contact } from 'src/modules/Contacts/models/contacts';

import { Conversation } from 'src/models/conversations/conversations';
import NoteListView from './NoteListView';
import UserAvatarName from './UserAvatarName';
import { Box, Button, styled, useTheme } from '@mui/material';

interface Props {
  setContact: Dispatch<SetStateAction<Contact>>;
  contact: Contact;
  conversation: Conversation;
  filterNote: 'contactsNotes' | 'conversationsNotes';
}

const NoteContactList = (props: Props) => {
  const { conversation, setContact, contact, filterNote } = props;

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          minWidth: 300,
          width: '100%',
          height: '100%'
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="start">
          <Button
            color="primary"
            variant="text"
            size="small"
            startIcon={<ArrowBackIosNewIcon />}
            sx={{ marginTop: theme.spacing(0.75), marginLeft: 3 }}
            onClick={() => {
              setContact(null);
            }}
            id="btnBack-UpdateContactDataView"
          >
            {t('Back')}
          </Button>
        </Box>

        <UserAvatarName contact={contact} conversation={conversation} />

        <Box height={'calc(100% - 48px - 70px)'}>
          <NoteListView
            conversation={conversation}
            filterNote={filterNote}
            contact={contact}
          />
        </Box>
      </Box>
    </>
  );
};

export default NoteContactList;
