import { ColumnSort, SortingState } from '@tanstack/react-table';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { Sort } from 'src/components/TableReporting/models/filtersRequest';

export const getSort = <T>(sorting: SortingState) => {
  const { desc, id } = sorting?.[0] ?? {};
  if (!id) return null;
  const direction: DirectionType = desc
    ? DirectionType.DESC
    : DirectionType.ASC;
  const sortKey = id as keyof T;
  const sort: Sort<T> = {
    direction,
    sort: sortKey
  };
  return sort;
};
