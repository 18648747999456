import axios, { AxiosResponse } from 'axios';
import { Note } from 'src/models/conversations/conversations';
import { replaceURLParams } from 'src/services/utils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
const BASE_MS = process.env.REACT_APP_BASE_SOCIALMEDIA_MS as string;
const PATH = process.env.REACT_APP_API_NOTES_ID_UPDATE as string;

export const updateNoteApi = async (noteId: string, note: Note) => {
  const path = replaceURLParams(BASE_URL + BASE_MS + PATH, {
    '{id}': noteId
  });

  return axios
    .put(path, note)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      return error.response;
    });
};
