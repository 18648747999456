import { useContext, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  styled,
  TextField
} from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useAlert } from 'src/hooks/useAlert';
import { ContactTag } from 'src/modules/Contacts/models/contacts';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { createTag } from 'src/modules/Contacts/domain/createTag';
import { logger } from 'src/utils/logger';
import { ContactGroupsSelector } from '../contactGroups/selector';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkUpdateContactGroupPermissions,
  checkUpdateContactTagsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import AuthManager from 'src/services/authentication/manager';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import Scrollbar from 'src/components/Scrollbar';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

interface Props {
  onClose: () => void;
  tag?: ContactTag;
  onData?: (props: ContactTag) => void;
  defaultContactGroupId?: string;
  disableContactGroupSelector?: boolean;
}

export const CreateTagPopoverForm: React.FC<Props> = ({
  onClose,
  tag,
  onData,
  defaultContactGroupId,
  disableContactGroupSelector = false
}) => {
  const { hasAccess } = useContext(PermissionsContext);
  const hasTagdAccess =
    hasAccess(checkUpdateContactGroupPermissions) &&
    hasAccess(checkUpdateContactTagsPermissions);
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const { showAlert } = useAlert();
  const companyId = tag ? tag.companyId : AuthManager.getLoggedUserCompanyId();
  const [contactGroupId, setContactGroupId] = useState(
    defaultContactGroupId ?? ''
  );

  const [loading, setLoading] = useState<boolean>(false);

  const title = tag ? t('Update tag') : t('Create new tag');
  const subheader = tag
    ? t('Use this dialog window to update a tag')
    : t('Use this dialog window to add a new tag');

  function handleCreate() {
    setLoading(true);
    if (!contactGroupId || !name) {
      showAlert(t(`Missing required fields`), 'warning', 3000);
      return;
    }
    setLoading(true);
    createTag(companyId, name, contactGroupId)
      .then((res) => {
        onData?.(res);
        showAlert(t('Field created'), 'success', 3000);
        emitCustomEvent(REFETCH_DATA_EVENT_NAME);
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        logger.error(error);
        setLoading(false);
        showAlert(
          t(`Could not create tag. ${getErrorMessage(error).errorMessage}`),
          'error',
          3000
        );
      });
  }

  return (
    <Card
      sx={{
        height: '35vh',
        minHeight: '300px',
        minWidht: '300px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <IconButtonWrapper
            sx={{
              m: 1
            }}
            size="small"
            color="secondary"
            onClick={onClose}
          >
            <CloseTwoToneIcon />
          </IconButtonWrapper>
        }
      />
      <Divider />
      <CardContent
        sx={{
          p: 0,
          '&.MuiCardContent-root': {
            paddingBottom: 0
          },
          overflow: 'hidden',
          height: '100%'
        }}
      >
        <Stack height={'100%'}>
          <Scrollbar>
            <Stack direction={'column'} spacing={2} py={2} px={2}>
              <ContactGroupsSelector
                {...{
                  contactGroupId,
                  setContactGroupId
                }}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                disabled={disableContactGroupSelector}
              />

              <TextField
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                id="createFields-name"
                label={t('Name')}
              />
            </Stack>
          </Scrollbar>

          <Divider />
          <Stack
            px={1.5}
            py={1.5}
            width="100%"
            direction={{ xs: 'column-reverse', sm: 'row' }}
            justifyContent={'space-between'}
            spacing={1.5}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color="secondary"
              id="createTag-cancel-btn"
            >
              {t('Cancel')}
            </Button>
            <PermissionsTooltip hasAccess={hasTagdAccess}>
              <Button
                id="createTag-create-btn"
                disabled={loading || !contactGroupId || !hasTagdAccess}
                onClick={() => {
                  handleCreate();
                }}
                variant="contained"
                color="secondary"
                startIcon={loading && <CircularProgress size={16} />}
              >
                {t('Create')}
              </Button>
            </PermissionsTooltip>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
