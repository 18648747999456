import { Grid, Stack } from '@mui/material';
import LogoBig from 'src/components/LogoBig';
import PrepareDevices from './components/PrepareDevices';
import { VideoCallForm } from './components/form/VideoCallForm';
import { useContext } from 'react';
import { VideocallRoomContext } from '../../context/VideocallRoomContext';
import { connectWebsocket } from '../../domain/room-join';
import { useVideocallCampaignData } from '../../hooks/useVideocallCampaign';

function PreVideoCallRoom() {
  const videoCallsContext = useContext(VideocallRoomContext);
  const { videoRoom, isLoggedIn, isMobile, isTablet } = videoCallsContext;

  const videoCallCampaignData = useVideocallCampaignData(
    videoCallsContext.videoRoom?.room.campaignId,
    videoCallsContext.isLoggedIn
  );

  const direction = isLoggedIn || isTablet ? 'column' : 'row';

  return (
    <Stack
      style={{
        position: isMobile ? 'fixed' : 'inherit',
        height: !isMobile ? '100vh' : 'inherit',
        //disable scroll
        overflow: 'hidden'
      }}
    >
      {!isLoggedIn && (
        <Grid padding={'16px 16px 8px 16px'}>
          <LogoBig />
        </Grid>
      )}
      <Stack
        direction={direction}
        justifyContent={'space-around'}
        alignItems={'center'}
        width="100%"
        height={'100%'}
        paddingBottom={5}
      >
        {<PrepareDevices isMobile={isMobile} direction={direction} />}
        <Stack width="40%" alignItems={'center'}>
          {!isMobile && (
            <VideoCallForm
              campaignData={videoCallCampaignData}
              direction={direction}
              onSubmit={(data) => {
                connectWebsocket(videoCallsContext, data);
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PreVideoCallRoom;
