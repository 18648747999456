import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { InboxMainPanel } from './inboxMainPanel';
import {
  isCallConversation,
  isSocialmediaConversation,
  isVideoCallConversation
} from 'src/modules/VideoCall/utils/isVideoCallConversation';
import {
  HelperMenuItemVisibility,
  HelperTab,
  HelperTabsValue,
  helperMenuItems
} from '../HelperMenu/items';
import { HOTLINE_COMPANIES } from 'src/services/socialmedia/domain/conversation/utils';
import { getTicketById } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/getTickets';
import {
  Ticket,
  TicketType
} from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import { isOpenTicketType } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/checkIsOpenTicket';
import { getConversationReportinTicketsQuery } from 'src/modules/Admin/modules/Operations/modules/Tickets/views/utils/getConversationReportingTicketsQuery';

/**
 *
 * @param conversation
 * @param isExternalActionConfigured id of the configured external action in the service of the conversation passed
 * @returns
 */
export const getInboxMainPanel = async (
  conversation: Conversation,
  isExternalActionConfigured: boolean
) => {
  if (!conversation) {
    return isExternalActionConfigured
      ? InboxMainPanel.EXTERNAL_ACTION
      : InboxMainPanel.TICKETS;
  }
  // EMAIL
  if (conversation.channel === ChannelType.EMAIL) return InboxMainPanel.EMAIL;
  // CHAT
  if (
    [
      ChannelType.TELEGRAM,
      ChannelType.WEBCHAT,
      ChannelType.WHATSAPP,
      ChannelType.INSTAGRAM
    ].includes(conversation.channel)
  )
    return InboxMainPanel.CHAT;
  // VIDEOCALL
  if (isVideoCallConversation(conversation)) {
    return InboxMainPanel.VIDEOCALL;
  }
  // CALL
  if (isCallConversation(conversation))
    if (HOTLINE_COMPANIES.includes(conversation?.companyId)) {
      // PROVISIONAL : SGI external action (HOTLINE) is not displayed in the main panel when outbound call.
      // When HOTLINE and outbound call, isExternalActionConfigured equals to false
      return isExternalActionConfigured
        ? InboxMainPanel.EXTERNAL_ACTION
        : InboxMainPanel.CONTACT_DATA;
    }
  return isExternalActionConfigured
    ? InboxMainPanel.EXTERNAL_ACTION
    : InboxMainPanel.TICKETS;
};

/**
 *
 * @param conversation
 * @param isExternalActionConfigured true if external action configured. false, otherwise.
 * @returns
 */
export const getInboxHelperPanel = (
  conversation: Conversation,
  isExternalActionConfigured: boolean
) => {
  if (isVideoCallConversation(conversation))
    return isExternalActionConfigured
      ? HelperTab.THIRD_PARTY
      : HelperTab.VIDEO_PARTICIPANTS;
  if (isSocialmediaConversation(conversation))
    return isExternalActionConfigured
      ? HelperTab.THIRD_PARTY
      : HelperTab.TICKETS;
  if (isCallConversation(conversation)) {
    // PROVISIONAL : SGI external action (HOTLINE) is not displayed in the main panel when outbound call
    // CONTACT DATA panel is displayed in the main panel and the CONVERSATION STATUS panel is displayed in the helper panel
    if (HOTLINE_COMPANIES.includes(conversation?.companyId)) {
      return HelperTab.CONVERSATION_STATUS;
    }
    return HelperTab.CONTACT_DATA;
  }
};

/**
 * Returns null to display the list of tickets in the convrsation passed and returns the ticket to show the form to edit the ticket
 * @param conversation
 * @returns
 */
export const getInboxTicketPanel = async (
  conversation: Conversation
): Promise<Ticket | null> => {
  const conversationReportingTickets =
    await getConversationReportinTicketsQuery(conversation);
  const openTickets = conversationReportingTickets?.elements.filter(
    (reportingTicket) =>
      isOpenTicketType(reportingTicket['subResolution.type'] as TicketType)
  );
  const isOnlyOneOpenTicket = openTickets?.length === 1;

  if (isOnlyOneOpenTicket) {
    const ticketId = openTickets?.[0]?.id;
    const ticket = await getTicketById(ticketId);
    return ticket;
  }
  return null;
};

/**
 * If the conversation param is not passed, returns all helper menu items.
 * If the value of the isExternalActionConfigured param is false and the conversation is a call, the tickets item is not returned. The default is true.
 * An item will be hidden if the key is added to the itemVisibility object and the value is false. It will be displayed otherwise.
 * @param
 * @returns helperMenuItems
 */
export const getHelperMenuItems = (params?: {
  conversation?: Conversation;
  isExternalActionConfigured?: boolean;
  itemVisibility?: HelperMenuItemVisibility;
}) => {
  const {
    conversation = null,
    isExternalActionConfigured = true,
    itemVisibility
  } = params ?? {};

  const filteredItemEntries = Object.entries(helperMenuItems).filter(
    ([key, _value]: [HelperTab, HelperTabsValue]) => {
      if (!conversation) return true;
      if (!!itemVisibility && Object.keys(itemVisibility).includes(key))
        return itemVisibility?.[key];
      // PROVISIONAL : SGI external action (HOTLINE) is not displayed when outbound call
      // CONTACT DATA panel is displayed in the main panel and the CONTACT DATA item is hidden
      if (
        key === HelperTab.CONTACT_DATA &&
        HOTLINE_COMPANIES.includes(conversation?.companyId) &&
        conversation.originAgent.orientation === 'Outbound'
      ) {
        return false;
      }
      if (
        key === HelperTab.TICKETS &&
        isCallConversation(conversation) &&
        !isExternalActionConfigured
      ) {
        return false;
      }
      if (
        key === HelperTab.THIRD_PARTY &&
        (isCallConversation(conversation) || !isExternalActionConfigured)
      ) {
        return false;
      }
      if (
        key === HelperTab.CALENDAR_SCHEDULE &&
        !isCallConversation(conversation)
      )
        return false;
      if (
        key === HelperTab.VIDEO_PARTICIPANTS &&
        !isVideoCallConversation(conversation)
      )
        return false;
      return true;
    }
  ) as [HelperTab, HelperTabsValue][];
  const filteredItems = Object.fromEntries(filteredItemEntries) as Record<
    HelperTab,
    HelperTabsValue
  >;
  return filteredItems;
};
