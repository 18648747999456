import { RTQueue } from 'src/models/queue';
import { setRTQueueStore } from '../../infraestructure/store/setRTQueueStore';
import { addRTQueue } from 'src/store/slices/rtusers/rtuserSlices';

/** UPDATE RTQUEUE
 *
 */
export const addRTQueueDomain = (rtQueue: RTQueue) => {
  if (!rtQueue.calls) rtQueue.calls = [];

  addRTQueue(rtQueue);
};
