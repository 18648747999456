import { LocalParking } from '@mui/icons-material';
import { Grid, IconButton, Stack, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import CallManagerDomain from 'src/modules/CTI/CallManager';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { setActiveRTCallId } from 'src/services/rtusers/domain/rtuser/setActiveRTCall';

export const ParkButton: React.FC = () => {
  const { barState } = useContext(CTIBarContext);
  const { isBarCompressed, isDragging } = barState;
  const activeCall = getActiveRTCall(getConnectedRTUser());
  const parkedCalls = getConnectedRTUser(true)?.calls?.filter(
    (c) => c.status === 'Parked'
  );
  const isParked = activeCall && activeCall.status === 'Parked';

  const togglePark = () => {
    if (!isParked) {
      CallManagerDomain.parkCall(activeCall).then((response) => {
        if (response.status === 200) {
          setActiveRTCallId(activeCall.callId);
        }
      });
    } else {
      CallManagerDomain.unparkCall(activeCall).then((response) => {
        if (response.status === 200) {
          setActiveRTCallId(null);
        }
      });
    }
  };

  return (
    <Grid
      item
      xs={6} // There are 12 columns and this component should occupy 50%
      sx={{
        backgroundColor: isParked ? '#33C2FF' : '#EBF9FF',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: isParked ? '#0fa7ff' : '#D5E4ED'
        }
      }}
      onClick={() => {
        if (!isDragging && activeCall) togglePark();
      }}
    >
      {isBarCompressed && (
        <Stack
          sx={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '13px'
          }}
          height={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {parkedCalls?.length > 0 && parkedCalls?.length}
        </Stack>
      )}
      {!isBarCompressed && (
        <>
          {' '}
          <Stack
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 9,
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            {parkedCalls?.length > 0 && parkedCalls?.length}
          </Stack>
          <Stack alignItems={'center'} justifyContent={'center'}>
            <IconButton
              id="park-button"
              sx={{
                borderRadius: 0,
                color: isParked ? 'white' : '#33C2FF'
              }}
            >
              <LocalParking fontSize="medium" />
            </IconButton>
          </Stack>
        </>
      )}
    </Grid>
  );
};
