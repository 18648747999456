import { RTCall } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';
import { getActiveRTCallIdStore } from '../../infraestructure/store/getActiveRTCallStore';

export const getActiveRTCall = (user: RTUser) => {
  if (user) {
    const calls = user?.calls;
    const activeCallId = getActiveRTCallIdStore(false);

    return (
      calls.find((call) => {
        return call.callId === activeCallId; // If we want to select an specific parked Call
      }) ||
      calls.find((call: RTCall) => {
        return call.status === 'Talking';
      }) ||
      calls.find((call: RTCall) => {
        return call.status === 'Conference';
      }) ||
      calls.find((call: RTCall) => {
        return call.status === 'Parked';
      }) ||
      null
    );
  } else return null;
};
