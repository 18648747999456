export type CallModeTypes =
  | 'DialerBot'
  | 'Inbound'
  | 'Internal'
  | 'Outbound'
  | 'Predictive'
  | 'Progressive'
  | 'Powerdialer';
export type ChannelTypes =
  | 'Call'
  | 'Email'
  | 'Telegram'
  | 'WhatsApp'
  | 'Instagram';

export type UserKeys = keyof User;

export interface User {
  companyId: string;
  description: string;
  email: string;
  enabled: boolean;
  fixedExtension: string;
  id: string;
  password: string;
  username: string;
  roleIds?: string[];
  superadmin?: boolean;
  setDoubleFactorOnNextLogin: boolean;
  doubleFactorEnabled?: boolean;
}

export interface UserFormikValues extends User {
  retypePassword: string;
  assignExtension: boolean;
}

export enum UserStatusTypes {
  IN_CALL = 'InCall',
  ADMIN_TIME = 'AdministrativeTime',
  AVAILABLE = 'Available',
  BUSY = 'Busy',
  DISCONNECTED = 'Disconnected',
  BLOCKED = 'Blocked'
}

export const userStatusTypeOptions = Object.values(UserStatusTypes);

export enum UserStatusReason {
  USER_REQUEST = 'UserRequest',
  CALL_EVENT = 'CallEvent',
  TIMEOUT = 'Timeout',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  RESET = 'Reset'
}

export const useStatusReasonOptions = Object.values(UserStatusReason);

export interface UserStatus {
  name: string;
  type: UserStatusTypes;
  color: string;
  canBeChangedManually?: boolean;
}

export const companyStatus = [
  'InCall',
  'AdministrativeTime',
  'Disconnected',
  'Available',
  'Busy',
  'Blocked'
];

export const defaultCompanyStatus = [
  'InCall',
  'AdministrativeTime',
  'Disconnected',
  'Available',
  'Blocked'
];

export const defaultUserStatus = Object.freeze([
  {
    name: 'Disconnected',
    type: UserStatusTypes.DISCONNECTED,
    color: '#7A7A7B'
  },
  { name: 'InCall', type: UserStatusTypes.IN_CALL, color: '#57CA22' },
  { name: 'Available', type: UserStatusTypes.AVAILABLE, color: '#1870F5' },
  {
    name: 'Administrative Time',
    type: UserStatusTypes.ADMIN_TIME,
    color: '#775DD0'
  }
]) as readonly UserStatus[];

export const defaultCompany = {
  autoExtensionAssignment: true,
  defaultLoginStatus: 'Available',
  enabled: true,
  extensions: 0,
  name: '',
  userStatuses: defaultUserStatus,
  shortcode: '',
  defaultUser: {
    username: '',
    password: '',
    retypePassword: '',
    superadmin: false
  }
} as Company;

/** This object is useful to check if a userStatus has the same name as one of the defaults and has the same type */
export const defaultUserStatusObject = Object.freeze({
  [UserStatusTypes.DISCONNECTED]: {
    name: 'Disconnected',
    type: UserStatusTypes.DISCONNECTED
  },
  [UserStatusTypes.IN_CALL]: {
    name: 'InCall',
    type: UserStatusTypes.IN_CALL
  },
  [UserStatusTypes.AVAILABLE]: {
    name: 'Available',
    type: UserStatusTypes.DISCONNECTED
  }
}) as {
  readonly [key in UserStatusTypes]?: UserStatus;
};

export interface Company {
  autoExtensionAssignment: boolean;
  defaultLoginStatus?: string;
  deleteTime?: string;
  deletionRequest?: boolean;
  defaultUser: DefaultUser;
  forceDoubleFactor: boolean;
  enabled: boolean;
  extensions: number;
  id?: string;
  name: string;
  userStatuses?: UserStatus[];
  shortcode?: string;
}

export interface DefaultUser {
  password?: string;
  retypePassword?: string;
  superadmin?: boolean;
  username?: string;
}

export interface CompanyDto {
  autoExtensionAssignment: boolean;
  enabled: boolean;
  extensions: number;
  name: string;
  shortcode: string;
  userStatuses: UserStatus[];
  defaultLoginStatus: string;
  defaultUser: DefaultUser;
}
export type UserRole = 'admin' | 'customer' | 'subscriber';
export type FormTypes = 'All Users' | 'Users Group';
export type ActionType = 'create' | 'update';
export type TabType =
  | 'Add user'
  | 'Add users CSV'
  | 'Create user group'
  | 'Add users group CSV'
  | '';

export const HOTLINE_COMPANY_ID = '645e2b9ce339381ed1b65400';
