import axios from 'axios';
import { TELEGRAM_MESSAGE_EXISTING_TEXT } from 'src/utils/apiRoutes/msTelegram';

export interface DeleteTelegramMessageTextApiParams {
  accountId: string;
  conversationId: string;
  messageId: string;
}

/**
 * Delete text from chatId from your account
 * @param params
 */
export const deleteTelegramMessageTextApi = async (
  params: DeleteTelegramMessageTextApiParams
) => {
  await axios.delete(TELEGRAM_MESSAGE_EXISTING_TEXT, { params });
};
