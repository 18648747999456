import { useQuery } from '@tanstack/react-query';
import {
  MohRequestParams,
  getMusicOnHold
} from '../infrastructure/musicOnHold';

export const useMusicOnHoldQuery = (params: MohRequestParams) => {
  return useQuery(['musicOnHold'], () => getMusicOnHold(params), {
    enabled: true,
    staleTime: 60000
  });
};
