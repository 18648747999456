import store, { useSelector } from 'src/store/store';

export const getNotificationsOffStore = (shouldUseHook: boolean) => {
  const notificationsOff = (
    shouldUseHook
      ? useSelector((state) => state.callData.notificationsOff)
      : store.getState().callData.notificationsOff
  ) as boolean;
  return notificationsOff;
};
