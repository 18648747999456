import { RenewWSMessage } from 'src/models/auth';
import wsManager from 'src/services/websocket/manager';
import {
  AuthEventSubtypes,
  EventTypes
} from 'src/services/websocket/utils/notifications/dataMsgs';
import { logger } from 'src/utils/logger';

/** REFRESH REQUEST
 *
 * sends a webscoket message to request a new token
 *
 * @param token
 * @param websocketId
 */

export const refreshRequest = (token: string, websocketId: string) => {
  const refreshMessage: RenewWSMessage = {
    type: EventTypes.AUTH,
    subtype: AuthEventSubtypes.RENEW,
    token: token,
    websocketId: websocketId,
    timestamp: new Date().toJSON()
  };
  try {
    wsManager.getWSClient().send(JSON.stringify(refreshMessage));
  } catch (error) {
    logger.error(error);
  }
};
