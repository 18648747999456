import store, { RootState, useSelector } from 'src/store/store';

export const getConversationsLoadingStatusStore = (shouldUseHook: boolean) => {
  const storeState: RootState = store.getState();
  return shouldUseHook
    ? useSelector((state) => state.socialmedia.loadingConversations)
    : storeState.socialmedia.loadingConversations;
};

/** GET CONVERSATIONS FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of conversations
 */
export const getConversationsStore = (shouldUseHook: boolean) => {
  const storeState: RootState = store.getState();
  const allConversationsObject = shouldUseHook
    ? useSelector((state) => state.socialmedia.conversations)
    : storeState.socialmedia.conversations;

  return Object.values(allConversationsObject);
};
