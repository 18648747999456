import axios from 'axios';
import { TextContent } from 'src/models/conversations/ChatMessages/TextMessages';
import { INSTAGRAM_SEND_NEW_TEXT_MESSAGE } from 'src/utils/apiRoutes/msInstagram';

export interface SendInstagramNewMessageTextApiParams {
  campaignId: string;
  content: TextContent;
  username: string;
  ticketId?: string;
}

export const sendInstagramNewMessageTextApi = async (
  params: SendInstagramNewMessageTextApiParams
) => {
  const { content, ...restParams } = params;
  await axios.post(INSTAGRAM_SEND_NEW_TEXT_MESSAGE, content, {
    params: restParams
  });
};
