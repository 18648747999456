import {
  Code,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatPaint,
  FormatStrikethrough,
  FormatUnderlined,
  Highlight,
  Subscript,
  Superscript
} from '@mui/icons-material';
import { ReactNode } from 'react';

export const textActions = [
  {
    label: 'Bold',
    formatType: 'bold',
    icon: FormatBold,
    checker: 'isBold'
  },
  {
    label: 'Italic',
    formatType: 'italic',
    icon: FormatItalic,
    checker: 'isItalic'
  },
  {
    label: 'Underline',
    formatType: 'underline',
    icon: FormatUnderlined,
    checker: 'isUnderline'
  },
  {
    label: 'Strikethrough',
    formatType: 'strikethrough',
    icon: FormatStrikethrough,
    checker: 'isStrikethrough'
  },
  {
    label: 'Code',
    formatType: 'code',
    icon: Code,
    checker: 'isCode'
  },
  {
    label: 'Highlight',
    formatType: 'highlight',
    icon: FormatPaint,
    checker: 'isHighlight'
  },
  {
    label: 'Subscript',
    formatType: 'subscript',
    icon: Subscript,
    checker: 'isSubscript'
  },
  {
    label: 'Superscript',
    formatType: 'superscript',
    icon: Superscript,
    checker: 'isSuperscript'
  }
];

export const editorFontFamilies = [
  'Arial',
  'Calibri',
  'Georgia',
  'Impact',
  'Tahoma',
  'Times New Roman',
  'Verdana'
];

export const alignOptions = [
  {
    label: 'Left',
    formatType: 'left',
    icon: FormatAlignLeft
  },
  {
    label: 'Center',
    formatType: 'center',
    icon: FormatAlignCenter
  },
  {
    label: 'Right',
    formatType: 'right',
    icon: FormatAlignRight
  },
  {
    label: 'Justify',
    formatType: 'justify',
    icon: FormatAlignJustify
  }
];
