import { queryClient } from 'src/utils/queryClient';
import {
  ShowflowsFilters,
  getShowflowDraftsApi,
  getShowflowsApi
} from '../../infrastructure/showflow/getShowflowsApi';
import { useQuery } from '@tanstack/react-query';
import { Showflow } from '../../models/showflow';

const queryDependencies = (filters?: ShowflowsFilters) => {
  return ['showflows', filters?.companyId, ...filters?.serviceIds];
};

export function getShowflowsQuery(filters?: ShowflowsFilters) {
  return queryClient.fetchQuery(queryDependencies(filters), {
    queryFn: () => getShowflowsApi(filters),
    staleTime: 60 * 5 * 1000
  });
}

export function useShowflowsQuery(filters?: ShowflowsFilters) {
  return useQuery(
    queryDependencies(filters),
    () => {
      if (!filters.serviceIds || filters.serviceIds.length === 0) {
        return [] as Showflow[];
      }
      return getShowflowsApi(filters);
    },
    {
      staleTime: 60 * 5 * 1000
    }
  );
}

export function invalidateShowflowsQuery() {
  return queryClient.invalidateQueries({
    queryKey: ['showflows']
  });
}

export async function getShowflowDrafts(filters: ShowflowsFilters = {}) {
  return getShowflowDraftsApi(filters);
}

export const checkIsShowflowDraft = (showflow: Showflow) => {
  return !!showflow?.draftId;
};
