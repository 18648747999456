import { getCampaignById } from 'src/services/users/domain/getCampaigns';
import { getAllCampaigns } from '../domain/campaigns';
import { disableCampaign, enableCampaign } from './campaigns';

import {
  createDaService,
  removeDaService,
  retrieveAllDaServices,
  updateDaService,
  enableDaService,
  disableDaService,
  retrieveDaServiceById,
  addResolutionsToService,
  addTicketFieldsToService,
  addTicketTagsToService
} from './services';

const DaServicesDomain = {
  addResolutionsToService,
  addTicketFieldsToService,
  addTicketTagsToService,
  getAllCampaigns,
  getCampaignById,
  enableCampaign,
  disableCampaign,
  createDaService,
  removeDaService,
  retrieveAllDaServices,
  retrieveDaServiceById,
  updateDaService,
  enableDaService,
  disableDaService
};

export default DaServicesDomain;
