import {
  ALL_CHANNEL_TYPES,
  ChannelType
} from 'src/models/conversations/conversations';
import { Features } from 'src/modules/Admin/modules/General/modules/Companies/models/CompanyFeatures';
import { getChannelFeatureChecker } from './checkFeatures';

export default function getEnabledChannelsByFeatures(
  features: Features
): ChannelType[] {
  const enabledChannels = ALL_CHANNEL_TYPES.filter((channel) => {
    const channelFeatureChecker = getChannelFeatureChecker(channel);
    return channelFeatureChecker(features);
  });
  return enabledChannels;
}
