import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {
  checkReadContactsAndContactGroupsPermissions,
  checkReadServicePermissions,
  checkReadTicketViewPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Services = Loader(lazy(() => import('../modules/DaServices/views')));
const Tickets = Loader(lazy(() => import('../modules/Tickets/views')));
const CompaniesReborn = Loader(
  lazy(() => import('../modules/CompaniesReborn'))
);
const Contacts = Loader(lazy(() => import('../../../../Contacts/views')));

const operationsRoutes: RouteObject[] = [
  {
    path: 'services',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadServicePermissions}>
        <Services />
      </Restricted>
    )
  },
  {
    path: 'tickets',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadTicketViewPermissions}>
        <Tickets />
      </Restricted>
    )
  },
  {
    path: 'create-showflow',
    element: <CompaniesReborn />
  },
  {
    path: 'view-contacts',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  },
  {
    path: 'tags',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  },
  {
    path: 'contact-fields',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  },
  {
    path: 'contact-groups',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  }
];

export default operationsRoutes;
