import { t } from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useAlert } from 'src/hooks/useAlert';
import { ChannelType } from 'src/models/conversations/conversations';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { VideoCallsDomain } from 'src/modules/VideoCall/domain';
import setAutoselectNextConversationDomain from 'src/services/socialmedia/domain/conversation/setAutoselectNextConversationDomain';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';

export const useVIdeoCallConversation = () => {
  const { data, isLoading } = useUserCampaignsQuery(true);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');

  const videoCallCampaigns = data?.elements.filter(
    (campaign) =>
      campaign.channel === ChannelType.CALL && campaign.callMode === 'VideoCall'
  );
  const { showAlert } = useAlert();

  useEffect(() => {
    if (videoCallCampaigns.length > 0) {
      const isSelectedCampaignValid = videoCallCampaigns.some(
        (campaign) => campaign.id === selectedCampaignId
      );

      if (!isSelectedCampaignValid) {
        setSelectedCampaignId(videoCallCampaigns[0].id);
      }
    }
  }, [videoCallCampaigns, selectedCampaignId]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedCampaignId(event.target.value);
  };

  const handleClick = () => {
    VideoCallsDomain.createVideoRoom(selectedCampaignId)
      .then((response) => {
        if (response != null) {
          setAutoselectNextConversationDomain(true);
          showAlert(t('Videoroom created successfully'), 'success', 3000);
          emitCustomEvent('Close popover');
        } else {
          showAlert(t('Videoroom could not be created'), 'error', 3000);
        }
      })
      .catch((e) => {
        const msg = getErrorMessage(e)?.errorMessage;
        showAlert(msg, 'error', 3000);
      });
  };

  return {
    isLoading,
    selectedCampaignId,
    videoCallCampaigns,
    handleChange,
    handleClick
  };
};
