import axios from 'axios';
import { PROMPT_ELEMENT_ID } from 'src/utils/apiRoutes/msAcd';
import { replaceURLParams } from 'src/services/utils';
import { PromptElement } from 'src/modules/Shared/models/ariModels';

export async function updatePromptElementApi(promptElement: PromptElement) {
  const path = replaceURLParams(PROMPT_ELEMENT_ID, {
    '{id}': promptElement.id
  });
  const response = await axios.put(path, promptElement);
  return response.data as PromptElement;
}
