import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { BASE_MS, BASE_URL } from '../constants';
import { TICKET_SUBRESOLUTIONS_ID } from 'src/utils/apiRoutes/msMessaging';

export const deleteTicketSubResolutionApi = async (id: string) => {
  const path = replaceURLParams(TICKET_SUBRESOLUTIONS_ID, {
    '{id}': id
  });
  await axios.delete(path);
};
