import {
  TicketField,
  TicketFieldOperator,
  TicketFieldVisibilityDependency
} from './tickets';

/**
 * Represents an array of TicketFieldVisibilityDependency instances with extended functionality.
 * This class introduces the custom method `someEvery`, which allows for conditional evaluation of elements within
 * the array based on the operator of a provided TicketField object. Depending on the operator type, it behaves as follows:
 *   - When the TicketField operator is set to 'AND', the `someEvery` method will evaluate whether all elements in the array
 *     satisfy the provided predicate function.
 *   - When the TicketField operator is set to 'OR', the `someEvery` method will evaluate whether at least one element in the array
 *     satisfies the provided predicate function.
 * */
export class TicketFieldVisibilityDependencyExtendedArray extends Array<TicketFieldVisibilityDependency> {
  constructor(visibilityDependencies: TicketFieldVisibilityDependency[]) {
    super(...visibilityDependencies);
  }

  /**
   * Determines whether the specified callback function returns true for any element of an array or all the members of an array satisfy the specified test based on the ticketfield operator value.
   * @param ticketField
   * @param predicate A function that accepts up to three arguments.
   * - The some method calls the predicate function for each element in the array until the predicate returns a value which is coercible to the Boolean value true, or until the end of the array.
   * - The every method calls the predicate function for each element in the array until the predicate returns a value which is coercible to the Boolean value false, or until the end of the array.
   * @param thisArg An object to which the this keyword can refer in the predicate function. If thisArg is omitted, undefined is used as the this value.
   */
  someEvery(
    ticketField: TicketField,
    predicate: (
      value: TicketFieldVisibilityDependency,
      index: number,
      array: TicketFieldVisibilityDependency[]
    ) => unknown,
    thisArg?: any
  ): boolean {
    switch (ticketField.operator) {
      case TicketFieldOperator.AND:
        return this.every(predicate, thisArg);
      case TicketFieldOperator.OR:
        return this.some(predicate, thisArg);
    }
  }
}
