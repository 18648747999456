import { TicketFieldValue } from '../models/tickets';

export const convertDateTimeTicketFieldValueToIsoString = (
  ticketField: TicketFieldValue
): TicketFieldValue => {
  if (ticketField.type !== 'DateTime') return ticketField;
  return {
    ...ticketField,
    value: new Date(ticketField.value as string).toISOString()
  };
};

export const getIsoDateTimeLocal = (date: string) => {
  // Obtener los componentes de la fecha y hora
  const year = new Date(date as string).getFullYear(); // Año con cuatro dígitos
  const month = (new Date(date as string).getMonth() + 1)
    .toString()
    .padStart(2, '0'); // Mes con dos dígitos
  const day = new Date(date as string).getDate().toString().padStart(2, '0'); // Día con dos dígitos
  const hours = new Date(date as string).getHours().toString().padStart(2, '0'); // Hora con dos dígitos
  const minutes = new Date(date as string)
    .getMinutes()
    .toString()
    .padStart(2, '0'); // Minutos con dos dígitos

  // Crear el formato "YYYY-MM-DDTHH:MM"
  const dateTimeLocal = `${year}-${month}-${day}T${hours}:${minutes}`;
  return dateTimeLocal;
};
