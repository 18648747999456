import { RTCall } from 'src/models/rtcall';
import { RTUser } from 'src/models/rtUser';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import WebRTCNotifications from 'src/services/webrtc/notifications/webrtc-notifications';
import CallManager from 'src/modules/CTI/infrastructure/manager';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import CallManagerDomain from '../CallManager';
import { TransferCallProps } from '../infrastructure/transferCallApi';
import { SpyModesType } from 'src/models/call';

/** HANGUP CALL FUNCTION
 *
 * @param RTCall - RTCall to hangup
 */
export const hangupCall = (call: RTCall) => {
  /*const idToHangup =
    call.status === 'Conference'
      ? call.linkedEndpoint?.rtCallId || call.id
      : call.id;*/
  return CallManager.hangupCallApi(call.id, call.instanceId).then(
    (response) => {
      if (response.status === 200) {
        WebRTCNotifications.hangUpCallAlert();
      }
      return response;
    }
  );
};

/** LAUNCH CALL FUNCTION
 *
 * @param campaignId campaign id
 * @param phone phone number or extension to call
 * @param extension extension of the agent
 */
export const launchCall = (params: {
  campaignId: string;
  phone: string;
  extension: string;
  isInternal: boolean;
  ticketId?: string;
  isSpecial?: boolean;
}) => {
  const {
    campaignId,
    phone,
    extension,
    isInternal,
    ticketId,
    isSpecial = false
  } = params;
  return CallManager.launchCallApi(
    campaignId,
    phone,
    extension,
    isInternal,
    ticketId,
    isSpecial
  );
};

/** PARK CALL FUNCTION
 *
 * @param RTCall - RTCall to park
 */
export const parkCall = (call: RTCall) => {
  return CallManager.parkCallApi(call.id, call.instanceId);
};

/** UNPARK CALL FUNCTION
 *
 * @param RTCall - RTCall to unpark
 */
export const unparkCall = (call: RTCall) => {
  const connectedRTUser = getConnectedRTUser(false);
  // parkActiveCall();
  return CallManager.unParkCallApi(
    call.id,
    connectedRTUser.extension?.extension,
    call.instanceId,
    connectedRTUser.extension?.complexExtension
  );
};

/** PARK ACTIVE CALL
 *
 *  This function can be used to park active call
 *
 */
export const parkActiveCall = () => {
  const activeCall = getActiveRTCall(getConnectedRTUser(false));
  if (activeCall) {
    CallManagerDomain.parkCall(activeCall);
  }
};
/** UNPARK CALL FUNCTION
 *
 *  This function can be used to transfer a parked call.
 *
 * @param RTCall - RTCall to unpark
 * @param rtUser - Agent that will get the call
 */
export const unparkToOtherAgent = (call: RTCall, extension: string) => {
  const connectedRTUser = getConnectedRTUser(false);

  return CallManager.unParkCallApi(
    call.id,
    extension,
    call.instanceId,
    connectedRTUser?.extension?.complexExtension
  );
};

/** DIRECT TRANSFER CALL FUNCTION
 *
 * @param RTCall - RTCall to transfer
 * @param extension - Agent extension to transfer
 */
export const transferCall = async ({
  call,
  extension,
  isInternal,
  campaignId
}: TransferCallProps) => {
  if (call.status === 'Parked') return unparkToOtherAgent(call, extension);

  return CallManager.transferCallApi({
    call,
    extension,
    isInternal,
    campaignId,
    instanceId: call.instanceId
  });
};

/** ATTENDED TRANSFER CALL FUNCTION
 *
 * @param RTCall - Linked RTCall to transfer
 * @param extension - Agent extension to transfer
 */
export const attendedTransferCall = (
  call: RTCall,
  number: string,
  isInternal: boolean,
  campaignId?: string
) => {
  return CallManager.attendedTransferCallApi(
    call.id,
    number,
    call.instanceId,
    isInternal,
    campaignId
  );
};

/** COMPLETE ATTENDED TRANSFER CALL FUNCTION
 *
 * @param RTCall - Linked RTCall to transfer
 * @param complete - complete attended transfer boolean
 */
export const completeAttendedTransferCall = (
  call: RTCall,
  complete: boolean
) => {
  return CallManager.completeAttendedTransferCallApi(
    call.id,
    complete,
    call.instanceId
  );
};

/** CADD AGENT TO CONFERENCE FUNCTION
 *
 * @param call - RTCall of the conference
 * @param extension - agent extension to add
 *
 */
export const addAgentToConference = (
  extension: string,
  call: RTCall,
  isInternal: boolean,
  campaignId: string
) => {
  return CallManager.addToConferenceApi(
    call.id,
    extension,
    call.instanceId,
    isInternal,
    campaignId
  );
};

/** REMOVE AGENT FROM CONFERENCE FUNCTION
 *
 * @param call - RTCall of the conference
 */
export const removeAgentFromConference = (call: RTCall) => {
  return CallManager.removeFromConference(call.id, call.instanceId);
};

/** SPY CALL FUNCTION
 *
 * @param callId - Call id to transfer
 * @param extension - Agent extension to transfer
 * @param mode - Spying mode
 * @param instance - Instance id
 */
export const spyCall = (
  callId: string,
  extension: string,
  mode: SpyModesType,
  instance: string
) => {
  return CallManager.spyCall(callId, extension, mode, instance);
};
