import axios from 'axios';
import { Variable } from '../models/models';
import { replaceURLParams } from 'src/services/utils';
import { VARIABLE_ID } from 'src/utils/apiRoutes/msAcd';

export const getVariableByIdApi = async (id: string) => {
  const path = replaceURLParams(VARIABLE_ID, { '{id}': id });
  const response = await axios.get(path);
  return response.data as Variable;
};
