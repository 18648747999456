import axios, { AxiosResponse } from 'axios';
import { ScheduleCalendar } from '../createScheduleCalendar';
import { queryClient } from 'src/utils/queryClient';
import { schedulesQueryKey } from '../domain/getSchedulesCalendar';
import { SCHEDULE_CALENDAR } from 'src/utils/apiRoutes/msCallSchedule';

export const createScheduleCalendar = async (
  scheduleCalendar: ScheduleCalendar,
  conversationId: string
): Promise<ScheduleCalendar> => {
  const params = { conversationId };
  const response: AxiosResponse<ScheduleCalendar> = await axios.post(
    SCHEDULE_CALENDAR,
    scheduleCalendar,
    { params }
  );
  queryClient.invalidateQueries({
    queryKey: [schedulesQueryKey]
  });
  return response.data;
};
