import { Card, Grow, Stack } from '@mui/material';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { ConferenceCallInfoCard } from './CallInfoCard';
import { HangupButton } from '../../CallButtons/HangupButton';
import { useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

export const ConferenceInfo: React.FC = () => {
  const user = getConnectedRTUser(true);
  if (!user) return <></>;
  const { barState } = useContext(CTIBarContext);
  const { positionY } = barState;

  const isOnTop = positionY / window.innerHeight <= 0.5;

  const conferenceCalls = user.calls
    ?.filter((c) => c.status === 'Conference')
    .filter((c) => c.userId !== user.id);

  return (
    <Grow
      in={conferenceCalls.length !== 0}
      timeout={{ appear: 2000, enter: 2000, exit: 0 }}
      style={{
        transformOrigin: 'top'
      }}
    >
      <Card
        sx={{
          borderTopLeftRadius: isOnTop ? 0 : '8px',
          borderTopRightRadius: isOnTop ? 0 : '8px',
          borderBottomLeftRadius: isOnTop ? '8px' : 0,
          borderBottomRightRadius: isOnTop ? '8px' : 0,
          position: 'absolute',
          bottom: isOnTop ? 'inherit' : '100%',
          top: isOnTop ? '100%' : 'inherit',
          left: '45px',
          zIndex: 2000,
          boxShadow: isOnTop
            ? '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)'
            : '0px -6px 10px rgba(159, 162, 191, .12), 0px -1px 1px rgba(159, 162, 191, 0.2)'
        }}
      >
        <Stack py={1} px={1} spacing={1}>
          {conferenceCalls.map((call, index) => (
            <Stack
              direction="row"
              alignItems={'center'}
              key={index}
              width="100%"
              justifyContent={'space-between'}
              spacing={0.5}
            >
              <ConferenceCallInfoCard call={call} />
              <HangupButton call={call} />
            </Stack>
          ))}
        </Stack>
      </Card>
    </Grow>
  );
};
