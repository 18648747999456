import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { TicketResolution } from '../../models/tickets';
import {
  TICKET_RESOLUTIONS,
  TICKET_RESOLUTIONS_ID
} from 'src/utils/apiRoutes/msMessaging';

export const getTicketResolutionsApi = async (
  companyId: string
): Promise<TicketResolution[]> => {
  const params = { companyId };
  const resp = await axios.get(TICKET_RESOLUTIONS, { params });
  return resp.data;
};

export const getTicketResolutionsByIdApi = async (
  id: string
): Promise<TicketResolution> => {
  const path = replaceURLParams(TICKET_RESOLUTIONS_ID, {
    '{id}': id
  });
  const resp = await axios.get(path);
  return resp.data;
};
