import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useContext } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { VideoCallsDomain } from 'src/modules/VideoCall/domain';
import { VideoCallParticipant } from 'src/modules/VideoCall/types/videoCallTypes';

interface Props {
  participant: VideoCallParticipant;
  buttonWidth?: string;
}

export default function ActionButtons({
  participant,
  buttonWidth: width
}: Props) {
  const theme = useTheme();
  const { videoRoom } = useContext(VideocallRoomContext);
  const roomId = videoRoom.room.id;

  return (
    <>
      <motion.div
        layoutId={`accept-${participant.id}`}
        style={{
          padding: '8px 20px',
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          fontWeight: 'bold',
          fontFamily: 'Inter',
          cursor: 'pointer',
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width
        }}
        onClick={() => {
          VideoCallsDomain.acceptParticipant(roomId, participant.id);
        }}
      >
        {t('Accept')}
      </motion.div>
      <motion.div
        layoutId={`deny-${participant.id}`}
        style={{
          padding: '8px 20px',
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
          fontWeight: 'bold',
          fontFamily: 'Inter',
          cursor: 'pointer',
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width
        }}
        onClick={() => {
          VideoCallsDomain.rejectParticipant(roomId, participant.id);
        }}
      >
        {t('Reject')}
      </motion.div>
    </>
  );
}
