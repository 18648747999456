import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import {
  SendInstagramMessageTextParams,
  sendInstagramMessageText
} from 'src/services/instagram/domain/message/sendInstagramMessageText';
import { emitCustomEvent } from 'react-custom-events';
import { useAlert } from 'src/hooks/useAlert';
import {
  CTIBarContext,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import setAutoselectNextConversationDomain from 'src/services/socialmedia/domain/conversation/setAutoselectNextConversationDomain';
import { setCampaignSelector } from '../utils/setCampaignSelector';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import {
  SendInstagramMessageFileParams,
  sendInstagramMessageFile
} from 'src/services/instagram/domain/message/sendInstagramMessageFile';
import { ChatMessageType } from 'src/models/conversations/messages';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';

interface Props {
  /** Default is true */
  useCTIBarContext?: boolean;
  /** ignored if useCTIBarContext equals to true */
  username?: string;
  /** ignored if useCTIBarContext equals to true */
  setUsername?: Dispatch<SetStateAction<string>>;
}

const InstagramConversation = ({
  username: usernameProps,
  setUsername: setUsernameProps,
  useCTIBarContext = true
}: Props) => {
  const theme = useTheme();
  const [message, setMessage] = useState('');
  const campaignsQuery = useUserCampaignsQuery(true);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const { setActiveTab } = useContext(CTIBarContext);

  const {
    username: usernameContext,
    setUsername: setUsernameContext,
    selectedTicket
  } = useDialpadStateContext();

  const [username, setUsername] = useCTIBarContext
    ? [usernameContext, setUsernameContext]
    : [usernameProps, setUsernameProps];

  const { showAlert } = useAlert();
  const [file, setFile] = useState<File | null>(null);

  const ticketId = selectedTicket?.id;

  const formattedUsername = /^@\w+/.test(username)
    ? username.slice(1)
    : username;

  const onSendSuccessMessage = () => {
    setAutoselectNextConversationDomain(true);
    if (useCTIBarContext) {
      emitCustomEvent('Close popover');
      setActiveTab('dialpad');
    }
    showAlert('Instagram message has been sent!', 'success', 3000);
  };

  const onChangeSelect = (e): void => {
    setSelectedCampaignId(e.target.value);
    localStorage.setItem('selectedInstagramCampaign', e.target.value);
  };

  const Input = styled('input')({
    display: 'none'
  });

  const handleStartConversation = () => {
    if (file) return startConversationAttachment();
    if (!username || !selectedCampaignId || !message) return;
    const baseParams: SendInstagramMessageTextParams = {
      campaignId: selectedCampaignId,
      username: formattedUsername,
      text: message,
      ticketId
    };

    sendInstagramMessageText({
      ...baseParams,
      username: formattedUsername
    })
      .then(onSendSuccessMessage)
      .catch((error) => {
        showAlert(t(getErrorMessage(error)?.errorMessage), 'error', 3000);
      });
  };

  const startConversationAttachment = () => {
    if (!username || !selectedCampaignId || !file) return;

    let tp = file?.type.match(/^[^/]*/)[0];
    if (tp === 'application') tp = 'document';
    tp = tp.charAt(0).toUpperCase() + tp.slice(1);

    const params: SendInstagramMessageFileParams = {
      campaignId: selectedCampaignId,
      username: formattedUsername,
      file: file,
      type: tp as ChatMessageType,
      ticketId
    };

    sendInstagramMessageFile(params)
      .then(onSendSuccessMessage)
      .catch(() => {
        showAlert(t('Error sending the message'), 'error', 3000);
      });
  };

  useEffect(() => {
    setCampaignSelector(
      'selectedInstagramCampaign',
      setCampaigns,
      setSelectedCampaignId,
      campaignsQuery?.data?.elements,
      'Instagram',
      selectedTicket?.serviceId
    );
  }, [campaignsQuery?.dataUpdatedAt]);

  const onLoadAttachmentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    setFile(fileList[0]);
  };

  return (
    <Card sx={{ width: '500px' }}>
      <Stack
        width="100%"
        direction={'row'}
        justifyContent={setUsername ? 'space-between' : 'flex-start'}
        alignItems="center"
        sx={{
          backgroundColor: '#F6F8FB',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          color: '#c13584'
        }}
        gap={2}
        px={2}
        borderRadius={1}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <InstagramIcon />
          <h3>{t('New Instagram Conversation')}</h3>
        </Box>
      </Stack>
      <Stack direction="column" divider={<Divider orientation="horizontal" />}>
        <Box p={2}>
          <TextField
            label={t('User')}
            value={username}
            onChange={(e) => {
              setUsername(e.target.value.replace(/\s/g, ''));
            }}
            fullWidth={true}
            id="user-InstagramConversation"
          />
        </Box>
        <Box width="100%" p={1.5}>
          <textarea
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            placeholder={t('Write your message here...')}
            style={{
              borderRadius: '10px',
              resize: 'none',
              border: 'none',
              width: '100%',
              outline: 'none',
              fontSize: '1.1em',
              backgroundColor: '#FFEDF3',
              padding: '20px',
              fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`
            }}
          ></textarea>
        </Box>
        <Box width="100%" p={2}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Select
              placeholder="Campaña"
              sx={{ height: '40px', width: '240px' }}
              value={selectedCampaignId}
              onChange={onChangeSelect}
              id="campana-InstagramConversation"
            >
              {campaigns.map((c, idx) => {
                return (
                  <MenuItem key={idx} value={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Input
                id="instagram-upload-file-cti"
                type="file"
                onChange={onLoadAttachmentFile}
              />
              <Tooltip
                arrow
                placement="top"
                title={file ? file.name : t('Attach a file')}
              >
                <label htmlFor="instagram-upload-file-cti">
                  <IconButton
                    sx={{ mx: 1 }}
                    color={file ? 'success' : 'primary'}
                    component="span"
                  >
                    <AttachFileTwoToneIcon fontSize="small" />
                  </IconButton>
                </label>
              </Tooltip>
              {/* <PermissionsTooltip hasAccess={hasStartChatConversationAccess}> */}
              <Button
                id="btnSend-InstagramConversation"
                variant={'contained'}
                color="secondary"
                disabled={!username || !selectedCampaignId || !message}
                onClick={handleStartConversation}
              >
                {t('Send')}
              </Button>
              {/* </PermissionsTooltip> */}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default InstagramConversation;
