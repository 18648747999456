import { RTUser } from 'src/models/rtUser';
import { setRTUser, setRTUsers } from 'src/store/slices/rtusers/rtuserSlices';
import store from 'src/store/store';

/** ADD / UPDATE RTUSER TO STORE
 *
 * @param rtuser RTUser to be added / updated
 */
export const setRTUserStore = (rtuser: RTUser) => {
  return store.dispatch(setRTUser(rtuser));
};

export const setRTUsersStore = (rtusers: RTUser[]) => {
  store.dispatch(setRTUsers(rtusers));
};
