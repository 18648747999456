import store, { useSelector } from 'src/store/store';

export function useUserConversationCount(userId: string) {
  return useSelector((state) => state.socialmedia.conversationCount[userId]);
}

export function getFullConversationCount() {
  return store.getState().socialmedia.conversationCount;
}

export function useFullConversationCount() {
  return useSelector((state) => state.socialmedia.conversationCount);
}
