import { Avatar, Tooltip, styled, useTheme } from '@mui/material';

import { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.secondary.lighter};
          color: ${theme.colors.secondary.main};
          width: ${theme.spacing(4)};
          height: ${theme.spacing(4)};
    `
);

type Props = {
  icon: ReactNode;
  label: string;
  isSelected?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

const HelperPanelHeaderButtonItem = (props: Props) => {
  const { icon: Icon, label, isSelected, onClick } = props;
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip title={t(label)} arrow>
      <AvatarWrapper
        sx={{
          cursor: 'pointer',
          transition: 'transform 0.3s',
          boxSizing: 'border-box',
          border: isSelected ? 1 : undefined,
          borderColor: theme.colors.secondary.main,
          '&:hover': {
            transform: 'scale(1.1)'
          }
        }}
        onClick={onClick}
      >
        {Icon}
      </AvatarWrapper>
    </Tooltip>
  );
};

export default HelperPanelHeaderButtonItem;
