import { useEffect, useState } from 'react';
import { Variable } from 'src/modules/Admin/modules/General/modules/Variables/models/models';
import { getVariableByIdQuery } from 'src/modules/Admin/modules/General/modules/Variables/domain/getVariableByIdDomain';
import { getCompanyVariablesQuery } from 'src/modules/Admin/modules/General/modules/Variables/application/getVariablesQuery';
import { getSelectedService } from 'src/services/daServices/domain/getSelectedService';
import { emailSignaturesMock } from 'src/services/email/models/emailSignatureModels';
import { getContactFieldByIdQuery } from 'src/modules/Contacts/domain/getContactFields';
import { useVariablesContext } from '../context/VariablesEditorContext';

interface Props {
  variableId: string;
  companyId?: string;
}

function useVariableId({ variableId, companyId }: Props) {
  const { variables } = useVariablesContext();

  async function getVariable(id: string) {
    const variableOnContext = variables.find((v) => v.id === id);
    if (variableOnContext) return variableOnContext;

    const emailSignatureVariable = emailSignaturesMock.find((v) => v.id === id);
    if (emailSignatureVariable) return emailSignatureVariable as Variable;

    if (id.includes('C_contact[')) {
      // V_contact[contactFieldId]
      // extract contactFieldId with regex
      const contactFieldId = id.match(/\[(.*?)\]/)[1];
      const contactField = await getContactFieldByIdQuery(contactFieldId);
      return {
        id: id,
        name: contactField?.name,
        description: contactField?.name
      } as Variable;
    }

    if (id.includes('default')) {
      const service = getSelectedService();
      const serviceCompanyId = service?.companyId;
      const { elements: variables } = await getCompanyVariablesQuery({
        companyId: companyId ?? serviceCompanyId
      });
      const variable = variables.find((variable) => 'V_' + variable.id === id);
      if (variable) return variable;

      return variables.find((variable) => variable.id === id);
    }

    const variable = await getVariableByIdQuery(id);
    return variable;
  }
  async function getVariableValue() {
    const variable = await getVariable(variableId);

    setVariable(variable);
  }
  const [variable, setVariable] = useState({} as Variable);

  useEffect(() => {
    if (variableId) {
      getVariableValue();
    }
  }, [variableId]);

  return variable as Variable;
}

export default useVariableId;
