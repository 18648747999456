import { useTheme } from '@mui/material';

export function useShowflowColors() {
  const theme = useTheme();

  const showflowColorsMap = {
    Call: theme.colors.success.main,
    Company: '#D962D7',
    Ticket: theme.colors.warning.main,
    Contact: theme.colors.secondary.main
  };

  return { showflowColorsMap };
}
