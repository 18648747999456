import { queryClient } from 'src/utils/queryClient';

import {
  getKnowledgeDocumentationApi,
  KnowledgeDocumentationFilters
} from '../../infrastructure/api/knowledgeModel/getKnowledgeDocumentationApi';
import { useQuery } from '@tanstack/react-query';

export const getKnowledgeDocumentationQuery = (
  params: KnowledgeDocumentationFilters
) => {
  return queryClient.fetchQuery(
    ['knowledgeDocumentation', params?.companyId, params?.status, params],
    {
      queryFn: () => getKnowledgeDocumentationApi(params),
      staleTime: 60 * 1000
    }
  );
};

export function useKnowledgeDocumentationQuery(
  params: KnowledgeDocumentationFilters
) {
  return useQuery(['knowledgeDocumentation', params], {
    queryFn: () => getKnowledgeDocumentationApi(params),
    staleTime: 60 * 1000
  });
}

export function invalidateKnowledgeDocumentationQuery() {
  return queryClient.invalidateQueries({
    queryKey: ['knowledgeDocumentation']
  });
}
