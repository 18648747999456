import { ChatMessageType } from 'src/models/conversations/messages';
import {
  SendInstagramNewMessageFileApiParams,
  sendInstagramNewMessageFileApi
} from '../../infrastructure/message/sendInstagramNewMessageFileApi';
import {
  SendInstagramExistingMessageFileApiParams,
  sendInstagramExistingMessageFileApi
} from '../../infrastructure/message/sendInstagramExistingMessageFileApi';

/**
 * If username and campaignId: adds message to a new conversation.
 * If conversationId and accountId: adds message to an existing conversation.
 */

interface CommonProps {
  file: File;
  type: ChatMessageType;
}

type ConditionalProps =
  | {
      // NEW CONVERSATION
      campaignId: string;
      username: string;
      ticketId?: string;
      accountId?: never;
      conversationId?: never;
      replyTo?: never;
    }
  | {
      // EXISTING CONVERSATION
      accountId: string;
      conversationId: string;
      replyTo?: string;
      ticketId?: never;
      campaignId?: never;
      username?: never;
    };

export type SendInstagramMessageFileParams = CommonProps & ConditionalProps;

export const sendInstagramMessageFile = async (
  params: SendInstagramMessageFileParams
) => {
  const {
    file,
    type,
    username,
    campaignId,
    conversationId,
    accountId,
    replyTo,
    ticketId
  } = params;
  if (username) {
    const apiParams: SendInstagramNewMessageFileApiParams = {
      campaignId: campaignId,
      file: file,
      type: type,
      username: username,
      ticketId
    };
    await sendInstagramNewMessageFileApi(apiParams);
  } else if (conversationId) {
    const apiParams: SendInstagramExistingMessageFileApiParams = {
      file: file,
      type: type,
      conversationId: conversationId,
      replyTo: replyTo,
      accountId: accountId
    };
    await sendInstagramExistingMessageFileApi(apiParams);
  }
};
