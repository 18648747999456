import { Conversation } from 'src/models/conversations/conversations';
import {
  CallCampaign,
  CallMode,
  Campaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { getCampaignByIdQuery } from 'src/services/users/application/query/getUsersDataQueries';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { HOTLINE_COMPANIES } from 'src/services/socialmedia/domain/conversation/utils';
import { getSocialmediaAgentEndpointFrom } from 'src/services/socialmedia/domain/conversation/setCurrentConversation';
import { ExternalAction } from '../models/externalActionsModels';
import { logger } from 'src/utils/logger';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { getExternalActionByIdQuery } from '../utils/getExternalActionByIdQuery';
import { getDaServiceByIdQuery } from 'src/modules/Admin/modules/Operations/modules/DaServices/views/hooks/daServiceQueries';

/**
 * Request the campaign and the service assigned to the conversation.
 *
 * If the logged user is from Hotline, return the SGI external action.
 *
 * If the campaign of the conversation passed ignores the externalaction, return null.
 *
 * Otherwise, request the externalActions filtered by the the contactGroupId of the conversation service
 * and return the external action of the conversation service.
 * @param conversation
 * @returns externalAction
 */
export const getConversationExternalAction = async (
  conversation: Conversation
) => {
  //PROVISIONAL/////////////////////////////////////////////////////////////////////
  if (conversation?.id === 'hotline') {
    const hotlineExternalAction: ExternalAction = {
      companyId: '12',
      contactGroupId: '123',
      id: '1234',
      name: 'SGI',
      type: 'FORM',
      url: 'https://app.f1-connecting.com',
      openNewTab: false
    };
    return hotlineExternalAction;
  }
  /////////////////////////////////////////////////////////////////////////////////
  if (!conversation?.campaignId) return null;

  let campaign: Campaign = null;
  try {
    campaign =
      (await getCampaignByIdQuery(conversation?.campaignId)) ?? undefined;
  } catch (error) {
    logger.error(getErrorMessage(error)?.errorMessage);
  }

  if (campaign?.ignoreExternalAction) return null;

  const loggedUser = getConnectedRTUser(false);
  // The services of the conversation's company are stored in cache or retrieved from cache
  const service = await getDaServiceByIdQuery(conversation.serviceId);

  let url = null;
  //PROVISIONAL/////////////////////////////////////////////////////////////////////
  const isHotlineUser = HOTLINE_COMPANIES.includes(loggedUser.companyId);
  /////////////////////////////////////////////////////////////////////////////////
  let serviceExternalAction = null;
  if (!isHotlineUser) {
    if (!service?.externalActionId) return null;
    try {
      serviceExternalAction = await getExternalActionByIdQuery(
        service?.externalActionId
      );
    } catch (error) {
      logger.error(error);
    }
    return serviceExternalAction;
  }
  //PROVISIONAL/////////////////////////////////////////////////////////////////////
  const endpoint = getSocialmediaAgentEndpointFrom(conversation);
  const hotlineCallUrl =
    `https://app.f1-connecting.com/hotlinewn/lanza_popup.php?idpopup=openformfromdialapplet` +
    (endpoint || conversation?.originAgent?.phoneNumber
      ? `&llaman_de=${endpoint ?? conversation?.originAgent?.phoneNumber}`
      : '') +
    `&linkedid=${conversation.originAgent?.callId}` +
    `&uniqueid=${conversation.originAgent?.callId}` +
    `&usuario=${loggedUser?.username}` +
    `&conversationId=${conversation.id}` +
    `&campaignid=${conversation.campaignId}` +
    (conversation?.originAgent?.chatId
      ? `&telegramChatId=${conversation.originAgent.chatId}`
      : '');

  const callCampaign = campaign as CallCampaign;
  if (!callCampaign.callMode || callCampaign.callMode === CallMode.OUTBOUND)
    // url = null;
    url = hotlineCallUrl;
  else if (conversation.id === 'hotline') url = 'https://app.f1-connecting.com';
  else {
    url = hotlineCallUrl;
  }
  const externalAction: ExternalAction = {
    companyId: conversation.companyId,
    contactGroupId: service.contactGroupId,
    id: '1234',
    name: 'SGI',
    type: 'FORM',
    openNewTab: false,
    url
  };
  return externalAction;
  /////////////////////////////////////////////////////////////////////////////////
};
