import React from 'react';
import {
  Box,
  useTheme,
  Typography,
  Divider,
  MenuItem,
  ListItemText,
  styled,
  MenuList,
  Button,
  Grid,
  Stack,
  Icon
} from '@mui/material';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { Item, Menu } from 'src/models/menus';
import { useContext } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { AdminPanelSettings, AutoAwesome } from '@mui/icons-material';
import { useFeaturesContext } from 'src/contexts/CompanyFeaturesContext/CompanyFeaturesContext';

interface MegaMenuPropTypes {
  title: string;
  subtitle?: string;
  items: Menu[];
  footer?: {
    text: string;
    link: string;
  };
}

export const MegaMenu: React.FC<MegaMenuPropTypes> = ({
  title,
  subtitle,
  items,
  footer
}) => {
  const { hasAccess, isSuperAdmin } = useContext(PermissionsContext);
  const { hasFeature } = useFeaturesContext();
  const theme = useTheme();

  const MenuListWrapperSecondary = styled(MenuList)(
    ({ theme }) => `

  & .MuiMenuItem-root {
      padding-left: 5px;
      text-decoration: none;
      border-radius: 50px;
      min-width: 100px;
      position: relative;
      margin-top:-10px;
      background:transparent;
      &:last-child {
          margin-bottom: 5px;
      },
      &:hover, &:focus, &:active {
        background-color: transparent !important;
      }
    }
`
  );

  const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding-r: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(15)};
      border-radius: ${theme.general.borderRadius};
      margin-bottom:10px;
      margin-top:5px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      max-height: ${theme.spacing(3)};
    font-weight:bold;    
`
  );

  const IconWrapper = styled('span')(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(15)};
      border-radius: ${theme.general.borderRadius};
      margin-bottom:10px;
      margin-top:5px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      max-height: ${theme.spacing(3)};
    font-weight:bold;    
`
  );

  function checkItemIsEnabled(item: Item) {
    if (item.featureCheckerFn) {
      const isFeatureEnabled = hasFeature(item.featureCheckerFn);
      if (!isFeatureEnabled) return false;
    }

    if (item.permissionsCheckerFn) {
      const hasPermissions = hasAccess(item.permissionsCheckerFn);
      if (!hasPermissions) return false;
    }

    return true;
  }

  return (
    <Stack
      sx={{
        width: 1100
      }}
      my={1}
      ml={0.5}
      mr={1}
    >
      <Box
        sx={{
          p: 1,
          pl: 2
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">{t(title)}</Typography>
        <Typography noWrap variant="subtitle2">
          {t(subtitle)}
        </Typography>
      </Box>
      <Grid container spacing={0.5}>
        {items.map((element, index) => {
          return (
            <Grid
              item
              key={index}
              sx={{
                marginTop: -2
              }}
              sm={3}
            >
              <MenuListWrapperSecondary key={index} disablePadding>
                <Stack
                  direction="row"
                  justifyContent={'top'}
                  alignItems={'top'}
                >
                  <IconWrapper>
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: 2,
                        height: 28,
                        width: 28,
                        textAlign: 'center',
                        marginTop: '4px',
                        marginBottom: '2px',
                        marginRight: '3px',
                        boxShadow: `0px 3px 8px rgba(0, 0, 0, 0.1)`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Icon
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          color: `${element.iconColor}`
                        }}
                      >
                        {React.createElement(element.icon, {
                          fontSize: 'inherit'
                        })}
                      </Icon>
                    </Box>
                  </IconWrapper>
                  <Stack direction="column" width={'100%'}>
                    <LabelWrapper sx={{ mt: 1, mb: 1, ml: 1 }}>
                      {t(element.option)}
                    </LabelWrapper>
                    {element.items
                      .sort((a, b) => t(a.name).localeCompare(t(b.name)))
                      .map((item) => {
                        const isEnabled = checkItemIsEnabled(item);
                        if (!isEnabled) return <></>;
                        return (
                          <MenuItem
                            disableRipple
                            key={item.name}
                            component={NavLink}
                            to={item.link}
                            sx={{
                              m: 0.5,
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              '&.active .menuItemText': {
                                textDecoration: 'underline',
                                fontWeight: '700',
                                background: 'transparent'
                              },
                              '&:hover .menuItemText': {
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                variant: 'body1',
                                className: 'menuItemText'
                              }}
                              sx={{}}
                              primary={t(item.name)}
                            />
                            {(item.hasSuperadminFeatures ||
                              item.hasSuperadminFeatures) &&
                              isSuperAdmin && (
                                <AdminPanelSettings
                                  color="inherit"
                                  sx={{
                                    fontSize: '17.5px',
                                    marginBottom: 0.25,
                                    color: `${theme.palette.primary.light}`
                                  }}
                                />
                              )}
                            {item.isAI && (
                              <AutoAwesome
                                color="inherit"
                                sx={{ marginBottom: 0.25, fontSize: '17.5px' }}
                              />
                            )}
                          </MenuItem>
                        );
                      })}
                  </Stack>
                </Stack>
              </MenuListWrapperSecondary>
            </Grid>
          );
        })}
      </Grid>
      {footer ? (
        <>
          <Divider />
          <Box
            sx={{
              m: 2,
              textAlign: 'center'
            }}
          >
            <Button size="small" color="primary" id="btnFooter-MegaMenu">
              {t(footer.text)}
            </Button>
          </Box>
        </>
      ) : null}
    </Stack>
  );
};
