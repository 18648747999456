import axios from 'axios';
import { BASE_MS, BASE_URL, BASE_SHOWFLOW } from '../constants';
import qs from 'qs';
import { Showflow } from '../../models/showflow';

export const SHOWFLOW_DRAFTS_KEY = 'showflowDrafts';

export interface ShowflowsFilters {
  companyId?: string;
  serviceIds?: string[];
}

export async function getShowflowsApi(filters?: ShowflowsFilters) {
  const path = `${BASE_URL}${BASE_MS}${BASE_SHOWFLOW}`;

  const response = await axios.get(path, {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

  return response.data as Showflow[];
}

const checkShowflowFilters = (params: {
  showflow: Showflow;
  filters: ShowflowsFilters;
}) => {
  const { showflow, filters } = params;
  const { companyId, serviceIds } = filters;

  const filterFnArray = [
    // Check serviceId
    () => !serviceIds || serviceIds.includes(showflow?.serviceId),
    // Check companyId
    () => !companyId || showflow.companyId === companyId
  ];
  // Check every filter function
  return filterFnArray.every((filterFn) => filterFn());
};

export async function getShowflowDraftsApi(filters?: ShowflowsFilters) {
  const resp = localStorage.getItem(SHOWFLOW_DRAFTS_KEY);
  const showflowDrafts: Showflow[] = !!resp ? JSON.parse(resp) : [];
  const elements = showflowDrafts.filter((showflow) =>
    checkShowflowFilters({ showflow, filters })
  );
  return Promise.resolve(elements);
}
