import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { COMPANIES, COMPANIES_ID, USERS_MS } from 'src/utils/apiRoutes/msUsers';

const USER_STATUS = process.env.REACT_APP_USER_STATUS as string;

export const getCompanyStatusById: Function = (id: string) => {
  const path = replaceURLParams(COMPANIES + USER_STATUS, {
    '{id}': id
  });

  let params = { manualChange: true };

  return axios
    .get(path, { params })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getCompanies: Function = () => {
  return axios
    .get(COMPANIES)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getCompanieById: Function = (id: string) => {
  const path = replaceURLParams(COMPANIES_ID, {
    '{id}': id
  });

  return axios
    .get(path)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};
