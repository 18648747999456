import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  styled,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'src/hooks/useAlert';
import { createContactField } from 'src/modules/Contacts/domain/createContactField';
import {
  ContactField,
  ContactFieldTypeNames
} from 'src/modules/Contacts/models/contacts';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { ContactGroupsSelector } from '../contactGroups/selector';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import { UserRole } from 'src/models/permissionGroups';
import { getUserRoles } from 'src/services/permissionGroups/domain/getUserRoles';
import { updateContactField } from 'src/modules/Contacts/domain/updatecontactField';
import { queryClient } from 'src/utils/queryClient';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { Formik } from 'formik';
import AuthManager from 'src/services/authentication/manager';
import { DialTableImperativeHandle } from 'src/components/DialTable';
import Scrollbar from 'src/components/Scrollbar';
import {
  checkCreateContactFieldsPermissions,
  checkUpdateContactFieldsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { is } from 'immutable';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

interface Props {
  onClose: () => void;
  contactField?: ContactField;
  onData?: (props: ContactField) => void;
  contactGroupId?: string;
  tableRef?: MutableRefObject<DialTableImperativeHandle<ContactField>>;
}

export const CreateUpdateContactFieldForm: React.FC<Props> = ({
  onClose,
  contactField,
  onData,
  contactGroupId,
  tableRef
}) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { hasAccess } = useContext(PermissionsContext);
  const mode = contactField ? 'Update' : 'Create';
  const title = contactField
    ? t('Update contact field')
    : t('Create new contact field');
  const subheader = contactField
    ? t('Use this dialog window to update a contact field')
    : t('Use this dialog window to add a new contact field');

  const defaultContactField: ContactField = useMemo(() => {
    return {
      companyId: '',
      contactGroupId: contactGroupId ?? '',
      id: '',
      mainField: false,
      name: '',
      type: 'Name',
      editableBy: [],
      visibleTo: [],
      required: false,
      unique: false,
      autoGenerated: false,
      pinned: false
    };
  }, []);

  // PROVISIONAL : Add userRole ids from userPermissions
  const userRoleIds = [];
  const editableBy = contactField?.editableBy ?? [];

  const userIsInEditableBy =
    editableBy.length === 0 ||
    userRoleIds.some((id) => editableBy.includes(id));

  const userCanEdit = userIsInEditableBy;

  const fieldTypes = Object.keys(ContactFieldTypeNames);
  const [contactFieldToEdit, setContactFieldToEdit] = useState(
    contactField ?? defaultContactField
  );

  const [userRoleOptions, setUserRoleOptions] = useState<UserRole[]>([]);

  const contactFieldsQuery = useGetContactFields({
    contactGroupId: contactFieldToEdit.contactGroupId
  });
  const contactFields = contactFieldsQuery.data?.elements ?? [];
  const mainFieldExists = contactFields.some(
    (field) => field.type === contactFieldToEdit.type
  );
  //The first field of each type is the main field
  const mustBeMainField = !mainFieldExists;

  const companyId = AuthManager.getLoggedUserCompanyId();

  function handleCreate(values: ContactField) {
    createContactField({
      ...values,
      companyId,
      mainField: mustBeMainField || values.mainField
    })
      .then((res) => {
        onData?.(res);
        showAlert(t('Field created'), 'success', 3000);
        emitCustomEvent(REFETCH_DATA_EVENT_NAME);
        tableRef?.current.refetchData();
        onClose();
      })
      .catch((error) => {
        showAlert(
          t(`Could not create field. ${getErrorMessage(error).errorMessage}`),
          'error',
          3000
        );
      });
  }

  function handleUpdate(values: ContactField) {
    updateContactField({
      ...values,
      mainField: mustBeMainField || values.mainField
    })
      .then(() => {
        showAlert(t('Field updated'), 'success', 3000);
        queryClient.invalidateQueries([
          'companyFields',
          contactField.companyId,
          contactField.contactGroupId,
          [contactField.type]
        ]);

        emitCustomEvent(REFETCH_DATA_EVENT_NAME);
        tableRef?.current.refetchData();
        onClose();
      })
      .catch((error) => {
        showAlert(
          t(`Could not update field. ${getErrorMessage(error).errorMessage}`),
          'error',
          3000
        );
      });
  }

  function handleSubmit(values: ContactField) {
    if (mode === 'Create') {
      handleCreate(values);
    }
    if (mode === 'Update') {
      handleUpdate(values);
    }
  }

  useEffect(() => {
    getUserRoles({ companyId }).then((roleOptions) => {
      setUserRoleOptions(roleOptions);
    });
  }, []);

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <IconButtonWrapper
            sx={{
              m: 1
            }}
            size="small"
            color="secondary"
            onClick={onClose}
          >
            <CloseTwoToneIcon />
          </IconButtonWrapper>
        }
      />
      <Divider />
      <Formik<ContactField>
        initialValues={contactFieldToEdit}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <Stack height={'65vh'}>
              <Scrollbar>
                <Stack
                  direction={'column'}
                  spacing={2}
                  py={2}
                  px={2}
                  height="100%"
                >
                  <TextField
                    name="name"
                    id="name"
                    label={t('Name')}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="type"
                    id="type"
                    label={t('Type')}
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                  >
                    {fieldTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {ContactFieldTypeNames[type]}
                      </MenuItem>
                    ))}
                  </TextField>
                  <ContactGroupsSelector
                    contactGroupId={values.contactGroupId}
                    setContactGroupId={(contactGroupId) =>
                      setFieldValue('contactGroupId', contactGroupId)
                    }
                  />
                  {mustBeMainField && (
                    <Alert severity="info">
                      {t(`The first field of each type is the main field.`)}
                    </Alert>
                  )}
                  <Stack
                    direction="row"
                    justifyContent={'flex-start'}
                    alignItems={'baseline'}
                    flexWrap={'wrap'}
                    columnGap={3}
                  >
                    <Box>
                      <Stack direction="row" alignItems={'baseline'}>
                        <Switch
                          name="mainField"
                          id="mainField"
                          checked={mustBeMainField || values.mainField}
                          disabled={mustBeMainField}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          color="secondary"
                        />
                        <Typography variant="body1">
                          {t('Main field')}
                        </Typography>
                      </Stack>
                    </Box>
                    <Stack direction="row" alignItems={'baseline'}>
                      <Switch
                        name="required"
                        id="required"
                        disabled={mode === 'Update' && values.unique}
                        checked={values.required}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          //If unique is checked, required must be checked as well
                          setFieldValue('required', isChecked);
                          setFieldValue(
                            'unique',
                            !isChecked ? false : values.unique
                          );
                        }}
                        onBlur={handleBlur}
                        color="secondary"
                      />
                      <Typography variant="body1">{t('Required')}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems={'baseline'}>
                      <Switch
                        name="unique"
                        id="unique"
                        disabled={mode === 'Update'}
                        checked={values.unique}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          //If unique is checked, required must be checked as well
                          setFieldValue('unique', isChecked);
                          setFieldValue(
                            'required',
                            isChecked ? true : values.required
                          );
                        }}
                        onBlur={handleBlur}
                        color="secondary"
                      />
                      <Typography variant="body1">{t('Unique')}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems={'baseline'}>
                      <Switch
                        name="autoGenerated"
                        id="autoGenerated"
                        disabled={mode === 'Update'}
                        checked={values.autoGenerated}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="secondary"
                      />
                      <Typography variant="body1">
                        {t('Autogenerated')}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems={'baseline'}>
                      <Switch
                        name="pinned"
                        id="pinned"
                        checked={values.pinned}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="secondary"
                      />
                      <Typography variant="body1">{t('Pinned')}</Typography>
                    </Stack>
                  </Stack>

                  <Autocomplete
                    id="editableBy"
                    multiple
                    fullWidth
                    options={userRoleOptions.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )}
                    // @ts-ignore
                    getOptionLabel={(userRole) => userRole?.name}
                    value={userRoleOptions.filter((userRole) =>
                      values.editableBy?.includes(userRole.id)
                    )}
                    onChange={(_, values: UserRole[]) => {
                      const newUserRoleIds = values.map(
                        (userRole) => userRole.id
                      );
                      setFieldValue('editableBy', newUserRoleIds);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="roles"
                        label={t('Editable by')}
                        placeholder={t('Select roles...')}
                        helperText={t('Empty if editable by anyone')}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    fullWidth
                    options={userRoleOptions.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )}
                    // @ts-ignore
                    getOptionLabel={(userRole) => userRole?.name}
                    value={userRoleOptions.filter((userRole) =>
                      values.visibleTo?.includes(userRole.id)
                    )}
                    onChange={(_, values: UserRole[]) => {
                      const newUserRoleIds = values.map(
                        (userRole) => userRole.id
                      );
                      setFieldValue('visibleTo', newUserRoleIds);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="roles"
                        label={t('Visible to')}
                        placeholder={t('Select roles...')}
                        helperText={t('Empty if visible to anyone')}
                      />
                    )}
                  />
                </Stack>
              </Scrollbar>
              <Divider />
              <Stack
                px={1.5}
                py={1.5}
                width="100%"
                direction={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent={'space-between'}
                spacing={1.5}
              >
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="secondary"
                  id="createFields-cancel-btn"
                >
                  {t('Cancel')}
                </Button>
                <PermissionsTooltip hasAccess={userCanEdit}>
                  <Button
                    id="createFields-create-btn"
                    disabled={
                      isSubmitting ||
                      !values.contactGroupId ||
                      !hasAccess(
                        mode === 'Create'
                          ? checkCreateContactFieldsPermissions
                          : checkUpdateContactFieldsPermissions
                      )
                    }
                    startIcon={isSubmitting && <CircularProgress size={16} />}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {t(mode)}
                  </Button>
                </PermissionsTooltip>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Card>
  );
};
