import axios from 'axios';
import { queryClient } from 'src/utils/queryClient';
import { TicketTag } from '../../models/tickets';
import { TICKET_TAGS } from 'src/utils/apiRoutes/msMessaging';

export const createTicketTagApi = async (
  ticketTag: TicketTag
): Promise<TicketTag> => {
  const resp = await axios.post(TICKET_TAGS, ticketTag);

  queryClient.invalidateQueries({
    queryKey: ['ticketTags']
  });

  return resp.data;
};
