import { UserRole } from 'src/models/permissionGroups';
import {
  TicketField,
  TicketFieldOperation,
  TicketFieldVisibilityDependency,
  TicketFieldsValues
} from '../../models/tickets';
import { TicketFieldVisibilityDependencyExtendedArray } from '../../models/TicketFieldVisibilityDependencyExtendedArray';

export const checkEditableVisibleTicketField = (
  ticketField: TicketField,
  userRoles: UserRole[]
): [isEditable: boolean, isVisible: boolean] => {
  if (!userRoles || userRoles?.length === 0) return [false, false];
  const isEditable =
    ticketField.editableBy.length === 0 ||
    ticketField.editableBy.some((id) =>
      userRoles.some((role) => role.id === id)
    );
  const isVisible =
    ticketField.visibleTo.length === 0 ||
    ticketField.visibleTo.some((id) =>
      userRoles.some((role) => role.id === id)
    );
  return [isEditable, isVisible];
};

export const checkVisibilityDependenciesTicketField = (
  ticketField: TicketField,
  ticketFieldValues: TicketFieldsValues
) => {
  const visibilityDependencies =
    new TicketFieldVisibilityDependencyExtendedArray(
      ticketField.visibilityDependencies
    );

  return (
    visibilityDependencies.length === 0 ||
    visibilityDependencies.someEvery(ticketField, (dependency) =>
      checkVisibilityDependencyTicketField(dependency, ticketFieldValues)
    )
  );
};

export const checkVisibilityDependencyTicketField = (
  dependency: TicketFieldVisibilityDependency,
  ticketFieldValues: TicketFieldsValues
) => {
  if (!ticketFieldValues) return false;

  switch (dependency.operation) {
    case TicketFieldOperation.NOT_NULL:
      const value = ticketFieldValues?.[dependency.fieldId]?.value;
      switch (typeof value) {
        case 'string':
          return value;
        case 'boolean':
          // considers false as null
          return value;
        case 'object':
          return value.length !== 0;
        case 'undefined':
          return false;
      }
    default:
      return false;
  }
};

/**
 * Check if the ticket field is visible and editable due to user roles added to editableBy and visible to props
 * and check if the ticket field is visible due to the ticket field visibility dependencies
 */
export const isVisibleEditableTicketField = (
  ticketField: TicketField,
  ticketFieldValues: TicketFieldsValues,
  userRoles: UserRole[]
): [isEditable: boolean, isVisible: boolean] => {
  const [isEditable, visible] = checkEditableVisibleTicketField(
    ticketField,
    userRoles
  );
  const visibility = checkVisibilityDependenciesTicketField(
    ticketField,
    ticketFieldValues
  );
  const isVisible = visible && visibility;

  return [isEditable, isVisible];
};
