import { queryClient } from 'src/utils/queryClient';
import {
  UsersParams,
  getUsersApi
} from '../infraestructure/api/users/getUsers';

export const usersQueryKey = 'users-query';

function getUsersQuery(params: UsersParams) {
  return queryClient.fetchQuery({
    queryKey: [usersQueryKey, ...Object.values(params)],
    queryFn: () => getUsersApi(params),
    staleTime: 1000 * 60 * 5
  });
}

export const invalidateUsersQuery = () => {
  queryClient.invalidateQueries({
    queryKey: [usersQueryKey]
  });

  queryClient.refetchQueries({
    queryKey: [usersQueryKey]
  });
};

export default getUsersQuery;
