import { RTUser } from 'src/models/rtUser';
import { RTCall } from 'src/models/rtcall';
import { getConnectedRTUser } from '../rtuser/getConnectedRTUser';

import { logger } from 'src/utils/logger';
import { getRTcalls } from '../../../rtcalls/domain/getRTCalls';
import { getRTUser } from '../rtuser/getRTUser';
import { getRTUsers } from '../rtuser/getRTUsers';
import { updateRTUser } from '../rtuser/updateRTUser';
import { getMissedCallsCountDomain } from './getMissedCallsCountDomain';
import { getMissedCallsDomain } from './getMissedCallsDomain';
import { setMissedCallsCountDomain } from './setMissedCallsCountDomain';
import { setMissedCallsDomain } from './setMissedCallsDomain';
import { selectRTCallConversation } from 'src/services/socialmedia/domain/conversation/selectProperConversation';

/** ADD RTCALL TO RTUSER
 *
 */
export const addRTCallToRTUsers = (rtCall: RTCall) => {
  // Find RTUser owner of the call
  let rtuser = getRTUser({ rtCall, shouldUseHook: false });
  if (!rtuser && rtCall.status !== 'Conference') {
    selectConversation(rtCall);
    return logger.error("Couldn't find any user for this RTCall: ", rtCall);
  }

  // Update RTUser with new RTCall
  if (rtuser) {
    updateRTuserCall(rtCall, rtuser);
  }

  //Also add the call to the other users in the conference
  if (rtCall.status === 'Conference') {
    updateUsersInConference(rtCall);
  }

  if (rtCall.previousStatus === 'Conference') {
    const updatedRtuser = {
      ...rtuser,
      calls: [
        ...rtuser.calls
          .filter((call) => call.callId !== rtCall.callId)
          .concat(rtCall)
      ]
    } as RTUser;
    updateRTUser(updatedRtuser);
  }

  // Select Conversation if attended
  selectConversation(rtCall);
  // Handle missed calls count
  handleMissedCall(rtCall);
};

function updateUsersInConference(rtCall: RTCall) {
  //Get all users in the conference with the same callId
  const rtusers = getRTUsers({ callId: rtCall.callId, shouldUseHook: false });

  rtusers.forEach((rtuser) => {
    // Add all the conference calls to the new user
    if (rtuser.id === rtCall.userId) {
      const allConferenceCalls = getRTcalls({
        shouldUseHook: false,
        callId: rtCall.callId
      });

      updateRTuserCalls(allConferenceCalls, rtuser);
      return;
    }
    //Add call to the users that are already in the conference
    updateRTuserCall(rtCall, rtuser);
  });
}

function updateRTuserCalls(rtCalls: RTCall[], rtUser: RTUser) {
  const updatedRtuser = rtCalls.reduce((acc, rtCall) => {
    return insertCallInUser(acc, rtCall);
  }, rtUser);
  updateRTUser(updatedRtuser);
}

function updateRTuserCall(rtCall: RTCall, rtUser: RTUser) {
  const updatedRtuser = insertCallInUser(rtUser, rtCall);
  updateRTUser(updatedRtuser);
}

function insertCallInUser(rtUser: RTUser, rtCall: RTCall) {
  return {
    ...rtUser,
    calls: [
      ...rtUser.calls.filter((call) => call.id !== rtCall.id).concat(rtCall)
    ]
  } as RTUser;
}

function handleMissedCall(rtCall: RTCall) {
  const allowedStatus = ['Ringing', 'Talking'];
  const isAllowedStatus = allowedStatus.includes(rtCall.status);
  if (!isAllowedStatus) return;

  const missedCallsCount = getMissedCallsCountDomain(false);
  setMissedCallsCountDomain(
    missedCallsCount.filter((call) => call.callId !== rtCall.callId)
  );

  const missedCalls = getMissedCallsDomain(false);
  if (missedCalls.some((call) => call.callId === rtCall.callId)) {
    setMissedCallsDomain(
      missedCalls.filter((call) => call.callId !== rtCall.callId)
    );
  }
}

function selectConversation(rtCall: RTCall) {
  if (rtCall.status !== 'Talking') return;

  const currentRTUser = getConnectedRTUser(false);
  if (!currentRTUser) return;

  const userComplexExtension = currentRTUser.extension?.complexExtension;
  const rtCallCurrentEndpoint = rtCall.currentEndpoint?.endpoint;
  const rtCallLinkedEndpoint = rtCall.linkedEndpoint?.endpoint;

  const isFromConnectedUser = rtCallCurrentEndpoint === userComplexExtension;
  const isToConnectedUser = rtCallLinkedEndpoint === userComplexExtension;
  const userHasExtension = !!userComplexExtension;

  if (!userHasExtension) return;
  if (!isFromConnectedUser && !isToConnectedUser) return;

  setTimeout(() => selectRTCallConversation(rtCall), 500);
}
