import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { ScheduleCalendar } from '../createScheduleCalendar';
import { queryClient } from 'src/utils/queryClient';
import { schedulesQueryKey } from '../domain/getSchedulesCalendar';
import { SCHEDULE_CALENDAR_ID } from 'src/utils/apiRoutes/msCallSchedule';

/*PUT scheduleCalendar*/
export const updateScheduleCalendar = async (
  scheduleCalendar: ScheduleCalendar
) => {
  const path = replaceURLParams(SCHEDULE_CALENDAR_ID, {
    '{callScheduleId}': scheduleCalendar.id
  });
  const response = await axios.put(path, scheduleCalendar);
  queryClient.invalidateQueries({
    queryKey: [schedulesQueryKey]
  });
  return response.data as ScheduleCalendar;
};
