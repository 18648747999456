import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { WhatsAppTemplateVariables } from 'src/services/whatsapp/models/WhatsAppTemplate';
import { getWhatsAppTemplateParamsValidationSchema } from '../utils/getWhatsAppTemplateParamsSchema';

interface Props {
  templateParams: WhatsAppTemplateVariables;
  onChange?: (
    values: WhatsAppTemplateVariables,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSubmit?: (
    values: WhatsAppTemplateVariables,
    formikHelpers: FormikHelpers<WhatsAppTemplateVariables>
  ) => Promise<void>;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const WhatsAppTemplateParamsForm = (props: Props) => {
  const { templateParams, onChange, onSubmit, onClose } = props;
  const { t }: { t: any } = useTranslation();

  const validationSchema =
    getWhatsAppTemplateParamsValidationSchema(templateParams);
  const formik = useFormik<WhatsAppTemplateVariables>({
    initialValues: templateParams,
    onSubmit: (values, formikHelpers) => {
      onSubmit?.(values, formikHelpers);
    },
    validationSchema
  });
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting
  } = formik;

  const onChangeTemplateParams = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChange(event);
    const newValues: WhatsAppTemplateVariables = {
      ...values,
      [event.target.name]: event.target.value
    };
    onChange?.(newValues, event);
  };

  const templateParamsKeys = Object.keys(templateParams);

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <Stack spacing={2} overflow="scroll" py={1}>
        {templateParamsKeys.map((key) => (
          <Stack direction="row" key={key} alignItems="baseline" spacing={1}>
            <Typography variant={'h5'}>{key}</Typography>
            <TextField
              name={key}
              fullWidth
              required
              placeholder={t('Enter variable value')}
              error={Boolean(touched[key] && errors[key])}
              helperText={touched[key] && errors[key]}
              onBlur={handleBlur}
              value={values[key]}
              onChange={onChangeTemplateParams}
              size="small"
            />
          </Stack>
        ))}
      </Stack>

      {(!!onClose || !!onSubmit) && (
        <Stack
          direction="row"
          mt={3}
          justifyContent={!!onClose && !!onSubmit ? 'space-between' : 'end'}
        >
          {!!onClose && (
            <Button variant="outlined" color="secondary" onClick={onClose}>
              <Typography>{t('Close')}</Typography>
            </Button>
          )}
          {!!onSubmit && (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={!isValid || isSubmitting}
            >
              <Typography>{t('Save')}</Typography>
            </Button>
          )}
        </Stack>
      )}
    </form>
  );
};
