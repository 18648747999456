import { useContext, useEffect, useRef, useState } from 'react';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { VideoCallParticipant } from 'src/modules/VideoCall/types/videoCallTypes';

export function useAudioLevel(stream: MediaStream) {
  const { devices, streams } = useContext(VideocallRoomContext);
  const selectedStream = streams.selectedStreamId;

  const [audioLevel, setAudioLevel] = useState(0);
  const audioContextRef = useRef<AudioContext>(null);
  const analyserRef = useRef<AnalyserNode>(null);

  function createAudioAnalyzer() {
    if (!stream) {
      return;
    }
    const audioTracks = stream.getAudioTracks();
    if (audioTracks.length === 0) {
      requestAnimationFrame(createAudioAnalyzer);
      return;
    }

    audioContextRef.current = new AudioContext();
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = 2048;

    const source = audioContextRef.current.createMediaStreamSource(stream);
    source.connect(analyserRef.current);

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    // Función para actualizar el nivel de audio
    const updateAudioLevel = () => {
      analyserRef.current.getByteFrequencyData(dataArray);
      let sum = 0;
      for (let i = 0; i < bufferLength; i++) {
        sum += dataArray[i];
      }
      const average = sum / bufferLength;

      // Normaliza el nivel de audio al rango 0-1
      const normalizedLevel = average / 255;

      setAudioLevel(normalizedLevel);
      requestAnimationFrame(updateAudioLevel);
    };

    updateAudioLevel();
  }

  useEffect(() => {
    // Crea un AudioContext
    setTimeout(() => {
      createAudioAnalyzer();
    }, 500);

    // Limpia al desmontar el componente
    return () => {
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== 'closed'
      ) {
        audioContextRef.current.close();
      }
    };
  }, [
    devices.microphoneDeviceId,
    stream?.getAudioTracks().length,
    selectedStream
  ]);

  return audioLevel;
}
