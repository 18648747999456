import { MicOffTwoTone } from '@mui/icons-material';
import { Grid, IconButton, Stack, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { TransferModal } from '../TransferModal/TransferModal';

export const TransferButton: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={6} // There are 12 columns and this component should occupy 50%
      sx={{
        backgroundColor: theme.colors.warning.lighter
      }}
    >
      <Stack alignItems={'center'} justifyContent={'center'}>
        <TransferModal />
      </Stack>
    </Grid>
  );
};
