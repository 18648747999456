import { ChannelType } from 'src/models/conversations/conversations';
import { Features } from '../../../modules/Admin/modules/General/modules/Companies/models/CompanyFeatures';

// AI Rules
export function checkKnowledgeModelsFeature(features: Features) {
  return features.aiRules.knowledgeModels;
}

export function checkNluFeature(features: Features) {
  return features.aiRules.nlu;
}

export function checkSpeechAnalyticsFeature(features: Features) {
  return features.aiRules.speechAnalytics;
}

// Channel
export function getChannelFeatureChecker(channel: ChannelType) {
  const channelCheckerObject: Record<
    ChannelType,
    (features: Features) => boolean
  > = {
    Call: checkCallChannelFeature,
    Email: checkEmailChannelFeature,
    Instagram: checkInstagramChannelFeature,
    Telegram: checkTelegramChannelFeature,
    WebChat: checkWebChatChannelFeature,
    WhatsApp: checkWhatsAppChannelFeature
  };
  return channelCheckerObject?.[channel];
}

// Call Channel
export function checkCallChannelFeature(features: Features) {
  return features.callChannel;
}

// Digital Channel
export function checkEmailChannelFeature(features: Features) {
  return features.digitalChannel.email;
}

export function checkInstagramChannelFeature(features: Features) {
  return features.digitalChannel.instagram;
}

export function checkTelegramChannelFeature(features: Features) {
  return features.digitalChannel.telegram;
}

export function checkWebChatChannelFeature(features: Features) {
  return features.digitalChannel.webChat;
}

export function checkWhatsAppChannelFeature(features: Features) {
  return features.digitalChannel.whatsApp;
}

// External Action
export function checkExternalActionFeature(features: Features) {
  return features.externalAction;
}

// Massive Communication
export function checkMassiveEmailFeature(features: Features) {
  return features.massive.email;
}

export function checkMassiveSmsFeature(features: Features) {
  return features.massive.sms;
}

export function checkMassiveWhatsAppFeature(features: Features) {
  return features.massive.whatsApp;
}

// Roles and Permissions
export function checkRolesAndPermsFeature(features: Features) {
  return features.rolesAndPerms;
}
