import { MouseEvent, useContext } from 'react';

import {
  Clear,
  Phone,
  Telegram,
  Videocam,
  WhatsApp,
  Instagram,
  Chat,
  EmailOutlined
} from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import '../styles.css';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkViewConversationPermissions,
  checkViewConversationSummaryPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { TicketConversationSummary } from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import { isVideoCallConversation } from 'src/modules/VideoCall/utils/isVideoCallConversation';

interface ListItemProps {
  disableOnclick?: boolean;
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
}

interface ConversationListItemProps extends ListItemProps {
  conversation: Conversation;
  setConversation?: (conversation: Conversation | null) => void;
  conversationSummary?: never;
  setConversationSummary?: never;
}

interface ConversationSummaryListItemProps extends ListItemProps {
  conversationSummary: TicketConversationSummary;
  setConversationSummary?: (
    conversation: TicketConversationSummary | null
  ) => void;
  conversation?: never;
  setConversation?: never;
}

type Props = ConversationListItemProps | ConversationSummaryListItemProps;

export const ConversationListItem = ({
  conversation,
  conversationSummary,
  setConversation,
  setConversationSummary,
  disableOnclick,
  onClose
}: Props) => {
  const { hasAccess } = useContext(PermissionsContext);
  const hasViewConversationAccess =
    (conversation &&
      hasAccess(checkViewConversationPermissions(conversation))) ||
    (conversationSummary &&
      hasAccess(checkViewConversationSummaryPermissions(conversationSummary)));

  const creationDate =
    conversation?.metadata?.creationDate ?? conversationSummary.creationDate;
  const date = new Date(creationDate);
  //get time from date in format hh:mm
  const time = date.toLocaleTimeString('es-ES', {
    hour: 'numeric',
    minute: 'numeric'
  });

  const { i18n, t } = useTranslation();
  const theme = useTheme();

  const channel = conversation?.channel ?? conversationSummary?.channel;
  const status = conversation?.status ?? conversationSummary?.status;

  // PROVISIONAL: Need to add a prop to ConversationSummary entity to be able to check if it is a videocall
  const isVideoCall = conversation && isVideoCallConversation(conversation);

  const getChannelIcon = () => {
    switch (channel) {
      case ChannelType.TELEGRAM:
        return <Telegram color="secondary" fontSize="small" />;
      case ChannelType.EMAIL:
        return <EmailOutlined fontSize="small" />;
      case ChannelType.WHATSAPP:
        return <WhatsApp color="success" />;
      case ChannelType.WEBCHAT:
        return <Chat color="warning" />;
      case ChannelType.INSTAGRAM:
        return <Instagram sx={{ color: '#c13584' }} />;
      default:
        if (isVideoCall) return <Videocam color="error" fontSize="small" />;
        return <Phone fontSize="small" />;
    }
  };

  const icon = getChannelIcon();

  const CloseIconButton = (
    <Tooltip arrow placement="top" title={t('Unassign conversation')}>
      <IconButton
        className="close-button"
        aria-label={t('Unassign conversation')}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onClose(e);
        }}
        sx={{
          color: 'white',
          backgroundColor: '#b7becb',
          borderRadius: 2,
          ':hover': {
            backgroundColor: '#b7becb'
          },
          position: 'absolute',
          zIndex: 10,
          right: '-13px',
          top: '-13px',
          visibility: 'hidden'
        }}
      >
        <Clear
          //@ts-ignore
          fontSize="8px"
        />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box
      className="conversation-list-item"
      width={'80%'}
      sx={{
        '&:hover': {
          background: theme.colors.primary.lighter
        },
        cursor:
          disableOnclick || !hasViewConversationAccess ? 'default' : 'pointer'
      }}
      px={1}
      py={1}
      borderRadius={1}
      onClick={() => {
        if (!disableOnclick && hasViewConversationAccess) {
          if (setConversation) setConversation(conversation);
          if (setConversationSummary)
            setConversationSummary(conversationSummary);
        }
      }}
      position="relative"
    >
      {onClose && CloseIconButton}
      <Stack direction="row" spacing={3}>
        <Box
          flexDirection={'column'}
          alignItems={'center'}
          sx={{ background: '#f6f7f8' }}
          borderRadius={1}
          px={2}
          py={1}
          position={'relative'}
        >
          <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
            {creationDate ? date?.getUTCDate() : 'DD'}
          </div>
          <div
            style={{
              fontSize: '12px',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}
          >
            {creationDate
              ? date?.toLocaleString(i18n.language, { month: 'short' })
              : 'mmm'}
          </div>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              position: 'absolute',
              top: -15,
              right: -15,
              background: '#FFF6E8',
              borderRadius: 50
            }}
            p={0.5}
          >
            {icon}
          </Stack>
        </Box>
        <Box>
          <Stack direction="column" justifyContent={'center'} height={'100%'}>
            <label
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: theme.colors.primary.main,
                textOverflow: 'ellipsis'
              }}
            >
              {channel !== 'Call' || conversationSummary
                ? channel
                : t(conversation?.originAgent?.orientation)}
            </label>
            <label>{t(status === 'Assigned' ? 'Opened' : status)}</label>
            <label> {creationDate ? time : 'HH:MM'}</label>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
