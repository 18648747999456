import { getHours, getMinutes } from 'date-fns';
import { Call, CallTableColumns } from 'src/models/call';

export const getDateDDmmmYYYY = (dateFormat: string): string => {
  const date = new Date(dateFormat);
  let mmm = new Intl.DateTimeFormat('es-ES', { month: 'short' }).format(date);
  let yyyy = date.getFullYear();
  let dd = date.getDate();

  const formattedDate = dd + ' ' + mmm + ' ' + yyyy;

  return formattedDate;
};

export const getTimeHHmm = (dateFormat: string): string => {
  const date = new Date(dateFormat);
  let hh = getHours(date);
  let mm = getMinutes(date);

  const formattedTime = hh + ':' + mm;

  return formattedTime;
};

export const transfomConversation = async (
  calls: Call[]
): Promise<CallTableColumns[]> => {
  let callsTable: CallTableColumns[] = []; // CONTAINER OUTPUT

  for (const call of calls) {
    let conversationTable = call as CallTableColumns;
    conversationTable = {
      ...conversationTable,
      creationDate: getDateDDmmmYYYY(call.metadata.creationDate),
      modificationDate: getDateDDmmmYYYY(call.metadata.modificationDate)
    };

    // PUSH ARRAY CALL TRANSFORMED
    //callsTable.push(callsTable);
  }
  return callsTable;
};
