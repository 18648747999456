import { useSnackbar } from 'notistack';
import { DownlaodReportAlert } from 'src/components/DownloadReportAlert';

export const useDownloadSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const renderContent = (
    reportingAttachmentId: string,
    filename?: string,
    isError?: boolean
  ) => (
    <DownlaodReportAlert
      reportingAttachmentId={reportingAttachmentId}
      filename={filename}
      isError={isError}
      onClose={(e, reportingAttachmentId) => {
        closeSnackbar(reportingAttachmentId);
      }}
    />
  );

  const enqueueDownloadSnackbar = (
    reportingAttachmentId: string,
    filename?: string,
    isError?: boolean
  ) => {
    enqueueSnackbar(null, {
      key: reportingAttachmentId,
      variant: 'default',
      persist: true,
      preventDuplicate: true,
      // At the top center, the alerts from showAlert are already displayed
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      content: renderContent(reportingAttachmentId, filename, isError)
    });
  };
  return { enqueueDownloadSnackbar };
};
