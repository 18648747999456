import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  Stack,
  styled,
  TextField,
  useTheme
} from '@mui/material';
import { useContext, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'src/hooks/useAlert';
import { ContactTag } from 'src/modules/Contacts/models/contacts';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { ContactsDomain } from 'src/modules/Contacts/domain/ContactsDomain';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkUpdateContactGroupPermissions,
  checkUpdateContactTagsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

interface Props {
  onClose: () => void;
  tag: ContactTag | undefined;
}

export const UpdateTagModal: React.FC<Props> = ({ onClose, tag }) => {
  const { hasAccess } = useContext(PermissionsContext);
  const hasTagdAccess =
    hasAccess(checkUpdateContactGroupPermissions) &&
    hasAccess(checkUpdateContactTagsPermissions);

  const theme = useTheme();
  const { t } = useTranslation();
  const [name, setName] = useState(tag?.name);
  const { showAlert } = useAlert();

  async function handleUpdate() {
    ContactsDomain.updateTag(tag.id, name)
      .then(() => {
        showAlert(t('Tag updated'), 'success', 3000);
        onClose();
        emitCustomEvent(REFETCH_DATA_EVENT_NAME);
      })
      .catch((error) => {
        showAlert(
          t(`Could not update tag. ${getErrorMessage(error).errorMessage}`),
          'error',
          3000
        );
      });
  }

  const title = t('Update tag');
  const subheader = t('Use this dialog window to update a tag');

  return (
    <>
      <Popover
        open={tag !== undefined}
        onClose={onClose}
        anchorEl={document.getElementById('root')}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        <Card>
          <CardHeader
            title={title}
            subheader={subheader}
            action={
              <IconButtonWrapper
                sx={{
                  m: 1
                }}
                size="small"
                color="secondary"
                onClick={onClose}
              >
                <CloseTwoToneIcon />
              </IconButtonWrapper>
            }
          />
          <Divider />
          <CardContent
            sx={{
              p: 0,
              '&.MuiCardContent-root': {
                paddingBottom: 0
              }
            }}
          >
            <Stack direction={'column'} spacing={2} py={2} px={2}>
              <TextField
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                id="updateFields-name"
                label={t('Name')}
              ></TextField>
            </Stack>
            <Divider />
            <Stack
              px={1.5}
              py={1.5}
              width="100%"
              direction={{ xs: 'column-reverse', sm: 'row' }}
              justifyContent={'space-between'}
              spacing={1.5}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                color="secondary"
                id="updateTag-cancel-btn"
              >
                {t('Cancel')}
              </Button>
              <PermissionsTooltip hasAccess={hasTagdAccess}>
                <Button
                  id="updateTag-update-btn"
                  disabled={!hasTagdAccess}
                  onClick={() => {
                    handleUpdate();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {t('Update')}
                </Button>
              </PermissionsTooltip>
            </Stack>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};
