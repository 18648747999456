import axios from 'axios';
import packageJson from 'src/../package.json';
import { logger } from './logger';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { replaceURLParams } from 'src/services/utils';
import {
  getCompanyShortCode,
  getSplitHostname
} from 'src/services/authentication/domain/login';

export async function getRemoteAppVersion() {
  const companyShortcode = getCompanyShortCode();
  const baseUrl = process.env.REACT_APP_FRONTEND_BASE_URL;
  const versionUrl = `${baseUrl}/static/version.json`;
  const path = replaceURLParams(versionUrl, {
    '{companyShortcode}': companyShortcode
  });
  const { data } = await axios.get(path);
  const remoteAppVersion: string = data?.version;
  return remoteAppVersion;
}

export function getLocalAppVersion() {
  return packageJson?.version;
}

export async function checkAppVersion() {
  try {
    const localAppVersion = getLocalAppVersion();
    const companyShortCode = getSplitHostname()?.[0];

    if (companyShortCode === 'localhost')
      return {
        isLastVersion: true,
        localAppVersion,
        remoteAppVersion: localAppVersion
      };

    const remoteAppVersion = await getRemoteAppVersion();
    return {
      isLastVersion: localAppVersion === remoteAppVersion,
      localAppVersion,
      remoteAppVersion
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error)?.errorMessage;
    logger.error(`Error checking app version. ${errorMessage}`);
    return {
      isLastVersion: true,
      localAppVersion: undefined,
      remoteAppVersion: undefined
    };
  }
}
