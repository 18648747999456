import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { TicketTag } from '../../models/tickets';
import { TICKET_TAGS, TICKET_TAGS_ID } from 'src/utils/apiRoutes/msMessaging';

export interface TicketTagsFilters {
  companyId: string;
}

export const getTicketTagsApi = async (
  filters: TicketTagsFilters
): Promise<TicketTag[]> => {
  const params = filters;
  const resp = await axios.get(TICKET_TAGS, { params });

  return resp.data;
};

export const getTicketTagsByIdApi = async (id: string): Promise<TicketTag> => {
  const path = replaceURLParams(TICKET_TAGS_ID, {
    '{id}': id
  });
  const resp = await axios.get(path);
  return resp.data;
};
