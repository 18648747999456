const esJSON = {
  NONDISPLAYEDNAME: '',
  Blueprints: 'Planos',
  'Extended Sidebar': 'Barra lateral extendida',
  'Accent Sidebar': 'Barra lateral de acento',
  'Accent Header': 'Encabezado de acento',
  'Boxed Sidebar': 'Barra lateral en caja',
  'Collapsed Sidebar': 'Barra lateral contraída',
  'Bottom Navigation': 'Navegación inferior',
  'Top Navigation': 'Navegación superior',
  'Lead Developer': 'Desarrollador principal',
  'Mega menu': 'Mega menú',
  Doctors: 'Doctores',
  Reports: 'Informes',
  Recipients: 'Destinatarios',
  'New email from': 'Nuevo email de',
  'New Telegram message from': 'Nuevo mensaje de Telegram de',
  'Prompt deleted successfully': 'Prompt eliminado con éxito',
  'Prompt created successfully': 'Prompt creado con éxito',
  'Prompt updated successfully': 'Prompt actualizado con éxito',
  'Prompts deleted successfully': 'Prompts eliminados con éxito',
  'Prompts created successfully': 'Prompts creados con éxito',
  'Prompts updated successfully': 'Prompts actualizados con éxito',
  'These are the created prompts to be used in inbound routes':
    'Estos son los prompts creados para ser usados en rutas entrantes',
  'Delete question group': 'Borrar grupo de preguntas',
  'Delete questions groups': 'Borrar grupos de preguntas',
  Synchronize: 'Sincronizar',
  'Custom dashboard built using the included components':
    'Panel de control personalizado construido con los componentes incluidos',
  Hospital: 'Hospital',
  Export: 'Exportar',
  'Change status': 'Cambiar estado',
  'Last year': 'El año pasado',
  Expenses: 'Gastos',
  'Default subtypology': 'Subtipología por defecto',
  'Select a subtypology to create tickets automatically':
    'Selecciona una subtipología para crear tickets automáticamente',
  Products: 'Productos',
  Statistics: 'Estadísticas',
  General: 'General',
  Automation: 'Automatización',
  'Data Display': 'Visualización de datos',
  Calendar: 'Calendario',
  Mailbox: 'Buzón',
  Dashboards: 'Cuadros de mando',
  Analytics: 'Analítica',
  Banking: 'Bancario',
  Commerce: 'Comercio',
  Crypto: 'Cripto',
  Finance: 'Finanzas',
  Fitness: 'Aptitud física',
  Healthcare: 'Cuidado de la salud',
  'Doctors Page': 'Página de médicos',
  'Hospital Overview': 'Descripción del hospital',
  Helpdesk: 'Mesa de ayuda',
  Learning: 'Aprendiendo',
  Monitoring: 'Supervisión',
  Tasks: 'Tareas',
  Applications: 'Aplicaciones',
  'File Manager': 'Administrador de archivos',
  'Jobs Platform': 'Plataforma de trabajos',
  Messenger: 'Mensajero',
  'Projects Board': 'Junta de proyectos',
  Management: 'Administración',
  Users: 'Usuarios',
  'is required': 'es requerido',
  'Users:': 'Usuarios:',
  'List All': 'Listar todo',
  'User Profile': 'Perfil del usuario',
  Projects: 'Proyectos',
  Shop: 'Tienda',
  'All aspects related to the contact reports can be managed here':
    'Todos los aspectos relacionados con los reportes de contactos se pueden gestionar desde aquí',
  'Products List': 'Lista de productos',
  'View Product': 'Ver el producto',
  'Create Product': 'Crear producto',
  Invoices: 'Facturas',
  'View Single': 'Ver individual',
  'Auth Pages': 'Autenticación',
  LoginSession: 'Inicia sesión',
  Basic: 'Básico',
  Cover: 'Cubrir',
  Register: 'Registrarse',
  Wizard: 'Mago',
  'Recover Password': 'Recuperar contraseña',
  Status: 'Estado',
  Foundation: 'Fundación',
  'Extra Pages': 'Páginas extra',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Próximamente',
  Maintenance: 'Mantenimiento',
  Overview: 'Visión general',
  'Layout Starters': 'Arrancadores de diseño',
  'Starter Kit 1': 'Kit de inicio 1',
  'Starter Kit 2': 'Kit de inicio 2',
  Documentation: 'Documentación',
  Welcome: 'Bienvenida',
  Help: 'Ayuda',
  'Features tour': 'Tour de características',
  'Getting started guide': 'Guía de inicio',
  'Contact support': 'Soporte de contacto',
  Version: 'Versión',
  Search: 'Buscar',
  Notifications: 'Notificaciones',
  Settings: 'Ajustes',
  'Language Switcher': 'Selector de idioma',
  'Sign out': 'Desconectar',
  'Sign in': 'Iniciar sesion',
  'Start being productive': 'Empieza a ser productivo',
  User: 'Usuario',
  Password: 'Contraseña',
  'Change password': 'Cambiar contraseña',
  'Without extension': 'Sin extensión',
  'Have a problem ?': '¿Tienes un problema?',
  'Click here': 'Click aquí',
  'Something went wrong: Check your user or password':
    'Algo salio mal: Verifica tu usuario o contraseña',
  'Fill in the fields below to sign into your account.':
    'Completa los campos para iniciar sesion en tu cuenta.',
  'Change Theme': 'Cambiar de tema',
  'View all notifications': 'Ver todas las notificaciones',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Panel de administración de Tokyo React',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
  'Browse Live Preview': 'Explorar vista previa en vivo',
  'Live Preview': 'Vista previa en vivo',
  'Key Features': 'Características clave',
  'ACD field': 'Campo del ACD',
  'Active participants': 'Participantes activos',
  dashboards: 'cuadros de mando',
  applications: 'aplicaciones',
  'management sections': 'secciones de gestión',
  'dark/light themes': 'temas oscuros / claros',
  components: 'componentes',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
  'Design Source Files': 'Diseñar archivos fuente',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
  'We only translated a small part of the template, for demonstration purposes':
    'Solo traducimos una pequeña parte de la plantilla, con fines de demostración',
  'Show contact information': 'Mostrar información del contacto',
  'Accept call': 'Aceptar llamada',
  'Reject call': 'Rechazar llamada',
  'Reschedule call': 'Reagendar llamada',
  Asigned: 'Asignadas',
  Queue: 'Cola',
  Queues: 'Colas',
  Calls: 'Llamadas',
  Attend: 'Atender',
  Routes: 'Rutas',
  Interactions: 'Interacciones',
  Election: 'Eleccion',
  Comprobations: 'Comprobaciones',
  Method: 'Método',
  Operations: 'Operaciones',
  Integrations: 'Integraciones',
  'Contact Center': 'Contact Center',
  Quality: 'Calidad',
  Contacts: 'Contactos',
  'In Firefox you can not change output':
    'En Firefox no se puede cambiar el altavoz',
  'General Settings': 'General',
  Actions: 'Acciones',
  Companies: 'Empresas',
  Assigned: 'Asignadas',
  Create: 'Crear',
  Update: 'Actualizar',
  'No Options': 'Sin Opciones',
  'You have not permissions to upload questions':
    'No tienes permissions para subir preguntas',
  'Internal Chat': 'Chat Interno',
  'Configure Web Client': 'Configurar Cliente Web',
  'Events Panel': 'Panel de eventos',
  'FTP Servers': 'Servidores FTP',
  'Web Events': 'Eventos Web',
  'Create service': 'Crear servicio',
  'View services': 'Ver Servicios',
  'Create showflow': 'Crear ShowFlow',
  'View ShowFlows': 'Ver ShowFlows',
  'View showflows': 'Ver ShowFlows',
  Tipology: 'Tipologías',
  Comments: 'Comentarios',
  'API Methods': 'Metodos API',
  'Api methods': 'Metodos API',
  Webservices: 'Servicios Web',
  'API Tokens': 'Llaves API',
  'Api tokens': 'Llaves API',
  'Create User': 'Crear Usuario',
  'View Users': 'Ver Usuarios',
  Skills: 'Habilidades',
  'Roles and permissions': 'Roles y permisos',
  'Agent groups': 'Grupos de Agentes',
  'Agent states': 'Estados de Agentes',
  Patterns: 'Patrones',
  'Schedule groups': 'Grupos Agendados',
  'Voice messages': 'Mensajes de Voz',
  'Showflow created': 'Guión creado',
  'Showflow updated': 'Guión actualizado',
  Task: 'Tarea',
  'View Contacts': 'Ver contactos',
  'Import CSV': 'Importar CSV',
  Blacklist: 'Lista Negra',
  'Creating user': 'Creando usuario',
  'No Calls': 'Sin Llamadas',
  'Contact Data': 'Datos de contacto',
  'Conversations History': 'Historial de conversaciones',
  'Entity model': 'Modelo de entidad',
  'Template variables': 'Variables de plantilla',
  'Intention model': 'Modelo de intención',
  'Intention Models': 'Modelos de Intención',
  'Call Schedule': 'Calendario de llamadas',
  'Assigned user is required': 'El usuario asignado es requerido',
  'Chose model': 'Elegir modelo',
  'Template media': 'Adjuntos de la plantilla',
  'Variables order': 'Orden de las variables',
  'No added contact': 'No hay ningún contacto añadido',
  Enabled: 'Activo',
  Disabled: 'Inactivo',
  Notes: 'Notas',
  'Add users to conversation': 'Añadir usuarios a la conversación',
  'Use this dialog window to add users to conversation':
    'Utiliza esta ventana de diálogo para añadir usuarios a la conversación',
  'New call': 'Nueva llamada',
  Typology: 'Tipología',
  Conversations: 'Conversaciones',
  'New Telegram Conversation': 'Nueva conversación de Telegram',
  'New Email Conversation': 'Nuevo correo',
  'Your message  here...': 'El mensaje aqui...',
  Send: 'Enviar',
  'Select an agent:': 'Selecciona un agente:',
  'The user was added.': 'El usuario ha sido añadido.',
  ' could not be added to conversation.':
    ' no se pudo añadir a la conversación.',
  'The user ': 'El usuario ',
  To: 'Para',
  Subject: 'Asunto',
  'Text here': 'Escribe aquí',
  'User to add': 'Usuario a añadir',
  Transfer: 'Transferir',
  Spy: 'Espiar',
  Barge: 'Intrusión',
  'Conversation users': 'Usuarios de la conversación',
  Whisper: 'Susurro',
  'Select spy mode': 'Selecciona modo de espionaje',
  'Select mode': 'Selecciona modo',
  'Spy call': 'Espiar llamada',
  'Capture call': 'Capturar llamada',
  'Has a next node that is not in the route or does not exist.':
    'Tiene un nodo siguiente que no está en la ruta o no existe.',
  'Choose an extension for transfering': 'Elija una extensión para transferir',
  'No extensions available': 'No hay extensiones disponibles',
  'Choose a mode for spying': 'Elija un modo para espiar',
  'No account has been set up': 'No se ha configurado ninguna cuenta',
  Agent: 'Agente',
  Available: 'Disponible',
  'Clear errors': 'Limpiar errores',
  'There were errors on some nodes.': 'Hubo errores en algunos nodos',
  'Go to the node': 'Ir al nodo',
  'Discard error': 'Descartar error',
  'All conditions must have a next node and a fallback node':
    'Todas las condiciones deben tener un nodo siguiente y un nodo de fallback',
  'There are validation errors in the condition nodes':
    'Hay errores de validación en los nodos de condición',
  'Massive email database': 'Base de datos de emails masivos',
  'See the general data or upload a CSV file':
    'Vea los los datos de la base de datos o suba un archivo CSV',
  'Variable or announcement cannot be empty':
    'Debes seleccionar una variable y un anuncio',
  'All aspects related to massive WhatsApp can be managed here':
    'Todos los aspectos relacionados con WhatsApp masivo se pueden gestionar aquí',
  'No accounts found': 'No se encontraron cuentas',
  'No templates found': 'No se encontraron plantillas',
  'Account is required': 'La cuenta es requerida',
  'Parameters cannot be empty': 'Los parametros no pueden estar vacios',
  'Skip char cannot be empty': 'El salto de caracteres no puede estar vacio',
  'Chat response time cannot be empty':
    'El tiempo de respuesta no puede estar vacio',
  'Database created successfully': 'Base de datos creada con éxito',
  'Pin conversation': 'Fijar conversación',
  'Unpin conversation': 'Desfijar conversación',
  'Massive WhatsApp': 'WhatsApp masivo',
  Busy: 'Ocupado',
  Unavailable: 'No disponible',
  'Error changing status': 'Error al cambiar de estado',
  'Disabled while in call': 'Desactivado mientras esté en llamada',
  'Attended transfer': 'Transferencia atendida',
  Disconnected: 'Desconectado',
  Loading: 'Cargando',
  'Copy configuration': 'Copiar configuración',
  'Select all': 'Seleccionar todos',
  'Copy to selected days': 'Copiar en los días seleccionados',
  'Dialerbot Campaigns': 'Campañas de dialerbot',
  'These are your dialertbots': 'Esto son tus dialerbots',
  'Create new dialerbot': 'Crear un nuevo dialerbot',
  'Search by campaign, channel or status...':
    'Buscar por campaña, canal, estado...',
  'In call': 'En llamada',
  'Parked call': 'En llamada pausada',
  'The title field is required': 'El titulo es requerido',
  'The timeout field is required': 'El timeout es requerido',
  Title: 'Titulo',
  'The Max calls per second field is required':
    'El maximo de llamadas por segundo es requerido',
  'The Max simultaneus calls field is required':
    'El maximo de llamadas simultaneos es requerido',
  'The Amd Detection field is required': 'La deteccion de AMD es requerido',
  'The Pause Holidays field is required': 'Pausar en fiestas es requerido',
  Description: 'Descripción',
  'Select Company': 'Seleccione compañia',
  'Select Service': 'Seleccione servicio',
  'DialerBot configuration': 'Configuración del dialerbot',
  Dates: 'Fechas',
  'Select Queue Percentage': 'Seleccione porcentaje de encolados',
  'Select Outbound Route': 'Seleccione Ruta Saliente',
  'Select Inbound Route': 'Seleccione Ruta Entrante',
  'AMD detection': 'Detección AMD',
  'Launch date': 'Fecha de inicio',
  'Pause on holidays': 'Pausar en fiestas',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miercoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sabado',
  Sunday: 'Domingo',
  MONDAY: 'LUNES',
  TUESDAY: 'MARTES',
  WEDNESDAY: 'MIERCOLES',
  THURSDAY: 'JUEVES',
  FRIDAY: 'VIERNES',
  SATURDAY: 'SABADO',
  SUNDAY: 'DOMINGO',
  'All Days': 'Todos los dias',
  'Monday to Friday': 'Lunes a viernes',
  'Create a new dialerbot campaign': 'Crear una nueva campaña dialerbot',
  'Use this dialog window to add a new call launch engine':
    'En esta ventana podra crear un nuevo motor de lanzamiento de llamadas',
  Cancel: 'Cancelar',
  'Upload CSV': 'Cargar CSV',
  'Keep as draft': 'Solo crear dialerbot',
  'Max calls per second': 'Max llamadas por segundo',
  'Max simultaneous calls': 'Max llamadas simultaneas',
  'Enter the 6-digit PIN.': 'Introduce el PIN de 6 dígitos.',
  'If you don’t have one, the number you enter will become your new PIN.':
    'Si no tienes uno, el número que introduzcas se convertirá en tu nuevo PIN.',
  CAMPAIGN: 'CAMPAÑA',
  CHANNEL: 'CANAL',
  'LAUNCH DATE': 'FECHA DE INICIO',
  'Remove file': 'Quitar archivo',
  Attachments: 'Adjuntos',
  PROGRESS: 'PROGRESO',
  STATUS: 'ESTADO',
  ACTIONS: 'ACCIONES',
  'Rows per page': 'Filas por página',
  'Edit columns': 'Editar columnas',
  'Condition node settings': 'Configuración del nodo condición',
  'Start time': 'Tiempo inicial',
  'Apply filters': 'Aplicar filtros',
  'Filter results': 'Filtrar resultados',
  'Go to first page': 'Ir a la primera página',
  'Go to last page': 'Ir a la última página',
  'Go to next page': 'Ir a la página siguiente',
  'Go to previous page': 'Ir a la página anterior',
  'Phone column': 'Columna del numero de teléfono',
  'Massive WhatsApp database launched successfully':
    'Base de datos de WhatsApp masivo lanzada con éxito',
  Active: 'Activo',
  Finished: 'Finalizado',
  Unstarted: 'No iniciado',
  'Are you sure to delete this dialerbot?':
    'Esta seguro de eliminar este dialerbot?',
  'Are you sure to delete several dialerbots?':
    'Esta seguro de eliminar todos estos dialerbots?',
  'CSV Uploaded': 'CSV cargado',
  Delete: 'Eliminar',
  Conditions: 'Condiciones',
  'Ignore case': 'Ignorar mayúsculas y minúsculas',
  'Any of the values (OR)': 'Alguno de los valores (O)',
  'All of the values (AND)': 'Todos los valores (Y)',
  'Any of the conditions (OR)': 'Alguna de las condiciones (O)',
  'All of the conditions (AND)': 'Todas las condiciones (Y)',
  'Any of the values': 'Alguno de los valores',
  'All of the values': 'Todos los valores',
  'Error deleting showflow': 'Error al eliminar showflow',
  'Error deleting showflows': 'Error al eliminar showflows',
  'Showflow deleted successfully': 'Showflow eliminado con éxito',
  'Filter variables by type': 'Filtrar variables por tipo',
  'All variables': 'Todas las variables y campos',
  'Showflows deleted successfully': 'Showflows eliminados con éxito',
  'No conditions': 'Sin condiciones',
  'Condition between values': 'Condición entre valores',
  'Type of validation': 'Tipo de validación',
  'Add value': 'Añadir nuevo valor',
  'New value': 'Nuevo valor',
  Values: 'Valores',
  'Massive Email': 'Email masivo',
  'Template is required': 'La plantilla es requerida',
  'From email is required': 'El email de origen es requerido',
  'Subject is required': 'El asunto es requerido',
  'Invalid email format': 'Formato de email inválido',
  'Name is required': 'El nombre es requerido',
  'Select an account to to show its templates':
    'Selecciona una cuenta para ver sus plantillas',
  'All aspects related to massive emails can be managed here':
    'Todos los aspectos relacionados con emails masivos se pueden gestionar aquí',
  'Content type': 'Tipo de contenido',
  'To add variables, you can use {{variable}} in the message':
    'Para añadir variables, puedes usar {{nombre_variable}} ',
  'Use HTML editor': 'Usar editor de código HTML',
  'The delimiter field is required': 'El delimitador es requerido',
  Delimiter: 'Delimitador',
  'The phonenumber field is required': 'El nombre de telefono es requerido',
  'Name of PhoneColumn': 'Nombre de columna de telefono',
  'Select Holiday': 'Seleccione fiesta',
  Holiday: 'Fiesta',
  'drag and drop the files here': 'Arrasta tus archivos aqui',
  Upload: 'Subir',
  'Create Dialerbot': 'Crear dialerbot',
  'No documents uploaded': 'No hay documentos',
  'Dialerbot finished': 'Dialerbot finalizado',
  'Add new CSV': 'Añadir nuevo CSV',
  Done: 'Hecho',
  'Export Result CSV': 'Exportar resultados a CSV',
  'Stop Dialerbot': 'Parar DialerBot',
  "We couldn't find any users matching your search criteria":
    'No pudimos encontrar lo que buscabas',
  Undefined: 'Sin fecha',
  Showing: 'Mostrando',
  'Start Dialerbot': 'Iniciar Dialerbot',
  'Dialerbot execution not found': 'Ejecucion de dialerbot no encontrada',
  Until: 'Hasta',
  'Select the hours of weeks to be executed:':
    'Seleccione las horas a la semana para ser ejecutado:',
  'Cannot start a dialerbot that is paused by time of day configuration':
    'No se puede iniciar un dialerbot que es pausado por el tiempo de su configuración',
  'The dialerBot was updated': 'El dialerBot se actualizó con éxito',
  'Create a new call launch engine':
    'Cree un nuevo motor de lanzamiento de llamadas',
  'Update a call launch engine': 'Edite un motor de lanzamiento de llamadas',
  'Update a dialerbot campaign': 'Actualizar una campaña de dialerbot',
  'Use this dialog window to update a call launch engine':
    'Use esta ventana de diálogo para actualizar un motor de lanzamiento de llamadas',
  'The document was deleted successfully':
    'El documento fue eliminado con éxito',
  'The dialerBot was paused successfully': 'El dialerBot se pausó con éxito',
  'The report was generated successfully': 'El informe se generó con éxito',
  'The dialerBot was started successfully': 'El dialerBot se inició con éxito',
  'A connection error occurred, contact the administrator':
    'Ha ocurrido un error en la conexión, contacte al administrador',
  Company: 'Empresa',
  'Company name': 'Nombre de la compañía',
  'Create a new company': 'Crear una nueva compañía',
  Channel: 'Canal',
  'Edit the hours of weeks to be executed:':
    'Edita las horas de la semana para ser ejecutado:',
  'Dates configuration': 'Configuración de fechas',
  'Start time must be before end time.':
    'Fechas de inicio deben ser antes que fechas de finalización',
  'Dialerbot Name': 'Nombre del dialerbot',
  'The dialerbot name field is required':
    'El nombre del dialerbot es requerido',
  'Refresh table': 'Refrescar tabla',
  'Delete one or several dialerbots': 'Eliminar uno o varios dialerbots',
  'Pause one or several dialerbots': 'Pausar uno o varios dialerbots',
  'Start one or several dialerbots': 'Iniciar uno o varios dialerbots',
  'Add Dialerbot': 'Añadir dialerbot',
  'Update Dialerbot': 'Actualizar dialerbot',
  'Delete dialerbot': 'Eliminar dialerbot',
  'Close conversation': 'Cerrar conversación',
  'Transfer call': 'Transferir llamada',
  'Transfer failed': 'Error en la transferencia',
  'Hangup call': 'Colgar llamada',
  'Park Call': 'Aparcar llamada',
  'Unpark call': 'Desaparcar llamada',
  "We're working on implementing new awesomes features!":
    'Estamos trabajando en nuevas funcionalidades increibles',
  'Do you have any problem?': '¿Tienes algún problema?',
  'The email was sent successfully':
    'El correo electrónico fue enviado con éxito',
  'You must enter the destination email':
    'Debes introducir el email de destino',
  'Telegram message has been sent!': 'El mensaje se ha enviado correctamente!',
  'Error sending the message': 'Error al enviar el mensaje',
  'Call launched!': 'Llamada lanzada!',
  'Error launching the call': 'Error al lanzar la llamada.',
  'Are you sure to remove the following DialerBots?':
    'Esta seguro de eliminar los siguientes DialerBots?',
  schedules: 'Horarios',
  'Here you can edit,add or delete your schedules':
    'Aquí puedes editar,añadir o eliminar horarios',
  NAME: 'NOMBRE',
  'A call cannot be transfered to itself or to the same extension that it is already talking with.':
    'Una llamada no se puede transferir a sí misma ni a la misma extensión con la que ya está hablando.',
  'The call ended successfully': 'La llamada terminó exitosamente',
  'An error occurred in the connection': 'Ocurrió un error en la conexión',
  'The call was successfully transferred':
    'La llamada fue transferida con éxito',
  'The call was successfully unparked': 'La llamada se desaparcó con éxito',
  'The call was successfully parked': 'La llamada fue estacionada con éxito',
  'The call was successfully captured': 'La llamada fue capturada con éxito',
  'The conversation was closed successfully':
    'La conversación se cerró con éxito',
  'The call is being spied on successfully':
    'La llamada está siendo espiada con éxito',
  'Do not disturb': 'No molestar',
  'Open SGI': 'Abrir SGI',
  'Open Fichaje': 'Abrir Fichaje',
  'Reset calls': 'Refrescar llamadas',
  'Disable do not disturb mode to launch calls':
    'Desactiva el modo no molestar para lanzar llamadas',
  'Do not disturb enabled': 'Modo no molestar activado',
  'New conversation': 'Nueva conversación',
  'Devices configuration': 'Configuración de sonido',
  'Maximize bar': 'Maximizar',
  'Compress bar': 'Minimizar',
  'Your session is about to expire': 'Su sesión está a punto de caducar',
  "I'm still here": 'Sigo aquí',
  'Conversation history': 'Historial de conversaciones',
  Entity: 'Entidad',
  Outbound: 'Saliente',
  Inbound: 'Entrante',
  Opened: 'Abierta',
  Closed: 'Cerrada',
  'An error occurred': 'Se ha producido un error',
  'Cannot change status while call is active':
    'No se puede cambiar el estado mientras la llamada está activa',
  'Open inbox on call': 'Abrir inbox al atender una llamada',
  'Open inbox on conversation': 'Abrir inbox cuando se asigne una conversación',
  'Save the contact to see the history':
    'Guarde el contacto para ver el historial',
  'The user could not be deleted': 'El usuario no se pudo eliminar',
  'The user could not be created': 'El usuario no se pudo crear',
  'Update User': 'Actualizar usuario',
  'The user could not be updated': 'No se pudo actualizar el usuario',
  'The user was successfully created': 'El usuario fue creado exitosamente',
  'The user was successfully updated': 'El usuario fue actualizado con éxito',
  Username: 'Nombre de usuario',
  Extension: 'Extensión',
  'The user was successfully cloned': 'El usuario fue clonado con éxito',
  'The user could not be cloned': 'No se pudo clonar el usuario',
  'Queue Percentage': 'Porcentaje de colas',
  'OutBound Route': 'Ruta Saliente',
  'Outbound route': 'Ruta Saliente',
  'Inbound Route': 'Ruta Entrante',
  'Inbound route': 'Ruta entrante',
  'Max calls simultaneous': 'Max llamadas simultaneas',
  'AMD Detection': 'Detección Amd',
  Service: 'Servicio',
  'General configuration': 'Configuración general',
  'Select Start Date': 'Seleccionar fecha de inicio',
  Account: 'Cuenta',
  Templates: 'Plantillas',
  'Create new user': 'Crear nuevo usuario',
  'There are the users for company': 'Estos son los usuarios por empresa',
  'All Users': 'Todos los usuarios',
  'Users Group': 'Grupo de usuarios',
  'Add user': 'Añadir usuario',
  'Add users CSV': 'Añadir usuarios CSV',
  'Create user group': 'Crear grupo de usuarios',
  'Add users group CSV': 'Añadir grupo de usuarios CSV',
  'WhatspApp Accounts': 'Cuentas de WhatsApp',
  'All aspects related to WhatspApp can be managed from this page':
    'Todos los aspectos relacionados con WhatspApp se pueden gestionar desde esta página',
  'Add WhatspApp account': 'Añadir cuenta de WhatsApp',
  Accounts: 'Cuentas',
  'Meta templates': 'Metaplantillas',
  'New WhatsApp Conversation': 'Nueva conversación de WhatsApp',
  Number: 'Número',
  Select: 'Seleccionar',
  Selected: 'Seleccionado',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character':
    'Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un carácter de caso especial',
  'The name is required': 'El nombre es requerido',
  'The password is required': 'La contraseña es requerida',
  'The email is required': 'El correo electrónico es requerido',
  'The description is required': 'La descripción es requerida',
  'The company is required': 'La empresa es requerida',
  'The Asign extension is required': 'La asignación de extensión es requerida',
  'The extension is required': 'La extensión es requerida',
  'The email format is invalid':
    'El formato del correo electrónico no es válido',
  'Unlock bar': 'Desbloquear barra',
  'Lock bar': 'Bloquear barra',
  'Please retype your password': 'Por favor, escriba de nuevo su contraseña',
  'Your passwords do not match': 'Tus contraseñas no coinciden',
  'The password can not be longer than 16 characters':
    'La contraseña no puede tener más de 16 caracteres',
  Preview: 'Vista previa',
  'No User selected': 'Ningún usuario seleccionado',
  'Delete one or several users': 'Eliminar uno o varios usuarios',
  'Disable one or several users': 'Deshabilitar uno o varios usuarios',
  'Enable one or several users': 'Habilitar uno o varios usuarios',
  'The user was enabled sucessfully': 'El usuario fue habilitado con éxito',
  'The user was disabled sucessfully': 'El usuario fue deshabilitado con éxito',
  'Create new WhatspApp Account': 'Crear nueva cuenta de WhatspApp',
  'Use this dialog window to add a new WhatspApp Account':
    'Use esta ventana de diálogo para añadir una nueva cuenta de WhatsApp',
  'Create account': 'Crear cuenta',
  'Edit/Update WhatspApp Account': 'Editar/Actualizar cuenta de whatspApp',
  'Use this dialog window to edit/update WhatspApp Account':
    'Use esta ventana de diálogo para editar/actualizar la cuenta de WhatsApp',
  'Update account': 'Actualizar cuenta',
  'The key is required': 'La clave es requerida',
  'The phone number is required': 'El número de teléfono es requerido',
  'The provider is required': 'El proveedor es requerido',
  'The WhatsApp account was successfully created':
    'La cuenta de WhatsApp se creó correctamente',
  'The WhatsApp account was successfully updated':
    'La cuenta de WhatsApp se actualizó correctamente',
  'The WhatsApp account could not be created':
    'No se pudo crear la cuenta de WhatsApp',
  'The WhatsApp account could not be updated':
    'No se pudo actualizar la cuenta de WhatsApp',
  'No WhatsApp account selected': 'Ninguna cuenta de WhatsApp seleccionada',
  'Delete one or several WhatsApp account':
    'Eliminar una o varias cuentas de WhatsApp',
  'Disable one or several WhatsApp account':
    'Deshabilitar una o varias cuentas de WhatsApp',
  'Enable one or several WhatsApp account':
    'Habilitar una o varias cuentas de WhatsApp',
  'The WhatsApp account was enable sucessfully':
    'La cuenta de WhatsApp se habilitó con éxito',
  'The WhatsApp account was disable sucessfully':
    'La cuenta de WhatsApp fue deshabilitada con éxito',
  'No Routes selected': 'No hay rutas seleccionadas',
  'The selected route is already enabled':
    'La ruta seleccionada ya esta habilitada',
  'The selected route is already disabled':
    'La ruta seleccionada ya está deshabilitada',
  'The route was enable sucessfully': 'La ruta fue habilitada exitosamente',
  'The route was disable sucessfully': 'La ruta fue deshabilitada exitosamente',
  'The files were uploaded!': 'Los archivos fueron subidos correctamente!',
  'The file was deleted!': 'El archivo fue eliminado correctamente',
  'The Dialerbot execution has started!':
    'La ejecución del dialerbot ha comenzado!',
  'Execution is already paused.': 'La ejecución ya esta pausada.',
  'Download Report': 'Descargar reporte',
  'Generate report': 'Generar reporte',
  'Report name': 'Nombre de reporte',
  'Duplicate fields found in the CSV file.':
    'Se encontraron campos duplicados en el archivo CSV',
  'That date is already selected': 'La fecha ya ha sido seleccionada',
  'Create a new': 'Crear una nueva',
  ' dialerbot campaign': ' campaña dialerbot',
  'Update a': 'Actualizar una',
  'Use this dialog window to ': 'Use esta ventana para ',
  Name: 'Nombre',
  'Please create a dialerbot first!!': 'Por favor crea primero el dialerbot!!',
  'No dialerbot selected': 'No hay dialerbots seleccionados',
  'Droping the files here ...': 'Arrastando los archivos aca...',
  Field: 'Campo',
  'not found in the file': 'no encontrado en el archivo',
  'Download report': 'Descargar reporte',
  'Ignore wrong lines': 'Ignorar lineas incorrectas',
  'The selected user is already disabled':
    'El usuario seleccionado ya está deshabilitado.',
  'The selected user is already enabled':
    'El usuario seleccionado ya está habilitado',
  'The selected WhatsApp account is already enabled':
    'La cuenta de WhatsApp seleccionada ya está habilitada',
  'The selected WhatsApp account is already disabled':
    'La cuenta de WhatsApp seleccionada ya está deshabilitada',
  'There is elements without edge': 'Hay elementos sin conexión alguna',
  'Full Route was updated': 'La ruta completa fue actualizada',
  Nodes: 'Nodos',
  Save: 'Guardar',
  'Publish now': 'Publicar ahora',
  'Write Route title here': 'Escribe el titulo de la ruta aca',
  'Route title': 'Titulo de la ruta',
  'Route History': 'Historia de la ruta',
  'Search node...': 'Buscar Nodo...',
  'Inbound Routes': 'Rutas entrantes',
  'Create new Route': 'Crear nueva ruta',
  'Disable one or several routes': 'Deshabilitar una o varias rutas',
  'Enable one or several routes': 'Habilitar una o varias rutas',
  'Delete one or several routes': 'Eliminar una o varias rutas',
  'Edit route': 'Editar ruta',
  'Delete route': 'Eliminar ruta',
  'Create new inbound route': 'Crear nueva ruta entrante',
  'Create a new route': 'Crear nueva ruta',
  'Create Route': 'Crear ruta',
  Close: 'Cerrar',
  'No elements': 'No hay elementos',
  'Time condition': 'Condiciones de tiempo',
  'Time Condition': 'Condiciones de tiempo',
  'Send email': 'Enviar emails',
  HangUp: 'Colgados',
  Hangup: 'Colgado',
  PlayBack: 'Reproducción',
  of: 'de',
  'more than': 'más de',
  'Time groups': 'Grupos de tiempo',
  'These are the time groups': 'Estos son los grupos de tiempo',
  'Create extensions': 'Crear extensiones',
  'Create new extension': 'Crear nueva extensión',
  'Use this dialog window to add a new time group':
    'Use esta ventana de diálogo para añadir un nuevo grupo de tiempo',
  'Create time group': 'Crear grupo de tiempo',
  'The time group was successfully created':
    'El grupo de tiempo se creó con éxito.',
  'The time group could not be created': 'No se pudo crear el grupo de tiempo',
  'Create Extension': 'Crear extensión',
  'Write here the time group name...':
    'Escriba aquí el nombre del grupo de tiempo...',
  'The time group was successfully deleted':
    'El grupo de tiempo se eliminó con éxito',
  'The time group could not be deleted':
    'No se pudo eliminar el grupo de tiempo',
  'Copy time group': 'Copiar grupo de tiempo',
  'Edit time group': 'Editar grupo de tiempo',
  'Delete time group': 'Eliminar grupo de tiempo',
  'No time groups selected': 'No hay grupos de tiempo seleccionados',
  'Clone one or several time groups': 'Clonar uno o varios grupos de tiempo',
  'Delete one or several time group': 'Eliminar uno o varios grupos de tiempo',
  'The temporal range was successfully deleted':
    'El rango temporal se eliminó con éxito',
  'The temporal range could not be deleted':
    'No se pudo eliminar el rango temporal',
  'The temporal range was successfully updated':
    'El rango temporal se actualizó con éxito.',
  'The temporal range could not be updated':
    'No se pudo actualizar el rango temporal',
  'The time group was successfully copied':
    'El grupo de tiempo se copió con éxito',
  'The time group could not be copied': 'No se pudo copiar el grupo de tiempo',
  'Select existing ': 'Seleccionar existente ',
  Interaction: 'Interacción',
  Choice: 'Elección',
  Verification: 'Verificación',
  Registration: 'Registro',
  Finalize: 'Finalización',
  'Update time group': 'Actualizar grupo de tiempo',
  'Use this dialog window to update a time group':
    'Use esta ventana de diálogo para actualizar un grupo de tiempo',
  'The temporal range was successfully copied':
    'El rango temporal se copió con éxito',
  'The temporal range could not be copied':
    'No se pudo copiar el rango temporal',
  "You don't have permissions": 'No tienes permisos',
  Since: 'Desde',
  'Copy temporal range': 'Copiar rango temporal',
  'Edit temporal range': 'Editar rango temporal',
  'Delete temporal range': 'Eliminar rango temporal',
  'Use this dialog window to add a new extension':
    'Use esta ventana de diálogo para añadir una nueva extensión',
  'Create new time range': 'Crear nuevo rango de tiempo',
  'Update a time range': 'Actualizar un intervalo de tiempo',
  'Use this dialog window to add a new time range':
    'Use esta ventana de diálogo para añadir un nuevo rango de tiempo',
  'Use this dialog window to update a time range':
    'Use esta ventana de diálogo para actualizar un rango de tiempo',
  'Create temporal range': 'Crear rango temporal',
  'Update temporal range': 'Actualizar rango temporal',
  'The final month cannot be less than the initial month':
    'El mes final no puede ser inferior al mes inicial',
  'Start Month': 'Mes de inicio',
  'End Month': 'Mes final',
  'Start Week Day': 'Día de inicio de semana',
  'End Week Day': 'Día de fin de semana',
  'Start Month Day': 'Día inicial del mes',
  'End Month Day': 'Día final del mes',
  'Initial time': 'Tiempo inicial',
  'End time': 'Tiempo final',
  'The end time is required': 'La hora de finalización es obligatoria',
  'The start time is required': 'La hora de inicio es obligatoria',
  'The start month is required': 'El mes de inicio es obligatorio',
  'The end month is required': 'El mes final es obligatorio',
  'The start week day is required': 'Se requiere el día de inicio de la semana',
  'The start month day is required': 'Se requiere el día del mes de inicio',
  'The time range was successfully created':
    'El intervalo de tiempo se creó correctamente',
  'The time range could not be created': 'No se pudo crear el rango de tiempo',
  'The time range was successfully updated':
    'El intervalo de tiempo se actualizó correctamente',
  'The time range could not be updated':
    'No se pudo actualizar el rango de tiempo',
  'Phone number': 'Número de teléfono',
  Or: 'O',
  'Number to call': 'Número a llamar',
  'Use the original caller id':
    'Utilizar el identificador de llamadas original',
  'Call as anonymous': 'Llamar como anónimo',
  'Create Node': 'Crear Nodo',
  'Update Node': 'Actualizar Nodo',
  'The Dialerbot execution depends on configuration!':
    'La ejecución del dialerbot depende de su configuración!',
  'The download has started!': 'La descarga ha iniciado!',
  'The download has finished successfully!':
    'La descarga ha finalizado exitosamente!',
  'Something went wrong please contact your administrator!':
    'Algo salio mal, por favor contacta tu administrador!',
  'The number must be less or equal to 200':
    'El número tiene que ser menos o igual a 200',
  'Repite Password': 'Repita la contraseña',
  'Asign extension': 'Asignar extensión',
  'Delete report': 'Eliminar reporte',
  'Create new service': 'Crear nuevo servicio',
  'Must select company first': 'Tienes que escoger una compañia',
  You: 'Tú',
  Announcement: 'Anuncio',
  Announcements: 'Anuncios',
  'Time Conditions': 'Condiciones de tiempo',
  'Send Email': 'Enviar email',
  'Send SMS': 'Enviar SMS',
  'Send sms': 'Enviar SMS',
  Redirects: 'Redirecciones',
  'Call Forwardings': 'Desvío de llamadas',
  Publish: 'Publicar',
  Back: 'Atrás',
  Hangups: 'Colgados',
  SendEmail: 'Envio email',
  SendSMS: 'Envio SMS',
  TimeCondition: 'Condición de tiempo',
  AriQueue: 'Cola',
  'Announcement configuration': 'Configuración de anuncio',
  'Ivr configuration': 'Configuración de IVR',
  Skipped: 'Omitido',
  Subtract: 'Quitar',
  'Crossroad configuration': 'Configuración de crossroad',
  'TimeCondition configuration': 'Configuración de condición de tiempo',
  'SendEmail configuration': 'Configuración de envio de email',
  'SendSMS configuration': 'Configuración de envio de sms',
  'AriQueue configuration': 'Configuración de campaña',
  'Hangup configuration': 'Configuración de colgado',
  'Operation configuration': 'Configuración de operación',
  'DataInput configuration': 'Configuración de entrada de datos',
  'Redirect configuration': 'Configuración de redirección',
  'Call Forwarding configuration': 'Configuración de desvío de llamadas',
  CallForwarding: 'Desvío de llamada',
  on: 'el',
  at: 'a la(s)',
  'Edit/Update user': 'Editar/Actualizar usuario',
  'Use this dialog window to edit/update a user':
    'Use esta ventana de diálogo para editar/actualizar un usuario',
  'Use this dialog window to add a new user':
    'Use esta ventana de diálogo para añadir un nuevo usuario',
  'Load Phones': 'Carga de teléfonos',
  'In Progress': 'En progreso',
  Desactivate: 'Desactivado',
  Pending: 'Pendiente',
  completed: 'Completados',
  'No answer': 'Sin respuesta',
  'New schedule': 'Nueva programación',
  Robinson: 'Lista Robinson',
  Rejected: 'Rechazado',
  Answerphone: 'Buzón de voz',
  Congestion: 'Congestión',
  Invalid: 'Inválido',
  Overflow: 'Desbordada',
  'Hide results': 'Ocultar resultados',
  'Show results': 'Mostrar resultados',
  'Dont call': 'Sin llamada',
  Reschedule: 'Reagendamiento',
  'Field deleted': 'Campo eliminado',
  'Could not delete field': 'No se pudo eliminar el campo',
  'Configure reschedules': 'Configurar reagendamientos',
  'Dialerbot reschedules configuration':
    'Configuración de reagendamientos de dialerbot',
  'Select the number of call retries:':
    'Selecciona el número de intentos de llamada:',
  'Select the percentage reserved of relaunches:':
    'Selecciona el procentaja de reservadas de relanzadas:',
  'Select the call results for reschedules and the delay for each:':
    'Selecciona el resultado de llamada y retraso de cada uno:',
  Attempts: 'Intentos',
  relaunches: 'Relanzadas',
  'Delay (min)': 'Retardo (min)',
  Attended: 'Atendida',
  Unanswered: 'No contestado',
  Answeringmachine: 'Contestador',
  'Next step': 'Siguiente',
  'Variable created successfully': 'Variable creada correctamente',
  'Previous step': 'Anterior',
  'The number must be  equal to larger than to 1':
    'El valor debe ser igual o mas grande que 1',
  'Manually managed': 'Configurado manualmente',
  'The field is required': 'El campo es requerido',
  'Are you sure to remove the following?':
    '¿Está seguro de eliminar lo siguiente?',
  'Select the percentage of rescheduled:':
    'Selecciona el porcentaje de reprogramadas:',
  rescheduled: 'reprogramadas',
  Failed: 'Fallido',
  'View launched database': 'Ver base de datos lanzada',
  'Upload CSV to database': 'Subir CSV a base de datos',
  Completed: 'Completado',
  'Analysis in progress': 'Análisis en progreso',
  'Use this window to see the configuration of a launched database':
    'Utilice esta ventana para ver la configuración de una base de datos lanzada',
  "Use this window to upload CSV's to a created database":
    'Utilice esta ventana para subir CSVs a una base de datos creada',
  Underanalyzed: 'No analizable',
  'Provider account': 'Cuenta de proveedor',
  'Provider account updated successfully':
    'Cuenta de proveedor actualizada correctamente',
  'Provider account created successfully':
    'Cuenta de proveedor creada correctamente',
  'Error updating provider account': 'Error al actualizar cuenta de proveedor',
  'Error creating provider account': 'Error al crear cuenta de proveedor',
  'Here you can add a new contacts file and view call results details':
    'Aquí puedes añadir un nuevo archivo de contactos y ver detalle de resultados de las llamadas',
  'Download full file report': 'Descargar reporte de todo el fichero',
  'Download report of ': 'Descargar reporte de ',
  'File management': 'Gestión de ficheros',
  'The final week day cannot be less than the initial week day':
    'El último día de la semana no puede ser inferior al día de la semana inicial',
  'The final day cannot be less than the initial day':
    'El día final no puede ser inferior al día inicial',
  'The final hour cannot be less than the initial hour':
    'La hora final no puede ser inferior a la hora inicial',
  'The initial hour cannot be less than the final hour':
    'La hora inicial no puede ser inferior a la hora final',
  'The  company field is required': 'La compañia es requerida',
  'The service field is required': 'El servicio es requerido',
  'The outbound Routes field is required': 'La ruta saliente es requerida',
  'The inbound Routes field is required': 'La ruta entrate es requerida',
  'The file you are trying to upload is already uploaded':
    'El archivo que intentas subir ya está cargado',
  COMPANY: 'COMPAÑIA',
  'The route ': 'La ruta ',
  ' is already disabled': ' esta deshabilitada',
  ' is already enabled': ' esta habilitada',
  ' has been updated': ' ha sido actualizada',
  'Create provider account': 'Crear cuenta de proveedor',
  Confirm: 'Confirmar',
  'Select a time range': 'Seleccione un rango de tiempo',
  'Add existing time range': 'Añadir intervalo de tiempo existente',
  'Add temporal range': 'Añadir rango temporal',
  'Values for prompt variables': 'Valores para variables de prompt',
  'Params saved in node': 'Parámetros guardados en el nodo',
  'You have to select one temporal range':
    'Tienes que seleccionar un rango temporal',
  'The temporal range already exists: ': 'El rango temporal ya existe: ',
  'If there is a campaign in progress, the provider account will not be modified':
    'Si hay una campaña en progreso, la cuenta de proveedor no se modificará.',
  'Provider accounts': 'Cuentas de proveedor',
  'Prompt node settings': 'Configuración del nodo Prompt',
  'Variable to save result': 'Variable para guardar el resultado',
  'You must select a route': 'Debes seleccionar una ruta',
  'Select a route': 'Seleccionar una ruta',
  'Create prompt and node': 'Crear prompt y nodo',
  'Update prompt and node': 'Actualizar prompt y nodo',
  'Create prompt and update node': 'Crear prompt y actualizar nodo',
  'Update prompt and create node': 'Actualizar prompt y crear nodo',
  'Create element and node': 'Crear elemento y nodo',
  'Update element and node': 'Actualizar elemento y nodo',
  'Create element and update node': 'Crear elemento y actualizar nodo',
  'Update element and create node': 'Actualizar elemento y crear nodo',
  'Redirect to': 'Redireccionar a',
  'Special number': 'Número especial',
  New: 'Nuevo',
  'Select an element': 'Selecciona un elemento',
  Existing: 'Existente',
  'Must select an outbound route': 'Debe seleccionar una ruta saliente',
  'route configuration': 'Configuración de ruta',
  'Update Route': 'Actualizar ruta',
  'Select roles...': 'Seleccionar roles',
  'CallForwarding configuration': 'Configuración de desvío de llamadas',
  'Call Forwarding': 'Desvío de llamada',
  'Select a destination number': 'Seleccione número de destino',
  Configuration: 'Configuración',
  'Select an outbound route': 'Seleccione una ruta saliente',
  Yes: 'Si',
  'Create DialerBot': 'Crear Dialerbot',
  'The refresh time to see the call results is around one minute':
    'El tiempo de refresco para ver los resultados de llamada es de alrededor un minuto.',
  'Call user': 'Llamar usuario',
  'Must set a phone nomber': 'Tiene que colocar número de teléfono',
  'The Dialerbot ': 'El Dialerbot ',
  ' was deleted': ' fue eliminado',
  'Outbound Routes': 'Rutas salientes',
  Order: 'Orden',
  'Add number restrictions': 'Añadir restricciones de número',
  'Create new outbound route': 'Crear nueva ruta saliente',
  'Add number restriction': 'Añadir restricción de número',
  'Create new number restriction': 'Crear nueva restricción de número',
  Length: 'Longitud',
  'Start with': 'Empezar con',
  'Length must be a number': 'La longitud debe ser un número',
  'The length is required': 'La longitud es requerida',
  'The start with is required': 'El comienzo con es obligatorio',
  'The number restriction was successfully added':
    'La restricción de número se agregó con éxito.',
  'Update number restriction': 'Actualizar restricción de número',
  'The number restriction was successfully updated':
    'La restricción de número se actualizó con éxito.',
  'The ddi is required': 'Se requiere el ddi',
  'The ddi must be a number': 'El ddi debe ser un número',
  'The route was successfully created': 'La ruta fue creada exitosamente',
  'The route was sucessfully enabled': 'La ruta fue habilitada exitosamente',
  'The route was sucessfully disabled': 'La ruta fue deshabilitada con éxito',
  'The route was sucessfully deleted': 'La ruta fue eliminada con éxito',
  'Here you can see your schedules': 'Aquí puedes ver tus agendamientos',
  'Here you can see the calendar': 'Aquí puedes ver el calendario',
  'Here you can see the calendar with rescheduled calls':
    'Aquí puedes ver el calendario con llamadas reagendadas',
  'Select the service to filter the campaigns':
    'Selecciona un servicio para filtrar las campañas',
  'Select the campaign to filter the schedules':
    'Selecciona una campaña para filtrar los agendamientos',
  'The route was successfully updated': 'La ruta se actualizó con éxito.',
  'At least one of the fields is required':
    'Al menos uno de los campos es obligatorio',
  BETWEEN: 'Entre',
  GREATER_THAN: 'Mayor',
  GREATER_THAN_OR_EQUALS: 'Mayor o igual',
  LESS_THAN: 'Menor que',
  LESS_THAN_OR_EQUALS: 'Menor o igual',
  EQUALS: 'Igual',
  NOT_EQUALS: 'Desigual',
  CONTAINS: 'Contiene',
  equals: 'igual',
  'not equals': 'distinto',
  'greater than': 'mayor que',
  'greater than or equals': 'mayor o igual que',
  'less than': 'menor que',
  'less than or equals': 'menor o igual que',
  between: 'entre',
  'not null': 'no nulo',
  contains: 'contiene',
  companyId: 'Empresa',
  callMode: 'Modo de llamada',
  callResult: 'Resultado de llamada',
  recorded: 'Grabado',
  originNumber: 'Número origen',
  destinationNumber: 'Número destino',
  creationDate: 'Fecha de creación',
  ddi: 'DDI',
  callDuration: 'Duración de llamada',
  overallDuration: 'Duración total',
  campaignDataCampaignId: 'Campaña',
  serviceDataId: 'Servicio',
  contactDataContactId: 'Contacto',
  agentDataId: 'Agente',
  'Bulk actions': 'Acciones múltiples',
  'Add caller id': 'Añadir identificador',
  'Select caller id': 'Seleccionar identificador de llamadas',
  'An inbound route with the same caller ID already exists':
    'Una ruta entrante ya esta usando este caller Id',
  'Create new caller': 'Crear nuevo identificador',
  'No caller': 'Sin Identificador de llamada',
  'You cannot enable a route if it does not have a first node assigned':
    'No puedes habilitar una ruta si no tiene un primer nodo asignado',
  'The route was updated successfully': 'La ruta fue actualizada con exito',
  Prefix: 'Prefijo',
  'The prefix must be numeric': 'El prefijo debe ser numérico.',
  'The caller id is already added':
    'El identificador de llamadas ya está agregado',
  'Sort ascending': 'Orden ascendente',
  'Sort descending': 'Orden descendiente',
  'Search email': 'Buscar correo electrónico',
  'An Error has ocurred! Please contact your administrator':
    'Un error inesperado ha sucedido, por favor contacte a su administrador!',
  'Unknown Error': 'Error desconocido',
  'Copy user': 'Copiar usuario',
  'Duplicate user': 'Duplicar usuario',
  'Edit user': 'Editar usuario',
  'Delete user': 'Eliminar usuario',
  'Mute queue': 'Silenciar cola',
  'Unmute queue': 'Desilenciar cola',
  campaignDataName: 'Campaña',
  agentDataName: 'Agente',
  routeDataName: 'Ruta entrante',
  serviceDataName: 'Servicio',
  waitingTime: 'Tiempo de espera',
  nonDisplayedName: '',
  'Not grouped': 'No agrupado',
  'Group by': 'Agrupar por',
  'Show finished calls': 'Mostrar llamadas finalizadas',
  'Show only my queues': 'Mostrar solo mis colas',
  'Show only users in my campaigns': 'Mostrar solo usuarios en mis campañas',
  CallAction: 'Llamar',
  Call: 'Llamada',
  French: 'Francés',
  Germany: 'Alemán',
  English: 'Inglés',
  Spanish: 'Español (España)',
  MexicanSpanish: 'Español (Mexico)',
  ColombianSpanish: 'Español (Colombia)',
  PeruvianSpanish: 'Español (Perú)',
  ChileanSpanish: 'Español (Chile)',
  'Unknown name': 'Nombre desconocido',
  'Monday to friday': 'Lunes a viernes',
  'All days': 'Todos los dias',
  From: 'Desde',
  'Add time slot': 'Añadir rango',
  'Click to edit': 'Click para editar',
  'The number must be greater or equal to 1':
    'El número debe ser mayor o igual a 1',
  username: 'nombre de usuario',
  date: 'fecha',
  firstLogin: 'primer inicio de sesión',
  lastLogin: 'último acceso',
  firstLogout: 'primer cierre de sesión',
  lastLogout: 'último cierre de sesión',
  formattedDuration: 'Duración formateada',
  Filters: 'Filtros',
  'Download logs': 'Descargar logs',
  'Real time dashboards': 'Dashboards en tiempo real',
  'Create new dashboard': 'Crear nuevo dashboard',
  'Creating new dashboard': 'Creando un nuevo dashboard',
  'Here you can create a new dashboard': 'Aquí puedes crear un nuevo dashboard',
  'The Dashboard ': 'El dashboard ',
  'Delete dashboard': 'Eliminar dashboard',
  'Edit dashboard': 'Editar dashboard',
  'No dashboard selected': 'No hay dashboards seleccionados',
  'Delete one or several dashboards': 'Eliminar uno o varios dashboards',
  'Create a new Panel': 'Crear nuevo panel',
  'Here you can create a new panel': 'Aquí tu puedes crear un nuevo panel',
  'No panels yets': 'No hay paneles aún',
  'Create Dashboard': 'Crear dashboard',
  'The dashboard was created!': 'El dashboard fue creado',
  Calculation: 'Cálculo',
  'Data type': 'Tipo de dato',
  'Panel Type': 'Tipo de panel',
  'Term and use conditions': 'Términos de uso',
  'Cookie policy': 'Políticas de cookies',
  'All rights reserved': 'Todos los derechos reservados',
  'Suscribed Users': 'Usuarios suscritos',
  'Create Panel': 'Crear panel',
  Percentage: 'Porcentaje',
  Count: 'Conteo',
  Mean: 'Promedio',
  'Waiting Time': 'Tiempo de espera',
  'Abandonend Calls': 'Llamadas abandonadas',
  'Attended Calls': 'Llamadas atendidas',
  'Available Agents': 'Agentes disponibles',
  'Disconnected Agents': 'Agentes desconectados',
  'In Calls': 'En llamadas',
  Realtime: 'Tiempo real',
  Group: 'Grupo',
  'Edit and create panels for you dashboard':
    'Edita y crea paneles para tu dashboard',
  'Images and videos': 'Fotos y videos',
  Document: 'Documento',
  'Temporal conditions': 'Condiciones temporales',
  'Contacts fields': 'Campos de contactos',
  'Contacts groups': 'Grupos de contactos',
  Extensions: 'Extensiones',
  'Music On Hold': 'Música de espera',
  'Caller Ids': 'Identificadores',
  'Start dialerbot': 'Iniciar dialerbot',
  'Stop dialerbot': 'Pausar dialerbot',
  'Select existing contacts': 'Seleccionar contactos existentes',
  'Create new file': 'Crear nuevo archivo',
  'Use this dialog window to add dialerbot file':
    'Utilice esta ventana de diálogo para añadir un nuevo archivo dialerbot',
  'Create File': 'Crea un archivo',
  'Call until contact': 'Llamar hasta contactar',
  'Call all PhoneNumbers': 'Llamar a todos los números de teléfono',
  'Tag field is required': 'El campo de etiqueta es obligatorio',
  'Select contact tags...': 'Seleccionar etiquetas de contacto...',
  'Contact tags': 'Etiquetas de contacto',
  'Add contact tags': 'Añadir etiquetas de contacto',
  'Add contact fields': 'Añadir campos de contacto',
  'Add contact field': 'Añadir campo de contacto',
  'Contact field is required': 'El campo de contacto es obligatorio',
  'Select phone contact fields...':
    'Seleccionar campos de contacto telefónico...',
  'Phone contact fields': 'Campos de contacto telefónico',
  'Add or update contacts': 'Añadir o actualizar contactos',
  'Server connection error': 'Error de conexión del servidor',
  'Panel Size': 'Tamaño del panel',
  'Group Interval': 'Intervalo del grupo',
  Time: 'Tiempo',
  'Time Unit': 'Unidad de tiempo',
  'Update Panel': 'Actualizar Panel',
  'Update a Panel': 'Actualizar un panel',
  'Here you can update a panel': 'Aqui puedes actualizar un panel',
  'Add numbers': 'Añadir números',
  'Main field': 'Campo principal',
  Required: 'Requerido',
  Unique: 'Único',
  'Contact group': 'Grupo de contactos',
  Instants: 'Instantes',
  'In order to create the campaign the service must be updated':
    'Para crear la campaña, el servicio debe actualizarse',
  Continue: 'Continuar',
  'Abandoned Calls': 'Llamadas abandonadas',
  'Busy Agents': 'Agentes ocupados',
  'In Call Agents': 'Agentes en llamadas',
  'Call Results': 'Resultados de llamadas',
  'Closed Conversations': 'Conversaciones cerradas',
  'Opened Conversations': 'Conversaciones abiertas',
  'Conversation Status By Account': 'Estado de conversaciones por cuenta',
  'Agent Status': 'Estado de agente',
  Days: 'Dias',
  Nanoseconds: 'Nanosegundos',
  Microseconds: 'Microsegundos',
  Milliseconds: 'Milisegundos',
  Seconds: 'Segundos',
  Minutes: 'Minutos',
  Hours: 'Horas',
  'Dialerbot type': 'Tipo de dialerbot',
  'Selected contact group:': 'Grupo de contactos seleccionado:',
  'Create contact group': 'Crear grupo de contactos',
  'Or select an existing contact group':
    'O selecciona un grupo de contactos existente',
  'The Inbound Route field is required':
    'El campo Ruta de entrada es obligatorio',
  'The min value is 1': 'El valor mínimo es 1',
  'The max value is 100': 'El valor máximo es 100.',
  'The value must be numeric': 'El valor debe ser numérico.',
  'The min values is 5': 'El valor mínimo es 5.',
  "Press 'ESC' key to exit full screen mode":
    "Presiona la tecla 'ESC' para salir del modo pantalla completa",
  'Full Screen': 'Pantalla completa',
  'Default time zone': 'Zona horaria predeterminada',
  'Time zone': 'Zona horaria',
  'Change time zone': 'Cambiar zona horaria',
  'Record percentage': 'Porcentaje de grabación',
  Start: 'Iniciar',
  'Record all calls': 'Grabar todas las llamadas',
  'Record only if no partial recording exists':
    'Grabar solo si no existe una grabación parcial activa',
  'Record only if there is no active recording':
    'Grabar solo si no existe una grabación activa',
  'Record only if no total recording exists':
    'Grabar solo si no existe una grabación total activa',
  'Start condition': 'Condición de inicio',
  Action: 'Acción',
  Stop: 'Detener',
  'Stop recording all calls': 'Detener todas las grabaciones',
  'Stop recording last started partial recording':
    'Detener la última grabación parcial',
  'Stop recording total recording': 'Detener la grabación total',
  'Play beep': 'Reproducir beep',
  Condition: 'Condición',
  'Please select a condition': 'Selecciona una condición',
  'Download recordings': 'Descargar grabaciones',
  Contact: 'Contacto',
  Full: 'Completo',
  'Record call': 'Grabar llamada',
  'Stop recording': 'Detener grabación',
  'No recordings': 'No hay grabaciones',
  Recordings: 'Grabaciones',
  'Massive SMS': 'SMS masivo',
  Massive: 'Masivo',
  Type: 'Tipo',
  Partial: 'Parcial',
  Duration: 'Duración',
  'Error downloading recording': 'Error descargando grabación',
  'Downloading recording...': 'Descargando grabación...',
  'Recording downloaded!': 'Grabación descargada!',
  'AI Models': 'Modelos de IA',
  'Here you can find the models created to be used in inboundn routes':
    'Aquí puedes encontrar los modelos creados para ser utilizados en las rutas entrantes',
  'Create knowledge model': 'Crear modelo de conocimiento',
  'Update knowledge model': 'Actualizar modelo de conocimiento',
  'Content label': 'Etiqueta de contenido',
  'Remove label': 'Eliminar etiqueta',
  'Label to remove': 'Etiqueta a eliminar',

  'Press enter to add a label to remove':
    'Presiona intro para añadir una etiqueta para eliminar',
  'Knowledge models': 'Modelos de conocimiento',
  'Label already added': 'Etiqueta ya añadida',
  Webpage: 'Página web',
  'Here you can find the models created to be used in the NLU node':
    'Aquí puede encontrar los modelos creados para ser utilizados en el nodo NLU.',
  'Create AI MODEL': 'Crear MODELO de IA',
  'Create intention model': 'Crear modelo de intención',
  'Update intention model': 'Actualizar modelo de intención',
  'Create new AI MODEL': 'Crear un nuevo MODELO de IA',
  'Use this dialog window to add new AI MODEL':
    'Utilice esta ventana de diálogo para agregar un nuevo MODELO AI',
  'Write here the AI model name': 'Escribe aquí el nombre del modelo de IA.',
  'Write here a description...': 'Escribe aquí una descripción...',
  Modify: 'Modificar',
  Schedule: 'Agendar',
  'Copy AI MODEL': 'Copiar MODELO AI',
  'Edit AI MODEL': 'Editar MODELO AI',
  'Delete AI MODEL': 'Eliminar MODELO AI',
  'Edit one or several AI MODEL': 'Editar uno o varios MODELOS AI',
  'Delete one or several AI MODELS': 'Eliminar uno o varios MODELOS AI',
  'You must enter at least one model': 'Debes ingresar al menos un modelo',
  'The AI MODEL was successfully registered':
    'El MODELO AI fue registrado exitosamente',
  'No AI MODEL selected': 'No se seleccionó ningún MODELO de IA',
  'to navigate': 'para navegar',
  'to select': 'para seleccionar',
  'Write the intention name here...':
    'Escribe aquí el nombre de la intención...',
  'Agent user name': 'Nombre de usuario del agente',
  'User ID of the agent': 'ID del agente',
  'Date and time of the recording': 'Fecha y hora de la grabación',
  'Date of the recording': 'Fecha de la grabación',
  'Time of the recording': 'Hora de la grabación',
  'Name of the service': 'Nombre del servicio',
  'ID of the service': 'ID del servicio',
  'ID of the call': 'ID de la llamada',
  'Campaign ID': 'ID de la campaña',
  'Name of the campaign': 'Nombre de la campaña',
  'Source number': 'Número de origen',
  'Destination number': 'Número de destino',
  'DDI of the route': 'DDI de la ruta',
  "Agent's extension": 'Extensión del agente',
  'Recording name variables': 'Variables de nombre de grabación',
  'Recordings name': 'Nombre de grabaciones',
  'Answering machine detection': 'Detección de contestadores',
  'Show last:': 'Mostrar ultimos:',
  'Group By:': 'Agrupar por:',
  'Starter time must be before end time':
    'La hora de inicio debe ser antes de la hora de finalización',
  'Add holiday': 'Añadir festivo',
  'Change to line chart': 'Cambiar a gráfica de linea',
  Next: 'Siguiente',
  'Add label': 'Agregar etiqueta',
  'Add channel': 'Añadir canal',
  'There must be one account per channel': 'Debe haber una cuenta por canal',
  Label: 'Etiqueta',
  'Change to area chart': 'Cambiar a gráfica de area',
  'Update AI MODEL': 'Actualizar MODELO AI',
  'Type intention name': 'Escriba el nombre de la intención',
  'First edit the intention opened': 'Primero edite la intención abierta.',
  'First save the intention name': 'Primero guarde el nombre de la intención.',
  'You have to write one intention': 'Tienes que escribir una intención.',
  'You have to write one tag in every intention':
    'Tienes que escribir una etiqueta en cada intención.',
  'Day: ': 'Día: ',
  'Month: ': 'Mes: ',
  'Week days: ': 'Días de la semana: ',
  'Hour: ': 'Hora: ',
  Deselect: 'Deseleccionar',
  'Ending route': 'Ruta de finalización',
  'No numbers in the list': 'No hay números en la lista',
  'Add numbers to the list pressing enter':
    'Añade números a la lista presionando intro',
  'Robinson List': 'Lista Robinson',
  'Select a Kpi': 'Seleccionar un kpi',
  'The destination number is in the Robinson list':
    'El número destino está en la lista Robinson',
  'The priority was updated successfully':
    'La prioridad se actualizó correctamente.',
  'Priority Hight': 'Prioridad alta',
  'Priority Medium': 'Prioridad media',
  'Priority Low': 'Prioridad baja',
  'Campaign users': 'Usuarios de la campaña',
  'No users in the service to add':
    'No hay usuarios en el servicio para añadir',
  'Users in the campaign service': 'Usuarios en el servicio de la campaña',
  'Filter by username': 'Filtrar por nombre de usuario',
  'Available users': 'Usuarios disponibles',
  'Added users': 'Usuarios añadidos',
  'No users added': 'No hay usuarios añadidos',
  'No users available': 'No hay usuarios disponibles',
  'Recording name pattern': 'Patrón de nombre de grabación',
  'Anonymous call': 'Llamada anónima',
  'Recording name': 'Nombre de grabación',
  'Call mode': 'Modo de llamada',
  'The service name field is required': 'El nombre del servicio es requerido',
  'Campaigns from': 'Campañas de',
  'Charts Configuration': 'Configuración de graficas',
  Visualization: 'Visualización',
  'Time intervals': 'Intervalos de tiempo',
  'KPI Configuration': 'Configuración de KPI',
  'Administrative time': 'Tiempo administrativo',
  'Adminintrative time (s)': 'Tiempo administrativo (s)',
  'Call Results Percentage': 'Porcentaje de resultados de llamadas',
  'Mean Waiting Time': 'Promedio de tiempo de espera',
  'Mean Call Duration': 'Promedio de duración de llamada',
  'Outgoing Launched Calls': 'Llamadas salientes lanzadas',
  Launched: 'Lanzado',
  'Incoming Launched Calls': 'Llamadas entrantes lanzadas',
  'Simultaneous Calls': 'Llamadas simultaneas',
  'Agent Status Percentage': 'Porcentaje de estado de agente',
  'Call Status': 'Estado de llamada',
  'Call Status Percentage': 'Porcentaje de estados de llamada',
  Campaign: 'Campaña',
  Width: 'Ancho',
  Height: 'Alto',
  'Manual change': 'Cambio manual',
  Hidden: 'Oculto',
  Language: 'Idioma',
  Example: 'Ejemplo',
  'All Services': 'Todos los servicios',
  'All Campaigns': 'Todas las campañas',
  'Chart type': 'Tipo de grafica',
  Calculations: 'Cálculo',
  Icon: 'Icono',
  Charts: 'Gráficos',
  Last: 'Ultimo',
  Abandoned: 'Abandonada',
  line: 'lineas',
  bar: 'barras',
  pie: 'circular',
  area: 'area',
  histogram: 'histograma',
  donut: 'dona',
  'The dashboard was subscribed': 'El dashboard fue suscrito',
  'The panel  was created!': 'El panel fue creado!',
  'The dashboard was unsubscribed': 'El panel fue desuscrito',
  'The panel was updated!': 'El panel fue actualizado!',
  'Scan QR code': 'Escanea el código QR',
  'Enter the 6-digit authentication code.':
    'Introduce el código de autenticación de 6 dígitos.',
  'Error, please contact the administrator.':
    'Error, por favor contacte al administrador.',
  'Service updated succesfully': 'Servicio actualizado exitosamente',
  'Campaign updated succesfully': 'Campaña actualizada exitosamente',
  'You must add at least one schedule': 'Debes agregar al menos un horario',
  'Two-Factor Authentication has been successfully configured.':
    'La autenticación en dos pasos se ha configurado correctamente.',
  'Could not detect delimiter automatically.':
    'No se pudo detectar el delimitador automáticamente.',
  'You have selected': 'Usted ha seleccionado',
  file: 'archivo',
  'Drag & drop files here': 'Arrastra y suelta archivos aquí',
  Campaigns: 'Campañas',
  'Confirm Service': 'Confirmar servicio',
  'Create a new service': 'Crear un nuevo servicio',
  'Update a service': 'Actualizar un servicio',
  'Use this dialog window to add a new service':
    'Utilice esta ventana para agregar un nuevo servicio',
  'Use this dialog window to update a service':
    'Utilice esta ventana para actualizar un servicio',
  Progressive: 'Progresiva',
  Predictive: 'Predictiva',
  'Predictive configuration': 'Configuración predictiva',
  'Progressive Configuration': 'Configuración progresiva',
  'Abandoned rate': 'Porcentaje de abandonadas',
  'Force autoanswer': 'Forzar auto-respuesta',
  'Recordings configuration': 'Configuración de grabaciones',
  'Recordings percentage': 'Porcentaje de grabaciones',
  'Start date': 'Fecha de inicio',
  'Relaunch configuration': 'Configuración de reagendamientos',
  'Relaunch configurations': 'Configuraciones de reagendamiento',
  'Dialerbot configuration': 'Configuración de Dialerbot',
  'Number of call retries': 'Número de reintentos de llamada',
  'Reschedule percentage': 'Porcentaje de reagendamiento',
  'Calls configuration': 'Configuración de llamadas',
  'Conversation timeout (mins)': 'Timeout de conversación (mins)',
  'Socialmedia account': 'Cuenta',
  Autoattended: 'Auto atendido',
  'Call Duration': 'Duración de la llamada',
  Canceled: 'Cancelado',
  Circuit: 'Circuito',
  Forwarded: 'Reenviado',
  InCall: 'En llamada',
  InRoute: 'En ruta',
  Incoming: 'Entrante',
  Internal: 'Interna',
  Missed: 'Perdida',
  Outgoing: 'Saliente',
  Parked: 'En espera',
  Preparing: 'Preparando',
  Queued: 'En cola',
  Requested: 'Solicitado',
  Ringing: 'Sonando',
  RouteHangup: 'Colgado en ruta',
  Talking: 'Hablando',
  Transferred: 'Transferido',
  Unknown: 'Desconocido',
  'In Route': 'En ruta',
  Recall: 'Volver a llamar',
  'In Call': 'En llamada',
  'An error occurred, please contact your administrator.':
    'Ha ocurrido un error, por favor contactar el administrador.',
  'Call launch engines': 'Motores de lanzamiento',
  'Call launch engine': 'Motor de lanzamiento de llamadas',
  'All aspects related to call launch engines can be found here':
    'Todos los aspectos relacionados con motores de lanzamiento de llamadas se pueden gestionar aquí',
  'Create a new launch engine': 'Crear un nuevo motor de lanzamiento',
  'Call launch engine type': 'Tipo de motor de lanzamiento',
  Identifier: 'Identificador',
  'Editable by': 'Editable por',
  'Visible to': 'Visible para',
  'Field type': 'Tipo de campo',
  //TODO: MUST CHECK THIS TRANSLATIONS
  'Group by ': 'Agrupar por ',
  'Last minute': 'Último minuto',
  day: 'dia',
  days: 'dias',
  second: 'segundo',
  seconds: 'segundos',
  'Group by hour': 'Agrupar por hora',
  'Group by second': 'Agrupar por segundo',
  'Group by minute': 'Agrupar por minuto',
  'Group by 5 seconds': 'Agrupar por 5 segundos',
  'Group by 10 seconds': 'Agrupar por 10 segundos',
  'Group by 30 seconds': 'Agrupar por 30 segundos',
  'Group by 5 minutes': 'Agrupar por 5 minutos',
  'Group by 15 minutes': 'Agrupar por 10 minutos',
  'Group by 30 minutes': 'Agrupar por 30 minutos',
  'Group by 2 hours': 'Agrupar por 2 horas',
  'Group by 6 hours': 'Agrupar por 6 horas',
  'Group by day': 'Agrupar por dia',
  'Grouped by hour': 'Agrupado cada  hora',
  'Grouped by second': 'Agrupado cada  segundo',
  'Grouped by minute': 'Agrupado cada minuto',
  'Grouped by 5 seconds': 'Agrupado cada 5 segundos',
  'Grouped by 10 seconds': 'Agrupado cada 10 segundos',
  'Grouped by 30 seconds': 'Agrupado cada 30 segundos',
  'Grouped by 5 minutes': 'Agrupado cada 5 minutos',
  'Grouped by 15 minutes': 'Agrupado cada 10 minutos',
  'Grouped by 30 minutes': 'Agrupado cada 30 minutos',
  'Grouped by 2 hours': 'Agrupado cada 2 horas',
  'Grouped by 6 hours': 'Agrupado cada 6 horas',
  'Grouped by day': 'Agrupado por cada dia',
  hour: 'hora',
  hours: 'horas',
  minute: 'minuto',
  minutes: 'minutos',
  'Group interval or panel size wrongly especified or max number of groups exceeded.':
    'Intervalo de grupo o tamaño de panel especificado erróneamente o número máximo de grupos excedido.',
  'Show Last': 'Mostrar Último/s',
  'Last 5 minutes': 'Últimos 5 minutos',
  'Last 15 minutes': 'Últimos 15 minutos',
  'Last 30 minutes': 'Últimos 30 minutos',
  'Last hour': 'Última hora',
  'Last day': 'Último dia',
  'Last 3 hours': 'Últimas 3 horas',
  'Last 6 hours': 'Últimas 6 horas',
  'Last 12 hours': 'Últimas 12 horas',
  'Last 2 days': 'Últimos 2 dias',
  'Last 5 days': 'Últimos 5 dias',
  '5 minutes': '5 minutos',
  '15 minutes': '15 minutos',
  '30 minutes': '30 minutes',
  '3 hours': '3 horas',
  '6 hours': '6 horas',
  '12 hours': '12 horas',
  '2 days': '2 dias',
  '5 days': '5 dias',
  '5 seconds': '5 segundos',
  '10 seconds': '10 segundos',
  '30 seconds': '30 segundos',
  'The dashboard was updated': 'El dashboard fue actualizado',
  'The dashboard was not updated!': 'EL dashboard no pudo ser actualizado!',
  'You have uploaded': 'Has cargado',
  files: 'archivos',
  Submit: 'Guardar',
  'No more to add': 'No hay más para añadir',
  'Tags column': 'Columna que contiene las etiquetas',
  'Delimiter character': 'Caracter delimitador',
  ', (comma)': ', (coma)',
  '. (period)': '. (periodo)',
  '; (semicolon)': '; (punto y coma)',
  ': (colon)': ': (colon)',
  '- (hyphen)': '- (guión)',
  '_ (underscore)': '_ (guión bajo)',
  '| (pipe)': '| (barra vertical)',
  'Add CSV column': 'Añadir columna CSV',
  'Contact field': 'Campo de contacto',
  'CSV Column': 'Columna CSV',
  'Create tag when not found': 'Crear etiqueta si no existe',
  'Progressive configuration': 'Configuración de Progresiva',
  'Use this dialog window to add a new Predictive file':
    'Utilice esta ventana de diálogo para agregar un nuevo archivo predictivo',
  'Use this dialog window to add a new Progressive file':
    'Utilice esta ventana de diálogo para agregar un nuevo archivo progresivo',
  'Use this dialog window to add a new DialerBot file':
    'Utilice esta ventana de diálogo para agregar un nuevo archivo dialerbot',
  'The Predictive execution depends on configuration!':
    '¡La ejecución predictiva depende de la configuración!',
  'The Progressive execution depends on configuration!':
    '¡La ejecución progresiva depende de la configuración!',
  'Write max time here...': 'Escribe el máximo aquí...',
  Measure: 'Medida',
  'Maximum time in queue (s)': 'Tiempo máximo en cola (s)',
  'Update times': 'Actualizar tiempos',
  'No data': 'Sin datos',
  Speed: 'Velocidad',
  Hour: 'Hora',
  'Phone Link Erase': 'Telefono colgado',
  Storage: 'Almacenaje',
  'The times were successfully  updated!':
    'Los tiempos fueron exitosamente actualizados!',
  CREATE_NEW_COMPANY: 'Crear nueva empresa',
  'Update a company': 'Actualizar una empresa',
  'Use this dialog window to update a company':
    'Utilice esta ventana de diálogo para actualizar una empresa',
  'Update company': 'Actualizar empresa',
  'Create company': 'Crear empresa',
  Licenses: 'Licencias',
  'Number of licenses': 'Número de licencias',
  'Contact fields': 'Campos de contacto',
  'Add contact': 'Añadir contacto',
  'Upload contacts CSV': 'Subir CSV',
  Autogenerated: 'Autogenerado',
  'Users And Queues': 'Usuarios y colas',
  'Ticket conversation': 'Tíckets de la conversación',
  'Use this dialog window to create a new company':
    'Utilice esta ventana de diálogo para crear una nueva empresa',
  'Displayed Extensions': 'Extensiones mostradas',
  'Write here the typology name...':
    'Escriba aquí el nombre de la tipología...',
  'Add sub-typology': 'Añadir subtipología',
  'The first field of each type is the main field.':
    'El primer campo de cada tipo es el campo principal.',
  'Update contact field': 'Actualizar campo de contacto',
  'Create new contact field': 'Crear nuevo campo de contacto',
  'Use this dialog window to update a contact field':
    'Utilice esta ventana para actualizar un campo de contacto',
  'Use this dialog window to add a new contact field':
    'Utilice esta ventana para añadir un nuevo campo de contacto',
  "The campaign doesn't exist anymore": 'La campaña ya no existe',
  "The service doesn't exist anymore": 'El servicio ya no existe',
  'Campaign not found': 'Campaña no encontrada',
  'Service not found': 'Servicio no encontrado',
  'The panel was deleted correctly': 'El panel fue eliminado correctamente',
  'The service is required': 'El servicio es requerido',
  'The campaign is required': 'La campaña es requerida',
  Coordinator: 'Coordinador',
  'Allow rescheduling': 'Permitir reagendamientos',
  'Allow manual reschedules': 'Permitir reagendamientos manuales',
  'Conversation contacts': 'Contactos de la conversación',
  Participant: 'Participante',
  'View contact': 'Ver contacto',
  'Contact notes': 'Notas del contacto',
  'Phone Number': 'Número de telefono',
  'Intention models': 'Modelos de intención',
  Models: 'Modelos',
  'Error while creating the following nodes:':
    'Error al crear los siguientes nodos:',
  'Error while creating nodes': 'Error al crear nodos',
  'All aspects related to AI models can be managed from this page':
    'Todo aquello relacionado con modelos de IA puede ser gestionado desde aquí.',
  'Add knowledge model': 'Añadir modelo de conocimiento',
  'Add intention model': 'Añadir modelo de intención',
  Phone: 'Telefono',
  Edit: 'Editar',
  'Add as new contact': 'Agregar como nuevo contacto',
  'Variable name': 'Nombre de la variable',
  'Add to an existing contact': 'Agregar a un contacto existente',
  'Max open conversations': 'Máximo de conversaciones abiertas',
  'Max open conversations must be a positive number':
    'El máximo de conversaciones abiertas debe ser un número positivo',
  'Max open conversations is required':
    'El máximo de conversaciones abiertas es requerido',
  'Delete Contact': 'Eliminar contacto',
  'Allow using in manual rescheduling':
    'Permitir usar en reagendamientos manuales',
  'Allow the agent to create manual reschedules':
    'Permitir al agente crear reagendamientos manuales',
  'General rescheduling configuration':
    'Configuración general de reagendamientos',
  'Incoming Calls Started': 'Llamadas entrantes iniciadas',
  'Outgoing Calls Started': 'Llamadas salientes iniciadas',
  'Identification field': 'Campo de identificación',
  Live: 'En tiempo real',
  'Change contact group': 'Cambiar grupo de contactos',
  'Search contacts': 'Buscar contactos',
  'All contact groups': 'Todos los grupos de contactos',
  'Select a contact group to see its contacts':
    'Selecciona un grupo para ver sus contactos',
  'Search contact groups': 'Buscar grupo de contactos',
  'No contacts found': 'No se encontraron contactos',
  Reporting: 'Reportes',
  'Create new report': 'Crear nuevo reporte',
  'Select multiple conversations': 'Selección múltiple',
  'Show Icon': 'Mostrar Icono',
  'Typology for maximum rescheduling achieved':
    'Tipología de reprogramación máxima alcanzada',
  'Ticket sub-typologies': 'Subtipologías de Tickets',
  'Add ticket sub-typologies to configure a relaunch':
    'Agregue subtipologías de tickets para configurar un relanzamiento',
  'Sub-typologies': 'Subtipologías',
  'Create campaign': 'Crear campaña',
  'Update campaign': 'Actualizar campaña',
  'Use this dialog window to create a campaign':
    'Utilice esta ventana de diálogo para crear una campaña.',
  'Use this dialog window to update a campaign':
    'Utilice esta ventana de diálogo para actualizar una campaña',
  'End date': 'Fecha de fin',
  'No title': 'Sin título',
  'Date can not be in the past': 'La fecha no puede ser anterior a hoy',
  Date: 'Fecha',
  'Assigned user': 'Usuario asignado',
  'Schedule created succesfully': 'Agendamiento creado exitosamente',
  'Schedule updated succesfully': 'Agendamiento actualizado exitosamente',
  'Move Back': 'Mover atras',
  'Move Forward': 'Mover adelante',
  'Zoom is activated': 'Zoom esta activado',
  'Reset to base state': 'Reiniciar a estado base',
  'No users conected': 'No hay usuarios conectados',
  MOH_TITLE: 'Música de espera',
  MOH_SUBTITLE: 'Administrar música de espera',
  MOH_CREATE_BUTTON: 'Crear música de espera',
  MOH_CREATE_BUTTON_NEW: 'Create new queue Music On Hold',
  MOH_CREATE_TITLE: 'Crear nueva música de espera',
  MOH_CREATE_SUBTITLE:
    'Utilice esta ventana de diálogo para agregar música de espera',

  MOH_UPDATE_TITLE: 'Actualizar nueva música de espera',
  MOH_UPDATE_SUBTITLE:
    'Utilice esta ventana de diálogo para actualizar Música de espera',

  MOH_COMPANY: 'Empresa',
  MOH_NAME: 'Nombre',
  MOH_ORDER: 'Orden',
  MOH_AUDIOS: 'Audios',
  MOH_ADD_NEW: 'Agregar nuevo Anuncio',
  MOH_CANCEL_BUTTON: 'Cancelar',
  MOH_UPDATE_BUTTON: 'Actualizar',
  MOH_SUCCESS_CREATE_MESSAGE: 'Music On Hold creado exitosamente',
  MOH_SUCCESS_UPDATE_MESSAGE: 'Music On Hold actualizado exitosamente',
  MOH_DELETE_BUTTON: 'Eliminar Music On Hold',
  MOH_COMPANY_REQUIRED: 'El campo Empresa es requerido',
  MOH_NAME_REQUIRED: 'El campo Nombre es requerido',
  MOH_ORDER_REQUIRED: 'El campo Orden es requerido',
  MOH_AUDIOS_REQUIRED: 'El campo Audios es requerido',
  Random: 'Aleatorio',
  Sequential: 'Secuencial',
  'The start date cannot be later than the end date':
    'La fecha de inicio no puede ser posterior a la fecha de finalización.',
  'Reschedule and save': 'Reagendar y guardar',
  'Empty if editable by anyone': 'Vacío si es editable para cualquiera',
  'Empty if visible to anyone': 'Vacío si es visible para cualquiera',
  'Successful upload': 'Subida exitosa',
  Summary: 'Resumen',
  Created: 'Creados',
  CREATED_SINGULAR: 'Creado',
  'Only models with status Created are available':
    'Solo estan disponibles los modelos con estado "Creado"',
  'Knowledge models deleted successfully':
    'Modelos de conocimiento eliminados correctamente',
  'Error deleting knowledge model':
    'Error al eliminar el modelo de conocimiento',
  'Filter by channel': 'Filtrar por canal',
  'Filter by date': 'Filtrar por fecha',
  Updated: 'Actualizados',
  Ignored: 'Ignorados',
  'Time elapsed:': 'Tiempo total:',
  'The identification field must be unique':
    'El campo de identificación debe ser único',
  'event(s)': 'evento(s)',
  'Repeat every year': 'Repetir cada año',
  'Punctual day': 'Día puntual',
  'Status name...': 'Nombre de estado...',
  'Extensions group': 'Grupo de extensiones',
  'To create a new group, type the name and press enter':
    'Para crear un nuevo grupo, escribe el nombre y presiona enter',
  'Basic info': 'Información básica',
  'Add field': 'Añadir campo',
  Members: 'Miembros',
  'Select extension': 'Seleccionar extensión',
  To_hasta: 'Hasta',
  'Add range': 'Añadir rango',
  'Search extension': 'Buscar una extensión',
  'Add phone number': 'Añadir número de teléfono',
  'Outbound route will be created automatically':
    'La ruta saliente se creará automáticamente',
  'Inbound route is assigned automatically':
    'La ruta entrante es asignada automáticamente',
  'Edit route on new tab': 'Editar ruta en nueva pestaña',
  'Add extensions': 'Añadir extensiones',
  'Add phone numbers': 'Añadir números de teléfono',
  'It is required to add at least one role':
    'Es requerido añadir al menos un rol',
  'The contacts upload has finished': 'La subida de contactos ha finalizado',
  'Service for new campaign': 'Servicio para nueva campaña',
  'Name of Username': 'Nombre de columna de usuario',
  'The username field is required':
    'El campo de nombre de usuario es obligatorio',
  'The dashboard update was done correctly':
    'La actualizacion del dashboard fue hecha correctamente',
  'No group panels': 'Paneles sin grupo',
  Automations: 'Automatizaciones',
  'Auto close conversation on last ticket edited':
    'Cerrar conversación automáticamente al editar el último ticket',
  Panels: 'Paneles',
  'Add panels group': 'Agregar grupo de paneles',
  'Panels group name is required': 'Nombre de grupo de paneles es requerido',
  'Write here the group Panels name...':
    'Escriba aquí el nombre del grupo de paneles...',
  'Create and enable': 'Crear y habilitar',
  'New WhatspApp Account': 'Nueva cuenta WhatsApp',
  'Edit WhatspApp Account': 'Editar cuenta de WhatsApp',
  'Use this dialog window to create new WhatspApp Account':
    'Utilice esta ventana de diálogo para crear una nueva cuenta de WhatspApp',
  'Use this dialog window to edit WhatspApp Account':
    'Utilice esta ventana de diálogo para editar la cuenta de WhatspApp',
  'Account details': 'Detalles de la cuenta',
  'Provider details': 'Detalles del proveedor',
  JOIN: 'Unirse',
  'Use this dialog window to mange the templates':
    'Utilice esta ventana de diálogo para administrar las plantillas',
  'Templates from': 'Plantillas de',
  'Search template here...': 'Buscar plantilla aquí...',
  'Show disabled': 'Mostrar desactivados',
  'WhatsApp State': 'Estado de WhatsApp',
  'New webchat style': 'Nuevo estilo de chat web',
  'Use this dialog window to create new webchat style':
    'Utilice esta ventana de diálogo para crear un nuevo estilo de chat web',
  'Template Name': 'Nombre de la plantilla',
  Category: 'Categoría',
  Message: 'Mensaje',
  'Confirm action': 'Confirmar acción',
  Marketing: 'Marketing',
  Utility: 'Utilidad',
  'Custom Message': 'Mensaje personalizado',
  'Send promo offers, product offers more to increase awareness and engagement.':
    'Envíe ofertas promocionales y más ofertas de productos para aumentar el conocimiento y la participación.',
  'Send account updates, order updates, alerts and more to share important information.':
    'Envíe actualizaciones de cuentas, actualizaciones de pedidos, alertas y más para compartir información importante.',
  'Send promotional offers, announcements and more to increase awareness and engagement.':
    'Envíe ofertas promocionales, anuncios y más para aumentar el conocimiento y la participación.',
  'Header (Optional)': 'Encabezado (opcional)',
  'Header text': 'Texto de la cabecera',
  'Add a 60 character title to your message. Only one variable is supported in the header.':
    'Añade un título de 60 caracteres a tu mensaje. Solo se admite una variable en el encabezado.',
  'Define sample variable value for header:':
    'Defina el valor de la variable de muestra para el encabezado:',
  Body: 'Cuerpo',
  'Enter the text for your message in the language you have selected.':
    'Ingrese el texto de su mensaje en el idioma que haya seleccionado.',
  'Define sample variable value for body:':
    'Defina el valor de la variable de muestra para el cuerpo:',
  'Footer text (Optional)': 'Texto de pie de página (opcional)',
  'Hide sample templates': 'Ocultar plantillas de muestra',
  'Create a panel': 'Crear un panel',
  "Create a panel's group": 'Crear un grupo de paneles',
  'Panels group': 'Grupo de paneles',
  'Write here the group Panels description...':
    'Escribe la descripción del grupo de paneles...',

  Effects: 'Efectos',
  'Customize color': 'Customizar color',
  'Drop shadow': 'Sombreado inferior',
  'Inner shadow': 'Sombreado interior',
  'Background blur': 'Difuminar fondo',
  'Layer blur': 'Difuminar capa',
  Size: 'Tamaño',
  'Style name': 'Nombre del estilo',
  'Style description': 'Descripción del estilo',
  Font: 'Fuente',
  Conversation: 'Conversación',
  Bubble: 'Burbuja',
  'Header background': 'Fondo de la parte superior',
  'Header controls': 'Controles de la parte superior',
  'Main content': 'Contenido principal',
  'Bubble color': 'Color de la burbuja',
  'Contact message': 'Mensaje del contacto',
  'Contact message background': 'Fondo del mensaje del contacto',
  'Contact message text': 'Texto del mensaje del contacto',
  'Agent message': 'Mensaje del agente',
  'Agent message background': 'Fondo del mensaje del agente',
  'Agent message text': 'Texto del mensaje del agente',
  Others: 'Otros',
  'Scrollbar color': 'Barra de desplazamiento',
  'Conversation background': 'Fondo de la conversación',
  Shadow: 'Sombra',
  'Font size': 'Tamaño de la fuente',
  Margin: 'Margen',
  'Max width': 'Anchura máxima',
  'Response inbox': 'Cajetín de respuestas',
  'Background color': 'Color de fondo',
  'Font color': 'Color de la fuente',
  'Icons color': 'Color de los iconos',
  'Logo size': 'Tamaño del logo',
  'Data politics': 'Política de datos',
  'Has credentials': 'Tiene credenciales',
  'Send conversation': 'Enviar conversación',
  Border: 'Borde',
  Text: 'Texto',
  Background: 'Fondo',
  'Fields width': 'Anchura de los campos',
  'Open chat': 'Abrir chat',
  'Conversation style': 'Estilo de la conversación',
  'Keyword Groups': 'Grupos de palabras clave',
  'These are the keyword groups': 'Estos son los grupos de palabras claves',
  'Create a keyword groups': 'Crear un grupo de palabras clave',
  'Welcome message': 'Mensaje de bienvenida',
  'Contact fields variables': 'Variables de los campos de contacto',
  External: 'Externa',
  Special: 'Especial',
  'Default prefix here...': 'Escribe aquí el prefijo por defecto...',
  'Create new caller id': 'Crear un nuevo identificador',
  'Use this dialog window to add a new caller id':
    'Use esta ventana para añadir un nuevo identificador',
  'Edit caller id': 'Editar un identificador',
  'Use this dialog window to edit a caller id':
    'Use esta ventana para editar un identificador',
  'Call type': 'Tipo de llamada',
  Both: 'Ambas',
  'Use this dialog to create a new outbound route':
    'Utiliza esta ventana para crear una nueva ruta saliente',
  'Use this dialog to update a new outbound route':
    'Utiliza esta ventana para editar una ruta saliente',
  'Update outbound route': 'Actualiza ruta saliente',
  'Create a new restriction number': 'Crear una nueva restricción de número',
  'Select prefered caller ids': 'Identificadores de llamadas preferidos',
  'If not selected, a random caller id will be used':
    'Si no se selecciona ninguno, se usará un identificador de llamada aleatorio entre los disponibles en la ruta',
  'Use this dialog to create a new restriction number':
    'Use esta ventana para crear una nueva restricción de número',
  'Fill all the fields': 'Rellena todos los campos',
  'Sending email': 'Enviando email',
  'Use this dialog window to create new webchat account':
    'Utiliza esta ventana para crear una nueva cuenta de WebChat',
  'Use this dialog window to update webchat account':
    'Utiliza esta ventana para actualizar una cuenta de WebChat',
  Style: 'Estilo',
  'Accept policies': 'Aceptar políticas',
  'Initial form': 'Formulario inicial',
  'New Videocall': 'Nueva videollamada',
  'Join Videocall': 'Unirse a la videollamada',
  'Edit webchat style': 'Editar estilo de WebChat',
  'Use this dialog window to edit a webchat style':
    'Utiliza esta ventana para editar un estilo de WebChat',
  'Write the new keyword': 'Escribe la nueva palabra clave',
  'New Keyword': 'Nueva palabra clave',
  'New Keywords Group': 'Nuevo grupo de palabras claves',
  'No categories added': 'No hay categorias',
  'Write the new keywoard group': 'Escribe el nuevo grupo de palabras claves',
  Keywords: 'Palabras clave',
  'Create Keywords group': 'Crear grupo de palabras claves',
  'Write here the keywords group name...': 'Escribe el nombre del grupo...',
  word: 'palabra',
  'Write here the word...': 'Escribe la palabra...',
  'Add variable': 'Añadir variable',
  'Conversation preview:': 'Vista previa de la conversación:',
  'WhatsApp with': 'Whatsapp con',
  Reply: 'Responder',
  'Reply all': 'Responder a todos',
  Forward: 'Reenviar',
  'No service': 'Sin servicio',
  'Keywords group color': 'Color de grupo',
  'Edit account': 'Editar cuenta',
  'The keyword already exists': 'La palabra clave ya existe',
  'The keyword group was created succesfully':
    'El grupo de palabras clave se creó correctamente.',
  'The keyword group was updated succesfully':
    'El grupo de palabras clave se actualizó correctamente.',
  'Update Keywords group': 'Actualizar grupo de palabras clave',
  'Create a keywords group': 'Crear un grupo de palabras clave',
  'Call Info': 'Información de llamada',
  Sumary: 'Resumen',
  Positive: 'Positivo',
  Neutro: 'Neutral',
  Negative: 'Negativo',
  'Conversation evaluator': 'Evaluador de la conversación',
  Transcription: 'Transcripción',
  'Filter messages': 'Filtrar mensajes',
  'Complete recording': 'Audio completo',
  'Contact Recording': 'Audio del contacto',
  'Agent Recording': 'Audio del agente',
  Evaluator: 'Evaluador',
  'The role is in use:': 'El rol está en uso:',
  INBOX_LOADED: 'Cargadas',
  'No more conversations': 'No hay más conversaciones',
  'Load more': 'Cargar más',
  'Oldest first': 'Más antiguo primero',
  'Newest first': 'Más nuevo primero',
  'Clear text filter': 'Limpiar filtro de texto',
  'Add filter': 'Añadir filtro',
  'Clear filters': 'Limpiar filtros',
  Columns: 'Columnas',
  Refresh: 'Actualizar',
  'Analyzed Conversations': 'Conversaciones analizadas',
  'Analyzed conversations': 'Conversaciones analizadas',
  'These are the analyzed conversations':
    'Estas son las conversaciones analizadas',
  'The analysis was deleted succesfully!':
    'El analisis fue eliminado con éxito!',
  'See analysis': 'Ver analisis',
  'Next refresh in': 'Puedes actualizar en',
  'The analysis is being generating!': 'El analisis esta siendo generado!',
  'You can either add variables with the button or wrap a word between double keys':
    'Puedes añadir variables desde el botón o poniendo una palabra entre dobles claves',
  'Message body': 'Cuerpo del mensaje',
  'Use this dialog window to create a massive SMS launch database':
    "Utiliza este dialogo para crear una base de datos para lanzar SMS's",
  'Create massive SMS launch database':
    'Crear base de datos de lanzamiento de SMS',
  'Massive SMS Launching': 'Lanzamiento masivo de SMS',
  'All aspects related to massive SMS launching can be found here':
    'Todos los aspectos del lanzamiento masivo de SMS se pueden gestionar aquí',
  "Database hasn't finished. Some data might be missing":
    'El lanzamiento no ha terminado. Pueden faltar datos',
  'Create new database': 'Crear nueva base de datos',
  status: 'estado',
  'Max. End Date': 'Fecha de finalización max.',
  Started: 'Lanzado',
  Uploading: 'Subiendo',
  'Selected option': 'Opción seleccionada',
  'Posible options': 'Opciones posibles',
  'Loading audio file ...': 'Cargando archivo de audio...',
  'The call is not analyzable': 'La llamada no es analizable',
  Opening: 'Inicio',
  Male: 'Hombre',
  Sentiment: 'Sentimiento',
  'Timeout time': 'Tiempo timeout',
  'Show only unread conversations': 'Mostrar solo conversaciones no leídas',
  'Unread only': 'Solo no leídas',
  'The App number is required': 'El número de la aplicación es obligatorio.',
  'The API key is required': 'Se requiere la clave API',
  'Displayed extension': 'Extensiones mostradas',
  'Create extensions from range': 'Crear extensiones desde el rango',
  'Update and create node': 'Actualizar y crear nodo',
  'Status Name': 'Nombre del estado',
  'Create node': 'Crear nodo',
  'Status Type': 'Tipo de estado',
  'Status Color': 'Color de estado',
  'Add new status': 'Agregar nuevo estado',
  AdministrativeTime: 'Tiempo administrativo',
  'The company was successfully created': 'La empresa fue creada con éxito.',
  'The extensions could not be created': 'No se pudieron crear las extensiones',
  'The company could not be created': 'La empresa no se pudo crear.',
  'The company was deleted:': 'La empresa fue eliminada:',
  Trunk: 'Troncal',
  Trunks: 'Troncales',
  'Use this dialog window to create a trunk':
    'Use esta ventana para crear un troncal.',
  'Use this dialog window to edit a trunk':
    'Use esta ventana para editar un troncal.',
  'Create trunk': 'Crear troncal',
  'Edit trunk': 'Editar troncal',
  'The company was successfully updated':
    'La empresa se actualizó exitosamente',
  'The company could not be updated': 'La empresa no se pudo actualizar.',
  'This field is required': 'Este campo es obligatorio',
  'This title is required': 'Este título es requerido',
  'The WhatsApp Account was sucessfully created':
    'La cuenta de WhatsApp fue creada exitosamente',
  'The WhatsApp Account was sucessfully updated':
    'La cuenta de WhatsApp se actualizó exitosamente',
  'The WhatsApp Account was deleted:': 'La cuenta de WhatsApp fue eliminada:',
  'The speech analysis has started!': 'El analisis ha iniciado!',
  'The speech analysis is running!': 'El analisis esta corriendo!',
  "Join when you're ready": 'Únete cuando estés listo',
  Join: 'Unirse',
  Option: 'Opción',
  Gender: 'Género',
  Female: 'Mujer',
  'updated successfully': 'actualizado exitosamente',
  'created successfully': 'creado exitosamente',
  'The company was disabled:': 'La empresa quedó inhabilitada:',
  'Invalid digit': 'Dígito inválido',
  'New option': 'Nuevo opción',
  'Welcome announcement': 'Anuncio de bienvenida',
  'Timeout announcement': 'Anuncio de timeout',
  'Invalid digit announcement': 'Anuncio de dígito inválido',
  Advanced: 'Avanzado',
  Initial: 'Inicio',
  'Waiting for video input': 'Esperando entrada de video',
  'Analyzed recordings': 'Grabaciones analizadas',
  'These are the analyzed recordings': 'Estas son las grabaciones analizadas',
  'Analyze new recording': 'Analizar nueva grabación',
  'Use this dialog window to add a new':
    'Utilice esta ventana de diálogo para agregar un nuevo',
  'Chatting with': 'Chateando con',
  'Agent name': 'Nombre del agente',
  'Creation date': 'Fecha de creación',
  'Modification date': 'Fecha de modificación',
  'Assigned users': 'Usuarios asignados',
  Ticket: 'Ticket',
  Tickets: 'Tickets',
  'Unsaved contacts': 'Contactos no guardados',
  Today: 'Hoy',
  'Today at': 'Hoy a las',
  'Your message here...': 'Tu mensaje va aquí...',
  'Contact information': 'Información del contacto',
  'Send email copy': 'Enviar copia al email',
  'Privacy policy': 'Política de privacidad',
  'Upload bubble': 'Subir burbuja',
  'There must be at least one unique field':
    'Debe haber al menos un campo único',
  'Failed upload': 'Carga fallida',
  'Waiting for camera and microphone permissions':
    'Esperando permisos de cámara y micrófono',
  Uploaded: 'Cargado',
  Generated: 'Generado',
  DURATION: 'DURACIÓN',
  Script: 'Guión',
  'Max retries': 'Reintentos max',
  'Select a script to see the content':
    'Selecciona un guión para ver el contenido',
  'Call variable': 'Variable de llamada',
  'Company variable': 'Variable de empresa',
  'Ticket field': 'Campo de ticket',
  'The extension cannot have only white spaces':
    'La extensión no puede tener solo espacios en blanco.',
  'Add extensions to selected groups':
    'Agregar extensiones a grupos seleccionados',
  'Email account': 'Cuenta de correo',
  'All aspects related to webchat can be managed from this page':
    'Todo lo relacionado con webchat se puede administrar desde esta página',
  'All aspects related to trunks can be managed here':
    'Todo lo relacionado con troncales se puede administrar desde esta página',
  'Must enter a password.': 'Se debe añadir una contraseña.',
  'Must enter a realm.': 'Se debe añadir un realm',
  'Add new recording': 'Agregar nueva grabación',
  'Add Recording': 'Agregar Grabación',
  Credentials: 'Credenciales',
  Domain: 'Dominio',
  'Add Multiple Recordings': 'Agregar multiples grabaciones',
  'External Action': 'Accion Externa',
  None: 'No seleccionado',
  'Add recording': 'Agregar Grabación',
  'Add multiple recordings': 'Agregar multiples grabaciones',
  'Create new analysis': 'Crear un nuevo analisis',
  'Sentiment analysis': 'Analisis de emociones',
  'Upload type': 'Tipo de subida',
  '1 file (2 audio tracks LEFT | RIGHT)':
    '1 archivo (2 pistas de audio IZQUIERDA | DERECHA)',
  '2 channels (CONTACT|AGENT)': '2 canales (CONTACTO|AGENTE)',
  'The uploaded .wav file should contain the audio of the recording separated into 2 tracks,one for the agent and the other for the contact.':
    'El archivo .wav cargado debe contener el audio de la grabación separado en 2 pistas, una para el agente y otra para el contacto.',
  Instructions: 'Instrucciones',
  'There are 2  options to upload multiple recordings.':
    'Hay 2 opciones para cargar varias grabaciones.',
  '1-Through a ZIP that contains the recordings and a KEY-VALUE of whether it is the contact or the agent.(For example:recording1_agent.wav & recording1_contact.wav)':
    '1-A través de un ZIP que contiene las grabaciones y un CLAVE-VALOR de si es el contacto o el agente. (Por ejemplo::recording1_agent.wav & recording1_contact.wav)',
  '2- Through a ZIP that contains the recordings (in case of two tracks in the same file).The .wav file should contain the audio of the recording separated into 2 tracks, one for the agent and the other for the contact.':
    '2- A través de un ZIP que contiene las grabaciones (en el caso de dos pistas en el mismo archivo). El archivo .wav debe contener el audio de la grabación separado en 2 pistas, una para el agente y la otra para el contacto.',
  'The analysis has been uploaded': 'El analisis ha sido cargado!',
  'An error ocurred, please contact your administrator.':
    'Un error ha ocurrido, por favor contacte a su administrador.',
  'The audio does not have 2 audio tracks, right chanel agent, left chanel contact':
    'El audio no tiene 2 pistas de audio, canal de agente derecho, canal de contacto izquierdo',
  'You must select at least one day': 'Debes seleccionar al menos un día',
  'The campaign was sucessfully deleted':
    'La campaña fue eliminada exitosamente.',
  'Delete trunk': 'Eliminar troncal',
  'Campaign created succesfully': 'Campaña creada con éxito',
  'The trunk was succesfully created': 'Troncal creado con éxito',
  'The trunk was succesfully edited': 'Troncal editado con éxito',
  'No recordings,please upload': 'No hay grabaciones por favor adjuntar',
  'One recording is missing': 'Falta una grabación, por favor adjuntar',
  'Only two records allowed': 'Solo dos grabaciones permitidas',
  'Analyzed Recordings': 'Grabaciones analizadas',
  'Videoroom could not be created': 'No se ha podido crear la video room',
  'Videoroom created successfully': 'Videoroom creada con exito',
  'Enable one company': 'Habilitar una empresa',
  'Disable one company': 'Deshabilitar una empresa',
  'Enable one or several companies': 'Habilitar una o varias empresas',
  'Disable one or several companies': 'Deshabilitar una o varias empresas',
  'The company was enabled successfully':
    'La empresa fue habilitada exitosamente',
  'The company was disabled successfully':
    'La empresa fue desactivada con éxito.',
  'You must select at least one company':
    'Debes seleccionar al menos una empresa',
  'The company is already enabled': 'La empresa ya está habilitada',
  'The company is already disabled': 'La empresa ya está inhabilitada',
  'You cannot delete an active company':
    'No se puede eliminar una empresa activa',
  'Edit company': 'Editar empresa',
  'No value set': 'Sin valor asociado',
  'Toggle camera': 'Alternar cámara',
  'Toggle mute': 'Alternar silencio',
  'Toggle speaker volume': 'Alternar volumen del altavoz',
  'Select camera': 'Seleccionar cámara',
  'Select microphone': 'Seleccionar micrófono',
  'Speaker settings': 'Configuración de altavoces',
  Scripts: 'Guiones',
  'Discard changes': 'Descartar cambios',
  'New script': 'Nuevo guión',
  'Ticket fields': 'Campos de ticket',
  'Call variables': 'Variables de llamada',
  'Call result': 'Resultado de llamada',
  'Origin number': 'Número de origen',
  'Waiting time': 'Tiempo de espera',
  'Call duration': 'Duración de llamada',
  Route: 'Ruta',
  'Hangup code': 'Código de colgado',
  'Company variables': 'Variables de empresa',
  'Select a variable type': 'Selecciona un tipo de variable',
  'Script will be created after saving the service':
    'El guión se creará después de guardar el servicio',
  'Deleted successfully': 'Eliminado exitosamente',
  'Value error, Duplicate words are not allowed':
    'Error en valor, palabras duplicadas no son permitidas',
  'Update a keywords group': 'Actualizar grupo de palabras claves',
  'Search external action name here...':
    'Busca un noombre de una acción externa',
  'Delete External Actions': 'Eliminar acciones externas',
  'Copy External Actions': 'Copiar acciones externas',
  'Delete External Action': 'Eliminar acción externa',
  'Edit External Action': 'Editar acción externa',
  'Copy External Action': 'Copiar acción externa',
  'Create new External Action': 'Crear una nueva acción externa',
  'Use this dialog window to add a new external action':
    'Utiliza esta ventana para añadir una nueva acción externa',
  'External Action name is required':
    'Es necesario un nombre para la acción externa',
  'Contact Group is requiered to create External Action':
    'Es necesario seleccionar un Grupo de contacto para la acción externa',
  'External Action type is required':
    'Es necesario seleccionar un tipo para la acción externa',
  'Update External Action': 'Actualiza la acción externa',
  'Use this dialog window to update a external action':
    'Use esta ventana para actualizar una acción externa',
  'Write here the extrenal action name':
    'Escribe aqui el nombre de la acción externa',
  'Contact Group': 'Grupo de contacto',
  'Write here the URL for the external action':
    'Escribe aqui la URL de la accion externa',
  'Url name variables': 'Nombre de las variables de la URL',
  'Type $ to see the available variables':
    'Escribe $ para ver las variables disponibles',
  'External Actions': 'Acciones externas',
  'All aspects related to external actions can be managed from this page':
    'Todos los aspectos relacionados con las acciones externas se pueden controlar desde esta página',
  'Add external action': 'Añade una acción externa',
  'The external action was successfully ':
    'La acción externa fue correctamente ',
  ' updated': ' actualizada',
  ' created': ' creada',
  'The external action could not be': 'La accion externa no se pudo ',
  'The external action': 'La accion externa',
  ' could not be deleted': ' no se ha podido borrar',
  ' was successfully deleted': ' se ha borrado correctamente',
  'The External Action ': 'La acción externa ',
  ' was successfully cloned': ' se clonó correctamente',
  ' could not be cloned': ' no pudo ser borrado',
  'Are you sure to remove ': '¿Estas seguro de que quieres borrar ',
  ' items?': ' items?',
  'The zip is incorrect could be due to several reasons: the names are not correct, some files have no pair or are not wavs.':
    'El zip es incorrecto puede deberse a varias razones: los nombres no son correctos, algunos archivos no tienen par o no son wavs.',
  WORDS: 'PALABRAS',
  'Drop the files to start uploading':
    'Suelta el archivo para iniciar la subida',
  'Waiting to be accepted': 'Esperando a ser aceptado',
  'No keywords': 'No hay palabras claves',
  'No summary': 'No hay resumen',
  Accept: 'Aceptar',
  Reject: 'Rechazar',
  Waiting: 'En espera',
  Denied: 'Denegado',
  Exited: 'Ha salido',
  VIDEOCALLS_TALKING: 'En llamada',
  'Show actions': 'Mostrar acciones',
  'No fields available': 'No hay campos disponibles',
  'Hang up': 'Colgar',
  'Show fields': 'Mostrar campos',
  'Hide fields': 'Ocultar campos',
  Readmit: 'Readmitir',
  'Hide actions': 'Ocultar acciones',
  'Videocall has finished.': 'La videollamada ha finalizado.',
  'Go back': 'Volver',
  Exit: 'Salir',
  'Finish videocall': 'Finalizar videollamada',
  'Join videocall': 'Unirse',
  'You don"t have announcements permissions':
    'No tienes permisos para anuncios',
  'Create style': 'Crear estilo',
  'Edit style': 'Editar estilo',
  'Upload logo': 'Subir logo',
  'Show script': 'Mostrar guión',
  'Create External Action': 'Crear Acción Externa',
  'Videocall has finished or it does not exist':
    'La videoroom no existe o se encuentra finalizada',
  'has joined the call': 'se ha unido a la llamada',
  'has left the call': 'ha salido de la llamada',
  'You have an active video call': 'Tienes una videollamada activa',
  'Do you want to exit the current call?':
    '¿Quieres salir de la llamada actual?',
  'Write your message here': 'Escribe aquí tu mensaje',
  'The contact closed the conversation.': 'El contacto cerró la conversación.',
  typing: 'escribiendo',
  'No analyzed recording selected':
    'No hay grabaciones analizadas seleccionadas',
  'Generate or view analysis': 'Ver analisis o generar de nuevo',
  'Delete one or several analyses': 'Elimine uno o varios analisis',
  'Delete analyzed recording': 'Eliminar grabación analizada',
  'The analyzed recording was deleted succesfully!':
    'La grabación analizada fue eliminada exitosamente',
  'Seems like you have blocked the camera access':
    'Parece que has bloqueado el acceso a la cámara',
  'Please enable the camera access to continue':
    'Por favor, habilita el acceso a la cámara en el navegador para continuar',
  'Enable camera access': 'Habilite el acceso a la cámara',
  'Attach file': 'Adjuntar archivo',
  'Attach a file': 'Adjuntar archivo',
  "The videoroom is full, you can't join":
    'La videollamada está completa, no puedes unirte',
  'Enter an email': 'Introduce un email',
  'This type is not allowed here': 'Este tipo no está permitido.',
  'This type of file is not allowed here.':
    'Este tipo de archivo no está permitido.',
  Subtypology: 'Subtipología',
  Headers: 'Encabezados',
  Params: 'Parámetros',
  Format: 'Formato',
  'Disable all': 'Desactivar todas',
  'Enable all': 'Activar todas',
  'External actions': 'Acciones externas',
  'Open in a new window instead of in a web client window.':
    'Abrir en una nueva ventana en lugar de en la ventana del cliente.',
  Shortcode: 'Subdominio',
  'This URL has been deprecated. Please use the new URL provided by DialApplet.':
    'Esta URL ha quedado obsoleta. Por favor, utiliza la nueva URL proporcionada por DialApplet.',
  'Ignore external action': 'Ignorar acción externa',
  'This could happen due to a key or value not being enclosed in double quotes or not ending with a comma':
    'Esto podría ocurrir porque una clave o un valor no está entre comillas dobles o por no terminar con una coma',
  'Invalid JSON': 'JSON inválido',
  'New key': 'Nueva clave',
  'Add header': 'Añadir encabezado',
  'Add param': 'Añadir parámetro',
  'Add form data': 'Añadir datos de formulario',
  'Add row': 'Añadir fila',
  'Response JSON': 'Respuesta JSON',
  'Response code': 'Código de respuesta',
  'Please enter a URI': 'Por favor, introduce una URI',
  'Please enter a valid JSON body':
    'Por favor, introduce un cuerpo JSON válido',
  Intention: 'Intención',
  Spell: 'Escrito',
  'Out of time': 'Tiempo agotado',
  'In time': 'A tiempo',
  'No message': 'Sin mensaje',
  'No origin name': 'Sin nombre de origen',
  'No phone number': 'Sin número de teléfono',
  'Text blocks': 'Bloques de texto',
  'Enter phone number': 'Añadir número de teléfono',
  'Destination email': 'Correo de destino',
  'Origin name': 'Nombre de origen',
  showflow: 'guión',
  'Number of roads': 'Número de caminos',
  'Recording configuration': 'Configuración de grabación',
  Request: 'Petición',
  Response: 'Respuesta',
  'NLU configuration': 'Configuración NLU',
  Key: 'Clave',
  Value: 'Valor',
  'Keys must be unique': 'Las claves deben ser únicas',
  Open: 'Abrir',
  'Filter values': 'Filtrar valores',
  'Add condition': 'Añadir condición',
  'Contact not added': 'Contacto no agregado',
  'Add group': 'Añadir grupo',
  'Upload contacts': 'Subir contactos',
  'Create new tag': 'Crear nueva etiqueta',
  'Create new field': 'Crear nuevo campo',
  'Add block': 'Añadir bloque',
  'La petición se realizará desde la IP ':
    'La petición se realizará desde la IP ',
  'recuerde permitirla en su firewall. Sólo se permite realizar peticiones a los puertos 80 o 443':
    'recuerde permitirla en su firewall. Sólo se permite realizar peticiones a los puertos 80 o 443',
  'Schedule deleted succesfully': 'Agendamiento eliminado exitosamente',
  'Create variable': 'Crear variable',
  'Remove row': 'Eliminar fila',
  'Initial value': 'Valor inicial',
  'Use this dialog window to update a variable':
    'Use esta ventana para actualizar una variable',
  'Use this dialog window to add a new variable':
    'Use esta ventana para crear una variable',
  Scope: 'Ámbito',
  'Not assigned to any campaign': 'Ninguna campaña asignada',
  'Ignore invalids': 'Ignorar inválidos',
  'Type of validation for conditions': 'Tipo de validación entre condiciones',
  'No values': 'Sin valores',
  'Create new robinson list': 'Crear una nueva lista robinson',
  'Upload numbers CSV': 'Subir CSV con los números',
  'Edit robinson list': 'Editar lista robinson',
  'Change numbers': 'Cambiar números',
  'Edit with CSV': 'Editar con CSV',
  'Edit individually': 'Editar individualmente',
  'Number phone colum': 'Columna del número de teléfono',
  'Save and delete': 'Guardar y borrar',
  'Save and add': 'Guardar y añadir',
  'Number phone column': 'Columna de los teléfonos',
  'Phone numbers in ': 'Números de teléfono en ',
  'Delete numbers': 'Eliminar números',
  'Add number': 'Añadir número',
  'Delete number': 'Eliminar número',
  'No subject': 'Sin asunto',
  'All subresolutions attached will also be deleted':
    'Todas las subtipologías asignadas también serán eliminadas',
  'The attached subresolutions will be deleted: ':
    'Las siguientes subtipologías asignadas también se borrarán: ',
  'The resolution': 'La tipología',
  'The resolutions': 'Las tipologías',
  'Could not be deleted': 'no se pudo eliminar',
  'This ticket subresolution': 'Esta subtipología ',
  'is used by one or more tickets': 'es utilizada por algún ticket',
  'Error message: ': 'Mensaje de error: ',
  'The typology': 'La tipología',
  'The typologies': 'Las tipologías ',
  'was successfully cloned': 'fue copiada con éxito',
  'could not be cloned': 'no se pudo copiar',
  'were successfully deleted': 'se borraron con éxito',
  'was successfully deleted': 'se borró con éxito',
  "weren't deleted. Error message: ": 'no fueron borradas. Mensajes de error: ',
  'could not be deleted. Error message: ':
    'no se pudo borrar. Mensaje de error: ',
  'Instagram accounts': 'Cuentas de Instagram',
  'All aspects related to Instagram can be managed from this page':
    'Todos los aspectos relacionados con Instagram se pueden gestionar desde esta página',
  'Add Instagram Account': 'Añadir cuenta de Instagram',
  'Create Instagram account': 'Crear cuenta de Instagram',
  'Use this dialog window to add an Instagram account':
    'Utiliza esta ventana para añadir una nueva cuenta de Instagram',
  'Update Instagram account': 'Actualizar cuenta de Instagram',
  'Use this dialog window to edit an Instagram account':
    'Utiliza esta ventana para editar una cuenta de Instagram',
  'Use this dialog window to Authenticate an Instagram account':
    'Utiliza esta ventana para autenticar una cuenta de Instagram',
  'Authenticate Instagram account': 'Autenticar cuenta de Instagram',
  'To authenticate this account, click on the button below to be redirected to Facebook and complete the authentication process':
    'Para autenticar esta cuenta, haz clic en el botón de abajo para ser redirigido a Facebook y sigue los pasos',
  'Not authenticated': 'Sin autenticar',
  Authenticate: 'Autenticar',
  Authenticated: 'Autenticada',
  'Refresh status': 'Actualizar estado',
  'Account authenticated successfully': 'Cuenta autenticada con éxito',
  'Process completed successfully': 'Proceso completado con éxito',
  'Instagram account deleted successfully':
    'Cuenta de Instagram eliminada con éxito',
  'Instagram account created successfully':
    'Cuenta de Instagram creada con éxito',
  'When you are done, click on the refresh button':
    'Cuando hayas terminado, pulsa en el botón de actualizar',
  'Pending authentication': 'Pendiente de autenticación',
  'Needs to be the name of the Instagram account':
    'Debe ser el mismo nombre que la cuenta de Instagram',
  'Show unique queue': 'Mostrar cola única',
  'Element ID': 'ID de elemento',
  Disable: 'Desactivar',
  Enable: 'Activar',
  'Clone route': 'Clonar ruta',
  'All aspects related to inbound routes can be managed here':
    'Todos los aspectos relacionados con las rutas entrantes se pueden gestionar aquí',
  'Filter variables': 'Filtrar variables',
  Add: 'Añadir',
  Substract: 'Restar',
  Set: 'Establecer',
  Unset: 'Desestablecer',
  'Read variable': 'Variable de lectura',
  'Write variable': 'Variable de escritura',
  'Only default variables': 'Solo variables por defecto',
  'No Email account selected': 'No hay una cuenta de email seleccionada',
  'The selected email account is already': 'La cuenta de email ya esta',
  Protocol: 'Protocolo',
  'Authentication status': 'Estado de auntentificacion',
  'Status enable': 'Estado Activo',
  'Delete account': 'Borrar cuenta',
  'Edit Account': 'Editar Cuenta',
  'Disable one or several email accounts':
    'Desactivar 1 o mas cuentas de email',
  'Enable one or several email accounts': 'Activar 1 o mas cuentas de email',
  'Delete one o several email accounts': 'Borrar 1 o mas cuentas de email',
  'Activation Status': 'Estado de activación',
  'User name': 'Nombre de usuario',
  'Verify account': 'Verificar cuenta',
  'Enable one or several telegram accounts':
    'Activar 1 o varias cuentas de telegram',
  'Disable one or several telegram accounts':
    'Desactivar 1 o varias cuentas de telegram',
  'No Telegram account selected': 'No hay cuenta de telegram seleccionada',
  'The selected telegram account is already': 'La cuenta de telegram ya esta',
  'Delete one o several telegram accounts':
    'Borrar 1 o varias cuentas de telegram ',
  'Telegram Accounts': 'Cuentas de Telegram',
  'These are the created recordings': 'Estos son los anuncios creados',
  'Create announcement': 'Crear anuncio',
  'Create new announcement': 'Crear nuevo anuncio',
  'Use this dialog window to add a new announcement':
    'Use este diálogo para añadir un nuevo anuncio',
  'Upload File': 'Subir Archivo',
  'Record Audio': 'Grabar Audio',
  'Type Text': 'Introducir texto',
  'Your file exceeds maximum file size for images':
    'El archivo excede el máximo tamaño permitido para las imágenes',
  'Your file exceeds maximum file size for documents':
    'El archivo excede el máximo tamaño permitido para los documentos',
  'Uploading an audio file is required. You can also record an audio or type text.':
    'Se requiere subir un archivo. Si no, puede grabar un audio o introducir el texto',
  'Recording audio is required. You can also upload an audio file or type text.':
    'Se requiere grabar un audio. Si no, puede subir un archivo o introducir el texto',
  'Announcement text field is required. You cab also upload an audio file or type text.':
    'Se requiere introducir un texto. Si no, puede subir un archivo o grabar un audio',

  All: 'Todos',
  'Least recent': 'Agente menos reciente',
  'Round robin': 'Round robin',
  'Leave queued': 'Dejar en cola',
  'Assign strategy': 'Estraegia de asignación',
  'New Instagram Conversation': 'Nueva conversación de Instagram',
  'Write your message here...': 'Escribe aquí tu mensaje...',
  'Phone numbers': 'Números de teléfono',
  'Edit Robinson list': 'Editar lista Robinson',
  'Delete Robinson list': 'Borrar lista Robinson',
  'All aspects related to Robinson Lists management can be found here':
    'Todo lo relacionado con las listas Robinson pueden ser encontrados aquí',
  'Campaign configuration': 'Configuración de campaña',
  'Update email signature': 'Actualizar firma de email',
  'Create new email signature': 'Crear una nueva firma de email',
  'Use this dialog window to update a email signature':
    'Utiliza esta ventana para actualizar una firma de email',
  'Use this dialog window to add a new email signature':
    'Utiliza esta ventana para crear una nueva firma de email',
  'Email signature name is required': 'La firma de email requiere un nombre',
  'Contact Group is requiered to create email signature':
    'La firma de email requiere un grupo de contacto',
  'Write here the email signature name':
    'Escribe aqui el nombre de la firma de email',
  'Write here the email signature in ': 'Escribe aqui la firma de email en ',
  'Create email signature': 'Crea la firma de email',
  TEXT: 'TEXTO',
  'Call campaign': 'Campaña de llamadas',
  'Email campaign': 'Campaña de email',
  'WhatsApp campaign': 'Campaña de WhatsApp',
  'Telegram campaign': 'Campaña de Telegram',
  'Instagram campaign': 'Campaña de Instagram',
  'WebChat campaign': 'Campaña de Webchat',
  'Edit campaign': 'Editar campaña',
  'Campaign to update': 'Campaña a actualizar',
  'Add new channel': 'Añadir nuevo canal',
  'Only calls': 'Solo llamadas',
  'If a conversation from a different channel other than calls goes through this node, nothing will happen':
    'Si una conversación de un canal diferente a llamadas pasa por este nodo, no ocurrirá nada',
  'Max. conversations per agent': 'Max. conversaciones por agente',
  'Using this form you can create a new inbound route':
    'Usando este formulario puedes crear una nueva ruta entrante',
  'Please select a contact group first':
    'Por favor, selecciona un grupo de contactos primero',
  'Add a channel': 'Añadir un canal',
  'Conversation History': 'Historial de conversación',
  'Conversation Status': 'Estado de la conversación',
  'Instagram message has been sent!': '¡Se ha enviado un mensaje de Instagram!',
  'Thank you for attending!': '¡Gracias por asistir!',
  'You have been rejected...': 'Has sido rechazado...',
  'You have been kicked out of the video call':
    'Se te ha echado de la videollamada.',
  'The videoroom is full': 'La sala de videollamadas está llena',
  'The video call has already ended or does not exist':
    'La videollamada ya ha acabado o no existe',
  'Could not connect with videocalls':
    'No se ha podido conectar con videocalls',
  'The template was created successfully':
    'La plantilla fue creada exitosamente.',
  'Phone numbers must be 3-15 digits long':
    'Los teléfonos deben tener entre 3 y 15 dígitos',
  'Invalid email. Please enter a valid user and domain':
    'Dirección de correo inválida. Por favor entre un usuario y dominio válidos',
  'Invalid field: contains not allowed character such as "@", "_", "!?". Allowed only alphanumeric':
    'Campo incorrecto. Contiene caracteres no permitidos como "@", "_", "!?". Allowed only alphanumeric',
  'Telegram username must be alphanumeric or with underscores between 5-32 characters length':
    'Un usuario de Telegram debe contener entre 5-32 caracteres alfanuméricos o "_"',
  'Instagram username must be alphanumeric or with underscores between 5-30 characters length':
    'Un usuario de Instagram debe contener entre 5-30 caracteres alfanuméricos o "_"',
  'Name contains not allowed characters':
    'El nombre contiene caracteres no permitidos',
  TYPE_DOLLAR_TO_VARIABLES: 'Escribe $ para ver las variables disponibles',
  'No conversations': 'No hay conversaciones',
  'Queues-Users': 'Colas-Usuarios',
  'Copy Robinson list': 'Copiar lista Robinson',
  'Open in Maps': 'Abrir en Maps',
  'All aspects related to the email can be managed here':
    'Todos los aspectos relacionados con email se pueden manejar aqui',
  'Email Account': 'Cuenta de Email',
  'Email Signature': 'Firma de Email',
  'Add Email Account': 'Crear una cuenta de Email',
  'Add Email Signature': 'Crear una firma de Email',
  Reason: 'Motivo',
  Reassign: 'Reasignar',
  'Filter by name': 'Filtrar por nombre',
  'Save and Assign': 'Guardar y asignar',
  'Recommended contacts': 'Contactos recomendados',
  'Please type an extension': 'Por favor introduzca una extension',
  'Extension must be at least 3 digits long':
    'La extension debe tener mínimo 3 dígitos',
  'Extension should be numeric': 'La extensión debe ser numérica',
  'Please type upper limit': 'Por favor introduzca el limite superior',
  'Please type lower limit': 'Por favor introduzca el limite inferior',
  'Upper limit should be higher than the lower':
    'El limite superior debe ser mayor que el inferior',
  'The mesage template was successfully sent':
    'La plantilla de mensaje se envió correctamente.',
  'Deleted message': 'Mensaje borrado',
  'Reply to': 'Responder a',
  'Voice message': 'Mensaje de voz',
  Location: 'Ubicación',
  Photo: 'Foto',
  Video: 'Vídeo',
  Editing: 'Editando',
  Edited: 'Editado',
  'Unsupported message': 'Mensaje no soportado',
  'The is no approved templates': 'No hay plantillas aprobadas.',
  'Use this dialog window to see the contact info':
    'Use esta ventana para ver la información del contacto.',
  'Ticket name singular': 'Nombre del ticket en singular',
  'Ticket name plural': 'Nombre de los tickets en plural',
  'Ticket display name is required':
    'El nombre para referirse a los tickets es requerido',
  'Contact info': 'Información de contacto',
  'Conversations report': 'Informes de conversaciones',
  'Conversation Report': 'Informe de la conversación',
  'Use this dialog window to analyze the conversation info':
    'Use esta ventada para analizar la información de la conversación',
  'Creation Day': 'Día de creación',
  'Modification Day': 'Día de modificación',
  'Overall Duration': 'Duración total',
  'New assigned conversation': 'Nueva conversación asignada',
  'New WhatsApp assigned conversation':
    'Nueva conversación de WhatsApp asignada',
  'New assigned Telegram conversation':
    'Nueva conversación de Telegram asignada',
  'New assigned Instagram conversation':
    'Nueva conversación de Instagram asignada',
  'New assigned WebChat conversation': 'Nueva conversación de WebChat asignada',
  ' New assigned Email conversation': 'Nueva conversación de Email asignada',
  'New assigned Call conversation': 'Nueva conversación de llamada asignada',
  'These are the extensions': 'Estas son las extensiones',
  'These are the extensions groups': 'Estos son los grupos de extensiones.',
  'Extensions Groups': 'Grupos de extensiones',
  'Create extensions groups': 'Crear grupos de extensiones',
  Approve: 'Aprobar',
  'Paste from clipboard': 'Pegar desde el portapapeles',
  'Upload file': 'Selecccionar archivo',
  'Select a file or use Ctrl + V to previsualize':
    'Selecciona un archivo o usa Ctrl + V para previsualizar',
  Import: 'Importar',
  'No accounts available': 'No hay cuentas disponibles',
  Channels: 'Canales',
  'Click on the CTI bar to start a new conversation.':
    'Pulsa sobre la barra CTI para empezar a contactar.',
  'No conversations assigned': 'No tienes conversaciones asignadas',
  'Open CTI bar': 'Abrir barra CTI',
  'The WhatsApp templates was successfully updated':
    'Las plantillas de WhatsApp se actualizaron exitosamente',
  'Select one or click on the CTI bar to start a new conversation.':
    'Selecciona una o pulsa sobre la barra CTI para empezar a contactar.',
  'No conversations selected': 'No tienes ninguna conversación seleccionada',
  'Upload questions': 'Subir preguntas',
  'Enter a name for your question group and add questions or upload a file':
    'Introduce un nombre para tu grupo de preguntas y añade preguntas o sube un archivo',
  'Create questions': 'Crear preguntas',
  'Group name': 'Nombre del grupo',
  'This conversation is already closed': 'Esta conversación ya está cerrada',
  'Open conversation': 'Abrir conversación',
  Questions: 'Preguntas',
  'Type your question here': 'Introduce tu pregunta aquí',
  Question: 'Pregunta',
  'Add question': 'Añadir pregunta',
  'You can only upload questions for completed analyzed recordings':
    'Solo puedes subir preguntas para analisis con estado completado',
  'CSV should follow this format: GROUP_NAME,QUESTION_1, QUESTION_2,...':
    'El CSV debe seguir este formato: NOMBRE_GRUPO,PREGUNTA_1, PREGUNTA_2,...',
  'Questions uploaded successfully': 'Preguntas subidas correctamente',
  'There must be one field checked to select the contacts related.':
    'Debe haber un field señalado para poder buscar los contactos relacionados.',
  'Questions Groups': 'Grupos de preguntas',
  'Here you can find questions groups':
    'Aquí puedes encontrar los grupos de preguntas',
  'Create a new questions groups': 'Crear nuevo grupos de preguntas',
  'Automatically sent greeting message when new conversation starts':
    'Mensaje de bienvenida enviado automaticamente al iniciar una nueva conversacion',
  'Initial automessage': 'Mensaje de bienvenida',
  'Email signature': 'Firma de email',
  'There is more agents assigned to this conversation.':
    'Hay más agentes asignados a esta conversación.',
  'Use this to close the conversation.':
    'Utiliza ese selector para cerrar la conversación.',
  'The templates could not be loaded': 'No se pudieron cargar las plantillas.',
  'Select a template': 'Selecciona una plantilla',
  'Type a message': 'Escribe un mensaje',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (@, !, #, %, &, *)':
    'Debe contener 8 caracteres, una mayúscula, una minúscula, un número y un carácter de caso especial (@, !, #, %, &, *)',
  'Password cannot contain username':
    'La contraseña no puede contener el nombre de usuario',
  'Show password': 'Mostrar contraseña',
  'No messages yet': 'Aún no hay mensajes',
  'Start the conversation with the AI writing a message below':
    'Empieza la conversación con la IA escribiendo un mensaje abajo',
  'Fill the fields below manually or use generative ai to create a new model':
    'Rellena los campos manualmente o usa la inteligencia artificial para crear un nuevo modelo',
  'Fill the fields below manually or use generative ai to update a new model':
    'Rellena los campos manualmente o usa la inteligencia artificial para actualizar un modelo',
  'New intention': 'Nueva intención',
  'There is a minimum of 3 intentions and 5 tags per intention.':
    'Se requiere un mínimo de 3 intenciones y 5 etiquetas por intención.',
  'Enter intention name': 'Introduce el nombre de la intención',
  'Are you sure to close this conversation?':
    '¿Estás seguro de cerrar esta conversación?',
  'There are more agents assigned to this conversation':
    'Hay más agentes asignados a esta conversación',
  Home: 'Inicio',
  'When email channel goes through this node nothing will happen':
    'Cuando un canal de tipo email pase a través de este nodo no tendrá ningún efecto',
  'Use this dialog window to create new Whatsapp template':
    'Utilice esta ventana para crear una nueva plantilla de Whatsapp',
  'New Whatsapp template': 'Nueva plantilla de Whatsapp',
  'Create robinson list': 'Crear lista robinson',
  'Use this dialog window to create a new robinson list':
    'Use esta ventana de diálogo para crear una lista robinson',
  'Use this dialog window to edit a robinson list':
    'Use esta ventana de diálogo para crear una lista robinson',
  'Enter tag name': 'Introduce el nombre de la etiqueta',
  'Add tag': 'Agregar etiqueta',
  'Generate tags with AI': 'Generar etiquetas con IA',
  Appointments: 'Citas',
  'Client support': 'Soporte al cliente',
  'Debt recovery': 'Recuperación de deudas',
  'Statistic study': 'Estudio estadístico',
  Telemarketing: 'Telemarketing',
  'Use case': 'Caso de uso',
  'Generating tags': 'Generando etiquetas',
  'Generate tags': 'Generar etiquetas',
  'Number of tags': 'Número de etiquetas',
  'Enter prompt to generate tags': 'Introduce la frase para generar etiquetas',
  'Add Intention': 'Añadir intención',
  'Subtypology status': 'Estado de subtipología',
  Participants: 'Participantes',
  Intentions: 'Intenciones',
  'Minimum one channel required': 'Se requiere mínimo un canal',
  Tags: 'Etiquetas',
  Analyzed: 'Analizado',
  Analizyng: 'Analizando',
  'View questions': 'Ver preguntas',
  'There is more than one matching contact for this conversation.':
    'Hay más de un contacto con campos similares a los de la conversación.',
  'Showing default variable values.':
    'Mostrando valores por defecto de las variables',
  'Use username': 'Usar nombre de usuario',
  'Delete Field': 'Eliminar Campo',
  'Add button': 'Agregar botón',
  'The text is required': 'El texto es requerido.',
  'Use this dialog window to create new whatsapp template':
    'Utilice esta ventana de diálogo para crear una nueva plantilla de WhatsApp',
  'You must add at least one button': 'Debes agregar al menos un botón',
  'Agent first name': 'Nombre del agente',
  'Agent last name': 'Apellidos del agente',
  'Agent phone number': 'Teléfono del agente',
  'Ticket id': 'Id de ticket',
  'Conversation tickets': 'Tickets de la conversación',
  'Conversation modification date': 'Fecha de modificación de la conversación',
  'Ticket creation date': 'Fecha de creación del ticket',
  'Ticket modification date': 'Fecha de modificación del ticket',
  'Contact creation date': 'Fecha de creación del contacto',
  'Contact modification date': 'Fecha de modificación del contacto',
  'Account ID': 'ID de la cuenta',
  'Ticket IDs': 'IDs del ticket',
  'Hang up code': 'Código de colgado',
  'Recorded?': '¿Grabada?',
  'Overall duration': 'Duración total',
  'Call launch database': 'Base de datos de lanzamiento',
  'Call launch file name': 'Nombre del fichero de lanzamiento',
  'Call launch file upload date': 'Fecha de subida del fichero de lanzamiento',
  'Call launch file status': 'Estado del fichero de lanzamiento',
  Tab: 'Pestaña',
  'Download questions': 'Descargar preguntas',
  words: 'palabras',
  'All aspects related to the contacts can be managed here':
    'Todos los aspectos relacionados con los contactos se pueden gestionar desde aquí',
  Downloads: 'Descargas',
  'All aspects related to the downloads can be managed here':
    'Todos los aspectos relacionados con las descargas se pueden gestionar aquí',
  'No keywords found': 'No se encontraron palabras clave',
  Analysis: 'Analisis',
  Answer: 'Respuesta',
  'Add account': 'Añadir cuenta',
  'Add style': 'Agregar estilo',
  'Add pre written message': 'Agregar mensaje escrito previamente',
  Services: 'Servicios',
  'Use this dialog window to create a new service':
    'Utilice esta ventana de diálogo para crear un nuevo servicio.',
  'First Login': 'Primer Acceso',
  'Last Login': 'Último Acceso',
  'First Logout': 'Primer Cierre de Sesión',
  'Last Logout': 'Último Cierre de Sesión',
  'Formatted Duration': 'Duración Formateada',
  Timestamp: 'Fecha de conexión',
  'Current status type': 'Estado actual (tipo)',
  'Previous status type': 'Estado previo (tipo)',
  'Current status name': 'Estado actual (nombre)',
  'Previous status name': 'Estado previo (nombre)',
  'All aspects related to the Workday reports can be managed here':
    'Todos los aspectos relacionados con los reportes de jornadas se pueden gestionar desde aquí',
  'Please enter a shortcode for the company':
    'Por favor introduzca una extensión',
  'Please enter a company name': 'Por favor introduzca un nombre de empresa',
  'Please enter extensions number':
    'Por favor introduzca un número de licencias',
  'Extension number must be greater or equal 100':
    'El número de extensión debe ser mayor o igual a 100',
  'Please enter the upper value': 'Por favor introduzca cota superior',
  'Upper value has to be greater than the lower value':
    'La cota superior debe ser mayor a la inferior',
  'Unique field': 'Campo único',
  'Please enter a name for the status':
    'Por favor introduzca un nombre de estado',
  Revoked: 'Revocado',
  'Revoke token': 'Revocar Token',
  'Delete API Key': 'Eliminar clave de la api',
  'The api Key was revoked': 'La clave de la api fue revocada',
  'The api Key was deleted': 'La clave de la api fue eliminada',
  'API Keys': 'Claves de API',
  'Here you can find the API keys': 'Aquí puedes encontrar las claves de API',
  'Create a new API Key': 'Crear una nueva clave de API',
  'The name must contain only lowercase letters and no spaces, use _ instead':
    'El nombre no puede contener mayúsculas, caracteres especiales o espacios, usa _ en su lugar',
  'Has expiration': 'Tiene expiración',
  'Please select an user': 'Por favor seleccona un usuario',
  'The api key was created': 'La clave de la Api fue creada',
  'All aspects related to the tickets can be managed here':
    'Todos los aspectos relacionados con las tickets se pueden gestionar aquí',
  'Add typologies': 'Agregar tipología',
  'Add ticket': 'Añadir ticket',
  'Add fields': 'Añadir campo',
  Typologies: 'Tipologías',
  Fields: 'Campos',
  Park: 'Aparcar',
  'Search by extension or name': 'Buscar por extensión o nombre',
  'Order by': 'Ordenar por',
  'The API key was created': 'La clave de API fue creada',
  'The API key was copied to clickboard': 'La clave de API fue copiada',
  'Token information': 'Información del token',
  'Show API Key': 'Mostrar clave de API',
  'Hide API Key': 'Ocultar clave de API',
  'Copy API Key': 'Copiar clave de API',
  State: 'Estado',
  'Optional but recommended.': 'Opcional pero recomendado.',
  'Details about': 'Detalles sobre',
  'Conversations of': 'Conversaciones de',
  'Ring strategy': 'Estrategia de llamada',
  'Music on hold': 'Música de espera',
  'Retry time': 'Tiempo de reintento',
  'Send Template': 'Enviar plantilla',
  'You have to select a template': 'Se debe seleccionar una plantilla',
  'Selected template params': 'Parámetros de la plantilla seleccionada',
  'Please select a template': 'Por favor selecciona una plantilla',
  'Please save the token, once you close this screen wont be able to get it again':
    'Por favor guardar el token, una vez se cierre esta pantalla no tendrás acceso otra vez',
  'All aspects related with roles and permissions are managed here':
    'Todos los aspectos relacionados con roles y permisos se pueden gestionar aquí',
  'Add custom role': 'Añadir rol personalizado',
  'Create new custom role': 'Crear nuevo rol personalizado',
  'Edit role': 'Editar rol',
  'Use this dialog window to edit a role':
    'Utilice esta ventana de diálogo para editar un rol',
  'Use this dialog window to add a new role':
    'Utilice esta ventana de diálogo para agregar un nuevo rol',
  Template: 'Plantilla',
  'Template of type image': 'Plantilla de tipo imagen',
  'Template of type document': 'Plantilla de tipo documento',
  'This template has no variables': 'Esta plantilla no tiene variables',
  'Create new custom rol': 'Crea un nuevo rol customizado',
  'Copy permissions from': 'Copiar permisos de',
  Permissions: 'Permisos',
  Productivity: 'Productividad',
  'Not allowed': 'No permitido',
  Paused: 'Pausado',
  'If a conversation from a different channel other than WhatsApp goes through this node, nothing will happen':
    'Si una conversación de un canal diferente a WhatsApp pasa por este nodo, no ocurrirá nada',
  'If a conversation from a different channel other than':
    'Si una conversación de un canal diferente a',
  'goes through this node, nothing will happen':
    'pasa por este nodo, no ocurrirá nada',
  'No templates available': 'No hay plantillas disponibles',
  Image: 'Imagen',
  'Write here the time group description...':
    'Escriba aquí la descripcion del grupo de tiempo...',
  'Share screen': 'Compartir pantalla',
  'Stop sharing screen': 'Parar de compartir pantalla',
  "We're working on implementing the last features before our launch!":
    '¡Estamos trabajando en implementar las últimas características antes de nuestro lanzamiento!',
  'Go back to inbox': 'Volver al inbox',
  'Coming soon': 'Próximamente',
  'Mute socialmedia': 'Mutear socialmedia',
  'Log out': 'Cerrar sesión',
  'Contact center': 'Centro de contactos',
  Inbox: 'Bandeja de entrada',
  'Filter Conversations': 'Filtrar Conversaciones',
  'Contact Groups': 'Grupos de Contacto',
  'Keep bar extended': 'Mantener la barra extendida',
  'Keep extended and lock': 'Mantener extendido y fijar',
  Unlock: 'Desbloquear',
  Autoanswer: 'Contestar automáticamente',
  'Attend calls when clicking on the browser notification':
    'Contestar llamadas al pinchar en la notificación del navegador',
  'Select ringtones and sounds': 'Seleccionar tonos de llamada y sonidos',
  'Select ringtone': 'Seleccionar tono de llamada',
  'Select telegram sound': 'Seleccionar tono de telegram',
  'Select email sound': 'Seleccionar tono de email',
  'Select chat sound': 'Seleccionar tono de chat',
  'Select whatsapp sound': 'Seleccionar sonido de whatsapp',
  Default: 'Predeterminado',
  Recents: 'Recientes',
  'Refresh view': 'Refrescar vista',
  'The report': 'El reporte',
  'has been generated succesfully!': 'ha sido generado exitosamente',
  'could not be generated!': 'no se ha podido generar',
  'Transfer conversation': 'Transferir conversación',
  'Click to add a new ticket': 'Haga click para añadir un nuevo ticket',
  'Contact qualification': 'Calificación de contactos',
  'All aspects related to the contact qualification reports can be managed here':
    'Todos los aspectos relacionados con los reportes de calificación pueden ser gestionados aquí',
  'All aspects related to the ticket reports can be managed here':
    'Todos los aspectos relacionados con los reportes de tickets pueden ser gestionados aquí',
  'A powerdialer campaign is needed in the service.':
    'Es necesario tener una campaña powerdialer en el servicio.',
  'All aspects related to the calls reports can be managed here':
    'Todos los aspectos relacionados con reportes de llamadas pueden ser gestionados aquí',
  'All aspects related to the activity reports can be managed here':
    'Todos los aspectos relacionados con la actividad de los agentes pueden ser gestionados aquí',
  Empty: 'Vacío',
  'There was an error, please try again later':
    'Hubo un error, por favor inténtalo de nuevo más tarde',
  'The server was not able to complete your request':
    'El servidor no ha sido capaz de completar tu petición',
  Activity: 'Actividad',
  'Workday Reports': 'Reportes de jornada',
  'Work day': 'Jornada',
  'All aspects related to company management can be managed here':
    'Todos los aspectos relacionados con gestión de compañías pueden gestionarse aquí',
  'All aspects related to the caller ids can be managed here':
    'Todos los aspectos de los identificadores de agentes pueden gestionarse aquí',
  'Default login status': 'Estado por defecto al iniciar sesión',
  'Add to one or more extensions groups':
    'Añadir uno o más grupos de extensiones',
  'Occuppied extensions': 'Extensiones ocupadas',
  'Please, select a company': 'Por favor seleccione una companía',
  'Use this dialog window to add a new extensions group':
    'Use este diálogo para crear nuevos grupos de extensiones',
  'Select from range': 'Seleccionar rango',
  'Retype password': 'Reescribir la contraseña',
  'Create extensions group': 'Crear grupo de extensiones',
  'Create new Caller Id': 'Crear nuevo identificador',
  'Basic data': 'Datos generales',
  'Recordings settings': 'Ajustes de grabación',
  'Maximum open tickets per contact': 'Máximo de tickets abiertos por contacto',
  'Modify closed tickets': 'Modificar tickets cerrados',
  'Roles to notify': 'Roles notificados',
  'Ticket typologies configuration': 'Contiguración de tipologías',
  'Chatting with agent': 'Chat con un agente',
  'Receiving server': 'Servidor de recepción',
  'Outgoing server': 'Servidor de envío',
  'Retrieve old messages': 'Retirar mensajes antiguos',
  'Email address': 'Dirección de correo',
  'Username field is required': 'Por favor introduzca un nombre de usuario',
  'Email address field is required':
    'Por favor introduzca una dirección de correo',
  'Password field is required': 'Por favor introduzca una contraseña',
  Port: 'Puerto',
  'Default prefix': 'Prefijo por defecto',
  'Port field is required': 'Por favor introduzca un número de puerto',
  'Host field is required': 'Por favor introduzca un IP de Host',
  'Delete read': 'Eliminar leídos',
  'Create email account': 'Crear cuenta de correo',
  'All aspects related to Telegram can be managed from this page':
    'Todos los aspectos de las cuentas de Telegram pueden gestionarse aquí',
  'Add Telegram account': 'Añadir cuenta de Telegram',
  'New Telegram account': 'Nueva cuenta de Telegram',
  'Use this dialog window to add a new Telegram account':
    'Use este diálogo para crear una nueva cuenta de Telegram',
  'Confirmation code': 'Código de confirmación',
  'Send code': 'Enviar código',
  'Resend code': 'Reenviar código',
  Provider: 'Proveedor',
  'API key': 'Clave de API',
  Admin: 'Administrador',
  buttons: 'botones',
  'What the contact will see:': 'Lo que verá el contacto:',
  'Add Contact Field': 'Añadir campo de contacto',
  'Remove Contact Field': 'Eliminar campo de contacto',
  'Select Contact Field': 'Seleccionar campo de contacto',
  'Change Contact Field Label': 'Cambiar etiqueta del campo de contacto',
  'Create new Tag': 'Crear nueva etiqueta',
  'The route': 'La ruta',
  'was deleted': 'ha sido eliminada',
  'Use this dialog window to add a new tag':
    'Use esta ventana para crear una nueva etiqueta',
  'Route cloned succesfully': 'Ruta clonada con éxito',
  'The route was not deleted': 'La ruta no fue eliminada',
  'The route was not disabled.': 'La ruta no fue deshabilitada.',
  'Text cannot be just blank spaces':
    'El texto no puede ser solo espacios en blanco',
  'Extension group': 'Grupo de extensiones',
  'Create new extensions group': 'Crear un nuevo grupo de extensiones',
  'Edit extensions group': 'Editar grupo de extensiones',
  'Use this dialog window to edit this extensions group':
    'Use este diálogo para editar el grupo de extensiones',
  Pinned: 'Fijado',
  'Create new Caller id': 'Crear un nuevo identificador',
  'Use this dialog window to add new typology or sub-typology':
    'Use esta ventana de diálogo para añadir una nueva tipología o subtipología',
  'Create new typology': 'Crear una nueva tipología',
  'Typology name is required': 'El nombre de la tipología es necesario',
  'Create new email account': 'Crear nueva cuenta de email',
  'Use this dialog window to add a new email account':
    'Use esta ventana de diálogo para crear una nueva cuenta de email',
  'Contact without name': 'Contacto sin nombre',
  'Edit email account': 'Editar cuenta de email',
  'Warning! Enabling the retrieval of old messages will download all the messages from the account.':
    '¡Cuidado! Permitir la recuperación de mensajes antiguos descargará todos los correos de la cuenta.',
  'Click to add a new role': 'Click para añadir un nuevo rol',
  'Use this dialog window to create a new API Key':
    'Use esta ventada de diálogo para crear una nueva clave de API',
  'Select user': 'Seleccionar usuario',
  'Move Down': 'Mover hacia abajo',
  'Move Up': 'Mover hacia arriba',
  'Show Editor': 'Mostrar editor',
  'Preview HTML': 'Vista previa HTML',
  'Image URL': 'URL de la imagen',
  'Insert Image': 'Insertar imagen',
  'Insert Link': 'Insertar enlace',
  'Modify link': 'Modificar enlace',
  'Label is optional': 'La etiqueta es opcional',
  'Unable to load image': 'No se ha podido cargar la imagen',
  'The dialerbot will be created once the service is created':
    'El dialerbot sera agregado una vez el servicio sea creado',
  'The campaign will be created once the service is created':
    'La campaña será añadida una vez el servicio sea creado',
  'Role name': 'Nombre del rol',
  'Revoke 2FA': 'Revocar 2FA',
  'Create new ticket field': 'Crear nuevo campo',
  'Use this dialog window to add a new ticket field':
    'Use esta ventana de diálogo para crear un nuevo campo',
  'Field name': 'Nombre del campo',
  Operator: 'Operador',
  'Visibility dependencies': 'Dependencias de visibilidad',
  'Update ticket field': 'Actualizar campo',
  'Use this dialog window to update a ticket field':
    'Use esta ventana de diálogo para actualizar un campo',
  'Create new ticket tag': 'Crear nueva etiqueta',
  'Use this dialog window to add a new ticket tag':
    'Use esta ventana de diálogo para crear una nueva etiqueta',
  'Configure two-factor authentication (2FA) on next login':
    'Configurar la autenticación de dos factores (2FA) en el próximo inicio de sesión',
  'Force the use of 2FA for all users':
    'Forzar el uso de autenticación en dos pasos para todos los usuarios',
  'Tag name': 'Nombre de la etiqueta',
  'Default Status': 'Estado por defecto',
  'Tag color': 'Color de la etiqueta',
  'Update ticket tag': 'Actualizar etiqueta',
  'Use this dialog window to update a ticket tag':
    'Use esta ventana de diálogo para acutalizar una nueva etiqueta',
  'Name here...': 'Escribe aquí el nombre...',
  'Description here...': 'Escribe aquí la descripción...',
  'Use this dialog window to update robinson list':
    'Use esta ventana de diálogo para actualizar una lista robinson',
  'Use this dialog window to create new robinson list':
    'Use esta ventana de diálogo para crear una lista robinson',
  'Phone number here...': 'Escribe aquí tu número de teléfono...',
  'Use this dialog window to add and delete numbers from the robinson list':
    'Use esta ventana de diálogo para añadir y borrar números de la lista robinson',
  'New ticket': 'Nueva incidencia',
  'Use this dialog window to create a ticket':
    'Use esta ventana de diálogo para crear una nueva incidencia',
  'Use this dialog window to edit a ticket':
    'Use esta ventana de diálogo para editar una incidencia',
  'Create a new ticket': 'Crear una nueva incidencia',
  'Upload tickets via CSV': 'Subir incidencias con CSV',
  'Create ticket': 'Crear incidencia',
  'Sub-typology': 'Subtipología',
  'Upload ticket CSV': 'Subr CSV de incidencias',
  'Link ticket fields': 'Relacionar los campos',
  'Column delimiter': 'Delimitador de columna',
  'List delimiter': 'Delimitador de lista',
  'Default typology': 'Tipología por defecto',
  'Title here...': 'Escribe aquí el título...',
  'Ticket tags': 'Etiquetas del ticket',
  'Assigned users here...': 'Escribe aquí los usuarios asignados...',
  'Add a new comment here...': 'Escribe aquí el comentario...',
  'added the conversation': 'ha añadido la conversación',
  'Copy from campaign': 'Copiar de una campaña',
  'Powerdialer configuration': 'Configuración de Powerdialer',
  'The contact group is required': 'Seleccione un grupo de contacto',
  'Temporal ranges': 'Intervalos temporales',
  "Select a contact group to edit recording's name":
    'Seleccione un grupo de contacto para editar el nombre de las grabaciones',
  'Use $ to add variables': 'Use $ para añadir variables',
  'Create WebChat account': 'Crear cuenta de WebChat',
  'Update WebChat account': 'Actualizar cuenta de WebChat',
  'Verify Telegram account': 'Verificar cuenta de Telegram',
  'Use this dialog window to verify a Telegram account':
    'Use esta ventana de diálogo para verificar una cuenta de Telegram',
  Previous: 'Anterior',
  'Start a new conversation from this ticket!':
    '¡Empieza una nueva conversación desde el ticket!',
  'Add a temporal range': 'Añade un intervalo temporal',
  'Configure Two-Factor Authentication':
    'Configura la autenticación en dos pasos',
  'Enter authenticator code': 'Introduce el código del autenticador',
  'Change the contact group can misconfigure the form':
    'Cambiar el grupo de contactos puede desconfigurar el formulario',
  'Template parameters': 'Parámetros de la plantilla',
  'Enter variable value': 'Introduzca el valor de la variable',
  'Deselect template': 'Deseleccionar plantilla',
  'Please select an attachment': 'Por favor seleccione un archivo',
  'Please fill all the variables': 'Por favor rellene todas las variables',
  'Last email date': 'Fecha del último correo electrónico',
  'Email account address': 'Dirección de cuenta de correo electrónico',
  'Last email sender': 'Último remitente de correo electrónico',
  'Last email subject': 'Asunto del último correo electrónico',
  Details: 'Detalles',
  'Load more conversations': 'Cargar más conversaciones',
  Expiration: 'Expiración',
  'These are the keywords groups': 'Estos son los grupos de palabras clave',
  'Use this dialog window to create a keywords group':
    'Use esta ventana de diálogo para crear un grupo de palabras clave',
  'Use this dialog window to update a keywords group':
    'Use esta ventana de diálogo para editar un grupo de palabras clave',
  'Add new time range': 'Añadir nuevo intervalo de tiempo',
  'Use this dialog window to add a new recording':
    'Use esta ventana de diálogo para añadir una nueva grabación',
  'Use this dialog window to evaluate a conversation':
    'Use esta ventana de diálogo para evaluar una conversación',
  'Use this dialog window to see the questions':
    'Use esta ventana de diálogo para ver las preguntas',
  'Use this dialog window to add new contacts':
    'Use esta ventana de diálogo para añadir nuevos contactos',
  'Update tag': 'Actualizar etiqueta',
  'to edit': 'para editar',
  'Use this dialog window to update a tag':
    'Use esta ventana de diálogo para editar una etiqueta',
  'Create new contact group': 'Crear nuevo grupo de contactos',
  'Use this view to create a new contact group':
    'Use esta ventana de diálogo para añadir un nuevo grupo de contactos',
  'Asociate color': 'Color asociado',
  'Update contact group': 'Actualizar grupo de contactos',
  'Use this view to update the contact group':
    'Use esta ventana de diálogo para editar un grupo de contactos',
  'Contact Tags': 'Etiquetas de contacto',
  'Or enter your data manually': 'O introduce tus datos manualmente',
  'Login with Facebook': 'Iniciar sesión con Facebook',
  'Account configuration': 'Configuración de la cuenta',
  'Or choose another provider': 'O elige otro proveedor',
  'Complete the facebook login to continue':
    'Completa el inicio de sesión de Facebook para continuar',
  'Facebook successfully connected': 'Facebook conectado con éxito',
  Disconnect: 'Desconectar',
  "When a ticket typology is changed its assigned conversations will be closed automatically. If a conversation has more than one ticket won't do any effect":
    'Cuando se cambie la tipología de un ticket sus conversaciones asignadas se cierran automáticamente. Si la conversación tiene más de un ticket no tiene efecto',
  "Ticket's general configuration": 'Configuración general de tickets',
  'Allow close conversations if the assigned ticket is still open':
    'Permitir cerrar conversaciones si el ticket asignado está abierto',
  'Create new contact': 'Crear nuevo contacto',
  'To enter variables text create the announcement and add it to an existent route.':
    'Para añadir el texto con variables cree el anuncio y añádalo a una ruta existente.',
  'Conversations notes': 'Notas de la conversación',
  'Save changes': 'Guardar cambios',
  'No results': 'No hay resultados',
  'Edit Note': 'Editar nota',
  'Do you want to cancel this note?': 'Quieres cancelar esta nota?',
  'Are you sure do you want to delete this note?':
    'Estas seguro de eliminar esta nota?',
  'Helper Panel - Notes': 'Panel de ayuda - notas',
  'Click to add a new note': 'Clic para agregar nueva nota',
  'Are you sure?': 'Estas seguro?',
  'Unsaved changes will disappear.': 'Cambios sin guardar desapareceran.',
  'Save and exit': 'Salvar y salir',
  'Call until Answer': 'LLamar hasta que conteste',
  'Upload CSV with contacts': 'Subir CSV con contactos',
  'Call until answer': 'Llamar hasta que conteste',
  'Call All': 'Llamar a todos',
  'You do not have permissions': 'No tiene permisos',
  'Table (below)': 'Tabla (debajo)',
  'Table (right)': 'Tabla (derecha)',
  Legend: 'Leyenda',
  'Select a valid a channel': 'Selecciona un canal válido',
  'Start a new conversation': 'Empezar una nueva conversación',
  Grouped: 'Agrupado',
  'Nothing on queue': 'Nada en la cola',
  'Manage all the variables': 'Administrar todas las variables',
  'Edit variable': 'Editar variable',
  'Edit announcement': 'Editar anuncio',
  'Use this dialos window to edit an announcement':
    'Use esta ventana de diálogo para editar un anuncio',
  'Write here the announcement name...':
    'Escriba aquí el nombre del anuncio...',
  'Audio transcription': 'Transcripción del audio',
  'Announcement text field is required.':
    'El campo del texto del anuncio es necesario.',
  'Voice gender': 'Género de la voz',
  'Voice type': 'Tipo de voz',
  'Announcement field name must contain characters.':
    'El nombre del anuncio debe contener algún caracter.',
  'Use this dialog window to edit an announcement':
    'Use esta ventana de diálogo para editar un anuncio.',
  'These are the created announcements':
    'Aquí se muestran los anuncios creador.',
  'Existing contacts': 'Contactos Existentes',
  'Add time range': 'Añadir rango de tiempo',
  'Existing time range': 'Rango de tiempo existente',
  'New time range': 'Nuevo rango de tiempo',
  'Create new element': 'Crear nuevo elemento',
  'Update element': 'Actualizar elemento',
  'Create new account': 'Crear nueva cuenta',
  'Turn on camera': 'Activar cámara',
  'Turn off camera': 'Desactivar cámara',
  Deafen: 'Silenciar',
  Undeafen: 'Dejar de silenciar',
  'Turn on microphone': 'Activar micrófono',
  'Turn off microphone': 'Desactivar micrófono',
  'This user is superadmin': 'Este usuario es superadmin',
  'Only for superadmins': 'Visible para superadmins',
  'Default User': 'Usuario base',
  'Max interactions': 'Interacciones max',
  'Knowledge model': 'Modelo de conocimiento',
  'Knowledge Model': 'Modelo de Conocimiento',
  'Assign to same agent': 'Asignar al mismo agente',
  Function: 'Función',
  'Function node settings': 'Configuración de nodo de función',
  Evaluate: 'Evaluar',
  Minimize: 'Minimizar',
  Maximize: 'Maximizar',
  Validate: 'Validar',
  Run: 'Ejecutar',
  Code: 'Código',
  'No maximum limit': 'Sin límite máximo',
  'Set the maximum limit': 'Establecer el límite máximo',
  'Validate code': 'Validar código',
  'There are errors in the code': 'Hay errores en el código',
  'Code is valid': 'Código válido',
  'Review code errors on the editor':
    'Revisa los errores del código en el editor',
  Day: 'Día',
  Month: 'Mes',
  Year: 'Año',
  'Remove variable': 'Quitar variable',
  'Legend position': 'Posición de leyenda',
  'No channels available': 'No tienes canales disponibles',
  'You must be assigned to an outbound campaign':
    'Debes estar asignado a una campaña saliente',
  'No access to microphone': 'Sin acceso al micrófono',
  'Grant access': 'Permitir acceso',
  'Grant access to your microphone to attend the upcoming calls':
    'Habilita el  acceso al micrófono para poder atender las próximas llamadas',
  'To enable it, please go to your browser settings, find the microphone permissions, and select "Allow" for this site':
    'Para habilitarlo, ve a la configuración de tu navegador, encuentra los permisos del micrófono y selecciona "Permitir" para este sitio',
  'There was an error while trying to grant access to the microphone':
    'Hubo un error al intentar permitir el acceso al micrófono',
  'This conversation has already been analyzed, do you want to generate it again?':
    'Esta conversación ya ha sido analizada, ¿quieres analizarla de nuevo?',
  'This will overwrite the previous analysis':
    'Esto sobreescribirá el análisis anterior',
  'View analysis': 'Ver análisis',
  'Analyze again': 'Analizar de nuevo',
  'Generate analysis': 'Generar análisis',
  'Generate again': 'Generar de nuevo',
  'In firefox you must reload the page to enable the microphone after granting access':
    'En Firefox debes recargar la página para habilitar el micrófono después de conceder acceso',
  'No templates found for this campaign':
    'No se encontraron plantillas para esta campaña',
  'All aspects related to the call statistics can be seen here':
    'Todos los aspectos relacionados con los detalles de llamadas se pueden ver aquí',
  "Select column's time unit": 'Seleccionar medida de tiempo de las columnas',
  'Pending tickets': 'Tickets pendientes',
  Generate: 'Generar',
  'These tickets must be closed before closing the conversation':
    'Estos tickets deben cerrarse para poder cerrar la conversación',
  'Go to conversation': 'Ir a la conversación',
  'No queues found': 'No hay colas disponibles',
  'No users found': 'No hay usuarios disponibles',
  'Skip character can be a number': 'El carácter puede ser un número',
  or: 'o',
  'No involved contacts': 'No hay contactos',
  True: 'Verdadero',
  False: 'Falso',
  'Create new script': 'Crear nuevo guión',
  'Character to skip': 'Carácter para omitir',
  'Digit limit': 'Límite de dígitos',
  Retries: 'Reintentos',
  'Response time': 'Tiempo de respuesta',
  'Idle timeout': 'Tiempo de inactividad',
  'Speak timeout': 'Tiempo de habla',
  'Time in minutes to wait for a response from the user':
    'Tiempo en minutos para esperar una respuesta del usuario',
  'Chat response time': 'Tiempo de respuesta del chat',
  'Remove timeout': 'Quitar tiempo de respuesta',
  'API Request': 'Petición API',
  'API Request configuration': 'Configuración de la petición API',
  Redirect: 'Redirección',
  Recording: 'Grabación',
  Operation: 'Operación',
  Clear: 'Limpiar',
  'Copy from service': 'Copiar desde servicio',
  Nuevo: 'Nuevo',
  Draft: 'Borrador',
  'Save script as draft': 'Guardar guión como borrador',
  'Create new': 'Crear nuevo',
  'Create from draft': 'Crear desde borrador',
  'Delete draft': 'Eliminar borrador',
  'Save as draft': 'Guardar como borrador',
  'New available version!': '¡Nueva versión disponible!',
  'Reload this page to get the latest version':
    'Recarga esta página para obtener la última versión',
  'Report created successfully': 'Reporte creado con éxito',
  'Reload this page': 'Recargar esta página',
  'Report updated successfully': 'Reporte actualizado con éxito',
  'Update report': 'Actualizar reporte',
  'Create report': 'Crear reporte',
  'View report': 'Ver reporte',
  'Edit report': 'Editar reporte',
  'Delete selected reports': 'Eliminar reportes seleccionados',
  'No nodes found': 'No se encontraron nodos',
  'Data input': 'Entrada de datos',
  "This conversation's route is closed":
    'La ruta de esta conversación está cerrada',
  'Add tags to all contacts': 'Agregar etiquetas a todos los contactos',
  'Select one tag': 'Seleccionar una etiqueta',
  'Filter by tags': 'Filtrar por etiquetas',
  'No options': 'No hay opciones',
  'Call channel': 'Canal de llamadas',
  'Digital channel': 'Canal digital',
  'AI Rules': 'Reglas de IA',
  'Speech analytics': 'Análisis de voz',
  'External action': 'Acción externa',
  Features: 'Características',
  Contains: 'Contiene',
  'Allow simultaneous launches': 'Permitir lanzamientos simultáneos',
  'Not contains': 'No contiene',
  'Starts with': 'Empieza con',
  'Not starts with': 'No empieza con',
  'Ends with': 'Termina con',
  'Not ends with': 'No termina con',
  Equals: 'Igual a',
  Fails: 'Falla',
  'Ticket fields configuration': 'Configuración de campos de ticket',
  'Ticket tags configuration': 'Configuración de etiquetas de ticket',
  'Not equals': 'No es igual a',
  'Greater than': 'Mayor que',
  'Less than': 'Menor que',
  'Is null': 'Es nulo',
  'Is not null': 'No es nulo',
  'Select month and year': 'Seleccionar mes y año',
  'Show only monthly data of year ': 'Mostrar solo datos mensuales del año  ',
  Billing: 'Facturación',
  'All information related with billing are showed here':
    'Toda la información relacionada con facturación es mostrada aquí',
  'Replace tags': 'Reemplazar etiquetas',
  'Prefix to add': 'Prefijo a añadir',
  'Prefix to remove': 'Prefijo a eliminar',
  'Prefix to remove here..': 'Prefijo a eliminar aquí',
  'Prefix to add here...': 'Prefijo a añadir aquí',
  'Prefix selection': 'Selección de prefijos',
  'The caller id was successfully updated':
    'El identificador fue exitósamente actualizado',
  'The caller id was successfully created':
    'El identificador fue exitósamente creado',
  'Group by year': 'Agrupar por año',
  'Select year': 'Seleccionar año',
  'Average waiting time': 'Tiempo promedio de espera'
};

export default esJSON;
