import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState
} from 'react';
import useVideoRoom, { VideocallContextVideoRoom } from '../hooks/useVideoRoom';
import { RTCSession } from 'jssip/lib/RTCSession';
import { contextWebsocket } from './contextWebsocket';
import {
  stopStream,
  VIDEOCALLS_WEBRTC_STREAM,
  videoMicrophoneDeviceKey
} from '../utils/videocalls-webrtc-devices';
import { VideoroomWSService } from '../infrastructure/websocket/websocket';
import { VideoroomWebRTCService } from '../infrastructure/webrtc';
import AuthManager from 'src/services/authentication/manager';
import { useMediaQuery } from '@mui/material';

export interface VideocallRoomContextProps {
  isMobile: boolean;
  isTablet: boolean;
  toInitialContext?: Function;
  videoRoom?: VideocallContextVideoRoom;
  isLoggedIn?: boolean;
  streams?: {
    local: MediaStream;
    remote: MediaStream;
    setLocalStream: Dispatch<SetStateAction<MediaStream>>;
    setRemoteStream: Dispatch<SetStateAction<MediaStream>>;

    selectedStreamId: string;
    setSelectedStreamId: Dispatch<SetStateAction<string>>;

    amIScreenSharing: boolean;
    setAmIScreenSharing: Dispatch<SetStateAction<boolean>>;
  };
  devices?: {
    speakerDevice: MediaDeviceInfo;
    setSpeakerDevice: Dispatch<SetStateAction<MediaDeviceInfo>>;
    speakerMuted: boolean;
    setSpeakerMuted: Dispatch<SetStateAction<boolean>>;
    speakersVolume: number;
    setSpeakersVolume: Dispatch<SetStateAction<number>>;

    microphoneDeviceId: string;
    setMicrophoneDeviceId: Dispatch<SetStateAction<string>>;
    microphoneMuted: boolean;
    setMicrophoneMuted: Dispatch<SetStateAction<boolean>>;

    facingMode: 'user' | 'environment';
    setFacingMode: Dispatch<SetStateAction<'user' | 'environment'>>;
  };
  phase?: {
    videocallPhase: VideocallPhase;
    setVideocallPhase: Dispatch<SetStateAction<VideocallPhase>>;
    finishedReason: FinishReason;
    setFinishedReason: Dispatch<SetStateAction<FinishReason>>;
  };
  rtcSession?: {
    currentRTCSession: RTCSession;
    setCurrentRTCSession: Dispatch<SetStateAction<RTCSession>>;
  };
  connection?: {
    connectionId: string;
    setConnectionId: Dispatch<SetStateAction<string>>;
  };
  extensionData?: {
    extension?: MutableRefObject<string>;
    password?: MutableRefObject<string>;
    connected: boolean;
    setConnected: Dispatch<SetStateAction<boolean>>;
  };
}

export const VideocallRoomContext = createContext<VideocallRoomContextProps>(
  {} as VideocallRoomContextProps
);

interface Props {
  children: React.ReactNode;
}

export enum VideocallPhase {
  PREROOM,
  INCALL,
  FINISHED
}

export enum FinishReason {
  NORMAL,
  DENIED,
  KICK_OUT,
  IS_FULL,
  ERROR,
  NO_CONNECTION
}

export function VideocallRoomContextProvider(props: Props) {
  const { children } = props;

  const isLoggedIn = !!AuthManager.getUserId() && !!AuthManager.getToken();
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isTablet = useMediaQuery('(max-width: 1024px)');

  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');

  const videoRoom = useVideoRoom(isLoggedIn);

  const [localStream, setLocalStream] = useState(new MediaStream());
  const [remoteStream, setRemoteStream] = useState(new MediaStream());

  const [speakerDevice, setSpeakerDevice] = useState<MediaDeviceInfo>();
  const [speakerMuted, setSpeakerMuted] = useState<boolean>(false);
  const [speakersVolume, setSpeakersVolume] = useState<number>(50);

  const [microphoneMuted, setMicrophoneMuted] = useState<boolean>(false);
  const [microphoneDeviceId, setMicrophoneDeviceId] = useState<string>(
    localStorage.getItem(videoMicrophoneDeviceKey) ?? ''
  );

  const extension = useRef<string>();
  const password = useRef<string>();

  const [amIScreenSharing, setAmIScreenSharing] = useState(false);

  const [extensionConnected, setExtensionConnected] = useState<boolean>(false);

  const [videocallPhase, setVideocallPhase] = useState<VideocallPhase>(
    VideocallPhase.PREROOM
  );
  const [finishedReason, setFinishedReason] = useState<FinishReason>(null);

  const [currentRTCSession, setCurrentRTCSession] = useState<RTCSession>();
  const [connectionId, setConnectionId] = useState<string>();

  const [selectedStreamId, setSelectedStreamId] = useState('remote');

  const value: VideocallRoomContextProps = {
    toInitialContext: () => {
      setFinishedReason(null);
      setCurrentRTCSession(null);
      setExtensionConnected(false);
      setConnectionId(null);
      setRemoteStream(new MediaStream());

      VideoroomWSService.instance.disconnect();
      VideoroomWebRTCService.instance.disconnectWebRTC();
      setVideocallPhase(VideocallPhase.PREROOM);
    },
    isLoggedIn,
    videoRoom,
    connection: {
      connectionId,
      setConnectionId
    },
    streams: {
      local: localStream,
      remote: remoteStream,
      amIScreenSharing,
      setAmIScreenSharing,
      setLocalStream,
      setRemoteStream,
      selectedStreamId,
      setSelectedStreamId
    },
    devices: {
      speakerDevice,
      setSpeakerDevice,
      speakerMuted,
      setSpeakerMuted,
      speakersVolume,
      setSpeakersVolume,
      microphoneDeviceId,
      setMicrophoneDeviceId,
      microphoneMuted,
      setMicrophoneMuted,
      facingMode,
      setFacingMode
    },
    phase: {
      videocallPhase,
      setVideocallPhase,
      finishedReason,
      setFinishedReason
    },
    rtcSession: {
      currentRTCSession,
      setCurrentRTCSession
    },
    extensionData: {
      extension,
      password,
      connected: extensionConnected,
      setConnected: setExtensionConnected
    },
    isMobile,
    isTablet
  };

  useEffect(() => {
    if (!videoRoom?.room?.id) {
      stopStream(VIDEOCALLS_WEBRTC_STREAM);
      stopStream(localStream);
    }
  }, [videoRoom?.room]);

  contextWebsocket(value);

  return (
    <VideocallRoomContext.Provider value={value}>
      {children}
    </VideocallRoomContext.Provider>
  );
}
