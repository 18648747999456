import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'src/utils/queryClient';
import {
  getContactTag,
  getContactTags
} from 'src/modules/Contacts/domain/getContactTags';
import { TagFilters } from 'src/modules/Contacts/infraestructure/api/getContactTagsApi';

export const getContactTagsQuery = (
  filter: {
    companyId?: string;
    contactGroupId?: string;
  },
  waitFor?: boolean
) => {
  return useQuery(
    ['companyTags', filter?.companyId, filter?.contactGroupId],
    () =>
      getCompanyContactTagsQuery({
        companyId: filter.companyId,
        contactGroupId: filter.contactGroupId
      }),
    { enabled: !!waitFor, staleTime: 60000 }
  );
};

export function invalidateContactTagsQuery() {
  queryClient.invalidateQueries({
    queryKey: ['companyTags']
  });
}

export const getCompanyContactTagsQuery = async (filter?: TagFilters) => {
  return queryClient.fetchQuery(
    ['companyTags', filter?.companyId, filter?.contactGroupId],
    {
      queryFn: () => getContactTags(filter),
      staleTime: 60000
    }
  );
};

export const getContactTagByIdQuery = async (id: string) => {
  const contactGroups = await queryClient.fetchQuery(['companyTag', id], {
    queryFn: () => getContactTag(id),
    staleTime: 60000
  });

  return contactGroups;
};

export const getContactTagsNoQuery = (filter?: TagFilters) => {
  return getContactTags(filter);
};
