import { useTranslation } from 'react-i18next';
import Label, { LabelColorType, LabelProps } from 'src/components/Label';

interface Props extends LabelProps {
  enabled?: boolean;
}

export const DialTableStatusLabel = ({
  enabled,
  color: colorProps,
  children: childrenProps,
  ...rest
}: Props) => {
  const { t }: { t: any } = useTranslation();
  const labelText = !!enabled ? t('Enabled') : t('Disabled');
  const labelColor: LabelColorType = !!enabled ? 'success' : 'error';
  const children = enabled === undefined ? childrenProps : labelText;
  const color = enabled === undefined ? colorProps : labelColor;
  return (
    <Label {...rest} color={color}>
      {children}
    </Label>
  );
};
