import { Ticket } from '../../models/tickets';

export const getTicketInvolvedUserIds = (ticket: Ticket) => {
  const conversationsUserIds = ticket?.assignedUserIds
    ? Object.keys(ticket?.assignedUserIds).filter(
        (userId) => !ticket?.assignedUserIds[userId].fromTicket
      )
    : [];
  const commentsUserIds =
    ticket?.comments?.map((comment) => comment.userId) ?? [];
  const historyUserIds =
    ticket.changeHistory?.map((historyItem) => historyItem?.userId) ?? [];

  const allUserIds = [
    ...new Set([...conversationsUserIds, ...commentsUserIds, ...historyUserIds])
  ];
  return allUserIds;
};
