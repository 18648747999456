import { t } from 'i18next';
import React, {
  FC,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect
} from 'react';
import {
  CallResultCount,
  DialerBotCampaign,
  DialerBotTab,
  DialerbotCheck,
  DialerbotFiles,
  DilaerbotStatusFiles,
  TabFormsTypes,
  TimeObject,
  WeekTimes
} from '../../models/dialerbot';
import { Permissions } from 'src/models/permissionGroups';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreateDialerbotCampaignPermissions,
  checkCreateLaunchEngineCampaignPermissions,
  checkUpdateDialerbotCampaignPermissions,
  checkUpdateLaunchEngineCampaignPermissions,
  checkUploadOrReportDialerbotCampaignPermissions,
  checkUploadOrReportLaunchEngineCampaignPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { CallMode } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';

type DialerBotContext = {
  campaign: DialerBotCampaign | null;
  setCampaign: Dispatch<SetStateAction<DialerBotCampaign | null>>;
  currentTab: DialerBotTab;
  setCurrentTab: Dispatch<SetStateAction<DialerBotTab>>;
  tabsForm: TabFormsTypes[];
  setTabsForm: Dispatch<SetStateAction<TabFormsTypes[]>>;
  disableTabs: (arrayTabs: DialerBotTab[]) => void;
  enableTabs: (arrayTabs: DialerBotTab[]) => void;
  openForm: boolean;
  setOpenForm: Dispatch<SetStateAction<boolean>>;
  handleFormClose: () => void;
  handleFormOpen: () => void;
  dayHoursValidation: (dayHours: WeekTimes) =>
    | false
    | {
        [x: string]: TimeObject[];
      };
  checked: DialerbotCheck;
  setChecked: React.Dispatch<React.SetStateAction<DialerbotCheck>>;
  contactGroupId: string;
  setContactGroupId: React.Dispatch<React.SetStateAction<string>>;
  isFileSelected: boolean;
  setIsFileSelected: React.Dispatch<React.SetStateAction<boolean>>;
  callResults: DilaerbotStatusFiles;
  setCallResults: React.Dispatch<React.SetStateAction<DilaerbotStatusFiles>>;
  globalCallResults: CallResultCount;
  setGlobalCallResult: React.Dispatch<React.SetStateAction<CallResultCount>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: DialerbotFiles;
  setFileName: React.Dispatch<React.SetStateAction<DialerbotFiles>>;
  schedulesArray: any[];
  setSchedulesArray: React.Dispatch<React.SetStateAction<any[]>>;
  arraySchedules: DialerbotFiles[];
  setArraySchedules: React.Dispatch<React.SetStateAction<DialerbotFiles[]>>;
  totalItems: number;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DialerBotContext = createContext<DialerBotContext>(
  {} as DialerBotContext
);

export const DialerBotProvider: FC = ({ children }) => {
  const [campaign, setCampaign] = useState<DialerBotCampaign | null>(null);
  const [currentTab, setCurrentTab] = useState<DialerBotTab>('General');
  const [contactGroupId, setContactGroupId] = useState<string>('');
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const handleFormClose = () => {
    setOpenForm(false);
    setCurrentTab('General');
  };
  const [checked, setChecked] = useState<DialerbotCheck>({
    dialerbot: true,
    progressive: false,
    predictive: false
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fileName, setFileName] = useState<DialerbotFiles>();
  const [callResults, setCallResults] = useState<DilaerbotStatusFiles>({});
  const [globalCallResults, setGlobalCallResult] = useState<CallResultCount>(
    {}
  );
  const [schedulesArray, setSchedulesArray] = useState([]);
  const [arraySchedules, setArraySchedules] = useState<DialerbotFiles[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const handleFormOpen = () => setOpenForm(true);

  const { hasAccess } = useContext(PermissionsContext);

  const hasAccessCreateOrUpdate = hasAccess((permissions: Permissions) => {
    return campaign?.callMode === CallMode.DIALERBOT
      ? checkCreateDialerbotCampaignPermissions(permissions) ||
          checkUpdateDialerbotCampaignPermissions(permissions)
      : checkCreateLaunchEngineCampaignPermissions(permissions) ||
          checkUpdateLaunchEngineCampaignPermissions(permissions);
  });
  const hasAccessReportOrUpload = hasAccess(
    campaign?.callMode === CallMode.DIALERBOT
      ? checkUploadOrReportDialerbotCampaignPermissions
      : checkUploadOrReportLaunchEngineCampaignPermissions
  );
  const defaultTabs = [
    {
      value: 'General',
      label: t('General'),
      disabled: !hasAccessCreateOrUpdate
    },
    {
      value: 'Users',
      label: t('Users'),
      disabled: !hasAccessCreateOrUpdate
    },
    {
      value: 'Reschedule',
      label: t('Reschedule'),
      disabled: !hasAccessCreateOrUpdate
    },
    {
      value: 'CSV',
      label: t('File management'),
      disabled: !hasAccessReportOrUpload
    }
  ] as TabFormsTypes[];

  const [tabsForm, setTabsForm] = useState<TabFormsTypes[]>(defaultTabs);

  useEffect(() => {
    if (campaign?.callMode === 'DialerBot') {
      setTabsForm(defaultTabs.filter((tab) => tab.value !== 'Users'));
    } else {
      setTabsForm(defaultTabs);
    }
  }, [campaign]);

  const disableTabs = (arrayTabs: DialerBotTab[]) => {
    const tempTabsForms = tabsForm.map((tab) => {
      if (arrayTabs.includes(tab.value)) return { ...tab, disabled: true };
      return tab;
    });
    setTabsForm(tempTabsForms);
  };

  const enableTabs = (arrayTabs: DialerBotTab[]) => {
    const tempTabsForms = tabsForm.map((tab) => {
      if (arrayTabs.includes(tab.value)) return { ...tab, disabled: false };
      return tab;
    });
    setTabsForm(tempTabsForms);
  };

  const dayHoursValidation = (dayHours: WeekTimes) => {
    const tempDayHours = { ...dayHours };

    const mondayToFriday = 'MONDAY TO FRIDAY';
    const allDays = 'ALL DAYS';
    let isEmpty = true;
    for (let day in dayHours) {
      if (tempDayHours[day].filter((time) => time.enabled).length > 0)
        isEmpty = false;
      break;
    }

    const isAllWeekEnabled =
      tempDayHours[mondayToFriday].filter((time) => time.enabled).length ===
      tempDayHours[mondayToFriday].length;
    const isAllDaysEnabled =
      tempDayHours[allDays].filter((time) => time.enabled).length ===
      tempDayHours[allDays].length;

    if (isAllWeekEnabled) {
      delete tempDayHours['SUNDAY'];
      delete tempDayHours['SATURDAY'];
      Object.keys(tempDayHours).map((day) => {
        if (day !== mondayToFriday)
          tempDayHours[day] = tempDayHours[mondayToFriday];
      });
      delete tempDayHours[mondayToFriday];
      delete tempDayHours[allDays];
      return tempDayHours;
    }

    if (isAllDaysEnabled) {
      Object.keys(tempDayHours).map((day) => {
        if (day !== allDays && day !== mondayToFriday)
          tempDayHours[day] = tempDayHours[allDays];
      });
      delete tempDayHours[mondayToFriday];
      delete tempDayHours[allDays];
      return tempDayHours;
    }

    if (!isAllDaysEnabled && !isAllWeekEnabled) {
      const finalObject = {};
      for (let day in tempDayHours) {
        if (tempDayHours[day].filter((time) => time.enabled).length > 0) {
          finalObject[day] = [...tempDayHours[day]];
        }
      }
      return finalObject;
    }

    if (isEmpty) return {};
  };

  return (
    <DialerBotContext.Provider
      value={{
        campaign,
        setCampaign,
        currentTab,
        setCurrentTab,
        tabsForm,
        setTabsForm,
        disableTabs,
        enableTabs,
        openForm,
        setOpenForm,
        handleFormClose,
        handleFormOpen,
        dayHoursValidation,
        checked,
        setChecked,
        contactGroupId,
        setContactGroupId,
        isFileSelected,
        setIsFileSelected,
        callResults,
        setCallResults,
        globalCallResults,
        setGlobalCallResult,
        open,
        setOpen,
        isLoading,
        setIsLoading,
        isOpen,
        setIsOpen,
        fileName,
        setFileName,
        schedulesArray,
        setSchedulesArray,
        arraySchedules,
        setArraySchedules,
        totalItems,
        setTotalItems,
        currentPage,
        setCurrentPage
      }}
    >
      {children}
    </DialerBotContext.Provider>
  );
};
