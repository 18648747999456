import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { MusicOnHold } from '../../model/MusicOnHold';
import { MusicOnHoldContext } from '../context';
import { MOH_UPDATE_FORM } from '../constants';
import { deleteMusicOnHold } from '../../domain/deleteMusicOnHold';
import { useAlert } from 'src/hooks/useAlert';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
// import { checkDeleteMusicOnHoldPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { ColumnDef } from '@tanstack/react-table';
import { IconButton } from '@mui/material';
import { useMusicOnHoldQuery } from '../../../CampaignNode/domain/getMusicOnHoldDomain';
import { DialTableRowActionsContext } from 'src/components/DialTable/models/functionContexts';
import { checkDeleteMusicOnHoldPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

export const useMusicOnHoldTable = () => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const musicOnHoldQuery = useMusicOnHoldQuery({});

  const {
    setMusicOnHoldEdit,
    toggleMusicOnHoldModal,
    setTypeForm,
    toggleMusicOnHoldDelete,
    setMusicOnHoldIds,
    setMusicOnHoldsDelete,
    musicOnHoldIds
  } = useContext(MusicOnHoldContext);

  const handleEditMusicOnHold = (musicOnHold: MusicOnHold) => {
    toggleMusicOnHoldModal();
    setTypeForm(MOH_UPDATE_FORM);
    setMusicOnHoldEdit(musicOnHold);
  };

  const handleDeleteMusicOnHold = (
    musicOnHoldToDelete: MusicOnHold | MusicOnHold[]
  ) => {
    toggleMusicOnHoldDelete();

    const musicOnHoldsDelete = Array.isArray(musicOnHoldToDelete)
      ? musicOnHoldToDelete.map((musicOnHold) => musicOnHold)
      : [musicOnHoldToDelete];

    setMusicOnHoldsDelete(musicOnHoldsDelete);

    const musicOnHoldIds = Array.isArray(musicOnHoldToDelete)
      ? musicOnHoldToDelete.map((musicOnHold) => musicOnHold.id)
      : [musicOnHoldToDelete.id];

    setMusicOnHoldIds(musicOnHoldIds);
  };

  // const ActionButtons: Array<TableToolbarActionButton<MusicOnHold>> = [
  //   {
  //     Icon: DeleteIcon,
  //     onClickMulti: handleDeleteMusicOnHold,
  //     title: t('MOH_DELETE_BUTTON'),
  //     iconButtonStyles: {
  //       color: '#FF1943'
  //     },
  //     permissionCheckerFn: () => checkDeleteMusicOnHoldPermissions
  //   }
  // ];

  const deleteMusicOnHolds = async () => {
    try {
      await Promise.all(
        musicOnHoldIds.map(async (musicOnHoldId) => {
          try {
            await deleteMusicOnHold(musicOnHoldId);
            showAlert(
              t('Musica de espera borrada exitosamente'),
              'success',
              3000
            );
            musicOnHoldQuery.refetch();
          } catch (error) {
            showAlert(getErrorMessage(error).errorMessage, 'error', 3000);
          }
        })
      );
    } catch (error) {
      showAlert(error.message, 'error', 3000);
    }
  };

  const getColumns: () => Promise<ColumnDef<MusicOnHold>[]> = async () => [
    {
      accessorKey: 'name',
      id: 'name',
      header: t('MOH_NAME')
    },
    {
      accessorKey: 'order',
      id: 'order',
      header: t('MOH_ORDER')
    }
  ];

  const getRowActions = ({
    rowData: musicOnHold,
    hasAccess
  }: DialTableRowActionsContext<MusicOnHold>) => [
    <IconButton
      key="editMusicOnHold"
      title={t('MOH_UPDATE_BUTTON')}
      size="small"
      color="secondary"
      onClick={async () => handleEditMusicOnHold(musicOnHold)}
    >
      <EditIcon fontSize="small" />
    </IconButton>,
    <IconButton
      key="deleteMusicOnHold"
      title={t('MOH_DELETE_BUTTON')}
      size="small"
      color="error"
      onClick={async () => handleDeleteMusicOnHold(musicOnHold)}
      disabled={!hasAccess(checkDeleteMusicOnHoldPermissions)}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  ];

  return {
    deleteMusicOnHolds,
    getColumns,
    getRowActions,
    musicOnHoldQuery
  };
};
