import { RTCall } from 'src/models/rtcall';
import { setActiveRTCallId } from 'src/store/slices/calls/calls';

import store from 'src/store/store';

/** SET THE ACTIVE CALL
 *
 * @param callId RTCallId to set
 */
export const setActiveRTCallIdStore = (callId: string) => {
  store.dispatch(setActiveRTCallId(callId));
};
