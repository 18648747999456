import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useFeaturesContext } from 'src/contexts/CompanyFeaturesContext/CompanyFeaturesContext';
import { Features } from 'src/modules/Admin/modules/General/modules/Companies/models/CompanyFeatures';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { Permissions } from 'src/models/permissionGroups';

interface Props {
  permissionCheckerFn: (
    permissions: Permissions,
    isSuperAdmin: boolean
  ) => boolean;
  featureCheckerFn?: (features: Features) => boolean;
  navigate?: boolean;
  fallback?: JSX.Element | string;
  loadingElement?: JSX.Element | string;
}

const Restricted: FC<Props> = ({
  permissionCheckerFn,
  fallback,
  loadingElement,
  navigate = false,
  children,
  featureCheckerFn = () => true
}) => {
  // PERMISSIONS
  const { hasAccess, loading: loadingPermissions } =
    useContext(PermissionsContext);
  const isAllowed = hasAccess(permissionCheckerFn);

  // FEATURES
  const { hasFeature, loading: loadingFeatures } = useFeaturesContext();
  const isFeatureEnabled = hasFeature(featureCheckerFn);

  const isSucceeded =
    loadingPermissions === 'succeeded' && loadingFeatures === 'succeeded';

  const isPending =
    loadingPermissions === 'pending' || loadingFeatures === 'pending';

  // When the navigate param is sent and the user does not have permissions to access the route, the user is redirected to a PUBLIC route
  if (navigate && isSucceeded && (!isAllowed || !isFeatureEnabled)) {
    return <Navigate to="/dashboard/home" replace />;
  }
  // When the user permissions are loading, a loading element is returned
  if (isPending) return <>{loadingElement}</>;

  // When the user is allowed to access to the route, the children is returned
  if (isAllowed && isFeatureEnabled) {
    return <>{children}</>;
  }

  // When the navigate param is not sent, a fallback element is returned
  return <>{fallback}</>;
};

export default Restricted;
