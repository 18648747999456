import { queryClient } from 'src/utils/queryClient';
import { getVariableByIdApi } from '../infrastructure/getVariableByIdApi';

export const getVariableByIdDomain = async (id: string) => {
  try {
    return await getVariableByIdApi(id);
  } catch (error: unknown) {
    throw error;
  }
};

export const getVariableByIdQuery = (id: string) => {
  return queryClient.fetchQuery({
    queryKey: ['variable id' + id],
    queryFn: () => getVariableByIdApi(id),
    staleTime: 60 * 5 * 1000
  });
};
