import { BASE_URL } from './base';

const EMAIL_MS = `${BASE_URL}/email`;

// Send controller
const SEND_CONTROLLER = `${EMAIL_MS}/message`;
export const SEND_EMAIL_MESSAGE_TEXT_NEW = `${SEND_CONTROLLER}/new/text`;
export const SEND_EMAIL_MESSAGE_TEXT_REPLY = `${SEND_CONTROLLER}/existing/text`;
export const SEND_EMAIL_MESSAGE_FILE_NEW = `${SEND_CONTROLLER}/new/file`;
export const SEND_EMAIL_MESSAGE_FILE_REPLY = `${SEND_CONTROLLER}/existing/file`;
export const SEND_EMAIL_MESSAGE_ACTIONS = `${SEND_CONTROLLER}/actions`;
export const EMAIL_MESSAGE_READ = `${SEND_CONTROLLER}/read`;

// Attachments controller
const ATTACHMENTS_CONTROLLER = `${EMAIL_MS}/attachment`;
export const EMAIL_ATTACHMENT_ID = `${ATTACHMENTS_CONTROLLER}/{id}`;

// Account controller
const ACCOUNT_CONTROLLER = `${EMAIL_MS}/account`;
export const EMAIL_ACCOUNT = `${ACCOUNT_CONTROLLER}`;
export const EMAIL_ACCOUNT_ID = `${ACCOUNT_CONTROLLER}/{id}`;
export const EMAIL_ACCOUNT_ID_FAILED = `${ACCOUNT_CONTROLLER}/{id}/failed`;

// Google controller
const GOOGLE_CONTROLLER = `${EMAIL_MS}/provider/google`;
export const EMAIL_GOOGLE_LOGIN = `${GOOGLE_CONTROLLER}/login`;
export const EMAIL_GOOGLE_OAUTH = `${GOOGLE_CONTROLLER}/oauth`;

// Office 365 controller
const OFFICE_365_CONTROLLER = `${EMAIL_MS}/provider/office365`;
export const EMAIL_OFFICE_365_LOGIN = `${OFFICE_365_CONTROLLER}/login`;

// Generic provider controller
const PROVIDER_CONTROLLER = `${EMAIL_MS}/provider/generic`;
export const EMAIL_GENERIC_SEND = `${PROVIDER_CONTROLLER}/send`;
export const EMAIL_GENERIC_RECEIVE = `${PROVIDER_CONTROLLER}/receive`;

//Signature controller
const SIGNATURE_CONTROLLER = `${EMAIL_MS}/signature`;
export const EMAIL_SIGNATURE = `${SIGNATURE_CONTROLLER}`;
export const EMAIL_SIGNATURE_ID = `${SIGNATURE_CONTROLLER}/{id}`;
export const EMAIL_SIGNATURE_PARSED = `${SIGNATURE_CONTROLLER}/parsedSignature`;
