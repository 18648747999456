import ReactDOMServer from 'react-dom/server';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';
import { t } from 'i18next'; //no possible use translation hook because that componet us render in charts

const CustomLeftArrowIcon = () => {
  const leftArrow = (
    <Box title={t('Move Back')}>
      <ArrowBackIcon />
    </Box>
  );

  // Convierte el componente de React a una cadena de texto HTML
  const leftArrowHtml = ReactDOMServer.renderToStaticMarkup(leftArrow);

  return leftArrowHtml;
};

export default CustomLeftArrowIcon;
