import store, { useSelector } from 'src/store/store';

/** GET RTUSERS FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of conversations
 */
export const getRTUsersStore = (shouldUseHook: boolean) => {
  const allRTUsers = shouldUseHook
    ? useSelector((state) => state.rtusers.rtUsers)
    : store.getState().rtusers.rtUsers;

  return Object.values(allRTUsers);
};

/** GET CURRENT RTUSER FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 * @param idUser string
 *
 * @returns User
 */
export const getRTUserStore = (shouldUseHook: boolean, idUser: string) => {
  const rtUser = shouldUseHook
    ? useSelector((state) => state.rtusers.rtUsers[idUser])
    : store.getState().rtusers.rtUsers[idUser];

  return rtUser;
};
