import { Delete } from '@mui/icons-material';
import { IconButton, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';

interface Props {
  setTag: (tag: string) => void;
  onRemove: () => void;
  tag: string;
  addNewTag: () => void;
  tagRefs: React.MutableRefObject<HTMLInputElement[]>;
  index: number;
}

function IntentionTagItem({
  setTag,
  tag,
  onRemove,
  addNewTag,
  tagRefs,
  index
}: Props) {
  const [textField, setTextField] = useState(tag);

  return (
    <Stack direction={'row'}>
      <TextField
        inputRef={(ref) => {
          tagRefs.current[index] = ref;
        }}
        autoFocus={textField === ''}
        value={textField}
        onChange={(e) => setTextField(e.target.value)}
        onBlur={() => setTag(textField)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && textField !== '') {
            addNewTag();
          }
        }}
        variant="outlined"
        size="small"
        placeholder={t('Enter tag name') + '...'}
        fullWidth
        InputProps={{
          sx: {
            background: 'white'
          }
        }}
      />
      <IconButton
        onClick={onRemove}
        size="small"
        color="error"
        sx={{ ml: 1 }}
        style={{
          marginRight: 'calc(109px-100%)'
        }}
      >
        <Delete />
      </IconButton>
    </Stack>
  );
}

export default IntentionTagItem;
