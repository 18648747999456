import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { Dashboard, PanelsGroup } from '../../models/Dashboards';
import {
  REALTIME_DASHBOARDS_UPDATE_PANEL_GROUPS,
  REALTIME_DASHBOARDS_UPDATE_TIMES,
} from 'src/utils/apiRoutes/msFlow';

export const updateDashboardTimesApi = async (
  dashboardId: string,
  groupIntervalSeconds: number,
  panelSizeSeconds: number
): Promise<Dashboard> => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_UPDATE_TIMES, {
    '{id}': dashboardId,
  });

  const params = { groupIntervalSeconds, panelSizeSeconds };

  const response: AxiosResponse<Dashboard> = await axios.put(path, null, {
    params,
  });

  return response.data;
};

export const updateDashboardPanelsGroupsApi = async (
  dashboardId: string,
  panelsGroups: PanelsGroup[]
): Promise<Dashboard> => {
  const path = replaceURLParams(REALTIME_DASHBOARDS_UPDATE_PANEL_GROUPS, {
    '{id}': dashboardId,
  });

  const response: AxiosResponse<Dashboard> = await axios.put(
    path,
    panelsGroups
  );

  return response.data;
};
