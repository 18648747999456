import axios from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { EmailAccount } from 'src/models/accounts';
import { EMAIL_ACCOUNT } from 'src/utils/apiRoutes/msEmail';

export interface EmailAccountParams {
  companyId?: string;
  emailAddress?: string;
  enabled?: boolean;
  host?: string;
  id?: string;
  receiveProtocol?: string;
  sortDir?: DirectionType;
  sortBy?: keyof EmailAccount;
}

export const getEmailAccountsApi = async (
  params: EmailAccountParams
): Promise<EmailAccount[]> => {
  const accounts = await axios
    .get(EMAIL_ACCOUNT, { params })
    .then((response: any) => {
      return response.data as EmailAccount[];
    })
    .catch((error: any) => {
      return error.response;
    });
  return accounts;
};
