import axios from 'axios';
import qs from 'qs';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';
import { WebChatAccount } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/models/webChatAccount';
import { WEBCHAT_ACCOUNT } from 'src/utils/apiRoutes/msWebChat';
export interface WebChatAccountFilter {
  contactGroupId?: string;
  id?: string;
  name?: string;
  companyId?: string;
  page?: number;
  size?: number;
  styleId?: string;
  sortBy?: keyof WebChatAccount;
  sortDir?: DirectionType;
}

export const getWebChatAccountsApi = async (
  filter: WebChatAccountFilter
): Promise<PaginatedElements<WebChatAccount>> => {
  const params = { ...filter };

  const paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };

  const response = await axios.get(WEBCHAT_ACCOUNT, {
    params,
    paramsSerializer
  });

  return response.data;
};
