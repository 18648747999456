import { logout } from './infraestructure/logoutAuth';
import { refreshRequest } from './infraestructure/refreshRequest';
import { refreshRTUsers } from './infraestructure/refreshRtUsers';
import {
  getExpiration,
  getExpiresInMs,
  getLoggedUserCompanyId,
  getToken,
  getUserExtension,
  getUserId,
  getUsername,
  removeCompanyId,
  removeExpiration,
  removeExpiresInMs,
  removeToken,
  removeUserExtension,
  removeUserId,
  removeUsername,
  setExpiration,
  setExpiresInMs,
  setLoggedUserCompanyId,
  setToken,
  setUserExtension,
  setUserId,
  setUsername
} from './domain/getAuthenticationData';
import { login } from './domain/login';

const AuthManager = {
  getExpiration,
  getExpiresInMs,
  getLoggedUserCompanyId,
  getToken,
  getUserExtension,
  getUserId,
  login,
  logout,
  refreshRequest,
  refreshRTUsers,
  setExpiration,
  setExpiresInMs,
  setLoggedUserCompanyId,
  setToken,
  setUserExtension,
  setUserId,
  setUsername,
  getUsername,
  removeCompanyId,
  removeExpiration,
  removeExpiresInMs,
  removeToken,
  removeUserExtension,
  removeUserId,
  removeUsername
};
export default AuthManager;
