import { BASE_URL } from './base';

// CONVERSATIONS MICROSERVICE
const CONVERSATIONS_MS = `${BASE_URL}/conversations`;

// Conversations
const CONVERSATIONS_CONTROLLER = `${CONVERSATIONS_MS}/conversations`;
export const CONVERSATIONS = CONVERSATIONS_CONTROLLER;
export const CONVERSATIONS_ID = `${CONVERSATIONS_CONTROLLER}/{id}`;
export const CONVERSATIONS_ID_CLOSE = `${CONVERSATIONS_CONTROLLER}/{id}/close`;
export const CONVERSATIONS_BY_USER = `${CONVERSATIONS_CONTROLLER}/by_user`;
export const CONVERSATIONS_BY_USER_UNGROUPED = `${CONVERSATIONS_CONTROLLER}/by_user_ungrouped`;
export const CONVERSATIONS_CHAT = `${CONVERSATIONS_CONTROLLER}/chat`;
export const CONVERSATIONS_COUNT = `${CONVERSATIONS_CONTROLLER}/count`;
export const CONVERSATIONS_EMAIL = `${CONVERSATIONS_CONTROLLER}/email`;
export const CONVERSATIONS_SUMMARIES = `${CONVERSATIONS_CONTROLLER}/summaries`;
export const CONVERSATIONS_VIDEOCALL = `${CONVERSATIONS_CONTROLLER}/videocall`;
