import axios from 'axios';
import { replaceURLParams } from '../../utils';
import { logger } from 'src/utils/logger';
import { RTUSERS_RESET } from 'src/utils/apiRoutes/msUsers';

export const refreshRTUsers: Function = (userId: string) => {
  const path = replaceURLParams(RTUSERS_RESET, { '{id}': userId });

  return axios
    .post(path)

    .catch((error: any) => {
      logger.error(error);
      return error.response;
    });
};
