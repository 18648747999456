import { replaceURLParams } from 'src/services/utils';
import axios, { AxiosResponse } from 'axios';
import { AC } from 'country-flag-icons/react/3x2';
import {
  SCHEDULE_POWERDIALER_ACCEPT,
  SCHEDULE_POWERDIALER_IGNORE,
  SCHEDULE_POWERDIALER_POSTPONE
} from 'src/utils/apiRoutes/msCallLaunching';

export const acceptPowerManualApi = async (
  campaignId: string,
  scheduleId: string,
  userId: string
) => {
  const path = replaceURLParams(SCHEDULE_POWERDIALER_ACCEPT, {
    '{scheduleId}': scheduleId
  });
  const params = { campaignId, userId };
  const response = await axios.put(path, null, { params });
  return response.data;
};

export const ignorePowerManualApi = async (
  campaignId: string,
  scheduleId: string,
  userId: string,
  companyId: string
) => {
  const path = replaceURLParams(SCHEDULE_POWERDIALER_IGNORE, {
    '{scheduleId}': scheduleId
  });
  console.log(path);
  const params = { campaignId, userId, companyId };
  const response = await axios.put(path, null, { params });
  return response.data;
};

export const postponePowerManualApi = async (
  campaignId: string,
  scheduleId: string,
  userId: string,
  companyId: string
) => {
  const path = replaceURLParams(SCHEDULE_POWERDIALER_POSTPONE, {
    '{scheduleId}': scheduleId
  });
  const params = { campaignId, userId, companyId };

  const response = await axios.put(path, null, { params });
  return response.data;
};
