import React from 'react';
import { Card, CardActions, CardContent, SxProps, Theme } from '@mui/material';
import { WhatsAppTemplate } from 'src/services/whatsapp/models/WhatsAppTemplate';
import TemplatesCardHeader from './TemplatesCardHeader';
import TemplatesCardBody from './TemplatesCardBody';

interface Props {
  template: WhatsAppTemplate;
  footerElement: (template: WhatsAppTemplate) => JSX.Element;
  sx?: SxProps<Theme>;
}

const TemplateCard: React.FC<Props> = ({ template, footerElement, sx }) => {
  return (
    <Card
      sx={{
        border: '1px solid #e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...sx
        //height: isSmallScreen ? '280px' : '100%'
      }}
    >
      <CardContent>
        <TemplatesCardHeader template={template} />
        <TemplatesCardBody template={template} />
      </CardContent>
      <CardActions sx={{ px: 1 }}>{footerElement(template)}</CardActions>
    </Card>
  );
};

export default TemplateCard;
