import React, { useState, useEffect } from 'react';

interface Props {
  initialTime: number;
}

export const TimeCounter = (props: Props) => {
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  useEffect(() => {
    setInterval(() => setCurrentTime(Date.now()), 1000);
  }, []);

  const elapsedSeconds = Math.floor((currentTime - props.initialTime) / 1000);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = secs.toString().padStart(2, '0');

    if (hours > 0) {
      const formattedHours = hours.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  };

  return (
    <>
      <h2>{formatTime(elapsedSeconds)}</h2>
    </>
  );
};
