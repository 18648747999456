import { Search } from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { t } from 'i18next';
import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';

interface SearchBarProps {
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  placeholder?: string;
  fullWidth?: boolean;
  sx?: CSSProperties;
  id?: string;
  autoFocus?: boolean;
  endIcon?: React.ReactNode;
  textFieldProps?: TextFieldProps;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchValue,
  setSearchValue,
  fullWidth,
  sx,
  placeholder,
  id,
  autoFocus = false,
  endIcon,
  textFieldProps
}) => {
  return (
    <TextField
      id={id}
      fullWidth={!!fullWidth}
      InputProps={{
        endAdornment: endIcon
      }}
      placeholder={t(placeholder)}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
      size={'small'}
      sx={sx}
      autoFocus={autoFocus}
      {...textFieldProps}
    />
  );
};
