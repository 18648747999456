import { useContext, useEffect, useState } from 'react';
import { Box, Button, MenuItem, Select, useTheme } from '@mui/material';
import { Stack } from '@mui/material';
import PhoneInput from 'src/components/PhoneInput';
import {
  CTIBarContext,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { getCampaignsOfUserCompany } from 'src/services/users/domain/getCampaignsOfUserCompany';
import CallManagerDomain from '../../CallManager';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { useAlert } from 'src/hooks/useAlert';
import { emitCustomEvent } from 'react-custom-events';
import { t } from 'i18next';
import { transferCall } from '../../domain/CallActions';

const callKeysCTI = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['*', '0', '#']
];

export const TransferPad = () => {
  const activeCall = getActiveRTCall(getConnectedRTUser(true));

  const [inputValue, setInputValue] = useState('');
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const { setCallToTransferID } = useContext(CTIBarContext);

  const { countryCode, setCountryCode } = useDialpadStateContext();

  const { showAlert } = useAlert();
  function handleAttendedTransfer() {
    setCallToTransferID(activeCall.callId);
    CallManagerDomain.attendedTransferCall(
      activeCall,
      inputValue,
      false,
      selectedCampaignId
    );
  }
  function handleTransfer() {
    transferCall({
      call: activeCall,
      extension: inputValue,
      campaignId: selectedCampaignId,
      isInternal: false
    })
      .then(() => {
        emitCustomEvent('Transfer in progress', activeCall.id);
        emitCustomEvent('Close popover');
        showAlert('Transfer in progress', 'success', 3000);
      })
      .catch(() => {
        showAlert('Transfer failed', 'error', 3000);
      });
  }

  const onChangeSelect = (e) => {
    setSelectedCampaignId(e.target.value);
  };

  const onEnterDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (
      e.key === 'Enter' &&
      inputValue.length > 0 &&
      !Number.isNaN(parseInt(inputValue))
    ) {
      handleAttendedTransfer();
    }
  };

  useEffect(() => {
    getCampaignsOfUserCompany().then((resp) => {
      const campaignFilter = (campaign: Campaign) => {
        return (
          campaign.channel === 'Call' &&
          campaign.callMode === 'Outbound' &&
          campaign.serviceId === activeCall.serviceId
        );
      };
      const outboundCampaigns = resp?.elements?.filter(campaignFilter);

      setCampaigns(outboundCampaigns);
      setSelectedCampaignId(outboundCampaigns[0].id);
    });
  }, []);

  return (
    <Stack
      height={'100%'}
      direction={'column'}
      alignItems="center"
      justifyContent={'space-between'}
      spacing={1.5}
      py={2}
    >
      <Stack direction="column" alignItems={'center'}>
        <Stack direction="column" alignItems={'center'}>
          <PhoneInput
            autoFocus
            label={null}
            size="small"
            phoneNumber={inputValue}
            setPhoneNumber={setInputValue}
            callType={'External'}
            setCallType={undefined}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            onKeyDown={onEnterDown}
            sx={{ width: '300px' }}
            disableCallTypeSelector
          />
        </Stack>
      </Stack>
      <Stack
        direction={'column'}
        justifyContent="center"
        alignItems="center"
        width={240}
      >
        {callKeysCTI &&
          callKeysCTI.map((g, idx) => {
            return (
              <Stack
                direction="row"
                key={idx}
                width={'100%'}
                justifyContent="space-around"
              >
                {g.map((k) => {
                  return (
                    <Button
                      key={k}
                      sx={{ fontSize: '20px' }}
                      onClick={(): void =>
                        setInputValue((oldValue) => {
                          if (oldValue === '') oldValue = '34';
                          return oldValue + k;
                        })
                      }
                      id="btnKey-TransferPad"
                    >
                      {k}
                    </Button>
                  );
                })}
              </Stack>
            );
          })}
      </Stack>
      <Select
        sx={{ height: '40px', width: '240px' }}
        value={selectedCampaignId}
        onChange={onChangeSelect}
        id="campaing-TransferPad"
      >
        {campaigns.map((c, idx) => {
          return (
            <MenuItem key={idx} value={c.id}>
              {c.name}
            </MenuItem>
          );
        })}
      </Select>
      <Stack direction="row" justifyContent={'space-between'} gap={5}>
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={() => {
            handleAttendedTransfer();
          }}
          id="btnCall-TransferUsers"
        >
          {t('Call')}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="warning"
          onClick={() => {
            handleTransfer();
          }}
          id="btnTransfer-TransferUsers"
        >
          {t('Transfer')}
        </Button>
      </Stack>
    </Stack>
  );
};
