import {
  Box,
  Input,
  List,
  Popover,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import {
  CustomFilterValueOptions,
  DialTableFilter,
  FieldDataType,
  Operator
} from '../models/filters';
import { Close } from '@mui/icons-material';
import { KeyboardEvent } from 'react';
import { DialTableFilterValueOptions } from './DialTableFilterValueOptions';

import { Option } from '../models/option';
import { DialTableListItemButton } from './DialTableListItemButton';
import { useDialTableFilterItem } from '../hooks/useDialTableFilterItem';
import { DialTableFilterItemBox } from './DialTableFilterItemBox';
import { useTranslation } from 'react-i18next';
import { DialTableNoResultsFilterItem } from './DialTableNoResultsFilterItem';

interface Props<T> {
  filter: DialTableFilter<T>;
  onChangeFilter?: (
    newFilter: DialTableFilter<T>,
    previousFilter: DialTableFilter<T>,
    selectedValueOptions?: Option[]
  ) => void;
  onKeyDownFilter?: (event: KeyboardEvent<HTMLUListElement>) => void;
  onRemoveFilter?: (filter: DialTableFilter<T>) => void;
  /**
   * Function to determine whether the operator box is displayed or not.
   * If not passed, the operator box is displayed when the operator is other than undefined or null.
   */
  showOperatorFn?: (filter: DialTableFilter<T>) => boolean;
  /**
   * Function to determine whether the values box is displayed or not.
   * If not passed, the values box is displayed when the length of the values array is greater than 0.
   */
  showValuesFn?: (filter: DialTableFilter<T>) => boolean;
  /**
   * Object to map the filter fieldName to an Option array.
   * It is in charge of displaying the label of the option using the values of the filter whose fieldName matches with the key.
   * If the label option cannot be found in the optionsMap object, the option is requested to backend (using the function getOptionValue).
   * */
  optionsMap?: { [fieldName: string]: Option[] };
  /** Object with callbacks called to return custom filter options and selected values. The custom options and selcted values will not overwrite the default options.
   *
   * This callback can be used to consider other DataCategory that are not already considered by the table.
   */
  customFilterValueOptions?: CustomFilterValueOptions;
  onlyEqualsOperator?: boolean;
}
export const DialTableFilterItem = <T extends Object>(props: Props<T>) => {
  const {
    filter: filterProps,
    onChangeFilter,
    onKeyDownFilter,
    onRemoveFilter,
    showOperatorFn,
    showValuesFn,
    optionsMap,
    customFilterValueOptions,
    onlyEqualsOperator
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    anchorEl,
    dropdownMenuRef,
    filter,
    filterValues,
    getCustomFilterValueOptionListFn,
    handleClickOperator,
    handleClickValues,
    handleClose,
    handleInputChange,
    handleKeyDown,
    input,
    inputSearchRef,
    isDisabled,
    isOperatorVisible,
    isSelectedOperatorItem,
    isSelectedTextValueItem,
    isValuesVisible,
    itemRefs,
    onInputBlur,
    onInputKeyDown,
    onSearchKeyDown,
    onTextFilterInputChange,
    openDropdownMenu,
    operatorLabel,
    operatorOptions,
    textFilterInput
  } = useDialTableFilterItem({
    filter: filterProps,
    onChangeFilter,
    onKeyDownFilter,
    onRemoveFilter,
    showOperatorFn,
    showValuesFn,
    optionsMap,
    customFilterValueOptions,
    onlyEqualsOperator
  });

  return (
    <>
      <Stack
        direction="row"
        spacing={0.2}
        sx={{
          // Selects all direct child elements
          '&:hover > *': {
            background:
              isSelectedTextValueItem || isDisabled
                ? undefined
                : alpha(theme.colors.alpha.black[100], 0.2)
          }
        }}
      >
        {/* FIELDNAME */}
        <DialTableFilterItemBox disabled={isDisabled}>
          <Typography whiteSpace="nowrap" color={theme.colors.alpha.black[70]}>
            {filter?.label ?? filter.fieldName}
          </Typography>
        </DialTableFilterItemBox>
        {/* OPERATOR */}
        {isOperatorVisible && (
          <DialTableFilterItemBox
            onClick={handleClickOperator}
            disabled={isDisabled}
          >
            <Typography whiteSpace="nowrap">{operatorLabel}</Typography>
          </DialTableFilterItemBox>
        )}
        {/* VALUES */}
        {isValuesVisible && !isSelectedTextValueItem && (
          <DialTableFilterItemBox
            onClick={handleClickValues}
            disabled={isDisabled}
          >
            <Typography whiteSpace="nowrap">{filterValues}</Typography>
          </DialTableFilterItemBox>
        )}
        {/* INPUT TEXT */}
        {isValuesVisible && isSelectedTextValueItem && (
          <Input
            fullWidth
            disableUnderline
            autoFocus
            value={textFilterInput}
            onChange={onTextFilterInputChange}
            onKeyDown={onInputKeyDown}
            onBlur={onInputBlur}
            sx={{
              minWidth: '150px'
            }}
          />
        )}
        {/* REMOVE FILTER */}
        {!filter.required && onRemoveFilter && (
          <Box
            display="flex"
            borderRadius={0.25}
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              onRemoveFilter(filter);
            }}
            sx={{
              backgroundColor: theme.colors.alpha.black[10],
              color: theme.colors.alpha.black[70],
              width: '30px',
              cursor: 'pointer',
              ':hover': {
                color: theme.colors.alpha.black[100]
              }
            }}
          >
            <Close fontSize="small" />
          </Box>
        )}
      </Stack>
      {/* DROPDOWN MENU */}
      <Popover
        ref={dropdownMenuRef}
        open={openDropdownMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onKeyDown={handleKeyDown}
        disablePortal
      >
        {!isSelectedOperatorItem &&
          filter.fieldDataType !== FieldDataType.DATE && (
            <Stack
              p={1}
              boxSizing="border-box"
              borderBottom={1}
              height="60px"
              borderColor={theme.colors.alpha.black[30]}
              justifyContent="center"
            >
              <TextField
                autoFocus
                ref={inputSearchRef}
                size="small"
                title={t('Search')}
                placeholder={`${t('Search')}...`}
                value={input}
                onChange={handleInputChange}
                onKeyDown={onSearchKeyDown}
              />
            </Stack>
          )}
        <List
          onKeyDown={onKeyDownFilter}
          sx={{
            minWidth: '60px',
            overflow: 'scroll',
            maxHeight: !isSelectedOperatorItem ? `calc(80vh - 60px)` : '80vh'
          }}
        >
          {/* OPERATOR */}
          {isSelectedOperatorItem && operatorOptions.length === 0 && (
            <DialTableNoResultsFilterItem />
          )}
          {isSelectedOperatorItem &&
            operatorOptions.map((operator) => {
              return (
                <DialTableListItemButton
                  key={String(operator.value)}
                  label={operator?.label}
                  description={operator?.description}
                  onClick={() => {
                    const newFilter = {
                      ...filter,
                      operator: operator?.value as Operator
                    };
                    onChangeFilter?.(newFilter, filter);
                  }}
                />
              );
            })}
          {/* VALUES */}
          {!isSelectedOperatorItem && (
            <DialTableFilterValueOptions
              itemRefs={itemRefs}
              filterToEdit={filter}
              onChangeFilterValues={(
                _newValues,
                newFilter,
                selectedValueOptions
              ) => {
                onChangeFilter(newFilter, filter, selectedValueOptions);
              }}
              getCustomFilterValueOptionsFn={getCustomFilterValueOptionListFn}
              input={input}
            />
          )}
        </List>
      </Popover>
    </>
  );
};
