import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { ChatMessage } from 'src/models/conversations/messages';
import ChatReplyToFile from './ChatReplyToFile';
import { t } from 'i18next';

interface ChatReplyMessageBoxProps {
  message: ChatMessage;
  replyMessage: any;
}

const ChatReplyMessageBox: React.FC<ChatReplyMessageBoxProps> = ({
  message,
  replyMessage
}) => {
  const theme = useTheme();
  const fontColor =
    message.orientation === 'Incoming'
      ? `${theme.colors.alpha.black[100]}`
      : `${theme.colors.alpha.white[100]}`;

  const borderRight =
    message.orientation === 'Incoming'
      ? `${theme.general.borderRadiusLg}`
      : `none`;

  const borderLeft =
    message.orientation === 'Outgoing'
      ? `${theme.general.borderRadiusLg}`
      : `none`;

  return (
    <Box
      sx={{
        background: 'rgba(255, 255, 255, 0.3)',
        color: `${fontColor}`,
        padding: `${theme.spacing(0.7)} ${theme.spacing(1)}`,
        borderRadius: `${theme.general.borderRadiusSm}`,
        borderTopRightRadius: `${borderRight}`,
        borderTopLeftRadius: `${borderLeft}`,
        marginY: `${theme.spacing(0.5)}`
      }}
    >
      {replyMessage?.type === 'Text' && (
        <>
          <Typography variant="h4">{replyMessage?.replyUser}</Typography>
          <Typography
            variant="subtitle2"
            color={`${fontColor}`}
            sx={{ wordBreak: 'break-word' }}
          >
            {replyMessage?.content?.text}
          </Typography>
        </>
      )}

      {replyMessage?.type !== 'Text' && replyMessage?.type !== undefined && (
        <>
          {replyMessage?.type === 'Location' && (
            <Box>
              <Typography variant="h4">{replyMessage?.replyUser}</Typography>
              <Typography variant="subtitle2" color={`${fontColor}`}>
                {t('Location')}
              </Typography>
            </Box>
          )}
          {replyMessage?.type === 'Audio' && (
            <Box>
              <Typography variant="h4">{replyMessage?.replyUser}</Typography>
              <Typography variant="subtitle2" color={`${fontColor}`}>
                {t('Voice message')}
              </Typography>
            </Box>
          )}
          {replyMessage?.type === 'Document' && (
            <Box>
              <Typography variant="h4">{replyMessage?.replyUser}</Typography>
              <ChatReplyToFile message={replyMessage} />
            </Box>
          )}
          {replyMessage?.type === 'Video' && (
            <Stack mb={1} height={'35px'} direction={'row'}>
              <Box height={'50px'} width={'50px'}>
                <ChatReplyToFile message={replyMessage} />
              </Box>
              <Box mx={1.5}>
                <Typography variant="h4">{replyMessage?.replyUser}</Typography>
                <Typography variant="subtitle2" color={`${fontColor}`}>
                  {replyMessage?.content?.text
                    ? replyMessage?.content?.text
                    : t('Video')}
                </Typography>
              </Box>
            </Stack>
          )}
          {(replyMessage?.type === 'Image' ||
            replyMessage?.type === 'Sticker') && (
            <Stack mb={1} height={'35px'} direction={'row'}>
              <Box height={'50px'} width={'50px'}>
                <ChatReplyToFile message={replyMessage} />
              </Box>
              <Box mx={1.5}>
                <Typography variant="h4">{replyMessage?.replyUser}</Typography>
                <Typography variant="subtitle2" color={`${fontColor}`}>
                  {replyMessage?.content?.text
                    ? replyMessage?.content?.text
                    : t('Photo')}
                </Typography>
              </Box>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

export default ChatReplyMessageBox;
