import axios from 'axios';
import { ChatMessageType } from 'src/models/conversations/messages';
import { INSTAGRAM_SEND_EXISTING_FILE_MESSAGE } from 'src/utils/apiRoutes/msInstagram';

export interface SendInstagramExistingMessageFileApiParams {
  conversationId: string;
  replyTo?: string;
  accountId: string;
  file: File;
  type: ChatMessageType;
}

export const sendInstagramExistingMessageFileApi = async (
  params: SendInstagramExistingMessageFileApiParams
) => {
  const { file, ...restParams } = params;
  const formData = new FormData();
  formData.append('file', file);
  await axios.post(INSTAGRAM_SEND_EXISTING_FILE_MESSAGE, formData, {
    params: restParams
  });
};
