import { Box, Card, Grow } from '@mui/material';
import { Stack } from '@mui/system';
import { useContext } from 'react';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { CallButtons } from './CallButtons';
import { HangupButton } from './CallButtons/HangupButton';
import { CallInfo } from './CallInfo/index';
import { CompressBarButton } from './CompressBarButton';
import { DevicesMenu } from './DevicesMenu';
import { LockCTIButton } from './LockCTIButton';
import { ConferenceInfo } from './NewConversation/conference/ConferenceInfo';
import { NewConversation } from './NewConversation/NewConversation';
import { SpeakerTestProps } from './hooks/useSpeakerTest';
import { ConferenceButton } from './CallButtons/Conference/ConferenceButton';

interface Props {
  speakersTest: SpeakerTestProps;
}

export const ExtendedBar: React.FC<Props> = ({ speakersTest }) => {
  const { barState } = useContext(CTIBarContext);
  const {
    isBarExtended,
    isDragging,
    isBarCompressed,
    positionX,
    keepBarExtended
  } = barState;

  const origin = positionX / window.innerWidth <= 0.5 ? 'left' : 'right'; //Check if the cti is on the left or right
  const leftStyle = isBarCompressed ? 'auto' : origin === 'left' ? 0 : 'auto'; //Border radius depending on origin and size
  const rightStyle = isBarCompressed ? 'auto' : origin === 'right' ? 0 : 'auto';

  const offset = isBarCompressed ? 46 : 80; //Size of the MainButtons component

  return (
    <Box
      sx={{
        position: 'absolute',
        right: origin === 'right' ? offset : 'auto',
        left: origin === 'left' ? offset : 'auto'
      }}
    >
      <Grow
        in={keepBarExtended || isDragging || isBarExtended}
        timeout={{ appear: 0, enter: 250, exit: 1500 }}
        style={{
          transformOrigin: origin
        }}
        unmountOnExit
      >
        <Card
          sx={{
            borderTopLeftRadius: leftStyle,
            borderBottomLeftRadius: leftStyle,
            borderTopRightRadius: rightStyle,
            borderBottomRightRadius: rightStyle
          }}
        >
          <Stack
            direction="row"
            alignItems={'center'}
            spacing={'3px'}
            p={isBarCompressed ? '3px' : 1}
          >
            <NewConversation />
            <ConferenceInfo />
            <CallInfo />
            <HangupButton />

            <DevicesMenu speakersTest={speakersTest} />
            <CompressBarButton />
            <LockCTIButton />
            <CallButtons />
            {/* CallButtons --> Buttons that are only displayed while in call */}
          </Stack>
        </Card>
      </Grow>
    </Box>
  );
};
