import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
  useTheme
} from '@mui/material';

import { Conversation } from 'src/models/conversations/conversations';
import { Dispatch, MouseEvent, SetStateAction, useRef, useState } from 'react';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import ContactListPopover from '../../ContactDataPanel/ContactDataListView/searchContactListPopover/SearchContactListPopover';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
	  width: ${theme.spacing(6)};
	  height: ${theme.spacing(6)};
  `
);

interface Props {
  contact: Contact;
  companyFields: ContactField[];
  conversation: Conversation;
  setSelectedContact: Dispatch<SetStateAction<Contact>>;
  setIsAdding: Dispatch<SetStateAction<boolean>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const NoteContactListItem = (props: Props) => {
  const {
    contact,
    companyFields,
    conversation,
    setSelectedContact,
    setIsAdding,
    setIsEditing
  } = props;

  const [openSort, setOpenMenuSort] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

  const onClickAddToExisting = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  const actionRef = useRef<any>(null);

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const nameContactFieldsQuery = useGetContactFields(
    {
      companyId: conversation.companyId,
      type: 'Name',
      contactGroupId: contact.contactGroupId
    },
    !!conversation
  );

  if (nameContactFieldsQuery.isLoading) return <CircularProgress />;

  const contactNameKey = nameContactFieldsQuery.data.elements[0];

  const contactName = contact.fields[contactNameKey.id];

  const contactLabel = contact.label;

  return (
    <ListItem
      sx={{
        borderRadius: 0
      }}
    >
      <ListItemAvatar>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          overlap="circular"
        >
          <AvatarWrapper alt={contactName && contactName}>
            <Typography variant="h3" color="white">
              {contactName && contactName.charAt(0)}
            </Typography>
          </AvatarWrapper>
        </Badge>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Typography variant="h4" gutterBottom noWrap>
            {contactLabel && contactLabel.value}
          </Typography>
        }
        secondary={<Typography variant="subtitle2"></Typography>}
        sx={{ ml: theme.spacing(1.5) }}
      />
      {contact.id && (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          sx={{ width: '120px', minWidth: '120px' }}
          onClick={() => {
            setOpenMenuSort(true);
            setSelectedContact(contact);
          }}
          id="btnView-NoteContactListItem"
        >
          {t('View notes')}
        </Button>
      )}

      {!contact.id && (
        <>
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            ref={actionRef}
            sx={{ width: '120px', minWidth: '120px' }}
            onClick={() => {
              setOpenMenuSort(true);
            }}
            id="btnAdd-ContactListItem"
          >
            {t('Add contact')}
          </Button>
          <Menu
            disableScrollLock
            anchorEl={actionRef.current}
            onClose={() => setOpenMenuSort(false)}
            open={openSort}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem
              sx={{ color: 'primary' }}
              onClick={() => {
                setSelectedContact(contact);
                setIsAdding(true);
                setOpenMenuSort(false);
              }}
            >
              <PersonAddAltIcon />
              <Typography variant="body1" sx={{ ml: theme.spacing(1) }}>
                {t('Add as new contact')}
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{
                color: 'primary'
              }}
              onClick={(e) => {
                setOpenMenuSort(false);
                onClickAddToExisting(e);
              }}
            >
              <PersonOutlineIcon />
              <Typography variant="body1" sx={{ ml: theme.spacing(1) }}>
                {t('Add to an existing contact')}
              </Typography>
            </MenuItem>
          </Menu>
          <ContactListPopover
            key={contact.id}
            isOpen={isOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            companyFields={companyFields}
            selectedContact={contact}
            setSelectedContact={setSelectedContact}
            setIsEditing={setIsEditing}
          />
        </>
      )}
    </ListItem>
  );
};

export default NoteContactListItem;
