import { ExtensionProps } from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/models/acdRoutes';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { Company, User } from 'src/models/users';
import {
  CompanyParams,
  getCompanies,
  getUsedExtensionsCompanyByIdApi
} from '../infraestructure/api/companies/getCompanies';
import { getDaServices } from '../infraestructure/api/services/getDaServices';
import { getExtensionById } from 'src/services/acd/domain/getRoutesData';
import {
  getUsersApi,
  getUsersListApi,
  UsersParams,
  getUserByIdApi
} from '../infraestructure/api/users/getUsers';
import { PaginatedElements } from 'src/models/pagination';

/** GET ALL COMPANIES
 *
 * Get all companies in database
 *
 *
 * @returns array of companies
 */
export const getAllCompanies = async (
  params?: CompanyParams
): Promise<Company[]> => {
  let allCompanies = (await getCompanies(params)) as Company[];

  return allCompanies.map((c) => {
    return {
      ...c,
      userStatuses: c.userStatuses.map((status) => {
        return {
          ...status,
          color: `#${status.color}`
        };
      })
    };
  });
};

/** GET ALL SERVICES
 *
 * Get all services in database
 *
 *
 * @returns array of companies
 */
export const getAllServices = async (): Promise<DaService[]> => {
  let allCompanies = await getDaServices();
  return allCompanies;
};

/** GET ALL USERS
 *
 * Get all users
 *
 *
 * @returns users array
 */
export const getAllUsers = async (
  params?: UsersParams
): Promise<PaginatedElements<User>> => {
  const allUsers = await getUsersApi({ ...params, size: params?.size ?? 0 });
  return allUsers;
};

/** GET USED EXTENSIONS FOR A COMPANY
 *
 * Get used extension for a company
 *
 *
 * @returns users array
 */
export const getUsedExtensionsCompanyById = async (
  id: string
): Promise<string[]> => {
  const allExtension = await getUsedExtensionsCompanyByIdApi(id);
  return allExtension;
};

/** GET AVAILABLE EXTENSIONS FOR A COMPANY
 *
 * Get available extensions for a company
 *
 *
 * @returns users array
 */
export const getAvailableExtensionsCompanyById = async (
  id: string,
  user: User
): Promise<ExtensionProps[]> => {
  // Si no hay Id no ejecuto el query
  if (!id) return [];

  // Si hay Id ejecuto query
  let extensions = (await getExtensionById(id)).elements;
  let usedExtensions = await getUsedExtensionsCompanyById(id);
  let availableExtensions = [];
  let myExtension;

  // Si extensiones en uso tiene elementos
  if (extensions.length > 0 && usedExtensions.length > 0) {
    availableExtensions = extensions.filter(
      (ext) => !usedExtensions.includes(ext.extension)
    );
  } else {
    availableExtensions = extensions;
  }

  // Si existe usuario y encuentro mi extension la asigno para mostrarla en el formulario
  if (user) {
    myExtension = extensions.find(
      (ext) => ext.extension === user.fixedExtension
    );
    if (myExtension !== undefined) {
      availableExtensions.push(myExtension);
    }
  }

  return availableExtensions;
};

export const getUsersList = async (userIds: string[]) => {
  const ids = userIds.filter((id) => id !== 'SYSTEM');
  const resp = await getUsersListApi(ids);
  return resp;
};

export const getUserById = async (userId: string) => {
  if (!userId) return null;
  const resp = await getUserByIdApi(userId);
  return resp;
};
