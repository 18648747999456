import { useState } from 'react';
import { t } from 'i18next';
import {
  Box,
  IconButton,
  Link,
  Modal,
  Paper,
  Slider,
  Stack,
  Typography
} from '@mui/material';
import { CloseSharp, Download } from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { isImageFile } from '../utils/utils';

interface Props {
  file: File;
  openPreview: boolean;
  setOpenPreview: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PreviewAttachmentFile: React.FC<Props> = ({
  file,
  setOpenPreview,
  openPreview
}) => {
  const [zoom, setZoom] = useState(0);

  const title = file?.name;

  function handleScroll(e: React.WheelEvent<HTMLDivElement>) {
    e.preventDefault();
    const delta = e.deltaY;
    setZoom((prevZoom) => {
      const newZoom = prevZoom + (delta > 0 ? -10 : 10);
      return Math.max(Math.min(newZoom, 100), 0);
    });
  }

  return (
    <Box sx={{ cursor: 'pointer', width: 'max-content' }}>
      {/* POPOVER */}
      {openPreview && (
        <Modal open={openPreview}>
          <Stack
            width="100vw"
            height="100vh"
            justifyContent="center"
            alignItems={'center'}
          >
            <Stack
              width="100%"
              height={'100%'}
              sx={{
                background: 'rgba(181, 181, 181, 0.6)'
              }}
            >
              <Stack
                direction="row"
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ background: 'white' }}
                px={2}
                py={0.5}
                zIndex={100}
                height="60px"
              >
                <Stack
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={2}
                  flexGrow={1}
                  flexBasis={0}
                >
                  <Typography style={{ margin: 0, textOverflow: 'ellipsis' }}>
                    {title}
                  </Typography>
                </Stack>
                {isImageFile(file) && (
                  <Stack
                    direction="row"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={2}
                  >
                    <label>Zoom</label>
                    <Slider
                      value={zoom}
                      onChange={(e, value) => setZoom(value as number)}
                      min={0}
                      max={100}
                      style={{ width: 100 }}
                    />
                    <label>{zoom}%</label>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  flexGrow={1}
                  flexBasis={0}
                >
                  <Link
                    href={URL.createObjectURL(file)}
                    download={file.name}
                    target="_blank"
                    color="inherit"
                  >
                    <IconButton>
                      <Download />
                    </IconButton>
                  </Link>

                  <IconButton
                    onClick={() => {
                      setOpenPreview(false);
                      setZoom(0);
                    }}
                  >
                    <CloseSharp />
                  </IconButton>
                </Stack>
              </Stack>
              <Box
                sx={{
                  height: `calc(100% - 60px)`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {file.type.split('/')[0] === 'video' ? (
                  <Box
                    sx={{
                      position: 'relative',
                      aspectRatio: '16/9',
                      borderRadius: '8px',
                      paddingX: 1
                    }}
                  >
                    <video
                      controls
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px'
                      }}
                    >
                      <source src={URL.createObjectURL(file)} />
                    </video>
                  </Box>
                ) : isImageFile(file) ? (
                  <img
                    onWheel={handleScroll}
                    style={{
                      objectFit: 'contain',
                      maxHeight: '90%',
                      minHeight: '60%',
                      maxWidth: 'calc(100vw-40px)',
                      transform: `scale(${(zoom + 50) / 100})`
                    }}
                    onClick={() => setOpenPreview(true)}
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                  />
                ) : file.type === 'application/pdf' ? (
                  <object
                    data={URL.createObjectURL(file)}
                    width="100%"
                    height="100%"
                    name={file.name}
                  ></object>
                ) : (
                  <Box width="100%" height="100%">
                    <Paper
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'lightgrey',
                        borderRadius: 0
                      }}
                    >
                      <DescriptionOutlinedIcon
                        sx={{ width: '50px', height: '50px' }}
                      />
                      <Typography variant="h5">
                        {t('No preview available')}
                      </Typography>
                    </Paper>
                  </Box>
                )}
              </Box>
            </Stack>
          </Stack>
        </Modal>
      )}
    </Box>
  );
};
