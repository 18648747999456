import { Box, Divider, Stack, useTheme, Button } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import { SearchBar } from 'src/components/SearchBar';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import CallManagerDomain from 'src/modules/CTI/CallManager';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getRTUsersStore } from 'src/services/rtusers/infraestructure/store/getRTUsersStore';
import { setActiveRTCallId } from 'src/store/slices/calls/calls';
import { useAlert } from 'src/hooks/useAlert';
import { useSelector } from 'src/store/store';
import { RTUser } from 'src/models/rtUser';

export const TransferUsers: React.FC = () => {
  const theme = useTheme();
  const connectedRTUser = getConnectedRTUser();
  const activeCall = getActiveRTCall(connectedRTUser);
  const { setCallToTransferID } = useContext(CTIBarContext);
  // const rtUsers = getRTUsersStore(true).filter((user) => {
  //   return user.id !== connectedRTUser.id;
  // });

  let rtUsersObj = useSelector((state) => state.rtusers.rtUsers);
  let rtUsers = Object.values(rtUsersObj).filter((user) => {
    return user.id !== connectedRTUser.id;
  });

  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<RTUser>(null);
  const selectedExtension = selectedUser?.extension?.complexExtension;
  const { showAlert } = useAlert();

  const handleTransfer = () => {
    if (!selectedUser) {
      showAlert('Please select an agent', 'info', 3000);
      return;
    }

    const extension = rtUsers.find((user) => {
      return (
        selectedUser.extension?.complexExtension ===
        user.extension?.complexExtension
      );
    })?.extension?.extension;

    CallManagerDomain.transferCall({
      call: activeCall,
      extension,
      isInternal: true
    })
      .then(() => {
        emitCustomEvent('Transfer in progress', activeCall.id);
        emitCustomEvent('Close popover');
        showAlert('Transfer in progress', 'success', 3000);
      })
      .catch(() => {
        showAlert('Transfer failed', 'error', 3000);
      });
  };

  const handleAttendedTransfer = () => {
    if (!selectedUser) {
      showAlert('Please select an agent', 'info', 3000);
      return;
    }

    CallManagerDomain.attendedTransferCall(
      activeCall,
      selectedUser.extension.extension,
      true
    ).then((response: AxiosResponse) => {
      if (response.status === 200) {
        setActiveRTCallId(activeCall.callId);
        setCallToTransferID(activeCall.callId);
      }
    });
  };

  return (
    <Box width="100%" p={1}>
      <Stack
        direction="column"
        justifyContent="space-between"
        rowGap={1}
        height="420px"
      >
        <Stack direction={'column'} rowGap={1}>
          <h2 style={{ margin: 0, flex: 1 }}>{t('Select an agent:')}</h2>

          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Search by extension or name"
          />
        </Stack>
        <Scrollbar disableAutoHide>
          <Stack
            direction="column"
            divider={<Divider orientation="horizontal"></Divider>}
            sx={{ height: '300px' }}
          >
            {rtUsers
              .sort((a, b) =>
                a.extension?.extension <= b.extension?.extension ? -1 : 1
              )
              .filter((user) => {
                return (
                  user.extension?.extension &&
                  (user.extension?.extension.includes(searchValue) ||
                    user.username
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()))
                );
              })
              .map((user, idx) => {
                if (user.companyId !== connectedRTUser.companyId) return null;
                return (
                  <Button
                    key={idx}
                    sx={{
                      backgroundColor:
                        selectedExtension === user.extension?.complexExtension
                          ? theme.colors.secondary.lighter
                          : 'white',
                      '&:hover': {
                        backgroundColor:
                          selectedExtension === user.extension?.complexExtension
                            ? theme.colors.secondary.lighter
                            : 'inherited'
                      },
                      borderRadius: 0
                    }}
                    onClick={() => {
                      setSelectedUser(user);
                    }}
                    id="extension-TransferUsers"
                  >
                    {user.extension?.extension + ' - ' + user.username}
                  </Button>
                );
              })}
          </Stack>
        </Scrollbar>
        <Stack direction="row" justifyContent={'space-between'} gap={5}>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={() => {
              handleAttendedTransfer();
            }}
            id="btnCall-TransferUsers"
          >
            {t('Call')}
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="warning"
            onClick={() => {
              handleTransfer();
            }}
            id="btnTransfer-TransferUsers"
          >
            {t('Transfer')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
