import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { getCompanyFeatures } from '../../modules/Admin/modules/General/modules/Companies/domain/companyFeatures/getCompanyFeatures';
import {
  CompanyFeatures,
  Features
} from '../../modules/Admin/modules/General/modules/Companies/models/CompanyFeatures';
import getEnabledChannelsByFeatures from './utils/getEnabledChannelsByFeatures';
import { ChannelType } from 'src/models/conversations/conversations';
import { getChannelFeatureChecker } from './utils/checkFeatures';
import AuthManager from 'src/services/authentication/manager';
import { logger } from 'src/utils/logger';

export type CompanyFeaturesStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

interface FeaturesContextState {
  companyFeatures: CompanyFeatures | null;
  enabledChannelsByFeatures: ChannelType[];
  checkChannelIsEnabled: (channel: ChannelType) => boolean;
  hasFeature: (featureCheckerFn: (features: Features) => boolean) => boolean;
  loading: CompanyFeaturesStatus;
}

const FeaturesContext = createContext<FeaturesContextState>({
  companyFeatures: null,
  enabledChannelsByFeatures: [],
  checkChannelIsEnabled: () => false,
  hasFeature: () => false,
  loading: 'idle'
});

export function useFeaturesContext() {
  const context = useContext(FeaturesContext);
  if (!context) {
    throw new Error(
      'useFeaturesContext must be used within an FeaturesProvider'
    );
  }

  return context;
}

export const FeaturesProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [loading, setLoading] = useState<CompanyFeaturesStatus>('idle');
  const companyId = AuthManager.getLoggedUserCompanyId();

  const [companyFeatures, setCompanyFeatures] =
    useState<CompanyFeatures | null>(null);

  useEffect(() => {
    if (companyId) {
      setLoading('pending');
      getCompanyFeatures(companyId)
        .then((resp) => {
          setCompanyFeatures(resp);
          setLoading('succeeded');
        })
        .catch((err) => {
          logger.error(err);
          setLoading('failed');
        });
    }
  }, [companyId]);

  const enabledChannelsByFeatures: ChannelType[] = useMemo(() => {
    if (!companyFeatures) return [];
    return getEnabledChannelsByFeatures(companyFeatures?.features);
  }, [companyFeatures?.features]);

  const checkChannelIsEnabled = useCallback(
    (channel: ChannelType) => {
      if (!companyFeatures?.features) return false;
      const channelFeatureChecker = getChannelFeatureChecker(channel);
      return channelFeatureChecker(companyFeatures?.features);
    },
    [companyFeatures?.features]
  );

  const hasFeature = useCallback(
    (featureCheckerFn: (features: Features) => boolean) => {
      if (companyFeatures === null) return null;

      return featureCheckerFn(companyFeatures.features);
    },
    [companyFeatures]
  );

  return (
    <FeaturesContext.Provider
      value={{
        companyFeatures,
        checkChannelIsEnabled,
        enabledChannelsByFeatures,
        hasFeature,
        loading
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesContext;
