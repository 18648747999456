import deleteShowflowApi, {
  deleteShowflowDraftApi
} from '../../infrastructure/showflow/deleteShowflowApi';
import { Showflow } from '../../models/showflow';

export async function deleteShowflow(showflow: Showflow) {
  if (!showflow?.id) return;
  return deleteShowflowApi(showflow.id);
}

export async function deleteShowflows(showflows: Showflow[]) {
  const promises = showflows.map((showflow) => deleteShowflow(showflow));
  const results = await Promise.allSettled(promises);
  return results.every((result) => result.status === 'fulfilled');
}

export async function deleteShowflowDraft(draftId: string) {
  return deleteShowflowDraftApi(draftId);
}

export async function deleteShowflowDrafts(draftIds: string[]) {
  const deletePromises = draftIds?.map((id) => {
    deleteShowflowDraft(id);
  });
  await Promise.all(deletePromises);
}
