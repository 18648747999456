import { UserRole } from 'src/models/permissionGroups';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getUserRoles } from '../domain/getUserRoles';
import { GroupsFilters } from '../infrastructure/getGroupsApi';
import { queryClient } from 'src/utils/queryClient';

export const useGetUserRolesQuery = (
  params?: GroupsFilters,
  enabled?: boolean
): UseQueryResult<UserRole[]> => {
  return useQuery<UserRole[], Error>(
    ['userRoles', params?.companyId, params?.groupType, params?.userId],
    () => getUserRoles(params),
    { enabled, staleTime: 3 * 60 * 1000 }
  );
};

export const getUserRolesQuery = async (params?: GroupsFilters) => {
  return queryClient.fetchQuery(
    ['userRoles', params?.companyId, params?.groupType, params?.userId],
    {
      queryFn: () => getUserRoles(params),
      staleTime: 3 * 60 * 1000
    }
  );
};
