import {
  ALL_CHANNEL_TYPES,
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';

export function handleFilterAssignedConversations(
  conv: Conversation,
  inputValue: string
) {
  if (inputValue === '') return true;

  const f = inputValue.toLowerCase();

  if (conv.involvedContacts[0]?.socialmediaAgent?.phoneNumber?.includes(f)) {
    return true;
  }
  if (conv.involvedContacts[0]?.socialmediaAgent?.endpoint?.includes(f)) {
    return true;
  }
  if (conv.originAgent.userName?.toLowerCase().includes(f)) {
    return true;
  }
  if (conv.involvedContacts[0]?.socialmediaAgent?.emailAddress?.includes(f)) {
    return true;
  }
  return false;
}

export type ChannelCurrentPage = {
  [key in ChannelType | 'All']: number;
};

export const initializeChannelCurrentPage = () => {
  return [...ALL_CHANNEL_TYPES, 'All'].reduce((obj, channel) => {
    return { ...obj, [channel]: 0 };
  }, {} as ChannelCurrentPage);
};

export const binSizes: readonly string[] = [
  'Bytes',
  'KiB',
  'MiB',
  'GiB',
  'TiB',
  'PiB',
  'EiB',
  'ZiB',
  'YiB'
];

export const decSizes: readonly string[] = [
  'Bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB'
];

/**
 * @param bytes
 * @param decimals The default is 2
 * @param si true to use SI units (decimals) and false to use binary units (ISO/IEC 80000-13). The default is true
 */
export const formatBytes = (params: {
  bytes: number;
  decimals?: number;
  si?: boolean;
}) => {
  const { bytes, decimals = 2, si = true } = params;
  if (!bytes) return '0 Bytes';

  const k = si ? 1000 : 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const sizes = si ? decSizes : binSizes;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isImageFile = (file: File) => {
  if (!file) return false;
  const fileName = file?.name.toLowerCase();
  return (
    file.type.split('/')[0] === 'image' ||
    fileName.includes('.png') ||
    fileName.includes('.jpg') ||
    fileName.includes('.jpeg')
  );
};
