import { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { VariableNode } from '../VariableNode';
import VariableModalSelector from './modal';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection } from 'lexical';
import useUpdate from 'src/hooks/useUpdateEffect';
import useVariableId from '../../hooks/useVariableId';
import { KeyboardReturn } from '@mui/icons-material';
import { t } from 'i18next';

interface Props {
  node: VariableNode;
}

function VariableNodeComponent({ node }: Props) {
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(
    node.getKey()
  );

  const variableId = node.getVariableId();
  const companyId = node.getCompanyId();

  const variable = useVariableId({ variableId, companyId });

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    editor.update(() => {
      if (!isSelected) {
        setIsFocused(false);
        return;
      }
      const selection = $getSelection();
      const nodes = selection.getNodes().length;
      if (nodes > 1) return;
      setIsFocused(true);
    });
  }, [isSelected, variableId]);

  useEffect(() => {
    if (!variableId) {
      setIsOpen(true);
    }
  }, []);

  useUpdate(() => {
    if (!isOpen && !variableId) {
      editor.update(() => {
        node.remove();
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isFocused) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          setIsOpen(true);
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isFocused]);

  const theme = useTheme();

  return (
    <>
      <Tooltip
        open={isFocused && !isOpen}
        arrow
        placement="bottom"
        title={
          <Stack direction="row" alignItems="center" columnGap={1}>
            <KeyboardReturn
              fontSize="small"
              sx={{ width: 16, paddingTop: 0.2 }}
            />
            <Typography>{t('to edit')}</Typography>
          </Stack>
        }
      >
        <span
          ref={ref}
          style={{
            display: 'inline-flex',
            transformOrigin: 'left center',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 2px',
            background: 'white',
            borderRadius: '4px',
            overflow: 'hidden',
            backgroundColor: isFocused
              ? theme.colors.secondary.light
              : '#E0E0E0',
            fontWeight: 'bold',
            color: '#000000',
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.05)'
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <span
            style={{
              display: 'inline-block',
              height: '21px',
              maxWidth: '175px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              margin: 0
            }}
          >
            {variable?.name}
          </span>
          {!variable?.name && <CircularProgress size={12} />}
        </span>
      </Tooltip>
      <VariableModalSelector
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        node={node}
        parentRef={ref}
      />
    </>
  );
}

export default VariableNodeComponent;
