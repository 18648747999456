import { Contact, ContactField } from 'src/modules/Contacts/models/contacts';
import { Ticket, TicketField } from '../../../../models/tickets';
import {
  ShowflowVariableType,
  ShowflowVariableTypeConverter
} from '../hooks/useShowflowVariable';
import { Variable } from 'src/modules/Admin/modules/General/modules/Variables/models/models';
import { getContactFieldByIdQuery } from 'src/modules/Contacts/domain/getContactFields';
import { getTicketFieldByIdQuery } from '../../../../domain/fields/getTicketField';
import { getSelectedService } from 'src/services/daServices/domain/getSelectedService';
import { getCompanyVariablesQuery } from 'src/modules/Admin/modules/General/modules/Variables/application/getVariablesQuery';
import { getVariableByIdQuery } from 'src/modules/Admin/modules/General/modules/Variables/domain/getVariableByIdDomain';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { ChannelType } from 'src/models/conversations/conversations';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getRTCallByCallIdApi } from 'src/modules/CTI/infrastructure/getCallsByCallIdApi';
import { getReportingCallSummaryByIdApi } from 'src/modules/Productivity/modules/Calls/infrastructure/api/getReportingCallSummaryApi';

async function getVariable(id: string) {
  if (id.includes('default')) {
    const service = getSelectedService();
    const companyId = service?.companyId;
    const variables = await getCompanyVariablesQuery({ companyId });
    const variable = variables?.elements.find((variable) => variable.id === id);
    return variable;
  } else {
    const variable = await getVariableByIdQuery(id);
    return variable;
  }
}

export async function getShowflowVariable(
  variableId: string,
  contact?: Contact,
  ticket?: Ticket
) {
  let type: ShowflowVariableType;
  let field: TicketField | ContactField | Variable;
  let value: string | string[] | boolean;

  const [typeAbbreviation, ...rest] = variableId.split('-');
  const id = rest.join('-');
  const variableType = ShowflowVariableTypeConverter[typeAbbreviation];

  if (!variableType) return null;
  type = variableType;

  if (variableType === 'Contact') {
    field = await getContactFieldByIdQuery(id);

    const fieldValue = contact?.fields[id];
    value = fieldValue;
  }

  if (variableType === 'Ticket') {
    field = await getTicketFieldByIdQuery(id);
    const ticketFieldValue = ticket?.fields[id]?.value;
    value = ticketFieldValue;
  }

  if (variableType === 'Company') {
    field = await getVariable(id);
    value = field.value;
  }

  if (variableType === 'Call') {
    field = await getVariable(id);

    if (contact && ticket) {
      const currentConversation = getCurrentConversation(false);
      if (currentConversation?.channel !== ChannelType.CALL) {
        value = undefined;
        return;
      }
      const activeCall = getActiveRTCall(getConnectedRTUser(false));
      const isAnActiveCall =
        activeCall?.callId === currentConversation.originAgent.callId;

      if (isAnActiveCall) {
        const instanceId = activeCall.instanceId;
        const rtCall = await getRTCallByCallIdApi(
          activeCall.callId,
          instanceId
        );
        const variable = rtCall?.variables[id];
        value = variable?.value;
      } else {
        const callId = currentConversation.originAgent?.callId;
        const call = await getReportingCallSummaryByIdApi(callId);
        value = call?.variables[field.name];
      }
    }
  }

  return { type, field, value };
}
