import { ReactNode } from 'react';
import { MessageButton } from 'src/models/conversations/messages';

export interface WhatsAppTemplate {
  accountId: string;
  category: WhatsAppTemplateCategory | string;
  companyId: string;
  content: string;
  description: string;
  exampleVariableValues: WhatsAppTemplateVariables;
  id?: string;
  language: WhatsAppTemplateLanguage | string;
  name: string;
  providerId: string;
  status: WhatsAppTemplateStatus;
  type: WhatsAppTemplateType;
  file?: File;
  buttons: MessageButton[];
}

export type WhatsAppTemplateKeys = keyof WhatsAppTemplate;

export interface WhatsAppTemplateVariables {
  [key: string]: string;
}

export enum WhatsAppButtonsTypes {
  COPY_CODE = 'COPY_CODE',
  OTP = 'OTP',
  PHONE_NUMBER = 'PHONE_NUMBER',
  QUICK_REPLY = 'QUICK_REPLY',
  URL = 'URL'
}

export enum WhatsAppTemplateCategory {
  MARKETING = 'Marketing',
  AUTHENTICATION = 'Authentication',
  UTILITY = 'Utility'
}

export enum WhatsAppTemplateLanguage {
  ENGLISH = 'English',
  SPANISH = 'Spanish'
}

export enum WhatsAppTemplateStatus {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  DELETED = 'Deleted',
  FAILED = 'Failed'
}

export enum WhatsAppTemplateType {
  DOCUMENT = 'Document',
  IMAGE = 'Image',
  TEXT = 'Text'
}

export const WhatsAppTemplateAcceptedFileExtensions = {
  IMAGE: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] },
  DOCUMENT: {
    'text/plain': ['.txt'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx'
    ],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx'
    ],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      ['.pptx'],
    'application/pdf': ['.pdf']
  },
  ANY: { '*': ['*'] }
};

export enum WhatsAppMaxByteFileSizes {
  DOCUMENT = 10000000,
  IMAGE = 5000000
}

export interface WhatsAppTemplateCategoryItem {
  id: string;
  title: string;
  value: WhatsAppTemplateCategory;
  description: string;
  icon?: ReactNode;
}
