import axios from 'axios';
import { TicketSubResolution } from '../../models/tickets';
import { TICKET_SUBRESOLUTIONS } from 'src/utils/apiRoutes/msMessaging';

export const createTicketSubResolutionApi = async (
  ticketSubResolution: TicketSubResolution
): Promise<TicketSubResolution> => {
  const resp = await axios.post(TICKET_SUBRESOLUTIONS, ticketSubResolution);
  return resp.data;
};
