import store, { useSelector } from 'src/store/store';

export const getWebrtcWSConnectedStore = (shouldUseHook: boolean) => {
  const connected = (
    shouldUseHook
      ? useSelector((state) => state.userData.isWebrtcWSConnected)
      : store.getState().userData.isWebrtcWSConnected
  ) as boolean;

  return connected;
};
