import { TicketField, TicketFieldType } from '../../models/tickets';

const ticketFieldOrder: Record<TicketFieldType, number> = {
  Text: 0,
  DateTime: 1,
  Options: 2,
  Boolean: 3
};

export const sortTicketFields = (ticketFields: TicketField[]) => {
  if (!ticketFields || ticketFields.length === 0) return [];
  return [...ticketFields].sort(compareTicketFields);
};

export const compareTicketFields = (a: TicketField, b: TicketField) => {
  return ticketFieldOrder[a.type] - ticketFieldOrder[b.type];
};
