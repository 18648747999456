import { Conversation } from 'src/models/conversations/conversations';
import {
  addConversationsStore,
  setConversationsStore
} from '../../infraestructure/store/setConversationsStore';
import { getStoredConversations } from '../../../conversations/domain/getConversations';

/** UPDATE LIST OF CONVERSATIONS
 *
 */
export const updateConversations = (conversations: Conversation[]) => {
  addConversationsStore(conversations);
};

export const unassignContactFromConversations = (contactId: string) => {
  const conversations = getStoredConversations({ shouldUseHook: false });

  const updatedConversations = conversations.map((conversation) => {
    const involvedContacts = conversation.involvedContacts.map(
      (involvedContact) => {
        if (involvedContact.contactId === contactId) {
          involvedContact = { ...involvedContact, contactId: null };
        }
        return involvedContact;
      }
    );
    return { ...conversation, involvedContacts: involvedContacts };
  });
  setConversationsStore(updatedConversations);
};
