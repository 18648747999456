import LocalParkingIcon from '@mui/icons-material/LocalParking';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  Telegram,
  WhatsApp,
  Chat,
  Videocam,
  CallEndOutlined
} from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import CompressIcon from '@mui/icons-material/Compress';
import { Box } from '@mui/system';

export type ConversationIconTypes =
  | 'Call'
  | 'InCall'
  | 'Telegram'
  | 'WhatsApp'
  | 'WebChat'
  | 'Email'
  | 'Talking'
  | 'Ringing'
  | 'Finished'
  | 'Queued'
  | 'Preparing'
  | 'Parked'
  | 'VideoCall'
  | 'Instagram';

const IconComponents: Record<ConversationIconTypes, JSX.Element> = {
  Call: <CallEndOutlined color="primary" />,
  VideoCall: <Videocam color="primary" />,
  InCall: <WifiCalling3Icon color="success" />,
  Telegram: <Telegram color="secondary" />,
  WhatsApp: <WhatsApp color="success" />,
  WebChat: <Chat color="warning" />,
  Email: <EmailOutlinedIcon />,
  Talking: <BeenhereOutlinedIcon color="primary" />,
  Ringing: <NotificationsActiveIcon />,
  Finished: <CheckCircleIcon />,
  Queued: <GroupIcon />,
  Preparing: <CompressIcon />,
  Instagram: <InstagramIcon />,
  Parked: (
    <Box sx={{ width: '20px', height: '20px', background: '#EBF9FF' }}>
      <LocalParkingIcon color="secondary" fontSize="small" />
    </Box>
  )
};

export const getIconComponent = (key: ConversationIconTypes): JSX.Element => {
  return IconComponents[key];
};
