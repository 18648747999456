import React from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import FlagTwoToneIcon from '@mui/icons-material/FlagTwoTone';
import { PrioritySchedule } from 'src/modules/DialerBot/models/dialerbot';
import { t } from 'i18next';
import { arrayPriority } from '../../utils/constants';

interface Props {
  priority: PrioritySchedule;
  onChangePriority: (data: PrioritySchedule) => void;
  fullWidth: boolean;
  showText: boolean;
}

const PriorityForm: React.FC<Props> = ({
  priority,
  onChangePriority,
  fullWidth,
  showText
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: {
          xs: '100%',
          sm: fullWidth ? '100%' : '90%',
          md: fullWidth ? '100%' : '55%'
        }
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          width: '100%',
          padding: { xs: '4px', sm: '4px', md: '7px' },
          background: '#FFFFFF',
          borderRadius: '10px',
          border: '1px solid #9099A9',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            background: '#D5DBDB'
          },
          '& .MuiInputBase-root': {
            width: '100%',
            '& div': {
              padding: '0px !important',
              '& span': {
                display: showText ? 'block' : 'none'
              }
            },
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            '& fieldset': {
              border: 'none'
            },
            '& .MuiSelect-iconOutlined': {
              display: 'none'
            }
          },
          '& label': {
            display: 'none'
          },
          '& button': {
            display: 'none'
          }
        }}
      >
        <Select
          value={priority}
          onChange={async (e) => {
            onChangePriority(e.target.value as PrioritySchedule);
          }}
        >
          {arrayPriority.map((priority) => (
            <MenuItem key={priority.id} value={priority.value}>
              <FlagTwoToneIcon
                fontSize="large"
                sx={{
                  color: priority.color
                }}
              />
              <span>{t(priority.name)}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default PriorityForm;
