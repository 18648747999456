import axios from 'axios';
import { replaceURLParams } from 'src/services/utils';
import { CONTACT_FIELDS_ID } from 'src/utils/apiRoutes/msContacts';
import { queryClient } from 'src/utils/queryClient';

export const deleteContactFieldApi = async (id: string) => {
  const path = replaceURLParams(CONTACT_FIELDS_ID, { '{id}': id });
  await axios.delete(path);
  queryClient.invalidateQueries({
    queryKey: ['companyFields']
  });
};
