import { MenuItem, Select, useTheme } from '@mui/material';
import { useMicrophone } from './useMicrophone';

export const VoiceSelect: React.FC = () => {
  const theme = useTheme();
  const [voiceDevice, mediaDevices, onSelectChange] = useMicrophone();

  return (
    <>
      {mediaDevices.length > 0 && (
        <Select
          value={voiceDevice.label}
          sx={{
            width: '175px',
            backgroundColor: theme.colors.secondary.main,
            color: 'white'
          }}
          rows={2}
          id="selectVoice-VoiceSelect"
        >
          <MenuItem sx={{ display: 'none' }} value={voiceDevice.label}>
            {voiceDevice.label}
          </MenuItem>
          {mediaDevices.map((device, idx) => {
            return (
              <MenuItem
                key={idx}
                onClick={(): void => {
                  onSelectChange(device.label);
                }}
                value={device.label}
              >
                {device.label}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </>
  );
};
