import { Card, Stack } from '@mui/material';
import { Rnd } from 'react-rnd';
import { getRTCSessions } from 'src/services/webrtc/domain/getRTCSessions';
import { removeRTCSession } from 'src/services/webrtc/domain/removeRTCSession';
import { getCallerIdDataFromSession } from 'src/services/webrtc/domain/utils';
import WebRTCNotifications from 'src/services/webrtc/notifications/webrtc-notifications';
import WebRTCCallManagement from 'src/services/webrtc/webrtc-call-management';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { Item } from './Item';
import { setActiveRTCallId } from 'src/services/rtusers/domain/rtuser/setActiveRTCall';
import { getMutedQueuesDomain } from 'src/services/rtusers/domain/queues/mutedQueues';
import WebRTCService from 'src/services/webrtc/webrtc.service';
import { useEffect } from 'react';

interface AnswerCardProps {}

export const AnswerCard: React.FC<AnswerCardProps> = ({}) => {
  const mutedQueues = getMutedQueuesDomain(true);
  const sessions = getRTCSessions(true).filter((session) => {
    const callerIdData = getCallerIdDataFromSession(session);
    const queueIsMuted = mutedQueues.find(
      (queueId) => queueId === callerIdData.campaignId
    );
    const isAutoanswered =
      WebRTCService.shouldWebrtcsessionBeAutoanswered(session);
    return !queueIsMuted && !isAutoanswered;
  });

  const ringingRessions = sessions.filter((s) => s.status === 'Ringing');

  const answerCall = (session: WebRTCSession): void => {
    if (session != undefined) {
      WebRTCCallManagement.attendCall(session);
      setActiveRTCallId('');
    }
  };

  const hangupCall = (session): void => {
    removeRTCSession(session);
    if (session != undefined) {
      WebRTCCallManagement.hangupCall(session);
    }
  };

  useEffect(() => {
    if (ringingRessions.length === 0) {
      WebRTCNotifications.stopRinger(); //STOP RING IF NO SESSIONS
      return null;
    } else {
      WebRTCNotifications.startRinger(); // START RING SOUND IF NO ACTIVE CALL
    }
  }, [ringingRessions.length]);

  if (!ringingRessions.length) return null;

  return (
    <Rnd
      style={{ zIndex: 101 }}
      default={{
        x: window.innerWidth - 500,
        y: 50,
        height: '',
        width: '300px'
      }}
      enableResizing={false}
      bounds={'window'}
    >
      <Card>
        <Stack
          px={2}
          direction="row"
          justifyContent={'flex-start'}
          alignItems="center"
          sx={{
            backgroundColor: '#F6F8FB',
            fontSize: '8px',
            fontWeight: 'bold'
          }}
        >
          <h2>Incomming Calls</h2>
        </Stack>
        <Stack
          sx={{
            padding: '5px'
          }}
        >
          {ringingRessions.map((session, idx) => {
            return (
              <Item
                key={idx}
                myKey={idx}
                hangupCall={hangupCall}
                answerCall={answerCall}
                session={session}
              />
            );
          })}
        </Stack>
      </Card>
    </Rnd>
  );
};
