import { UserRole } from 'src/models/permissionGroups';
import {
  getGroupByIdApi,
  getGroupsApi,
  GroupsFilters
} from '../infrastructure/getGroupsApi';

export const getUserRoles = async (filters: GroupsFilters) => {
  const userRoles = await getGroupsApi(filters);

  return userRoles;
};

export const getUserRoleById = async (roleId: string): Promise<UserRole> => {
  const userRole = await getGroupByIdApi(roleId);

  return userRole;
};
