import { Checkbox } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { SELECT_COLUMN_ID } from '../models/columns';

export const getSelectColumn = <T extends Object>() => {
  const selectColumn: ColumnDef<T> = {
    id: SELECT_COLUMN_ID,
    header: ({ table }) => (
      <Checkbox
        size="small"
        color="secondary"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        size="small"
        color="secondary"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    enableSorting: false,
    size: 32
  };
  return selectColumn;
};
