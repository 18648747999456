import axios from 'axios';
import { ContactGroup } from '../../models/contacts';
import { CONTACT_GROUP_ID } from 'src/utils/apiRoutes/msContacts';
import { replaceURLParams } from 'src/services/utils';

export const updateContactGroupApi = async (contactGroup: ContactGroup) => {
  const url = replaceURLParams(CONTACT_GROUP_ID, { '{id}': contactGroup.id });
  delete contactGroup.id;
  return axios.put(url, contactGroup);
};
