import {
  DialTableFilter,
  FieldDataType,
  Operator,
  booleanOperators,
  dateOperators,
  doubleOperators,
  integerOperators,
  objectIdOperators,
  stringOperators
} from '../models/filters';

export const SPLITTER = '/';

export const getFiltersFromUrlSearchParams = <T>(
  urlSearchParams: URLSearchParams,
  availableFilters: DialTableFilter<T>[]
) => {
  if (!urlSearchParams || !availableFilters) return [];
  const urlSearchParamsObject = Object.fromEntries(urlSearchParams);
  const filterFieldNameList = Object.keys(urlSearchParamsObject);
  const urlFilters =
    availableFilters?.reduce(
      (urlFilterArray: DialTableFilter<T>[], urlFilter: DialTableFilter<T>) => {
        if (!filterFieldNameList.includes(urlFilter.fieldName as string))
          return urlFilterArray;

        const operator = getFilterOperatorFromUrlOperator(
          urlSearchParams,
          urlFilter
        );

        const values = getDialTableFilterValuesFromUrlSearchParams(
          urlSearchParams,
          urlFilter?.fieldName as string
        );

        const newFilter: DialTableFilter<T> = {
          ...urlFilter,
          operator,
          values
        };
        return [...urlFilterArray, newFilter];
      },
      []
    ) ?? [];
  return urlFilters;
};

/**
 * Gets the filter operator from the URL search params. Checks if the operator is valid.
 * If the operator is valid, returns it. Otherwise, returns a default operator.
 * @param urlSearchParams
 * @param filter
 * @returns operator
 */
export const getFilterOperatorFromUrlOperator = <T>(
  urlSearchParams: URLSearchParams,
  filter: DialTableFilter<T>
) => {
  const urlSearchParamsObject = Object.fromEntries(urlSearchParams);
  const valueParamsArray = decodeURIComponent(
    urlSearchParamsObject?.[filter.fieldName as string]
  )?.split(SPLITTER);
  const urlOperator = valueParamsArray?.[0] as Operator;
  const isValidOperator = checkIsValidOperator(filter, urlOperator);
  if (isValidOperator) return urlOperator;
  const defaultOperator = getFilterOperators(filter)?.[0];
  return defaultOperator;
};

/**
 * Gets the filter values from the URL search params.
 * @param urlSearchParams
 * @param filterFieldName
 * @returns values
 */
export const getDialTableFilterValuesFromUrlSearchParams = <T>(
  urlSearchParams: URLSearchParams,
  filterFieldName: string
) => {
  const urlSearchParamsObject = Object.fromEntries(urlSearchParams);
  const valueParamsArray =
    decodeURIComponent(urlSearchParamsObject?.[filterFieldName])?.split(
      SPLITTER
    ) ?? [];
  const urlValues = valueParamsArray.slice(1);
  return urlValues;
};

export const checkIsValidOperator = <T>(
  filter: DialTableFilter<T>,
  urlOperator: Operator
) => {
  const availableOperators = getFilterOperators(filter);
  const isValidOperator = availableOperators.includes(urlOperator);
  return isValidOperator;
};

/**
 * Get the available operators of the filter
 * @param filter
 */
export const getFilterOperators = <T>(
  filter: DialTableFilter<T>
): Operator[] => {
  switch (filter.fieldDataType) {
    case FieldDataType.INTEGER:
      return integerOperators;
    case FieldDataType.DOUBLE:
      return doubleOperators;
    case FieldDataType.DATE:
      return dateOperators;
    case FieldDataType.BOOLEAN:
      return booleanOperators;
    case FieldDataType.STRING:
      return stringOperators;
    case FieldDataType.OBJECTID:
      return objectIdOperators;
    default:
      return [];
  }
};
