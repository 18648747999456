import {
  ContactField,
  Contact,
  ContactsRequestParams,
  ContactUI
} from 'src/modules/Contacts/models/contacts';
import { PaginatedElements } from 'src/models/pagination';
import { ContactsDomain } from '../../domain/ContactsDomain';
import { getContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { getContactTagByIdQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import { getContactGroupByIdQuery } from '../../domain/getContactGroups';
import { getContactList } from '../../domain/getContacts';
import { getContactFieldsObject } from '../../domain/getContactFields';

export const getContactUI = async (params: {
  contact: Contact;
  companyContactFields: ContactField[];
  withContactTags?: boolean;
  withContactGroupName?: boolean;
}): Promise<ContactUI> => {
  const {
    contact,
    companyContactFields,
    withContactTags = true,
    withContactGroupName = true
  } = params;
  const {
    name: nameFields,
    phone: phoneFields,
    email: emailFields,
    telegram: telegramFields,
    instagram: instagramFields
  } = getContactFieldsObject(contact, companyContactFields);
  let displayedTags = undefined;
  let contactGroup = undefined;
  if (withContactTags) {
    const tagPromises = contact.tags.map((tagId) =>
      getContactTagByIdQuery(tagId)
    );
    const tags = await Promise.all(tagPromises);
    const tagNames = tags.map((tag) => tag?.name ?? '');
    displayedTags = tagNames.join(', ');
  }
  if (withContactGroupName) {
    contactGroup = await getContactGroupByIdQuery(contact?.contactGroupId);
  }

  const contactUi: ContactUI = {
    ...contact,
    contactGroupName: contactGroup?.name,
    name: getMainFields(nameFields),
    phone: getMainFields(phoneFields),
    email: getMainFields(emailFields),
    telegram: getMainFields(telegramFields),
    instagram: getMainFields(instagramFields),
    displayedTags
  };

  return contactUi;
};

const getMainFields = (fields: ContactField[]) => {
  if (!fields.length) {
    return null;
  }
  const mainField = fields.find((field) => field.mainField);
  if (mainField) {
    return mainField.value;
  } else {
    return fields[0].value;
  }
};

/**
 *
 * @param companyId overwrites the companyId from params
 * @param params
 */
export const getContactsUI = async (
  companyId: string,
  params?: ContactsRequestParams
): Promise<PaginatedElements<ContactUI>> => {
  const companyContactFields = (
    await getContactFieldsQuery({
      companyId
    })
  ).elements;
  const paginatedContacts = await ContactsDomain.getContacts({
    ...params,
    companyId: companyId
  });
  const contactsUIPromises = paginatedContacts.elements.map((contact) =>
    getContactUI({ contact, companyContactFields })
  );
  const contactsUI = await Promise.all(contactsUIPromises);
  return { ...paginatedContacts, elements: contactsUI };
};

export const getContactListUI = async (params?: {
  contactIds: string[];
  /** id of the contact fields company */
  companyId: string;
  withContactTags?: boolean;
  withContactGroupName?: boolean;
}) => {
  const {
    contactIds,
    companyId,
    withContactTags = true,
    withContactGroupName = true
  } = params;
  const contactsPromise = getContactList(contactIds);
  const companyContactFieldPromise = getContactFieldsQuery({
    companyId
  });
  const [contacts, companyContactFields] = await Promise.all([
    contactsPromise,
    companyContactFieldPromise
  ]);
  const contactsUIPromise = contacts?.map((contact) =>
    getContactUI({
      contact,
      companyContactFields: companyContactFields?.elements,
      withContactTags,
      withContactGroupName
    })
  );
  const contactsUI = await Promise.all(contactsUIPromise);
  return contactsUI;
};
