import axios from 'axios';
import { SendEmailMessageData } from 'src/modules/Dashboard/views/Inbox/EmailPanel/components/EmailEditor/hooks/useEmailEditor';
import {
  SEND_EMAIL_MESSAGE_FILE_NEW,
  SEND_EMAIL_MESSAGE_FILE_REPLY
} from 'src/utils/apiRoutes/msEmail';

// Headers and content type are needed in order to format correctly the email content and subject with special characters
const headers = {
  'Content-Type': 'multipart/form-data; charset=utf-8'
}

const blobContentType = {
  type: 'application/json; charset=utf-8',
}

export type APISendEmailFileByCampaignParams = {
  files: File[];
  inlineFiles: File[];
  message: SendEmailMessageData;
} & (NewMailApiParams | ReplyToMailApiParams);

export type NewMailApiParams = {
  campaignId: string;
  ticketId?: string;
};

export type ReplyToMailApiParams = {
  conversationId: string;
  accountId: string;
};

/** SEND NEW EMAIL
 *
 * @param params must include campaignId
 * @returns boolean
 */
export const sendNewEmailApi = async (
  params: APISendEmailFileByCampaignParams
) => {
  const formData = new FormData();

  params.files.forEach((file) => formData.append('file', file));
  params.inlineFiles.forEach((file) => formData.append('inlineFile', file));

  formData.append('campaignId', (params as NewMailApiParams).campaignId);
  formData.append('ticketId', (params as NewMailApiParams)?.ticketId);

  formData.append(
    'emailMessage',
    new Blob([JSON.stringify({ ...params.message, channel: 'Email' })], blobContentType)
  );

  const response = await axios.post(SEND_EMAIL_MESSAGE_FILE_NEW, formData, {
    headers: headers
  });

  return response.data;
};

/** REPLY TO AN EMAIL
 *
 * @param params must include accountId and conversationId
 * @returns boolean
 */
export const sendReplyEmailApi = async (
  params: APISendEmailFileByCampaignParams
) => {
  const formData = new FormData();

  params.files.forEach((file) => formData.append('file', file));
  params.inlineFiles.forEach((file) => formData.append('inlineFile', file));

  formData.append(
    'conversationId',
    (params as ReplyToMailApiParams).conversationId
  );
  formData.append('accountId', (params as ReplyToMailApiParams).accountId);

  formData.append(
    'emailMessage',
    new Blob([JSON.stringify({ ...params.message, channel: 'Email' })], blobContentType)
  );

  const response = await axios.post(SEND_EMAIL_MESSAGE_FILE_REPLY, formData, {
    headers: headers
  });

  return response.data;
};
