import { useState } from 'react';
import { Conversation } from 'src/models/conversations/conversations';
import TicketsPanel from 'src/modules/Dashboard/views/Inbox/HelperPanel/TicketsPanel';
import NotesPanel from 'src/modules/Dashboard/views/Inbox/HelperPanel/NotesPanel';
import { ConversationHistory } from '../ConversationHistory';
import ContactDataPanel from 'src/modules/Dashboard/views/Inbox/HelperPanel/ContactDataPanel';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { Box, CircularProgress, IconButton, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';

import CustomTabs from '../CustomTabs';

interface ConcatcDataProps {
  conversation: Conversation;
  onClose?: () => void;
}

enum TabsContact {
  ContactData = 'ContactData',
  ConversationHistory = 'ConversationHistory',
  Notes = 'Notes',
  Tickets = 'Tickets'
}

type TabsContactType =
  | 'ContactData'
  | 'ConversationHistory'
  | 'Notes'
  | 'Tickets';

const ContactDataPowerdialer: React.FC<ConcatcDataProps> = (props) => {
  const { conversation, onClose } = props;
  const [tab, setTab] = useState<TabsContact>(TabsContact.ContactData);
  const companyFieldsQuery = useGetContactFields(
    { companyId: conversation?.companyId },
    !!conversation
  );

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {companyFieldsQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              flexShrink: 0,
              borderBottom: '1px solid #e2e9fa',
              paddingBottom: 1
            }}
          >
            <CustomTabs<TabsContactType>
              tabs={[
                { label: 'Contact Data', value: 'ContactData' },
                { label: 'Conversation History', value: 'ConversationHistory' },
                { label: 'Notes', value: 'Notes' },
                { label: 'Tickets', value: 'Tickets' }
              ]}
              currentTab={tab}
              setCurrentTab={setTab}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Grid container spacing={2} sx={{ height: '100%' }}>
              {tab === TabsContact.ContactData && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      border: '1px solid #e2e9fa',
                      p: 2,
                      overflow: 'auto'
                    }}
                  >
                    <ContactDataPanel
                      key={conversation.id}
                      conversation={conversation}
                    />
                  </Box>
                </Grid>
              )}
              {tab === TabsContact.ConversationHistory && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      border: '1px solid #e2e9fa',
                      p: 2,
                      overflow: 'auto'
                    }}
                  >
                    <ConversationHistory
                      key={conversation.id}
                      conversation={conversation}
                    />
                  </Box>
                </Grid>
              )}
              {tab === TabsContact.Notes && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      border: '1px solid #e2e9fa',
                      p: 2,
                      overflow: 'auto'
                    }}
                  >
                    <NotesPanel
                      key={conversation.id}
                      conversation={conversation}
                      showConversationNotes={false}
                    />
                  </Box>
                </Grid>
              )}
              {tab === TabsContact.Tickets && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      border: '1px solid #e2e9fa',
                      p: 2,
                      overflow: 'auto'
                    }}
                  >
                    <TicketsPanel
                      key={conversation.id}
                      conversation={conversation}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ContactDataPowerdialer;
