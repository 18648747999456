import { useTheme } from '@mui/material';

export const getStatusStyles = (state) => {
  const theme = useTheme();
  switch (state) {
    case 'draft':
      return {
        textColor: '#F49200',
        backColor: '#FFF6E8'
      };
    case 'Active':
      return {
        textColor: theme.colors.secondary.dark,
        backColor: theme.colors.secondary.lighter
      };
    case 'Inactive':
      return {
        textColor: '#F4002C',
        backColor: '#FFE8EC'
      };
    case 'Paused':
      return {
        textColor: theme.colors.error.dark,
        backColor: theme.colors.error.lighter
      };
    case 'OutOfTime':
        return {
          textColor: theme.colors.warning.dark,
          backColor: theme.colors.warning.lighter
        };
    case 'Unstarted':
      return {
        textColor: theme.colors.alpha.black[70],
        backColor: theme.colors.alpha.black[10],
      };
    case 'Finished':
      return {
        textColor: theme.colors.success.dark,
        backColor: theme.colors.success.lighter
      };
      case 'Assigned':
        return {
          textColor: theme.colors.success.main,
          backColor: theme.colors.success.lighter
        };
      case 'Closed':
        return {
          textColor: theme.colors.gradients.black1,
          backColor: theme.colors.alpha.black[10]
        };
      case true:
        return {
          textColor: '#3BB900',
          backColor: '#ECFBE6'
        };
      case false:
        return {
          textColor: '#F4002C',
          backColor: '#FFE8EC'
        };
    default:
      return {
        textColor: 'black',
        backColor: 'white'
      };
  }
};
