import axios from 'axios';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { UserRole, UserPermissions } from 'src/models/permissionGroups';
import { PermissionsStructure } from 'src/models/permissionsStructure';
import { replaceURLParams } from 'src/services/utils';
import {
  GROUPS_BY_ID,
  GROUPS_MS,
  GROUPS_PERMISSIONS_USER_ID,
  GROUPS_STRUCTURE
} from 'src/utils/apiRoutes/msApiGateway';

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
const BASE_MS = process.env.REACT_APP_BASE_API_MS as string;

const API_URL_GROUP_LIST = GROUPS_MS;
const API_URL_GROUP_ID = GROUPS_BY_ID;
const API_URL_PERMISSIONS_USER_ID = GROUPS_PERMISSIONS_USER_ID;
const API_URL_GROUP_STRUCTURE = GROUPS_STRUCTURE;

export interface GroupsFilters {
  companyId?: string;
  groupType?: 'Role' | 'User';
  userId?: string;
  sortBy?: keyof UserRole;
  sortDir?: DirectionType;
  roleName?: string;
}

export const getGroupsApi = async (
  params: GroupsFilters
): Promise<UserRole[]> => {
  const path = BASE_URL + BASE_MS + API_URL_GROUP_LIST;

  const resp = await axios.get(path, { params });

  return resp.data;
};

export const getGroupByIdApi = async (id: string): Promise<UserRole> => {
  const GROUPS = BASE_URL + BASE_MS + API_URL_GROUP_ID;
  const path = replaceURLParams(GROUPS, { '{id}': id });

  const resp = await axios.get(path);

  return resp.data;
};

export const getPermissionsByUserIdApi = async (
  userId: string
): Promise<UserPermissions> => {
  const PERMISSIONS = BASE_URL + BASE_MS + API_URL_PERMISSIONS_USER_ID;
  const path = replaceURLParams(PERMISSIONS, { '{userId}': userId });

  const resp = await axios.get(path);

  return resp.data;
};

export const getPermissionsStructureApi =
  async (): Promise<PermissionsStructure> => {
    const path = BASE_URL + BASE_MS + API_URL_GROUP_STRUCTURE;
    const resp = await axios.get(path);
    return resp.data;
  };
