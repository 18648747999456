import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/modules/Shared/utils/apiFunctions';
import { PANELS_ALERT_ID, PANELS_ID } from 'src/utils/apiRoutes/msFlow';

export const deletePanelById = async (panelId: string): Promise<string> => {
  const path = replaceURLParams(PANELS_ID, {
    '{id}': panelId
  });

  const response: AxiosResponse<string> = await axios.delete(path);

  return response.data;
};

export const deleteAlertByPanleId = async (
  panelId: string
): Promise<string> => {
  const path = replaceURLParams(PANELS_ALERT_ID, {
    '{panelId}': panelId
  });

  const response: AxiosResponse<string> = await axios.delete(path);

  return response.data;
};
