import { Divider, Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import StyleModifiers from './StyleModifiers';
import { WebChatStyle } from '../../../../models/webChatStyle';
import { FormikProps } from 'formik';
import ContactInformationPreview from '../../../components/ContactInformationPreview';

interface ChatContactTabProps {
  formikProps: FormikProps<WebChatStyle>;
  logoImage: File;
  setLogoImage: Dispatch<SetStateAction<File>>;
  setUpdatedLogo: Dispatch<SetStateAction<boolean>>;
}

const ContactTab: React.FC<ChatContactTabProps> = ({
  formikProps,
  logoImage,
  setLogoImage,
  setUpdatedLogo
}) => {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      height="calc(100vh - 375px)"
      overflow={{ xs: 'visible', md: 'scroll' }}
    >
      <Stack
        direction="column"
        alignItems={'center'}
        px={2}
        py={1.5}
        spacing={1}
        overflow={'scroll'}
        width="100%"
        minHeight={{ xs: '650px', md: 'auto' }}
        maxWidth={{ xs: 'auto', md: '420px' }}
      >
        <ContactInformationPreview
          formikProps={formikProps.values}
          logo={logoImage}
        />
      </Stack>
      <Divider orientation="vertical"></Divider>
      <Stack width="100%">
        <StyleModifiers
          formikProps={formikProps}
          setLogoImage={setLogoImage}
          setUpdatedLogo={setUpdatedLogo}
        />
      </Stack>
    </Stack>
  );
};

export default ContactTab;
