import { useTheme, Box, Stack, Tabs, Tab, styled } from '@mui/material';
import { MultipleStop } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useContext, useState } from 'react';

import { TransferPad } from './TransferPad';
import { TransferUsers } from './TransferUsers';
import { CtiPopover } from '../CtiPopover';
import { CTIBarContext, CTIPopoverNames } from 'src/contexts/CTIBarContext';
import { AttendedTransferModal } from './AttendedTransfer';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { t } from 'i18next';
interface TransferMenuProps {}

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
      .MuiTabs-indicator {
        min-height: 4px;
        height: 4px;
        box-shadow: none;
        border: 0;
      }
  
      .MuiTab-root {
        &.MuiButtonBase-root {
          padding: 0;
          margin-right: ${theme.spacing(3)};
          font-size: ${theme.typography.pxToRem(16)};
          color: ${theme.colors.alpha.black[50]};
  
          .MuiTouchRipple-root {
            display: none;
          }
        }
  
        &.Mui-selected:hover,
        &.Mui-selected {
          color: ${theme.colors.alpha.black[100]};
        }
      }
    `
);

export const TransferModal: React.FC<TransferMenuProps> = ({}) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('users');
  const { barState, callToTransferID } = useContext(CTIBarContext);
  const { isBarCompressed } = barState;
  const activeCall = getActiveRTCall(getConnectedRTUser(true));

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  const transferButton = isBarCompressed ? (
    <Box
      sx={{
        height: 23,
        width: 23,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#E8E2D9'
        }
      }}
    ></Box>
  ) : (
    <IconButton
      id="transfer-button"
      sx={{
        borderRadius: 0,
        color: theme.colors.warning.main
      }}
    >
      <MultipleStop fontSize="medium" />
    </IconButton>
  );

  if (!activeCall || activeCall.status === 'Conference') {
    return transferButton;
  }

  return (
    <CtiPopover icon={transferButton} title={CTIPopoverNames.TRANSFER}>
      {callToTransferID ? (
        <AttendedTransferModal />
      ) : (
        <Box minWidth={'456px'} overflow="hidden">
          <Stack
            p={1}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <TabsContainerWrapper>
              <Tabs
                value={activeTab}
                onChange={handleTabs}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="secondary"
              >
                <Tab label={t('Agents')} value={'users'} />
                <Tab label={t('External')} value={'dialpad'} />
              </Tabs>
            </TabsContainerWrapper>
            {activeTab === 'users' && <TransferUsers />}
            {activeTab === 'dialpad' && <TransferPad />}
          </Stack>
        </Box>
      )}
    </CtiPopover>
  );
};
