import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Close, Link } from '@mui/icons-material';
import {
  Button,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { t } from 'i18next';
import { $insertNodes } from 'lexical';
import { useEffect, useRef, useState } from 'react';
import { $createLinkNode } from 'src/components/LexicalNodes/LinkNode/LinkNode';

export function InsertLinkButton() {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const [editor] = useLexicalComposerContext();
  const [payload, setPayload] = useState<{ url: string; label: string }>({
    url: '',
    label: ''
  });
  const labelRef = useRef<HTMLInputElement>(null);

  function insertLink() {
    editor.update(() => {
      const node = $createLinkNode({
        url: payload.url,
        label: payload.label
      });
      $insertNodes([node]);
    });

    setOpen(false);
  }

  useEffect(() => {
    if (!open) {
      setPayload({ url: '', label: '' });
    }
  }, [open]);

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter' && labelRef.current?.focus) {
      insertLink();
    }
  }

  return (
    <>
      <Tooltip title={t('Insert Link')} arrow placement="top">
        <IconButton ref={ref} onClick={() => setOpen(true)} size="small">
          <Link />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <CardHeader
          title={t('Insert Link')}
          subheader={t('Label is optional')}
          action={
            <IconButton onClick={() => setOpen(false)} size="small">
              <Close fontSize="small" />
            </IconButton>
          }
        />
        <Divider />
        <Stack p={1} gap={1}>
          <TextField
            label="URL"
            value={payload.url}
            onChange={(e) => setPayload({ ...payload, url: e.target.value })}
            size="small"
            required
          />
          <TextField
            label={t('Label')}
            inputRef={labelRef}
            value={payload.label}
            onChange={(e) => setPayload({ ...payload, label: e.target.value })}
            onKeyDown={handleKeyDown}
            size="small"
          />
          <Button
            onClick={insertLink}
            variant="contained"
            color="secondary"
            size="small"
          >
            {t('Insert')}
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
