import { useEffect } from 'react';

import { Box, IconButton, Slider, Stack } from '@mui/material';
import { setAudioVolume } from 'src/store/slices/users/configVolumeSlice';
import { useDispatch, useSelector } from 'src/store/store';
import { useMemoValue } from './useMemoValue';
import {
  VolumeOffRounded,
  VolumeOffTwoTone,
  VolumeUpRounded,
  VolumeUpTwoTone
} from '@mui/icons-material';

export const AudioSlider: React.FC = () => {
  const volumeLocalStgKey = 'audioVolume';

  const { value, onSetInputValue, onResetMemoValue, onSetMemoValue, isReset } =
    useMemoValue(volumeLocalStgKey);

  const { audioVolume } = useSelector((state) => state.configVolume);
  const dispatch = useDispatch();

  const audio = document.getElementById(
    'webrtc-audio-remote'
  ) as HTMLMediaElement & { setSinkId(deviceId: string): void };
  // change also the test speakers audio
  const callTest = document.getElementById(
    'call-test'
  ) as HTMLMediaElement

  // audio.volume values between 0 and 1.
  useEffect(() => {
    if (localStorage.getItem(volumeLocalStgKey)) return;
    // because useMemoValue is in charge of getting the item from Local Storage
    audio.volume = audioVolume / 100;
    callTest.volume = audioVolume / 100
    onSetInputValue(audioVolume);
  }, []);
  // audio.volume values between 0 and 1.
  useEffect(() => {
    audio.volume = value / 100;
    callTest.volume = value / 100
    dispatch(setAudioVolume(value));
    localStorage.setItem(volumeLocalStgKey, JSON.stringify(value));
  }, [value]);

  return (
    <Box>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems="center"
        spacing={2}
      >
        <Slider
          id="audio-slider"
          size="medium"
          sx={{
            width: '192px'
          }}
          color="secondary"
          orientation="horizontal"
          value={value}
          onChange={(e, val) => {
            onSetInputValue(val);
          }}
        />

        <IconButton
          id="audio-slider-button"
          onClick={isReset ? onSetMemoValue : onResetMemoValue}
        >
          {isReset ? (
            <VolumeOffTwoTone color="secondary" />
          ) : (
            <VolumeUpTwoTone color="secondary" />
          )}
        </IconButton>
      </Stack>
    </Box>
  );
};
