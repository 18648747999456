import { setVoiceVolume } from 'src/store/slices/users/configVolumeSlice';
import store from 'src/store/store';

/** SET VOICE VOLUME VALUE IN STORE
 *
 * @param volume New volume value
 */
export const setMicVolumeStore = (volume: number) => {
  store.dispatch(setVoiceVolume(volume));
};
