import { FieldDataType } from './availableFilters';
import { DirectionType } from './direction';

export const IntegerOperator = {
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS',
  GREATHER_THAN: 'GREATER_THAN',
  GREATHER_THAN_OR_EQUALS: 'GREATER_THAN_OR_EQUALS',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUALS: 'LESS_THAN_OR_EQUALS'
} as const;

export type IntegerOperatorType =
  (typeof IntegerOperator)[keyof typeof IntegerOperator];

export const DoubleOperator = {
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS',
  GREATHER_THAN: 'GREATER_THAN',
  GREATHER_THAN_OR_EQUALS: 'GREATER_THAN_OR_EQUALS',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUALS: 'LESS_THAN_OR_EQUALS'
} as const;

export type DoubleOperatorType =
  (typeof DoubleOperator)[keyof typeof DoubleOperator];

export const DateOperator = {
  BETWEEN: 'BETWEEN',
  NOT_NULL: 'NOT_NULL',
  GREATHER_THAN: 'GREATER_THAN',
  GREATHER_THAN_OR_EQUALS: 'GREATER_THAN_OR_EQUALS',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUALS: 'LESS_THAN_OR_EQUALS'
} as const;

export type DateOperatorType = (typeof DateOperator)[keyof typeof DateOperator];

export const BooleanOperator = {
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS'
} as const;

export type BooleanOperatorType =
  (typeof BooleanOperator)[keyof typeof BooleanOperator];

export const StringOperator = {
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS',
  CONTAINS: 'CONTAINS'
} as const;

export type StringOperatorType =
  (typeof StringOperator)[keyof typeof StringOperator];

export interface Filter {
  dataType?: FieldDataType;
  field: string;
  operator:
    | IntegerOperatorType
    | DoubleOperatorType
    | DateOperatorType
    | BooleanOperatorType
    | StringOperatorType;
  values: string[];
  type?: string;
  /** Prop added to label the filter */
  label?: string;
}

export interface Sort<T> {
  direction: DirectionType;
  sort: keyof T;
}

export default interface FiltersRequest<T, K extends keyof T> {
  direction: 'ASC' | 'DESC';
  filters: Filter[];
  pageNumber: number;
  pageSize: number;
  select: K[];
  sort: K;
}
