import axios from 'axios';
import {
  EXECUTION_KILL,
  EXECUTION_PROGRESS,
  EXECUTION_START,
  EXECUTION_STOP
} from 'src/utils/apiRoutes/msCallSchedule';

export const startDialerBot: Function = (campaignId: string) => {
  const params = { campaignId };
  return axios
    .post(EXECUTION_START, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const stopDialerBot: Function = (campaignId: string) => {
  const params = { campaignId };
  return axios
    .post(EXECUTION_STOP, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const killDialerBot: Function = (campaignId: string) => {
  const params = { campaignId };
  return axios
    .delete(EXECUTION_KILL, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getProgress: Function = (campaignId: string): Promise<number> => {
  const params = { campaignId };
  return axios
    .get(EXECUTION_PROGRESS, { params })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};
