import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  DashboardData,
  DashboardInstantData,
  PanelData
} from 'src/modules/Productivity/modules/Dashboards/models/Dashboards';

type DashboardSliceData = {
  dashboards: DashboardData;
  updateInterval: number;
  totalPeriod: number;
};

const initialState: DashboardSliceData = {
  dashboards: {},
  // In seconds
  updateInterval: 1,
  totalPeriod: 60
};

interface PanelInitialData {
  panelId: string;
  data: PanelData[];
}

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setDashboardInitialData: (state, action: PayloadAction<DashboardData>) => {
      state.dashboards = action.payload;
    },
    setUpdateInterval: (state, action: PayloadAction<number>) => {
      state.updateInterval = action.payload;
    },
    setTotalPeriod: (state, action: PayloadAction<number>) => {
      state.totalPeriod = action.payload;
      //state.dashboards = {};
    },
    addDashboardData: (state, action: PayloadAction<DashboardInstantData>) => {
      const maxDataPoints = Math.ceil(state.totalPeriod / state.updateInterval);
      const newDashboards = { ...state.dashboards };
      Object.entries(action.payload).forEach(([panelId, newPanelData]) => {
        if (!newDashboards[panelId]) {
          newDashboards[panelId] = [];
        }
        newDashboards[panelId] = [...newDashboards[panelId], newPanelData];
        if (newDashboards[panelId].length > maxDataPoints + 1) {
          newDashboards[panelId].shift();
        }
      });
      state.dashboards = newDashboards;
    },
    setPanelInitialData: (state, action: PayloadAction<PanelInitialData>) => {
      const newDashboards = { ...state.dashboards };
      const panelId = action.payload.panelId;
      const newData = action.payload.data;

      newDashboards[panelId] = newData;

      state.dashboards = newDashboards;
    }
  }
});

export const {
  addDashboardData,
  setDashboardInitialData,
  setPanelInitialData,
  setUpdateInterval,
  setTotalPeriod
} = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
