import { ContactFieldType } from 'src/modules/Contacts/models/contacts';

// create enum for videocall room status
export enum VideoCallRoomStatus {
  Waiting = 'Waiting',
  Active = 'Active',
  Ended = 'Ended'
}

export enum VideoRoomStatus {
  PENDING = 'Pending',
  WAITING = 'WaitingContact',
  ONGOING = 'Ongoing',
  FINISHED = 'Finished'
}

export interface VideoCallRoom {
  id: string;
  status: VideoRoomStatus;
  conversationId: string;
  ownerUserId: string;
  callParticipants: VideoCallParticipant[];
  companyId: string;
  campaignId: string;
  callId: string;

  // String que contiene la fecha en la que se inicia la llamada
  startCall: string;
}

export enum ParticipantStatus {
  TALKING = 'Talking',
  WAITING = 'Waiting',
  EXITED = 'Exited',
  DENIED = 'Denied'
}

export enum ParticipantType {
  AgentParticipant = 'AgentParticipant',
  ContactParticipant = 'ContactParticipant'
}

interface ParticipantFormField {
  contactFieldId: string;
  value: string;
  contactFieldType: ContactFieldType;
}

export interface VideoCallParticipant {
  connectionId: string;
  id: string;
  displayName: string;
  extension: { username: string, password: string };
  formFieldValues: ParticipantFormField[];
  lastJoinDate: Date;
  rtCallId: string;
  status: ParticipantStatus;
  type: ParticipantType;
  muted: boolean;
  deafen: boolean;
}
