import {
  EventTypes,
  TicketEventSubtypes,
  WSMessage
} from 'src/services/websocket/utils/notifications/dataMsgs';
import {
  ChannelType,
  Conversation,
  ConversationStatus,
  SocialmediaOriginAgent
} from '../../../../../../../models/conversations/conversations';

export enum TicketType {
  NEW = 'New',
  TOUCHED = 'Touched',
  CLOSED_NEGATIVE = 'ClosedNegative',
  CLOSED_POSITIVE = 'ClosedPositive',
  CLOSED_NOT_USEFUL = 'ClosedNotUseful'
}

export const ticketTypeOptions = Object.values(TicketType);

export interface TicketUI extends Ticket {
  creationDate: string;
  modificationDate: string;
  idDetails: string;
  resolutionName: string;
}

export interface Ticket {
  /** present when retrieving the ticket by id */
  assignedUserIds?: TicketAgentInfo;
  attachments?: TicketAttachment[];
  changeHistory?: TicketChangeHistory[];
  comments?: TicketComment[];
  companyId: string;
  conversationIds: string[];
  conversationSummaries?: TicketConversationSummary[];
  description: string;
  fields: TicketFieldsValues;
  friendlyId?: number;
  id?: string;
  _id?: string;
  involvedContacts?: TicketContact[];
  involvedConversations?: number;
  metadata?: TicketMetadata;
  serviceId: string;
  /** subResolution type equals to string when comes from the reporting microservice */
  subResolution?: TicketSubResolution;
  subResolutionId?: string;
  tagIds: string[];
  /** Required to add or edit the ticket users, when adding or editing the ticket*/
  ticketUserIds?: string[];
  title: string;
}

export enum TicketCSVFieldKeys {
  FRIENDLYID = 'friendlyId',
  SUBRESOLUTION = 'subresolution',
  RESOLUTION = 'resolution',
  TICKETUSERS = 'ticketUsers',
  TAGS = 'tags',
  TITLE = 'title',
  DESCRIPTION = 'description'
}

export const ticketCsvFields: TicketCSVFieldKeys[] =
  Object.values(TicketCSVFieldKeys);

export const requiredTicketCsvFields: TicketCSVFieldKeys[] = [
  TicketCSVFieldKeys.FRIENDLYID,
  TicketCSVFieldKeys.SUBRESOLUTION,
  TicketCSVFieldKeys.RESOLUTION
];

export interface TicketFieldsValues {
  [id: string]: TicketFieldValue;
}

export interface TicketFieldValue {
  type: TicketFieldType;
  value: string | boolean | string[];
}

export interface TicketAgentInfo {
  [id: string]: {
    count?: number;
    fromTicket?: boolean;
  };
}

export interface TicketAttachment {
  id: string;
  key: string;
  name: string;
  sha256: string;
  sizeKb: number;
}

export interface TicketAttachmentFile {
  fileId?: string;
  ticketId: string;
  file: File;
}

export interface TicketChangeHistory {
  action: TicketChangeHistoryAction;
  currentValue: string;
  element: TicketChangeHistoryElement;
  elementId: string;
  previousValue: string;
  timestamp: string;
  userId: string;
}

export enum TicketChangeHistoryAction {
  ADD = 'Add',
  REMOVE = 'Remove',
  UPDATE = 'Update'
}

export enum TicketChangeHistoryElement {
  AGENT = 'Agent',
  ATTACHMENT = 'Attachment',
  ATTRIBUTE = 'Attribute',
  COMMENT = 'Comment',
  CONTACT = 'Contact',
  CONVERSATION = 'Conversation',
  FIELD = 'Field',
  TAG = 'Tag'
}

export interface TicketComment {
  comment: string;
  id?: string;
  metadata?: TicketMetadata;
  userId?: string;
}

export interface TicketConversationSummary {
  channel: ChannelType;
  creationDate: string;
  id: string;
  status: ConversationStatus;
}

export interface TicketContact {
  contactId: string;
  socialmediaAgent: SocialmediaOriginAgent;
}

export interface TicketMetadata {
  creationDate: string;
  modificationDate: string;
}

export interface TicketField {
  companyId: string;
  editableBy: string[];
  id?: string;
  multipleChoice: boolean;
  name: string;
  operator: TicketFieldOperator;
  options?: string[];
  required: boolean;
  serviceIds: string[];
  type: TicketFieldType;
  valueDependencies: TicketFieldValueDependency[];
  visibilityDependencies: TicketFieldVisibilityDependency[];
  visibleTo: string[];
}

export enum TicketFieldOperator {
  AND = 'And',
  OR = 'Or'
}

export enum TicketFieldType {
  BOOLEAN = 'Boolean',
  DATETIME = 'DateTime',
  OPTIONS = 'Options',
  TEXT = 'Text'
}

export const ticketFieldTypes: TicketFieldType[] =
  Object.values(TicketFieldType);

export const ticketFieldOperators: TicketFieldOperator[] =
  Object.values(TicketFieldOperator);

export interface TicketSubResolution {
  companyId: string;
  id?: string;
  name: string;
  resolution?: TicketResolution;
  resolutionId?: string;
  serviceIds: string[];
  type: TicketType;
}

export interface TicketSubResolutionUI extends TicketSubResolution {
  resolutionName: string;
}

export interface TicketSubResolutionsRequestParams {
  companyId: string;
  resolutionId?: string;
  serviceId?: string;
}

export interface TicketResolution {
  companyId: string;
  id?: string;
  name: string;
}

export interface TicketResolutionUI extends TicketResolution {
  subResolutionNames: string;
  subResolutions: TicketSubResolution[];
}

export interface TicketTag {
  companyId: string;
  hexColor: string;
  id: string;
  name: string;
  serviceIds: string[];
}

export interface TicketFieldValueDependency {
  fieldId: string;
  valueMatchings: TicketFieldValueMatching[];
}

export interface TicketFieldVisibilityDependency {
  booleanOperator: TicketFieldOperator;
  fieldId: string;
  ignoreCase: boolean;
  operation: TicketFieldOperation;
  values: string[];
}

export interface TicketFieldValueMatching {
  allowedValues: string[];
  previousFieldValue: string[];
}

export enum TicketFieldOperation {
  CONTAINS = 'Contains',
  ENDS_WITH = 'EndsWith',
  EQUALS = 'Equals',
  GREATER_THAN = 'GreaterThan',
  LESS_THAN = 'LessThan',
  NOT_CONTAINS = 'NotContains',
  NOT_ENDS_WITH = 'NotEndsWith',
  NOT_EQUALS = 'NotEquals',
  NOT_NULL = 'NotNull',
  NOT_STARTS_WITH = 'NotStartsWith',
  NULL = 'Null',
  STARTS_WITH = 'StartsWith'
}

export interface ReportingTicket {
  id: string;
  'ticket.friendlyId'?: string;
  companyId?: string;
  'companyId.name'?: string;
  'companyId.extensions'?: string;
  'companyId.enabled'?: string;
  serviceId?: string;
  'serviceId.name': string;
  'serviceId.creationDate': string;
  'serviceId.modificationDate': string;
  'serviceId.enabled': string;
  subResolution: string;
  'subResolution.$id': string;
  'subResolution.name': string;
  'subResolution.serviceIds': string;
  'subResolution.type': string;
  'subResolution.resolution': string;
  title: string;
  'ticket.metadata.creationDate': string;
  'ticket.metadata.modificationDate': string;
  metadata: string;
  'fields.$value$.value': string;
  'fields.$value$': string;
  fields: string;
  tagIds: string;
  'tagIds.name': string;
  'assignedUserIds.$value$.count': string;
  'assignedUserIds.$value$.fromTicket': string;
  'assignedUserIds.name': string;
  'participants.name': string;
  'involvedContacts.count': string;
  'involvedContacts.socialmediaAgent.emailAddress': string;
  'involvedContacts.socialmediaAgent.firstName': string;
  'involvedContacts.socialmediaAgent.lastName': string;
  'involvedContacts.socialmediaAgent.userName': string;
  'involvedContacts.socialmediaAgent.phoneNumber': string;
  'involvedContacts.socialmediaAgent.type': string;
  'involvedContacts.socialmediaAgent.chatId': string;
  'involvedContacts.socialmediaAgent.channel': string;
  'involvedContacts.socialmediaAgent.webChatConvId': string;
  'involvedContacts.socialmediaAgent.webChatForm.name': string;
  'involvedContacts.socialmediaAgent.webChatForm.value': string;
  'involvedContacts.socialmediaAgent.originIp': string;
  'involvedContacts.socialmediaAgent.endpoint': string;
  'involvedContacts.socialmediaAgent.orientation': string;
  'involvedContacts.socialmediaAgent.callId': string;
  'involvedContacts.contactId': string;
  'involvedContacts.uniqueField': string;
  'unsavedContacts.uniqueField': string;
  'conversationSummaries._id': string;
  'conversationSummaries.ids': string;
  [ticketFieldName: string]: string;
}

export interface TicketMsg extends WSMessage {
  autogenerated: boolean;
  conversations: Partial<Conversation>[];
  friendlyId: string;
  subtype: TicketEventSubtypes;
  ticketId: string;
  type: EventTypes.TICKET;
}

export interface UploadTicketCsvParams {
  /** CSV ticket key - DA ticket key*/
  specialFieldMapping: { [csvField: string]: string };
  /** CSV ticket field key - DA ticket field key */
  fieldMapping: { [csvField: string]: string };
  file: File;
  companyId: string;
  createTagWhenNotFound: boolean;
  defaultResolution: string;
  defaultSubresolution: string;
  delimiter: string;
  listDelimiter: string;
  serviceId: string;
}

export const initialUploadTicketCSVParams: UploadTicketCsvParams = {
  specialFieldMapping: {},
  fieldMapping: {},
  file: null,
  companyId: '',
  createTagWhenNotFound: false,
  defaultResolution: '',
  defaultSubresolution: '',
  delimiter: ',',
  listDelimiter: ',',
  serviceId: ''
};
