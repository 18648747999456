import { RenewWSMessage } from 'src/models/auth';
import AuthManager from '../manager';

export const refreshResponse = (eventWS: RenewWSMessage) => {
  AuthManager.setToken(eventWS.token, eventWS.expiresInMs);
  AuthManager.setExpiresInMs(eventWS.expiresInMs);
  AuthManager.setExpiration(eventWS.expiration);
  const userId = AuthManager.getUserId();
  if (userId) AuthManager.setUserId(userId, eventWS.expiresInMs);
};
