import { VideoCallsApi } from '../infrastructure';
import { FormFieldData } from '../components/PhasePreroom/components/form/VideoCallForm';
import { VideoCallParticipant } from '../types/videoCallTypes';
import AuthManager from 'src/services/authentication/manager';

export const createVideoRoom = async (campaignId: string) => {
  const res = await VideoCallsApi.createVideoRoom(campaignId);

  return res;
};

export const getFormFieldsOfVideocallCampaign = async (campaignId: string) => {
  const res = await VideoCallsApi.getFormFieldsOfVideocallCampaign(campaignId);

  return res;
};

export const getVideoRoomById = async (roomId: string, isPublic: boolean) => {
  const res = isPublic ? await VideoCallsApi.getVideoRoomByIdPublic(roomId) : await VideoCallsApi.getVideoRoomById(roomId);

  return res;
};

/**
 * Accept participant.
 * @param roomId room
 * @param participantId user
 * @returns true or false if the request has been completed succesfully
 */
export const acceptParticipant = async (roomId: string, participantId: string) => {
  const res = await VideoCallsApi.acceptParticipant(roomId, participantId);

  return res;
};

/**
 * Reject participant.
 * @param roomId room
 * @param participantId user
 * @returns true or false if the request has been completed succesfully
 */
export const rejectParticipant = async (roomId: string, participantId: string) => {
  const res = await VideoCallsApi.rejectParticipant(roomId, participantId);

  return res;
};

export const readmitUser = async (roomId: string, participantId: string) => {
  const res = await VideoCallsApi.readmitParticipant(roomId, participantId);

  return res;
};

export const hangUp = async (participantId: string, roomId: string, isPublic: boolean, socketId?: string) => {
  const res = isPublic ? await VideoCallsApi.hangUpParticipantPublic(participantId, roomId, socketId!) : await VideoCallsApi.hangUpParticipant(participantId, roomId);

  return res;
};

export const finishRoom = async (roomId: string) => {
  const res = await VideoCallsApi.finishRoom(roomId);

  return res;
};

export const joinVideoRoom = async (
  roomId: string,
  connectionId: string,
  muted: boolean,
  deafen: boolean,
  formFieldValues?: FormFieldData[],
): Promise<VideoCallParticipant> => {
  const isPublic = !AuthManager.getToken();
  const newParticipant = isPublic
    ? await VideoCallsApi.joinVideoRoomPublic(roomId, connectionId, muted, deafen, formFieldValues)
    : await VideoCallsApi.joinVideoRoom(roomId, connectionId, muted, deafen);
  return newParticipant;
};


export const participantReady = async (
  extension: string,
  roomId: string,
) => {
  const result = await VideoCallsApi.participantReady(extension, roomId)
  return result
}


export const toggleMute = async (roomId: string, socketId: string) => {
  const res = await VideoCallsApi.toggleMute(roomId, socketId);

  return res;
};

export const toggleDeaf = async (roomId: string, socketId: string) => {
  const res = await VideoCallsApi.toggleDeaf(roomId, socketId);

  return res;
};

