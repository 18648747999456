import { LocalParking, PhoneForwarded } from '@mui/icons-material';
import { Box, Grow, Stack, useTheme } from '@mui/material';

import Timer from 'src/components/Timer/timer';

import { RTCall } from 'src/models/rtcall';
import { getRTUser } from 'src/services/rtusers/domain/rtuser/getRTUser';
import { useGetContactNameQuery } from 'src/services/socialmedia/application/query/getContactsQuery';

interface Props {
  call: RTCall;
}

export const ConferenceCallInfoCard: React.FC<Props> = ({ call }) => {
  const theme = useTheme();
  const isParked = call?.status === 'Parked';

  //Check if the call is an agent or an external call in torder to search a contact or a user name
  const isAgent = call?.currentEndpoint.endpoint.includes('-');

  const endpoint = getEndpoint(call);

  const rtUser = isAgent
    ? getRTUser({
        complexExtension: endpoint,
        shouldUseHook: false
      })
    : null;

  const contactNameQuery = useGetContactNameQuery(call.contactId, !isAgent);

  const displayedName = rtUser?.username ?? contactNameQuery?.data ?? endpoint;

  function getEndpoint(rtCall: RTCall) {
    if (!call) return null;

    return rtCall.currentEndpoint.endpoint;
  }

  if (!call) return <></>;

  return (
    <Grow in={call !== null} style={{ transformOrigin: 'left' }}>
      <Box width="100%">
        <Stack sx={{ position: 'relative' }}>
          <Box
            sx={{
              border: 'none',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '10px',
              backgroundColor: isParked ? '#EBF9FF' : '#EEFAE8', //Theme colors can't be used because they have transparency
              height: 40,
              borderRadius: '10px',
              zIndex: 100
            }}
            px={1}
          >
            <Stack direction={'row'} alignItems="center" spacing={1}>
              {isParked ? (
                <LocalParking fontSize="medium" color="secondary" />
              ) : (
                <PhoneForwarded fontSize="medium" color="success" />
              )}
              {}
              <label
                style={{
                  color: theme.colors.primary.main,
                  fontWeight: 'bold',
                  cursor: 'inherit',
                  whiteSpace: 'nowrap',
                  maxWidth: '180px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {displayedName}
              </label>
            </Stack>
            <Stack minWidth={38}>
              <Timer
                style={{
                  color: 'gray'
                }}
                updateTime={call.lastStatusUpdateTime}
              />
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Grow>
  );
};
