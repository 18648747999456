import { RTCall } from 'src/models/rtcall';
import store, { useSelector } from 'src/store/store';

export const getMissedCallsCountStore = (shouldUseHook: boolean) => {
  const missedCalls = (
    shouldUseHook
      ? useSelector((state) => state.callData.missedCallsCount)
      : store.getState().callData.missedCallsCount
  ) as RTCall[];

  return missedCalls;
};
