import { Queue } from 'src/models/ariQueues';
import {
  getMutedQueuesStore,
  setMutedQueuesStore
} from '../../infraestructure/store/mutedQueues';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { getCallerIdDataFromSession } from 'src/services/webrtc/domain/utils';
import { useSelector } from 'src/store/store';
import { MUTED_QUEUES_KEY } from 'src/store/slices/queues/queuesSlice';

export function toggleMuteQueue(queueId: string) {
  let mutedQueues = getMutedQueuesStore(false);
  if (mutedQueues.find((q) => q === queueId)) {
    removeMutedQueue(queueId);
  } else {
    addMutedQueue(queueId);
  }
}

export function addMutedQueue(queueId: string) {
  let mutedQueues = getMutedQueuesStore(false);
  mutedQueues = [...mutedQueues, queueId];

  localStorage.setItem(MUTED_QUEUES_KEY, JSON.stringify(mutedQueues));

  setMutedQueuesStore(mutedQueues);
}

export function removeMutedQueue(queueId: string) {
  let mutedQueues = getMutedQueuesStore(false);
  mutedQueues = mutedQueues.filter((q) => {
    return q !== queueId;
  });

  localStorage.setItem(MUTED_QUEUES_KEY, JSON.stringify(mutedQueues));

  setMutedQueuesStore(mutedQueues);
}

export function useQueueIsMuted(queueId: string) {
  return useSelector((state) => {
    return state.queues.mutedQueueIds.find((q) => q === queueId);
  });
}

export function setMutedQueuesDomain(queues: string[]) {
  setMutedQueuesStore(queues);
}

export function getMutedQueuesDomain(shouldUseHook: boolean) {
  return getMutedQueuesStore(shouldUseHook);
}

export function checkSessionQueueIsMuted(session: WebRTCSession) {
  const callerIdData = getCallerIdDataFromSession(session);
  const mutedQueues = getMutedQueuesDomain(false);
  const mutedQueue = mutedQueues.some((q) => q === callerIdData.queueId);
  return mutedQueue;
}
