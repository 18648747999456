import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { ConversationMessage } from 'src/models/conversations/messages';

export const socialmediaChannels: Partial<ChannelType[]> = [
  ChannelType.EMAIL,
  ChannelType.TELEGRAM,
  ChannelType.WEBCHAT,
  ChannelType.WHATSAPP,
  ChannelType.INSTAGRAM
];

export const chatChannels: Partial<ChannelType[]> = [
  ChannelType.TELEGRAM,
  ChannelType.WEBCHAT,
  ChannelType.WHATSAPP,
  ChannelType.INSTAGRAM
];

export const isSocialmediaConversation = (conversation: Conversation) => {
  if (!conversation) return false;
  return socialmediaChannels.includes(conversation.channel);
};

export const isVideoCallConversation = (conversation: Conversation) => {
  return (
    conversation?.channel === ChannelType.CALL &&
    conversation?.originAgent?.orientation === 'VideoCall'
  );
};

export const isCallConversation = (conversation: Conversation) => {
  return (
    conversation?.channel === ChannelType.CALL &&
    !isVideoCallConversation(conversation)
  );
};

export const isChatMessage = (message: ConversationMessage) => {
  if (!message || message.channel === ChannelType.EMAIL) return false;
  return socialmediaChannels.includes(message.channel);
};
