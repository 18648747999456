import { createSlice } from '@reduxjs/toolkit';
import { updateContactById } from './thunks';
import { Contact } from 'src/modules/Contacts/models/contacts';

interface ContactsState {
  contacts: Contact[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: ContactsState = {
  contacts: [],
  loading: 'idle'
};

export const contactSlice = createSlice({
  name: 'daServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateContactById.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(updateContactById.fulfilled, (state) => {
      state.loading = 'succeeded';
    });
    builder.addCase(updateContactById.rejected, (state) => {
      state.loading = 'failed';
    });
  }
});

export const {} = contactSlice.actions;

export default contactSlice.reducer;
