import { deleteKnowledgeDocumentationApi } from '../../infrastructure/api/knowledgeModel/deleteKnowledgeDocumentationApi';
import { invalidateKnowledgeDocumentationQuery } from './getKnowledgeModels';

export async function deleteKnowledgeDocumentation(id: string) {
  const response = await deleteKnowledgeDocumentationApi(id);
  return response;
}

export async function deleteKnowledgeDocumentationItems(ids: string[]) {
  const promises = ids.map((id) => deleteKnowledgeDocumentation(id));
  await Promise.allSettled(promises);
  invalidateKnowledgeDocumentationQuery();
}
