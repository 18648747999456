import { CallEnd } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Stack, useTheme } from '@mui/material';
import { AudioOutlinedSelect } from 'src/modules/VideoCall/components/ActionsBar/components/AudioOutlinedSelect';
import { CameraOutlinedSelect } from 'src/modules/VideoCall/components/ActionsBar/components/CameraOutlinedSelect';
import { VoiceOutlinedSelect } from 'src/modules/VideoCall/components/ActionsBar/components/VoiceOutilnedSelect';
import { VideocallsHangupButton } from './components/HangupButton';
import { FinishVideocallButton } from './components/FinishVideocallButton';
import { VideocallRoomContext } from '../../context/VideocallRoomContext';
import { useContext } from 'react';
import { ShareScreenSelect } from './components/ShareScreenSelect';

export function VideocallActionBar() {
  const videoContext = useContext(VideocallRoomContext);
  return (
    <Card
      style={{
        border: '1px solid rgba(0,0,0,0.05)'
      }}
    >
      <Stack
        direction="row"
        justifyContent={'center'}
        padding={1}
        alignItems={'center'}
        columnGap={0.75}
        rowGap={0.75}
      >
        <Box>
          <CameraOutlinedSelect showIcon={true} />
        </Box>
        <Box>
          <AudioOutlinedSelect showIcon={true} />
        </Box>
        <Box>
          <VoiceOutlinedSelect showIcon={true} />
        </Box>
        <Box>
          <ShareScreenSelect />
        </Box>
        <VideocallsHangupButton />
        {videoContext.isLoggedIn && <FinishVideocallButton />}
      </Stack>
    </Card>
  );
}
