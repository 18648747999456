import { memo, useEffect, useRef } from 'react';
import { Conversation } from 'src/models/conversations/conversations';
import {
  getMessagesDate,
  isSameChatMessageSender
} from 'src/services/socialmedia/application/utils';
import {
  CHAT_MESSAGES_PAGE_SIZE,
  ChatMessage
} from 'src/models/conversations/messages';
import { readMessages } from 'src/services/socialmedia/domain/messages/readMessages';
import ChatMessageCard from './ChatMessageCard';
import { Box, styled, Divider, IconButton, Button } from '@mui/material';
import { refreshConversationMessages } from 'src/services/socialmedia/domain/conversation/refreshConversationMessages';
import { Refresh } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

interface Props {
  conversation: Conversation;
}

const ChatContent = (props: Props) => {
  const { conversation } = props;
  const messages = conversation?.messages;
  const { t }: { t: any } = useTranslation();

  useEffect(() => {
    if (messages?.length > 0) {
      readMessages(conversation);
    }
  }, [messages?.length]);

  const refs = useRef([]);

  function scrollToLastReadMessage(behavior: 'auto' | 'smooth' = 'auto') {
    if (!messages || messages.length === 0) return;
    const lastUnreadMessageIndex = messages.findIndex(
      (message) => message.status === 'Received'
    );

    if (lastUnreadMessageIndex !== -1) {
      refs.current[lastUnreadMessageIndex]?.scrollIntoView({
        behavior
      });
      return;
    }

    refs.current[messages.length - 1]?.scrollIntoView({ behavior });
  }

  function scrollToMyLastMessage() {
    if (!messages || messages.length === 0) return;
    //check that the last message is from the user
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.orientation === 'Outgoing') {
      refs.current[messages.length - 1]?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    //Scroll to last message with no animation at first render
    scrollToLastReadMessage();
  }, []);

  // useEffect(() => {
  //   //Scroll to last message with smooth animation when new message is added
  //   scrollToMyLastMessage();
  // }, [messages?.length]);

  return (
    <Box px={3} py={2}>
      {conversation?.totalMessages > conversation?.messages?.length && (
        <Box mb={3} display="flex" justifyContent="center" width="100%">
          <Button
            size="small"
            title={t('Load more')}
            color="secondary"
            onClick={async () => {
              const nextPageNumber = Math.ceil(
                conversation.messages.length / CHAT_MESSAGES_PAGE_SIZE
              );
              refreshConversationMessages(conversation, nextPageNumber);
            }}
            startIcon={<Refresh />}
          >
            {t('Load more')}
          </Button>
        </Box>
      )}
      {messages?.map((message: ChatMessage, i) => {
        const message1 = messages[i - 1] as ChatMessage;
        const message2 = messages[i] as ChatMessage;
        // isSameSender allows messages to be grouped by sender for better visualization
        const isSameSender = isSameChatMessageSender(message1, message2);

        const messagesDate = getMessagesDate(messages[i - 1], messages[i]);
        return (
          <Box
            key={message.messageId}
            ref={(ref) => {
              refs.current[i] = ref;
            }}
          >
            {messagesDate && (
              <DividerWrapper
                sx={{ marginTop: i > 0 ? 3 : 0, marginBottom: i > 0 ? 2 : 1 }}
              >
                {messagesDate}
              </DividerWrapper>
            )}
            <Box sx={{ pt: isSameSender ? 0 : 0.75 }}>
              <ChatMessageCard message={message} conversation={conversation} />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(ChatContent);
