import { ChatMessageType } from 'src/models/conversations/messages';
import {
  SendTelegramMessageExistingFileApiParams,
  sendTelegramMessageExistingFileApi
} from '../../infrastructure/message/sendTelegramMessageExistingFileApi';
import {
  SendTelegramMessageNewFileApiParams,
  sendTelegramMessageNewFileApi
} from '../../infrastructure/message/sendTelegramMessageNewFileApi';

interface CommonParams {
  caption?: string;
  file: File;
  type: ChatMessageType;
}

type ConditionalParams =
  // EXISTING CONVERSATION
  | {
      accountId: string;
      conversationId: string;
      replyTo?: string;
      campaignId?: never;
      phoneNumber?: never;
      username?: never;
      ticketId?: never;
    }
  // NEW CONVERSATION
  | {
      campaignId: string;
      phoneNumber?: string;
      username?: string;
      ticketId?: string;
      accountId?: never;
      conversationId?: never;
      replyTo?: never;
    };

export type SendTelegramMessageFileParams = CommonParams & ConditionalParams;

/**
 * Send a file message text.
 * Provide the phoneNumber or username param to start a new Telegram conversation.
 * Provide the conversationId to send a file message to an existing conversation.
 * @param params
 */
export const sendTelegramMessageFile = async (
  params: SendTelegramMessageFileParams
) => {
  // NEW CONVERSATION
  if (params?.campaignId) {
    const apiNewParams: SendTelegramMessageNewFileApiParams = {
      campaignId: params.campaignId,
      caption: params?.caption,
      phoneNumber: params?.phoneNumber,
      username: params?.username,
      file: params?.file,
      type: params?.type,
      ticketId: params?.ticketId
    };
    await sendTelegramMessageNewFileApi(apiNewParams);
  }
  // EXISTING CONVERSATION
  if (params?.conversationId) {
    const apiExistingParams: SendTelegramMessageExistingFileApiParams = {
      accountId: params?.accountId,
      caption: params?.caption,
      conversationId: params?.conversationId,
      file: params?.file,
      replyTo: params?.replyTo,
      type: params?.type
    };
    await sendTelegramMessageExistingFileApi(apiExistingParams);
  }
};
