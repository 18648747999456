import axios from 'axios';
import { ContactGroup } from '../../models/contacts';
import { CONTACT_GROUP } from 'src/utils/apiRoutes/msContacts';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';

export interface ContactGroupsFilters {
  companyId?: string;
  name?: string;
  description?: string;
  page?: number;
  size?: number;
  sortBy?: keyof ContactGroup;
  sortDir?: DirectionType;
}

export const getContactGroupsApi = async (filters?: ContactGroupsFilters) => {
  const request = await axios.get<PaginatedElements<ContactGroup>>(
    CONTACT_GROUP,
    { params: filters }
  );
  return request.data;
};
