import { useEffect, useMemo, useState } from 'react';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  Campaign,
  CallMode,
  CallEngineCampaign,
  CallCampaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import {
  useCampaignsQuery,
  useGetUsersQuery
} from 'src/services/users/application/query/getUsersDataQueries';
import {
  NEW_SCHEDULE_ID,
  PhonesAutocompleteOption
} from './ScheduleCalendarForm';
import { ScheduleCalendar } from './createScheduleCalendar';
import {
  createContactsFromInvolvedQuery,
  getContactFieldsQuery
} from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import AuthManager from 'src/services/authentication/manager';

export const useSchedulesForm = (conversation: Conversation) => {
  const companyId = AuthManager.getLoggedUserCompanyId();

  // CAMPAIGNS
  const campaignsQuery = useCampaignsQuery({
    serviceId: conversation?.serviceId
  });
  const {
    campaigns,
    outboundCampaigns,
    allowedCampaigns,
    conversationCampaign
  } = useMemo(() => {
    const outbound: Campaign[] = [];
    const allowed: Campaign[] = [];
    let conversationCampaign: Campaign;

    campaignsQuery?.data?.elements.forEach?.((campaign) => {
      if (campaign.channel === ChannelType.CALL) {
        if ((campaign as CallCampaign).callMode === CallMode.OUTBOUND) {
          outbound.push(campaign);
        }
        if (
          (campaign as CallCampaign).callMode === CallMode.POWERDIALER &&
          (campaign as CallEngineCampaign).allowRescheduling
        ) {
          allowed.push(campaign);
        }
        if (campaign.id === conversation?.campaignId) {
          conversationCampaign = campaign;
        }
      }
    });

    return {
      campaigns: campaignsQuery?.data,
      outboundCampaigns: outbound,
      allowedCampaigns: allowed,
      conversationCampaign
    };
  }, [campaignsQuery?.data]);

  const conversationCampaignIsPowerDialer =
    conversationCampaign?.channel === ChannelType.CALL &&
    conversationCampaign.callMode === CallMode.POWERDIALER;

  // -----------------  CONTACTS -----------------

  const contactsQuery = createContactsFromInvolvedQuery(
    conversation,
    conversation
  );
  const contacts = contactsQuery?.data;
  const [phoneNumbers, setPhoneNumbers] = useState<PhonesAutocompleteOption[]>(
    []
  );

  const contactIds = contacts?.map((c) => c.id).filter(Boolean);

  const getPhoneNumberOptions = async () => {
    let phoneAutocompleteOptions: PhonesAutocompleteOption[] = [];

    for (const contact of contacts) {
      if (!contact?.contactGroupId) {
        phoneAutocompleteOptions.push({
          contactId: '',
          name: 'Sin contacto',
          number: contact.fields['Phone']
        });
        continue;
      }

      const contactFields = (
        await getContactFieldsQuery({
          companyId: contact?.companyId,
          contactGroupId: contact?.contactGroupId
        })
      )?.elements;

      let name = contactFields
        ?.filter((field) => field.type === 'Name' && field.mainField)
        .map((field) => contact?.fields[field.id])
        .join();

      const phoneNumbers = contactFields
        ?.filter((field) => field.type === 'Phone')
        .flatMap((field) => contact?.fields[field.id] || []);

      phoneAutocompleteOptions.push(
        ...phoneNumbers.map((number) => ({
          contactId: contact?.id,
          name,
          number
        }))
      );
    }

    return phoneAutocompleteOptions;
  };

  useEffect(() => {
    if (contactsQuery.isFetched) {
      getPhoneNumberOptions().then((phoneNumbers) =>
        setPhoneNumbers(phoneNumbers)
      );
    }
  }, [conversation?.id, contactsQuery.dataUpdatedAt]);

  // -----------------  USERS  ----------------

  const usersQuery = useGetUsersQuery({
    companyId: companyId
  });
  const users = usersQuery?.data?.elements ?? [];

  // -----------------  FORM  -----------------
  const defaultSchedule = useMemo(() => {
    const socialmediaAgent = conversation.involvedContacts
      ? conversation.involvedContacts[0]?.socialmediaAgent
      : undefined;
    const phonenumber = socialmediaAgent?.endpoint ?? '';
    const campaignId = conversationCampaignIsPowerDialer
      ? conversation.campaignId
      : allowedCampaigns?.[0]?.id;

    const userId = allowedCampaigns?.some((campaign) =>
      campaign.users.includes(conversation?.assignedUserIds[0])
    )
      ? conversation?.assignedUserIds[0]
      : allowedCampaigns?.[0]?.users[0];

    return {
      userId,
      phonenumber,
      campaignId,
      id: NEW_SCHEDULE_ID,
      companyId: conversation?.companyId ?? companyId,
      calendarData: {
        title: '',
        description: '',
        //contactId: '',
        scheduleTimestamp: new Date().valueOf() / 1000
        //createdBy: conversation?.assignedUserIds[0] ?? '',
        //originCampaignId: conversationCampaign?.id,
        //ticketId: undefined
      }
    } as ScheduleCalendar;
  }, [conversation?.id, campaignsQuery.isFetched]);

  return {
    allowedCampaigns,
    defaultSchedule,
    phoneNumbers,
    users,
    contactIds
  };
};
