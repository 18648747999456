import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { useEffect, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getRoot,
  $insertNodes,
  BLUR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  FOCUS_COMMAND,
  KEY_DOWN_COMMAND
} from 'lexical';

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import CopyPastePlugin from 'src/modules/Admin/modules/Operations/modules/DaServices/views/form/tabs/ShowflowTab/components/CopyPastePlugin';
import { Variable } from 'src/modules/Admin/modules/General/modules/Variables/models/models';
import {
  $createVariableNode,
  $isVariableNode
} from '../lexicalNode/VariableNode';
import TabIndentPlugin from 'src/modules/Dashboard/views/Inbox/EmailPanel/components/EmailEditor/components/Editor/plugins/TabIndent';

export interface FocusState {
  isFocused: boolean;
  setIsFocused: (value: boolean) => void;
}

interface Props {
  variables: Variable[];
  text?: string;
  setUpdatedText: (text: string) => void;
  companyId: string;
  focusState?: FocusState;
}

function LexicalVariablesEditor({
  text,
  setUpdatedText,
  variables,
  focusState,
  companyId
}: Props) {
  const [editor] = useLexicalComposerContext();
  const previousText = useRef<string | undefined>(undefined);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    const removeListener = editor.registerCommand<KeyboardEvent>(
      KEY_DOWN_COMMAND,
      (event) => {
        if (event.key === '$' && variables.length !== 0) {
          event.preventDefault();
          event.stopPropagation();

          const node = $createVariableNode(undefined, companyId, variables);
          $insertNodes([node]);
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH
    );

    return () => {
      removeListener();
    };
  }, [editor, variables]);

  //set initial content to text
  useEffect(() => {
    if (text !== previousText.current) {
      previousText.current = text;
      // replace text with text
      editor.update(() => {
        // text between $ and $ replace with <code> tag
        function textToShowflowHtml(text: string) {
          return text.replace(/\$.*?\$/g, (match) => {
            return `<code>${match}</code>`;
          });
        }
        const parser = new DOMParser();
        const html = parser.parseFromString(
          textToShowflowHtml(text),
          'text/html'
        );
        const nodes = $generateNodesFromDOM(editor, html);

        nodes.forEach((node) => {
          if ($isVariableNode(node)) {
            node.setVariables(variables);
            node.setCompanyId(companyId);
          }
        });

        $getRoot().clear();
        $getRoot().select();
        $insertNodes(nodes);
      });
    }
  }, [text]);

  useEffect(() => {
    let removeListener;
    debounceTimeout.current = setTimeout(() => {
      removeListener = editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const htmlString = $generateHtmlFromNodes(editor);
          //remove all html tags
          const updatedText = htmlString.replace(/<[^>]*>?/gm, '');
          if (updatedText !== previousText.current) {
            previousText.current = updatedText; // Actualiza referencia
            setUpdatedText(updatedText);
          }
        });
      });
    });

    return () => {
      removeListener?.();
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [editor, setUpdatedText]);

  useEffect(() => {
    if (focusState) {
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          focusState.setIsFocused(false);

          return false;
        },
        COMMAND_PRIORITY_LOW
      );
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          focusState.setIsFocused(true);
          return false;
        },
        COMMAND_PRIORITY_LOW
      );
    }
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <RichTextPlugin
        placeholder={<></>}
        contentEditable={
          <ContentEditable
            spellCheck={false}
            style={{
              border: 'none',
              outline: 'none',
              position: 'relative',
              maxWidth: '100%',
              margin: '0 !important'
            }}
          />
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <CopyPastePlugin />
      <TabIndentPlugin />
    </div>
  );
}

export default LexicalVariablesEditor;
