import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FileDropZone from 'src/components/FileDropZone';
import { Ticket, TicketAttachmentFile } from '../../../../models/tickets';
import { TicketsDomain } from '../../../../domain/TicketsDomain';
import AttachmentFilesContainer from 'src/modules/Dashboard/views/Inbox/TelegramPanel/AttachmentFileContainer';
import { Box } from '@mui/material';

interface Props {
  ticket: Ticket;
  ticketFiles: TicketAttachmentFile[];
  setTicketFiles: Dispatch<SetStateAction<TicketAttachmentFile[]>>;
}

const TicketAttachments = (props: Props) => {
  const { ticket, ticketFiles, setTicketFiles } = props;

  const [_selectedFile, setSelectedFile] = useState<File>(null);

  const onAcceptFiles = (acceptedFiles: File[]) => {
    const ticketAttachments = acceptedFiles.map(
      (file) => ({ ticketId: ticket?.id, file: file }) as TicketAttachmentFile
    );
    setTicketFiles((prev) => [...prev, ...ticketAttachments]);
  };

  useEffect(() => {
    let isMounted = true;
    if (ticket?.attachments?.length > 0) {
      TicketsDomain.downloadTicketAttachments({
        ticketId: ticket?.id,
        ticketAttachments: ticket?.attachments
      }).then((resp) => {
        if (isMounted) {
          setTicketFiles(resp);
        }
      });
      return () => {
        isMounted = false;
      };
    }
  }, [ticket?.attachments]);

  return (
    <Box width="100%">
      <FileDropZone
        multiple
        hideIcon
        hideFilesInfo
        onAcceptFiles={onAcceptFiles}
      />
      <Box py={2} display="flex" flexWrap="wrap" width="100%" gap={0.5}>
        {ticketFiles.length > 0 &&
          ticketFiles.map((ticketFile, index) => (
            <AttachmentFilesContainer
              key={ticketFile.file.name + index}
              file={ticketFile.file}
              setFile={setSelectedFile}
              onClose={() => {
                setTicketFiles(ticketFiles.filter((_file, i) => i !== index));
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default TicketAttachments;
