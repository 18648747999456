import AdminHeader from 'src/components/AdminHeader';

import { Helmet } from 'react-helmet-async';
import { useContext, useState } from 'react';
import { DialTable } from 'src/components/DialTable';
import { getPromptElements } from '../InboundRoutes/domain/prompt/getPromptElements';
import { DialTableDataContext } from 'src/components/DialTable/models/functionContexts';
import { PromptElement } from 'src/modules/Shared/models/ariModels';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';
import { Button, IconButton, Stack } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { PromptElementFormModal } from './components/PromptElementFormModal';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { deletePromptElements } from '../InboundRoutes/domain/prompt/deletePromptElement';
import { useAlert } from 'src/hooks/useAlert';
import { t } from 'i18next';
import {
  checkCreatePromptsPermissions,
  checkDeletePromptsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

function Prompts() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [promptElementToEdit, setPromptElementToEdit] =
    useState<PromptElement>();

  const [promptsToDelete, setPromptsToDelete] = useState<PromptElement[]>([]);
  const { hasAccess } = useContext(PermissionsContext);

  async function getDataFn(context: DialTableDataContext<PromptElement>) {
    const prompts = await getPromptElements({ ...context.filtersObject });
    const paginatedPrompts = getPaginatedElements({ elements: prompts });
    return paginatedPrompts;
  }

  const { showAlert } = useAlert();
  async function handleDelete() {
    try {
      await deletePromptElements(promptsToDelete.map((p) => p.id));
      setPromptsToDelete([]);
      showAlert(
        t(
          `Prompt${promptsToDelete.length > 1 ? 's' : ''} deleted successfully`
        ),
        'success'
      );
    } catch (error) {
      showAlert(
        t(`Error deleting prompt${promptsToDelete.length > 1 ? 's' : ''}`),
        'error'
      );
    } finally {
      setPromptsToDelete([]);
    }
  }

  return (
    <>
      <Helmet>
        <title>Prompts</title>
      </Helmet>
      <AdminHeader
        heading="Prompts"
        subheading="These are the created prompts to be used in inbound routes"
        action={
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setIsModalOpen(true)}
            color="secondary"
          >
            {t('Create') + ' prompt'}
          </Button>
        }
        permissionCheckerFn={checkCreatePromptsPermissions}
      />
      <PromptElementFormModal
        isOpen={isModalOpen}
        onClose={() => {
          setPromptElementToEdit(undefined);
          setIsModalOpen(false);
        }}
        promptElement={promptElementToEdit}
      />
      <ConfirmationModal
        isOpen={promptsToDelete.length > 0}
        onConfirm={handleDelete}
        mode={ConfirmationModalModes.DELETE}
        data={promptsToDelete}
        dataKey="name"
        closeFn={() => setPromptsToDelete([])}
      />
      <Stack px={4} py={2}>
        <DialTable<PromptElement>
          queryKey={['promptElements']}
          getDataFn={getDataFn}
          getColumnsFn={async () => {
            return [
              {
                id: 'name',
                accessorKey: 'name',
                header: 'Name'
              },
              {
                accessorKey: 'description',
                header: 'Description',
                id: 'description'
              },
              {
                accessorKey: 'text',
                header: 'Text',
                id: 'text'
              }
            ];
          }}
          getBulkActionsFn={(context) => {
            return (
              <IconButton
                size="small"
                color="error"
                onClick={() => setPromptsToDelete(context.selectedData)}
                disabled={!hasAccess(checkDeletePromptsPermissions)}
              >
                <Delete />
              </IconButton>
            );
          }}
          getRowActionsFn={(row) => {
            return (
              <>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setPromptElementToEdit(row.rowData);
                    setIsModalOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setPromptsToDelete([row.rowData]);
                  }}
                  disabled={!hasAccess(checkDeletePromptsPermissions)}
                >
                  <Delete />
                </IconButton>
              </>
            );
          }}
        />
      </Stack>
    </>
  );
}

export default Prompts;
