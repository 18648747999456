import {
  Base64Attachment,
  EmailMessage
} from 'src/models/conversations/messages';
import {
  formatAttachmentUrl,
  getBlobUrl
} from 'src/modules/Shared/utils/attachments';

export const getEmailTextWithParsedInlineAttachments = async (
  messageData: EmailMessage
) => {
  const attachments = messageData?.attachedFiles ?? [];
  let parsedHtml = messageData.message;

  for (const attachment of attachments) {
    if (attachment.type === 'Inline') {
      const cid = attachment.contentId.replace('<', '').replace('>', '');
      const url = formatAttachmentUrl(
        attachment.uploadedFile,
        messageData.channel
      );
      const urlBlob = await getBlobUrl(url);
      parsedHtml = parsedHtml?.replaceAll?.(`cid:${cid}`, urlBlob);
    }
  }

  return parsedHtml;
};

export const getEmailTextWithParsedBase64InlineAttachments = async (
  message: string,
  attachments: Base64Attachment[]
) => {
  let parsedHtml = message;

  for (const attachment of attachments) {
    if (attachment.type === 'Inline') {
      const name = attachment.name;
      const src = `data:image/png;base64,${attachment.base64}`;

      parsedHtml = parsedHtml?.replaceAll?.(`cid:${name}`, src);
    }
  }

  return parsedHtml;
};
