import { deleteContactGroupApi } from '../infraestructure/api/deleteContactGroupApi';
import { ContactGroup } from '../models/contacts';
import { invalidateContactGroupsQuery } from './getContactGroups';

export const deleteContactGroup = (contactGroup: ContactGroup) => {
  return deleteContactGroupApi(contactGroup.id);
};

export const deleteContactGroups = async (contactGroups: ContactGroup[]) => {
  const promises = await Promise.all(
    contactGroups.map((contactGroup) => deleteContactGroup(contactGroup))
  );
  invalidateContactGroupsQuery();
  return promises;
};
