import { queryClient } from 'src/utils/queryClient';
import { TicketSubResolutionsRequestParams } from '../../models/tickets';
import { TicketsDomain } from '../../domain/TicketsDomain';

export const getTicketSubResolutionsQuery = (
  params: TicketSubResolutionsRequestParams
) => {
  return queryClient.fetchQuery(['ticketSubResolutions', params], {
    queryFn: () => TicketsDomain.getTicketSubResolutions(params),
    staleTime: 60 * 1000
  });
};
