import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { MusicOnHold } from '../../model/MusicOnHold';
import { MOH_CREATE_FORM, MOH_UPDATE_FORM } from '../constants/index';

interface MusicOnHoldContextProps {
  musicOnHoldEdit: MusicOnHold;
  musicOnHoldsDelete: MusicOnHold[];
  musicOnHoldIds: string[];
  isOpenModal: boolean;
  isOpenDelete: boolean;
  isOpenNewAudioModal: boolean;
  isOpenEditAudioModal: boolean;
  typeForm: typeof MOH_CREATE_FORM | typeof MOH_UPDATE_FORM;
  setMusicOnHoldEdit: Dispatch<SetStateAction<MusicOnHold>>;
  setMusicOnHoldsDelete: Dispatch<SetStateAction<MusicOnHold[]>>;
  setMusicOnHoldIds: Dispatch<SetStateAction<string[]>>;
  toggleMusicOnHoldModal: () => void;
  toggleMusicOnHoldDelete: () => void;
  toggleNewAudioModal: () => void;
  toggleEditAudioModal: () => void;
  setTypeForm: Dispatch<
    SetStateAction<typeof MOH_CREATE_FORM | typeof MOH_UPDATE_FORM>
  >;
}

const MUSIC_ON_HOLD_INITIAL_VALUES: MusicOnHold = {
  companyId: '',
  name: '',
  order: '',
  id: '',
  announcements: []
};

export const MusicOnHoldContext = createContext<MusicOnHoldContextProps>(
  {} as MusicOnHoldContextProps
);

export const MusicOnHoldProvider: React.FC = ({ children }) => {
  const [musicOnHoldEdit, setMusicOnHoldEdit] = useState<MusicOnHold>(
    MUSIC_ON_HOLD_INITIAL_VALUES
  );
  const [musicOnHoldsDelete, setMusicOnHoldsDelete] = useState<MusicOnHold[]>(
    []
  );
  const [musicOnHoldIds, setMusicOnHoldIds] = useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [isOpenNewAudioModal, setIsOpenNewAudioModal] =
    useState<boolean>(false);

  const [isOpenEditAudioModal, setIsOpenEditAudioModal] =
    useState<boolean>(false);

  const [typeForm, setTypeForm] = useState<
    typeof MOH_CREATE_FORM | typeof MOH_UPDATE_FORM
  >(MOH_CREATE_FORM);

  const toggleMusicOnHoldModal = () =>
    setIsOpenModal((prevState) => !prevState);

  const toggleMusicOnHoldDelete = () =>
    setIsOpenDelete((prevState) => !prevState);

  const toggleNewAudioModal = () =>
    setIsOpenNewAudioModal((prevState) => !prevState);

  const toggleEditAudioModal = () =>
    setIsOpenEditAudioModal((prevState) => !prevState);

  useEffect(() => {
    if (!isOpenDelete) {
      setMusicOnHoldIds([]);
      setMusicOnHoldsDelete([]);
    }
  }, [isOpenDelete]);

  useEffect(() => {
    if (!isOpenModal) {
      setMusicOnHoldEdit(MUSIC_ON_HOLD_INITIAL_VALUES);
    }
  }, [isOpenModal]);

  return (
    <MusicOnHoldContext.Provider
      value={{
        musicOnHoldEdit,
        musicOnHoldsDelete,
        musicOnHoldIds,
        isOpenModal,
        isOpenDelete,
        isOpenNewAudioModal,
        isOpenEditAudioModal,
        typeForm,
        setMusicOnHoldEdit,
        setMusicOnHoldsDelete,
        setMusicOnHoldIds,
        toggleMusicOnHoldModal,
        toggleMusicOnHoldDelete,
        toggleNewAudioModal,
        toggleEditAudioModal,
        setTypeForm
      }}
    >
      {children}
    </MusicOnHoldContext.Provider>
  );
};
