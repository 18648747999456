import {
  DialerBotCampaign,
  DialerbotExecutionStatus,
  ExecutionParams,
  Schedules
} from 'src/modules/DialerBot/models/dialerbot';
import { PaginatedElements, PaginationValues } from 'src/models/pagination';
import { RTUser } from 'src/models/rtUser';
import { getCampaignById } from 'src/modules/Admin/modules/Operations/modules/DaServices/infrastructure/campaigns/getCampaigns';
import { getSchedulesDialerBot } from '../infrastructure/api/callSchedulling';
import {
  getDialerBotCampaigns,
  getDialerBotInformationApi,
  getDialerBotsApi,
  getExecutionListApi
} from '../infrastructure/api/dialerBotCampaigns';
import { DialerBotCampaignsParams } from 'src/modules/DialerBot/models/dialerbot';
import { getProgress } from '../infrastructure/api/executionDialer';

export type dialerBotCampaignsKeys = keyof DialerBotCampaign;
export type rtusersKeys = keyof RTUser;

/** GET ALL DIALERBOT CAMPAIGNS
 *
 * Get the list of all dialerbot campaigns
 *
 * @returns All dialerbot campaigns
 */
export const getDialerBotsCampaigns = async (
  params: DialerBotCampaignsParams
): Promise<DialerBotCampaign[]> => {
  let allDialerCampaigns = await getDialerBotCampaigns(params);
  return allDialerCampaigns;
};

/** GET ONE DIALERBOT CAMPAIGN
 *
 * Get the list of all dialerbot campaigns
 *
 * @returns All dialerbot campaigns
 */
export const getOneDialerBotsCampaignById = async (
  id: string
): Promise<DialerBotCampaign> => {
  let oneDialerCampaign = await getCampaignById(id);
  return oneDialerCampaign;
};
/** GET DIALERBOT PROGRESS
 *
 * Get the dialerbot campaign progress by campaign Id
 *@param campaignId Id of campaign
 * @returns progress number
 */
export const getDialerBotProgress = async (
  campaignId: string
): Promise<number> => {
  let progress = await getProgress(campaignId);

  return progress;
};

/** GET DIALERBOT SCHEDULES
 *
 * Get the dialerbot schedules by campaign
 *@param campaignId Id of campaign
 * @returns dialerbot schedules
 */
export const getDialerBotSchedules = async (
  campaignId?: string,
  scheduleDatabaseId?: string,
  page: number = 0,
  size: number = 10
): Promise<PaginatedElements<Schedules>> => {
  const schedules = await getSchedulesDialerBot(
    campaignId,
    scheduleDatabaseId,
    page,
    size
  );
  return schedules;
};

/** GET SCHEDULES LAUNCH DATE DIALERBOT
 *
 * Get the launch date of schedules by campaign id
 *
 * @param campaignId Id of campaign
 * @returns date string
 */
export const getSchedulesLaunchDate = async (campaignId: string) => {
  // Getting the list of schedules
  let paginatedSchedules = await getDialerBotSchedules(campaignId);
  // If there is not schedules return not date
  if (paginatedSchedules.elements.length === 0) return 'Not date';
  // If there is schedules return date
  let date = new Date(0);
  date.setUTCSeconds(paginatedSchedules.elements[0].uploadDate);

  return date.toLocaleDateString();
};

/** GET ALL DIALERBOTS
 *
 * Get the list of all dialerbots
 *
 * @returns All dialerbots
 */
export const getDialerBots = async (): Promise<DialerbotExecutionStatus[]> => {
  let allDialerCampaigns = await getDialerBotsApi();
  return allDialerCampaigns;
};

export const getExecutionList = async (
  executionParams: ExecutionParams
): Promise<PaginatedElements<DialerbotExecutionStatus>> => {
  let allExecutions = await getExecutionListApi(executionParams);
  return allExecutions;
};

/** GET ONE DIALERBOT
 *
 * Get one dialerbot
 *
 * @returns One dialerbot
 */
export const getDialerBotDomain = async (
  id: string
): Promise<DialerBotCampaign> => {
  let allDialerCampaigns = await getDialerBotInformationApi(id);
  return allDialerCampaigns;
};
