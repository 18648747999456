import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useContext } from 'react';
import { VideocallRoomContext } from '../../../context/VideocallRoomContext';
import { VideoCallsDomain } from '../../../domain';
import { t } from 'i18next';
import { Close } from '@mui/icons-material';

export const FinishVideocallButton = () => {
  const videoCallsContext = useContext(VideocallRoomContext);
  const { room, setRoom } = videoCallsContext.videoRoom;

  const theme = useTheme();

  async function handleFinishRoom() {
    VideoCallsDomain.finishRoom(room.id).then((room) => setRoom(room));
  }

  return (
    <Tooltip title={t('Finish videocall')}>
      <IconButton
        sx={{
          boxShadow: `${theme.colors.shadows.error}`,
          background: `${theme.colors.error.main}`,
          color: 'white',

          '&:hover': {
            background: `${theme.colors.error.dark}`,
            color: `${theme.palette.getContrastText(theme.colors.primary.main)}`
          }
        }}
        onClick={handleFinishRoom}
      >
        <Close />
      </IconButton>
    </Tooltip>
  );
};
