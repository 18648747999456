export enum UserPermissionsType {
  ROLE = 'Role',
  USER = 'User'
}

export enum CampaignsPermissionLevel {
  ALL = 'All',
  NONE = 'None',
  ALL_FROM_SERVICES = 'AllFromServices',
  ONLY_ASSIGNED_CAMPAIGNS = 'OnlyAssignedCampaigns'
}

export enum ServicesPermissionLevel {
  ALL = 'All',
  NONE = 'None',
  ONLY_ASSIGNED_SERVICES = 'OnlyAssignedServices'
}

export enum LimitedCampaignsPermissionLevel {
  NONE = 'None',
  ONLY_ASSIGNED_CAMPAIGNS = 'OnlyAssignedCampaigns'
}

export interface UserRole {
  companyId: string;
  description: string;
  enabled: boolean;
  groupMemberCount: number;
  id?: string;
  name: string;
  permissions: Permissions;
  type: UserPermissionsType.ROLE;
  userIds?: string[];
}

export interface UserPermissions {
  companyId: string;
  permissions: Permissions;
  superadmin: boolean;
  userId: string;
  roleIds: string[];
}

export interface Permissions {
  admin: AdminPermissions;
  omnichannel: OmnichannelPermissions;
  operations: OperationsPermissions;
  productivity: ProductivityPermissions;
}

export interface AdminPermissions {
  acd: ACDPermissions;
  contacts: ContactsPermissions;
  general: GeneralPermissions;
  management: ManagementPermissions;
  quality: QualityPermissions;
  tickets: TicketsPermissions;
  integrations: IntegrationsPermissions;
}

export interface OmnichannelPermissions {
  accounts: AccountsPermissions;
  conversations: ConversationsPermissions;
  related: RelatedPermissions;
}

export interface OperationsPermissions {
  campaigns: CampaignsPermissions;
  company: CompanyPermissions;
  massive: MassivePermissions;
  robinsonList: RobinsonListsPermissions;
  services: ServicesPermissions;
}

export interface ProductivityPermissions {
  realtimeReporting: RealtimeReportingPermissions;
}

export interface ACDPermissions {
  announcements: BasicPermissions;
  entityModels: BasicPermissions;
  inboundRoutes: BasicPermissions;
  intentionModels: BasicPermissions;
  knowledgeModels: BasicPermissions;
  prompts: BasicPermissions;
  moh: BasicPermissions;
  outboundRoutes: BasicPermissions;
  timeConditions: BasicPermissions;
  variables: BasicPermissions;
}

export interface ContactsPermissions {
  contactGroups: ContactGroupsPermissions;
  contacts: ContactEntitiesPermissions;
  fields: ContactFieldsPermissions;
  tags: ContactTagsPermissions;
}

export interface TicketsPermissions {
  tickets: TicketEntitiesPermissions;
  tags: TagsPermissions;
  fields: FieldsPermissions;
  resolutions: ResolutionsPermissions;
}

export interface IntegrationsPermissions {
  externalActions: ExternalActionsPermissions;
  apiKeys: ApiKeysPermissions;
}

export interface GeneralPermissions {
  callerIds: CallerIdsPermissions;
  extensions: BasicPermissions;
  trunks: TrunksPermissions;
}

export interface ManagementPermissions {
  roles: BasicPermissions;
  users: UsersPermissions;
}

export interface ContactGroupsPermissions {
  create: boolean;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
}

export interface ContactEntitiesPermissions {
  create: ServicesPermissionLevel;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
  upload: ServicesPermissionLevel;
}

export interface ContactFieldsPermissions {
  create: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
  delete: ServicesPermissionLevel;
}

export interface ContactTagsPermissions {
  create: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
  delete: ServicesPermissionLevel;
}

export interface QualityPermissions {
  analyze: AnalyzePermissions;
  keyWordsGroups: KeyWordsGroupsPermissions;
  questionGroups: QuestionGroupsPermissions;
}

export interface TicketEntitiesPermissions {
  create: ServicesPermissionLevel;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
  upload: ServicesPermissionLevel;
}

export interface TagsPermissions {
  create: boolean;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
}

export interface FieldsPermissions {
  create: boolean;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
}

export interface ResolutionsPermissions {
  create: boolean;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
}

export interface ExternalActionsPermissions {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface ApiKeysPermissions {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface CallerIdsPermissions {
  read: boolean;
}

export interface TrunksPermissions {
  read: boolean;
}

export interface UsersPermissions {
  create: boolean;
  delete: boolean;
  read: CampaignsPermissionLevel;
  update: boolean;
}

export interface AnalyzePermissions {
  create: ServicesPermissionLevel;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  upload: ServicesPermissionLevel;
}

export interface KeyWordsGroupsPermissions {
  create: ServicesPermissionLevel;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
}

export interface QuestionGroupsPermissions {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface AccountsPermissions {
  email: BasicPermissions;
  instagram: BasicPermissions;
  telegram: BasicPermissions;
  webChat: BasicPermissions;
  whatsApp: BasicPermissions;
}

export interface ConversationsPermissions {
  chat: TextConversationsPermissions;
  email: TextConversationsPermissions;
  voice: VoiceConversationsPermissions;
}

export interface TextConversationsPermissions {
  interact: CampaignsPermissionLevel;
  close: CampaignsPermissionLevel;
  view: CampaignsPermissionLevel;
}

export interface VoiceConversationsPermissions {
  intervene: CampaignsPermissionLevel;
  close: CampaignsPermissionLevel;
  view: CampaignsPermissionLevel;
}

export interface RelatedPermissions {
  emailSignatures: BasicPermissions;
  webchatStyles: BasicPermissions;
}

export interface CampaignsPermissions {
  dialerbot: DialerbotPermissions;
  email: BasicCampaignsPermissions;
  inbound: BasicCampaignsPermissions;
  instagram: BasicCampaignsPermissions;
  launchEngine: DialerbotPermissions;
  manual: BasicCampaignsPermissions;
  telegram: BasicCampaignsPermissions;
  videocalls: BasicCampaignsPermissions;
  webChat: BasicCampaignsPermissions;
  whatsApp: BasicCampaignsPermissions;
}

export interface DialerbotPermissions extends BasicCampaignsPermissions {
  startPause: CampaignsPermissionLevel;
  upload: CampaignsPermissionLevel;
}

export interface ServicesPermissions {
  basic: BasicServicesPermissions;
}

export interface RobinsonListsPermissions {
  basic: BasicRobinsonListPermissions;
}

export interface MassivePermissions {
  email: EmailPermissions;
  sms: SmsPermissions;
  whatsApp: WhatsAppPermissions;
}

export interface EmailPermissions {
  create: boolean;
  read: boolean;
  remove: boolean;
  upload: boolean;
  report: boolean;
  launch: boolean;
}
export interface SmsPermissions {
  create: boolean;
  read: boolean;
  remove: boolean;
  upload: boolean;
  report: boolean;
  launch: boolean;
}

export interface WhatsAppPermissions {
  create: boolean;
  read: boolean;
  remove: boolean;
  upload: boolean;
  report: boolean;
  launch: boolean;
}

export interface CompanyPermissions {
  userStatuses: UserStatusPermissions;
}

export interface UserStatusPermissions {
  update: boolean;
  changeOthers: CampaignsPermissionLevel;
}

export interface RealtimeReportingPermissions {
  dashboard: DashboardPermissions;
  panel: PanelPermissions;
}

export interface DashboardPermissions {
  create: boolean;
  delete: CampaignsPermissionLevel;
  read: CampaignsPermissionLevel;
  update: CampaignsPermissionLevel;
}

export interface PanelPermissions {
  create: CampaignsPermissionLevel;
  delete: CampaignsPermissionLevel;
  update: CampaignsPermissionLevel;
}

export interface BasicPermissions {
  create: boolean;
  delete: boolean;
  read: boolean;
  update: boolean;
}

export interface BasicCampaignsPermissions {
  create: ServicesPermissionLevel;
  delete: CampaignsPermissionLevel;
  enableDisable: CampaignsPermissionLevel;
  read: CampaignsPermissionLevel;
  report: CampaignsPermissionLevel;
  update: CampaignsPermissionLevel;
}

export interface BasicServicesPermissions {
  create: boolean;
  delete: ServicesPermissionLevel;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
}

export interface BasicRobinsonListPermissions {
  create: boolean;
  delete: boolean;
  read: ServicesPermissionLevel;
  update: ServicesPermissionLevel;
}

export type PermissionTypeKey = keyof Permissions;

export type PermissionGroupKey =
  | keyof AdminPermissions
  | keyof OmnichannelPermissions
  | keyof OperationsPermissions;

export type PermissionNameKey =
  | keyof AccountsPermissions
  | keyof ACDPermissions
  | keyof CampaignsPermissions
  | keyof ContactsPermissions
  | keyof ConversationsPermissions
  | keyof GeneralPermissions
  | keyof ManagementPermissions
  | keyof ServicesPermissions;

export type PermissionKey =
  | keyof BasicCampaignsPermissions
  | keyof BasicServicesPermissions
  | keyof CallerIdsPermissions
  | keyof ContactEntitiesPermissions
  | keyof ContactGroupsPermissions
  | keyof DialerbotPermissions
  | keyof TextConversationsPermissions
  | keyof UsersPermissions
  | keyof VoiceConversationsPermissions;

export type PermissionLevel =
  | CampaignsPermissionLevel
  | ServicesPermissionLevel
  | LimitedCampaignsPermissionLevel;

export type PermissionValue = PermissionLevel | boolean;
