import { getTicketFilterFieldsApi } from '../../infrastructure/reporting/getTicketFilterFieldsApi';
import { TicketsDomain } from '../TicketsDomain';
import { ReportingTicket, TicketField } from '../../models/tickets';
import {
  AvailableFilter,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import { ReportingContact } from 'src/modules/Productivity/modules/Contacts/models/reportingContact';
import { t } from 'i18next';

export const getReportingTicketFilterFields = async (companyId: string) => {
  const ticketFieldsPromise = TicketsDomain.getTicketFieldsQuery({
    companyId
  });
  const ticketFilterFieldsPromise = getTicketFilterFieldsApi();

  const [ticketFields, ticketFilterFields] = await Promise.all([
    ticketFieldsPromise,
    ticketFilterFieldsPromise
  ]);
  const fields = ticketFilterFields
    .map((filterField) =>
      filterField.fieldName === 'fields.$value$.value'
        ? ticketFields.map((ticketField) =>
            getFilterFieldFromTicketField(filterField, ticketField)
          )
        : filterField
    )
    .flat();
  return fields;
};

export const getFilterFieldFromTicketField = (
  filterField: AvailableFilter<ReportingTicket>,
  ticketField: TicketField
): AvailableFilter<ReportingTicket> => {
  let fieldDataType: FieldDataType;
  let fieldType: FieldType;
  switch (ticketField.type) {
    case 'Boolean':
      fieldDataType = FieldDataType.BOOLEAN;
      break;
    case 'DateTime':
      fieldDataType = FieldDataType.DATE;
      break;
    case 'Options':
      fieldDataType = FieldDataType.STRING;
      // fieldType = 'MULTI_SELECTOR';
      fieldType = FieldType.TEXT;
      break;
    case 'Text':
      fieldDataType = FieldDataType.STRING;
      fieldType = FieldType.TEXT;
      break;
  }
  const filter: DialTableFilter<ReportingContact> = {
    ...filterField,
    fieldName: replaceVariableByValue(
      filterField.fieldName as string,
      ticketField.id
    ),
    fieldDataType,
    fieldType,
    label: ` ${t('Ticket field')} ${ticketField.name}`,
    operator: StringOperator.EQUALS,
    values: []
  };
  return filter;
};

const VARIABLE_REGEX = /\$(.*?)\$/g;

/** Returns the string with the variables (text between '$' and '$', including $$) replaced by the replaceValue.
 * @param stringWithVariables e.g., 'text.$variable$.number'
 * @param replaceValue the replacement text
 */
export const replaceVariableByValue = (
  stringWithVariables: string,
  replaceValue: string
) => {
  return stringWithVariables.replace(VARIABLE_REGEX, replaceValue);
};

/** Returns true if fieldname contains a variable. Otherwise, returns false. */
export const checkIsFieldWithVariable = (fieldName: string) => {
  return Boolean(fieldName?.match(VARIABLE_REGEX)?.length > 0);
};

/** Returns id (first occurrence of the substring which is between '.' (or '') and '.value' in the TicketField filter)
 * @param fielName
 */
export const getFieldIdFromFilterFieldname = (fieldname: string) => {
  const regex = /^[^.]+(?=\.value)|(?<=\.value\.)[^.]+/g;
  return fieldname.match(regex)?.at(0);
};
