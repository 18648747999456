import { VideoCallParticipant } from '../types/videoCallTypes';
import { ContactField } from 'src/modules/Contacts/models/contacts';
import { getContactFieldByIdQuery } from 'src/modules/Contacts/domain/getContactFields';

export async function getParticipantFields(participant: VideoCallParticipant) {
  if (!participant?.formFieldValues) return [];
  const fieldIds = participant.formFieldValues.map(
    (formField) => formField.contactFieldId
  );
  let fields: ContactField[] = [];

  for (const fieldId of fieldIds) {
    const field = await getContactFieldByIdQuery(fieldId);
    fields.push(field);
  }

  return fields;
}
