import axios from 'axios';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { replaceURLParams } from 'src/services/utils';
import { CAMPAIGNS_ID } from 'src/utils/apiRoutes/msUsers';
import { queryClient } from 'src/utils/queryClient';
import { CAMPAIGN_DRAFTS_KEY } from './getCampaigns';

export const putCampaign = async (
  campaignDto: Campaign,
  campaignId: string
): Promise<Campaign> => {
  const path = replaceURLParams(CAMPAIGNS_ID, { '{id}': campaignId });
  const response = await axios.put(path, campaignDto);

  queryClient.invalidateQueries({
    queryKey: ['campaigns']
  });

  return response.data;
};

export const updateCampaignDraftApi = async (updatedCampaign: Campaign) => {
  const resp = localStorage.getItem(CAMPAIGN_DRAFTS_KEY);
  const previousCampaignDrafts: Campaign[] = !!resp ? JSON.parse(resp) : [];
  const campaignDrafts = previousCampaignDrafts.filter(
    (campaign) => campaign.draftId !== updatedCampaign.draftId
  );
  const newCampaign: Campaign = { ...updatedCampaign };
  const newCampaignDrafts = [...campaignDrafts, newCampaign];
  localStorage.setItem(CAMPAIGN_DRAFTS_KEY, JSON.stringify(newCampaignDrafts));
  return newCampaign;
};
