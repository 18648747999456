import { WhatsAppTemplate } from 'src/services/whatsapp/models/WhatsAppTemplate';
import { setWhatspAppTemplate } from 'src/store/slices/socialmedia/socialmediaSlice';
import store, { useSelector } from 'src/store/store';

/** SEND WHATSPAPP TEMPLATE
 *
 * @param template WhatspAppTemplate
 */
export const setWhatspAppTemplateStore = (template: WhatsAppTemplate) => {
  store.dispatch(setWhatspAppTemplate(template));
};

/** GET WHATSPAPP TEMPLATE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns WhatspApp Template
 */
export const getWhatspAppTemplateStore = (shouldUseHook: boolean) => {
  let template = shouldUseHook
    ? useSelector((state) => state.socialmedia.templateWhatspApp)
    : store.getState().socialmedia.templateWhatspApp;

  return template;
};
