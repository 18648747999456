import { PaginatedElements } from 'src/models/pagination';
import { ExtensionProps } from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/models/acdRoutes';
import { getExtensionsByCompanyId } from '../infrastructure/getRoutes';
import { useQuery } from '@tanstack/react-query';

/** GET EXTENSIONS BY ID
 *
 * Get extension by id
 *
 *@params company id
 * @returns extension
 */
export const getExtensionById = async (
  id: string
): Promise<PaginatedElements<ExtensionProps>> => {
  let extension = await getExtensionsByCompanyId(id);
  return extension;
};

export const useExtensionByIdQuery = (id: string) => {
  return useQuery(['extensionById', id], () => getExtensionById(id), {
    enabled: true,
    staleTime: 60000
  });
};
