import { StringIfPlural } from 'react-i18next';
import { Conversation } from 'src/models/conversations/conversations';
import store, { useSelector } from 'src/store/store';

/** GET CURRENT CONVERSATION ID FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of conversations
 */
export const getCurrentConversationIdStore = (
  shouldUseHook: boolean
): string => {
  const conversationId = shouldUseHook
    ? useSelector((state) => state.socialmedia.currentConversationId)
    : store.getState().socialmedia.currentConversationId;

  return conversationId;
};

/** GET CURRENT MESSAGE ID FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of conversations
 */
export const getCurrentMessageIdStore = (shouldUseHook: boolean): string[] => {
  const messageId = shouldUseHook
    ? useSelector((state) => state.socialmedia.messageId)
    : store.getState().socialmedia.messageId;

  return messageId;
};

/** GET CURRENT MESSAGE CHANNEL FROM STORE
 *
 * @param shouldUseHook Make it true if using a componen that should be
 * re-rendered when the state changes.
 *
 * @returns Array of conversations
 */
export const getCurrentMessageChannelStore = (
  shouldUseHook: boolean
): string => {
  const conversationChannel = shouldUseHook
    ? useSelector((state) => state.socialmedia.conversationChannel)
    : store.getState().socialmedia.conversationChannel;

  return conversationChannel;
};
