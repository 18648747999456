import { Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { ColorPicker } from '../../../../components/Style/ColorPicker';
import { t } from 'i18next';
import { WebChatStyle } from '../../../../../models/webChatStyle';
import { FormikProps } from 'formik';

interface ColorBaseAccordionProps {
  formikProps: FormikProps<WebChatStyle>;
}

const ColorBaseAccordion: React.FC<ColorBaseAccordionProps> = ({
  formikProps
}) => {
  return (
    <>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="32px">
          <ColorPicker
            title={t('Header background')}
            color={formikProps.values.conversationStyle.titleColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.titleColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="32px">
          <ColorPicker
            title={t('Header text')}
            color={formikProps.values.conversationStyle.titleText.color}
            setColor={(c) => {
              formikProps.setFieldValue('conversationStyle.titleText.color', c);
            }}
          />
        </Stack>
        <Stack paddingRight="32px">
          <ColorPicker
            title={t('Header controls')}
            color={formikProps.values.conversationStyle.controlsColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.controlsColor.color',
                c
              );
            }}
          />
        </Stack>
      </Stack>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ xs: 2 }}>
        <Stack paddingRight="32px">
          <ColorPicker
            title={t('Main content')}
            color={formikProps.values.conversationStyle.mainContentColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.mainContentColor.color',
                c
              );
            }}
          />
        </Stack>
        <Stack paddingRight="32px" sx={{ marginLeft: '0px' }}>
          <ColorPicker
            title={t('Bubble color')}
            color={formikProps.values.conversationStyle.bubbleColor.color}
            setColor={(c) => {
              formikProps.setFieldValue(
                'conversationStyle.bubbleColor.color',
                c
              );
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ColorBaseAccordion;
