import axios from 'axios';
import {
  WhatsAppTemplate,
  WhatsAppTemplateCategory,
  WhatsAppTemplateLanguage,
  WhatsAppTemplateType
} from '../../models/WhatsAppTemplate';
import {
  WHATSAPP_TEMPLATE,
  WHATSAPP_TEMPLATE_ID
} from 'src/utils/apiRoutes/msWhatsapp';
import { replaceURLParams } from 'src/services/utils';

export interface GetWhatsAppTemplateApiParams {
  accountId?: string;
  category?: WhatsAppTemplateCategory;
  companyId?: string;
  enabled?: boolean;
  facebookId?: string;
  id?: string;
  language?: WhatsAppTemplateLanguage;
  placeholders?: string[];
  providerId?: string;
  text?: string;
  type?: WhatsAppTemplateType;
}

export const getWhatsAppTemplatesApi = async (
  params?: GetWhatsAppTemplateApiParams
) => {
  const resp = await axios.get<WhatsAppTemplate[]>(WHATSAPP_TEMPLATE, {
    params
  });
  return resp.data;
};

export const getWhatsAppTemplateByIdApi = async (id: string) => {
  const path = replaceURLParams(WHATSAPP_TEMPLATE_ID, { '{id}': id });
  const resp = await axios.get<WhatsAppTemplate>(path);
  return resp.data;
};
