import { LinearProgress, Box, Typography, styled, Stack } from '@mui/material';
import React from 'react';

interface LineProgressPropTypes {
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  value: number;
}

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
        flex-grow: 0.5;
        margin-right: ${theme.spacing(1)};
`
);

const LineProgress: React.FC<LineProgressPropTypes> = ({ color, value }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        flexGrow: 1,
        mb: 0.5
      }}
    >
      <LinearProgressWrapper
        value={value}
        variant="determinate"
        color={color}
        sx={{ width: '50%' }}
      />

      <Typography
        variant="h4"
        color="text.primary"
        sx={{ width: '50%' }}
        align="center"
      >
        {' '}
        {value}%
      </Typography>
    </Stack>
  );
};

export default LineProgress;
