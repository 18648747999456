import { BASE_URL } from './base';

//CONTACTS MS
export const CONTACTS_MS = `${BASE_URL}/contacts`;

// CONTACTS CONTROLLER
const CONTACTS_CONTROLLER = `${CONTACTS_MS}/contacts`;
export const CONTACTS = CONTACTS_CONTROLLER;
export const CONTACTS_ID = `${CONTACTS_CONTROLLER}/{id}`;
export const CONTACTS_UPLOAD_CSV = `${CONTACTS_CONTROLLER}/upload-csv`;
export const CONTACTS_LIST = `${CONTACTS_CONTROLLER}/list`;
export const CONTACTS_FIELD_VALUE = `${CONTACTS_CONTROLLER}/field_value`;

// TAGS CONTROLLER
const TAGS_CONTROLLER = `${CONTACTS_MS}/tags`;
export const TAGS = TAGS_CONTROLLER;
export const TAGS_ID = `${TAGS_CONTROLLER}/{id}`;

// FIELDS CONTROLLER
const CONTACT_FIELDS_CONTROLLER = `${CONTACTS_MS}/fields`;
export const CONTACT_FIELDS = CONTACT_FIELDS_CONTROLLER;
export const CONTACT_FIELDS_ID = `${CONTACT_FIELDS_CONTROLLER}/{id}`;

// CONTACT GROUP CONTROLLER
const CONTACT_GROUP_CONTROLLER = `${CONTACTS_MS}/contactGroup`;
export const CONTACT_GROUP = CONTACT_GROUP_CONTROLLER;
export const CONTACT_GROUP_ID = `${CONTACT_GROUP_CONTROLLER}/{id}`;
