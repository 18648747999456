import axios, { AxiosResponse } from 'axios';
import { MUSIC_ON_HOLD } from 'src/utils/apiRoutes/msAcd';
import { MusicOnHold } from '../../MOH/model/MusicOnHold';

export interface MohRequestParams {}

export const getMusicOnHold = async (
  params: MohRequestParams
): Promise<MusicOnHold[]> => {
  const response: AxiosResponse<MusicOnHold[]> = await axios.get(
    MUSIC_ON_HOLD,
    params
  );
  return response.data;
};
