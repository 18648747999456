import axios from 'axios';
import { ContactTag } from 'src/modules/Contacts/models/contacts';
import { replaceURLParams } from 'src/services/utils';
import { TAGS_ID } from 'src/utils/apiRoutes/msContacts';

export const deleteContactTagApi = async (tag: ContactTag): Promise<void> => {
  if (!tag?.id) {
    throw new Error('No tag id');
  }
  const path = replaceURLParams(TAGS_ID, { '{id}': tag.id });

  await axios.delete(path);
};
