import { PaginationState } from '@tanstack/react-table';

export const defaultRowsPerPageOptions = [10, 25, 50];

export const getDefaultPaginationState = (
  rowsPerPageOptions: number[] = defaultRowsPerPageOptions
) => {
  const defaultPaginationState: PaginationState = {
    pageIndex: 0,
    pageSize: rowsPerPageOptions[0]
  };
  return defaultPaginationState;
};

// export interface PaginationValues {
//   page: number;
//   size: number;
// }
