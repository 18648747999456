import {
  getCoordinatorQueuesIds,
  getCoordinatorUsersIds
} from './coordinatorFunctions';
import { deleteDashboardById } from './deleteDashboards';
import { deleteAlert, deletePanel } from './deletePanels';
import { getDashboards, getDashboardById } from './getDashboards';
import {
  getAllPanels,
  getCalculations,
  getChartTypes,
  getCoordinatorDataTypes,
  getDataTypes,
  getGroupIntervals,
  getKpiDataTypes,
  getKpiIdentifiers,
  getPanel,
  getPanelCampaginName,
  getPanelData,
  getPanelKpiCalculations,
  getPanelsByPanelsIds,
  getPanelServiceName,
  getPanelSizes,
  getPanelTypes
} from './getPanels';
import {
  convertGLayoutToPanelParameters,
  getGridlayoutByDashboard,
  getNextLayoutPosition,
  getPanelDefaultSize
} from './gridLayoutConversions';
import { createNewKpiObject, getBaseKpiObject } from './kpiFunctions';
import {
  addPanelToDashboard,
  createNewDashboard,
  subscribeToDashboard,
  unSubscribeToDashboard,
  updateDashboard
} from './postDashboards';
import {
  createAlert,
  createNewPanel,
  updatePanel,
  updatePanelParams
} from './postPanels';
import {
  updateDashboardPanelsGroups,
  updateDashboardTimes
} from './putDashboard';
import { getEntitiesWithCreatePermissions } from './getEntitiesWithCreatePermissions';

const dashboardLogic = {
  getDashboards,
  createNewDashboard,
  deleteDashboardById,
  updateDashboard,
  addPanelToDashboard,
  getDashboardById,
  getAllPanels,
  getPanel,
  createNewPanel,
  updatePanel,
  createAlert,
  deletePanel,
  deleteAlert,
  getGridlayoutByDashboard,
  getPanelsByPanelsIds,
  updatePanelParams,
  convertGLayoutToPanelParameters,
  subscribeToDashboard,
  unSubscribeToDashboard,
  getDataTypes,
  getCalculations,
  getPanelTypes,
  getPanelData,
  getPanelSizes,
  getGroupIntervals,
  getPanelKpiCalculations,
  getChartTypes,
  createNewKpiObject,
  getBaseKpiObject,
  getCoordinatorUsersIds,
  getCoordinatorQueuesIds,
  getKpiIdentifiers,
  getPanelDefaultSize,
  updateDashboardTimes,
  getKpiDataTypes,
  getCoordinatorDataTypes,
  getNextLayoutPosition,
  getPanelCampaginName,
  getPanelServiceName,
  updateDashboardPanelsGroups,
  getEntitiesWithCreatePermissions
};

export default dashboardLogic;
