import type { MouseEventHandler, ReactNode } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InboxIcon from '@mui/icons-material/Inbox';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import { Permissions } from 'src/models/permissionGroups';
import { logoutCurrentUser } from 'src/services/authentication/domain/logoutCurrentUser';
import {
  checkAdminPermissions,
  checkContactsPermissions,
  checkInboxPermissions,
  checkProductivityPermissions,
  checkReadAnalyzePermissions,
  checkReadAnnouncementPermissions,
  checkReadAnyCallLaunchCampaignPermissions,
  checkReadApiKeysPermissions,
  checkReadCallerIdsPermissions,
  checkReadContactPermissions,
  checkReadEmailAccountPermissions,
  checkReadEntityModelsPermissions,
  checkReadExtensionPermissions,
  checkReadExternalActionsPermissions,
  checkReadInboundRoutePermissions,
  checkReadInstagramAccountPermissions,
  checkReadIntentionModelsPermissions,
  checkReadKeyWordsGroupsPermissions,
  checkReadKnowledgeModelsPermissions,
  checkReadMassiveEmailPermissions,
  checkReadMassiveSmsPermissions,
  checkReadMassiveWhatsAppPermissions,
  checkReadMusicOnHoldPermissions,
  checkReadOutboundCampaignPermissions,
  checkReadPromptsPermissions,
  checkReadQuestionGroupsPermissions,
  checkReadRobinsonListsPermissions,
  checkReadServicePermissions,
  checkReadTelegramAccountPermissions,
  checkReadTicketPermissions,
  checkReadTimeConditionPermissions,
  checkReadTrunksPermissions,
  checkReadUserPermissions,
  checkReadUserRolePermissions,
  checkReadVariablePermissions,
  checkReadWebChatAccountPermissions,
  checkReadWhatsAppAccountPermissions,
  checkUpdateServicePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { Features } from 'src/modules/Admin/modules/General/modules/Companies/models/CompanyFeatures';
import {
  checkCallChannelFeature,
  checkInstagramChannelFeature,
  checkEmailChannelFeature,
  checkRolesAndPermsFeature,
  checkWebChatChannelFeature,
  checkTelegramChannelFeature,
  checkWhatsAppChannelFeature,
  checkKnowledgeModelsFeature,
  checkNluFeature,
  checkMassiveEmailFeature,
  checkMassiveSmsFeature,
  checkMassiveWhatsAppFeature,
  checkSpeechAnalyticsFeature,
  checkExternalActionFeature
} from 'src/contexts/CompanyFeaturesContext/utils/checkFeatures';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
  permissionCheckerFn?: (permissions: Permissions) => boolean;
  featureCheckerFn?: (features: Features) => boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface MenuItemExtended extends MenuItem {
  isVisible: boolean;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Inbox',
        icon: InboxIcon,
        link: '/dashboard/inbox',
        permissionCheckerFn: checkInboxPermissions
      },
      {
        name: 'Services',
        icon: ArticleIcon,
        link: '/dashboard/services',
        permissionCheckerFn: checkUpdateServicePermissions
      },
      {
        name: 'Contacts',
        icon: PersonIcon,
        link: '/dashboard/contacts',
        permissionCheckerFn: checkContactsPermissions
      },
      {
        name: 'Calendar',
        icon: CalendarTodayIcon,
        link: '/dashboard/schedule',
        permissionCheckerFn: (permissions) => true
      },
      {
        name: 'Productivity',
        icon: BarChartIcon,
        link: '/dashboard/productivity/live',
        permissionCheckerFn: checkProductivityPermissions
      },
      {
        name: 'Admin',
        icon: SettingsIcon,
        link: '/dashboard/admin',
        items: [
          {
            name: 'General Settings',

            items: [
              {
                name: 'Companies',
                link: '/dashboard/admin/settings/companies'
              },
              {
                name: 'Users',
                link: '/dashboard/admin/general/view-users',
                permissionCheckerFn: checkReadUserPermissions
              },
              {
                name: 'Roles and permissions',
                link: '/dashboard/admin/general/roles-and-permissions',
                permissionCheckerFn: checkReadUserRolePermissions,
                featureCheckerFn: checkRolesAndPermsFeature
              },
              {
                name: 'Variables',
                link: '/dashboard/admin/general/variables',
                permissionCheckerFn: checkReadVariablePermissions
              }
            ]
          },
          {
            name: 'Operations',
            items: [
              {
                name: 'Services',
                link: '/dashboard/admin/operations/services',
                permissionCheckerFn: checkReadServicePermissions
              },
              {
                name: 'Tickets',
                link: '/dashboard/admin/operations/tickets',
                permissionCheckerFn: checkReadTicketPermissions
              },
              {
                name: 'Contacts',
                link: '/dashboard/admin/operations/view-contacts',
                permissionCheckerFn: checkReadContactPermissions
              }
            ]
          },
          {
            name: 'Calls',

            items: [
              {
                name: 'Caller Ids',
                link: '/dashboard/admin/calls/caller-ids',
                permissionCheckerFn: checkReadCallerIdsPermissions,
                featureCheckerFn: checkCallChannelFeature
              },
              {
                name: 'Outbound Routes',
                link: '/dashboard/admin/calls/outbound-routes',
                permissionCheckerFn: checkReadOutboundCampaignPermissions,
                featureCheckerFn: checkCallChannelFeature
              },
              {
                name: 'Extensions',
                link: '/dashboard/admin/calls/extensions',
                permissionCheckerFn: checkReadExtensionPermissions,
                featureCheckerFn: checkCallChannelFeature
              },
              {
                name: 'Robinson List',
                link: '/dashboard/admin/calls/robinson-list',
                permissionCheckerFn: checkReadRobinsonListsPermissions,
                featureCheckerFn: checkCallChannelFeature
              },
              {
                name: 'Trunks',
                link: '/dashboard/admin/calls/trunks',
                permissionCheckerFn: checkReadTrunksPermissions,
                featureCheckerFn: checkCallChannelFeature
              }
            ]
          },
          {
            name: 'Channels',
            items: [
              {
                name: 'WebChat',
                link: '/dashboard/admin/contact-center/chat',
                permissionCheckerFn: checkReadWebChatAccountPermissions,
                featureCheckerFn: checkWebChatChannelFeature
              },
              {
                name: 'Email',
                link: '/dashboard/admin/contact-center/email',
                permissionCheckerFn: checkReadEmailAccountPermissions,
                featureCheckerFn: checkEmailChannelFeature
              },
              {
                name: 'Instagram',
                link: '/dashboard/admin/contact-center/instagram',
                permissionCheckerFn: checkReadInstagramAccountPermissions,
                featureCheckerFn: checkInstagramChannelFeature
              },
              {
                name: 'Telegram',
                link: '/dashboard/admin/contact-center/telegram',
                permissionCheckerFn: checkReadTelegramAccountPermissions,
                featureCheckerFn: checkTelegramChannelFeature
              },
              {
                name: 'WhatsApp',
                link: '/dashboard/admin/contact-center/whatsapp',
                permissionCheckerFn: checkReadWhatsAppAccountPermissions,
                featureCheckerFn: checkWhatsAppChannelFeature
              }
            ]
          },
          {
            name: 'Acd',
            items: [
              {
                name: 'Inbound Routes',
                link: '/dashboard/admin/acd/routes',
                permissionCheckerFn: checkReadInboundRoutePermissions
              },
              {
                name: 'Models',
                link: '/dashboard/admin/acd/models',
                permissionCheckerFn: (permissions) =>
                  checkReadIntentionModelsPermissions(permissions) ||
                  checkReadEntityModelsPermissions(permissions) ||
                  checkReadKnowledgeModelsPermissions(permissions),
                featureCheckerFn(features) {
                  return (
                    checkKnowledgeModelsFeature(features) ||
                    checkNluFeature(features)
                  );
                }
              },
              {
                name: 'Music On Hold',
                link: '/dashboard/admin/acd/moh',
                permissionCheckerFn: checkReadMusicOnHoldPermissions
              },
              {
                name: 'Temporal conditions',
                link: '/dashboard/admin/acd/schedule-groups',
                permissionCheckerFn: checkReadTimeConditionPermissions
              },
              {
                name: 'Announcements',
                link: '/dashboard/admin/acd/announcements',
                permissionCheckerFn: checkReadAnnouncementPermissions
              },
              {
                name: 'Prompts',
                link: '/dashboard/admin/acd/prompts',
                permissionCheckerFn: checkReadPromptsPermissions
              }
            ]
          },
          {
            name: 'Massive',

            items: [
              {
                name: 'Call launch engines',
                link: '/dashboard/admin/massive/dialerbot',
                permissionCheckerFn: checkReadAnyCallLaunchCampaignPermissions,
                featureCheckerFn: checkCallChannelFeature
              },
              {
                name: 'Massive SMS',
                link: '/dashboard/admin/massive/massive-sms',
                permissionCheckerFn: checkReadMassiveSmsPermissions,
                featureCheckerFn: checkMassiveSmsFeature
              },
              {
                name: 'Massive Email',
                link: '/dashboard/admin/massive/massive-email',
                permissionCheckerFn: checkReadMassiveEmailPermissions,
                featureCheckerFn: checkMassiveEmailFeature
              },
              {
                name: 'Massive WhatsApp',
                link: '/dashboard/admin/massive/massive-whatsapp',
                permissionCheckerFn: checkReadMassiveWhatsAppPermissions,
                featureCheckerFn: checkMassiveWhatsAppFeature
              }
            ]
          },
          {
            name: 'Quality',
            items: [
              {
                name: 'Keyword Groups',
                link: '/dashboard/admin/quality/keyword-groups',
                permissionCheckerFn: checkReadKeyWordsGroupsPermissions
              },
              {
                name: 'Analyzed Conversations',
                link: '/dashboard/admin/quality/analyzed-conversations',
                permissionCheckerFn: checkReadAnalyzePermissions,
                featureCheckerFn: checkSpeechAnalyticsFeature
              },
              {
                name: 'Questions Groups',
                link: '/dashboard/admin/quality/questions-groups',
                permissionCheckerFn: checkReadQuestionGroupsPermissions
              }
            ]
          },
          {
            name: 'Integrations',
            items: [
              {
                name: 'External Actions',
                link: '/dashboard/admin/integrations/externalActions',
                permissionCheckerFn: checkReadExternalActionsPermissions,
                featureCheckerFn: checkExternalActionFeature
              },
              {
                name: 'API Keys',
                link: '/dashboard/admin/integrations/api-keys',
                permissionCheckerFn: checkReadApiKeysPermissions
              }
            ]
          }
        ],
        permissionCheckerFn: checkAdminPermissions
      }
    ]
  },
  {
    heading: 'Others',
    items: [
      {
        name: 'Log out',
        onClick: (e) => {
          logoutCurrentUser();
        },
        icon: PowerSettingsNewIcon
      }
    ]
  }
];

export default menuItems;
