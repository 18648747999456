import { useEffect, useState } from 'react';
import { getContactGroupsQuery } from 'src/modules/Contacts/domain/getContactGroups';
import {
  ContactField,
  Contact,
  ContactFieldType,
  ContactTag
} from 'src/modules/Contacts/models/contacts';
import {
  FormFields,
  getInitialContactFormValuesFrom
} from 'src/services/contacts/application/utils';
import { getContactFieldsQuery } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { getCompanyContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import * as Yup from 'yup';
import { nameRegExp, phoneRegExp } from 'src/services/utils/regEx';
import { t } from 'i18next';
import { TAG_CUSTOM_TEXT } from '../../../utils/constants';
import AuthManager from 'src/services/authentication/manager';

interface Props {
  contact?: Contact;
  defaultContactGroupId?: string;
}

export interface ContactDataFormValues extends FormFields {
  submit: string;
}

const useContactDataForm = ({ contact, defaultContactGroupId }: Props) => {
  const [companyId, setCompanyId] = useState(
    contact?.companyId ?? AuthManager.getLoggedUserCompanyId()
  );

  // Contact group id
  const [contactGroupId, setContactGroupId] = useState(
    contact?.contactGroupId ?? defaultContactGroupId
  );

  // Dynamically set contactfields
  const [contactFields, setContactFields] = useState([]);

  // fieldTypes is a Set of ContactField types (an array of unique values of type), e.g. ['Name', 'TelegramUsername', 'Email' , 'Custom']
  // a ContactDataAccordion will be rendered for each fieldType
  const fieldTypes: ContactFieldType[] = [
    ...new Set(contactFields?.map((field: ContactField) => field.type))
  ];

  // fieldNames is an array of field names filtered by 'Name' type
  // a TextField will be rendered for each fieldName
  const fieldNames = contactFields
    ?.filter((f) => f.type !== 'Name')
    .map((field: ContactField) => field.name);

  // Get the required contact fields
  const requiredFields = contactFields
    .filter((field: ContactField) => field.required)
    .map((field: ContactField) => field.name);

  const hasEmptyRequiredFields = (
    values: ContactField[],
    fieldType: ContactFieldType
  ) => {
    const emptyRequiredFields = (contactFields as ContactField[]).filter(
      (f) =>
        f.type === fieldType &&
        f.autoGenerated === false &&
        requiredFields.includes(f.name) &&
        !values?.some?.((v) => v.name === f.name && v.value.length > 0)
    ).length;
    return emptyRequiredFields > 0;
  };

  // All contact tags of the contact group
  const [allContactTags, setAllContactTags] = useState<ContactTag[]>([]);

  const [openNewTagModal, setOpenNewTagModal] = useState(false);

  // Contact tags of the contact
  const [selectedTags, setSelectedTags] = useState<string[]>(
    contact?.tags ?? []
  );

  const addNewTag = () => {
    setOpenNewTagModal(true);
    setSelectedTags(
      selectedTags.filter((tag) => tag != TAG_CUSTOM_TEXT.CREATE_TAG_ID)
    );
  };

  // format and adapt contact fields to Formik initial values (FormFields) in order to be able to push, update and delete fields using a FieldArray
  const [initialValues, setInitialValues] = useState<FormFields>(
    getInitialContactFormValuesFrom(contactFields, contact)
  );

  useEffect(() => {
    if (contactGroupId) {
      getContactFieldsQuery({
        contactGroupId: contactGroupId,
        companyId: companyId
      }).then((response) => {
        const contactFields = response?.elements;
        setInitialValues(
          getInitialContactFormValuesFrom(contactFields, contact)
        );
        setContactFields(contactFields);
      });
      getContactGroupsQuery().then((resp) => {
        const groups = resp?.elements;
        const companyId = groups.find(
          (g) => g.id === contactGroupId
        )?.companyId;
        setCompanyId(companyId);
      });
      getCompanyContactTagsQuery({ contactGroupId, companyId }).then(
        (response) => {
          setAllContactTags(response?.elements);
        }
      );
    }
  }, [contactGroupId]);

  // Formik dinamic validations
  const validationSchema = Yup.object().shape({
    // Validate that the value of Phone from FormFields does not include letters and it is a phone number
    Name: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string().when('name', {
          is: (name: string) => requiredFields.includes(name),
          then: Yup.string()
            .matches(nameRegExp, t('Name contains not allowed characters'))
            .required(t('This field is required')),
          otherwise: Yup.string().matches(
            nameRegExp,
            t('Name contains not allowed characters')
          )
        })
      })
    ),
    Phone: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string().when('name', {
          is: (name: string) => requiredFields.includes(name),
          then: Yup.string()
            .matches(phoneRegExp, t('Phone numbers must be 3-15 digits long'))
            .required(t('This field is required')),
          otherwise: Yup.string().matches(
            phoneRegExp,
            t('Phone numbers must be 3-15 digits long')
          )
        })
      })
    ),
    Email: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string().when('name', {
          is: (name: string) => requiredFields.includes(name),
          then: Yup.string()
            .email(t('Invalid email. Please enter a valid user and domain.'))
            .required(t('This field is required')),
          otherwise: Yup.string().email(
            t('Invalid email. Please enter a valid user and domain.')
          )
        })
      })
    ),
    Custom: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string().when('name', {
          is: (name: string) => requiredFields.includes(name),
          then: Yup.string().required(t('This field is required')),
          otherwise: Yup.string()
        })
      })
    ),
    TelegramUsername: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string().when('name', {
          is: (name: string) => requiredFields.includes(name),
          then: Yup.string().required(t('This field is required')),
          otherwise: Yup.string()
        })
      })
    ),
    InstagramUsername: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string().when('name', {
          is: (name: string) => requiredFields.includes(name),
          then: Yup.string().required(t('This field is required')),
          otherwise: Yup.string()
        })
      })
    )
  });

  return {
    // general
    companyId,
    contactGroupId,
    setContactGroupId,

    // contact fields
    contactFields,
    fieldTypes,
    fieldNames,
    requiredFields,
    hasEmptyRequiredFields,

    // tags
    allContactTags,
    setAllContactTags,
    openNewTagModal,
    setOpenNewTagModal,
    addNewTag,
    selectedTags,
    setSelectedTags,

    // formik props
    initialValues,
    validationSchema
  };
};

export default useContactDataForm;
