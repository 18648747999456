import {
  CallResultCount,
  DialerbotFiles
} from 'src/modules/DialerBot/models/dialerbot';
import { PaginatedElements } from 'src/models/pagination';
import {
  getCallResultsCount,
  getUploadedSchedules
} from '../infrastructure/api/callSchedulling';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { logger } from 'src/utils/logger';

/** GET DIALERBOT SCHEDULES BY ID
 *
 * Get the dialerbot schedules by campaign Id
 *@param campaignId Id of campaign
 * @returns schedules
 */
export const getSchedulesById = async (
  campaignId: string,
  currentPage?: number
): Promise<PaginatedElements<DialerbotFiles>> => {
  if (!campaignId)
    return { currentPage: 0, elements: [], totalItems: 0, totalPages: 0 };
  let schedules = await getUploadedSchedules(campaignId, currentPage);
  return schedules;
};

/** GET CALL RESULTS COUNT
 *
 *@param campaignId Id of campaign
 *@param scheduleDatabaseId Upload file id
 * @returns call result count
 */
export const getCallResultsCountById = async (
  campaignId: string,
  scheduleDatabaseId?: string
): Promise<CallResultCount> => {
  try {
    let callResultsCount = await getCallResultsCount(
      campaignId,
      scheduleDatabaseId
    );
    return callResultsCount;
  } catch (error) {
    const { errorMessage } = getErrorMessage(error);
    logger.error('ERROR: ' + errorMessage);
  }
};

/** GET CALL RESULTS OF MULTIPLE FILES
 * *@param elements schedules elements
 *@param campaignId Id of campaign
 * @returns FULL CALL RESULTS OBJECTS
 */

export const getCallResultForMultipleFiles = async (
  elements: DialerbotFiles[],
  campaignId: string,
  singleFile: boolean,
  index?: number
) => {
  let fullCallResults = {};
  if (singleFile) {
    const resultCount: CallResultCount = await getCallResultsCountById(
      campaignId,
      elements[index].id
    );
    fullCallResults[elements[index].id] = resultCount;
  } else {
    for (const element of elements) {
      const resultCount: CallResultCount = await getCallResultsCountById(
        campaignId,
        element.id
      );
      fullCallResults[element.id] = resultCount;
    }
  }
  return fullCallResults;
};

/** REMOVE NULLS FROM CALL RESULTS
 * *@param CallResultCount call results array
 * @returns CallResult array without nulls
 */
export const removeNullCallResults = (CallResultCount: CallResultCount[]) => {
  return CallResultCount.map((callResult) => {
    if (callResult._id !== null) return callResult;
  });
};
