import {
  Call,
  EmailOutlined,
  WhatsApp,
  Telegram,
  Chat,
  Instagram,
} from "@mui/icons-material";
import { IconProps, SvgIconProps } from "@mui/material";
import { ChannelType } from "src/models/conversations/conversations";

export const getChannelIcon = (
  channel: ChannelType,
  iconProps?: SvgIconProps
) => {
  const channelIcon = {
    [ChannelType.CALL]: <Call fontSize="small" {...iconProps} />,
    [ChannelType.EMAIL]: <EmailOutlined fontSize="small" {...iconProps} />,
    [ChannelType.WHATSAPP]: (
      <WhatsApp color="success" fontSize="small" {...iconProps} />
    ),
    [ChannelType.TELEGRAM]: (
      <Telegram color="secondary" fontSize="small" {...iconProps} />
    ),
    [ChannelType.WEBCHAT]: (
      <Chat color="warning" fontSize="small" {...iconProps} />
    ),
    [ChannelType.INSTAGRAM]: (
      <Instagram style={{ color: "#c13584" }} fontSize="small" {...iconProps} />
    ),
  };

  return channelIcon[channel];
};
