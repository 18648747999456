import Label, { LabelColorType } from 'src/components/Label';
import { TicketSubResolution, TicketType } from '../../../../models/tickets';
import { Typography } from '@mui/material';

interface Props {
  subResolution: TicketSubResolution;
}

const TicketSubResolutionLabel = (props: Props) => {
  const { subResolution } = props;
  const getColor = (): LabelColorType => {
    if (
      subResolution.type === TicketType.NEW ||
      subResolution.type === TicketType.CLOSED_POSITIVE
    ) {
      return 'success';
    }
    if (subResolution.type === TicketType.TOUCHED) {
      return 'warning';
    }
    if (
      subResolution.type === TicketType.CLOSED_NEGATIVE ||
      subResolution.type === TicketType.CLOSED_NOT_USEFUL
    ) {
      return 'error';
    }
  };
  const color = getColor();
  return (
    <Label sx={{ py: 0 }} color={color}>
      <Typography>{subResolution.type}</Typography>
    </Label>
  );
};

export default TicketSubResolutionLabel;
