import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  $getNodeByKey,
  KEY_TAB_COMMAND,
  COMMAND_PRIORITY_EDITOR
} from 'lexical';
import { useEffect } from 'react';

// Plugin para manejar la indentación con Tab
const TabIndentPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand<KeyboardEvent>(
      KEY_TAB_COMMAND,
      (event) => {
        event.preventDefault();
        //add \t to the cursor position
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return false;
        }
        const { anchor } = selection;
        const node = $getNodeByKey(anchor.key);
        if (!node) {
          return false;
        }
        if (event.shiftKey) {
          const thereIsTabBeforeCursor =
            node.getTextContent().charAt(anchor.offset - 1) === '\t';
          if (thereIsTabBeforeCursor) {
            selection.deleteCharacter(true);
          }
          return;
        }
        //insert \t to the cursor position
        selection.insertText('\t');

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
};

export default TabIndentPlugin;
