import { Button, Stack, Typography } from '@mui/material';
import {
  FinishReason,
  VideocallRoomContext
} from '../../context/VideocallRoomContext';
import { useContext, useEffect } from 'react';
import { t } from 'i18next';
import { VideoroomWebRTCService } from '../../infrastructure/webrtc';
import { VideoroomWSService } from '../../infrastructure/websocket/websocket';
import { useTranslation } from 'react-i18next';
import { stopStream } from '../../utils/videocalls-webrtc-devices';

interface VideocallFinishedViewProps {
  finishedReason: FinishReason;
}

export const VideocallFinished = ({
  finishedReason
}: VideocallFinishedViewProps) => {
  const context = useContext(VideocallRoomContext);
  const { streams } = context;

  const { t } = useTranslation();

  const isNormal = finishedReason == FinishReason.NORMAL;
  const isDenied = finishedReason == FinishReason.DENIED;
  const isKickOut = finishedReason == FinishReason.KICK_OUT;
  const isFull = finishedReason == FinishReason.IS_FULL;
  const isError = finishedReason == FinishReason.ERROR;
  const isNoConnection = finishedReason == FinishReason.NO_CONNECTION;

  useEffect(() => {
    VideoroomWebRTCService.instance.disconnectWebRTC();
    VideoroomWSService.instance.disconnect();
    const localStream = streams.local;
    stopStream(localStream);
  }, []);

  return (
    <>
      <Stack
        sx={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          rowGap: 2
        }}
      >
        <Typography variant="h1">
          {isNormal && t('Thank you for attending!')}
          {isDenied && t('You have been rejected...')}
          {isKickOut && t('You have been kicked out of the video call')}
          {isFull && t('The videoroom is full')}
          {isError && t('The video call has already ended or does not exist')}
          {isNoConnection && t('Could not connect with videocalls')}
        </Typography>
        <Typography fontFamily={'Inter'}>
          {t('Videocall has finished.')}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            context.toInitialContext();
          }}
        >
          {t('Go back')}
        </Button>
      </Stack>
    </>
  );
};
